export const FIB_COLOR_CODES = [
  {
    dark: 'rgb(250, 100, 0)',
    light: 'rgba(250, 100, 0, 0.1)',
  },
  {
    dark: 'rgb(0, 145, 255)',
    light: 'rgba(0, 145, 255, 0.1)',
  },
  {
    dark: 'rgb(109, 212, 0)',
    light: 'rgba(109, 212, 0, 0.1)',
  },
  {
    dark: 'rgb(224, 32, 32)',
    light: 'rgba(224, 32, 32, 0.1)',
  },
];

//Supported Extensions
const EXT_JPEG = 'jpeg';
const EXT_PNG = 'png';
const EXT_PDF = 'pdf';
const EXT_DOC = 'doc';
const EXT_DOCX = 'docx';
const EXT_MP4 = 'mp4';
const EXT_MP3 = 'mp3';
//END

//Supported MIMES
const MIME_PDF = 'application/pdf';
const MIME_DOC = 'application/msword';
const MIME_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const MIME_JPEG = 'image/jpeg';
const MIME_PNG = 'image/png';
const MIME_MP4 = 'video/mp4';
const MIME_MP3 = 'audio/mpeg';
//END

export const MIME_TO_EXT_MAP = {
  [MIME_PDF]: EXT_PDF,
  [MIME_DOC]: EXT_DOC,
  [MIME_DOCX]: EXT_DOCX,
  [MIME_JPEG]: EXT_JPEG,
  [MIME_PNG]: EXT_PNG,
  [MIME_MP4]: EXT_MP4,
  [MIME_MP3]: EXT_MP3,
};
