<template>
  <v-card class="pa-4 rounded-lg">
    <v-row>
      <v-col class="pr-2">
        <v-sheet
          color="grey lighten-4"
          width="100%"
          class="text-center mb-2 pt-10 pb-10"
          rounded="rounded"
        >
          <h2 style="font-size: 16px;">
            Total Questions <br />
            Created
          </h2>
          <h1 style="font-size: 48px;">
            {{ getQuestionTypeCounts.totalNumberOfQuestions }}
          </h1>
        </v-sheet>
        <v-sheet
          color="grey lighten-4"
          width="100%"
          class="text-center pt-11 pb-11"
          rounded="rounded"
        >
          <v-row>
            <v-col v-for="(purpose, index) in questionPurposes" :key="index">
              <h2 :style="`font-size: ${questionPurposes.length > 2 ? '14px' : '16px'}`">
                {{ capitalCase(purpose) }}<br />
                Questions
              </h2>
              <h1 style="font-size: 48px;">
                {{
                  getQuestionTypeCounts[purpose]['totalQuestions'] +
                    getQuestionTypeCounts[purpose]['totalGroupQuestions']
                }}
              </h1>
              <v-divider vertical />
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col v-for="(purpose, index) in questionPurposes" :key="index" class="pr-2 pl-2">
        <QuestionCountInfo
          :title="`${capitalCase(purpose)} Questions`"
          :questionPurpose="purpose"
          :data="getQuestionTypeCounts[purpose]"
          :index="index"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import QuestionCountInfo from '@/components/contentEntry/questionComponents/infoCardComponents/QuestionCountInfo.vue';
import { capitalCase } from 'change-case';

export default {
  components: {
    QuestionCountInfo,
  },
  computed: {
    ...mapGetters(['getQuestionTypeCounts']),
    ...mapState({
      questionMetaInfo: (state) => state.questions.questionMetaInfo
    }),
    questionPurposes() {
      try {
        return this.questionMetaInfo.questionPurposes[this.lpType];
      } catch (err) {
        return [];
      }
    }
  },
  methods: {
    capitalCase
  },
  props: {
    lpType: {
      type: String,
      default: 'questions'
    }
  }
};
</script>
