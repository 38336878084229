<template>
  <div style="background-color: #fff; width: 100%;">
    <div v-for="(stage, index) in Object.keys(LP_STAGES)" :key="`commentsBlock${index}`">
      <CommentDetails
        v-if="canShowComment(stage)"
        :commentHash="stageCommentHash(stage)"
        :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
        :canResolveComment="canResolveComment"
        :loggedInUserEmail="loggedInUserEmail"
        :stageName="stage"
        :headerText="LP_STAGES_HEADER_TEXT[stage]"
        :resolveStatus="resolveStatusesHash[stage]"
        :noCommentsText="getNoCommentsString(stage)"
      />
      <CommentInput
        v-if="canShowCommentInput(stage)"
        :commentHash="stageCommentHash(stage)"
        :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
        :canAddComment="canAddComment"
        :loggedInUserEmail="loggedInUserEmail"
        :headerText="LP_STAGES_HEADER_TEXT[stage]"
        :stageName="stage"
        :saveStatus="saveStatusesHash[stage]"
      />
      <div
        style="margin: 0 20px 0 10px;"
        v-if="canShowComment('authoring1Stage') && canShowComment('review1Stage')"
      >
        <hr style="border-top: 1px solid #f6f6f6;" />
      </div>
    </div>
  </div>
</template>

<script>
import CommentDetails from '@/components/comments/CommentDetails';
import CommentInput from '@/components/comments/CommentInput';
import { LP_STAGES, LP_STAGES_HEADER_TEXT } from '@/constants/LessonPlan';
import { PROJECT_LESSON_PLAN_STAGES_IN_ORDER } from '@/constants/Project';
import AppTexts from '@/constants/AppTexts';

export default {
  name: 'Comments',
  props: {
    comments: {
      type: Object,
      required: true,
    },
    currentStage: {
      type: String,
      required: true,
    },
    commentsSaveButtonClickHandler: {
      type: Function,
      required: true,
    },
    commentsResolveButtonClickHandler: {
      type: Function,
      required: true,
    },
    canAddComment: {
      type: Boolean,
      required: true,
    },
    canResolveComment: {
      type: Boolean,
      required: true,
    },
    loggedInUserEmail: {
      type: String,
      required: true,
    },
    saveStatusesHash: {
      type: Object,
      required: true,
    },
    resolveStatusesHash: {
      type: Object,
      required: true,
    },
  },
  methods: {
    stageCommentHash(stage) {
      return this.getPurticularStageComment(stage);
    },
    canShowComment(stage) {
      return this.currentStage !== stage && this.comments[stage];
    },
    canShowCommentInput(stage) {
      return this.currentStage === stage;
    },
    getPurticularStageComment(stage) {
      return (this.comments[stage] || [])[0];
    },
    getNoCommentsString(stage) {
      let stageIndex = PROJECT_LESSON_PLAN_STAGES_IN_ORDER.indexOf(stage);
      let currentStageIndex = PROJECT_LESSON_PLAN_STAGES_IN_ORDER.indexOf(this.currentStage);

      if (currentStageIndex < 0 || stageIndex < 0) {
        return '';
      } else if (stageIndex < currentStageIndex) {
        return AppTexts.NO_COMMENTS_TEXT;
      } else if (stageIndex > currentStageIndex) {
        return AppTexts.NO_COMMENTS_YET_TEXT;
      } else {
        return '';
      }
    },
  },
  data() {
    return {
      LP_STAGES,
      LP_STAGES_HEADER_TEXT,
    };
  },
  components: {
    CommentDetails,
    CommentInput,
  },
};
</script>
