var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 dialog-header",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.dialogTile)+" ")]),_c('div',{staticClass:"micro-topic-project-form-wrapper"},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary","dark":""},on:{"click":_vm.applyToAll}},'v-btn',attrs,false),on),[_vm._v("Apply To All")])]}}])},[_c('span',[_vm._v("This is meant only for first time creation of a project")])])],1)],1),_vm._l((_vm.newMicroTopicProjectsData),function(projectData,index){return _c('div',{key:'ProjectStageAssigneeFormContent' + index + _vm.id,staticClass:"form-item"},[_c('div',{staticClass:"form-header"},[_c('v-checkbox',{attrs:{"dense":"","label":("\n            " + (_vm.capitalCase(_vm.newMicroTopicProjectsDataKeys[index])) + "\n              " + (projectData.workflowStatus && projectData.workflowStatus == 'completed'
                  ? projectData.enabled
                    ? '(Attempting to Re-open)'
                    : '(published)'
                  : ''))},on:{"change":function($event){return _vm.resetAssigneesIfReopeningProject(projectData)}},model:{value:(projectData.enabled),callback:function ($$v) {_vm.$set(projectData, "enabled", $$v)},expression:"projectData.enabled"}})],1),_c('div',{staticClass:"form-content"},[_c('v-form',{ref:"project-assignee-form",refInFor:true},[(projectData.enabled)?_c('ProjectStageAssigneeFormContent',{attrs:{"projectData":projectData,"getUsersByRoles":_vm.getUsersByRoles,"colsPerRow":"3"}}):_vm._e()],1)],1)])})],2),_c('v-card-actions',{staticClass:"dialog-footer"},[_c('div',{staticClass:"footer-action-buttons"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSaveProject}},[_vm._v(_vm._s(_vm.isUpdate ? 'Update' : 'Create'))]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeProjectDialog}},[_vm._v("Cancel")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }