<template>
  <div class="stepper-wrapper">
    <div v-if="headerText && headerText.length && !showOnlyLastUpdateTime" class="header-wrapper">
      <div class="header-text">{{ headerText }}</div>
      <div class="step-label">{{ updateDateText }}</div>
    </div>
    <div v-if="showOnlyLastUpdateTime" class="header-wrapper">
      <div class="step-label-1">{{ getUpdatedTimeText }}</div>
    </div>

    <div>
      <div class="step-graph-wrapper">
        <div class="name-wrapper">
          <div :class="'user-name user-' + index" v-for="(item, index) in steps" :key="index">
            {{ userData[item.Type] ? userData[item.Type] : 'skipped' }}
          </div>
        </div>

        <div
          v-for="(item, index) in steps"
          :key="index"
          :class="
            'step-divider step-' +
            index +
            (index == currentStepIndex
              ? ' current-active-step'
              : index < currentStepIndex
              ? ' non-completed-step'
              : ' completed-step')
          "
          :style="stepDividerStyle(index)"
        >
          <span :class="stepClass(userData[item.Type])">{{ stepData(userData[item.Type]) }}</span>
          <span class="step-label">{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  data() {
    return {
      activeColor: '#BB161B',
      inActiveColor: '#D1D1D1',
    };
  },
  methods: {
    stepData(dataType) {
      console.log(dataType, 'x', this.currentStepIndex);

      if (dataType === null || dataType === 'skipped') {
        return 'x';
      } else {
        return '';
      }
    },
    stepClass(dataType) {
      if (dataType === null || dataType === 'skipped') {
        return 'skip-step-indicator';
      } else {
        return 'step-indicator';
      }
    },
    stepDividerStyle(stepIndex) {
      return {
        width: `${93 / (this.steps.length - 1)}%`,
        color: '#000',
        'background-color':
          stepIndex < this.currentStepIndex ? this.activeColor : this.inActiveColor,
      };
    },
  },
  props: {
    updateDateText: {
      type: String,
      required: false,
    },
    showNames: {
      required: true,
      type: Boolean,
    },
    userData: {
      type: Object,
      required: true,
    },
    headerText: { type: String, required: false },
    steps: {
      type: Array,
      required: false,
      default: () => {
        return [
          { label: 'AU1', Type: 'author' },
          { label: 'R1', Type: 'reviewer1' },
          { label: 'R2', Type: 'reviewer2' },
          { label: 'AU2', Type: 'author2' },
          { label: 'AMR', Type: 'academicSignOff' },
          { label: 'EDT', Type: 'editor' },
          { label: 'VIZ', Type: 'visualizer' },
          { label: 'UA', Type: 'assetUploader' },
          { label: 'AA', Type: 'assetAuthorizer' },
          { label: 'PR', Type: 'proofReader' },
        ];
      },
    },
    currentStepIndex: { type: Number, required: true },

    showOnlyLastUpdateTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getUpdatedTimeText() {
      let temp = this.updateDateText || '';
      return temp.charAt(0).toUpperCase() + temp.slice(1);
    },
  },
};
</script>
<style scoped lang="scss">
.skipped-step {
  background: rgb(209, 209, 209);
  border: 2px solid rgb(209, 209, 209);

  .step-indicator,
  .skip-step-indicator {
    border: 2px solid #999;
    transform: translate(-5px, -7px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e4e4e4;
    background: #ababab;
  }
}
.header-text {
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0px 0px 0px;
}
.stepper-wrapper {
  border-right: 0.5px solid rgb(0, 0, 0, 0.2);
  margin: 10px 0px 10px 15px;
  border-radius: 0px;
}

.skip-step-indicator,
.step-indicator {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  transform: translate(0px, -7px);
}
.header-wrapper {
  padding-left: 6px;
  padding-bottom: 10px;
}

.completed-step .skip-step-indicator,
.non-completed-step .skip-step-indicator,
.current-active-step .skip-step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e4e4e4;
  background: #ababab;
  border: solid 2px #ababab;
}
.name-wrapper {
  display: flex;
  padding: 0px 0 10px 6px;
  .user-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 13.2857%;
    overflow: hidden;
    margin-right: 25px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }
}
.user-name:last-child {
  margin: 0px;
}
.stepper-wrapper {
  background-color: #fff;
}
.step-graph-wrapper {
  padding: 10px 4% 10px 6px;
  text-align: center;
  position: relative;
  left: -5px;
}
.step-divider {
  display: inline-block;
  height: 3px;
  text-align: left;
}
.step-indicator {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  transform: translateY(-6px);
}
.step-graph-wrapper .step-divider:last-child {
  width: 20px !important;
  background-color: transparent !important;
}
.step-label {
  font-size: 11px;
  color: #999;
}
.current-active-step .step-label {
  color: black;
  font-weight: 500;
}
.current-active-step .step-indicator {
  background-color: white;
  border: 2px solid #bb161b;
}
.non-completed-step .step-indicator {
  background-color: #bb161b;
}
.completed-step .step-indicator {
  background-color: white;
  border: 2px solid #999;
}
.step-label-1 {
  font-size: 16px;
  color: #999;
}
</style>
