<template>
  <v-form ref="keywordForm">
    <v-row v-for="(item, index) in items" :key="index" :v-bind="index">
      <v-col class="pad-Rm">
        <TextInput
          key="keyword name"
          name="keyword name"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isKeywordChanged(index)"
          :fieldIdentifier="'aim.keywords[' + index + '].keyword'"
          :placeholder="placeholder"
          v-model="item.keyword"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          customClass
          :readonly="!isNotReadOnly"
          :counter="titleCharacterLength"
        />
      </v-col>
      <v-col class="pad-Lm" cols="6" style="display: flex;">
        <div style="flex-grow: 1;">
          <TextInput
            key="keyword description"
            name="keyword description"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isDescriptionChanged(index)"
            :fieldIdentifier="'aim.keywords[' + index + '].description'"
            :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
            :placeholder="descriptionPlaceholder"
            v-model="item.description"
            customClass
            :readonly="!isNotReadOnly"
            :counter="descriptionCharacterLength"
          />
        </div>
        <div>
          <v-btn style="padding-top: 12px;" icon @click="deleteKeyword(index)" v-if="isNotReadOnly">
            <img height="16" width="16" src="@/assets/close.svg" />
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div style="display: flex; align-items: center;">
      <div>
        <v-btn
          class="xs-accent-button mgn-Tm"
          @click="addKeyword"
          :class="!canAddKeyword() ? 'disable-btn-click-event' : ''"
          >{{ AppTexts.ADD_KEYWORD }}</v-btn
        >
      </div>
      <div>
        <p
          class="mgn-Lm remaining-step-warning-text"
          style="margin-bottom: 0; padding-top: 12px;"
          v-if="canAddKeyword()"
        >
          {{ getStepAdditionValidationText(maxKeywordLength - items.length, 'keyword') }}
        </p>
      </div>
    </div>
  </v-form>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import TextInput from '@/components/inputs/TextInput';
import { getStepAdditionValidationText } from '@/utils';

export default {
  name: 'ContentEntryKeywords',
  components: {
    TextInput,
  },
  data() {
    return {
      AppTexts,
      Errors,
    };
  },
  methods: {
    getStepAdditionValidationText,
    canAddKeyword() {
      return this.items.length < this.maxKeywordLength && this.isNotReadOnly;
    },
    isKeywordChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.keywordsDiff[index].isChanged === true ||
            this.keywordsDiff[index].keyword.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDescriptionChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.keywordsDiff[index].isChanged === true ||
            this.keywordsDiff[index].description.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    addKeyword: {
      type: Function,
      required: true,
    },
    deleteKeyword: {
      type: Function,
      required: true,
    },
    keywordsDiff: {
      type: Array,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxKeywordLength: {
      type: Number,
      required: false,
      default: 5,
    },
    titleCharacterLength: {
      type: Number,
      default: undefined,
    },
    descriptionCharacterLength: {
      type: Number,
      default: undefined,
    },
  },
};
</script>
