var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"290","persistent":""},model:{value:(_vm.showLoadingDialog),callback:function ($$v) {_vm.showLoadingDialog=$$v},expression:"showLoadingDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"text-align":"center","display":"block"}},[_vm._v(_vm._s(_vm.loadingStatus.message ? _vm.loadingStatus.message : 'Brewing your coffee...'))]),_c('v-card-text',{staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{attrs:{"width":3,"color":"red","indeterminate":""}})],1)],1)],1),_c('FormLayout',{attrs:{"moveToNextStageButtonText":_vm.getNextStageButtonText(),"canMoveProjectLP":!_vm.auth.canMoveProjectLP || _vm.currentStage == 'completedStage',"breadCrumbText":_vm.breadCrumbText,"buttonActions":_vm.buttonActions,"auth":_vm.auth,"onClickMoveToNextStage":_vm.onClickMoveToNextStage,"onClickCompleteAndPublish":function () {},"isCompleted":_vm.currentStage == 'completedStage',"isPublished":false,"canPublishProject":_vm.auth.canPublishProject || _vm.auth.canSaveProjectLP,"onClickPublish":function () {},"rightSidePreviewContent":_vm.rightSidePreviewContent,"toggleRightSidePanel":_vm.toggleRightSidePanel,"showRightSidePanel":_vm.showRightSidePanel,"currentStage":_vm.currentStage,"loggedInUserEmail":_vm.loggedInUserEmail ? _vm.loggedInUserEmail : '',"commentsHash":_vm.commentsHash,"diffOptions":{
      diffCloseHandler: _vm.diffCloseHandler,
      fieldDiff: _vm.fieldDiff,
      currentDiffIdentifier: _vm.currentDiffIdentifier,
    },"commentsSaveButtonClickHandler":_vm.commentsSaveButtonClickHandler,"commentsResolveButtonClickHandler":_vm.commentsResolveButtonClickHandler,"closeComments":_vm.closeComments,"commentSectionIdentifier":_vm.commentSectionIdentifier,"onClickMoveToPreviousStage":_vm.onClickMoveToPreviousStage,"projectType":'question',"previewData":_vm.previewData,"isLessonPlan":true,"lpPreviewUrl":_vm.lpPreviewUrl,"isAdmin":_vm.isAdmin}},[_c('v-row',{staticClass:"left-hand-side-form"},[_c('v-form',{ref:"form",staticStyle:{"width":"100%"}},[(
            _vm.lpType == _vm.LP_TYPES_HASH.REVISION ||
            _vm.lpType == _vm.LP_TYPES_HASH.ASSESSMENT ||
            _vm.lpType == _vm.LP_TYPES_HASH.MENTAL_MATH
          )?_c('section',{staticClass:"revision-question"},[_c('QuestionsLessonTemplate',{attrs:{"lpType":_vm.lpType,"lessonPlanDetails":_vm.lessonPlanDetails,"lessonPlanData":_vm.lessonPlan.data,"signedUrls":_vm.signedUrls,"componentOptions":_vm.componentOptions,"currentStageDetails":_vm.currentStageDetails,"uploadingStatus":_vm.uploadingStatus,"showImageBrief":_vm.showImageBrief,"canAppproveAsset":_vm.canAppproveAsset,"onChangeLessonPlanData":_vm.handleQuestionSave,"toggleComments":_vm.toggleComments,"diff":_vm.diff,"auth":_vm.auth,"acceptableFileType":_vm.acceptableFileType,"currentProjectQuestionLinkableLessonPlans":_vm.currentProjectQuestionLinkableLessonPlans}})],1):(_vm.lpType == _vm.LP_TYPES_HASH.VIRTUAL_QUESTIONS)?_c('section',{staticClass:"virtual-question"},[_c('VirtualQuestion',{attrs:{"data":_vm.lessonPlan.data,"onQuestionDataChanged":_vm.onQuestionDataChanged,"handleQuestionSave":_vm.handleQuestionSave,"componentOptions":_vm.componentOptions,"canAppproveAsset":_vm.canAppproveAsset,"toggleComments":_vm.toggleComments,"lpType":_vm.lpType,"currentProjectQuestionLinkableLessonPlans":_vm.currentProjectQuestionLinkableLessonPlans,"currentStage":_vm.currentStage,"lessonPlanDetails":_vm.lessonPlanDetails,"currentStageDetails":_vm.currentStageDetails,"currentProjectAssignees":_vm.currentProject.assignees,"onConfirmRemoveQuestion":_vm.handleOnConfirmRemoveQuestion}})],1):_c('div',[_vm._v("Un handled project type")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }