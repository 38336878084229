<template>
  <div class="block-title-outter-wrapper">
    <div v-if="title" :class="title ? 'headeing-divider ' : 'headeing-divider margin-for-header'">&nbsp;</div>
    <div class="title-sub-title-wrapper">
      <div v-if="title" :class="subTitle ? 'title-text' : 'title-text big-font'">{{ title }}</div>
      <div v-show="subTitle" :class="'sub-title-text color-red'">{{ subTitle }}</div>
    </div>
    <div v-if="time" class="time-text">{{ time }}</div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'BlockTitleWithSubtitle',
  props: {
    title: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    },
    time: {
      type: String,
      required: false
    }
  }
};
</script>
<style scoped>
.block-title-outter-wrapper {
  display: flex;
}
.title-text {
  font-size: 14px;
}
.sub-title-text {
  font-size: 20px;

  font-weight: 500;
}
.title-sub-title-wrapper {
  line-height: 20px;
}

.headeing-divider {
  width: 10px;
  margin-right: 10px;

  background-color: #bb161b;
}
.margin-for-header {
  margin: 4px 10px 4px 0px;
}
.color-red {
  text-transform: uppercase;
  color: #bb161b;
}

.color-black {
  color: #242424;
}
.big-font {
  font-size: 20px;
}
.time-text {
  font-size: 16px;
  padding: 0px 10px;
  margin-left: 30px;
  background: #60dace !important;
  border-radius: 2px;
}
</style>
