<template>
  <v-row class="user-container">
    <HorizontalFilterNav
      :title="AppTexts.MANAGE_USER_TEXT"
      :subTitle="`Showing ${usersTableData.length} ${
        usersTableData.length > 1 ? 'Users' : 'User'
      } `"
      @filterChangedAction="roleFilterChanged"
      :tabs="roles"
      :filerText="AppTexts.FILTER_ROLE"
      :actionButtonText="AppTexts.CREATE_USER"
      @searchChangedHandler="searchChangedHandler"
      @buttonActionHandler="dialogToggle = true"
      :showSearch="true"
      :placeholderText="AppTexts.USER_PLACEHOLDER_TEXT"
    />
    <!--CREATE USER DIALOG-->
    <v-dialog v-model="dialogToggle" width="700">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{
          edit ? AppTexts.UPDATE_USER : AppTexts.CREATE_USER
        }}</v-card-title>
        <v-container>
          <TextFieldWithLabel v-model="newUser.name" :placeholder="AppTexts.FULL_NAME" />
          <TextFieldWithLabel v-model="newUser.email" :placeholder="AppTexts.EMAIL" />
          <v-select
            v-model="newUser.roles"
            :items="roles"
            :placeholder="AppTexts.SELECT_ROLE"
            :rules="[(v) => !!v || 'Role is required']"
            required
            item-text="name"
            item-value="_id"
            multiple
          />
          <v-btn class="text-center" @click="createNewUser">{{
            edit ? AppTexts.UPDATE_USER : AppTexts.CREATE_USER
          }}</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <v-row v-if="loadingStatus === 'loading'" class="loading-div-wrapper">
      <v-progress-circular :size="50" color="primary" class="mt-10" indeterminate />
    </v-row>
    <v-row v-else-if="loadingStatus === 'success'">
      <v-row v-if="usersTableData.length > 0" class="card-container-wrapper d-flex justify-center">
        <div
          class="main-card-wrapper justify-center"
          v-for="(temp, index) in searchResult"
          :key="index"
        >
          <XSCard
            :data="temp"
            :title="temp.user.name"
            :subtitle="temp.user.email"
            :deleteAction="alertDeleteUser"
            :editAction="editUser"
            :tags="roleFiltered ? roleFilter : temp.role"
            :initials="getInitials(temp.user.name)"
          />
        </div>
      </v-row>
      <v-row v-else>No Data available</v-row>
    </v-row>
    <v-row v-else-if="loadingStatus === 'falure'">Error while fetching data</v-row>
    <!-- Delete user dialog -->
    <template>
      <v-row justify="center">
        <v-dialog @click:outside="edit = false" v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline">{{ AppTexts.SUSPEND_USER }}</v-card-title>

            <v-card-text>{{ AppTexts.CLICK_OK_TO_SUSPEND }}</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green darken-1" text @click="dialog = false">
                {{ AppTexts.CANCEL }}
              </v-btn>

              <v-btn color="green darken-1" text @click="okDelete">{{ AppTexts.OK }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TextFieldWithLabel from '@/components/inputs/TextFieldWithLabel';
import AppTexts from '@/constants/AppTexts';
import XSCard from '@/components/Admin/XSCard';
import HorizontalFilterNav from '../../components/Admin/HorizontalFilterNav';

export default {
  name: 'Users',
  components: {
    HorizontalFilterNav,
    TextFieldWithLabel,
    XSCard,
  },
  created() {
    this.getUsers();
    this.getRoles();
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      roles: (state) => state.user.roles,
      loadingStatus: (state) => state.user.loadingUserStatus,
    }),
    searchResult() {
      return this.usersTableData.filter((entity) => {
        if (entity.user) {
          return (
            (entity.user.name &&
              entity.user.name.toLowerCase().includes(this.search.toLowerCase())) ||
            (entity.user.email &&
              entity.user.email.toLowerCase().includes(this.search.toLowerCase()))
          );
        }
      });
    },
  },
  watch: {
    dialogToggle(newVal) {
      if (!newVal) {
        this.newUser = {
          userId: '',
          name: '',
          email: '',
          roles: [],
        };
      }
    },
    users: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.usersTableData = [];
        newVal.forEach((item, index) => {
          if (item.user && item.user !== null) {
            let roles = '';
            item.roles.forEach((element, index) => {
              if (index > 0) {
                roles = roles + ', ' + element.name;
              } else {
                roles = roles + element.name;
              }
            });
            this.roleFilter = this.getRole(roles);
            const data = {
              ...item,
              sNo: index + 1,
              roles,
              role: this.roleFilter,
            };
            this.usersTableData.push(data);
          }
        });
      },
    },
  },
  data() {
    return {
      search: '',
      AppTexts,
      shouldChangePassword: false,
      dialogToggle: false,
      tableOptions: {
        itemsPerPage: 20,
      },
      initials: '',
      roleFiltered: false,
      usersTableData: [],
      selectedUser: '',
      roleFilter: '',
      clickedRole: '',
      dialog: false,
      edit: false,
      newUser: {
        userdId: '',
        name: '',
        email: '',
        roles: [],
      },
    };
  },
  methods: {
    ...mapActions(['getUsers', 'getRoles', 'createUser', 'updateUser', 'deleteUser']),
    getRole(str) {
      let tempRole = str ? str.split(',')[0] : '';
      return tempRole;
    },
    editUser(item) {
      this.edit = true;
      this.dialogToggle = true;
      const index = item.sNo - 1;
      const selectedUser = this.users[index];
      const rolesMap = selectedUser.roles.map((item) => item._id);
      this.newUser = {
        name: selectedUser.user.name,
        email: selectedUser.user.email,
        roles: rolesMap,
        userId: selectedUser.user._id,
      };
    },
    getInitials(string) {
      var names = string ? string.split(' ') : ''
      let initials = '';
      if(names[0]) {
        initials = names[0].substring(0, 1).toUpperCase();
      }

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      } else {
        return names[0];
      }
      return initials;
    },
    alertDeleteUser(item) {
      this.dialog = true;
      this.selectedUser = item.user._id;
    },
    okDelete() {
      this.deleteUser(this.selectedUser);
      this.dialog = false;
    },
    createNewUser() {
      if (
        this.newUser.name &&
        this.newUser.email &&
        this.newUser.roles.length > 0 &&
        this.validateEmail(this.newUser.email)
      ) {
        if (!this.edit) {
          this.createUser(this.newUser);
        } else {
          this.updateUser(this.newUser);
        }
        this.edit = false;
        this.dialogToggle = false;
      }
    },
    roleFilterChanged(val) {
      this.roleFiltered = true;
      this.roleFilter = val.name;
      this.getUsers(val._id);
      this.clickedRole = val._id;
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    searchChangedHandler(searchString) {
      this.search = searchString;
    },
  },
};
</script>
<style>
.user-container {
  padding-top: 0px !important;
  width: 96vw !important;
  padding-left: 60px !important;
}
</style>
