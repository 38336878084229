<template>
  <v-row dense>
    <v-col
      :cols="colsPerRow"
      v-for="(assignee, index) in Object.keys(projectData.assignees)"
      :key="index"
      dense
    >
      <v-autocomplete
        outlined
        :label="projectAssigneesRoles[assignee]"
        v-model="projectData.assignees[assignee].id"
        :items="getUsersList(assignee, index, currentStageIndex)"
        :rules="[(v) => !!v || projectAssigneesRoles[assignee] + ' is required']"
        required
        item-text="name"
        item-value="id"
        :disabled="canEnableCurrentAssigneeDropdown(index)"
      />
    </v-col>
    <v-layout row justify-center></v-layout>
  </v-row>
</template>
<script>
import AppTexts from '@/constants/AppTexts';
import { capitalCase } from 'change-case';
import { LP_STAGES } from '@/constants/LessonPlan';
import _ from 'lodash';
import { SKIP_STAGE_USER_ID_VALUE } from '@/constants/microTopic';
import {
  PROJECT_WORKFLOW_STATUSES_HASH,
  PROJECT_STAGES_ASSIGNEE_IN_ORDER,
} from '@/constants/Project';

export default {
  name: 'ProjectStageAssigneeFormContent',
  data() {
    return {
      AppTexts,
      projectAssigneesRoles: {
        author: 'Author',
        reviewer1: 'Reviewer 1',
        reviewer2: 'Reviewer 2',
        academicSignOff: 'Academic Sign Off',
        editor: 'Editor',
        visualizer: 'Visualizer',
        assetUploader: 'Asset Uploader',
        assetAuthorizer: 'Asset Authorizer',
        proofReader: 'Proof Reader',
      },
      completedStatuses: [
        PROJECT_WORKFLOW_STATUSES_HASH.COMPLETED,
        PROJECT_WORKFLOW_STATUSES_HASH.PUBLISHED,
      ],
    };
  },
  computed: {
    currentStageIndex() {
      try {
        if (this.projectData.workflowStatus == PROJECT_WORKFLOW_STATUSES_HASH.NOT_STARTED)
          return -1;
        if (
          this.projectData.workflowStatus.toLowerCase() == PROJECT_WORKFLOW_STATUSES_HASH.COMPLETED
        )
          return 99;
        if (
          this.projectData.workflowStatus.toLowerCase() == PROJECT_WORKFLOW_STATUSES_HASH.PUBLISHED
        )
          return 99;
        let _index = PROJECT_STAGES_ASSIGNEE_IN_ORDER.indexOf(this.projectData.workflowStatus);
        //Ticket for reference - https://xseededucation.atlassian.net/browse/LCMS-510
        //  _index > 2 is required since we are not providing a way to change assignee for author 2

        return _index > 2 ? _index - 1 : _index;
      } catch (error) {
        return 0;
      }
    },
  },
  methods: {
    capitalCase,
    getUsersList(assignee, currentIterator, currentStageIndex) {
      let assignees = [];
      let assigneeRole = this.projectAssigneesRoles[assignee];
      let isCurrentStage = currentIterator == currentStageIndex;
      assignees = this.getUsersByRoles(assigneeRole);
      const mandatoryStages = ['Uploading Assets', 'Asset Authorizing', 'Proofreading'];
      const currentStage = LP_STAGES[assigneeRole];
      if (this.projectData.contents && isCurrentStage && !mandatoryStages.includes(currentStage)) {
        assignees.splice(0, 1);
      }
      if (
        _.isEmpty(this.projectData.assignees[assignee]) ||
        _.isEmpty(this.projectData.assignees[assignee].id)
      ) {
        if (!['assetAuthorizer', 'assetUploader', 'proofReader'].includes(assignee)) {
          if (this.projectData.assignees[assignee]) {
            this.projectData.assignees[assignee]['id'] = SKIP_STAGE_USER_ID_VALUE;
          } else {
            this.projectData.assignees[assignee] = { id: SKIP_STAGE_USER_ID_VALUE };
          }
        }
      }

      return assignees;
    },
    canEnableCurrentAssigneeDropdown(index) {
      return this.completedStatuses.includes(this.projectData.workflowStatus.toLowerCase()) ||
        (this.projectData.workflowStatus.toLowerCase() == 'author 2' && index == 0)
        ? false
        : index == 2 && this.projectData.workflowStatus.toLowerCase() == 'author 2'
        ? true
        : this.currentStageIndex == index
        ? false
        : this.currentStageIndex > index;
    },
  },
  props: {
    getUsersByRoles: {
      type: Function,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
    colsPerRow: {
      type: String,
      required: false,
      default: '12',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectBoxClassName: {
      type: String,
      required: false,
    },
  },
};
</script>
