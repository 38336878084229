<template>
  <v-dialog :value="showWarningPopup" width="350">
    <v-card>
      <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>
      <v-card-text> {{ message }} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="confirm">Yes</v-btn>
        <v-btn text @click="cancle">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'WarningPopup',
  data() {
    return {};
  },
  props: {
    confirmHandler: {
      type: Function,
      required: true,
    },
    cancleHandler: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
      default: 'Do you want to proceed?',
    },
  },
  computed: {
    ...mapState({
      showWarningPopup: (state) => state.questions.showWarningDialog,
    }),
  },
  methods: {
    ...mapActions(['closeWarningDialog']),
    cancle() {
      this.cancleHandler();
      this.closeWarningDialog();
    },
    confirm() {
      this.confirmHandler();
      this.closeWarningDialog();
    },
  },
};
</script>
