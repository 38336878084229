import { SUBJECT_SELECTION_MUTATION } from './subjectSelection';
import { BLOCK_MAP_MUTATION } from './blockMap';
import { PROJECT_MUTATION } from './project';
import { AUTH_MUTATION } from './auth';
import { MICRO_TOPIC_MUTATION } from './micro_topic';
import { BLOCK_MUTATION } from './block';

export const mutations = {
  ...SUBJECT_SELECTION_MUTATION,
  ...BLOCK_MAP_MUTATION,
  ...PROJECT_MUTATION,
  ...AUTH_MUTATION,
  ...MICRO_TOPIC_MUTATION,
  ...BLOCK_MUTATION,
  changeLoadingState(state, payload) {
    state.isLoading = payload;
  },
  SET_CURRENT_MODULE(state, moduleName) {
    state.currentModule = moduleName;
  },
};
