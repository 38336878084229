<template>
  <div style="width: 100%;">
    <v-row>
      <v-col style="width: 20px;">
        <v-radio :label="data.optionText" :value="data.optionText" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" class="ml-8">
        <div :style="setWidth">
          <TextInput
            v-if="showMisconception"
            :enableTextFormatting="false"
            :fieldIdentifier="`${partialFieldIdentifier}['${data._id}'].misconception`"
            placeholder="Feedback to student if they select this answer"
            projectType="question"
            v-model="data.misconception"
            :canShowDiffIcon="true"
            :readonly="false"
            customClass="mt-4"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextInput from '@/components/inputs/TextInput';

export default {
  components: {
    TextInput,
  },
  computed: {
    ...mapState({
      questionCommentsToggle: (state) => state.questions.questionCommentsToggle,
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
    }),
    setWidth() {
      return this.questionCommentsToggle || this.setQuestionDiffPanel
        ? 'width: 400px'
        : 'width: 750px';
    },

    showMisconception() {
      return typeof this.data.misconception != 'undefined';
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    partialFieldIdentifier: {
      type: String,
      required: true,
    },
  },
};
</script>
