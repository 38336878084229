<template>
  <div>
    <div
      v-for="(teacher_tip, index) in lessonPlanData.application.teacher_tips"
      :key="`questionTeacherTipsForPractice${index}`"
    >
      <div style="display: flex;">
        <div style="flex: 1;">
          <BlockTitle
            :title="`Question ${teacher_tip.questionNumber ? teacher_tip.questionNumber : ''} Tip`"
          />
        </div>
        <div>
          <v-btn icon @click="() => deleteQuestionTeacherTipAtIndex(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <TextFieldWithLabel
        placeholder="Question Number"
        v-model="teacher_tip.questionNumber"
        type="number"
      />

      <span v-if="teacher_tip.isAuthoredQuestion == true">
        <div
          v-for="(questionIdKey, index) in Object.keys(
            getQuestionsToDisplay(teacher_tip.questions)
          )"
          :key="`questionTeacherTipsQuestion${index}`"
        >
          <v-card
            v-if="Array.isArray(getQuestionsToDisplay(teacher_tip.questions)[questionIdKey])"
            class="wrapper-container"
          >
            <Padder :horizontal="30">
              <QuestionGroupCard
                :data="getQuestionsToDisplay(teacher_tip.questions)[questionIdKey]"
                :isReadOnly="true"
                :saveForm="saveForm"
                :stimulus="getQuestionsToDisplay(teacher_tip.questions)[questionIdKey][0].stimulus"
                :options="options"
                :toggleComments="toggleComments"
                :isViewOnly="true"
                :shouldEmitDeleteAction="false"
                :canShowActionButtons="false"
                @remove-question-clicked="() => {}"
              />
              <div
                v-for="(question, groupQuestionIndex) in getQuestionsToDisplay(
                  teacher_tip.questions
                )[questionIdKey]"
                :key="`question-card-wrapper-${groupQuestionIndex}`"
              >
                <QuestionCard
                  :flat="true"
                  :data="question"
                  :isReadOnly="true"
                  :saveForm="saveForm"
                  :options="options"
                  :toggleComments="toggleComments"
                  :shouldEmitDeleteAction="false"
                  @question-preview-cliked="() => {}"
                  @remove-question-clicked="() => {}"
                  :lessonPlans="lessonPlans"
                  :isViewOnly="true"
                  :isSchoolProgramQuestion="true"
                  :canShowActionButtons="false"
                  :questionPurposeDisplayText="getQuestionPurposeDisplayText"
                />
                <v-divider
                  class="mb-8"
                  v-if="
                    getQuestionsToDisplay(teacher_tip.questions)[questionIdKey].length - 1 !=
                    groupQuestionIndex
                  "
                />
              </div>
              <section>
                <TeacherTipDisplay
                  :teacherTip="
                    getQuestionsToDisplay(teacher_tip.questions)[questionIdKey][0].teacherTip
                  "
                  :isAssetUploadRequired="options.isAssetUploadRequired"
                />
              </section>
            </Padder>
          </v-card>
          <div v-else class="list-view-question-wrapper">
            <QuestionCard
              :key="`question-card-${index}`"
              :data="getQuestionsToDisplay(teacher_tip.questions)[questionIdKey]"
              :isReadOnly="true"
              :saveForm="saveForm"
              :options="options"
              :toggleComments="toggleComments"
              :shouldEmitDeleteAction="false"
              @question-preview-cliked="() => {}"
              @remove-question-clicked="() => {}"
              :lessonPlans="lessonPlans"
              :isViewOnly="true"
              :isSchoolProgramQuestion="true"
              :canShowActionButtons="false"
              :questionPurposeDisplayText="getQuestionPurposeDisplayText"
            />
          </div>
        </div>
      </span>
      <span v-else>
        <RTEWithMultipleAssetUpload
          :key="`questionTeacherTipsForPracticeRTEWithAsseetOption${index}`"
          :data="teacher_tip"
          :isReadOnly="!isNotReadOnly"
          :isAssetUploadRequired="isAssetUploadRequired"
          :canShowDiffIcon="canShowDiffIcon"
          placeholder="Question Teacher Tip"
          :diffChanges="(applicationDiff.teacher_tips || [])[index] || {}"
          :assetSignedUrlsHash="
            (applicationSignedUrls.teacher_tips[index] || { assets: [] }).assets
          "
          :showImageBrief="showImageBrief"
          :fileValidators="fileValidators"
          :assetDiffIdentifierPrefix="`application.teacher_tips[${index}].assets[<assetIndex>]`"
          :descriptionDiffIdentifier="`application.teacher_tips[${index}].description`"
          :sectionIdentifier="`application.teacher_tips`"
          :assetTypeMapping="{
            practice: AssetTypes.PRACTICE_TEACHER_TIP_ASSET_TYPE,
          }"
          :requireDeleteAsset="false"
          :requireAddAsset="false"
          :stepIndex="index"
          :isAssetMandatory="false"
          :descriptionMaxLength="schema.teacherTipsDescriptionMaxLength"
          :canAppproveAsset="canAppproveAsset"
          :canFinalizeAsset="true"
          :requireDeleteBlock="false"
        />
      </span>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import RTEWithMultipleAssetUpload from '@/components/inputs/RTEWithMultipleAssetUpload';
import BlockTitle from '@/components/inputs/BlockTitle';
import TextFieldWithLabel from '@/components/inputs/TextFieldWithLabel.vue';
import AssetTypes from '@/constants/AssetTypes';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { RTETextToHTML } from '@xseededucation/rte-helper-functions';
import {
  QuestionGroupCard,
  QuestionCard,
  TeacherTipDisplay,
} from '@xseededucation/vue-question-display-component';
import { getQuestionPurposeDisplayText } from '@/utils/questionUtils.js';

export default {
  name: 'TeacherTips',
  components: {
    RTEWithMultipleAssetUpload,
    BlockTitle,
    TextFieldWithLabel,
    QuestionGroupCard,
    QuestionCard,
    TeacherTipDisplay,
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    applicationDiff: {
      type: Object,
      required: true,
    },
    applicationSignedUrls: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    fileValidators: {
      type: Array,
      required: false,
    },
    assetDiffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    localQuestions: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    questionMetaInfo: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    lessonPlans: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    saveForm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      AssetTypes,
      options: { isAssetUploadRequired: this.isAssetUploadRequired },
    };
  },
  methods: {
    RTETextToHTML,
    getQuestionPurposeDisplayText,
    deleteQuestionTeacherTipAtIndex(index) {
      this.$delete(this.lessonPlanData.application.teacher_tips, index);
      this.saveForm();
      this.$delete(this.applicationSignedUrls.teacher_tips, index);
      this.questionTeacherTipRevision += 1;
    },
    getQuestionsToDisplay(questionsId) {
      let groupedQuestions = {};
      let questions = [];
      if (this.questionDataForList && this.questionDataForList.length > 0) {
        questions = this.questionDataForList.filter((_q) => questionsId.includes(_q._id));
      }

      if (questions.length == 0) {
        questions = this.localQuestions.filter((_q) => questionsId.includes(_q._id));
      }

      for (const datum of questions) {
        if (!datum.questionGroupId) {
          groupedQuestions[datum._id] = cloneDeep(datum);
        } else {
          if (!groupedQuestions[datum.questionGroupId]) {
            groupedQuestions[datum.questionGroupId] = [];
          }
          groupedQuestions[datum.questionGroupId].push(cloneDeep(datum));
        }
      }

      return groupedQuestions;
    },
    toggleComments() {},
  },
  computed: {
    ...mapState({
      questionDataForList: (state) => state.questions.questionDataForList,
    }),
  },
};
</script>
