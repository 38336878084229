export const AUTH_MUTATION = {
  SET_LOGGED_IN_USER(state, user) {
    let isTrainingAdmin = false;
    if (user && user.xseedUniversalApp && user.xseedUniversalApp.users) {
      user.xseedUniversalApp.users.forEach((element) => {
        element.allotments.forEach((e) => {
          if (e.role && e.role.name == 'Training Admin') {
            isTrainingAdmin = true;
          }
        });
      });
    }
    state.loggedInUser = user;
    state.auth.isTrainingAdmin = isTrainingAdmin;
  },
  UPDATE_USER_AUTH_PERMISSIONS: (
    state,
    { canCreateProject, canGetProjects, canEditProjects, canDeleteProject, canSwitchType }
  ) => {
    state.auth.isAdmin = canCreateProject || false;
    state.auth.project.canMutateProject = canCreateProject || false;
    state.auth.project.canReadProject = canGetProjects || false;
    state.auth.project.canEditProjectList = canEditProjects;
    state.auth.project.canDeleteProject = canDeleteProject || false;
    state.auth.project.canSwitchType = canSwitchType || false;
    state.auth.isAcademicAdmin = canEditProjects.length > 0 ? true : false;
  },
  SET_LESSON_PLAN_SAVE_AND_MOVE_TO_NEXT_STAGE_PERMISSION(
    state,
    {
      canMoveProjectLP,
      canSaveProjectLP,
      canAddComment,
      canResolveComment,
      canDownloadContentAssets,
      canMoveToAssetUploadStage,
    }
  ) {
    state.auth.lessonPlan.canMoveProjectLP = canMoveProjectLP;
    state.auth.lessonPlan.canSaveProjectLP = canSaveProjectLP;
    state.auth.lessonPlan.canAddComment = canAddComment;
    state.auth.lessonPlan.canResolveComment = canResolveComment;
    state.auth.lessonPlan.canDownloadContentAssets = canDownloadContentAssets;
    state.auth.lessonPlan.canMoveToAssetUploadStage = canMoveToAssetUploadStage;
  },
  SET_DAILY_PLAN_PERMISSION(state, { canDownloadContentAssets, canSwitchType }) {
    state.auth.dailyPlan.canDownloadContentAssets = canDownloadContentAssets;
    state.auth.dailyPlan.canSwitchType = canSwitchType;
  },
  UPDATE_MICRO_TOPIC_AUTH_PERMISSIONS(
    state,
    { canMutateMicroTopic, canReadMicroTopic, canViewMicroTopic, canEditMicroTopics }
  ) {
    state.auth.isAdmin = canMutateMicroTopic || false;
    state.auth.isAcademicAdmin = canEditMicroTopics.length > 0 ? true : false;
    state.auth.microTopic.canMutateMicroTopic = canMutateMicroTopic;
    state.auth.microTopic.canReadMicroTopic = canReadMicroTopic;
    state.auth.microTopic.canViewMicroTopic = canViewMicroTopic;
    state.auth.microTopic.canEditMicroTopicsList = canEditMicroTopics;
  },
  UPDATE_CONTENT_ENTRY_PROJECT_FORM_PERMISSIONS(
    state,
    {
      canSaveContent,
      canMoveContent,
      canDownloadContentAssets,
      canMoveContentProjectToPreviousStage,
      canPublishProject,
      canValidateContent,
      canAddComment,
      canResolveComment,
    }
  ) {
    state.auth.contentEntryProjectForm.canSaveContent = canSaveContent;
    state.auth.contentEntryProjectForm.canValidateContent = canValidateContent;
    state.auth.contentEntryProjectForm.canMoveContent = canMoveContent;
    state.auth.contentEntryProjectForm.canDownloadContentAssets = canDownloadContentAssets;
    state.auth.contentEntryProjectForm.canPublishProject = canPublishProject || false;
    state.auth.contentEntryProjectForm.canAddComment = canAddComment || false;
    state.auth.contentEntryProjectForm.canResolveComment = canResolveComment || false;
    state.auth.contentEntryProjectForm.canMoveContentProjectToPreviousStage =
      canMoveContentProjectToPreviousStage || false;
  },
  UPDATE_NEW_PROJECT_IN_CAN_EDITABLE_PROJECT_LIST(state, newId) {
    state.auth.project.canEditProjectList.push(newId);
  },
};
