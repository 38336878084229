<template>
  <div class="pb-4">
    <v-select
      :items="visibleQuestionTypes"
      :value="defaultType"
      item-text="displayName"
      item-value="value"
      label="Question Type"
      solo
      hide-details
      :disabled="disabled"
      @change="(e) => $emit('selectedQuestionTypeFromChild', e)"
    ></v-select>
  </div>
</template>

<script>
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import { LP_TYPES_HASH } from '@/constants/common';
export default {
  data() {
    return {
      LP_TYPES_HASH,
      questionTypes: [
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_MCQ,
        },
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_MSQ,
        },
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_TF,
        },
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_MTF,
        },
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_FIB,
        },
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_ATF,
        },
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_SA,
        },
        {
          value: questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
          displayName: questionsOprogramProject.DISPLAY_QUESTION_TYPE_LA,
        },
      ],
    };
  },
  methods: {},
  computed: {
    visibleQuestionTypes() {
      let qTypes = this.questionTypesBasedOnLpType;
      qTypes =
        this.allowedQuestionTypes === undefined
          ? qTypes
          : qTypes.filter((qType) => this.allowedQuestionTypes.includes(qType.value));
      return qTypes;
    },
    questionTypesBasedOnLpType() {
      //This is still unclear so commenting this as of now
      // if(this.lpType == this.LP_TYPES_HASH.VIRTUAL_QUESTIONS) {
      //   return this.questionTypes.filter(type => !([questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA, questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA].includes(type.value)));
      // }
      return this.questionTypes;
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultType: {
      type: String,
      default: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ,
    },
    lpType: {
      type: String,
      required: true,
    },
    allowedQuestionTypes: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  created() {
    this.$emit('selectedQuestionTypeFromChild', this.defaultType);
  },
};
</script>
