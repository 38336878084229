import _ from 'lodash';
import { getAssetUploadRequiredStatus } from '@/utils';

const mutations = {
  updateDailyPlanFieldInVuex(state, payload) {
    let { fieldName, value } = payload;
    if (['description', 'brief', 'dp_title', 'dp_description'].includes(fieldName)) {
      state.projectDailyPlanCurrentStageData.currentStageData.data[fieldName] = value;
    }
  },
  setProjectDailyPlanCurrentStageData(state, payload) {
    state.projectDailyPlanCurrentStageData = payload;
  },
  setDailyPlanLoadingStatus(state, status) {
    state.dailyPlanLoadingStatus = status;
  },
  setDailyPlanDataFetchApiStatus(state, status) {
    state.dailyPlanDataFetchApiStatus = status;
  },
  setDailyPlanCurrentSatgeData(state, data) {
    state.projectDailyPlanCurrentStageData.currentStageData.data = data;
  },
  setDailyPlanBasicDetails(state, data) {
    state.projectBasicDetails = data;
  },
  setDailyPlanCurrentStage(state, data) {
    state.projectDailyPlanCurrentStageData.currentStage = data.currentStage;
  },
  setCoverImageUploadingStatus(state, status) {
    state.coverImageUploadingStatus = status;
  },
  importDailyPlanCoverImage(state, payload) {
    let assetKey = 'assetId';
    let signedUrlKey = 'assetSignedUrl';
    const currentStage = state.projectDailyPlanCurrentStageData.currentStage;
    let isAssetUploadRequired = (this.isAssetUploadRequired = getAssetUploadRequiredStatus(
      currentStage
    ));

    var forceUpdateImageUrl = payload.forceApproveAsset != null;
    const isAssetApproved =
      (payload.forceApproveAsset == true ||
        currentStage == 'assetAuthorizingStage' ||
        currentStage == 'proofReadingStage') &&
      payload.id != '';

    if (!(isAssetUploadRequired || forceUpdateImageUrl)) {
      assetKey = 'brief.assetId';
      signedUrlKey = 'brief.assetSignedUrl';
    }

    _.set(state.projectDailyPlanCurrentStageData.currentStageData.data, assetKey, payload.id);
    _.set(
      state.projectDailyPlanCurrentStageData.currentStageData.data,
      'assetApproved',
      isAssetApproved
    );
    _.set(
      state.projectDailyPlanCurrentStageData.currentStageAssetsSignedUrl,
      signedUrlKey,
      payload.signedUrl
    );
  },
};

export default mutations;
