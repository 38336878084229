const state = {
  trainingListApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  selectedTraining: {},
  trainingQuestionAuthoringApiResponse: {
    status: 'completed',
    message: 'Successfully fetched all questions',
    data: [],
  },
  trainingDetailsByTrainingIdApiResponse: {
    status: 'completed',
    message: 'Successfully fetched training Details',
    data: [],
  },
};

export default state;
