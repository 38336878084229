<template>
  <section>
    <v-dialog v-model="removeQuestionLoader" max-width="290" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">{{
          'Brewing your coffee...'
        }}</v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unlinkAndDeleteQuestionConfirmationDialog.status" width="350">
      <v-card v-if="unlinkAndDeleteQuestionConfirmationDialog.status">
        <v-card-title class="headline">Question is linked</v-card-title>
        <v-card-text>
          The question you are deleting is linked with
          <b>{{ getLinkedLessonPlansName(this.unlinkAndDeleteQuestionConfirmationDialog.data) }}</b>
          question lesson.
          <br />
          <br />
          Do you want to unlink and proceed?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onCancelLinkQuestion">Cancel</v-btn>
          <v-btn color="primary" @click="onRemoveAndUnlinkQuestion">Unlink and Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="flex-row ml-2">
      <v-col cols="6">
        <div class="lesson-plan-title">{{ lessonPlanDetails.title }}</div>
        <div class="lesson-plan-details">{{ lessonPlanDetails.details }}</div>
      </v-col>
      <v-col cols="6">
        <Stepper
          :currentStepIndex="currentStageDetails.index"
          :headerText="currentStageDetails.stageName"
          :currentProjectAssignees="currentProjectAssignees"
        />
      </v-col>
    </v-row>
    <br />
    <Questions
      :data="data"
      @onQuestionDataChanged="onQuestionDataChanged"
      :saveForm="handleQuestionSave"
      :options="componentOptions"
      :canAppproveAsset="canAppproveAsset"
      :toggleComments="toggleComments"
      @question-preview-cliked="() => {}"
      :lpType="lpType"
      :lessonPlans="currentProjectQuestionLinkableLessonPlans"
      :currentStage="currentStage"
      :shouldEmitDeleteAction="true"
      @remove-question-clicked="onConfirmRemoveQuestion"
      showFilter
    />
  </section>
</template>
<script>
import Stepper from '@/components/lessonPlan/Stepper';
import Questions from '@/components/contentEntry/Questions';
import { mapMutations, mapState, mapActions } from 'vuex';
export default {
  name: 'VirtualQuestion',
  components: {
    Stepper,
    Questions,
  },
  computed: {
    ...mapState({
      unlinkAndDeleteQuestionConfirmationDialog: (state) =>
        state.questions.unlinkAndDeleteQuestionConfirmationDialog,
      removeQuestionLoader: (state) => state.questions.removeQuestionLoader,
    }),
  },
  methods: {
    ...mapActions(['onConfirmRemoveLinkedQuestion', 'questionForceSaveWithData']),
    ...mapMutations([
      'SET_LOADING_STATUS',
      'SET_UNLINK_AND_DELETE_QUESTION_CONFIRMATION_DIALOG_STATUS',
    ]),
    onRemoveAndUnlinkQuestion() {
      this.onConfirmRemoveLinkedQuestion({
        payload: this.unlinkAndDeleteQuestionConfirmationDialog.data,
        onSuccess: () => {
          this.questionForceSaveWithData({
            validateForm: false,
            showLoader: true,
            callback: this.handleQuestionSave,
          });
        },
      });
    },
    getLinkedLessonPlansName(data) {
      return (data.linkedLPDetails || []).map((_) => _.lpName).join(', ');
    },
    onCancelLinkQuestion() {
      this.SET_UNLINK_AND_DELETE_QUESTION_CONFIRMATION_DIALOG_STATUS({ status: false, data: {} });
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    onQuestionDataChanged: {
      type: Function,
      required: true,
    },
    handleQuestionSave: {
      tpe: Function,
      required: true,
    },
    componentOptions: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    lpType: {
      type: String,
      required: true,
    },
    currentProjectQuestionLinkableLessonPlans: {
      type: Array,
      required: true,
    },
    currentStage: {
      type: String,
      required: true,
    },
    lessonPlanDetails: {
      type: Object,
      required: true,
    },
    currentStageDetails: {
      type: Object,
      required: true,
    },
    currentProjectAssignees: {
      type: Object,
      default: () => {
        return {};
      },
    },
    onConfirmRemoveQuestion: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
.lesson-plan-title {
  font-size: 20px;
  font-weight: 500;
}
.lesson-plan-details {
  color: #242424;
}
</style>
