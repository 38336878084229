<template>
  <v-form ref="genericActionSectionForm" class="section-title-cnr">
    <StepWithHeader
      :items="get(lessonPlanData, schema.configurableAction.items)"
      :canShowStepNumber="true"
      :canShowInstructionBreakCheckbox="true"
      :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
      :canShowAddNewStepButton="true"
      :paragraphText="get(lessonPlanData, schema.configurableAction.paragraphTextKey)"
      :descriptionPlaceholder="AppTexts.DESCRIPTION"
      :subPath="schema.configurableAction.items"
      :addNewStepHandler="addInstructionStepForPath"
      :signedUrls="actionSignedUrls[schema.configurableAction.leafNode]"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :canAppproveAsset="canAppproveAsset"
      :canFinalizeAsset="true"
      :deleteStepHandler="deleteInstructionStepForPath"
      :hasSubStep="true"
      :allowDeleteStep="true"
      :allowDeleteSubStep="true"
      :supportAssets="true"
      :deleteSubStepHandler="deleteInstructionSubStepForPath"
      :maxNoOfSteps="schema.configurableAction.maxNoOfSteps"
      :maxNoOfSubStepsInStep="schema.configurableAction.maxNoOfSubStepsInStep"
      :maxSumOfStepsAndSubSteps="schema.configurableAction.maxSumOfStepsAndSubSteps"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :addNewSubStepHandler="addInstructionSubStepForPath"
      :canShowInstructionTeacherTip="canShowInstructionTeacherTip"
      :toggleTeacherTipFieldHandler="toggleInstructionTeacherTipBasedOnPath"
    />
    <div class="pad-Ts mgn-Bs">
      <XSDisplayCard :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isActionTeacherTipChanged()"
          fieldIdentifier="schema.teacher_tip"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="teacherTip"
          :counter="220"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
        <br />
      </XSDisplayCard>
    </div>
  </v-form>
</template>

<script>
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import AppTexts from '@/constants/AppTexts';
import AssetTypes from '@/constants/AssetTypes';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { ACTION_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import { mapActions } from 'vuex';
import StepWithHeader from '@/components/lessonPlanTemplate/commonLpComponents/StepWithHeader';
import { get, set } from 'lodash';

export default {
  name: 'GenericActionSection',
  components: {
    RichTextEditor,
    XSDisplayCard,
    StepWithHeader,
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ACTION_BLOCK_KEY_OF_STATE_LP_DATA,
      BASIC_TOOLS,
      FILE_UPLOADING_STATUS,
    };
  },
  computed: {
    teacherTip: {
      get() {
        return get(this.lessonPlanData, this.schema.configurableAction.teacherTipPath);
      },
      set(value) {
        set(this.lessonPlanData, this.schema.configurableAction.teacherTipPath, value);
      },
    },
  },
  methods: {
    get,
    set,
    ...mapActions([
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'addInstructionStepForPath',
      'deleteInstructionStepForPath',
      'addInstructionSubStepForPath',
      'deleteInstructionSubStepForPath',
      'toggleInstructionTeacherTipBasedOnPath',
    ]),
    getActionInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        signedBriefUrl: '',
        signedUrl: '',
      };
      if (this.actionSignedUrls) {
        let instruction = this.actionSignedUrls.instructions[stepIndex];
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.signedUrl = asset.signedUrl;
            }
            previewUrl.signedBriefUrl = asset.signedBriefUrl;
          }
        }
      }

      return previewUrl;
    },
    isStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.instructions[stepIndex].description.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isSubStepDescriptionChanged(stepIndex, subStepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.actionDiff.instructions[stepIndex].children[subStepIndex].description.isChanged ==
            true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetBriefChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.instructions[stepIndex].assets[assetIndex].brief.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetImageChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          if (this.isAssetUploadRequired) {
            return this.actionDiff.instructions[stepIndex].assets[assetIndex].url.isChanged == true;
          } else {
            return (
              this.actionDiff.instructions[stepIndex].assets[assetIndex].brief_url.isChanged == true
            );
          }
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isActionTeacherTipChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.teacher_tip.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getFileNameFromUrl,
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionSignedUrls: {
      type: Object,
      required: true,
    },
    actionDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canAppproveAsset: { type: Boolean, required: true },
    subPath: {
      type: String,
      required: false,
      default: '',
    },
    schema: {
      type: Object,
      required: true,
    },
    canShowInstructionTeacherTip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
