<template>
  <div>
    <v-card>
      <v-card-title class="headline grey lighten-3" primary-title>Equation Editor</v-card-title>
      <v-card-text>
        <center v-if="loading">Loading, Please wait.</center>
        <iframe
          name="EquationEditorIframe"
          class="equation-editor-iframe"
          width="100%"
          height="750px;"
          @load="onLoadEquationEditor"
          src="/equationEditor/app/html/EquationEditor.html"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeEquationEditorDialog">Cancel</v-btn>
        <v-btn color="primary" @click="onCLickLatexInsert">Insert</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'EquationEditorDialogContent',
  data() {
    return { loading: true };
  },
  methods: {
    onCLickLatexInsert() {
      // eslint-disable-next-line no-undef
      let latex = EquationEditorIframe.getLatex();
      // eslint-disable-next-line no-undef
      let json = EquationEditorIframe.getJson();
      this.$emit('insertLatexInForm', latex, JSON.stringify(json));
    },
    closeEquationEditorDialog() {
      this.$emit('closeEquationEditorDialog');
    },
    onLoadEquationEditor() {
      this.loading = false;
      if (this.defaultValueJson && Object.keys(this.defaultValueJson).length) {
        // eslint-disable-next-line no-undef
        EquationEditorIframe.setEquationFromJson(this.defaultValueJson);
      }
    },
  },
  props: {
    defaultValueJson: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.equation-editor-iframe {
  zoom: 0.5;
  border: none;
}
</style>
