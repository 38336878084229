<template>
  <v-container fluid>
    <v-row>
      <SubSection title="Answer" />
    </v-row>
    <v-checkbox
      v-model="data.questionData.allowedResponseTypes"
      label="Accept response as Image"
      value="Image"
    ></v-checkbox>
    <v-checkbox
      v-model="data.questionData.allowedResponseTypes"
      label="Accept response as Text"
      value="Text"
    ></v-checkbox>
    <XSDivider />
    <RichTextEditor
      :key="`${answerGuideTextRevision}['${data._id}'].questionData.answerGuideText`"
      :readonly="false"
      :canShowDiffIcon="true"
      v-model="data.questionData.answerGuideText"
      placeholder="Enter Sample Answer"
      :fieldIdentifier="`['${data._id}'].questionData.answerGuideText`"
      projectType="question"
      :showValidationError="true"
      :includeOnlyToolBarActions="getToolBarActions"
      :isAssetUploadRequired="options.isAssetUploadRequired"
      @input="onChangeAnswerGuideText"
      :forceAssetFinalized="forceAssetFinalized"
      :acceptedFileTypes="
        options.isAssetUploadRequired
          ? ['image/png', 'image/jpeg']
          : [
              'image/png',
              'image/jpeg',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]
      "
    />
    <v-row>
      <v-col
        v-for="(image, index) in imagesInAnswerGuideText"
        :key="`imagesInAnswerGuideText${revision}${index}`"
        :cols="3"
      >
        <FileInput
          :canShowDiffIcon="false"
          :diffIdentifier="''"
          :fileAcceptType="'Image/*'"
          :label="'Image'"
          :loadingStatus="false"
          :isRequired="options.isAssetUploadRequired"
          :isAssetUploadRequired="options.isAssetUploadRequired"
          :fileChangeHandler="(file) => fileChangeHandler(file, index)"
          :previewUrl="image.attrs.src"
          :previewUrlBrief="image.attrs.briefSrc"
          :image_url="image.attrs.assetId"
          :image_url_brief="image.attrs.briefAssetId"
          :isNotReadOnly="options.isNotReadOnly"
          :canAppproveAsset="canAppproveAsset"
          :ApproveAssetAndEmitResult="true"
          :canFinalizeAsset="true"
          :useAssetVault="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, approveAsset) =>
              addToAsset(previewUrl, briefUrl, approveAsset, index)
          "
          :isAssetApproved="image.attrs.assetApproved"
          @assetApprovedByUser="(val) => approveAssets(val, index)"
        />
        <br />
        <RichTextEditor
          :readonly="options.isAssetUploadRequired"
          :canShowDiffIcon="false"
          v-model="image.attrs.brief"
          placeholder="Enter Image Brief"
          projectType="question"
          :showValidationError="false"
          :includeOnlyToolBarActions="getToolBarActionsForQuestionBriefs"
          @input="addBriefTextAtPosition(image.attrs.brief, index)"
          :removeAllPluginExceptFullScreen="true"
          :counter="3000"
          :allowCharacterCount="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SubSection from '@/components/inputs/SubSection.vue';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import FileInput from '@/components/inputs/FileInput';
import {
  getImageUrlFromRte,
  deleteImageInRTE,
  insertImageAssetAtPosition,
  approveAssetAtPosition,
  addBriefToAssetInPosition,
} from '@xseededucation/rte-helper-functions';
export default {
  components: {
    SubSection,
    RichTextEditor,
    FileInput,
  },
  data() {
    return {
      imagesInAnswerGuideText: [],
      revision: 0,
      answerGuideTextRevision: 0,
    };
  },
  methods: {
    addBriefTextAtPosition(rteData, index) {
      let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
      addBriefToAssetInPosition(answerGuideText.content, index, 0, rteData);
      this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
    },
    addToAsset(previewUrl, briefUrl, approveAsset, index) {
      let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
      approveAssetAtPosition(
        answerGuideText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        approveAsset,
        previewUrl,
        briefUrl
      );
      this.getImagesListFromAnswerGuideText(answerGuideText);
      this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
      this.revision += 1;
    },
    approveAssets(val, index) {
      let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
      approveAssetAtPosition(
        answerGuideText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        val
      );
      this.getImagesListFromAnswerGuideText(answerGuideText);
      this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
      this.answerGuideTextRevision += 1;
      this.revision += 1;
    },
    onChangeAnswerGuideText(jsonString) {
      let jsonData = JSON.parse(jsonString);
      this.getImagesListFromAnswerGuideText(jsonData);
    },
    fileChangeHandler(file, index) {
      if (!file) {
        let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
        deleteImageInRTE(answerGuideText.content, index, 0, this.options.isAssetUploadRequired);
        this.getImagesListFromAnswerGuideText(answerGuideText);
        this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
        this.revision += 1;
        this.answerGuideTextRevision += 1;
      } else {
        let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
        insertImageAssetAtPosition(
          answerGuideText.content,
          index,
          0,
          file,
          this.options.isAssetUploadRequired
        );
        this.getImagesListFromAnswerGuideText(answerGuideText);
        this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
        this.revision += 1;
        this.answerGuideTextRevision += 1;
      }
    },
    getImagesListFromAnswerGuideText(jsonData) {
      let imagesInAnswerGuideText = [];
      getImageUrlFromRte(jsonData.content, imagesInAnswerGuideText);
      this.imagesInAnswerGuideText = imagesInAnswerGuideText;
      this.revision += 1;
    },
  },
  mounted() {
    if (this.data.questionData.answerGuideText) {
      this.getImagesListFromAnswerGuideText(JSON.parse(this.data.questionData.answerGuideText));
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    getToolBarActions: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    forceAssetFinalized: {
      type: Boolean,
      required: true,
    },
    getToolBarActionsForQuestionBriefs: {
      type: Array,
      required: true,
    },
  },
};
</script>
