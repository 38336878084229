<template>
  <v-row class="p-0" style="background-color: rgb(238, 238, 238);" dense>
    <v-col :class="`remove-padding`">
      <div style="background: #eee;" class="pa-5">
        <!-- First Row -->
        <v-row dense>
          <v-col>
            <div v-if="questionNumber">
              <SubSection
                v-if="questionNumber.start !== questionNumber.end"
                :title="`${subSectionTitle}: ${questionNumber.start} - ${questionNumber.end}`"
              />
              <SubSection v-else :title="`${subSectionTitle}: ${questionNumber.start}`" />
            </div>
            <div v-else>
              <SubSection :title="subSectionTitle" />
            </div>
          </v-col>
          <v-spacer />
          <div v-if="showIndividualQuestionCloseButton" class="text-end">
            <v-btn color="primary" outlined @click="closeEditForm"
              >Close<v-icon>mdi-close</v-icon></v-btn
            >
          </div>
          <v-btn
            v-if="!showIndividualQuestionCloseButton"
            class="ma-1 action-button"
            icon
            tile
            color="red"
            @click="onQuestionDelete"
          >
            <v-icon small>mdi-trash-can-outline</v-icon>
          </v-btn>
          <div class="text-end px-4">
            <v-btn
              v-if="showIndividualQuestionCloseButton"
              class="primary"
              text
              @click="saveHandler"
            >
              <v-icon left>mdi-content-save</v-icon> Save</v-btn
            >
          </div>
        </v-row>
        <div v-if="showMetaFields">
          <XSDivider />
          <v-row class="pa-0 ma-0">
            <SubSection title="Meta Data" />
          </v-row>
          <v-row>
            <v-col v-for="(options, key) in filteredData" :key="key" cols="2">
              <v-select
                :items="options"
                :item-text="getItemText(key)"
                :item-value="'_id'"
                :label="keyLabel(key)"
                v-model="selectedMetaDataOptions[getSingularKey(key)]"
                :menu-props="{ closeOnContentClick: false }"
                @change="(value) => onSelectChange(key, value)"
                dense
                outlined
                :return-object="key === 'multiCompetencies'"
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- Second Row -->
        <v-row dense>
          <v-col cols="3">
            <QuestionTypeSelect
              @selectedQuestionTypeFromChild="changeInQuestionTypeHelper"
              :disabled="!newlyCreatedQuestion || options.disableQuestionTypeDropdown"
              :defaultType="this.data.questionType || defaultQuestionType"
              :lpType="lpType"
              :allowedQuestionTypes="allowedQuestionTypes"
            />
          </v-col>
          <div style="width: 10px;"></div>
          <v-checkbox
            @change="changeInstructionAddedHandler"
            v-model="data.isInstructionAdded"
            label="Instruction is added in the question text"
            v-if="defaultQuestionType == 'fib'"
          ></v-checkbox>
        </v-row>

        <div :key="questionBlockRenderer">
          <RichTextEditor
            :key="questionTextKeyId"
            :readonly="false"
            :canShowDiffIcon="!options.hideDiff"
            v-model="data.questionData.questionText"
            placeholder="Enter question Text"
            :fieldIdentifier="`['${data._id}'].questionData.questionText`"
            projectType="question"
            :showValidationError="!turnOffQuestionTextValidation"
            @input="onChangeQuestionText"
            :includeOnlyToolBarActions="[...getToolBarActions(), 'table']"
            :isAssetUploadRequired="options.isAssetUploadRequired"
            :forceAssetFinalized="forceAssetFinalized"
            :acceptedFileTypes="[...getRTEAcceptedFileType, 'video/mp4', 'audio/mpeg']"
          />
        </div>
        <v-row>
          <v-col
            v-for="(image, index) in imagesInQuestionText"
            :key="`imagesInQuestionText${questionBlockRenderer}${revision}${index}`"
            :cols="3"
          >
            <FileInput
              :canShowDiffIcon="false"
              :diffIdentifier="''"
              :fileAcceptType="'Image/*'"
              :label="'Image'"
              :loadingStatus="false"
              :isRequired="options.isAssetUploadRequired"
              :isAssetUploadRequired="options.isAssetUploadRequired"
              :fileChangeHandler="(file) => fileChangeHandler(file, index)"
              :previewUrl="image.attrs.src"
              :previewUrlBrief="image.attrs.briefSrc"
              :image_url="image.attrs.assetId"
              :image_url_brief="image.attrs.briefAssetId"
              :isNotReadOnly="options.isNotReadOnly"
              :canAppproveAsset="canAppproveAsset"
              :ApproveAssetAndEmitResult="true"
              :canFinalizeAsset="true"
              :useAssetVault="true"
              @addBriefToAsset="
                (previewUrl, briefUrl, approveAsset) =>
                  addToAsset(previewUrl, briefUrl, approveAsset, index)
              "
              :isAssetApproved="image.attrs.assetApproved"
              @assetApprovedByUser="(val) => approveAssets(val, index)"
            /><br />
            <RichTextEditor
              :readonly="options.isAssetUploadRequired"
              :canShowDiffIcon="false"
              v-model="image.attrs.brief"
              placeholder="Enter Image Brief"
              projectType="question"
              :showValidationError="false"
              :includeOnlyToolBarActions="[...getToolBarActionsForQuestionBriefs(), 'table']"
              @input="addBriefTextAtPosition(image.attrs.brief, index)"
              :removeAllPluginExceptFullScreen="true"
              :counter="3000"
              :allowCharacterCount="true"
            />
          </v-col>
        </v-row>
        <div :class="wrapAnswerBlockInContainer ? 'answerContainer mt-6' : ''">
          <McqQuestion
            :data="data"
            v-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
            :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
          />
          <MsqQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
            :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
          />
          <TfQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
          />

          <MtfQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
          />
          <FibQuestion
            :key="`${optionTextKeyId}-fib-content`"
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :deleteBlankHandler="blankDeleteHandler"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
          />
          <AtfQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
          />
          <SaLaQuestion
            :data="data"
            v-else-if="
              selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
              selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA
            "
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
          />
        </div>
      </div>

      <Padder :horizontal="50" :vertical="20" v-if="canEnableWritingLinesAndDrawingBox">
        <XSDivider />
        <v-row>
          <Padder :left="7">
            <SubSection title="Additional Info" />
          </Padder>
        </v-row>
        <section class="d-flex align-start mb-6 justify-space-between">
          <v-col cols="3">
            <v-text-field
              placeholder="Writing Lines"
              label="Writing Lines"
              type="number"
              outlined
              dense
              v-model="data.writingLines"
              :max="WRITING_LINES_HASH.max"
              :min="WRITING_LINES_HASH.min"
              :rules="[(v) => v <= 20 || 'Maximum is 20', (v) => (v) => 0 || 'Minimum is 0']"
              @keydown="
                (event) => {
                  if (event.key === '.' || event.key === '-') {
                    event.preventDefault();
                  }
                }
              "
              @change="(event) => callOnWritingLinesOrDrawingBoxUpdate('writingLines', event)"
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
          <v-col cols="6">
            <v-select
              v-model="data.drawingBox"
              :items="DRAWING_BOX_OPTIONS"
              placeholder="Drawing Box"
              item-text="label"
              item-value="value"
              outlined
              dense
              hide-details
              label="Drawing Box"
              @change="(event) => callOnWritingLinesOrDrawingBoxUpdate('drawingBox', event)"
            />
          </v-col>
        </section>
      </Padder>
    </v-col>
    <v-col
      cols="5"
      v-if="setQuestionDiffPanel"
      class="fix-height-diff-comments"
      style="box-shadow: -2px 0px 2px 1px #ccc;"
    >
      <div>
        <Diff
          :fieldDiff="fieldDiff"
          :closeHandler="diffCloseHandler"
          :currentStage="questionsCurrentStage"
          :title="computeTitle"
          :isForContentEntry="true"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import Diff from '@/components/ContentDiff/Diff';
import SubSection from '@/components/inputs/SubSection.vue';
import QuestionTypeSelect from '@/components/contentEntry/questionComponents/dialogComponents/QuestionTypeSelect.vue';
import FileInput from '@/components/inputs/FileInput';
import { LP_TYPES_HASH } from '@/constants/common';
import {
  WRITING_LINES_HASH,
  DRAWING_BOX_OPTIONS,
  DRAWING_BOX_OPTION_NONE,
} from '@/constants/question';

import {
  getNewlyInsertedBlankIndex,
  deleteBlank,
  getBlankTypesFromContent,
  getAllBlankTypesFromContent,
  getImageUrlFromRte,
  deleteImageInRTE,
  insertImageAssetAtPosition,
  approveAssetAtPosition,
  addBriefToAssetInPosition,
} from '@xseededucation/rte-helper-functions';
import { v4 as uuidv4 } from 'uuid';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import { startCase, camelCase } from 'lodash';

//Question Components START
import McqQuestion from '@/components/contentEntry/questionComponents/dialogComponents/McqQuestion.vue';
import MsqQuestion from '@/components/contentEntry/questionComponents/dialogComponents/MsqQuestion.vue';
import TfQuestion from '@/components/contentEntry/questionComponents/dialogComponents/TfQuestion.vue';
import MtfQuestion from '@/components/contentEntry/questionComponents/dialogComponents/MtfQuestion.vue';
import FibQuestion from '@/components/contentEntry/questionComponents/dialogComponents/FibQuestion.vue';
import AtfQuestion from '@/components/contentEntry/questionComponents/dialogComponents/AtfQuestion.vue';
import SaLaQuestion from '@/components/contentEntry/questionComponents/dialogComponents/SaLaQuestion.vue';
//END
import _ from 'lodash';
import { deleteQuestionSpecificItems } from '@xseededucation/rte-helper-functions';

export default {
  name: 'ConfidenceQuestionEdit',
  data() {
    return {
      questionsOprogramProject,
      DEFAULT_QUESTION_DATA,
      selectedQuestionType: '',
      newlyInsertedBlankIndices: [],
      questionTextKeyId: uuidv4(),
      optionTextKeyId: uuidv4(),
      defaultQuestionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ,
      questionBlockRenderer: 0,
      imagesInQuestionText: [],
      revision: 0,
      LP_TYPES_HASH,
      WRITING_LINES_HASH,
      DRAWING_BOX_OPTIONS,
      DRAWING_BOX_OPTION_NONE,
      isInstructionAdded: false,
      selectedMetaDataOptions: {},
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.onQuestionChange) {
          this.onQuestionChange(val);
        }
        if (val && val.questionType) {
          this.defaultQuestionType = val.questionType;
          this.isInstructionAdded = val.isInstructionAdded;
        }
      },
    },
  },
  components: {
    QuestionTypeSelect,
    RichTextEditor,
    Diff,
    SubSection,
    McqQuestion,
    MsqQuestion,
    FibQuestion,
    TfQuestion,
    MtfQuestion,
    AtfQuestion,
    SaLaQuestion,
    FileInput,
  },
  computed: {
    ...mapState({
      isEditDialog: (state) => state.question.isEditDialog,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
      showLoader: (state) => state.questions.questionDialogLoader,
      questionsCurrentStage: (state) => state.questions.currentStage,
      contentEntryProjectFormAuth: (state) => state.auth.contentEntryProjectForm,
      lessonPlanFormAuth: (state) => state.auth.lessonPlan,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      currentDiffIdentifier: (state) => state.rightSideContent.currentDiffIdentifier,
      fieldDiff: (state) => state.rightSideContent.fieldDiff,
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
    }),
    filteredData() {
      // Filter out any null or empty arrays from the data object
      return Object.fromEntries(
        Object.entries(this.metadata).filter(([, value]) => value && value.length > 0)
      );
    },
    questionPurposeItems() {
      return this.questionMetaInfo.questionPurposes[this.lpType].map((purpose) => {
        return { label: startCase(camelCase(purpose)), value: purpose };
      });
    },
    languageSkills() {
      return this.questionMetaInfo.languageSkills.map((skill) => {
        return { label: skill, value: camelCase(skill) };
      });
    },
    questionPurposeData: {
      get: function () {
        return this.getAndSetInitQuestionPurpose();
      },
      set: function (purpose) {
        this.data.questionPurpose = purpose;
      },
    },
    //NOTE: This is temproary will be changed once RTE image upload implementation is across the portal
    getRTEAcceptedFileType() {
      return this.options.isAssetUploadRequired
        ? ['image/png', 'image/jpeg']
        : [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
          ];
    },
    forceAssetFinalized() {
      return this.questionsCurrentStage == 'assetAuthorizingStage';
    },
    computeTitle() {
      let list = this.currentDiffIdentifier.split('.');
      let finalTitle = list[list.length - 1];
      if (finalTitle == 'misconception') {
        return 'Feedback to student if they select this answer';
      }
      return this.currentDiffIdentifier;
    },
    selectedSkillValue() {
      if (
        this.questionMetaInfo &&
        this.questionMetaInfo.skills &&
        this.data &&
        this.data.skill != null
      ) {
        return this.questionMetaInfo.skills.filter(
          (questionMetaInfo) => questionMetaInfo.id == this.data.skill
        )[0];
      }
      return '';
    },
    selectedSubSkillValue() {
      if (
        this.questionMetaInfo &&
        this.questionMetaInfo.subSkills &&
        this.data &&
        this.data.subSkill != null
      ) {
        return this.questionMetaInfo.subSkills[this.selectedSkillValue.id].filter(
          (questionMetaInfo) => questionMetaInfo.id == this.data.subSkill
        )[0];
      }
      return '';
    },
    auth() {
      if (this.lpType == 'questions') {
        return this.contentEntryProjectFormAuth;
      } else {
        return this.lessonPlanFormAuth;
      }
    },
    canEnableWritingLinesAndDrawingBox() {
      return (
        this.lpType == this.LP_TYPES_HASH.VIRTUAL_QUESTIONS &&
        (this.data.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT ||
          this.data.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_WORKSHEET) &&
        [
          this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
          this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
        ].includes(this.selectedQuestionType)
      );
    },
  },
  mounted() {
    if (
      this.lpType == 'questions' &&
      [
        this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
        this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
      ].includes(this.selectedQuestionType)
    ) {
      this.data.questionPurpose = this.questionsOprogramProject.QUESTION_PURPOSE_PRACTICE;
    }
    this.getImagesListFromQuestionText(JSON.parse(this.data.questionData.questionText));
  },
  methods: {
    ...mapActions(['setQuestionRightSidePanel']),
    ...mapMutations(['SET_COMMENTS_DIALOG', 'SET_GROUP_QUESTION_COMMENTS_DIALOG']),
    saveHandler() {
      if (
        this.selectedMetaDataOptions.multiCompetency &&
        typeof this.selectedMetaDataOptions.multiCompetency != 'object'
      ) {
        const multiCompetency = this.metadata.multiCompetencies.find(
          (item) => item._id === this.selectedMetaDataOptions.multiCompetency
        );
        this.selectedMetaDataOptions.multiCompetency = {
          blockId: multiCompetency.block._id,
          _id: multiCompetency._id,
          text: multiCompetency.text,
        };
      }
      this.data.metadata = { ...this.selectedMetaDataOptions };
      this.data.questionType = this.selectedQuestionType;
      this.saveForm(this.data);
    },
    onSelectChange(key, value) {
      console.log(`${key}: ${value}`);
      this.data.difficulty = 'difficulty';
      if (key === 'multiCompetencies') {
        this.selectedMetaDataOptions['multiCompetency'] = {
          blockId: value.block._id,
          _id: value._id,
          text: value.text,
        };
      } else {
        if (
          this.selectedMetaDataOptions.multiCompetency &&
          typeof this.selectedMetaDataOptions.multiCompetency != 'object'
        ) {
          const multiCompetency = this.metadata.multiCompetencies.find(
            (item) => item._id === this.selectedMetaDataOptions.multiCompetency
          );
          this.selectedMetaDataOptions.multiCompetency = {
            blockId: multiCompetency.block._id,
            _id: multiCompetency._id,
            text: multiCompetency.text,
          };
        }
      }
      this.data.metadata = { ...this.selectedMetaDataOptions };
      this.data.difficulty = '';
    },
    keyLabel(key) {
      return key.charAt(0).toUpperCase() + key.slice(1);
    },
    getItemText(key) {
      // Determine the correct field name for item text based on key
      if (key === 'aims') return 'text';
      if (key === 'difficulty') return 'name';
      if (key === 'cognitiveType') return 'name';
      if (key === 'microTopics') return 'title';
      if (key === 'microTopic') return 'title';
      if (key === 'pisaStandards' || key === 'ncfStandards')
        return (item) => `${item.code} (${item.title})`;
      if (key === 'multiCompetencies')
        return (item) => `G${item.grade && item.grade.shortCode} - ${item.text}`;
      return 'name';
    },
    getSingularKey(key) {
      const pluralRules = [
        { plural: /ies$/, singular: 'y' }, // e.g. difficulties -> difficulty
        { plural: /ves$/, singular: 'f' }, // e.g. leaves -> leaf
        { plural: /oes$/, singular: 'o' }, // e.g. heroes -> hero
        { plural: /ses$/, singular: 's' }, // e.g. buses -> bus
        { plural: /s$/, singular: '' }, // general rule for plurals ending in 's'
      ];

      // Loop over the rules and apply them if the key matches the plural form
      for (const rule of pluralRules) {
        if (rule.plural.test(key)) {
          return key.replace(rule.plural, rule.singular);
        }
      }
      return key; // If no rule matched, return the original key
    },
    addToAsset(previewUrl, briefUrl, approveAsset, index) {
      let questionText = JSON.parse(this.data.questionData.questionText);
      approveAssetAtPosition(
        questionText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        approveAsset,
        previewUrl,
        briefUrl
      );
      this.getImagesListFromQuestionText(questionText);
      this.data.questionData.questionText = JSON.stringify(questionText);
      this.questionTextKeyId = uuidv4();
      this.revision += 1;
    },
    approveAssets(val, index) {
      let questionText = JSON.parse(this.data.questionData.questionText);
      approveAssetAtPosition(
        questionText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        val
      );
      this.getImagesListFromQuestionText(questionText);
      this.data.questionData.questionText = JSON.stringify(questionText);
      this.questionTextKeyId = uuidv4();
      this.revision += 1;
    },
    addBriefTextAtPosition(rteData, index) {
      let questionText = JSON.parse(this.data.questionData.questionText);
      addBriefToAssetInPosition(questionText.content, index, 0, rteData);
      this.data.questionData.questionText = JSON.stringify(questionText);
    },
    changeInQuestionTypeHelper(val) {
      this.selectedQuestionType = val;
      this.changeInQuestionType(val);
      this.questionBlockRenderer += 1;
      this.callOnQuestionTypeOrPurposeUpdate();
    },
    changeInstructionAddedHandler(val) {
      this.data.isInstructionAdded = val;
    },
    fileChangeHandler(file, index) {
      if (!file) {
        let questionText = JSON.parse(this.data.questionData.questionText);
        deleteImageInRTE(questionText.content, index, 0, this.options.isAssetUploadRequired);
        this.getImagesListFromQuestionText(questionText);
        this.data.questionData.questionText = JSON.stringify(questionText);
        this.questionTextKeyId = uuidv4();
        this.revision += 1;
      } else {
        let questionText = JSON.parse(this.data.questionData.questionText);

        insertImageAssetAtPosition(
          questionText.content,
          index,
          0,
          file,
          this.options.isAssetUploadRequired
        );
        this.getImagesListFromQuestionText(questionText);
        this.data.questionData.questionText = JSON.stringify(questionText);
        this.questionTextKeyId = uuidv4();
        this.revision += 1;
      }
    },
    // THIS IS A TEMPORARY method. Need to move the questions inside each question type component
    getToolBarActions() {
      if (this.selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB) {
        return [
          'fontFamily',
          'fontSize',
          'unorderedList',
          'orderedList',
          'fontColor',
          'bold',
          'italic',
          'underline',
          'superscript',
          'subscript',
          'equation',
          'image',
          'blank',
          'fullScreen',
          'table',
        ];
      } else {
        return [
          'fontFamily',
          'fontSize',
          'unorderedList',
          'orderedList',
          'fontColor',
          'bold',
          'italic',
          'underline',
          'superscript',
          'subscript',
          'equation',
          'image',
          'fullScreen',
          'table',
        ];
      }
    },
    getToolBarActionsForQuestionBriefs() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'table',
        'fullScreen',
      ];
    },
    diffCloseHandler() {
      this.setQuestionRightSidePanel(false);
    },
    blankDeleteHandler(index) {
      let typeofQuestion = typeof this.data.questionData.questionText;
      let questionText;
      if (typeofQuestion == 'string') {
        questionText = JSON.parse(this.data.questionData.questionText);
      } else {
        questionText = this.data.questionData.questionText;
      }

      deleteBlank(questionText.content, index, -1);
      this.data.questionData.blanks.splice(index, 1);
      this.data.questionData.questionText = JSON.stringify(questionText);
      this.updateSequence();
      this.questionTextKeyId = uuidv4(); // this is to force re-render
      this.optionTextKeyId = uuidv4();
    },
    handleBlankCreation() {
      let questionTextJSON = JSON.parse(this.data.questionData.questionText);
      let recentBlankIndex = this.data.questionData.blanks.length - 1;

      let trackingIndexesObject = {
        currentBlankIndex: -1,
        newlyInsertedBlankIndex: [],
        recentBlankIndex,
      };
      getNewlyInsertedBlankIndex(questionTextJSON.content, trackingIndexesObject);

      this.newlyInsertedBlankIndices = trackingIndexesObject.newlyInsertedBlankIndex;

      this.data.questionData.questionText = JSON.stringify(questionTextJSON);

      this.newlyInsertedBlankIndices.forEach((_blankIndex) => {
        this.data.questionData.blanks.splice(_blankIndex, 0, {
          correctAnswers: [{ text: '' }],
          scorePercentage: 0,
        });
      });
      this.updateSequence();
    },
    handleBlankDeletion(jsonData) {
      let obj = {
        currentBlankIndex: -1,
        deleteBlankIndex: -1,
      };

      let blankTypeArray = [];
      getBlankTypesFromContent(jsonData.content, blankTypeArray, obj);
      if (blankTypeArray.length == this.data.questionData.blanks.length) {
        return;
      }
      if (blankTypeArray.length == 0) {
        this.data.questionData.blanks.splice(0, this.data.questionData.blanks.length);
      } else {
        for (var i = this.data.questionData.blanks.length - 1; i >= 0; i--) {
          if (!blankTypeArray.includes(i - 1)) {
            this.data.questionData.blanks.splice(i, 1);
          }
        }
      }
      this.updateSequence();
      this.data.questionData.questionText = JSON.stringify(jsonData);
      this.optionTextKeyId = uuidv4();
    },
    updateSequence() {
      for (let i = 0, j = this.data.questionData.blanks.length; i < j; i++) {
        this.data.questionData.blanks[i].sequence = i;
      }
    },
    getImagesListFromQuestionText(jsonData) {
      let imagesInQuestionText = [];
      getImageUrlFromRte(jsonData.content, imagesInQuestionText);
      this.imagesInQuestionText = imagesInQuestionText;
      this.revision += 1;
    },
    onChangeQuestionText(jsonString) {
      let jsonData = JSON.parse(jsonString);
      this.getImagesListFromQuestionText(jsonData);
      if (this.selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB) {
        // NO of blanks info is here
        let allBlankTypeArray = [];
        getAllBlankTypesFromContent(jsonData.content, allBlankTypeArray);

        let isBlankNewlyCreated = false;

        if (allBlankTypeArray.length == this.data.questionData.blanks.length) {
          console.log(`No blank touched! Hence skip this callback and return.`);
          return;
        } else if (allBlankTypeArray.length > this.data.questionData.blanks.length) {
          isBlankNewlyCreated = true;
        }

        isBlankNewlyCreated ? this.handleBlankCreation() : this.handleBlankDeletion(jsonData);
      }
    },
    getAndSetInitQuestionPurpose() {
      let questionPurposeValues = this.questionPurposeItems.map((_) => _.value);

      let _questionPurpose = questionPurposeValues.includes(this.data.questionPurpose)
        ? this.data.questionPurpose
        : null;

      return _questionPurpose;
    },
    callOnWritingLinesOrDrawingBoxUpdate(field, updateValue) {
      if (this.onWritingLinesOrDrawingBoxUpdate) {
        this.onWritingLinesOrDrawingBoxUpdate(field, updateValue);
      }
    },
    callOnQuestionTypeOrPurposeUpdate() {
      if (
        this.canEnableWritingLinesAndDrawingBox &&
        this.data.writingLines == undefined &&
        this.data.drawingBox == undefined
      ) {
        this.$set(this.data, 'writingLines', WRITING_LINES_HASH.min);
        this.$set(this.data, 'drawingBox', DRAWING_BOX_OPTION_NONE);
      }
      if (this.onQuestionTypeOrPurposeUpdate) {
        this.onQuestionTypeOrPurposeUpdate();
      }
    },
    changeInQuestionType() {
      if (this.data.questionType && this.selectedQuestionType != this.data.questionType) {
        //Deconstructs questionData and places rest of the keys under metaInformation
        var { questionData } = this.data;
        var { questionText } = questionData;
        const existingMetaData = this.data.metadata;
        const questionGroupId = this.data.questionGroupId;
        const questionTypeDefaultData = this.DEFAULT_QUESTION_DATA[this.selectedQuestionType];
        let selectedQuestion = _.cloneDeep(questionTypeDefaultData);
        selectedQuestion.questionId = this.data.questionId;
        this.data = selectedQuestion;
        //Copies question text and meta information while changing question type
        let processedQuestionText = this.sanitizeQuestionText(questionText);
        this.data.questionData.questionText = processedQuestionText;
        questionData.questionText = processedQuestionText;
        this.data.metadata = existingMetaData;
        this.data.questionGroupId = questionGroupId;
      }
    },
    sanitizeQuestionText(questionText) {
      try {
        let parsedQuestionText = JSON.parse(questionText);
        deleteQuestionSpecificItems(parsedQuestionText.content);
        return JSON.stringify(parsedQuestionText);
      } catch (e) {
        if (e instanceof SyntaxError) {
          return questionText;
        } else {
          console.log('You have some error while sanitizing questionText');
          console.log(e);
        }
      }
    },
  },
  created() {
    this.selectedMetaDataOptions = this.selectedMetaDataProp;
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    newlyCreatedQuestion: {
      type: Boolean,
      required: true,
    },
    closeEditForm: {
      type: Function,
      required: true,
    },
    saveForm: {
      type: Function,
      required: true,
    },
    onQuestionChange: {
      type: Function,
      required: true,
    },
    showIndividualQuestionCloseButton: {
      type: Boolean,
      default: true,
    },
    wrapAnswerBlockInContainer: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    lpType: {
      type: String,
      default: 'questions',
    },
    lessonPlans: {
      type: Array,
      required: false,
    },
    onQuestionDelete: {
      type: Function,
      required: false,
    },
    onWritingLinesOrDrawingBoxUpdate: {
      type: Function,
      required: false,
    },
    onQuestionTypeOrPurposeUpdate: {
      type: Function,
      required: false,
    },
    isLanguageSubject: {
      type: Boolean,
      defualt: false,
    },
    showMetaFields: {
      type: Boolean,
      default: true,
    },
    modifyChangeQuestionBaseData: {
      type: Function,
      default: undefined,
    },
    subSectionTitle: {
      type: String,
      default: 'Question',
    },
    dynamicMetaDataKeySourceDataMap: {
      type: Object,
      default: () => {},
    },
    turnOffQuestionTextValidation: {
      type: Boolean,
      default: false,
    },
    allowedQuestionTypes: {
      type: Array,
      require: false,
      default: undefined,
    },
    metadata: {
      type: Object,
      require: false,
      default: () => {},
    },
    selectedMetaDataProp: {
      type: Object,
      require: false,
      default: () => {},
    },
    onMetaDataSelect: {
      type: Function,
      require: false,
    },
    questionNumber: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped>
.remove-padding {
  padding: 0 !important;
}
.answerContainer {
  background: white;
  border-radius: 15px;
}
.fix-height {
  z-index: 999;
  padding: 10px;
}
.fix-height-diff-comments {
  z-index: 999;
  padding: 10px;
}
</style>
