<template>
  <section>
    <RTEWithTwoAssets
      class="mgn-Tm"
      :isReadOnly="isReadOnly"
      :canShowDiffIcon="canShowDiffIcon"
      :data="data"
      :diffIdentifierPrefix="diffIdentifierPrefix"
      :descriptionPlaceholder="descriptionPlaceholder"
      :diffChanges="diffChanges || {}"
      :isAssetUploadRequired="isAssetUploadRequired"
      :signedUrls="signedUrls"
      :uploadingStatus="uploadingStatus"
      :acceptableFileType="acceptableFileType"
      :showImageBrief="showImageBrief"
      :fileValidators="fileValidators"
      :canAppproveAsset="canAppproveAsset"
      :canFinalizeAsset="true"
      :isFirstAssetMandatory="isQuestionImageMandatory"
      :assetTypeMapping="assetTypeMapping"
      :descriptionMaxLength="descriptionMaxLength"
      :isDescriptionOptional="isDescriptionOptional"
      :showRTEDescription="true"
      firstAssetKey="question"
      secondAssetKey="question_answer"
    />
    <div class="mgn-Ts mgn-Bs" v-if="showTeacherTip">
      <XSDisplayCard :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="(diffChanges.teacher_tip || {}).isChanged || false"
          :fieldIdentifier="`${diffIdentifierPrefix}.teacher_tip`"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="data.teacher_tip"
          :counter="teacherTipMaxLength"
          :readonly="isReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
      </XSDisplayCard>
    </div>
  </section>
</template>
<script>
import RTEWithTwoAssets from '@/components/inputs/RTEWithTwoAssets';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import AppTexts from '@/constants/AppTexts';
export default {
  name: 'ClassWorkHomeWork',
  components: {
    RTEWithTwoAssets,
    XSDisplayCard,
    RichTextEditor,
  },
  data() {
    return {
      AppTexts,
      BASIC_TOOLS,
    };
  },
  props: {
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    diffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    diffChanges: {
      type: Object,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: true,
    },
    signedUrls: {
      type: Object,
      required: false,
    },
    uploadingStatus: {
      type: Object,
      required: true,
    },
    acceptableFileType: {
      type: String,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    fileValidators: {
      type: Array,
      required: false,
    },
    assetTypeMapping: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      required: true,
    },
    canFinalizeAsset: {
      type: Boolean,
      required: true,
    },
    isQuestionImageMandatory: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAnswerImageMandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDescriptionOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    descriptionMaxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    showTeacherTip: {
      type: Boolean,
      default: false,
    },
    teacherTipMaxLength: {
      type: Number,
      default: 0,
    },
  },
};
</script>
