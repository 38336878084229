<template>
  <div
    :style="compact ? 'padding: 22px 15px 0px;' : 'padding: 34px 15px 20px 15px;'"
    class="display-card-outter-wrapper"
  >
    <span class="title-text">{{ title }}</span>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'XSDisplayCard',
  props: {
    title: { type: String, required: true },
    compact: { type: Boolean, required: false, default: false }
  }
};
</script>

<style scoped>
.display-card-outter-wrapper {
  border: 1px solid #979797;
  border-radius: 5px;
  position: relative;
  margin-top: 32px;
}
.title-text {
  font-size: 20px;
  color: #fff;
  background-color: #bb161b;
  border-radius: 20px;
  padding: 6px 25px;
  position: absolute;
  top: -23px;
}
</style>
