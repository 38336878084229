<template>
  <v-form ref="assessmentSectionForm" class="section-title-cnr">
    <BlockTitleWithSubtitle :title="AppTexts.CHECK" v-if="canShowEvaluateAndGrade()" />
    <div class="mgn-Ts" v-if="canShowEvaluateAndGrade()">
      <span class="workseet-text"
        >Worksheet {{ lessonPlanData.application.classwork.description }}</span
      >
      <v-row dense>
        <v-col cols="6" class="pad-Rm">
          <FileInput
            :image_url="
              isAssetUploadRequired ? lessonPlanData.assessment.check.question_image_url : ''
            "
            :image_url_brief="lessonPlanData.assessment.check.question_image_brief_url"
            :previewUrl="isAssetUploadRequired ? assessmentSignedUrls.check.signedQuestionUrl : ''"
            :previewUrlBrief="assessmentSignedUrls.check.signedQuestionBriefUrl"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentCheckAttributesIsChangedValue(questionImageKey)"
            :diffIdentifier="'assessment.check.' + questionImageKey"
            :fileAcceptType="acceptableFileType"
            :label="AppTexts.SELECT_QUESTION_FILE"
            :loadingStatus="uploadingStatus.student_workbook == FILE_UPLOADING_STATUS.IN_PROGRESS"
            :isRequired="true"
            :isAssetUploadRequired="isAssetUploadRequired"
            :fileChangeHandler="importAssessmentQuestionImage"
            :isNotReadOnly="isNotReadOnly"
            :fileValidators="fileValidators"
            :isAssetApproved="lessonPlanData.assessment.check.question_image_url_approved"
            :canAppproveAsset="canApproveAsset"
            :canFinalizeAsset="true"
            @addBriefToAsset="
              (previewUrl, briefUrl, isAssetApproved) =>
                copyAssessmentQuestionImage(previewUrl, briefUrl, isAssetApproved)
            "
          />
        </v-col>
        <v-col cols="6" class="pad-Lm">
          <v-row class="pad-top-none" dense>
            <v-col class="pad-top-none">
              <FileInput
                :image_url="
                  isAssetUploadRequired ? lessonPlanData.assessment.check.answer_image_url : ''
                "
                :image_url_brief="lessonPlanData.assessment.check.answer_image_brief_url"
                :previewUrl="
                  isAssetUploadRequired ? assessmentSignedUrls.check.signedAnswerUrl : ''
                "
                :previewUrlBrief="assessmentSignedUrls.check.signedAnswerBriefUrl"
                :canShowDiffIcon="canShowDiffIcon"
                :isDiffChanged="getAssessmentCheckAttributesIsChangedValue(answerImageKey)"
                :diffIdentifier="'assessment.check.' + answerImageKey"
                :fileAcceptType="acceptableFileType"
                :label="AppTexts.SELECT_ANSWER_FILE"
                :loadingStatus="
                  uploadingStatus.student_workbook_answer == FILE_UPLOADING_STATUS.IN_PROGRESS
                "
                :isRequired="false"
                :isAssetUploadRequired="isAssetUploadRequired"
                :fileChangeHandler="importAssessmentAnswerImage"
                :isNotReadOnly="isNotReadOnly"
                :fileValidators="fileValidators"
                :isAssetApproved="lessonPlanData.assessment.check.answer_image_url_approved"
                :canAppproveAsset="canApproveAsset"
                :canFinalizeAsset="true"
                @addBriefToAsset="
                  (previewUrl, briefUrl, isAssstApproved) =>
                    copyAssessmentAnswerImage(previewUrl, briefUrl, isAssstApproved)
                "
              />
              <RichTextEditor
                :canShowDiffIcon="canShowDiffIcon"
                :isDiffChanged="getAssessmentCheckAttributesIsChangedValue('answer_image_brief')"
                fieldIdentifier="assessment.check.answer_image_brief"
                v-if="showImageBrief"
                :placeholder="AppTexts.WORKSHEET_ANSWER_BRIEF"
                v-model="lessonPlanData.assessment.check.answer_image_brief"
                :showValidationError="true"
                :counter="3000"
                :readonly="!isNotReadOnly"
                class="mgn-Tm"
                :includeOnlyToolBarActions="BASIC_TOOLS"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="mgn-Bs">
      <v-row class="assessment-grade-template-row" v-if="canShowEvaluateAndGrade()">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.APPROACHING[0] }}</span>
        </div>
        <v-col>
          <TextInput
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('approaching')"
            fieldIdentifier="assessment.grade_template.approaching.description"
            :placeholder="AppTexts.APPROACHING"
            :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
            v-model="lessonPlanData.assessment.grade_template.approaching.description"
            @update:value="
              lessonPlanData.assessment.grade_template.approaching.description = $event
            "
            :enableTextFormatting="true"
            customClass=""
            :readonly="!isNotReadOnly"
            :counter="160"
          />
        </v-col>
      </v-row>
      <v-row class="assessment-grade-template-row" v-if="canShowEvaluateAndGrade()">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.MEETING[0] }}</span>
        </div>
        <v-col>
          <TextInput
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('meeting')"
            fieldIdentifier="assessment.grade_template.meeting.description"
            :placeholder="AppTexts.MEETING"
            :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
            v-model="lessonPlanData.assessment.grade_template.meeting.description"
            @update:value="lessonPlanData.assessment.grade_template.meeting.description = $event"
            :enableTextFormatting="true"
            customClass=""
            :readonly="!isNotReadOnly"
            :counter="160"
          />
        </v-col>
      </v-row>
      <v-row class="assessment-grade-template-row" v-if="canShowEvaluateAndGrade()">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.EXCEEDING[0] }}</span>
        </div>
        <v-col>
          <TextInput
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('exceeding')"
            fieldIdentifier="assessment.grade_template.exceeding.description"
            :placeholder="AppTexts.EXCEEDING"
            :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
            v-model="lessonPlanData.assessment.grade_template.exceeding.description"
            @update:value="lessonPlanData.assessment.grade_template.exceeding.description = $event"
            :enableTextFormatting="true"
            :readonly="!isNotReadOnly"
            :counter="160"
          />
        </v-col>
      </v-row>
      <v-row class="assessment-grade-template-row" v-if="!canShowEvaluateAndGrade()">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.OVERALL[0] }}</span>
        </div>
        <v-col>
          <TextInput
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('overall')"
            fieldIdentifier="assessment.grade_template.overall.description"
            :placeholder="AppTexts.OVERALL"
            :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
            v-model="lessonPlanData.assessment.grade_template.overall.description"
            @update:value="lessonPlanData.assessment.grade_template.overall.description = $event"
            :enableTextFormatting="true"
            customClass=""
            :readonly="!isNotReadOnly"
          />
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { ASSESSMENT_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import AssetTypes from '@/constants/AssetTypes';
import FileInput from '@/components/inputs/FileInput';
import TextInput from '@/components/inputs/TextInput';
import { LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE } from '@/constants/LpForm';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';

export default {
  name: 'AssessmentSection',
  components: {
    BlockTitleWithSubtitle,
    FileInput,
    TextInput,
    RichTextEditor
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ASSESSMENT_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      imageKey: this.isAssetUploadRequired ? 'image_url' : 'brief_url',
      questionImageKey: this.isAssetUploadRequired
        ? 'question_image_url'
        : 'question_image_brief_url',
      answerImageKey: this.isAssetUploadRequired ? 'answer_image_url' : 'answer_image_brief_url',
      BASIC_TOOLS,
    };
  },
  methods: {
    ...mapActions(['importSingleAssetAction', 'deleteSingleAssetAction', 'copySingleAssetAction']),
    importAssessmentQuestionImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.assessment.check.question_image_url
            : this.lessonPlanData.assessment.check.question_image_brief_url,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        });
      }
    },
    copyAssessmentQuestionImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        isAssetApproved
      });
    },
    importAssessmentAnswerImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.assessment.check.answer_image_url
            : this.lessonPlanData.assessment.check.answer_image_brief_url,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE,
        });
      }
    },
    copyAssessmentAnswerImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE,
        isAssetApproved
      });
    },
    getAssessmentGradeTemplateIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.assessmentDiff.grade_template[type].description.isChanged;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
    getAssessmentCheckAttributesIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.assessmentDiff.check[type].isChanged;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
    getFileNameFromUrl,
    canShowEvaluateAndGrade() {
      return !(
        this.lessonPlanFormRendererType == LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE &&
        this.isWorkSheetEnabled == false
      );
    },
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    assessmentSignedUrls: {
      type: Object,
      required: true,
    },
    assessmentDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    lessonPlanFormRendererType: {
      type: String,
      required: false,
    },
    isWorkSheetEnabled: {
      type: Boolean,
      required: false,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canApproveAsset: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
    }),
  },
};
</script>
<style scoped>
.assessment-grade-name {
  background: #b5e9e4;
  padding: 4px 1px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
}
.assessment-grade-name-col {
  display: flex;
  align-items: center;
  width: 45px;
}
.workseet-text {
  font-size: 14px;
  display: block;
  color: #000000;
  transform: translateY(9px);
  margin-left: 10px;
}
.pad-top-none {
  padding-top: 0px !important;
}
.assessment-grade-template-row {
  padding: 0px 5px;
  align-items: center;
}
</style>
