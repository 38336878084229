import gql from 'graphql-tag';

export const getProjectDailyPlanCurrentStageData = (projectId, dailyPlanLibraryId) => {
  return gql`
{
  project(id: "${projectId}"){
    id
    name
    type
    block {
      id
      bId
      title
    }
    board {
      id
      name
    }
    product {
      id
      name
    }
    subject {
      id
      name
    }
    standard {
      id
      name
      displayName
    }
  }
  projectDailyPlanCurrentStageData(projectId: "${projectId}", dailyPlanLibraryId: "${dailyPlanLibraryId}" ) {
    library_id
    name
    type
    currentStage
    lessonPlans {
      type
      library_id
    }
    libraryLessonPlans {
      id
      name
      type
      template
      sequence
    }
    currentStageAssetsSignedUrl
    currentStageData {
      validationStatus
      data {
        dp_title
        dp_description
        description
        assetId
        assetApproved
        brief
        lessonPlans {
          type
          library_id
          status
          summary
          template
          worksheetEnabled
        }
      }
    }
  }
}`;
};

export const saveProjectDailyPlanStageData = () => {
  return gql`
    mutation($projectId: ID!, $dailyPlanLibraryId: ID!, $data: DailyPlanDataInput) {
      saveProjectDailyPlanStageData(
        projectId: $projectId
        dailyPlanLibraryId: $dailyPlanLibraryId
        data: $data
      ) {
        description
        assetId
        assetApproved
        brief
        lessonPlans {
          type
          library_id
          status
          summary
          template
          worksheetEnabled
        }
      }
    }
  `;
};

export const vaidateEarlyPlan = () => {
  return gql`
    mutation($projectId: ID!, $dailyPlanLibraryId: ID!) {
      validateProjectDailyPlanStageData(
        projectId: $projectId
        dailyPlanLibraryId: $dailyPlanLibraryId
      ) {
        status
        message
        errors {
          path
          errorMessage
        }
      }
    }
  `;
};

export const moveDPToNextStage = () => {
  return gql`
    mutation($projectId: ID!, $dailyPlanLibraryId: ID!) {
      moveProjectDailyPlanToNextStage(
        projectId: $projectId
        dailyPlanLibraryId: $dailyPlanLibraryId
      ) {
        currentStage
      }
    }
  `;
};

export const uploadSingleAsset = () => {
  return gql`
    mutation uploadSingleAsset(
      $file: Upload!
      $fileName: String!
      $libraryId: Int!
      $namespace: String
    ) {
      uploadSingleAsset(
        file: $file
        fileName: $fileName
        libraryId: $libraryId
        namespace: $namespace
      ) {
        path
        signedUrl
      }
    }
  `;
};

export const removeDailyPlanAsset = () => {
  return gql`
    mutation removeDailyPlanAsset($url: String!, $projectId: ID!, $dailyPlanLibraryId: ID!) {
      removeDailyPlanAsset(
        url: $url
        projectId: $projectId
        dailyPlanLibraryId: $dailyPlanLibraryId
      ) {
        path
        signedUrl
      }
    }
  `;
};

export const removeDailyPlanAssetInCaForProject = () => {
  return gql`
    mutation removeDailyPlanAssetInCaForProject(
      $assetId: String!
      $projectId: ID!
      $dailyPlanLibraryId: ID!
      $fieldIdentifier: String!
    ) {
      removeDailyPlanAssetInCaForProject(
        assetId: $assetId
        projectId: $projectId
        dailyPlanLibraryId: $dailyPlanLibraryId
        fieldIdentifier: $fieldIdentifier
      ) {
        assetId
        signedUrl
      }
    }
  `;
};

export const downloadCurrentStageLpAssetsQuery = gql`
  query($projectId: ID!, $contentLibraryId: ID!) {
    downloadCurrentStageLpAssets(projectId: $projectId, contentLibraryId: $contentLibraryId) {
      status
    }
  }
`;

export const dailyPlansDataStatus = (libraryIds) => {
  return gql`
      {
        dailyPlansDataStatus(libraryIds: [${libraryIds.join(',')}])
      }
  `;
};
