<template>
  <div class="d-flex flex-row items boardwork-title">
    <span class="title primary mgn-Rs"></span>
    <span class="block-title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'SubSection',
  props: ['title']
};
</script>
<style scoped>
.title {
  height: 30px;
  width: 10px;
  font-size: 20px;
}
.items {
  align-items: center;
}
.boardwork-title {
  padding: 12px 0px;
}
.block-title {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
}
</style>
