<template>
  <!-- 
    Prevent form submission on enter key press
    https://vuejs.org/v2/guide/events.html#Event-Modifiers
  -->
  <v-form ref="stepForm" v-on:submit.prevent>
    <v-row dense class="item">
      <v-col cols="1">
        <v-label>{{ label }}</v-label>
      </v-col>
      <v-col cols="12">
        <v-text-field
          height="45"
          dense
          :label="placeholder"
          outlined
          class="text-field"
          :value="value"
          @keyup="onChange"
          v-on="listeners"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          hide-details
          :type="type"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Errors from '@/constants/Errors';

export default {
  name: 'TextFieldWithLabel',
  data() {
    return {
      Errors
    };
  },
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    onChange: {
      type: Function,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: false,
      default: "text"
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners
      };
    }
  }
};
</script>

<style scoped>
.item {
  align-items: center;
}
</style>
