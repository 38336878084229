<template>
  <div class="subject-selection">
    <v-container v-bind:style="{ width: '450px' }">
      <v-flex class="text-xs-center">
        <v-form ref="form">
          <v-label>{{ AppTexts.SELECT_BOARD }}</v-label>
          <v-select
            v-model="selectedBoard"
            :items="boards"
            :rules="[(v) => !!v || 'Board is required']"
            required
            item-text="name"
            item-value="id"
            class="select-board-dropdown"
            @change="selectBoard"
          />
          <v-label>{{ AppTexts.SELECT_SUBJECT }}</v-label>
          <v-select
            v-model="selectedSubject"
            :items="subjects"
            :rules="[(v) => !!v || 'Subject is required']"
            required
            item-text="name"
            item-value="id"
            class="select-subject-dropdown"
            @change="selectSubject"
          />
          <v-label>{{ AppTexts.SELECT_STANDARD }}</v-label>
          <v-select
            v-model="selectedGrade"
            :items="standards"
            :rules="[(v) => !!v || 'Grade is required']"
            required
            item-text="name"
            item-value="id"
            class="select-grade-dropdown"
            @change="selectGrade"
          />
          <v-label>{{ AppTexts.SELECT_PRODUCT }}</v-label>
          <v-select
            v-model="selectedProduct"
            :items="products"
            :rules="[(v) => !!v || 'Product is required']"
            required
            item-text="name"
            item-value="id"
            class="select-grade-dropdown"
            @change="selectProduct"
          />
          <v-layout row justify-center>
            <v-btn
              class="view-block-map-btn"
              :disabled="disbleViewBlockMapButton"
              color="primary"
              @click="submitHandler()"
              >View Block Map</v-btn
            >
          </v-layout>
        </v-form>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fetchAllBoardsSubjectsStandards } from '@/apiQueries/subjectSelectionQuery';
import AppTexts from '@/constants/AppTexts';

import { sortByFnForArrayOfObjectsByStringProperty } from '@/functions/array';

export default {
  name: 'subjectSelection',
  apollo: {
    globalBoardsSubjectsStandards: {
      query: fetchAllBoardsSubjectsStandards,
      loadingKey: 'loading',
      update: (data) => data
    }
  },
  computed: {
    subjects() {
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.subjects || [],
        'name'
      );
    },
    standards() {
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.standards || [],
        'name'
      );
    },
    boards() {
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.boards || [],
        'name'
      );
    },
    products() {
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.products || [],
        'name'
      );
    },
    ...mapState({
      selectedBoard: (state) => state.selectedBoard,
      selectedSubject: (state) => state.selectedSubject,
      selectedGrade: (state) => state.selectedGrade,
      selectedProduct: (state) => state.selectedProduct
    }),
    disbleViewBlockMapButton() {
      return !(
        this.$store.state.selectedBoard.name &&
        this.$store.state.selectedSubject.name &&
        this.$store.state.selectedGrade.name
      );
    }
  },
  data: () => {
    return {
      AppTexts,
      globalBoardsSubjectsStandards: {
        subjects: [],
        standards: [],
        boards: [],
        products: []
      }
    };
  },
  methods: {
    selectBoard(boardId) {
      this.$store.dispatch(
        'setSelectedBoard',
        this.boards.find((board) => board.id == boardId)
      );
    },
    selectSubject(subjectId) {
      this.$store.dispatch(
        'setSelectedSubject',
        this.subjects.find((subject) => subject.id == subjectId)
      );
    },
    selectGrade(gradeId) {
      this.$store.dispatch(
        'setSelectedGrade',
        this.standards.find((grade) => grade.id == gradeId)
      );
    },
    selectProduct(productId) {
      this.$store.dispatch(
        'setSelectedProduct',
        this.products.find((product) => product.id == productId)
      );
    },
    submitHandler() {
      this.$router.push({
        name: 'blockMapping',
        query: {
          boardName: this.selectedBoard.name,
          subjectId: this.selectedSubject.id,
          gradeId: this.selectedGrade.id,
          subjectName: this.selectedSubject.name,
          gradeName: this.selectedGrade.name,
          productId: this.selectedProduct.id,
          productName: this.selectedProduct.name
        }
      });
    }
  }
};
</script>
