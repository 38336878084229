const mutations = {
  setTrainingsDataOnApiInProgress: (state, payload) => {
    console.log('MUTATION#setTrainingsDataOnApiInProgress#START');

    if (payload) {
      console.log('MUTATION#setTrainingsDataOnApiInProgress#UPDATE');
      state.trainingListApiResponse.status = 'in_progress';
      state.trainingListApiResponse.message = payload.message;
      // Don't update data so that UI will be showing list
      // state.trainingListApiResponse.data    = payload.data;
    }

    console.log('MUTATION#setTrainingsDataOnApiInProgress#END');
  },
  setTrainingsDataOnApiSuccess: (state, payload) => {
    console.log('MUTATION#setTrainingsDataOnApiSuccess#START');
    console.log('MUTATION#payload.data', payload.data);

    if (payload) {
      console.log('MUTATION#setTrainingsDataOnApiSuccess#UPDATE');
      state.trainingListApiResponse.status = 'completed';
      state.trainingListApiResponse.message = payload.message;
      state.trainingListApiResponse.data = payload.data.trainings;
    }

    console.log('MUTATION#setTrainingsDataOnApiSuccess#END');
  },
  setTrainingsDataOnApiFailure: (state, payload) => {
    console.log('MUTATION#setTrainingsDataOnApiFailure#START');

    if (payload) {
      console.log('MUTATION#setTrainingsDataOnApiFailure#UPDATE');
      state.trainingListApiResponse.status = 'error';
      state.trainingListApiResponse.message = payload.message;
      // state.trainingListApiResponse.data    = payload.data;
    }

    console.log('MUTATION#setTrainingsDataOnApiFailure#END');
  },
  setTrainingsDataOnApiError: (state, payload) => {
    console.log('MUTATION#setTrainingsDataOnApiError#START');

    if (payload) {
      console.log('MUTATION#setTrainingsDataOnApiError#UPDATE');
      state.trainingListApiResponse.status = 'error';
      state.trainingListApiResponse.message = payload.message;
      // state.trainingListApiResponse.data    = payload.data;
    }

    console.log('MUTATION#setTrainingsDataOnApiError#END');
  },
  setCreateOrUpdateTrainingApiStatus: (state, payload) => {
    console.log('MUTATION#setsetCreateOrUpdateTrainingApiStatus#START');
    state.createOrUpdateTrainingApi.status = payload.state;
    state.createOrUpdateTrainingApi.message = payload.message;
    state.createOrUpdateTrainingApi.data = payload.data;
  },
  setTrainingDetails(state, payload) {
    state.selectedTraining = payload;
  },
  setFetchQuestionApiStatusToState: (state, payload) => {
    console.log('MUTATION#setFetchQuestionApiStatusToState#START');
    state.trainingQuestionAuthoringApiResponse.status = payload.status;
    state.trainingQuestionAuthoringApiResponse.message = payload.message;
    state.trainingQuestionAuthoringApiResponse.data = payload.data;
    console.log('MUTATION#setFetchQuestionApiStatusToState#END');
  },
  setFetchTrainingDetailsApiStatusToState: (state, payload) => {
    console.log('QUERY#setFetchTrainingDetailsApiStatusToState#START');
    state.trainingDetailsByTrainingIdApiResponse.status = payload.status;
    state.trainingDetailsByTrainingIdApiResponse.message = payload.message;
    state.trainingDetailsByTrainingIdApiResponse.data = payload.data;
    console.log('QUERY#setFetchTrainingDetailsApiStatusToState#END');
  },
};

export default mutations;
