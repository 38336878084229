<template>
  <v-form ref="actionSectionForm" class="section-title-cnr">
    <XSRightSwitch
      :primaryDs="COMPONENT_TO_DS_MAP.STANDARD_LP_ACTION"
      :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
      subPath="action"
      :initialActive="isSlideLayoutEnabled('action.instructions.0', lessonPlanData)"
      :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
      :ignoreKeys="['types', 'summary', 'time', 'type', 'summaryData']"
    >
      <template #true-slot>
        <MultiSlideLayout
          :isNotReadOnly="isNotReadOnly"
          subPath="action.instructions"
          :lessonPlanData="lessonPlanData"
          :isAssetUploadRequired="isAssetUploadRequired"
          :canAppproveAsset="canAppproveAsset"
          :signedUrls="signedUrls"
          :canShowDiffIcon="canShowDiffIcon"
          :showImageBrief="showImageBrief"
          :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
        />
      </template>
      <template #false-slot>
        <Step
          :items="lessonPlanData.action.instructions"
          :canShowStepNumber="true"
          :canShowInstructionBreakCheckbox="true"
          :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
          :canShowAddNewStepButton="true"
          :addNewStepHandler="addActionStep"
          :hasSubStep="true"
          :allowDeleteStep="true"
          :deleteStepHandler="deleteActionStep"
          :allowDeleteSubStep="true"
          :deleteSubStepHandler="deleteActionSubStep"
          :descriptionPlaceholder="AppTexts.STEP_DESCRIPTION"
          :maxNoOfSteps="schema.maxNoOfSteps"
          :maxNoOfSubStepsInStep="schema.maxNoOfSubStepsInStep"
          :maxSumOfStepsAndSubSteps="schema.maxSumOfStepsAndSubSteps"
          :supportAssets="true"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :getPreviewUrl="getActionInstructionAssetPreviewUrl"
          stepAssetIdentifier="action.instructions"
          :actionSignedUrls="actionSignedUrls"
          :canShowDiffIcon="canShowDiffIcon"
          stepDiffIdentifier="action.instructions[<stepIndex>].description"
          :isStepDescriptionChanged="isStepDescriptionChanged"
          subStepDiffIdentifier="action.instructions[<stepIndex>].children[<subStepIndex>].description"
          :isSubStepDescriptionChanged="isSubStepDescriptionChanged"
          assetBriefDiffIdentifier="action.instructions[<stepIndex>].assets[<assetIndex>].brief"
          :isAssetBriefChanged="isAssetBriefChanged"
          assetImageDiffIdentifierPrefix="action.instructions[<stepIndex>].assets[<assetIndex>]"
          :isAssetImageChanged="isAssetImageChanged"
          :showDividerBetweenSteps="true"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :addNewSubStepHandler="addActionSubStep"
          :canFinalizeAsset="true"
          :canAppproveAsset="canAppproveAsset"
          instructionTeacherTipDiffIdentifier="action.instructions[<stepIndex>].teacher_tip"
          :canShowInstructionTeacherTip="true"
          :canShowLastInstructionTeacherTip="false"
          :toggleTeacherTipFieldHandler="toggleActionInstructionTeacherTipField"
          :stepDescriptionMaxLength="schema.stepDescriptionMaxLength"
          :subStepDescriptionMaxLength="schema.subStepDescriptionMaxLength"
          :stepTeacherTipMaxLength="schema.stepTeacherTipMaxLength"
          @addAudioWord="$emit('addAudioWordToInstruction', $event)"
          @removeAudioWord="$emit('removeAudioWordFromInstruction', $event)"
          :instructionsDiff="actionDiff.instructions"
          :supportAudioWord="schema.showInstructionAudioWord"
          :maxAudioWordLength="schema.maxAudioWordLength"
          :maxAudioWordNameLength="schema.maxAudioWordNameLength"
          :maxAudioWordDescriptionLength="schema.maxAudioWordDescriptionLength"
          diffIdentifierPrefix="action.instructions[<stepIndex>]"
        />
        <div class="pad-Ts mgn-Bs">
          <XSDisplayCard :title="AppTexts.TEACHER_TIP">
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isActionTeacherTipChanged()"
              fieldIdentifier="action.teacher_tip"
              :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
              v-model="lessonPlanData.action.teacher_tip"
              :isRequired="false"
              :value="lessonPlanData.action.teacher_tip"
              :counter="schema.teacherTipMaxLength"
              :readonly="!isNotReadOnly"
              :includeOnlyToolBarActions="BASIC_TOOLS"
              class="mgn-Bm"
            />
          </XSDisplayCard>
        </div>
        <RTEWithMultipleAssetUpload
          v-if="lessonPlanData.action.homework"
          :data="lessonPlanData.action.homework"
          :isReadOnly="!isNotReadOnly"
          :isAssetUploadRequired="isAssetUploadRequired"
          :canShowDiffIcon="canShowDiffIcon"
          placeholder="Description"
          :diffChanges="(actionDiff || {}).homework || {}"
          :assetSignedUrlsHash="(actionSignedUrls.homework || {}).assets || []"
          :showImageBrief="showImageBrief"
          :fileValidators="fileValidators"
          :assetDiffIdentifierPrefix="`action.homework.assets[<assetIndex>]`"
          :descriptionDiffIdentifier="`action.homework.description`"
          :sectionIdentifier="`action.homework`"
          :requireDeleteAsset="true"
          :requireAddAsset="true"
          title="Homework"
          :stepIndex="NaN"
          :isAssetMandatory="isAssetUploadRequired"
          @deleteBlock="() => {}"
          :requireDeleteBlock="false"
          :descriptionMaxLength="5000"
          :canAppproveAsset="canAppproveAsset"
          :canFinalizeAsset="true"
          :returnEmptyStringInsteadOfDefaultJsonInDescription="true"
          :showValidationError="false"
        />
      </template>
    </XSRightSwitch>
  </v-form>
</template>

<script>
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import Step from '@/components/inputs/Step';
import AppTexts from '@/constants/AppTexts';
import AssetTypes from '@/constants/AssetTypes';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { ACTION_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import { mapActions } from 'vuex';
import RTEWithMultipleAssetUpload from '@/components/inputs/RTEWithMultipleAssetUpload.vue';
import XSRightSwitch from '@/components/lessonPlanTemplate/commonLpComponents/XSRightSwitch.vue';
import { COMPONENT_TO_DS_MAP } from '@/constants/componentDataStructure.js';
import { isSlideLayoutEnabled } from '@/utils/slideLayoutHelpers.js';
import MultiSlideLayout from '@/components/lessonPlanTemplate/commonLpComponents/MultiSlideLayout';

export default {
  name: 'ActionSection',
  components: {
    Step,
    RichTextEditor,
    XSDisplayCard,
    RTEWithMultipleAssetUpload,
    XSRightSwitch,
    MultiSlideLayout,
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ACTION_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      BASIC_TOOLS,
      COMPONENT_TO_DS_MAP,
    };
  },
  methods: {
    ...mapActions([
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'toggleActionInstructionTeacherTipField',
    ]),
    isSlideLayoutEnabled,
    getActionInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedBriefUrl: '',
        assetSignedUrl: '',
        loadingStatus: 'not_started',
      };
      if (this.actionSignedUrls) {
        let instruction = this.actionSignedUrls.instructions[stepIndex];
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.assetSignedUrl = asset.assetSignedUrl;
            }
            previewUrl.assetSignedBriefUrl = (asset.brief || {}).assetSignedUrl;
            previewUrl.loadingStatus = asset.loadingStatus;
          }
        }
      }

      return previewUrl;
    },
    isStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.instructions[stepIndex].description.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isSubStepDescriptionChanged(stepIndex, subStepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.actionDiff.instructions[stepIndex].children[subStepIndex].description.isChanged ==
            true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetBriefChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.assets[assetIndex].brief.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetImageChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          if (this.isAssetUploadRequired) {
            return (
              this.actionDiff.instructions[stepIndex].assets[assetIndex].assetId.isChanged == true
            );
          } else {
            return (
              this.actionDiff.instructions[stepIndex].assets[assetIndex].briefAssetId.isChanged ==
              true
            );
          }
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isActionTeacherTipChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.teacher_tip.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getFileNameFromUrl,
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    addActionSubStep: {
      type: Function,
      required: true,
    },
    deleteActionStep: {
      type: Function,
      required: true,
    },
    deleteActionSubStep: {
      type: Function,
      required: true,
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
    addActionStep: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionSignedUrls: {
      type: Object,
      required: true,
    },
    actionDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canAppproveAsset: { type: Boolean, required: true },
    schema: {
      type: Object,
      required: true,
    },
    signedUrls: {
      type: Object,
    },
  },
};
</script>
