var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',{staticClass:"mgn-Lxs"},[_c('v-col',{attrs:{"md":"auto"}},[_vm._v(_vm._s(_vm.sectionPrefixText()))]),_c('v-col',{staticClass:"small-text-field",attrs:{"cols":"3"}},[_c('TextInput',{attrs:{"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getSectionIsChanged('description'),"fieldIdentifier":("application." + _vm.section + ".description"),"placeholder":_vm.isClassWork ? _vm.AppTexts.WORKSHEET_NUMBER : _vm.AppTexts.QUESTION_NUMBER,"rules":[function (v) { return !!v || _vm.Errors.REQUIRED_FIELD; }],"readonly":!_vm.isNotReadOnly,"counter":220},model:{value:(_vm.data[_vm.section].description),callback:function ($$v) {_vm.$set(_vm.data[_vm.section], "description", $$v)},expression:"data[section].description"}})],1),_c('v-col',[_vm._v(_vm._s(_vm.sectionSuffixText()))])],1),_c('v-row',{staticClass:"mgn-Ts mgn-Lxs"},[_c('v-col',[_c('p',[_vm._v(_vm._s(_vm.staticTexts2))])])],1),_c('v-row',[_c('v-col',{staticClass:"pad-Rm",attrs:{"cols":"6"}},[_c('FileInput',{attrs:{"image_url":_vm.isAssetUploadRequired ? _vm.assetId : '',"image_url_brief":_vm.briefurlName,"previewUrl":_vm.isAssetUploadRequired ? _vm.previewUrls.url : '',"previewUrlBrief":_vm.previewUrls.briefUrl,"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getSectionIsChanged(_vm.imageKey.url),"diffIdentifier":("application." + _vm.section + "." + (_vm.imageKey.url)),"label":_vm.label,"loadingStatus":_vm.uploadingStatus[_vm.section] == _vm.FILE_UPLOADING_STATUS.IN_PROGRESS,"fileChangeHandler":function (file) { return _vm.importApplicationSectionImage(file, _vm.getImageKey); },"showImageBrief":_vm.showImageBrief,"isAssetUploadRequired":_vm.isAssetUploadRequired,"fileAcceptType":_vm.acceptableFileType,"isNotReadOnly":_vm.isNotReadOnly,"fileValidators":_vm.fileValidators,"isAssetApproved":_vm.isClassWork
            ? _vm.data[_vm.section]['assetApproved']
            : _vm.data[_vm.section]['question']['assetApproved'],"canAppproveAsset":_vm.canApproveAsset,"canFinalizeAsset":true},on:{"addBriefToAsset":function (previewUrl, briefUrl, isAssetApproved) { return _vm.copySectionImage(previewUrl, briefUrl, isAssetApproved, _vm.getImageKey); }}}),(_vm.showImageBrief)?_c('RichTextEditor',{staticClass:"text-field-text",staticStyle:{"margin":"25px 0px 0px 0px"},attrs:{"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getSectionIsChanged(_vm.imageKey.url),"fieldIdentifier":("application." + _vm.section + "." + (_vm.isClassWork ? 'brief' : 'question_image_brief')),"placeholder":_vm.isClassWork ? _vm.AppTexts.CLASSWORK_IMAGE_BRIEF : _vm.AppTexts.HOMEWORK_QUESTION_FILE_BRIEF,"showValidationError":true,"counter":3000,"readonly":!_vm.isNotReadOnly,"includeOnlyToolBarActions":_vm.BASIC_TOOLS},model:{value:(_vm.sectionBrief),callback:function ($$v) {_vm.sectionBrief=$$v},expression:"sectionBrief"}}):_vm._e()],1),(!_vm.isClassWork)?_c('v-col',{staticClass:"pad-Lm",attrs:{"cols":"6"}},[_c('FileInput',{attrs:{"image_url":_vm.isAssetUploadRequired ? _vm.getValueFromPath(_vm.data, 'homework.question_answer.assetId') : '',"image_url_brief":_vm.getValueFromPath(_vm.data, 'homework.question_answer.brief.assetId'),"previewUrl":_vm.isAssetUploadRequired
            ? _vm.getValueFromPath(_vm.sectionSignedUrls, 'question_answer.assetSignedUrl')
            : '',"previewUrlBrief":_vm.getValueFromPath(_vm.sectionSignedUrls, 'question_answer.brief.assetSignedUrl'),"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getSectionIsChanged(_vm.imageKey.answerKey),"diffIdentifier":'application.homework.' + _vm.imageKey.answerKey,"label":_vm.AppTexts.SELECT_HOMEWORK_ANSWER_FILE,"loadingStatus":_vm.uploadingStatus.homework_answer == _vm.FILE_UPLOADING_STATUS.IN_PROGRESS,"fileChangeHandler":function (file) { return _vm.importApplicationSectionImage(file, _vm.AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE); },"showImageBrief":_vm.showImageBrief,"isAssetUploadRequired":_vm.isAssetUploadRequired,"isRequired":false,"fileAcceptType":_vm.acceptableFileType,"isNotReadOnly":_vm.isNotReadOnly,"fileValidators":_vm.fileValidators,"isAssetApproved":_vm.getValueFromPath(_vm.data, 'homework.question_answer.assetApproved', false),"canAppproveAsset":_vm.canApproveAsset,"canFinalizeAsset":true},on:{"addBriefToAsset":function (previewUrl, briefUrl, isAssetApproved) { return _vm.copySectionImage(
              previewUrl,
              briefUrl,
              isAssetApproved,
              _vm.AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE
            ); }}}),(_vm.showImageBrief)?_c('RichTextEditor',{staticClass:"text-field-text",staticStyle:{"margin":"25px 0px 0px 0px"},attrs:{"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getSectionIsChanged('question_answer.brief.text'),"fieldIdentifier":"application.homework.question_answer.brief.text","placeholder":_vm.AppTexts.HOMEWORK_ANSWER_FILE_BRIEF,"showValidationError":true,"counter":3000,"readonly":!_vm.isNotReadOnly,"includeOnlyToolBarActions":_vm.BASIC_TOOLS},model:{value:(_vm.data.homework.question_answer.brief.text),callback:function ($$v) {_vm.$set(_vm.data.homework.question_answer.brief, "text", $$v)},expression:"data.homework.question_answer.brief.text"}}):_vm._e()],1):_vm._e()],1),(!_vm.isClassWork)?_c('div',{staticClass:"mgn-Bs"},[_vm._v(_vm._s(_vm.greetingText))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }