const AUTHORING1_STAGE = 'authoring1Stage';
const REVIEW1_STAGE = 'review1Stage';
const REVIEW2_STAGE = 'review2Stage';
const AUTHORING2_STAGE = 'authoring2Stage';
const ACADEMIC_SIGN_OFF_STAGE = 'academicSignOffStage';
const EDITING_STAGE = 'editingStage';
const VISUALIZING_STAGE = 'visualizingStage';
const ASSET_UPLOAD_STAGE = 'assetUploadStage';
const ASSET_AUTHORIZING_STAGE = 'assetAuthorizingStage';
const PROOF_READING_STAGE = 'proofReadingStage';
const COMPLETED_STAGE = 'completedStage';

const O_LESSON_TYPE = 'oLesson';
const QUESTIONS_TYPE = 'questions';
const MICRO_TEXT_TYPE = 'microText';
const WORKSHEET_TYPE = 'worksheet';
const CONTENT_BOOK_TYPE = 'contentBook';
const VIDEOS_TYPE = 'videos';

const MICRO_TOPIC_PROJECT_TYPES = [
  O_LESSON_TYPE,
  QUESTIONS_TYPE,
  MICRO_TEXT_TYPE,
  WORKSHEET_TYPE,
  CONTENT_BOOK_TYPE,
  VIDEOS_TYPE,
];

const PROJECT_TYPES_HASH = {
  O_LESSON: O_LESSON_TYPE,
  QUESTIONS: QUESTIONS_TYPE,
  MICROTEXT: MICRO_TEXT_TYPE,
  WORKSHEET: WORKSHEET_TYPE,
  CONTENTBOOK: CONTENT_BOOK_TYPE,
  VIDEOS: VIDEOS_TYPE,
};

const IN_PROGRESS_ITEM_STATUS = 'in_progress';
const NOT_SATRTED_ITEM_STATUS = 'not_started';

const MICROTOPIC_STATUSES = [
  { text: 'Not Started', value: 'not_started' },
  { text: 'In Progress', value: 'in_progress' },
  { text: 'Completed', value: 'completed' },
];

const PROJECT_LIST_FILTERS = {
  DEFAULT: { text: 'All Projects', value: 'All Projects' },
  PERSONALIZED: { text: 'Assigned to Me', value: 'Assigned to Me' },
};

const MANDATORY_STAGES = ['Asset Uploader', 'Proof Reader'];

const SKIP_STAGE_USER_ID_VALUE = 'skip';

const MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING = {
  [AUTHORING1_STAGE]: 'AU1',
  [REVIEW1_STAGE]: 'R1',
  [REVIEW2_STAGE]: 'R2',
  [AUTHORING2_STAGE]: 'AU2',
  [ACADEMIC_SIGN_OFF_STAGE]: 'ASO',
  [EDITING_STAGE]: 'ED',
  [VISUALIZING_STAGE]: 'VIZ',
  [ASSET_UPLOAD_STAGE]: 'AS',
  [ASSET_AUTHORIZING_STAGE]: 'AA',
  [PROOF_READING_STAGE]: 'PR',
  [COMPLETED_STAGE]: 'COM',
};

const PROJECT_CREATE_MANDATORY_STAGES = ['Asset Uploader', 'Asset Authorizer', 'Proof Reader'];

module.exports = {
  O_LESSON_TYPE,
  QUESTIONS_TYPE,
  MICRO_TEXT_TYPE,
  WORKSHEET_TYPE,
  CONTENT_BOOK_TYPE,
  VIDEOS_TYPE,
  MICRO_TOPIC_PROJECT_TYPES,
  IN_PROGRESS_ITEM_STATUS,
  NOT_SATRTED_ITEM_STATUS,
  PROJECT_LIST_FILTERS,
  MICROTOPIC_STATUSES,
  MANDATORY_STAGES,
  PROJECT_TYPES_HASH,
  SKIP_STAGE_USER_ID_VALUE,
  MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING,
  ASSET_AUTHORIZING_STAGE,
  PROJECT_CREATE_MANDATORY_STAGES,
  COMPLETED_STAGE,
  PROOF_READING_STAGE,
  ASSET_UPLOAD_STAGE,
};
