<template>
  <div class="image-preview-wrapper">
    <div>
      <v-dialog
        @keydown.esc="dialog = false"
        v-model="dialog"
        :content-class="'after-asset-upload-test'"
        :width="getDialogWidth"
      >
        <template v-slot:activator="{ on }">
          <div>
            <div v-on="on">
              <v-avatar class="profile-avatar" color="grey" tile>
                <v-img
                  contain
                  v-if="previewFileFormat(imageUrl[+isAssetUploadRequired]) == 'image'"
                  :src="imageUrl[+isAssetUploadRequired]"
                  :style="ApproveAssetAndEmitResult ? 'max-width: 450px;' : ''"
                />
                <v-img
                  contain
                  v-else-if="previewFileFormat(imageUrl[+isAssetUploadRequired]) == 'video'"
                  src="@/assets/video-arrow-overlay.png"
                />
                <img
                  contain
                  v-else-if="previewFileFormat(imageUrl[+isAssetUploadRequired]) == 'audio'"
                  src="@/assets/audio.svg"
                  style="width: 50%;"
                />
                <v-img
                  contain
                  v-else-if="previewFileFormat(imageUrl[+isAssetUploadRequired]) == 'doc'"
                  src="@/assets/docx-image.png"
                />
                <v-img
                  contain
                  v-else-if="previewFileFormat(imageUrl[+isAssetUploadRequired]) == 'pdf'"
                  src="@/assets/pdf-overlay.png"
                />
              </v-avatar>
            </div>
            <span class="file-name-label-wrapper">
              <div class="filename-display">
                <div class="file-name-header">
                  {{ getFileNameFromUrl(imageUrl[+isAssetUploadRequired]) }}
                </div>
              </div>
              <v-btn
                style="float: right;"
                icon
                @click="fileChangeHandler(null)"
                v-if="isNotReadOnly"
              >
                <img height="16" width="16" src="@/assets/close-button-white.svg" />
              </v-btn>
            </span>
            <DiffButton
              class="asset-diff-button"
              v-if="canShowDiffIcon"
              :fieldIdentifier="diffIdentifier"
              :isDiffChanged="isDiffChanged"
              :canShowDiffIcon="canShowDiffIcon"
            />
            <!-- <v-btn @click:stop="handleAssetReplaceAction" class="asset-replace-button">REPLACE</v-btn> -->
          </div>
        </template>
        <div
          :class="
            isAssetUploadRequired == true
              ? 'lpForm-asset-preview-container-brief'
              : 'lpForm-asset-preview-container-before-asset-uploader'
          "
        >
          <v-card v-for="(assetUrl, index) in imageUrl" :key="index">
            <v-row class="preview-container">
              <span class="file-name-label">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" v-if="index == 0 && assetUrl" class="filename">
                      {{ 'Brief Asset ->' + getFileNameFromUrl(assetUrl) }}
                    </div>
                    <div v-bind="attrs" v-on="on" v-else class="filename">
                      {{ getFileNameFromUrl(assetUrl) }}
                    </div>
                  </template>
                  <span> {{ getFileNameFromUrl(assetUrl) }}</span>
                </v-tooltip>
              </span>
              <v-col
                cols="12"
                sm="6"
                md="12"
                v-if="assetUrl && previewFileFormat(assetUrl) == 'video'"
              >
                <video-player
                  :style="isAssetUploadRequired ? 'margin-left: 5%;' : 'margin-left: 30%;'"
                  class="preview-video-player"
                  ref="videoPlayer"
                  :options="{
                    sources: [{ type: 'video/mp4', src: assetUrl }],
                    poster: videoBackgroundPoster,
                    language: 'en',
                  }"
                  :playsinline="true"
                  customEventName="customstatechangedeventname"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="12"
                v-if="assetUrl && previewFileFormat(assetUrl) == 'audio'"
              >
                <div
                  :style="`position: absolute; top: 30%; ${
                    isAssetUploadRequired ? 'margin-left: 140px;' : 'margin-left: 420px;'
                  }`"
                >
                  <audio controls>
                    <source :src="assetUrl" type="audio/mpeg" />
                  </audio>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="12" v-else class="asset-wrapper">
                <v-col v-if="assetUrl">
                  <v-img
                    contain
                    v-if="previewFileFormat(assetUrl) == 'image'"
                    :src="assetUrl"
                    height="450px"
                  ></v-img>
                  <v-img
                    contain
                    v-else-if="previewFileFormat(assetUrl) == 'doc'"
                    src="@/assets/docx-image.png"
                    height="300px"
                  ></v-img>
                  <PdfViewer
                    :key="`pdfViewer${index}`"
                    v-else-if="previewFileFormat(assetUrl) == 'pdf'"
                    :src="assetUrl"
                    height="300px"
                  />
                </v-col>
                <v-col v-else md="8" style="display: flex; justify-content: center;">
                  <v-img src="@/assets/na.svg" height="450px" contain></v-img>
                </v-col>
              </v-col>
              <div
                class="align-download-center"
                :style="previewFileFormat(assetUrl) == 'pdf' ? 'margin-top: 270px;' : ''"
              >
                <a style="text-align: center;" v-if="assetUrl" :href="assetUrl" download
                  >Download</a
                >
                <v-btn
                  class="ml-5"
                  v-if="assetUrl && canAppproveAsset && index == 1"
                  :disabled="isAssetApproved"
                  @click="assetApproval"
                  >{{ isAssetApproved ? 'Approved' : 'Approve Asset' }}</v-btn
                >
              </div>
            </v-row>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { getFileNameFromUrl, getFileTypeFromUrl } from '@/functions/global';
import videoBackgroundPoster from '@/assets/video-arrow-overlay.png';
import AppTexts from '@/constants/AppTexts';
import { LP_FORM_ROUTES } from '@/constants/common';
import DiffButton from '@/components/ContentDiff/DiffButton';
import { mapMutations } from 'vuex';
import PdfViewer from '@/components/common/PdfViewer';
export default {
  name: 'AssetPreviewDialog',
  components: {
    DiffButton,
    PdfViewer,
  },
  data() {
    return {
      videoBackgroundPoster,
      dialog: false,
      AppTexts,
      playerOptions: {
        // videojs options
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],

        poster: '/static/images/author.jpg',
      },
    };
  },
  computed: {
    getDialogWidth() {
      let img = this.imageUrl.find((_i) => this.previewFileFormat(_i) == 'pdf');
      if (img) {
        return '1500px';
      }
      return '';
    },
  },
  methods: {
    ...mapMutations(['SET_CAN_APPROVE_ASSET_TRUE', 'APPROVE_LESSON_PLAN_ASSET']),
    getFileNameFromUrl,
    getFileTypeFromUrl,
    previewFileFormat(file) {
      return this.getFileTypeFromUrl(file);
    },
    assetApproval() {
      if (!this.ApproveAssetAndEmitResult) {
        const routeName = this.$route.name;

        if (routeName == 'ContentEntryProjectForm') {
          this.SET_CAN_APPROVE_ASSET_TRUE(this.diffIdentifier);
        } else if (LP_FORM_ROUTES.includes(routeName)) {
          this.APPROVE_LESSON_PLAN_ASSET(this.diffIdentifier);
        }
      }
      this.$emit('assetApprovedByUser', true);
    },
  },
  props: {
    imageUrl: {
      type: Array,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    handleAssetReplaceAction: {
      type: Function,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDiffChanged: {
      type: Boolean,
      required: false,
      default: true,
    },
    diffIdentifier: {
      type: String,
      required: false,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: true,
      default: false,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    isAssetApproved: {
      type: Boolean,
      default: false,
    },
    ApproveAssetAndEmitResult: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>
.image-preview-wrapper {
  position: relative;
  border: 1px solid #999;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
}
.profile-avatar {
  height: 250px !important;
  width: 100% !important;
}
.file-name-label-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  background: rgba(70, 70, 70, 0.7);
  align-items: center;
  display: flex;
  width: 100%;
}
.filename-display {
  font-size: 16px;
  font-weight: 500;
  color: white;
  width: 95%;
}
.file-name-label {
  color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
}
.file-name-header {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filename {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding-left: 10px;
  display: inline-block;
}
.preview-icons {
  max-height: 250px;
}
</style>
<style lang="scss">
.preview-video-player .video-js {
  width: 500px !important;
  height: 300px !important;
}
.preview-video-player .vjs-big-play-button {
  visibility: hidden;
}
.asset-replace-button {
  position: absolute;
  bottom: 18px;
  right: 35px;
  border-radius: 2px;
  width: 90px;
  background-color: #f5f5f5 !important;
  color: #177eee !important;
  font-size: 12px !important;
  font-weight: 500;
}
.asset-diff-button {
  position: absolute;
  bottom: 18px;
  right: 18px;
  border-radius: 2px;
  width: 35px;
  background-color: #f5f5f5 !important;
  color: #177eee !important;
  font-size: 12px !important;
  font-weight: 500;
}
.align-download-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 170px 0 0 0;
  z-index: 2;
}
.asset-wrapper {
  max-height: 300px;
  display: flex;
  justify-content: center;
}
.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lpForm-asset-preview-container-brief {
  display: flex;
  .v-card {
    width: 50%;
    margin: 0 5px;
    position: relative;
  }
}
.lpForm-asset-preview-container-before-asset-uploader {
  display: flex;
  .v-card {
    width: 100%;
    margin: 0 5px;
    position: relative;
  }
}
.after-asset-upload-test {
  width: 90vw;
  height: 90vh;
  margin-top: 90px;
  .lpForm-asset-preview-container-brief {
    height: 100% !important;
  }
}
</style>
