<template>
  <v-form ref="fileInputForm">
    <div
      style="align-items: baseline;"
      v-for="(item, index) in items"
      :key="`lessonResouce${refreshKey}${index}`"
      :v-bind="index"
      class="file-input-row"
    >
      <v-row dense>
        <v-col class="pad-Rm">
          <RichTextEditor
            :key="index + 'name'"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isResourceTextChanged(index)"
            :fieldIdentifier="subPath + '[' + index + '].text'"
            :placeholder="placeholder"
            class="text-field-text"
            v-model="item.text"
            :showValidationError="true"
            :counter="maxNameLength"
            v-if="item.text != null && item.text != undefined"
            :readonly="!isNotReadOnly"
            :includeOnlyToolBarActions="resourceToolBarActions"
          />
        </v-col>
        <v-col class="pad-Lm" cols="5">
          <FileInput
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isResourceImageChanged(index)"
            :diffIdentifier="diffIdentifier(index)"
            :image_url="isAssetUploadRequired ? item.assetId : ''"
            :image_url_brief="item.brief.assetId"
            :previewUrl="
              isAssetUploadRequired ? (resourcesSignedUrls[index] || {}).assetSignedUrl : ''
            "
            :previewUrlBrief="resourcesSignedUrls[index].brief.assetSignedUrl"
            :fileAcceptType="acceptableFileType"
            :label="label"
            :loadingStatus="
              resourcesSignedUrls[index].loadingStatus == FILE_UPLOADING_STATUS.IN_PROGRESS
            "
            :isRequired="isRequired"
            :isAssetUploadRequired="isAssetUploadRequired"
            :fileChangeHandler="
              (file) =>
                importFile(file, index, isAssetUploadRequired ? item.assetId : item.brief.assetId)
            "
            :isNotReadOnly="isNotReadOnly"
            :fileValidators="fileValidators"
            :canFinalizeAsset="canFinalizeAsset"
            :canAppproveAsset="canAppproveAsset"
            :isAssetApproved="item.assetApproved"
            @addBriefToAsset="
              (previewUrl, briefUrl, isAssetApproved) =>
                copyFile(previewUrl, briefUrl, index, isAssetApproved)
            "
          />
          <RichTextEditor
            :key="index + '_brief'"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isResourceBriefChanged(index)"
            :fieldIdentifier="subPath + '[' + index + '].brief'"
            :placeholder="AppTexts.RESOURCE_IMAGE_BRIEF"
            class="text-field-text mgn-Ts"
            v-model="(item.brief || {}).text"
            :showValidationError="true"
            customClass="mgn-Tm"
            :counter="3000"
            :readonly="!isNotReadOnly"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </v-col>
        <v-col cols="1">
          <v-btn
            icon
            @click="deleteCurrentResource(index)"
            title="Remove Resource"
            v-if="isNotReadOnly && canDeleteResource()"
          >
            <img height="16" width="16" src="@/assets/close.svg" />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <p class="error-message">{{ error }}</p>
    <v-btn
      class="xs-accent-button"
      v-show="withAdd"
      @click="addResource(resourseMaxLength)"
      :class="!canAddResouce() ? 'disable-btn-click-event' : ''"
    >
      {{ AppTexts.ADD_RESOURCE }}
    </v-btn>
    <span class="mgn-Lm remaining-step-warning-text" v-if="canAddResouce()">
      {{ getStepAdditionValidationText(resourseMaxLength - items.length, 'resource') }}
    </span>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import Errors from '@/constants/Errors';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import { getFileNameFromUrl } from '@/functions/global';
import FileInput from '@/components/inputs/FileInput';
import { getStepAdditionValidationText } from '@/utils';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';

export default {
  name: 'Resources',
  components: {
    FileInput,
    RichTextEditor,
  },
  data() {
    return {
      Errors,
      AppTexts,
      FILE_UPLOADING_STATUS,
      imageKey: this.isAssetUploadRequired ? 'assetId' : 'briefAssetId',
      BASIC_TOOLS,
      refreshKey: 0,
    };
  },
  methods: {
    ...mapActions([
      'importSingleResourceAssetAction',
      'deleteSingleResourceAssetAction',
      'copySingleResourceAssetAction',
    ]),
    getStepAdditionValidationText,
    getFileNameFromUrl,
    diffIdentifier(index) {
      return this.identifiedSubPath(index) + '.' + this.imageKey;
    },
    identifiedSubPath(index) {
      return this.subPath + '[' + index + ']';
    },
    importFile(file, index, assetId) {
      if (file) {
        this.importSingleResourceAssetAction({
          payload: file,
          resourceIndex: index,
          identifier: this.identifiedSubPath(index),
          subPath: this.identifiedSubPath(index),
        });
      } else {
        this.deleteSingleResourceAssetAction({
          assetId,
          resourceIndex: index,
          identifier: this.identifiedSubPath(index),
          subPath: this.identifiedSubPath(index),
        });
      }
    },
    copyFile(signedUrl, briefAssetId, index, isAssetApproved) {
      this.copySingleResourceAssetAction({
        signedUrl,
        briefAssetId,
        index,
        isAssetApproved,
        identifier: this.identifiedSubPath(index),
      });
    },
    canAddResouce() {
      return this.items.length < this.resourseMaxLength && this.isNotReadOnly;
    },
    canDeleteResource() {
      return this.items.length > 1 && this.isNotReadOnly;
    },
    isResourceTextChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.resourcesDiff[index].isChanged === true ||
            this.resourcesDiff[index].text.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isResourceBriefChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.resourcesDiff[index].isChanged === true ||
            this.resourcesDiff[index].brief.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isResourceImageChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.resourcesDiff[index].isChanged === true ||
            this.resourcesDiff[index][this.imageKey].isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    deleteCurrentResource(index) {
      this.deleteResource(index);
      this.refreshKey += 1;
    },
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    addResource: {
      type: Function,
      required: true,
    },
    deleteResource: {
      type: Function,
      required: true,
    },
    withAdd: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    error: {
      type: String,
      required: true,
      default: '',
    },
    showImageBrief: {
      type: Boolean,
      required: true,
      default: false,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    resourcesSignedUrls: {
      type: Array,
      required: true,
    },
    resourcesDiff: {
      type: Array,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxNameLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    canAppproveAsset: {
      type: Boolean,
      required: false,
      default: false,
    },
    canFinalizeAsset: {
      type: Boolean,
      required: false,
      default: false,
    },
    resourseMaxLength: {
      type: Number,
      required: false,
      default: 7,
    },
    resourceToolBarActions: {
      type: Array,
      required: false,
      default: () => BASIC_TOOLS,
    },
    subPath: {
      type: String,
      default: 'aim.resources',
    },
  },
};
</script>
<style scoped>
.error-message {
  margin: 10px;
  color: red;
}
</style>
