import { getUsers, getRoles, createUser, updateUser, deleteUser } from '@/apiQueries/user';
import { apolloClient } from '@/apollo';
import { logError } from '@/utils';
import { getCookie } from '@/utils/auth';
import axios from 'axios';
import Vue from 'vue';

const CAA_URL = process.env.VUE_APP_CAA_API_URL;

const state = {
  users: [],
  roles: [],
  loadingUserStatus: 'not_started',
};

const getters = {
  users: (state) => state.users,
  roles: (state) => state.roles,
  loadingStatus: (state) => state.loadingUserStatus,
};

const actions = {
  async getUsers({ commit }, payload) {
    if (!payload) payload = '';
    try {
      if (!payload) payload = '';
      commit('setLoadingStatus', 'loading');
      const response = await apolloClient.query({
        query: getUsers(payload),
      });
      commit('setLoadingStatus', 'success');

      if (response.data) {
        commit('getUsers', response.data.lpEntryUsers);
      } else {
        commit('getUsers', []);
      }
    } catch (err) {
      logError(err);
      commit('setLoadingStatus', 'failure');
    }
  },
  async getRoles({ commit }, payload) {
    if (state.roles.length == 0) {
      if (!payload) payload = '';
      try {
        const response = await apolloClient.query({
          query: getRoles,
        });
        if (response.data) {
          commit('getRoles', response.data.lpEntryRoles);
        } else {
          commit('getRoles', []);
        }
      } catch (err) {
        logError(err);
      }
    }
  },
  async createUser({ commit }, payload) {
    try {
      const referrer = new URL(window.location.href).host;
      const caaResponse = await axios.post(
        CAA_URL + 'lp_entry_user',
        { ...payload, ...{ referrer } },
        {
          headers: { 'xs-auth-token': getCookie('xs-lp-entry-token') },
        }
      );
      if (caaResponse.data.success) {
        const response = await apolloClient.mutate({
          mutation: createUser,
          variables: {
            userId: caaResponse.data.data._id,
            roles: payload.roles,
          },
        });
        if (response.data) {
          commit('createUser', response.data.createNewLpEntryUser);
          Vue.notify({
            title: 'Success!',
            text: `User created successfully!`,
            type: 'success',
          });
        }
      }
    } catch (err) {
      logError(err);
    }
  },
  async updateUser({ commit }, payload) {
    const referrer = new URL(window.location.href).host;
    const caaResponse = await axios.put(
      CAA_URL + 'lp_entry_user',
      { ...payload, ...{ referrer } },
      {
        headers: { 'xs-auth-token': getCookie('xs-lp-entry-token') },
      }
    );
    if (caaResponse.data.success) {
      const response = await apolloClient.mutate({
        mutation: updateUser,
        variables: {
          userId: payload.userId,
          roles: payload.roles,
        },
      });
      if (response.data) {
        commit('updateUser', response.data.updateLpEntryUser);
        Vue.notify({
          title: 'Success!',
          text: `User updated successfully!`,
          type: 'success',
        });
      }
    }
  },
  async deleteUser({ commit }, payload) {
    const response = await apolloClient.mutate({
      mutation: deleteUser,
      variables: {
        userId: payload,
      },
    });
    if (response.data) {
      commit('deleteUser', payload);
      Vue.notify({
        title: 'Success!',
        text: `User suspended successfully!`,
        type: 'success',
      });
    }
  },
};

const mutations = {
  getUsers(state, users) {
    state.users = users;
  },
  getRoles(state, roles) {
    state.roles = roles;
  },
  createUser(state, newUser) {
    state.users.push(newUser);
  },
  updateUser(state, newUser) {
    state.users.map((item) => {
      if (item.user._id === newUser.user._id) {
        item.user.name = newUser.user.name;
        item.user.email = newUser.user.email;
        item.roles = newUser.roles;
      }
    });
  },
  deleteUser(state, userId) {
    const index = state.users.findIndex((item) => item.user._id == userId);
    state.users.splice(index, 1);
  },
  setLoadingStatus(state, status) {
    state.loadingUserStatus = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
