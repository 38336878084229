<template>
  <div>
    <v-dialog v-model="showSaveInprogressDialog" max-width="290" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          Brewing your coffee...
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSaveDialogPopup" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"> Alert</v-card-title>
        <v-card-text>
          You have not saved your changes yet. Press YES to save your changes, press NO to continue
          without saving.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="proceedNavigationBasedOnChoice('no')"
            >NO</v-btn
          >
          <v-btn color="green darken-1" text @click="proceedNavigationBasedOnChoice('yes')"
            >YES</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 
      :isPublished="projectDetails.workflowStatus == 'completed'"
      :projectType="projectDetails.type"
      :onClickPublish="onClickPublishHandler"
    -->
    <FormLayout
      :moveToNextStageButtonText="getNextStageButtonText()"
      :canMoveProjectLP="!auth.canMoveProjectLP || currentStage == 'completedStage'"
      :breadCrumbText="breadCrumbText"
      :buttonActions="buttonActions"
      :auth="auth"
      :isAdmin="isAdmin"
      :onClickMoveToNextStage="onClickMoveToNextStage"
      :onClickCompleteAndPublish="() => {}"
      :isCompleted="currentStage == 'completedStage'"
      :isPublished="false"
      :canPublishProject="auth.canPublishProject || auth.canSaveProjectLP"
      :onClickPublish="() => {}"
      :rightSidePreviewContent="rightSidePreviewContent"
      :toggleRightSidePanel="toggleRightSidePanel"
      :showRightSidePanel="showRightSidePanel"
      :currentStage="currentStage"
      :loggedInUserEmail="loggedInUserEmail ? loggedInUserEmail : ''"
      :commentsHash="commentsHash"
      :diffOptions="{
        diffCloseHandler: diffCloseHandler,
        fieldDiff: fieldDiff,
        currentDiffIdentifier: currentDiffIdentifier,
      }"
      :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
      :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
      :closeComments="closeComments"
      :commentSectionIdentifier="commentSectionIdentifier"
      :onClickMoveToPreviousStage="onClickMoveToPreviousStage"
      :projectType="'question'"
      :previewData="templatePreviewData()"
      :isLessonPlan="true"
      :lpPreviewUrl="lpPreviewUrl"
      :showAssetDownloadButton="showAssetDownloadButton"
    >
      <div class="project-wrapper">
        <!-- <v-row class="flex-row">
          <v-col cols="6">
            <div class="lesson-plan-title bold">
              {{ projectDetails.name.toUpperCase() }} — {{ getProjectTypeText }}
            </div>
            <div>GRADE {{ projectDetails.standard.name }} - {{ projectDetails.subject.name }}</div>
          </v-col>
          <v-col cols="6">
            <Stepper
              :userData="assigneeData"
              :currentStepIndex="currentStageIndex"
              :showNames="false"
            />
          </v-col>
        </v-row> -->
        <v-form ref="form" class="left-hand-side-form">
          <CoverImage
            :lessonPlanDetails="lessonPlanDetails()"
            :title="lessonPlanWatcher.title || ''"
            :brief="lessonPlanWatcher.brief.text"
            :imageTitleChangeHandler="(val) => (lessonPlanWatcher.title = val)"
            :imageBriefChangeHandler="(val) => (lessonPlanWatcher.brief.text = val)"
            :image_url="isAssetUploadRequired ? lessonPlanWatcher.assetId : ''"
            :image_url_brief="lessonPlanWatcher.brief.assetId"
            :previewUrl="isAssetUploadRequired ? signedUrls.assetSignedUrl : ''"
            :previewUrlBrief="signedUrls.brief.assetSignedUrl"
            :coverImagePlaceholder="AppTexts.LESSON_PLAN_COVER_IMAGE"
            :currentStageDetails="currentStageDetails"
            :loadingStatus="uploadingStatus.cover_image == FILE_UPLOADING_STATUS.IN_PROGRESS"
            :isAssetUploadRequired="isAssetUploadRequired"
            :fileChangeHandler="importLessonPlanCoverImage"
            :briefChangeHandler="(text) => updateBriefText({ text })"
            :canShowDiffIcon="canShowDiffIcon"
            :isImageChanged="getCoverImageIsChangedValue()"
            :isImageBriefDiffChanged="getCoverImageBriefIsChangedValue()"
            :showImageBrief="showImageBrief"
            :isNotReadOnly="Schema.isCoverImageEditable && auth.canSaveProjectLP"
            :fileValidators="fileValidators"
            :copyLessonPlanCoverImage="copyLessonPlanCoverImage"
            :isAssetApproved="lessonPlanWatcher.assetApproved"
            :lessonPlan="lessonPlanWatcher"
            :canAppproveAsset="canAppproveAsset"
            :canEditTime="auth.canSaveProjectLP"
            @set-timer-input="(val) => updateTimeOfBlockToState('overall', val)"
            :currentProjectAssignees="currentProject.assignees"
            :allowedTimeValues="Schema.coverImageAllowedTimes"
            :showSelectInputForTitle="Schema.showSelectInputForLpTitle"
            :canEditTitle="Schema.canEditTitle"
            :canEditDescription="Schema.canEditDescription"
          />
          <v-expansion-panels multiple focusable v-model="panel">
            <v-expansion-panel
              v-if="Schema.summary.show"
              class="expansion-panel"
              key="SummarySection"
            >
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :sectionTitle="getSummaryTitle()"
                  :displayTrailing="false"
                  :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.SUMMARY, e)"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <SummarySection
                  v-if="Schema.summary.show"
                  :summaryData="summaryData"
                  :lessonPlanData="lessonPlanWatcher"
                  :updateSummaryTextToStore="updateSummaryText"
                  :diff="diff"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :canShowEvaluateAndGrade="canShowEvaluateAndGrade"
                  :schema="Schema"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="Schema.aim.show" class="expansion-panel" key="AimSection">
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :sectionTitle="AppTexts.AIM"
                  :displayTrailing="false"
                  :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.AIM, e)"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <AimSectionForDiscussionLpType
                  v-if="Schema.aim.showDiscussionLpTypeComponent"
                  :lessonPlanData="lessonPlanWatcher"
                  :addResource="addResource"
                  :deleteResource="deleteResource"
                  :addKeyword="addKeyword"
                  :deleteKeyword="deleteKeyword"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :acceptableFileType="acceptableFileType"
                  :resourcesError="resourcesError"
                  :aimSignedUrls="signedUrls.aim"
                  :aimDiff="diff.aim || {}"
                  :keywordsDiff="diff.keywords || []"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :fileValidators="fileValidators"
                  :schema="Schema.aim"
                  :isKeywordDefinitionOptional="Schema.aim.keyWordDefinitionOptional"
                  :canAppproveAsset="canAppproveAsset"
                />
                <AimSection
                  v-else
                  :lessonPlanData="lessonPlanWatcher"
                  :addResource="addResource"
                  :deleteResource="deleteResource"
                  :addKeyword="addKeyword"
                  :deleteKeyword="deleteKeyword"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :acceptableFileType="acceptableFileType"
                  :resourcesError="resourcesError"
                  :aimSignedUrls="signedUrls.aim"
                  :aimDiff="diff.aim || {}"
                  :keywordsDiff="diff.keywords || []"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :fileValidators="fileValidators"
                  :schema="Schema.aim"
                  :isKeywordNameOptional="!(isCircleTime ? isCircleTime : true)"
                  :isKeywordDefinitionOptional="Schema.aim.keyWordDefinitionOptional"
                  :canAppproveAsset="canAppproveAsset"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-if="Schema.action.show"
              class="expansion-panel"
              key="ActionSection"
            >
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :sectionTitle="AppTexts.ACTION"
                  :types="
                    lessonPlanWatcher && lessonPlanWatcher.action
                      ? lessonPlanWatcher.action.types
                      : []
                  "
                  :time="
                    lessonPlanWatcher && lessonPlanWatcher.action
                      ? lessonPlanWatcher.action.time
                      : ''
                  "
                  :canEditType="auth.canSaveProjectLP"
                  @update-type-of-block-to-state="(val) => updateTypeOfBlockToState('action', val)"
                  :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ACTION, e)"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :displayTime="true"
                  :canEditTime="auth.canSaveProjectLP"
                  @set-timer-input="(val) => updateTimeOfBlockToState('action', val)"
                  :allowedTimeValues="Schema.action.allowedTimes"
                  :schema="Schema"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <ActionSection
                  :lessonPlanData="lessonPlanWatcher"
                  :addActionSubStep="addActionSubStep"
                  :deleteActionStep="deleteActionStep"
                  :deleteActionSubStep="deleteActionSubStep"
                  :addActionStep="addActionStep"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
                  :actionSignedUrls="signedUrls.action || {}"
                  :actionDiff="diff.action || {}"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :acceptableFileType="acceptableFileType"
                  :fileValidators="fileValidators"
                  :canAppproveAsset="canAppproveAsset"
                  :schema="Schema.action"
                  @addAudioWordToInstruction="addAudioWordToInstruction"
                  @removeAudioWordFromInstruction="removeAudioWordFromInstruction"
                  :signedUrls="signedUrls"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Action Program 1 Section -->
            <ActionProgram
              v-if="Schema.actionProgram1.show"
              :lessonPlanData="lessonPlanWatcher"
              :auth="auth"
              :schema="Schema"
              :fileValidators="fileValidators"
              :canShowDiffIcon="canShowDiffIcon"
              :diff="diff"
              :acceptableFileType="acceptableFileType"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
              :isAssetUploadRequired="isAssetUploadRequired"
              :showImageBrief="showImageBrief"
              :showProgram1="true"
              :updateTimeOfBlockToState="updateTimeOfBlockToState"
              :updateTypeOfBlockToState="updateTypeOfBlockToState"
              :toggleComments="toggleComments"
            />
            <!--END Action Program Section -->
            <!-- Analysis Program 1  -->
            <AnalysisProgram
              :key="`analysisProgramValue1`"
              v-if="Schema.analysisProgram1.show"
              :lessonPlanData="lessonPlanWatcher"
              :auth="auth"
              :schema="Schema"
              :fileValidators="fileValidators"
              :canShowDiffIcon="canShowDiffIcon"
              :diff="diff"
              :acceptableFileType="acceptableFileType"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
              :isAssetUploadRequired="isAssetUploadRequired"
              :showImageBrief="showImageBrief"
              :showProgram1="true"
              :updateTimeOfBlockToState="updateTimeOfBlockToState"
              :updateTypeOfBlockToState="updateTypeOfBlockToState"
            />
            <!-- END Analysis Program -->
            <!--Action Program 2 Section -->
            <ActionProgram
              v-if="Schema.actionProgram2.show"
              :lessonPlanData="lessonPlanWatcher"
              :auth="auth"
              :schema="Schema"
              :fileValidators="fileValidators"
              :canShowDiffIcon="canShowDiffIcon"
              :diff="diff"
              :acceptableFileType="acceptableFileType"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
              :isAssetUploadRequired="isAssetUploadRequired"
              :showImageBrief="showImageBrief"
              :showProgram2="true"
              :updateTimeOfBlockToState="updateTimeOfBlockToState"
              :updateTypeOfBlockToState="updateTypeOfBlockToState"
            />
            <!--END Action Program Section -->
            <!-- Analysis Program 2  -->
            <AnalysisProgram
              :key="`analysisProgramValue2`"
              v-if="Schema.analysisProgram2.show && lessonPlanWatcher.actionProgram2.show"
              :lessonPlanData="lessonPlanWatcher"
              :auth="auth"
              :schema="Schema"
              :fileValidators="fileValidators"
              :canShowDiffIcon="canShowDiffIcon"
              :diff="diff"
              :acceptableFileType="acceptableFileType"
              :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls"
              :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
              :isAssetUploadRequired="isAssetUploadRequired"
              :showImageBrief="showImageBrief"
              :showProgram2="true"
              :updateTimeOfBlockToState="updateTimeOfBlockToState"
              :updateTypeOfBlockToState="updateTypeOfBlockToState"
            />
            <!-- END Analysis Program -->
            <v-expansion-panel
              class="expansion-panel"
              key="AnalyseSection"
              v-if="Schema.analysis.show"
            >
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :sectionTitle="AppTexts.ANALYSIS"
                  :time="
                    lessonPlanWatcher.analysis && lessonPlanWatcher
                      ? lessonPlanWatcher.analysis.time
                      : ''
                  "
                  :types="
                    lessonPlanWatcher.analysis && lessonPlanWatcher
                      ? lessonPlanWatcher.analysis.types
                      : []
                  "
                  @update-type-of-block-to-state="
                    (val) => updateTypeOfBlockToState('analysis', val)
                  "
                  :canEditType="auth.canSaveProjectLP"
                  :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ANALYSIS, e)"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :displayTime="true"
                  :canEditTime="auth.canSaveProjectLP"
                  @set-timer-input="(val) => updateTimeOfBlockToState('analysis', val)"
                  :allowedTimeValues="Schema.analysis.allowedTimes"
                  :schema="Schema"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <AnalyseSectionForDiscussionLpType
                  v-if="Schema.analysis.showDiscussionLpTypeComponent"
                  :lessonPlanData="lessonPlanWatcher"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :analysisSignedUrls="signedUrls.analysis || {}"
                  :analysisDiff="diff.analysis || {}"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :acceptableFileType="acceptableFileType"
                  :fileValidators="fileValidators"
                  :schema="Schema.analysis"
                  :addAnalysisSubStep="addAnalysisSubStep"
                  :canAppproveAsset="canAppproveAsset"
                  :deleteAnalysisActivityDiscussionStep="deleteDiscussionStepInstruction"
                  :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
                  :addAnalysiDiscussionStep="addAnalysisInstructionStep"
                />
                <PhonicsAnalyseSection
                  v-else-if="Schema.analysis.showPhonicsLpTypeComponent"
                  :lessonPlanData="lessonPlanWatcher"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :analysisSignedUrls="signedUrls.analysis || {}"
                  :analysisDiff="diff.analysis || {}"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :acceptableFileType="acceptableFileType"
                  :fileValidators="fileValidators"
                  :schema="Schema.analysis"
                  :addAnalysisSubStep="addAnalysisSubStep"
                  :canAppproveAsset="canAppproveAsset"
                  :staticTexts="staticTexts.analysis"
                />
                <AnalyseSection
                  v-else
                  :lessonPlanData="lessonPlanWatcher"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :addAnalysiDiscussionStep="addAnalysisDiscussionStep"
                  :addAnalysisActivityDiscussionStep="addAnalysisActivityDiscussionStep"
                  :deleteAnalysisDiscussionStep="deleteAnalysisDiscussionStep"
                  :deleteAnalysisActivityDiscussionStep="deleteAnalysisActivityDiscussionStep"
                  :deleteAnalysisGeneralizedDiscussionStep="deleteAnalysisGeneralizedDiscussionStep"
                  :deleteAnalysisDiscussionSubStep="deleteAnalysisDiscussionSubStepAction"
                  :addAnalysisGeneralizedDiscussionStep="addAnalysisGeneralizedDiscussionStep"
                  :deleteAnalysisHigherOrderStep="deleteAnalysisHigherOrderStep"
                  :addAnalysisHigherOrderStep="addAnalysisHigherOrderStep"
                  :addAnaysisExplanationAssets="addAnaysisExplanationAssets"
                  :deleteAnaysisExplanationAssets="deleteAnaysisExplanationAssets"
                  :analysisSignedUrls="signedUrls.analysis || {}"
                  :analysisDiff="diff.analysis || {}"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :acceptableFileType="acceptableFileType"
                  :fileValidators="fileValidators"
                  :schema="Schema.analysis"
                  :addAnalysisSubStep="addAnalysisSubStep"
                  :canAppproveAsset="canAppproveAsset"
                  :signedUrls="signedUrls"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              class="expansion-panel"
              key="ApplicationSection"
              v-if="Schema.application.show"
            >
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :sectionTitle="AppTexts.APPLICATION"
                  :time="
                    lessonPlanData && lessonPlanData.application
                      ? lessonPlanData.application.time
                      : ''
                  "
                  :types="
                    lessonPlanData && lessonPlanData.application
                      ? lessonPlanData.application.types
                      : []
                  "
                  @update-type-of-block-to-state="
                    (val) => updateTypeOfBlockToState('application', val)
                  "
                  :canEditType="auth.canSaveProjectLP"
                  :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.APPLICATION, e)"
                  :displayTime="Schema.application.displayTime"
                  :displayTrailing="Schema.application.displayTrailing"
                  :canEditTime="auth.canSaveProjectLP"
                  @set-timer-input="(val) => updateTimeOfBlockToState('application', val)"
                  :allowedTimeValues="Schema.application.allowedTimes"
                  :schema="Schema"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <ApplicationSectionForDiscussionLpType
                  v-if="Schema.application.showDiscussionLpTypeComponent && signedUrls"
                  :lessonPlanData="lessonPlanWatcher"
                  :deletePractice="deletePractice"
                  :addPractice="addPractice"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :acceptableFileType="acceptableFileType"
                  :applicationSignedUrls="signedUrls.application || {}"
                  :applicationDiff="diff.application || {}"
                  :canShowDiffIcon="canShowDiffIcon"
                  :workSheetEnabled="worksheetEnabled"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :fileValidators="fileValidators"
                  :schema="Schema.application"
                  :canAppproveAsset="canAppproveAsset"
                />
                <ApplicationSection
                  v-else
                  :lessonPlanData="lessonPlanWatcher"
                  :deletePractice="deletePractice"
                  :addPractice="addPractice"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :acceptableFileType="acceptableFileType"
                  :applicationSignedUrls="signedUrls.application || {}"
                  :applicationDiff="diff.application || {}"
                  :canShowDiffIcon="canShowDiffIcon"
                  :workSheetEnabled="Schema.isWorksheetEnabled"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :fileValidators="fileValidators"
                  :schema="Schema.application"
                  :canAppproveAsset="canAppproveAsset"
                  :saveForm="saveForm"
                  :allSignedUrls="signedUrls"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              class="expansion-panel"
              key="AssessmentSection"
              v-if="Schema.assessment.show"
            >
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :sectionTitle="AppTexts.ASSESS"
                  :displayTrailing="false"
                  :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ASSESSMENT, e)"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <AssessmentSection
                  :lessonPlanData="lessonPlanWatcher"
                  :showImageBrief="showImageBrief"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :acceptableFileType="acceptableFileType"
                  :assessmentSignedUrls="signedUrls.assessment || {}"
                  :assessmentDiff="diff.assessment || {}"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :fileValidators="fileValidators"
                  :canShowEvaluateAndGrade="canShowEvaluateAndGrade"
                  :schema="Schema.assessment"
                  :canAppproveAsset="canAppproveAsset"
                >
                  <template v-if="Schema.assessment.canShowClassroomExplanation">
                    <WhatWeLearned
                      :lessonPlanData="lessonPlanWatcher"
                      :canShowDiffIcon="canShowDiffIcon"
                      :isNotReadOnly="auth.canSaveProjectLP"
                      :schema="Schema.assessment"
                      :diff="diff.assessment || {}"
                      :showImageBrief="showImageBrief"
                      :isAssetUploadRequired="isAssetUploadRequired"
                      :acceptableFileType="acceptableFileType"
                      :fileValidators="fileValidators"
                      :canApproveAsset="canAppproveAsset"
                      :signedUrls="signedUrls.assessment || {}"
                    />
                  </template>
                </AssessmentSection>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-if="Schema.reference.show"
              class="expansion-panel"
              key="ReferencesSection"
            >
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :displayTrailing="false"
                  :displayComments="false"
                  :sectionTitle="AppTexts.REFERENCES"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <ReferencesSection
                  :deleteReference="deleteReference"
                  :addReference="addReference"
                  :references="lessonPlanData.references"
                  :referenceDiff="diff.references || []"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :schema="Schema.reference"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              class="expansion-panel"
              key="informationAndAssessmentSection"
              v-if="
                Schema.informationAndAssessment.show && lessonPlanWatcher.informationAndAssessment
              "
            >
              <v-expansion-panel-header class="section-title-bar">
                <ExpansionPanelHeader
                  :sectionTitle="AppTexts.INFORMATION_AND_ASSESSMENT"
                  :displayTrailing="false"
                  :toggleComments="
                    (e) => toggleComments(COMMENT_SECTION_NAMES_HASH.INFORMATION_AND_ASSESSMENT, e)
                  "
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="section-title-content">
                <!-- todo -->
                <InformationAndAssessment
                  :canShowDiffIcon="canShowDiffIcon"
                  :data="lessonPlanWatcher"
                  primaryDescriptionPlaceholder="Primary Description"
                  secondaryDescriptionPlaceholder="Secondary Description"
                  :diffChanges="diff.informationAndAssessment || {}"
                  :isNotReadOnly="!auth.canSaveProjectLP"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :showImageBrief="showImageBrief"
                  :canAppproveAsset="canAppproveAsset"
                  :signedUrls="signedUrls.informationAndAssessment || {}"
                  diffIdentifierPrefix="informationAndAssessment"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </div>
    </FormLayout>
  </div>
</template>

<script>
/**
 * Mixins
 */
import { baseMixin } from '@/mixins/lessonPlan/base';
import { mapActions, mapState } from 'vuex';
import { LP_TEMPLATE_TO_MIXIN_HASH, LP_TEMPLATES_HASH } from '@/constants/common';
import { cloneDeep } from 'lodash';

function getMixins() {
  let searchParams = new URL(window.location).searchParams;
  let template = searchParams.get('template') || null;

  console.log('getMixins : template : ', template);
  console.log(
    'getMixins : LP_TEMPLATE_TO_MIXIN_HASH : ',
    LP_TEMPLATE_TO_MIXIN_HASH[template] || [baseMixin]
  );

  return LP_TEMPLATE_TO_MIXIN_HASH[template] || [baseMixin];
}
export default {
  name: 'LessonPlanTemplate',
  mixins: getMixins(),
  mounted() {
    console.log('getMixins : template : mounted');
    this.setRedirectRoute();
  },
  computed: {
    ...mapState({
      currentProject: (state) => state.currentProject,
      questionDataForList: (state) => state.questions.questionDataForList,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
    }),
  },
  methods: {
    ...mapActions(['updateTimeOfBlock', 'deleteAnalysisDiscussionSubStepAction']),
    templatePreviewData() {
      const lessonPlanCopy = JSON.parse(JSON.stringify(this.lessonPlan));
      const template = this.$route.query.template;

      if (template == LP_TEMPLATES_HASH.PRACTICE) {
        if (
          lessonPlanCopy.data &&
          lessonPlanCopy.data.application &&
          lessonPlanCopy.data.application.teacher_tips
        ) {
          for (const teacherTip of lessonPlanCopy.data.application.teacher_tips) {
            if (teacherTip.isAuthoredQuestion) {
              teacherTip.questions = cloneDeep(this.questionDataForList)
                .filter((_q) => teacherTip.questions.includes(_q._id))
                .map((_q) => {
                  _q.skill = this.questionMetaInfo.skills.find(
                    (_skill) => (_skill.id || '').toString() == (_q.skill || '').toString()
                  );
                  if (_q.skill) {
                    _q.skill.id = (_q.skill.id || '').toString();
                    _q.subSkill = this.questionMetaInfo.subSkills[_q.skill.id].find(
                      (_subSkill) =>
                        (_subSkill.id || '').toString() == (_q.subSkill || '').toString()
                    );

                    if (_q.subSkill) {
                      _q.subSkill.id = (_q.subSkill.id || '').toString();
                    }
                  } else {
                    _q.subSkill = null;
                  }

                  return _q;
                });
            }
          }
        }
      }

      return {
        ...lessonPlanCopy,
        template: template,
        isWorkSheetEnabled: this.isTrue(this.$route.query.worksheetEnabled),
        awsSignedUrls: this.signedUrls || {},
        displayBriefUrl: !this.isAssetUploadRequired,
      };
    },
    updateTimeOfBlockToState(blockName, value) {
      console.log(blockName);
      this.updateTimeOfBlock({
        blockName,
        value,
      });
    },
    isTrue(arg) {
      return typeof arg == 'string' ? arg == 'true' : Boolean(arg);
    },
    addAudioWordToInstruction(event) {
      let step = this.lessonPlan.data.action.instructions[event.stepIndex];
      if (step && !step.audio_words) {
        this.$set(step, 'audio_words', []);
      }
      step.audio_words.push({
        keyword: '',
        description: '',
      });
    },
    removeAudioWordFromInstruction(event) {
      this.$delete(
        this.lessonPlan.data.action.instructions[event.stepIndex].audio_words,
        event.index
      );
    },
  },
};
</script>

<style scoped>
.project-wrapper {
  padding: 30px;
}
.lesson-plan-title {
  font-size: 20px;
}
</style>
