import { mapState, mapActions, mapMutations } from 'vuex';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import AssetTypes from '@/constants/AssetTypes';
import { COMMENT_SECTION_NAMES_HASH } from '@/constants/LessonPlan';
import _ from 'lodash';

export const baseQuestionMixin = {
  data() {
    return {
      AppTexts,
      FILE_UPLOADING_STATUS,
      COMMENT_SECTION_NAMES_HASH,
      AssetTypes,
      panel: [0, 1, 2, 3, 4, 5, 6],
      isInitialQuestionDataLoaded: false,
      Schema: {
        canEditTitle: true,
        canEditDescription: true,
        showSelectInputForLpTitle: false,
        types: ['individual', 'pair', 'group', 'class'],
        aim: {
          show: true,
          showKeywords: false,
          showResources: true,
          maxResourcesLength: 7,
          descriptionMaxLength: 250,
          resourceNameMaxLength: undefined,
          isSummaryOptional: true,
          label: {
            resourcesTitle: AppTexts.RESOURCES,
          },
          summaryMaxLength: 250,
        },
        action: {
          show: false,
        },
        analysis: {
          show: false,
        },
        application: {
          show: true,
          showClasswork: true,
          showHomeWork: true,
          isSummaryOptional: true,
          maxClassworkQuestionsLength: 12,
          maxHomeworkQuestionsLength: 12,
          maxMentalMathQuestionsLength: 10,
          maxProblemSolvingQuestionsLength: 10,
          mentalMathSummaryMaxLength: 250,
          problemSolvingSummaryMaxLength: 250,
          classworkSummaryMaxLength: 33,
          homeworkSummaryMaxLength: 33,
          teacherTipMaxLength: 220,
          showMentalMath: false,
          showProblemSolving: false,
          allowedTimeValues: [
            '5 mins',
            '10 mins',
            '15 mins',
            '20 mins',
            '25 mins',
            '30 mins',
            '35 mins',
            '40 mins',
            '45 mins',
            '50 mins',
          ],
        },
        assessment: {
          show: false,
        },
      },
      summaryData: {
        aim: '',
        application: '',
      },
      commentSectionIdentifier: '',
      resourcesError: '',
      isCircleTime: false,
    };
  },
  computed: {
    ...mapState({
      showLoadingForQuestionDataList: (state) => state.questions.showLoadingForQuestionDataList,
      questionDataForList: (state) => state.questions.questionDataForList,
      staticTexts: (state) => state.lessonPlan.staticTexts,
      currentProject: (state) => state.currentProject,
    }),
    isAssetUploadRequired() {
      return this.componentOptions.isAssetUploadRequired;
    },
    signedUrls() {
      return this.componentOptions.signedAssetUrls;
    },
    canShowDiffIcon() {
      return this.componentOptions.canShowDiffIcon;
    },
    canShowEvaluateAndGrade() {
      return false;
    },
    isReadOnly() {
      return this.componentOptions.isReadOnly;
    },
    isNotReadOnly() {
      return this.componentOptions.isNotReadOnly;
    },
  },
  watch: {
    currentProject: {
      handler(val) {
        if (val && val.block && val.block.id) {
          this.loadInitialQuestionData();
        }
      },
    },
    lessonPlanData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          this.lessonPlanWatcher = val;
          if (val.aim) {
            this.summaryData['aim'] = val.aim.summary;
          }

          if (val.application) {
            this.summaryData['application'] = val.application.summary;
          }

          if (!this.isInitialQuestionDataLoaded) {
            this.isInitialQuestionDataLoaded = true;
            this.loadInitialQuestionData();
          }
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'updateBriefText',
      'copySingleAssetAction',
      'setRightSidePanelVisibility',
      'updateSummaryText',
      'addResource',
      'deleteResource',
      'addKeyword',
      'deleteKeyword',
      'updateTypeOfBlock',
      'updateTimeOfBlock',
      'loadQuestionDataForList',
    ]),
    ...mapMutations(['SET_QUESTION_DATA_FOR_LIST']),
    loadInitialQuestionData() {
      if (
        this.currentProject &&
        this.currentProject.block &&
        this.currentProject.block.id &&
        this.lessonPlanData
      ) {
        var questionIds = this.getAllQuestionIdsFromLessonPlanData(this.lessonPlanData);
        this.loadQuestionDataForList({ questionIds });
      }
    },
    getAllQuestionIdsFromLessonPlanData(data) {
      var questionIds = [];

      if (data.application.classwork && data.application.classwork.questions) {
        questionIds = questionIds.concat(data.application.classwork.questions);
      }

      if (data.application.homework && data.application.homework.questions) {
        questionIds = questionIds.concat(data.application.homework.questions);
      }

      if (data.application.mental_math && data.application.mental_math.questions) {
        questionIds = questionIds.concat(data.application.mental_math.questions);
      }

      if (data.application.problem_solving && data.application.problem_solving.questions) {
        questionIds = questionIds.concat(data.application.problem_solving.questions);
      }

      return questionIds;
    },
    importLessonPlanCoverImage(file) {
      if (file && file.name && file.name.length) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        });
      } else if (file == null) {
        this.deleteSingleAssetAction({
          assetId: this.isAssetUploadRequired
            ? this.lessonPlanData.assetId
            : this.lessonPlanData.brief.assetId,
          fieldIdentifier: this.isAssetUploadRequired ? 'assetId' : 'brief.assetId',
          assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        });
      }
    },
    getCoverImageIsChangedValue() {
      try {
        if (this.canShowDiffIcon) {
          // let imageKey = this.isAssetUploadRequired ? 'image_url' : 'brief_url';
          // return this.diff[imageKey].isChanged;
        }
      } catch (err) {
        console.log(err.message);
      }

      return false;
    },
    getCoverImageBriefIsChangedValue() {
      try {
        if (this.canShowDiffIcon) {
          return this.diff.brief.isChanged === true;
        }
      } catch (err) {
        console.log(err.message);
      }

      return false;
    },
    fileValidators() {
      const FILE_SIZE_50_MB = 50 * 1024 * 1024;
      const FILE_SIZE_150_MB = 150 * 1024 * 1024;
      const error150mb = 'File size must be < 150 MB.';
      const error50mb = 'File size must be < 50 MB.';
      const defaultValidator = (file) => (file && file.size > FILE_SIZE_50_MB ? error50mb : true);

      if (this.subjectName == 'English') {
        return [
          (file) => {
            if (file) {
              if (file.type.includes('video/')) {
                if (file.size > FILE_SIZE_150_MB) {
                  return error150mb;
                }
              } else {
                if (file.size > FILE_SIZE_50_MB) {
                  return error50mb;
                }
              }
            }
          },
        ];
      } else {
        return [defaultValidator];
      }
    },
    copyLessonPlanCoverImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        isAssetApproved,
      });
    },
    updateTypeOfBlockToState(blockName, value) {
      this.updateTypeOfBlock({
        blockName,
        value,
      });
    },
    updateTimeOfBlockToState(blockName, value) {
      this.updateTimeOfBlock({
        blockName,
        value,
      });
    },
    getQuestionIdsFromQuestion(question) {
      let questionIds = [];

      if (Array.isArray(question)) {
        questionIds = question.map((element) => element._id);
      } else {
        questionIds = [question._id];
      }

      return questionIds;
    },
    addApplicationSectionQuestion(section, questions) {
      let lessonPlanDataClone = _.cloneDeep(this.lessonPlanData);
      let questionDataForListClone = _.cloneDeep(this.questionDataForList);
      let sectionData = lessonPlanDataClone.application[section];

      questions.forEach((question) => {
        const questionIds = this.getQuestionIdsFromQuestion(question);
        sectionData.questions = sectionData.questions.concat(questionIds);

        const questionDataArray = Array.isArray(question) ? question : [question];
        questionDataForListClone = questionDataForListClone.concat(questionDataArray);
      });

      this.SET_QUESTION_DATA_FOR_LIST(questionDataForListClone);
      this.onChangeLessonPlanData({ data: lessonPlanDataClone });
    },
    removeApplicationSectionQuestion(section, question) {
      let lessonPlanDataClone = _.cloneDeep(this.lessonPlanData);
      let questionDataForListClone = _.cloneDeep(this.questionDataForList);
      let sectionData = lessonPlanDataClone.application[section];

      const questionIds = this.getQuestionIdsFromQuestion(question);

      questionIds.forEach((questionId) => {
        let indexToRemove = sectionData.questions.findIndex((element) => element == questionId);

        if (indexToRemove != -1) {
          sectionData.questions.splice(indexToRemove, 1);
        }

        const questionDataIndex = questionDataForListClone.findIndex((e) => e._id == questionId);
        if (questionDataIndex != -1) {
          questionDataForListClone.splice(questionDataIndex, 1);
        }
      });

      this.SET_QUESTION_DATA_FOR_LIST(questionDataForListClone);
      this.onChangeLessonPlanData({ data: lessonPlanDataClone });
    },
  },
};
