import gql from 'graphql-tag';

export const createProject = () => {
  return gql`
    mutation createProject($input: ProjectCreateInputType!) {
      createProject(input: $input) {
        id
        name
        workflowStatus
        product {
          id
          name
        }
        board {
          id
          name
        }
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
        }
        block {
          id
          bId
          title
          childrenType
        }
        contents {
          id
          library_id
          name
          type
          template
          currentStage
        }
        dailyPlans {
          id
          library_id
          name
          type
          currentStage
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
      }
    }
  `;
};

export const updateProject = () => {
  return gql`
    mutation update($input: ProjectUpdateInputType!) {
      updateProject(input: $input) {
        id
        name
        workflowStatus
        product {
          id
          name
        }
        board {
          id
          name
        }
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
        }
        block {
          id
          bId
          title
          childrenType
        }
        contents {
          id
          library_id
          name
          type
          template
          currentStage
        }
        dailyPlans {
          id
          library_id
          name
          type
          currentStage
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
      }
    }
  `;
};

export const getProjectsListV2 = (page, limit, isMyProjects, searchKey) => {
  return gql`
    query {
      multipleProjects(filters: {
        page: ${page},
        limit: ${limit},
        isMyProjects: ${isMyProjects},
        searchKey: "${searchKey}"
      }) {
        totalCount,
        projects {
          id
          name
          workflowStatus
          updatedAt
          product {
            id
            name
          }
          board {
            id
            name
          }
          standard {
            id
            name
            displayName
          }
          subject {
            id
            name
            shortName
          }
          block {
            id
            bId
            title
            childrenType
          }
          contents {
            library_id
            name
            currentStage
            type
            template
          }
          dailyPlans{
            library_id
            name
            currentStage
            type
          }
          assignees {
            author {
              id
              name
            }
            reviewer1 {
              id
              name
            }
            reviewer2 {
              id
              name
            }
            academicSignOff {
              id
              name
            }
            editor {
              id
              name
            }
            visualizer {
              id
              name
            }
            assetUploader {
              id
              name
            }
            assetAuthorizer {
              id
              name
            }
            proofReader {
              id
              name
            }
          }
        }
      }
    }
  `;
};

export const getProjectsList = () => {
  return gql`
    query {
      projects {
        id
        name
        workflowStatus
        product {
          id
          name
        }
        board {
          id
          name
        }
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
        }
        block {
          id
          bId
          title
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
        type
        contents {
          id
          library_id
          name
          description
          type
          template
          currentStage
        }
        dailyPlans {
          id
          library_id
          name
          type
          currentStage
        }
      }
    }
  `;
};

export const getProductsBoardsStandardsSubjectsQuery = () => {
  return gql`
    query {
      boards {
        id
        name
        order
      }
      subjects(isAvailableInSTA: true) {
        id
        name
        shortName
      }
      standards {
        id
        name
        displayName
        subjects {
          id
          name
          shortName
        }
      }
      products {
        id
        name
        description
      }
    }
  `;
};

export const getBlocksForSelectedProductBoardStandardSubjectQuery = (
  productId,
  boardName,
  standardId,
  subjectId
) => {
  return gql`
  query {
    getAvailableBlocksOnStandardSubjectBoardProductComboForProjectCreation(
      boardName: "${boardName}",
      subjectId: ${subjectId},
      standardId: ${standardId},
      productId: ${productId}
    ){
      id
      title
      bId
      childrenType
      sequence
      lessonPlans {
        id
        name
      }
      dailyPlans {
        id
        name
      }
    }
  }
`;
};

export const getAllBlocksForSelectedProductBoardStandardSubjectQuery = (
  productId,
  boardName,
  standardId,
  subjectId
) => {
  return gql`
  query {
    getAllBlocksOnStandardSubjectBoardProductComboForProjectCreation(
      boardName: "${boardName}",
      subjectId: ${subjectId},
      standardId: ${standardId},
      productId: ${productId}
    ){
      id
      title
      bId
      childrenType
      sequence
      lessonPlans {
        id
        name
        type
      }
      dailyPlans {
        id
        name
      }
    }
  }
`;
};

export const getProjectDetailsQuery = (projectId) => {
  return gql`
    {
      project(id: "${projectId}") {
        id
        name
        workflowStatus
        product {
          id
          name
        }
        board {
          id
          name
        }
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
          shortName
        }
        block {
          id
          bId
          title
        }
        contents {
          id
          library_id
          name
          type
          template
          currentStage
          linkedOProgramLPUs {
            microTopicId
            title
            mtId
          }
        }
        type
        dailyPlans {
          id
          library_id
          name
          type
          currentStage
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
      }
    }
  `;
};

export const publishProjectQuery = (projectId) => {
  return gql`
    mutation {
      publishProjectLpsToTeacherReadinessApp(projectId: "${projectId}") {
        triggerStatus
      }
    }
  `;
};

export const getReadPermissionForProjectQuery = (projectId) => {
  return gql`
    {
      canGetProject: canPerformLPAction(
        action: "project"
        arguments: { id: "${projectId}" }
      )
      canSwitchType: canPerformLPAction(action: "switchProjectLessonPlanTemplate")
      canPublishProject: canPerformLPAction(action: "publishProjectLpsToTeacherReadinessApp", arguments: {projectId: "${projectId}"})
      canRevertProjectToPreviousStage: canPerformLPAction(
        action: "revertProjectToPreviousStage",
        arguments: {}
      )
    }
  `;
};

export const revertProjectToPreviousStageQuery = (
  projectId,
  contentId,
  destinationStage,
  copyBackData
) => gql`
mutation{
  revertProjectToPreviousStage(
    projectId: "${projectId}"
    contentId: ${contentId}
    destinationStage: "${destinationStage}"
    copyBackData: ${copyBackData}
  ) {
    currentStage
  }
}
`;

export const getProjectStatus = (projectId) => gql`
{
  getProjectStatus(projectId: "${projectId}") {
    workflowStatus
  }
}`;

export const deleteProject = () => {
  return gql`
    mutation deleteProject($id: ID!) {
      deleteProject(id: $id) {
        status
      }
    }
  `;
};

export const switchProjectLessonPlanTemplate = () => {
  return gql`
    mutation switchProjectLessonPlanTemplate(
      $projectId: ID!
      $contentLibraryId: Int!
      $selectedType: String!
    ) {
      switchProjectLessonPlanTemplate(
        projectId: $projectId
        contentLibraryId: $contentLibraryId
        selectedType: $selectedType
      ) {
        status
      }
    }
  `;
};

export const generateHTMLTemplateMutation = () => {
  return gql`
    mutation generateHtmlTemplate($libraryId: Int!, $isEarlyProgram: Boolean!) {
      generateHtmlTemplate(libraryId: $libraryId, isEarlyProgram: $isEarlyProgram) {
        success
        data
      }
    }
  `;
};

export const generatePdfTemplateMutation = () => {
  return gql`
    mutation generatePdfTemplate($libraryId: Int!, $isEarlyProgram: Boolean!) {
      generatePdfTemplate(libraryId: $libraryId, isEarlyProgram: $isEarlyProgram) {
        success
        data
      }
    }
  `;
};

export const getModelAnswerGuidePDFMutation = (blockIdList, metaData = false) => {
  return gql`
    query {
      getModelAnswerGuidePDF(blockIdList: [${blockIdList}],metaData:${metaData}) {
        zip
      }
    }
  `;
};
