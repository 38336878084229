<template>
  <div>
    <v-btn icon v-if="canShowDiffIcon" @click="getProjectLessonPlanDiffData">
      <img v-if="isDiffChanged" height="21" width="21" src="@/assets/trackicon.svg" />
      <img v-else height="14" width="14" src="@/assets/trackiconwithdot.svg" />
    </v-btn>
  </div>
</template>

<script>
import { DIFF_INDICATOR_COLORS } from '@/constants/Project';
import { mapActions, mapState, mapMutations } from 'vuex';
import { RIGHT_SIDE_PREVIEW_CONTENT_TYPE } from '@/constants/LessonPlan';
export default {
  name: 'DiffButton',
  data() {
    return { DIFF_INDICATOR_COLORS, RIGHT_SIDE_PREVIEW_CONTENT_TYPE };
  },
  computed: {
    ...mapState({
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
    }),
  },
  methods: {
    ...mapMutations(['SET_COMMENTS_DIALOG', 'SET_GROUP_QUESTION_COMMENTS_DIALOG']),
    ...mapActions([
      'projectLessonPlanDiffData',
      'setRightSidePreviewContent',
      'setQuestionRightSidePanel',
      'setGroupQuestionRightSidePanel',
    ]),
    getProjectLessonPlanDiffData() {
      this.projectLessonPlanDiffData({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
        fieldIdentifier: this.fieldIdentifier,
        projectType: this.projectType,
      });
      if (this.projectType == 'question') {
        if (this.subType == 'group') {
          this.setGroupQuestionRightSidePanel(true);
          this.setQuestionRightSidePanel(false);
        } else {
          this.setQuestionRightSidePanel(true);
          this.setGroupQuestionRightSidePanel(false);
        }
        this.SET_COMMENTS_DIALOG(false);
        this.SET_GROUP_QUESTION_COMMENTS_DIALOG(false);
      } else {
        this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.CONTENT_DIFF);
      }
    },
  },
  props: {
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isDiffChanged: {
      type: Boolean,
      required: true,
    },
    fieldIdentifier: {
      type: String,
      required: true,
    },
    projectType: {
      type: String,
      default: 'noQuestion',
    },
    subType: {
      type: String,
      default: '',
    },
  },
};
</script>
