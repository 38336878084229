const state = {
  questionMetaInfo: null,
  showQuestionDialog: false,
  showSourceBlockFilterDialog: false,
  showGroupQuestionDialog: false,
  currentQuestionDialogData: {},
  questionDialogLoader: false,
  questionData: [],
  questionCommentsToggle: false,
  groupQuestionCommentsToggle: false,
  currentStage: '',
  questionSearchFilterOptions: [],
  searchedQuestionsList: [],
  showQuestionSearchLoader: false,
  showLoadingForQuestionDataList: false,
  questionDataForList: [],
  unlinkAndDeleteQuestionConfirmationDialog: { status: false, data: {} },
  removeQuestionLoader: false,
  selectedVirtualQuestionPurposes: [],
  filteredQuestionData: [],
  virtualQuestionPurposes: [],
  reuseQuestionLessonPlans: [],
  reuseQuestionBlocks: [],
  addQuestionDialog: false,
  blockIdToBlockBIdMapping: {},
  showWarningDialog: false,
};

module.exports = {
  state,
};
