import { apolloClient } from '@/apollo';
import Router from '@/router';
import Vue from 'vue';
import {
  airTableSubjectsQuery,
  airTableStandardsQuery,
  projectsFromAirTableStandardsAndSubjectsQuery,
  airTableBigSkillsQuery,
  airTableSubSkillsQuery,
  SYNC_AIRTABLE_DATA,
  airTableThemesQuery,
  airTableMicrotopicsQuery,
  airTableMicroskillCategoriesQuery,
  recentlyCreatedQuestionSetsQuery,
  testFromFiltersQuery,
  airTableBlueprintsQuery,
  initiateTestCreation,
  finalTestCreation,
  fetchAllTestQuestionsQuery,
  fetchProjectListQuery,
  fetchYearsQuery,
} from '@/apiQueries/learnometer';
import { deleteTest, editTest } from '../../../apiQueries/learnometer';

const actions = {
  fetchSubjects: async ({ commit }, payload) => {
    try {
      console.log('ACTION#fetchSubjects#START');
      let { limit = 150, offset = 0 } = payload || {};
      // commit('setTrainingsDataOnApiInProgress', {
      //   message: 'Fetching training data...',
      // });
      const response = await apolloClient.query({
        query: airTableSubjectsQuery,
        variables: { limit, offset },
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setSubjectsDataOnApiResponse', {
            message: 'Successfully fetched subject data!',
            data: response.data.airTableSubjects,
          });
        }, 500);
        console.log('ACTION#fetchSubjectss#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setSubjectssDataOnApiFailure', {
          message:
            'Something went wrong while fetching Subjects details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchSubjectss#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setSubjectssDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchSubjectss#END#ERROR');
    }
  },

  fetchStandards: async ({ commit }, { allStandards = false }) => {
    try {
      console.log('ACTION#fetchStandards#START');
      // commit('setTrainingsDataOnApiInProgress', {
      //   message: 'Fetching training data...',
      // });
      const response = await apolloClient.query({
        query: airTableStandardsQuery,
        variables: { allStandards },
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setStandardsDataOnApiResponse', {
            message: 'Successfully fetched subject data!',
            data: response.data.airTableStandards,
          });
        }, 500);
        console.log('ACTION#fetchStandardss#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setStandardsDataOnApiFailure', {
          message:
            'Something went wrong while fetching Standardsdetails! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchStandardss#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setStandardsDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchStandards#END#ERROR');
    }
  },

  fetchProjectsFromAirTableStandardsAndSubjects: async ({ commit }, payload = {}) => {
    try {
      console.log('ACTION#fetchProjectsFromAirTableStandardsAndSubjects#START');
      commit('setProjectListApiResponse', {
        status: 'in_progress',
        message: 'Brewing your coffee..',
      });
      const response = await apolloClient.query({
        query: projectsFromAirTableStandardsAndSubjectsQuery,
        variables: {
          standardId: payload.standardId || 0,
          subjectId: payload.subjectId || 0,
        },
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        setTimeout(() => {
          commit('setProjectListApiResponse', {
            status: 'completed',
            message: 'Successfully fetched question data!',
            data: response.data.projectsFromAirTableStandardsAndSubjects,
          });
        }, 500);
        console.log('ACTION#fetchTrainings#END#SUCCESS');
        return;
      }
      setTimeout(() => {
        commit('setProjectListApiResponse', {
          status: 'failed',
          message:
            'Something went wrong while fetching training details! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        commit('setProjectListApiResponse', {
          status: 'failed',
          message:
            'Something went wrong while fetching training details! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
      console.log('ACTION#fetchProjectsFromAirTableStandardsAndSubjects#END#ERROR');
    }
  },

  fetchAirtableBigSkills: async ({ commit }) => {
    try {
      console.log('ACTION#fetchAirtableBigSkills#START');
      commit('setAirtableBigSkillsOnApiResponse', {
        message: 'FETCHING Big SKills Data',
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: airTableBigSkillsQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setAirtableBigSkillsOnApiResponse', {
            status: 'completed',
            message: 'Successfully fetched subject data!',
            data: response.data.airTableBigSkills,
          });
        }, 500);
        console.log('ACTION#fetchAirtableBigSkills#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setAirtableBigSkillsOnApiResponse', {
          status: 'failed',
          message:
            'Something went wrong while fetching Big Skills details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableBigSkills#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setAirtableBigSkillsOnApiResponse', {
          status: 'failed',
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableBigSkills#END#ERROR');
    }
  },

  fetchAirtableSubSkills: async ({ commit }) => {
    try {
      console.log('ACTION#fetchAirtableSubSkills#START');
      commit('setAirtableSubSkillsOnApiResponse', {
        status: 'in_progress',
        message: 'FETCHING AIRTABLE SUB SKILL DATA',
      });
      const response = await apolloClient.query({
        query: airTableSubSkillsQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setAirtableSubSkillsOnApiResponse', {
            status: 'completed',
            message: 'Successfully fetched sub skills data!',
            data: response.data.airTableSubSkills,
          });
        }, 500);
        console.log('ACTION#fetchAirtableSubSkillss#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setAirtableSubSkillsOnApiResponse', {
          status: 'failed',
          message:
            'Something went wrong while fetching Subjects details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableSubSkillss#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setAirtableSubSkillsOnApiResponse', {
          status: 'failed',
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableSubSkillss#END#ERROR');
    }
  },
  fetchAirtableData: async ({ commit }) => {
    try {
      console.log('ACTION#fetchAirtable#START');
      commit('airtableDataOnApiResponse', {
        status: 'in_progress',
        message: 'FETCHING AIRTABLE DATA',
      });
      const response = await apolloClient.query({
        query: SYNC_AIRTABLE_DATA,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        commit('airtableDataOnApiResponse', {
          status: 'completed',
          message: 'Airtable data sync successfully.',
        });
        Vue.notify({
          title: 'Success',
          text: `Airtable data sync successfully.`,
          type: 'success',
        });
        console.log('ACTION#fetchAirtable#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('airtableDataOnApiFailure', {
          message:
            'Something went wrong while fetching Airtabledetails! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtable#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('airtableDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtable#END#ERROR');
    }
  },
  fetchAirtableThemes: async ({ commit }) => {
    try {
      console.log('ACTION#fetchAirtableThemes#START');
      commit('setAirtableThemesOnApiResponse', {
        status: 'in_progress',
        message: 'FETCHING AIRTABLE THEMES DATA',
      });
      const response = await apolloClient.query({
        query: airTableThemesQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setAirtableThemesOnApiResponse', {
            status: 'completed',
            message: 'Successfully fetched themes data!',
            data: response.data.airTableThemes,
          });
        }, 500);
        console.log('ACTION#fetchAirtableThemess#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setAirtableThemesOnApiResponse', {
          status: 'failed',
          message: 'Something went wrong while fetching themes! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableThemess#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setAirtableThemesOnApiResponse', {
          status: 'failed',
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableThemess#END#ERROR');
    }
  },
  fetchAirtableMicrotopics: async ({ commit }) => {
    try {
      console.log('ACTION#fetchAirtableMicrotopics#START');
      commit('setAirtableMicrotopicsOnApiResponse', {
        status: 'in_progress',
        message: 'FETCHING AIRTABLE MICROTOPICS DATA',
      });
      const response = await apolloClient.query({
        query: airTableMicrotopicsQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setAirtableMicrotopicsOnApiResponse', {
            status: 'completed',
            message: 'Successfully fetched microtopics data!',
            data: response.data.airTableMicrotopics,
          });
        }, 500);
        console.log('ACTION#fetchAirtableMicrotopicss#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setAirtableMicrotopicsOnApiResponse', {
          status: 'failed',
          message: 'Something went wrong while fetching themes! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableThemess#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setAirtableMicrotopicsOnApiResponse', {
          status: 'failed',
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableMicrotopics#END#ERROR');
    }
  },

  fetchAirtableMicroskillCategories: async ({ commit }) => {
    try {
      console.log('ACTION#MicroskillCategories#START');
      commit('setAirtableMicroskillsCategoiesOnApiResponse', {
        status: 'in_progress',
        message: 'FETCHING AIRTABLE  Microskill Categories DATA',
      });
      const response = await apolloClient.query({
        query: airTableMicroskillCategoriesQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setAirtableMicroskillsCategoiesOnApiResponse', {
            status: 'completed',
            message: 'Successfully fetched  Microskill Categories data!',
            data: response.data.airTableMicroskillCategories,
          });
        }, 500);
        console.log('ACTION#MicroskillCategoriess#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setAirtableMicroskillsCategoiesOnApiResponse', {
          status: 'failed',
          message:
            'Something went wrong while fetching Microskill Categories! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchAirtableThemess#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setAirtableMicroskillsCategoiesOnApiResponse', {
          status: 'failed',
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#MicroskillCategories#END#ERROR');
    }
  },

  fetchRecentlyCreatedQuestionSets: async ({ commit }) => {
    try {
      console.log('ACTION#fetchRecentlyCreatedQuestionSets#START');
      commit('setRecentlyCreatedQuestionSetsOnApiResponse', {
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: recentlyCreatedQuestionSetsQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setRecentlyCreatedQuestionSetsOnApiResponse', {
            status: 'success',
            message: 'Successfully fetched recently created question set data!',
            data: response.data.getRecentlyCreatedTests,
          });
        }, 500);
        console.log('ACTION#fetchRecentlyCreatedQuestionSets#END#SUCCESS');
        return;
      }
      setTimeout(() => {
        commit('setRecentlyCreatedQuestionSetsOnApiFailure', {
          message:
            'Something went wrong while fetching question set details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchRecentlyCreatedQuestionSets#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setRecentlyCreatedQuestionSetsOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchRecentlyCreatedQuestionSets#END#ERROR');
    }
  },

  fetchQuestionSetsUsingFilters: async ({ commit }, payload) => {
    try {
      console.log('ACTION#fetchQuestionSetsUsingFilters#START');
      commit('setQuestionSetsUsingFiltersOnApiResponse', {
        status: 'in_progress',
      });
      let { name, gradeId, yearId, blueprintId, subjectId } = payload || {};
      const filters = {
        name,
        gradeId,
        yearId,
        subjectId,
        blueprintId,
      };
      const response = await apolloClient.query({
        query: testFromFiltersQuery,
        variables: { filters },
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setQuestionSetsUsingFiltersOnApiResponse', {
            message: 'Successfully fetched recently created question set data!',
            data: response.data.getTestsFromFilters,
          });
        }, 500);
        console.log('ACTION#fetchQuestionSetsUsingFilters#END#SUCCESS');
        return;
      }
      setTimeout(() => {
        commit('setQuestionSetsUsingFiltersOnApiFailure', {
          message:
            'Something went wrong while fetching question set details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchQuestionSetsUsingFilters#END#FAILURE');
    } catch (err) {
      setTimeout(() => {
        commit('setQuestionSetsUsingFiltersOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchQuestionSetsUsingFilters#END#ERROR');
    }
  },

  fetchBlueprints: async ({ commit }) => {
    try {
      console.log('ACTION#fetchBlueprints#START');
      commit('setBlueprintsDataOnApiResponse', {
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: airTableBlueprintsQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setBlueprintsDataOnApiResponse', {
            status: 'success',
            message: 'Successfully fetched Blueprints data!',
            data: response.data.airtableBlueprints,
          });
        }, 500);
        console.log('ACTION#fetchBlueprints#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setBlueprintsDataOnApiFailure', {
          message:
            'Something went wrong while fetching Blueprints details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setBlueprintsDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#ERROR');
    }
  },
  createXLMTest: async ({ commit }, payload) => {
    try {
      commit('setCreateTestDataOnApiResponse', {
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: initiateTestCreation,
        variables: {
          questionSetId: payload.questionSetId,
          standardId: payload.standardId,
          isRandomisedClone: false,
          id: payload.id,
        },
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        if (response.data.createXLMTest.status == true) {
          setTimeout(() => {
            commit('setCreateTestDataOnApiResponse', {
              status: true,
              message: 'Successfully fetched Create Test data!',
              data: response.data.createXLMTest.data,
            });
          }, 500);
          console.log('ACTION#fetchBlueprints#END#SUCCESS');
          Router.push({
            name: 'QuestionSetAuthoring',
            params: { testId: response.data.createXLMTest.data.id, type: 'create' },
          });
        } else {
          setTimeout(() => {
            commit('setCreateTestDataOnApiResponse', {
              status: false,
              message: response.data.createXLMTest.message,
              data: null,
            });
          }, 500);
          console.log('ACTION#fetchBlueprints#END#FAILURE');
          Vue.notify({
            title: 'Error!',
            text: response.data.createXLMTest.message,
            type: 'error',
          });
        }
      }

      setTimeout(() => {
        commit('setCreateTestDataOnApiFailure', {
          message:
            'Something went wrong while fetching Blueprints details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setTestDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#ERROR');
    }
  },
  editXLMTest: async ({ commit }, payload) => {
    try {
      commit('setEditTestDataOnApiResponse', {
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: editTest,
        variables: {
          id: payload.id,
        },
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        console.log(response);
        setTimeout(() => {
          commit('setEditTestDataOnApiResponse', {
            status: 'success',
            message: 'Successfully fetched Create Test data!',
            data: response.data.createXLMTest,
          });
        }, 500);
        console.log('ACTION#setEditTestDataOnApiResponse#END#SUCCESS');
        Router.push({
          name: 'QuestionSetAuthoring',
          params: { testId: payload.id, type: 'edit' },
        });
      }

      setTimeout(() => {
        commit('setCreateTestDataOnApiFailure', {
          message:
            'Something went wrong while fetching Blueprints details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#FAILURE');
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        commit('setTestDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#ERROR');
    }
  },
  deleteXLMTest: async ({ commit }, payload) => {
    try {
      commit('setDeleteTestDataOnApiResponse', {
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: deleteTest,
        variables: {
          id: payload.id,
        },
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setDeleteTestDataOnApiResponse', {
            status: 'success',
            message: 'Successfully Deleted Test data!',
            data: response.data.createXLMTest,
          });
        }, 500);
        console.log('ACTION#setDeleteTestDataOnApiResponse#END#SUCCESS');
      }

      setTimeout(() => {
        commit('setDeleteTestDataOnApiFailure', {
          message:
            'Something went wrong while fetching Blueprints details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setTestDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#ERROR');
    }
  },
  publishXLMTest: async ({ commit }, payload) => {
    try {
      commit('setCreateTestDataOnApiResponse', {
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: finalTestCreation,
        variables: {
          id: payload.id,
          questionSetId: payload.questionSetId,
          testName: payload.testName,
          testDescription: payload.testDescription,
          isRandomisedClone: payload.isRandomisedClone,
          questionList: payload.questionList,
          publishTest: payload.publishTest,
        },
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setCreateTestDataOnApiResponse', {
            status: 'success',
            message: 'Successfully fetched Create Test data!',
            data: response.data.publishXLMTest,
          });
        }, 500);
        console.log('ACTION#fetchBlueprints#END#SUCCESS');
        Router.push({
          name: 'ViewQuestionSet',
        });
      }

      setTimeout(() => {
        commit('setCreateTestDataOnApiFailure', {
          message:
            'Something went wrong while fetching Blueprints details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setTestDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchBlueprints#END#ERROR');
    }
  },

  fetchAllTestQuestions: async ({ commit }, payload) => {
    try {
      commit('setFetchTestDataOnApiResponse', {
        status: 'in_progress',
      });
      const response = await apolloClient.query({
        query: fetchAllTestQuestionsQuery,
        variables: { testId: payload.testId },
        fetchPolicy: 'no-cache',
      });
      if (
        response &&
        response.data &&
        response.data.fetchAllTestQuestions &&
        response.data.fetchAllTestQuestions.data
      ) {
        setTimeout(() => {
          if (
            Array.isArray(
              ((response.data.fetchAllTestQuestions || {}).data || {}).missingGlobalQuestionIds
            ) &&
            response.data.fetchAllTestQuestions.data.missingGlobalQuestionIds.length > 0
          ) {
            Vue.notify({
              title: 'FOLLOWING GLOBAL QUESTION IDS ARE NOT AUTHORED!',
              text: response.data.fetchAllTestQuestions.data.missingGlobalQuestionIds.join(', '),
              type: 'warn',
              duration: -1,
            });
          }
          commit('setFetchTestDataOnApiResponse', {
            status: 'success',
            message: 'Successfully fetched Test data!',
            data: response.data.fetchAllTestQuestions.data,
          });
        }, 500);
        console.log('ACTION#fetchTests#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setTestDataOnApiFailure', {
          message:
            'Something went wrong while fetching test details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchTest#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setTestDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchTest#END#ERROR');
    }
  },

  fetchYears: async ({ commit }) => {
    try {
      console.log('ACTION#fetchYears#START');
      const response = await apolloClient.query({
        query: fetchYearsQuery,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setYearDataOnApiResponse', {
            status: 'success',
            message: 'Successfully fetched years data!',
            data: response.data.getTestYears,
          });
        }, 500);
        console.log('ACTION#fetchYears#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setYearDataOnApiFailure', {
          message:
            'Something went wrong while fetching year details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchYears#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setYearDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchYears#END#ERROR');
    }
  },

  fetchProjectListWithStandardIdAndSubjectId: async ({ commit }, payload) => {
    try {
      commit('setProjectListWithStandardIdAndSubjectIdResponse', {
        status: 'in_progress',
      });

      const response = await apolloClient.query({
        query: fetchProjectListQuery,
        variables: { Questions: Object.values(payload.Questions[0]) },
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        setTimeout(() => {
          if (
            Array.isArray(response.data.fetchProjectListWithStandardIdAndSubjectId || {} || {}) &&
            response.data.fetchProjectListWithStandardIdAndSubjectId.length > 0
          ) {
            Vue.notify({
              title: 'FOLLOWING GLOBAL QUESTION IDS ARE NOT AUTHORED!',
              text: response.data.fetchProjectListWithStandardIdAndSubjectId,
              type: 'warn',
              duration: -1,
            });
          }

          commit('setProjectListWithStandardIdAndSubjectIdResponse', {
            status: 'success',
            message: 'Successfully fetched Test data!',
            data: response.data.fetchProjectListWithStandardIdAndSubjectId,
          });
        }, 500);
        console.log('ACTION#setProjectListWithStandardIdAndSubjectIdResponse#END#SUCCESS');

        return;
      }

      setTimeout(() => {
        commit('setProjectWithStandardIdAndSubjectIdFailure', {
          message:
            'Something went wrong while setProjectListWithStandardIdAndSubjectIdResponse! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#setProjectListWithStandardIdAndSubjectIdResponse#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setProjectWithStandardIdAndSubjectIdApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log(`ACTION#fetchProjectWithStandardIdAndSubjectId#END#ERROR ${error}`);
    }
  },
};

export default actions;
