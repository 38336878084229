<template>
  <div style="width=100%;display: flex;position: relative;">
    <v-select
      :items="items"
      :label="placeholder"
      item-text="label"
      item-value="value"
      hide-details
      outlined
      :value="selectedValue"
      class="remove-right-border-radious xs-text-input border"
      @change="emitCurrenlySelectedValue($event)"
    ></v-select>

    <DiffButton
      v-if="canShowDiffIcon"
      :style="canShowDiffIcon ? 'border: 1px solid #979797; border-left: 0px;' : 'border: 0px'"
      :class="'diff-button'"
      :fieldIdentifier="fieldIdentifier"
      :isDiffChanged="isDiffChanged"
      :canShowDiffIcon="canShowDiffIcon"
      :projectType="projectType"
    />
  </div>
</template>

<script>
import DiffButton from '@/components/ContentDiff/DiffButton';

export default {
  components: {
    DiffButton,
  },
  methods: {
    emitCurrenlySelectedValue(event) {
      this.$emit('selectChanged', event);
    },
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      require: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      default: true,
    },
    isDiffChanged: {
      type: Boolean,
      required: false,
    },
    fieldIdentifier: {
      type: String,
      required: false,
    },
    selectedValue: {
      type: String,
      default: '',
    },
    projectType: {
      type: String,
      default: 'noQuestion',
    },
  },
};
</script>

<style scoped>
.remove-right-border-radious {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.diff-button {
  width: 50px;
  background-color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
