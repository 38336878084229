<template>
  <v-card>
    <v-card-title class="headline grey lighten-2 dialog-header" primary-title>
      {{ dialogTile }}
    </v-card-title>
    <div class="micro-topic-project-form-wrapper">
      <v-row>
        <v-col style="display: flex; justify-content: flex-end;">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" dark v-bind="attrs" v-on="on" @click="applyToAll"
                >Apply To All</v-btn
              >
            </template>
            <span>This is meant only for first time creation of a project</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <div
        class="form-item"
        v-for="(projectData, index) in newMicroTopicProjectsData"
        :key="'ProjectStageAssigneeFormContent' + index + id"
      >
        <div class="form-header">
          <v-checkbox
            @change="resetAssigneesIfReopeningProject(projectData)"
            dense
            v-model="projectData.enabled"
            :label="`
              ${capitalCase(newMicroTopicProjectsDataKeys[index])}
                ${
                  projectData.workflowStatus && projectData.workflowStatus == 'completed'
                    ? projectData.enabled
                      ? '(Attempting to Re-open)'
                      : '(published)'
                    : ''
                }`"
          ></v-checkbox>
        </div>
        <div class="form-content">
          <v-form ref="project-assignee-form">
            <ProjectStageAssigneeFormContent
              :projectData="projectData"
              v-if="projectData.enabled"
              :getUsersByRoles="getUsersByRoles"
              colsPerRow="3"
            />
          </v-form>
        </div>
      </div>
    </div>
    <v-card-actions class="dialog-footer">
      <div class="footer-action-buttons">
        <v-btn color="primary" @click="handleSaveProject">{{
          isUpdate ? 'Update' : 'Create'
        }}</v-btn>
        <v-btn text @click="closeProjectDialog">Cancel</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
import { capitalCase } from 'change-case';
import ProjectStageAssigneeFormContent from '@/components/contentEntry/ProjectStageAssigneeFormContent';
import { SKIP_STAGE_USER_ID_VALUE } from '@/constants/microTopic';
import _ from 'lodash';

export default {
  name: 'MicroTopicProjectFormContent',
  data() {
    return {
      SKIP_STAGE_USER_ID_VALUE,
      mandatoryStages: ['Asset Uploader', 'Asset Authorizer', 'Proof Reader'],
    };
  },
  components: {
    ProjectStageAssigneeFormContent,
  },
  methods: {
    capitalCase,
    resetAssigneesIfReopeningProject(projectData) {
      if (projectData.workflowStatus == 'completed' && projectData.enabled) {
        let assignees = Object.keys(projectData.assignees);
        assignees.forEach((assignee) => {
          if (!this.mandatoryStages.includes(assignee)) {
            projectData.assignees[assignee].id = this.SKIP_STAGE_USER_ID_VALUE;
            projectData.assignees[assignee].name = 'Skip(Not assign Users)';
          } else {
            projectData.assignees[assignee].id = '';
            projectData.assignees[assignee].name = '';
          }
        });
      }
    },
    applyToAll() {
      let firstActiveFlag = false;
      let dataAssignees = {};
      this.newMicroTopicProjectsData.map((data) => {
        if (data.enabled) {
          if (!firstActiveFlag) {
            firstActiveFlag = true;
            dataAssignees = _.cloneDeep(data.assignees);
          } else {
            if (!data.contents) {
              data.assignees = _.cloneDeep(dataAssignees);
            }
          }
        }
        return;
      });
    },
    getUsersByRoles(role) {
      let sortListedUsers = [];
      if (!this.mandatoryStages.includes(role)) {
        sortListedUsers.push({
          id: this.SKIP_STAGE_USER_ID_VALUE,
          name: 'Skip(Not assign Users)',
        });
      }

      for (let user of this.users) {
        let rolesList = user.roles.map((role) => role.name);
        if (rolesList.includes(role) && user.user) {
          sortListedUsers.push({
            id: user.user._id,
            name: user.user.name,
          });
        }
      }

      return sortListedUsers;
    },
    handleSaveProject() {
      let isValidForm = true;
      this.$refs['project-assignee-form'].forEach((form) => {
        isValidForm = isValidForm && form.validate();
      });

      if (isValidForm) {
        this.saveProject();
      }
    },
  },
  props: {
    newMicroTopicProjectsData: {
      type: Array,
      required: true,
    },
    newMicroTopicProjectsDataKeys: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    dialogTile: {
      type: String,
      required: true,
    },
    closeProjectDialog: {
      type: Function,
      required: true,
    },
    saveProject: {
      type: Function,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
    },
  },
};
</script>
<style scoped>
.micro-topic-project-form-wrapper {
  padding: 20px;
}
.form-content {
  padding: 0px 40px;
}
.footer-action-buttons {
  width: 100%;
}
.footer-action-buttons button {
  float: right;
}
.dialog-header {
  top: 0;
  z-index: 1;
  position: sticky;
}
.dialog-footer {
  bottom: 0;
  z-index: 1;
  position: sticky;
  background-color: #eeeeee;
}
</style>
