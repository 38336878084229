<template>
  <div class="mgn-Ls">
    <v-btn
      :class="canAdd ? '' : 'disable-btn-click-event'"
      @click="onClick"
      class="xs-accent-button"
    >
      {{ buttonText }}
    </v-btn>
    <span class="mgn-Rm remaining-step-warning-text" v-if="showRemainingItemCount && canAdd">
      {{ getStepAdditionValidationText(remainingItemsCount, remainingItemsSuffix) }}
    </span>
  </div>
</template>

<script>
import { getStepAdditionValidationText } from '@/utils';

export default {
  name: 'AddItemButton',
  methods: {
    getStepAdditionValidationText
  },
  computed: {
    canAdd() {
      return this.canAddItem && !this.isReadOnly;
    }
  },
  props: {
    isReadOnly: {
      type: Boolean,
      required: true
    },
    canAddItem: {
      type: Boolean,
      required: true
    },
    showRemainingItemCount: {
      type: Boolean,
      required: false,
      default: true
    },
    remainingItemsCount: {
      type: Number,
      required: false
    },
    remainingItemsSuffix: {
      type: String,
      required: false
    },
    buttonText: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: false
    }
  }
};
</script>
