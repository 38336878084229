import Vue from 'vue';
import router from '@/router';
export const MICRO_TOPIC_MUTATION = {
  SET_MICRO_TOPICS: (state, { count, data }) => {
    state.microTopics = data || [];
    state.microTopicTotalCount = count || state.microTopics.length;
  },
  ADD_PROJECT_TO_MT_LIST: (state, { microTopicId, projectData }) => {
    let microTopic = state.microTopics.find((mt) => mt.id == microTopicId);

    microTopic.projects.splice(0, 0, projectData);
    router.push({
      name: 'MicroTopicView',
      params: {
        microTopicId: microTopic.id,
      },
    });
  },
  ADD_PROJECT_TO_MT_VIEW: (state, { microTopicId, projectData }) => {
    if (microTopicId != state.currentMicroTopic.id) throw new Error('Invalid MicroTopic ID');

    if (state.currentMicroTopic.projects)
      state.currentMicroTopic.projects.splice(0, 0, projectData);
    else state.currentMicroTopic.projects = [projectData];
  },
  UPDATE_CURRENT_MICRO_TOPIC_PROJECT_STATUS: (state, { projectData, projectId }) => {
    let projectIndex = state.currentMicroTopic.projects.findIndex((p) => p.id == projectId);

    Vue.set(state.currentMicroTopic.projects, projectIndex, projectData);
  },
  UPDATE_CURRENT_MICRO_TOPIC_PROJECT_ASSIGNEE_STATUS: (state, { projectId, assigneeDetails }) => {
    let projectIndex = state.currentMicroTopic.projects.findIndex((p) => p.id == projectId);

    state.currentMicroTopic.projects[projectIndex].assignees = assigneeDetails;
  },
  SET_CURRENT_MICRO_TOPIC_DETAILS: (state, { microTopic }) => {
    state.currentMicroTopic = microTopic || {};
  },
  SET_INITIAL_MICRO_TOPICS_API_STATUS: (state, status) => {
    state.initialMicroTopicsListApiStatus = status;
  },
  UPDATE_MICRO_TOPIC_FILTER_OPTIONS: (state, { subjects, standards, strands }) => {
    state.microTopicfilterOptions = {
      subjects: formFilterOptionsFromData(subjects),
      standards: formFilterOptionsFromData(standards, 'displayName'),
      themes: formFilterOptionsFromData(strands),
    };
  },
  SET_MICROTOPIC_SUMMARY_DATA(state, data) {
    state.summaryFilter = data;
    localStorage.setItem('LP_ENTRY_MICROTOPICS_SUMMARY_FILTER', JSON.stringify(data));
  },
  GET_MICROTOPIC_SUMMARY_FILTER_DATA(state) {
    let microTopicFilter = localStorage.getItem('LP_ENTRY_MICROTOPICS_SUMMARY_FILTER');
    if (microTopicFilter) state.summaryFilter = JSON.parse(microTopicFilter);
  },
  REMOVE_MICROTOPIC_SUMMARY_FILTER_DATA(state) {
    state.summaryFilter = {};
    localStorage.removeItem('LP_ENTRY_MICROTOPICS_SUMMARY_FILTER');
  },
  DELETE_PROJECT_FROM_MICROTOPIC: (state, projectId) => {
    let projects = state.currentMicroTopic.projects;
    const index = projects.findIndex((p) => p.id == projectId);
    Vue.delete(projects, index);
  },
};

const formFilterOptionsFromData = (items, textKey = 'name', valueKey = 'id') => {
  let response = [];
  (items || []).forEach((item) => {
    response.push({
      text: item[textKey],
      value: item[valueKey],
    });
  });

  return response;
};
