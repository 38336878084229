import gql from 'graphql-tag';

export const getUsers = (role) => {
  return gql`
  query {
    lpEntryUsers(roleId: "${role}") {
      user {
        _id
        name
        email
      }
      roles {
        _id
        name
      }
    }
  }
`;
};

export const createUser = gql`
  mutation createNewLpEntryUser($userId: String!, $roles: [String]!) {
    createNewLpEntryUser(data: { userId: $userId, roles: $roles }) {
      user {
        name
        email
        _id
      }
      roles {
        _id
        name
      }
    }
  }
`;

export const updateUser = gql`
  mutation updateLpEntryUser($userId: String!, $roles: [String]!) {
    updateLpEntryUser(data: { userId: $userId, roles: $roles }) {
      user {
        name
        email
        _id
      }
      roles {
        _id
        name
      }
    }
  }
`;

export const getRoles = gql`
  query {
    lpEntryRoles {
      _id
      name
    }
  }
`;

export const deleteUser = gql`
  mutation suspendLPEntryUser($userId: String!) {
    suspendLPEntryUser(userId: $userId)
  }
`;
