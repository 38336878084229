<template>
  <div>
    <div v-if="dailyPlanDataFetchApiStatus == 'in_progress'" style="margin-top: 100px;">
      <Loader
        :loadingText="AppTexts.FETCHING_DATA_TEXT"
        :loadingStatus="dailyPlanDataFetchApiStatus == 'in_progress'"
      />
    </div>
    <div v-else-if="dailyPlanDataFetchApiStatus == 'error'">
      Some thing went wrong. Please try again later.
    </div>
    <v-row v-else-if="dailyPlanDataFetchApiStatus == 'completed'">
      <div
        class="overflow-hidden"
        :style="
          showRightSidePanel
            ? 'width: 70%; position: relative;'
            : ' width: 96%; position: relative;'
        "
      >
        <v-app-bar
          height="58px;"
          dense
          absolute
          color="white"
          elevation="2"
          scroll-target="#scrolling-techniques-7"
          class="action-bar"
        >
          <v-row>
            <v-col>
              <span class="dp-detailes-header">{{ breadCrumbText }}</span>
            </v-col>
          </v-row>
          <div></div>
          <v-dialog v-model="showSaveInprogressDialog" max-width="290" persistent>
            <v-card>
              <v-card-title style="text-align: center; display: block;" class="headline"
                >Brewing your coffee...</v-card-title
              >
              <v-card-text style="text-align: center;">
                <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-row justify="center">
            <v-dialog v-model="switchTemplate.alert" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Are you sure about switching the template</span>
                </v-card-title>
                <v-card-text>
                  Please note that this may result in data loss of many fields that you have entered
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="rejectSwitchingTemplate()">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="confirmSwitchingTemplate()">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-row justify="center">
            <v-dialog v-model="switchTemplate.show" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Switch Type</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-select
                          :items="getDpTemplatesToSwitch"
                          item-text="label"
                          item-value="value"
                          label="Template to switch to"
                          v-model="switchTemplate.selectedType"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="switchTemplate.show = false">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="initiateSwitchingTemplate()">
                    Switch
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn
            class="ml-5 download-assets-button"
            v-show="
              auth.canDownloadContentAssets &&
              projectDailyPlanCurrentStageData.currentStage == 'assetUploadStage'
            "
            depressed
            color="blue"
            medium
            :disabled="!downloadContentAssets"
            @click="downloadContentAssets() || null"
            >Assets</v-btn
          >
          <v-btn
            class="ml-5"
            depressed
            color="primary save-button"
            @click="validateDailyPlan"
            medium
            >VALIDATE DP</v-btn
          >
          <v-btn
            class="ml-5 draft-save-button"
            depressed
            color="blue"
            medium
            @click="saveDailyPlanAsDraft()"
            >DRAFT SAVE</v-btn
          >
        </v-app-bar>
        <v-sheet style="margin-top: 100px;" id="scrolling-techniques-7" class="overflow-y-auto">
          <v-form ref="form" class="cnr">
            <v-row>
              <v-col>
                <CoverImage
                  :title="dailyPlanData.description || ''"
                  :brief="dailyPlanData.brief.text || ''"
                  :imageTitleChangeHandler="(val) => (dailyPlanData.description = val)"
                  :imageBriefChangeHandler="(val) => (dailyPlanData.brief.text = val)"
                  :lessonPlanDetails="{
                    title: dailyPlanName,
                    details: projectDetails,
                  }"
                  :image_url="dailyPlanData.assetId"
                  :image_url_brief="dailyPlanData.brief.assetId"
                  :previewUrl="awsSignedUrl.assetSignedUrl || ''"
                  :previewUrlBrief="
                    awsSignedUrl.brief ? awsSignedUrl.brief.assetSignedUrl || '' : ''
                  "
                  :showImageTitle="true"
                  coverImagePlaceholder="Cover image"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :isRequired="isAssetUploadRequired"
                  :loadingStatus="coverImageUploadingStatus == 'in_progress'"
                  :fileChangeHandler="importLessonPlanCoverImage"
                  :canShowDiffIcon="false"
                  :showImageBrief="true"
                  :currentStageDetails="currentStageDetails"
                  :isNotReadOnly="true"
                  :canFinalizeAsset="true"
                  :canAppproveAsset="true"
                  :copyLessonPlanCoverImage="copyDailyPlanCoverImage"
                  :currentProjectAssignees="currentProject.assignees"
                  :isAssetApproved="dailyPlanData.assetApproved"
                  :canEditTitle="true"
                  :canEditDescription="true"
                  :lessonPlan="dailyPlanData"
                  titleKey="dp_title"
                  descriptionKey="dp_description"
                  @changedTitleAndDesc="changedTitleAndDesc($event)"
                />
              </v-col>
            </v-row>
            <div class="top-empty-space"></div>
            <v-expansion-panels multiple focusable v-model="panel">
              <v-expansion-panel
                v-for="(lessonPlanData, lessonPlanIndex) in dailyPlanData.lessonPlans"
                :key="'dailyPlanIntermediatePageList' + lessonPlanIndex"
                :v-bind="lessonPlanIndex"
                :set="
                  (lessonPlanInfo = getLessonPlanInfo(lessonPlanData.library_id))
                "
              >
                <!-- HEADER  -->
                <v-expansion-panel-header class="section-title-bar">
                  <DailyPlanPanelHeader
                    :title="lessonPlanInfo.name"
                    :canShowWorksheetEnableSwitch="
                      canShowWorksheetEnableSwitch(lessonPlanData.type, lessonPlanData.template)
                    "
                    :worksheetEnabled="lessonPlanData.worksheetEnabled"
                    :onChangeWorksheetEnabled="
                      (val) => onChangeWorksheetEnabledFlag(val, lessonPlanData.library_id)
                    "
                  />
                </v-expansion-panel-header>
                <!-- CONTENT  -->
                <v-expansion-panel-content class="section-title-content">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <RichTextEditor
                        :canShowDiffIcon="false"
                        :fieldIdentifier="lessonPlanInfo.name"
                        :placeholder="lessonPlanInfo.name"
                        v-model="lessonPlanData.summary"
                        :showValidationError="true"
                        :readonly="false"
                        :includeOnlyToolBarActions="BASIC_TOOLS"
                      />
                    </v-col>
                    <v-col cols="6">
                      <Stepper
                        :steps="dailyPlanSteps"
                        :currentStepIndex="trackProgressOfTimeCompletion(lessonPlanData.status)"
                        :showUserName="false"
                      />
                      <span>{{ getProgressText(lessonPlanData.status) }}</span>
                    </v-col>
                    <v-col cols="6" align="right">
                      <v-btn
                        :key="'dailyPlanIntermediatePageListButton' + lessonPlanIndex"
                        large
                        color="#B5E9E4"
                        @click="
                          redirectToLpForm(
                            libraryIdToTimesMap[lessonPlanData.library_id],
                            lessonPlanData
                          )
                        "
                        >Enter Lesson Plan</v-btn
                      >
                      <v-menu
                        bottom
                        right
                        v-if="auth.canSwitchType && canShowSwitchTemplate(lessonPlanData)"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon dark>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-btn @click="showTemplateSwichClicked(lessonPlanData)"
                              >Switch template</v-btn
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
          <MoveToNextStageButton
            @onClickMoveToNextStage="MoveDPToNextStage"
            :buttonText="getNextStageButtonText()"
            :canMoveProjectLP="false"
          />
        </v-sheet>
      </div>
      <div style="align-item: center; width: 30%;">
        <div>
          <v-btn
            icon
            :style="showRightSidePanel ? 'right: 30%' : 'right: 3px;'"
            class="toggle-preview-button"
            :title="showRightSidePanel ? 'Hide Preview' : 'Show Preview'"
            @click="toggleRightSidePanel()"
          >
            <v-icon large>{{
              showRightSidePanel ? 'mdi-arrow-right-drop-circle' : 'mdi-arrow-left-drop-circle'
            }}</v-icon>
          </v-btn>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import MoveToNextStageButton from '@/components/lessonPlan/MoveToNextStageButton';
import CoverImage from '@/components/lessonPlanTemplate/CoverImage';
import DailyPlanPanelHeader from '@/components/DailyPlanPanelHeader';
import Stepper from '@/components/lessonPlan/Stepper';
import Loader from '@/components/common/Loader';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import AssetTypes from '@/constants/AssetTypes';
import { mapState, mapActions } from 'vuex';
import { getAssetUploadRequiredStatus } from '@/utils';
import Errors from '@/constants/Errors';
import { LP_STAGES, LP_FORM_SUBMIT_TEXT } from '@/constants/LessonPlan';
import {
  STATUS_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  DP_TEMPLATE_ABLE_TO_SWITCH_DIFFERENT_TEMPLATE,
} from '@/constants/common';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import AppTexts from '@/constants/AppTexts';
import { startCase } from 'lodash';

export default {
  name: 'DailyPlanEditPage',
  data() {
    return {
      Errors,
      LP_STAGES,
      DP_TEMPLATE_ABLE_TO_SWITCH_DIFFERENT_TEMPLATE,
      showRightSidePanel: false,
      panel: [0, 1, 2, 3, 4, 5],
      FILE_UPLOADING_STATUS,
      AssetTypes,
      isAssetUploadRequired: true,
      dailyPlanSteps: [{ label: '' }, { label: '' }, { label: '' }],
      currentStageDetails: {
        index: 0,
        stageName: LP_STAGES[0],
      },
      awsSignedUrl: {
        assetSignedUrl: '',
        brief: {
          assetSignedUrl: '',
        },
      },
      libraryIdToTimesMap: {},
      lessonPlanIds: {},
      dailyPlanName: '',
      dailyPlanData: {
        circleTime: {},
        englishTime: {},
        scienceTime: {},
        mathTime: {},
        storyTime: {},
        artTime: {},
        playTime: {},
        musicAndMovementTime: {},
        dramaTime: {},
      },
      currentStageIdentifier: '',
      AppTexts,
      libraryId: 0,
      BASIC_TOOLS,
      switchTemplate: {
        show: false,
        alert: false,
        selectedTyped: '',
        contentLibraryId: '',
        projectId: '',
      },
    };
  },
  created() {
    this.getProjectDetails({ projectId: this.$route.params.projectId });
    this.getProjectDailyPlanCurrentStageData({
      projectId: this.$route.params.projectId,
      dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
    });
    this.getDailyPlanPermissions({
      projectId: this.$route.params.projectId,
      contentLibraryId: this.$route.params.dailyPlanLibraryId,
    });
    console.log(this.projectDailyPlanCurrentStageData);
  },
  computed: {
    ...mapState({
      projectDailyPlanCurrentStageData: (state) => state.dailyPlan.projectDailyPlanCurrentStageData,
      dailyPlanLoadingStatus: (state) => state.dailyPlan.dailyPlanLoadingStatus,
      dailyPlanDataFetchApiStatus: (state) => state.dailyPlan.dailyPlanDataFetchApiStatus,
      projectBasicDetails: (state) => state.dailyPlan.projectBasicDetails,
      coverImageUploadingStatus: (state) => state.dailyPlan.coverImageUploadingStatus,
      currentProject: (state) => state.currentProject,
      auth: (state) => state.auth.dailyPlan,
    }),
    getDpTemplatesToSwitch() {
      return DP_TEMPLATE_ABLE_TO_SWITCH_DIFFERENT_TEMPLATE.map((_d) => {
        return { label: this.startCase(_d), value: _d };
      });
    },
    breadCrumbText() {
      let block;
      let blockText = ``;
      if (this.projectBasicDetails && this.projectBasicDetails.block)
        block = this.projectBasicDetails.block;
      if (block) blockText = `- ${block.title} (${block.bId}) `;

      return `Home > Block ${blockText}> ${this.dailyPlanName}`;
    },
    showSaveInprogressDialog() {
      return this.dailyPlanLoadingStatus.status == 'in_progress' ? true : false;
    },
    projectDetails() {
      try {
        return `${this.projectBasicDetails.board.name} -
          Grade ${this.projectBasicDetails.standard.name} -
          Block ${this.projectBasicDetails.block.title}(${this.projectBasicDetails.block.bId})`;
      } catch (er) {
        return '';
      }
    },
  },
  watch: {
    projectDailyPlanCurrentStageData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.currentStageData) {
          this.dailyPlanName = val.name;
          this.libraryId = val.library_id;
          this.lessonPlanIds = Object.assign({}, val.lessonPlans);
          let sortedLessonPlansData = [];
          const currentStageData = val.currentStageData.data;

          val.libraryLessonPlans.forEach((lp) => {
            const lpData = currentStageData.lessonPlans.find((_) => _.library_id == lp.id);
            if (lpData) {
              sortedLessonPlansData.push(lpData);
            }
          });

          this.dailyPlanData = Object.assign(
            {},
            { ...currentStageData, lessonPlans: sortedLessonPlansData }
          );
          this.awsSignedUrl = val.currentStageAssetsSignedUrl;

          let currentStageIndex = Object.keys(this.LP_STAGES).indexOf(val.currentStage);

          this.currentStageDetails = {
            index: currentStageIndex,
            stageName: this.LP_STAGES[val.currentStage],
          };

          if (!this.currentStageIdentifier) {
            this.currentStageIdentifier = val.currentStage;
          }

          if (this.currentStageIdentifier != val.currentStage) {
            this.$router.push({
              path: '/projects/' + this.$route.params.projectId,
            });
          }

          this.showImageBrief = currentStageIndex < 7;
          this.isAssetUploadRequired = getAssetUploadRequiredStatus(val.currentStage);
        }
      },
    },
  },
  components: {
    MoveToNextStageButton,
    RichTextEditor,
    CoverImage,
    Loader,
    Stepper,
    DailyPlanPanelHeader,
  },
  methods: {
    startCase,
    getAssetUploadRequiredStatus,
    ...mapActions([
      'getProjectDetails',
      'updateDailyPlanFieldInVuex',
      'importDailyPlanCoverImage',
      'removeDailyPlanCoverImage',
      'getProjectDailyPlanCurrentStageData',
      'saveProjectDailyPlanStageData',
      'vaildateEarlyPlan',
      'moveDPToNextStage',
      'copyDailyPlanSingleAsset',
      'downloadDPAssets',
      'getDailyPlanPermissions',
      'switchProjectLessonPlanTemplate',
    ]),
    changedTitleAndDesc(event) {
      this.updateDailyPlanFieldInVuex({ fieldName: 'dp_title', value: event.title });
      this.updateDailyPlanFieldInVuex({ fieldName: 'dp_description', value: event.description });
    },
    getLessonPlanInfo(libraryId) {
      let currentDpLessonPlan = this.projectDailyPlanCurrentStageData.lessonPlans.find(
        (_c) => +_c.library_id == +libraryId
      );

      if (currentDpLessonPlan && currentDpLessonPlan.type) {
        currentDpLessonPlan.name = currentDpLessonPlan.type;
        this.libraryIdToTimesMap[libraryId] = currentDpLessonPlan.name;
        return currentDpLessonPlan;
      }
      let projectData = this.projectDailyPlanCurrentStageData.libraryLessonPlans.find(
        (_) => _.id == libraryId
      );
      this.libraryIdToTimesMap[libraryId] = projectData.name;
      return projectData;
    },
    canShowSwitchTemplate(lessonPlanData) {
      return (
        DP_TEMPLATE_ABLE_TO_SWITCH_DIFFERENT_TEMPLATE.includes(lessonPlanData.template) &&
        this.currentStageDetails.stageName != 'Completed'
      );
    },
    showTemplateSwichClicked(lessonPlanData) {
      this.switchTemplate.selectedType = lessonPlanData.template;
      this.switchTemplate.show = true;
      this.switchTemplate.contentLibraryId = lessonPlanData.library_id;
      this.switchTemplate.projectId = this.$route.params.projectId;
    },
    rejectSwitchingTemplate() {
      this.switchTemplate.selectedType = '';
      this.switchTemplate.show = false;
      this.switchTemplate.alert = false;
      this.switchTemplate.contentLibraryId = '';
      this.switchTemplate.projectId = '';
    },
    confirmSwitchingTemplate() {
      this.switchProjectLessonPlanTemplate(this.switchTemplate);
    },
    initiateSwitchingTemplate() {
      this.switchTemplate.alert = true;
    },

    downloadContentAssets() {
      this.downloadDPAssets({
        projectId: this.$route.params.projectId,
        contentLibraryId: this.$route.params.dailyPlanLibraryId,
      });
    },
    getProgressText(status) {
      switch (status) {
        case STATUS_NOT_STARTED:
          return AppTexts.NOT_STARTED;
        case STATUS_IN_PROGRESS:
          return AppTexts.IN_PROGRESS;
        case STATUS_COMPLETED:
          return AppTexts.COMPLETED;
        default:
          return AppTexts.NOT_STARTED;
      }
    },
    //NOTE: DO NOT send lessonPlanInfo.name as argument for lpTimeKey
    //it is always having last data ie., it has the name of whichever time is rendered at last
    redirectToLpForm(lpTime, lessonPlanData) {
      // let lpInfo = ;
      // let lpTimeKey = lpInfo.name;
      this.$router.push({
        name: 'DailyPlanLessonPlanTemplate',
        params: {
          libraryId: lessonPlanData.library_id,
          dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
          projectId: this.$route.params.projectId,
        },
        query: {
          lpTimeKey: lpTime,
          lpType: lessonPlanData.type,
          blockSequence: '',
          blockTitle: this.projectBasicDetails.block.title,
          bId: this.projectBasicDetails.block.bId,
          productName: this.projectBasicDetails.product.name,
          grade: this.projectBasicDetails.standard.name,
          subject: this.projectBasicDetails.subject.name,
          boardName: this.projectBasicDetails.board.name,
          template: lessonPlanData.template,
          worksheetEnabled: lessonPlanData.worksheetEnabled || false,
        },
      });
      this.$router.go();
    },
    trackProgressOfTimeCompletion(status) {
      switch (status) {
        case STATUS_NOT_STARTED:
          return 0;
        case STATUS_IN_PROGRESS:
          return 1;
        case STATUS_COMPLETED:
          return 2;
        default:
          return 0;
      }
    },
    toggleRightSidePanel() {
      this.showRightSidePanel = !this.showRightSidePanel;
    },
    importLessonPlanCoverImage(file) {
      let imageKey = this.isAssetUploadRequired ? 'assetId' : 'brief.assetId';

      if (file) {
        let filePaths = file.name.split('.');

        if (this.showImageBrief) {
          filePaths[0] + '_brief';
        }

        this.importDailyPlanCoverImage({
          file,
          libraryId: this.$route.params.dailyPlanLibraryId,
          fileName: filePaths.join('.'),
          imageKey,
        });
      } else {
        let assetId = this.isAssetUploadRequired
          ? this.dailyPlanData.assetId
          : this.dailyPlanData.brief.assetId;

        this.removeDailyPlanCoverImage({
          assetId,
          imageKey,
          projectId: this.$route.params.projectId,
          dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
        });
      }
    },
    saveDailyPlanAsDraft() {
      this.saveProjectDailyPlanStageData({
        projectId: this.$route.params.projectId,
        dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
        data: this.dailyPlanData,
      });
    },
    MoveDPToNextStage() {
      this.moveDPToNextStage({
        projectId: this.$route.params.projectId,
        dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
        data: this.dailyPlanData,
      });
    },
    getNextStageButtonText() {
      let buttonText;
      const stages = Object.keys(LP_FORM_SUBMIT_TEXT);
      const nextStageIndex = stages.indexOf(this.currentStageIdentifier || stages[0]) + 1;

      if (stages[nextStageIndex]) {
        buttonText = AppTexts.MOVE_TO_NEXT_STAGE;
      } else {
        buttonText = 'Completed';
      }

      return buttonText;
    },
    validateDailyPlan() {
      if (this.$refs.form.validate()) {
        if (!this.dailyPlanData.assetId && this.isAssetUploadRequired) {
          return false;
        }
        this.vaildateEarlyPlan({
          projectId: this.$route.params.projectId,
          dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
          data: this.dailyPlanData,
        });
      }
    },
    canShowWorksheetEnableSwitch(type, template) {
      return type == 'standard' && template == '5Step';
    },
    onChangeWorksheetEnabledFlag(val, library_id) {
      const lessonPlan = this.dailyPlanData.lessonPlans.find((_) => _.library_id == library_id);
      lessonPlan.worksheetEnabled = val;

      this.saveDailyPlanAsDraft();
    },
    copyDailyPlanCoverImage(previewUrl, briefUrl, isAssetApproved) {
      let imageKey = this.isAssetUploadRequired ? 'assetId' : 'brief.assetId';

      this.copyDailyPlanSingleAsset({
        path: briefUrl,
        signedUrl: previewUrl,
        imageKey,
        isAssetApproved,
      });
    },
  },
};
</script>
<style scoped>
.section-title-cnr {
  margin-top: 10px;
}
.preview-block {
  margin-top: 120px;
  max-height: 88vh;
  position: fixed;
  width: 30%;
}
.top-empty-space {
  height: 35px;
}
.section-title-bar {
  background-color: #e1e1e1;
  border-radius: 5px 5px 0px 0px !important;
  height: 70px;
  margin-bottom: 15px;
}
.section-title-content {
  border-radius: 5px;
}
.toggle-preview-button {
  position: fixed;
  top: 120px;
  z-index: 1;
}
.draft-save-button {
  color: #0da27d;
  background: #fff !important;
  border: 1px solid #0da27d !important;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
}
.save-button {
  border-radius: 2px;
  width: 150px;
  font-size: 18px;
  font-weight: 500;
}
.daily-plan-title {
  font-size: 20px;
  font-weight: 500;
}
.daily-plan-details {
  color: #242424;
}
.action-bar {
  z-index: 999;
  height: 58px;
  position: fixed;
  top: 64px;
  transform: translateY(0px);
  left: 0px;
  right: 0px;
}
.dp-detailes-header {
  font-size: 14px;
  color: #242424;
}
</style>
