<template>
  <div :style="getStyle">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Padder',
  computed: {
    getStyle() {
      let _vertical = this.vertical;
      let _horizontal = this.horizontal;
      if (this.top > 0 || this.bottom > 0) {
        _vertical = 0;
      }
      if (this.left > 0 || this.right > 0) {
        _horizontal = 0;
      }
      return `padding-top:${this.top > 0 ? this.top : _vertical}px; padding-right: ${
        this.right > 0 ? this.right : _horizontal
      }px; padding-left: ${this.left > 0 ? this.left : _horizontal}px; padding-bottom: ${
        this.bottom > 0 ? this.bottom : _vertical
      }px`;
    }
  },
  props: {
    horizontal: {
      type: Number,
      required: false,
      default: 0
    },
    vertical: {
      type: Number,
      required: false,
      default: 10
    },
    top: {
      type: Number,
      required: false,
      default: 0
    },
    left: {
      type: Number,
      required: false,
      default: 0
    },
    bottom: {
      type: Number,
      required: false,
      default: 0
    },
    right: {
      type: Number,
      required: false,
      default: 0
    }
  }
};
</script>
