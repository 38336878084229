import gql from 'graphql-tag';

export const fetchAllBoardsSubjectsStandards = gql`
  query {
    boards {
      id
      name
    }
    subjects(isAvailableInSTA: true) {
      id
      name
    }
    standards {
      id
      name
    }
    products {
      id
      name
    }
  }
`;
