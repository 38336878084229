<template>
  <v-row class="slide-wrapper">
    <Padder>
      <span class="slide-name">{{ slide }}</span>
    </Padder>
  </v-row>
</template>

<script>
export default {
  name: 'Slide',
  props: ['slide']
};
</script>

<style scoped>
.slide-name {
  height: 25px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background: #bb161b;
  padding: 2px 30px 2px 20px;
  border-radius: 0px 2px 2px 0px;
  margin-left: -25px;
}
.slide-wrapper {
  padding-left: 13px;
}
</style>
