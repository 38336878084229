<template>
  <div>
    <v-row v-if="title">
      <v-col>
        <BlockTitleWithSubtitle :subTitle="title" :time="time" />
      </v-col>
    </v-row>
    {{ paragraphText }}
    <Step
      :key="`StepWithHeaderKey${signedUrlKey}`"
      :items="items"
      :canShowStepNumber="canShowStepNumber"
      :canShowAddNewStepButton="canShowAddNewStepButton"
      :addNewStepHandler="addNewStepHandler"
      :allowDeleteStep="allowDeleteStep"
      :deleteStepHandler="deleteStepHandler"
      :descriptionPlaceholder="AppTexts.DESCRIPTION"
      :maxNoOfSteps="maxNoOfSteps"
      :maxNoOfSubStepsInStep="maxNoOfSubStepsInStep"
      :maxSumOfStepsAndSubSteps="maxSumOfStepsAndSubSteps"
      :supportAssets="supportAssets"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :getPreviewUrl="getMorePracticeInstructionAssetPreviewUrl"
      :stepAssetIdentifier="subPath"
      :canShowDiffIcon="canShowDiffIcon"
      :stepDiffIdentifier="`${subPath}[<stepIndex>].description`"
      :isStepDescriptionChanged="isMorePracticeStepDescriptionChanged"
      :assetBriefDiffIdentifier="`${subPath}[<stepIndex>].assets[<assetIndex>].brief`"
      :isAssetBriefChanged="isMorePracticeAssetBriefChanged"
      :assetDiffIdentifierPrefix="`${subPath}[<stepIndex>].assets[<assetIndex>]`"
      :assetImageDiffIdentifierPrefix="`${subPath}[<stepIndex>].assets[<assetIndex>]`"
      :isAssetImageChanged="isMorePracticeAssetImageChanged"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :stepDescriptionMaxLength="stepDescriptionMaxLength"
      :subStepDescriptionMaxLength="subStepDescriptionMaxLength"
      :stepTeacherTipMaxLength="stepTeacherTipMaxLength"
      :canAppproveAsset="canAppproveAsset"
      :canFinalizeAsset="canFinalizeAsset"
      :subPath="subPath"
      :hasSubStep="hasSubStep"
      :allowDeleteSubStep="allowDeleteSubStep"
      :deleteSubStepHandler="deleteSubStepHandler"
      :addNewSubStepHandler="addNewSubStepHandler"
      :canShowInstructionBreakCheckbox="canShowInstructionBreakCheckbox"
      :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
      :canShowInstructionTeacherTip="canShowInstructionTeacherTip"
      :toggleTeacherTipFieldHandler="toggleTeacherTipFieldHandler"
    />
  </div>
</template>

<script>
import Step from '@/components/inputs/Step';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import AppTexts from '@/constants/AppTexts';
import { get } from 'lodash';
export default {
  name: 'StepWithHeader',
  components: {
    BlockTitleWithSubtitle,
    Step,
  },
  data() {
    return {
      AppTexts,
      signedUrlKey: 0,
    };
  },
  watch: {
    signedUrls: {
      deep: true,
      immediate: true,
      handler() {
        this.signedUrlKey++;
      },
    },
  },
  methods: {
    getMorePracticeInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedUrl: '',
        assetSignedBriefUrl: '',
        loadingStatus: 'not_started',
      };
      if (this.signedUrls) {
        let instruction = get(this.signedUrls, stepIndex);
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.assetSignedUrl = asset.assetSignedUrl;
            }
            previewUrl.assetSignedBriefUrl = asset.brief.assetSignedUrl;
            previewUrl.loadingStatus = asset.loadingStatus;
          }
        }
      }

      return previewUrl;
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    paragraphText: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    descriptionPlaceholder: {
      type: String,
      default: '',
    },
    canShowStepNumber: {
      type: Boolean,
      default: true,
    },
    canShowAddNewStepButton: {
      type: Boolean,
      default: true,
    },
    allowDeleteStep: {
      type: Boolean,
      default: true,
    },
    supportAssets: {
      type: Boolean,
      default: true,
    },
    canFinalizeAsset: {
      type: Boolean,
      default: true,
    },
    maxNoOfSteps: {
      type: Number,
      default: 4,
    },
    maxNoOfSubStepsInStep: {
      type: Number,
      default: 4,
    },
    maxSumOfStepsAndSubSteps: {
      type: Number,
      default: 4,
    },
    subPath: {
      type: String,
      required: true,
    },
    stepDescriptionMaxLength: {
      type: Number,
      default: undefined,
    },
    subStepDescriptionMaxLength: {
      type: Number,
      default: undefined,
    },
    stepTeacherTipMaxLength: {
      type: Number,
      default: undefined,
    },
    addNewStepHandler: {
      type: Function,
      default: () => {},
    },
    deleteStepHandler: {
      type: Function,
      default: () => {},
    },
    signedUrls: {
      type: Array,
      default: () => [],
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    isNotReadOnly: {
      type: Boolean,
      default: false,
    },
    fileValidators: {
      type: Object,
      default: () => {},
    },
    hasSubStep: {
      type: Boolean,
      default: false,
    },
    allowDeleteSubStep: {
      type: Boolean,
      default: false,
    },
    deleteSubStepHandler: {
      type: Function,
      default: () => {},
    },
    addNewSubStepHandler: {
      type: Function,
      default: () => {},
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      default: () => {},
    },
    canShowInstructionBreakCheckbox: {
      type: Boolean,
      default: false,
    },
    canShowInstructionTeacherTip: {
      type: Boolean,
      default: false,
    },
    toggleTeacherTipFieldHandler: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
