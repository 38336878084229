<template>
  <v-row no-gutters>
    <v-col cols="5">
      <h3>{{ title }}</h3>
    </v-col>
    <v-col cols="7" align="right" v-if="canShowWorksheetEnableSwitch">
      <v-btn @click.native.stop :ripple="false">
        <span>Worksheet Enabled</span>
        <v-switch
          :input-value="worksheetEnabled"
          @change="onChangeWorksheetEnabled"
          style="margin-left: 15px"
        ></v-switch>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DailyPlanPanelHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    worksheetEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    canShowWorksheetEnableSwitch: {
      type: Boolean,
      required: false,
      default: false
    },
    onChangeWorksheetEnabled: {
      type: Function,
      required: false,
      default: () => {}
    }
  }
};
</script>
