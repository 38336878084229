import gql from 'graphql-tag';

export const getLessonPlanSaveAndMoveToNextStagePermissionQuery = (projectId, lpId) => {
  return gql`
      query {
        canMoveProjectLP: canPerformLPAction(
          action: "moveProjectLessonPlanToNextStage"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: ${lpId}
          }
        )
        canSaveProjectLP: canPerformLPAction(
          action: "saveProjectLessonPlanStageData"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: ${lpId}
          }
        )
        canDownloadContentAssets: canPerformLPAction(
          action: "downloadCurrentStageLpAssets"
          arguments:{
            projectId: "${projectId}"
            contentLibraryId: ${lpId}
          }
        )
        canAddComment: canPerformLPAction(
          action: "saveProjectLessonPlanSectionComment"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: ${lpId}
          }
        )
        canResolveComment: canPerformLPAction(
          action: "resolveProjectLessonPlanSectionComment"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: ${lpId}
          }
        )
        canMoveToAssetUploadStage: canPerformLPAction(
          action: "moveFromProofReaderToAssetAuthorizingStage"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: ${lpId}
          }
        )
      }
    `;
};

export const getCurrentUserPermissions = () => {
  return gql`
    query {
      canCreateProject: canPerformLPAction(action: "createProject", arguments: {})
      canGetProjects: canPerformLPAction(action: "projects")
      canDeleteProject: canPerformLPAction(action: "deleteProject")
      canEditProjects: invokeLPAuthFunction(action: "updatableProjectList")
    }
  `;
};

export const getCurrentUserMicroTopicAuthPermissions = () => {
  return gql`
    query {
      canMutateMicroTopic: canPerformLPAction(action: "createProject", arguments: {})
      canReadMicroTopic: canPerformLPAction(action: "projects")
      canViewMicroTopic: canPerformLPAction(action: "projects")
      canDeleteMicroTopic: canPerformLPAction(action: "deleteProject")
      canEditMicroTopics: invokeLPAuthFunction(action: "updatableMicroTopicIdList")
    }
  `;
};

export const getCurrentUserContentEntryProjectFormPermissionsQuery = (
  projectId,
  contentLibraryId
) => {
  return gql`
      query {
        canMoveContent: canPerformLPAction(
          action: "moveProjectLessonPlanToNextStage"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: "${contentLibraryId}"
          }
        )
        canSaveContent: canPerformLPAction(
          action: "saveProjectLessonPlanStageData"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: "${contentLibraryId}"
          }
        )
        canValidateContent: canPerformLPAction(
          action: "saveProjectLessonPlanStageData"
          arguments: {
            projectId: "${projectId}"
            contentLibraryId: "${contentLibraryId}"
          }
        )
        canDownloadContentAssets: canPerformLPAction(
          action: "downloadCurrentStageLpAssets"
          arguments:{
            projectId: "${projectId}"
            contentLibraryId: "${contentLibraryId}"
          }
        )
        canMoveContentProjectToPreviousStage: canPerformLPAction(
          action: "moveFromProofReaderToAssetAuthorizingStage"
          arguments:{
            projectId: "${projectId}"
            contentLibraryId: "${contentLibraryId}"
          }
        )
        canPublishProject: canPerformLPAction(
          action: "publishProjectLpsToTeacherReadinessApp"
          arguments:{
            projectId: "${projectId}"
          }
        )
        canAddComment: canPerformLPAction(
          action: "saveProjectLessonPlanSectionComment"
          arguments: {
            projectId: "${projectId}"
          }
        )
        canResolveComment: canPerformLPAction(
          action: "resolveProjectLessonPlanSectionComment"
          arguments: {
            projectId: "${projectId}"
          }
        )
      }
    `;
};

export const getDailyPlanPermissionQuery = (projectId, contentLibraryId) => {
  return gql`
    query {
      canSwitchType: canPerformLPAction(action: "switchProjectLessonPlanTemplate")
      canDownloadContentAssets: canPerformLPAction(
        action: "downloadCurrentStageLpAssets"
        arguments:{
          projectId: "${projectId}"
          contentLibraryId: "${contentLibraryId}"
        }
      )
    }
  `;
};

export const authorizeUser = () => {
  return gql`
    query {
      invokeLPAuthFunction(action: "authorizeCESUser")
    }
  `;
};
