<template>
  <div id="maintenance-message">
    Site is under scheduled maintenance untill 19 March 2021 3:00 AM IST
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
};
</script>

<style lang="scss" scoped>
#maintenance-message {
  font-size: 30px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: #ebf10d;
}
</style>
