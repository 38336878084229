<template>
  <v-expansion-panels multiple focusable v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="AppTexts.CONTENT_BOOK_HEADING"
          :time="data.time"
          :typeSync="data.type"
          :displayTrailing="true"
          :toggleComments="() => toggleComments('contentBook')"
          :type="data.type"
          @update-type-of-block-to-state="
            (val) => {
              data.type = val;
            }
          "
          :isNotReadOnly="options.isNotReadOnly"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-title-content">
        <v-row>
          <v-col md="6">
            <FileInput
              :canShowDiffIcon="options.canShowDiffIcon"
              :fileAcceptType="!options.isAssetUploadRequired ? options.fileAcceptType : '.pdf'"
              label="PDF"
              :loadingStatus="options.fileUploadingStatus.contentBookImage == 'in_progress'"
              :isRequired="options.isAssetUploadRequired"
              :isAssetUploadRequired="options.isAssetUploadRequired"
              :fileChangeHandler="
                (file) => fileUploadHandler(file, 'contentBookImage', data[fileKey])
              "
              :previewUrl="options.isAssetUploadRequired ? options.signedAssetUrls.file : ''"
              :previewUrlBrief="options.signedAssetUrls.fileBrief"
              :image_url="options.isAssetUploadRequired ? data.file : ''"
              :image_url_brief="data.fileBrief"
              :isNotReadOnly="options.isNotReadOnly"
              :diffIdentifier="fileKey"
              imagePlaceholder="pdf"
              @addBriefToAsset="
                (previewUrl, briefUrl, approveAsset) =>
                  addToAsset(previewUrl, briefUrl, approveAsset, 'contentBookImage')
              "
              :isAssetApproved="data.fileApproved"
              :canFinalizeAsset="true"
              :canAppproveAsset="canApproveAsset"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <RichTextEditor
              :readonly="options.isReadOnly || options.isAssetUploadRequired"
              :canShowDiffIcon="options.canShowDiffIcon"
              v-model="data.brief"
              fieldIdentifier="brief"
              placeholder="Brief"
              :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from 'vuex';
import ExpansionPanelHeader from '@/components/contentEntry/oLessonComponents/ExpansionPanelHeader';
import FileInput from '@/components/inputs/FileInput';
import AppTexts from '@/constants/AppTexts';
import RichTextEditor from '@/components/RTE/RichTextEditor';

export default {
  name: 'ContentBook',
  created() {
    this.data.time = '8 mins';
    if (typeof this.data.brief == 'undefined') {
      this.$set(this.data, 'brief', '');
      this.currentStageDataCopy.brief = '';
    }
    this.currentStageDataCopy.time = '8 mins';
  },
  components: {
    ExpansionPanelHeader,
    FileInput,
    RichTextEditor,
  },
  data() {
    return {
      panel: [0],
      AppTexts,
      defaultFileType: 'pdf',
    };
  },
  computed: {
    ...mapState({
      currentStageDataCopy: (state) => state.contentEntry.currentStageDataCopy,
    }),
    fileKey() {
      return this.options.isAssetUploadRequired ? 'file' : 'fileBrief';
    },
  },
  methods: {
    fileUploadHandler(file, type, path) {
      if (file == null) {
        this.fileChangeHandler(file, type, path);
        return true;
      }
      let fileType = file.name.split('.').pop();
      if (this.options.isAssetUploadRequired && this.defaultFileType != fileType) {
        this.$notify({
          title: 'Error!',
          text: 'File format not supported, please upload a PDF file',
          type: 'error',
          clean: false,
          duration: -1,
        });
      } else {
        this.fileChangeHandler(file, type, path);
      }
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canApproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
