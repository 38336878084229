<template>
  <v-col style="padding: 0px 25px;" v-if="currentProjectData && currentProjectData.name">
    <v-dialog v-model="showRevertProjectStageDialog" max-width="500">
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          style="text-align: center; display: block;"
          primary-title
          >Revert Project to Previous Stage</v-card-title
        >
        <div style="padding: 50px;">
          Select Lesson
          <v-select
            :items="currentProjectData.lessonPlans"
            :rules="[(v) => !!v || 'Lessonplans is required']"
            required
            item-text="name"
            item-value="library_id"
            v-model="revertStageData.contentId"
          />
          <span v-if="revertStageData.contentId">
            Select Stage
            <v-select
              :items="getStagesForRevert"
              :rules="[(v) => !!v || 'Stage is required']"
              required
              item-text="label"
              item-value="value"
              v-model="revertStageData.destinationStage"
            />
          </span>
          <span v-if="revertStageData.contentId && revertStageData.destinationStage">
            <v-checkbox
              v-model="revertStageData.copyBackData"
              :label="`Do you want to copy current stage data into ${revertStageData.destinationStage}`"
            ></v-checkbox>
          </span>
          <span v-if="revertProjectRequestData.status == 'not_started'">
            <v-btn @click="revertStageData.userConfirmation = true">Revert</v-btn>
            <span v-if="revertStageData.userConfirmation">
              Are you sure?
              <v-btn @click="revertToPreviousStageClickHandler()">Confirm</v-btn>
            </span>
          </span>
          <span v-else>
            {{ revertProjectRequestData.message }}
          </span>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lpuDialog.show" max-width="500">
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          style="text-align: center; display: block;"
          primary-title
          >Linked LPUs</v-card-title
        >
        <div style="padding: 10px 40px;">
          <v-row>
            <v-col cols="8">MT Title</v-col>
            <v-col cols="2">MTID</v-col>
            <v-col style="text-align: center;" cols="2">Actions</v-col>
          </v-row>
          <v-divider />
          <span v-for="item in lpuDialog.data" :key="item.microTopicId">
            <v-row>
              <v-col cols="8">{{ item.title }}</v-col>
              <v-col cols="2">{{ item.mtId }}</v-col>
              <v-col cols="2">
                <v-btn small @click="openLPU(item.microTopicId)">
                  View LPU
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
          </span>
        </div>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="switchTemplate.alert" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Are you sure about switching the type</span>
          </v-card-title>
          <v-card-text>
            Please note that this may result in data loss of many fields that you have entered
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="rejectSwitchingTemplate()">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="confirmSwitchingTemplate()">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="switchTemplate.show" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Switch Type</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    :items="getLpTypesForTypeSwitch"
                    item-text="label"
                    item-value="value"
                    label="Lp Type To Switch"
                    v-model="switchTemplate.selectedType"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="switchTemplate.show = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="initiateSwitchingTemplate()">
              Switch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col class="breadcrumb-css">
        <!-- breadcrumb-->
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{
              item.text.toUpperCase()
            }}</v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <p class="slash">/</p>
        <span>{{ currentProjectData.name }}</span>
      </v-col>
      <v-col cols="2">
        <v-btn
          v-if="currentProjectData.canPublishProject"
          :disabled="isPublishButtonDisabled"
          @click="onClickPublishProject()"
          >{{ getPublishButtonText }}</v-btn
        >
      </v-col>
      <!-- <v-col cols="2">
        <v-btn
          v-if="
            currentProjectData.type == PROJECT_TYPE_LESSON_PLAN &&
            currentProjectData.canRevertProjectToPreviousStage
          "
          @click="toggleRevertStagePopup"
          >Revert Stage</v-btn
        >
      </v-col> -->
    </v-row>
    <v-row>
      <!-- projeect title -->
      <h4>
        {{ currentProjectData.board.name }} - Grade {{ currentProjectData.standard.name }} - Block
        {{ currentProjectData.block.title }} - {{ currentProjectData.block.bId }}
      </h4>
    </v-row>
    <LessonPlanStageStepper
      style="box-shadow: 0 0 0 0;"
      :assignees="currentProjectData.assignees"
    />
    <br />
    <v-divider></v-divider>
    <v-row style="font-weight: bold; color: grey;">
      <v-col :cols="1">S.No</v-col>
      <v-col :cols="3">{{ isEarlyPlanProject ? 'DP' : 'LP' }} Name</v-col>
      <v-col :cols="3">Current Stage</v-col>
      <v-col :cols="3">Assignee</v-col>
      <v-col :cols="2">Actions</v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row v-for="(item, index) in returnsLessonPlanOrDailyPlan" :key="index">
      <v-col :cols="1">{{ (index + 1).toString() }}</v-col>
      <v-col :cols="3">{{ item.name }}</v-col>
      <v-col :cols="3">{{ LP_STAGES[item.currentStage] }}</v-col>
      <v-col :cols="3">
        {{ (currentProjectData.assignees[LP_ASSIGNEES[item.currentStage]] || { name: '-' }).name }}
      </v-col>
      <v-col :cols="2">
        <v-btn
          v-if="currentProjectData.canReadProject"
          :key="index"
          small
          class="mr-2"
          @click="onClickEditLessonPlan(item)"
          >View</v-btn
        >
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon dark>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="
                !isEarlyPlanProject && item.linkedOProgramLPUs && item.linkedOProgramLPUs.length > 0
              "
            >
              <v-btn @click="onClickViewLPUs(item.linkedOProgramLPUs)">{{
                item.linkedOProgramLPUs.length > 1 ? 'View LPUs' : 'View LPU'
              }}</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                width="250px"
                @click="
                  onClickDownloadHtml(
                    item.library_id,
                    isEarlyPlanProject,
                    currentProjectData.block.bId,
                    index + 1
                  )
                "
                >Download STA manual HTML</v-btn
              >
            </v-list-item>
            <v-list-item>
              <v-btn
                @click="
                  onClickDownloadPdf(
                    item.library_id,
                    isEarlyPlanProject,
                    currentProjectData.block.bId,
                    index + 1
                  )
                "
                >Download STA manual PDF</v-btn
              >
            </v-list-item>
            <v-list-item>
              <v-btn
                v-if="currentProject.canSwitchType && canShowSwitchType(item)"
                :key="`switchTypeButton${index}`"
                small
                class="mr-2"
                @click="showTemplateSwichClicked(item)"
                >Switch Type</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-icon>mdi-pencil</v-icon> -->
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-col>
  <v-col v-else style="margin-top: 50px;">
    <Loader
      :loadingText="AppTexts.FETCHING_DATA_TEXT"
      :loadingStatus="!(currentProjectData && currentProjectData.name)"
    />
  </v-col>
</template>

<script>
import LessonPlanStageStepper from '@/components/Admin/LessonPlanStageStepper';
import Loader from '@/components/common/Loader';
import { mapState, mapActions } from 'vuex';
import { LP_STAGES, LP_ASSIGNEES } from '@/constants/LessonPlan';
import { PROJECT_TYPE_DAILY_PLAN, PROJECT_TYPE_LESSON_PLAN } from '@/constants/Project';
import {
  PROJECT_LESSON_PLAN_STAGES_IN_ORDER,
  PROJECT_LESSON_PLAN_STAGE_KEY_TO_NAME_MAPPING,
  PROJECT_LESSON_PLAN_STAGE_KEY_TO_ASSIGNEE_KEY_NAME_MAPPING,
} from '@/constants/Project';
import {
  LP_TYPES_HASH,
  LP_TEMPLATE_TO_ROUTES_HASH,
  LP_TYPE_ABLE_TO_SWITCH_DIFFERENT_TYPE,
} from '@/constants/common';

import AppTexts from '@/constants/AppTexts';
import { startCase } from 'lodash';

const REVERT_STAGE_DEFAULT_DATA = {
  contentId: null,
  destinationStage: null,
  copyBackData: true,
  userConfirmation: false,
};

export default {
  name: 'ViewProject',
  data() {
    return {
      LP_STAGES,
      LP_ASSIGNEES,
      PROJECT_TYPE_DAILY_PLAN,
      PROJECT_TYPE_LESSON_PLAN,
      LP_TYPE_ABLE_TO_SWITCH_DIFFERENT_TYPE,
      AppTexts,
      currentProjectData: {},
      isEarlyPlanProject: false,
      items: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'projects',
          disabled: false,
          href: '/projects',
        },
      ],
      e1: 1,
      disablePublishButton: true,
      showRevertProjectStageDialog: false,
      revertStageData: JSON.parse(JSON.stringify(REVERT_STAGE_DEFAULT_DATA)),
      polling: null,
      isPublishButtonDisabled: false,
      lpuDialog: {
        show: false,
        data: [],
      },
      switchTemplate: {
        show: false,
        alert: false,
        selectedType: '',
        contentLibraryId: '',
        projectId: '',
      },
    };
  },
  created() {
    this.getProjectDetails({ projectId: this.$route.params.projectId });
  },
  computed: {
    ...mapState({
      currentProject: (state) => state.currentProject,
      revertProjectRequestData: (state) => state.revertProjectRequestData,
      currentWorkflowStatus: (state) => state.currentWorkflowStatus,
    }),
    getLpTypesForTypeSwitch() {
      return LP_TYPE_ABLE_TO_SWITCH_DIFFERENT_TYPE.map((_l) => {
        return { label: this.startCase(_l), value: _l };
      });
    },
    getPublishButtonText() {
      switch (this.currentWorkflowStatus) {
        case 'completed':
          return 'Published';
        case 'publish_in_progress':
          return 'Publish In Progress';
        default:
          return 'Publish Project';
      }
    },
    returnsLessonPlanOrDailyPlan() {
      return this.isEarlyPlanProject
        ? this.currentProjectData.dailyPlans
        : this.currentProjectData.lessonPlans;
    },
    getStagesForRevert() {
      let stages = [];
      let contentToRevert;

      if (this.currentProjectData && this.currentProjectData.lessonPlans)
        contentToRevert = this.currentProjectData.lessonPlans.find(
          (_c) => _c.library_id == this.revertStageData.contentId
        );

      if (contentToRevert) {
        const currentStageAssigneeIndex = PROJECT_LESSON_PLAN_STAGES_IN_ORDER.indexOf(
          contentToRevert.currentStage
        );
        for (let index = 0; index < currentStageAssigneeIndex; index++) {
          const previousStage = PROJECT_LESSON_PLAN_STAGES_IN_ORDER[index];

          if (
            this.currentProjectData.assignees[
              PROJECT_LESSON_PLAN_STAGE_KEY_TO_ASSIGNEE_KEY_NAME_MAPPING[previousStage]
            ]
          ) {
            stages.push({
              label: PROJECT_LESSON_PLAN_STAGE_KEY_TO_NAME_MAPPING[previousStage],
              value: previousStage,
            });
          }
        }
      }

      return stages;
    },
  },
  watch: {
    currentWorkflowStatus: {
      immediate: true,
      handler(val) {
        if (val == 'publish_in_progress') {
          this.initiatePolling();
        }
        this.getPublishButtonStatus(val);
      },
    },
    currentProject: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.lessonPlans) {
          val.assignees['author2'] = val.assignees['author'];
          this.isEarlyPlanProject = val.type == this.PROJECT_TYPE_DAILY_PLAN;
          this.currentProjectData = val;
          let projectChildren = this.isEarlyPlanProject ? val.dailyPlans : val.lessonPlans;

          let completedChildren = (projectChildren || []).filter(
            (lp) => lp.currentStage.toLowerCase() == 'completedstage'
          );

          if (
            completedChildren.length == projectChildren.length &&
            val.workflowStatus != 'completed'
          ) {
            this.disablePublishButton = false;
          } else {
            this.disablePublishButton = true;
          }
        }
      },
    },
  },
  components: {
    Loader,
    LessonPlanStageStepper,
  },
  beforeUpdate() {
    this.$forceUpdate();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    startCase,
    ...mapActions([
      'getProjectDetails',
      'publishProjectLpsToTeacherReadinessApp',
      'revertProjectToPreviousStage',
      'setDeafultRevertProjectStatus',
      'getProjectStatus',
      'switchProjectLessonPlanTemplate',
      'generateHTMLTemplate',
      'generatePdfTemplate',
    ]),
    canShowDropDown(item) {
      return (
        (item.linkedOProgramLPUs && item.linkedOProgramLPUs.length > 0) ||
        (this.currentProject.canSwitchType && this.canShowSwitchType(item))
      );
    },
    showTemplateSwichClicked(item) {
      this.switchTemplate.selectedType = item.type;
      this.switchTemplate.show = true;
      this.switchTemplate.contentLibraryId = item.library_id;
      this.switchTemplate.projectId = this.$route.params.projectId;
    },
    canShowSwitchType(item) {
      return (
        LP_TYPE_ABLE_TO_SWITCH_DIFFERENT_TYPE.includes(item.type) &&
        item.currentStage != 'completedStage'
      );
    },
    rejectSwitchingTemplate() {
      this.switchTemplate.selectedType = '';
      this.switchTemplate.show = false;
      this.switchTemplate.alert = false;
      this.switchTemplate.contentLibraryId = '';
      this.switchTemplate.projectId = '';
    },
    confirmSwitchingTemplate() {
      this.switchProjectLessonPlanTemplate(this.switchTemplate);
    },
    initiateSwitchingTemplate() {
      this.switchTemplate.alert = true;
    },
    getPublishButtonStatus(currentValue) {
      this.isPublishButtonDisabled =
        currentValue == 'completed' || currentValue == 'publish_in_progress';
    },
    initiatePolling: function () {
      const self = this;
      const projectId = this.$route.params.projectId;
      this.polling = setInterval(function () {
        self.getProjectStatus(projectId);
      }, 10000);
    },
    onClickEditLessonPlan(item) {
      if (this.isEarlyPlanProject) {
        this.$router.push({
          name: 'dailyPlanEditPage',
          params: {
            dailyPlanLibraryId: item.library_id,
            projectId: this.$route.params.projectId,
          },
        });
      } else {
        let routeName =
          item.type == LP_TYPES_HASH.VIRTUAL_QUESTIONS
            ? 'StandardLPForm'
            : LP_TEMPLATE_TO_ROUTES_HASH[item.template];

        this.$router.push({
          name: routeName,
          params: {
            libraryId: item.library_id,
            projectId: this.$route.params.projectId,
          },
          query: {
            lpType: item.type,
            template: item.template,
            blockSequence: '',
            blockTitle: this.currentProjectData.block.title,
            bId: this.currentProjectData.block.bId,
            productName: this.currentProjectData.product.name,
            grade: this.currentProjectData.standard.name,
            subject: this.currentProjectData.subject.name,
            boardName: this.currentProjectData.board.name,
          },
        });
        this.$router.go();
      }
    },
    onClickPublishProject() {
      this.publishProjectLpsToTeacherReadinessApp(this.$route.params.projectId);
    },
    onClickDownloadHtml(library_id, isEarlyProgram, blockId, index) {
      this.generateHTMLTemplate({
        library_id: +library_id,
        isEarlyProgram: isEarlyProgram,
        blockId: blockId,
        index: index,
      });
    },
    onClickDownloadPdf(library_id, isEarlyProgram, blockId, index) {
      this.generatePdfTemplate({
        library_id: +library_id,
        isEarlyProgram: isEarlyProgram,
        blockId: blockId,
        index: index,
      });
    },
    toggleRevertStagePopup() {
      this.setDeafultRevertProjectStatus();
      this.showRevertProjectStageDialog = true;
      this.revertStageData = JSON.parse(JSON.stringify(REVERT_STAGE_DEFAULT_DATA));
    },
    revertToPreviousStageClickHandler() {
      if (this.currentProjectData.canRevertProjectToPreviousStage == true) {
        this.revertProjectToPreviousStage({
          projectId: this.$route.params.projectId,
          contentId: this.revertStageData.contentId,
          destinationStage: this.revertStageData.destinationStage,
          copyBackData: this.revertStageData.copyBackData,
        });
      }
    },
    openLPU(microTopicId) {
      let routeData = this.$router.resolve({ name: 'MicroTopicView', params: { microTopicId } });
      window.open(routeData.href, '_blank');
    },
    onClickViewLPUs(linkedLPUs) {
      if (linkedLPUs) {
        this.lpuDialog.data = [];
        this.lpuDialog.show = false;
        if (linkedLPUs.length == 1) {
          this.openLPU(linkedLPUs[0].microTopicId);
        } else if (linkedLPUs.length > 1) {
          this.lpuDialog.data = linkedLPUs;
          this.lpuDialog.show = true;
        }
      }
    },
  },
};
</script>
<style>
.breadcrumb-css {
  display: flex;
  flex-direction: row;
}
.breadcrumb-css span {
  padding: 18px 12px 18px 8px;
  cursor: pointer;
  color: #bb151b;
}
.slash {
  padding: 0;
  color: rgba(0, 0, 0, 0.38);
  margin: 18px 0 0 0;
}
</style>
