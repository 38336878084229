<template>
  <v-dialog
    persistent
    v-if="showGroupQuestionDialog"
    :value="showGroupQuestionDialog"
    scrollable
    color="white"
    :width="setDialogWidth"
  >
    <v-card class="text-center mgn-al" v-if="showLoader">
      <p class="pt-4">
        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
      </p>
      <p>Saving question....</p>
    </v-card>
    <v-card
      class="fix-height mt-6"
      color="white"
      v-if="data && data.length > 0 && !showLoader"
      style="overflow-x: hidden;"
    >
      <div style="background-color: #f6f6f6; padding: 10px; height: 56px; text-align: right;">
        <v-btn color="primary" text @click="closeDialog">Close<v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-row>
        <v-col :cols="groupQuestionCommentsToggle || setGroupQuestionDiffPanel ? 8 : 12">
          <v-sheet color="white" class="pl-8 pr-8 pt-4 pb-4">
            <v-row>
              <v-col>
                <v-row dense>
                  <v-col>
                    <SubSection title="Group Questions" />
                  </v-col>
                  <v-spacer />
                  <v-col>
                    <v-btn
                      v-if="!options.hideComments"
                      :disabled="questionGroupId == null"
                      class="comment-btn"
                      @click="toggleComments"
                      ><span style="color: #fff; font-size: 18px;">COMMENT</span></v-btn
                    >
                  </v-col>
                </v-row>
                <v-spacer />
                <div>Stimulus</div>
                <RichTextEditor
                  :key="`groupQuestionStimulusText-${stimulusRevision}`"
                  :readonly="false"
                  :canShowDiffIcon="!newQuestionGroup"
                  v-model="stimulus"
                  placeholder="Enter question Stimulus"
                  :fieldIdentifier="
                    newQuestionGroup
                      ? ''
                      : `['${data.find((element) => element._id != '')._id}'].stimulus`
                  "
                  projectType="question"
                  subType="group"
                  :showValidationError="true"
                  :includeOnlyToolBarActions="getToolBarActions()"
                  @input="onChangeStimulusText"
                  :isAssetUploadRequired="options.isAssetUploadRequired"
                  :forceAssetFinalized="questionsCurrentStage == 'assetAuthorizingStage'"
                  :acceptedFileTypes="getRTEAcceptedFileType"
                />
                <v-row>
                  <v-col
                    v-for="(image, index) in imagesInStimulusText"
                    :key="`imagesInQuestionText${imageContentRevision}${index}`"
                    :cols="3"
                  >
                    <FileInput
                      :canShowDiffIcon="false"
                      :diffIdentifier="''"
                      :fileAcceptType="'Image/*'"
                      :label="'Image'"
                      :loadingStatus="false"
                      :isRequired="options.isAssetUploadRequired"
                      :isAssetUploadRequired="options.isAssetUploadRequired"
                      :fileChangeHandler="(file) => fileChangeHandler(file, index)"
                      :previewUrl="image.attrs.src"
                      :previewUrlBrief="image.attrs.briefSrc"
                      :image_url="image.attrs.assetId"
                      :image_url_brief="image.attrs.briefAssetId"
                      :isNotReadOnly="options.isNotReadOnly"
                      :canAppproveAsset="canAppproveAsset"
                      :ApproveAssetAndEmitResult="true"
                      :canFinalizeAsset="true"
                      :useAssetVault="true"
                      @addBriefToAsset="
                        (previewUrl, briefUrl, approveAsset) =>
                          addToAsset(previewUrl, briefUrl, approveAsset, index)
                      "
                      :isAssetApproved="image.attrs.assetApproved"
                      @assetApprovedByUser="(val) => approveAssets(val, index)"
                    />
                    <br />
                    <RichTextEditor
                      :readonly="options.isAssetUploadRequired"
                      :canShowDiffIcon="false"
                      v-model="image.attrs.brief"
                      placeholder="Enter Image Brief"
                      projectType="question"
                      :showValidationError="false"
                      :includeOnlyToolBarActions="getToolBarActionsForQuestionBriefs()"
                      @input="addBriefTextAtPosition(image.attrs.brief, index)"
                      :removeAllPluginExceptFullScreen="true"
                      :counter="3000"
                      :allowCharacterCount="true"
                    />
                  </v-col>
                </v-row>
                <div style="display: flex; align-items: center;" class="mt-16">
                  <v-dialog v-model="showDeleteConfirmationDialog" width="350">
                    <v-card>
                      <v-card-title class="headline">Remove Question?</v-card-title>
                      <v-card-text
                        >Are you sure you want to do this? This is an irreversible
                        action</v-card-text
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="quitQuestionDeletion">Cancel</v-btn>
                        <v-btn color="primary" @click="confirmQuestionDeletion"
                          >Remove Question</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <div style="display: flex;">
                    <v-tabs
                      v-model="tab"
                      background-color="#F7F7F7"
                      class="elevation-2"
                      :grow="true"
                      @change="questionChange"
                    >
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab
                        :style="`width: 134px; font-size: 10px;`"
                        v-for="index in data.length"
                        :key="`groupQuestionTab${index}`"
                        :href="`#tab-${index}`"
                      >
                        Question {{ index }}
                        <v-btn
                          v-if="data.length > 1 && canDeleteQuestionFromGroup"
                          icon
                          @click="deleteQuestionFromTab(index - 1)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-tab>
                    </v-tabs>
                  </div>
                  <div style="display: flex;">
                    <v-btn
                      v-if="canAddNewQuestionToGroup"
                      class="xs-accent-button font-weight-medium ml-4"
                      width="165"
                      small
                      @click="addQuestion"
                      :disabled="data.length > 5"
                      ><v-icon left>mdi-plus</v-icon> Add question</v-btn
                    >
                  </div>
                </div>
                <v-tabs-items v-model="tab" :key="`itemHolderForTab${revision}`">
                  <v-tab-item
                    v-for="(question, index) in data"
                    :key="`groupQuestionTabItem${index + 1}`"
                    :value="`tab-${index + 1}`"
                  >
                    <DialogContents
                      :data="question"
                      :newlyCreatedQuestion="notDeleteType[index]"
                      :changeInQuestionType="
                        (questionType) => confirmAndChangeQuestionType(index, questionType)
                      "
                      :closeDialog="() => {}"
                      :key="`questionGroupDialog${refreshKey}${index + 1}`"
                      :showCloseButton="false"
                      :wrapAnswerBlockInContainer="true"
                      :options="options"
                      :canAppproveAsset="canAppproveAsset"
                      :lpType="lpType"
                      :lessonPlans="lessonPlans"
                      :onWritingLinesOrDrawingBoxUpdate="onWritingLinesOrDrawingBoxUpdate"
                      :onQuestionTypeOrPurposeUpdate="onQuestionTypeOrPurposeUpdate"
                      :isLanguageSubject="isLanguageSubject"
                      :showMetaFields="showMetaFields"
                      :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
                      :dynamicMetaDataKeySourceDataMap="dynamicMetaDataKeySourceDataMap"
                    />
                  </v-tab-item>
                </v-tabs-items>
                <section v-if="isSchoolProgramQuestion">
                  <Padder :horizontal="50">
                    <section v-if="isAssessmentPurposePresent">
                      <XSDivider />
                      <AnswerCriteriaInput
                        :answerCriteria="answerCriteria || []"
                        @onUpdate="onAnswerCriteriaUpdate"
                      />
                    </section>
                    <XSDivider />
                    <SubSection title="Teacher tip" />
                    <RichTextEditor
                      :readonly="options.isReadOnly"
                      :canShowDiffIcon="!newQuestionGroup && options.canShowDiffIcon"
                      v-model="teacherTip"
                      placeholder="Teacher tip"
                      :fieldIdentifier="
                        newQuestionGroup
                          ? ''
                          : `['${data.find((element) => element._id != '')._id}'].teacherTip`
                      "
                      projectType="question"
                      subType="group"
                      :showValidationError="false"
                      :includeOnlyToolBarActions="getToolBarActions(false)"
                      @input="onTecherTipChanged"
                      :isAssetUploadRequired="options.isAssetUploadRequired"
                      :forceAssetFinalized="questionsCurrentStage == 'assetAuthorizingStage'"
                      :acceptedFileTypes="getRTEAcceptedFileType"
                    />
                  </Padder>
                </section>
                <v-row class="question-creation-dialog-action-buttons">
                  <v-col>
                    <p class="text-center">
                      <v-btn
                        width="300"
                        style="font-size: 18px; border: 1px solid #ccc;"
                        depressed
                        class="mr-4 font-weight-medium"
                        @click="closeDialog"
                        >Cancel</v-btn
                      >
                      <v-btn
                        width="300"
                        style="font-size: 18px;"
                        depressed
                        color="primary"
                        class="font-weight-medium"
                        @click="closeDialogAndSaveContent"
                        >Save</v-btn
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col v-if="setGroupQuestionDiffPanel">
          <v-col class="fix-height-diff-comments" style="box-shadow: -2px 0px 2px 1px #ccc;">
            <div>
              <Diff
                :fieldDiff="fieldDiff"
                :closeHandler="diffCloseHandler"
                :currentStage="questionsCurrentStage"
                title="Stimulus"
                :isForContentEntry="true"
              />
            </div>
          </v-col>
        </v-col>
        <v-col v-if="groupQuestionCommentsToggle">
          <v-col class="fix-height-diff-comments" style="box-shadow: -2px 0px 2px 1px #ccc;">
            <div v-if="questionCommentsLoader" style="overflow: hidden;" class="text-center">
              <p class="pt-4">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
              </p>
              <p>Fetching Comments....</p>
            </div>
            <div v-else>
              <div style="background: #eee; height: 56px;">
                <v-col style="text-align: right;">
                  <v-btn color="primary" class="mt-1 mr-2" text @click="toggleComments"
                    >Close<v-icon>mdi-close</v-icon></v-btn
                  >
                </v-col>
              </div>
              <Comments
                :comments="
                  commentsHash[questionGroupId] ? commentsHash[questionGroupId].comments : {}
                "
                :currentStage="questionsCurrentStage"
                :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
                :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
                :canAddComment="auth.canAddComment"
                :canResolveComment="auth.canResolveComment"
                :loggedInUserEmail="loggedInUserEmail ? loggedInUserEmail : ''"
                :saveStatusesHash="
                  commentsHash[questionGroupId]
                    ? commentsHash[questionGroupId].saveStatusesHash
                    : {}
                "
                :resolveStatusesHash="
                  commentsHash[questionGroupId]
                    ? commentsHash[questionGroupId].resolveStatusesHash
                    : {}
                "
                :commentSectionIdentifier="questionGroupId"
              />
            </div>
          </v-col>
        </v-col>
      </v-row>
      <WarningPopup
        title="Retain question data?"
        message="Options will not be retained. Do you want to proceed?"
        :showWarningPopup="showWarningPopup"
        :cancleHandler="
          () => {
            refreshKey += 1;
          }
        "
        :confirmHandler="() => this.changeInQuestionType()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import DialogContents from '@/components/contentEntry/questionComponents/dialogComponents/DialogContents.vue';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import SubSection from '@/components/inputs/SubSection.vue';
import Comments from '@/components/comments/Comments';
import _ from 'lodash';
import { QUESTIONS_TYPE } from '@/constants/microTopic.js';
import { validateQuestionDataBasedOnType, validateAnswerCriteria } from '@/utils/questionUtils.js';
import FileInput from '@/components/inputs/FileInput';
import { LP_TYPES_HASH } from '@/constants/common';
import {
  isRteEmpty,
  getImageUrlFromRte,
  deleteImageInRTE,
  insertImageAssetAtPosition,
  approveAssetAtPosition,
  addBriefToAssetInPosition,
  deleteQuestionSpecificItems,
} from '@xseededucation/rte-helper-functions';
import Diff from '@/components/ContentDiff/Diff';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import AnswerCriteriaInput from '@/components/Questions/AnswerCriteriaInput.vue';
import { WRITING_LINES_HASH, DRAWING_BOX_OPTION_NONE } from '@/constants/question';
import WarningPopup from '@/components/common/WarningPopup.vue';

let LOCAL_STORAGE_BADGE_MAX_COUNT = 5;
let LOCAL_STORAGE_BADGE_COUNT = 0;

export default {
  data() {
    return {
      questionsOprogramProject,
      QUESTIONS_TYPE,
      stimulus: '',
      tab: null,
      DEFAULT_QUESTION_DATA,
      questionGroupId: null,
      newQuestionGroup: false,
      currentTab: 0,
      revision: 0,
      showDeleteConfirmationDialog: false,
      deletionIndex: 0,
      notDeleteType: new Array(6).fill(true),
      imagesInStimulusText: [],
      imageContentRevision: 0,
      stimulusRevision: 0,
      LP_TYPES_HASH,
      answerCriteria: [],
      teacherTip: '',
      WRITING_LINES_HASH,
      DRAWING_BOX_OPTION_NONE,
      deletedQuestions: [],
      selectedQuestion: {
        index: null,
        type: null,
      },
      refreshKey: 0,
    };
  },
  methods: {
    ...mapActions([
      'instantiateContentEntryProjectData',
      'setGroupQuestionDataInStore',
      'setQuestionRightSidePanel',
      'setGroupQuestionRightSidePanel',
      'closeDialogAndClearQuestionData',
      'fetchProjectLessonPlanSectionComment',
      'saveComment',
      'resolveComment',
      'deleteContentEntryQuestion',
      'questionForceSaveWithData',
      'showWarningDialog',
    ]),
    ...mapMutations([
      'SET_SHOW_GROUP_QUESTION_DIALOG',
      'SET_GROUP_QUESTION_COMMENTS_DIALOG',
      'setQuestionRightSidePanel',
      'setGroupQuestionRightSidePanel',
      'SET_COMMENTS_DIALOG',
    ]),
    validateAnswerCriteria,
    async cacheQuestionGroupDataInLocalStorage() {
      LOCAL_STORAGE_BADGE_COUNT++;

      if (LOCAL_STORAGE_BADGE_COUNT >= LOCAL_STORAGE_BADGE_MAX_COUNT) {
        LOCAL_STORAGE_BADGE_COUNT = 0;
        let questionGroupData = this.data;

        for (let singleQuestionData of questionGroupData) {
          if (this.stimulus) singleQuestionData.stimulus = this.stimulus;

          if (this.isSchoolProgramQuestion) {
            if (this.answerCriteria) singleQuestionData.answerCriteria = this.answerCriteria;
            if (this.teacherTip) singleQuestionData.teacherTip = this.teacherTip;
          }
        }

        localStorage.setItem('QUESTION_CACHED_DATA', JSON.stringify(questionGroupData));
      }
    },
    onChangeStimulusText(jsonString) {
      let jsonData = JSON.parse(jsonString);
      this.getImagesListFromStimulusText(jsonData);
    },
    getToolBarActions(supportImageUpload = true) {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        ...(supportImageUpload ? ['image'] : []),
        'table',
        'fullScreen',
      ];
    },
    addBriefTextAtPosition(rteData, index) {
      let stimulusText = JSON.parse(this.stimulus);
      addBriefToAssetInPosition(stimulusText.content, index, 0, rteData);
      this.stimulus = JSON.stringify(stimulusText);
    },
    addToAsset(previewUrl, briefUrl, approveAsset, index) {
      let stimulus = JSON.parse(this.stimulus);
      approveAssetAtPosition(
        stimulus.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        approveAsset,
        previewUrl,
        briefUrl
      );
      this.getImagesListFromStimulusText(stimulus);
      this.stimulus = JSON.stringify(stimulus);
      this.stimulusRevision += 1;
      this.imageContentRevision += 1;
    },
    approveAssets(val, index) {
      let stimulus = JSON.parse(this.stimulus);
      approveAssetAtPosition(stimulus.content, index, 0, this.options.isAssetUploadRequired, val);
      this.getImagesListFromStimulusText(stimulus);
      this.stimulus = JSON.stringify(stimulus);
      this.stimulusRevision += 1;
      this.imageContentRevision += 1;
    },
    fileChangeHandler(file, index) {
      if (!file) {
        let stimulus = JSON.parse(this.stimulus);
        deleteImageInRTE(stimulus.content, index, 0, this.options.isAssetUploadRequired);
        this.getImagesListFromStimulusText(stimulus);
        this.stimulus = JSON.stringify(stimulus);
        this.stimulusRevision += 1;
        this.imageContentRevision += 1;
      } else {
        let stimulus = JSON.parse(this.stimulus);
        insertImageAssetAtPosition(
          stimulus.content,
          index,
          0,
          file,
          this.options.isAssetUploadRequired
        );
        this.getImagesListFromStimulusText(stimulus);
        this.stimulus = JSON.stringify(stimulus);
        this.stimulusRevision += 1;
        this.imageContentRevision += 1;
      }
    },
    getImagesListFromStimulusText(jsonData) {
      let imagesInStimulusText = [];
      getImageUrlFromRte(jsonData.content, imagesInStimulusText);
      this.imagesInStimulusText = imagesInStimulusText;
      this.imageContentRevision += 1;
    },
    confirmQuestionDeletion() {
      this.notDeleteType = new Array(6).fill(false);
      let data_id = this.data[this.deletionIndex]._id;
      if (data_id) {
        this.deleteContentEntryQuestion(data_id);
      }
      this.deletedQuestions.push(this.data[this.deletionIndex]._id);
      this.$delete(this.data, this.deletionIndex);
      this.revision += 1;
      this.showDeleteConfirmationDialog = false;
    },
    deleteQuestionFromTab(index) {
      this.deletionIndex = index;
      this.showDeleteConfirmationDialog = true;
    },
    quitQuestionDeletion() {
      this.notDeleteType = new Array(6).fill(true);
      this.showDeleteConfirmationDialog = false;
    },

    addQuestion() {
      this.SET_GROUP_QUESTION_COMMENTS_DIALOG(false);
      this.SET_COMMENTS_DIALOG(false);
      this.setQuestionRightSidePanel(false);
      this.setGroupQuestionRightSidePanel(false);
      const firstQuestionData = this.data[0];
      let defaultData = _.cloneDeep(DEFAULT_QUESTION_DATA.mcq);
      if (this.modifyChangeQuestionBaseData) {
        defaultData = this.modifyChangeQuestionBaseData(defaultData);
      }
      const firstQuestionMetaData = {
        ...defaultData,
        time: firstQuestionData.time,
        academicYear: firstQuestionData.academicYear,
        skill: firstQuestionData.skill,
        subSkill: firstQuestionData.subSkill,
        questionPurpose: firstQuestionData.questionPurpose,
        difficulty: firstQuestionData.difficulty,
        machineScorable: firstQuestionData.machineScorable,
        stimulus: firstQuestionData.stimulus,
        questionGroupId: firstQuestionData.questionGroupId,
        questionSequenceInGroup: firstQuestionData.questionSequenceInGroup,
        scorePercentage: firstQuestionData.scorePercentage,
        lessonPlanLibraryId: firstQuestionData.lessonPlanLibraryId,
        languageSkill: firstQuestionData.languageSkill,
      };
      this.data.push(_.cloneDeep(firstQuestionMetaData));
      this.notDeleteType[this.data.length - 1] = true;
      this.tab = `tab-${this.data.length}`;
      this.setCurrentTab();
    },
    notifyUsersWithError(text) {
      this.$notify({
        title: 'Fix the following',
        text: text.join('<br/>'),
        type: 'error',
        duration: -1,
      });
    },
    confirmAndChangeQuestionType(index, newQuestionType) {
      this.selectedQuestion.index = index;
      this.selectedQuestion.type = newQuestionType;
      if (this.data[index].questionType && newQuestionType != this.data[index].questionType) {
        if (
          this.data[index]._id &&
          this.checkIfOptionsWillNotBeRetained(this.data[index].questionType, newQuestionType)
        ) {
          this.showWarningDialog();
        } else {
          this.changeInQuestionType();
        }
      }
    },
    changeInQuestionType() {
      //This is to enforce vue's reactivity
      const index = this.selectedQuestion.index;
      const newQuestionType = this.selectedQuestion.type;
      if (
        this.data[index].questionType &&
        newQuestionType != this.data[index].questionType &&
        this.notDeleteType[index]
      ) {
        //Deconstructs questionData and places rest of the keys under metaInformation
        var { questionData, ...metaInformation } = this.data[index];
        var { questionText } = questionData;
        var defaultQuestionData = _.cloneDeep(this.DEFAULT_QUESTION_DATA[newQuestionType]);
        if (this.modifyChangeQuestionBaseData) {
          defaultQuestionData = this.modifyChangeQuestionBaseData(defaultQuestionData);
        }
        this.$set(this.data, index, defaultQuestionData);
        //Sanitized current question data by removing question specific items like blanks
        let processedQuestionText = this.sanitizeCurrentQuestionText(questionText);
        this.data[index].questionData.questionText = processedQuestionText;
        this.modifyQuestionDataHash({ questionData, ...metaInformation }, this.data[index]);

        //Copies question text and meta information while changing question type
        if (
          this.lpType == 'questions' &&
          (this.data[index].questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
            this.data[index].questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA)
        ) {
          metaInformation.questionPurpose = questionsOprogramProject.QUESTION_PURPOSE_PRACTICE;
        }
        metaInformation.questionType = this.data[index].questionType;
        Object.assign(this.data[index], metaInformation);
      }
      if (this.notDeleteType[index] == false) {
        this.notDeleteType[index] = true;
        this.$forceUpdate();
      }
    },
    sanitizeCurrentQuestionText(questionText) {
      try {
        let parsedQuestionText = JSON.parse(questionText);
        deleteQuestionSpecificItems(parsedQuestionText.content);
        return JSON.stringify(parsedQuestionText);
      } catch (e) {
        if (e instanceof SyntaxError) {
          return questionText;
        } else {
          console.log('You have error while sanitizing current question text');
          console.log(e);
        }
      }
    },
    closeDialog() {
      this.SET_GROUP_QUESTION_COMMENTS_DIALOG(false);
      this.SET_COMMENTS_DIALOG(false);
      this.setQuestionRightSidePanel(false);
      this.setGroupQuestionRightSidePanel(false);
      this.closeDialogAndClearQuestionData();
      localStorage.removeItem('QUESTION_CACHED_DATA');
    },
    questionChange() {
      this.SET_GROUP_QUESTION_COMMENTS_DIALOG(false);
      this.SET_COMMENTS_DIALOG(false);
      this.setQuestionRightSidePanel(false);
      this.setGroupQuestionRightSidePanel(false);
      this.setCurrentTab();
      if (this.notDeleteType[this.currentTab] == false) {
        this.notDeleteType[this.currentTab] = true;
        this.$forceUpdate();
      }
    },
    toggleComments() {
      this.SET_GROUP_QUESTION_COMMENTS_DIALOG(!this.groupQuestionCommentsToggle);
      this.SET_COMMENTS_DIALOG(false);
      this.setQuestionRightSidePanel(false);
      this.setGroupQuestionRightSidePanel(false);
      this.fetchProjectLessonPlanSectionComment({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
        sectionIdentifier: this.questionGroupId,
        projectType: QUESTIONS_TYPE,
      });
    },
    async closeDialogAndSaveContent() {
      let text = this.validateQuestions(this.data);
      if (text.length == 0) {
        this.data.map((datum, index) => {
          if (!datum.questionGroupId) {
            datum.questionGroupId = this.newQuestionGroup ? 'to_generate' : this.questionGroupId;
          }
          datum.questionSequenceInGroup = index;
          datum.stimulus = this.stimulus;

          if (this.lpType == 'learnometerQuestion') {
            datum.isEdited = true;
          }

          if (this.isSchoolProgramQuestion) {
            datum.answerCriteria = this.answerCriteria;
            datum.teacherTip = this.teacherTip;
          }

          return;
        });

        if (this.newQuestionGroup) {
          await this.instantiateContentEntryProjectData({
            type: this.lpType,
            data: this.data,
          });
        } else {
          let newQuestions = _.cloneDeep(this.data.filter((element) => !element._id));
          let questionsWithId = _.cloneDeep(this.data.filter((element) => !!element._id));

          if (newQuestions.length > 0) {
            await this.instantiateContentEntryProjectData({
              type: this.lpType,
              data: newQuestions,
            });
          }
          this.setGroupQuestionDataInStore({
            updatedQuestions: questionsWithId,
            deletedQuestions: this.deletedQuestions,
          });
        }
        this.closeDialog();

        this.questionForceSaveWithData({
          validateForm: false,
          showLoader: false,
          callback: this.saveForm,
        });
        localStorage.removeItem('QUESTION_CACHED_DATA');
      } else {
        this.notifyUsersWithError(text);
      }
    },
    commentsSaveButtonClickHandler(text, stageName) {
      if (this.auth.canAddComment) {
        this.saveComment({
          projectId: this.$route.params.projectId,
          sectionIdentifier: this.questionGroupId,
          text,
          stageName,
        });
      }
    },
    commentsResolveButtonClickHandler(stageName) {
      if (this.auth.canResolveComment) {
        this.resolveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.questionGroupId,
          stageName,
        });
      }
    },
    validateQuestions(data) {
      let text = [];
      let scorePercentage = 0;
      data.forEach((datum, index) => {
        let individualErrorText = validateQuestionDataBasedOnType(
          datum,
          this.lpType,
          this.isLanguageSubject,
          this.showMetaFields
        );
        if (individualErrorText.length != 0) {
          text.push(`Following errors in question ${index + 1}:`);
          text.push(...individualErrorText);
        }
        if (datum.questionPurpose != this.data[0].questionPurpose) {
          if (individualErrorText.length == 0) {
            text.push(`Following errors in question ${index + 1}:`);
          }
          text.push('- Please enter valid question purpose');
        }
        scorePercentage += parseInt(datum.scorePercentage);
      });
      if (isRteEmpty(this.stimulus)) {
        text.push('Please fill stimulus field');
      }
      if (this.showMetaFields && scorePercentage != 100) {
        text.push('The sum of Score Percentage must be 100');
      }

      if (this.isSchoolProgramQuestion && this.isAssessmentPurposePresent) {
        const answerCriteriaErrorMessages = this.validateAnswerCriteria(this.answerCriteria);
        text = text.concat(answerCriteriaErrorMessages || []);
      }

      return text;
    },
    setCurrentTab() {
      this.currentTab = parseInt(this.tab.split('-').pop());
    },
    diffCloseHandler() {
      this.setGroupQuestionRightSidePanel(false);
    },
    getToolBarActionsForQuestionBriefs() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'table',
        'fullScreen',
      ];
    },
    onAnswerCriteriaUpdate(data) {
      if (this.isSchoolProgramQuestion) {
        this.answerCriteria = data;
        this.cacheQuestionGroupDataInLocalStorage();
      }
    },
    onTecherTipChanged(val) {
      if (this.isSchoolProgramQuestion) {
        this.teacherTip = val;
        this.cacheQuestionGroupDataInLocalStorage();
      }
    },
    /* eslint-disable no-unused-vars */
    onWritingLinesOrDrawingBoxUpdate(field, updateValue) {
      // if (this.lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS) {
      //   for (const questionIndex in this.data) {
      //     const question = this.data[questionIndex];
      //     if (
      //       (question.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT || question.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_WORKSHEET) &&
      //       [
      //         questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
      //         questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
      //       ].includes(question.questionType) &&
      //       question[field] != updateValue
      //     ) {
      //       this.$set(this.data[questionIndex], field, updateValue);
      //     }
      //   }
      // }
    },
    onQuestionTypeOrPurposeUpdate() {
      // if (this.lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS) {
      //   const firstQuestionIndex = this.data.findIndex(
      //     (question) =>
      //      (question.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT || question.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_WORKSHEET) &&
      //       [
      //         questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
      //         questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
      //       ].includes(question.questionType)
      //   );
      //   const firstQuestion = this.data[firstQuestionIndex];
      //   if (firstQuestion) {
      //     if (firstQuestion.writingLines == undefined && firstQuestion.drawingBox == undefined) {
      //       this.$set(this.data[firstQuestionIndex], 'writingLines', WRITING_LINES_HASH.min);
      //       this.$set(this.data[firstQuestionIndex], 'drawingBox', DRAWING_BOX_OPTION_NONE);
      //     }
      //     for (let index = 0; index < this.data.length; index++) {
      //       const question = this.data[index];
      //       if (
      //         question.questionPurpose ==
      //           this.questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT &&
      //         [
      //           questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
      //           questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
      //         ].includes(question.questionType)
      //       ) {
      //         this.$set(this.data[index], 'writingLines', firstQuestion['writingLines']);
      //         this.$set(this.data[index], 'drawingBox', firstQuestion['drawingBox']);
      //       }
      //     }
      //   }
      // }
    },
  },
  computed: {
    ...mapState({
      showGroupQuestionDialog: (state) => state.questions.showGroupQuestionDialog,
      data: (state) => state.questions.currentQuestionDialogData,
      showLoader: (state) => state.questions.questionDialogLoader,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      contentEntryProjectFormAuth: (state) => state.auth.contentEntryProjectForm,
      lessonPlanFormAuth: (state) => state.auth.lessonPlan,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      questionsCurrentStage: (state) => state.questions.currentStage,
      groupQuestionCommentsToggle: (state) => state.questions.groupQuestionCommentsToggle,
      questionCommentsLoader: (state) => state.rightSideContent.questionCommentsLoader,
      questionCommentsToggle: (state) => state.questions.questionCommentsToggle,
      setGroupQuestionDiffPanel: (state) => state.rightSideContent.setGroupQuestionDiffPanel,
      fieldDiff: (state) => state.rightSideContent.fieldDiff,
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
      warningDialog: (state) => state.questions.showWarningDialog,
    }),
    //NOTE: This is temproary will be changed once RTE image upload implementation is across the portal
    getRTEAcceptedFileType() {
      return this.options.isAssetUploadRequired
        ? ['image/png', 'image/jpeg', 'video/mp4', 'audio/mpeg']
        : [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'video/mp4',
            'audio/mpeg',
          ];
    },
    setDialogWidth() {
      return this.showLoader
        ? '200px'
        : this.questionCommentsToggle || this.setQuestionDiffPanel
        ? '1400px'
        : '1100px';
    },
    auth() {
      if (this.lpType == 'questions') {
        return this.contentEntryProjectFormAuth;
      } else {
        return this.lessonPlanFormAuth;
      }
    },
    isAssessmentPurposePresent() {
      return this.data.find(
        (_) => _.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT
      );
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val != '') {
          if (val.findIndex((element) => element.questionGroupId != null) == -1) {
            this.newQuestionGroup = true;
          }

          if (this.questionGroupId == null) {
            val.forEach((question) => {
              if (question.questionGroupId != null && !this.questionGroupId)
                this.questionGroupId = question.questionGroupId;

              if (question.stimulus != null && !this.stimulus) this.stimulus = question.stimulus;

              if (
                question.answerCriteria != null &&
                !(this.answerCriteria && this.answerCriteria.length)
              )
                this.answerCriteria = question.answerCriteria;
              if (question.teacherTip != null && (!this.teacherTip || this.teacherTip == ''))
                this.teacherTip = question.teacherTip;
            });

            this.getImagesListFromStimulusText(JSON.parse(this.stimulus));
          }

          this.cacheQuestionGroupDataInLocalStorage();
        }
      },
    },
    stimulus: {
      immediate: true,
      deep: true,
      handler() {
        this.cacheQuestionGroupDataInLocalStorage();
      },
    },
  },
  mounted() {
    this.tab = 0;
    this.setCurrentTab();
  },
  props: {
    saveForm: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    lpType: {
      type: String,
      default: 'questions',
    },
    lessonPlans: {
      type: Array,
      required: false,
    },
    isLanguageSubject: {
      type: Boolean,
      defualt: false,
    },
    isSchoolProgramQuestion: {
      type: String,
      required: true,
    },
    modifyQuestionDataHash: {
      type: Function,
      required: true,
    },
    checkIfOptionsWillNotBeRetained: {
      type: Function,
      required: true,
    },
    showMetaFields: {
      type: Boolean,
      required: true,
    },
    modifyChangeQuestionBaseData: {
      type: Function,
      default: undefined,
    },
    dynamicMetaDataKeySourceDataMap: {
      type: Object,
      default: () => {},
    },
    canAddNewQuestionToGroup: {
      type: Boolean,
      default: true,
    },
    canDeleteQuestionFromGroup: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DialogContents,
    RichTextEditor,
    SubSection,
    Comments,
    Diff,
    FileInput,
    AnswerCriteriaInput,
    WarningPopup,
  },
};
</script>
<style scoped>
.fix-height {
  overflow-y: scroll;
  height: 80vh;
  z-index: 999;
  padding: 0px;
}
.fix-height-diff-comments {
  overflow-y: scroll;
  height: 80vh;
  z-index: 999;
  padding: 10px;
}
</style>
