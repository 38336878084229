<template>
  <section>
    <BlockTitleWithSubtitle :title="AppTexts.CHECK" />
    <div class="mgn-Ts">
      <span class="workseet-text">Worksheet {{ classworkDescription }}</span>
      <v-row dense>
        <v-col cols="6" class="pad-Rm">
          <FileInput
            :image_url="isAssetUploadRequired ? data.check.question.assetId : ''"
            :image_url_brief="data.check.question.brief.assetId"
            :previewUrl="
              isAssetUploadRequired ? assessmentSignedUrls.check.question.assetSignedUrl : ''
            "
            :previewUrlBrief="assessmentSignedUrls.check.question.brief.assetSignedUrl"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentCheckAttributesIsChangedValue(imageKey.questionUrl)"
            :diffIdentifier="'assessment.check.' + imageKey.questionUrl"
            :fileAcceptType="acceptableFileType"
            :label="AppTexts.SELECT_QUESTION_FILE"
            :loadingStatus="uploadingStatus.student_workbook == FILE_UPLOADING_STATUS.IN_PROGRESS"
            :isRequired="true"
            :isAssetUploadRequired="isAssetUploadRequired"
            :fileChangeHandler="
              (file) =>
                importAssessmentImage(
                  file,
                  AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
                  imageKey.questionUrl
                )
            "
            :isNotReadOnly="isNotReadOnly"
            :fileValidators="fileValidators"
            :isAssetApproved="data.check.question.assetApproved"
            :canAppproveAsset="canApproveAsset"
            :canFinalizeAsset="true"
            @addBriefToAsset="
              (previewUrl, briefUrl, isAssetApproved) =>
                copySectionImage(
                  previewUrl,
                  briefUrl,
                  isAssetApproved,
                  AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE
                )
            "
          />
        </v-col>
        <v-col cols="6" class="pad-Lm">
          <v-row class="pad-top-none" dense>
            <v-col class="pad-top-none">
              <FileInput
                :image_url="isAssetUploadRequired ? data.check.question_answer.assetId : ''"
                :image_url_brief="data.check.question_answer.brief.assetId"
                :previewUrl="
                  isAssetUploadRequired ? assessmentSignedUrls.check.question_answer.assetSignedUrl : ''
                "
                :previewUrlBrief="assessmentSignedUrls.check.question_answer.brief.assetSignedUrl"
                :canShowDiffIcon="canShowDiffIcon"
                :isDiffChanged="getAssessmentCheckAttributesIsChangedValue(imageKey.answerUrl)"
                :diffIdentifier="'assessment.check.' + imageKey.answerUrl"
                :fileAcceptType="acceptableFileType"
                :label="AppTexts.SELECT_ANSWER_FILE"
                :loadingStatus="
                  uploadingStatus.student_workbook_answer == FILE_UPLOADING_STATUS.IN_PROGRESS
                "
                :isRequired="false"
                :isAssetUploadRequired="isAssetUploadRequired"
                :fileChangeHandler="
                  (file) =>
                    importAssessmentImage(
                      file,
                      AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE,
                      imageKey.answerUrl
                    )
                "
                :isNotReadOnly="isNotReadOnly"
                :fileValidators="fileValidators"
                :isAssetApproved="data.check.question_answer.assetApproved"
                :canAppproveAsset="canApproveAsset"
                :canFinalizeAsset="true"
                @addBriefToAsset="
                  (previewUrl, briefUrl, isAssstApproved) =>
                    copySectionImage(
                      previewUrl,
                      briefUrl,
                      isAssstApproved,
                      AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE
                    )
                "
              />
              <RichTextEditor
                :canShowDiffIcon="canShowDiffIcon"
                :isDiffChanged="getAssessmentCheckAttributesIsChangedValue('answer_image_brief')"
                fieldIdentifier="assessment.check.question_answer.brief.text"
                v-if="showImageBrief"
                :placeholder="AppTexts.WORKSHEET_ANSWER_BRIEF"
                v-model="data.check.question_answer.brief.text"
                :showValidationError="true"
                :counter="3000"
                :readonly="!isNotReadOnly"
                class="mgn-Tm"
                :includeOnlyToolBarActions="BASIC_TOOLS"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </section>
</template>
<script>
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import AssetTypes from '@/constants/AssetTypes';
import FileInput from '@/components/inputs/FileInput';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AssessmentCheck',
  components: {
    BlockTitleWithSubtitle,
    FileInput,
    RichTextEditor,
  },
  data() {
    return {
      AppTexts,
      FILE_UPLOADING_STATUS,
      Errors,
      BASIC_TOOLS,
      AssetTypes,
    };
  },
  methods: {
    ...mapActions(['importSingleAssetAction', 'deleteSingleAssetAction', 'copySingleAssetAction']),
    importAssessmentImage(file, keyName, url) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: keyName,
        });
      } else {
        this.deleteSingleAssetAction({
        assetId: url,
        fieldIdentifier:`assessment.check.${url}`,
        assetType: keyName,
        });
      }
    },
    copySectionImage(previewUrl, briefUrl, isAssetApproved, keyname) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: keyname,
        isAssetApproved,
      });
    },
    getAssessmentCheckAttributesIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.assessmentDiff.check[type].isChanged;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    assessmentSignedUrls: {
      type: Object,
      required: true,
    },
    assessmentDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canApproveAsset: {
      type: Boolean,
      required: true,
    },
    classworkDescription: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
    }),
    imageKey() {
      if (this.isAssetUploadRequired) {
        return {
          questionUrl: 'question.assetId',
          answerUrl: 'question_answer.assetId',
        };
      } else {
        return {
          questionUrl: 'question.brief.assetId',
          answerUrl: 'question_answer.brief.assetId',
        };
      }
    },
  },
};
</script>
