<template>
  <v-menu offset-y v-model="showMenu" :close-on-content-click="false" :nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dense
        small
        class="tiptap-toolbar-action"
        :class="{ 'is-active': isActive }"
        v-bind="attrs"
        v-on="on"
      >
        <img height="20" width="20" :src="icon" />
      </v-btn>
    </template>
    <div>
      <v-color-picker
        v-model="value"
        class="ma-2"
        :swatches="swatches"
        show-swatches
      ></v-color-picker>
      <v-btn style="float: right; padding: 10px;" @click="handleColorSelect">Select</v-btn>
      <br />
    </div>
  </v-menu>
</template>
<script>
import textColorIcon from '@/assets/RTE/textColor.svg';
export default {
  name: 'ColorPicker',
  data() {
    return {
      value: '',
      showMenu: false,
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],
    };
  },
  methods: {
    handleColorSelect() {
      this.showMenu = false;
      this.onChange(this.value);
    },
  },
  props: {
    onChange: {
      type: Function,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      default: textColorIcon,
    },
  },
};
</script>
<style scoped>
.tiptap-toolbar-action {
  background-color: #f5f5f5 !important;
  border-radius: 0px;
  box-shadow: none;
  height: 35px !important;
}
.tiptap-toolbar-action.is-active {
  background-color: #e1e1e1 !important;
}
</style>
