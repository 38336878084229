<template>
  <p class="character-count">
    {{ remainingCharacterCount }} {{ remainingCharacterCount && remainingWordCount ? ' or ' : ''
    }}{{ remainingWordCount }}
  </p>
</template>
<script>
export default {
  name: 'FooterText',
  props: {
    remainingCharacterCount: { type: String },
    remainingWordCount: { type: String },
  },
};
</script>
<style scoped>
.character-count {
  /* display: flex; */
  position: absolute;
  /* align-items: flex-end; */
  font-size: 12px;
  margin: 0px 15px -7px 0 !important;
  color: rgba(0, 0, 0, 0.5);
  right: 0px;
  bottom: 0px;
}
</style>
