import { baseMixin } from './base';
import PhonicsAnalyseSection from '@/components/lessonPlanTemplate/phonics/AnalyseSection';
import { validateAssetAuthorized } from '@/utils';

export const phonicsLessonMixin = {
  mixins: [baseMixin],
  components: {
    PhonicsAnalyseSection,
  },
  data() {
    return {
      Schema: {
        action: {
          showInstructionAudioWord: true,
          maxAudioWordLength: NaN,
          maxAudioWordNameLength: 12,
          maxAudioWordDescriptionLength: undefined,
        },
        application: {
          morePractice: false,
          showDifferentiationTips: true,
          isClassworkQuestionImageMandatory: true,
        },
        analysis: {
          show: true,
          showPhonicsLpTypeComponent: true,
          discussionTeacherTipMaxLength: 220,
        },
        assessment: {
          show: false,
        },
      },
    };
  },
  methods: {
    validateAssetAuthorized,
    prepareLessonPlanDataToSave() {
      // remove loadingStatus from resource object..
      let resources = [];
      this.lessonPlan.data.aim.resources.forEach((resource) => {
        delete resource.loadingStatus;
        resources.push(resource);
      });

      this.lessonPlan.data.aim.resources = resources;

      // set placeholder image for action instructions videos
      for (let ins of this.lessonPlan.data.action.instructions) {
        if (ins.assets && (ins.assets[0] || {}).type == 'video') {
          if (!ins.assets[0].placeholder) {
            ins.assets[0].placeholder = { assetId: '' };
          }
          ins.assets[0].placeholder.assetId = this.lessonPlan.data.assetId;
        }
        let assets = [];

        for (let asset of ins.assets || []) {
          delete asset.loadingStatus;
          assets.push(asset);
        }
        ins.assets = assets;
      }
    },
    customValidations() {
      try {
        let lp = this.lessonPlan;

        if (!lp) throw Error('Invalid LessonPlan');
        if (!lp.data) throw Error('Invalid LessonPlan Data');

        if (!lp.data.lp_title || !lp.data.lp_description)
          throw Error('Title or Description cannot be empty');

        // time validation - start
        const getMinutesFromTimeString = (timeString) => {
          try {
            return +timeString.split(' ')[0];
          } catch (error) {
            return 0;
          }
        };

        let overallTime = lp.data ? getMinutesFromTimeString(lp.data.time) : 0;
        let actionTime =
          lp.data && lp.data.action ? getMinutesFromTimeString(lp.data.action.time) : 0;
        let analysisTime =
          lp.data && lp.data.analysis ? getMinutesFromTimeString(lp.data.analysis.time) : 0;
        let applicationTime =
          lp.data && lp.data.application ? getMinutesFromTimeString(lp.data.application.time) : 0;

        if (overallTime !== actionTime + analysisTime + applicationTime) {
          let errorMessage = 'Overall time of the LP is not equal to sum of individual step time';
          throw Error(errorMessage);
        }

        const validationStatus = this.validationForAssetApprovingStage();

        return true && validationStatus;
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    validationForAssetApprovingStage() {
      try {
        if (this.currentStage == 'assetAuthorizingStage') {
          let unApprovedAssetCount = validateAssetAuthorized(
            this.lessonPlan.data,
            ['assetId'],
            {
              assetId: 'assetApproved',
            },
            ['brief', 'placeholder'],
            0
          );

          if (unApprovedAssetCount > 0) {
            this.$notify({
              title: 'Failed',
              text: `There are ${unApprovedAssetCount} unapproved assets`,
              type: 'error',
              duration: -1,
            });

            return false;
          }
        }

        return true;
      } catch (error) {
        console.log(error);

        this.$notify({
          title: 'Validation error!',
          text: error,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    validateLPData() {
      return true;
    },
  },
};
