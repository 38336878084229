import {
  getProductsBoardsStandardsSubjectsQuery,
  getProjectsList,
  getProjectsListV2,
  createProject,
  updateProject,
  getBlocksForSelectedProductBoardStandardSubjectQuery,
  getProjectDetailsQuery,
  publishProjectQuery,
  getReadPermissionForProjectQuery,
  revertProjectToPreviousStageQuery,
  getProjectStatus,
  deleteProject,
  switchProjectLessonPlanTemplate,
  generateHTMLTemplateMutation,
  generatePdfTemplateMutation,
  getModelAnswerGuidePDFMutation,
} from '@/apiQueries/project';
import { apolloClient } from '@/apollo';
import { logError } from '@/utils';
import Vue from 'vue';
import Router from '@/router';
import {
  PROJECT_TYPE_VIRTUAL_QUESTION,
  PROJECT_TYPE_QUESTIONS,
  PROJECT_ENTITY_TYPE_LEARNOMETER,
} from '@/constants/common';

export const PROJECT_ACTION = {
  async getProductsBoardsStandardsSubjects(context) {
    try {
      const response = await apolloClient.query({
        query: getProductsBoardsStandardsSubjectsQuery(),
      });

      if (response.data) {
        context.commit('GET_PRODUCTS_BOARDS_STANDARDS_SUBJECTS', response.data);
      }
    } catch (er) {
      logError(er);
    }
  },
  async getProjectsListV2(context, payload) {
    console.log(`payload`);
    console.log(payload);

    try {
      let { page, limit, isMyProjects, searchKey } = payload;
      context.commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: getProjectsListV2(parseInt(page), parseInt(limit), isMyProjects, searchKey),
      });

      if (response.data) {
        context.commit('GET_PROJECTS_DATA', response.data.multipleProjects);
      }
      context.commit('changeLoadingState', false);
    } catch (er) {
      logError(er);
    }
  },
  async getProjectsList(context) {
    try {
      context.commit('SET_INITIAL_PROJECTS_LIST_API_STATUS', 'in_progress');
      const response = await apolloClient.query({
        query: getProjectsList(),
      });

      if (response.data) {
        context.commit('GET_PROJECTS_LIST', response.data.projects);
      }
      context.commit('SET_INITIAL_PROJECTS_LIST_API_STATUS', 'completed');
    } catch (er) {
      context.commit('SET_INITIAL_PROJECTS_LIST_API_STATUS', 'error');
      logError(er);
    }
  },
  async createProject(context, payload) {
    try {
      let {
        name,
        product,
        board,
        standard,
        subject,
        block,
        assignees,
        childrenEntityIds,
        projectType,
      } = payload;

      const response = await apolloClient.mutate({
        mutation: createProject(),
        variables: {
          input: {
            name: name,
            boardId: board.id,
            subjectId: subject.id,
            standardId: standard.id,
            productId: product.id,
            entityId: block.id,
            entityType: 'Block',
            type: projectType ? projectType : block.childrenType,
            childrenEntityIds: childrenEntityIds,
            assignees: {
              author: assignees.author.id,
              reviewer1: assignees.reviewer1.id,
              reviewer2: assignees.reviewer2.id,
              academicSignOff: assignees.academicSignOff.id,
              editor: assignees.editor.id,
              visualizer: assignees.visualizer.id,
              assetUploader: assignees.assetUploader.id,
              assetAuthorizer: assignees.assetAuthorizer.id,
              proofReader: assignees.proofReader.id,
            },
          },
        },
      });

      context.commit('CREATE_PROJECT', response.data.createProject);
      if (response.data.createProject) {
        context.commit(
          'UPDATE_NEW_PROJECT_IN_CAN_EDITABLE_PROJECT_LIST',
          response.data.createProject.id
        );
        Vue.notify({
          title: 'Success!',
          text: `Project (${response.data.createProject.name}) created successfully!`,
          type: 'success',
        });
      }
    } catch (err) {
      logError(err);
    }
  },
  async updateProject(context, payload) {
    try {
      let {
        id,
        name,
        product,
        board,
        standard,
        subject,
        block,
        assignees,
        entityType = 'Block',
      } = payload;
      context.commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: updateProject(),
        variables: {
          input: {
            id: id,
            name: name,
            boardId: board.id,
            subjectId: subject.id,
            standardId: standard.id,
            productId: product.id,
            entityId: block.id,
            entityType: entityType,
            assignees: {
              author: assignees.author.id,
              reviewer1: assignees.reviewer1.id,
              reviewer2: assignees.reviewer2.id,
              academicSignOff: assignees.academicSignOff.id,
              editor: assignees.editor.id,
              visualizer: assignees.visualizer.id,
              assetUploader: assignees.assetUploader.id,
              assetAuthorizer: assignees.assetAuthorizer.id,
              proofReader: assignees.proofReader.id,
            },
          },
        },
      });
      if (entityType == 'Block') context.commit('UPDATE_PROJECT', response.data.updateProject);
      if (response.data.updateProject) {
        Vue.notify({
          title: 'Success!',
          text: `Project (${response.data.updateProject.name}) updated successfully!`,
          type: 'success',
        });
        if (entityType == PROJECT_ENTITY_TYPE_LEARNOMETER) {
          window.location.reload();
        }
        context.commit('changeLoadingState', false);
      }
    } catch (err) {
      context.commit('changeLoadingState', false);
      logError(err);
    }
  },
  async getBlocksForSelectedProductBoardStandardSubject({ commit }, payload) {
    try {
      let { productId, boardName, standardId, subjectId } = payload;

      const response = await apolloClient.query({
        query: getBlocksForSelectedProductBoardStandardSubjectQuery(
          productId,
          boardName,
          standardId,
          subjectId
        ),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        let blocks =
          response.data.getAvailableBlocksOnStandardSubjectBoardProductComboForProjectCreation ||
          [];
        commit('GET_BLOCKS_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT', blocks);
      }
    } catch (er) {
      logError(er);
    }
  },
  async resetProjectDetails({ commit }) {
    commit('SET_PROJECT_DATA', {});
  },
  async getProjectDetails({ commit, dispatch }, { projectId }) {
    try {
      const response = await apolloClient.query({
        query: getProjectDetailsQuery(projectId),
        fetchPolicy: 'no-cache',
      });
      const authResponse = await apolloClient.query({
        query: getReadPermissionForProjectQuery(projectId),
        fetchPolicy: 'no-cache',
      });
      let projectData = response.data.project;
      projectData.lessonPlans = projectData.contents;
      delete projectData.contents;

      if (authResponse && authResponse.data) {
        projectData.canReadProject = authResponse.data.canGetProject;
        projectData.canSwitchType = authResponse.data.canSwitchType;
        projectData.canPublishProject = authResponse.data.canPublishProject;
        projectData.canRevertProjectToPreviousStage =
          authResponse.data.canRevertProjectToPreviousStage;
      }

      if (response && response.data) {
        commit('SET_PROJECT_DATA', response.data.project);
        commit('SET_PROJECT_STATUS_POLLING_STATUS', response.data.project.workflowStatus);

        if (
          [PROJECT_TYPE_VIRTUAL_QUESTION, PROJECT_TYPE_QUESTIONS].includes(
            response.data.project.type
          )
        ) {
          dispatch('fetchQuestionMetaData', {
            currentSubjectId: response.data.project.subject.id,
          });
        }
      }
    } catch (er) {
      logError(er);
    }
  },
  async publishProjectLpsToTeacherReadinessApp({ commit }, projectId) {
    try {
      commit('SET_PROJECT_STATUS_POLLING_STATUS', 'publish_in_progress');
      const response = await apolloClient.mutate({
        mutation: publishProjectQuery(projectId),
      });
      if (response && response.data) {
        commit('PUBLISH_PROJECT', {
          workflowStatus: response.data.publishProjectLpsToTeacherReadinessApp.triggerStatus,
        });
        Vue.notify({
          title: 'Success!',
          text: 'Project publish triggered.',
          type: 'success',
        });
      }
    } catch (er) {
      commit('SET_PROJECT_STATUS_POLLING_STATUS', 'in_progress');
      Vue.notify({
        title: 'Failed!',
        text: er.message,
        type: 'error',
      });
      logError(er);
    }
  },
  setDeafultRevertProjectStatus({ commit }) {
    commit('REVERT_PROJECT_STATUS', {
      status: 'not_started',
    });
  },
  async revertProjectToPreviousStage(
    { commit },
    { projectId, contentId, destinationStage, copyBackData }
  ) {
    commit('REVERT_PROJECT_STATUS', {
      status: 'in_progress',
      message: 'Request in progress....',
    });

    try {
      const response = await apolloClient.mutate({
        mutation: revertProjectToPreviousStageQuery(
          projectId,
          contentId,
          destinationStage,
          copyBackData
        ),
      });

      if (response && response.data && response.data.revertProjectToPreviousStage) {
        commit('REVERT_PROJECT_STATUS', {
          status: 'completed',
          message: 'Request completed!',
        });

        Vue.notify({
          title: 'Success!',
          text: 'Successfully reverted!',
          type: 'success',
        });

        Router.go();
      } else {
        throw new Error('Something went wrong. Please try again after some time.');
      }
    } catch (er) {
      commit('REVERT_PROJECT_STATUS', {
        status: 'error',
        message: 'Something went wrong. Please try again after some time.',
      });

      Vue.notify({
        title: 'Failed!',
        text: er.message,
        type: 'error',
      });
      logError(er);
    }
  },
  async getProjectStatus({ commit }, projectId) {
    try {
      if (!projectId) {
        return;
      }
      const response = await apolloClient.query({
        query: getProjectStatus(projectId),
        fetchPolicy: 'no-cache',
      });
      if (response && response.data && response.data.getProjectStatus) {
        commit('SET_PROJECT_STATUS_POLLING_STATUS', response.data.getProjectStatus.workflowStatus);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async deleteProject({ commit }, { id, name }) {
    try {
      if (!id) {
        return;
      }
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: deleteProject(),
        variables: {
          id,
        },
      });
      if (
        response &&
        response.data &&
        response.data.deleteProject &&
        response.data.deleteProject.status
      ) {
        commit('DELETE_PROJECT_FROM_LIST', id);
      }
      commit('changeLoadingState', false);
      Vue.notify({
        title: 'Success!',
        text: `< ${name} and ${id} > deleted successfully`,
        type: 'success',
      });
    } catch (error) {
      commit('changeLoadingState', false);
      Vue.notify({
        title: 'Failed!',
        text: 'Something went wrong',
        type: 'error',
      });
      console.log(error);
    }
  },
  async switchProjectLessonPlanTemplate({ commit }, { projectId, contentLibraryId, selectedType }) {
    if (!projectId || !contentLibraryId || !selectedType) {
      Vue.notify({
        title: 'Failed!',
        text: 'Invalid data provided',
        type: 'error',
      });
      return;
    }
    commit('changeLoadingState', true);
    try {
      const response = await apolloClient.mutate({
        mutation: switchProjectLessonPlanTemplate(),
        variables: {
          projectId,
          contentLibraryId: parseInt(contentLibraryId),
          selectedType,
        },
      });
      if (
        response &&
        response.data &&
        response.data.switchProjectLessonPlanTemplate &&
        response.data.switchProjectLessonPlanTemplate.status
      ) {
        Vue.notify({
          title: 'Success!',
          text: `Successfully Switched Template to ${selectedType}!`,
          type: 'success',
        });

        Router.go();
      } else {
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }
    } catch (e) {
      console.log(e);
      commit('changeLoadingState', false);
      Vue.notify({
        title: 'Failed!',
        text: `Something went wrong!`,
        type: 'error',
      });
    }
  },
  async createLearnometerQuestionProject(context, payload) {
    try {
      let {
        name,
        assignees,
        subjectId,
        standardId,
        entityId,
        entityType,
        type,
        currentQuestionSequenceNumber,
        projectId,
        selectedYear,
        isDemoQuestion,
        isGroupQuestion,
        questionGroupId,
      } = payload;

      const response = await apolloClient.mutate({
        mutation: createProject(),
        variables: {
          input: {
            name,
            subjectId,
            standardId,
            entityId,
            entityType,
            type,
            assignees: {
              author: assignees.author.id,
              reviewer1: assignees.reviewer1.id,
              reviewer2: assignees.reviewer2.id,
              academicSignOff: assignees.academicSignOff.id,
              editor: assignees.editor.id,
              visualizer: assignees.visualizer.id,
              assetUploader: assignees.assetUploader.id,
              assetAuthorizer: assignees.assetAuthorizer.id,
              proofReader: assignees.proofReader.id,
            },
          },
        },
      });

      context.commit('CREATE_PROJECT', response.data.createProject);
      if (response.data.createProject) {
        Vue.notify({
          title: 'Success!',
          text: `Project (${response.data.createProject.name}) created successfully!`,
          type: 'success',
        });

        if (payload?.questionSetEditing) {
          if (currentQuestionSequenceNumber && projectId && selectedYear) {
            if (isGroupQuestion) {
              const targetUrl = Router.resolve({
                name: 'LearnometerQuestion',
                params: {
                  questionSequenceNumber: questionGroupId,
                  id: response.data.createProject.id,
                  selectedYear: selectedYear,
                },
                query: {
                  showDemoQuestions: isDemoQuestion,
                  isGroupQuestion: true,
                },
              }).href;
              window.open(targetUrl, '_blank');
            } else {
              const targetUrl = Router.resolve({
                name: 'LearnometerQuestion',
                params: {
                  questionSequenceNumber: currentQuestionSequenceNumber,
                  id: response.data.createProject.id,
                  selectedYear: selectedYear,
                },
                query: {
                  showDemoQuestions: isDemoQuestion,
                },
              }).href;
              window.open(targetUrl, '_blank');
            }
          }
        } else {
          Router.push({
            name: 'ViewLearnometerProject',
            params: { id: response.data.createProject.id },
          });
        }
      }
    } catch (err) {
      logError(err);
      Vue.notify({
        title: 'Failed!',
        text: err.message,
        type: 'error',
      });
    }
  },
  async generateHTMLTemplate(context, payload) {
    try {
      let { library_id, isEarlyProgram, blockId, index } = payload;
      context.commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: generateHTMLTemplateMutation(),
        variables: {
          libraryId: library_id,
          isEarlyProgram: isEarlyProgram,
        },
      });

      if (response.data['generateHtmlTemplate'].success == true) {
        Vue.notify({
          title: 'Success!',
          text: `Download Generated`,
          type: 'success',
        });
        //window.location.href = 'data:text/html;base64,' + response.data['generateHtmlTemplate'].data;
        var a = document.createElement('a');
        a.href = 'data:text/html;base64,' + response.data['generateHtmlTemplate'].data;
        a.download = `${blockId}_LP_${index}.html`;
        //  a.download = `${blockId}_${title}_${index}.html`;
        a.click();
        context.commit('changeLoadingState', false);
      }
    } catch (err) {
      logError(err);
      context.commit('changeLoadingState', false);
      Vue.notify({
        title: 'Failed!',
        text: err.message,
        type: 'error',
      });
    }
  },
  async generatePdfTemplate(context, payload) {
    try {
      let { library_id, isEarlyProgram, blockId, index } = payload;
      console.log('Payload ', payload);
      context.commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: generatePdfTemplateMutation(),
        variables: {
          libraryId: library_id,
          isEarlyProgram: isEarlyProgram,
        },
      });

      if (response.data['generatePdfTemplate'].success == true) {
        Vue.notify({
          title: 'Success!',
          text: `Download Generated`,
          type: 'success',
        });
        //window.location.href = 'data:text/html;base64,' + response.data['generateHtmlTemplate'].data;
        var a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + response.data['generatePdfTemplate'].data;
        a.download = `${blockId}_LP_${index}.pdf`;
        // a.download = `${blockId}_${title}_${index}.pdf`;
        a.click();
        context.commit('changeLoadingState', false);
      }
    } catch (err) {
      logError(err);
      context.commit('changeLoadingState', false);
      Vue.notify({
        title: 'Failed!',
        text: err.message,
        type: 'error',
      });
    }
  },
  async generateModelAnswerGuideTemplate(context, payload) {
    try {
      let { blockIdList, metaData } = payload;
      context.commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: getModelAnswerGuidePDFMutation(blockIdList, metaData),
      });

      if (response.data['getModelAnswerGuidePDF'].zip != null) {
        const link = document.createElement('a');
        link.href = `data:application/zip;base64,${response.data['getModelAnswerGuidePDF'].zip}`;
        link.download = `Model_Answer_Guide_${blockIdList.join('_')}.zip`;
        link.click();

        Vue.notify({
          title: 'Success!',
          text: `Download Generated`,
          type: 'success',
        });
        context.commit('changeLoadingState', false);
      }
    } catch (err) {
      logError(err);
      context.commit('changeLoadingState', false);
      Vue.notify({
        title: 'Failed!',
        text: err.message,
        type: 'error',
      });
    }
  },
};
