<template>
  <v-form ref="aimSectionForm" class="section-title-cnr">
    <Slide :slide="'Slide 1'" />
    <TextInput
      :enableTextFormatting="true"
      :readonly="options.isReadOnly"
      :canShowDiffIcon="options.canShowDiffIcon"
      fieldIdentifier="aim.description"
      :placeholder="AppTexts.AIM_DESCRIPTION"
      v-model="data.description"
      :rules="[(v) => !!v || options.errorOfRequired]"
      :counter="85"
      :wordCounter="15"
    />
    <BlockTitle :title="AppTexts.KEYWORDS_WE_WILL_USE" />
    <ContentEntryKeywords
      class="mgn-Bm"
      :items="data.keywords"
      :addKeyword="contentEntryAddKeyword"
      :deleteKeyword="contentEntryDeleteKeyword"
      :placeholder="AppTexts.KEYWORD"
      :descriptionPlaceholder="AppTexts.KEYWORD_DESCRIPTION"
      :keywordsDiff="[]"
      :maxKeywordLength="10"
      :canShowDiffIcon="options.canShowDiffIcon"
      :isNotReadOnly="options.isNotReadOnly"
      :titleCharacterLength="20"
      :descriptionCharacterLength="100"
    />
    <SubSection :title="AppTexts.TEACHER_ZONE" />
    <TextInput
      :enableTextFormatting="true"
      :readonly="options.isReadOnly"
      :canShowDiffIcon="options.canShowDiffIcon"
      fieldIdentifier="aim.keywordTeacherZone"
      :placeholder="AppTexts.ADD_TEACHER_TIP"
      v-model="data.keywordTeacherZone"
    />
    <XSDivider />
    <Slide :slide="'Slide 2'" />
    <BlockTitle :title="AppTexts.RESOURCES" />
    <ContentEntryResources
      class="mgn-Bs"
      :items="data.resources"
      :addResource="contentEntryAddResource"
      :deleteResource="contentEntryDeleteResource"
      withAdd
      :placeholder="AppTexts.RESOURCE_NAME"
      :isRequired="false"
      :label="AppTexts.SELECT_RESOURCE"
      :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
      :error="''"
      :showImageBrief="false"
      :acceptableFileType="''"
      :resourcesSignedUrls="signedUrls.resources"
      :resourcesDiff="[]"
      :resourseMaxLength="3"
      :fileChangeHandler="fileChangeHandler"
      :options="options"
      :addToAsset="addToAsset"
      :canAppproveAsset="canAppproveAsset"
      :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
    />
    <SubSection :title="AppTexts.TEACHER_ZONE" style="padding-top: 22px;" />
    <TextInput
      :enableTextFormatting="true"
      :readonly="options.isReadOnly"
      :canShowDiffIcon="options.canShowDiffIcon"
      fieldIdentifier="aim.resourcesTeacherZone"
      :placeholder="AppTexts.ADD_TEACHER_TIP"
      v-model="data.resourcesTeacherZone"
    />
  </v-form>
</template>
<script>
import TextInput from '@/components/inputs/TextInput';
import ContentEntryKeywords from '@/components/inputs/ContentEntryKeywords';
import ContentEntryResources from '@/components/inputs/ContentEntryResources';
import BlockTitle from '@/components/inputs/BlockTitle';
import SubSection from '@/components/inputs/SubSection';
import AppTexts from '@/constants/AppTexts';
import Slide from '@/components/contentEntry/Slide';
export default {
  name: 'AimSection',
  data() {
    return {
      AppTexts,
    };
  },
  components: {
    Slide,
    TextInput,
    BlockTitle,
    ContentEntryKeywords,
    SubSection,
    ContentEntryResources,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    contentEntryDeleteKeyword: {
      type: Function,
      required: true,
    },
    contentEntryAddKeyword: {
      type: Function,
      required: true,
    },
    signedUrls: {
      type: Object,
      required: true,
    },
    contentEntryAddResource: {
      type: Function,
      required: true,
    },
    contentEntryDeleteResource: {
      type: Function,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
