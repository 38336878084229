<template>
  <div>
    <v-dialog v-model="showCreateQuestionProject" content-class="learnometer-topic-form-dialog">
      <v-card height="380">
        <v-card-title class="headline grey lighten-2" primary-title>
          Reopen project
        </v-card-title>
        <div class="form-content">
          <v-form ref="project_assignee_form" :key="`projectFormPopUp`" class="ma-4">
            <ProjectStageAssigneeFormContent
              :projectData="projectData"
              :getUsersByRoles="getUsersByRoles"
              colsPerRow="3"
            />
          </v-form>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 0;
            margin-top: -60px;
            padding-right: 10px;
          "
        >
          <v-btn text @click="showCreateQuestionProject = false">Cancel</v-btn>

          <v-btn color="primary" v-if="this.buttonText == 'REOPEN'" @click="validateAssigneeForm"
            >Reopen</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLoader" max-width="400" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          Brewing your coffee...
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar
      height="58px;"
      dense
      absolute
      color="white"
      elevation="2"
      scroll-target="#scrolling-techniques-7"
      class="action-bar"
    >
      <div class="bread-crumb-row">
        <a href="/learnometer">Learnometer </a>

        <v-icon style="color: #818181;">mdi-chevron-right</v-icon>

        <a href="/learnometer/createQuestionSet"
          >{{ this.$route.params.type == 'create' ? 'Create Question Set' : 'Edit Question Set' }}
        </a>

        <v-icon style="color: #818181;">mdi-chevron-right</v-icon>

        <a href="javascript:void(0);"
          ><b
            >Blueprint: {{ test.data.testData != null ? test.data.testData.blueprintName : '' }}</b
          ></a
        >
      </div>
    </v-app-bar>
    <v-sheet v-if="test.data == null || test.data == undefined"></v-sheet>
    <v-sheet v-else>
      <v-col>
        <v-container fluid style="background-color: #fff0f0; margin-top: 55px;">
          <div style="margin-left: 80px;">
            <v-row align="center">
              <div style="font-family: Roboto; font-size: 15px; color: #404040;">
                Blueprint:
              </div>
              <div
                style="
                  padding-left: 10px;
                  font-weight: 600;
                  font-family: Roboto;
                  font-size: 20px;
                  color: #000000;
                "
              >
                {{ test.data.testData != null ? test.data.testData.blueprintName : '' }}
              </div>

              <div style="font-family: Roboto; font-size: 15px; color: #404040; margin-left: 55px;">
                Grade:
              </div>
              <div
                style="
                  padding-left: 10px;
                  font-weight: 600;
                  font-family: Roboto;
                  font-size: 20px;
                  color: #000000;
                "
              >
                {{ test.data.testData != null ? test.data.testData.standardName : '' }}
              </div>

              <!-- <v-card rounded elevation="0" style="background-color: #fff0f0;">
                <v-row style="justify-content: center;">
                  <div
                    style="
                      font-family: Roboto;
                      font-size: 15px;
                      color: #404040;
                      margin-left: 55px;
                      justify-content: center;
                    "
                  >
                    Analytics:
                  </div>
                  <div
                    style="
                      font-family: Roboto;
                      font-size: 15px;
                      color: #404040;
                      border-radius: 4px;
                      border: 1px solid #000;
                      margin-left: 4px;
                      justify-items: center;
                      cursor: pointer;
                    "
                    @click="analyticsToggle"
                  >
                    <img
                      align="center"
                      src="@/assets/analytics.png"
                      style="width: 24px; height: 24px; margin-left: 6px; cursor: pointer;"
                    />
                    <v-icon style="margin-bottom: 2px;"
                      >{{ isShowAnalytics ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </div>
                </v-row>
              </v-card> -->
              <v-spacer></v-spacer>
              <v-checkbox
                style="font-size: 14px; font-weight: 400;"
                v-model="isCloneRandomized"
                label="Random Clone &amp; Clone Series Selection"
              ></v-checkbox>
              <div style="margin-left: 10px;">
                <v-tooltip bottom small color="#FBF7C5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span style="color: black;">
                    By using this feature, you will have the option to randomly select clones or
                    choose a specific <br />clone series in the Supertool Assessment Creation
                    process.</span
                  >
                </v-tooltip>
              </div>
            </v-row>
          </div>
          <!-- <v-container v-if="isShowAnalytics" style="background-color: #ffffff;" fluid>
            <v-container style="width: 500px;">
              <v-row align="center" justify="space-between">
                <button
                  @click="updateAnalyticsTab('difficulty')"
                  :class="{
                    'difficulty-level': this.analyticsTab === 'difficulty',
                    'top-skill-text': this.analyticsTab !== 'difficulty',
                  }"
                >
                  Difficulty
                </button>
                <button
                  @click="updateAnalyticsTab('skill')"
                  :class="{
                    'difficulty-level': this.analyticsTab === 'skill',
                    'top-skill-text': this.analyticsTab !== 'skill',
                  }"
                >
                  Skill
                </button>
                <button
                  @click="updateAnalyticsTab('english')"
                  :class="{
                    'difficulty-level': this.analyticsTab === 'english',
                    'top-skill-text': this.analyticsTab !== 'english',
                  }"
                >
                  English
                </button>
                <button
                  @click="updateAnalyticsTab('math')"
                  :class="{
                    'difficulty-level': this.analyticsTab === 'math',
                    'top-skill-text': this.analyticsTab !== 'math',
                  }"
                >
                  Math
                </button>
                <button
                  @click="updateAnalyticsTab('science')"
                  :class="{
                    'difficulty-level': this.analyticsTab === 'science',
                    'top-skill-text': this.analyticsTab !== 'science',
                  }"
                >
                  Science
                </button>
              </v-row>
            </v-container>
            <v-container pt-4>
              <table class="custom-table">
                <thead class="first-row">
                  <tr>
                    <th
                      v-for="(column, columnIndex) in test.data.analytics[analyticsTab]['column']"
                      :key="columnIndex"
                    >
                      <center>{{ column }}</center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in test.data.analytics[analyticsTab]['rows']"
                    :key="rowIndex"
                  >
                    <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                      <center>{{ cell }}</center>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-container>
          </v-container> -->

          <v-container fluid style="margin-left: 60px;">
            <v-row align="center">
              <v-col>
                <span
                  style="font-family: Roboto; font-size: 12px; color: #404040; font-weight: 500;"
                  >Question set title:</span
                >
                <div style="margin-top: 10px; width: 30px;"></div>
                <input
                  style="box-sizing: border-box; border: 0.5px solid #818181; width: 222px;"
                  type="text"
                  v-model="tittle"
                  class="text-field"
                  placeholder="Write a title"
                />
              </v-col>
              <v-col>
                <span
                  style="font-family: Roboto; font-size: 12px; color: #404040; font-weight: 500;"
                  >Question Set Description:</span
                >
                <div style="margin-top: 10px; width: 30px;"></div>
                <input
                  style="box-sizing: border-box; border: 0.5px solid #818181; width: 222px;"
                  type="text"
                  v-model="description"
                  class="text-field"
                  placeholder="Write a description"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-row style="justify-content: right; margin-right: 45px;">
                <button class="custom-button" @click="goToCreateQuestionSetPage">Cancel</button>
                <div style="width: 20px;"></div>
                <button @click="createQuestionSetFinal()" class="custom-button save-button">
                  Save
                </button>
              </v-row>
            </v-row>
          </v-container>
        </v-container>

        <v-container style="padding: 33px; margin-left: 45px;" fluid>
          <div>
            <div class="row-list">
              <div
                v-for="(question, questionIndex) in questions"
                :key="questionIndex"
                class="row-item"
              >
                <div
                  v-if="isGroupQuestion(question)"
                  class="custom-box"
                  style="background-color: #ffffe0;"
                >
                  <button class="box-text" @click="scrollToQuestionContainer(questionIndex)">
                    {{
                      `${question[0][0][0]['questionGroupId']}(${question[0][0][0]['xseedGlobalSubjectId']['shortName']}) - ( ${question[0].length} Clones )`
                    }}
                  </button>
                </div>

                <div v-else class="custom-box">
                  <button class="box-text" @click="scrollToQuestionContainer(questionIndex)">
                    {{
                      `Q ${question[0].questionDisplaySequenceNumber || '#'}- ( ${
                        question.length
                      } Clones )`
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </v-container>
        <div v-if="loading">
          <p><center>Loading...</center></p>
        </div>
        <div>
          <div v-for="(question, questionIndex) in questions" :key="questionIndex">
            <v-container v-if="isQuestionAuthored(question)" class="rounded-container">
              <v-row>
                <v-col>
                  <v-container class="question-border">
                    <v-container pl-1.5 style="background-color: rgba(211, 211, 211, 0.55);">
                      <v-row align="center">
                        <div class="yellow-container"></div>
                        <div class="question-12">
                          Question -
                          {{ question[0].questionDisplaySequenceNumber || '#' }}
                        </div>
                      </v-row>
                    </v-container>
                    <v-container style="background-color: #f5f5f5;">
                      <v-row justify="center">
                        This question is not authored
                      </v-row>
                    </v-container>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              v-else-if="!isGroupQuestion(question)"
              class="rounded-container"
              :id="'questionContainer' + questionIndex"
            >
              <v-row>
                <v-col>
                  <v-container :class="{ 'question-border': selectedQuestion == questionIndex }">
                    <v-container pl-1.5 style="background-color: rgba(211, 211, 211, 0.55);">
                      <v-row align="center">
                        <div class="yellow-container"></div>
                        <div class="question-12">
                          Question -
                          {{
                            returnSelectedQuestion(question).questionDisplaySequenceNumber || '#'
                          }}
                        </div>
                        <div class="question-12">|</div>
                        <div class="question-12">
                          Selected: Clone
                          {{ returnSelectedQuestion(question).cloneNumber }}
                        </div>
                        <div class="question-12">|</div>
                        <button class="edit-question" @click="checkProjectStatus(question)">
                          Edit Question
                        </button>

                        <div class="project-current-status-wrapper">
                          <div class="project-status-text">
                            {{
                              project.data[returnSelectedQuestion(question)._id].workflowStatus ==
                              AppTexts.WORKFLOW_COMPLETED
                                ? AppTexts.PUBLISHED
                                : project.data[returnSelectedQuestion(question)._id].workflowStatus
                            }}
                          </div>
                        </div>

                        <v-spacer></v-spacer>
                        <div class="row-list">
                          <div
                            v-for="(clone, cloneIndex) in question"
                            :key="cloneIndex"
                            class="row-item"
                          >
                            <button
                              :class="{
                                'edit-question': clone.isSelectedLocal === true,
                                'clone-button': clone.isSelectedLocal === false,
                              }"
                              @click="updateSelectedQuestionV2(question, clone._id)"
                            >
                              {{ `Clone ${clone.cloneNumber}` }}
                            </button>
                          </div>
                        </div>
                      </v-row>
                    </v-container>
                    <v-container style="background-color: #f5f5f5;">
                      <v-row align="center">
                        <v-spacer></v-spacer>
                        <div @click="metaDataToggle(questionIndex)" class="metadata">
                          {{ showMetadata[questionIndex] ? 'Hide Metadata' : 'Show Metadata' }}
                        </div>
                        <v-icon
                          style="padding-right: 15px;s"
                          :elevation="0"
                          @click="metaDataToggle(questionIndex)"
                          >{{ showMetadata[questionIndex] ? 'mdi-menu-up' : 'mdi-menu-down' }}
                        </v-icon>
                      </v-row>

                      <div
                        v-if="showMetadata[questionIndex]"
                        class="d-flex flex-wrap bg-surface-variant mt-4"
                      >
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Primary Big Skill</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.primaryBigSkillId.name }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Primary Sub Skill</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.primarySubSkillId.name }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Secondary Big Skill</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.secondaryBigSkillId.name }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Secondary Sub Skill</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.secondarySubSkillId.name }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Theme</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.themeId.name }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Microtopic Name:</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.microtopicId.name }}
                          </div>
                        </div>
                        <!-- <div class="mr-14 mb-10 w-25">
                      <div class="skill-heading mb-1">Microtopic Description</div>
                      <div class="skill-name">NA</div>
                    </div> -->
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Microskill Category:</div>
                          <div class="skill-name">
                            {{
                              returnSelectedQuestion(question).metadata.microSkillCategoryId.name
                            }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Score:</div>
                          <div class="skill-name">{{ returnSelectedQuestion(question).score }}</div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Weightage:</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).weightage }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Difficulty:</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.difficulty }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Global Question ID:</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).globalQuestionId }}
                          </div>
                        </div>
                        <div class="mr-14 mb-10 w-25">
                          <div class="skill-heading mb-1">Microskill Assessed:</div>
                          <div class="skill-name">
                            {{ returnSelectedQuestion(question).metadata.microskillAssessed }}
                          </div>
                        </div>
                      </div>
                      <XSQuestionRenderer
                        :questionIndex="index"
                        :datum="returnSelectedQuestion(question)"
                        :saveForm="{}"
                        :options="{}"
                        :toggleComments="{}"
                        :isViewOnly="true"
                        :canShowActionButtons="true"
                        :isSchoolProgramQuestion="false"
                        :showMinimalDetails="true"
                        :shouldEmitDeleteAction="true"
                        :questionMetaInfo="{}"
                        :convertIndividualToGroupQuestion="() => {}"
                        :openQuestionDialog="editQuestionDialog"
                        :openGroupQuestionDialog="() => {}"
                        :deleteGroupQuestion="() => {}"
                        @remove-question-clicked="
                          {
                          }
                        "
                        lpType="learnometerQuestion"
                        minimalInfoPrefixText="Clone"
                        :cloneQuestionData="{}"
                        :deleteQuestion="() => {}"
                        :cloneNotAvailableMessage="{}"
                        :isLeanometerQuestionSetView="true"
                      />
                      <v-row>
                        <v-spacer></v-spacer>

                        <button
                          v-if="!returnSelectedQuestion(question).isSelected"
                          @click="
                            updateSelectedQuestion(question, returnSelectedQuestion(question)._id)
                          "
                          class="custom-button save-button"
                        >
                          Select
                        </button>
                        <button v-else class="custom-button selected-button">
                          Selected
                        </button>
                        <div style="width: 20px;"></div>
                      </v-row>
                    </v-container>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>
              <div
                class="rounded-container"
                fluid
                v-for="(groupQuestion, groupQuestionIndex) in question"
                :key="groupQuestionIndex"
                :id="'questionContainer' + questionIndex"
              >
                <v-row>
                  <v-col>
                    <v-container style="background-color: #ffffe0;" class="pl-10">
                      <v-row align="center">
                        <div class="yellow-container"></div>
                        <div class="question-12">
                          {{
                            returnSelectedGroupQuestion(groupQuestion)['questions'][
                              returnSelectedGroupQuestion(groupQuestion)['index']
                            ].questionGroupId
                          }}
                        </div>
                        <div class="question-12">|</div>
                        <div class="question-12">
                          Selected: Clone
                          {{
                            returnSelectedGroupQuestion(groupQuestion)['questions'][
                              returnSelectedGroupQuestion(groupQuestion)['index']
                            ].cloneNumber
                          }}
                        </div>
                        <div class="question-12">|</div>
                        <!--<div class="edit-question">Edit Question</div>-->
                        <v-spacer></v-spacer>
                        <div class="row-list">
                          <div
                            v-for="(clone, cloneIndex) in groupQuestion"
                            :key="cloneIndex"
                            class="row-item"
                          >
                            <button
                              :class="{
                                'edit-question': clone[cloneIndex].isSelectedLocal === true,
                                'clone-button': clone[cloneIndex].isSelectedLocal === false,
                              }"
                              @click="
                                updateSelectedGroupQuestion(
                                  groupQuestion,
                                  returnSelectedGroupQuestion(groupQuestion)['index'],
                                  cloneIndex
                                )
                              "
                            >
                              {{ `Clone ${clone[0].cloneNumber}` }}
                            </button>
                          </div>
                        </div>
                      </v-row>
                      <div
                        v-html="
                          RTETextToHTML(
                            returnSelectedGroupQuestion(groupQuestion)['questions'][
                              returnSelectedGroupQuestion(groupQuestion)['index']
                            ].stimulus
                          )
                        "
                      ></div>
                    </v-container>
                    <v-container
                      style="background-color: #ffffe0;"
                      v-for="(question, questionIndex) in returnSelectedGroupQuestion(
                        groupQuestion
                      )['questions']"
                      :key="questionIndex"
                    >
                      <v-container pl-1.5 style="background-color: rgba(211, 211, 211, 0.55);">
                        <v-row align="center" class="mb-4 ml-1">
                          <div class="yellow-container"></div>
                          <div class="question-12">
                            Question -
                            {{
                              returnSelectedGroupQuestion(groupQuestion)['questions'][questionIndex]
                                .questionDisplaySequenceNumber
                            }}
                          </div>

                          <button
                            class="edit-question"
                            @click="checkProjectStatusOfGroupQuestion(question)"
                          >
                            Edit Question
                          </button>

                          <div class="project-current-status-wrapper">
                            <div class="project-status-text">
                              {{
                                project.data[
                                  returnSelectedGroupQuestion(groupQuestion)['questions'][
                                    questionIndex
                                  ]._id
                                ].workflowStatus == AppTexts.WORKFLOW_COMPLETED
                                  ? AppTexts.PUBLISHED
                                  : project.data[
                                      returnSelectedGroupQuestion(groupQuestion)['questions'][
                                        questionIndex
                                      ]._id
                                    ].workflowStatus
                              }}
                            </div>
                          </div>
                        </v-row>
                        <v-row align="center">
                          <v-spacer></v-spacer>
                          <div @click="metaDataToggle(questionIndex)" class="metadata">
                            {{ showMetadata[questionIndex] ? 'Hide Metadata' : 'Show Metadata' }}
                          </div>
                          <v-icon
                            style="padding-right: 15px;s"
                            :elevation="0"
                            @click="metaDataToggle(questionIndex)"
                            >{{ showMetadata[questionIndex] ? 'mdi-menu-up' : 'mdi-menu-down' }}
                          </v-icon>
                        </v-row>
                        <div
                          v-if="showMetadata[questionIndex]"
                          class="d-flex flex-wrap bg-surface-variant mt-4"
                        >
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Primary Big Skill</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.primaryBigSkillId.name
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Primary Sub Skill</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.primarySubSkillId.name
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Secondary Big Skill</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.secondaryBigSkillId.name
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Secondary Sub Skill</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.secondarySubSkillId.name
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Theme</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.themeId.name
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Microtopic Name:</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.microtopicId.name
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Microskill Category:</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.microSkillCategoryId.name
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Score:</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].score
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Weightage:</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].weightage
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Difficulty:</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.difficulty
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Global Question ID:</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].globalQuestionId
                              }}
                            </div>
                          </div>
                          <div class="mr-14 mb-10 w-25">
                            <div class="skill-heading mb-1">Microskill Assessed:</div>
                            <div class="skill-name">
                              {{
                                returnSelectedGroupQuestion(groupQuestion)['questions'][
                                  questionIndex
                                ].metadata.microskillAssessed
                              }}
                            </div>
                          </div>
                        </div>
                        <XSQuestionRenderer
                          :questionIndex="index"
                          :datum="
                            returnSelectedGroupQuestion(groupQuestion)['questions'][questionIndex]
                          "
                          :saveForm="{}"
                          :options="{}"
                          :toggleComments="{}"
                          :isViewOnly="true"
                          :canShowActionButtons="true"
                          :isSchoolProgramQuestion="false"
                          :showMinimalDetails="true"
                          :shouldEmitDeleteAction="true"
                          :questionMetaInfo="{}"
                          :convertIndividualToGroupQuestion="() => {}"
                          :openQuestionDialog="editQuestionDialog"
                          :openGroupQuestionDialog="() => {}"
                          :deleteGroupQuestion="() => {}"
                          @remove-question-clicked="
                            {
                            }
                          "
                          lpType="learnometerQuestion"
                          minimalInfoPrefixText="Clone"
                          :cloneQuestionData="{}"
                          :deleteQuestion="() => {}"
                          :cloneNotAvailableMessage="{}"
                          :isLeanometerQuestionSetView="true"
                        />
                      </v-container>
                    </v-container>
                    <v-container style="background-color: #f5f5f5;">
                      <v-row>
                        <v-spacer></v-spacer>

                        <button
                          v-if="
                            !groupQuestion[returnSelectedGroupQuestion(groupQuestion)['index']][0]
                              .isSelected
                          "
                          @click="
                            finalUpdateSelectedGroupQuestion(
                              groupQuestion,
                              groupQuestionIndex,
                              returnSelectedGroupQuestion(groupQuestion)['index']
                            )
                          "
                          class="custom-button save-button"
                        >
                          Select
                        </button>
                        <button v-else class="custom-button selected-button">
                          Selected
                        </button>
                        <div style="width: 20px;"></div>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </div>
        </div>
      </v-col>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { XSQuestionRenderer } from '@xseededucation/vue-question-display-component';
import { RTETextToHTML } from '@xseededucation/rte-helper-functions';
import Vue from 'vue';
import { PROJECT_WORKFLOW_STATUSES_HASH } from '@/constants/Project';
import ProjectStageAssigneeFormContent from '@/components/contentEntry/ProjectStageAssigneeFormContent';
import { SKIP_STAGE_USER_ID_VALUE } from '@/constants/microTopic';
import AppTexts from '@/constants/AppTexts';
export default {
  name: 'QuestionSetAuthoring',

  beforeCreate() {
    this.getCurrentUserAuthPermissions();
  },

  created() {
    this.fetchAllTestQuestions({
      testId: this.$route.params.testId,
    });
    this.getUsers();
    this.resetProjectData();
  },
  computed: {
    ...mapState({
      test: (state) => state.learnometer.getTestestApiResponse,
      createdTestData: (state) => state.learnometer.createTestApiResponse,
      isAdmin: (state) => state.auth.isAdmin,
      users: (state) => state.user.users,
      project: (state) => state.learnometer.getProjectListApiResponse,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
    }),
    showLoader() {
      return (
        this.test.status == 'in_progress' ||
        this.createdTestData.status == 'in_progress' ||
        this.project.status == 'in_progress'
      );
    },
  },
  components: {
    XSQuestionRenderer,
    ProjectStageAssigneeFormContent,
  },
  watch: {
    test: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) this.addQuestionDataWithClone(val.data);
      },
    },

    project: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) this.loading = false;
      },
    },
  },
  methods: {
    RTETextToHTML,
    ...mapActions([
      'fetchAllTestQuestions',
      'publishXLMTest',
      'getUsers',
      'getCurrentUserAuthPermissions',
      'createLearnometerQuestionProject',
      'fetchProjectListWithStandardIdAndSubjectId',
    ]),

    isGroupQuestion(question) {
      return question[0] && Array.isArray(question[0]);
    },
    isQuestionAuthored(question) {
      if (question[0] && Array.isArray(question[0])) {
        let authored = true;
        question[0].map((clone) => {
          if (!clone.authored) {
            authored = false;
          }
        });
        return authored;
      } else {
        return !question[0].authored;
      }
    },
    updateSelectedQuestion(clones, questionId) {
      clones.forEach((clone) => {
        if (clone._id == questionId) {
          clone.isSelected = true;
          clone.isSelectedLocal = true;
        } else {
          clone.isSelected = false;
          clone.isSelectedLocal = false;
        }
      });
    },
    finalUpdateSelectedGroupQuestion(clones, previousCloneIndex, newCloneIndex) {
      clones[newCloneIndex].forEach((clone) => {
        clone.isSelected = true;
        clone.isSelectedLocal = true;
      });
      clones[previousCloneIndex].forEach((clone) => {
        clone.isSelected = false;
        clone.isSelectedLocal = false;
      });
      this.returnSelectedGroupQuestion(clones);
    },
    scrollBackward() {
      const list = this.$el.querySelector('.row-list');
      list.scrollLeft -= this.scrollAmount;
    },
    scrollForward() {
      const list = this.$el.querySelector('.row-list');
      list.scrollLeft += this.scrollAmount;
    },
    analyticsToggle() {
      this.isShowAnalytics = !this.isShowAnalytics;
    },
    metaDataToggle(index) {
      this.$set(this.showMetadata, index, !this.showMetadata[index]);
    },
    updateAnalyticsTab(tab) {
      this.analyticsTab = tab;
    },
    goToCreateQuestionSetPage() {
      this.$router.go(-1);
    },

    extractDataFromJson(questionsData) {
      const extractedData = {};

      let data = questionsData.flat(Infinity);
      data.forEach((item) => {
        const {
          _id,
          yearId,
          xseedGlobalSubjectId,
          xseedGlobalStandardId,
          globalQuestionId,
          questionSequenceNumber,
          questionGroupId,
        } = item;

        const extractedItem = {
          _id,
          yearId,
          xseedGlobalSubjectId: xseedGlobalSubjectId.id,
          xseedGlobalStandardId,
          globalQuestionId,
          questionSequenceNumber,
          questionGroupId,
        };

        extractedData[_id] = extractedItem;
      });

      return extractedData;
    },

    addQuestionDataWithClone(questData) {
      let clonePushed = [];
      let tq = [];
      if (questData.testData) {
        this.tittle = questData.testData.testName;
        this.description = questData.testData.testName;
        this.isCloneRandomized = questData.testData.isRandomisedClone;
        const combinedList = [
          ...questData.questions.normalQuestions.flat(Infinity),
          ...questData.questions.groupQuestions.flat(Infinity),
        ];
        combinedList.sort(
          (a, b) => a.questionDisplaySequenceNumber - b.questionDisplaySequenceNumber
        );
        for (let question of combinedList) {
          if (!question.questionGroupId) {
            if (!clonePushed.includes(question.globalQuestionId)) {
              clonePushed.push(question.globalQuestionId);
              let sameClone = combinedList.filter(
                (sc) => sc.globalQuestionId == question.globalQuestionId
              );
              tq.push(sameClone);
            }
          } else {
            if (
              !clonePushed.includes(
                question.questionGroupId +
                  (question.xseedGlobalSubjectId
                    ? '-' + question.xseedGlobalSubjectId.id.toString()
                    : '')
              )
            ) {
              clonePushed.push(
                question.questionGroupId +
                  (question.xseedGlobalSubjectId
                    ? '-' + question.xseedGlobalSubjectId.id.toString()
                    : '')
              );
              let gpClonePushed = [];

              let sameGP = combinedList.filter(
                (gp) =>
                  gp.questionGroupId == question.questionGroupId &&
                  gp.xseedGlobalSubjectId &&
                  question.xseedGlobalSubjectId &&
                  gp.xseedGlobalSubjectId.id == question.xseedGlobalSubjectId.id
              );
              let sameCloneGroup = [];
              let sameCloneGPGroup = [];
              sameGP.forEach((gp) => {
                if (!gpClonePushed.includes(gp.cloneNumber)) {
                  let cloneWise = [];
                  gpClonePushed.push(gp.cloneNumber);
                  let a = sameGP.filter((b) => gp.cloneNumber == b.cloneNumber);
                  cloneWise.push(a);
                  cloneWise.forEach((c) => {
                    sameCloneGPGroup.push(c);
                  });
                }
              });
              sameCloneGroup.push(sameCloneGPGroup);
              tq.push(sameCloneGroup);
            }
          }
        }

        Object.assign(this.questions, tq);
        const extractedQuestionData = this.extractDataFromJson(this.questions);

        this.currentQuestionData = extractedQuestionData;
        this.fetchProjectListWithStandardIdAndSubjectId({
          Questions: [extractedQuestionData],
        });
      }
    },
    async createQuestionSetFinal() {
      let updatedQuestionList = [];
      for (let question of this.test.data.questions['normalQuestions']) {
        if ('authored' in question[0] && !question[0].authored) {
          Vue.notify({
            title: 'Info!',
            text: `All questions must be Authored to save!`,
            type: 'info',
          });
          return;
        }
        let questionData = question.find((item) => item.isSelected);
        updatedQuestionList.push({
          questionId: questionData._id,
          cloneNumber: questionData.cloneNumber,
          questionSequence: questionData.questionSequenceNumber,
          globalQuestionId: questionData.globalQuestionId,
          questionDisplaySequenceNumber: questionData.questionDisplaySequenceNumber,
        });
      }
      for (let groupQuestions of this.test.data.questions['groupQuestions']) {
        for (let clones of groupQuestions) {
          clones.map((clone) => {
            if (!clone.authored) {
              Vue.notify({
                title: 'Info!',
                text: `All questions must be Authored to save!`,
                type: 'info',
              });
              return;
            }
          });
          let questionData = clones.filter((item) => item.isSelected);
          questionData.forEach((question) => {
            if (question)
              updatedQuestionList.push({
                questionId: question._id,
                cloneNumber: question.cloneNumber,
                questionSequence: question.questionSequenceNumber,
                globalQuestionId: question.globalQuestionId,
                questionDisplaySequenceNumber: question.questionDisplaySequenceNumber,
              });
          });
        }
      }
      await this.publishXLMTest({
        id: this.$route.params.testId,
        questionSetId: this.test.data.testData.questionSetId,
        testName: this.tittle,
        testDescription: this.description,
        standardId: this.test.data.testData.standardId,
        isRandomisedClone: this.isCloneRandomized,
        questionList: updatedQuestionList,
        publishTest: true,
      });
    },
    updateCloneNumber(question, cloneNumber) {
      console.log(question, cloneNumber);
      this.selectedQuestionAndClone[question.globalQuestionId]['cloneNumber'] = cloneNumber;
      console.log(this.selectedQuestionAndClone);
    },
    getQuestionIndex(question) {
      return this.selectedQuestionAndClone[question[0].globalQuestionId].cloneNumber - 1;
    },
    returnSelectedQuestion(questions) {
      let question = {};
      questions.forEach((value) => {
        if (value.isSelectedLocal == true) {
          question = value;
        }
      });
      return question;
    },
    returnSelectedGroupQuestion(groupQuestions) {
      let question = [];
      let outerIndex = 0;
      groupQuestions.forEach((value, index) => {
        value.forEach((q) => {
          if (q.isSelectedLocal == true) {
            question.push(q);
            outerIndex = index;
          }
        });
      });
      return { questions: question, index: outerIndex };
    },
    updateSelectedQuestionV2(clones, questionId) {
      clones.forEach((value) => {
        if (value._id == questionId) {
          value.isSelectedLocal = true;
          this.returnSelectedQuestion(clones);
        } else {
          value.isSelectedLocal = false;
        }
      });
    },

    showReopenProjectDialog() {
      this.resetProjectData();
      this.isReopen = true;
      this.buttonText = 'REOPEN';
      for (const assignee in this.projectData.assignees) {
        this.projectData.assignees[assignee].id = '';
      }
      this.showCreateQuestionProject = true;
    },

    getUsersByRoles(role) {
      let sortListedUsers = [];
      if (!this.mandatoryStages.includes(role)) {
        sortListedUsers.push({
          id: this.SKIP_STAGE_USER_ID_VALUE,
          name: 'Skip(Not assign Users)',
        });
      }

      for (let user of this.users) {
        let rolesList = user.roles.map((role) => role.name);
        if (rolesList.includes(role) && user.user && user.user._id && user.user.name) {
          sortListedUsers.push({
            id: user.user._id,
            name: user.user.name,
          });
        }
      }

      return sortListedUsers;
    },

    resetProjectData() {
      this.projectData = {
        workflowStatus: PROJECT_WORKFLOW_STATUSES_HASH.NOT_STARTED,
        entityType: 'Learnometer',
        type: 'learnometerQuestion',
        subjectId: '',
        standardId: '',
        name: '',
        entityId: '111',
        questionSetEditing: false,
        currentQuestionData: '',
        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
        contents: [
          {
            currentStage: '',
          },
        ],
      };
    },

    validateAssigneeForm() {
      if (this.$refs.project_assignee_form.validate()) {
        this.saveFormData();
      }
    },

    saveFormData() {
      this.projectData.standardId = +this.currentlySelectedQuestionDetails.xseedGlobalStandardId;
      this.projectData.subjectId = +this.currentlySelectedQuestionDetails.xseedGlobalSubjectId;
      this.projectData.name = this.currentlySelectedProjectDetails.name;
      this.projectData.questionSetEditing = true;

      this.projectData.currentGlobalQuestionId = this.currentlySelectedQuestionDetails.globalQuestionId;
      this.projectData.currentQuestionSequenceNumber = this.currentlySelectedQuestionDetails.questionSequenceNumber;
      this.projectData.projectId = this.currentlySelectedProjectDetails._id;
      this.projectData.selectedYear = this.currentlySelectedQuestionDetails.yearId;
      this.projectData.isDemoQuestion = this.currentlySelectedQuestionDetails.isDemoQuestion;
      if (this.currentlySelectedQuestionDetails.questionGroupId) {
        this.projectData.questionGroupId = this.currentlySelectedQuestionDetails.questionGroupId;
        this.projectData.isGroupQuestion = true;
      }

      this.createLearnometerQuestionProject(this.projectData);
      this.showCreateQuestionProject = false;
      this.resetProjectData();
    },

    checkProjectStatus(question) {
      this.currentlySelectedQuestionDetails = this.currentQuestionData[
        this.returnSelectedQuestion(question)._id
      ];
      this.currentlySelectedProjectDetails = this.project.data[
        this.returnSelectedQuestion(question)._id
      ];

      let currentWorkflowStatus = this.project.data[this.returnSelectedQuestion(question)._id]
        .workflowStatus;

      if (currentWorkflowStatus == 'completed' && (this.isAdmin || this.isAcademicAdmin)) {
        this.showReopenProjectDialog();
      } else if (currentWorkflowStatus == 'in_progress' && (this.isAdmin || this.isAcademicAdmin)) {
        const targetUrl = this.$router.resolve({
          name: 'LearnometerQuestion',
          params: {
            questionSequenceNumber: this.currentlySelectedQuestionDetails.questionSequenceNumber,
            id: this.currentlySelectedProjectDetails._id,
            selectedYear: this.currentlySelectedQuestionDetails.yearId,
          },
          query: {
            showDemoQuestions: this.currentlySelectedQuestionDetails.isDemoQuestion,
          },
        }).href;
        window.open(targetUrl, '_blank');
      }
    },

    checkProjectStatusOfGroupQuestion(question) {
      this.currentlySelectedQuestionDetails = this.currentQuestionData[question._id];
      this.currentlySelectedProjectDetails = this.project.data[question._id];

      let currentWorkflowStatus = this.project.data[question._id].workflowStatus;

      if (currentWorkflowStatus == 'completed' && (this.isAdmin || this.isAcademicAdmin)) {
        this.showReopenProjectDialog();
      } else if (currentWorkflowStatus == 'in_progress' && (this.isAdmin || this.isAcademicAdmin)) {
        const targetUrl = this.$router.resolve({
          name: 'LearnometerQuestion',
          params: {
            questionSequenceNumber: this.currentlySelectedQuestionDetails.questionGroupId,
            id: this.currentlySelectedProjectDetails._id,
            selectedYear: this.currentlySelectedQuestionDetails.yearId,
          },
          query: {
            showDemoQuestions: this.currentlySelectedQuestionDetails.isDemoQuestion,
            isGroupQuestion: true,
          },
        }).href;
        window.open(targetUrl, '_blank');
      }
    },
    updateSelectedGroupQuestion(clones, previousCloneIndex, newCloneIndex) {
      clones[previousCloneIndex].forEach((question) => {
        question.isSelectedLocal = false;
      });
      clones[newCloneIndex].forEach((question) => {
        question.isSelectedLocal = true;
      });
      this.returnSelectedGroupQuestion(clones);
    },
    scrollToQuestionContainer(questionIndex) {
      document
        .getElementById('questionContainer' + questionIndex)
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
  data() {
    return {
      tittle: '',
      description: '',
      isShowAnalytics: false,
      showMetadata: [],
      isCloneRandomized: false,
      analyticsTab: 'difficulty',
      selectedQuestion: 0,
      scrollAmount: 200,
      selectedQuestionAndClone: {},
      tempKey: 0,
      questions: [],
      currentWorkFlowStatus: '',
      showCreateQuestionProject: false,
      buttonText: 'REOPEN',
      SKIP_STAGE_USER_ID_VALUE,
      loading: true,
      AppTexts,
      mandatoryStages: ['Asset Uploader', 'Asset Authorizer', 'Proof Reader'],
      currentlySelectedQuestionDetails: '',
      currentlySelectedProjectDetails: '',
      projectData: {
        workflowStatus: PROJECT_WORKFLOW_STATUSES_HASH.NOT_STARTED,
        entityType: 'Learnometer',
        type: 'learnometerQuestion',
        subjectId: 0,
        standardId: 0,
        name: '',
        entityId: '111',
        questionSetEditing: false,
        questionSequenceNumber: 0,
        currentGlobalQuestionId: 0,
        currentQuestionSequenceNumber: 0,
        projectId: '',
        selectedYear: 0,
        isDemoQuestion: false,
        isGroupQuestion: false,
        questionGroupId: 0,

        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
        contents: [
          {
            currentStage: '',
          },
        ],
      },
    };
  },

  mounted() {
    this.getCurrentUserAuthPermissions();
  },
};
</script>

<style scoped lang="scss">
.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.first-row {
  background-color: #d0f9f5;
}
.difficulty-level {
  box-sizing: border-box;
  color: #f5f5f5;
  font-size: 13px;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 10px;

  width: 110px;
  height: 31px;

  background: #b81e22;
  border-radius: 111px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.yellow-container {
  width: 12px;
  height: 33px;
  background-color: #f6b137;
  border-radius: 3px;
  margin-right: 10px;
  /* Add any other styles you want for the container */
}
.rounded-container {
  border-radius: 10px; /* Adjust the value as per your preference */
  background-color: #ffffff; /* Set the background color */
  padding: 20px; /* Add padding as needed */
  border-color: #242424;
  /* Add any other styles you want for the container */
}

.question-12 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 15px;
  /* Add any other styles you want for the question text */
}
.metadata {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #404040;
}
.edit-question {
  width: 77px;
  height: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.23px;
  text-decoration-line: underline;
  color: #b81e22;
}
.clone-button {
  @extend .edit-question;
  text-decoration-line: none;
}
.top-skill-text {
  @extend .edit-question;
  color: #404040;
}
.skill-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  margin-right: 6em;
  /* Add any other styles you want for the question text */
}
.skill-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-right: 6em;

  /* Add any other styles you want for the question text */
}
.text-field {
  background-color: white;
  padding-left: 3px;
  padding-right: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
}
.text-field::placeholder {
  color: #686868;
}

.custom-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 33px;
  gap: 6px;
  width: 154px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #b81e22;
  color: #b81e22;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center; /* Center-align the text */
}
.save-button {
  background-color: #b81e22;
  color: white;
}
.selected-button {
  background-color: #02a726;
  border: 1px solid #02a726;
  color: white;
}
.question-border {
  border: 1px solid #02a726;
}

.custom-box {
  // position: absolute;
  width: 130px;
  height: 36px;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add any additional styling you want for the box */
  border: 1px solid #686867;
  box-sizing: border-box;
}

.box-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center; /* Align the text at the center */
  color: #686868;
}

.row-list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding-bottom: 20px; /* Add bottom padding to accommodate horizontal scrollbar */
}

.row-item {
  flex-shrink: 0; /* Prevent items from shrinking */
}

.questions-text {
  font-size: 20px;
  font-weight: bold;
}
.custom-btn {
  color: #707b89 !important;
  background-color: #f7fbfd !important;
}
.bread-crumb-row p {
  width: 5px;
  height: 9px;
  padding: 9px 10px;
  color: #979797;
}
.bread-crumb-row a {
  cursor: pointer;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #242424;
  opacity: 0.7;
}
.bread-crumb-row {
  display: flex;
  flex-direction: row;
}

.topic-view-container-one {
  .main-header {
    margin: 20px 15px 25px 15px;
    display: -webkit-box;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    height: 50px;
  }
  .main-header :nth-child(1) {
    width: 50%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
  .main-header :nth-child(2) {
    width: 20%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
  .main-header :nth-child(3) {
    width: 30%;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
}

.sub-project-status-wrapper {
  height: 60px;
}
.project-name {
  color: #242424;
  font-weight: 700;
  font-size: 12px;
}
.project-status-wrapper {
  margin-top: 14px;
  display: flex;
}
.project-status-wrapper .current-stage {
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-width: 1px;
  border-style: solid;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-status-wrapper .current-stage-text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-status-text {
  font-size: 12px;
  font-weight: 500;
}
.project-current-status-wrapper {
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-status-graph {
  border-radius: 10.5px;
  height: 5px;
  width: 70px;
  background-color: #eaeaea;
}
.project-status-graph > div {
  height: 5px;
  border-radius: 10.5px;
}
.project-status-graph .in_progress-bar {
  width: 50%;
  background-color: #ffa158;
}
.project-status-graph .completed-bar {
  width: 100%;
  background-color: #007aff;
}
.project-status-graph .com-bar {
  width: 100%;
  background-color: #3ab54a;
}
.project-status-graph .not_started-bar {
  width: 0%;
  background-color: #979797;
}

.filter-select-field,
.filter-select-field label {
  font-size: 14px;
  font-weight: 500;
  color: black;
}
.filter-select-field .v-label:not(.v-label--active) {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-5px);
}
.micro-topic-filter-card {
  padding: 20px;
  margin-left: 50px;
}
.landing {
  padding: 30px;
}
.learnometer-topic-form-dialog {
  transform: translateY(30px);
  max-height: 88% !important;
}

.landing-page:hover {
  background-color: #fff0f0;
  box-shadow: none;
}
.airTable-btn:hover {
  background-color: white;
  box-shadow: none;
}
.right-btn {
  position: absolute;
  right: 0;
  margin-right: 45px;
}
</style>
