<template>
  <v-form ref="actionSectionForm" class="section-title-cnr">
    <div v-for="(dataInstance, index) in data.instructions" :key="index">
      <Slide :slide="`Slide ${3 + index}`" />
      <v-row>
        <v-col v-for="(label, count) in checkBoxOptions" :key="'instuctionType' + count">
          <v-checkbox
            v-if="dataInstance.instructionType"
            v-model="dataInstance.instructionType"
            :value="label"
            :label="label"
            :disabled="
              !dataInstance.instructionType.includes(label) &&
              dataInstance.instructionType.length >= 2
            "
          ></v-checkbox>
        </v-col>
        <v-col>
          <DiffButton
            class="diff-button"
            :fieldIdentifier="`action.instructions[${index}].instructionType`"
            :isDiffChanged="true"
            :canShowDiffIcon="options.canShowDiffIcon"
          />
        </v-col>
      </v-row>
      <Slider
        :options="options"
        :data="dataInstance"
        :fileChangeHandler="fileChangeHandler"
        :type="`slider.action.${index}`"
        :fieldIdentifierPrefix="`action.instructions[${index}]`"
        :key="index"
        :isValidationRequired="index == 0 ? true : false"
        :dataCopy="currentStageDataCopy.action.instructions[index]"
        :addToAsset="addToAsset"
        :canAppproveAsset="canAppproveAsset"
        :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
      />
      <Padder :top="20">
        <SubSection :title="AppTexts.TEACHER_ZONE" />
        <TextInput
          :enableTextFormatting="true"
          :readonly="options.isReadOnly"
          :canShowDiffIcon="options.canShowDiffIcon"
          :fieldIdentifier="`action.instructions[${index}].teacherZone`"
          :hintText="AppTexts.TEACHER_ZONE"
          v-model="dataInstance.teacherZone"
        />
      </Padder>
      <XSDivider v-if="index < data.instructions.length - 1" />
    </div>
  </v-form>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import TextInput from '@/components/inputs/TextInput';
import Slider from '@/components/inputs/Slider.vue';
import AppTexts from '@/constants/AppTexts';
import SubSection from '@/components/inputs/SubSection';
import Slide from '@/components/contentEntry/Slide';
import DiffButton from '@/components/ContentDiff/DiffButton';
export default {
  name: 'ActionSection',
  components: {
    Slider,
    TextInput,
    SubSection,
    Slide,
    DiffButton,
  },
  computed: {
    ...mapState({
      currentStageDataCopy: (state) => state.contentEntry.currentStageDataCopy,
    }),
  },
  created() {
    for (let i = 0; i < 3; i++) {
      if (!this.data.instructions[i]) {
        this.data.instructions.push(
          _.cloneDeep({
            teacherZone: '',
            layoutType: '1 rich text box',
            layoutData: {
              text: '',
            },
            instructionType: [],
          })
        );
        this.currentStageDataCopy.action.instructions.push(
          _.cloneDeep({
            teacherZone: '',
            layoutType: '1 rich text box',
            layoutData: {
              text: '',
            },
            instructionType: [],
          })
        );
        this.options.signedAssetUrls.action.instructions.push({
          layoutData: {},
        });
      } else {
        if (!this.data.instructions[i].instructionType) {
          this.$set(this.data.instructions[i], 'instructionType', []);
          this.$set(this.currentStageDataCopy.action.instructions[i], 'instructionType', []);
        }
      }
    }
  },
  data() {
    return {
      AppTexts,
      checkBoxOptions: ['Chat Box', 'Unmute & Speak', 'Write in Notebook'],
    };
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
