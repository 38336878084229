<template>
  <v-form enctype="multipart/form-data" id="nativeForm">
    <v-file-input
      name="assets"
      :multiple="multiple"
      v-model="files"
      :loading="componentLoading || loading"
      show-size
      :accept="accept"
      :clearable="false"
      :error-messages="errorMessages"
      :error="error"
      :placeholder="placeholder"
      :prepend-icon="prependIcon"
      outlined
      :disabled="disabled"
    ></v-file-input>
    <slot />
    <v-btn
      @click="uploadToAssetVault"
      :disabled="disabled"
      :loading="componentLoading || loading"
      color="primary"
      >Done</v-btn
    >
  </v-form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AssetVaultUpload',
  data() {
    return {
      files: null,
      componentLoading: false,
      errorMessages: [],
      error: false,
    };
  },
  computed: {
    accept() {
      return this.acceptedFileTypes.join(',');
    },
  },
  methods: {
    async uploadToAssetVault(e) {
      e.preventDefault();
      if (this.files && this.files.length) {
        this.error = false;
        this.errorMessages = [];
        this.componentLoading = true;
        var formData = new FormData();
        if (this.multiple) {
          this.files.forEach((file) => {
            this.formData('assets[]', file, file.name);
          });
          throw new Error('Multiple assets is not supported by AssetVault.');
        } else {
          if (
            this.acceptableFileSizeInMB != 0 &&
            this.files[0].size > this.acceptableFileSizeInMB * 1000000
          ) {
            this.error = true;
            this.componentLoading = false;
            this.errorMessages.push(
              `file size cannot be greater than ${this.acceptableFileSizeInMB} MB!`
            );
            return;
          }

          console.log('this.files[0].type', this.files[0].type);
          console.log('this.acceptedFileTypes', this.acceptedFileTypes);
          if (!this.acceptedFileTypes.includes(this.files[0].type)) {
            this.error = true;
            this.componentLoading = false;
            this.errorMessages.push('Invalid file type!');
            return;
          }
          formData.append('assets', this.files[0], this.files[0].name);
        }
        formData.append('preset', this.preset);
        formData.append('appName', this.appName);

        axios
          .post(`${process.env.VUE_APP_ASSET_VAULT_ENDPOINT}/upload`, formData, {
            headers: {
              Authorization: `Bearer ${this.getCookie('xs-lp-entry-token')}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.data && response.data.status) {
              this.componentLoading = false;
              if (this.clearFieldOnSuccess) {
                this.files = [];
              }
              this.assetResponseHook(response.data.data);
            }

            // Reset all file input states
            this.error = false;
            this.errorMessages = [];
          })
          .catch((error) => {
            console.log('came to error');
            console.log(error);
            if (error.response && error.response.status == 422) {
              this.errorMessages.push(error.response.data.error);
            } else {
              this.errorMessages.push('Something went wrong!');
            }
            this.componentLoading = false;
            this.error = true;
            this.files = [];
          });
      } else if (this.enableBlankImageInsert) {
        this.assetResponseHook({ isEmpty: true });
      }
    },
    getCookie(cookieName) {
      let existingCookieObj = this.getCookieAsObject();
      return existingCookieObj[cookieName];
    },
    getCookieAsObject() {
      const existingCookie = document.cookie;
      let existingCookieObj = {};
      existingCookie.split(';').map((keyVal) => {
        let cookieVals = keyVal.split('=').map((val) => val.trim());
        existingCookieObj[cookieVals[0]] = cookieVals[1];
      });

      return existingCookieObj;
    },
  },
  props: {
    preset: {
      type: String,
      required: true,
    },
    appName: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    assetResponseHook: {
      type: Function,
      required: true,
    },
    acceptedFileTypes: {
      type: Array,
      default: () => ['image/png', 'image/jpeg'],
    },
    placeholder: {
      type: String,
      default: 'Supported files (png/jpeg)',
    },
    prependIcon: {
      type: String,
      default: 'mdi-attachment',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearFieldOnSuccess: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enableBlankImageInsert: {
      type: Boolean,
      default: false,
    },
    acceptableFileSizeInMB: {
      type: Number,
      default: 0,
    },
  },
};
</script>
