<template>
  <v-container>
    <v-dialog v-model="showLoader" max-width="400" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          Baking your cake
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar
      height="58px;"
      absolute
      dense
      color="white"
      elevation="2"
      scroll-target="#scrolling-techniques-7"
      class="action-bar"
    >
      <div class="bread-crumb-row">
        <a href="/learnometer">Learnometer</a>
        <v-icon style="color: #818181;">mdi-chevron-right</v-icon>
        <a href="javascript:void(0);"><b>Question Authoring</b></a>
      </div>
    </v-app-bar>

    <v-content class="mt-14">
      <v-row class="questions-text"> Questions </v-row>
      <v-row style="font-size: 20px;"> Grade {{ getGradeName() }} - {{ getSubjectName() }} </v-row>

      <div class="topic-view-container-one">
        <v-card>
          <div class="main-header">
            <div>Question set</div>
            <div>Current Stage</div>
            <div>Person Responsible</div>
          </div>
        </v-card>
        <div>
          <MicroTopic
            :currentStage="getProjectCurrentStage(projectDetails)"
            :name="projectDetails.name"
            :assignee="getProjectCurrentStageAssignee(projectDetails)"
            :id="projectDetails.id"
            :projectData="projectDetails"
            :isAdmin="isAdmin"
            :updatedDate="updatedDay"
            :showViewDelete="false"
            :showOnlyLastUpdateTime="true"
            columnType="three"
          />
        </div>
      </div>
      <v-btn
        depressed
        elevation="2"
        outlined
        x-large
        :class="{
          'ma-2 custom-btn': this.SELECTED_YEAR !== 0,
          'ma-2 selected-year-btn': this.SELECTED_YEAR === 0,
        }"
        @click="getQuestionForCorrespondingYear(0)"
        >Demo Questions</v-btn
      >
      <v-btn
        depressed
        elevation="2"
        outlined
        x-large
        :class="{
          'ma-2 custom-btn': this.SELECTED_YEAR !== 1,
          'ma-2 selected-year-btn': this.SELECTED_YEAR === 1,
        }"
        @click="getQuestionForCorrespondingYear(1)"
        >Y2022 - Questions</v-btn
      >
      <v-btn
        depressed
        elevation="2"
        outlined
        x-large
        :class="{
          'ma-2 custom-btn': this.SELECTED_YEAR !== 2,
          'ma-2 selected-year-btn': this.SELECTED_YEAR === 2,
        }"
        @click="getQuestionForCorrespondingYear(2)"
        >Y2023 - Questions</v-btn
      >
      <v-btn
        depressed
        elevation="2"
        outlined
        x-large
        :class="{
          'ma-2 custom-btn': this.SELECTED_YEAR !== 3,
          'ma-2 selected-year-btn': this.SELECTED_YEAR === 3,
        }"
        @click="getQuestionForCorrespondingYear(3)"
        >Y2024 - Questions</v-btn
      >
      <div style="height: 30px;"></div>
      <v-card class="pa-6">
        <v-row align-content="left">
          <v-col class="ml-2" md="auto">
            <div style="height: 40px; width: 10px; background: #bb161b;" />
          </v-col>
          <v-col md="auto" class="questions-text"> Questions </v-col>
        </v-row>

        <v-btn
          v-for="(i, index) in Object.keys(questionsGroupedBySequenceNumber)"
          :key="`learnometerViewProjectTilePage${index}`"
          depressed
          elevation="2"
          outlined
          x-large
          class="ma-2 custom-btn"
          @click="gotoQuestionSet(questionsGroupedBySequenceNumber[i])"
          >Q {{ i }} - ({{ questionsGroupedBySequenceNumber[i].length }} Clones)</v-btn
        >
        <v-btn
          v-for="(i, index) in Object.keys(groupQuestionsGroupedBySequenceNumber)"
          :key="`learnometerViewProjectTilePageGroupQuestion${index}`"
          depressed
          elevation="2"
          outlined
          x-large
          class="ma-2 custom-btn-2"
          @click="gotoGroupQuestionSet(groupQuestionsGroupedBySequenceNumber[i])"
          >{{ i }} - ({{
            getClonesCountForTheGroup(groupQuestionsGroupedBySequenceNumber[i])
          }}
          Clones)</v-btn
        >
        <v-btn
          v-for="(i, index) in Object.keys(demoQuestionsBySequenceNumber)"
          :key="`learnometerViewProjectTilePageDemoQuestion${index}`"
          depressed
          elevation="2"
          outlined
          x-large
          class="ma-2 custom-btn"
          @click="gotoQuestionSet(demoQuestionsBySequenceNumber[i])"
          >Demo {{ i }} - ({{ demoQuestionsBySequenceNumber[i].length }} Clones)</v-btn
        >
      </v-card>
      <br />
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          color="primary mr-2"
          v-if="canShowSubmitButton"
          :disabled="!disableSubmitButton"
          style="width: 300px;"
          @click="validateQuestionCloneCount(questionsGroupedBySequenceNumber)"
          >Submit</v-btn
        >
        <v-btn
          color="primary mr-2"
          v-if="canShowPublishButton"
          :disabled="disablePublishButton"
          style="width: 300px;"
          @click="publishQuestionCloneCount(questionsGroupedBySequenceNumber)"
          >Publish</v-btn
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-content>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { groupBy } from '@/functions/array';
import { LP_STAGES, LP_ASSIGNEES } from '@/constants/LessonPlan';
import MicroTopic from '@/components/contentEntry/microTopic/MicroTopicSingleView';
import { capitalCase } from 'change-case';
import {
  PROOF_READING_STAGE,
  COMPLETED_STAGE,
  ASSET_UPLOAD_STAGE,
  ASSET_AUTHORIZING_STAGE,
} from '@/constants/microTopic.js';
import { isRteEmpty } from '@xseededucation/rte-helper-functions';
import { get } from 'lodash';
import { PROJECT_LESSON_PLAN_STAGE_KEY_TO_ASSIGNEE_KEY_NAME_MAPPING } from '@/constants/Project';
export default {
  name: 'ViewLearnometerProject',
  components: {
    MicroTopic,
  },
  beforeCreate() {
    this.getCurrentUserAuthPermissions();
  },
  created() {
    this.getCurrentUserAuthPermissions();
    this.getQuestionForCorrespondingYear(this.SELECTED_YEAR);
  },
  data() {
    return {
      LP_STAGES,
      LP_ASSIGNEES,
      PROOF_READING_STAGE,
      COMPLETED_STAGE,
      ASSET_UPLOAD_STAGE,
      ASSET_AUTHORIZING_STAGE,
      questionsGroupedBySequenceNumber: {},
      groupQuestionsGroupedBySequenceNumber: {},
      demoQuestionsBySequenceNumber: {},
      MANDATORY_META_DATA_PATH: [
        { path: 'metadata.primaryBigSkillId', label: 'primaryBigSkillId' },
        { path: 'metadata.primarySubSkillId', label: 'primarySubSkillId' },
        { path: 'metadata.themeId', label: 'themeId' },
        { path: 'score', label: 'score' },
        { path: 'weightage', label: 'weightage' },
      ],
      SELECTED_YEAR: 3,
      KEYS_FOR_ASSET_CHECKING: {
        stimulus: 'stimulus',
        questionText: 'questionText',
        optionText: 'optionText',
      },
    };
  },
  computed: {
    ...mapState({
      currentStage: (state) => state.contentEntry.contentData.currentStage,
      contentData: (state) => state.contentEntry.contentData,
      projectDetails: (state) => state.contentEntry.projectDetails,
      contentDetails: (state) => state.contentEntry.contentDetails,
      currentStageData: (state) => state.contentEntry.currentStageData,
      loadingStatus: (state) => state.contentEntry.loadingStatus,
      loggedInUser: (state) => state.loggedInUser,
      isAdmin: (state) => state.auth.isAdmin,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
    }),
    currentStageIndex() {
      try {
        let _index = Object.keys(LP_STAGES).indexOf(this.projectData.contents[0].currentStage);

        return _index != -1 ? _index : 0;
      } catch (error) {
        console.log('error' + error);
      }
      return 0;
    },
    assigneeData() {
      let names = {};
      for (let role in this.projectDetails.assignees) {
        if (
          role != 'assetAuthorizer' &&
          (!this.projectDetails.assignees[role] || this.projectDetails.assignees[role].id === '')
        ) {
          names[role] = 'skipped';
        } else {
          names[role] = this.projectDetails.assignees[role].name;
        }
      }
      names['author2'] = names.author;
      return names;
    },
    updatedDay() {
      const pastDate = this.contentData.currentStageData.updatedAt;
      let dayDifference = Date.now() - pastDate;
      const toatalDay = Math.floor(dayDifference / (1000 * 3600 * 24));
      const hours = toatalDay * 1000 * 3600 * 24;
      const totalMin = Math.ceil((dayDifference - hours) / (1000 * 24));
      const totalHours = Math.ceil((dayDifference - hours) / (1000 * 3600));
      if (
        this.contentData.currentStageData.updatedAt == this.contentData.currentStageData.createdAt
      ) {
        return '';
      } else if (totalMin == 1) {
        return `last edited few seconds ago`;
      } else if (totalMin <= 59) {
        return `last edited ${totalMin} min ago`;
      } else if (totalHours <= 23) {
        return `last edited ${totalHours} hours ago`;
      }
      return `last edited ${toatalDay} days ago`;
    },
    showLoader() {
      return this.loadingStatus.status == 'in_progress';
    },
    canShowSubmitButton() {
      return (
        this.currentStage != this.PROOF_READING_STAGE && this.currentStage != this.COMPLETED_STAGE
      );
    },
    canShowPublishButton() {
      return this.currentStage == this.PROOF_READING_STAGE;
    },
    disableSubmitButton() {
      return (
        this.projectDetails.assignees[
          PROJECT_LESSON_PLAN_STAGE_KEY_TO_ASSIGNEE_KEY_NAME_MAPPING[
            this.projectDetails.contents[0].currentStage
          ]
        ].id == this.loggedInUser.id
      );
    },
    disablePublishButton() {
      return !(this.isAdmin || this.isAcademicAdmin);
    },
  },
  methods: {
    capitalCase,
    ...mapActions([
      'getContentEntryProjectDetails',
      'fetchSubjects',
      'fetchStandards',
      'moveContentToNextStage',
      'completeContentAndPublish',
      'getLearnometerProjectDetails',
      'getCurrentUserAuthPermissions',
    ]),
    ...mapMutations(['SET_CURRENT_STAGE_DATA']),

    getQuestionForCorrespondingYear(yearId) {
      this.SELECTED_YEAR = yearId;
      (this.questionsGroupedBySequenceNumber = {}),
        (this.groupQuestionsGroupedBySequenceNumber = {}),
        (this.demoQuestionsBySequenceNumber = {}),
        this.getLearnometerProjectDetails({ projectId: this.$route.params.id, yearId: yearId });
    },
    getProjectCurrentStage(project) {
      try {
        if (project.workflowStatus == 'completed') return 'Published';
        return this.LP_STAGES[project.contents[0].currentStage];
      } catch (er) {
        return '-';
      }
    },
    getProjectCurrentStageAssignee(project) {
      try {
        return project.assignees[this.LP_ASSIGNEES[project.contents[0].currentStage]].name;
      } catch (er) {
        return '-';
      }
    },
    gotoQuestionSet(row) {
      if (row.length) {
        this.$router.push({
          name: 'LearnometerQuestion',
          params: {
            questionSequenceNumber: row[0].questionSequenceNumber,
            id: this.$route.params.id,
            selectedYear: this.SELECTED_YEAR,
          },
          query: {
            showDemoQuestions: row[0].isDemoQuestion,
          },
        });
      }
    },
    gotoGroupQuestionSet(row) {
      if (row.length) {
        this.$router.push({
          name: 'LearnometerQuestion',
          params: {
            questionSequenceNumber: row[0].questionGroupId,
            id: this.$route.params.id,
            selectedYear: this.SELECTED_YEAR,
          },
          query: {
            showDemoQuestions: row[0].isDemoQuestion,
            isGroupQuestion: true,
          },
        });
      }
    },
    getGradeName() {
      return ((this.projectDetails || {}).standard || {}).name || 'NA';
    },
    getSubjectName() {
      return ((this.projectDetails || {}).subject || {}).name || 'NA';
    },
    isValidDemoQuestions() {
      for (const key in this.demoQuestionsBySequenceNumber) {
        if (
          this.demoQuestionsBySequenceNumber[key].length > 2 ||
          this.demoQuestionsBySequenceNumber[key].length == 0
        ) {
          return false;
        }
      }
      return true;
    },
    validateQuestionCloneCount(questionsGroupedBySequenceNumber) {
      console.log(
        'questionsGroupedBySequenceNumber' +
          questionsGroupedBySequenceNumber[Object.keys(questionsGroupedBySequenceNumber)[0]].length
      );
      let setToValidateLength = new Set();
      for (const key in questionsGroupedBySequenceNumber) {
        const element = questionsGroupedBySequenceNumber[key];
        setToValidateLength.add(element.length);
      }

      const validDummyQuestion = this.validateDummyQuestions(this.currentStageData);
      const contentValidation = this.validateIsNotQuestionEmpty();
      if (!validDummyQuestion || !contentValidation.status) {
        this.$notify({
          title: 'Error!',
          text: 'Question data not created.',
          type: 'error',
          clean: false,
          duration: -1,
        });
        this.$notify({
          title: 'Validation error!',
          text: 'Please enter content for ' + contentValidation.message + '.',
          type: 'error',
          clean: false,
          duration: -1,
        });
        return;
      }
      let processData = JSON.parse(JSON.stringify(this.currentStageData));
      if (
        this.currentStage == this.ASSET_UPLOAD_STAGE ||
        this.currentStage == this.ASSET_AUTHORIZING_STAGE
      ) {
        processData = processData.map((_e) => this.processQuestionData(_e, 'parse'));
      }
      if (this.currentStage == this.ASSET_UPLOAD_STAGE) {
        let blankAssets = this.validateAllAssetsPresent(processData, 'assetId', [], {
          _id: undefined,
          isDemoQuestion: undefined,
          questionSequenceNumber: undefined,
          questionGroupId: undefined,
          key: undefined,
        });
        console.log(blankAssets);
        if (blankAssets.length > 0) {
          const groupedQuestionsByName = {};
          let questionIndices = new Set(),
            count = 0;
          blankAssets.forEach((asset) => {
            if (asset.isDemoQuestion && asset.questionSequenceNumber) {
              groupedQuestionsByName['Demo ' + asset.questionSequenceNumber] = asset._id;
            } else if (asset.questionGroupId == '' || asset.questionGroupId == null) {
              groupedQuestionsByName['Q' + asset.questionSequenceNumber] == undefined
                ? (groupedQuestionsByName['Q' + asset.questionSequenceNumber] = [asset])
                : groupedQuestionsByName['Q' + asset.questionSequenceNumber].push(asset);
            } else {
              groupedQuestionsByName[asset.questionGroupId] == undefined
                ? (groupedQuestionsByName[asset.questionGroupId] = [asset])
                : groupedQuestionsByName[asset.questionGroupId].push(asset);
            }
          });
          console.log(groupedQuestionsByName);
          for (const key in groupedQuestionsByName) {
            if (Array.isArray(groupedQuestionsByName[key])) {
              let keys = [];
              for (const asset of groupedQuestionsByName[key]) {
                if (keys.includes('stimulus') && asset['key'] == 'stimulus') {
                  continue;
                } else {
                  count = count + 1;
                  keys.push(asset.key);
                }
              }
            } else {
              count = count + 1;
            }
          }
          questionIndices = Object.keys(groupedQuestionsByName);
          if (questionIndices.length == 1) {
            this.$notify({
              title: 'Failed',
              text: `There are ${count} empty asset in the page. Please upload all the assets.`,
              type: 'error',
              duration: -1,
            });
            this.$notify({
              title: 'Validation error!',
              text: `Please check assets in question ${questionIndices}.`,
              type: 'error',
              duration: -1,
            });
          } else {
            this.$notify({
              title: 'Failed',
              text: `There are ${count} empty assets in the page. Please upload all the assets.`,
              type: 'error',
              duration: -1,
            });
            this.$notify({
              title: 'Validation error!',
              text: `Please check assets in questions ${questionIndices
                .slice(0, -1)
                .join(', ')} and ${questionIndices.slice(-1)}.`,
              type: 'error',
              duration: -1,
            });
          }
          return false;
        }
      }
      if (this.currentStage == this.ASSET_AUTHORIZING_STAGE) {
        console.log(processData);
        let unApprovedAssets = this.validateAssetAuthorized(
          processData,
          'assetId',
          'assetApproved',
          [],
          {
            _id: undefined,
            isDemoQuestion: undefined,
            questionSequenceNumber: undefined,
            questionGroupId: undefined,
            key: undefined,
          }
        );
        console.log(unApprovedAssets);
        if (unApprovedAssets.length > 0) {
          const groupedQuestionsByName = {};
          let questionIndices = new Set(),
            count = 0;
          unApprovedAssets.forEach((asset) => {
            if (asset.isDemoQuestion && asset.questionSequenceNumber) {
              groupedQuestionsByName['Demo ' + asset.questionSequenceNumber] = asset._id;
            } else if (asset.questionGroupId == '' || asset.questionGroupId == null) {
              groupedQuestionsByName['Q' + asset.questionSequenceNumber] == undefined
                ? (groupedQuestionsByName['Q' + asset.questionSequenceNumber] = [asset])
                : groupedQuestionsByName['Q' + asset.questionSequenceNumber].push(asset);
            } else {
              groupedQuestionsByName[asset.questionGroupId] == undefined
                ? (groupedQuestionsByName[asset.questionGroupId] = [asset])
                : groupedQuestionsByName[asset.questionGroupId].push(asset);
            }
          });
          console.log(groupedQuestionsByName);
          for (const key in groupedQuestionsByName) {
            if (Array.isArray(groupedQuestionsByName[key])) {
              let keys = [];
              for (const asset of groupedQuestionsByName[key]) {
                if (keys.includes('stimulus') && asset['key'] == 'stimulus') {
                  continue;
                } else {
                  count = count + 1;
                  keys.push(asset.key);
                }
              }
            } else {
              count = count + 1;
            }
          }
          questionIndices = Object.keys(groupedQuestionsByName);
          console.log('inside asset upload if condition unApprovedAssetCount');
          if (questionIndices.length > 1) {
            this.$notify({
              title: 'Failed',
              text: `There are ${count} unapproved assets.`,
              type: 'error',
              duration: -1,
            });
            this.$notify({
              title: 'Validation error!',
              text: `Please check assets in questions ${questionIndices
                .slice(0, -1)
                .join(', ')} and ${questionIndices.slice(-1)}.`,
              type: 'error',
              duration: -1,
            });
          } else {
            this.$notify({
              title: 'Failed',
              text: `There are ${count} unapproved asset.`,
              type: 'error',
              duration: -1,
            });
            this.$notify({
              title: 'Validation error!',
              text: `Please check assets in question ${questionIndices}.`,
              type: 'error',
              duration: -1,
            });
          }
          return false;
        }
      }
      this.moveContentToNextStage({
        projectId: this.$route.params.id,
        data: this.currentStageData,
        projectType: 'learnometerQuestions',
      });
    },
    validateQuestionMetaData() {
      let nonDemoQuestions = this.currentStageData.filter((_c) => !_c.isDemoQuestion);
      for (const _nonDemoQuestion of nonDemoQuestions) {
        let missingFields = [];
        let isValid = this.MANDATORY_META_DATA_PATH.reduce((accu, curr) => {
          if (!get(_nonDemoQuestion, curr.path)) {
            missingFields.push(curr.label);
            return accu && false;
          }
          return accu;
        }, true);

        if (!isValid) {
          this.$notify({
            title: 'Validation Error!',
            text: `The following fields for Question Number ${
              _nonDemoQuestion.questionSequenceNumber
            } are missing <br /> ${missingFields.join('<br />')}`,
            type: 'error',
            clean: false,
            duration: -1,
          });
          return false;
        }
      }
      return true;
    },
    removeDemoQuestionsIfNotAuthored() {
      let index = 0;
      while (index != this.currentStageData.length) {
        const processingQuestion = this.currentStageData[index];
        if (
          processingQuestion.isDemoQuestion &&
          (processingQuestion.isDummyQuestion ||
            isRteEmpty(processingQuestion.questionData.questionText))
        ) {
          this.$delete(this.currentStageData, index);
        } else {
          index++;
        }
      }
      let startingDemoQuestion = 1;
      for (let question of this.currentStageData) {
        if (question.isDemoQuestion) {
          question.questionSequenceNumber = startingDemoQuestion;
          startingDemoQuestion++;
        }
      }
      this.SET_CURRENT_STAGE_DATA(this.currentStageData);
    },
    publishQuestionCloneCount(questionsGroupedBySequenceNumber) {
      let setToValidateLength = new Set();
      for (const key in questionsGroupedBySequenceNumber) {
        const element = questionsGroupedBySequenceNumber[key];
        setToValidateLength.add(element.length);
      }
      this.removeDemoQuestionsIfNotAuthored();

      this.completeContentAndPublish({
        projectId: this.$route.params.id,
        data: this.currentStageData,
        projectType: 'learnometerQuestions',
      });
    },
    validateAssetAuthorized(_currentStageData, fieldIdentifier, approvedFlag, returned, parent) {
      if (
        Object.keys(_currentStageData).includes(fieldIdentifier) &&
        Object.keys(_currentStageData).includes(approvedFlag)
      ) {
        if (
          _currentStageData[fieldIdentifier] == null ||
          !_currentStageData[approvedFlag] ||
          _currentStageData[approvedFlag] == false
        ) {
          returned.push(parent);
        }
      } else {
        for (let key in _currentStageData) {
          if (JSON.stringify(_currentStageData[key]).includes(fieldIdentifier)) {
            this.validateAssetAuthorized(
              typeof _currentStageData[key] == 'string'
                ? JSON.parse(_currentStageData[key])
                : _currentStageData[key],
              fieldIdentifier,
              approvedFlag,
              returned,
              {
                _id: _currentStageData[key]['_id'] ?? parent._id,
                isDemoQuestion: _currentStageData[key]['isDemoQuestion'] ?? parent.isDemoQuestion,
                questionSequenceNumber:
                  _currentStageData[key]['questionSequenceNumber'] ?? parent.questionSequenceNumber,
                questionGroupId:
                  _currentStageData[key]['questionGroupId'] ?? parent.questionGroupId,
                key: this.KEYS_FOR_ASSET_CHECKING[key] ?? parent.key,
              }
            );
          }
        }
      }
      return returned;
    },
    validateAllAssetsPresent(_currentStageData, fieldIdentifier, returned, parent) {
      if (Object.keys(_currentStageData).includes(fieldIdentifier)) {
        if (!_currentStageData[fieldIdentifier]) {
          returned.push(parent);
        }
      } else {
        for (let key in _currentStageData) {
          if (JSON.stringify(_currentStageData[key]).includes(fieldIdentifier)) {
            returned = this.validateAllAssetsPresent(
              typeof _currentStageData[key] == 'string'
                ? JSON.parse(_currentStageData[key])
                : _currentStageData[key],
              fieldIdentifier,
              returned,
              {
                _id: _currentStageData[key]['_id'] ?? parent._id,
                isDemoQuestion: _currentStageData[key]['isDemoQuestion'] ?? parent.isDemoQuestion,
                questionSequenceNumber:
                  _currentStageData[key]['questionSequenceNumber'] ?? parent.questionSequenceNumber,
                questionGroupId:
                  _currentStageData[key]['questionGroupId'] ?? parent.questionGroupId,
                key: this.KEYS_FOR_ASSET_CHECKING[key] ?? parent.key,
              }
            );
          }
        }
      }
      return returned;
    },
    processQuestionData(data, processType) {
      let { questionData } = data;
      questionData.questionText =
        processType == 'parse'
          ? JSON.parse(questionData.questionText)
          : JSON.stringify(questionData.questionText);

      if (questionData.options) {
        questionData.options.forEach((_option) => {
          _option.optionText =
            processType == 'parse'
              ? JSON.parse(_option.optionText)
              : JSON.stringify(_option.optionText);
        });
      }
      return data;
    },
    validateDummyQuestions(data) {
      for (const datum in data) {
        if (data[datum].isDemoQuestion) continue;
        if (data[datum].isDummyQuestion) {
          console.log(data[datum]);
          return false;
        }
      }

      return true;
    },
    validateIsNotQuestionEmpty() {
      let nonDemoQuestions = this.currentStageData.filter((_a) => !_a.isDemoQuestion);
      let invalidQuestions = new Set();
      nonDemoQuestions.forEach((_, index) => {
        const question = nonDemoQuestions[index];
        if (isRteEmpty(question.questionData.questionText)) {
          if (question.isDemoQuestion && question.questionSequenceNumber) {
            invalidQuestions.add('Demo ' + question.questionSequenceNumber);
          } else if (question.questionGroupId == '' || question.questionGroupId == null) {
            invalidQuestions.add('Q' + question.questionSequenceNumber);
          } else {
            invalidQuestions.add(question.questionGroupId);
          }
        }
        for (const options of question.questionData.options) {
          if (isRteEmpty(options.optionText)) {
            if (question.isDemoQuestion && question.questionSequenceNumber) {
              invalidQuestions.add('Demo ' + question.questionSequenceNumber);
            } else if (question.questionGroupId == '' || question.questionGroupId == null) {
              invalidQuestions.add('Q' + question.questionSequenceNumber);
            } else {
              invalidQuestions.add(question.questionGroupId);
            }
          }
        }
      });
      invalidQuestions = Array.from(invalidQuestions);
      if (invalidQuestions.length == 0) {
        return {
          status: true,
        };
      }
      return {
        status: false,
        message: invalidQuestions.slice(0, -1).join(', ') + ' and ' + invalidQuestions.slice(-1),
      };
    },
    getClonesCountForTheGroup(groupQuestionArray) {
      let groupedByCloneNumber = groupBy(groupQuestionArray, 'cloneNumber');
      let groupCloneCount = Object.keys(groupedByCloneNumber).length;
      return groupCloneCount;
    },
  },
  watch: {
    currentStageData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.questionsGroupedBySequenceNumber = groupBy(
            val.filter((_g) => !_g.isDemoQuestion && !_g.questionGroupId),
            'questionSequenceNumber'
          );
          this.groupQuestionsGroupedBySequenceNumber = groupBy(
            val.filter((_g) => !_g.isDemoQuestion && _g.questionGroupId),
            'questionGroupId'
          );
          this.demoQuestionsBySequenceNumber = groupBy(
            val.filter((_g) => _g.isDemoQuestion),
            'questionSequenceNumber'
          );
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.questions-text {
  font-size: 20px;
  font-weight: bold;
}

.custom-btn {
  color: #707b89 !important;
  background-color: #f7fbfd !important;
}

.selected-year-btn {
  @extend .custom-btn;
  border-color: #b81e22 !important;
}

.custom-btn-2 {
  color: #707b89 !important;
  background-color: #ffffe0 !important;
}

.bread-crumb-row p {
  width: 5px;
  height: 9px;
  padding: 9px 10px;
  color: #979797;
}

.bread-crumb-row a {
  cursor: pointer;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #242424;
  opacity: 0.7;
}

.bread-crumb-row {
  display: flex;
  flex-direction: row;
}

.topic-view-container-one {
  .main-header {
    margin: 20px 15px 25px 15px;
    display: -webkit-box;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    height: 50px;
  }

  .main-header :nth-child(1) {
    width: 50%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  .main-header :nth-child(2) {
    width: 20%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  .main-header :nth-child(3) {
    width: 30%;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
}
</style>
