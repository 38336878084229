import Vue from 'vue';
const mutations = {
  setSubjectsDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setSubjectsDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setSubjectsDataOnApiResponse#UPDATE');
      state.subjectListApiResponse.status = payload.status;
      state.subjectListApiResponse.message = payload.message;
      state.subjectListApiResponse.data = payload.data || [];
      // Don't update data so that UI will be showing list
      // state.trainingListApiResponse.data    = payload.data;
    }

    console.log('MUTATION#setSubjectsDataOnApiResponse#END');
  },
  setStandardsDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setStandardsDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setStandardsDataOnApiResponse#UPDATE');
      state.standardsListApiResponse.status = payload.status;
      state.standardsListApiResponse.message = payload.message;
      state.standardsListApiResponse.data = payload.data || [];
      // Don't update data so that UI will be showing list
      // state.trainingListApiResponse.data    = payload.data;
    }

    console.log('MUTATION#setStandardsDataOnApiResponse#END');
  },

  setProjectListApiResponse: (state, payload) => {
    console.log('MUTATION#setStandardsDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setStandardsDataOnApiResponse#UPDATE');
      state.projectListApiResponse.status = payload.status;
      state.projectListApiResponse.message = payload.message;
      state.projectListApiResponse.data = payload.data || [];
      // Don't update data so that UI will be showing list
      // state.trainingListApiResponse.data    = payload.data;
    }

    console.log('MUTATION#setStandardsDataOnApiResponse#END');
  },
  _setAirtableBigSkillsOnApiResponse: (state, payload) => {
    console.log('MUTATION#setBigSkillsOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setBigSkillsOnApiResponse#UPDATE');
      state.airtableBigSkillsApiResponse.status = payload.status;
      state.airtableBigSkillsApiResponse.message = payload.message;
      // state.airtableBigSkillsApiResponse.data = payload.data || [];
      Vue.set(state.airtableBigSkillsApiResponse, 'data', payload.data || []);
    }

    console.log('MUTATION#setBigSkillsOnApiResponse#END');
  },
  get setAirtableBigSkillsOnApiResponse() {
    return this._setAirtableBigSkillsOnApiResponse;
  },
  set setAirtableBigSkillsOnApiResponse(value) {
    this._setAirtableBigSkillsOnApiResponse = value;
  },
  setAirtableSubSkillsOnApiResponse: (state, payload) => {
    console.log('MUTATION#setAirtableSubSkillsOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setAirtableSubSkillsOnApiResponse#UPDATE');
      state.airtableSubSkillsApiResponse.status = payload.status;
      state.airtableSubSkillsApiResponse.message = payload.message;
      // state.airtableSubSkillsApiResponse.data = payload.data || [];
      Vue.set(state.airtableSubSkillsApiResponse, 'data', payload.data || []);
    }

    console.log('MUTATION#setAirtableSubSkillsOnApiResponse#END');
  },
  airtableDataOnApiResponse: (state, payload) => {
    console.log('#AirtableApitFetchCalled');
    state.airtableDataOnApiResponse.status = payload.status;
    state.airtableDataOnApiResponse.message = payload.message;
  },
  setAirtableThemesOnApiResponse: (state, payload) => {
    console.log('MUTATION#setAirtableThemesOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setAirtableThemesOnApiResponse#UPDATE');
      state.airtableThemesApiResponse.status = payload.status;
      state.airtableThemesApiResponse.message = payload.message;
      Vue.set(state.airtableThemesApiResponse, 'data', payload.data || []);
    }

    console.log('MUTATION#setAirtableThemesOnApiResponse#END');
  },
  setAirtableMicrotopicsOnApiResponse: (state, payload) => {
    console.log('MUTATION#setAirtableMicrotopicsOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setAirtableMicrotopicsOnApiResponse#UPDATE');
      state.airtableMicrotopicsApiResponse.status = payload.status;
      state.airtableMicrotopicsApiResponse.message = payload.message;
      Vue.set(state.airtableMicrotopicsApiResponse, 'data', payload.data || []);
    }

    console.log('MUTATION#setAirtableMicrotopicsOnApiResponse#END');
  },

  setAirtableMicroskillsCategoiesOnApiResponse: (state, payload) => {
    console.log('MUTATION#setAirtableMicroskillsCategoiesOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setAirtableMicroskillsCategoiesOnApiResponse#UPDATE');
      state.airtableMicroskillsCategoiesOnApiResponse.status = payload.status;
      state.airtableMicroskillsCategoiesOnApiResponse.message = payload.message;
      Vue.set(state.airtableMicroskillsCategoiesOnApiResponse, 'data', payload.data || []);
    }

    console.log('MUTATION#setAirtableMicroskillsCategoiesOnApiResponse#END');
  },

  setRecentlyCreatedQuestionSetsOnApiResponse: (state, payload) => {
    console.log('MUTATION#setRecentlyCreatedQuestionSetsOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setRecentlyCreatedQuestionSetsOnApiResponse#UPDATE');
      state.recentlyCreatedQuestionSetsApiResponse.status = payload.status;
      state.recentlyCreatedQuestionSetsApiResponse.message = payload.message;
      state.recentlyCreatedQuestionSetsApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setRecentlyCreatedQuestionSetsOnApiResponse#END');
  },

  setQuestionSetsUsingFiltersOnApiResponse: (state, payload) => {
    console.log('MUTATION#setQuestionSetsUsingFiltersOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setQuestionSetsUsingFiltersOnApiResponse#UPDATE');
      state.questionSetsUsingFiltersApiResponse.status = payload.status;
      state.questionSetsUsingFiltersApiResponse.message = payload.message;
      state.questionSetsUsingFiltersApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setQuestionSetsUsingFiltersOnApiResponse#END');
  },

  setBlueprintsDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setBlueprintsDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setBlueprintsDataOnApiResponse#UPDATE');
      state.blueprintListApiResponse.status = payload.status;
      state.blueprintListApiResponse.message = payload.message;
      state.blueprintListApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setBlueprintsDataOnApiResponse#END');
  },

  setCreateTestDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setCreateTestDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setCreateTestDataOnApiResponse#UPDATE');
      state.createTestApiResponse.status = payload.status;
      state.createTestApiResponse.message = payload.message;
      state.createTestApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setCreateTestDataOnApiResponse#END');
  },

  setEditTestDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setEditTestDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setEditTestDataOnApiResponse#UPDATE');
      state.editTestApiResponse.status = payload.status;
      state.editTestApiResponse.message = payload.message;
      state.editTestApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setEditTestDataOnApiResponse#END');
  },

  setDeleteTestDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setDeleteTestDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setDeleteTestDataOnApiResponse#UPDATE');
      state.deleteTestApiResponse.status = payload.status;
      state.deleteTestApiResponse.message = payload.message;
      state.deleteTestApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setDeleteTestDataOnApiResponse#END');
  },

  setFetchTestDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setFetchTestDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setFetchTestDataOnApiResponse#UPDATE');
      state.getTestestApiResponse.status = payload.status;
      state.getTestestApiResponse.message = payload.message;
      state.getTestestApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#ssetFetchTestDataOnApiResponse#END');
  },

  setYearDataOnApiResponse: (state, payload) => {
    console.log('MUTATION#setYearDataOnApiResponse#START');
    if (payload) {
      console.log('MUTATION#setYearDataOnApiResponse#UPDATE');
      console.log('MUTATION#setYearDataOnApiResponse#rdctfvygbhunijctxrdctfvybntrxerdcftvgb');
      console.log(JSON.stringify(payload));
      state.yearListApiResponse.status = payload.status;
      state.yearListApiResponse.message = payload.message ?? '';
      state.yearListApiResponse.data = payload.data ?? [];
    }

    console.log('MUTATION#setYearDataOnApiResponse#END');
  },

  setProjectListWithStandardIdAndSubjectIdResponse: (state, payload) => {
    console.log('MUTATION#setProjectListWithStandardIdAndSubjectIdResponse#START');
    if (payload) {
      console.log('MUTATION#setProjectListWithStandardIdAndSubjectIdResponse#UPDATE');
      state.getProjectListApiResponse.status = payload.status;
      state.getProjectListApiResponse.message = payload.message;
      state.getProjectListApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setProjectListWithStandardIdAndSubjectIdResponse#END');
  },
};
export default mutations;
