import gql from 'graphql-tag';

export const lessonPlansDataStatus = (libraryIds) => {
  return gql`
      {
        lessonPlansDataStatus(libraryIds: [${libraryIds.join(',')}])
      }
  `;
};

export const uploadLpAssets = () => {
  return gql`
    mutation uploadLpAssets($file: Upload!, $libraryId: Int!) {
      uploadLpAssets(file: $file, libraryId: $libraryId) {
        coverImageUrl
        contentBookImageUrl
        studentWorkbookImageUrl
        studentWorkbookAnswerImageUrl
        homeworkQuestionImageUrl
        homeworkAnswerImageUrl
        actionVideoUrl
        resources
      }
    }
  `;
};

export const uploadSingleAsset = () => {
  return gql`
    mutation uploadSingleAsset($file: Upload!, $fileName: String!, $libraryId: Int!) {
      uploadSingleAsset(file: $file, fileName: $fileName, libraryId: $libraryId) {
        path
        signedUrl
      }
    }
  `;
};

export const removeSingleAssetFromProject = () => {
  return gql`
    mutation removeSingleAssetFromProject(
      $url: String!
      $projectId: ID!
      $lessonPlanLibraryId: ID!
    ) {
      removeSingleAssetFromProject(
        url: $url
        projectId: $projectId
        contentLibraryId: $lessonPlanLibraryId
      ) {
        path
        signedUrl
      }
    }
  `;
};

export const removeSingleAssetInCaForProject = () => {
  return gql`
    mutation removeSingleAssetInCaForProject(
      $assetId: String!
      $projectId: ID!
      $lessonPlanLibraryId: ID!
      $fieldIdentifier: String!
    ) {
      removeSingleAssetInCaForProject(
        assetId: $assetId
        projectId: $projectId
        contentLibraryId: $lessonPlanLibraryId
        fieldIdentifier: $fieldIdentifier
      ) {
        assetId
        signedUrl
      }
    }
  `;
};

export const generateLessonPlanAudioQuery = (libraryId) => {
  return gql`
    mutation {
      generateLessonPlanAudio(libraryId: "${libraryId}"){
        readerModeAudio {
          aim
          action
          application
          analysis
          assessment
        }
      }
    }
  `;
};

export const moveProjectLessonPlanToNextStageQuery = (projectId, libraryId) => {
  return gql`
    mutation {
      moveProjectLessonPlanToNextStage(
        projectId: "${projectId}"
        contentLibraryId: ${libraryId}
      ) {
        currentStage
      }
    }
  `;
};

export const moveProjectLessonPlanToPreviousStageQuery = (projectId, libraryId) => {
  return gql`
    mutation {
      moveFromProofReaderToAssetAuthorizingStage(
        projectId: "${projectId}"
        contentLibraryId: ${libraryId}
      )
    }
  `;
};

export const validateProjectLessonPlanStageDataQuery = (projectId, libraryId) => {
  return gql`
    mutation {
      validateProjectLessonPlanStageData(
        projectId: "${projectId}"
        contentLibraryId: ${libraryId}
      ) {
        status
        errors{
          path
          errorMessage
        }
      }
    }
  `;
};

export const projectLessonPlanCurrentStageDataAndlessonPlansWithMicroTopicsAndlessonPlanStaticTextsDataQuery = (
  projectId,
  libraryId,
  projectType = '',
  template = ''
) => {
  return gql`
    query {
      projectLessonPlanCurrentStageData(
        projectId: "${projectId}",
        contentLibraryId: "${libraryId}"
      ) {
        currentStage
        name
        library_id
        subjectName
        type
        subject {
          id
          name
        }
        commentsCount {
          aim
          action
          analysis
          application
          assessment
          summary
        }
        dailyPlan {
          name
          correspondingTimeData {
            status
            summary
          }
        }
        currentStageAssetsSignedUrl
        currentStageData {
          validationStatus
          createdAt
          updatedAt
          equationEditorJsonArray {
            latex
            json
          }
          data
        }
        diff
      }
      lessonPlanStaticTextsData(type: "${projectType}" template: "${template}")
      lessonPlansWithMicroTopics(ids: [${libraryId}]) {
        id
        name
        type
      }
    }
  `;
};

export const saveLessonPlanStageDataQuery = () => {
  return gql`
    mutation(
      $projectId: ID!
      $lessonPlanLibraryId: ID!
      $data: JSON!
      $equationEditorJsonArray: [EquationEditorJsonInput]!
    ) {
      saveProjectLessonPlanStageData(
        projectId: $projectId
        contentLibraryId: $lessonPlanLibraryId
        data: $data
        equationEditorJsonArray: $equationEditorJsonArray
      )
    }
  `;
};

export const downloadCurrentStageLpAssetsQuery = gql`
  query($projectId: ID!, $lessonPlanLibraryId: ID!, $yearId: Float) {
    downloadCurrentStageLpAssets(
      projectId: $projectId
      contentLibraryId: $lessonPlanLibraryId
      yearId: $yearId
    ) {
      status
    }
  }
`;

export const activateLessonPlanAndPublishToTeacherReadinessApp = () => {
  return gql`
    mutation($projectId: ID!, $libraryId: ID!) {
      activateLessonPlanAndPublishToTeacherReadinessApp(
        projectId: $projectId
        libraryId: $libraryId
      ) {
        triggerStatus
        projectId
      }
    }
  `;
};
