<template>
  <div :class="classNames">
    <div style="overflow: hidden; padding-bottom: 10px;">
      <div style="float: left;">
        <span style="font-weight: bold;">{{ headerText }}</span>
        <div
          v-if="commentHash.commentedBy && commentHash.commentedBy.name"
          style="font-size: 10px;"
        >
          {{
            loggedInUserEmail == commentHash.commentedBy.email
              ? AppTexts.YOU_TEXT
              : commentHash.commentedBy.name
          }}
        </div>
      </div>
      <div style="float: right; padding-right: 0;">
        <v-btn
          :loading="isResolveSaving"
          style="float: right;"
          @click="localCommentsResolveButtonClickHandler"
          color="success"
          :disabled="!canResolve"
        >
          {{
            this.commentHash.resolvedBy && this.commentHash.resolvedBy.name
              ? AppTexts.LP_SECTION_COMMENT_RESOLVED_TEXT
              : AppTexts.LP_SECTION_COMMENT_RESOLVE_TEXT
          }}
        </v-btn>
        <div v-if="commentHash.resolvedBy && commentHash.resolvedBy.name" style="font-size: 10px;">
          {{ AppTexts.LP_SECTION_COMMENT_RESOLVED_BY_TEXT }}
          {{
            loggedInUserEmail == commentHash.resolvedBy.email
              ? AppTexts.YOU_TEXT
              : commentHash.resolvedBy.name
          }}
        </div>
      </div>
    </div>
    <div style="background-color: #f6f6f6; padding: 10px;">
      <span v-html="getDisplayText(commentHash.text)" v-if="commentHash.text.length > 0"></span>
      <span v-else>{{ noCommentsText }}</span>
    </div>
  </div>
</template>
<script>
import AppTexts from '@/constants/AppTexts';

export default {
  name: 'CommentDetails',
  props: {
    headerText: {
      type: String,
      required: true
    },
    stageName: {
      type: String,
      required: true
    },
    commentHash: {
      type: Object,
      required: true
    },
    commentsResolveButtonClickHandler: {
      type: Function,
      required: true
    },
    canResolveComment: {
      type: Boolean,
      required: true
    },
    loggedInUserEmail: {
      type: String,
      required: true
    },
    resolveStatus: {
      type: String
    },
    noCommentsText: {
      type: String
    }
  },
  computed: {
    canResolve() {
      if (this.canResolveComment) {
        if (this.commentHash.commentedBy && this.commentHash.commentedBy.name) {
          return this.commentHash.resolvedBy && this.commentHash.resolvedBy.name ? false : true;
        }
      }

      return false;
    },
    classNames() {
      return this.commentHash.resolvedBy && this.commentHash.resolvedBy.name
        ? 'comment-details faded'
        : 'comment-details';
    }
  },
  methods: {
    formattedDate(number) {
      let date = new Date(parseInt(number));
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}H:${date.getMinutes()}M`;
    },
    localCommentsResolveButtonClickHandler() {
      this.isResolveSaving = true;
      this.commentsResolveButtonClickHandler(this.stageName);
    },
    getDisplayText(text) {
      return text.replace(/\n/g, '<br />');
    }
  },
  watch: {
    resolveStatus: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log('val', val);
        if (val !== 'in_progress') {
          this.isResolveSaving = false;
        } else {
          this.isResolveSaving = true;
        }
      }
    }
  },
  data() {
    return {
      AppTexts,
      isResolveSaving: false
    };
  }
};
</script>

<style scoped>
.faded.comment-details {
  opacity: 0.5;
}
.comment-details {
  padding: 15px 30px 15px 20px;
  width: 100%;
}
</style>
