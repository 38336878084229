<template>
  <section>
    <v-row class="mgn-Lxs">
      <v-col md="auto">{{ sectionPrefixText() }}</v-col>
      <v-col class="small-text-field" cols="3">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionIsChanged('description')"
          :fieldIdentifier="`application.${section}.description`"
          :placeholder="isClassWork ? AppTexts.WORKSHEET_NUMBER : AppTexts.QUESTION_NUMBER"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          v-model="data[section].description"
          :readonly="!isNotReadOnly"
          :counter="220"
        />
      </v-col>
      <v-col>{{ sectionSuffixText() }}</v-col>
    </v-row>
    <v-row class="mgn-Ts mgn-Lxs">
      <v-col>
        <p>{{ staticTexts2 }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="pad-Rm">
        <FileInput
          :image_url="isAssetUploadRequired ? assetId : ''"
          :image_url_brief="briefurlName"
          :previewUrl="isAssetUploadRequired ? previewUrls.url : ''"
          :previewUrlBrief="previewUrls.briefUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionIsChanged(imageKey.url)"
          :diffIdentifier="`application.${section}.${imageKey.url}`"
          :label="label"
          :loadingStatus="uploadingStatus[section] == FILE_UPLOADING_STATUS.IN_PROGRESS"
          :fileChangeHandler="(file) => importApplicationSectionImage(file, getImageKey)"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :isAssetApproved="
            isClassWork
              ? data[section]['assetApproved']
              : data[section]['question']['assetApproved']
          "
          :canAppproveAsset="canApproveAsset"
          :canFinalizeAsset="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              copySectionImage(previewUrl, briefUrl, isAssetApproved, getImageKey)
          "
        />
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionIsChanged(imageKey.url)"
          :fieldIdentifier="`application.${section}.${
            isClassWork ? 'brief' : 'question_image_brief'
          }`"
          v-if="showImageBrief"
          :placeholder="
            isClassWork ? AppTexts.CLASSWORK_IMAGE_BRIEF : AppTexts.HOMEWORK_QUESTION_FILE_BRIEF
          "
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          :showValidationError="true"
          v-model="sectionBrief"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col cols="6" class="pad-Lm" v-if="!isClassWork">
        <FileInput
          :image_url="
            isAssetUploadRequired ? getValueFromPath(data, 'homework.question_answer.assetId') : ''
          "
          :image_url_brief="getValueFromPath(data, 'homework.question_answer.brief.assetId')"
          :previewUrl="
            isAssetUploadRequired
              ? getValueFromPath(sectionSignedUrls, 'question_answer.assetSignedUrl')
              : ''
          "
          :previewUrlBrief="
            getValueFromPath(sectionSignedUrls, 'question_answer.brief.assetSignedUrl')
          "
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionIsChanged(imageKey.answerKey)"
          :diffIdentifier="'application.homework.' + imageKey.answerKey"
          :label="AppTexts.SELECT_HOMEWORK_ANSWER_FILE"
          :loadingStatus="uploadingStatus.homework_answer == FILE_UPLOADING_STATUS.IN_PROGRESS"
          :fileChangeHandler="
            (file) => importApplicationSectionImage(file, AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE)
          "
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :isRequired="false"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :isAssetApproved="getValueFromPath(data, 'homework.question_answer.assetApproved', false)"
          :canAppproveAsset="canApproveAsset"
          :canFinalizeAsset="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              copySectionImage(
                previewUrl,
                briefUrl,
                isAssetApproved,
                AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE
              )
          "
        />
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionIsChanged('question_answer.brief.text')"
          fieldIdentifier="application.homework.question_answer.brief.text"
          v-if="showImageBrief"
          :placeholder="AppTexts.HOMEWORK_ANSWER_FILE_BRIEF"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          v-model="data.homework.question_answer.brief.text"
          :showValidationError="true"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>

    <div v-if="!isClassWork" class="mgn-Bs">{{ greetingText }}</div>
  </section>
</template>
<script>
import TextInput from '@/components/inputs/TextInput';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import FileInput from '@/components/inputs/FileInput';
import AssetTypes from '@/constants/AssetTypes';
import { mapActions, mapState } from 'vuex';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { get } from 'lodash';
export default {
  name: 'LegacyClassWorkHomeWork',
  data() {
    return {
      BASIC_TOOLS,
      AssetTypes,
      AppTexts,
      FILE_UPLOADING_STATUS,
      Errors,
    };
  },
  components: {
    TextInput,
    RichTextEditor,
    FileInput,
  },
  methods: {
    ...mapActions(['importSingleAssetAction', 'deleteSingleAssetAction', 'copySingleAssetAction']),
    getValueFromPath(data, path, fallbackValue = '') {
      return get(data, path, fallbackValue);
    },
    importApplicationSectionImage(file, keyName) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: keyName,
        });
      } else {
        this.deleteSingleAssetAction({
          fieldIdentifier: `application.${this.section}.${this.imageKey.url}`,
          assetId: this.isAssetUploadRequired ? this.imageUrlName : this.briefurlName,
          assetType: keyName,
        });
      }
    },
    copySectionImage(previewUrl, briefUrl, isAssetApproved, keyname) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: keyname,
        isAssetApproved,
      });
    },
    sectionPrefixText() {
      return this.sectionText.split(this.sectionTextPlaceholder)[0];
    },
    sectionSuffixText() {
      let suffixTexts = this.sectionText.split(this.sectionTextPlaceholder);
      if (this.isClassWork) {
        return suffixTexts[1] + ` ${this.data[this.section].description} ` + suffixTexts[2];
      }

      return suffixTexts[1];
    },
    sectionParagraphText() {
      return this.sectionText.replace(/`${this.sectionTextPlaceholder}`/g, this.data.description);
    },
    getSectionIsChanged(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.sectionDiff[this.section][type].isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
    }),
    sectionBrief: {
      get: function () {
        if (this.isClassWork) {
          return this.data[this.section].brief.text;
        } else {
          return this.data[this.section].question.brief.text;
        }
      },
      set: function (value) {
        if (this.isClassWork) {
          this.data[this.section].brief.text = value;
        } else {
          this.data[this.section].question.brief.text = value;
        }
      },
    },
    imageUrlName: {
      get: function () {
        if (this.isClassWork) {
          return this.data.classwork.assetId;
        } else {
          return this.data.homework.question.assetId;
        }
      },

      set: function (value) {
        if (this.isClassWork) {
          this.data.classwork.assetId = value;
        } else {
          this.data.homework.question.assetId = value;
        }
      },
    },
    briefurlName: {
      get: function () {
        if (this.isClassWork) {
          return this.data.classwork.brief.assetId;
        } else {
          return this.data.homework.question.brief.assetId;
        }
      },

      set: function (value) {
        if (this.isClassWork) {
          this.data.classwork.brief.assetId = value;
        } else {
          this.data.homework.question.brief.assetId = value;
        }
      },
    },
    isClassWork() {
      return this.section == 'classwork';
    },
    previewUrls() {
      if (this.isClassWork) {
        return {
          url: this.sectionSignedUrls.assetSignedUrl,
          briefUrl: this.sectionSignedUrls.brief.assetSignedUrl,
        };
      } else {
        return {
          url: this.sectionSignedUrls.question.assetSignedUrl,
          briefUrl: this.sectionSignedUrls.question.brief.assetSignedUrl,
        };
      }
    },
    imageKey() {
      if (this.isClassWork) {
        return { url: this.isAssetUploadRequired ? 'assetId' : 'brief.assetId' };
      } else {
        if (this.isAssetUploadRequired) {
          return {
            url: 'question.assetId',
            answerKey: 'question_answer.assetId',
          };
        } else {
          return {
            url: 'question.brief.assetId',
            answerKey: 'question_answer.brief.assetId',
          };
        }
      }
    },
    getImageKey() {
      return this.isClassWork
        ? this.AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE
        : this.AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE;
    },
  },
  mounted() {
    if (typeof this.data.assetApproved != 'undefined') {
      this.data.assetApproved = true;
    }
    if (this.data.homework && typeof this.data.homework.assetApproved != 'undefined') {
      this.data.homework.assetApproved = true;
    }
    if (this.section == 'homework') {
      if (!this.data.homework.question_answer) {
        this.$set(this.data.homework, 'question_answer', {
          assetId: '',
          brief: {
            assetId: '',
            text: '',
          },
        });
        this.$set(this.sectionSignedUrls, 'question_answer', {
          assetSignedUrl: '',
          brief: {
            assetSignedUrl: '',
          },
        });
      }
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    applicationStaticText: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    sectionDiff: {
      type: Object,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    sectionSignedUrls: {
      type: Object,
      required: true,
    },
    canApproveAsset: {
      type: Boolean,
      required: true,
    },
    sectionText: {
      type: String,
      required: true,
    },
    sectionTextPlaceholder: {
      type: String,
      required: true,
    },
    staticTexts2: {
      type: String,
      required: true,
    },
    greetingText: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
