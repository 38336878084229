import {
  getMicroTopicsPaginationQuery,
  getFilterDataForMicroTopicListPageQuery,
  getMicroTopicDetailsQuery,
  createMicroTopicProjectQuery,
  updateMicroTopicProjectQuery,
  deleteMicroTopicProjectMutation,
} from '@/apiQueries/microTopic';
import { apolloClient } from '@/apollo';
import { logError } from '@/utils';
import Vue from 'vue';

export const MICRO_TOPIC_ACTION = {
  async getMicroTopicsPagination({ commit }, payload) {
    try {
      let { options } = payload;

      commit('SET_INITIAL_MICRO_TOPICS_API_STATUS', 'in_progress');
      const response = await apolloClient.query({
        query: getMicroTopicsPaginationQuery(),
        variables: {
          limit: options.limit || 10,
          offset: options.offset || 0,
          filters: options.filters || {},
        },
        fetchPolicy: 'no-cache',
      });

      if (response.data) {
        commit('SET_MICRO_TOPICS', response.data.microtopicProjects);
      }
      commit('SET_INITIAL_MICRO_TOPICS_API_STATUS', 'completed');
    } catch (er) {
      commit('SET_INITIAL_MICRO_TOPICS_API_STATUS', 'error');
      logError(er);
    }
  },
  async getFilterDataForMicroTopicListPage({ commit }) {
    try {
      const response = await apolloClient.query({
        query: getFilterDataForMicroTopicListPageQuery(),
      });

      if (response.data) {
        commit('UPDATE_MICRO_TOPIC_FILTER_OPTIONS', response.data);
      }
    } catch (er) {
      logError(er);
    }
  },
  async createMicroTopicProjecs({ commit }, { microTopic, projectType, assignees, isListPage }) {
    try {
      const response = await apolloClient.mutate({
        mutation: createMicroTopicProjectQuery(),
        variables: {
          input: {
            name: microTopic.name,
            subjectId: microTopic.subject.id,
            standardId: microTopic.standard.id,
            entityId: microTopic.id,
            entityType: 'MicroTopic',
            type: projectType,
            assignees: {
              author: assignees.author.id,
              reviewer1: assignees.reviewer1.id,
              reviewer2: assignees.reviewer2.id,
              academicSignOff: assignees.academicSignOff.id,
              editor: assignees.editor.id,
              visualizer: assignees.visualizer.id,
              assetUploader: assignees.assetUploader.id,
              assetAuthorizer: assignees.assetAuthorizer.id,
              proofReader: assignees.proofReader.id,
            },
          },
        },
      });

      let mutationToCall = isListPage ? 'ADD_PROJECT_TO_MT_LIST' : 'ADD_PROJECT_TO_MT_VIEW';
      if (response.data) {
        commit(mutationToCall, {
          microTopicId: microTopic.id,
          projectData: response.data.createProject,
        });
      }
    } catch (er) {
      logError(er);
    }
  },
  async updateMicroTopicProjectAssignee({ commit }, { microTopic, projectId, assignees }) {
    try {
      const response = await apolloClient.mutate({
        mutation: updateMicroTopicProjectQuery(),
        variables: {
          input: {
            id: projectId,
            name: microTopic.name,
            subjectId: microTopic.subject.id,
            standardId: microTopic.standard.id,
            entityId: microTopic.id,
            entityType: 'MicroTopic',
            assignees: {
              author: assignees.author.id,
              reviewer1: assignees.reviewer1.id,
              reviewer2: assignees.reviewer2.id,
              academicSignOff: assignees.academicSignOff.id,
              editor: assignees.editor.id,
              visualizer: assignees.visualizer.id,
              assetUploader: assignees.assetUploader.id,
              assetAuthorizer: assignees.assetAuthorizer.id,
              proofReader: assignees.proofReader.id,
            },
          },
        },
      });

      if (response.data) {
        commit('UPDATE_CURRENT_MICRO_TOPIC_PROJECT_ASSIGNEE_STATUS', {
          projectId: projectId,
          assigneeDetails: response.data.updateProject.assignees || {},
        });
      }
    } catch (er) {
      logError(er);
    }
  },
  async getMicroTopicDetails({ commit }, { microTopicId }) {
    try {
      commit('SET_CURRENT_MICRO_TOPIC_DETAILS', {
        microTopic: {},
      });
      const response = await apolloClient.query({
        query: getMicroTopicDetailsQuery(microTopicId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        commit('SET_CURRENT_MICRO_TOPIC_DETAILS', {
          microTopic: response.data.microtopicProject,
        });
      }
    } catch (error) {
      commit('SET_INITIAL_MICRO_TOPICS_API_STATUS', 'error');
      logError(error);
    }
  },
  async deleteMicroTopicProject({ commit }, projectId) {
    try {
      commit('SET_INITIAL_MICRO_TOPICS_API_STATUS', 'in_progress');
      const response = await apolloClient.mutate({
        mutation: deleteMicroTopicProjectMutation(),
        variables: {
          id: projectId,
        },
      });
      if (response && response.data) {
        if (response.data.deleteProject.status) {
          commit('DELETE_PROJECT_FROM_MICROTOPIC', projectId);
          Vue.notify({
            title: 'Success!',
            text: 'Project has been deleted successfully!',
            type: 'success',
          });
        }
      }
      commit('SET_INITIAL_MICRO_TOPICS_API_STATUS', 'completed');
    } catch (error) {
      logError(error);
    }
  },
  setMicroTopicSummaryFilter({ commit }, payload) {
    commit('SET_MICROTOPIC_SUMMARY_DATA', payload);
  },
  getMicroTopicSummaryFilter({ commit }) {
    commit('GET_MICROTOPIC_SUMMARY_FILTER_DATA');
  },
  removeMicroTopicSummaryFilterData({ commit }) {
    commit('REMOVE_MICROTOPIC_SUMMARY_FILTER_DATA');
  },
};
