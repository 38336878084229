<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="showDeletePopup" persistent max-width="390">
        <v-card>
          <v-card-title class="headline">
            Are you sure about deleting this project?
          </v-card-title>
          <v-card-text>
            This action will delete the project and it cannot be retrieved back. Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="showDeletePopup = false">
              CANCEL
            </v-btn>
            <v-btn color="green darken-1" text @click="deleteItem">
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-col></v-col>
      <v-col cols="2">
        <ProjectForm
          :key="`projectFormPopUp${revision}`"
          :dialog="dialog"
          :projectData="projectData"
          :onClickCancel="onClickCancel"
          :getBlocksForSelectedProductBoardStandardSubject="
            getBlocksForSelectedProductBoardStandardSubject
          "
          :data="{
            boards,
            standards,
            products,
            subjects,
            lpEntryUsers,
          }"
          :blocks="blocks"
          :createProject="onClickCreateProject"
          :onClickAddProject="onClickAddProject"
          :canCreateProject="canCreateProject"
          @clearModel="closeDialog"
          :isEdit="isEdit"
          @resetForm="() => refereshProjectForm()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="auth.canReadProject == null">
          <Loader
            :loadingText="AppTexts.FETCHING_DATA_TEXT"
            :loadingStatus="auth.canReadProject == null"
          />
        </div>
        <div v-else-if="auth.canReadProject">
          <XTable
            :items="projectListDetails()"
            :headers="headers"
            :handlers="handlers"
            :subHandlers="subHandlers"
            :auth="auth"
            :data="{ boards, standards, products, subjects }"
            :filters="filters"
            :searchHandler="searchHandler"
            :paginationHandler="paginationHandler"
            :totalCount="totalCount"
            :loading="isProjectsListFetchingInProgress"
            :loadingText="projectsListFetchingStatusText"
            :hasGlobalSearch="true"
            :itemsPerPageOptions="[5, 15, 30, 50, -1]"
            :isAdmin="isAdmin"
          />
        </div>
        <div class="text-center" v-else>You are not authorized to view projects list</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectForm from '@/components/Admin/ProjectForm';
import XTable from '@/components/Admin/XTable';
import Loader from '@/components/common/Loader';
import { mapActions, mapState } from 'vuex';
import { PROJECT_LIST_FILTERS, PROJECT_WORKFLOW_STATUSES_HASH } from '@/constants/Project';
import AppTexts from '@/constants/AppTexts';
import { BLOCK_CHILDREN_TYPES_HASH } from '@/constants/Block';

export default {
  name: 'Project',
  data() {
    return {
      AppTexts,
      revision: 0,
      isEdit: false,
      dialog: false,
      showDeletePopup: false,
      blocks: [],
      projectData: {},
      projectDataToDeleteHash: {},
      headers: [
        {
          value: 'index',
          sortable: true,
          text: 'S.No',
        },
        {
          value: 'name',
          sortable: true,
          text: 'Name',
        },
        {
          value: 'blockId',
          sortable: true,
          text: 'Block ID',
        },
        {
          value: 'productName',
          sortable: true,
          text: 'Product',
        },
        {
          value: 'boardName',
          sortable: true,
          text: 'Board',
        },
        {
          value: 'standardName',
          sortable: true,
          text: 'Grade',
        },
        {
          value: 'subjectName',
          sortable: true,
          text: 'Subject',
        },
        {
          value: 'blockName',
          sortable: true,
          text: 'Block',
        },
        {
          value: 'workflowStatus',
          sortable: true,
          text: 'Status',
        },
        {
          value: 'updatedAt',
          sortable: true,
          text: 'Last Updated Date',
        },
        {
          value: 'actions',
          sortable: false,
          text: 'Actions',
        },
      ],
      handlers: [
        {
          icon: 'mdi-eye-outline',
          name: 'View',
          function: this.viewItem,
          authPermissionKey: 'canReadProject',
        },
      ],
      subHandlers: [
        {
          icon: 'mdi-pencil',
          name: 'Edit',
          function: this.editItem,
          canShow: this.isProjectEditable,
          authPermissionKey: 'canMutateProject',
        },
        {
          icon: 'mdi-delete',
          name: 'Delete',
          canShow: this.ProjectDeletable,
          function: this.deleteConfirmation,
          authPermissionKey: 'canDeleteProject',
        },
      ],
      filters: [
        {
          type: 'select',
          items: Object.values(PROJECT_LIST_FILTERS),
          value: PROJECT_LIST_FILTERS.PERSONALIZED,
          handler: this.updateProjectListFilterType,
          cols: '3',
        },
      ],
      projectListFilterType: PROJECT_LIST_FILTERS.PERSONALIZED,
      projectItems: [],
      lpEntryUsers: [],
      searchKey: '',
      options: {},
    };
  },
  components: {
    ProjectForm,
    Loader,
    XTable,
  },
  created() {
    this.getProductsBoardsStandardsSubjects();
    this.getUsers();
    this.resetProjectForm();
    this.getCurrentUserAuthPermissions();
    this.$forceUpdate();
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.loggedInUser,
      projects: (state) => state.projects,
      initialProjectsListApiStatus: (state) => state.initialProjectsListApiStatus,
      boards: (state) => state.boards,
      standards: (state) => state.standards,
      products: (state) => state.products,
      subjects: (state) => state.subjects,
      globalBlocks: (state) => state.blocks,
      users: (state) => state.user.users,
      auth: (state) => state.auth.project,
      shouldListMyProjectsAlone: function () {
        return this.projectListFilterType == PROJECT_LIST_FILTERS.PERSONALIZED;
      },
      totalCount: (state) => state.totalCount,
      isAdmin: (state) => state.auth.isAdmin,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
    }),
    projectsListFetchingStatusText() {
      let text;
      if (this.initialProjectsListApiStatus == 'completed') {
        text = 'No data available.'; // In completed state, send loading text as no data available. It will be shown only when list is empty.
      } else if (this.initialProjectsListApiStatus == 'error') {
        text = 'Something went wrong. Please try again after some time.';
      } else {
        text = 'Fetching projects...';
      }

      return text;
    },
    canCreateProject() {
      return this.auth.canMutateProject || this.isAcademicAdmin;
    },
    isProjectsListFetchingInProgress() {
      return this.initialProjectsListApiStatus == 'in_progress';
    },
  },
  watch: {
    projects: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!this.isEdit) {
          this.projectItems = val;
        }
      },
    },
    globalBlocks: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.blocks = val;
        }
      },
    },
    users: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.lpEntryUsers = newVal;
      },
    },
    isAcademicAdmin: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.projectListFilterType = PROJECT_LIST_FILTERS.DEFAULT;
        } else {
          this.projectListFilterType = PROJECT_LIST_FILTERS.PERSONALIZED;
        }

        this.filters[0].default = this.projectListFilterType;
      },
    },
    isAdmin: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.projectListFilterType = PROJECT_LIST_FILTERS.DEFAULT;
        } else {
          this.projectListFilterType = PROJECT_LIST_FILTERS.PERSONALIZED;
        }

        this.filters[0].value = this.projectListFilterType;
      },
    },
  },
  methods: {
    ...mapActions([
      'getProductsBoardsStandardsSubjects',
      'createProject',
      'updateProject',
      'getProjectsList',
      'getProjectsListV2',
      'getBlocksForSelectedProductBoardStandardSubject',
      'getUsers',
      'resetProjectDetails',
      'getCurrentUserAuthPermissions',
      'deleteProject',
    ]),
    onClickCreateProject() {
      if (this.isEdit) {
        this.updateProject(Object.assign({}, this.projectData));
      } else {
        this.createProject(Object.assign({}, this.projectData));

        // Update the totla records count.
        this.$store.state.totalCount = this.$store.state.totalCount + 1;
      }
      this.closeDialog();
    },
    onClickCancel() {
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
      this.isEdit = false;
    },
    editItem(data) {
      let _projectdata = this.projects.find((project) => project.id == data.id);
      let selectedBlockChildrens =
        _projectdata.block.childrenType == BLOCK_CHILDREN_TYPES_HASH.DAILY_PLAN
          ? _projectdata.dailyPlans
          : _projectdata.contents;
      _projectdata.childrenEntityIds = selectedBlockChildrens.map((_child) => +_child.library_id);
      this.projectData = JSON.parse(JSON.stringify(_projectdata));
      this.dialog = true;
      this.isEdit = true;
    },
    ProjectDeletable(data) {
      return (
        ![
          PROJECT_WORKFLOW_STATUSES_HASH.COMPLETED,
          PROJECT_WORKFLOW_STATUSES_HASH.PUBLISHED,
          PROJECT_WORKFLOW_STATUSES_HASH.PUBLISH_IN_PROGRESS,
        ].includes(data.workflowStatus.toLowerCase()) && this.isAdmin
      );
    },
    deleteItem() {
      this.showDeletePopup = false;
      this.deleteProject(this.projectDataToDeleteHash);
    },
    deleteConfirmation(data) {
      this.projectDataToDeleteHash = JSON.parse(JSON.stringify(data));
      this.showDeletePopup = true;
    },
    isProjectEditable(data) {
      return (
        [
          PROJECT_WORKFLOW_STATUSES_HASH.COMPLETED,
          PROJECT_WORKFLOW_STATUSES_HASH.PUBLISHED,
        ].includes(data.workflowStatus.toLowerCase()) == false
      );
    },
    viewItem(data) {
      this.resetProjectDetails();
      this.$router.push({
        path: '/projects/' + data.id,
      });
    },
    updateProjectListFilterType(filter) {
      this.projectListFilterType = filter;

      this.getProjectsListV2({
        page: this.options.page - 1,
        limit: this.options.itemsPerPage,
        isMyProjects: this.shouldListMyProjectsAlone,
        searchKey: this.searchKey.toString(),
      });
    },
    searchHandler: function (searchKey) {
      console.log(`searchKey = ${searchKey}`);
      this.searchKey = searchKey;

      this.getProjectsListV2({
        page: this.options.page - 1,
        limit: this.options.itemsPerPage,
        isMyProjects: this.shouldListMyProjectsAlone,
        searchKey: searchKey,
      });
    },
    paginationHandler: function (options) {
      console.log(`--------------- paginationHandler ---------------`);

      console.log(`options @ paginationHandler`);
      console.log(options);
      this.options = options;

      this.getProjectsListV2({
        page: this.options.page - 1,
        limit: this.options.itemsPerPage,
        isMyProjects: this.shouldListMyProjectsAlone,
        searchKey: this.searchKey,
      });
    },
    getProjectLastUpdateAt(projectUpdatedAt) {
      const updatedAt = new Date(parseInt(projectUpdatedAt));
      if(isNaN(updatedAt)){
        return '-';
      }
      const dateOptions = {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      };
      const dateValue = updatedAt.toLocaleString('en-US', dateOptions);
      return dateValue.toString();
    },
    projectListDetails() {
      return this.projects.map((project) => {
        return {
          id: project.id,
          name: project.name,
          blockId: project.block ? project.block.bId || '-' : '-',
          workflowStatus: project.workflowStatus || '-',
          boardName: project.board.name || '-',
          subjectName: project.subject.name || '-',
          standardName: project.standard.name || '-',
          blockName: project.block ? project.block.title || '-' : '-',
          productName: project.product.name || '-',
          updatedAt: this.getProjectLastUpdateAt(project.updatedAt),
        };
      });
    },
    onClickAddProject() {
      this.isEdit = false;
      this.resetProjectForm();
    },
    refereshProjectForm() {
      this.revision += 1;
    },
    resetProjectForm() {
      this.projectData = {
        workflowStatus: PROJECT_WORKFLOW_STATUSES_HASH.NOT_STARTED,
        name: '',
        product: {
          id: '',
        },
        board: {
          id: '',
        },
        standard: {
          id: '',
        },
        subject: {
          id: '',
        },
        block: {
          id: '',
        },
        childrenEntityIds: [],
        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
        projectType: 'LessonPlan',
      };
    },
  },
};
</script>
