<template>
  <v-row style="padding: 0px;" dense>
    <v-col :class="`${showCloseButton ? 'fix-height' : ''} remove-padding`">
      <div
        style="
          overflow: hidden;
          background-color: #f6f6f6;
          padding: 10px;
          height: 56px;
          text-align: right;
        "
        v-if="showCloseButton"
      >
        <v-btn color="primary" text @click="closeDialog">Close<v-icon>mdi-close</v-icon></v-btn>
      </div>

      <div
        style="background: #eee; padding: 10px 40px 40px 40px; margin-left: 4px; margin-right: 4px;"
      >
        <!-- First Row -->
        <v-row dense>
          <v-col>
            <SubSection :title="subSectionTitle" />
          </v-col>
          <v-spacer />
          <v-col v-if="!options.hideComments">
            <v-btn :disabled="!data._id" class="comment-btn" @click="toggleComments"
              ><span style="color: #fff; font-size: 18px;">COMMENT</span></v-btn
            >
          </v-col>
        </v-row>
        <!-- Second Row -->
        <v-row dense>
          <v-col cols="5">
            <QuestionTypeSelect
              @selectedQuestionTypeFromChild="changeInQuestionTypeHelper"
              :disabled="!newlyCreatedQuestion || options.disableQuestionTypeDropdown"
              :defaultType="defaultQuestionType"
              :lpType="lpType"
              :allowedQuestionTypes="allowedQuestionTypes"
            />
          </v-col>
          <div style="width: 10px;"></div>
          <v-checkbox
            @change="changeInstructionAddedHandler"
            v-model="data.isInstructionAdded"
            label="Instruction is added in the question text"
            v-if="defaultQuestionType == 'fib'"
          ></v-checkbox>
        </v-row>
        <div v-if="data.metadata" style="margin-bottom: 40px;">
          <SubSection title="META DATA" />
          <v-row v-if="lpType == 'learnometerQuestion'">
            <v-col
              cols="3"
              v-for="(value, key) in getMetaData()"
              :key="`dynamicQuestionMeta-${key}`"
            >
              <v-select
                disabled
                dense
                hide-details
                height="46"
                :label="options.metaDataKeyToLabelMap[key] || key"
                :items="convertToObjectArray(dynamicMetaDataKeySourceDataMap[key])"
                item-text="value"
                item-value="key"
                :value="value"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              v-for="(value, key) in getMicroskillAssessed()"
              :key="`dynamicQuestionMeta-${key}`"
            >
              <v-select
                disabled
                dense
                hide-details
                height="46"
                :label="options.metaDataKeyToLabelMap[key] || key"
                :items="convertToObjectArray(dynamicMetaDataKeySourceDataMap[key])"
                item-text="value"
                item-value="key"
                :value="value"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col
              cols="3"
              v-for="(value, key) in data.metadata"
              :key="`dynamicQuestionMeta-${key}`"
            >
              <v-select
                disabled
                dense
                hide-details
                height="46"
                :label="options.metaDataKeyToLabelMap[key] || key"
                :items="convertToObjectArray(dynamicMetaDataKeySourceDataMap[key])"
                item-text="value"
                item-value="key"
                v-model="data.metadata[key]"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
        <div :key="questionBlockRenderer">
          <RichTextEditor
            :key="questionTextKeyId"
            :readonly="false"
            :canShowDiffIcon="!options.hideDiff"
            v-model="data.questionData.questionText"
            placeholder="Enter question Text"
            :fieldIdentifier="`['${data._id}'].questionData.questionText`"
            projectType="question"
            :showValidationError="!turnOffQuestionTextValidation"
            @input="onChangeQuestionText"
            :includeOnlyToolBarActions="[...getToolBarActions(), 'table']"
            :isAssetUploadRequired="options.isAssetUploadRequired"
            :forceAssetFinalized="forceAssetFinalized"
            :acceptedFileTypes="[...getRTEAcceptedFileType, 'video/mp4', 'audio/mpeg']"
          />
        </div>
        <v-row>
          <v-col
            v-for="(image, index) in imagesInQuestionText"
            :key="`imagesInQuestionText${questionBlockRenderer}${revision}${index}`"
            :cols="3"
          >
            <FileInput
              :canShowDiffIcon="false"
              :diffIdentifier="''"
              :fileAcceptType="'Image/*'"
              :label="'Image'"
              :loadingStatus="false"
              :isRequired="options.isAssetUploadRequired"
              :isAssetUploadRequired="options.isAssetUploadRequired"
              :fileChangeHandler="(file) => fileChangeHandler(file, index)"
              :previewUrl="image.attrs.src"
              :previewUrlBrief="image.attrs.briefSrc"
              :image_url="image.attrs.assetId"
              :image_url_brief="image.attrs.briefAssetId"
              :isNotReadOnly="options.isNotReadOnly"
              :canAppproveAsset="canAppproveAsset"
              :ApproveAssetAndEmitResult="true"
              :canFinalizeAsset="true"
              :useAssetVault="true"
              @addBriefToAsset="
                (previewUrl, briefUrl, approveAsset) =>
                  addToAsset(previewUrl, briefUrl, approveAsset, index)
              "
              :isAssetApproved="image.attrs.assetApproved"
              @assetApprovedByUser="(val) => approveAssets(val, index)"
            /><br />
            <RichTextEditor
              :readonly="options.isAssetUploadRequired"
              :canShowDiffIcon="false"
              v-model="image.attrs.brief"
              placeholder="Enter Image Brief"
              projectType="question"
              :showValidationError="false"
              :includeOnlyToolBarActions="[...getToolBarActionsForQuestionBriefs(), 'table']"
              @input="addBriefTextAtPosition(image.attrs.brief, index)"
              :removeAllPluginExceptFullScreen="true"
              :counter="3000"
              :allowCharacterCount="true"
            />
          </v-col>
        </v-row>
        <div :class="wrapAnswerBlockInContainer ? 'answerContainer mt-6' : ''">
          <McqQuestion
            :data="data"
            v-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
            :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
          />
          <MsqQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
            :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
          />
          <TfQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
          />

          <MtfQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
          />
          <FibQuestion
            :key="`${optionTextKeyId}-fib-content`"
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :deleteBlankHandler="blankDeleteHandler"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
          />
          <AtfQuestion
            :data="data"
            v-else-if="selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF"
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getRTEAcceptedFileType="getRTEAcceptedFileType"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
          />
          <SaLaQuestion
            :data="data"
            v-else-if="
              selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
              selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA
            "
            :wrapAnswerBlockInContainer="wrapAnswerBlockInContainer"
            :getToolBarActions="getToolBarActions()"
            :options="options"
            :canAppproveAsset="canAppproveAsset"
            :forceAssetFinalized="forceAssetFinalized"
            :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs()"
          />
        </div>
      </div>
      <Padder :horizontal="50" :vertical="20" v-if="showMetaFields">
        <XSDivider />
        <v-row>
          <Padder :left="7">
            <SubSection title="Meta Data" />
          </Padder>
        </v-row>
        <v-row v-if="questionMetaInfo">
          <v-col cols="4">
            <v-select
              placeholder="Question Purpose"
              item-text="label"
              item-value="value"
              v-model="questionPurposeData"
              :items="questionPurposeItems"
              dense
              :disabled="!newlyCreatedQuestion"
              outlined
              :readonly="
                (lpType == 'questions' &&
                (selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
                selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA))
              "
              @change="callOnQuestionTypeOrPurposeUpdate"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              placeholder="Difficulty"
              v-model="data.difficulty"
              :items="questionMetaInfo.difficultyLevels"
              dense
              outlined
            />
          </v-col>
          <v-col cols="4">
            <v-select
              placeholder="Skill"
              :value="selectedSkillValue"
              item-text="name"
              item-value="id"
              @change="
                (val) => {
                  data.skill = val;
                  data.subSkill = null;
                }
              "
              :items="questionMetaInfo.skills"
              v-if="questionMetaInfo.skills"
              dense
              outlined
            />
          </v-col>
          <v-col
            cols="4"
            v-if="questionMetaInfo.subSkills && lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS"
          >
            <v-select
              placeholder="Sub Skill"
              :value="selectedSubSkillValue"
              item-text="name"
              item-value="id"
              @change="
                (val) => {
                  data.subSkill = val;
                }
              "
              :items="questionMetaInfo.subSkills[data.skill]"
              dense
              outlined
            />
          </v-col>
          <v-col cols="4">
            <v-select
              placeholder="Academic Year"
              v-model="data.academicYear"
              :items="questionMetaInfo.academicYears"
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              placeholder="Enter time"
              v-model="data.time"
              :rules="[
                (v) => !!v || 'This is required',
                (v) => v >= 1 || 'Minimum is 1 min',
                (v) => v <= 20 || 'Maximum is 20 mins ',
              ]"
              outlined
              @keydown="
                (event) => {
                  if (event.key === '.') {
                    event.preventDefault();
                  }
                }
              "
              dense
              suffix="Minutes"
            />
          </v-col>
          <v-col cols="4" v-if="lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS">
            <v-select
              placeholder="Select Lesson Plan"
              v-model="data.lessonPlanLibraryId"
              item-text="name"
              item-value="library_id"
              :items="lessonPlans"
              dense
              outlined
            />
          </v-col>
          <v-col cols="4" v-if="lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS && isLanguageSubject">
            <v-select
              placeholder="Select Language Skill"
              v-model="data.languageSkill"
              item-text="label"
              item-value="value"
              :items="languageSkills"
              dense
              outlined
              :rules="[(v) => !!v || 'This is required']"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-if="wrapAnswerBlockInContainer"
              type="number"
              placeholder="Enter score percentage"
              v-model="data.scorePercentage"
              :rules="[
                (v) => !!v || 'This is required',
                (v) => v >= 1 || 'Minimum is 1',
                (v) => v <= 100 || 'Maximum is 100',
              ]"
              outlined
              @keydown="
                (event) => {
                  if (event.key === '.') {
                    event.preventDefault();
                  }
                }
              "
              dense
              suffix="%"
            />
          </v-col>
        </v-row>
      </Padder>
      <Padder :horizontal="50" :vertical="20" v-if="canEnableWritingLinesAndDrawingBox">
        <XSDivider />
        <v-row>
          <Padder :left="7">
            <SubSection title="Additional Info" />
          </Padder>
        </v-row>
        <section class="d-flex align-start mb-6 justify-space-between">
          <v-col cols="3">
            <v-text-field
              placeholder="Writing Lines"
              label="Writing Lines"
              type="number"
              outlined
              dense
              v-model="data.writingLines"
              :max="WRITING_LINES_HASH.max"
              :min="WRITING_LINES_HASH.min"
              :rules="[(v) => v <= 20 || 'Maximum is 20', (v) => (v) => 0 || 'Minimum is 0']"
              @keydown="
                (event) => {
                  if (event.key === '.' || event.key === '-') {
                    event.preventDefault();
                  }
                }
              "
              @change="(event) => callOnWritingLinesOrDrawingBoxUpdate('writingLines', event)"
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
          <v-col cols="6">
            <v-select
              v-model="data.drawingBox"
              :items="DRAWING_BOX_OPTIONS"
              placeholder="Drawing Box"
              item-text="label"
              item-value="value"
              outlined
              dense
              hide-details
              label="Drawing Box"
              @change="(event) => callOnWritingLinesOrDrawingBoxUpdate('drawingBox', event)"
            />
          </v-col>
        </section>
      </Padder>
    </v-col>
    <v-col
      cols="4"
      v-if="questionCommentsToggle"
      class="fix-height-diff-comments"
      style="box-shadow: -2px 0px 2px 1px #ccc;"
    >
      <div v-if="questionCommentsLoader" style="overflow: hidden;" class="text-center">
        <p class="pt-4">
          <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
        </p>
        <p>Fetching Comments....</p>
      </div>
      <div v-else>
        <div style="background: #eee; height: 56px;">
          <v-col style="text-align: right;">
            <v-btn color="primary" class="mt-1 mr-2" text @click="toggleComments"
              >Close<v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </div>
        <Comments
          :comments="commentsHash[data._id] ? commentsHash[data._id].comments : {}"
          :currentStage="questionsCurrentStage"
          :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
          :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
          :canAddComment="auth.canAddComment"
          :canResolveComment="auth.canResolveComment"
          :loggedInUserEmail="loggedInUserEmail ? loggedInUserEmail : ''"
          :saveStatusesHash="commentsHash[data._id] ? commentsHash[data._id].saveStatusesHash : {}"
          :resolveStatusesHash="
            commentsHash[data._id] ? commentsHash[data._id].resolveStatusesHash : {}
          "
          :commentSectionIdentifier="data._id"
        />
      </div>
    </v-col>
    <v-col
      cols="5"
      v-if="setQuestionDiffPanel"
      class="fix-height-diff-comments"
      style="box-shadow: -2px 0px 2px 1px #ccc;"
    >
      <div>
        <Diff
          :fieldDiff="fieldDiff"
          :closeHandler="diffCloseHandler"
          :currentStage="questionsCurrentStage"
          :title="computeTitle"
          :isForContentEntry="true"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import Comments from '@/components/comments/Comments';
import Diff from '@/components/ContentDiff/Diff';
import SubSection from '@/components/inputs/SubSection.vue';
import QuestionTypeSelect from '@/components/contentEntry/questionComponents/dialogComponents/QuestionTypeSelect.vue';
import FileInput from '@/components/inputs/FileInput';
import { LP_TYPES_HASH } from '@/constants/common';
import {
  WRITING_LINES_HASH,
  DRAWING_BOX_OPTIONS,
  DRAWING_BOX_OPTION_NONE,
} from '@/constants/question';

import {
  getNewlyInsertedBlankIndex,
  deleteBlank,
  getBlankTypesFromContent,
  getAllBlankTypesFromContent,
  getImageUrlFromRte,
  deleteImageInRTE,
  insertImageAssetAtPosition,
  approveAssetAtPosition,
  addBriefToAssetInPosition,
} from '@xseededucation/rte-helper-functions';
import { v4 as uuidv4 } from 'uuid';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import { startCase, camelCase } from 'lodash';

//Question Components START
import McqQuestion from '@/components/contentEntry/questionComponents/dialogComponents/McqQuestion.vue';
import MsqQuestion from '@/components/contentEntry/questionComponents/dialogComponents/MsqQuestion.vue';
import TfQuestion from '@/components/contentEntry/questionComponents/dialogComponents/TfQuestion.vue';
import MtfQuestion from '@/components/contentEntry/questionComponents/dialogComponents/MtfQuestion.vue';
import FibQuestion from '@/components/contentEntry/questionComponents/dialogComponents/FibQuestion.vue';
import AtfQuestion from '@/components/contentEntry/questionComponents/dialogComponents/AtfQuestion.vue';
import SaLaQuestion from '@/components/contentEntry/questionComponents/dialogComponents/SaLaQuestion.vue';
//END

export default {
  data() {
    return {
      questionsOprogramProject,
      DEFAULT_QUESTION_DATA,
      commentSectionIdentifier: '',
      selectedQuestionType: '',
      newlyInsertedBlankIndices: [],
      questionTextKeyId: uuidv4(),
      optionTextKeyId: uuidv4(),
      defaultQuestionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ,
      questionBlockRenderer: 0,
      imagesInQuestionText: [],
      revision: 0,
      LP_TYPES_HASH,
      WRITING_LINES_HASH,
      DRAWING_BOX_OPTIONS,
      DRAWING_BOX_OPTION_NONE,
      isInstructionAdded: false,
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.questionType) {
          this.defaultQuestionType = val.questionType;
          this.isInstructionAdded = val.isInstructionAdded;
        }
      },
    },
  },
  components: {
    QuestionTypeSelect,
    RichTextEditor,
    Comments,
    Diff,
    SubSection,
    McqQuestion,
    MsqQuestion,
    FibQuestion,
    TfQuestion,
    MtfQuestion,
    AtfQuestion,
    SaLaQuestion,
    FileInput,
  },
  computed: {
    ...mapState({
      isEditDialog: (state) => state.question.isEditDialog,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
      showLoader: (state) => state.questions.questionDialogLoader,
      questionCommentsToggle: (state) => state.questions.questionCommentsToggle,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      questionCommentsLoader: (state) => state.rightSideContent.questionCommentsLoader,
      questionsCurrentStage: (state) => state.questions.currentStage,
      contentEntryProjectFormAuth: (state) => state.auth.contentEntryProjectForm,
      lessonPlanFormAuth: (state) => state.auth.lessonPlan,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      currentDiffIdentifier: (state) => state.rightSideContent.currentDiffIdentifier,
      fieldDiff: (state) => state.rightSideContent.fieldDiff,
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
    }),
    questionPurposeItems() {
      return this.questionMetaInfo.questionPurposes[this.lpType].map((purpose) => {
        return { label: startCase(camelCase(purpose)), value: purpose };
      });
    },
    languageSkills() {
      return this.questionMetaInfo.languageSkills.map((skill) => {
        return { label: skill, value: camelCase(skill) };
      });
    },
    questionPurposeData: {
      get: function () {
        return this.getAndSetInitQuestionPurpose();
      },
      set: function (purpose) {
        this.data.questionPurpose = purpose;
      },
    },
    //NOTE: This is temproary will be changed once RTE image upload implementation is across the portal
    getRTEAcceptedFileType() {
      return this.options.isAssetUploadRequired
        ? ['image/png', 'image/jpeg']
        : [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
          ];
    },
    forceAssetFinalized() {
      return this.questionsCurrentStage == 'assetAuthorizingStage';
    },
    computeTitle() {
      let list = this.currentDiffIdentifier.split('.');
      let finalTitle = list[list.length - 1];
      if (finalTitle == 'misconception') {
        return 'Feedback to student if they select this answer';
      }
      return this.currentDiffIdentifier;
    },
    selectedSkillValue() {
      if (
        this.questionMetaInfo &&
        this.questionMetaInfo.skills &&
        this.data &&
        this.data.skill != null
      ) {
        return this.questionMetaInfo.skills.filter(
          (questionMetaInfo) => questionMetaInfo.id == this.data.skill
        )[0];
      }
      return '';
    },
    selectedSubSkillValue() {
      if (
        this.questionMetaInfo &&
        this.questionMetaInfo.subSkills &&
        this.data &&
        this.data.subSkill != null
      ) {
        return this.questionMetaInfo.subSkills[this.selectedSkillValue.id].filter(
          (questionMetaInfo) => questionMetaInfo.id == this.data.subSkill
        )[0];
      }
      return '';
    },
    auth() {
      if (this.lpType == 'questions') {
        return this.contentEntryProjectFormAuth;
      } else {
        return this.lessonPlanFormAuth;
      }
    },
    canEnableWritingLinesAndDrawingBox() {
      return (
        this.lpType == this.LP_TYPES_HASH.VIRTUAL_QUESTIONS &&
        (this.data.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT ||
          this.data.questionPurpose == this.questionsOprogramProject.QUESTION_PURPOSE_WORKSHEET) &&
        [
          this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
          this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
        ].includes(this.selectedQuestionType)
      );
    },
  },
  mounted() {
    if (
      this.lpType == 'questions' &&
      [
        this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
        this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
      ].includes(this.selectedQuestionType)
    ) {
      this.data.questionPurpose = this.questionsOprogramProject.QUESTION_PURPOSE_PRACTICE;
    }
    this.getImagesListFromQuestionText(JSON.parse(this.data.questionData.questionText));
  },
  methods: {
    ...mapActions([
      'setQuestionRightSidePanel',
      'fetchProjectLessonPlanSectionComment',
      'saveComment',
      'resolveComment',
    ]),
    ...mapMutations(['SET_COMMENTS_DIALOG', 'SET_GROUP_QUESTION_COMMENTS_DIALOG']),
    addToAsset(previewUrl, briefUrl, approveAsset, index) {
      let questionText = JSON.parse(this.data.questionData.questionText);
      approveAssetAtPosition(
        questionText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        approveAsset,
        previewUrl,
        briefUrl
      );
      this.getImagesListFromQuestionText(questionText);
      this.data.questionData.questionText = JSON.stringify(questionText);
      this.questionTextKeyId = uuidv4();
      this.revision += 1;
    },
    getMetaData() {
      return {
        ...this.data.metadata,
        score: this.data.score,
        weightage: this.data.weightage,
        globalQuestionId: this.data.globalQuestionId,
      };
    },
    getMicroskillAssessed() {
      let microskillAssessedString = '';
      if (this.data.metadata && this.dynamicMetaDataKeySourceDataMap.microskillCategoryId) {
        microskillAssessedString =
          this.data.metadata.microtopicId +
          '_' +
          this.dynamicMetaDataKeySourceDataMap.microskillCategoryId[
            this.data.metadata.microskillCategoryId
          ];
      }
      return {
        microskillAssessed: microskillAssessedString,
      };
    },
    approveAssets(val, index) {
      let questionText = JSON.parse(this.data.questionData.questionText);
      approveAssetAtPosition(
        questionText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        val
      );
      this.getImagesListFromQuestionText(questionText);
      this.data.questionData.questionText = JSON.stringify(questionText);
      this.questionTextKeyId = uuidv4();
      this.revision += 1;
    },
    addBriefTextAtPosition(rteData, index) {
      let questionText = JSON.parse(this.data.questionData.questionText);
      addBriefToAssetInPosition(questionText.content, index, 0, rteData);
      this.data.questionData.questionText = JSON.stringify(questionText);
    },
    changeInQuestionTypeHelper(val) {
      this.selectedQuestionType = val;
      this.changeInQuestionType(val);
      this.questionBlockRenderer += 1;
      this.callOnQuestionTypeOrPurposeUpdate();
    },
    changeInstructionAddedHandler(val) {
      this.data.isInstructionAdded = val;
    },
    fileChangeHandler(file, index) {
      if (!file) {
        let questionText = JSON.parse(this.data.questionData.questionText);
        deleteImageInRTE(questionText.content, index, 0, this.options.isAssetUploadRequired);
        this.getImagesListFromQuestionText(questionText);
        this.data.questionData.questionText = JSON.stringify(questionText);
        this.questionTextKeyId = uuidv4();
        this.revision += 1;
      } else {
        let questionText = JSON.parse(this.data.questionData.questionText);

        insertImageAssetAtPosition(
          questionText.content,
          index,
          0,
          file,
          this.options.isAssetUploadRequired
        );
        this.getImagesListFromQuestionText(questionText);
        this.data.questionData.questionText = JSON.stringify(questionText);
        this.questionTextKeyId = uuidv4();
        this.revision += 1;
      }
    },
    // THIS IS A TEMPORARY method. Need to move the questions inside each question type component
    getToolBarActions() {
      if (this.selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB) {
        return [
          'fontFamily',
          'fontSize',
          'unorderedList',
          'orderedList',
          'fontColor',
          'bold',
          'italic',
          'underline',
          'superscript',
          'subscript',
          'equation',
          'image',
          'blank',
          'fullScreen',
          'table',
        ];
      } else {
        return [
          'fontFamily',
          'fontSize',
          'unorderedList',
          'orderedList',
          'fontColor',
          'bold',
          'italic',
          'underline',
          'superscript',
          'subscript',
          'equation',
          'image',
          'fullScreen',
          'table',
        ];
      }
    },
    getToolBarActionsForQuestionBriefs() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'table',
        'fullScreen',
      ];
    },
    diffCloseHandler() {
      this.setQuestionRightSidePanel(false);
    },
    toggleComments() {
      this.SET_COMMENTS_DIALOG(!this.questionCommentsToggle);
      this.SET_GROUP_QUESTION_COMMENTS_DIALOG(false);
      this.setQuestionRightSidePanel(false);
      this.fetchProjectLessonPlanSectionComment({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
        sectionIdentifier: this.data._id,
        projectType: 'questions',
      });
    },
    commentsSaveButtonClickHandler(text, stageName) {
      if (this.auth.canAddComment) {
        this.saveComment({
          projectId: this.$route.params.projectId,
          sectionIdentifier: this.data._id,
          text,
          stageName,
        });
      }
    },
    commentsResolveButtonClickHandler(stageName) {
      if (this.auth.canResolveComment) {
        this.resolveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.data._id,
          stageName,
        });
      }
    },
    blankDeleteHandler(index) {
      console.log(`~~~~~~~~~~~ index`);
      console.log(index);

      console.log(`------------ this.data.questionData.questionText BEFORE --------------`);
      console.log(this.data.questionData.questionText);
      let typeofQuestion = typeof this.data.questionData.questionText;
      console.log(typeofQuestion);
      let questionText;
      if (typeofQuestion == 'string') {
        questionText = JSON.parse(this.data.questionData.questionText);
      } else {
        questionText = this.data.questionData.questionText;
      }

      deleteBlank(questionText.content, index, -1);
      this.data.questionData.blanks.splice(index, 1);
      this.data.questionData.questionText = JSON.stringify(questionText);
      this.updateSequence();
      this.questionTextKeyId = uuidv4(); // this is to force re-render
      this.optionTextKeyId = uuidv4();

      console.log(`------------ this.data.questionData.questionText AFTER --------------`);
      console.log(this.data.questionData.questionText);
    },
    handleBlankCreation() {
      console.log(`IF BLOCK of isBlankNewlyCreated`);

      let questionTextJSON = JSON.parse(this.data.questionData.questionText);
      let recentBlankIndex = this.data.questionData.blanks.length - 1;

      let trackingIndexesObject = {
        currentBlankIndex: -1,
        newlyInsertedBlankIndex: [],
        recentBlankIndex,
      };
      getNewlyInsertedBlankIndex(questionTextJSON.content, trackingIndexesObject);

      this.newlyInsertedBlankIndices = trackingIndexesObject.newlyInsertedBlankIndex;

      this.data.questionData.questionText = JSON.stringify(questionTextJSON);

      console.log(`~~~~~~~~~~~~~~ newlyInsertedBlankIndex`);
      console.log(this.newlyInsertedBlankIndices);
      this.newlyInsertedBlankIndices.forEach((_blankIndex) => {
        this.data.questionData.blanks.splice(_blankIndex, 0, {
          correctAnswers: [{ text: '' }],
          scorePercentage: 0,
        });
      });
      this.updateSequence();
    },
    handleBlankDeletion(jsonData) {
      console.log(`ELSE BLOCK of isBlankNewlyCreated`);

      let obj = {
        currentBlankIndex: -1,
        deleteBlankIndex: -1,
      };

      let blankTypeArray = [];
      getBlankTypesFromContent(jsonData.content, blankTypeArray, obj);
      let deleteBlankIndex = obj.deleteBlankIndex;

      console.log(`blankTypeArray.length`);
      console.log(blankTypeArray.length);

      console.log(`this.data.questionData.blanks.length`);
      console.log(this.data.questionData.blanks.length);

      if (blankTypeArray.length == this.data.questionData.blanks.length) {
        console.log('Blanks untouched');
        return;
      }
      console.log('Deleted Index');
      console.log(deleteBlankIndex);
      console.log(typeof deleteBlankIndex);
      if (blankTypeArray.length == 0) {
        this.data.questionData.blanks.splice(0, this.data.questionData.blanks.length);
      } else {
        console.log(blankTypeArray);

        console.log(this.data.questionData.blanks);
        for (var i = this.data.questionData.blanks.length - 1; i >= 0; i--) {
          if (!blankTypeArray.includes(i - 1)) {
            this.data.questionData.blanks.splice(i, 1);
          }
        }
      }
      this.updateSequence();
      this.data.questionData.questionText = JSON.stringify(jsonData);
      this.optionTextKeyId = uuidv4();
    },
    updateSequence() {
      for (let i = 0, j = this.data.questionData.blanks.length; i < j; i++) {
        this.data.questionData.blanks[i].sequence = i;
      }
    },
    getImagesListFromQuestionText(jsonData) {
      let imagesInQuestionText = [];
      getImageUrlFromRte(jsonData.content, imagesInQuestionText);
      this.imagesInQuestionText = imagesInQuestionText;
      this.revision += 1;
    },
    onChangeQuestionText(jsonString) {
      let jsonData = JSON.parse(jsonString);
      this.getImagesListFromQuestionText(jsonData);
      if (this.selectedQuestionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB) {
        // NO of blanks info is here
        let allBlankTypeArray = [];
        getAllBlankTypesFromContent(jsonData.content, allBlankTypeArray);

        let isBlankNewlyCreated = false;

        if (allBlankTypeArray.length == this.data.questionData.blanks.length) {
          console.log(`No blank touched! Hence skip this callback and return.`);
          return;
        } else if (allBlankTypeArray.length > this.data.questionData.blanks.length) {
          isBlankNewlyCreated = true;
        }

        console.log(`Blank has been added/deleted. Proceeding...`);

        console.log(`isBlankNewlyCreated = ${isBlankNewlyCreated}`);

        isBlankNewlyCreated ? this.handleBlankCreation() : this.handleBlankDeletion(jsonData);
      }
    },
    getAndSetInitQuestionPurpose() {
      let questionPurposeValues = this.questionPurposeItems.map((_) => _.value);

      let _questionPurpose = questionPurposeValues.includes(this.data.questionPurpose)
        ? this.data.questionPurpose
        : null;

      return _questionPurpose;
    },
    callOnWritingLinesOrDrawingBoxUpdate(field, updateValue) {
      if (this.onWritingLinesOrDrawingBoxUpdate) {
        this.onWritingLinesOrDrawingBoxUpdate(field, updateValue);
      }
    },
    callOnQuestionTypeOrPurposeUpdate() {
      if (
        this.canEnableWritingLinesAndDrawingBox &&
        this.data.writingLines == undefined &&
        this.data.drawingBox == undefined
      ) {
        this.$set(this.data, 'writingLines', WRITING_LINES_HASH.min);
        this.$set(this.data, 'drawingBox', DRAWING_BOX_OPTION_NONE);
      }
      if (this.onQuestionTypeOrPurposeUpdate) {
        this.onQuestionTypeOrPurposeUpdate();
      }
    },
    convertToObjectArray(obj) {
      let finalArr = [];
      for (const key in obj) {
        const val = obj[key];
        finalArr.push({
          key: isNaN(key) ? key : +key,
          value: val,
        });
      }
      finalArr.push({
        key: 0,
        value: 'NA',
      });
      finalArr.push({
        key: '',
        value: 'NA',
      });
      console.log(finalArr);
      return finalArr;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    newlyCreatedQuestion: {
      type: Boolean,
      required: true,
    },
    changeInQuestionType: {
      type: Function,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    wrapAnswerBlockInContainer: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    lpType: {
      type: String,
      default: 'questions',
    },
    lessonPlans: {
      type: Array,
      required: false,
    },
    onWritingLinesOrDrawingBoxUpdate: {
      type: Function,
      required: false,
    },
    onQuestionTypeOrPurposeUpdate: {
      type: Function,
      required: false,
    },
    isLanguageSubject: {
      type: Boolean,
      defualt: false,
    },
    showMetaFields: {
      type: Boolean,
      default: true,
    },
    modifyChangeQuestionBaseData: {
      type: Function,
      default: undefined,
    },
    subSectionTitle: {
      type: String,
      default: 'Question',
    },
    dynamicMetaDataKeySourceDataMap: {
      type: Object,
      default: () => {},
    },
    turnOffQuestionTextValidation: {
      type: Boolean,
      default: false,
    },
    allowedQuestionTypes: {
      type: Array,
      require: false,
      default: undefined,
    },
  },
};
</script>

<style scoped>
.remove-padding {
  padding: 0 !important;
}
.answerContainer {
  background: white;
  border-radius: 15px;
}
.fix-height {
  z-index: 999;
  padding: 10px;
}
.fix-height-diff-comments {
  z-index: 999;
  padding: 10px;
}
</style>
