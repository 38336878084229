<template>
  <div>
    <BlockTitle :title="AppTexts.DISCUSSION" />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiscussionKeyQuestionChanged('keyQuestionPath')"
      :fieldIdentifier="schema.configurableDiscussion.keyQuestionPath"
      :placeholder="AppTexts.KEY_QUESTION"
      :showValidationError="true"
      v-model="keyQuestion"
      :readonly="!isNotReadOnly"
      :counter="schema.discussionKeyQuestionMaxLength"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <br />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiscussionKeyQuestionChanged('keyQuestionGuidePath')"
      :fieldIdentifier="schema.configurableDiscussion.keyQuestionGuidePath"
      :placeholder="AppTexts.KEY_QUESTION_GUIDE"
      :showValidationError="true"
      v-model="keyQuestionGuide"
      :readonly="!isNotReadOnly"
      :counter="schema.discussionKeyQuestionGuideMaxLength"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <BlockTitle class="analysis-explanation-title" :title="AppTexts.EXPLANATION_TEXT" />
    <Step
      :items="get(lessonPlanData, schema.configurableDiscussion.items)"
      :addNewStepHandler="addNewStep"
      :descriptionPlaceholder="AppTexts.EXPLANATION"
      :canShowAddNewStepButton="true"
      :hasSubStep="false"
      :allowDeleteStep="true"
      :deleteStepHandler="deleteNewStep"
      :maxNoOfSteps="schema.configurableDiscussion.maxNoOfDiscussionSteps"
      :canShowStepBullet="true"
      :canShowDiffIcon="canShowDiffIcon"
      :stepDiffIdentifier="`${schema.configurableDiscussion.items}[<stepIndex>].description`"
      :isStepDescriptionChanged="isADStepDescriptionChanged"
      class="analysis-explanation-step"
      :isNotReadOnly="isNotReadOnly"
      :stepDescriptionMaxLength="schema.discussionStepDescriptionMaxLength"
      :subStepDescriptionMaxLength="schema.discussionSubStepDescriptionMaxLength"
      :stepTeacherTipMaxLength="schema.discussionStepTeacherTipMaxLength"
      :subPath="schema.configurableDiscussion.items"
    />
    <v-divider class="mgn-Ts mgn-Bs" />
    <MultipleAssetUploader
      :key="`multiAssetUploader${generateIdentifier()}`"
      :items="get(lessonPlanData, schema.configurableDiscussion.explanations)"
      :addAssetAction="addExplanationAsset"
      :deleteAssetAction="deleteExplanationAsset"
      :canShowDiffIcon="canShowDiffIcon"
      :itemDiff="getExaplanationDiff()"
      :diffIdentifierPrefix="schema.configurableDiscussion.explanations"
      :assetType="AssetTypes.ACTIVITY_EXPLANATION_ASSET_TYPE"
      withAdd
      :uploadAssetAction="importAnalysisExplanationAssetAction"
      :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
      :placeholder="AppTexts.TITLE"
      :isRequired="false"
      :label="AppTexts.SELECT_FILE"
      :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :acceptableFileType="acceptableFileType"
      :assetsSignedUrls="get(signedUrls, schema.configurableDiscussion.explanations)"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="true"
      :canApproveAsset="canAppproveAsset"
      :subPath="schema.configurableDiscussion.explanations"
    />
    <!--<div class="mgn-Ts">
      <XSDisplayCard :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiscussionTeacherTipGuideChanged('activity_discussion')"
          fieldIdentifier="analysis.activity_discussion.teacher_tip"
          :hintText="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="lessonPlanData.analysis.activity_discussion.teacher_tip"
          :counter="schema.discussionTeacherTipMaxLength"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
      </XSDisplayCard>
    </div> -->
  </div>
</template>
<script>
import BlockTitle from '@/components/inputs/BlockTitle';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import { get, set } from 'lodash';
import AppTexts from '@/constants/AppTexts';
import Step from '@/components/inputs/Step';
import { mapActions } from 'vuex';
import AssetTypes from '@/constants/AssetTypes';
import MultipleAssetUploader from '@/components/inputs/MultipleAssetUploader';

export default {
  components: {
    RichTextEditor,
    BlockTitle,
    Step,
    MultipleAssetUploader,
  },
  data() {
    return {
      BASIC_TOOLS,
      AppTexts,
      AssetTypes,
      analysisIdentifier: '',
    };
  },
  methods: {
    get,
    ...mapActions([
      'addInstructionStepForPath',
      'deleteInstructionStepForPath',
      'addExplanationAssetForPath',
      'deleteExplanationAssetActionForPath',
      'deleteAnaysisExplanationAssetsForPath',
      'importExplanationAssetActionForPath',
    ]),
    generateIdentifier() {
      this.analysisIdentifier = this.analysisIdentifier || Math.random().toString(36).slice(2);
    },
    isDiscussionKeyQuestionChanged(path) {
      return get(this.diff, `${this.schema.configurableDiscussion[path]}.isChanged`, false);
    },
    addNewStep({ index, path }) {
      this.addInstructionStepForPath({ index, path, hasSubStep: false, hasAsset: false });
    },
    deleteNewStep({ index, path }) {
      this.deleteInstructionStepForPath({ index, path, hasAsset: false });
    },
    addExplanationAsset(type) {
      this.addExplanationAssetForPath({
        path: this.schema.configurableDiscussion.explanations,
        type,
      });
    },
    deleteAnalysisExplanationAssetAction(payload) {
      this.deleteExplanationAssetActionForPath({
        ...payload,
        path: this.schema.configurableDiscussion.explanations,
      });
    },
    importAnalysisExplanationAssetAction(payload) {
      this.importExplanationAssetActionForPath({
        ...payload,
        path: this.schema.configurableDiscussion.explanations,
      });
    },
    deleteExplanationAsset({ index }) {
      this.deleteAnaysisExplanationAssetsForPath({
        path: this.schema.configurableDiscussion.explanations,
        index,
      });
    },
    isADStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return get(
            this.diff,
            `${this.schema.configurableDiscussion.explanations}[${stepIndex}}.isChanged`,
            false
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getExaplanationDiff() {
      return get(this.diff, `${this.schema.configurableDiscussion.explanations}.isChanged`, false);
    },
  },
  computed: {
    keyQuestion: {
      get() {
        return get(this.lessonPlanData, this.schema.configurableDiscussion.keyQuestionPath);
      },
      set(value) {
        console.log(this.schema.configurableDiscussion.keyQuestionPath);
        set(this.lessonPlanData, this.schema.configurableDiscussion.keyQuestionPath, value);
      },
    },
    keyQuestionGuide: {
      get() {
        return get(this.lessonPlanData, this.schema.configurableDiscussion.keyQuestionGuidePath);
      },
      set(value) {
        set(this.lessonPlanData, this.schema.configurableDiscussion.keyQuestionGuidePath, value);
      },
    },
  },
  props: {
    diff: {
      type: Object,
      default: () => {},
    },
    lessonPlanData: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      default: () => {},
    },
    fileValidators: { type: Array, required: false, default: () => [] },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
