const TrainingHelpers = require('./helpers');
const { sortBy } = require('lodash');
// const moment = require('moment');
// const { TRAINING_STATUSES } = require('../../../constants/training');

const getters = {
  fetchTrainingApiStatus: (state) => {
    console.log('GETTERS#fetchTrainingApiStatus#START');

    if (state && state.trainingListApiResponse) {
      console.log('GETTERS#fetchTrainingApiStatus#END.SUCC');
      return state.trainingListApiResponse.status;
    }

    console.log('GETTERS#fetchTrainingApiStatus#END');
    return null;
  },
  fetchTrainingApiStatusMessage: (state) => {
    console.log('GETTERS#completedTrainings#START');

    if (state && state.trainingListApiResponse) {
      console.log('GETTERS#completedTrainings#END.SUCC');
      return state.trainingListApiResponse.message;
    }

    console.log('GETTERS#completedTrainings#END');
    return null;
  },
  // training with every slot was ended in past, goes to completed list.
  completedTrainings: (state) => {
    console.log('GETTERS#completedTrainings#START');
    if (state && state.trainingListApiResponse && state.trainingListApiResponse.data) {
      const masterTrainingsList = state.trainingListApiResponse.data;
      const currentTimeInMs = new Date().getTime();
      const parsedCompletedTrainings = [];

      masterTrainingsList.forEach((_trainingData) => {
        if (_trainingData.trainingSlots && _trainingData.trainingSlots.length > 0) {
          const lastSlotEndDateInMs = TrainingHelpers.getLastTime(
            _trainingData.trainingSlots.map((_ts) => _ts.endDate)
          );

          if (lastSlotEndDateInMs < currentTimeInMs) {
            const lastSlot = _trainingData.trainingSlots.find((_trainingSlot) => {
              return +_trainingSlot.endDate == lastSlotEndDateInMs;
            });

            const lastSlotStartDateInMs = +lastSlot.startDate;

            const lastSlotStartDateParsed = TrainingHelpers.getDisplayTextForDate(
              new Date(lastSlotStartDateInMs)
            );

            const lastSlotEndDateParsed = TrainingHelpers.getDisplayTextForDate(
              new Date(lastSlotEndDateInMs)
            );

            const firstTrainingSlot = _trainingData.trainingSlots[0] || {};

            parsedCompletedTrainings.push({
              id: _trainingData.id,
              title: _trainingData.title,
              imageUrl: _trainingData.image.url,
              date: lastSlotStartDateParsed.fullDateString,
              time: `${lastSlotStartDateParsed.fullTimeString} - ${lastSlotEndDateParsed.fullTimeString} ${lastSlotEndDateParsed.ampm}`,
              location: lastSlot.location.locationType,
              sortParam: lastSlotStartDateInMs,
              groupByParam: lastSlotEndDateParsed.year,
              pollsQuestionCount: (firstTrainingSlot.polls || []).reduce((accu, current) => {
                if (_trainingData.pollsAndCorrespondingQuestionCount[`POLL-${current.index}`]) {
                  accu[`Poll-${current.index}`] =
                    _trainingData.pollsAndCorrespondingQuestionCount[`POLL-${current.index}`];
                } else {
                  accu[`Poll-${current.index}`] = 0;
                }
                return accu;
              }, {}),
              preTestQuestionCount: _trainingData.preTestQuestionCount,
              postTestQuestionCount: _trainingData.postTestQuestionCount,
              feedbackTestQuestionCount: _trainingData.feedbackTestQuestionCount,
            });
          }
        }
      });

      console.log('GETTERS#completedTrainings#END.SUCC', parsedCompletedTrainings);
      return sortBy(parsedCompletedTrainings, 'sortParam');
    }

    console.log('GETTERS#completedTrainings#END.FALLBACK');
    return null;
  },
  // training with some slot is in future, goes to upcoming list.
  upcomingTrainings: (state) => {
    console.log('GETTERS#upcomingTrainings#START');
    if (state && state.trainingListApiResponse && state.trainingListApiResponse.data) {
      const masterTrainingsList = state.trainingListApiResponse.data;
      const currentTimeInMs = new Date().getTime();
      const parsedUpcomingTrainings = [];

      masterTrainingsList.forEach((_trainingData) => {
        if (_trainingData.trainingSlots && _trainingData.trainingSlots.length > 0) {
          const upcomingSlots = _trainingData.trainingSlots.filter((_trainingSlot) => {
            return +_trainingSlot.endDate > currentTimeInMs;
          });

          if (upcomingSlots.length > 0) {
            const firstSlotStartDateInMs = TrainingHelpers.getEarliestTime(
              upcomingSlots.map((_ts) => _ts.startDate)
            );

            const firstSlot = _trainingData.trainingSlots.find((_trainingSlot) => {
              return +_trainingSlot.startDate == firstSlotStartDateInMs;
            });

            const firstSlotEndDateInMs = +firstSlot.endDate;

            const firstSlotStartDateParsed = TrainingHelpers.getDisplayTextForDate(
              new Date(firstSlotStartDateInMs)
            );
            const firstSlotEndDateParsed = TrainingHelpers.getDisplayTextForDate(
              new Date(firstSlotEndDateInMs)
            );

            const upcominSlots = _trainingData.trainingSlots.filter((_trainingSlot) => {
              return currentTimeInMs < +_trainingSlot.endDate;
            });

            const firstTrainingSlot = _trainingData.trainingSlots[0] || {};

            parsedUpcomingTrainings.push({
              id: _trainingData.id,
              title: _trainingData.title,
              imageUrl: _trainingData.image.url,
              date: firstSlotStartDateParsed.fullDateString,
              time: `${firstSlotStartDateParsed.fullTimeString} - ${firstSlotEndDateParsed.fullTimeString} ${firstSlotEndDateParsed.ampm}`,
              location: firstSlot.location.locationType,
              upcomingSlotsCount: upcominSlots.length,
              sortParam: +firstSlotStartDateInMs,
              pollsQuestionCount: (firstTrainingSlot.polls || {}).reduce((accu, current) => {
                if (_trainingData.pollsAndCorrespondingQuestionCount[`POLL-${current.index}`]) {
                  accu[`Poll-${current.index}`] =
                    _trainingData.pollsAndCorrespondingQuestionCount[`POLL-${current.index}`];
                } else {
                  accu[`Poll-${current.index}`] = 0;
                }
                return accu;
              }, {}),
              preTestQuestionCount: _trainingData.preTestQuestionCount,
              postTestQuestionCount: _trainingData.postTestQuestionCount,
              feedbackTestQuestionCount: _trainingData.feedbackTestQuestionCount,
            });
          }
        }
      });

      console.log('GETTERS#upcomingTrainings#END.SUCC', parsedUpcomingTrainings);
      return sortBy(parsedUpcomingTrainings, 'sortParam');
    }

    console.log('GETTERS#upcomingTrainings#END.FALLBACK');
    return null;
  },
};

export default getters;
