<template>
  <div>
    <v-sheet
      :style="`border: 1px solid ${getColor};`"
      rounded="rounded lg"
      class="text-center"
      outlined
      elevation="1"
    >
      <div style="height: 30px;">
        <p class="font-weight-medium questionPurposeName" style="font-size: 11px;">
          <span>{{ getTitleWithoutQuestion[0] }}</span>
          <span>Questions ({{(+data['totalGroupQuestions'] + data['totalQuestions'])}})</span>
        </p>
      </div>
    </v-sheet>
    <Padder :top="10">
      <v-sheet
        :style="`border: 1px solid ${getColor};`"
        rounded="rounded lg"
        class="text-center"
        outlined
        elevation="1"
      >
        <Padder :horizontal="10" :vertical="10">
          <v-row>
            <v-col class="font-weight-medium" style="font-size: 10px;">Total Time</v-col>
            <v-col>
              <v-responsive
                class="text-center grey lighten-4 rounded-circle d-inline-flex align-center justify-center font-weight-medium"
                height="35"
                width="35"
                style="font-size: 18px;"
              >
                {{ data['totalTime'] }}
              </v-responsive>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col class="font-weight-medium" style="font-size: 10px;">Group <br />Questions</v-col>
            <v-col
              ><v-responsive
                class="text-center grey lighten-4 rounded-circle d-inline-flex align-center justify-center font-weight-medium"
                height="35"
                width="35"
                style="font-size: 18px;"
              >
                {{ data['totalGroupQuestions'] }}
              </v-responsive></v-col
            >
          </v-row>
          <v-row>
            <v-col class="font-weight-medium" style="font-size: 10px;"
              >Individual <br />Questions</v-col
            >
            <v-col>
              <v-responsive
                class="text-center grey lighten-4 rounded-circle d-inline-flex align-center justify-center font-weight-medium"
                height="35"
                width="35"
                style="font-size: 18px;"
              >
                {{ data['totalQuestions'] }}
              </v-responsive>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col class="font-weight-medium" style="font-size: 10px;">Easy Questions</v-col>
            <v-col>
              <v-responsive
                class="text-center grey lighten-4 rounded-circle d-inline-flex align-center justify-center font-weight-medium"
                height="35"
                width="35"
                style="font-size: 18px;"
              >
                {{ data['easyQuestions'] }}
              </v-responsive>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font-weight-medium" style="font-size: 10px;">Medium Questions</v-col>
            <v-col>
              <v-responsive
                class="text-center grey lighten-4 rounded-circle d-inline-flex align-center justify-center font-weight-medium"
                height="35"
                width="35"
                style="font-size: 18px;"
              >
                {{ data['mediumQuestions'] }}
              </v-responsive>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font-weight-medium" style="font-size: 10px;">Hard Questions</v-col>
            <v-col>
              <v-responsive
                class="text-center grey lighten-4 rounded-circle d-inline-flex align-center justify-center font-weight-medium"
                height="35"
                width="35"
                style="font-size: 18px;"
              >
                {{ data['hardQuestions'] }}
              </v-responsive>
            </v-col>
          </v-row>
        </Padder>
      </v-sheet>
    </Padder>
    <v-sheet :color="getBorderColor" rounded="rounded b-lg" class="mr-1 ml-1" elevation="0">
      <Padder :horizontal="20" :vertical="10">
        <v-row
          v-for="(questionType, key) in data['individualQuestionsCount']"
          :key="key"
          class="font-weight-medium"
          style="font-size: 12px;"
        >
          <v-col class="pl-4">{{ getQuestionTypeDisplayText(key) }}</v-col>
          <v-col class="text-center" cols="3">{{ questionType }}</v-col>
        </v-row>
      </Padder>
    </v-sheet>
  </div>
</template>

<script>
import { getQuestionTypeDisplayText } from '@/utils/questionUtils.js';
import { QUESTION_PURPOSE_COLORS, QUESTION_PURPOSE_BORDER_COLORS } from '@/constants/question';

export default {
  data() {
    return {};
  },
  computed: {
    getTitleWithoutQuestion() {
      return this.title.split('Questions');
    },
    getColor() {
      return QUESTION_PURPOSE_COLORS[this.index % QUESTION_PURPOSE_COLORS.length];
    },
    getBorderColor() {
      return QUESTION_PURPOSE_BORDER_COLORS[this.index % QUESTION_PURPOSE_BORDER_COLORS.length];
    },
  },
  methods: {
    getQuestionTypeDisplayText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    questionPurpose: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>
<style scoped>
.questionPurposeName span {
  display: block;
}
</style>
