<template>
  <div>
    <div v-for="(microTopic, index) in microTopics" :key="index">
      <v-card class="micro-topic-card">
        <div class="micro-topic-row align-child-verically-center">
          <div class="micro-topic-name-column">
            <div class="micro-topic-id">{{ microTopic.mtId }}</div>
            <v-divider class="verical-divider" vertical />
            <div style="width: 100%;">
              <div class="micro-topic-details-row">
                <span class="micro-topic-theme-topic-text"
                  >{{ microTopic.theme.name }} - {{ microTopic.topic.name }}</span
                >
                <span class="micro-topic-grade-subject-text"
                  >{{ microTopic.standard.displayName }}, {{ microTopic.subject.name }}</span
                >
              </div>
              <div class="micro-topic-name-text">{{ microTopic.name }}</div>
            </div>
          </div>
          <v-divider class="verical-divider" vertical />
          <div class="micro-topic-status-column">
            <SubProjectStatus
              lessonType="O Lesson"
              :currentStage="getProjectCurrentStage(microTopic, 'oLesson')"
              :status="getProjectStatus(microTopic, 'oLesson')"
            />
          </div>
          <v-divider class="verical-divider" vertical />
          <div class="micro-topic-status-column">
            <SubProjectStatus
              lessonType="Worksheet"
              :currentStage="getProjectCurrentStage(microTopic, 'worksheet')"
              :status="getProjectStatus(microTopic, 'worksheet')"
            />
          </div>
          <v-divider class="verical-divider" vertical />
          <div class="micro-topic-status-column">
            <SubProjectStatus
              lessonType="Content Book"
              :currentStage="getProjectCurrentStage(microTopic, 'contentBook')"
              :status="getProjectStatus(microTopic, 'contentBook')"
            />
          </div>
          <v-divider class="verical-divider" vertical />
          <div class="micro-topic-status-column">
            <SubProjectStatus
              lessonType="Micro-Text"
              :currentStage="getProjectCurrentStage(microTopic, 'microText')"
              :status="getProjectStatus(microTopic, 'microText')"
            />
          </div>
          <v-divider class="verical-divider" vertical />
          <div class="micro-topic-status-column">
            <SubProjectStatus
              lessonType="Videos"
              :currentStage="getProjectCurrentStage(microTopic, 'videos')"
              :status="getProjectStatus(microTopic, 'videos')"
            />
          </div>
          <v-divider class="verical-divider" vertical />
          <div class="micro-topic-status-column">
            <SubProjectStatus
              lessonType="Questions"
              :currentStage="getProjectCurrentStage(microTopic, 'questions')"
              :status="getProjectStatus(microTopic, 'questions')"
            />
          </div>
          <v-divider class="verical-divider" vertical />
          <div class="micro-topic-action-column">
            <v-btn
              @click="$emit('viewMicroTopic', microTopic)"
              class="xs-accent-button action-button"
              v-if="isActiveMicroTopic(microTopic)"
              >View</v-btn
            >
            <v-btn
              @click="$emit('createMicroTopic', microTopic)"
              v-else
              class="xs-accent-button action-button create-micro-topic-btn"
              >Create
            </v-btn>
          </div>
        </div>
      </v-card>
      <br />
    </div>
  </div>
</template>
<script>
import SubProjectStatus from '@/components/contentEntry/microTopic/SubProjectStatus';
import {
  NOT_STARTED_ITEM_STATUS,
  MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING,
} from '@/constants/microTopic';

export default {
  name: 'MicroTopicList',
  components: {
    SubProjectStatus,
  },
  methods: {
    isActiveMicroTopic(microTopic) {
      if (microTopic.projects && microTopic.projects.length) {
        let isActiveMicroTopic = true;
        microTopic.projects.forEach((proj) => {
          isActiveMicroTopic = isActiveMicroTopic && proj.workflowStatus != NOT_STARTED_ITEM_STATUS;
        });

        return isActiveMicroTopic;
      } else {
        return false;
      }
    },
    getProjectStatus(microTopic, projectType) {
      try {
        let project = microTopic.projects.find((project) => project.type == projectType);

        return project.workflowStatus;
      } catch (e) {
        return 'not_available';
      }
    },
    getProjectCurrentStage(microTopic, projectType) {
      try {
        let project = microTopic.projects.find((project) => project.type == projectType);

        return MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING[project.contents[0].currentStage];
      } catch (e) {
        return 'NA';
      }
    },
  },
  props: {
    microTopics: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.micro-topic-card {
  padding: 20px 20px;
}
.action-button {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-left: 11px;
  width: 115px;
  height: 41px !important;
  box-shadow: none !important;
  border-radius: 4px;
}
.verical-divider {
  margin: 0px 9px;
}
.micro-topic-name-column {
  width: 25%;
  display: flex;
}
.micro-topic-status-column {
  width: 10%;
}
.micro-topic-action-column {
  width: 135px;
}
.create-micro-topic-btn {
  background-color: #38b274 !important;
  font-size: 12px;
  font-weight: bold;
  color: #fff !important;
}
.micro-topic-name-text {
  font-size: 16px;
  color: #242424;
  font-weight: bold;
  margin-top: 5px;
}
.micro-topic-details-row {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  color: #242424;
}
.micro-topic-id {
  font-size: 12px;
  color: #242424;
  min-width: 47px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
</style>
