import gql from 'graphql-tag';

export const getProjectAndContentDetails = (projectId) => {
  return gql`
  {
    project(id: "${projectId}") {
      id
      type
      name
      workflowStatus
      standard {
        id
        displayName
        name
      }
      subject {
        id
        name
        shortName
      }
      contents {
        id
        library_id
        name
        type
        currentStage
      }
      assignees {
        author {
          id
          name
        }
        reviewer1 {
          id
          name
        }
        reviewer2 {
          id
          name
        }
        academicSignOff {
          id
          name
        }
        editor {
          id
          name
        }
        visualizer {
          id
          name
        }
        assetUploader {
          id
          name
        }
        assetAuthorizer {
          id
          name
        }
        proofReader {
          id
          name
        }
      }
    }
    projectLessonPlanCurrentStageData(
      projectId: "${projectId}"
    ) {
      currentStage
      library_id
      name
      type
      commentsCount {
        aim
        action
        analysis
        application
        assessment
        summary
      }
      currentStageAssetsSignedUrl
      currentStageData {
        validationStatus
        createdAt
        updatedAt
        equationEditorJsonArray {
          latex
          json
        }
        data 
      }
      diff    
    }
  }
  `;
};
export const getLearnometerDetails = (projectId, yearId) => {
  return gql`
  {
    project(id: "${projectId}") {
      id
      type
      name
      workflowStatus
      standard {
        id
        displayName
        name
      }
      subject {
        id
        name
        shortName
      }
      contents {
        id
        library_id
        name
        type
        currentStage
      }
      assignees {
        author {
          id
          name
        }
        reviewer1 {
          id
          name
        }
        reviewer2 {
          id
          name
        }
        academicSignOff {
          id
          name
        }
        editor {
          id
          name
        }
        visualizer {
          id
          name
        }
        assetUploader {
          id
          name
        }
        assetAuthorizer {
          id
          name
        }
        proofReader {
          id
          name
        }
      }
    }
    projectLearnometerCurrentStageData(projectId: "${projectId}", yearId: ${yearId}) {
      currentStage
      library_id
      name
      type
      currentStageAssetsSignedUrl
      currentStageData {
        validationStatus
        createdAt
        updatedAt
        equationEditorJsonArray {
          latex
          json
        }
        data
      }
    }
  }
  `;
};

export const uploadSingleAsset = () => {
  return gql`
    mutation uploadSingleAsset(
      $file: Upload!
      $fileName: String!
      $libraryId: Int!
      $namespace: String
    ) {
      uploadSingleAsset(
        file: $file
        fileName: $fileName
        libraryId: $libraryId
        namespace: $namespace
      ) {
        path
        signedUrl
      }
    }
  `;
};

export const removeSingleAssetFromProject = () => {
  return gql`
    mutation removeSingleAssetFromProject(
      $url: String!
      $projectId: ID!
      $lessonPlanLibraryId: ID!
    ) {
      removeSingleAssetFromProject(
        url: $url
        projectId: $projectId
        contentLibraryId: $lessonPlanLibraryId
      ) {
        path
        signedUrl
      }
    }
  `;
};

export const projectContentEntryCurrentStageData = (projectId) => {
  return gql`
    query {
      projectLessonPlanCurrentStageData(
        projectId: "${projectId}"
      ) {
        currentStage
        library_id
        name
        type
        commentsCount {
          aim
          action
          analysis
          application
          assessment
          summary
        }
        currentStageAssetsSignedUrl
        currentStageData {
          validationStatus
          createdAt
          updatedAt
          equationEditorJsonArray {
            latex
            json
          }
          data 
        }
        diff    
      }
    }
  `;
};
export const saveContentDataQuery = () => {
  return gql`
    mutation(
      $projectId: ID!
      $lessonPlanLibraryId: ID!
      $data: JSON!
      $equationEditorJsonArray: [EquationEditorJsonInput]!
    ) {
      saveProjectLessonPlanStageData(
        projectId: $projectId
        contentLibraryId: $lessonPlanLibraryId
        data: $data
        equationEditorJsonArray: $equationEditorJsonArray
      )
    }
  `;
};

export const validateContentStageDataQuery = (projectId, libraryId) => {
  return gql`
    mutation {
      validateProjectLessonPlanStageData(
        projectId: "${projectId}"
        contentLibraryId: ${libraryId}
      ) {
        status
        errors{
          path
          errorMessage
        }
      }
    }
  `;
};

export const moveContentToNextStageQuery = (projectId, libraryId) => {
  return gql`
    mutation {
      moveProjectLessonPlanToNextStage(
        projectId: "${projectId}"
        contentLibraryId: ${libraryId}
      ) {
        currentStage
      }
    }
  `;
};

export const publishContentQuery = (projectId) => {
  return gql`
    mutation {
      publishProjectLpsToTeacherReadinessApp(
        projectId: "${projectId}"
      ){
        triggerStatus
      }
    }
  `;
};

export const downloadCurrentStageContentAssetsQuery = gql`
  query($projectId: ID!) {
    downloadCurrentStageLpAssets(projectId: $projectId) {
      status
    }
  }
`;

export const moveFromProofReaderToAssetAuthorizingStage = (projectId, libraryId) => gql`
mutation {
  moveFromProofReaderToAssetAuthorizingStage(projectId: "${projectId}" 
  contentLibraryId: "${libraryId}"
  )
}`;
export const getQuestionMetaInfo = gql`
  query($subjectId: ID) {
    questionMetaData(subjectId: $subjectId) {
      skills {
        id
        name
      }
      academicYears
      questionPurposes
      difficultyLevels
      subSkills
      languageSkills
    }
  }
`;

export const instantiateContentEntryProjectData = () => {
  return gql`
    mutation($type: String!, $data: JSON!) {
      instantiateContentEntryProjectData(type: $type, data: $data)
    }
  `;
};

export const uploadAssetZip = () => {
  return gql`
    mutation uploadAssetZip(
      $assetId: ID!
      $preSignedUrl: String!
      $projectId: ID!
      $libraryId: Int!
      $assetType: String!
    ) {
      uploadAssetZip(
        assetId: $assetId
        preSignedUrl: $preSignedUrl
        projectId: $projectId
        libraryId: $libraryId
        assetType: $assetType
      )
    }
  `;
};

export const validateUploadAssetPageToken = () => {
  return gql`
    mutation validateUploadAssetPageToken($projectId: ID!, $libraryId: Int!) {
      validateUploadAssetPageToken(projectId: $projectId, libraryId: $libraryId)
    }
  `;
};

export const saveLearnometerDataQuery = () => {
  return gql`
    mutation($projectId: ID!, $data: JSON!, $equationEditorJsonArray: [EquationEditorJsonInput]!) {
      saveLearnometerStageData(
        projectId: $projectId
        data: $data
        equationEditorJsonArray: $equationEditorJsonArray
      )
    }
  `;
};
