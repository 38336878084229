export default {
  SLIDER_DEFAULT_DATA_LAYOUT: {
    Layout1: {
      text: '',
    },
    Layout2: {
      text: '',
      images: [
        {
          imageTitle: '',
          imageUrl: '',
          brief: '',
          briefUrl: '',
          imageUrlApproved: false,
        },
        {
          imageTitle: '',
          imageUrl: '',
          brief: '',
          briefUrl: '',
          imageUrlApproved: false,
        },
        {
          imageTitle: '',
          imageUrl: '',
          brief: '',
          briefUrl: '',
          imageUrlApproved: false,
        },
      ],
    },
    Layout3: {
      headerText: '',
      images: [
        {
          imageTitle: '',
          imageUrl: '',
          brief: '',
          briefUrl: '',
          imageUrlApproved: false,
        },
        {
          imageTitle: '',
          imageUrl: '',
          brief: '',
          briefUrl: '',
          imageUrlApproved: false,
        },
        {
          imageTitle: '',
          imageUrl: '',
          brief: '',
          briefUrl: '',
          imageUrlApproved: false,
        },
      ],
      footerText: '',
    },
    Layout4: [
      {
        text: '',
        imageTitle: '',
        imageUrl: '',
        brief: '',
        briefUrl: '',
        imageUrlApproved: false,
      },
      {
        text: '',
        imageTitle: '',
        imageUrl: '',
        brief: '',
        briefUrl: '',
        imageUrlApproved: false,
      },
    ],
    Layout5: {
      imageTitle: '',
      imageUrl: '',
      brief: '',
      briefUrl: '',
      imageUrlApproved: false,
    },
  },
  SLIDER_DEFAULT_ASSETS_LAYOUT: {
    Layout1: {
      text: '',
    },
    Layout2: {
      images: [
        {
          imageUrl: '',
          briefUrl: '',
        },
        {
          imageUrl: '',
          briefUrl: '',
        },
        {
          imageUrl: '',
          briefUrl: '',
        },
      ],
    },
    Layout3: {
      images: [
        {
          imageUrl: '',
          briefUrl: '',
        },
        {
          imageUrl: '',
          briefUrl: '',
        },
        {
          imageUrl: '',
          briefUrl: '',
        },
      ],
    },
    Layout4: [
      {
        imageUrl: '',
        briefUrl: '',
      },
      {
        imageUrl: '',
        briefUrl: '',
      },
    ],
    Layout5: {
      imageUrl: '',
      briefUrl: '',
    },
  },
};
