<template>
  <div id="block-mapping">
    <div id="content">
      <h3>
        Displaying Blocks for {{ this.$route.query.boardName }} /
        {{ this.$route.query.subjectName }} / Grade
        {{ this.$route.query.gradeName }}
      </h3>
      <div v-if="fetchStatus == API_FETCH_STATUS.IN_PROGRESS">
        <span class="summary-text">
          {{ AppTexts.API_FETCH_IN_PROGRESS_MESSAGE }}
        </span>
      </div>
      <div v-else-if="fetchStatus == API_FETCH_STATUS.COMPLETED">
        <span class="summary-text">
          {{ blocks.length }} Blocks with {{ lessonPlans.length }} Lesson Plans
        </span>
        <v-expansion-panels id="blocks-wrapper">
          <v-expansion-panel v-for="(_blockHash, i) in blocks" :key="i" class="block-wrapper">
            <v-expansion-panel-header class="block-header">
              <BlockHeader :block="_blockHash" />
            </v-expansion-panel-header>
            <v-expansion-panel-content class="block-lesson-plans">
              <LessonPlanSimpleView
                v-for="(_lessonPlanHash, i) in _blockHash.lessonPlans"
                :key="i"
                class="block-lesson-plan"
                :lessonPlan="_lessonPlanHash"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>
        <span class="summary-text">{{ AppTexts.API_FAILURE_MESSAGE }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { API_FETCH_STATUS } from '@/constants/ApiFetchStatus';
import AppTexts from '@/constants/AppTexts';
import LessonPlanSimpleView from '@/components/LessonPlanSimpleView';
import BlockHeader from '@/components/BlockHeader';
import { groupBy, sortBy } from '@/functions/array';

export default {
  name: 'BlockMapping',
  components: {
    LessonPlanSimpleView,
    BlockHeader
  },
  data() {
    return {
      data: {
        fetchStatus: API_FETCH_STATUS.IN_PROGRESS,
        lessonPlans: []
      },
      API_FETCH_STATUS,
      AppTexts
    };
  },
  created() {
    this.getLessonPlans({
      boardName: this.$route.query.boardName,
      subjectId: this.$route.query.subjectId,
      gradeId: this.$route.query.gradeId,
      productId: this.$route.query.productId
    });
  },
  computed: {
    ...mapState({
      lessonPlans: (state) => state.lessonPlans
    })
  },
  watch: {
    lessonPlans: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.empty) {
          this.fetchStatus = API_FETCH_STATUS.IN_PROGRESS;
          return;
        }

        this.fetchStatus = API_FETCH_STATUS.COMPLETED;
        let lessonPlansGroupedByBlockId = groupBy(val, 'bId');
        let groupedByBlocks = Object.keys(lessonPlansGroupedByBlockId).map((_blockId) => {
          let { blockSequence, blockTitle } = lessonPlansGroupedByBlockId[_blockId][0];

          return {
            bId: _blockId,
            blockSequence,
            blockTitle,
            lessonPlans: sortBy(lessonPlansGroupedByBlockId[_blockId], 'sequence')
          };
        });

        this.blocks = sortBy(groupedByBlocks, 'blockSequence');
      }
    }
  },
  methods: {
    ...mapActions(['getLessonPlans'])
  }
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

#block-mapping #content {
  margin: 10px;
}

#block-mapping #content .block-wrapper {
  border: 1px solid;
  margin-top: 10px;
  margin-bottom: 10px;
}

#block-mapping #content .block-wrapper .block-lesson-plans .block-lesson-plan {
  margin-top: 8px;
  overflow: hidden;
}

#block-mapping #content .block-wrapper .block-lesson-plans .block-lesson-plan:not(:last-child) {
  border-bottom: 1px solid $bg-block-header;
}

#block-mapping #content .block-wrapper .block-lesson-plans .block-lesson-plan {
  padding: 10px;
}

.v-expansion-panel-content.block-lesson-plans .v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.v-expansion-panel-header.block-header {
  padding: 0px !important;
  background-color: $bg-block-header;
}
</style>
