<template>
  <div>
    <div class="profile-avatar" color="grey" tile>
      <div class="filename-display">{{ filename }}</div>

      <div class="upload-inprogress-wrapper">
        <div class="title">Uploading Document</div>
        <div class="content">You can keep working while your document is uploaded</div>
        <br /><br />
        <v-progress-linear
          style="bottom: 0;"
          v-model="fileUploadProgressValue"
          color="blue-grey"
          height="25"
          v-if="fileUploadProgressValue > 0 && fileUploadProgressValue < 100"
        >
          <template v-slot:default="{ value }">
            <strong>{{ value }}%</strong>
          </template>
        </v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AssetUploadInProgressStatus',
  props: {
    filename: { type: String, required: true },
  },
  data() {
    return {
      fileUploadProgressValue: 0,
    };
  },
  computed: {
    ...mapState({
      fileUploadProgress: (state) => state.common.fileUploadProgress,
    }),
  },
  watch: {
    fileUploadProgress: {
      immediate: true,
      handler(val) {
        console.log(val);
        this.fileUploadProgressValue = val;
      },
    },
  },
};
</script>

<style scoped>
.profile-avatar {
  height: 250px;
  width: 100%;
  background-color: #b4b4b4;
  vertical-align: middle;
}
.filename-display {
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  background-color: rgba(70, 70, 70, 0.7);
  color: white;
}
.upload-inprogress-wrapper {
  margin-top: 30px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: white;
}
.content {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: white;
}
</style>
