import * as LessonPlanQueries from '@/apiQueries/lpForm';
import { apolloClient } from '@/apollo';
import { logError } from '@/utils';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import AssetTypes from '@/constants/AssetTypes';
import Router from '@/router';
import Vue from 'vue';
import { API_FETCH_STATUS } from '@/constants/ApiFetchStatus';
import { LIMIT, LESSON_PLAN_LOADING_STATES } from '@/constants/LessonPlan';
import { uploadToCA } from '@/utils/CAFileUploadUtils.js';
import {
  LP_TYPE_REVISION,
  LP_TYPE_MENTAL_MATH,
  LP_TYPE_DISCUSSION,
  LP_TYPE_PRACTICE,
} from '@/constants/common';

const projectInfoForAssetRemoval = () => {
  return {
    projectId: Router.history.current.params.projectId,
    lessonPlanLibraryId: parseInt(Router.history.current.params.libraryId),
  };
};

const actions = {
  updateSummaryText({ commit }, payload) {
    commit('updateSummaryText', payload);
  },
  updateBriefText({ commit }, payload) {
    commit('updateBriefText', payload);
  },
  addKeyword({ commit, state }, payload = LIMIT.KEYWORDS) {
    if (state.data.keywords.length < payload) {
      commit('addKeyword');
    } else {
      Vue.notify({
        title: 'Failed!',
        text: `Number of items in Keywords can only be ${payload}`,
        type: 'error',
      });
    }
  },
  deleteKeyword({ commit }, index) {
    commit('deleteKeyword', index);
  },
  addResource({ commit, state }, payload = LIMIT.RESOURCES) {
    if (state.data.aim.resources.length < payload) {
      commit('addResource');
    } else {
      Vue.notify({
        title: 'Failed!',
        text: `Number of items in Resources can only be ${payload}`,
        type: 'error',
      });
    }
  },
  deleteResource({ commit }, index) {
    commit('deleteResource', index);
  },
  addNewAssest({ commit }, payload) {
    commit('addNewAssest', payload);
    commit('addNewSignedUrlForAsset', payload);
  },
  removeAssest({ dispatch }, payload) {
    dispatch('deleteStepAssetAction', {
      assetId: payload.assetId,
      stepIndex: payload.stepIndex,
      assetIndex: payload.assetIndex,
      section: payload.section,
      identifier: payload.identifier,
      removeHashAlso: true,
      path: payload.path,
    });
  },
  addActionStep({ commit }, { index }) {
    commit('addActionStep', { index });
  },
  addActionSubStep({ commit }, index) {
    commit('addActionSubStep', index);
  },
  deleteActionStep({ commit }, index) {
    commit('deleteActionStep', index);
  },
  deleteActionSubStep({ commit }, payload) {
    commit('deleteActionSubStep', payload);
  },
  addInstructionStepForPath({ commit }, { index, path, hasSubStep = true, hasAsset = true }) {
    commit('addInstructionStepForPath', { index, path, hasSubStep, hasAsset });
  },
  addInstructionSubStepForPath({ commit }, { stepIndex, path }) {
    commit('addInstructionSubStepForPath', { stepIndex, path });
  },
  deleteInstructionStepForPath({ commit }, { index, path, hasAsset = true }) {
    commit('deleteInstructionStepForPath', { index, path, hasAsset });
  },
  deleteInstructionSubStepForPath({ commit }, { stepIndex, subStepIndex, path }) {
    commit('deleteInstructionSubStepForPath', { stepIndex, subStepIndex, path });
  },
  updateInstructionBreakValueOfStep({ commit }, payload) {
    commit('updateInstructionBreakValueOfStep', payload);
  },
  addPractice({ commit }, payload) {
    let { index } = payload || {};
    commit('addPractice', { index });
  },
  addPracticeForStep({ commit }, payload) {
    let { index, path } = payload || {};
    commit('addPracticeForStep', { index, path });
  },
  deletePracticeForStep({ commit }, payload) {
    let { index, path } = payload || {};
    commit('deletePracticeForStep', { index, path });
  },
  updateApplicationClassworkDescription({ commit }, { description }) {
    commit('updateApplicationClassworkDescription', description);
  },
  deletePractice({ commit }, index) {
    commit('deletePractice', index);
  },
  addAnalysisHigherOrderStep({ commit }, { index }) {
    commit('addAnalysisHigherOrderStep', { index });
  },
  deleteAnalysisHigherOrderStep({ commit }, index) {
    commit('deleteAnalysisHigherOrderStep', index);
  },
  addAnalysisGeneralizedDiscussionStep({ commit }, { index }) {
    commit('addAnalysisGeneralizedDiscussionStep', { index });
  },
  deleteAnalysisGeneralizedDiscussionStep({ commit }, index) {
    commit('deleteAnalysisGeneralizedDiscussionStep', index);
  },
  deleteAnalysisDiscussionSubStepAction({ commit }, payload) {
    commit('deleteAnalysisDiscussionSubStep', payload);
  },
  addAnalysisActivityDiscussionStep({ commit }, { index }) {
    commit('addAnalysisActivityDiscussionStep', { index });
  },
  addAnalysisDiscussionStep({ commit }, { index }) {
    commit('addAnalysisDiscussionStep', { index });
  },
  addAnalysisInstructionStep({ commit }, { index }) {
    commit('addAnalysisInstructionStep', { index });
  },
  addPhonicsAnalysisStep({ commit }, payload) {
    commit('addPhonicsAnalysisStep', payload);
  },
  deleteAnalysisActivityDiscussionStep({ commit }, index) {
    commit('deleteAnalysisActivityDiscussionStep', index);
  },
  deleteDiscussionStepInstruction({ commit }, index) {
    commit('deleteDiscussionStepInstruction', index);
    commit('removeSignedUrlForActionAssest', {
      section: 'analysis.instructions',
      stepIndex: index,
    });
  },
  deleteAnalysisDiscussionStep({ commit }, index) {
    commit('deleteAnalysisDiscussionStep', index);
  },
  deletePhonicsAnalysisStep({ commit }, payload) {
    commit('deletePhonicsAnalysisStep', payload);
  },
  addPhonicsAnalysisSubStep({ commit }, payload) {
    commit('addPhonicsAnalysisSubStep', payload);
  },
  deletePhonicsAnalysisSubStep({ commit }, payload) {
    commit('deletePhonicsAnalysisSubStep', payload);
  },
  addAnaysisExplanationAssets({ commit }, type) {
    commit('addAnaysisExplanationAssets', type);
  },
  addExplanationAssetForPath({ commit }, { type, path }) {
    commit('addExplanationAssetForPath', { type, path });
  },
  deleteAnaysisExplanationAssets({ commit }, payload) {
    commit('deleteAnaysisExplanationAssets', payload);
  },
  deleteAnaysisExplanationAssetsForPath({ commit }, payload) {
    commit('deleteAnaysisExplanationAssetsForPath', payload);
  },
  addReference({ commit }) {
    commit('addReference');
  },
  deleteReference({ commit }, index) {
    commit('deleteReference', index);
  },
  setParsedJsonToState({ commit }, parsedJson) {
    commit('setParsedJsonToState', parsedJson);
  },
  setLessonPlanStaticTextsData({ commit }, _data) {
    commit('setLessonPlanStaticTextsData', _data);
  },
  updateTimeOfBlock({ commit }, { blockName, value }) {
    commit('updateTimeOfBlock', {
      blockName,
      value,
    });
  },
  updateTypeOfBlock({ commit }, { blockName, value }) {
    commit('updateTypeOfBlock', {
      blockName,
      value,
    });
  },
  // Not in usage
  async importAssetsAction({ commit, state, dispatch }, payload) {
    try {
      if (payload) {
        commit('setImageUploadStatus', {
          assetType: AssetTypes.IMPORT_ASSET_TYPE,
          status: FILE_UPLOADING_STATUS.IN_PROGRESS,
        });

        dispatch('changeLoadingState', true);
        const response = await apolloClient.mutate({
          mutation: LessonPlanQueries.uploadLpAssets(),
          variables: {
            file: payload,
            libraryId: state.libraryId,
          },
        });

        dispatch('changeLoadingState', false);
        if (response.data && response.data.uploadLpAssets) {
          Vue.notify({
            title: 'Success!',
            text: 'Assets uploaded successfully!',
            type: 'success',
          });

          commit('setImageUploadStatus', {
            assetType: AssetTypes.IMPORT_ASSET_TYPE,
            status: FILE_UPLOADING_STATUS.COMPLETED,
          });
          commit('importAssets', response.data.uploadLpAssets);
        } else {
          dispatch('showErrorNotification', {
            assetType: AssetTypes.IMPORT_ASSET_TYPE,
          });
        }
      }
    } catch (err) {
      dispatch('showErrorNotification', {
        assetType: AssetTypes.IMPORT_ASSET_TYPE,
      });
    }
  },
  async importSingleAssetAction({ commit, state, dispatch }, payload) {
    const { file, assetType, index } = payload;
    const failureResponse = {
      path: null,
      signedUrl: null,
      assetType,
      index,
    };

    try {
      if (file) {
        // commit('importSingleAsset', {
        //   assetType: assetType,
        //   path: file.name,
        // });

        commit('setImageUploadStatus', {
          assetType,
          status: FILE_UPLOADING_STATUS.IN_PROGRESS,
          index,
        });
        let response;
        let fileName = _getBriefFileName(state, file);
        dispatch('changeLoadingState', true);
        response = await uploadToCA(file, fileName);
        if (response && response.status === 'success') {
          commit('setImageUploadStatus', {
            assetType,
            status: FILE_UPLOADING_STATUS.COMPLETED,
            index,
          });

          commit('importSingleAsset', {
            ...response,
            assetType,
            index,
          });
        } else {
          dispatch('showErrorNotification', {
            assetType,
            index,
          });
          commit('importSingleAsset', failureResponse);
        }
      }
    } catch (err) {
      dispatch('showErrorNotification', {
        assetType,
        index,
      });
      commit('importSingleAsset', failureResponse);
    }
    dispatch('changeLoadingState', false);
  },
  async copySingleAssetAction({ commit }, { signedUrl, path, assetType, isAssetApproved, index }) {
    commit('importSingleAsset', {
      id: path,
      signedUrl,
      assetType,
      forceApproveAsset: isAssetApproved,
      index,
    });
  },
  async importSingleResourceAssetAction(
    { commit, state, dispatch },
    { payload, resourceIndex, identifier }
  ) {
    const failureResponse = {
      response: {
        path: null,
        signedUrl: '',
        assetType: AssetTypes.RESOURCES_ASSET_TYPE,
      },
      resourceIndex,
    };
    try {
      if (payload) {
        commit('importSingleResourceAsset', {
          response: {
            id: payload.name,
            signedUrl: '',
            assetType: AssetTypes.RESOURCES_ASSET_TYPE,
            loadingStatus: FILE_UPLOADING_STATUS.IN_PROGRESS,
          },
          identifier,
          resourceIndex,
        });
        let response;
        let fileName = _getBriefFileName(state, payload);
        dispatch('changeLoadingState', true);
        response = await uploadToCA(payload, fileName);
        if (response.status == 'success') {
          commit('importSingleResourceAsset', {
            response: {
              ...response,
              assetType: AssetTypes.RESOURCES_ASSET_TYPE,
              loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
            },
            identifier,
            resourceIndex,
          });
        } else {
          dispatch('showErrorNotification', {
            assetType: AssetTypes.RESOURCES_ASSET_TYPE,
            index: resourceIndex,
          });
          commit('importSingleResourceAsset', failureResponse);
        }
      }
    } catch (err) {
      dispatch('showErrorNotification', {
        assetType: AssetTypes.RESOURCES_ASSET_TYPE,
        index: resourceIndex,
      });
      commit('importSingleResourceAsset', failureResponse);
    }
    dispatch('changeLoadingState', false);
  },
  async copySingleResourceAssetAction(
    { commit },
    { signedUrl, briefAssetId, index, isAssetApproved, identifier }
  ) {
    commit('importSingleResourceAsset', {
      response: {
        id: briefAssetId,
        signedUrl,
        assetType: AssetTypes.RESOURCES_ASSET_TYPE,
        loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
      },
      resourceIndex: index,
      forceApproveAsset: isAssetApproved,
      identifier,
    });
  },
  async importSingleAssetActionByIdentifier(
    { commit, state, dispatch },
    { payload, identifier, assetUrlIdentifier }
  ) {
    const failureResponse = {
      response: {
        path: null,
        signedUrl: '',
      },
    };
    try {
      if (payload) {
        commit('importSingleAssetActionByIdentifier', {
          response: {
            id: payload.name,
            signedUrl: '',
            loadingStatus: FILE_UPLOADING_STATUS.IN_PROGRESS,
          },
          identifier,
          assetUrlIdentifier,
        });
        let response;
        let fileName = _getBriefFileName(state, payload);
        dispatch('changeLoadingState', true);
        response = await uploadToCA(payload, fileName);
        if (response.status == 'success') {
          commit('importSingleAssetActionByIdentifier', {
            response: {
              ...response,
              loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
            },
            identifier,
            assetUrlIdentifier,
          });
        } else {
          dispatch('showErrorNotification', {
            assetType: AssetTypes.RESOURCES_ASSET_TYPE,
          });
          commit('importSingleAssetActionByIdentifier', failureResponse);
        }
      }
    } catch (err) {
      console.log(err);

      dispatch('showErrorNotification', {
        assetType: AssetTypes.RESOURCES_ASSET_TYPE,
      });
      commit('importSingleAssetActionByIdentifier', failureResponse);
    }
    dispatch('changeLoadingState', false);
  },
  async copySingleResourceAssetActionByIdentifier(
    { commit },
    { signedUrl, briefAssetId, isAssetApproved, identifier, assetUrlIdentifier }
  ) {
    commit('importSingleAssetActionByIdentifier', {
      response: {
        id: briefAssetId,
        signedUrl,
        loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
      },
      forceApproveAsset: isAssetApproved,
      identifier,
      assetUrlIdentifier,
    });
  },
  async importAnalysisExplanationAssetAction({ commit, state, dispatch }, payload) {
    let { file, assetType, resourceIndex } = payload;
    const failureResponse = {
      response: {
        path: null,
        signedUrl: '',
        assetType,
      },
      resourceIndex,
    };
    try {
      if (file) {
        commit('importAnalysisExplanationAsset', {
          response: {
            path: file.name,
            signedUrl: '',
            assetType,
            loadingStatus: FILE_UPLOADING_STATUS.IN_PROGRESS,
          },
          resourceIndex,
        });
        let response;
        let fileName = _getBriefFileName(state, file);

        response = await uploadToCA(file, fileName);
        dispatch('changeLoadingState', true);
        if (response.status == 'success') {
          commit('importAnalysisExplanationAsset', {
            response: {
              ...response,
              assetType,
              loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
            },
            resourceIndex,
          });
        } else {
          dispatch('showErrorNotification', {
            assetType,
            index: resourceIndex,
          });
          commit('importAnalysisExplanationAsset', failureResponse);
        }
      }
    } catch (err) {
      dispatch('showErrorNotification', {
        assetType,
        index: resourceIndex,
      });
      commit('importSingleResourceAsset', failureResponse);
    }
    dispatch('changeLoadingState', false);
  },
  async importExplanationAssetActionForPath({ commit, state, dispatch }, payload) {
    let { file, assetType, resourceIndex, path } = payload;
    const failureResponse = {
      response: {
        path: null,
        signedUrl: '',
        assetType,
      },
      resourceIndex,
    };
    try {
      if (file) {
        commit('importExplanationAssetActionForPath', {
          response: {
            path: file.name,
            signedUrl: '',
            assetType,
            loadingStatus: FILE_UPLOADING_STATUS.IN_PROGRESS,
          },
          resourceIndex,
          path,
        });
        let response;
        let fileName = _getBriefFileName(state, file);

        response = await uploadToCA(file, fileName);
        dispatch('changeLoadingState', true);
        if (response.status == 'success') {
          commit('importExplanationAssetActionForPath', {
            response: {
              ...response,
              assetType,
              loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
            },
            resourceIndex,
            path,
          });
        } else {
          dispatch('showErrorNotification', {
            assetType,
            index: resourceIndex,
          });
          commit('importExplanationAssetActionForPath', {
            ...failureResponse,
            path,
          });
        }
      }
    } catch (err) {
      dispatch('showErrorNotification', {
        assetType,
        index: resourceIndex,
      });
      commit('importExplanationAssetActionForPath', {
        ...failureResponse,
        path,
      });
    }
    dispatch('changeLoadingState', false);
  },
  async deleteExplanationAssetActionForPath({ commit }, payload) {
    let { url, assetType, resourceIndex, path } = payload;
    if (url && url.length) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.removeSingleAssetFromProject(),
        variables: {
          url,
          ...projectInfoForAssetRemoval(),
        },
      });

      if (response && response.data && response.data.removeSingleAssetFromProject) {
        commit('importExplanationAssetActionForPath', {
          response: {
            path: response.data.removeSingleAssetFromProject.path,
            assetType,
            loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
          },
          path,
          resourceIndex,
        });
        commit('triggerSaveLessonPlan');
      }
    }
  },
  async copyAnalysisExplanationAssetAction({ commit }, payload) {
    let { previewUrl, briefUrl, index, assetType, approveAsset, path } = payload;
    if (path) {
      commit('importExplanationAssetActionForPath', {
        response: {
          id: briefUrl,
          signedUrl: previewUrl,
          assetType,
          loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
        },
        path,
        resourceIndex: index,
        forceApproveAsset: approveAsset,
      });
    } else {
      commit('importAnalysisExplanationAsset', {
        response: {
          id: briefUrl,
          signedUrl: previewUrl,
          assetType,
          loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
        },
        resourceIndex: index,
        forceApproveAsset: approveAsset,
      });
    }
  },
  async deleteSingleAssetAction({ commit }, payload) {
    const { assetId, assetType, fieldIdentifier, index } = payload;
    if (assetId && assetId.length) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.removeSingleAssetInCaForProject(),
        variables: {
          assetId,
          fieldIdentifier,
          ...projectInfoForAssetRemoval(),
        },
      });
      if (response && response.data && response.data.removeSingleAssetInCaForProject) {
        commit('importSingleAsset', {
          id: response.data.removeSingleAssetInCaForProject.assetId,
          signedUrl: response.data.removeSingleAssetInCaForProject.signedUrl,
          assetType,
          index,
        });
        commit('setLessonPlanLoadingStatus', {
          status: LESSON_PLAN_LOADING_STATES.completed,
        });
        commit('triggerSaveLessonPlan');
      }
    }
  },
  async deleteSingleResourceAssetAction({ commit }, { assetId, resourceIndex, identifier, subPath = '' }) {

    if (assetId && assetId.length) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.removeSingleAssetInCaForProject(),
        variables: {
          assetId,
          fieldIdentifier: identifier,
          ...projectInfoForAssetRemoval(),
        },
      });

      if (response && response.data && response.data.removeSingleAssetInCaForProject) {
        commit('importSingleResourceAsset', {
          response: {
            id: response.data.removeSingleAssetInCaForProject.assetId,
            signedUrl: response.data.removeSingleAssetInCaForProject.signedUrl,
            assetType: AssetTypes.RESOURCES_ASSET_TYPE,
            loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
          },
          resourceIndex,
          identifier: identifier,
          subPath: subPath,
        });
        commit('triggerSaveLessonPlan');
      }
    }
  },
  async deleteSingleResourceAssetActionByIdentifier(
    { commit },
    { assetId, identifier, assetUrlIdentifier }
  ) {
    if (assetId && assetId.length) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.removeSingleAssetInCaForProject(),
        variables: {
          assetId,
          fieldIdentifier: identifier,
          ...projectInfoForAssetRemoval(),
        },
      });

      if (response && response.data && response.data.removeSingleAssetInCaForProject) {
        commit('importSingleAssetActionByIdentifier', {
          response: {
            id: response.data.removeSingleAssetInCaForProject.assetId,
            signedUrl: response.data.removeSingleAssetInCaForProject.signedUrl,
            loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
          },
          identifier: identifier,
          assetUrlIdentifier,
        });
        commit('triggerSaveLessonPlan');
      }
    }
  },
  async deleteAnalysisExplanationAssetAction({ commit }, payload) {
    let { url, assetType, resourceIndex } = payload;
    if (url && url.length) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.removeSingleAssetFromProject(),
        variables: {
          url,
          ...projectInfoForAssetRemoval(),
        },
      });

      if (response && response.data && response.data.removeSingleAssetFromProject) {
        commit('importAnalysisExplanationAsset', {
          response: {
            path: response.data.removeSingleAssetFromProject.path,
            assetType,
            loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
          },
          resourceIndex,
        });
        commit('triggerSaveLessonPlan');
      }
    }
  },
  async importStepAssetAction(
    { commit, state, dispatch },
    { file, stepIndex, assetIndex, section }
  ) {
    try {
      if (file) {
        commit('importStepAssetAction', {
          response: {
            id: file.name,
            loadingStatus: FILE_UPLOADING_STATUS.IN_PROGRESS,
          },
          stepIndex,
          assetIndex,
          section,
        });
        let response;
        let fileName = _getBriefFileName(state, file);
        response = await uploadToCA(file, fileName);
        dispatch('changeLoadingState', true);
        if (response.status == 'success') {
          commit('importStepAssetAction', {
            response: {
              ...response,
              loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
            },
            stepIndex,
            assetIndex,
            section,
          });
        } else {
          dispatch('showErrorNotification', {
            assetType: AssetTypes.STEP_ASSET,
            stepIndex,
            assetIndex,
            section,
          });
        }
      }
    } catch (err) {
      dispatch('showErrorNotification', {
        assetType: AssetTypes.STEP_ASSET,
        stepIndex,
        assetIndex,
        section,
      });
    }
    dispatch('changeLoadingState', false);
  },
  async copyStepAssetAction({ commit }, payload) {
    let { signedUrl, assetId, stepIndex, assetIndex, section, approveAsset } = payload;
    commit('importStepAssetAction', {
      response: {
        id: assetId,
        signedUrl,
        loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
      },
      stepIndex,
      assetIndex,
      section,
      forceApproveAsset: approveAsset,
    });
  },
  toggleActionInstructionTeacherTipField({ commit }, payload) {
    commit('TOGGLE_ACTION_INSTRUCTION_TEACHER_TIP_FIELD', payload);
  },
  toggleAnalysisDiscussionInstructionTeacherTipField({ commit }, payload) {
    commit('TOGGLE_ANALYSIS_DISCUSSION_INSTRUCTION_TEACHER_TIP_FIELD', payload);
  },
  toggleInstructionTeacherTipBasedOnPath({ commit }, payload) {
    commit('TOGGLE_INSTRUCTION_TEACHER_TIP_FIELD_BASED_ON_PATH', payload);
  },
  togglePhonicsAnalysisInstructionTeacherTipField({ commit }, payload) {
    commit('TOGGLE_PHONICS_ANALYSIS_DISCUSSION_INSTRUCTION_TEACHER_TIP_FIELD', payload);
  },
  toggleAnalysisPhonicsInstructionTeacherTipField({ commit }, payload) {
    commit('TOGGLE_ANALYSIS_PHONICS_INSTRUCTION_TEACHER_TIP_FIELD', payload);
  },
  async deleteStepAssetAction({ commit }, payload) {
    let { assetId, stepIndex, assetIndex, section, removeHashAlso, identifier } = payload;
    if (assetId && assetId.length) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.removeSingleAssetInCaForProject(),
        variables: {
          assetId,
          fieldIdentifier: identifier,
          ...projectInfoForAssetRemoval(),
        },
      });

      if (response && response.data && response.data.removeSingleAssetInCaForProject) {
        if (removeHashAlso) {
          commit('removeAssest', payload);
          commit('removeSignedUrlForActionAssest', payload);
        } else {
          commit('importStepAssetAction', {
            response: {
              id: response.data.removeSingleAssetInCaForProject.assetId,
              signedUrl: response.data.removeSingleAssetInCaForProject.signedUrl,
              loadingStatus: FILE_UPLOADING_STATUS.COMPLETED,
            },
            stepIndex,
            assetIndex,
            section,
          });
        }
        commit('triggerSaveLessonPlan');
      }
    } else if (removeHashAlso) {
      commit('removeAssest', payload);
      commit('removeSignedUrlForActionAssest', payload);
    }
  },
  async downloadLPAssets({ commit }, payload) {
    try {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });

      let { projectId, libraryId, yearId } = payload;
      const response = await apolloClient.query({
        query: LessonPlanQueries.downloadCurrentStageLpAssetsQuery,
        variables: {
          lessonPlanLibraryId: parseInt(libraryId),
          yearId: yearId ?? 0,
          projectId: projectId,
        },
        fetchPolicy: 'no-cache',
      });

      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      if (
        response.data &&
        response.data.downloadCurrentStageLpAssets &&
        response.data.downloadCurrentStageLpAssets.status
      ) {
        Vue.notify({
          title: 'Success!',
          text: 'Lesson Plan Assets will be mailed to you shortly!',
          type: 'success',
        });
      } else {
        Vue.notify({
          title: 'Failed',
          text: 'Downloading Lesson Plan assets failed',
          type: 'error',
        });
      }
    } catch (err) {
      Vue.notify({
        title: 'Failed',
        text: 'Downloading Lesson Plan assets failed',
        type: 'error',
      });
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      logError(err);
    }
  },
  async saveLessonPlanData({ commit, state }, payload) {
    try {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      let { projectId, libraryId, data } = payload;
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.saveLessonPlanStageDataQuery(),
        variables: {
          lessonPlanLibraryId: parseInt(libraryId),
          projectId: projectId,
          data: JSON.parse(JSON.stringify(data.data)),
          equationEditorJsonArray: state.equationEditorJsonArray || [],
        },
      });

      if (response.data && response.data.saveProjectLessonPlanStageData) {
        Vue.notify({
          title: 'Success!',
          text: 'Lesson Plan saved successfully!',
          type: 'success',
        });

        if (response && response.data) {
          // dispatch("generateLessonPlanAudio", JSON.parse(JSON.stringify(data)));

          // Do not commit LP to state for Practice LP
          // The "Teacher Tips" needs to be shown in the same order as they were entered by user.
          // If state is updated, then the order will change based on "questionNumber" of Teacher Tip.
          if (payload.data.lpType != 'practice') {
            commit('saveLessonPlanData', {
              data: response.data.saveProjectLessonPlanStageData,
            });
          }
        }
      }
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });
      commit('triggerSaveLessonPlan', {
        flag: false,
      });
    } catch (err) {
      console.log(err);
      Vue.notify({
        title: 'Failed',
        text: 'Lesson Plan save failed',
        type: 'error',
      });
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });
      commit('triggerSaveLessonPlan', {
        flag: false,
      });

      logError(err);
    }
  },
  async validateLessonPlanData({ commit, state }, { projectId, libraryId, data }) {
    try {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });

      const lessonPlanSaveResponse = await apolloClient.mutate({
        mutation: LessonPlanQueries.saveLessonPlanStageDataQuery(),
        variables: {
          lessonPlanLibraryId: parseInt(libraryId),
          projectId: projectId,
          data: JSON.parse(JSON.stringify(data.data)),
          equationEditorJsonArray: state.equationEditorJsonArray || [],
        },
      });
      if (lessonPlanSaveResponse && lessonPlanSaveResponse.data) {
        const validationResponse = await apolloClient.mutate({
          mutation: LessonPlanQueries.validateProjectLessonPlanStageDataQuery(projectId, libraryId),
        });
        if (
          validationResponse &&
          validationResponse.data &&
          validationResponse.data.validateProjectLessonPlanStageData &&
          validationResponse.data.validateProjectLessonPlanStageData.status == 'success'
        ) {
          Vue.notify({
            title: 'Success',
            text: 'Lesson Plan validation success',
            type: 'success',
          });
        } else {
          Vue.notify({
            title: 'Failed',
            text: 'Lesson Plan validation failed in back end',
            type: 'warn',
          });
        }
      }
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });
    } catch (err) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      logError(err);
      Vue.notify({
        title: 'Failed',
        text: 'Lesson Plan validation failed in back end. Error: ' + err.message,
        type: 'warn',
      });
    }
  },
  async getLessonPlanData({ commit, dispatch }, { projectId, libraryId, projectType = '' }) {
    commit('setDefaultData');
    dispatch('changeLoadingState', true);
    try {
      commit('setLessonPlanDataFetchStatus', API_FETCH_STATUS.IN_PROGRESS);
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      const template = Router.currentRoute.query.template;

      const response = await apolloClient.query({
        query: LessonPlanQueries.projectLessonPlanCurrentStageDataAndlessonPlansWithMicroTopicsAndlessonPlanStaticTextsDataQuery(
          projectId,
          libraryId,
          projectType,
          template
        ),
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        if (
          response.data.projectLessonPlanCurrentStageData.type == 'information_and_assessment' &&
          response.data.projectLessonPlanCurrentStageData.currentStageData.data
            .informationAndAssessment &&
          !response.data.projectLessonPlanCurrentStageData.currentStageData.data
            .informationAndAssessment.question_answer
        ) {
          const currentStageIAData =
            response.data.projectLessonPlanCurrentStageData.currentStageData.data
              .informationAndAssessment;
          const currentStageIAAssetsSignedUrl =
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl
              .informationAndAssessment;
          const questionAnswerDefaultHash = {
            assetId: '',
            type: '',
            brief: {
              text: '',
              assetId: '',
              type: '',
            },
            placeholder_image: '',
          };
          const questionAnswerDefaultAssetHash = {
            brief: {
              assetSignedUrl: '',
            },
            assetSignedUrl: '',
          };
          if (currentStageIAData.answer) {
            response.data.projectLessonPlanCurrentStageData.currentStageData.data.informationAndAssessment.question_answer =
              currentStageIAData.answer;
          } else {
            response.data.projectLessonPlanCurrentStageData.currentStageData.data.informationAndAssessment.question_answer = questionAnswerDefaultHash;
          }
          if (currentStageIAAssetsSignedUrl.answer) {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer =
              currentStageIAAssetsSignedUrl.answer;
          } else {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer = questionAnswerDefaultAssetHash;
          }
        }

        const lessonPlansWithMicroTopics = response.data.lessonPlansWithMicroTopics;

        if (!lessonPlansWithMicroTopics) {
          Vue.notify({
            title: 'Error!',
            text: 'Error: while fetching Lesson Plans with MicroTopics!',
            type: 'error',
          });
        }

        let currentStageInfo = lessonPlansWithMicroTopics[0]
          ? (lessonPlansWithMicroTopics || [])[0]
          : response.data.projectLessonPlanCurrentStageData;
        let { name, type } = currentStageInfo;

        if (
          type == LP_TYPE_PRACTICE &&
          !response.data.projectLessonPlanCurrentStageData.currentStageData.data.application
            .support_tips
        ) {
          response.data.projectLessonPlanCurrentStageData.currentStageData.data.application.support_tips = [];
        }

        let subjectName = response.data.projectLessonPlanCurrentStageData.subjectName;
        if (response.data.lessonPlanStaticTextsData) {
          commit('setLessonPlanStaticTextsData', response.data.lessonPlanStaticTextsData);
        }

        if (
          response.data.projectLessonPlanCurrentStageData.currentStageData.data &&
          response.data.projectLessonPlanCurrentStageData.currentStageData.data.assessment &&
          response.data.projectLessonPlanCurrentStageData.currentStageData.data.assessment
            .grade_template
        ) {
          let gt =
            response.data.projectLessonPlanCurrentStageData.currentStageData.data.assessment
              .grade_template;
          let overallGT = gt.overall || { description: '' };
          response.data.projectLessonPlanCurrentStageData.currentStageData.data.assessment.grade_template.overall = overallGT;
        }

        // Handle Discussion type LP data
        if (type == LP_TYPE_DISCUSSION) {
          for (const index in response.data.projectLessonPlanCurrentStageData.currentStageData.data
            .analysis.questions_to_guide_discussion.questions) {
            let temp =
              response.data.projectLessonPlanCurrentStageData.currentStageData.data.analysis
                .questions_to_guide_discussion.questions[index];

            if (Object.keys(temp).includes('question_image')) {
              // Do nothing
            } else {
              response.data.projectLessonPlanCurrentStageData.currentStageData.data.analysis.questions_to_guide_discussion.questions[
                index
              ].question_image = {
                assetId: '',
                brief: {
                  text: '',
                  assetId: '',
                },
              };
            }

            if (Object.keys(temp).includes('explanation_image')) {
              // Do nothing
            } else {
              response.data.projectLessonPlanCurrentStageData.currentStageData.data.analysis.questions_to_guide_discussion.questions[
                index
              ].explanation_image = {
                assetId: '',
                brief: {
                  text: '',
                  assetId: '',
                },
              };
            }
          }

          if (
            Object.keys(
              response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis
                .questions_to_guide_discussion
            ).includes('questions')
          ) {
            for (const index in response.data.projectLessonPlanCurrentStageData
              .currentStageAssetsSignedUrl.analysis.questions_to_guide_discussion) {
              let temp =
                response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis
                  .questions_to_guide_discussion[index];

              if (Object.keys(temp).includes('question_image')) {
                // Do nothing
              } else {
                response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis.questions_to_guide_discussion[
                  index
                ].question_image = {
                  assetSignedUrl: '',
                  brief: {
                    assetSignedUrl: '',
                  },
                };
              }

              if (Object.keys(temp).includes('explanation_image')) {
                // Do nothing
              } else {
                response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis.questions_to_guide_discussion[
                  index
                ].explanation_image = {
                  assetSignedUrl: '',
                  brief: {
                    assetSignedUrl: '',
                  },
                };
              }
            }
          } else {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis.questions_to_guide_discussion.questions = [];
            for (const index in response.data.projectLessonPlanCurrentStageData.currentStageData
              .data.analysis.questions_to_guide_discussion.questions) {
              response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis.questions_to_guide_discussion.questions[
                index
              ] = {
                question_image: {
                  assetSignedUrl: '',
                  brief: {
                    assetSignedUrl: '',
                  },
                },
                explanation_image: {
                  assetSignedUrl: '',
                  brief: {
                    assetSignedUrl: '',
                  },
                },
              };
            }
          }

          if (
            !response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis
              .questions_to_guide_discussion.board_image_primary
          ) {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis.questions_to_guide_discussion.board_image_primary = {
              assetSignedUrl: '',
              brief: {
                assetSignedUrl: '',
              },
            };
          }
          if (
            !response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis
              .questions_to_guide_discussion.board_image_secondary
          ) {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.analysis.questions_to_guide_discussion.board_image_secondary = {
              assetSignedUrl: '',
              brief: {
                assetSignedUrl: '',
              },
            };
          }
        }

        commit('setParsedJsonToState', {
          name,
          lpType: type,
          libraryId,
          subjectName,
          currentStage: response.data.projectLessonPlanCurrentStageData.currentStage,
          equationEditorJsonArray:
            response.data.projectLessonPlanCurrentStageData.currentStageData
              .equationEditorJsonArray,
          currentStageAssetsSignedUrl:
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl,
          data: (response.data.projectLessonPlanCurrentStageData.currentStageData || {}).data,
          diff: response.data.projectLessonPlanCurrentStageData.diff || {},
          commentsCount: response.data.projectLessonPlanCurrentStageData.commentsCount,
          lpCurrentStageDataDailyPlan: response.data.projectLessonPlanCurrentStageData.dailyPlan,
        });
        Vue.notify({
          title: 'Success!',
          text: 'Lesson Plan data loaded successfully!',
          type: 'success',
        });

        if ([LP_TYPE_REVISION, LP_TYPE_MENTAL_MATH, LP_TYPE_PRACTICE].includes(type)) {
          dispatch('fetchQuestionMetaData', {
            currentSubjectId: response.data.projectLessonPlanCurrentStageData.subject.id,
          });
        }
      } else {
        Vue.notify({
          title: 'Error!',
          text: 'Error: while fetching data from Library!',
          type: 'error',
        });
      }
      commit('setLessonPlanDataFetchStatus', API_FETCH_STATUS.COMPLETED);
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      dispatch('changeLoadingState', false);
    } catch (err) {
      commit('setLessonPlanDataFetchStatus', API_FETCH_STATUS.ERROR);
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      dispatch('changeLoadingState', false);
      commit('setDefaultData');
      logError(err);
    }
  },
  setLibraryId({ commit }, libraryId) {
    commit('setLibraryId', parseInt(libraryId));
  },
  async generateLessonPlanAudio(_, payload) {
    let { libraryId } = payload;

    try {
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.generateLessonPlanAudioQuery(libraryId),
      });

      if (response.data && response.data.generateLessonPlanAudio) {
        Vue.notify({
          title: 'Success!',
          text: 'Audio generation was success!',
          type: 'success',
        });
      }
    } catch (err) {
      Vue.notify({
        title: 'Failed',
        text:
          'Lesson Plan audio generation failed. Reason may be data is not present or can contain invalid data',
        type: 'error',
      });
      logError(err);
    }
  },
  showErrorNotification({ commit }, payload) {
    Vue.notify({
      title: 'Error in file upload!',
      text: 'File upload failed!',
      type: 'error',
    });

    if (payload.assetType == AssetTypes.RESOURCES_ASSET_TYPE) {
      commit('importSingleResourceAsset', {
        response: {
          path: '',
          assetType: AssetTypes.RESOURCES_ASSET_TYPE,
          loadingStatus: FILE_UPLOADING_STATUS.ERROR,
        },
        resourceIndex: payload.index,
      });
    } else if (payload.assetType == AssetTypes.STEP_ASSET) {
      commit('importStepAssetAction', {
        response: {
          url: '',
          assetType: AssetTypes.STEP_ASSET,
          loadingStatus: FILE_UPLOADING_STATUS.ERROR,
        },
        stepIndex: payload.stepIndex,
        assetIndex: payload.assetIndex,
        section: payload.section,
      });
    } else {
      commit('setImageUploadStatus', {
        assetType: payload.assetType,
        status: FILE_UPLOADING_STATUS.ERROR,
      });
    }
  },
  async moveToPrevioustage({ commit, state }, { projectId, libraryId, data }) {
    try {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });

      const lessonPlanSaveEesponse = await apolloClient.mutate({
        mutation: LessonPlanQueries.saveLessonPlanStageDataQuery(),
        variables: {
          lessonPlanLibraryId: parseInt(libraryId),
          projectId: projectId,
          data: JSON.parse(JSON.stringify(data.data)),
          equationEditorJsonArray: state.equationEditorJsonArray || [],
        },
      });
      if (lessonPlanSaveEesponse && lessonPlanSaveEesponse.data) {
        const validationResponse = await apolloClient.mutate({
          mutation: LessonPlanQueries.validateProjectLessonPlanStageDataQuery(projectId, libraryId),
        });
        if (
          validationResponse &&
          validationResponse.data &&
          validationResponse.data.validateProjectLessonPlanStageData &&
          validationResponse.data.validateProjectLessonPlanStageData.status == 'success'
        ) {
          const response = await apolloClient.mutate({
            mutation: LessonPlanQueries.moveProjectLessonPlanToPreviousStageQuery(
              projectId,
              libraryId
            ),
          });

          if (response && response.data) {
            Vue.notify({
              title: 'Success',
              text: 'Successfully moved the project back to Asset Authorizer',
              type: 'success',
            });
            commit('moveCurrentLessonPlanToNextStage', {
              currentStage: 'assetAuthorizingStage',
            });
          }
        } else {
          Vue.notify({
            title: 'Failed',
            text: 'Lesson Plan validation failed in back end',
            type: 'warn',
          });
        }
      }
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });
    } catch (err) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      logError(err);
      Vue.notify({
        title: 'Failed',
        text: 'Failed move lesson plan stage. Error: ' + err.message,
        type: 'warn',
      });
    }
  },
  async moveToNextStage({ commit, state }, { projectId, libraryId, data }) {
    try {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });

      const lessonPlanSaveEesponse = await apolloClient.mutate({
        mutation: LessonPlanQueries.saveLessonPlanStageDataQuery(),
        variables: {
          lessonPlanLibraryId: parseInt(libraryId),
          projectId: projectId,
          data: JSON.parse(JSON.stringify(data.data)),
          equationEditorJsonArray: state.equationEditorJsonArray || [],
        },
      });
      if (lessonPlanSaveEesponse && lessonPlanSaveEesponse.data) {
        const validationResponse = await apolloClient.mutate({
          mutation: LessonPlanQueries.validateProjectLessonPlanStageDataQuery(projectId, libraryId),
        });
        if (
          validationResponse &&
          validationResponse.data &&
          validationResponse.data.validateProjectLessonPlanStageData &&
          validationResponse.data.validateProjectLessonPlanStageData.status == 'success'
        ) {
          const response = await apolloClient.mutate({
            mutation: LessonPlanQueries.moveProjectLessonPlanToNextStageQuery(projectId, libraryId),
          });

          if (response && response.data) {
            let currentStage = response.data.moveProjectLessonPlanToNextStage.currentStage;
            commit('moveCurrentLessonPlanToNextStage', {
              currentStage,
            });
          }
        } else {
          Vue.notify({
            title: 'Failed',
            text: 'Lesson Plan validation failed in back end',
            type: 'warn',
          });
        }
      }
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });
    } catch (err) {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      logError(err);
      Vue.notify({
        title: 'Failed',
        text: 'Failed move lesson plan stage. Error: ' + err.message,
        type: 'warn',
      });
    }
  },
  reSetLessonPlanFormState({ commit }) {
    commit('reSetLessonPlanFormState');
  },
  addEquationEditorJsonArray({ commit }, payload) {
    commit('addEquationEditorJsonArray', payload);
  },
  addAnalysisSubStep({ commit }, payload) {
    commit('addAnalysisSubStep', payload);
  },
  addQuestion({ commit }) {
    commit('addQuestion');
  },
  deleteQuestion({ commit }, index) {
    commit('deleteQuestion', index);
  },
  addSupportTip({ commit, state }, payload = LIMIT.SUPPORT_TIPS) {
    if (state.data.application.support_tips.length < payload) {
      commit('addSupportTip');
    } else {
      Vue.notify({
        title: 'Failed!',
        text: `Number of items in Support Tips can only be ${payload}`,
        type: 'error',
      });
    }
  },
  deleteSupportTip({ commit }, index) {
    commit('deleteSupportTip', index);
  },
  async activateLessonPlanAndPublishToTeacherReadinessApp({ commit }, payload) {
    try {
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.inProgress,
      });
      let { projectId, libraryId } = payload;
      const response = await apolloClient.mutate({
        mutation: LessonPlanQueries.activateLessonPlanAndPublishToTeacherReadinessApp(),
        variables: {
          libraryId: parseInt(libraryId),
          projectId: projectId,
        },
      });

      if (response.data && response.data.activateLessonPlanAndPublishToTeacherReadinessApp) {
        Vue.notify({
          title: 'Success!',
          text: 'Lesson Plan activated successfully!',
          type: 'success',
        });
      }
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });
    } catch (err) {
      console.log(err);
      Vue.notify({
        title: 'Failed',
        text: 'Lesson Plan activation failed',
        type: 'error',
      });
      commit('setLessonPlanLoadingStatus', {
        status: LESSON_PLAN_LOADING_STATES.completed,
      });

      logError(err);
    }
  },
  switchComponent({ commit }, payload) {
    commit('switchComponent', payload);
  },
  updateDeepPathValue({ commit }, payload) {
    commit('updateDeepPathValue', payload);
  },
  addLayoutOrder({ commit }, payload) {
    commit('addLayoutOrder', payload);
  },
  updateSectionTitle({ commit }, payload) {
    commit('updateSectionTitle', payload);
  }
};

const _isShowImageBriefEnabled = (state) => state.showImageBrief;

const _getBriefFileName = (state, file) => {
  let fileName = file.name.split('.');
  fileName[0] = _isShowImageBriefEnabled(state) ? fileName[0] + '_brief' : fileName[0];
  return fileName.join('.');
};

export default actions;
