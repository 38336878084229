import { apolloClient } from '@/apollo';
import * as DailyPlanQueries from '@/apiQueries/dailyPlan';
import { logError } from '@/utils';
import Vue from 'vue';
import { uploadToCA } from '@/utils/CAFileUploadUtils.js';

const actions = {
  async updateDailyPlanFieldInVuex({ commit }, payload) {
    let { fieldName } = payload;
    if (['description', 'brief', 'dp_title', 'dp_description'].includes(fieldName || '')) {
      commit('updateDailyPlanFieldInVuex', payload);
    }
  },
  async getProjectDailyPlanCurrentStageData({ commit }, payload) {
    try {
      commit('setDailyPlanDataFetchApiStatus', 'in_progress');

      let { projectId, dailyPlanLibraryId } = payload;
      const response = await apolloClient.query({
        query: DailyPlanQueries.getProjectDailyPlanCurrentStageData(projectId, dailyPlanLibraryId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        commit('setDailyPlanDataFetchApiStatus', 'completed');
        commit(
          'setProjectDailyPlanCurrentStageData',
          response.data.projectDailyPlanCurrentStageData
        );
        commit('setDailyPlanBasicDetails', response.data.project);
      } else {
        commit('setProjectDailyPlanCurrentStageData', {});
      }
    } catch (er) {
      commit('setDailyPlanDataFetchApiStatus', 'error');
      commit('setProjectDailyPlanCurrentStageData', {});
      logError(er);
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong',
        type: 'error',
      });
    }
  },
  async saveProjectDailyPlanStageData({ commit }, payload) {
    try {
      commit('setDailyPlanLoadingStatus', {
        status: 'in_progress',
        message: 'Fetching early plan details, Please wait...',
      });
      let { projectId, dailyPlanLibraryId, data, onSuccess, onFailure } = payload;
      let dataCopy = JSON.parse(JSON.stringify(data));
      _cleanKeys(dataCopy, ['template', 'type']);
      const response = await apolloClient.mutate({
        mutation: DailyPlanQueries.saveProjectDailyPlanStageData(),
        variables: {
          projectId,
          dailyPlanLibraryId,
          data: dataCopy,
        },
      });

      if (response && response.data) {
        //this state doesn't need to depend on backend response as of now also
        //save response of dailyPlan currentStage data doesn't respond with template information
        commit('setDailyPlanCurrentSatgeData', data);
        commit('setDailyPlanLoadingStatus', { status: 'completed' });
        Vue.notify({
          title: 'Success!',
          text: 'Daily Plan has been saved successfully',
          type: 'success',
        });

        if (onSuccess) onSuccess();
      } else {
        if (onFailure) onFailure();
      }
    } catch (er) {
      commit('setDailyPlanLoadingStatus', { status: 'completed' });
      logError(er);
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong',
        type: 'error',
      });

      if (payload.onFailure) payload.onFailure();
    }
  },
  async vaildateEarlyPlan({ dispatch, commit }, payload) {
    let { projectId, dailyPlanLibraryId, data, onSuccess, onFailure } = payload;

    dispatch('saveProjectDailyPlanStageData', {
      projectId: projectId,
      dailyPlanLibraryId: dailyPlanLibraryId,
      data: data,
      onSuccess: async () => {
        try {
          commit('setDailyPlanLoadingStatus', {
            status: 'in_progress',
            message: 'Validating early plan details, Please wait...',
          });
          const response = await apolloClient.mutate({
            mutation: DailyPlanQueries.vaidateEarlyPlan(),
            variables: {
              projectId,
              dailyPlanLibraryId,
            },
          });

          if (response && response.data) {
            commit('setDailyPlanLoadingStatus', {
              status: 'completed',
            });
            if (response.data.validateProjectDailyPlanStageData.status == 'failure') {
              response.data.validateProjectDailyPlanStageData.errors.forEach((_) => {
                Vue.notify({
                  title: 'Failed',
                  text: _.errorMessage,
                  type: 'error',
                });
              });

              commit('setDailyPlanLoadingStatus', {
                status: 'completed',
              });

              if (onFailure) onFailure();
            } else {
              Vue.notify({
                title: 'Success!',
                text: 'Validation passed.',
                type: 'success',
              });

              if (onSuccess) onSuccess();
            }
          } else {
            if (onFailure) onFailure();
          }
        } catch (error) {
          commit('setDailyPlanLoadingStatus', {
            status: 'completed',
          });

          Vue.notify({
            title: 'Failed',
            text: error.message,
            type: 'error',
          });

          if (onFailure) onFailure();
        }
      },
      onFailure: () => {},
    });
  },
  async moveDPToNextStage({ dispatch, commit }, payload) {
    let { projectId, dailyPlanLibraryId, data } = payload;

    dispatch('vaildateEarlyPlan', {
      projectId: projectId,
      dailyPlanLibraryId: dailyPlanLibraryId,
      data: data,
      onSuccess: async function () {
        try {
          commit('setDailyPlanLoadingStatus', {
            status: 'in_progress',
            message: 'Validating early plan details, Please wait...',
          });

          const response = await apolloClient.mutate({
            mutation: DailyPlanQueries.moveDPToNextStage(),
            variables: {
              projectId,
              dailyPlanLibraryId,
            },
          });

          if (response && response.data) {
            commit('setDailyPlanLoadingStatus', {
              status: 'completed',
            });
            commit('setDailyPlanCurrentStage', response.data);

            Vue.notify({
              title: 'Success!',
              text: 'Daily Plan has been moved successfully',
              type: 'success',
            });
          }
        } catch (error) {
          commit('setDailyPlanLoadingStatus', {
            status: 'completed',
          });

          Vue.notify({
            title: 'Failed',
            text: error.message,
            type: 'error',
          });
        }
      },
      onFailure: function () {},
    });
  },
  async copyDailyPlanSingleAsset({ commit }, { path, signedUrl, imageKey, isAssetApproved }) {
    commit('importDailyPlanCoverImage', {
      id: path,
      signedUrl,
      imageKey,
      forceApproveAsset: isAssetApproved,
    });
  },
  async importDailyPlanCoverImage({ commit, dispatch }, payload) {
    try {
      commit('setCoverImageUploadingStatus', 'in_progress');
      dispatch('changeLoadingState', true);
      let response;
      response = await uploadToCA(payload.file, payload.fileName);
      commit('setCoverImageUploadingStatus', 'completed');
      if (response && response.status === 'success') {
        commit('importDailyPlanCoverImage', {
          ...response,
          imageKey: payload.imageKey,
        });
      }
    } catch (er) {
      commit('setCoverImageUploadingStatus', 'completed');
      logError(er);
    }
    dispatch('changeLoadingState', false);
  },
  async removeDailyPlanCoverImage({ commit }, payload) {
    try {
      const { assetId, projectId, imageKey, dailyPlanLibraryId } = payload;
      commit('setDailyPlanLoadingStatus', {
        status: 'in_progress',
        message: 'removing cover image, Please wait...',
      });

      const response = await apolloClient.mutate({
        mutation: DailyPlanQueries.removeDailyPlanAssetInCaForProject(),
        variables: {
          assetId: assetId,
          projectId: projectId,
          dailyPlanLibraryId: dailyPlanLibraryId,
          fieldIdentifier: imageKey,
        },
      });

      if (response && response.data && response.data.removeDailyPlanAssetInCaForProject) {
        const data = response.data.removeDailyPlanAssetInCaForProject;

        commit('importDailyPlanCoverImage', {
          id: data.assetId,
          signedUrl: data.signedUrl,
          imageKey,
        });
      }
      commit('setDailyPlanLoadingStatus', {
        status: 'completed',
      });
    } catch (er) {
      commit('setDailyPlanLoadingStatus', {
        status: 'completed',
      });
      logError(er);
    }
  },
  async downloadDPAssets({ dispatch }, payload) {
    try {
      dispatch('changeLoadingState', true);

      let { projectId, contentLibraryId } = payload;
      const response = await apolloClient.query({
        query: DailyPlanQueries.downloadCurrentStageLpAssetsQuery,
        variables: {
          contentLibraryId: parseInt(contentLibraryId),
          projectId: projectId,
        },
        fetchPolicy: 'no-cache',
      });

      if (
        response.data &&
        response.data.downloadCurrentStageLpAssets &&
        response.data.downloadCurrentStageLpAssets.status
      ) {
        Vue.notify({
          title: 'Success!',
          text: 'Daily Plan Assets will be mailed to you shortly!',
          type: 'success',
        });
      } else {
        Vue.notify({
          title: 'Failed',
          text: 'Downloading Daily Plan assets failed',
          type: 'error',
        });
      }

      dispatch('changeLoadingState', false);
    } catch (err) {
      Vue.notify({
        title: 'Failed',
        text: 'Downloading Daily Plan assets failed',
        type: 'error',
      });
      dispatch('changeLoadingState', false);

      logError(err);
    }
  },
};

const _cleanKeys = (data, keysToClear) => {
  for (let datum in data) {
    if (typeof data[datum] === 'object') {
      _cleanKeys(data[datum], keysToClear);
    } else {
      if (keysToClear.includes(datum)) {
        Vue.delete(data, datum);
      }
    }
  }
  return;
};

export default actions;
