<template>
  <div class="worksheet-wrapper">
    <v-expansion-panels multiple focusable v-model="panel">
      <v-expansion-panel class="expansion-panel" key="SummarySection">
        <v-expansion-panel-header class="section-title-bar">
          <ExpansionPanelHeader
            :sectionTitle="AppTexts.WORKSHEET"
            :displayTrailing="false"
            :toggleComments="() => toggleComments('worksheet')"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="section-title-content">
          <v-row>
            <v-col cols="6">
              <FileInput
                :canShowDiffIcon="options.canShowDiffIcon"
                :diffIdentifier="fileKey"
                :fileAcceptType="!options.isAssetUploadRequired ? options.fileAcceptType : '.pdf'"
                label="PDF"
                :loadingStatus="options.fileUploadingStatus.worksheetImage == 'in_progress'"
                :isRequired="options.isAssetUploadRequired"
                :isAssetUploadRequired="options.isAssetUploadRequired"
                :fileChangeHandler="
                  (file) => fileUploadHandler(file, 'worksheetImage', data[fileKey])
                "
                :previewUrl="options.isAssetUploadRequired ? options.signedAssetUrls.file : ''"
                :previewUrlBrief="options.signedAssetUrls.fileBrief"
                :image_url="options.isAssetUploadRequired ? data.file : ''"
                :image_url_brief="data.fileBrief"
                :isNotReadOnly="options.isNotReadOnly"
                imagePlaceholder="pdf"
                @addBriefToAsset="
                  (previewUrl, briefUrl, approveAsset) =>
                    addToAsset(previewUrl, briefUrl, approveAsset, 'worksheetImage')
                "
                :isAssetApproved="data.fileApproved"
                :canFinalizeAsset="true"
                :canAppproveAsset="canApproveAsset"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <RichTextEditor
                :readonly="options.isReadOnly || options.isAssetUploadRequired"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.brief"
                fieldIdentifier="brief"
                placeholder="brief"
                :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import FileInput from '@/components/inputs/FileInput';
import ExpansionPanelHeader from '@/components/contentEntry/oLessonComponents/ExpansionPanelHeader';
import Errors from '@/constants/Errors';
import AppTexts from '@/constants/AppTexts';
import RichTextEditor from '@/components/RTE/RichTextEditor';

export default {
  name: 'Worksheet',
  components: {
    ExpansionPanelHeader,
    FileInput,
    RichTextEditor,
  },
  data() {
    return {
      AppTexts,
      Errors,
      panel: [0],
      defaultFileType: 'pdf',
    };
  },
  created() {
    if (typeof this.data.brief == 'undefined') {
      this.$set(this.data, 'brief', '');
      this.currentStageDataCopy.brief = '';
    }
  },
  computed: {
    fileKey() {
      return this.options.isAssetUploadRequired ? 'file' : 'fileBrief';
    },
  },
  methods: {
    fileUploadHandler(file, type, path) {
      if (file == null) {
        this.fileChangeHandler(file, type, path);
        return true;
      }
      let fileType = file.name.split('.').pop();
      if (this.options.isAssetUploadRequired && this.defaultFileType != fileType) {
        this.$notify({
          title: 'Error!',
          text: 'File format not supported, please upload a PDF file',
          type: 'error',
          clean: false,
          duration: -1,
        });
      } else {
        this.fileChangeHandler(file, type, path);
      }
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canApproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
