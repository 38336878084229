var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"fileInputForm"},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"file-input-row",staticStyle:{"align-items":"baseline"},attrs:{"v-bind":index}},[_c('v-row',[_c('v-col',{staticClass:"pad-Rm"},[(item.text != null && item.text != undefined)?_c('RichTextEditor',{key:index + 'name',staticClass:"text-field-text",attrs:{"canShowDiffIcon":_vm.options.canShowDiffIcon,"isDiffChanged":_vm.isResourceTextChanged(index),"fieldIdentifier":'aim.resources[' + index + '].text',"placeholder":_vm.placeholder,"showValidationError":true,"counter":50,"readonly":!_vm.options.isNotReadOnly,"includeOnlyToolBarActions":_vm.BASIC_TOOLS},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}}):_vm._e()],1),_c('v-col',{staticClass:"pad-Lm",attrs:{"cols":"5"}},[_c('FileInput',{attrs:{"canShowDiffIcon":_vm.options.canShowDiffIcon,"isDiffChanged":_vm.isResourceImageChanged(index),"diffIdentifier":'aim.resources[' + index + '].' + _vm.imageKey,"image_url":_vm.options.isAssetUploadRequired ? item.imageUrl : '',"image_url_brief":item.briefUrl,"previewUrl":_vm.options.isAssetUploadRequired
              ? _vm.options.signedAssetUrls.aim.resources[index].imageUrl
              : '',"previewUrlBrief":_vm.options.signedAssetUrls.aim.resources[index].briefUrl,"fileAcceptType":_vm.options.fileAcceptType,"label":_vm.label,"loadingStatus":_vm.options.fileUploadingStatus.resources[index] == 'in_progress',"isRequired":_vm.options.isRequired,"isAssetUploadRequired":_vm.options.isAssetUploadRequired,"fileChangeHandler":function (file) { return _vm.fileUploadHandler(
                file,
                'resources.' + index,
                _vm.options.isAssetUploadRequired ? item.imageUrl : item.briefUrl,
                index
              ); },"isNotReadOnly":_vm.options.isNotReadOnly,"canFinalizeAsset":true,"canAppproveAsset":_vm.canAppproveAsset,"isAssetApproved":item.imageUrlApproved},on:{"addBriefToAsset":function (previewUrl, briefUrl, approveAsset) { return _vm.addToAsset(previewUrl, briefUrl, approveAsset, 'resources.' + index, index); }}}),_c('br'),_c('RichTextEditor',{key:index + 'oprogram_Resources_brief',attrs:{"readonly":!_vm.options.isNotReadOnly || _vm.options.isAssetUploadRequired,"canShowDiffIcon":_vm.options.canShowDiffIcon,"fieldIdentifier":'aim.resources[' + index + '].brief',"placeholder":_vm.AppTexts.RESOURCE_IMAGE_BRIEF,"counter":3000,"allowWordCount":false,"allowCharacterCount":true,"includeOnlyToolBarActions":_vm.allowedToolBarActionWithTablesForRTE,"showValidationError":(!_vm.options.isAssetUploadRequired && _vm.options.validationFlag)},model:{value:(item.brief),callback:function ($$v) {_vm.$set(item, "brief", $$v)},expression:"item.brief"}})],1),_c('v-col',{attrs:{"cols":"1"}},[(_vm.options.isNotReadOnly)?_c('v-btn',{staticStyle:{"padding-top":"12px"},attrs:{"icon":"","title":"Remove Resource"},on:{"click":function($event){return _vm.deleteResource(index)}}},[_c('img',{attrs:{"height":"16","width":"16","src":require("@/assets/close.svg")}})]):_vm._e()],1)],1)],1)}),_c('p',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.withAdd),expression:"withAdd"}],staticClass:"xs-accent-button",class:!_vm.canAddResouce() ? 'disable-btn-click-event' : '',on:{"click":_vm.addResource}},[_vm._v(" "+_vm._s(_vm.AppTexts.ADD_RESOURCE)+" ")])],1),_c('div',[(_vm.canAddResouce())?_c('p',{staticClass:"mgn-Lm remaining-step-warning-text",staticStyle:{"margin-bottom":"0","padding-top":"1px"}},[_vm._v(" "+_vm._s(_vm.getStepAdditionValidationText(_vm.resourseMaxLength - _vm.items.length, 'resource'))+" ")]):_vm._e()])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }