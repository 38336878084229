<template>
  <div :style="`padding: 20px 0px; margin: 0px ${horizontalMargin}px`">
    <v-divider />
  </div>
</template>
<script>
export default {
  props: {
    horizontalMargin: {
      type: String,
      default: '-23',
      required: false
    }
  }
};
</script>
