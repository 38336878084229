<template>
  <div :id="`adobe-dc-view-${generateIdentifier()}`" style="height: 550px; display: block"></div>
</template>
<script>
import path from 'path';
import url from 'url';
export default {
  data() {
    return {
      pdfIdentifier: '',
      pdfApiReady: false,
      adobeDCView: null,
    };
  },
  props: {
    src: {
      type: String,
      require: true,
    },
  },
  watch: {
    pdfApiReady(val) {
      if (val) {
        // eslint-disable-next-line
        this.adobeDCView = new AdobeDC.View({
          clientId: process.env.VUE_APP_ADOBE_DC_VIEW_CLIENT_ID,
          divId: `adobe-dc-view-${this.generateIdentifier()}`,
        });
        this.viewPdf();
      }
    },
  },
  methods: {
    generateIdentifier() {
      if (!this.pdfIdentifier.length) {
        this.pdfIdentifier = 'pdf-input' + Math.random().toString(36).slice(2);
      }
      return this.pdfIdentifier;
    },
    viewPdf() {
      let viewer = this.adobeDCView;
      viewer.previewFile(
        {
          content: { location: { url: this.src } },
          metaData: { fileName: path.basename(url.parse(this.src).pathname) },
        },
        { showAnnotationTools: false, showLeftHandPanel: false }
      );
    },
  },
  created() {
    console.log(this.src);
    if (window.AdobeDC) this.pdfApiReady = true;
  },
};
</script>
