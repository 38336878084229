<template>
  <v-form ref="fileInputForm" class="mgn-Ls">
    <v-row style="align-items: baseline;" class="file-input-row">
      <v-col
        cols="5"
        v-for="(item, index) in items"
        :v-bind="index"
        :key="'multipleAssetUploaderKey' + index"
        style="margin-right: 30px;"
      >
        <v-row>
          <v-col>
            <TextInput
              :canShowDiffIcon="false"
              v-if="item.description != null && item.description != undefined"
              :placeholder="placeholder"
              class="text-field-text"
              v-model="item.description"
              customClass
              :readonly="!isNotReadOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              @click="deleteAssetAction({ index, assetType })"
              :title="AppTexts.REMOVE_IMAGE"
              v-if="isNotReadOnly"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11">
            <FileInput
              :canShowDiffIcon="false"
              :fileAcceptType="acceptableFileType"
              :label="label"
              :loadingStatus="
                assetsSignedUrls[index].loadingStatus == FILE_UPLOADING_STATUS.IN_PROGRESS
              "
              :isRequired="isRequired"
              :isAssetUploadRequired="isAssetUploadRequired"
              :fileChangeHandler="
                (file) =>
                  importFile(
                    file,
                    index,
                    isAssetUploadRequired ? item.assetId : item.brief.assetId,
                    assetType
                  )
              "
              @addBriefToAsset="
                (previewUrl, briefUrl, approveAsset) =>
                  copyFile(previewUrl, briefUrl, index, assetType, approveAsset)
              "
              :image_url="isAssetUploadRequired ? item.assetId : ''"
              :image_url_brief="item.brief.assetId"
              :previewUrl="isAssetUploadRequired ? assetsSignedUrls[index].assetSignedUrl : ''"
              :previewUrlBrief="assetsSignedUrls[index].brief.assetSignedUrl"
              :isNotReadOnly="isNotReadOnly"
              :fileValidators="fileValidators"
              :isAssetApproved="item.assetApproved"
              :canFinalizeAsset="canFinalizeAsset"
              :canAppproveAsset="canApproveAsset"
              :diffIdentifier="`${diffIdentifierPrefix}[${index}].assetId`"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11">
            <RichTextEditor
              :key="index + '_brief'"
              :canShowDiffIcon="false"
              :placeholder="AppTexts.REFERENCE_IMAGE_BRIEF"
              v-model="item.brief.text"
              :showValidationError="true"
              v-if="showImageBrief"
              :counter="3000"
              :readonly="!isNotReadOnly"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </v-col>
        </v-row>
      </v-col>
      <p v-if="error && error.length" class="error-message">{{ error }}</p>
      <v-btn
        class="xs-accent-button"
        @click="addAssetAction(assetType)"
        dense
        :class="!isNotReadOnly ? 'disable-btn-click-event' : ''"
      >
        <img class="mgn-Rs" width="21" height="19" src="@/assets/imagecolor.svg" />
        {{ AppTexts.ADD_ASSET }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import Errors from '@/constants/Errors';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import { getFileNameFromUrl } from '@/functions/global';
import FileInput from '@/components/inputs/FileInput';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import { mapActions } from 'vuex';
import TextInput from '@/components/inputs/TextInput';

export default {
  name: 'MultipleAssetUploader',
  components: {
    FileInput,
    RichTextEditor,
    TextInput,
  },
  data() {
    return {
      Errors,
      AppTexts,
      FILE_UPLOADING_STATUS,
      BASIC_TOOLS,
    };
  },
  methods: {
    ...mapActions(['copyAnalysisExplanationAssetAction']),
    getFileNameFromUrl,
    importFile(file, index, url, assetType) {
      if (file) {
        this.uploadAssetAction({
          file,
          assetType,
          resourceIndex: index,
        });
      } else {
        this.deleteAssetPathAction({
          url,
          assetType,
          resourceIndex: index,
        });
      }
    },
    copyFile(previewUrl, briefUrl, index, assetType, approveAsset) {
      this.copyAnalysisExplanationAssetAction({
        previewUrl,
        briefUrl,
        index,
        assetType,
        approveAsset,
        path: this.subPath,
      });
    },
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    addAssetAction: {
      type: Function,
      required: true,
    },
    deleteAssetAction: {
      type: Function,
      required: true,
    },
    uploadAssetAction: {
      type: Function,
      required: true,
    },
    deleteAssetPathAction: {
      type: Function,
      required: true,
    },
    assetType: {
      type: String,
      required: true,
      default: '',
    },
    withAdd: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    showImageBrief: {
      type: Boolean,
      required: true,
      default: false,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    assetsSignedUrls: {
      type: Array,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canFinalizeAsset: {
      type: Boolean,
      required: false,
      default: false,
    },
    canApproveAsset: { type: Boolean, required: false, default: false },
    diffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    subPath: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
<style scoped>
.error-message {
  margin: 10px;
  color: red;
}
</style>
