<template>
  <v-form ref="analyseSectionForm" class="section-title-cnr">
    <!-- ACTIVITY_DISCUSSION BLOCK -->
    <BlockTitleWithSubtitle title="Part 1" :subTitle="AppTexts.ACTIVITY_DISCUSSION" />
    <div class="mgn-Tm mgn-Ls">
      <div class="mgn-Bs">{{ analysisBockStaticTexts.activity_discussion.paragraph_text }}</div>
      <div class="mgn-Bs">{{ AppTexts.QUESTION_FOR_THE_STUDENTS_TEXT }}</div>
    </div>
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiscussionKeyQuestionChanged('activity_discussion')"
      fieldIdentifier="analysis.activity_discussion.key_question"
      :placeholder="AppTexts.KEY_QUESTION"
      :showValidationError="true"
      v-model="lessonPlanData.analysis.activity_discussion.key_question"
      :readonly="!isNotReadOnly"
      :counter="200"
      class="mgn-Bm"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />

    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiscussionKeyQuestionGuideChanged('activity_discussion')"
      fieldIdentifier="analysis.activity_discussion.key_question_guide"
      :placeholder="AppTexts.KEY_QUESTION_GUIDE"
      :showValidationError="true"
      v-model="lessonPlanData.analysis.activity_discussion.key_question_guide"
      :readonly="!isNotReadOnly"
      :counter="160"
      class="mgn-Bm"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <BlockTitle class="analysis-explanation-title" :title="AppTexts.ACTIVITY_EXPLANATION" />
    <Step
      :items="lessonPlanData.analysis.activity_discussion.instructions"
      :addNewStepHandler="addAnalysisActivityDiscussionStep"
      :descriptionPlaceholder="AppTexts.EXPLANATION"
      :canShowAddNewStepButton="true"
      :hasSubStep="false"
      :allowDeleteStep="true"
      :deleteStepHandler="deleteAnalysisActivityDiscussionStep"
      :maxNoOfSteps="4"
      :canShowStepBullet="true"
      :canShowDiffIcon="canShowDiffIcon"
      stepDiffIdentifier="analysis.activity_discussion.instructions[<stepIndex>].description"
      :isStepDescriptionChanged="isADStepDescriptionChanged"
      class="analysis-explanation-step"
      :isNotReadOnly="isNotReadOnly"
    />
    <v-divider class="mgn-Ts mgn-Bs" />
    <MultipleAssetUploader
      :items="lessonPlanData.analysis.activity_discussion.explanations"
      :addAssetAction="addAnaysisExplanationAssets"
      :deleteAssetAction="deleteAnaysisExplanationAssets"
      :canShowDiffIcon="canShowDiffIcon"
      :itemDiff="getExaplanationDiff('activity_discussion')"
      diffIdentifierPrefix="analysis.activity_discussion.explanations"
      :assetType="AssetTypes.ACTIVITY_EXPLANATION_ASSET_TYPE"
      withAdd
      :uploadAssetAction="importAnalysisExplanationAssetAction"
      :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
      :placeholder="AppTexts.TITLE"
      :isRequired="false"
      :label="AppTexts.SELECT_FILE"
      :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :acceptableFileType="acceptableFileType"
      :assetsSignedUrls="analysisSignedUrls.activityDiscussion.explanations"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="true"
      :canApproveAsset="canApproveAsset"
    />
    <div class="mgn-Ts">
      <XSDisplayCard :compact="true" :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiscussionTeacherTipGuideChanged('activity_discussion')"
          fieldIdentifier="analysis.activity_discussion.teacher_tip"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="lessonPlanData.analysis.activity_discussion.teacher_tip"
          :counter="220"
          :readonly="!isNotReadOnly"
          class="mgn-Bm"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </XSDisplayCard>
    </div>

    <!-- GENERALIZED_DISCUSSION BLOCK -->
    <br />
    <div v-if="lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE">
      <BlockTitleWithSubtitle title="Part 2" :subTitle="AppTexts.GENERALIZED_DISCUSSION" />
      <div class="mgn-Tm mgn-Ls">
        <div class="mgn-Bs">
          {{ analysisBockStaticTexts.generalized_discussion.paragraph_text }}
        </div>
        <div class="mgn-Bs">{{ AppTexts.QUESTION_FOR_THE_STUDENTS_TEXT }}</div>
      </div>
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDiscussionKeyQuestionChanged('generalized_discussion')"
        fieldIdentifier="analysis.generalized_discussion.key_question"
        :placeholder="AppTexts.KEY_QUESTION"
        :showValidationError="true"
        v-model="lessonPlanData.analysis.generalized_discussion.key_question"
        :readonly="!isNotReadOnly"
        :counter="200"
        class="mgn-Bm"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDiscussionKeyQuestionGuideChanged('generalized_discussion')"
        fieldIdentifier="analysis.generalized_discussion.key_question_guide"
        :placeholder="AppTexts.KEY_QUESTION_GUIDE"
        :showValidationError="true"
        v-model="lessonPlanData.analysis.generalized_discussion.key_question_guide"
        :readonly="!isNotReadOnly"
        :counter="160"
        class="mgn-Bm"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <BlockTitle :title="AppTexts.GENERALIZED_EXPLANATION" />
      <Step
        :items="lessonPlanData.analysis.generalized_discussion.instructions"
        :canShowAddNewStepButton="true"
        :addNewStepHandler="addAnalysisGeneralizedDiscussionStep"
        :allowDeleteStep="true"
        :deleteStepHandler="deleteAnalysisGeneralizedDiscussionStep"
        :descriptionPlaceholder="AppTexts.EXPLANATION"
        :maxNoOfSteps="4"
        :canShowStepBullet="true"
        :canShowDiffIcon="canShowDiffIcon"
        stepDiffIdentifier="analysis.generalized_discussion.instructions[<stepIndex>].description"
        :isStepDescriptionChanged="isGDStepDescriptionChanged"
        class="analysis-explanation-step"
        :isNotReadOnly="isNotReadOnly"
      />
      <v-divider class="mgn-Ts mgn-Bs" />
      <MultipleAssetUploader
        :items="lessonPlanData.analysis.generalized_discussion.explanations"
        :addAssetAction="addAnaysisExplanationAssets"
        :deleteAssetAction="deleteAnaysisExplanationAssets"
        :canShowDiffIcon="canShowDiffIcon"
        :itemDiff="getExaplanationDiff('generalized_discussion')"
        diffIdentifierPrefix="analysis.generalized_discussion.explanations"
        :assetType="AssetTypes.GENERALIZED_EXPLANATION_ASSET_TYPE"
        withAdd
        :uploadAssetAction="importAnalysisExplanationAssetAction"
        :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
        :placeholder="AppTexts.TITLE"
        :isRequired="false"
        :label="AppTexts.SELECT_FILE"
        :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
        :showImageBrief="showImageBrief"
        :isAssetUploadRequired="isAssetUploadRequired"
        :acceptableFileType="acceptableFileType"
        :assetsSignedUrls="analysisSignedUrls.generalizedDiscussion.explanations"
        :isNotReadOnly="isNotReadOnly"
        :fileValidators="fileValidators"
        :canFinalizeAsset="true"
        :canApproveAsset="canApproveAsset"
      />
      <div class="mgn-Ts">
        <XSDisplayCard :title="AppTexts.TEACHER_TIP">
          <RichTextEditor
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isDiscussionTeacherTipGuideChanged('generalized_discussion')"
            fieldIdentifier="analysis.generalized_discussion.teacher_tip"
            :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
            v-model="lessonPlanData.analysis.generalized_discussion.teacher_tip"
            :counter="220"
            :readonly="!isNotReadOnly"
            class="mgn-Bm"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </XSDisplayCard>
      </div>
    </div>

    <!-- HIGHER_ORDER_THINKING BLOCK -->
    <br />
    <div v-if="lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE">
      <BlockTitleWithSubtitle title="Part 3" :subTitle="AppTexts.HIGHER_ORDER_THINKING" />
      <div class="mgn-Tm mgn-Ls">
        <div class="mgn-Bs">{{ analysisBockStaticTexts.higher_order_thinking.paragraph_text }}</div>
        <div class="mgn-Bs">{{ AppTexts.QUESTION_FOR_THE_STUDENTS_TEXT }}</div>
      </div>

      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDiscussionKeyQuestionGuideChanged('higher_order_thinking')"
        fieldIdentifier="analysis.higher_order_thinking.key_question"
        :placeholder="AppTexts.KEY_QUESTION"
        :showValidationError="true"
        v-model="lessonPlanData.analysis.higher_order_thinking.key_question"
        :readonly="!isNotReadOnly"
        :counter="200"
        class="mgn-Bm"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDiscussionKeyQuestionChanged('higher_order_thinking')"
        fieldIdentifier="analysis.higher_order_thinking.key_question_guide"
        :placeholder="AppTexts.KEY_QUESTION_GUIDE"
        :showValidationError="true"
        v-model="lessonPlanData.analysis.higher_order_thinking.key_question_guide"
        :readonly="!isNotReadOnly"
        :counter="160"
        class="mgn-Bm"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <BlockTitle :title="AppTexts.HIGHER_ORDER_THINKING_EXPLANATION" />
      <Step
        :items="lessonPlanData.analysis.higher_order_thinking.instructions"
        :canShowAddNewStepButton="true"
        :addNewStepHandler="addAnalysisHigherOrderStep"
        :allowDeleteStep="true"
        :deleteStepHandler="deleteAnalysisHigherOrderStep"
        :descriptionPlaceholder="AppTexts.EXPLANATION"
        :maxNoOfSteps="4"
        :canShowStepBullet="true"
        :canShowDiffIcon="canShowDiffIcon"
        stepDiffIdentifier="analysis.higher_order_thinking.instructions[<stepIndex>].description"
        :isStepDescriptionChanged="isHOTSStepDescriptionChanged"
        class="analysis-explanation-step"
        :isNotReadOnly="isNotReadOnly"
      />
      <v-divider class="mgn-Ts mgn-Bs" />
      <MultipleAssetUploader
        :items="lessonPlanData.analysis.higher_order_thinking.explanations"
        :addAssetAction="addAnaysisExplanationAssets"
        :deleteAssetAction="deleteAnaysisExplanationAssets"
        :canShowDiffIcon="canShowDiffIcon"
        :itemDiff="getExaplanationDiff('higher_order_thinking')"
        diffIdentifierPrefix="analysis.higher_order_thinking.explanations"
        :assetType="AssetTypes.HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE"
        withAdd
        :uploadAssetAction="importAnalysisExplanationAssetAction"
        :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
        :placeholder="AppTexts.TITLE"
        :isRequired="false"
        :label="AppTexts.SELECT_FILE"
        :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
        :showImageBrief="showImageBrief"
        :isAssetUploadRequired="isAssetUploadRequired"
        :acceptableFileType="acceptableFileType"
        :assetsSignedUrls="analysisSignedUrls.higherOrderThinking.explanations"
        :isNotReadOnly="isNotReadOnly"
        :fileValidators="fileValidators"
        :canFinalizeAsset="true"
        :canApproveAsset="canApproveAsset"
      />
      <div class="mgn-Ts mgn-Bs">
        <XSDisplayCard :compact="true" :title="AppTexts.TEACHER_TIP">
          <RichTextEditor
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isDiscussionTeacherTipGuideChanged('higher_order_thinking')"
            fieldIdentifier="analysis.higher_order_thinking.teacher_tip"
            :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
            v-model="lessonPlanData.analysis.higher_order_thinking.teacher_tip"
            :counter="220"
            :readonly="!isNotReadOnly"
            class="mgn-Bm"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </XSDisplayCard>
      </div>
    </div>
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Step from '@/components/inputs/Step';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { ANALYSIS_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import MultipleAssetUploader from '@/components/inputs/MultipleAssetUploader';
import AssetTypes from '@/constants/AssetTypes';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import BlockTitle from '@/components/inputs/BlockTitle';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import { LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE } from '@/constants/LpForm';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';

export default {
  name: 'AnalyseSection',
  components: {
    Step,
    BlockTitleWithSubtitle,
    BlockTitle,
    XSDisplayCard,
    MultipleAssetUploader,
    RichTextEditor
  },
  data() {
    return {
      AppTexts,
      LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE,
      AssetTypes,
      Errors,
      ANALYSIS_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      BASIC_TOOLS,
    };
  },
  methods: {
    ...mapActions(['importAnalysisExplanationAssetAction', 'deleteAnalysisExplanationAssetAction']),
    getFileNameFromUrl,
    isDiscussionKeyQuestionChanged(discussionName) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff[discussionName].key_question.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDiscussionKeyQuestionGuideChanged(discussionName) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff[discussionName].key_question_guide.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDiscussionTeacherTipGuideChanged(discussionName) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff[discussionName].teacher_tip.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isADStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff.activity_discussion.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isGDStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff.generalized_discussion.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isHOTSStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff.higher_order_thinking.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getExaplanationDiff(discussionName) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff[discussionName].explanation;
        }
      } catch (error) {
        return {};
      }

      return {};
    },
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    addAnalysisActivityDiscussionStep: {
      type: Function,
      required: true,
    },
    deleteAnalysisActivityDiscussionStep: {
      type: Function,
      required: true,
    },
    deleteAnalysisGeneralizedDiscussionStep: {
      type: Function,
      required: true,
    },
    addAnalysisGeneralizedDiscussionStep: {
      type: Function,
      required: true,
    },
    deleteAnalysisHigherOrderStep: {
      type: Function,
      required: true,
    },
    addAnalysisHigherOrderStep: {
      type: Function,
      required: true,
    },
    addAnaysisExplanationAssets: {
      type: Function,
      required: true,
    },
    deleteAnaysisExplanationAssets: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    analysisSignedUrls: {
      type: Object,
      required: true,
    },
    analysisDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    lessonPlanFormRendererType: {
      type: String,
      required: false,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
  canApproveAsset: { type: Boolean, required: true },
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      analysisBockStaticTexts: (state) => state.lessonPlan.staticTexts.analysis,
    }),
  },
};
</script>
