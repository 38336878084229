<template>
  <div class="analysis-program-wrapper">
    <!--Analysis Program 1 -->
    <v-expansion-panel v-if="schema.analysisProgram1.show && showProgram1" class="expansion-panel"
      key="AnalysisProgram1Section">
      <v-expansion-panel-header class="section-title-bar">
        <ExpansionPanelHeader
          :sectionTitle="lessonPlanData.analysisProgram1.sectionTitle || AppTexts.ANALYSIS_PROGRAM_1" :types="lessonPlanData && lessonPlanData.analysisProgram1
            ? lessonPlanData.analysisProgram1.types
            : []
            " :time="lessonPlanData && lessonPlanData.analysisProgram1
            ? lessonPlanData.analysisProgram1.time
            : ''
            " :canEditType="auth.canSaveProjectLP" @update-type-of-block-to-state="(val) => updateTypeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_1, val)
              " :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_1, e)"
          :isNotReadOnly="auth.canSaveProjectLP" :displayTime="true" :canEditTime="auth.canSaveProjectLP"
          @set-timer-input="(val) => updateTimeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_1, val)
            " :allowedTimeValues="schema.analysisProgram1.allowedTimes" :schema="schema">
          <template #section-title>
            <v-btn text @click.native.stop :ripple="false">
              <v-switch v-model="analysisTitle1Switch" class="ml-3 align-center-switch" hide-details
                @change="() => changeAnalysis1Title()"></v-switch>
            </v-btn>
          </template>
        </ExpansionPanelHeader>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-title-content">
        <XSRightSwitch :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_PROGRAM_LEGACY_DATA"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION" subPath="analysisProgram1"
          :initialActive="isSlideLayoutEnabled('analysisProgram1.instructions.0', lessonPlanData) || !keysOtherThanLayout('analysisProgram1', lessonPlanData, 'instructions', ['types', 'summary', 'time', 'type', 'summaryData', 'show', 'sectionTitle'])"
          :showLegacyComponentSwitcher="schema.analysisProgram1.showLegacyComponentSwitcher"
          :ignoreKeys="['types', 'summary', 'time', 'type', 'summaryData', 'show', 'sectionTitle']">
          <template #true-slot>
            <MultiSlideLayout :isNotReadOnly="auth.canSaveProjectLP" subPath="analysisProgram1.instructions"
              :lessonPlanData="lessonPlanData" :isAssetUploadRequired="isAssetUploadRequired"
              :canAppproveAsset="canAppproveAsset" :signedUrls="signedUrls" :canShowDiffIcon="canShowDiffIcon"
              :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.analysisProgram1.configurableMultiSlide.maxSlidesCount" />
          </template>
          <template #false-slot>
            <GenericAnalysisSection :schema="schema.analysisProgram1" :lessonPlanData="lessonPlanData"
              :canShowDiffIcon="canShowDiffIcon" :isNotReadOnly="auth.canSaveProjectLP" :signedUrls="signedUrls"
              :fileValidators="fileValidators" :canAppproveAsset="canAppproveAsset"
              :isAssetUploadRequired="isAssetUploadRequired" :acceptableFileType="acceptableFileType"
              :showImageBrief="showImageBrief" />
          </template>
        </XSRightSwitch>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!--Analysis Program 1 END -->

    <!--Analysis Program 2 -->
    <v-expansion-panel v-if="schema.analysisProgram2.show && showProgram2" class="expansion-panel"
      key="AnalysisProgram1Section">
      <v-expansion-panel-header class="section-title-bar">
        <ExpansionPanelHeader
          :sectionTitle="lessonPlanData.analysisProgram2.sectionTitle || AppTexts.ANALYSIS_PROGRAM_2" :types="lessonPlanData && lessonPlanData.analysisProgram2
            ? lessonPlanData.analysisProgram2.types
            : []
            " :time="lessonPlanData && lessonPlanData.analysisProgram2
            ? lessonPlanData.analysisProgram2.time
            : ''
            " :canEditType="auth.canSaveProjectLP" @update-type-of-block-to-state="(val) => updateTypeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_2, val)
              " :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_2, e)"
          :isNotReadOnly="auth.canSaveProjectLP" :displayTime="lessonPlanData.analysisProgram2.show"
          :canEditTime="auth.canSaveProjectLP" @set-timer-input="(val) => updateTimeOfBlockToState(COMMENT_SECTION_NAMES_HASH.ANALYSIS_PROGRAM_2, val)
            " :allowedTimeValues="schema.analysisProgram2.allowedTimes" :schema="schema"
          :initialHeaderCheckValue="lessonPlanData.analysisProgram2.show"
          @headerCheckboxChange="(val) => (lessonPlanData.analysisProgram2.show = val)"
          :displayTrailing="lessonPlanData.analysisProgram2.show"
          :displayComments="lessonPlanData.analysisProgram2.show" :headerCheck="true">
          <template #section-title>
            <v-btn text @click.native.stop :ripple="false">
              <v-switch v-model="analysisTitle2Switch" class="ml-3 align-center-switch" hide-details
                @change="() => changeAnalysis2Title()"></v-switch>
            </v-btn>
          </template>
        </ExpansionPanelHeader>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-title-content" v-if="lessonPlanData.analysisProgram2.show">
        <XSRightSwitch :primaryDs="COMPONENT_TO_DS_MAP.ANALYSIS_PROGRAM_LEGACY_DATA"
          :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION" subPath="analysisProgram2"
          :initialActive="isSlideLayoutEnabled('analysisProgram2.instructions.0', lessonPlanData) || !keysOtherThanLayout('analysisProgram2', lessonPlanData, 'instructions', ['types', 'summary', 'time', 'type', 'summaryData', 'show', 'sectionTitle'])"
          :showLegacyComponentSwitcher="schema.analysisProgram2.showLegacyComponentSwitcher"
          :ignoreKeys="['types', 'summary', 'time', 'type', 'summaryData', 'show', 'sectionTitle']">
          <template #true-slot>
            <MultiSlideLayout :isNotReadOnly="auth.canSaveProjectLP && lessonPlanData.analysisProgram2.show"
              subPath="analysisProgram2.instructions" :lessonPlanData="lessonPlanData"
              :isAssetUploadRequired="isAssetUploadRequired" :canAppproveAsset="canAppproveAsset"
              :signedUrls="signedUrls" :canShowDiffIcon="canShowDiffIcon" :showImageBrief="showImageBrief"
              :maxSlidesCount="schema.analysisProgram2.configurableMultiSlide.maxSlidesCount" />
          </template>
          <template #false-slot>
            <GenericAnalysisSection :schema="schema.analysisProgram2" :lessonPlanData="lessonPlanData"
              :canShowDiffIcon="canShowDiffIcon"
              :isNotReadOnly="auth.canSaveProjectLP && lessonPlanData.analysisProgram2.show" :signedUrls="signedUrls"
              :fileValidators="fileValidators" :canAppproveAsset="canAppproveAsset"
              :isAssetUploadRequired="isAssetUploadRequired" :acceptableFileType="acceptableFileType"
              :showImageBrief="showImageBrief" />
          </template>
        </XSRightSwitch>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!--Analysis Program 2 END -->
  </div>
</template>
<script>
import AppTexts from '@/constants/AppTexts';
import AssetTypes from '@/constants/AssetTypes';
import Errors from '@/constants/Errors';
import { ACTION_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import ExpansionPanelHeader from '@/components/lessonPlanTemplate/ExpansionPanelHeader';
import GenericAnalysisSection from '@/components/lessonPlanTemplate/commonLpComponents/GenericAnalysisDiscussion';
import { COMMENT_SECTION_NAMES_HASH } from '@/constants/LessonPlan';
import XSRightSwitch from '@/components/lessonPlanTemplate/commonLpComponents/XSRightSwitch.vue';
import { COMPONENT_TO_DS_MAP } from '@/constants/componentDataStructure.js';
import { isSlideLayoutEnabled, keysOtherThanLayout } from '@/utils/slideLayoutHelpers.js';
import MultiSlideLayout from '@/components/lessonPlanTemplate/commonLpComponents/MultiSlideLayout';
import { mapActions } from 'vuex';
export default {
  name: 'AnalysisProgram',
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ACTION_BLOCK_KEY_OF_STATE_LP_DATA,
      BASIC_TOOLS,
      FILE_UPLOADING_STATUS,
      COMMENT_SECTION_NAMES_HASH,
      COMPONENT_TO_DS_MAP,
      analysisTitle1Switch: false,
      analysisTitle2Switch: false,
    };
  },
  watch: {
    "lessonPlanData.analysisProgram1": {
      deep: true,
      immediate: true,
      handler() {
        if (this.isSlideLayoutEnabled('analysisProgram1.instructions.0', this.lessonPlanData) || !keysOtherThanLayout('analysisProgram1', this.lessonPlanData, 'instructions', ['types', 'summary', 'time', 'type', 'summaryData', 'show', 'sectionTitle'])) {
          if (Array.isArray(this.lessonPlanData.analysisProgram1.instructions) && this.lessonPlanData.analysisProgram1.instructions.length == 0) {
            this.lessonPlanData.analysis.summary = ''
            this.schema.analysis.showSummarySection = false
            this.schema.analysis.isSummaryOptional = true
            return
          } 
        }
        this.schema.analysis.showSummarySection = true
        this.schema.analysis.isSummaryOptional = false
      }
    }
  },
  components: {
    ExpansionPanelHeader,
    GenericAnalysisSection,
    XSRightSwitch,
    MultiSlideLayout,
  },
  methods: {
    isSlideLayoutEnabled,
    keysOtherThanLayout,
    ...mapActions(['updateSectionTitle']),
    changeAnalysis1Title() {
      if (this.lessonPlanData.analysisProgram1.sectionTitle == this.AppTexts.ANALYSIS_PROGRAM_1) {
        this.updateSectionTitle({
          path: 'analysisProgram1.sectionTitle',
          title: this.AppTexts.ANALYSIS_PROJECT_1
        })
      } else {
        this.updateSectionTitle({
          path: 'analysisProgram1.sectionTitle',
          title: this.AppTexts.ANALYSIS_PROGRAM_1
        })
      }
    },
    changeAnalysis2Title() {
      if (this.lessonPlanData.analysisProgram2.sectionTitle == this.AppTexts.ANALYSIS_PROGRAM_2) {
        this.updateSectionTitle({
          path: 'analysisProgram2.sectionTitle',
          title: this.AppTexts.ANALYSIS_PROJECT_2
        })
      } else {
        this.updateSectionTitle({
          path: 'analysisProgram2.sectionTitle',
          title: this.AppTexts.ANALYSIS_PROGRAM_2
        })
      }
    }
  },
  mounted() {
    if (this.lessonPlanData.analysisProgram1.sectionTitle) {
      if (this.lessonPlanData.analysisProgram1.sectionTitle == this.AppTexts.ANALYSIS_PROGRAM_1) {
        this.analysisTitle1Switch = false
      } else {
        this.analysisTitle1Switch = true
      }
    } else {
      this.updateSectionTitle({
        path: 'analysisProgram1.sectionTitle',
        title: this.AppTexts.ANALYSIS_PROGRAM_1
      })
    }

    if (this.lessonPlanData.analysisProgram2.sectionTitle) {
      if (this.lessonPlanData.analysisProgram2.sectionTitle == this.AppTexts.ANALYSIS_PROGRAM_2) {
        this.analysisTitle2Switch = false
      } else {
        this.analysisTitle2Switch = true
      }
    } else {
      this.updateSectionTitle({
        path: 'analysisProgram2.sectionTitle',
        title: this.AppTexts.ANALYSIS_PROGRAM_2
      })
    }
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    fileValidators: {
      type: Array,
      default: () => [],
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    diff: {
      type: Object,
      required: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      default: () => ({}),
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },

    showProgram1: {
      type: Boolean,
      default: false,
    },
    showProgram2: {
      type: Boolean,
      default: false,
    },
    updateTimeOfBlockToState: {
      type: Function,
      required: true,
    },
    updateTypeOfBlockToState: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
.analysis-program-wrapper {
  width: 100%;
  padding: 0;
  margin: 16px 0 0 0;
}
</style>
