import { baseMixin } from './base';
import AimSectionForDiscussionLpType from '@/components/lessonPlanTemplate/discussion/AimSectionForDiscussionLpType';
import AnalyseSectionForDiscussionLpType from '@/components/lessonPlanTemplate/discussion/AnalyseSectionForDiscussionLpType';
import ApplicationSectionForDiscussionLpType from '@/components/lessonPlanTemplate/discussion/ApplicationSectionForDiscussionLpType';
import { isRteEmpty } from '@xseededucation/rte-helper-functions';
import { validateAssetAuthorized } from '@/utils';
import { DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION } from '../../../src/constants/LessonPlan';

export const discussionLessonMixin = {
  mixins: [baseMixin],
  components: {
    AimSectionForDiscussionLpType,
    AnalyseSectionForDiscussionLpType,
    ApplicationSectionForDiscussionLpType,
  },
  data() {
    return {
      showSaveDialogPopup: false,
      Schema: {
        coverImageAllowedTimes: ['35 mins', '45 mins'],
        aim: {
          show: true,
          maxKeywordsLength: 15,
          maxResourcesLength: 15,
          keyWordDefinitionOptional: true,
          isSummaryOptional: true,
          resourceMaxLength: 15,
          summaryMaxLength: undefined,
          resourceNameMaxLength: undefined,
          showDiscussionLpTypeComponent: true,
        },
        action: {
          show: false,
        },
        assessment: {
          show: false,
        },
        analysis: {
          isSummaryOptional: true,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION,
          summaryMaxLength: undefined,
          discussionKeyQuestionMaxLength: undefined,
          discussionKeyQuestionGuideMaxLength: undefined,
          discussionStepTeacherTipMaxLength: undefined,
          discussionTeacherTipMaxLength: undefined,
          showDiscussionLpTypeComponent: true,
          showReadXSEEDContentBookCheckbox: true,
          instructionsMaxNoOfSteps: 5,
          instructionsMaxNoOfAssetsPerStep: 3,
        },
        application: {
          isHomeworkOptional: true,
          isSummaryOptional: true,
          showDifferentiationTips: true,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION,
          showDiscussionLpTypeComponent: true,
          // classworkSummaryMaxLength: undefined,
          // homeworkSummaryMaxLength: undefined,
          // neeMoreHelpDescriptionMaxLength: undefined,
          // needExtraChallengeDescriptionMaxLength: undefined,
        },
        reference: {
          show: false,
        },
        summary: {
          show: false,
        },
      },
    };
  },
  computed: {
    canShowEvaluateAndGrade() {
      return false;
    },
  },
  methods: {
    worksheetEnabled() {
      return false;
    },
    validateLPForm() {
      this.reassignFormInputs(this.$refs.form);

      if (
        this.$refs.form.validate() &&
        this.customValidations() &&
        this.assetAutorizingStageValidations() &&
        this.validateRTEText()
      ) {
        return true;
      } else {
        return false;
      }
    },
    assetAutorizingStageValidations() {
      try {
        if (this.currentStage == 'assetAuthorizingStage') {
          let unApprovedAssetCount = validateAssetAuthorized(
            this.lessonPlan.data,
            ['assetId'],
            {
              assetId: 'assetApproved',
            },
            ['brief', 'placeholder'],
            0
          );

          if (unApprovedAssetCount > 0) {
            this.$notify({
              title: 'Failed',
              text: `There are ${unApprovedAssetCount} unapproved assets`,
              type: 'error',
              duration: -1,
            });

            return false;
          }

          return true;
        } else {
          return true;
        }
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    getUnApprovedAssetSCountForInstructions(items) {
      var unApprovedAssetsCount = 0;

      for (var item of items) {
        for (var asset of item.assets) {
          if (asset.url) {
            const isApproved = asset.url_approved == true;

            if (!isApproved) unApprovedAssetsCount += 1;
          }
        }
      }

      return unApprovedAssetsCount;
    },
    customValidations() {
      try {
        let lp = this.lessonPlan;
        if (!lp) throw Error('Invalid LessonPlan');
        if (!lp.data) throw Error('Invalid LessonPlan Data');

        if (!lp.data.lp_title || !lp.data.lp_description)
          throw Error('Title or Description cannot be empty');

        const text = lp.data.application.text;
        if (text && text.length > 0) {
          let errorMessage = "Application text can't be blank!";
          if (isRteEmpty(text)) {
            throw Error(errorMessage);
          }
        }
        return true;
      } catch (exception) {
        console.log(exception);
        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });
        return false;
      }
    },
    prepareLessonPlanDataToSave() {},
    validateLPData() {
      return true;
    },
  },
};
