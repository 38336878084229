export default {
  ACRONYM_QUESTION_TYPE_MCQ: 'mcq',
  ACRONYM_QUESTION_TYPE_MSQ: 'msq',
  ACRONYM_QUESTION_TYPE_TF: 'tf',
  ACRONYM_QUESTION_TYPE_MTF: 'mtf',
  ACRONYM_QUESTION_TYPE_FIB: 'fib',
  ACRONYM_QUESTION_TYPE_ATF: 'atf',
  ACRONYM_QUESTION_TYPE_SA: 'sa',
  ACRONYM_QUESTION_TYPE_LA: 'la',

  DISPLAY_QUESTION_TYPE_MCQ: 'Multiple Choice Question',
  DISPLAY_QUESTION_TYPE_MSQ: 'Multi Select Question',
  DISPLAY_QUESTION_TYPE_TF: 'True or False Question',
  DISPLAY_QUESTION_TYPE_MTF: 'Match the following',
  DISPLAY_QUESTION_TYPE_FIB: 'Fill in the Blanks',
  DISPLAY_QUESTION_TYPE_ATF: 'Arrange the following',
  DISPLAY_QUESTION_TYPE_SA: 'Short Answer Question',
  DISPLAY_QUESTION_TYPE_LA: 'Long Answer Question',

  DIFFICULTY_EASY: 'easy',
  DIFFICULTY_MEDIUM: 'medium',
  DIFFICULTY_HARD: 'hard',

  QUESTION_PURPOSE_MICROTEST: 'microtest',
  QUESTION_PURPOSE_PRACTICE: 'practice',

  QUESTION_PURPOSE_ASSESSMENT: 'assessment',
  QUESTION_PURPOSE_WORKSHEET: 'worksheet',
};
