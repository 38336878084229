<template>
  <Padder :left="50" :right="10" :vertical="20" style="overflow: hidden;">
    <!-- Third Row -->
    <v-row>
      <SubSection title="Answer" />
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-checkbox
          v-model="data.machineScorable"
          label="Machine Scorable"
          @change="machineScorableHandler"
          :disabled="disableMachineScorableCheckBox"
        />
      </v-col>
      <v-col cols="6">
        <v-radio-group
          row
          v-model="data.questionData.freeTyping"
          :disabled="data.machineScorable == false"
          style="background: white; display: flex;"
        >
          <v-radio label="Drag And Drop" :value="false" />
          <v-radio label="Free Typing" :value="true" />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-for="(blank, index) in data.questionData.blanks" :key="index">
      <Blank
        :key="`blank-${index}`"
        :data="blank"
        :isMachineScorable="data.machineScorable"
        :index="index"
        :partialFieldIdentifier="`['${data._id}'].questionData.blanks[${index}]`"
        :deleteHandler="deleteBlankHandler"
      />
    </v-row>
    <Distractor :data="data.questionData.distractors" v-if="!data.questionData.freeTyping" />
    <XSDivider />
    <h3 class="pb-4">{{ data.machineScorable ? 'Answer Guide Text' : 'Sample Answer' }}</h3>
    <RichTextEditor
      :key="`${answerGuideTextRevision}${placeholderKey}fib-answer-guide`"
      :readonly="false"
      :canShowDiffIcon="true"
      v-model="data.questionData.answerGuideText"
      :placeholder="data.machineScorable ? 'Enter answer guide' : 'Enter sample answer'"
      :fieldIdentifier="`['${data._id}].questionData.answerGuideText`"
      projectType="question"
      :showValidationError="false"
      :includeOnlyToolBarActions="removeBlankPlugin"
      @input="onChangeAnswerGuideText"
    />
    <v-row>
      <v-col
        v-for="(image, index) in imagesInAnswerGuideText"
        :key="`imagesInAnswerGuideText${revision}${index}`"
        :cols="3"
      >
        <FileInput
          :canShowDiffIcon="false"
          :diffIdentifier="''"
          :fileAcceptType="'Image/*'"
          :label="'Image'"
          :loadingStatus="false"
          :isRequired="options.isAssetUploadRequired"
          :isAssetUploadRequired="options.isAssetUploadRequired"
          :fileChangeHandler="(file) => fileChangeHandler(file, index)"
          :previewUrl="image.attrs.src"
          :previewUrlBrief="image.attrs.briefSrc"
          :image_url="image.attrs.assetId"
          :image_url_brief="image.attrs.briefAssetId"
          :isNotReadOnly="options.isNotReadOnly"
          :canAppproveAsset="canAppproveAsset"
          :ApproveAssetAndEmitResult="true"
          :canFinalizeAsset="true"
          :useAssetVault="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, approveAsset) =>
              addToAsset(previewUrl, briefUrl, approveAsset, index)
          "
          :isAssetApproved="image.attrs.assetApproved"
          @assetApprovedByUser="(val) => approveAssets(val, index)"
        /><br />
        <RichTextEditor
          :readonly="options.isAssetUploadRequired"
          :canShowDiffIcon="false"
          v-model="image.attrs.brief"
          placeholder="Enter Image Brief"
          projectType="question"
          :showValidationError="false"
          :includeOnlyToolBarActions="[...getToolBarActionsForQuestionBriefs, 'table']"
          @input="addBriefTextAtPosition(image.attrs.brief, index)"
          :removeAllPluginExceptFullScreen="true"
          :counter="3000"
          :allowCharacterCount="true"
        />
      </v-col>
    </v-row>
  </Padder>
</template>

<script>
import Blank from '@/components/contentEntry/questionComponents/dialogComponents/fibComponents/Blank.vue';
import Distractor from '@/components/contentEntry/questionComponents/dialogComponents/fibComponents/Distractor.vue';
import SubSection from '@/components/inputs/SubSection.vue';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import FileInput from '@/components/inputs/FileInput';
import {
  getImageUrlFromRte,
  deleteImageInRTE,
  insertImageAssetAtPosition,
  approveAssetAtPosition,
  addBriefToAssetInPosition,
} from '@xseededucation/rte-helper-functions';
export default {
  components: {
    Blank,
    SubSection,
    Distractor,
    RichTextEditor,
    FileInput,
  },
  watch: {
    'data.questionPurpose': {
      handler(val) {
        if (val == questionsOprogramProject.QUESTION_PURPOSE_MICROTEST) {
          this.data.machineScorable = true;
        }
      },
    },
  },
  computed: {
    removeBlankPlugin() {
      return this.getToolBarActions.filter((tool) => tool != 'blank');
    },
    correctOption() {
      for (let i = 0; i < this.data.questionData.options.length; i++) {
        let { isCorrectAnswer } = this.data.questionData.options[i];
        if (isCorrectAnswer == true) {
          return i;
        }
      }
      return null;
    },
    disableMachineScorableCheckBox() {
      return (
        !this.data.questionData.freeTyping ||
        this.data.questionPurpose == questionsOprogramProject.QUESTION_PURPOSE_MICROTEST
      );
    },
  },
  data() {
    return {
      placeholderKey: 0,
      imagesInAnswerGuideText: [],
      answerGuideTextRevision: 0,
      revision: 0,
    };
  },
  methods: {
    addNewOption() {
      this.data.questionData.options.push({
        optionText: '',
        isCorrectAnswer: false,
        misconception: '',
      });
    },
    machineScorableHandler() {
      if (this.data.questionData.answerGuideText == '') {
        this.placeholderKey += 1;
      }
    },
    addBriefTextAtPosition(rteData, index) {
      let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
      addBriefToAssetInPosition(answerGuideText.content, index, 0, rteData);
      this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
    },
    addToAsset(previewUrl, briefUrl, approveAsset, index) {
      let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
      approveAssetAtPosition(
        answerGuideText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        approveAsset,
        previewUrl,
        briefUrl
      );
      this.getImagesListFromAnswerGuideText(answerGuideText);
      this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
      this.revision += 1;
    },
    approveAssets(val, index) {
      let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
      approveAssetAtPosition(
        answerGuideText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        val
      );
      this.getImagesListFromAnswerGuideText(answerGuideText);
      this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
      this.revision += 1;
      this.answerGuideTextRevision += 1;
    },
    onChangeAnswerGuideText(jsonString) {
      let jsonData = JSON.parse(jsonString);
      this.getImagesListFromAnswerGuideText(jsonData);
    },
    fileChangeHandler(file, index) {
      if (!file) {
        let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
        deleteImageInRTE(answerGuideText.content, index, 0, this.options.isAssetUploadRequired);
        this.getImagesListFromAnswerGuideText(answerGuideText);
        this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
        this.revision += 1;
        this.answerGuideTextRevision += 1;
      } else {
        let answerGuideText = JSON.parse(this.data.questionData.answerGuideText);
        insertImageAssetAtPosition(
          answerGuideText.content,
          index,
          0,
          file,
          this.options.isAssetUploadRequired
        );
        this.getImagesListFromAnswerGuideText(answerGuideText);
        this.data.questionData.answerGuideText = JSON.stringify(answerGuideText);
        this.revision += 1;
        this.answerGuideTextRevision += 1;
      }
    },
    getImagesListFromAnswerGuideText(jsonData) {
      let imagesInAnswerGuideText = [];
      getImageUrlFromRte(jsonData.content, imagesInAnswerGuideText);
      this.imagesInAnswerGuideText = imagesInAnswerGuideText;
      this.revision += 1;
    },
  },
  mounted() {
    this.getImagesListFromAnswerGuideText(JSON.parse(this.data.questionData.answerGuideText));
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    deleteBlankHandler: {
      type: Function,
      required: true,
    },
    getToolBarActionsForQuestionBriefs: {
      type: Array,
      required: true,
    },
    getToolBarActions: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    forceAssetFinalized: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
