<template>
 <!-- 
 Equation editor was removed from this component and the reference commit id is
 https://github.com/xseededucation/t20-lp-entry-frontend/pull/398/commits/936a5ed4d135ae1529d25482cb59d4733573a254 
 -->
  <div style="position: relative;" :class="`text-field-outer-wrapper ${customClass}`">
    <div class="text-center">
      <div class="inline-button-wrapper bubble" v-if="!readonly && showInlineButtons">
        <v-btn dense small class="action-btns" @click="toggleFormatText('bold')">
          <img height="20" width="20" src="@/assets/bold.svg" />
        </v-btn>
        <span class="action-button-divider"></span>
        <v-btn dense small class="action-btns" @click="toggleFormatText('italics')">
          <img height="20" width="20" src="@/assets/italics.svg" />
        </v-btn>
        <span class="action-button-divider"></span>
        <v-btn dense small class="action-btns" @click="toggleFormatText('underline')">
          <img height="20" width="20" src="@/assets/underline.svg" />
        </v-btn>
      </div>
    </div>

    <div style="width=100%;display: flex;position: relative;">
      <v-textarea
        :label="placeholder"
        :placeholder="hintText"
        ref="textField"
        :id="id"
        color="accent"
        :class="
          (canShowDiffIcon ? 'remove-right-border-radious xs-text-input ' : 'xs-text-input ') +
          (showBorder || remainingCharacter < 0 || remainingWords < 0
            ? ' border'
            : ' border-none') +
          (counter ? ' counter-enabled' : ' counter-disabled')
        "
        style="width: 95%;"
        :style="isHindi ? 'font-family:ChanakyaXUniN':''"
        hide-details
        dense
        auto-grow
        outlined
        rows="1"
        row-height="15"
        :rules="[...rules , remainingCharacter >= 0 && remainingWords >= 0]"
        :counter="parseInt(counter)"
        :maxLength="setMaxAcceptedCharacter"
        :readonly="readonly"
        :value="value"
        @keydown.enter.exact.prevent
        @keydown.enter.shift.exact.prevent
        @change="onTextFieldChangeHandler"
        @input="handleInput"
        @focus="setInlineButtonState(true)"
        @select="setInlineButtonState(true)"
        @blur="onTextFieldFocusOut()"
      ></v-textarea>
      <div
        v-if="counter || wordCounter"
        :style="canShowDiffIcon ? 'right: 54px;' : 'right: 10px;'"
        class="remaining-char-text"
      >
        {{ counter ? remainingCharacter + ' Characters remaining ' : '' }}
        {{ counter && wordCounter ? 'or' : '' }}
        {{ wordCounter ? remainingWords + ' Words remaining' : '' }}
      </div>

      <DiffButton
        v-if="canShowDiffIcon"
        :style="showBorder ? 'border: 1px solid #979797; border-left: 0px;' : 'border: 0px'"
        :class="showInlineButtons ? 'diff-button text-field-focused' : 'diff-button'"
        :fieldIdentifier="fieldIdentifier"
        :isDiffChanged="isDiffChanged"
        :canShowDiffIcon="canShowDiffIcon"
        :projectType="projectType"
      />
    </div>
  </div>
</template>

<script>
import DiffButton from '@/components/ContentDiff/DiffButton';
import { mapState } from 'vuex';

export default {
  name: 'TextInput',
  components: {
    DiffButton,
  },
  data() {
    return {
      showInlineButtons: false,
      remainingCharacter: 0,
      remainingWords: 0,
      textFormatOptions: {
        bold: '**',
        italics: '*',
        underline: '_',
      },
    };
  },
  computed: {
    ...mapState({
         subjectName: (state) => state.lessonPlan.subjectName
      }),
    isHindi() {
      return this.subjectName.toLowerCase() == 'hindi';
    },
    setMaxAcceptedCharacter() {
      if (this.strictCount) {
        return parseInt(this.counter);
      }
      return false;
    },
  },
  created() {
    if (this.counter) {
      this.remainingCharacter = this.getRemainingCounterValue();
    }
    if (this.wordCounter) {
      this.remainingWords = this.getRemainingWordCounterValue();
    }
  },
  methods: {
    focusInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.textField.$refs.input.focus();
        });
      });
    },
    handleInput(val) {
      this.$emit('input', val);
      if (this.counter) {
        this.remainingCharacter = this.getRemainingCounterValue(val);
      }
      if (this.wordCounter) {
        this.remainingWords = this.getRemainingWordCounterValue(val);
      }
    },
    getRemainingCounterValue(val = this.value) {
      return parseInt(this.counter) - (val || '').length;
    },
    getRemainingWordCounterValue(val = this.value) {
      if (val && val != '') {
        return parseInt(this.wordCounter) - val.split(' ').length;
      }
      return parseInt(this.wordCounter);
    },
    onTextFieldChangeHandler(params) {
      if (this.onChange) {
        this.onChange(params);
      }
    },
    toggleFormatText(format_style) {
      const selection = document.getSelection();
      const txtarea = selection.anchorNode.lastElementChild;
      this.insertFormating(txtarea, this.textFormatOptions[format_style], format_style);
    },
    getTextFieldCursorPosition() {
      try {
        return this.$refs.textField.$el.querySelector('textarea').selectionEnd;
      } catch {
        return this.value.length;
      }
    },
    ReplaceAt(input, search, replace, start, end) {
      let orientation = 0;
      let markdown = input.slice(start, end);
      orientation = markdown.charCodeAt(0) >= markdown.charCodeAt(1);
      if (orientation) {
        markdown = markdown.split('').reverse().join('');
        markdown = markdown.replace(search, replace);
        markdown = markdown.split('').reverse().join('');
      } else markdown = markdown.replace(search, replace);
      return input.slice(0, start) + markdown + input.slice(end);
    },
    checkMarkdownActive(input, pattern, length, defaultTxt) {
      switch (defaultTxt) {
        case 'bold':
          return (input.match(/\*/g) || []).length >= length;
        case 'italics':
          return (
            (input.match(/\*/g) || []).length == length || (input.match(/\*/g) || []).length == 3
          );
        case 'underline':
          return (input.match(/_/g) || []).length == length;
      }
    },
    insertFormating(txtarea, text, defaultTxt = '', text2 = '') {
      const selectStart = txtarea.selectionStart;
      const selectEnd = txtarea.selectionEnd;
      const scrollPos = txtarea.scrollTop;
      const caretPos = txtarea.selectionStart;
      var mode = 0; //Adding markdown with selected text
      var front = txtarea.value.substring(0, caretPos);
      var back = txtarea.value.substring(selectEnd, txtarea.value.length);
      var middle = txtarea.value.substring(caretPos, selectEnd);
      let defaultString = txtarea.value;

      if (text2 == '') {
        text2 = text;
      }
      const textLen = text.length;
      const text2Len = text2.length;
      let skipAddition = 0;

      if (selectStart === selectEnd) {
        middle = defaultTxt;
        mode = 1; //Adding markdown with default text
      } else {
        let getMarkdownFromSelectedText = back.split(' ')[0];
        let getMarkdownLengthFromSelectedText = getMarkdownFromSelectedText.length;
        let isSelectedNameActive = this.checkMarkdownActive(
          getMarkdownFromSelectedText,
          text,
          textLen,
          defaultTxt
        );
        //Will allow addition of bold and italics only once and same goes for removal
        if (isSelectedNameActive) {
          skipAddition = 1;
          mode = 2;
        } else if (middle.substr(0, textLen) == text && middle.substr(-text2Len) == text2) {
          middle = middle.substring(textLen, middle.length - text2Len);
          text = '';
          text2 = '';
          mode = 3; //Removing markdown with selected text eg. <selected>**bold**<selected>
        }

        if (skipAddition) {
          front = this.ReplaceAt(
            front,
            text,
            '',
            selectStart - getMarkdownLengthFromSelectedText,
            selectStart
          );
          back = this.ReplaceAt(back, text, '', 0, getMarkdownLengthFromSelectedText);
          skipAddition = 0;
          text = '';
          text2 = '';
        }
      }
      let newValue = '';
      if (!skipAddition) {
        newValue = front + text + middle + text2 + back;
      } else newValue = defaultString;
      if (this.onChange) {
        this.onChange(newValue);
      } else {
        this.$emit('input', newValue);
      }
      if (selectStart !== selectEnd) {
        if (mode === 0) {
          this.focusSelectedText(txtarea, selectStart + textLen, selectEnd + textLen);
        } else if (mode === 2) {
          this.focusSelectedText(txtarea, selectStart - textLen, selectEnd - textLen);
        } else if (mode === 3) {
          this.focusSelectedText(txtarea, selectStart, selectEnd - textLen - text2Len);
        }
      } else {
        this.focusSelectedText(selectStart + textLen, txtarea.selectionStart + middle.length);
      }
      txtarea.focus();
      txtarea.scrollTop = scrollPos;
    },
    focusSelectedText(txtarea, selectionStart, selectionEnd) {
      setTimeout(() => {
        txtarea.setSelectionRange(selectionStart, selectionEnd);
      }, 0);
    },
    onTextFieldFocusOut() {
      setTimeout(() => {
        this.setInlineButtonState(false);
      });
    },
    onTextSelectHandler() {
      this.setInlineButtonState(true);
    },
    setInlineButtonState(val) {
      if (this.enableTextFormatting) {
        setTimeout(
          () => {
            this.showInlineButtons = val;
          },
          val ? 0 : 500
        );
      }
    },
  },
  props: {
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    counter: {
      type: Number,
      required: false,
      default: undefined,
    },
    wordCounter: {
      type: Number,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      required: true,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    value: {
      type: String,
      required: false,
      default: undefined,
    },
    onChange: {
      type: Function,
      required: false,
      default: null,
    },
    showDiffButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    onClickDiff: {
      type: Function,
      default: () => {},
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isDiffChanged: {
      type: Boolean,
      required: false,
    },
    fieldIdentifier: {
      type: String,
      required: false,
    },
    showFieldSetBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    hintText: {
      type: String,
      required: false,
      default: null,
    },
    showBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    customClass: {
      type: String,
      required: false,
      default: ' mgn-Bm',
    },
    enableTextFormatting: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    projectType: {
      type: String,
      default: 'noQuestion',
    },
    strictCount: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.remove-right-border-radious {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.diff-button {
  width: 50px;
  background-color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remaining-char-text {
  color: #242424;
  font-size: 12px;
  opacity: 0.5;
  position: absolute;
  bottom: 1px;
}
.action-btns {
  font-size: 1.5rem;
  font-weight: bold;
}
.inline-button-wrapper {
  position: absolute;
  top: -43px;
  z-index: 10;
  left: 42%;
  border: 1px solid #0046c0;
  background-color: #fff;
}
.text-field-focused {
  border-color: #0046c0;
}
.action-btns {
  background-color: #fff !important;
  border-radius: 0px;
  box-shadow: none;
  height: 35px !important;
}
.action-button-divider {
  border-right: 1px solid #ededed;
}
/* .bubble {
  position: relative;
  width: 400px;
  height: 250px;
  padding: 0px;
  background: #ffffff;
  border: #0046c0 solid 1px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.bubble:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 175px;
  border-style: solid;
  border-width: 25px 25px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.bubble:before {
  content: "";
  position: absolute;
  top: 250px;
  left: 174px;
  border-style: solid;
  border-width: 26px 26px 0;
  border-color: #0046c0 transparent;
  display: block;
  width: 0;
  z-index: 0;
} */
</style>
