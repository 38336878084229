import { apolloClient } from '@/apollo';
import axios from 'axios';
import { getCookie } from '@/utils/auth';
import { logError } from '@/utils';
import {
  getCurrentUserPermissions,
  getLessonPlanSaveAndMoveToNextStagePermissionQuery,
  getCurrentUserMicroTopicAuthPermissions,
  getCurrentUserContentEntryProjectFormPermissionsQuery,
  getDailyPlanPermissionQuery,
  authorizeUser,
} from '@/apiQueries/auth';
import Router from '@/router';
import Vue from 'vue';
import { APP_COOKIE_NAME } from '@/constants/common';

const CAA_TOKEN_VALIDATION_ENDPOINT = process.env.VUE_APP_CAA_TOKEN_VALIDATION_ENDPOINT;

export const AUTH_ACTION = {
  async setLoggedInUser({ commit }) {
    try {
      const caaResponse = await axios.post(
        CAA_TOKEN_VALIDATION_ENDPOINT,
        {},
        {
          headers: { 'xs-auth-token': getCookie('xs-lp-entry-token') },
        }
      );
      if (caaResponse.data.success) {
        commit('SET_LOGGED_IN_USER', caaResponse.data.data);
      }
    } catch (err) {
      logError(err);
    }
  },
  async getCurrentUserAuthPermissions({ commit }) {
    try {
      commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: getCurrentUserPermissions(),
        fetchPolicy: 'no-cache',
      });

      if (response.data) {
        commit('UPDATE_USER_AUTH_PERMISSIONS', response.data);
      }
      commit('changeLoadingState', false);
    } catch (er) {
      logError(er);
    }
  },
  async getLessonPlanSaveAndMoveToNextStagePermission({ commit }, { projectId, libraryId }) {
    try {
      const response = await apolloClient.query({
        query: getLessonPlanSaveAndMoveToNextStagePermissionQuery(projectId, libraryId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        commit('SET_LESSON_PLAN_SAVE_AND_MOVE_TO_NEXT_STAGE_PERMISSION', response.data);
      }
    } catch (er) {
      logError(er);
    }
  },
  async getCurrentUserMicroTopicAuthPermissions({ commit }) {
    try {
      const response = await apolloClient.query({
        query: getCurrentUserMicroTopicAuthPermissions(),
        fetchPolicy: 'no-cache',
      });

      if (response.data) {
        commit('UPDATE_MICRO_TOPIC_AUTH_PERMISSIONS', response.data);
      }
    } catch (er) {
      logError(er);
    }
  },
  async getCurrentUserContentEntryProjectFormPermissions(
    { commit },
    { projectId, contentLibraryId }
  ) {
    try {
      const response = await apolloClient.query({
        query: getCurrentUserContentEntryProjectFormPermissionsQuery(projectId, contentLibraryId),
        fetchPolicy: 'no-cache',
      });

      if (response.data) {
        commit('UPDATE_CONTENT_ENTRY_PROJECT_FORM_PERMISSIONS', response.data);
      }
    } catch (er) {
      logError(er);
    }
  },
  async getDailyPlanPermissions({ commit }, { projectId, contentLibraryId }) {
    try {
      const response = await apolloClient.query({
        query: getDailyPlanPermissionQuery(projectId, contentLibraryId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        commit('SET_DAILY_PLAN_PERMISSION', response.data);
      }
    } catch (er) {
      logError(er);
    }
  },
  async authoriseUser() {
    const response = await apolloClient.query({
      query: authorizeUser(),
      fetchPolicy: 'no-cache',
    });

    if (response && response.data && response.data.invokeLPAuthFunction) {
      console.log('Authorised User');
    } else {
      Router.push('/unauthorised');
      if (Vue.$cookies) Vue.$cookies.remove(APP_COOKIE_NAME);
    }
  },
};
