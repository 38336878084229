import { get } from 'lodash';

export const isSlideLayoutEnabled = (path, data) => {
  const slideLayoutEnabled = (!!get(data, path, {}).layoutName)
  return slideLayoutEnabled;
};

export const keysOtherThanLayout = (path, data, slideKey, ignoreKey) => {
  const allKeys = Object.keys(get(data, path, {}))

  for(const key of allKeys) {
    if(key != slideKey && !ignoreKey.includes(key)) {
      return true
    }
  }

  return false;
};
