const state = {
  fetchStandardsSubjectsApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  fetchConfidenceBlocksApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  fetchConfidenceQuestionsApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  fetchConfidenceMetadataApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: {},
  },
  isConfidenceUser: false,
};

export default state;
