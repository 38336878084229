<template>
  <div>
    <v-app-bar
      height="58px;"
      dense
      absolute
      color="white"
      elevation="2"
      scroll-target="#scrolling-techniques-7"
      class="action-bar"
    >
      <v-row>
        <v-col>
          <div class="bread-crumb-row">
            <a href="/projects">Home </a>
            <p>
              >
            </p>
            <a href="/xseed_academy">Create question </a>
            <p>
              >
            </p>
            <div>
              <v-select
                dense
                :items="trainingType"
                item-text="label"
                item-value="value"
                v-model="selectedEntityType"
                placeholder="Question Type"
                background-color="white"
                hide-details
                outlined
                style="height: 40px; width: 210px; padding: 0px 10px;"
              ></v-select>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-sheet
      style="margin-top: 60px; padding-left: 50px; padding-right: 50px;"
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
      <v-dialog v-model="showLoader" max-width="400" persistent>
        <v-card>
          <v-card-title
            style="text-align: center; display: block;"
            class="headline"
            v-text="questionFetchApiStatus.message"
          >
          </v-card-title>
          <v-card-text style="text-align: center;">
            <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div v-if="pollSelectionDataFetchStatus">
        <p style="margin-top: 20px; font-weight: 500; font-size: 20px; color: #242424;">
          Select Poll
        </p>
        <v-select
          dense
          :items="pollsData"
          item-text="label"
          item-value="value"
          placeholder="Select Poll"
          v-model="selectedPoll"
          hide-details
          outlined
          style="height: 40px; width: 150px; padding: 0px 10px;"
        ></v-select>
        <XSDivider />
      </div>

      <Questions
        :data="data"
        :options="{
          hideComments: true,
          isAssetUploadRequired: true,
          isReadOnly: false,
          selectedEntityType: selectedEntityType,
        }"
        :saveForm="saveForm"
        :canAppproveAsset="false"
        :toggleComments="() => {}"
        lpType="teacherTrainingQuestion"
        currentStage="assetAuthorizingStage"
        :showMetaFields="false"
        :showCreateQuestionAsBottomBar="true"
        :hideInfoCard="true"
        :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
        :showMinimalDetails="true"
        :shouldEmitDeleteAction="true"
        @remove-question-clicked="removeQuestionTriggered"
        :bufferSize="10"
      />
    </v-sheet>
  </div>
</template>

<style scoped>
.action-btn-text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.bread-crumb-row p {
  width: 5px;
  height: 9px;
  padding: 9px 10px;
  color: #979797;
}
.bread-crumb-row a {
  cursor: pointer;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #242424;
  opacity: 0.7;
}
.bread-crumb-row {
  display: flex;
  flex-direction: row;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import Questions from '@/components/contentEntry/Questions.vue';
import _ from 'lodash';

export default {
  name: 'XSEEDAcademyQuestions',
  data: () => {
    return {
      data: [],
      selectedEntityType: 'PRE_TEST',
      trainingType: [
        {
          label: 'PRE TEST',
          value: 'PRE_TEST',
        },
        {
          label: 'CERTIFICATION TEST',
          value: 'POST_TEST',
        },
        {
          label: 'POLL',
          value: 'POLL',
        },
        {
          label: 'FEEDBACK QUESTIONS',
          value: 'FEEDBACK_TEST',
        },
      ],
      pollsData: [],
      selectedPoll: 0,
      pollSelectionDataFetchStatus: false,
      currentQuestionId: '',
    };
  },
  components: {
    Questions,
  },
  computed: {
    ...mapState({
      questions: (state) => state.questions.questionData,
      questionFetchApiStatus: (state) => state.training.trainingQuestionAuthoringApiResponse,
      trainingDetailsById: (state) => state.training.trainingDetailsByTrainingIdApiResponse,
      currentQuestionDialogData: (state) => state.questions.currentQuestionDialogData,
    }),
    showLoader() {
      if (this.selectedEntityType == 'POLL') {
        return this.trainingDetailsById.status == 'in_progress';
      }
      return this.questionFetchApiStatus.status == 'in_progress';
    },
  },
  watch: {
    questions: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.length > 0) {
          this.data = this.modifyChangeQuestionBaseData(_.cloneDeep(val));
        }
      },
    },
    selectedEntityType: {
      handler(val) {
        if (val && val == 'POLL') {
          this.fetchTrainingDetailsByTrainingId({
            id: this.$route.params['trainingId'],
          });
          this.pollSelectionDataFetchStatus = true;
          const defaultSelectedPoll =
            Number.parseInt(this.$route.params['pollIndex']) == 0
              ? 1
              : Number.parseInt(this.$route.params['pollIndex']);
          this.selectedPoll = defaultSelectedPoll;
        } else {
          this.selectedPoll = 0;
          this.pollSelectionDataFetchStatus = false;
        }
        this.fetchQuestionsByTrainingIdAndEntityType({
          entity: this.selectedEntityType,
          pollIndex: this.selectedPoll,
          trainingId: this.$route.params['trainingId'],
        });
      },
    },
    trainingDetailsById: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const firstTrainingSlot = val.data.trainingSlots[0] || {};

          this.pollsData = (firstTrainingSlot.polls || []).map((_poll) => {
            return { label: `Poll ${_poll.index}`, value: _poll.index };
          });
        }
      },
    },
    selectedPoll: {
      handler(val) {
        if (val) {
          this.selectedPoll = val;
          this.fetchQuestionsByTrainingIdAndEntityType({
            entity: this.selectedEntityType,
            pollIndex: this.selectedPoll,
            trainingId: this.$route.params['trainingId'],
          });
        }
      },
    },
    currentQuestionDialogData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Array.isArray(val) && val.length > 0) {
          this.currentQuestionId = val[0]._id;
        } else if (val && Object.keys(val).length > 0) {
          this.currentQuestionId = val._id;
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'fetchQuestionsByTrainingIdAndEntityType',
      'saveQuestionsForTrainingModule',
      'fetchTrainingDetailsByTrainingId',
      'deleteContentEntryGroupQuestion',
      'deleteContentEntryQuestion',
      'deleteTrainingQuestionsByIds',
    ]),
    sanitizeDataForSave(data) {
      let dataCopy = _.cloneDeep(data);
      dataCopy.forEach((_d) => {
        delete _d.scorePercentage;
        delete _d.isSelected;
        delete _d.entity;
        delete _d.training;
        delete _d.questionPurpose;
        delete _d.time;
        delete _d.academicYear;
        delete _d.skill;
        delete _d.subSkill;
        delete _d.difficulty;
        delete _d.machineScorable;
        delete _d.lessonPlanLibraryId;
      });
      return dataCopy;
    },
    getQuestionListForCreateOrUpdate(currentQuestion, existingQuestionList) {
      if (currentQuestion.questionGroupId) {
        currentQuestion = existingQuestionList.filter(
          (_f) => _f.questionGroupId == currentQuestion.questionGroupId
        );
      } else {
        currentQuestion = [currentQuestion];
      }
      return currentQuestion;
    },
    saveForm(data, validateForm, showLoader) {
      console.log(validateForm);
      console.log(showLoader);
      let dataToSave = null;
      let currentQuestion = null;
      if (this.currentQuestionId) {
        currentQuestion = data.data.find((_d) => _d._id == this.currentQuestionId);
        currentQuestion = this.getQuestionListForCreateOrUpdate(currentQuestion, data.data);
      } else {
        dataToSave = data.data[data.data.length - 1];
        currentQuestion = this.getQuestionListForCreateOrUpdate(dataToSave, data.data);
      }
      dataToSave = this.sanitizeDataForSave(currentQuestion);
      this.saveQuestionsForTrainingModule({
        questions: dataToSave,
        trainingId: this.$route.params['trainingId'],
        entity: this.selectedEntityType,
        pollIndex: this.selectedPoll,
      });
    },
    removeQuestionTriggered(event) {
      let idsToDelete = [];
      if (Array.isArray(event)) {
        idsToDelete = event.map((_e) => _e._id);
        this.deleteContentEntryGroupQuestion({
          questionGroupId: event.find((_) => _.questionGroupId != null).questionGroupId,
        });
      } else {
        idsToDelete.push(event._id);
        this.deleteContentEntryQuestion({
          questionId: event._id,
        });
      }
      this.deleteTrainingQuestionsByIds({ questionIds: idsToDelete });
    },
    modifyChangeQuestionBaseData(data) {
      if (typeof data == 'object' && !Array.isArray(data)) {
        if (data.questionData && data.questionData.options) {
          data.questionData.options = data.questionData.options.map((_o) => {
            delete _o.misconception;
            return _o;
          });
        }
      } else {
        data.forEach((_d) => {
          _d.questionData.options = _d.questionData.options.map((_o) => {
            delete _o.misconception;
            return _o;
          });
        });
      }
      return data;
    },
  },
  mounted() {
    this.selectedEntityType = this.$route.params['entity'];
    this.trainingId = this.$route.params['trainingId'];
    this.selectedPoll = Number.parseInt(this.$route.params['pollIndex']);

    this.fetchQuestionsByTrainingIdAndEntityType({
      entity: this.selectedEntityType,
      pollIndex: this.selectedPoll,
      trainingId: this.$route.params['trainingId'],
    });

    this.fetchTrainingDetailsByTrainingId({
      id: this.$route.params['trainingId'],
    });
  },
  props: {
    isReadOnly: {
      type: Boolean,
      required: false,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    lpType: {
      type: String,
      default: 'questions',
    },
    lessonPlans: {
      type: Array,
      required: false,
    },
    isLanguageSubject: {
      type: Boolean,
      default: false,
    },
    isSchoolProgramQuestion: {
      type: String,
      required: true,
    },
    modifyQuestionDataHash: {
      type: Function,
      required: true,
    },
    checkIfOptionsWillNotBeRetained: {
      type: Function,
      required: true,
    },
    pollSelectionDataFetchStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
