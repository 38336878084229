<template>
  <div>
    <div v-for="(_, index) in allSlidesCount()" :key="`${subPath}.${index}`">
      <v-row>
        <v-col :cols="11">
          <SlideLayout
            :key="`slide-layout-${subPath}.${index}`"
            :subPath="`${subPath}.${index}`"
            :lessonPlanData="lessonPlanData"
            :isAssetUploadRequired="isAssetUploadRequired"
            :isNotReadOnly="isNotReadOnly"
            :fileValidators="fileValidators"
            :canAppproveAsset="canAppproveAsset"
            :signedUrls="signedUrls"
            :canShowDiffIcon="canShowDiffIcon"
            :showImageBrief="showImageBrief"
            :assetSubPath="`${assetSubPath || subPath}.${index}`"
          />
        </v-col>
        <v-col :cols="1">
          <v-btn
            title="Delete Slide"
            icon
            @click="callDeleteSlideHandler(index)"
            v-if="showAddButton"
          >
            <img height="19" width="16" src="@/assets/close.svg" />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="mgn-Ts mgn-Bs" v-if="showAddButton">
      <v-btn
        class="xs-accent-button"
        @click="callAddNewStepHandler()"
        dense
        :class="!canAddStep() ? 'disable-btn-click-event' : ''"
        >{{ AppTexts.ADD_SLIDE }}</v-btn
      >
      <span class="mgn-Lm remaining-step-warning-text" v-if="canAddStep()">
        {{ getStepAdditionValidationText(reminingSlides, 'Slide') }}
      </span>
    </div>
  </div>
</template>
<script>
import SlideLayout from './SlideLayout.vue';
import AppTexts from '@/constants/AppTexts';
import { get, cloneDeep } from 'lodash';
import { LAYOUT_1_OBJECT } from './layouts/generic/layoutObject';
import { getStepAdditionValidationText } from '@/utils';
export default {
  name: 'MultiSlideLayout',
  components: {
    SlideLayout,
  },
  data() {
    return {
      AppTexts,
      LAYOUT_1_OBJECT,
    };
  },
  computed: {
    reminingSlides() {
      return this.maxSlidesCount - this.allSlidesCount();
    },
    showAddButton() {
      return this.maxSlidesCount > 1;
    },
  },
  methods: {
    getStepAdditionValidationText,
    canAddStep() {
      return this.allSlidesCount() < this.maxSlidesCount;
    },
    callDeleteSlideHandler(index) {
      const pathArray = this.subPath.split('.');
      let target = this.lessonPlanData;
      for (let i = 0; i < pathArray.length - 1; i++) {
        target = target[pathArray[i]];
      }

      const key = pathArray[pathArray.length - 1];

      if (Array.isArray(target[key])) {
        target[key].splice(index, 1);
      }
    },
    allSlidesCount() {
      const allSlides = get(this.lessonPlanData, this.subPath, []);
      return allSlides.length;
    },
    callAddNewStepHandler() {
      console.log(this.subPath);
      const pathArray = this.subPath.split('.');
      let target = this.lessonPlanData;
      for (let i = 0; i < pathArray.length - 1; i++) {
        target = target[pathArray[i]];
      }

      const key = pathArray[pathArray.length - 1];
      if (!Array.isArray(target[key])) {
        this.$set(target, key, []);
      }

      target[key].push(cloneDeep({ ...this.LAYOUT_1_OBJECT }));
    },
  },
  props: {
    subPath: {
      type: String,
      default: '',
    },
    lessonPlanData: {
      type: Object,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    isNotReadOnly: {
      type: Boolean,
      default: false,
    },
    fileValidators: {
      type: Array,
      default: () => [],
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      default: () => {},
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },
    maxSlidesCount: {
      type: Number,
      default: 5,
    },
    assetSubPath: {
      type: String,
      default: '',
    },
  },
};
</script>
