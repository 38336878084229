<template>
  <div>
    <v-row>
      <div
        :style="
          showRightSidePanel
            ? 'width: 65%; position: relative;'
            : ' width: 96%; position: relative;'
        "
      >
        <div class="overflow-hidden">
          <FormActionBar
            :breadCrumbText="breadCrumbText"
            :buttonActions="buttonActions"
            :auth="auth"
            :currentStage="currentStage"
            :showRightSidePanel="showRightSidePanel"
            :showAssetDownloadButton="showAssetDownloadButton"
            :isAdmin="isAdmin"
          />
          <v-sheet id="scrolling-techniques-7" class="overflow-y-auto mt-6">
            <slot />
            <span v-if="isCompleted && canPublishProject && !isLessonPlan">
              <span v-if="isPublished">
                <MoveToNextStageButton
                  buttonText="Published"
                  :canMoveProjectLP="true"
                  @onClickMoveToNextStage="null"
                />
              </span>
              <span v-else>
                <MoveToNextStageButton
                  buttonText="Publish"
                  :canMoveProjectLP="!canPublishProject"
                  @onClickMoveToNextStage="onClickPublish"
                />
              </span>
            </span>
            <span v-else>
              <MoveToNextStageButton
                buttonText="Publish Final Content"
                :canMoveProjectLP="!auth.canMoveContent"
                @onClickMoveToNextStage="onClickCompleteAndPublish"
                v-if="currentStage == 'proofReadingStage' && !isLessonPlan"
              />
              <MoveToNextStageButton
                :buttonText="moveToNextStageButtonText"
                :canMoveProjectLP="!(auth.canMoveContent || auth.canMoveProjectLP)"
                @onClickMoveToNextStage="onClickMoveToNextStage"
                v-else
              />
            </span>
            <span v-if="currentStage == 'proofReadingStage' && !isLessonPlan">
              <MoveToNextStageButton
                buttonText="Move back to previous stage"
                :canMoveProjectLP="!auth.canMoveContentProjectToPreviousStage"
                @onClickMoveToNextStage="onClickMoveToPreviousStage"
              />
            </span>
          </v-sheet>
        </div>
      </div>
      <div style="align-item: center; width: 35%;" class="preview-wrapper">
        <div>
          <v-btn
            icon
            :style="
              showRightSidePanel ? 'right: 34%; margin-top: 10px;' : 'right: 3px; margin-top: 10px;'
            "
            class="toggle-preview-button"
            :title="showRightSidePanel ? 'Hide Preview' : 'Show Preview'"
            @click="toggleRightSidePanel()"
          >
            <v-icon large>
              {{
                showRightSidePanel ? 'mdi-arrow-right-drop-circle' : 'mdi-arrow-left-drop-circle'
              }}
            </v-icon>
          </v-btn>
        </div>
        <div class="right-side-panel-wrapper" v-if="showRightSidePanel">
          <div v-if="rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS">
            <div style="overflow: hidden; background-color: #f6f6f6; padding: 10px;">
              <span class="comment-identifier"
                >{{
                  !commentSectionHeaderText
                    ? capitalCase(commentSectionIdentifier)
                    : capitalCase(commentSectionHeaderText)
                }}
                Section</span
              >
              <span
                style="cursor: pointer; float: right; margin-right: 10px; color: #cb4c52;"
                @click="closeComments()"
              >
                CLOSE
                <v-icon title="close" color="#cb4c52">mdi-close</v-icon>
              </span>
            </div>
            <div v-if="commentsHash[commentSectionIdentifier].fetchStatus === 'in_progress'">
              <span style="padding: 10px;">
                {{ commentsHash[commentSectionIdentifier].message }}
              </span>
            </div>
            <div v-else-if="commentsHash[commentSectionIdentifier].fetchStatus === 'completed'">
              <Comments
                :comments="commentsHash[commentSectionIdentifier].comments"
                :currentStage="currentStage"
                :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
                :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
                :canAddComment="auth.canAddComment"
                :canResolveComment="auth.canResolveComment"
                :loggedInUserEmail="loggedInUserEmail"
                :saveStatusesHash="commentsHash[commentSectionIdentifier].saveStatusesHash"
                :resolveStatusesHash="commentsHash[commentSectionIdentifier].resolveStatusesHash"
                :commentSectionIdentifier="commentSectionIdentifier"
              />
            </div>
            <div v-else-if="commentsHash[commentSectionIdentifier].fetchStatus === 'error'">
              {{ commentsHash[commentSectionIdentifier].message }}
            </div>
          </div>
          <div v-else-if="rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.CONTENT_DIFF">
            <Diff
              :fieldDiff="diffOptions.fieldDiff"
              :closeHandler="diffOptions.diffCloseHandler"
              :currentStage="currentStage"
              :title="diffOptions.currentDiffIdentifier"
              :isForContentEntry="true"
            />
          </div>
          <div
            style="height: 100%; text-align: center; border-radius: 10px; padding: 2px;"
            class="preview-block"
            v-else
          >
            <iframe
              ref="content-preview-iframe"
              :src="onlinePreviewUrl + '/#' + projectType"
              frameborder="0"
              @load="iframeLoad"
              width="93%"
              height="100%"
            ></iframe>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import MoveToNextStageButton from '@/components/lessonPlan/MoveToNextStageButton';
import FormActionBar from '@/components/lessonPlan/FormActionBar';
import { RIGHT_SIDE_PREVIEW_CONTENT_TYPE } from '@/constants/LessonPlan';
import Diff from '@/components/ContentDiff/Diff';
import Comments from '@/components/comments/Comments';
import { capitalCase } from 'change-case';
import { APP_COOKIE_NAME } from '@/constants/common';
import { getCookie } from '@/utils/auth';

export default {
  name: 'FormLayout',
  components: {
    MoveToNextStageButton,
    FormActionBar,
    Diff,
    Comments,
  },
  watch: {
    previewData: {
      deep: true,
      immediate: true,
      handler() {
        console.log('preview watcher');
        this.sendDataToPreview();
      },
    },
    // questionPreviewData: {
    //   deep: true,
    //   immediate: true,
    //   handler(val) {
    //     this.sendQuestionToPreview(val);
    //   },
    // },
  },
  methods: {
    capitalCase,
    getCookie,
    iframeLoad() {
      const token = this.getCookie(this.APP_COOKIE_NAME);
      const self = this;
      setTimeout(function () {
        self.sendDataToPreview(token);
      }, 2000);
    },
    sendDataToPreview(token) {
      var iframe = this.$refs['content-preview-iframe'];
      console.log('called from place 1');

      if (this.previewData != null && iframe && iframe.isConnected) {
        console.log(' it came inside if called from place 1');

        var data = this.previewData;

        if (token && token.length) {
          data.token = token;
        }

        const doc = this.getIframeWindow(iframe);
        data.name = data.data.lp_title || data.name;
        const post = JSON.parse(JSON.stringify(data));
        doc.postMessage(post, '*');
        this.$forceUpdate();
      }
    },
    getIframeWindow(iframe) {
      var doc;
      if (iframe && iframe.contentWindow) {
        return iframe.contentWindow;
      }
      if (iframe.window) {
        return iframe.window;
      }
      if (!doc && iframe.contentDocument) {
        doc = iframe.contentDocument;
      }
      if (!doc && iframe.document) {
        doc = iframe.document;
      }
      if (doc && doc.defaultView) {
        return doc.defaultView;
      }
      if (doc && doc.parentWindow) {
        return doc.parentWindow;
      }
      return undefined;
    },
  },
  data() {
    return {
      RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
      onlinePreviewUrl: this.lpPreviewUrl || process.env.VUE_APP_XSEED_ONLINE_PREVIEW_ENDPOINT,
      APP_COOKIE_NAME,
    };
  },
  props: {
    moveToNextStageButtonText: {
      type: String,
      required: true,
    },
    canMoveProjectLP: {
      type: Boolean,
      required: true,
    },
    onClickMoveToNextStage: {
      type: Function,
      required: true,
    },
    onClickCompleteAndPublish: {
      type: Function,
      required: true,
    },
    breadCrumbText: {
      type: String,
      required: true,
    },
    buttonActions: {
      type: Object,
      required: true,
    },
    canPublishProject: {
      type: Boolean,
      required: true,
    },
    onClickPublish: {
      type: Function,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
    rightSidePreviewContent: {
      type: String,
      required: true,
    },
    diffOptions: {
      type: Object,
      required: true,
    },
    showRightSidePanel: {
      type: Boolean,
      required: true,
    },
    toggleRightSidePanel: {
      type: Function,
      required: true,
    },
    commentsHash: {
      type: Object,
      required: true,
    },
    commentsSaveButtonClickHandler: {
      type: Function,
      required: true,
    },
    commentsResolveButtonClickHandler: {
      type: Function,
      required: true,
    },
    currentStage: {
      type: String,
      required: true,
    },
    loggedInUserEmail: {
      type: String,
      required: true,
    },
    closeComments: {
      type: Function,
      required: true,
    },
    commentSectionIdentifier: {
      type: String,
      required: true,
    },
    commentSectionHeaderText: {
      type: String,
      required: false,
    },
    onClickMoveToPreviousStage: {
      type: Function,
      required: true,
    },
    projectType: {
      type: String,
      required: false,
    },
    previewData: {
      type: Object,
      required: true,
    },
    isLessonPlan: {
      type: Boolean,
      default: false,
    },
    lpPreviewUrl: {
      type: String,
      required: false,
    },
    showAssetDownloadButton: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.toggle-preview-button {
  position: fixed;
  top: 120px;
  z-index: 1;
}
.preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.right-side-panel-wrapper {
  margin-top: 120px;
  position: fixed;
  top: 0px;
  display: inline-block;
  overflow: scroll;
  width: 35%;
  padding: 2px;
  height: 83vh;
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.3);
}
.comment-identifier {
  font-size: 20px;
  font-weight: 500;
}
</style>
