<template>
  <v-dialog persistent :value="showDialog" scrollable color="white" :width="setDialogWidth">
    <v-card class="text-center mgn-al" v-if="showLoader">
      <p class="pt-4">
        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
      </p>
      <p>Saving question....</p>
    </v-card>
    <v-card class="mt-6" color="white" v-if="data && data.questionData && !showLoader">
      <v-sheet color="white" style="overflow-x: hidden;">
        <DialogContents
          :data="data"
          :newlyCreatedQuestion="true && !options.disableQuestionTypeDropdown"
          :changeInQuestionType="confirmAndChangeQuestionType"
          :closeDialog="closeDialog"
          :options="options"
          :canAppproveAsset="canAppproveAsset"
          :lpType="lpType"
          :lessonPlans="lessonPlans"
          :isLanguageSubject="isLanguageSubject"
          :key="`keyForDialogContentInQuestionDialog${refreshKey}`"
          :showMetaFields="showMetaFields"
          :modifyChangeQuestionBaseData="modifyChangeQuestionBaseData"
          :subSectionTitle="subSectionTitle"
          :dynamicMetaDataKeySourceDataMap="dynamicMetaDataKeySourceDataMap"
          :turnOffQuestionTextValidation="turnOffQuestionTextValidation"
          :allowedQuestionTypes="allowedQuestionTypes"
        />
        <section v-if="isSchoolProgramQuestion">
          <Padder :horizontal="50">
            <section
              v-if="data.questionPurpose == questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT"
            >
              <XSDivider />
              <AnswerCriteriaInput
                :answerCriteria="data.answerCriteria || []"
                @onUpdate="onAnswerCriteriaUpdate"
              />
            </section>
            <XSDivider />
            <SubSection title="Teacher Tip" />
            <RichTextEditor
              :readonly="options.isReadOnly"
              :canShowDiffIcon="options.canShowDiffIcon"
              v-model="teacherTip"
              @input="onTecherTipChanged"
              placeholder="Teacher Tip"
              :fieldIdentifier="`['${data._id}'].teacherTip`"
              projectType="question"
              :showValidationError="false"
              :includeOnlyToolBarActions="getToolBarActions()"
              :isAssetUploadRequired="options.isAssetUploadRequired"
              :forceAssetFinalized="questionsCurrentStage == 'assetAuthorizingStage'"
              :acceptedFileTypes="getRTEAcceptedFileType"
            />
          </Padder>
        </section>
        <v-row class="question-creation-dialog-action-buttons">
          <v-col>
            <p class="text-center">
              <v-btn
                width="300"
                style="font-size: 18px; border: 1px solid #ccc;"
                depressed
                class="mr-4 font-weight-medium"
                @click="closeDialog"
                >Cancel</v-btn
              >
              <v-btn
                width="300"
                style="font-size: 18px;"
                depressed
                color="primary"
                @click="closeDialogAndSaveContent"
                class="font-weight-medium"
                >Save</v-btn
              >
            </p>
          </v-col>
        </v-row>
        <WarningPopup
          :v-if="warningDialog"
          title="Retain question data?"
          message="Options will not be retained. Do you want to proceed?"
          :cancleHandler="
            () => {
              refreshKey += 1;
            }
          "
          :confirmHandler="this.changeInQuestionType"
        />
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import { validateQuestionDataBasedOnType, validateAnswerCriteria } from '@/utils/questionUtils.js';
import _ from 'lodash';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import { deleteQuestionSpecificItems } from '@xseededucation/rte-helper-functions';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import DialogContents from '@/components/contentEntry/questionComponents/dialogComponents/DialogContents.vue';
import AnswerCriteriaInput from '@/components/Questions/AnswerCriteriaInput.vue';
import SubSection from '@/components/inputs/SubSection.vue';
import WarningPopup from '@/components/common/WarningPopup.vue';
let LOCAL_STORAGE_BADGE_MAX_COUNT = 5;
let LOCAL_STORAGE_BADGE_COUNT = 0;

export default {
  data() {
    return {
      questionsOprogramProject,
      DEFAULT_QUESTION_DATA,
      commentSectionIdentifier: '',
      selectedQuestionType: '',
      defaultQuestionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ,
      teacherTip: '',
      refreshKey: 0,
    };
  },
  computed: {
    ...mapState({
      showDialog: (state) => state.questions.showQuestionDialog,
      data: (state) => state.questions.currentQuestionDialogData,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
      showLoader: (state) => state.questions.questionDialogLoader,
      questionCommentsToggle: (state) => state.questions.questionCommentsToggle,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      questionCommentsLoader: (state) => state.rightSideContent.questionCommentsLoader,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      currentDiffIdentifier: (state) => state.rightSideContent.currentDiffIdentifier,
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
      questionsCurrentStage: (state) => state.questions.currentStage,
      warningDialog: (state) => state.questions.showWarningDialog,
    }),
    newlyCreatedQuestion() {
      return !this.data._id;
    },
    setDialogWidth() {
      return this.showLoader
        ? '200px'
        : this.questionCommentsToggle || this.setQuestionDiffPanel
        ? '1200px'
        : '1008px';
    },
    getRTEAcceptedFileType() {
      return this.options.isAssetUploadRequired
        ? ['image/png', 'image/jpeg', 'video/mp4', 'audio/mpeg']
        : [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'video/mp4',
            'audio/mpeg',
          ];
    },
  },
  components: {
    DialogContents,
    AnswerCriteriaInput,
    RichTextEditor,
    SubSection,
    WarningPopup,
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val != '') {
          if (val.teacherTip != null && (!this.teacherTip || this.teacherTip == '')) {
            this.teacherTip = val.teacherTip;
          }

          this.cacheQuestionDataInLocalStorage();
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'closeDialogAndClearQuestionData',
      'instantiateContentEntryProjectData',
      'setQuestionDataInStore',
      'setQuestionRightSidePanel',
      'questionForceSaveWithData',
      'showWarningDialog',
    ]),
    async cacheQuestionDataInLocalStorage() {
      LOCAL_STORAGE_BADGE_COUNT++;

      if (LOCAL_STORAGE_BADGE_COUNT >= LOCAL_STORAGE_BADGE_MAX_COUNT) {
        LOCAL_STORAGE_BADGE_COUNT = 0;
        let questionData = this.data;
        if (this.isSchoolProgramQuestion) {
          questionData.teacherTip = this.teacherTip;
        }

        localStorage.setItem('QUESTION_CACHED_DATA', JSON.stringify(questionData));
      }
    },
    ...mapMutations(['SET_CURRENT_QUESTION_DIALOG_DATA', 'SET_COMMENTS_DIALOG']),
    validateAnswerCriteria,
    confirmAndChangeQuestionType(val) {
      this.selectedQuestionType = val;
      if (this.data.questionType && val != this.data.questionType) {
        if (this.data._id && this.checkIfOptionsWillNotBeRetained(this.data.questionType, val)) {
          this.showWarningDialog();
        } else {
          this.changeInQuestionType();
        }
      }
    },
    changeInQuestionType() {
      if (this.data.questionType && this.selectedQuestionType != this.data.questionType) {
        //Deconstructs questionData and places rest of the keys under metaInformation
        var { questionData, ...metaInformation } = this.data;
        var { questionText } = questionData;
        let selectedQuestion = _.cloneDeep(this.DEFAULT_QUESTION_DATA[this.selectedQuestionType]);
        if (this.modifyChangeQuestionBaseData) {
          selectedQuestion = this.modifyChangeQuestionBaseData(selectedQuestion);
        }
        this.SET_CURRENT_QUESTION_DIALOG_DATA({
          data: selectedQuestion,
        });
        //Copies question text and meta information while changing question type
        let processedQuestionText = this.sanitizeQuestionText(questionText);
        this.data.questionData.questionText = processedQuestionText;
        questionData.questionText = processedQuestionText;
        this.modifyQuestionDataHash({ questionData, ...metaInformation }, this.data);
        if (
          this.lpType == 'questions' &&
          (this.data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
            this.data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA)
        ) {
          metaInformation.questionPurpose = questionsOprogramProject.QUESTION_PURPOSE_PRACTICE;
        }
        metaInformation.questionType = this.selectedQuestionType;
        Object.assign(this.data, metaInformation);
      }
    },
    sanitizeQuestionText(questionText) {
      try {
        let parsedQuestionText = JSON.parse(questionText);
        deleteQuestionSpecificItems(parsedQuestionText.content);
        return JSON.stringify(parsedQuestionText);
      } catch (e) {
        if (e instanceof SyntaxError) {
          return questionText;
        } else {
          console.log('You have some error while sanitizing questionText');
          console.log(e);
        }
      }
    },
    closeDialog() {
      localStorage.removeItem('QUESTION_CACHED_DATA');
      this.SET_COMMENTS_DIALOG(false);
      this.setQuestionRightSidePanel(false);
      this.closeDialogAndClearQuestionData();
    },
    async closeDialogAndSaveContent() {
      let text = [];
      let questionTypeBasedErros = validateQuestionDataBasedOnType(
        this.data,
        this.lpType,
        this.isLanguageSubject,
        this.showMetaFields,
        this.turnOffQuestionTextValidation
      );
      text = text.concat(questionTypeBasedErros);

      if (
        this.isSchoolProgramQuestion &&
        this.data.questionPurpose == questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT
      ) {
        let answerCriteriaErrors = validateAnswerCriteria(this.data.answerCriteria);
        text = text.concat(answerCriteriaErrors);
      }

      if (text.length == 0) {
        if (this.newlyCreatedQuestion) {
          // New question flow
          await this.instantiateContentEntryProjectData({
            type: this.lpType,
            data: this.data,
          });
        } else {
          if (this.lpType == 'learnometerQuestion') {
            this.data.isEdited = true;
          }
          // Existing question flow
          await this.setQuestionDataInStore({
            data: this.data,
          });
        }

        this.questionForceSaveWithData({
          callback: this.saveForm,
          validateForm: false,
          showLoader: false,
        });

        localStorage.removeItem('QUESTION_CACHED_DATA');
        this.closeDialog();
      } else {
        this.$notify({
          title: 'Fix the following',
          text: text.join('<br/>'),
          type: 'error',
          duration: 6000,
        });
      }
    },
    onAnswerCriteriaUpdate(data) {
      if (this.isSchoolProgramQuestion) {
        this.data.answerCriteria = data;
      }
    },
    getToolBarActions() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'table',
        'fullScreen',
      ];
    },
    onTecherTipChanged() {
      if (this.isSchoolProgramQuestion) {
        this.data.teacherTip = this.teacherTip;
        this.cacheQuestionDataInLocalStorage();
      }
    },
  },
  props: {
    saveForm: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    lpType: {
      type: String,
      default: 'questions',
    },
    lessonPlans: {
      type: Array,
      required: false,
    },
    isLanguageSubject: {
      type: Boolean,
      defualt: false,
    },
    isSchoolProgramQuestion: {
      type: String,
      required: true,
    },
    modifyQuestionDataHash: {
      type: Function,
      required: true,
    },
    checkIfOptionsWillNotBeRetained: {
      type: Function,
      required: true,
    },
    showMetaFields: {
      type: Boolean,
      default: true,
    },
    modifyChangeQuestionBaseData: {
      type: Function,
      default: undefined,
    },
    subSectionTitle: {
      type: String,
      default: undefined,
    },
    dynamicMetaDataKeySourceDataMap: {
      type: Object,
      default: () => {},
    },
    turnOffQuestionTextValidation: {
      type: Boolean,
      default: false,
    },
    allowedQuestionTypes: {
      type: Array,
      require: false,
      default: undefined,
    },
  },
};
</script>
