<template>
  <div class="lesson-plan-simple-view">
    <div class="lesson-plan-image">
      <img :src="lessonPlan.image_url" height="75px" width="75px" />
    </div>
    <div class="lesson-plan-details">
      <span>{{ lessonPlan.linkedName }}</span>
      <br />
      <span>{{ lessonPlan.description }}</span>
      <br />
      <span class="status-display">
        <span class="status" v-if="lessonPlan.status == LP_STATUS.IN_PROGRESS">
          <v-icon small>mdi-autorenew</v-icon>
          {{ AppTexts.IN_PROGRESS }}
        </span>
        <span class="status" v-else-if="lessonPlan.status == LP_STATUS.WAITING_FOR_REVIEW">
          <v-icon small>mdi-clock-outline</v-icon>
          {{ AppTexts.WAITING_FOR_REVIEW }}
        </span>
        <span class="status" v-else-if="lessonPlan.status == LP_STATUS.PUBLISHED">
          <v-icon small>mdi-check-bold</v-icon>
          {{ AppTexts.PUBLISHED }}
        </span>
        <span class="status" v-else-if="lessonPlan.status == LP_STATUS.COMPLETED">
          <v-icon small>mdi-check-bold</v-icon>
          {{ AppTexts.COMPLETED }}
        </span>
        <span class="status" v-else>
          <v-icon small>mdi-checkbox-blank-circle</v-icon>
          {{ AppTexts.NOT_STARTED }}
        </span>
      </span>
    </div>
    <div class="actions" style="display: none;">
      <v-btn @click="onViewClick(lessonPlan.libraryId)">view</v-btn>&nbsp;
      <v-btn @click="generateAudioHandler(lessonPlan.libraryId)">generate audio</v-btn>
    </div>
  </div>
</template>

<script>
import { LP_STATUS } from '@/constants/LessonPlan';
import AppTexts from '@/constants/AppTexts';
import { mapActions } from 'vuex';

export default {
  name: 'LessonPlanSimpleView',
  props: ['lessonPlan'],
  data() {
    return {
      LP_STATUS,
      AppTexts
    };
  },
  methods: {
    ...mapActions(['generateLessonPlanAudio']),
    onViewClick(libraryId) {
      this.$router.push({
        name: 'lpForm',
        params: { libraryId },
        query: {
          lpType: this.lessonPlan.lpType,
          blockSequence: this.lessonPlan.blockSequence,
          blockTitle: this.lessonPlan.blockTitle,
          productName: this.$route.query.productName,
          grade: this.$route.query.gradeName,
          subject: this.$route.query.subjectName,
          boardName: this.$route.query.boardName
        }
      });
    },
    generateAudioHandler(libraryId) {
      this.generateLessonPlanAudio({ libraryId });
    }
  }
};
</script>

<style>
.lesson-plan-simple-view .lesson-plan-image,
.lesson-plan-simple-view .lesson-plan-details {
  float: left;
}

.lesson-plan-simple-view .actions {
  float: right;
}

.lesson-plan-simple-view .lesson-plan-details {
  margin-left: 20px;
}
</style>
