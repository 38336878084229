import { apolloClient } from '@/apollo';
import { logError } from '@/utils';
import { lessonPlansInBlock } from '../../apiQueries/block';

export const BLOCK_ACTION = {
  async getLessonPlansInBlock(context, { id }) {
    try {
      const _lessonPlansInBlock = await apolloClient.query({
        query: lessonPlansInBlock(id),
      });

      const blockResponse = (_lessonPlansInBlock.data || {}).block;

      if (blockResponse) {
        let lessonPlans = constructDisplayNameForLp(
          blockResponse.lessonPlans || [],
          blockResponse.bId
        );

        if (blockResponse && blockResponse.linkedBlockIds) {
          const linkedBlockDetailsResponse = await Promise.all(
            blockResponse.linkedBlockIds.map((linkedBlockId) => {
              return apolloClient.query({
                query: lessonPlansInBlock(linkedBlockId),
              });
            })
          );

          linkedBlockDetailsResponse.forEach((linkedBlockResponse) => {
            const linkedBlock = (linkedBlockResponse.data || {}).block;

            if (linkedBlock) {
              const linkedBlockLessonPlan = constructDisplayNameForLp(
                linkedBlock.lessonPlans || [],
                linkedBlock.bId
              );

              lessonPlans = lessonPlans.concat(linkedBlockLessonPlan);
            }
          });
        }

        context.commit('SET_LESSON_PLANS_IN_BLOCK', lessonPlans);
      } else {
        throw new Error('Something went wrong...');
      }
    } catch (er) {
      logError(er);
      context.commit('SET_LESSON_PLANS_IN_BLOCK', []);
    }
  },
};

function constructDisplayNameForLp(lessonPlans, bId) {
  return lessonPlans.map((lessonPlan) => {
    return {
      ...lessonPlan,
      displayName: `${bId} - LP ${lessonPlan.displaySequence}: ${lessonPlan.name}`,
    };
  });
}
