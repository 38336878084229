<template>
  <div
    :class="isDisabled ? 'single-question-view-wrapper disabled' : 'single-question-view-wrapper '"
  >
    <div class="question-details-wrapper">
      <div class="display-flex">
        <div
          class="question-select-checkbox"
          v-if="!isQuestionGroupQuestionView && !isReuseQuestion"
        >
          <v-checkbox
            dense
            @change="(isChecked) => $emit('onSelectQuestion', isChecked, data)"
            class="ma-0 pa-0"
            hide-details
            v-model="isSelected"
            :disabled="isDisabled || !(canSelectItem || isSelected)"
          />
        </div>
        <div class="question-details-inner-wrapper">
          <div class="question-details">
            <div class="display-flex">
              <div class="question-type">
                <span v-if="isQuestionGroupQuestionView" class="question-index-text"
                  >Question {{ index }} :
                </span>
                <span
                  v-else
                  style="cursor: pointer;"
                  @click="$emit('showQuestionFullScreenDialog', data)"
                  class="question-index-text"
                  ><u>Question {{ index }}</u> :
                </span>
                <span class="question-type-text font-weight-bold">{{
                  getQuestionTypeDisplayText(data.questionType)
                }}</span>
              </div>
              <div class="time" style="margin-left: 20px;">
                <span class="label">Time : </span
                ><span class="value">{{ convertToPluralText(data.time, 'Min') }}</span>
              </div>
            </div>
            <div
              class="display-flex question-details-trailing"
              v-if="isDisabled && !isQuestionGroupQuestionView"
            >
              <!-- <div class="sequence-number">
                <span class="label">Sequence number : </span><span class="value">-</span>
              </div> -->
              <div class="disabled-reason-div">
                {{ reasonText }}
              </div>
            </div>
          </div>
          <div class="question-meta-data display-flex">
            <div class="difficuty">
              <span class="label">Difficulty : </span
              ><span class="value">{{ data.difficulty }}</span>
            </div>
            <div class="skill">
              <span class="label">Skill : </span><span class="value">{{ skillName }}</span>
            </div>
            <div class="sub-skill">
              <span class="label">Sub Skill : </span><span class="value">{{ subSkillName }}</span>
            </div>
            <div class="academic-year">
              <span class="label">Academic Year : </span
              ><span class="value">{{ data.academicYear }}</span>
            </div>
            <div class="sub-skill" v-if="data.languageSkill">
              <span class="label">Langugae Skill : </span
              ><span class="value">{{ startCase(data.languageSkill) }}</span>
            </div>
            <div class="sub-skill" v-if="data.questionPurpose">
              <span class="label">Purpose : </span
              ><span class="value">{{ startCase(data.questionPurpose) }}</span>
            </div>
            <div class="sub-skill" v-if="lessonPlanName && lessonPlanName.length > 0">
              <span class="label">Lesson Plan : </span
              ><span class="value">{{ lessonPlanName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-divider style="margin: 15px 0px;" />
    <div class="question-content-wrapper">
      <div
        v-html="RTETextToHTML(data.questionData.questionText, isAssetUploadRequired)"
        class="editor__content"
      ></div>
    </div>
  </div>
</template>

<script>
import { getQuestionTypeDisplayText } from '@/utils/questionUtils.js';
import { convertToPluralText, getAssetUploadRequiredStatus } from '@/utils';
import { RTETextToHTML } from '@xseededucation/rte-helper-functions';
import { startCase } from 'lodash';

export default {
  name: 'SingleQuestionView',
  computed: {
    isAssetUploadRequired() {
      let projectCurrentStage = Array.isArray(this.data)
        ? this.data[0].projectCurrentStage
        : this.data.projectCurrentStage;

      return this.getAssetUploadRequiredStatus(projectCurrentStage);
    },
    skillName() {
      if (this.questionMetaInfo && this.questionMetaInfo.skills && this.data.skill) {
        const _skill = this.questionMetaInfo.skills.find(
          (questionMetaInfo) => questionMetaInfo.id == this.data.skill
        );
        if (_skill) {
          return _skill.name;
        }
      }

      return '';
    },
    subSkillName() {
      if (this.questionMetaInfo && this.questionMetaInfo.subSkills && this.data.subSkill) {
        const _subSkill = this.questionMetaInfo.subSkills[this.data.skill].find(
          (_questionMetaInfo) => _questionMetaInfo.id == this.data.subSkill
        );
        if (_subSkill) {
          return _subSkill.name;
        }
      }

      return '';
    },
    lessonPlanName() {
      try {
        return (
          this.lessonPlans.find((_lp) => _lp.library_id == this.data.lessonPlanLibraryId).name || ''
        );
      } catch (error) {
        return '';
      }
    },
    isDisabled() {
      return this.data.linkDetails && this.data.linkDetails.disabled;
    },
    reasonText() {
      if (this.isDisabled && this.data.linkDetails) {
        const isAddedInCurrentLp =
          this.$route.params.libraryId.toString() == this.data.linkDetails.lpId.toString();

        return isAddedInCurrentLp ? 'Already Added' : `Added in ${this.data.linkDetails.lpName}`;
      } else {
        return '';
      }
    },
  },
  methods: {
    startCase,
    getQuestionTypeDisplayText,
    convertToPluralText,
    RTETextToHTML,
    getAssetUploadRequiredStatus,
  },
  props: {
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    questionMetaInfo: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isQuestionGroupQuestionView: {
      type: Boolean,
      required: false,
      default: false,
    },
    canSelectItem: {
      type: Boolean,
      required: false,
    },
    lessonPlans: {
      type: Array,
      required: false,
      default: () => [],
    },
    isReuseQuestion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.single-question-view-wrapper {
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  padding: 19px 25px;
}
.question-details-inner-wrapper {
  width: 100%;
}
.question-select-checkbox {
  margin-right: 20px;
}

.question-details {
  display: flex;
  justify-content: space-between;
}
.question-index-text {
  text-transform: uppercase;
  font-size: 16px;
}
.question-type-text {
  opacity: 0.75;
}
.question-details-trailing {
  font-size: 16px;
}
.question-meta-data {
  font-size: 12px;
}
.question-details-trailing div .label,
.question-meta-data div .label {
  font-weight: 700;
  color: rgb(68, 68, 68);
}
.question-details-trailing div .value,
.question-meta-data div .value {
  font-weight: 400;
  color: rgb(68, 68, 68);
}
.question-details-trailing div:not(:last-child),
.question-meta-data div:not(:last-child) {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 2px solid rgb(68, 68, 68);
}
</style>

<style>
.single-question-view-wrapper.disabled *:not(.disabled-reason-div) {
  color: rgba(0, 0, 0, 0.5) !important;
}
.question-content-wrapper p {
  margin-bottom: 0px;
}
.disabled-reason-div {
  background: rgb(187, 22, 27);
  color: #fff !important;
  border-radius: 5px;
  opacity: 1;
  padding: 2px 5px;
  font-size: 12px;
}
</style>
