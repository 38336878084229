<template>
  <v-form ref="actionSectionForm" class="section-title-cnr">
    <Step
      :items="lessonPlanData.action.instructions"
      :canShowStepNumber="true"
      :canShowInstructionBreakCheckbox="true"
      :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
      :canShowAddNewStepButton="true"
      :addNewStepHandler="addActionStep"
      :hasSubStep="true"
      :addNewSubStepHandler="addActionSubStep"
      :allowDeleteStep="true"
      :deleteStepHandler="deleteActionStep"
      :allowDeleteSubStep="true"
      :deleteSubStepHandler="deleteActionSubStep"
      :descriptionPlaceholder="AppTexts.STEP_DESCRIPTION"
      :maxNoOfSteps="12"
      :maxNoOfSubStepsInStep="3"
      :maxSumOfStepsAndSubSteps="12"
      :supportAssets="true"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :getPreviewUrl="getActionInstructionAssetPreviewUrl"
      stepAssetIdentifier="action.instructions"
      :actionSignedUrls="actionSignedUrls"
      :canShowDiffIcon="canShowDiffIcon"
      stepDiffIdentifier="action.instructions[<stepIndex>].description"
      :isStepDescriptionChanged="isStepDescriptionChanged"
      subStepDiffIdentifier="action.instructions[<stepIndex>].children[<subStepIndex>].description"
      :isSubStepDescriptionChanged="isSubStepDescriptionChanged"
      assetBriefDiffIdentifier="action.instructions[<stepIndex>].assets[<assetIndex>].brief"
      :isAssetBriefChanged="isAssetBriefChanged"
      assetImageDiffIdentifierPrefix="action.instructions[<stepIndex>].assets[<assetIndex>]"
      :isAssetImageChanged="isAssetImageChanged"
      :showDividerBetweenSteps="true"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="true"
      :canAppproveAsset="canApproveAsset"
    />
    <div class="pad-Ts mgn-Bs">
      <XSDisplayCard :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isActionTeacherTipChanged()"
          fieldIdentifier="action.teacher_tip"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="lessonPlanData.action.teacher_tip"
          :counter="220"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
        <br />
      </XSDisplayCard>
    </div>
  </v-form>
</template>

<script>
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import Step from '@/components/inputs/Step';
import AppTexts from '@/constants/AppTexts';
import AssetTypes from '@/constants/AssetTypes';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { ACTION_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import { mapActions } from 'vuex';

export default {
  name: 'ActionSection',
  components: {
    Step,
    RichTextEditor,
    XSDisplayCard,
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      ACTION_BLOCK_KEY_OF_STATE_LP_DATA,
      BASIC_TOOLS,
      FILE_UPLOADING_STATUS,
    };
  },
  methods: {
    ...mapActions(['importSingleAssetAction', 'deleteSingleAssetAction']),
    getActionInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        signedBriefUrl: '',
        signedUrl: '',
      };
      if (this.actionSignedUrls) {
        let instruction = this.actionSignedUrls.instructions[stepIndex];
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.signedUrl = asset.signedUrl;
            }
            previewUrl.signedBriefUrl = asset.signedBriefUrl;
          }
        }
      }

      return previewUrl;
    },
    isStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.instructions[stepIndex].description.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isSubStepDescriptionChanged(stepIndex, subStepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.actionDiff.instructions[stepIndex].children[subStepIndex].description.isChanged ==
            true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetBriefChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.instructions[stepIndex].assets[assetIndex].brief.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetImageChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          if (this.isAssetUploadRequired) {
            return this.actionDiff.instructions[stepIndex].assets[assetIndex].url.isChanged == true;
          } else {
            return (
              this.actionDiff.instructions[stepIndex].assets[assetIndex].brief_url.isChanged == true
            );
          }
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isActionTeacherTipChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.actionDiff.teacher_tip.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getFileNameFromUrl,
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    addActionSubStep: {
      type: Function,
      required: true,
    },
    deleteActionStep: {
      type: Function,
      required: true,
    },
    deleteActionSubStep: {
      type: Function,
      required: true,
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
    addActionStep: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionSignedUrls: {
      type: Object,
      required: true,
    },
    actionDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canApproveAsset: { type: Boolean, required: true },
  },
};
</script>
