<template>
  <v-dialog :value="loadingStatus" max-width="290" persistent>
    <v-card>
      <v-card-title style="text-align: center; display: block;" class="headline">{{
        loadingText
      }}</v-card-title>
      <v-card-text style="text-align: center;">
        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loadingText: {
      type: String,
      required: false,
      default: 'Loading...'
    },
    loadingStatus: {
      type: Boolean,
      required: true
    }
  }
};
</script>
