import gql from 'graphql-tag';

export const lessonPlansInBlock = (id) => {
  return gql`
    query {
      block(id: "${id}") {
        bId
        lessonPlans {
          id
          name
          type
          sequence
          displaySequence
        }
        linkedBlockIds
      }
    }
  `;
};
