<template>
  <div>
    <!-- Grade and Subject Selection Dialog -->
    <v-dialog v-model="dialogState" persistent max-width="800px">
      <v-card>
        <v-card-title class="headline">
          <v-icon left>mdi-plus-box</v-icon> Create Confidence Block
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-select
                  label="Block Sequence Number"
                  v-model="blockData.blockSequenceNumber"
                  :items="Array.from({ length: 30 }, (_, i) => i + 1)"
                  placeholder="Select"
                  required
                  outlined
                >
                  <template #prepend>
                    <v-icon left>mdi-numeric</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Block Title"
                  v-model="blockData.title"
                  placeholder="Enter Block Title"
                  required
                  outlined
                >
                  <template #prepend>
                    <v-icon>mdi-format-title</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Block ID"
                  v-model="blockData.blockId"
                  placeholder="Enter Block ID"
                  required
                  outlined
                >
                  <template #prepend>
                    <v-icon>mdi-identifier</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- Grade Selection -->
              <v-col cols="4">
                <v-select
                  :items="grades"
                  item-text="name"
                  item-value="_id"
                  label="Select Grade"
                  v-model="selectedGrade"
                  :disabled="grades.length === 0"
                  outlined
                >
                  <template #prepend>
                    <v-icon>mdi-school</v-icon>
                  </template>
                </v-select>
              </v-col>

              <!-- Subject Selection -->
              <v-col cols="4">
                <v-select
                  :items="subjects"
                  item-text="name"
                  item-value="_id"
                  label="Select Subject"
                  v-model="selectedSubject"
                  :disabled="!selectedGrade"
                  outlined
                >
                  <template #prepend>
                    <v-icon>mdi-book-open-variant</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="confirmSelection"
            :disabled="!selectedGrade || !selectedSubject || !blockData.blockId || !blockData.title"
          >
            <v-icon left>mdi-check</v-icon> Next
          </v-btn>
          <v-btn color="red darken-1" text @click="closeDialog">
            <v-icon left>mdi-close-circle</v-icon> Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Assignee Form Dialog -->
    <v-dialog v-model="showAssigneeForm" content-class="learnometer-topic-form-dialog">
      <v-card height="380">
        <v-card-title class="headline grey lighten-2" primary-title>
          <v-icon left>mdi-account-multiple</v-icon> {{ createOrEditQuestionSetDialogText }}
        </v-card-title>
        <div class="form-content">
          <v-form ref="project_assignee_form" :key="`projectFormPopUp`" class="ma-4">
            <ProjectStageAssigneeFormContent
              :projectData="blockData"
              :getUsersByRoles="getUsersByRoles"
              colsPerRow="3"
            />
          </v-form>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 0;
            margin-top: -60px;
            padding-right: 10px;
          "
        >
          <v-btn text @click="showAssigneeForm = false">
            <v-icon left>mdi-arrow-left</v-icon> Cancel
          </v-btn>
          <v-btn color="primary" @click="createBlockProject">
            <v-icon left>mdi-check-circle</v-icon> Create
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ProjectStageAssigneeFormContent from '../ProjectStageAssigneeFormContent.vue';

export default {
  name: 'GradeSubjectDialog',
  components: {
    ProjectStageAssigneeFormContent,
  },
  props: {
    grades: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      type: Boolean,
      required: true,
    },
    createOrEditQuestionSetDialogText: {
      type: String,
      required: true,
    },
    getUsersByRoles: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogState: this.value, // Control dialog open/close via v-model
      selectedGrade: '',
      selectedSubject: '',
      showAssigneeForm: false,
      blockData: {
        blockId: '',
        title: '',
        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
        confidenceStandardId: '',
        subjectId: '',
        grade: '',
        entityId: '',
        workflowStatus: '',
        blockSequenceNumber: '',
      },
    };
  },
  watch: {
    value(newVal) {
      this.dialogState = newVal; // Keep dialogState synced with the parent
    },
    dialogState(newVal) {
      this.$emit('input', newVal); // Update the parent when dialogState changes
      if (newVal) {
        this.resetForm(); // Reset the form when the dialog opens
      }
    },
  },
  methods: {
    ...mapActions(['createConfidenceBlock']),
    resetForm() {
      // Reset all form data
      this.selectedSubject = '';
      this.selectedGrade = '';
      this.blockData = {
        blockId: '',
        title: '',
        assignees: {
          author: { id: '' },
          reviewer1: { id: '' },
          reviewer2: { id: '' },
          academicSignOff: { id: '' },
          editor: { id: '' },
          visualizer: { id: '' },
          assetUploader: { id: '' },
          assetAuthorizer: { id: '' },
          proofReader: { id: '' },
        },
        confidenceStandardId: '',
        subjectId: '',
        grade: '',
        entityId: '',
        workflowStatus: '',
        blockSequenceNumber: '',
      };
    },
    confirmSelection() {
      this.$emit('confirm', {
        grade: this.selectedGrade,
        subject: this.selectedSubject,
        blockId: this.blockData.blockId,
        title: this.blockData.title,
        blockSequenceNumber: this.blockData.blockSequenceNumber,
      });
      this.closeDialog();
      this.showAssigneeForm = true;
    },
    closeDialog() {
      this.dialogState = false;
    },
    createBlockProject() {
      const payload = {
        title: this.blockData.title,
        blockId: this.blockData.blockId,
        blockSequenceNumber: this.blockData.blockSequenceNumber,
        subjectId: this.selectedSubject,
        standardId: this.selectedGrade,
        assignees: this.blockData.assignees,
      };
      this.createConfidenceBlock(payload);
      this.showAssigneeForm = false;
      this.resetForm();
    },
  },
  computed: {
    subjects() {
      if (!this.grades) return [];
      let subjectsData =
        (this.grades.find((value) => value._id == this.selectedGrade) || {}).subjects || [];
      return subjectsData;
    },
  },
};
</script>
