<template>
  <v-card class="pa-4 rounded-lg">
    <div class="mb-4">
      <SubSection title="Create Question" />
    </div>
    <v-btn
      x-large
      :disabled="isReadOnly"
      color="primary"
      class="mr-8"
      width="320"
      style="font-size: 18px;"
      depressed
      @click="openGroupQuestionDialog"
      >Group Question</v-btn
    >
    <v-btn
      x-large
      :disabled="isReadOnly"
      width="320"
      class="mr-8"
      color="primary"
      @click="openQuestionDialog"
      style="font-size: 18px;"
      depressed
      >Individual Question</v-btn
    >
    <v-btn
      x-large
      :disabled="isReadOnly"
      width="320"
      color="primary"
      @click="openSourceBlockFilterDialog"
      style="font-size: 18px;"
      depressed
    >
      Reuse Existing Question</v-btn
    >
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import SubSection from '@/components/inputs/SubSection.vue';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import _ from 'lodash';

export default {
  components: {
    SubSection,
  },
  methods: {
    ...mapMutations([
      'SET_SHOW_QUESTION_DIALOG',
      'SET_CURRENT_QUESTION_DIALOG_DATA',
      'SET_SHOW_GROUP_QUESTION_DIALOG',
      'SHOW_SOURCE_BLOCK_FILTER_DIALOG',
    ]),
    openQuestionDialog() {
      this.SET_SHOW_QUESTION_DIALOG(true);
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: _.cloneDeep(DEFAULT_QUESTION_DATA.mcq) });
    },
    openGroupQuestionDialog() {
      this.SET_SHOW_GROUP_QUESTION_DIALOG(true);
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: [_.cloneDeep(DEFAULT_QUESTION_DATA.mcq)] });
    },
    openSourceBlockFilterDialog() {
      this.SHOW_SOURCE_BLOCK_FILTER_DIALOG(true);
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
