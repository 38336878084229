import { baseMixin } from './base';
import { validateAssetAuthorized } from '@/utils';
import { DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION } from '@/constants/LessonPlan.js';
export const standardLessonMixin = {
  mixins: [baseMixin],
  data() {
    return {
      Schema: {
        action: {
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
        },
        application: {
          showLegacyClassWorkHomeWork: true,
          showClassWorkHomeWork: false,
          showDifferentiationTips: true,
        },
        assessment: {
          canShowCheck: true,
        },
      },
    };
  },
  methods: {
    unApprovedAssetValidation() {
      let countUnApprovedAssets = validateAssetAuthorized(
        this.lessonPlan.data,
        ['assetId'],
        {
          assetId: 'assetApproved',
        },
        ['brief', 'placeholder', 'multimedia'],
        0
      );
      if (countUnApprovedAssets > 0) {
        this.$notify({
          title: 'Failed',
          text: `There are ${countUnApprovedAssets} unapproved assets`,
          type: 'error',
          duration: -1,
        });
        return false;
      }
      return true;
    },
  },
};
