<template>
  <div :style="getDiffWrapperStyle()">
    <div>
      <div class="title">
        Changes in {{ getTitle() }}
        <span class="close-button" @click="closeHandler">
          CLOSE
          <v-icon title="close" color="#cb4c52">mdi-close</v-icon>
        </span>
      </div>
      <div v-if="fieldDiff.fetchStatus == 'in_progress'" class="content-padding">
        Please wait. Fetching data in progress...
      </div>
      <div
        v-else-if="fieldDiff.fetchStatus == 'completed'"
        class="content-padding"
        style="margin-right: 10px;"
      >
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.AUTHORING1_STAGE)"
          :roleName="AppTexts.AUTHOR"
          :time="formatTime(fieldDiff.data.authoring1StageUpdatedAt)"
          :text="
            getDisplayDiffTextBetweenChanges('', HtmlConvert(fieldDiff.data.authoring1Stage)) || ''
          "
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.REVIEW1_STAGE)"
          :roleName="AppTexts.REVIEWER_1"
          :time="formatTime(fieldDiff.data.review1StageUpdatedAt)"
          :text="review1StageDiffText"
          class="non-first-diff-container"
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.REVIEW2_STAGE)"
          :roleName="AppTexts.REVIEWER_2"
          :time="formatTime(fieldDiff.data.review2StageUpdatedAt)"
          :text="review2StageDiffText"
          class="non-first-diff-container"
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.AUTHORING2_STAGE)"
          :roleName="AppTexts.AUTHOR"
          :time="formatTime(fieldDiff.data.authoring2StageUpdatedAt)"
          :text="authoring2StageDiffText"
          class="non-first-diff-container"
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.ACADEMIC_SIGN_OFF_STAGE)"
          :roleName="AppTexts.ASO"
          :time="formatTime(fieldDiff.data.academicSignOffStageUpdatedAt)"
          :text="academicSignOffStageDiffText"
          class="non-first-diff-container"
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.EDITING_STAGE)"
          :roleName="AppTexts.EDITOR"
          :time="formatTime(fieldDiff.data.editingStageUpdatedAt)"
          :text="editingStageDiffText"
          class="non-first-diff-container"
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.VISUALIZING_STAGE)"
          :roleName="AppTexts.VISUALIZER"
          :time="formatTime(fieldDiff.data.visualizingStageUpdatedAt)"
          :text="visualizingStageDiffText"
          class="non-first-diff-container"
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.ASSET_UPLOAD_STAGE)"
          :roleName="AppTexts.ASSET_UPLOADER"
          :time="formatTime(fieldDiff.data.assetUploadStageUpdatedAt)"
          :text="assetUploadStageDiffText"
          class="non-first-diff-container"
        />
        <DiffDetails
          v-if="canShowDiffContent(PROJECT_LESSON_PLAN_STAGES.ASSET_AUTHORIZING_STAGE)"
          :roleName="AppTexts.ASSET_AUTHORIZER"
          :time="formatTime(fieldDiff.data.assetAuthorizingStageUpdatedAt)"
          :text="assetAuthorizingStageDiffText"
          class="non-first-diff-container"
        />
      </div>
      <div v-else-if="fieldDiff.fetchStatus == 'error'" class="content-padding">
        Something went wrong. Please try again after some time.
      </div>
    </div>
  </div>
</template>

<script>
import DiffDetails from '@/components/ContentDiff/DiffDetails';
import { HtmlConvert, decodeHtml, filterImageContent } from '@xseededucation/rte-helper-functions';
import AppTexts from '@/constants/AppTexts';
import {
  PROJECT_LESSON_PLAN_STAGES,
  PROJECT_LESSON_PLAN_STAGES_IN_ORDER,
} from '@/constants/Project';
import { diff_match_patch } from 'diff-match-patch';
import { capitalCase } from 'change-case';
import { formatTime, getAssetUploadRequiredStatus } from '@/utils';
import { LP_STAGES } from '@/constants/LessonPlan';
import marked from 'marked';

export default {
  name: 'Diff',
  props: {
    fieldDiff: {
      type: Object,
      required: true,
    },
    closeHandler: {
      type: Function,
      required: true,
    },
    currentStage: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isForContentEntry: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DiffDetails,
  },
  data() {
    return {
      AppTexts,
      PROJECT_LESSON_PLAN_STAGES,
      LP_STAGES,
    };
  },
  methods: {
    capitalCase,
    formatTime,
    HtmlConvert,
    decodeHtml,
    getAssetUploadRequiredStatus,
    marked,
    filterImageContent,
    getNonSkippedPreviousStageData(stageName) {
      let stagesArray = Object.keys(LP_STAGES);
      let currentStageIndex = stagesArray.indexOf(stageName);
      let data;
      for (let i = currentStageIndex; i >= 0; i--) {
        if (this.fieldDiff.data[stagesArray[i]]) {
          data = this.fieldDiff.data[stagesArray[i]];
          break;
        }
      }
      return data;
    },
    canShowDiffContent(stageName) {
      let stageNameIndex = PROJECT_LESSON_PLAN_STAGES_IN_ORDER.indexOf(stageName);
      let currentStageIndex = PROJECT_LESSON_PLAN_STAGES_IN_ORDER.indexOf(this.currentStage);

      return stageNameIndex >= 0 && stageNameIndex < currentStageIndex;
    },
    getDisplayDiffTextBetweenChanges(oldText, newText) {
      if (!newText || !newText.output) return 'Skipped';
      let dmp = new diff_match_patch();
      let diff = dmp.diff_main(oldText.output || '', newText.output || '');
      dmp.diff_cleanupSemantic(diff);
      let prettyHtml = dmp.diff_prettyHtml(diff);
      if (oldText.type == 'html' || newText.type == 'html') {
        return this.decodeHtml(prettyHtml);
      }
      return this.marked(prettyHtml);
    },
    getTitle() {
      let list = this.title.split('.');
      let finalTitle = list[list.length - 1];
      return this.capitalCase(finalTitle);
    },
    getDiffWrapperStyle() {
      if (this.isForContentEntry) {
        return 'width: 100%;';
      }

      return 'width: 100%; margin-top: ' + (window.scrollY + 120) + 'px;';
    },
  },
  computed: {
    isAssetUploadRequired() {
      return this.getAssetUploadRequiredStatus(this.currentStage);
    },
    review1StageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('authoring1Stage'),
        false
      );
      if (this.fieldDiff.data.review1StageUpdatedAt == 0) return 'Skipped';
      let currentStage = this.HtmlConvert(this.fieldDiff.data.review1Stage, false);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
    review2StageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('review1Stage'),
        false
      );
      if (this.fieldDiff.data.review2StageUpdatedAt == 0) return 'Skipped';
      let currentStage = this.HtmlConvert(this.fieldDiff.data.review2Stage, false);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
    authoring2StageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('review2Stage'),
        false
      );
      if (this.fieldDiff.data.authoring2StageUpdatedAt == 0) return 'Skipped';
      let currentStage = this.HtmlConvert(this.fieldDiff.data.authoring2Stage, false);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
    academicSignOffStageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('authoring2Stage'),
        false
      );
      if (this.fieldDiff.data.academicSignOffStageUpdatedAt == 0) return 'Skipped';
      let currentStage = this.HtmlConvert(this.fieldDiff.data.academicSignOffStage, false);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
    editingStageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('academicSignOffStage'),
        false
      );
      if (this.fieldDiff.data.editingStageUpdatedAt == 0) return 'Skipped';
      let currentStage = this.HtmlConvert(this.fieldDiff.data.editingStage, false);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
    visualizingStageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('editingStage'),
        false
      );
      if (this.fieldDiff.data.visualizingStageUpdatedAt == 0) return 'Skipped';
      let currentStage = this.HtmlConvert(this.fieldDiff.data.visualizingStage, false);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
    assetUploadStageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('visualizingStage'),
        false
      );
      let currentStage = this.HtmlConvert(this.fieldDiff.data.assetUploadStage, true);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
    assetAuthorizingStageDiffText() {
      let previousStage = this.HtmlConvert(
        this.getNonSkippedPreviousStageData('assetUploadStage'),
        true
      );
      let currentStage = this.HtmlConvert(this.fieldDiff.data.assetAuthorizingStage, true);
      previousStage = this.filterImageContent(previousStage, '   ');
      return this.getDisplayDiffTextBetweenChanges(previousStage, currentStage);
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px;
  background-color: #e2e2e2;
  font-weight: 500;
}
.close-button {
  cursor: pointer;
  float: right;
  margin-right: 10px;
  color: #cb4c52;
}
.content-padding {
  padding: 10px;
}
.non-first-diff-container {
  margin-top: 10px;
}
</style>
