const mutations = {
  setFetchConfidenceStandardsSubjects: (state, payload) => {
    console.log('MUTATION#setFetchConfidenceStandardsSubjects#START');
    if (payload) {
      console.log('MUTATION#setFetchConfidenceStandardsSubjects#UPDATE');
      state.fetchStandardsSubjectsApiResponse.status = payload.status;
      state.fetchStandardsSubjectsApiResponse.message = payload.message;
      state.fetchStandardsSubjectsApiResponse.data = payload.data || [];
    }

    console.log('MUTATION#setFetchConfidenceStandardsSubjects#END');
  },
  setIsConfidenceUser: (state, payload) => {
    console.log('MUTATION#setIsConfidenceUser#START');
    console.log('PAYLOAD', payload);
    if (payload) {
      console.log('MUTATION#setIsConfidenceUser#UPDATE');
      state.isConfidenceUser = payload.data.isConfidenceUser || false;
      console.log(state.isConfidenceUser);
      console.log('MUTATION#setIsConfidenceUser#UPDATE');
    }

    console.log('MUTATION#setFetchConfidenceBlocks#END');
  },
  setFetchConfidenceBlocks: (state, payload) => {
    console.log('MUTATION#setFetchConfidenceBlocks#START');
    if (payload) {
      console.log('MUTATION#setFetchConfidenceBlocks#UPDATE');
      state.fetchConfidenceBlocksApiResponse.status = payload.status;
      state.fetchConfidenceBlocksApiResponse.message = payload.message;
      state.fetchConfidenceBlocksApiResponse.data = payload.data.data || [];
    }

    console.log('MUTATION#setFetchConfidenceBlocks#END');
  },
  setFetchConfidenceQuestions: (state, payload) => {
    console.log('MUTATION#setFetchConfidenceQuestions#START');
    if (payload) {
      console.log('MUTATION#setFetchConfidenceQuestions#UPDATE');
      state.fetchConfidenceQuestionsApiResponse.status = payload.status;
      state.fetchConfidenceQuestionsApiResponse.message = payload.message;
      state.fetchConfidenceQuestionsApiResponse.data = payload.data.data || {};
    }

    console.log('MUTATION#setFetchConfidenceQuestions#END');
  },

  setFetchConfidenceMetadata: (state, payload) => {
    console.log('MUTATION#setFetchConfidenceMetadata#START');
    if (payload) {
      console.log('MUTATION#setFetchConfidenceMetadata#UPDATE');
      state.fetchConfidenceMetadataApiResponse.status = payload.status;
      state.fetchConfidenceMetadataApiResponse.message = payload.message;
      state.fetchConfidenceMetadataApiResponse.data = payload.data.data || {};
    }

    console.log('MUTATION#setFetchConfidenceMetadata#END');
  },
  setFetchConfidenceBlocksOnApiFailure: (state, payload) => {
    // TODO: Make this work
    console.log('MUTATION#setFetchConfidenceBlocksOnApiFailure#START');
    if (payload) {
      console.log('MUTATION#setFetchConfidenceBlocksOnApiFailure#UPDATE');
      state;
      payload;
    }

    console.log('MUTATION#setFetchConfidenceBlocksOnApiFailure#END');
  },
  setStandardsDataOnApiError: (state, payload) => {
    // TODO: Make this work
    console.log('MUTATION#setFetchConfidenceBlocksOnApiFailure#START');
    if (payload) {
      console.log('MUTATION#setFetchConfidenceBlocksOnApiFailure#UPDATE');
      state;
      payload;
    }

    console.log('MUTATION#setFetchConfidenceBlocksOnApiFailure#END');
  },
  resetFetchConfidenceQuestionsState(state) {
    state.fetchConfidenceQuestionsApiResponse = {
      status: 'completed',
      message: 'Successfully fetched data!',
      data: [],
    };
  },

  resetFetchConfidenceMetadataState(state) {
    state.fetchConfidenceMetadataApiResponse = {
      status: 'completed',
      message: 'Successfully fetched data!',
      data: [],
    };
  },
};
export default mutations;
