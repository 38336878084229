<template>
  <div>
    <v-row dense>
      <v-col :cols="3">
        <v-text-field
          class="micro-topic-filter-search-field"
          placeholder="Search Microtopic ID"
          hide-details
          dense
          outlined
          :value="filterData.mtId"
          @keydown.enter="(event) => $emit('onSearchApply', event, { key: 'mtId' })"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <img
                @click="$emit('onSearchApply')"
                class="micro-topic-search"
                width="24"
                height="26"
                src="@/assets/search.svg"
                alt
              />
            </v-fade-transition>
          </template>
        </v-text-field>
      </v-col>
      <v-col :cols="4">
        <v-text-field
          class="micro-topic-filter-search-field"
          placeholder="Search Microtopic name"
          hide-details
          dense
          outlined
          :value="filterData.name"
          @keydown.enter="(event) => $emit('onSearchApply', event, { key: 'name' })"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <img
                @click="$emit('onSearchApply')"
                class="micro-topic-search"
                width="24"
                height="26"
                src="@/assets/search.svg"
                alt
              />
            </v-fade-transition>
          </template>
        </v-text-field>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row dense>
      <v-col :style="filterFielsStyle" dense v-for="(filter, index) in filters" :key="index">
        <v-select
          :label="filter.label"
          outlined
          dense
          hide-details
          on
          class="filter-select-field"
          v-model="filter.value"
          :items="filter.items"
          @change="(event) => $emit('onSearchApply', event, filter)"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'MicroTopicFilter',
  computed: {
    filterFielsStyle: function () {
      const flitersCount = this.filters.length;
      return 'width: ' + 100 / flitersCount + '%;';
    },
  },
  methods: {},
  props: {
    filters: {
      type: Array,
      required: true,
    },
    filterData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.filter-select-field,
.filter-select-field label {
  font-size: 14px;
  font-weight: 500;
  color: black;
}
.filter-select-field .v-label:not(.v-label--active) {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-5px);
}
.micro-topic-filter-search-field .v-label:not(.v-label--active) {
  color: #242424;
  font-size: 14px;
  transform: translateY(-5px);
}
.micro-topic-search {
  cursor: pointer;
  padding: 6px;
}
.filter-select-field .v-input__icon i {
  transform: translate(10px, -2px);
  font-size: 30px;
}
.filter-select-field .mdi-menu-down::before {
  position: relative;
  left: -10px;
}
</style>
