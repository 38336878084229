<template>
  <div style="padding: 5px 0px 15px 10px; width: 95%;">
    <v-card>
      <v-container>
        <span style="font-weight: bold;">{{ headerText }}</span>
        <div
          v-if="commentHash.commentedBy && commentHash.commentedBy.name"
          style="font-size: 10px;"
        >
          {{
            loggedInUserEmail == commentHash.commentedBy.email
              ? AppTexts.YOU_TEXT
              : commentHash.commentedBy.name
          }}
        </div>
        <v-textarea
          outlined
          rows="5"
          class="textarea"
          color="blue"
          solo
          flat
          v-model="text"
          v-on:focus="showActions = true"
          :disabled="!canAddComment"
          no-resize
        ></v-textarea>
        <v-row v-if="showActions && canAddComment">
          <v-col>
            <v-btn
              :loading="isSaving"
              @click="localCommentsSaveButtonClickHandler"
              color="blue"
              :disabled="!isTextChanged"
            >
              <span style="color: #fff;">{{ AppTexts.LP_SECTION_COMMENT_SAVE_BUTTON_TEXT }}</span>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn @click="cancelClickHandler" outlined color="blue">{{ AppTexts.CANCEL }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppTexts from '@/constants/AppTexts';

export default {
  name: 'CommentInput',
  data() {
    return {
      text: '',
      showActions: false,
      AppTexts,
      isSaving: false
    };
  },
  props: {
    headerText: {
      type: String,
      required: true
    },
    commentHash: {
      type: Object,
      required: true
    },
    commentsSaveButtonClickHandler: {
      type: Function,
      required: true
    },
    canAddComment: {
      type: Boolean,
      required: true
    },
    loggedInUserEmail: {
      type: String,
      required: true
    },
    stageName: {
      type: String,
      required: true
    },
    saveStatus: {
      type: String
    }
  },
  created() {
    this.text = this.commentHash.text;
  },
  computed: {
    isTextChanged() {
      return this.text != this.commentHash.text;
    }
  },
  watch: {
    saveStatus: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log('val', val);
        if (val) {
          if (val !== 'in_progress') {
            this.isSaving = false;
          } else {
            this.isSaving = true;
          }
        }
      }
    }
  },
  methods: {
    cancelClickHandler() {
      this.showActions = false;
      this.text = this.commentHash.text;
    },
    localCommentsSaveButtonClickHandler() {
      this.isSaving = true;
      this.commentsSaveButtonClickHandler(this.text, this.stageName);
    }
  }
};
</script>
