const questionsOprogramProject = require('./questionsOprogramProject.js').default;

// function currentAcademicYear() {
//   var date = new Date();
//   var year = date.getFullYear();
//   var month = date.getMonth() + 1;
//   if (month >= 3) {
//     return `${year}-${year + 1}`;
//   }
//   return `${year + 1}-${year + 2}`;
// }

let META_INFO_HASH = {
  time: null,
  academicYear: null,
  skill: null,
  subSkill: null,
  questionPurpose: '',
  difficulty: '',
  questionType: '',
  machineScorable: true,
  stimulus: null,
  questionGroupId: null,
  questionSequenceInGroup: null,
  scorePercentage: 100,
  lessonPlanLibraryId: null,
  languageSkill: undefined,
};

const SA_LA_DEFAULT_QUESTION_DATA = {
  questionData: {
    questionText: '',
    allowedResponseTypes: [],
    answerGuideText: '',
  },
};

const DEFAULT_QUESTION_DATA = {
  mcq: {
    ...{ ...META_INFO_HASH, questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ },
    questionData: {
      questionText: '',
      options: [
        {
          optionText: '',
          isCorrectAnswer: true,
          misconception: '',
        },
        {
          optionText: '',
          isCorrectAnswer: false,
          misconception: '',
        },
      ],
    },
  },
  msq: {
    ...{ ...META_INFO_HASH, questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ },
    questionData: {
      questionText: '',
      options: [
        {
          optionText: '',
          isCorrectAnswer: true,
          misconception: '',
        },
        {
          optionText: '',
          isCorrectAnswer: false,
          misconception: '',
        },
      ],
    },
  },
  tf: {
    ...{ ...META_INFO_HASH, questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF },
    questionData: {
      questionText: '',
      options: [
        {
          optionText: 'True',
          isCorrectAnswer: true,
          misconception: '',
        },
        {
          optionText: 'False',
          isCorrectAnswer: false,
          misconception: '',
        },
      ],
      answerGuideText: '',
    },
  },
  mtf: {
    ...{ ...META_INFO_HASH, questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF },
    questionData: {
      questionText: '',
      optionKeys: [
        {
          optionText: '',
          sequence: 1,
        },
        {
          optionText: '',
          sequence: 2,
        },
        {
          optionText: '',
          sequence: 3,
        },
      ],
      optionValues: [
        {
          optionText: '',
          sequence: 1,
        },
        {
          optionText: '',
          sequence: 2,
        },
        {
          optionText: '',
          sequence: 3,
        },
      ],
    },
  },
  atf: {
    ...{ ...META_INFO_HASH, questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF },
    questionData: {
      questionText: '',
      optionValues: [
        {
          optionText: '',
          sequence: 1,
        },
        {
          optionText: '',
          sequence: 2,
        },
        {
          optionText: '',
          sequence: 3,
        },
      ],
    },
  },
  fib: {
    ...{ ...META_INFO_HASH, questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB },
    questionData: {
      freeTyping: true,
      questionText: '',
      answerGuideText: '',
      blanks: [],
      distractors: [],
    },
  },
  sa: {
    ...{
      ...META_INFO_HASH,
      questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
      machineScorable: false,
    },
    ...SA_LA_DEFAULT_QUESTION_DATA,
  },
  la: {
    ...{
      ...META_INFO_HASH,
      questionType: questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
      machineScorable: false,
    },
    ...SA_LA_DEFAULT_QUESTION_DATA,
  },
};
module.exports = { DEFAULT_QUESTION_DATA };
