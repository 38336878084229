import { baseEarlyProgramMixin } from './base';

export const twoStepTemplate = {
  mixins: [baseEarlyProgramMixin],
  data() {
    return {
      Schema: {
        aim: {
          show: true,
          label: {
            resourcesTitle: 'Things We Will Use',
          },
        },
        action: {
          show: true,
        },
        analysis: {
          show: false,
        },
        application: {
          show: false,
        },
        assessment: {
          show: false,
        },
      },
    };
  },
  methods: {
    prepareLessonPlanDataToSave() {
      // remove loadingStatus from resource object..
      let resources = [];
      this.lessonPlan.data.aim.resources.forEach((resource) => {
        delete resource.loadingStatus;
        resources.push(resource);
      });
      this.lessonPlan.data.aim.resources = resources;

      // set placeholder image for action instructions videos
      for (let ins of this.lessonPlan.data.action.instructions) {
        if (ins.assets && (ins.assets[0] || {}).type == 'video') {
          if(!ins.assets[0].placeholder) {
            ins.assets[0].placeholder = {assetId: ''}
          }
          ins.assets[0].placeholder = this.lessonPlan.data.assetId;
        }
        let assets = [];

        for (let asset of ins.assets || []) {
          delete asset.loadingStatus;
          assets.push(asset);
        }
        ins.assets = assets;
      }
    },
  },
};
