<template>
  <div>
    <hr />
    <h1>You're Unauthorised</h1>
  </div>
</template>

<script>
export default {
  name: 'UnAuthorised',
};
</script>

<style scoped>
div {
  padding: 5px;
}
</style>
