<template>
  <div class="micro-text-wrapper">
    <v-expansion-panels multiple focusable v-model="panel">
      <v-expansion-panel class="expansion-panel" key="SummarySection">
        <v-expansion-panel-header class="section-title-bar">
          <ExpansionPanelHeader
            :sectionTitle="AppTexts.MICRO_TEXT_HEADING"
            :time="data.time"
            :typeSync="data.type"
            :displayTrailing="false"
            :toggleComments="() => toggleComments('microText')"
            :type="data.type"
            @update-type-of-block-to-state="
              (val) => {
                data.type = val;
              }
            "
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="section-title-content">
          <v-row>
            <v-col cols="6" class="input-component-wrapper">
              <FileInput
                :canShowDiffIcon="options.canShowDiffIcon"
                :fileAcceptType="options.isAssetUploadRequired ? 'image/*' : options.fileAcceptType"
                label="Image"
                :loadingStatus="options.fileUploadingStatus.microTextImage == 'in_progress'"
                :isRequired="options.isAssetUploadRequired"
                :isAssetUploadRequired="options.isAssetUploadRequired"
                :fileChangeHandler="
                  (file) => fileUploadHandler(file, 'microTextImage', data[imageKey])
                "
                :previewUrl="options.signedAssetUrls.image_url"
                :previewUrlBrief="options.signedAssetUrls.brief_url"
                :image_url="data.image_url"
                :image_url_brief="data.brief_url"
                :isNotReadOnly="options.isNotReadOnly"
                :diffIdentifier="options.isAssetUploadRequired ? 'image_url' : 'brief_url'"
                @addBriefToAsset="
                  (previewUrl, briefUrl, approveAsset) =>
                    addToAsset(previewUrl, briefUrl, approveAsset, 'microTextImage')
                "
                :isAssetApproved="data.image_url_approved"
                :canFinalizeAsset="true"
                :canAppproveAsset="canApproveAsset"
              />
              <br />
              <!-- ************************************************************* -->
              <!-- Image Title is being stored as description in backend -->
              <!-- ************************************************************* -->
              <TextInput
                v-model="data.description"
                placeholder="Image Title"
                :canShowDiffIcon="options.canShowDiffIcon"
                :readonly="options.isReadOnly"
                fieldIdentifier="description"
                class="micro-text-brief-input"
              />
              <RichTextEditor
                :readonly="options.isReadOnly || options.isAssetUploadRequired"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.brief"
                fieldIdentifier="brief"
                placeholder="brief"
                :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
                :showValidationError="options.validationFlag && !options.isAssetUploadRequired"
              />
            </v-col>
          </v-row>

          <br />
          <RichTextEditor
            :readonly="options.isReadOnly"
            :canShowDiffIcon="options.canShowDiffIcon"
            v-model="data.microTextDescription"
            fieldIdentifier="microTextDescription"
            placeholder="Enter Micro Text Description"
            :showValidationError="true"
          />
          <br />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import TextInput from '@/components/inputs/TextInput';
import FileInput from '@/components/inputs/FileInput';
import RichTextEditor from '@/components/RTE/RichTextEditor';
import ExpansionPanelHeader from '@/components/contentEntry/oLessonComponents/ExpansionPanelHeader';
import Errors from '@/constants/Errors';
import AppTexts from '@/constants/AppTexts';

export default {
  name: 'MicroText',
  components: {
    ExpansionPanelHeader,
    TextInput,
    FileInput,
    RichTextEditor,
  },
  mounted() {
    this.data.time = 'NA';
    this.data.type = 'individual';
    this.currentStageDataCopy.time = 'NA';
    this.currentStageDataCopy.type = 'individual';
  },
  computed: {
    imageKey() {
      return this.options.isAssetUploadRequired ? 'image_url' : 'brief_url';
    },
    ...mapState({
      currentStageDataCopy: (state) => state.contentEntry.currentStageDataCopy,
    }),
  },
  data() {
    return {
      AppTexts,
      Errors,
      panel: [0],
    };
  },
  methods: {
    fileUploadHandler(file, type, path) {
      if (!file) {
        this.fileChangeHandler(file, type, path);
        return true;
      }
      let pattern = '';
      if (this.options.isAssetUploadRequired) {
        pattern = 'image/*';
      } else {
        pattern = this.options.fileAcceptType;
      }
      if (!(file.type.startsWith('image') || pattern.includes(file.name.split('.').pop()))) {
        let partialErrorText = this.options.isAssetUploadRequired
          ? 'please upload an image file'
          : 'Please upload an image or a document file';
        this.$notify({
          title: 'Error!',
          text: `File format not supported, ${partialErrorText}`,
          type: 'error',
          clean: false,
          duration: -1,
        });
      } else this.fileChangeHandler(file, type, path);
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canApproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
