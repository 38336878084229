export const getFileNameFromUrl = (url) => {
  if (!url) return '';
  let array = url.split('/');
  return array[array.length - 1].replace(/%20/g, ' ').split('?')[0];
};

export const getFileTypeFromUrl = (url) => {
  if (!url) return '';
  let fileType = getFileNameFromUrl(url).split('.').pop();
  switch (fileType) {
    case 'pdf':
      return 'pdf';
    case 'doc':
      return 'doc';
    case 'docx':
      return 'doc';
    case 'mp4':
    case 'avi':
    case 'mkv':
      return 'video';
    case 'mp3':
      return 'audio';
    default:
      return 'image';
  }
};
