import { render, staticRenderFns } from "./SingleQuestionView.vue?vue&type=template&id=47ad0c42&scoped=true"
import script from "./SingleQuestionView.vue?vue&type=script&lang=js"
export * from "./SingleQuestionView.vue?vue&type=script&lang=js"
import style0 from "./SingleQuestionView.vue?vue&type=style&index=0&id=47ad0c42&prod&scoped=true&lang=css"
import style1 from "./SingleQuestionView.vue?vue&type=style&index=1&id=47ad0c42&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ad0c42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCheckbox,VDivider})
