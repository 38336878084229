import gql from 'graphql-tag';

export const searchQuestionsQuery = () => {
  return gql`
    query($blockId: Int!, $filter: JSON) {
      searchQuestions(blockId: $blockId, filter: $filter)
    }
  `;
};

export const virtualQuestionLessonPlanLinkDetailsQuery = () => {
  return gql`
    query($projectId: ID!, $libraryId: Int!, $questionIds: [ID!]) {
      virtualQuestionLessonPlanLinkDetails(
        projectId: $projectId
        libraryId: $libraryId
        questionIds: $questionIds
      ) {
        questionIds
        projectId
        lpId
        lpName
      }
    }
  `;
};
export const unLinkQuestionsFromQuestionLessonMutation = () => {
  return gql`
    mutation($data: [LessonPlanQuestionLinkDetailsInput!]!) {
      unLinkQuestionsFromQuestionLesson(data: $data) {
        status
      }
    }
  `;
};

export const downloadQuestions = () => {
  return gql`
    query(
      $questionIds: [ID]!
      $projectId: ID!
      $questionPropertiesToIncludeInDownload: QuestionPropertiesToIncludeInDownload!
      $downloadExt: String!
    ) {
      downloadVirtualQuestions(
        questionIds: $questionIds
        projectId: $projectId
        questionPropertiesToIncludeInDownload: $questionPropertiesToIncludeInDownload
        downloadExt: $downloadExt
      ) {
        base64FileContent
        downloadExt
      }
    }
  `;
};
