<template>
  <div>
    <!-- <div
      class="text-center"
      style="align-items: center; justify-content: center;"
      v-if="currentStageWatcher == 'completedStage'"
    >
      <div>The lp was in completed stage</div>
      <div>
        <v-btn @click="goToProjects">Go to Projects</v-btn>
      </div>
    </div>-->
    <v-dialog v-model="showSaveDialogPopup" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"> Alert</v-card-title>
        <v-card-text>
          You have not saved your changes yet. Press YES to save your changes, press NO to continue
          without saving.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="proceedNavigationBasedOnChoice('no')"
            >NO</v-btn
          >
          <v-btn color="green darken-1" text @click="proceedNavigationBasedOnChoice('yes')"
            >YES</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="lessonPlanDataFetchStatus == 'in_progress'" style="margin-top: 100px;">
      <Loader
        :loadingText="AppTexts.FETCHING_DATA_TEXT"
        :loadingStatus="lessonPlanDataFetchStatus == 'in_progress'"
      />
    </div>
    <div v-if="lessonPlanDataFetchStatus == 'error'">
      Error while fetching data. Please try again after some time.
    </div>
    <div v-if="lessonPlanDataFetchStatus == 'completed'">
      <v-row>
        <div
          :style="
            showRightSidePanel
              ? 'width: 65%; position: relative;'
              : ' width: 96%; position: relative;'
          "
        >
          <div class="overflow-hidden">
            <v-app-bar
              height="58px;"
              dense
              absolute
              color="white"
              elevation="2"
              scroll-target="#scrolling-techniques-7"
              class="action-bar"
            >
              <v-row>
                <v-col cols="1" v-if="this.$route.query.lpType == LP_TYPES.magic">
                  <img width="24" height="24" src="../assets/magic.svg" />
                </v-col>
                <v-col cols="1" v-if="this.$router.currentRoute.name == 'dailyPlans/lpForm'">
                  <img
                    width="24"
                    height="24"
                    src="../assets/back.png"
                    @click="redirectToPreviousPage()"
                  />
                </v-col>
                <v-col>
                  <span class="lp-detailes-header">
                    {{ breadCrumbText }} >
                    <span class="bold">{{ lessonPlan.name }}</span>
                  </span>
                </v-col>
              </v-row>
              <div></div>
              <v-spacer></v-spacer>
              <v-dialog v-model="showSaveInprogressDialog" max-width="290" persistent>
                <v-card>
                  <v-card-title style="text-align: center; display: block;" class="headline"
                    >Brewing your coffee...</v-card-title
                  >
                  <v-card-text style="text-align: center;">
                    <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <span v-if="!auth.canSaveProjectLP" class="read-only-mode-message">
                <img height="18" width="18" src="@/assets/alert.png" />
                <div style="float: right; margin-left: 10px;">
                  This page cannot be edited, its in read only mode
                </div>
              </span>
              <div v-if="!auth.canSaveProjectLP" id="read-only-mode-banner">
                <img
                  src="@/assets/readonly.png"
                  :class="showRightSidePanel ? 'half-width-view' : 'full-width-view'"
                />
              </div>
              <v-btn
                class="ml-5 download-assets-button"
                v-show="auth.canDownloadContentAssets"
                depressed
                color="blue"
                medium
                @click="downloadAsset()"
                >Assets</v-btn
              >
              <v-btn
                :class="!auth.canSaveProjectLP ? 'disable-btn-click-event' : ''"
                depressed
                color="ml-5 primary save-button"
                medium
                @click="saveForm(true)"
                >{{ AppTexts.SAVE }}</v-btn
              >
              <v-btn
                :class="!auth.canSaveProjectLP ? 'disable-btn-click-event' : ''"
                class="ml-5 draft-save-button"
                depressed
                color="blue"
                medium
                @click="saveForm(false)"
                >{{ AppTexts.SAVE_AS_DRAFT }}</v-btn
              >
            </v-app-bar>
            <v-sheet style="margin-top: 100px;" id="scrolling-techniques-7" class="overflow-y-auto">
              <v-form ref="form" class="left-hand-side-form">
                <iframe
                  :srcdoc="importedHtmlFile"
                  ref="html-iframe"
                  @load="iframeLoaded"
                  v-show="false"
                />
                <v-row dense style="display: none;">
                  <v-col cols="6">
                    <v-file-input
                      dense
                      accept="text/html"
                      @change="importHtml"
                      :label="AppTexts.IMPORT_HTML_FILE"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-file-input
                      dense
                      accept=".zip"
                      @change="importAssets"
                      :label="AppTexts.IMPORT_ASSETS"
                      :hint="AppTexts.HINT_IMPORT_ASSETS"
                      hide-details
                      :loading="uploadingStatus.assets == FILE_UPLOADING_STATUS.IN_PROGRESS"
                      :fileValidators="fileValidators"
                    />
                  </v-col>
                </v-row>
                <div class="top-empty-space"></div>
                <CoverImage
                  :lessonPlanDetails="lessonPlanDetails()"
                  :title="lessonPlanWatcher.title || ''"
                  :brief="lessonPlanWatcher.brief || ''"
                  :imageTitleChangeHandler="(val) => (lessonPlanWatcher.title = val)"
                  :imageBriefChangeHandler="(val) => (lessonPlanWatcher.brief = val)"
                  :image_url="isAssetUploadRequired ? lessonPlanWatcher.image_url : ''"
                  :image_url_brief="lessonPlanWatcher.brief_url"
                  :previewUrl="isAssetUploadRequired ? signedUrls.image.signedUrl : ''"
                  :previewUrlBrief="signedUrls.image.signedBriefUrl"
                  :coverImagePlaceholder="AppTexts.LESSON_PLAN_COVER_IMAGE"
                  :currentStageDetails="currentStageDetails"
                  :loadingStatus="uploadingStatus.cover_image == FILE_UPLOADING_STATUS.IN_PROGRESS"
                  :isAssetUploadRequired="isAssetUploadRequired"
                  :fileChangeHandler="importLessonPlanCoverImage"
                  :briefChangeHandler="(text) => updateBriefText({ text })"
                  :canShowDiffIcon="canShowDiffIcon"
                  :isImageChanged="getCoverImageIsChangedValue()"
                  :isImageBriefDiffChanged="getCoverImageBriefIsChangedValue()"
                  :showImageBrief="showImageBrief"
                  :isNotReadOnly="auth.canSaveProjectLP"
                  :fileValidators="fileValidators"
                  :copyLessonPlanCoverImage="copyLessonPlanCoverImage"
                  :canApproveAsset="canApproveAsset"
                  :canFinalizeAsset="true"
                  :isAssetApproved="lessonPlanWatcher.image_url_approved || false"
                  :currentProjectAssignees="currentProject.assignees"
                />
                <v-expansion-panels multiple focusable v-model="panel">
                  <v-expansion-panel class="expansion-panel" key="SummarySection">
                    <v-expansion-panel-header class="section-title-bar">
                      <ExpansionPanelHeader
                        :sectionTitle="getSummaryTitle()"
                        :displayTrailing="false"
                        :toggleComments="
                          (e) => toggleComments(COMMENT_SECTION_NAMES_HASH.SUMMARY, e)
                        "
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="section-title-content">
                      <SummarySection
                        :summaryData="summaryData"
                        :updateSummaryTextToStore="updateSummaryText"
                        :diff="diff"
                        :canShowDiffIcon="canShowDiffIcon"
                        :lessonPlanFormRendererType="lessonPlanFormRendererType"
                        :isWorkSheetEnabled="isWorkSheetEnabled"
                        :isNotReadOnly="auth.canSaveProjectLP"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="expansion-panel" key="AimSection">
                    <v-expansion-panel-header class="section-title-bar">
                      <ExpansionPanelHeader
                        :sectionTitle="AppTexts.AIM"
                        :displayTrailing="false"
                        :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.AIM, e)"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="section-title-content">
                      <AimSection
                        :lessonPlanData="lessonPlanWatcher"
                        :addResource="addResource"
                        :deleteResource="deleteResource"
                        :addKeyword="addKeyword"
                        :deleteKeyword="deleteKeyword"
                        :showImageBrief="showImageBrief"
                        :isAssetUploadRequired="isAssetUploadRequired"
                        :acceptableFileType="acceptableFileType"
                        :resourcesError="resourcesError"
                        :aimSignedUrls="signedUrls.aim"
                        :aimDiff="diff.aim || {}"
                        :keywordsDiff="diff.keywords || []"
                        :canShowDiffIcon="canShowDiffIcon"
                        :isNotReadOnly="auth.canSaveProjectLP"
                        :fileValidators="fileValidators"
                        :canApproveAsset="canApproveAsset"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="expansion-panel" key="ActionSection">
                    <v-expansion-panel-header class="section-title-bar">
                      <ExpansionPanelHeader
                        :sectionTitle="AppTexts.ACTION"
                        :types="lessonPlanWatcher.action.types"
                        :time="lessonPlanWatcher.action.time"
                        @update-type-of-block-to-state="
                          (val) => updateTypeOfBlockToState('action', val)
                        "
                        :toggleComments="
                          (e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ACTION, e)
                        "
                        :canEditType="auth.canSaveProjectLP"
                        :isNotReadOnly="auth.canSaveProjectLP"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="section-title-content">
                      <ActionSection
                        :lessonPlanData="lessonPlanWatcher"
                        :addActionSubStep="addActionSubStep"
                        :deleteActionStep="deleteActionStep"
                        :deleteActionSubStep="deleteActionSubStep"
                        :addActionStep="addActionStep"
                        :showImageBrief="showImageBrief"
                        :isAssetUploadRequired="isAssetUploadRequired"
                        :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
                        :actionSignedUrls="signedUrls.action"
                        :actionDiff="diff.action || {}"
                        :canShowDiffIcon="canShowDiffIcon"
                        :isNotReadOnly="auth.canSaveProjectLP"
                        :acceptableFileType="acceptableFileType"
                        :fileValidators="fileValidators"
                        :canApproveAsset="canApproveAsset"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    class="expansion-panel"
                    key="AnalyseSection"
                    v-if="
                      lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE
                    "
                  >
                    <v-expansion-panel-header class="section-title-bar">
                      <ExpansionPanelHeader
                        :sectionTitle="AppTexts.ANALYSIS"
                        :time="lessonPlanWatcher.analysis.time"
                        :types="lessonPlanWatcher.analysis.types"
                        @update-type-of-block-to-state="
                          (val) => updateTypeOfBlockToState('analysis', val)
                        "
                        :toggleComments="
                          (e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ANALYSIS, e)
                        "
                        :isNotReadOnly="auth.canSaveProjectLP"
                        :canEditType="auth.canSaveProjectLP"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="section-title-content">
                      <AnalyseSection
                        :lessonPlanData="lessonPlanWatcher"
                        :showImageBrief="showImageBrief"
                        :isAssetUploadRequired="isAssetUploadRequired"
                        :addAnalysisActivityDiscussionStep="addAnalysisActivityDiscussionStep"
                        :deleteAnalysisActivityDiscussionStep="deleteAnalysisActivityDiscussionStep"
                        :deleteAnalysisGeneralizedDiscussionStep="
                          deleteAnalysisGeneralizedDiscussionStep
                        "
                        :addAnalysisGeneralizedDiscussionStep="addAnalysisGeneralizedDiscussionStep"
                        :deleteAnalysisHigherOrderStep="deleteAnalysisHigherOrderStep"
                        :addAnalysisHigherOrderStep="addAnalysisHigherOrderStep"
                        :addAnaysisExplanationAssets="addAnaysisExplanationAssets"
                        :deleteAnaysisExplanationAssets="deleteAnaysisExplanationAssets"
                        :analysisSignedUrls="signedUrls.analysis"
                        :analysisDiff="diff.analysis || {}"
                        :canShowDiffIcon="canShowDiffIcon"
                        :lessonPlanFormRendererType="lessonPlanFormRendererType"
                        :isNotReadOnly="auth.canSaveProjectLP"
                        :acceptableFileType="acceptableFileType"
                        :fileValidators="fileValidators"
                        :canApproveAsset="canApproveAsset"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    class="expansion-panel"
                    key="ApplicationSection"
                    v-if="
                      lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE
                    "
                  >
                    <v-expansion-panel-header class="section-title-bar">
                      <ExpansionPanelHeader
                        :sectionTitle="AppTexts.APPLICATION"
                        :time="lessonPlanData.application.time"
                        :types="lessonPlanData.application.types"
                        :canEditType="false"
                        :canEditTime="false"
                        :toggleComments="
                          (e) => toggleComments(COMMENT_SECTION_NAMES_HASH.APPLICATION, e)
                        "
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="section-title-content">
                      <ApplicationSection
                        :lessonPlanData="lessonPlanWatcher"
                        :deletePractice="deletePractice"
                        :addPractice="addPractice"
                        :showImageBrief="showImageBrief"
                        :isAssetUploadRequired="isAssetUploadRequired"
                        :acceptableFileType="acceptableFileType"
                        :applicationSignedUrls="signedUrls.application"
                        :applicationDiff="diff.application || {}"
                        :canShowDiffIcon="canShowDiffIcon"
                        :lessonPlanFormRendererType="lessonPlanFormRendererType"
                        :isWorkSheetEnabled="isWorkSheetEnabled"
                        :isNotReadOnly="auth.canSaveProjectLP"
                        :fileValidators="fileValidators"
                        :canApproveAsset="canApproveAsset"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    class="expansion-panel"
                    key="AssessmentSection"
                    v-if="
                      lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE
                    "
                  >
                    <v-expansion-panel-header class="section-title-bar">
                      <ExpansionPanelHeader
                        :sectionTitle="AppTexts.ASSESS"
                        :displayTrailing="false"
                        :toggleComments="
                          (e) => toggleComments(COMMENT_SECTION_NAMES_HASH.ASSESSMENT, e)
                        "
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="section-title-content">
                      <AssessmentSection
                        :lessonPlanData="lessonPlanWatcher"
                        :showImageBrief="showImageBrief"
                        :isAssetUploadRequired="isAssetUploadRequired"
                        :acceptableFileType="acceptableFileType"
                        :assessmentSignedUrls="signedUrls.assessment"
                        :assessmentDiff="diff.assessment || {}"
                        :canShowDiffIcon="canShowDiffIcon"
                        :lessonPlanFormRendererType="lessonPlanFormRendererType"
                        :isWorkSheetEnabled="isWorkSheetEnabled"
                        :isNotReadOnly="auth.canSaveProjectLP"
                        :fileValidators="fileValidators"
                        :canApproveAsset="canApproveAsset"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="expansion-panel" key="ReferencesSection">
                    <v-expansion-panel-header class="section-title-bar">
                      <ExpansionPanelHeader
                        :displayTrailing="false"
                        :displayComments="false"
                        :sectionTitle="AppTexts.REFERENCES"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="section-title-content">
                      <ReferencesSection
                        :deleteReference="deleteReference"
                        :addReference="addReference"
                        :references="lessonPlanData.references"
                        :referenceDiff="diff.references || []"
                        :canShowDiffIcon="canShowDiffIcon"
                        :isNotReadOnly="auth.canSaveProjectLP"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-form>
              <MoveToNextStageButton
                :buttonText="getNextStageButtonText()"
                :canMoveProjectLP="
                  !auth.canMoveProjectLP || currentStageWatcher == 'completedStage'
                "
                @onClickMoveToNextStage="onClickMoveToNextStage"
              />
              <MoveToNextStageButton
                v-if="currentStageWatcher == 'proofReadingStage'"
                buttonText="Move to previous stage"
                :canMoveProjectLP="!auth.canMoveToAssetUploadStage"
                @onClickMoveToNextStage="onClickMoveToPreviousStage"
              />
            </v-sheet>
          </div>
        </div>
        <div style="align-item: center; width: 35%;">
          <div>
            <v-btn
              icon
              :style="
                showRightSidePanel
                  ? 'right: 34%; margin-top: 10px;'
                  : 'right: 3px; margin-top: 10px;'
              "
              class="toggle-preview-button"
              :title="showRightSidePanel ? 'Hide Preview' : 'Show Preview'"
              @click="toggleRightSidePanel()"
            >
              <v-icon large>
                {{
                  showRightSidePanel ? 'mdi-arrow-right-drop-circle' : 'mdi-arrow-left-drop-circle'
                }}
              </v-icon>
            </v-btn>
          </div>
          <div v-if="showRightSidePanel">
            <div
              v-if="rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS"
              :style="commentButtonPageYValue"
            >
              <div style="overflow: hidden; background-color: #f6f6f6; padding: 10px;">
                <span
                  style="cursor: pointer; float: right; margin-right: 10px; color: #cb4c52;"
                  @click="closeComments()"
                >
                  CLOSE
                  <v-icon title="close" color="#cb4c52">mdi-close</v-icon>
                </span>
              </div>
              <div v-if="commentsHash[commentSectionIdentifier].fetchStatus === 'in_progress'">
                <span style="padding: 10px;">
                  {{ commentsHash[commentSectionIdentifier].message }}
                </span>
              </div>
              <div v-else-if="commentsHash[commentSectionIdentifier].fetchStatus === 'completed'">
                <Comments
                  :comments="commentsHash[commentSectionIdentifier].comments"
                  :currentStage="currentStageWatcher"
                  :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
                  :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
                  :canAddComment="auth.canAddComment"
                  :canResolveComment="auth.canResolveComment"
                  :loggedInUserEmail="loggedInUserEmail"
                  :saveStatusesHash="commentsHash[commentSectionIdentifier].saveStatusesHash"
                  :resolveStatusesHash="commentsHash[commentSectionIdentifier].resolveStatusesHash"
                />
              </div>
              <div v-else-if="commentsHash[commentSectionIdentifier].fetchStatus === 'error'">
                {{ commentsHash[commentSectionIdentifier].message }}
              </div>
            </div>
            <div v-if="rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.CONTENT_DIFF">
              <Diff
                :fieldDiff="fieldDiff"
                :closeHandler="diffCloseHandler"
                :currentStage="currentStage"
                :title="currentDiffIdentifier"
              />
            </div>
            <div
              style="
                height: 100%;
                width: 35%;
                text-align: center;
                border-radius: 10px;
                padding: 2px;
              "
              class="preview-block"
              v-else
            >
              <iframe
                ref="lp-preview-iframe"
                :src="lpPreviewUrl"
                frameborder="0"
                @load="iframeLoad"
                width="93%"
                height="100%"
              ></iframe>
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CoverImage from '@/components/lessonPlan/CoverImage';
import SummarySection from '@/components/lessonPlan/SummarySection';
import AimSection from '@/components/lessonPlan/AimSection';
import ActionSection from '@/components/lessonPlan/ActionSection';
import AnalyseSection from '@/components/lessonPlan/AnalyseSection';
import ApplicationSection from '@/components/lessonPlan/ApplicationSection';
import AssessmentSection from '@/components/lessonPlan/AssessmentSection';
import ReferencesSection from '@/components/lessonPlan/ReferencesSection';
import ExpansionPanelHeader from '@/components/lessonPlan/ExpansionPanelHeader';
import MoveToNextStageButton from '@/components/lessonPlan/MoveToNextStageButton';
import Loader from '@/components/common/Loader';
import LPParser from '@/utils/LessonPlanParser';
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import { getFileNameFromUrl } from '@/functions/global';
import {
  LP_TYPES,
  LP_FORM_SUBMIT_TEXT,
  COMMENT_SECTION_NAMES_HASH,
  LESSON_PLAN_LOADING_STATES,
  RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
  LP_TIME_TYPES,
  ASSET_AUTHORIZING_STAGE,
} from '@/constants/LessonPlan';
import AssetTypes from '@/constants/AssetTypes';
// import FileInput from "@/components/inputs/FileInput";
import { getAssetUploadRequiredStatus, getAcceptableFileType } from '@/utils';
import Comments from '@/components/comments/Comments';
import Diff from '@/components/ContentDiff/Diff';
import { LP_STAGES } from '@/constants/LessonPlan';
import {
  LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE,
  LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE,
  LP_FORM_RENDERER_TYPE_NORMAL,
} from '@/constants/LpForm';
import _ from 'lodash';

export default {
  name: 'LpForm',
  components: {
    CoverImage,
    SummarySection,
    AimSection,
    ActionSection,
    AnalyseSection,
    ApplicationSection,
    AssessmentSection,
    ReferencesSection,
    Comments,
    MoveToNextStageButton,
    Loader,
    ExpansionPanelHeader,
    // FileInput,
    Diff,
  },
  created() {
    this.populateLessonPlanTimesObject();
    this.getProjectDetails({ projectId: this.$route.params.projectId });
    this.getLessonPlanData({
      projectId: this.$route.params.projectId,
      libraryId: this.$route.params.libraryId,
    });
    this.setLibraryId(this.$route.params.libraryId);
    this.getLessonPlanSaveAndMoveToNextStagePermission({
      projectId: this.$route.params.projectId,
      libraryId: this.$route.params.libraryId,
    });
    // this.addEventLisnerForWindowUnLoad();
  },
  data() {
    return {
      LP_STAGES,
      LP_TIME_TYPES,
      AppTexts,
      firstCall: true,
      isSaved: false,
      navigateTo: '',
      showSaveDialogPopup: false,
      lf: '122',
      LP_FORM_SUBMIT_TEXT,
      LESSON_PLAN_LOADING_STATES,
      COMMENT_SECTION_NAMES_HASH,
      AssetTypes,
      Errors,
      FILE_UPLOADING_STATUS,
      resourcesError: '',
      importedHtmlFile: '',
      panel: [0, 1, 2, 3, 4, 5, 6],
      dialog: false,
      lpPreviewUrl: process.env.VUE_APP_LP_PREVIEW_ENDPOINT,
      summaryData: {
        aim: '',
        action: '',
        analysis: '',
        application: '',
        assessment: '',
        assessmentOverall: '',
      },
      lessonPlanTimeObject: {},
      lessonPlanWatcher: {},
      lessonPlanDataCopy: {},
      unsavedChanges: false,
      LP_TYPES,
      currentStageWatcher: '',
      showImageBrief: true,
      isAssetUploadRequired: false,
      commentButtonPageYValue: 'margin-top: 0px;',
      RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
      commentSectionIdentifier: '',
      canShowDiffIcon: false,
      showRightSidePanel: true,
      previousStateOfShowRightSidePanel: true,
      currentStageDetails: {
        idenx: 0,
        name: LP_STAGES[0],
      },
      blocksToHide: [],
      lessonPlanFormRendererType: LP_FORM_RENDERER_TYPE_NORMAL,
      LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE,
      isWorkSheetEnabled: true,
      acceptableFileType: '',
    };
  },
  computed: {
    ...mapState({
      subjectName: (state) => state.lessonPlan.subjectName,
      lessonPlan: (state) => state.lessonPlan,
      currentStage: (state) => state.lessonPlan.currentStage,
      lessonPlanData: (state) => state.lessonPlan.data,
      diff: (state) => state.lessonPlan.diff,
      fieldDiff: (state) => state.rightSideContent.fieldDiff,
      commentsCount: (state) => state.lessonPlan.commentsCount,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      signedUrls: (state) => state.lessonPlan.signedUrls,
      auth: (state) => state.auth.lessonPlan,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      staticTexts: (state) => state.lessonPlan.staticTexts,
      lessonPlanLoadingStatus: (state) => state.lessonPlan.lessonPlanLoadingStatus,
      lessonPlanDataFetchStatus: (state) => state.lessonPlan.lessonPlanDataFetchStatus,
      rightSidePreviewContent: (state) => state.rightSideContent.rightSidePreviewContent,
      currentDiffIdentifier: (state) => state.rightSideContent.currentDiffIdentifier,
      lpCurrentStageDataDailyPlanFromVuex: (state) =>
        state.lessonPlan.lpCurrentStageDataDailyPlanFromVuex,
      saveLessonPlanTriggerState: (state) => state.lessonPlan.saveLessonPlanTriggerState,
      currentProject: (state) => state.currentProject,
    }),
    breadCrumbText() {
      let text = `Home > Block - ${this.$route.query.blockTitle} (${this.$route.query.bId})`;

      if (
        this.lpCurrentStageDataDailyPlanFromVuex &&
        this.lpCurrentStageDataDailyPlanFromVuex.name
      ) {
        return `${text} > ${this.lpCurrentStageDataDailyPlanFromVuex.name}`;
      }

      return text;
    },
    isPreviewBeingDisplayedInRightSideView() {
      return (
        this.rightSidePreviewContent != RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS &&
        this.rightSidePreviewContent != RIGHT_SIDE_PREVIEW_CONTENT_TYPE.CONTENT_DIFF
      );
    },
    showSaveInprogressDialog() {
      return this.lessonPlanLoadingStatus.status == LESSON_PLAN_LOADING_STATES.inProgress;
    },
    fileValidators() {
      const FILE_SIZE_50_MB = 50 * 1024 * 1024;
      const FILE_SIZE_150_MB = 150 * 1024 * 1024;
      const error150mb = 'File size must be < 150 MB.';
      const error50mb = 'File size must be < 50 MB.';
      const defaultValidator = (file) => (file && file.size > FILE_SIZE_50_MB ? error50mb : true);

      if (this.subjectName == 'English') {
        return [
          (file) => {
            if (file) {
              if (file.type.includes('video/')) {
                if (file.size > FILE_SIZE_150_MB) {
                  return error150mb;
                }
              } else {
                if (file.size > FILE_SIZE_50_MB) {
                  return error50mb;
                }
              }
            }
          },
        ];
      } else {
        return [defaultValidator];
      }
    },
    canApproveAsset() {
      return this.currentStage == ASSET_AUTHORIZING_STAGE;
    },
  },
  watch: {
    rightSidePreviewContent: {
      immediate: true,
      deep: true,
      handler(val) {
        let oldState = this.previousStateOfShowRightSidePanel;

        this.previousStateOfShowRightSidePanel = this.showRightSidePanel;
        if (val == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP) {
          this.showRightSidePanel = oldState;
        } else {
          this.showRightSidePanel = true;
        }
      },
    },
    lpCurrentStageDataDailyPlanFromVuex: function (val) {
      if (val && val.correspondingTimeData) {
        this.isWorkSheetEnabled = val.correspondingTimeData.isWorkSheetEnabled;
      }
    },
    currentStage: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          if (
            val &&
            val.length &&
            this.currentStageWatcher &&
            this.currentStageWatcher.length &&
            val != this.currentStageWatcher
          ) {
            this.reSetLessonPlanFormState();
            this.redirectToPreviousPage();
          } else {
            let currentStageIndex = Object.keys(LP_FORM_SUBMIT_TEXT).indexOf(val);
            this.currentStageDetails = {
              index: currentStageIndex,
              stageName: this.LP_STAGES[val],
            };
            let assetUploadStageIndex = Object.keys(this.LP_STAGES).indexOf('assetUploadStage');
            this.showImageBrief = currentStageIndex < assetUploadStageIndex;
            this.isAssetUploadRequired = getAssetUploadRequiredStatus(val);
            this.acceptableFileType = getAcceptableFileType(this.isAssetUploadRequired);
            this.currentStageWatcher = val;
            this.canShowDiffIcon = currentStageIndex > 1;
          }
        }
      },
    },
    lessonPlanData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          if (this.firstCall) {
            this.lessonPlanDataCopy = JSON.parse(JSON.stringify(val));
          }
          this.firstCall = false;
          this.lessonPlanWatcher = val;
          this.summaryData = {
            aim: val.aim.summary,
            action: val.action.summary,
            analysis: val.analysis.summary,
            application: val.application.summary,
            assessment: val.assessment.summary,
            assessmentOverall: val.assessment.overallSummary,
          };
          this.sendDataToPreview(val);
        }
      },
    },
    saveLessonPlanTriggerState: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.saveForm(false);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'getProjectDetails',
      'updateSummaryText',
      'addKeyword',
      'deleteKeyword',
      'addResource',
      'deleteResource',
      'addActionStep',
      'addActionSubStep',
      'deleteActionStep',
      'updateInstructionBreakValueOfStep',
      'addPractice',
      'deletePractice',
      'addAnalysisHigherOrderStep',
      'deleteAnalysisHigherOrderStep',
      'addAnalysisGeneralizedDiscussionStep',
      'deleteAnalysisGeneralizedDiscussionStep',
      'addAnalysisActivityDiscussionStep',
      'deleteAnalysisActivityDiscussionStep',
      'addAnaysisExplanationAssets',
      'deleteAnaysisExplanationAssets',
      'addReference',
      'deleteReference',
      'deleteActionSubStep',
      'setParsedJsonToState',
      'importAssetsAction',
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'getLessonPlanData',
      'saveLessonPlanData',
      'downloadLPAssets',
      'setLibraryId',
      'moveToNextStage',
      'reSetLessonPlanFormState',
      'fetchProjectLessonPlanSectionComment',
      'saveComment',
      'resolveComment',
      'getLessonPlanSaveAndMoveToNextStagePermission',
      'updateBriefText',
      'updateTypeOfBlock',
      'projectLessonPlanDiffData',
      'setRightSidePreviewContent',
      'moveToPrevioustage',
      'copySingleAssetAction',
    ]),
    getFileNameFromUrl,
    iframeLoad() {
      this.sendDataToPreview(this.lessonPlanWatcher);
    },
    redirectToPreviousPage() {
      if (this.$router.currentRoute.name == 'lpForm') {
        this.$router.push({
          path: '/projects/' + this.$route.params.projectId,
        });
      } else if (this.$router.currentRoute.name == 'dailyPlans/lpForm') {
        this.$router.push({
          name: 'dailyPlanEditPage',
          params: {
            dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
            projectId: this.$route.params.projectId,
          },
        });
      }
    },
    sendDataToPreview(data) {
      let awsSignedUrls = this.signedUrls || {};
      let lpStaticTexts = this.staticTexts || {};

      var iframe = this.$refs['lp-preview-iframe'];
      if (data != null && iframe) {
        const doc = this.getIframeWindow1(iframe);
        const post = JSON.parse(
          JSON.stringify({
            data: {
              ...data,
              title: this.lessonPlan.name,
              type: this.$route.query.lpType,
            },
            awsSignedUrls,
            displayBriefUrl: !this.isAssetUploadRequired,
            lpStaticTexts,
          })
        );
        console.log('post', JSON.stringify(post));
        doc.postMessage(post, '*');
      }
    },
    getIframeWindow1(iframe) {
      var doc;
      if (iframe && iframe.contentWindow) {
        return iframe.contentWindow;
      }
      if (iframe.window) {
        return iframe.window;
      }
      if (!doc && iframe.contentDocument) {
        doc = iframe.contentDocument;
      }
      if (!doc && iframe.document) {
        doc = iframe.document;
      }
      if (doc && doc.defaultView) {
        return doc.defaultView;
      }
      if (doc && doc.parentWindow) {
        return doc.parentWindow;
      }
      return undefined;
    },
    addEventLisnerForWindowUnLoad() {
      window.onbeforeunload = this.closeIt;
      const inputs = document.querySelectorAll('input');
      for (let input of inputs) {
        input.change = () => {
          this.unsavedChanges = true;
        };
      }
    },
    isBlockToBeHidden(blockName) {
      return !this.blocksToHide.includes(blockName);
    },
    closeIt() {
      if (this.unsavedChanges) {
        return 'You have unsaved changes in this page ';
      }
    },
    importAssets(file) {
      if (file.length) {
        this.importAssetsAction(file);
      }
    },
    copyLessonPlanCoverImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        isAssetApproved,
      });
    },
    importLessonPlanCoverImage(file) {
      if (file && file.name && file.name.length) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        });
      } else if (file == null) {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.image_url
            : this.lessonPlanData.brief_url,
          assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        });
      }
    },
    reassignFormInputs(form) {
      const inputs = [];
      // Copied from VForm's previous life* which had a getInputs() function
      const search = (children, depth = 0) => {
        for (let index = 0; index < children.length; index++) {
          const child = children[index];
          if (child.errorBucket !== undefined) inputs.push(child);
          else search(child.$children, depth + 1);
        }
        if (depth === 0) return inputs;
      };
      search(form.$children);
      form.inputs = inputs;
    },
    downloadAsset() {
      this.downloadLPAssets({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
      });
    },
    saveForm(validateForm = true) {
      this.lessonPlanDataCopy = JSON.parse(JSON.stringify(this.lessonPlan.data));
      this.prepareLessonPlanDataToSave();

      if (validateForm) {
        const validationStatus = this.validateLPForm(validateForm);
        if (validationStatus) {
          this.unsavedChanges = false;
          this.saveLessonPlanData({
            projectId: this.$route.params.projectId,
            libraryId: this.$route.params.libraryId,
            data: this.lessonPlan,
          });
        } else {
          this.$notify({
            title: 'Failed',
            text: 'Lesson Plan validation failed',
            type: 'warn',
          });
        }
      } else {
        if (this.lessonPlan.name) {
          this.saveLessonPlanData({
            projectId: this.$route.params.projectId,
            libraryId: this.$route.params.libraryId,
            data: this.lessonPlan,
          });
        }
      }
    },
    validateLPForm() {
      this.reassignFormInputs(this.$refs.form);
      this.validateLessonPlanData(this.lessonPlan);

      if (
        this.$refs.form.validate() &&
        this.customValidations() &&
        this.resourcesError.length == 0 &&
        this.assetAutorizingStageValidations()
      ) {
        return true;
      } else {
        return false;
      }
    },
    prepareLessonPlanDataToSave() {
      // remove loadingStatus from resource object..
      let resources = [];
      this.lessonPlan.data.aim.resources.forEach((resource) => {
        delete resource.loadingStatus;
        resources.push(resource);
      });
      this.lessonPlan.data.aim.resources = resources;

      // set placeholder image for action instructions videos
      for (let ins of this.lessonPlan.data.action.instructions) {
        if (ins.assets && (ins.assets[0] || {}).type == 'video') {
          ins.assets[0].placeholder_image = this.lessonPlan.data.image_url;
        }
        let assets = [];

        for (let asset of ins.assets || []) {
          delete asset.loadingStatus;
          assets.push(asset);
        }
        ins.assets = assets;
      }

      for (let _discussionName of [
        'activity_discussion',
        'generalized_discussion',
        'higher_order_thinking',
      ]) {
        let explanationData = [];
        this.lessonPlan.data.analysis[_discussionName].explanations.forEach(function (
          _explanation
        ) {
          delete _explanation.loadingStatus;
          explanationData.push(_explanation);
        });

        this.lessonPlan.data.analysis[_discussionName].explanations = explanationData;
      }

      if (
        this.lessonPlan.data.application.more_practice &&
        this.lessonPlan.data.application.more_practice.length > 0
      ) {
        // set placeholder image for more_practice instructions videos
        for (let ins of this.lessonPlan.data.application.more_practice) {
          if (ins.assets && (ins.assets[0] || {}).type == 'video') {
            ins.assets[0].placeholder_image = this.lessonPlan.data.image_url;
          }
          let assets = [];

          for (let asset of ins.assets || []) {
            delete asset.loadingStatus;
            assets.push(asset);
          }
          ins.assets = assets;
        }
      }

      this.lessonPlan.data.assessment.check.description = this.lessonPlan.data.application.classwork.description;
      this.lessonPlan.data.assessment.check.question_image_brief = this.lessonPlan.data.application.homework.question_image_brief;
    },
    customValidations() {
      // In case of ART, PLAY, STORY, CIRCLE lesson plans, no need to validate the AD, GD, HOTS data. Since these data are not present in the these lps.
      if (this.lessonPlanFormRendererType == LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE) {
        return true;
      }

      try {
        let lp = this.lessonPlan;

        if (!lp) throw Error('Invalid LessonPlan');
        if (!lp.data) throw Error('Invalid LessonPlan Data');
        if (!lp.data.analysis) throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS');
        if (!lp.data.analysis.activity_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD');
        if (!lp.data.analysis.activity_discussion.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD_EXPLANATION');
        if (!lp.data.analysis.generalized_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD');
        if (!lp.data.analysis.generalized_discussion.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD_EXPLANATION');
        if (!lp.data.analysis.higher_order_thinking)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS');
        if (!lp.data.analysis.higher_order_thinking.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS_EXPLANATION');

        // Atleast one of the AD, GD, HOTS should have the explanations image and content.
        let isADHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.activity_discussion.explanations
        );
        let isGDHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.generalized_discussion.explanations
        );
        let isHOTSHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.higher_order_thinking.explanations
        );
        if (!(isADHasExplanationData || isGDHasExplanationData || isHOTSHasExplanationData))
          throw Error(
            'Invalid LessonPlan Data. ERROR: Atleast one of Activity Discussion or Generalized Discussion or Higher Order Thinking should have valid explanations data.'
          );

        return true;
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    assetAutorizingStageValidations() {
      try {
        if (this.currentStage == 'assetAuthorizingStage') {
          let unApprovedAssetCount = 0;
          if (this.lessonPlan.data.image_url_approved != true) unApprovedAssetCount += 1;

          for (var resource of this.lessonPlan.data.aim.resources) {
            if (resource && resource.image_url && resource.image_url_approved != true) {
              unApprovedAssetCount += 1;
            }
          }

          unApprovedAssetCount += this.getUnApprovedAssetSCountForInstructions(
            this.lessonPlan.data.action.instructions
          );

          if (this.lessonPlan.data.analysis) {
            if (this.lessonPlan.data.analysis.activity_discussion.explanations) {
              unApprovedAssetCount += this.getUnApprovedAssetsCountForAnalysisDiscussions(
                this.lessonPlan.data.analysis.activity_discussion.explanations
              );
            }

            if (this.lessonPlan.data.analysis.generalized_discussion.explanations) {
              unApprovedAssetCount += this.getUnApprovedAssetsCountForAnalysisDiscussions(
                this.lessonPlan.data.analysis.generalized_discussion.explanations
              );
            }

            if (this.lessonPlan.data.analysis.higher_order_thinking.explanations) {
              unApprovedAssetCount += this.getUnApprovedAssetsCountForAnalysisDiscussions(
                this.lessonPlan.data.analysis.higher_order_thinking.explanations
              );
            }
          }

          if (this.lessonPlan.data.application) {
            if (this.lessonPlan.data.application.classwork) {
              if (this.lessonPlan.data.application.classwork.image_url_approved != true) {
                unApprovedAssetCount += 1;
              }
            }

            if (this.lessonPlan.data.application.homework) {
              if (this.lessonPlan.data.application.homework.question_image_url_approved != true) {
                unApprovedAssetCount += 1;
              }

              if (
                this.lessonPlan.data.application.homework.answer_image_url &&
                this.lessonPlan.data.application.homework.answer_image_url_approved != true
              ) {
                unApprovedAssetCount += 1;
              }
            }

            if (this.lessonPlan.data.application.more_practice) {
              unApprovedAssetCount += this.getUnApprovedAssetSCountForInstructions(
                this.lessonPlan.data.application.more_practice
              );
            }
          }

          if (this.lessonPlan.data.assessment) {
            if (this.lessonPlan.data.assessment.check) {
              if (this.lessonPlan.data.assessment.check.question_image_url_approved != true) {
                unApprovedAssetCount += 1;
              }

              if (
                this.lessonPlan.data.assessment.check.answer_image_url &&
                this.lessonPlan.data.assessment.check.answer_image_url_approved != true
              ) {
                unApprovedAssetCount += 1;
              }
            }
          }

          if (unApprovedAssetCount > 0) {
            this.$notify({
              title: 'Failed',
              text: `There are ${unApprovedAssetCount} unapproved assets`,
              type: 'error',
              duration: -1,
            });

            return false;
          }

          return true;
        } else {
          return true;
        }
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    getUnApprovedAssetSCountForInstructions(items) {
      var unApprovedAssetsCount = 0;

      for (var item of items) {
        for (var asset of item.assets) {
          if (asset.url) {
            const isApproved = asset.url_approved == true;

            if (!isApproved) unApprovedAssetsCount += 1;
          }
        }
      }

      return unApprovedAssetsCount;
    },
    getUnApprovedAssetsCountForAnalysisDiscussions(items) {
      var unApprovedAssetsCount = 0;

      for (var item of items) {
        if (item.image_url) {
          console.log(`-------- item.image_url ----------`);
          console.log(item.image_url);

          const isApproved = item.image_url_approved == true;
          if (!isApproved) unApprovedAssetsCount += 1;
        }
      }

      return unApprovedAssetsCount;
    },
    isValidExplanationDataInAnalysisStepDiscussions(explanationHash) {
      if (explanationHash && explanationHash.length) {
        let { image_url } = explanationHash[0];
        if (this.isAssetUploadRequired) {
          return image_url && image_url.length > 0;
        } else {
          return true;
        }
      }

      return false;
    },
    validateLessonPlanData(lessonPlan) {
      let lessonPlanData = lessonPlan.data;
      let resources = lessonPlanData.aim.resources;
      let errorMessage = '';
      let contentBookIndex, worksheetIndex;

      for (let index = 0; index < resources.length; index++) {
        let text = resources[index].text;
        if (text && text.toUpperCase().indexOf('CONTENT BOOK') !== -1) {
          // set content book image_url
          contentBookIndex = index;
        } else if (
          text &&
          (text.toUpperCase().indexOf('WORKSHEET') !== -1 ||
            text.toUpperCase().indexOf('WORKBOOK') !== -1)
        ) {
          // set student work book image_url
          worksheetIndex = index;
        }
      }

      if (contentBookIndex == null && worksheetIndex == null) {
        errorMessage = "'Content book' and 'Worksheet' are missing";
      } else if (contentBookIndex == null) {
        errorMessage = "'Content book' is missing";
      } else if (worksheetIndex == null) {
        errorMessage = "'Worksheet' is missing";
      } else if (
        this.isAssetUploadRequired &&
        (resources[contentBookIndex].image_url == null ||
          resources[contentBookIndex].image_url.length == 0)
      ) {
        errorMessage = "Content book's image is missing";
      } else if (
        this.isAssetUploadRequired &&
        (resources[worksheetIndex].image_url == null ||
          resources[worksheetIndex].image_url.length == 0)
      ) {
        errorMessage = "Worksheet's image is missing";
      }

      this.resourcesError = errorMessage;
    },
    importHtml(file) {
      if (file && file.name && file.name.length) {
        const fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onload = (result) => {
          this.importedHtmlFile = result.target.result;
        };
      }
    },
    iframeLoaded() {
      if (this.importedHtmlFile) {
        const doc = this.getIframeWindow(this.$refs['html-iframe']);
        const parsedJson = new LPParser(doc.document).parseHtml();
        console.log(parsedJson);
        this.setParsedJsonToState(parsedJson);
      }
    },
    getIframeWindow(iframe) {
      var doc;
      if (iframe && iframe.contentWindow) {
        return iframe.contentWindow;
      }
      if (iframe && iframe.window) {
        return iframe.window;
      }
      if (!doc && iframe && iframe.contentDocument) {
        doc = iframe.contentDocument;
      }
      if (!doc && iframe && iframe.document) {
        doc = iframe.document;
      }
      if (doc && doc.defaultView) {
        return doc.defaultView;
      }
      if (doc && doc.parentWindow) {
        return doc.parentWindow;
      }
      return undefined;
    },
    getNextStageButtonText() {
      let buttonText;
      const stages = Object.keys(this.LP_FORM_SUBMIT_TEXT);
      const nextStageIndex = stages.indexOf(this.currentStageWatcher || stages[0]) + 1;

      if (stages[nextStageIndex]) {
        buttonText =
          AppTexts.MOVE_TO_PREFIX_TEXT + this.LP_FORM_SUBMIT_TEXT[stages[nextStageIndex - 1]];
      } else {
        buttonText = 'Completed';
      }

      return buttonText;
    },
    onClickMoveToNextStage() {
      this.isSaved = true;
      const validationStatus = this.validateLPForm();
      this.prepareLessonPlanDataToSave();
      if (validationStatus) {
        let { projectId, libraryId } = this.$route.params;
        this.moveToNextStage({
          projectId,
          libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    onClickMoveToPreviousStage() {
      this.isSaved = true;
      const validationStatus = this.validateLPForm();
      this.prepareLessonPlanDataToSave();
      if (validationStatus) {
        let { projectId, libraryId } = this.$route.params;
        this.moveToPrevioustage({
          projectId,
          libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    goToProjects() {
      this.$router.push({
        path: '/projects/' + this.$route.params.projectId,
      });
    },
    closeComments() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
      this.commentSectionIdentifier = '';
    },
    diffCloseHandler() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
    },
    toggleComments(commentSectionIdentifier, event) {
      if (event) {
        this.commentButtonPageYValue = `margin-top: ${event.pageY - 30}px;`;
      }
      if (
        commentSectionIdentifier == this.commentSectionIdentifier &&
        this.rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS
      ) {
        return true;
      } else {
        this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS);
        this.commentSectionIdentifier = commentSectionIdentifier;
      }

      if (this.rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS) {
        this.fetchProjectLessonPlanSectionComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: commentSectionIdentifier,
        });
      }
    },
    toggleRightSidePanel() {
      this.showRightSidePanel = !this.showRightSidePanel;
      this.previousStateOfShowRightSidePanel = !this.showRightSidePanel;
    },
    commentsSaveButtonClickHandler(text, stageName) {
      if (this.auth.canAddComment) {
        this.saveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.commentSectionIdentifier,
          text,
          stageName,
        });
      }
    },
    commentsResolveButtonClickHandler(stageName) {
      if (this.auth.canResolveComment) {
        this.resolveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.commentSectionIdentifier,
          stageName,
        });
      }
    },
    lessonPlanDetails() {
      return {
        title: this.lessonPlan.name,
        details: `${this.$route.query.boardName} - Grade ${this.$route.query.grade} - Block ${this.$route.query.blockTitle} (${this.$route.query.bId})`,
        time: '35 mins',
      };
    },
    updateTypeOfBlockToState(blockName, value) {
      this.updateTypeOfBlock({
        blockName,
        value,
      });
    },

    getCoverImageIsChangedValue() {
      try {
        if (this.canShowDiffIcon) {
          let imageKey = this.isAssetUploadRequired ? 'image_url' : 'brief_url';
          return this.diff[imageKey].isChanged;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
    getCoverImageBriefIsChangedValue() {
      try {
        if (this.canShowDiffIcon) {
          return this.diff.brief.isChanged === true;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
    populateLessonPlanTimesObject() {
      if (this.$route.query.lpTimeKey) {
        let lpTimeKey = this.$route.query.lpTimeKey || '';
        this.lessonPlanTimeObject = {
          isArtOrPlayTime: lpTimeKey == this.LP_TIME_TYPES['artOrPlayTime'],
          isCircleTime: lpTimeKey == this.LP_TIME_TYPES['circleTime'],
          isScienceTime: lpTimeKey == this.LP_TIME_TYPES['scienceTime'],
          isMathTime: lpTimeKey == this.LP_TIME_TYPES['mathTime'],
          isStoryTime: lpTimeKey == this.LP_TIME_TYPES['storyTime'],
          isEnglishTime: lpTimeKey == this.LP_TIME_TYPES['englishTime'],
        };
        this.hideBlocksBasedOnTime(lpTimeKey);
      }
    },
    hideBlocksBasedOnTime(lpTimeKey) {
      if (lpTimeKey == 'artOrPlayTime' || lpTimeKey == 'storyTime' || lpTimeKey == 'circleTime') {
        this.lessonPlanFormRendererType = LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE;
      } else if (
        lpTimeKey == 'scienceTime' ||
        lpTimeKey == 'mathTime' ||
        lpTimeKey == 'englishTime'
      ) {
        this.lessonPlanFormRendererType = LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE;
      }
    },
    getSummaryTitle() {
      let summaryText = '';

      if (this.lessonPlanTimeObject.isArtOrPlayTime) {
        summaryText = this.lessonPlan.name;
      } else if (this.lessonPlanTimeObject.isCircleTime) {
        summaryText = this.lessonPlan.name;
      } else if (this.lessonPlanTimeObject.isEnglishTime) {
        summaryText = this.lessonPlan.name;
      } else if (this.lessonPlanTimeObject.isMathTime) {
        summaryText = this.lessonPlan.name;
      } else if (this.lessonPlanTimeObject.isScienceTime) {
        summaryText = this.lessonPlan.name;
      } else if (this.lessonPlanTimeObject.isStoryTime) {
        summaryText = this.lessonPlan.name;
      } else {
        summaryText = 'Teacher';
      }

      return (summaryText + ' summary').toUpperCase();
    },
    proceedNavigationBasedOnChoice(choice) {
      if (choice == 'yes') {
        this.saveForm(false);
      }
      if (this.navigateTo && this.navigateTo != '') {
        this.showSaveDialogPopup = false;
        this.isSaved = true;
        this.$router.push({ path: this.navigateTo.path });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isSaved) {
      this.isSaved = _.isEqual(this.lessonPlan.data, this.lessonPlanDataCopy);
    }
    if (this.isSaved) {
      next();
    } else {
      this.showSaveDialogPopup = true;
      this.navigateTo = to;
    }
  },
};
</script>
<style scoped>
.section-title-cnr {
  margin-top: 10px;
}
.preview-block {
  margin-top: 120px;
  max-height: 88vh;
  position: fixed;
  width: 30%;
}
.action-bar {
  z-index: 999;
  height: 58px;
  position: fixed;
  top: 64px;
  transform: translateY(0px);
  left: 0px;
  right: 0px;
}
.top-empty-space {
  height: 35px;
}
.section-title-bar {
  background-color: #e1e1e1;
  border-radius: 5px 5px 0px 0px !important;
  height: 70px;
  margin-bottom: 15px;
}
.section-title-content {
  border-radius: 5px;
}
.toggle-preview-button {
  position: fixed;
  top: 120px;
  z-index: 1;
}
.draft-save-button {
  color: #0da27d;
  background: #fff !important;
  border: 1px solid #0da27d !important;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
}
.save-button {
  border-radius: 2px;
  width: 150px;
  font-size: 18px;
  font-weight: 500;
}
.download-assets-button {
  color: #fff;
  background: #f08d4a !important;
  border: 1px solid #f08d4a !important;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
}
.left-hand-side-form {
  width: 94%;
  margin: auto;
}
.lp-detailes-header {
  font-size: 14px;
  color: #242424;
}
.expansion-panel:not(:first-child) {
  margin-top: 40px;
}
.read-only-mode-message {
  background-color: #f5f5f5;
  padding: 5px 10px 3px 10px;
  opacity: 0.8;
  font-size: 12px;
}
#read-only-mode-banner {
  position: fixed;
}
#read-only-mode-banner img {
  opacity: 0.1;
  position: fixed;
}
#read-only-mode-banner img.full-width-view {
  transform: rotate(-20deg);
  top: 40vh;
  left: -5px;
  width: 96vw;
}
#read-only-mode-banner img.half-width-view {
  transform: rotate(-30deg);
  top: 45vh;
  left: -45px;
  width: 71vw;
}
</style>

<style>
.disable-btn-click-event {
  pointer-events: none;
  opacity: 0.5;
}
</style>
