import gql from 'graphql-tag';

export const FETCH_TRAININGS_QUERY = gql`
  query {
    trainings(limit: 150, offset: 0) {
      id: _id
      title
      image {
        url
      }
      preTestQuestionCount
      postTestQuestionCount
      feedbackTestQuestionCount
      pollsAndCorrespondingQuestionCount
      trainingSlots {
        startDate
        endDate
        location {
          locationType
        }
        polls {
          index
        }
      }
    }
  }
`;

export const FETCH_QUESTIONS_BY_TRAINING_ID_AND_ENTITY_TYPE = gql`
  query questions($trainingId: String, $entity: String, $pollIndex: Float) {
    questions(trainingId: $trainingId, entity: $entity, pollIndex: $pollIndex) {
      _id
      training
      questionData
      questionType
      stimulus
      questionGroupId
      questionSequenceInGroup
      entity
      pollIndex
    }
  }
`;

export const SAVE_QUESTIONS_FOR_TRAINING_ENTITY = gql`
  mutation saveTrainingQuestions(
    $questionsInput: [QuestionInput!]!
    $trainingId: String!
    $entity: String!
    $pollIndex: Float!
  ) {
    saveTrainingQuestions(
      questions: $questionsInput
      trainingId: $trainingId
      entity: $entity
      pollIndex: $pollIndex
    ) {
      _id
    }
  }
`;

export const DELETE_QUESTIONS_BY_IDS = gql`
  mutation deleteTrainingQuestionsByIds($questionIds: [String!]!) {
    deleteTrainingQuestionsByIds(questionIds: $questionIds)
  }
`;

export const FETCH_TRAINING_DETAILS_BY_TRAINING_ID = gql`
  query training($id: String!) {
    training(id: $id) {
      title
      description
      preReadingDocs {
        _id
        url
        type
      }
      image {
        _id
        type
        url
      }
      trainingSlots {
        state
        startDate
        endDate
        location {
          locationType
          physicalAddress
          url
        }
        polls {
          index
        }
      }
    }
  }
`;
