<template>
  <v-card class="pa-4 rounded-lg">
    <div class="mb-4">
      <SubSection title="Create Question" />
    </div>
    <v-btn
      :disabled="isReadOnly"
      color="#B5E9E4"
      height="40px"
      width="277px"
      class="ma-1 action-button"
      @click="openQuestionDialog"
    >
      <span class="action-btn-text">+ INDIVIDUAL QUESTION</span>
    </v-btn>

    <v-btn
      v-if="selectedEntityType !== 'FEEDBACK_TEST'"
      :disabled="isReadOnly"
      color="#B5E9E4"
      height="40px"
      width="277px"
      class="ma-1 action-button"
      @click="openGroupQuestionDialog"
    >
      <span class="action-btn-text">+ GROUP QUESTION</span>
    </v-btn>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import SubSection from '@/components/inputs/SubSection.vue';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import _ from 'lodash';

export default {
  components: {
    SubSection,
  },
  methods: {
    ...mapMutations([
      'SET_SHOW_QUESTION_DIALOG',
      'SET_CURRENT_QUESTION_DIALOG_DATA',
      'SET_SHOW_GROUP_QUESTION_DIALOG',
      'SHOW_SOURCE_BLOCK_FILTER_DIALOG',
    ]),
    openQuestionDialog() {
      this.SET_SHOW_QUESTION_DIALOG(true);
      let defaultQuestionData = _.cloneDeep(DEFAULT_QUESTION_DATA.mcq);
      if (this.modifyChangeQuestionBaseData) {
        defaultQuestionData = this.modifyChangeQuestionBaseData(defaultQuestionData);
      }
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: defaultQuestionData });
    },
    openGroupQuestionDialog() {
      this.SET_SHOW_GROUP_QUESTION_DIALOG(true);
      let defaultQuestionsData = [_.cloneDeep(DEFAULT_QUESTION_DATA.mcq)];
      if (this.modifyChangeQuestionBaseData) {
        defaultQuestionsData = this.modifyChangeQuestionBaseData(defaultQuestionsData);
      }
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: defaultQuestionsData });
    },
    openSourceBlockFilterDialog() {
      this.SHOW_SOURCE_BLOCK_FILTER_DIALOG(true);
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    selectedEntityType: {
      type: String,
      require: false,
      default: '',
    },
    modifyChangeQuestionBaseData: {
      type: Function,
      default: undefined,
    },
  },
};
</script>
