export const EXHAUSTIVE_BLOCK_MAPS_LIST = [
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: 'Nursery',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: 'Nursery',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: 'LKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: 'LKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: 'UKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: 'UKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '1',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '1',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '1',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '1',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '2',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '2',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '2',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '2',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '3',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '3',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '3',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '3',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '3',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '4',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '4',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '4',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '4',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '4',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '5',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '5',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '5',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '5',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '5',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '6',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '6',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '6',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '6',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '6',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '7',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '7',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '7',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '7',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '7',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '8',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '8',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '8',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '8',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'ICSE',
    Grade: '8',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: 'Nursery',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: 'Nursery',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: 'LKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: 'LKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: 'UKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: 'UKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '1',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '1',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '1',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '1',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '2',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '2',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '2',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '2',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '3',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '3',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '3',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '3',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '3',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '4',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '4',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '4',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '4',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '4',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '5',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '5',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '5',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '5',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '5',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '6',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '6',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '6',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '6',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '6',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '7',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '7',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '7',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '7',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '7',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '8',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '8',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '8',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '8',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'TN',
    Grade: '8',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: 'Nursery',
    Subject: 'Early Childhood',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: 'Nursery',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: 'LKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: 'LKG',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: 'UKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: 'UKG',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '1',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '1',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '1',
    Subject: 'Environmental Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '1',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '2',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '2',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '2',
    Subject: 'Environmental Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '2',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '3',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '3',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '3',
    Subject: 'Environmental Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '3',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '4',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '4',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '4',
    Subject: 'Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '4',
    Subject: 'Social Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '4',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '5',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '5',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '5',
    Subject: 'Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '5',
    Subject: 'Social Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '5',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '6',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '6',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '6',
    Subject: 'Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '6',
    Subject: 'Social Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '6',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '7',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '7',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '7',
    Subject: 'Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '7',
    Subject: 'Social Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '7',
    Subject: 'Hindi',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '8',
    Subject: 'English',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '8',
    Subject: 'Mathematics',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '8',
    Subject: 'Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '8',
    Subject: 'Social Science',
  },
  {
    Product: 'XSEED One',
    Board: 'XSEED One India',
    Grade: '8',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: 'Nursery',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: 'Nursery',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: 'LKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: 'LKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: 'UKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: 'UKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Hindi',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'English',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Mathematics',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Social Science',
  },
  {
    Product: 'Foundation',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: 'Nursery',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: 'Nursery',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: 'LKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: 'LKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: 'UKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: 'UKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '1',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '2',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '3',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '4',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '5',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '6',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '7',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'CBSE',
    Grade: '8',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: 'Nursery',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: 'Nursery',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: 'LKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: 'LKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: 'UKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: 'UKG',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '1',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '1',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '1',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '1',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '2',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '2',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '2',
    Subject: 'Environmental Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '2',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '3',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '3',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '3',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '3',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '3',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '4',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '4',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '4',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '4',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '4',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '5',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '5',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '5',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '5',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '5',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '6',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '6',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '6',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '6',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '6',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '7',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '7',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '7',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '7',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '7',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '8',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '8',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '8',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '8',
    Subject: 'Social Science',
  },
  {
    Product: 'Prime',
    Board: 'State Board',
    Grade: '8',
    Subject: 'Hindi',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: 'Nursery',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: 'LKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: 'UKG',
    Subject: 'Early Childhood',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '1',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '1',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '1',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '2',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '2',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '2',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '3',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '3',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '3',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '4',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '4',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '4',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '5',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '5',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '5',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '6',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '6',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '6',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '7',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '7',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '7',
    Subject: 'Science',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '8',
    Subject: 'English',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '8',
    Subject: 'Mathematics',
  },
  {
    Product: 'Prime',
    Board: 'Philippines',
    Grade: '8',
    Subject: 'Science',
  },
];
