import { LAYOUT_1_OBJECT } from '@/components/lessonPlanTemplate/commonLpComponents/layouts/generic/layoutObject.js';
import { cloneDeep } from 'lodash';
const INSTRUCTION_COMPONENT = [
  {
    description: '',
    children: [],
    instructionBreak: false,
    assets: [],
  },
];
const EXPLANATION_COMPONENT = [
  {
    description: '',
    assetId: '',
    brief: {
      text: '',
      assetId: '',
    },
    assetApproved: false,
  },
];

const SLIDE_COMPONENT = [LAYOUT_1_OBJECT];

const SLIDE_INSTRUCTION_COMPONENT = {
  instructions: cloneDeep(SLIDE_COMPONENT),
};

const SLIDE_DISCUSSION_COMPONENT = {
  discussions: cloneDeep(SLIDE_COMPONENT),
};

const SLIDE_EXPLANATION_COMPONENT = {
  explanations: cloneDeep(SLIDE_COMPONENT),
};

const ANALYSIS_DISCUSSION_LEGACY_DATA = {
  key_question: '',
  key_question_guide: '',
  key_question_assets: [],
};

const ANALYSIS_EXPLANATION_LEGACY_DATA = {
  explanations: [],
  instructions: [
    {
      description: '',
    },
  ],
};

const ACTION_SECTION_STANDARD_LP = {
  instructions: cloneDeep(INSTRUCTION_COMPONENT),
  teacher_tip: '',
};

const CODING_CONNECTION_SECTION_STANDARD_LP = {
  instructions: cloneDeep(INSTRUCTION_COMPONENT),
  teacher_tip: '',
};


const ANALYSIS_EXPLANATION_STANDARD_LP = {
  
  explanations: cloneDeep(EXPLANATION_COMPONENT),
  teacher_tip: ''
};

const MORE_PRACTICE = [
  {
    description: '',
    assets: [],
  },
];

const COMPONENT_TO_DS_MAP = {
  INSTRUCTION: INSTRUCTION_COMPONENT,
  SLIDE_LAYOUT: SLIDE_COMPONENT,
  SLIDE_INSTRUCTION: SLIDE_INSTRUCTION_COMPONENT,
  SLIDE_EXPLANATION: SLIDE_EXPLANATION_COMPONENT,
  SLIDE_DISCUSSION: SLIDE_DISCUSSION_COMPONENT,
  ANALYSIS_DISCUSSION_LEGACY_DATA: ANALYSIS_DISCUSSION_LEGACY_DATA,
  ANALYSIS_EXPLANATION_LEGACY_DATA: ANALYSIS_EXPLANATION_LEGACY_DATA,
  MORE_PRACTICE: MORE_PRACTICE,
  STANDARD_LP_ACTION: ACTION_SECTION_STANDARD_LP,
  ANALYSIS_EXPLANATION_STANDARD_LP: ANALYSIS_EXPLANATION_STANDARD_LP,
  CODING_CONNECTION_SECTION_STANDARD_LP: CODING_CONNECTION_SECTION_STANDARD_LP
};

export { COMPONENT_TO_DS_MAP };
