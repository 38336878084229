<template>
  <div class="diff-content-container">
    <div class="diff-content-header">
      <span class="role-name">{{ roleName }}</span>
      <span class="time">{{ time }}</span>
    </div>
    <hr />
    <div class="diff-text editor__content" v-html="text"></div>
  </div>
</template>
<script>
export default {
  name: 'DiffDetails',
  props: {
    roleName: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.diff-content-container {
  background-color: #f6f6f6;
  padding: 5px;
}
.diff-content-header {
  overflow: hidden;
  padding: 0 10px;
}
.role-name {
  float: left;
  font-weight: 500;
  font-size: 18px;
}
.time {
  float: right;
  font-size: 14px;
}
.diff-text {
  padding: 0 10px;
  font-size: 16px;
}
</style>
