<template>
  <div :class="isDisabled ? 'group-question-view-wrapper disabled' : 'group-question-view-wrapper'">
    <div class="question-group-stimuls-wrapper">
      <div class="display-flex">
        <div class="group-question-header">
          <div class="group-question-select-checkbox display-flex">
            <div class="display-flex">
              <div class="question-select-checkbox" v-if="!isReuseQuestion">
                <v-checkbox
                  dense
                  @change="(isChecked) => $emit('onSelectQuestion', isChecked, data)"
                  class="ma-0 pa-0"
                  hide-details
                  v-model="isSelected"
                  :disabled="isDisabled || !(canSelectItem || isSelected)"
                />
              </div>
              <p style="cursor: pointer;" @click="$emit('showQuestionFullScreenDialog', data)">
                <u>Group Question {{ index }}</u>
              </p>
            </div>
            <div class="disabled-reason-div" v-if="isDisabled">
              {{ reasonText }}
            </div>
          </div>
        </div>
      </div>
      <div class="group-question-stimuls">
        <div
          v-html="RTETextToHTML(stimulusText, isAssetUploadRequired)"
          class="editor__content"
        ></div>
      </div>
      <div
        class="individual-question-wrapper"
        v-for="(question, questionIndex) in data"
        :key="questionIndex"
      >
        <SingleQuestionView
          :index="questionIndex + 1"
          :questionMetaInfo="questionMetaInfo"
          :lessonPlans="lessonPlans"
          :data="question"
          :isQuestionGroupQuestionView="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RTETextToHTML } from '@xseededucation/rte-helper-functions';
import SingleQuestionView from '@/components/Questions/SingleQuestionView';
import { getAssetUploadRequiredStatus } from '@/utils';

export default {
  name: 'SingleGroupQuestionView',
  components: {
    SingleQuestionView,
  },
  computed: {
    isAssetUploadRequired() {
      let projectCurrentStage = Array.isArray(this.data)
        ? this.data[0].projectCurrentStage
        : this.data.projectCurrentStage;

      return this.getAssetUploadRequiredStatus(projectCurrentStage);
    },
    stimulusText() {
      return this.data.find((_) => _.stimulus != null).stimulus;
    },
    linkDetails() {
      return (this.data.find((_) => _.linkDetails != null) || {}).linkDetails;
    },
    isDisabled() {
      return this.linkDetails && this.linkDetails.disabled;
    },
    reasonText() {
      if (this.isDisabled && this.linkDetails) {
        const isAddedInCurrentLp =
          this.$route.params.libraryId.toString() == this.linkDetails.lpId.toString();

        return isAddedInCurrentLp ? 'Already Added' : `Added in ${this.linkDetails.lpName}`;
      } else {
        return '';
      }
    },
  },
  methods: {
    RTETextToHTML,
    getAssetUploadRequiredStatus,
  },
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    questionMetaInfo: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    canSelectItem: {
      type: Boolean,
      required: true,
    },
    isReuseQuestion: {
      type: Boolean,
      required: false,
      default: false,
    },
    lessonPlans: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.group-question-view-wrapper {
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  padding: 19px 25px;
}
.group-question-view-wrapper.disabled {
  color: rgba(0, 0, 0, 0.5);
}
.group-question-header p {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 20px;
}
.group-question-header {
  width: 100%;
}
.group-question-select-checkbox {
  padding-bottom: 20px;
  width: 100%;
  justify-content: space-between;
}
.group-question-select-checkbox p {
  padding-bottom: 0px;
}
</style>

<style>
.group-question-header p {
  margin-bottom: 0px;
}
.group-question-view-wrapper.disabled .single-question-view-wrapper *:not(.disabled-reason-div) {
  color: rgba(0, 0, 0, 0.5) !important;
}
.individual-question-wrapper .single-question-view-wrapper {
  background: #fff;
  margin: 10px 18px;
}
</style>
