const { isRteEmpty } = require('@xseededucation/rte-helper-functions');
const questionsOprogramProject = require('../constants/questionsOprogramProject').default;
const {
  WRITING_LINES_HASH,
  DRAWING_BOX_OPTIONS,
  VIRTUAL_QUESTION_PURPOSE_DISPLAY_TEXT_MAPPING,
} = require('../constants/question');

function getQuestionTypeDisplayText(type) {
  switch (type) {
    case 'mcq':
      return 'Multiple choice question';
    case 'fib':
      return 'Fill in the blanks';
    case 'tf':
      return 'True or False';
    case 'mtf':
      return 'Match the following';
    case 'msq':
      return 'Multi-select question';
    case 'sa':
      return 'Short Answer';
    case 'atf':
      return 'Arrange the following';
    case 'la':
      return 'Long Answer';
    default:
      return 'Invalid Type';
  }
}

function getQuestionPurposeDisplayText(questionPurpose) {
  return VIRTUAL_QUESTION_PURPOSE_DISPLAY_TEXT_MAPPING[questionPurpose] || 'Invalid purpose';
}

function questionMetaValidation(data, lpType, isLanguageSubject) {
  let text = [];
  if (data.time == null || data.time == '') {
    text.push('- Please fill in the time field');
  }
  if (!(data.time && parseInt(data.time) > 0 && parseInt(data.time) <= 20)) {
    text.push('- Please enter time greater than 0 and less than 21 minutes');
  }
  if (data.skill == null || data.skill == '') {
    text.push('- Please fill in the skill field');
  }

  if (data.academicYear == null || data.academicYear == '') {
    text.push('- Please fill in the academic year');
  }

  if (data.questionPurpose == null || data.questionPurpose == '') {
    text.push('- Please fill in the questionPurpose field');
  }
  const { LP_TYPES_HASH } = require('../constants/common');
  if (lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS) {
    if (data.lessonPlanLibraryId == null || data.lessonPlanLibraryId == 0) {
      text.push('- Please choose a lesson plan to link');
    }
    if (data.subSkill == null || data.subSkill == 0) {
      text.push('- Please choose a Sub Skill');
    }
    if (isLanguageSubject && !data.languageSkill) {
      text.push('- Please choose a Language Skill');
    }
  }

  return text;
}

function validateQuestionText(questionText) {
  let text = [];
  if (isRteEmpty(questionText)) {
    text.push('- Please enter Question Text');
  }
  return text;
}

function validateQuestionDataBasedOnType(
  data,
  lpType,
  isLanguageSubject,
  showMetaFields = true,
  turnOffQuestionTextValidation = false
) {
  let text = [];
  const { questionText } = data.questionData;
  if (showMetaFields) {
    text = questionMetaValidation(data, lpType, isLanguageSubject);
  }
  if (!turnOffQuestionTextValidation) {
    text.push(...validateQuestionText(questionText));
  }
  if (
    data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ ||
    data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ
  ) {
    const { options } = data.questionData;

    if (options.length < 2 || options.length > 10) {
      text.push('- Please add a minimum of 2 and a maximum of 10 options');
    }

    var isOptionEmpty = false;
    options.forEach((option) => {
      if (isRteEmpty(option.optionText)) {
        isOptionEmpty = true;
        return false;
      }
    });
    if (isOptionEmpty) {
      text.push('- Please enter a value for each option');
    }

    if (options.filter((option) => option.isCorrectAnswer == true).length == 0) {
      text.push('- Please select a correct answer');
    }
  } else if (data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF) {
    const { options } = data.questionData;

    if (options.filter((option) => option.isCorrectAnswer == true).length == 0) {
      text.push('- Please select a correct answer');
    }
  } else if (data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF) {
    const { optionKeys, optionValues } = data.questionData;

    if (optionKeys.length < 3 || optionKeys.length > 10) {
      text.push('-  Please add a minimum of 3 and a maximum of 10 options');
    }
    if (optionKeys.length != optionValues.length) {
      text.push('- Please Make sure whether keys and values are of same length');
    }
    var isOptionKeyEmpty = false;
    optionKeys.forEach((option) => {
      if (isRteEmpty(option.optionText)) {
        isOptionKeyEmpty = true;
        return false;
      }
    });
    if (isOptionKeyEmpty) {
      text.push('- Please enter a value for each option Keys');
    }
    var isOptionValueEmpty = false;
    optionValues.forEach((option) => {
      if (isRteEmpty(option.optionText)) {
        isOptionValueEmpty = true;
        return false;
      }
    });
    if (isOptionValueEmpty) {
      text.push('- Please enter a value for each option Values');
    }
  } else if (data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB) {
    const machineScorable = data.machineScorable;
    const { blanks, distractors } = data.questionData;
    if (blanks.length < 1) {
      text.push('- A min of one blank is required');
    }
    var isBlankEmpty = false;
    var scorePercentage = 0;

    blanks.forEach((blank) => {
      blank.correctAnswers.forEach((correctAnswer) => {
        if (isRteEmpty(correctAnswer.text)) {
          isBlankEmpty = true;
          return false;
        }
      });
      scorePercentage += parseFloat(blank.scorePercentage);
    });
    if (isBlankEmpty) {
      text.push("- Blank can't be empty");
    }
    if (scorePercentage != 100 && machineScorable) {
      text.push('- Sum of the score percentages should be 100');
    }

    var isDistractorEmpty = false;
    distractors.forEach((distractor) => {
      if (isRteEmpty(distractor.text)) {
        isDistractorEmpty = true;
        return false;
      }
    });

    if (isDistractorEmpty) {
      text.push("- Distractors can't be empty");
    }
  } else if (data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF) {
    const { optionValues } = data.questionData;

    if (optionValues.length < 3 || optionValues.length > 10) {
      text.push('-  Please add a minimum of 2 and a maximum of 10 options');
    }
    var isOptionValuesEmpty = false;
    optionValues.forEach((option) => {
      if (isRteEmpty(option.optionText)) {
        isOptionValuesEmpty = true;
        return false;
      }
    });
    if (isOptionValuesEmpty) {
      text.push('- Please enter a value for each options');
    }
  } else if (
    data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
    data.questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA
  ) {
    const { allowedResponseTypes, answerGuideText } = data.questionData;
    if (data.questionPurpose == questionsOprogramProject.QUESTION_PURPOSE_MICROTEST) {
      text.push(
        `- Short Answer / Long Answer cannot be of type ${questionsOprogramProject.QUESTION_PURPOSE_MICROTEST}`
      );
    }
    const { LP_TYPES_HASH } = require('../constants/common');
    if (
      lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS &&
      (data.questionPurpose == questionsOprogramProject.QUESTION_PURPOSE_ASSESSMENT ||
        data.questionPurpose == questionsOprogramProject.QUESTION_PURPOSE_WORKSHEET)
    ) {
      let { writingLines, drawingBox } = data;
      writingLines = parseInt(writingLines);

      // isWritingLinesValid is valid if
      // 1. 0 <= writingLines <= 20
      const isWritingLinesValid =
        WRITING_LINES_HASH.min <= writingLines && writingLines <= WRITING_LINES_HASH.max;

      let drawingBoxValues = DRAWING_BOX_OPTIONS.map((e) => e.value);
      const isDrawingBoxValid = drawingBoxValues.includes(drawingBox);

      if (!isWritingLinesValid) {
        text.push(
          `- Writing lines should have value between ${WRITING_LINES_HASH.min} and ${WRITING_LINES_HASH.max}`
        );
      }

      if (!isDrawingBoxValid) {
        text.push(`- Drawing box should have proper value`);
      }
    }
    if (allowedResponseTypes.length == 0) {
      text.push('- Please select accept response type');
    }
    if (isRteEmpty(answerGuideText)) {
      text.push('- Please enter a value for answer guide text');
    }
  }
  return text;
}

function validateAnswerCriteria(answerCriteria) {
  let errorMessages = [];

  if (answerCriteria && answerCriteria.length) {
    let totalAnswerCriteriaPercentage = 0;
    let hasTitleForAllFields = true;
    let hasPercentForAllFields = true;

    answerCriteria.forEach((element) => {
      totalAnswerCriteriaPercentage += parseFloat(element.percentage || 0);

      if (!(element.title && element.title.length)) {
        hasTitleForAllFields = hasTitleForAllFields && false;
      }
      if (element.percentage <= 0) {
        hasPercentForAllFields = hasPercentForAllFields && false;
      }
    });

    if (totalAnswerCriteriaPercentage != 100) {
      errorMessages.push('- The sum of answer criteria percentage must be 100');
    }
    if (!hasPercentForAllFields) {
      errorMessages.push('- Please fill all the answer criteria percentage greater then 0');
    }
    if (!hasTitleForAllFields) {
      errorMessages.push('- Please fill all the answer criteria text');
    }
  } else {
    errorMessages.push('- Please fill answer criteria');
  }

  return errorMessages;
}

module.exports = {
  getQuestionTypeDisplayText,
  getQuestionPurposeDisplayText,
  validateQuestionDataBasedOnType,
  validateAnswerCriteria,
};
