const state = {
  subjectListApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  standardsListApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  projectListApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  airtableBigSkillsApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  airtableSubSkillsApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  airtableDataOnApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  airtableThemesApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  airtableMicrotopicsApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  airtableMicroskillsCategoiesOnApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  recentlyCreatedQuestionSetsApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  questionSetsUsingFiltersApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  blueprintListApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  createTestApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  editTestApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  deleteTestApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  getTestestApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
  yearListApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },

  getProjectListApiResponse: {
    status: 'completed',
    message: 'Successfully fetched data!',
    data: [],
  },
};

export default state;
