const getTeacherTip = () => {
  return {
    name: 'row',
    props: { justify: 'space-between' },
    children: [
      {
        name: 'expanded',
        child: {
          name: 'column',
          children: [
            {
              name: 'teacherTip',
              props: { enabled: false, name: 'teacherTip' },
              content: '',
            },
          ],
        },
      },
    ],
  };
};
const LAYOUT_1_OBJECT = {
  layoutName: 'layout1',
  layoutTemplate: 'generic',
  layoutOrder: ['components.0', 'components.1', 'components.2'],
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
                props: { name: 'header' },
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};

const LAYOUT_2_OBJECT = {
  layoutName: 'layout2',
  layoutTemplate: 'generic',
  layoutOrder: ['components.0', 'components.1', 'components.2'],
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                content: '',
                props: { name: 'step' },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};

const LAYOUT_3_OBJECT = {
  layoutName: 'layout3',
  layoutTemplate: 'generic',
  layoutOrder: ['components.0', 'components.1', 'components.2', 'components.3'],
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'list',
      children: [
        {
          name: 'column',
          children: [
            {
              name: 'file',
              props: { name: 'asset' },
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                  text: '',
                },
              },
            },
          ],
        },
        {
          name: 'column',
          children: [
            {
              name: 'file',
              props: { name: 'asset' },
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                  text: '',
                },
              },
            },
          ],
        },
      ],
    },
    getTeacherTip(),
  ],
};

const LAYOUT_4_OBJECT = {
  layoutName: 'layout4',
  layoutTemplate: 'generic',
  layoutOrder: ['components.0', 'components.1', 'components.2', 'components.3'],
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'list',
      children: [
        {
          name: 'column',
          children: [
            {
              name: 'file',
              props: { name: 'asset' },
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                  text: '',
                },
              },
            },
          ],
        },
        {
          name: 'column',
          children: [
            {
              name: 'file',
              props: { name: 'asset' },
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                  text: '',
                },
              },
            },
          ],
        },
        {
          name: 'column',
          children: [
            {
              name: 'file',
              props: { name: 'asset' },
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                  text: '',
                },
              },
            },
          ],
        },
      ],
    },
    getTeacherTip(),
  ],
};
const LAYOUT_5_OBJECT = {
  layoutName: 'layout5',
  layoutTemplate: 'generic',
  layoutOrder: ['components.0', 'components.1', 'components.2', 'components.3'],
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'center' },
      children: [
        {
          name: 'column',
          children: [
            {
              name: 'file',
              props: { name: 'asset' },
              content: {
                assetId: '',
                brief: {
                  assetId: '',
                  text: '',
                },
              },
            },
          ],
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};
const LAYOUT_6_OBJECT = {
  layoutName: 'layout6',
  layoutTemplate: 'generic',
  layoutOrder: ['components.0', 'components.1', 'components.2'],
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'center' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};
const LAYOUT_7_OBJECT = {
  layoutName: 'layout7',
  layoutTemplate: 'generic',
  layoutOrder: [
    'components.5',
    'components.0',
    'components.1',
    'components.2',
    'components.3',
    'components.4',
  ],
  components: [
    {
      name: 'row',
      props: { justify: 'space-between', dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between', dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between', dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'footer' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
      ],
    },
  ],
};

const LAYOUT_8_OBJECT = {
  layoutName: 'layout8',
  layoutTemplate: 'generic',
  components: [
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};

const LAYOUT_9_OBJECT = {
  layoutName: 'layout9',
  layoutTemplate: 'generic',
  layoutOrder: [
    'components.0',
    'components.1',
    'components.2',
    'components.3',
    'components.4',
  ],
  components: [ 
    {
      name: 'row',
      props: { justify: 'space-between' },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'header' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between', dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { justify: 'space-between', dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'file',
                props: { name: 'asset' },
                content: {
                  assetId: '',
                  brief: {
                    assetId: '',
                    text: '',
                  },
                },
              },
            ],
          },
        },
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'step' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'expanded',
          child: {
            name: 'column',
            children: [
              {
                name: 'rte',
                props: { name: 'footer' },
                content: '',
              },
            ],
          },
        },
      ],
    },
    getTeacherTip(),
  ],
};
module.exports = {
  LAYOUT_1_OBJECT,
  LAYOUT_2_OBJECT,
  LAYOUT_3_OBJECT,
  LAYOUT_4_OBJECT,
  LAYOUT_5_OBJECT,
  LAYOUT_6_OBJECT,
  LAYOUT_7_OBJECT,
  LAYOUT_8_OBJECT,
  LAYOUT_9_OBJECT,
};

