<template>
  <div>
    <BlockTitle :title="AppTexts.WHAT_WE_LEARNED" />
    <Step
      :items="lessonPlanData.assessment.what_we_learned.instructions"
      :canShowAddNewStepButton="true"
      :addNewStepHandler="addNewStep"
      :allowDeleteStep="true"
      :deleteStepHandler="deleteNewStep"
      :descriptionPlaceholder="AppTexts.EXPLANATION"
      :maxNoOfSteps="schema.configurableClassroomExplanation.maxNoOfSteps"
      :maxNoOfSubSteps="schema.configurableClassroomExplanation.maxNoOfSubStepsInStep"
      :maxSumOfStepsAndSubSteps="schema.configurableClassroomExplanation.maxSumOfStepsAndSubSteps"
      :canShowStepBullet="true"
      :canShowDiffIcon="canShowDiffIcon"
      stepDiffIdentifier="lessonPlanData.assessment.what_we_learned.instructions[<stepIndex>].description"
      :isStepDescriptionChanged="isStepDescriptionChanged"
      class="analysis-explanation-step"
      :isNotReadOnly="isNotReadOnly"
      :stepDescriptionMaxLength="schema.discussionStepDescriptionMaxLength"
      :subStepDescriptionMaxLength="schema.discussionSubStepDescriptionMaxLength"
      :stepTeacherTipMaxLength="schema.discussionStepTeacherTipMaxLength"
      :subPath="'assessment.what_we_learned.instructions'"
    />
    <v-divider class="mgn-Ts mgn-Bs" />
    <MultipleAssetUploader
      :key="`whatWeLearnedKey${signedUrlKey}`"
      :items="lessonPlanData.assessment.what_we_learned.explanations"
      :addAssetAction="addExplanationAsset"
      :deleteAssetAction="deleteExplanationAsset"
      :canShowDiffIcon="canShowDiffIcon"
      :itemDiff="getExaplanationDiff"
      diffIdentifierPrefix="assessment.what_we_learned.explanations"
      :assetType="AssetTypes.HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE"
      withAdd
      :uploadAssetAction="importAnalysisExplanationAssetAction"
      :deleteAssetPathAction="deleteAnalysisExplanationAssetAction"
      :placeholder="AppTexts.TITLE"
      :isRequired="true"
      :label="AppTexts.SELECT_FILE"
      :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :acceptableFileType="acceptableFileType"
      :assetsSignedUrls="signedUrls.what_we_learned.explanations"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="canFinalizeAsset"
      :canApproveAsset="canApproveAsset"
      subPath="assessment.what_we_learned.explanations"
    />
    <div class="mgn-Ts mgn-Bs">
      <XSDisplayCard :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isTeacherTipGuideChanged()"
          fieldIdentifier="assessment.what_we_learned.teacher_tip"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="lessonPlanData.assessment.what_we_learned.teacher_tip"
          :counter="schema.discussionTeacherTipMaxLength"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
      </XSDisplayCard>
    </div>
  </div>
</template>
<script>
import Step from '@/components/inputs/Step';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import MultipleAssetUploader from '@/components/inputs/MultipleAssetUploader';
import BlockTitle from '@/components/inputs/BlockTitle';
import AppTexts from '@/constants/AppTexts';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import { mapActions } from 'vuex';
import AssetTypes from '@/constants/AssetTypes';
export default {
  data() {
    return {
      AppTexts,
      BASIC_TOOLS,
      AssetTypes,
      signedUrlKey: 0,
    };
  },
  components: {
    Step,
    RichTextEditor,
    MultipleAssetUploader,
    BlockTitle,
    XSDisplayCard,
  },
  watch: {
    signedUrls: {
      deep: true,
      immediate: true,
      handler() {
        this.signedUrlKey++;
      },
    },
  },
  methods: {
    ...mapActions([
      'addInstructionStepForPath',
      'deleteInstructionStepForPath',
      'addExplanationAssetForPath',
      'importExplanationAssetActionForPath',
      'deleteExplanationAssetActionForPath',
      'deleteAnaysisExplanationAssetsForPath',
    ]),
    addExplanationAsset(type) {
      this.addExplanationAssetForPath({ path: 'assessment.what_we_learned.explanations', type });
    },
    deleteExplanationAsset({ index }) {
      this.deleteAnaysisExplanationAssetsForPath({
        path: 'assessment.what_we_learned.explanations',
        index,
      });
    },
    getExaplanationDiff() {
      try {
        if (this.canShowDiffIcon) {
          return this.diff.what_we_learned.explanations;
        }
      } catch (error) {
        return {};
      }

      return {};
    },
    isStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.diff.what_we_learned.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isTeacherTipGuideChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.diff.what_we_learned.teacher_tip.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    addNewStep({ index, path }) {
      this.addInstructionStepForPath({ index, path, hasSubStep: false, hasAsset: false });
    },
    deleteNewStep({ index, path }) {
      this.deleteInstructionStepForPath({ index, path, hasAsset: false });
    },
    importAnalysisExplanationAssetAction(payload) {
      this.importExplanationAssetActionForPath({
        ...payload,
        path: 'assessment.what_we_learned.explanations',
      });
    },
    deleteAnalysisExplanationAssetAction(payload) {
      this.deleteExplanationAssetActionForPath({
        ...payload,
        path: 'assessment.what_we_learned.explanations',
      });
    },
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    isNotReadOnly: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      required: true,
    },
    diff: {
      type: Object,
      default: () => {},
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    fileValidators: { type: Array, required: false, default: () => [] },
    canFinalizeAsset: {
      type: Boolean,
      default: true,
    },
    canApproveAsset: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      required: true,
    },
  },
};
</script>
