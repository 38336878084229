<template>
    <v-container class="card-container">
        <v-card color="#f5f5f5" width="250" height="250" class="d-flex flex-column justify-center">
            <v-row class="px-0 pa-0">
                <v-spacer></v-spacer>
                <v-col cols="3">
                    <v-menu :close-on-click="closeOnClick"
                            :close-on-content-click="closeOnContentClick"
                            :offset-y="offsetY"
                            left>
                        <template v-slot:activator="{ on }">
                            <span v-on="on" class="mdi mdi-dots-vertical icon-dot ml-3"></span>
                        </template>
                        <v-list>
                            <v-list-item @click="deleteAction(data)">
                                <v-list-item-title>Delete User</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row @click="editAction(data)" class="d-flex justify-center flex-column align-center">
                <v-avatar color="white" size="62">
                    <span class="black--text headline font-weight-bold">{{ initials }}</span>
                </v-avatar>
                <v-card-text class="font-weight-bold mt-2 px-4 pa-0 text-center">{{ title }}</v-card-text>
                <v-card-text class="text-center px-4 pa-0">{{ subtitle }}</v-card-text>
                <v-btn min-width="150" class=" mb-6 button-shadow-none mt-4" small color="#ffb458">{{ tags }}</v-btn>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
  export default {
    name: "XSCard",
    data() {
      return {
        closeOnClick: true,
        closeOnContentClick: true,
        offsetY: false
      };
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      deleteAction: {
        type: Function,
        required: true
      },
      editAction: {
        type: Function,
        required: true
      },
      tags: {
        type: String
      },
      initials: {
        type: String,
        required: true
      }
    }
  };
</script>
<style scoped lang="scss">
    .button-shadow-none {
        box-shadow: none;
    }

    .card-container {
        width: auto !important;
    }

    .icon-dot {
        font-size: 28px;
        cursor: pointer;
        margin-top: -1.5rem !important;
    }


</style>
