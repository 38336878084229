export const SUBJECT_SELECTION_ACTION = {
  setSelectedBoard(context, selectedBoard) {
    context.commit('SET_SELECTED_BOARD', selectedBoard);
  },
  setSelectedSubject(context, selectedSubject) {
    context.commit('SET_SELECTED_SUBJECT', selectedSubject);
  },
  setSelectedGrade(context, selectedGrade) {
    context.commit('SET_SELECTED_GRADE', selectedGrade);
  },
  setSelectedProduct(context, selectedProduct) {
    context.commit('SET_SELECTED_PRODUCT', selectedProduct);
  }
};
