<template>
  <v-form style="margin-top: 15px;" dense ref="stepForm" class="steps-cnr">
    <div
      style="align-items: center;"
      v-for="(item, stepIndex) in items"
      :key="`indexForStep${stepRevision}${stepIndex}`"
      :v-bind="stepIndex"
    >
      <div dense raised class="card">
        <v-row class="title-row align-child-middle-center">
          <span v-if="canShowStepNumber" class="step-number">{{ stepIndex + 1 }}</span>
          <span v-else-if="canShowStepBullet" class="step-bullet"></span>
          <v-col>
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isStepDescriptionChanged(stepIndex)"
              :fieldIdentifier="getStepDiffIdentifier(stepIndex)"
              :showValidationError="true"
              :placeholder="descriptionPlaceholder"
              v-model="item.description"
              :readonly="!isNotReadOnly"
              :counter="stepDescriptionMaxLength"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </v-col>
          <span v-if="allowDeleteStep && isNotReadOnly" cols="1">
            <v-btn title="Delete step" icon @click="callDeleteStepHandler(stepIndex)">
              <img v-if="hasSubStep" height="19" width="16" src="@/assets/bin.svg" />
              <img v-else height="16" width="16" src="@/assets/close.svg" />
            </v-btn>
          </span>
        </v-row>
        <div
          v-for="(child, subStepIndex) in item.children"
          :key="`subStepComponent${refreshSubStep}${subStepIndex}`"
          :v-bind="subStepIndex"
        >
          <v-row class="align-child-middle-center" v-if="hasSubStep">
            <span v-if="canShowStepNumber" class="step-number-placeholder"></span>
            <img class="mgn-Ls mgn-Rs" height="30" width="20" src="@/assets/enter.svg" />
            <v-col>
              <RichTextEditor
                :canShowDiffIcon="canShowDiffIcon"
                :isDiffChanged="isSubStepDescriptionChanged(stepIndex, subStepIndex)"
                :fieldIdentifier="getSubStepDiffIdentifier(stepIndex, subStepIndex)"
                :showValidationError="true"
                :placeholder="'Sub ' + descriptionPlaceholder"
                v-model="child.description"
                customClass=""
                :readonly="!isNotReadOnly"
                :counter="subStepDescriptionMaxLength"
                :includeOnlyToolBarActions="BASIC_TOOLS"
              />
            </v-col>
            <span>
              <v-btn
                icon
                v-if="allowDeleteSubStep && isNotReadOnly"
                @click="callDeleteSubStepHandler({ stepIndex, subStepIndex })"
                title="Delete sub step"
              >
                <img height="16" width="16" src="@/assets/close.svg" />
              </v-btn>
            </span>
          </v-row>
        </div>
        <v-row v-if="hasSubStep" dense>
          <v-col style="margin-right: 30px; text-align: right; margin-top: 15px;">
            <span
              class="mgn-Rm remaining-step-warning-text"
              v-if="canAddSubStep(item) && isNotReadOnly"
            >
              {{
                getStepAdditionValidationText(
                  maxNoOfSubStepsInStep - ((item.children || []).length + 1),
                  'Sub Step'
                )
              }}
            </span>
            <v-btn
              title="Add sub step"
              :class="!canAddSubStep(item) || !isNotReadOnly ? 'disable-btn-click-event' : ''"
              @click="callAddNewSubStepHandler(stepIndex)"
              >Add Sub Step</v-btn
            >
          </v-col>
        </v-row>
        <div v-if="supportAssets">
          <v-row dense>
            <v-col>
              <div class="step-assets-component">
                <v-row
                  v-if="
                    item.homework &&
                    item.homework.question &&
                    (item.homework.question.assetId !== '' ||
                      item.homework.question_answer.assetId !== '')
                  "
                >
                  <v-col cols="6" class="pad-Rm">
                    <FileInput
                      :image_url="isAssetUploadRequired ? item.homework.question.assetId : ''"
                      :image_url_brief="item.homework.question.brief.assetId"
                      :previewUrl="
                        isAssetUploadRequired
                          ? signedUrls[stepIndex].homework.question.assetSignedUrl
                          : ''
                      "
                      :previewUrlBrief="
                        signedUrls[stepIndex].homework.question.brief.assetSignedUrl
                      "
                      :canShowDiffIcon="canShowDiffIcon"
                      :isDiffChanged="isStepHomeworkDiffChanged(stepIndex, 'question')"
                      :diffIdentifier="`${stepAssetIdentifier}[${stepIndex}].homework.question.${imageKey}`"
                      label="Select Asset"
                      :loadingStatus="
                        uploadingStatus[assetTypeMapping['question']] ==
                        FILE_UPLOADING_STATUS.IN_PROGRESS
                      "
                      :fileChangeHandler="
                        (file) =>
                          fileChangeHandler(
                            file,
                            stepIndex,
                            'question',
                            `${stepAssetIdentifier}[${stepIndex}].homework.question.${imageKey}`
                          )
                      "
                      :showImageBrief="showImageBrief"
                      :isAssetUploadRequired="isAssetUploadRequired"
                      :fileAcceptType="getAcceptableFileType(isAssetUploadRequired)"
                      :isNotReadOnly="isNotReadOnly"
                      :fileValidators="fileValidators"
                      :canFinalizeAsset="canFinalizeAsset"
                      :canAppproveAsset="canAppproveAsset"
                      :isAssetApproved="item.homework.question.assetApproved"
                      :isRequired="true"
                      @addBriefToAsset="
                        (previewUrl, briefUrl, isAssetApproved) =>
                          addBriefToAsset(
                            previewUrl,
                            briefUrl,
                            'question',
                            isAssetApproved,
                            stepIndex
                          )
                      "
                    />
                    <RichTextEditor
                      v-if="showImageBrief"
                      v-model="item.homework.question.brief.text"
                      :canShowDiffIcon="canShowDiffIcon"
                      :isDiffChanged="false"
                      :fieldIdentifier="`${stepAssetIdentifier}[${stepIndex}].homework.question.brief`"
                      :placeholder="'Question Asset Brief'"
                      class="text-field-text"
                      style="margin: 25px 0px 0px 0px;"
                      :showValidationError="true"
                      :counter="3000"
                      :readonly="!isNotReadOnly"
                      :includeOnlyToolBarActions="BASIC_TOOLS"
                    />
                  </v-col>

                  <v-col v-if="item.homework.question_answer" cols="6" class="pad-Rm">
                    <FileInput
                      :image_url="
                        isAssetUploadRequired ? item.homework.question_answer.assetId : ''
                      "
                      :image_url_brief="item.homework.question_answer.brief.assetId"
                      :previewUrl="
                        isAssetUploadRequired
                          ? signedUrls[stepIndex].homework.question_answer.assetSignedUrl
                          : ''
                      "
                      :previewUrlBrief="
                        signedUrls[stepIndex].homework.question_answer.brief.assetSignedUrl
                      "
                      :canShowDiffIcon="canShowDiffIcon"
                      :isDiffChanged="isStepHomeworkDiffChanged(stepIndex, 'question_answer')"
                      :diffIdentifier="`${stepAssetIdentifier}[${stepIndex}].homework.question_answer.${imageKey}`"
                      label="Select Asset"
                      :loadingStatus="
                        uploadingStatus[assetTypeMapping['question_answer']] ==
                        FILE_UPLOADING_STATUS.IN_PROGRESS
                      "
                      :fileChangeHandler="
                        (file) =>
                          fileChangeHandler(
                            file,
                            stepIndex,
                            'question_answer',
                            `${stepAssetIdentifier}[${stepIndex}].homework.question_answer.${imageKey}`
                          )
                      "
                      :showImageBrief="showImageBrief"
                      :isAssetUploadRequired="isAssetUploadRequired"
                      :fileAcceptType="getAcceptableFileType(isAssetUploadRequired)"
                      :isNotReadOnly="isNotReadOnly"
                      :fileValidators="fileValidators"
                      :canFinalizeAsset="canFinalizeAsset"
                      :canAppproveAsset="canAppproveAsset"
                      :isAssetApproved="item.homework.question_answer.assetApproved"
                      :isRequired="false"
                      @addBriefToAsset="
                        (previewUrl, briefUrl, isAssetApproved) =>
                          addBriefToAsset(
                            previewUrl,
                            briefUrl,
                            'question_answer',
                            isAssetApproved,
                            stepIndex
                          )
                      "
                    />
                    <RichTextEditor
                      v-if="showImageBrief"
                      v-model="item.homework.question_answer.brief.text"
                      :canShowDiffIcon="canShowDiffIcon"
                      :isDiffChanged="false"
                      :fieldIdentifier="`${stepAssetIdentifier}[${stepIndex}].homework.question_answer.brief`"
                      :placeholder="'Question Answer Asset Brief'"
                      class="text-field-text"
                      style="margin: 25px 0px 0px 0px;"
                      :showValidationError="false"
                      :counter="3000"
                      :readonly="!isNotReadOnly"
                      :includeOnlyToolBarActions="BASIC_TOOLS"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="5"
                    v-for="(assetHash, _index) in item.assets || []"
                    :v-bind="_index"
                    :key="_index"
                    style="margin-right: 30px;"
                  >
                    <v-row>
                      <v-col :cols="11">
                        <FileInput
                          :image_url="isAssetUploadRequired ? assetHash.assetId : ''"
                          :image_url_brief="assetHash.brief.assetId"
                          :previewUrl="getPreviewUrl(stepIndex, _index).assetSignedUrl"
                          :previewUrlBrief="getPreviewUrl(stepIndex, _index).assetSignedBriefUrl"
                          :label="AppTexts.SELECT_STEP_ASSETS"
                          :fileAcceptType="getFileAcceptType(assetHash.type, isAssetUploadRequired)"
                          :loadingStatus="
                            getPreviewUrl(stepIndex, _index).loadingStatus ==
                            FILE_UPLOADING_STATUS.IN_PROGRESS
                          "
                          :isAssetUploadRequired="isAssetUploadRequired"
                          :isRequired="true"
                          :fileChangeHandler="
                            (file) =>
                              onAssetsChange(
                                file,
                                stepIndex,
                                _index,
                                isAssetUploadRequired ? assetHash.assetId : assetHash.brief.assetId
                              )
                          "
                          :canShowDiffIcon="canShowDiffIcon"
                          :isDiffChanged="isAssetImageChanged(stepIndex, _index)"
                          :diffIdentifier="getAssetImageDiffIdentifierPrefix(stepIndex, _index)"
                          :isNotReadOnly="isNotReadOnly"
                          :fileValidators="fileValidators"
                          :isAssetApproved="assetHash.assetApproved"
                          :canFinalizeAsset="canFinalizeAsset"
                          :canAppproveAsset="canAppproveAsset"
                          @addBriefToAsset="
                            (previewUrl, briefUrl, approveAsset) =>
                              copyAssetFromBrief(
                                previewUrl,
                                briefUrl,
                                stepIndex,
                                _index,
                                approveAsset
                              )
                          "
                        />
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          icon
                          @click="
                            deleteAssest(
                              stepIndex,
                              _index,
                              isAssetUploadRequired ? assetHash.assetId : assetHash.briefAssetId
                            )
                          "
                          title="Delete Assest"
                          v-if="isNotReadOnly"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="showImageBrief">
                      <v-col cols="11">
                        <RichTextEditor
                          :canShowDiffIcon="canShowDiffIcon"
                          :isDiffChanged="isAssetBriefChanged(stepIndex, _index)"
                          :fieldIdentifier="getAssetBriefDiffIdentifier(stepIndex, _index)"
                          :key="assetHash.brief_url + '_brief'"
                          :placeholder="AppTexts.REFERENCE_IMAGE_BRIEF"
                          v-model="assetHash.brief.text"
                          :showValidationError="true"
                          :counter="3000"
                          :readonly="!isNotReadOnly"
                          :includeOnlyToolBarActions="BASIC_TOOLS"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3" class="step-add-assets-button">
                    <v-btn
                      class="xs-accent-button"
                      :disabled="!canAddAsset(stepIndex)"
                      :class="
                        (item.assets && item.assets[0] && item.assets[0].type == 'video') ||
                        !isNotReadOnly
                          ? 'disable-btn-click-event'
                          : ''
                      "
                      @click="onClickAddNewAssest(stepIndex)"
                      dense
                    >
                      <img class="mgn-Rs" width="21" height="19" src="@/assets/imagecolor.svg" />
                      {{ AppTexts.ADD_ASSET }}
                    </v-btn>
                    <br />
                    <span
                      class="remaining-step-warning-text"
                      v-if="!isNaN(maxNoOfAssetsPerStep) && canAddAsset(stepIndex)"
                    >
                      {{
                        getStepAdditionValidationText(
                          maxNoOfAssetsPerStep - items[stepIndex].assets.length,
                          'Asset'
                        )
                      }}
                    </span>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="supportAudioWord">
          <Keywords
            class="mgn-Bm"
            :items="item.audio_words || []"
            :addKeyword="() => addAudioWord(stepIndex)"
            :deleteKeyword="(index) => removeAudioWord(stepIndex, index)"
            :placeholder="AppTexts.KEYWORD"
            :descriptionPlaceholder="AppTexts.KEYWORD_DESCRIPTION"
            :canShowDiffIcon="canShowDiffIcon"
            :keywordsDiff="
              (instructionsDiff[stepIndex] && instructionsDiff[stepIndex].audio_words) || []
            "
            :isNotReadOnly="isNotReadOnly"
            :maxNameLength="maxAudioWordNameLength"
            :maxDescriptionLength="maxAudioWordDescriptionLength"
            :maxKeywordLength="maxAudioWordLength"
            :buttonText="AppTexts.ADD_AUDIO_WORD"
            :diffIdentifierPrefix="audioWordsDiffIdentifierPrefix(stepIndex)"
          />
        </div>
      </div>
      <v-row dense>
        <div
          v-if="canShowInstructionBreakCheckbox && stepIndex + 1 < items.length"
          class="slide-break-wrapper"
        >
          <div
            @click="
              isNotReadOnly &&
                updateInstructionBreakValueOfStep({
                  index: stepIndex,
                  instructionBreak: !item.instructionBreak,
                  section: stepAssetIdentifier,
                })
            "
          >
            <v-switch
              class="justify-center slide-break-switch"
              :key="stepIndex"
              :input-value="item.instructionBreak"
              background-color="#F5F5F5"
              hide-details
              label="Slide break"
              :disabled="!isNotReadOnly"
            ></v-switch>
          </div>
        </div>
        <div
          v-if="
            canShowInstructionTeacherTip &&
            (stepIndex + 1 < items.length || canShowLastInstructionTeacherTip)
          "
          class="slide-break-wrapper instruction-teacher-tip"
        >
          <div @click="isNotReadOnly && toggleTeacherTipField(stepIndex)">
            <v-switch
              class="justify-center slide-break-switch"
              :key="stepIndex"
              style="width: 400px;"
              background-color="#F5F5F5"
              hide-details
              :input-value="isInstructionTeacherTipPresent(item)"
              label="Enable Teacher Tip"
              :disabled="!isNotReadOnly"
            ></v-switch>
          </div>
        </div>
      </v-row>
      <div
        class="pad-Ts mgn-Bs"
        v-if="
          canShowInstructionTeacherTip &&
          (stepIndex + 1 < items.length || canShowLastInstructionTeacherTip) &&
          isInstructionTeacherTipPresent(item)
        "
      >
        <XSDisplayCard :compact="true" :title="AppTexts.TEACHER_TIP">
          <RichTextEditor
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="true"
            :fieldIdentifier="instructionTeacherTipFieldIdentifier(stepIndex)"
            :hintText="AppTexts.TEACHER_TIP_DESCRIPTION"
            v-model="item.teacher_tip"
            :showValidationError="true"
            :counter="stepTeacherTipMaxLength"
            :readonly="!isNotReadOnly"
            :includeOnlyToolBarActions="BASIC_TOOLS"
            class="mgn-Bm"
          />
        </XSDisplayCard>
      </div>
      <v-divider style="margin: 20px; 0px" v-if="showDividerBetweenSteps && items.length > 1" />
      <div class="mgn-Ts mgn-Bs" v-if="canShowAddNewStepButton">
        <v-btn
          class="xs-accent-button"
          @click="callAddNewStepHandler(stepIndex + 1)"
          dense
          :class="!canAddStep() || !isNotReadOnly ? 'disable-btn-click-event' : ''"
          >{{ AppTexts.ADD_STEP }}</v-btn
        >
        <span class="mgn-Lm remaining-step-warning-text" v-if="canAddStep()">
          {{
            getStepAdditionValidationText(
              Math.min(
                maxNoOfSteps - items.length,
                maxSumOfStepsAndSubSteps - sumOfStepsAndSubSteps()
              ),
              'Step'
            )
          }}
        </span>
      </div>
    </div>
    <div class="mgn-Ts mgn-Bs" v-if="canShowAddNewStepButton && items.length == 0">
      <v-btn
        class="xs-accent-button"
        @click="callAddNewStepHandler(items.length + 1)"
        dense
        :class="!canAddStep() ? 'disable-btn-click-event' : ''"
        >{{ AppTexts.ADD_STEP }}</v-btn
      >
      <span class="mgn-Lm remaining-step-warning-text" v-if="canAddStep()">
        {{
          getStepAdditionValidationText(
            Math.min(
              maxNoOfSteps - items.length,
              maxSumOfStepsAndSubSteps - sumOfStepsAndSubSteps()
            ),
            'Step'
          )
        }}
      </span>
    </div>
  </v-form>
</template>
<script>
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import { getFileNameFromUrl } from '@/functions/global';
import { mapActions } from 'vuex';
import FileInput from '@/components/inputs/FileInput';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import { getStepAdditionValidationText } from '@/utils';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import Keywords from '@/components/inputs/Keywords.vue';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import { getAcceptableFileType } from '@/utils';
import { get } from 'lodash';

export default {
  name: 'Step',
  components: {
    FileInput,
    RichTextEditor,
    XSDisplayCard,
    Keywords,
  },
  data() {
    return {
      refreshSubStep: 0,
      AppTexts,
      Errors,
      FILE_UPLOADING_STATUS,
      BASIC_TOOLS,
      stepRevision: 0,
    };
  },
  computed: {
    imageKey() {
      return this.isAssetUploadRequired ? 'assetId' : 'brief.assetId';
    },
  },
  props: {
    maxAudioWordNameLength: {
      type: Number,
      default: undefined,
    },
    maxAudioWordDescriptionLength: {
      type: Number,
      default: undefined,
    },
    maxAudioWordLength: {
      type: Number,
      default: 5,
    },
    instructionsDiff: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    canShowStepNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    canShowStepBullet: {
      type: Boolean,
      required: false,
      default: false,
    },
    canShowInstructionBreakCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: false,
      default: () => {},
    },
    canShowAddNewStepButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    addNewStepHandler: {
      type: Function,
      required: false,
    },
    hasSubStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    addNewSubStepHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
    allowDeleteStep: {
      type: Boolean,
      required: true,
    },
    deleteStepHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
    allowDeleteSubStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteSubStepHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    defaultNoOfSteps: {
      type: Number,
      required: false,
      default: 1,
    },
    maxNoOfSteps: {
      type: Number,
      required: false,
      default: 10,
    },
    maxNoOfAssetsPerStep: {
      type: Number,
      required: false,
      default: undefined,
    },
    maxNoOfSubStepsInStep: {
      type: Number,
      required: false,
      default: 10,
    },
    maxSumOfStepsAndSubSteps: {
      type: Number,
      required: false,
      default: 100,
    },
    showImageBrief: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    supportAssets: {
      type: Boolean,
      required: false,
      default: false,
    },
    getPreviewUrl: {
      type: Function,
      required: false,
      default: () => {
        return null;
      },
    },
    showDividerBetweenSteps: {
      type: Boolean,
      required: false,
      default: false,
    },
    stepAssetIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    canShowDiffIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isStepDescriptionChanged: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
    isSubStepDescriptionChanged: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
    stepDiffIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    subStepDiffIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    assetBriefDiffIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    instructionTeacherTipDiffIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    canShowInstructionTeacherTip: {
      type: Boolean,
      required: false,
      default: false,
    },
    canShowLastInstructionTeacherTip: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleTeacherTipFieldHandler: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
    isAssetBriefChanged: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
    assetImageDiffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    isAssetImageChanged: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canFinalizeAsset: {
      type: Boolean,
      required: false,
      default: false,
    },
    canAppproveAsset: { type: Boolean, required: false, default: false },
    stepDescriptionMaxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    subStepDescriptionMaxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    stepTeacherTipMaxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    supportAudioWord: {
      type: Boolean,
      default: false,
    },
    diffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    signedUrls: {
      type: Array,
      required: true,
    },
    uploadingStatus: {
      type: Object,
      required: true,
    },
    assetTypeMapping: {
      type: Object,
      required: true,
    },
    isStepHomeworkDiffChanged: {
      type: Function,
      required: false,
    },
    subPath: {
      type: String,
      required: false,
      default: '',
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.items == undefined) {
          this.items = [];
          console.log('val', val);
        }

        if (this.item && this.items.length < this.defaultNoOfSteps) {
          let noOfMissingSteps = this.defaultNoOfSteps - this.items.length;

          for (let i = 0; i < noOfMissingSteps; i++) {
            if (this.addNewStepHandler) {
              this.addNewStepHandler({ index: null });
            }
          }
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'importStepAssetAction',
      'addNewAssest',
      'deleteStepAssetAction',
      'removeAssest',
      'copyStepAssetAction',
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'copySingleAssetAction',
      'updateInstructionBreakValueOfStep',
    ]),
    getStepAdditionValidationText,
    getFileNameFromUrl,
    getAcceptableFileType,
    addAudioWord(stepIndex) {
      this.$emit('addAudioWord', { stepIndex: stepIndex });
    },
    removeAudioWord(stepIndex, index) {
      this.$emit('removeAudioWord', { stepIndex: stepIndex, index: index });
    },
    canAddStep() {
      return (
        this.items.length < this.maxNoOfSteps &&
        this.sumOfStepsAndSubSteps() < this.maxSumOfStepsAndSubSteps
      );
    },
    canAddAsset(stepIndex) {
      if (isNaN(this.maxNoOfAssetsPerStep)) return true;

      try {
        return this.items[stepIndex].assets.length < this.maxNoOfAssetsPerStep;
      } catch (er) {
        return false;
      }
    },
    canAddSubStep(item) {
      return (
        (item.children || []).length + 1 < this.maxNoOfSubStepsInStep &&
        this.sumOfStepsAndSubSteps() < this.maxSumOfStepsAndSubSteps
      );
    },
    getImageBrief(assets, index) {
      return assets && assets.length > index && assets[index] ? assets[index].brief : '';
    },
    getFileAcceptType(assetHashType, isAssetUploadRequired) {
      if (assetHashType) {
        if (['image', 'video'].includes(assetHashType)) {
          return assetHashType + '/*';
        } else if (assetHashType == 'pdf') {
          return '.pdf';
        }
      }

      if (isAssetUploadRequired) {
        return 'image/*,video/*,.pdf';
      } else {
        return 'image/*,video/*,.docx,.pdf,.doc';
      }
    },
    callAddNewStepHandler(newStepIndex) {
      if (!this.canShowAddNewStepButton) {
        return true;
      }
      if (this.items.length < this.maxNoOfSteps) {
        if (this.sumOfStepsAndSubSteps() < this.maxSumOfStepsAndSubSteps) {
          if (this.addNewStepHandler) {
            this.addNewStepHandler({ index: newStepIndex, path: this.subPath });
            this.stepRevision += 1;
          }
        } else {
          this.$notify({
            title: 'Error',
            text: `Sum of all sub-steps across steps can only be ${this.maxSumOfStepsAndSubSteps}`,
            type: 'error',
          });
        }
      } else {
        this.$notify({
          title: 'Error',
          text: `Number of steps can only be ${this.maxNoOfSteps}`,
          type: 'error',
        });
      }
    },
    callAddNewSubStepHandler(stepIndex) {
      if (!this.hasSubStep) {
        return true;
      }

      // Step also considered as one SubStep. So adding 1.
      let existingSubStepsCount = (this.items[stepIndex].children || []).length + 1;

      if (existingSubStepsCount < this.maxNoOfSubStepsInStep) {
        if (this.sumOfStepsAndSubSteps() < this.maxSumOfStepsAndSubSteps) {
          if (this.addNewSubStepHandler) {
            if (this.subPath) {
              this.addNewSubStepHandler({ stepIndex, path: this.subPath });
            } else {
              this.addNewSubStepHandler(stepIndex);
            }
          }
        } else {
          this.$notify({
            title: 'Error',
            text: `Sum of all sub-steps across steps can only be ${this.maxSumOfStepsAndSubSteps}`,
            type: 'error',
          });
        }
      } else {
        this.$notify({
          title: 'Error',
          text: `Number of sub-steps in step can only be ${this.maxNoOfSubStepsInStep}`,
          type: 'error',
        });
      }
    },
    callDeleteStepHandler(stepIndex) {
      if (this.allowDeleteStep && this.deleteStepHandler) {
        if (this.subPath) {
          this.deleteStepHandler({ index: stepIndex, path: this.subPath });
        } else {
          this.deleteStepHandler(stepIndex);
        }
        this.stepRevision += 1;
      }
    },
    callDeleteSubStepHandler(args) {
      if (this.allowDeleteSubStep && this.deleteSubStepHandler) {
        if (this.subPath) {
          this.deleteSubStepHandler({
            stepIndex: args.stepIndex,
            subStepIndex: args.subStepIndex,
            path: this.subPath,
          });
        } else {
          this.deleteSubStepHandler(args);
        }
        this.refreshSubStep += 1;
      }
    },
    sumOfStepsAndSubSteps() {
      if (this.items.length) {
        let sumOfSubStepsAlone = this.items
          .map((currentItem) => {
            return currentItem.children ? currentItem.children.length : 0;
          })
          .reduce((accumulator, currentValue) => accumulator + currentValue);

        return this.items.length + sumOfSubStepsAlone;
      } else {
        return 0;
      }
    },
    onAssetsChange(file, stepIndex, assetIndex, assetId) {
      if (file) {
        this.importStepAssetAction({
          file,
          stepIndex,
          assetIndex,
          section: this.stepAssetIdentifier,
        });
      } else {
        this.deleteStepAssetAction({
          assetId,
          stepIndex,
          assetIndex,
          identifier: this.getAssetImageDiffIdentifierPrefix(stepIndex, assetIndex),
          section: this.stepAssetIdentifier,
          removeHashAlso: false,
        });
      }
    },
    fileChangeHandler(file, stepIndex, entity, fieldIdentifier) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: this.assetTypeMapping[entity],
          index: stepIndex,
        });
      } else {
        this.deleteSingleAssetAction({
          assetId: get(this.items[stepIndex].homework[entity], this.imageKey),
          assetType: this.assetTypeMapping[entity],
          fieldIdentifier,
          index: stepIndex,
        });
      }
    },
    addBriefToAsset(previewUrl, briefUrl, entity, isAssetApproved, stepIndex) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: this.assetTypeMapping[entity],
        isAssetApproved,
        index: stepIndex,
      });
    },
    copyAssetFromBrief(signedUrl, assetId, stepIndex, assetIndex, approveAsset) {
      this.copyStepAssetAction({
        signedUrl,
        assetId,
        stepIndex,
        assetIndex,
        section: this.stepAssetIdentifier,
        approveAsset,
      });
    },
    onClickAddNewAssest(stepIndex) {
      if (this.canAddAsset(stepIndex)) {
        this.addNewAssest({ stepIndex, section: this.stepAssetIdentifier });
      } else {
        this.$notify({
          title: 'Error',
          text: `Number of assets in step can only be ${this.maxNoOfAssetsPerStep}`,
          type: 'error',
        });
      }
    },
    deleteAssest(stepIndex, assetIndex, url) {
      this.removeAssest({
        stepIndex,
        assetIndex,
        url,
        section: this.stepAssetIdentifier,
      });
    },
    getStepDiffIdentifier(stepIndex) {
      try {
        return this.stepDiffIdentifier.replace('<stepIndex>', stepIndex);
      } catch (error) {
        console.log(error);
      }

      return '';
    },
    getSubStepDiffIdentifier(stepIndex, subStepIndex) {
      try {
        return this.subStepDiffIdentifier
          .replace('<stepIndex>', stepIndex)
          .replace('<subStepIndex>', subStepIndex);
      } catch (error) {
        console.log(error);
      }

      return '';
    },
    instructionTeacherTipFieldIdentifier(stepIndex) {
      try {
        return this.instructionTeacherTipDiffIdentifier.replace('<stepIndex>', stepIndex);
      } catch (error) {
        console.log(error);
      }

      return '';
    },
    getAssetBriefDiffIdentifier(stepIndex, assetIndex) {
      try {
        return this.assetBriefDiffIdentifier
          .replace('<stepIndex>', stepIndex)
          .replace('<assetIndex>', assetIndex);
      } catch (error) {
        console.log(error);
      }

      return '';
    },
    getAssetImageDiffIdentifierPrefix(stepIndex, assetIndex) {
      let str = this.assetImageDiffIdentifierPrefix
        .replace('<stepIndex>', stepIndex)
        .replace('<assetIndex>', assetIndex);

      if (this.isAssetUploadRequired) {
        return `${str}.assetId`;
      } else {
        return `${str}.brief.assetId`;
      }
    },
    toggleTeacherTipField(stepIndex) {
      if (this.subPath) {
        this.toggleTeacherTipFieldHandler({ stepIndex, path: this.subPath });
      } else {
        this.toggleTeacherTipFieldHandler({ stepIndex });
      }
    },
    isInstructionTeacherTipPresent(item) {
      return Object.keys(item).includes('teacher_tip');
    },
    audioWordsDiffIdentifierPrefix(stepIndex) {
      let stepPrefix = this.diffIdentifierPrefix.replace('<stepIndex>', stepIndex);
      return stepPrefix + '.audio_words';
    },
  },
};
</script>

<style scoped>
.step-number {
  height: 30px;
  width: 30px;
  padding: 4px;
  margin: 10px;
  border-radius: 50%;
  background-color: #b5e9e4;
  text-align: center;
}
.step-bullet {
  height: 15px;
  width: 15px;
  margin: auto 10px;
  border-radius: 50%;
  background-color: #00b09f;
}
.step-number-placeholder {
  height: 40px;
  width: 40px;
  padding: 10px;
  margin: 10px;
}
.step-assets-component {
  align-content: space-around;
}
.step-add-assets-button {
  margin: 5px 20px 5px 0px;
}
.slide-break-wrapper {
  width: 180px;
  cursor: pointer;
}
</style>
