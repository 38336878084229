import { baseMixin } from './base';
import { validateAssetAuthorized } from '@/utils';
import { DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION } from '@/constants/LessonPlan';

export const foundation5aLessonMixin = {
  mixins: [baseMixin],
  data() {
    return {
      Schema: {
        coverImageAllowedTimes: ['35 mins', '45 min'],
        isWorksheetEnabled: true,
        aim: {
          maxKeywordsLength: 15,
          maxResourcesLength: 15,
          keyWordDefinitionOptional: true,
          isSummaryOptional: true,
          resourceMaxLength: 15,
          summaryMaxLength: undefined,
          resourceNameMaxLength: undefined,
          keywordTextMaxLength: 40,
        },
        action: {
          isSummaryOptional: true,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
          summaryMaxLength: undefined,
          stepTeacherTipMaxLength: undefined,
          teacherTipMaxLength: undefined,
          maxNoOfSteps: 100,
          maxNoOfSubStepsInStep: 100,
          maxSumOfStepsAndSubSteps: 500,
        },
        analysis: {
          isSummaryOptional: true,
          allowedTimes: ['5 mins', '10 mins', '15 mins', '20 mins'],
          summaryMaxLength: undefined,
          discussionKeyQuestionMaxLength: undefined,
          discussionKeyQuestionGuideMaxLength: undefined,
          discussionStepTeacherTipMaxLength: undefined,
          discussionTeacherTipMaxLength: undefined,
          showReadXSEEDContentBookCheckbox: true,
        },
        application: {
          isHomeworkOptional: true,
          isSummaryOptional: true,
          showDifferentiationTips: true,
          allowedTimes: ['5 mins', '10 mins', '15 mins', '20 mins', '25 mins'],
          classworkSummaryMaxLength: undefined,
          isSkipQuestionAsset: true,
          homeworkSummaryMaxLength: undefined,
          neeMoreHelpDescriptionMaxLength: undefined,
          needExtraChallengeDescriptionMaxLength: undefined,
        },
        assessment: {
          isSummaryOptional: true,
          approachingSummaryMaxLength: undefined,
          meetingSummaryMaxLength: undefined,
          exceedingSummaryMaxLength: undefined,
          overallSummaryMaxLength: undefined,
          approachingDescriptionMaxLength: undefined,
          meetingDescriptionMaxLength: undefined,
          exceedingDescriptionMaxLength: undefined,
          overallDescriptionMaxLength: undefined,
          forceShowAMEFields: true,
        },
      },
    };
  },
  computed: {
    canShowEvaluateAndGrade() {
      return false;
    },
  },
  methods: {
    worksheetEnabled() {
      return false;
    },
    validateLPForm() {
      this.reassignFormInputs(this.$refs.form);

      if (
        this.$refs.form.validate() &&
        this.customValidations() &&
        this.assetAutorizingStageValidations() &&
        this.validateRTEText()
      ) {
        return true;
      } else {
        return false;
      }
    },
    assetAutorizingStageValidations() {
      try {
        if (this.currentStage == 'assetAuthorizingStage') {
          let unApprovedAssetCount = 0;
          unApprovedAssetCount = validateAssetAuthorized(
            this.lessonPlan.data,
            ['assetId'],
            {
              assetId: 'assetApproved',
            },
            ['brief', 'placeholder'],
            0
          );
          if (unApprovedAssetCount > 0) {
            this.$notify({
              title: 'Failed',
              text: `There are ${unApprovedAssetCount} unapproved assets`,
              type: 'error',
              duration: -1,
            });

            return false;
          }

          return true;
        } else {
          return true;
        }
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },

    customValidations() {
      try {
        let lp = this.lessonPlan;
        if (!lp) throw Error('Invalid LessonPlan');
        if (!lp.data) throw Error('Invalid LessonPlan Data');

        if (!lp.data.lp_title || !lp.data.lp_description)
          throw Error('Title or Description cannot be empty');

        // time validation - start
        const getMinutesFromTimeString = (timeString) => {
          try {
            return +timeString.split(' ')[0];
          } catch (error) {
            return 0;
          }
        };

        let overallTime = lp.data ? getMinutesFromTimeString(lp.data.time) : 0;
        let actionTime =
          lp.data && lp.data.action ? getMinutesFromTimeString(lp.data.action.time) : 0;
        let analysisTime =
          lp.data && lp.data.analysis ? getMinutesFromTimeString(lp.data.analysis.time) : 0;
        let applicationTime =
          lp.data && lp.data.application ? getMinutesFromTimeString(lp.data.application.time) : 0;

        if (overallTime !== actionTime + analysisTime + applicationTime) {
          let errorMessage = 'Overall time of the LP is not equal to sum of individual step time';
          throw Error(errorMessage);
        }
        // time validation - start

        // Analysis validation

        if (!lp.data.analysis) throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS');
        if (!lp.data.analysis.activity_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD');
        if (!lp.data.analysis.activity_discussion.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD_EXPLANATION');
        if (!lp.data.analysis.generalized_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD');
        if (!lp.data.analysis.generalized_discussion.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD_EXPLANATION');
        if (!lp.data.analysis.higher_order_thinking)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS');
        if (!lp.data.analysis.higher_order_thinking.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS_EXPLANATION');

        // Atleast one of the AD, GD, HOTS should have the explanations image and content.
        let isADHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.activity_discussion.explanations
        );
        let isGDHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.generalized_discussion.explanations
        );
        let isHOTSHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.higher_order_thinking.explanations
        );
        if (!(isADHasExplanationData || isGDHasExplanationData || isHOTSHasExplanationData))
          throw Error(
            'Invalid LessonPlan Data. ERROR: Atleast one of Activity Discussion or Generalized Discussion or Higher Order Thinking should have valid explanations data.'
          );

        return true;
      } catch (exception) {
        console.log(exception);
        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });
        return false;
      }
    },
    prepareLessonPlanDataToSave() {
      // remove loadingStatus from resource object..
      let resources = [];
      this.lessonPlan.data.aim.resources.forEach((resource) => {
        delete resource.loadingStatus;
        resources.push(resource);
      });
      this.lessonPlan.data.aim.resources = resources;

      // set placeholder image for action instructions videos
      for (let ins of this.lessonPlan.data.action.instructions) {
        if (ins.assets && (ins.assets[0] || {}).type == 'video') {
          if (!ins.assets[0].placeholder) {
            ins.assets[0].placeholder = { assetId: '' };
          }
          ins.assets[0].placeholder.assetId = this.lessonPlan.data.assetId;
        }
        let assets = [];

        for (let asset of ins.assets || []) {
          delete asset.loadingStatus;
          assets.push(asset);
        }
        ins.assets = assets;
      }

      for (let _discussionName of [
        'activity_discussion',
        'generalized_discussion',
        'higher_order_thinking',
      ]) {
        let instructionData = [];
        this.lessonPlan.data.analysis[_discussionName].instructions.forEach(function (
          _instruction
        ) {
          delete _instruction.loadingStatus;
          instructionData.push(_instruction);
        });

        this.lessonPlan.data.analysis[_discussionName].instructions = instructionData;
      }

      if (
        this.lessonPlan.data.application.more_practice &&
        this.lessonPlan.data.application.more_practice.length > 0
      ) {
        // set placeholder image for more_practice instructions videos
        for (let ins of this.lessonPlan.data.application.more_practice) {
          if (ins.assets && (ins.assets[0] || {}).type == 'video') {
            if (!ins.assets[0].placeholder) {
              ins.assets[0].placeholder = { assetId: '' };
            }
            ins.assets[0].placeholder.assetId = this.lessonPlan.data.assetId;
          }
          let assets = [];

          for (let asset of ins.assets || []) {
            delete asset.loadingStatus;
            assets.push(asset);
          }
          ins.assets = assets;
        }
      }
    },
    validateLPData() {
      return true;
    },
  },
};
