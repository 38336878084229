import lessonPlan from './lessonPlan/index';
import dailyPlan from './dailyPlan/index';
import contentEntry from './contentEntry/index';
import common from './common';
import user from './user';
import rightSideContent from './rightSideContent';
import questions from './questions';
import training from './training';
import learnometer from './learnometer';
import confidence from './confidence';
import qrManagement from './qrManagement';

export const modules = {
  lessonPlan,
  user,
  dailyPlan,
  contentEntry,
  rightSideContent,
  questions,
  common,
  training,
  learnometer,
  confidence,
  qrManagement,
};
