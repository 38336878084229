import { apolloClient } from '@/apollo';
import * as ContentEntryApiQueries from '@/apiQueries/contentEntry';
import { logError } from '@/utils';
import Vue from 'vue';
import { LP_FORM_SUBMIT_TEXT } from '@/constants/LessonPlan';
import router from '@/router';
import _ from 'lodash';
import axios from 'axios';
import { setFormDataForFileUploadForOProgram } from '@/utils';
import { isRteEmpty, RTEJsonCharCount } from '@xseededucation/rte-helper-functions';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import { PROJECT_TYPE_VIRTUAL_QUESTION, PROJECT_TYPE_QUESTIONS } from '@/constants/common';

const actions = {
  async getContentEntryProjectDetails({ commit, dispatch }, { projectId }) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });
      const response = await apolloClient.query({
        query: ContentEntryApiQueries.getProjectAndContentDetails(projectId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        if (
          response.data.projectLessonPlanCurrentStageData.type == 'information_and_assessment' &&
          response.data.projectLessonPlanCurrentStageData.currentStageData.data
            .informationAndAssessment &&
          !response.data.projectLessonPlanCurrentStageData.currentStageData.data
            .informationAndAssessment.question_answer
        ) {
          const currentStageIAData =
            response.data.projectLessonPlanCurrentStageData.currentStageData.data
              .informationAndAssessment;
          const currentStageIAAssetsSignedUrl =
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl
              .informationAndAssessment;
          const questionAnswerDefaultHash = {
            assetId: '',
            type: '',
            brief: {
              text: '',
              assetId: '',
              type: '',
            },
            placeholder_image: '',
          };
          const questionAnswerDefaultAssetHash = {
            brief: {
              assetSignedUrl: '',
            },
            assetSignedUrl: '',
          };
          if (currentStageIAData.answer) {
            response.data.projectLessonPlanCurrentStageData.currentStageData.data.informationAndAssessment.question_answer =
              currentStageIAData.answer;
          } else {
            response.data.projectLessonPlanCurrentStageData.currentStageData.data.informationAndAssessment.question_answer = questionAnswerDefaultHash;
          }
          if (currentStageIAAssetsSignedUrl.answer) {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer =
              currentStageIAAssetsSignedUrl.answer;
          } else {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer = questionAnswerDefaultAssetHash;
          }
        }

        commit('SET_PROJECT_DETAILS', response.data.project);
        commit('SET_CONTENT_DETAILS', response.data.project.contents[0]);
        commit(
          'SET_PROJECT_CONTENT_CURRENT_STAGE_DATA',
          response.data.projectLessonPlanCurrentStageData
        );

        if (
          [PROJECT_TYPE_VIRTUAL_QUESTION, PROJECT_TYPE_QUESTIONS].includes(
            response.data.project.type
          )
        ) {
          dispatch('fetchQuestionMetaData', {
            currentSubjectId: response.data.project.subject.id,
          });
        }
      }

      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
    }
  },
  async getLearnometerProjectDetails({ commit, dispatch }, { projectId, yearId }) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });
      const response = await apolloClient.query({
        query: ContentEntryApiQueries.getLearnometerDetails(projectId, yearId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        if (
          response.data.projectLearnometerCurrentStageData.type == 'information_and_assessment' &&
          response.data.projectLearnometerCurrentStageData.currentStageData.data
            .informationAndAssessment &&
          !response.data.projectLearnometerCurrentStageData.currentStageData.data
            .informationAndAssessment.question_answer
        ) {
          const currentStageIAData =
            response.data.projectLearnometerCurrentStageData.currentStageData.data
              .informationAndAssessment;
          const currentStageIAAssetsSignedUrl =
            response.data.projectLearnometerCurrentStageData.currentStageAssetsSignedUrl
              .informationAndAssessment;
          const questionAnswerDefaultHash = {
            assetId: '',
            type: '',
            brief: {
              text: '',
              assetId: '',
              type: '',
            },
            placeholder_image: '',
          };
          const questionAnswerDefaultAssetHash = {
            brief: {
              assetSignedUrl: '',
            },
            assetSignedUrl: '',
          };
          if (currentStageIAData.answer) {
            response.data.projectLearnometerCurrentStageData.currentStageData.data.informationAndAssessment.question_answer =
              currentStageIAData.answer;
          } else {
            response.data.projectLearnometerCurrentStageData.currentStageData.data.informationAndAssessment.question_answer = questionAnswerDefaultHash;
          }
          if (currentStageIAAssetsSignedUrl.answer) {
            response.data.projectLearnometerCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer =
              currentStageIAAssetsSignedUrl.answer;
          } else {
            response.data.projectLearnometerCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer = questionAnswerDefaultAssetHash;
          }
        }

        commit('SET_PROJECT_DETAILS', response.data.project);
        commit('SET_CONTENT_DETAILS', response.data.project.contents[0]);
        commit(
          'SET_PROJECT_CONTENT_CURRENT_STAGE_DATA',
          response.data.projectLearnometerCurrentStageData
        );

        if (
          [PROJECT_TYPE_VIRTUAL_QUESTION, PROJECT_TYPE_QUESTIONS].includes(
            response.data.project.type
          )
        ) {
          dispatch('fetchQuestionMetaData', {
            currentSubjectId: response.data.project.subject.id,
          });
        }
      }

      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
    }
  },
  async saveCurrentContentData(
    { commit, state },
    { projectId, data, callbacks, message, loader, banner }
  ) {
    try {
      if (typeof loader == 'undefined' || loader == true)
        commit('SET_LOADING_STATUS', {
          status: 'in_progress',
          message: message ? message : 'Doing Draft Save',
        });
      let response;
      if (state.projectDetails.type == 'learnometerQuestion') {
        response = await apolloClient.mutate({
          mutation: ContentEntryApiQueries.saveLearnometerDataQuery(),
          variables: {
            projectId: projectId,
            data: JSON.parse(
              JSON.stringify(
                _processData(JSON.parse(JSON.stringify(data)), state.projectDetails.type)
              )
            ),
            equationEditorJsonArray: [],
          },
        });
      } else {
        response = await apolloClient.mutate({
          mutation: ContentEntryApiQueries.saveContentDataQuery(),
          variables: {
            lessonPlanLibraryId: parseInt(0),
            projectId: projectId,
            data: JSON.parse(
              JSON.stringify(
                _processData(JSON.parse(JSON.stringify(data)), state.projectDetails.type)
              )
            ),
            equationEditorJsonArray: [],
          },
        });
      }

      if (response && response.data) {
        commit('SET_PROJECT_CONTENT_CURRENT_STAGE_DATA_COPY', data);
        Vue.notify({
          title: 'Success!',
          text: 'Content saved successfully!',
          type: 'success',
        });

        if (callbacks && callbacks.success) callbacks.success();
      } else {
        Vue.notify({
          title: 'Failed',
          text: 'Content save failed',
          type: 'warn',
        });
        if (callbacks && callbacks.failiure) callbacks.failiure();
      }
      if (!callbacks) {
        if (typeof banner == 'undefined' || banner == true)
          commit('SET_LOADING_STATUS', { status: 'completed', message: 'Draft Saved' });
        else commit('SET_LOADING_STATUS', { status: 'completed' });
      }
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
      if (callbacks && callbacks.failiure) callbacks.failiure();
    }
  },
  async validateContentData(
    { state, commit, dispatch },
    { projectId, data, callbacks, loader, banner }
  ) {
    try {
      if (typeof loader == 'undefined' || loader == true)
        commit('SET_LOADING_STATUS', { status: 'in_progress', message: 'Validating Content' });
      // Save content data before validation
      dispatch('saveCurrentContentData', {
        projectId,
        data,
        callbacks: {
          success: async () => {
            // On success save, This callback will be excecuted
            let microTestQuestionsCount = 0;
            let practiceTestQuestionsCount = 0;
            let microTestGroupQuestionsCount = 0;
            let practiceTestGroupQuestionsCount = 0;
            if (state.projectDetails.type == 'questions') {
              microTestQuestionsCount = data.filter(
                (question) =>
                  question.questionPurpose == 'microtest' && question.questionGroupId == null
              ).length;
              practiceTestQuestionsCount = data.filter(
                (question) =>
                  question.questionPurpose == 'practice' && question.questionGroupId == null
              ).length;
              let microTestGroupQuestions = data.filter(
                (question) =>
                  question.questionPurpose == 'microtest' && question.questionGroupId != null
              );
              microTestGroupQuestionsCount = _.uniq(
                microTestGroupQuestions.map((question) => question.questionGroupId)
              ).length;
              let practiceTestGroupQuestions = data.filter(
                (question) =>
                  question.questionPurpose == 'practice' && question.questionGroupId != null
              );
              practiceTestGroupQuestionsCount = _.uniq(
                practiceTestGroupQuestions.map((question) => question.questionGroupId)
              ).length;
              if (
                microTestQuestionsCount + microTestGroupQuestionsCount < 4 ||
                practiceTestQuestionsCount + practiceTestGroupQuestionsCount < 10
              ) {
                Vue.notify({
                  title: 'Failed',
                  text: 'You should add atleast 4 questions for Microtest and 10 for Practice test',
                  type: 'warn',
                });
                commit('SET_LOADING_STATUS', { status: 'completed', message: 'Validation failed' });
                return false;
              }
              let lpStages = Object.keys(LP_FORM_SUBMIT_TEXT);
              if (lpStages.indexOf(state.contentDetails.currentStage) < 7) {
                let briefTexts = [];
                data.forEach((_datum) => {
                  _processQuestionData(_datum, 'parse');
                });
                _extractAllBriefFromJSON(data, 'brief', briefTexts, 0);
                data.forEach((_datum) => {
                  _processQuestionData(_datum, 'stringify');
                });
                let text = [];
                for (let i = 0, j = briefTexts.length; i < j; i++) {
                  let briefCount = RTEJsonCharCount(briefTexts[i].brief, 3000);
                  if (briefCount && briefCount < 0) {
                    let questionPosition = data.findIndex((_ques) => _ques._id == briefTexts[i].id);
                    text.push(
                      `-Brief text of Question ${
                        questionPosition + 1
                      } has exceeded maximum character limit`
                    );
                  }
                }
                if (text.length > 0) {
                  Vue.notify({
                    title: 'Fix the following',
                    text: text.join('<br/>'),
                    type: 'error',
                    duration: -1,
                  });
                  commit('SET_LOADING_STATUS', {
                    status: 'completed',
                    message: 'Validation failed',
                  });
                  return false;
                }
              }
            }
            if (state.contentDetails.currentStage == 'assetUploadStage') {
              let blankAsset = 0;
              switch (state.projectDetails.type) {
                case 'questions':
                  data.forEach((_datum) => {
                    _processQuestionData(_datum, 'parse');
                  });
                  blankAsset = _validateAllAssetsPresent(data, 'assetId', 0);
                  data.forEach((_datum) => {
                    _processQuestionData(_datum, 'stringify');
                  });
                  break;
              }
              if (blankAsset > 0) {
                Vue.notify({
                  title: 'Failed',
                  text: `There are ${blankAsset} empty assets in the page please upload all the assets`,
                  type: 'error',
                  duration: -1,
                });
                commit('SET_LOADING_STATUS', {
                  status: 'completed',
                  message: 'Validation failed',
                });
                return false;
              }
            } else if (state.contentDetails.currentStage == 'assetAuthorizingStage') {
              let unApprovedAssetCount = 0;
              switch (state.projectDetails.type) {
                case 'oLesson':
                  unApprovedAssetCount = _validateAssetAuthorized(
                    data,
                    'imageUrl',
                    'imageUrlApproved',
                    0
                  );
                  break;
                case 'videos':
                  unApprovedAssetCount = _validateAssetAuthorized(data, 'file', 'fileApproved', 0);
                  break;
                case 'microText':
                  if (!data.image_url_approved) {
                    unApprovedAssetCount = 1;
                  }
                  break;
                case 'worksheet':
                  if (!data.fileApproved) {
                    unApprovedAssetCount = 1;
                  }
                  break;
                case 'contentBook':
                  if (!data.fileApproved) {
                    unApprovedAssetCount = 1;
                  }
                  break;
                case 'questions':
                  data.forEach((_datum) => {
                    _processQuestionData(_datum, 'parse');
                  });
                  unApprovedAssetCount = _validateAssetAuthorized(
                    data,
                    'assetId',
                    'assetApproved',
                    0
                  );
                  data.forEach((_datum) => {
                    _processQuestionData(_datum, 'stringify');
                  });
                  break;
              }
              if (unApprovedAssetCount > 0) {
                Vue.notify({
                  title: 'Failed',
                  text: `There are ${unApprovedAssetCount} unapproved assets`,
                  type: 'error',
                  duration: -1,
                });
                commit('SET_LOADING_STATUS', {
                  status: 'completed',
                  message: 'Validation failed',
                });
                return false;
              }
            }
            const response = await apolloClient.mutate({
              mutation: ContentEntryApiQueries.validateContentStageDataQuery(projectId, 0),
            });
            if (
              response &&
              response.data &&
              response.data.validateProjectLessonPlanStageData &&
              response.data.validateProjectLessonPlanStageData.status == 'success'
            ) {
              Vue.notify({
                title: 'Success!',
                text: 'Valid project content!',
                type: 'success',
              });
              if (typeof banner == 'undefined' || banner == true) {
                commit('SET_LOADING_STATUS', {
                  status: 'completed',
                  message: 'Validation Successful',
                });
              }

              if (callbacks && callbacks.success) callbacks.success();
            } else {
              Vue.notify({
                title: 'Failed',
                text: 'Content validation failed in back end',
                type: 'warn',
              });
              if (typeof banner == 'undefined' || banner == true) {
                commit('SET_LOADING_STATUS', {
                  status: 'completed',
                  message: 'Validation failed',
                });
              }

              if (callbacks && callbacks.failiure) callbacks.failiure();
            }
          },
          failiure: () => {
            // On save fail, This callback will be excecuted
            commit('SET_LOADING_STATUS', { status: 'completed', message: 'Validating Failed' });
          },
        },
        loader: false,
        message: 'Validating Content',
      });
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
    }
  },
  async moveFromProofReaderToAssetAuthorizingStage({ commit }, { projectId }) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });
      const response = await apolloClient.mutate({
        mutation: ContentEntryApiQueries.moveFromProofReaderToAssetAuthorizingStage(projectId, 0),
      });
      if (response && response.data && response.data.moveFromProofReaderToAssetAuthorizingStage) {
        router.push({
          name: 'MicroTopicView',
          params: {
            microTopicId: router.history.current.params.microTopicId,
          },
        });
      }
      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
    }
  },
  async moveContentToNextStage(
    { commit, dispatch },
    { projectId, data, projectType = 'microTopic' }
  ) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });

      dispatch('validateContentData', {
        projectId,
        data,
        callbacks: {
          success: async () => {
            // On Success
            const response = await apolloClient.mutate({
              mutation: ContentEntryApiQueries.moveContentToNextStageQuery(projectId, 0),
            });
            if (response && response.data) {
              if (projectType == 'microtopic') {
                router.push({
                  name: 'MicroTopicView',
                  params: {
                    microTopicId: router.history.current.params.microTopicId,
                  },
                });
              } else if (projectType == 'learnometerQuestions') {
                window.location.reload();
              }
            }
            commit('SET_LOADING_STATUS', {
              status: 'completed',
              message: 'Project moved to next stage successfully',
            });
          },
          failiure: () => {
            // On Failiure
            commit('SET_LOADING_STATUS', {
              status: 'completed',
              message: 'Failed moving project to next stage',
            });
          },
        },
        loader: false,
        banner: false,
      });
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
    }
  },
  async completeContentAndPublish(
    { dispatch, commit },
    { projectId, data, projectType = 'microTopic' }
  ) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });

      dispatch('validateContentData', {
        projectId,
        data,
        callbacks: {
          success: async () => {
            // On Success
            const response = await apolloClient.mutate({
              mutation: ContentEntryApiQueries.moveContentToNextStageQuery(projectId, 0),
            });
            if (response && response.data) {
              const publishResponse = await apolloClient.mutate({
                mutation: ContentEntryApiQueries.publishContentQuery(projectId),
              });

              if (publishResponse && publishResponse.data) {
                if (projectType == 'microTopic') {
                  router.push({
                    name: 'MicroTopicView',
                    params: {
                      microTopicId: router.history.current.params.microTopicId,
                    },
                  });
                } else if (projectType == 'learnometerQuestions') {
                  router.push({
                    name: 'LearnometerLanding',
                  });
                }
              }
            }
            commit('SET_LOADING_STATUS', {
              status: 'completed',
              message: 'Project Published successfully',
            });
          },
          failiure: () => {
            // On Failiure
            commit('SET_LOADING_STATUS', {
              status: 'completed',
              message: 'Failed to publish the project',
            });
          },
        },
        loader: false,
        banner: false,
      });
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
    }
  },
  async publishContent({ commit }, { projectId }) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });

      const response = await apolloClient.mutate({
        mutation: ContentEntryApiQueries.publishContentQuery(projectId),
      });

      if (response && response.data) {
        router.push({
          name: 'MicroTopicView',
          params: {
            microTopicId: router.history.current.params.microTopicId,
          },
        });
      }
      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
    }
  },
  contentEntryDeleteKeyword({ commit }, index) {
    commit('contentEntryDeleteKeyword', index);
  },
  contentEntryAddResource({ commit, state }) {
    if (state.currentStageData.aim.resources.length < 3) {
      commit('contentEntryAddResource');
    } else {
      Vue.notify({
        title: 'Failed!',
        text: `Number of items in Resources can only be 3`,
        type: 'error',
      });
    }
  },
  contentEntryDeleteResource({ commit }, index) {
    commit('contentEntryDeleteResource', index);
  },
  contentEntryAddKeyword({ commit, state }) {
    if (state.currentStageData.aim.keywords.length < 10) {
      commit('contentEntryAddKeyword');
    } else {
      Vue.notify({
        title: 'Failed!',
        text: `Number of items in Keywords can only be 10`,
        type: 'error',
      });
    }
  },
  async contentEntryFileUpload({ commit, state, dispatch }, payload) {
    try {
      let { file, type, data, index } = payload;

      commit('SET_FILE_UPLOADING_STATUS', { type, status: 'in_progress' });
      let fileName = _getBriefFileName(state.contentData.currentStage, file);
      let response;
      if (file.size <= 1000000) {
        let formData = setFormDataForFileUploadForOProgram(
          file,
          fileName,
          'assets/micro_topics/' + data.microTopicId + '/' + state.projectDetails.type + '/'
        );

        response = await axios.post(process.env.VUE_APP_FILE_UPLOADER, formData);
      } else {
        response = await apolloClient.mutate({
          mutation: ContentEntryApiQueries.uploadSingleAsset(),
          variables: {
            file: file,
            fileName: fileName,
            libraryId: +data.microTopicId,
            namespace: state.projectDetails.type,
          },
        });
      }
      if (response && response.data) {
        commit('SET_UPLOADED_FILE_PATH', {
          type,
          data: response.data.uploadSingleAsset ? response.data.uploadSingleAsset : response.data,
          index,
        });
        dispatch(
          'saveCurrentContentData',
          Object.assign(data, {}, { loader: false, banner: false })
        );
      }

      commit('SET_FILE_UPLOADING_STATUS', { type, status: 'completed' });
    } catch (er) {
      logError(er);
    }
  },
  async contentEntryAddBriefAssetToAsset({ commit }, payload) {
    let { previewUrl, briefUrl, approveAsset, type, index } = payload;
    commit('SET_UPLOADED_FILE_PATH', {
      type,
      data: { path: briefUrl, signedUrl: previewUrl },
      index,
      forceApproveAsset: approveAsset,
    });
  },
  async contentEntryFileRemove({ commit, state, dispatch }, payload) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress', message: 'Deleting your asset' });
      let { url, type, data, index } = payload;

      const response = await apolloClient.mutate({
        mutation: ContentEntryApiQueries.removeSingleAssetFromProject(),
        variables: {
          url,
          projectId: state.projectDetails.id,
          lessonPlanLibraryId: parseInt(0),
        },
      });

      if (response && response.data) {
        commit('SET_UPLOADED_FILE_PATH', {
          type,
          data: response.data.removeSingleAssetFromProject,
          index,
        });
        dispatch(
          'saveCurrentContentData',
          Object.assign(data, {}, { loader: true, banner: false })
        );
      }
    } catch (er) {
      logError(er);
    }
  },
  async contentEntryGetCurrentStageData({ commit }, { projectId }) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });

      const response = await apolloClient.query({
        query: ContentEntryApiQueries.projectContentEntryCurrentStageData(projectId),
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        if (
          response.data.projectLessonPlanCurrentStageData.type == 'information_and_assessment' &&
          response.data.projectLessonPlanCurrentStageData.currentStageData.data
            .informationAndAssessment &&
          !response.data.projectLessonPlanCurrentStageData.currentStageData.data
            .informationAndAssessment.question_answer
        ) {
          const currentStageIAData =
            response.data.projectLessonPlanCurrentStageData.currentStageData.data
              .informationAndAssessment;
          const currentStageIAAssetsSignedUrl =
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl
              .informationAndAssessment;
          const questionAnswerDefaultHash = {
            assetId: '',
            type: '',
            brief: {
              text: '',
              assetId: '',
              type: '',
            },
            placeholder_image: '',
          };
          const questionAnswerDefaultAssetHash = {
            brief: {
              assetSignedUrl: '',
            },
            assetSignedUrl: '',
          };
          if (currentStageIAData.answer) {
            response.data.projectLessonPlanCurrentStageData.currentStageData.data.informationAndAssessment.question_answer =
              currentStageIAData.answer;
          } else {
            response.data.projectLessonPlanCurrentStageData.currentStageData.data.informationAndAssessment.question_answer = questionAnswerDefaultHash;
          }
          if (currentStageIAAssetsSignedUrl.answer) {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer =
              currentStageIAAssetsSignedUrl.answer;
          } else {
            response.data.projectLessonPlanCurrentStageData.currentStageAssetsSignedUrl.informationAndAssessment.question_answer = questionAnswerDefaultAssetHash;
          }
        }

        commit(
          'SET_PROJECT_CONTENT_CURRENT_STAGE_DATA',
          response.data.projectLessonPlanCurrentStageData
        );
      }
      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (e) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong',
        type: 'error',
      });
    }
  },
  async contentEntryDownloadAssets({ commit }, payload) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });
      let { projectId } = payload;
      const response = await apolloClient.query({
        query: ContentEntryApiQueries.downloadCurrentStageContentAssetsQuery,
        variables: {
          projectId,
        },
        fetchPolicy: 'no-cache',
      });
      commit('SET_LOADING_STATUS', { status: 'completed' });

      if (
        response.data &&
        response.data.downloadCurrentStageLpAssets &&
        response.data.downloadCurrentStageLpAssets.status
      ) {
        Vue.notify({
          title: 'Success!',
          text: 'Micro Topic Assets will be mailed to you shortly!',
          type: 'success',
        });
      } else {
        Vue.notify({
          title: 'Failed',
          text: 'Downloading Lesson Plan assets failed',
          type: 'error',
        });
      }
    } catch (err) {
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong',
        type: 'error',
      });
    }
  },
  async contentEntryAssetZipUpload({ commit }, payload) {
    commit('ASSET_ZIP_UPLOADER_STATUS', { status: 'in_progress' });
    let { assetId, preSignedUrl, projectId, libraryId, assetType } = payload;
    let response = await apolloClient.mutate({
      mutation: ContentEntryApiQueries.uploadAssetZip(),
      variables: {
        assetId: assetId,
        preSignedUrl: preSignedUrl,
        libraryId: +libraryId,
        projectId: projectId,
        assetType: assetType,
      },
    });
    if (response && response.data && response.data.uploadAssetZip) {
      if (response.data.uploadAssetZip[0]) {
        Vue.notify({
          title: 'Error in Uploading Assets',
          text: response.data.uploadAssetZip[0],
          type: 'error',
          duration: -1,
        });
        commit('ASSET_ZIP_UPLOADER_STATUS', { status: 'failed' });
      } else {
        Vue.notify({
          title: 'Uploaded Successfully',
          text: 'Your assets have been uploaded successfully',
          type: 'success',
          duration: -1,
        });
        commit('ASSET_ZIP_UPLOADER_STATUS', { status: 'completed', assetType: assetType });
      }
    }
  },
  async validateUploadAssetPageToken({ commit }, payload) {
    let { projectId, libraryId } = payload;
    let response = await apolloClient.mutate({
      mutation: ContentEntryApiQueries.validateUploadAssetPageToken(),
      variables: {
        projectId: projectId,
        libraryId: parseInt(libraryId),
      },
    });
    if (response && response.data && response.data.validateUploadAssetPageToken) {
      console.log(response.data.validateUploadAssetPageToken.state);
      commit('ASSET_ZIP_UPLOADER_VALIDITY', {
        state: response.data.validateUploadAssetPageToken.state,
        data: response.data.validateUploadAssetPageToken.data,
      });
    } else {
      Vue.notify({
        title: 'Something went wrong',
        text: 'Please try again later',
        type: 'error',
        duration: -1,
      });
    }
  },
  async saveCurrentLearnometerQuestion(
    { commit, state },
    { projectId, data, callbacks, message, loader, banner }
  ) {
    try {
      if (typeof loader == 'undefined' || loader == true)
        commit('SET_LOADING_STATUS', {
          status: 'in_progress',
          message: message ? message : 'Doing Draft Save',
        });
      const response = await apolloClient.mutate({
        mutation: ContentEntryApiQueries.saveLearnometerDataQuery(),
        variables: {
          projectId: projectId,
          data: JSON.parse(
            JSON.stringify(
              _processData(JSON.parse(JSON.stringify(data)), state.projectDetails.type)
            )
          ),
          equationEditorJsonArray: [],
        },
      });

      if (response && response.data) {
        commit('SET_PROJECT_CONTENT_CURRENT_STAGE_DATA_COPY', data);
        Vue.notify({
          title: 'Success!',
          text: 'Content saved successfully!',
          type: 'success',
        });

        if (callbacks && callbacks.success) callbacks.success();
      } else {
        Vue.notify({
          title: 'Failed',
          text: 'Content save failed',
          type: 'warn',
        });
        if (callbacks && callbacks.failiure) callbacks.failiure();
      }
      if (!callbacks) {
        if (typeof banner == 'undefined' || banner == true)
          commit('SET_LOADING_STATUS', { status: 'completed', message: 'Draft Saved' });
        else commit('SET_LOADING_STATUS', { status: 'completed' });
      }
    } catch (er) {
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(er);
      if (callbacks && callbacks.failiure) callbacks.failiure();
    }
  },
};
const _processQuestionData = (data, processType) => {
  if (data.stimulus && data.questionGroupId != null && data.questionSequenceInGroup == 0) {
    data.stimulus =
      processType == 'parse' ? JSON.parse(data.stimulus) : JSON.stringify(data.stimulus);
  }
  let { questionType, questionData } = data;
  questionData.questionText =
    processType == 'parse'
      ? JSON.parse(questionData.questionText)
      : JSON.stringify(questionData.questionText);
  if (
    questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ ||
    questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ ||
    questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF ||
    questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF
  ) {
    if (questionData.options) {
      questionData.options.forEach((_option) => {
        _option.optionText =
          processType == 'parse'
            ? JSON.parse(_option.optionText)
            : JSON.stringify(_option.optionText);
      });
    }
    if (questionData.optionKeys) {
      questionData.optionKeys.forEach((_option) => {
        _option.optionText =
          processType == 'parse'
            ? JSON.parse(_option.optionText)
            : JSON.stringify(_option.optionText);
      });
    }
    if (questionData.optionValues) {
      questionData.optionValues.forEach((_option) => {
        _option.optionText =
          processType == 'parse'
            ? JSON.parse(_option.optionText)
            : JSON.stringify(_option.optionText);
      });
    }
  }
  if (
    questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
    questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA
  ) {
    questionData.answerGuideText =
      processType == 'parse'
        ? JSON.parse(questionData.answerGuideText)
        : JSON.stringify(questionData.answerGuideText);
  }
};

const _isShowImageBriefEnabled = (currentStage) => {
  let currentStageIndex = Object.keys(LP_FORM_SUBMIT_TEXT).indexOf(currentStage);
  currentStageIndex = currentStageIndex == -1 ? 0 : currentStageIndex;

  return currentStageIndex < 7;
};

const _getBriefFileName = (currentStage, file) => {
  let fileName = file.name.split('.');
  let n = fileName.length - 1;
  fileName[n - 1] = _isShowImageBriefEnabled(currentStage) ? fileName[0] + '_brief' : fileName[0];
  return fileName.join('.');
};
const _checkLayout2And3 = (data) => {
  let j = 0;
  for (let i = 0; i < 3; i++) {
    let { briefUrl, imageUrl, brief, imageTitle } = data.layoutData.images[j];
    if ((briefUrl == '' || imageUrl == '') && isRteEmpty(brief, true) && imageTitle == '') {
      Vue.delete(data.layoutData.images, j);
    } else {
      j++;
    }
  }
  return data;
};
const _checkLayout4 = (data) => {
  let j = 0;
  for (let i = 0; i < 2; i++) {
    let { briefUrl, imageUrl, brief, imageTitle, text } = data.layoutData[j];
    if (
      (briefUrl == '' || imageUrl == '') &&
      isRteEmpty(brief, true) &&
      imageTitle == '' &&
      isRteEmpty(text, true)
    ) {
      Vue.delete(data.layoutData, j);
    } else {
      j++;
    }
  }
  return { data: data, temp: j };
};

const _validateAssetAuthorized = (_currentStageData, fieldIdentifier, approvedFlag, returned) => {
  for (let key in _currentStageData) {
    if (typeof _currentStageData[key] == 'object') {
      returned = _validateAssetAuthorized(
        _currentStageData[key],
        fieldIdentifier,
        approvedFlag,
        returned
      );
    } else {
      if (key == fieldIdentifier) {
        if (
          _currentStageData[fieldIdentifier] != '' &&
          (!_currentStageData[approvedFlag] || _currentStageData[approvedFlag] == false)
        ) {
          returned += 1;
        }
      }
    }
  }
  return returned;
};

const _validateAllAssetsPresent = (_currentStageData, fieldIdentifier, returned) => {
  for (let key in _currentStageData) {
    if (typeof _currentStageData[key] == 'object') {
      if (key == fieldIdentifier) {
        if (!_currentStageData[fieldIdentifier]) {
          returned += 1;
        }
      }
      returned = _validateAllAssetsPresent(_currentStageData[key], fieldIdentifier, returned);
    }
  }
  return returned;
};

const _extractAllBriefFromJSON = (_currentStageData, fieldIdentifier, briefArray, id) => {
  for (let key in _currentStageData) {
    if (typeof _currentStageData['_id'] != 'undefined') {
      id = _currentStageData['_id'];
    }
    if (typeof _currentStageData[key] == 'object') {
      _extractAllBriefFromJSON(_currentStageData[key], fieldIdentifier, briefArray, id);
    } else {
      if (key == fieldIdentifier) {
        briefArray.push({ id: id, brief: _currentStageData[fieldIdentifier] });
      }
    }
  }
  return;
};

const _processData = (data, type) => {
  if (type == 'oLesson') {
    //for action:
    let j = 0;
    for (let i = 0; i < 3; i++) {
      if (data.action.instructions[j].layoutType == '1 rich text box') {
        if (data.action.instructions[j].layoutData.text == '')
          Vue.delete(data.action.instructions, j);
        else j++;
      } else if (data.action.instructions[j].layoutType == '1 rich text box + 3 images') {
        if (data.action.instructions[j].layoutData.text == '')
          Vue.delete(data.action.instructions, j);
        else {
          data.action.instructions[j] = _checkLayout2And3(data.action.instructions[j]);
          j++;
        }
      } else if (
        data.action.instructions[j].layoutType ==
        '1 rich text box (top) + 3 images + 1 rich text box (bottom)'
      ) {
        if (
          data.action.instructions[j].layoutData.headerText == '' &&
          data.action.instructions[j].layoutData.footerText == ''
        )
          Vue.delete(data.action.instructions, j);
        else {
          data.action.instructions[j] = _checkLayout2And3(data.action.instructions[j]);
          j++;
        }
      } else if (data.action.instructions[j].layoutType == 'Table of 1 rich text box + 1 image') {
        let instructionData = _checkLayout4(data.action.instructions[j]);
        if (instructionData.temp == 0) {
          Vue.delete(data.action.instructions, j);
        } else {
          data.action.instructions[j] = instructionData.data;
          j++;
        }
      } else if (data.action.instructions[j].layoutType == '1 image') {
        let { imageTitle, imageUrl, brief, briefUrl } = data.action.instructions[j].layoutData;
        if (imageTitle == '' && isRteEmpty(brief, true) && (imageUrl == '' || briefUrl == '')) {
          Vue.delete(data.action.instructions, j);
        } else {
          j++;
        }
      }
    }
    //analysis
    for (let i = 0; i < 2; i++) {
      if (
        data.analysis.contents[i].layoutType == '1 rich text box + 3 images' ||
        data.analysis.contents[i].layoutType ==
          '1 rich text box (top) + 3 images + 1 rich text box (bottom)'
      ) {
        data.analysis.contents[i] = _checkLayout2And3(data.analysis.contents[i]);
      } else if (data.analysis.contents[i].layoutType == 'Table of 1 rich text box + 1 image') {
        let instructionData = _checkLayout4(data.analysis.contents[i]);
        data.analysis.contents[i] = instructionData.data;
      }
    }

    if (data.analysis.contents[2]) {
      if (
        data.analysis.contents[2].layoutType == '1 rich text box + 3 images' ||
        data.analysis.contents[2].layoutType ==
          '1 rich text box (top) + 3 images + 1 rich text box (bottom)'
      ) {
        data.analysis.contents[2] = _checkLayout2And3(data.analysis.contents[2]);
      } else if (data.analysis.contents[2].layoutType == 'Table of 1 rich text box + 1 image') {
        let instructionData = _checkLayout4(data.analysis.contents[2]);
        data.analysis.contents[2] = instructionData.data;
      }
      data.analysis.optionalContentSlide = JSON.parse(JSON.stringify(data.analysis.contents[2]));
      data.analysis.contents.splice(2, 1);
    }
  }
  return data;
};

export default actions;
