<template>
  <div
    :style="`background: ${
      FIB_COLOR_CODES[index % FIB_COLOR_CODES.length].light
    }; border-radius: 7px; padding: 10px 3px; width: 100%;`"
  >
    <v-row
      v-for="(correctAnswer, j) in data.correctAnswers"
      :key="`blank-row-wrapper-${index}-${j}`"
    >
      <v-col style="width: 20px;">
        <span class="tip-tap-plugin-blank-wrapper" style="border-bottom: 0px;" v-if="j == 0"
          ><span
            class="tip-tap-plugin-blank"
            :style="`color: ${FIB_COLOR_CODES[index % FIB_COLOR_CODES.length].dark}`"
            >{{ index + 1 }}</span
          ></span
        >
        <span class="tip-tap-plugin-blank-wrapper" style="border-bottom: 0px;" v-else
          ><span
            class="tip-tap-plugin-blank"
            :style="`color: transparent; background: ${
              FIB_COLOR_CODES[index % FIB_COLOR_CODES.length].dark
            }; width: 15px; height: 15px; `"
          ></span
        ></span>
      </v-col>
      <v-col cols="10">
        <RichTextEditor
          :key="`blank-correct-answer-${index}-${j}`"
          :readonly="false"
          :canShowDiffIcon="true"
          v-model="correctAnswer.text"
          placeholder="Enter correct answer"
          :fieldIdentifier="`${partialFieldIdentifier}['${j}']`"
          projectType="question"
          :showValidationError="true"
          :includeOnlyToolBarActions="['equation']"
        />
      </v-col>
      <v-col>
        <v-btn
          icon
          @click="
            () => {
              _deleteCorrectAnswer(j);
            }
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pl-8 pr-8" v-if="isMachineScorable">
      <v-col cols="5" class="pt-4 bold">Enter percentage value for this blank</v-col>
      <v-col cols="2">
        <v-text-field
          solo
          flat
          v-model="data.scorePercentage"
          :key="`blank-score-percentage-${index}`"
          append-icon="mdi-percent"
          type="number"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pl-8 pr-8">
      <v-col>
        <v-btn @click="addAlternateAnswer" color="#B5E9E4" depressed
          ><v-icon left> mdi-plus </v-icon>Add alternate answer</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import { FIB_COLOR_CODES } from '@/components/RTE/utils/constants.js';
import { v4 as uuid } from 'uuid';

export default {
  data() {
    return {
      FIB_COLOR_CODES,
      uuid: uuid(),
    };
  },
  components: {
    RichTextEditor,
  },
  methods: {
    _deleteCorrectAnswer(j) {
      // this.deleteOption(this.index);
      this.data.correctAnswers.splice(j, 1);

      if (this.data.correctAnswers.length == 0) this.deleteHandler(this.index);
    },
    addAlternateAnswer() {
      this.data.correctAnswers.push({ text: '' });
    },
    updateCorrectAnswer(dataJson, j) {
      this.data.correctAnswers[j] = dataJson;
    },
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    deleteOption: {
      type: Function,
      required: false,
    },
    partialFieldIdentifier: {
      type: String,
      required: false,
    },
    deleteHandler: {
      type: Function,
      required: true,
    },
    isMachineScorable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
