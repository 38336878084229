import Router from '@/router';
import Vue from 'vue';
import { APP_COOKIE_NAME } from '@/constants/common';

export const loginRedirect = (pathName) => {
  return {
    name: 'Redirect',
    query: {
      redirectTo: `${location.protocol}//${location.host}${
        pathName.includes('unauthorised') ? '/' : pathName
      }`,
    },
  };
};

export const setCookie = (cookie) => {
  let existingCookieObj = getCookieAsObject();
  existingCookieObj[cookie.name] = cookie.value;
  writeCookieFromObject(existingCookieObj);
};

export const getCookie = (cookieName) => {
  let existingCookieObj = getCookieAsObject();

  return existingCookieObj[cookieName];
};

export const reLogin = () => {
  if (Vue.$cookies) Vue.$cookies.remove(APP_COOKIE_NAME);
  Router.push(loginRedirect(location.pathname));
};

const getCookieAsObject = () => {
  const existingCookie = document.cookie;
  let existingCookieObj = {};
  existingCookie.split(';').map((keyVal) => {
    let cookieVals = keyVal.split('=').map((val) => val.trim());
    existingCookieObj[cookieVals[0]] = cookieVals[1];
  });

  return existingCookieObj;
};

const writeCookieFromObject = (cookieObj) => {
  let newCookieStr = '';
  Object.keys(cookieObj).forEach((key) => {
    if (key && cookieObj[key]) newCookieStr += `${key}=${cookieObj[key]};`;
  });
  document.cookie = newCookieStr;
};
