<template>
  <router-view></router-view>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'XSEEDAcademy',
  created() {
    this.setLoggedInUser();
  },
  methods: {
    ...mapActions(['changeLoadingState', 'setLoggedInUser']),
  },
};
</script>
