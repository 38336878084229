import _ from 'lodash';

const individualQuestionsCountHash = {
  mcq: 0,
  fib: 0,
  tf: 0,
  mtf: 0,
  msq: 0,
  sa: 0,
  atf: 0,
  la: 0,
};

const defaultQuestionPurposeStatsData = {
  totalQuestions: 0,
  totalGroupQuestions: 0,
  groupQuestionIds: [],
  individualQuestionsCount: _.cloneDeep(individualQuestionsCountHash),
  totalTime: 0,
  easyQuestions: 0,
  mediumQuestions: 0,
  hardQuestions: 0,
};

const getters = {
  getQuestionTypeCounts: (state) => {
    const questionData = state.questionData;

    let questionTypeCountsHash = {
      totalNumberOfQuestions: 0,
      microtest: _.cloneDeep(defaultQuestionPurposeStatsData),
      practice: _.cloneDeep(defaultQuestionPurposeStatsData),
      revision: _.cloneDeep(defaultQuestionPurposeStatsData),
      assessment: _.cloneDeep(defaultQuestionPurposeStatsData),
      mental_math: _.cloneDeep(defaultQuestionPurposeStatsData),
      at_home_practice: _.cloneDeep(defaultQuestionPurposeStatsData),
      grammar: _.cloneDeep(defaultQuestionPurposeStatsData),
      worksheet: _.cloneDeep(defaultQuestionPurposeStatsData),
      in_class_practice: _.cloneDeep(defaultQuestionPurposeStatsData),
    };

    for (let i = 0; i < questionData.length; i++) {
      const question = questionData[i];
      const { questionGroupId, questionPurpose, questionType, time, difficulty } = question;

      if (
        typeof questionGroupId == 'undefined' ||
        questionGroupId == null ||
        questionGroupId == ''
      ) {
        if (
          questionTypeCountsHash[questionPurpose] &&
          questionTypeCountsHash[questionPurpose]['individualQuestionsCount'][questionType]
        ) {
          questionTypeCountsHash[questionPurpose]['individualQuestionsCount'][questionType] += 1;
        } else {
          questionTypeCountsHash[questionPurpose]['individualQuestionsCount'][questionType] = 1;
        }

        if (questionTypeCountsHash[questionPurpose]['totalQuestions']) {
          questionTypeCountsHash[questionPurpose]['totalQuestions'] += 1;
        } else {
          questionTypeCountsHash[questionPurpose]['totalQuestions'] = 1;
        }

        questionTypeCountsHash.totalNumberOfQuestions += 1;
      } else {
        if (questionTypeCountsHash[questionPurpose]['groupQuestionIds']) {
          const isAlreadyInserted = questionTypeCountsHash[questionPurpose][
            'groupQuestionIds'
          ].indexOf(questionGroupId);

          if (isAlreadyInserted == -1) {
            questionTypeCountsHash.totalNumberOfQuestions += 1;
            questionTypeCountsHash[questionPurpose]['totalGroupQuestions'] += 1;
            questionTypeCountsHash[questionPurpose]['groupQuestionIds'].push(questionGroupId);
          }
        } else {
          questionTypeCountsHash.totalNumberOfQuestions += 1;
          questionTypeCountsHash[questionPurpose]['totalGroupQuestions'] += 1;
          questionTypeCountsHash[questionPurpose]['groupQuestionIds'] = [questionGroupId];
        }
      }
      questionTypeCountsHash[questionPurpose]['totalTime'] =
        questionTypeCountsHash[questionPurpose]['totalTime'] + parseInt(time);

      questionTypeCountsHash[questionPurpose]['easyQuestions'] += +(difficulty == 'easy');
      questionTypeCountsHash[questionPurpose]['mediumQuestions'] += +(difficulty == 'medium');
      questionTypeCountsHash[questionPurpose]['hardQuestions'] += +(difficulty == 'hard');
    }

    return questionTypeCountsHash;
  },
};

export default getters;
