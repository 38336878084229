const state = {
  contentData: {
    currentStage: '',
  },
  currentStage: '',
  currentStageData: {},
  currentStageDataCopy: {},
  projectDetails: {},
  contentDetails: {},
  loadingStatus: {
    message: '',
    status: 'not_started',
  },
  assetZipUploaderStatus: 'not_started',
  validatedAssetZipUploaderState: 'not_started',
  assetZipUploadBasicProjectInfo: {},
  fileUploadingStatus: {
    microTextImage: 'not_started',
    worksheetImage: 'not_started',
    contentBookImage: 'not_started',
    coverImage: 'not_started',
    microMovie: 'not_started',
    coolTalk: 'not_started',
    solvedExample: 'not_started',
    slider_action: ['not_started', 'not_started', 'not_started'],
    slider: 'not_started',
    resources: ['not_started', 'not_started', 'not_started'],
  },
  currentStageAssetsSignedUrl: {},
  calledValidateFlag: false,
};

module.exports = {
  state,
};
