import { apolloClient } from '@/apollo';
import * as ConfidenceAPIQueries from '@/apiQueries/confidence';
import { logError } from '../../../utils';
import Vue from 'vue';
import { LP_FORM_SUBMIT_TEXT } from '../../../constants/LessonPlan';
import axios from 'axios';
import * as ContentEntryApiQueries from '@/apiQueries/contentEntry';
import { CA_APP_NAME, CA_CONFIDENCE_PRESET } from '../../../constants/centralizedAssetContants';
import { getCookie } from '../../../utils/auth';
import path from 'path';
import store from '@/store';

const _isShowImageBriefEnabled = (currentStage) => {
  let currentStageIndex = Object.keys(LP_FORM_SUBMIT_TEXT).indexOf(currentStage);
  currentStageIndex = currentStageIndex == -1 ? 0 : currentStageIndex;

  return currentStageIndex < 7;
};

const _getBriefFileName = (currentStage, file) => {
  let fileName = file.name.split('.');
  let n = fileName.length - 1;
  fileName[n - 1] = _isShowImageBriefEnabled(currentStage) ? fileName[0] + '_brief' : fileName[0];
  return fileName.join('.');
};

export const setFormDataForCAUpload = (file, fileName) => {
  let formData = new FormData();
  formData.append('assets', file, fileName);
  formData.append('preset', CA_CONFIDENCE_PRESET);
  formData.append('appName', CA_APP_NAME);
  return formData;
};

export const uploadConfidenceAssetToCA = async (file, fileName) => {
  let formData = setFormDataForCAUpload(file, fileName);
  let response = await axios.post(`${process.env.VUE_APP_ASSET_VAULT_ENDPOINT}/upload`, formData, {
    headers: {
      Authorization: `Bearer ${getCookie('xs-lp-entry-token')}`,
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    onUploadProgress: function (progressEvent) {
      if (path.extname(fileName) == '.mp4') {
        store.commit(
          'SET_FILE_UPLOAD_PROGRESS_VALUE',
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      }
    },
  });
  if (path.extname(fileName) == '.mp4') {
    store.commit('SET_FILE_UPLOAD_PROGRESS_VALUE', 0);
  }
  if (response && response.data && response.data.data) {
    let { _id, data } = response.data.data;
    return {
      status: 'success',
      id: _id,
      signedUrl: data.optimized ? data.optimized : data.original,
    };
  } else {
    return { status: 'failed' };
  }
};

const actions = {
  fetchConfidenceStandardsSubjects: async ({ commit }) => {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress' });
      console.log('ACTION#fetchConfidenceStandardsSubjects#START');
      const response = await apolloClient.query({
        query: ConfidenceAPIQueries.fetchConfidenceAllGradeSubjects,
        fetchPolicy: 'no-cache',
      });
      if (response && response.data) {
        setTimeout(() => {
          commit('setFetchConfidenceStandardsSubjects', {
            message: 'Successfully fetched standard subject data!',
            data: response.data.fetchAllConfidencdGradeSubjects,
          });
        }, 500);
        console.log('ACTION#fetchConfidenceStandardsSubjectss#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setStandardsDataOnApiFailure', {
          message:
            'Something went wrong while fetching Standards details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchConfidenceStandardsSubjects#END#FAILURE');
      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (error) {
      setTimeout(() => {
        commit('setStandardsDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      commit('SET_LOADING_STATUS', { status: 'error' });
      logError(error);
      console.log('ACTION#fetchConfidenceStandardsSubjects#END#ERROR');
    }
  },
  checkIsConfidenceUser: async ({ commit }) => {
    try {
      console.log('ACTION#isConfidenceUser#START');
      commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: ConfidenceAPIQueries.checkIsConfidenceUser(),
        fetchPolicy: 'no-cache',
      });
      console.log('response.data.isConfidenceUser');
      console.log(response.data.isConfidenceUser);
      if (response && response.data) {
        commit('setIsConfidenceUser', {
          message: 'Successfully fetched blocks data!',
          data: response.data,
        });
        console.log('ACTION#isConfidenceUser#END#SUCCESS');
        commit('changeLoadingState', false);
        return;
      }
      console.log('ACTION#isConfidenceUser#END#FAILURE');
      commit('changeLoadingState', false);
    } catch (error) {
      commit('changeLoadingState', false);
      logError(error);
      console.log('ACTION#isConfidenceUser#END#ERROR');
    }
  },
  fetchConfidenceBlocks: async ({ commit }, { standardId, subjectId }) => {
    try {
      console.log('ACTION#fetchConfidenceBlocks#START');
      commit('changeLoadingState', true);
      commit('SET_LOADING_STATUS', { status: 'in_progress' });
      const response = await apolloClient.query({
        query: ConfidenceAPIQueries.fetchConfidenceBlocks(standardId, subjectId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        commit('setFetchConfidenceBlocks', {
          message: 'Successfully fetched blocks data!',
          data: response.data.fetchConfidenceBlocks,
        });
        console.log('ACTION#fetchConfidenceBlocks#END#SUCCESS');
        commit('changeLoadingState', false);
        return;
      }

      setTimeout(() => {
        commit('setFetchConfidenceBlocksOnApiFailure', {
          message:
            'Something went wrong while fetching Blocks details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchConfidenceBlocks#END#FAILURE');
      commit('SET_LOADING_STATUS', { status: 'completed' });
      commit('changeLoadingState', false);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        commit('setStandardsDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      commit('SET_LOADING_STATUS', { status: 'error' });
      commit('changeLoadingState', false);
      logError(error);
      console.log('ACTION#fetchConfidenceBlocks#END#ERROR');
    }
  },
  fetchConfidenceMetadata: async ({ commit }, { gradeId, subjectId }) => {
    try {
      console.log('ACTION#fetchConfidenceMetadata#START');
      // commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: ConfidenceAPIQueries.fetchConfidenceMetadata(),
        variables: {
          gradeId: gradeId,
          subjectId: subjectId,
        },
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        // commit('changeLoadingState', false);
        setTimeout(() => {
          commit('setFetchConfidenceMetadata', {
            message: 'Successfully fetched Metadata data!',
            data: response.data.fetchConfidenceMetadata,
          });
        }, 500);
        console.log('ACTION#fetchConfidenceMetadata#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        // commit('changeLoadingState', false);
        commit('setFetchConfidenceMetadataOnApiFailure', {
          message:
            'Something went wrong while fetching Metadata details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchConfidenceMetadata#END#FAILURE');
      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        commit('setFetchConfidenceMetadataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      commit('changeLoadingState', false);
      logError(error);
      console.log('ACTION#fetchConfidenceMetadata#END#ERROR');
    }
  },
  fetchConfidenceQuestions: async ({ commit, dispatch }, { projectId }) => {
    try {
      console.log('ACTION#fetchConfidenceQuestions#START');
      commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: ConfidenceAPIQueries.getConfidenceQuestions(projectId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        commit('setFetchConfidenceQuestions', {
          message: 'Successfully fetched blocks data!',
          data: response.data.getConfidenceQuestions,
        });

        console.log('ACTION#fetchConfidenceBlocks#END#SUCCESS');

        // Assuming that 'getConfidenceQuestions' is an array or object that contains standardId and subjectId
        const confidenceQuestion = response.data.getConfidenceQuestions.data;
        if (confidenceQuestion && confidenceQuestion.gradeId && confidenceQuestion.subjectId) {
          dispatch('fetchConfidenceMetadata', {
            gradeId: confidenceQuestion.gradeId,
            subjectId: confidenceQuestion.subjectId,
          });
        }
        commit('changeLoadingState', false);
        return;
      }

      setTimeout(() => {
        commit('changeLoadingState', false);
        commit('setFetchConfidenceQuestionsOnApiFailure', {
          message:
            'Something went wrong while fetching Blocks details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchConfidenceQuestions#END#FAILURE');
      commit('SET_LOADING_STATUS', { status: 'completed' });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        commit('setStandardsDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      commit('changeLoadingState', false);
      logError(error);
      console.log('ACTION#fetchConfidenceQuestions#END#ERROR');
    }
  },
  updateAssignees: async ({ commit, dispatch }, payload) => {
    try {
      let { projectId, assignees, standardId, subjectId } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.updateAssignees(),
        variables: {
          projectId: projectId,
          assignees: {
            author: assignees.author.id,
            reviewer1: assignees.reviewer1.id,
            reviewer2: assignees.reviewer2.id,
            academicSignOff: assignees.academicSignOff.id,
            editor: assignees.editor.id,
            visualizer: assignees.visualizer.id,
            assetUploader: assignees.assetUploader.id,
            assetAuthorizer: assignees.assetAuthorizer.id,
            proofReader: assignees.proofReader.id,
          },
        },
      });
      if (response.data.updateConfidenceProject && response.data.updateConfidenceProject.success) {
        commit('setFetchConfidenceBlocks', {
          message: 'Successfully fetched blocks data!',
          data: [],
        });
        dispatch('fetchConfidenceBlocks', { standardId, subjectId });
        Vue.notify({
          title: 'Success!',
          text: `Assignee updated successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        if (
          response.data.updateConfidenceProject &&
          !response.data.updateConfidenceProject.success
        ) {
          commit('changeLoadingState', false);
          Vue.notify({
            title: 'Failed',
            text: 'Assignee updated failed',
            type: 'error',
          });
        }
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  createConfidenceProject: async ({ commit, dispatch }, payload) => {
    try {
      let { name, subjectId, standardId, entityId, assignees } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.createConfidenceProject(),
        variables: {
          input: {
            name: name,
            subjectId: subjectId,
            standardId: standardId,
            entityId: entityId,
            entityType: 'Block',
            type: 'confidence',
            assignees: {
              author: assignees.author.id,
              reviewer1: assignees.reviewer1.id,
              reviewer2: assignees.reviewer2.id,
              academicSignOff: assignees.academicSignOff.id,
              editor: assignees.editor.id,
              visualizer: assignees.visualizer.id,
              assetUploader: assignees.assetUploader.id,
              assetAuthorizer: assignees.assetAuthorizer.id,
              proofReader: assignees.proofReader.id,
            },
          },
        },
      });
      if (response.data.createConfidenceProject) {
        dispatch('fetchConfidenceBlocks', { standardId, subjectId });
        Vue.notify({
          title: 'Success!',
          text: `Confidence Project created successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'Confidence Project creation failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },

  updateBlock: async ({ commit, dispatch }, payload) => {
    try {
      let { projectId, blockName, aims, blockImage } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.updateBlock(),
        variables: {
          projectId,
          blockName,
          aims,
          blockImage,
        },
      });
      if (response.data.updateConfidenceProject && response.data.updateConfidenceProject.success) {
        dispatch('fetchConfidenceQuestions', { projectId });
        Vue.notify({
          title: 'Success!',
          text: `Block updated successfully!`,
          type: 'success',
        });
      } else {
        dispatch('fetchConfidenceQuestions', { projectId });
        if (
          response.data.updateConfidenceProject &&
          !response.data.updateConfidenceProject.success
        ) {
          commit('changeLoadingState', false);
          Vue.notify({
            title: 'Failed',
            text: 'Block updated failed',
            type: 'error',
          });
        }
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },

  createConfidenceBlock: async ({ commit, dispatch }, payload) => {
    try {
      let { title, subjectId, standardId, blockId, blockSequenceNumber, assignees } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.createConfidenceBlock(),
        variables: {
          title: title,
          blockId: blockId,
          subjectId: subjectId,
          standardId: standardId,
          blockSequenceNumber: blockSequenceNumber,
          assignees: {
            author: assignees.author.id,
            reviewer1: assignees.reviewer1.id,
            reviewer2: assignees.reviewer2.id,
            academicSignOff: assignees.academicSignOff.id,
            editor: assignees.editor.id,
            visualizer: assignees.visualizer.id,
            assetUploader: assignees.assetUploader.id,
            assetAuthorizer: assignees.assetAuthorizer.id,
            proofReader: assignees.proofReader.id,
          },
        },
      });
      if (response.data.createConfidenceBlock) {
        dispatch('fetchConfidenceBlocks', { standardId, subjectId });
        Vue.notify({
          title: 'Success!',
          text: `Confidence Block created successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'Confidence Block creation failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },

  updateConfidenceQuestion: async ({ commit, dispatch }, payload) => {
    try {
      let { projectId, isGroupQuestion, questionData } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.updateConfidenceQuestion(),
        variables: {
          projectId: projectId,
          isGroupQuestion: isGroupQuestion,
          questionData: questionData,
        },
      });
      console.log(response);
      if (response.data.updateConfidenceQuestion) {
        dispatch('fetchConfidenceQuestions', { projectId });
        Vue.notify({
          title: 'Success!',
          text: `Confidence Question updated successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        dispatch('fetchConfidenceQuestions', { projectId });
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'Confidence Question update failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  validateProjectLessonPlanStageData: async ({ commit, dispatch }, payload) => {
    try {
      let { projectId, contentLibraryId, isNextPublishStage } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.validateProjectLessonPlanStageData(),
        variables: {
          projectId: projectId,
          contentLibraryId: contentLibraryId,
        },
      });
      console.log(response);
      if (response.data.validateProjectLessonPlanStageData) {
        if (isNextPublishStage) {
          dispatch('publishConfidenceProject', { projectId });
        } else {
          dispatch('moveProjectLessonPlanToNextStage', { projectId, contentLibraryId: 0 });
        }
        Vue.notify({
          title: 'Success!',
          text: `Project validated successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'Project validation failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  moveProjectLessonPlanToNextStage: async ({ commit, dispatch }, payload) => {
    try {
      let { projectId, contentLibraryId } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.moveProjectLessonPlanToNextStage(),
        variables: {
          projectId: projectId,
          contentLibraryId: contentLibraryId,
        },
      });
      console.log(response);
      if (response.data.moveProjectLessonPlanToNextStage) {
        dispatch('fetchConfidenceQuestions', { projectId });
        Vue.notify({
          title: 'Success!',
          text: `LessonPlan project moved to next stage successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        dispatch('fetchConfidenceQuestions', { projectId });
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'LessonPlan project moved to next stage failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  saveProjectLessonPlanStageData: async ({ commit }, payload) => {
    try {
      let { projectId, contentLibraryId } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.saveProjectLessonPlanStageData(),
        variables: {
          projectId: projectId,
          contentLibraryId: contentLibraryId,
        },
      });
      console.log(response);
      if (response.data.saveProjectLessonPlanStageData) {
        // dispatch('fetchConfidenceBlocks', { standardId, subjectId });
        Vue.notify({
          title: 'Success!',
          text: `LessonPlan project saved successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'LessonPlan project save failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  createConfidenceQuestion: async ({ commit, dispatch }, payload) => {
    try {
      let { projectId, questionData, isGroupQuestion } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.createConfidenceQuestion(),
        variables: {
          projectId: projectId,
          questionData: questionData,
          isGroupQuestion: isGroupQuestion,
        },
      });
      if (response.data.addConfidenceQuestion) {
        dispatch('fetchConfidenceQuestions', { projectId });
        Vue.notify({
          title: 'Success!',
          text: `Question created successfully!`,
          type: 'success',
        });
        // commit('changeLoadingState', false);
      } else {
        dispatch('fetchConfidenceQuestions', { projectId });
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'Question creation failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  deleteConfidenceQuestion: async ({ commit, dispatch }, payload) => {
    try {
      let { projectId, questionId, questionGroupId } = payload;
      commit('changeLoadingState', true);

      const response =
        questionId !== ''
          ? await apolloClient.mutate({
              mutation: ConfidenceAPIQueries.deleteConfidenceQuestion(),
              variables: {
                projectId: projectId,
                questionId: questionId,
              },
            })
          : await apolloClient.mutate({
              mutation: ConfidenceAPIQueries.deleteConfidenceGroupQuestion(),
              variables: {
                projectId: projectId,
                questionGroupId: questionGroupId,
              },
            });

      if (response && response.data && response.data.deleteConfidenceQuestion) {
        dispatch('fetchConfidenceQuestions', { projectId });
        Vue.notify({
          title: 'Success!',
          text: `Question deleted successfully!`,
          type: 'success',
        });
      } else {
        Vue.notify({
          title: 'Failed',
          text: 'Question delete failed',
          type: 'error',
        });
      }
      commit('changeLoadingState', false);
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },

  publishConfidenceProject: async ({ commit }, payload) => {
    try {
      let { projectId } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.publishConfidenceProject(),
        variables: {
          projectId: projectId,
        },
      });
      console.log(response);
      if (response.data.publishConfidenceProject) {
        Vue.notify({
          title: 'Success!',
          text: `Project Published successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'Project Publish failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  resetConfidenceStateData: async ({ commit }, payload) => {
    try {
      let { projectId } = payload;
      commit('changeLoadingState', true);
      const response = await apolloClient.mutate({
        mutation: ConfidenceAPIQueries.publishConfidenceProject(),
        variables: {
          projectId: projectId,
        },
      });
      console.log(response);
      if (response.data.publishConfidenceProject) {
        Vue.notify({
          title: 'Success!',
          text: `Project Published successfully!`,
          type: 'success',
        });
        commit('changeLoadingState', false);
      } else {
        commit('changeLoadingState', false);
        Vue.notify({
          title: 'Failed',
          text: 'Project Publish failed',
          type: 'error',
        });
      }
    } catch (err) {
      commit('changeLoadingState', false);
      logError(err);
    }
  },
  async uploadBlockImage({ state, dispatch }, payload) {
    const { file, data, assetPath } = payload;
    try {
      if (file) {
        let response;
        let fileName = _getBriefFileName(state, file);
        dispatch('changeLoadingState', true);
        response = await uploadConfidenceAssetToCA(file, fileName);
        if (response && response.status === 'success') {
          dispatch('updateBlock', {
            projectId: data.projectId,
            blockName: data.blockName,
            aims: data.aims,
            blockImage: {
              ...data.blockImage,
              [assetPath]: response.id,
            },
          });
          Vue.notify({
            title: 'Success!',
            text: `Block Image Uploaded successfully!`,
            type: 'success',
          });
        } else {
          Vue.notify({
            title: 'Failed',
            text: 'Block Image is not valid!',
            type: 'error',
          });
          console.log('Failed');
          dispatch('changeLoadingState', false);
        }
      }
    } catch (err) {
      Vue.notify({
        title: 'Failed',
        text: 'Failed to upload block Image',
        type: 'error',
      });
      dispatch('changeLoadingState', false);
      console.log(err);
    }

    dispatch('changeLoadingState', false);
  },
  async confidenceFileRemove({ commit, dispatch }, payload) {
    try {
      commit('SET_LOADING_STATUS', { status: 'in_progress', message: 'Deleting your asset' });
      let { url, data } = payload;

      const response = await apolloClient.mutate({
        mutation: ContentEntryApiQueries.removeSingleAssetFromProject(),
        variables: {
          url,
          projectId: data.projectId,
          lessonPlanLibraryId: parseInt(0),
        },
      });

      if (response && response.data) {
        dispatch('updateBlock', {
          projectId: data.projectId,
          blockName: data.blockName,
          aims: data.aim,
          blockImage: {
            ...data.blockImage,
            briefAssetId: '',
            assetId: '',
            brief_url: '',
            image_url: '',
            imageUrlApproved: false,
          },
        });
      }
    } catch (er) {
      logError(er);
    }
  },
  resetAllConfidenceState({ commit }) {
    commit('resetFetchConfidenceQuestionsState');
    commit('resetFetchConfidenceMetadataState');
  },
};

export default actions;
