<template>
  <v-expansion-panels multiple focusable v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="AppTexts.VIDEO_HEADING"
          :toggleComments="() => toggleComments('video')"
          :displayTrailing="false"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content class="section-title-content">
        <BlockTitle :title="'MICRO-MOVIE'" />
        <p style="color: #bb161b;">SELECT AN OPTION BELOW:</p>
        <v-radio-group v-model="data.microMovie.position">
          <v-radio
            v-for="(text, index) in VIDEO_POSITION_TEXTS"
            :key="index"
            :value="text"
            :label="text"
          />
        </v-radio-group>
        <v-row>
          <v-col md="6">
            <v-col>
              <FileInput
                :canShowDiffIcon="options.canShowDiffIcon"
                :fileAcceptType="
                  !options.isAssetUploadRequired ? options.fileAcceptType + ',video/*' : 'video/*'
                "
                label="Your MP4"
                :loadingStatus="options.fileUploadingStatus.microMovie == 'in_progress'"
                :isRequired="true"
                :isAssetUploadRequired="options.isAssetUploadRequired"
                :fileChangeHandler="
                  (file) => fileUploadHandler(file, 'microMovie', data.microMovie[fileKey])
                "
                :previewUrl="
                  options.isAssetUploadRequired ? options.signedAssetUrls.microMovie.file : ''
                "
                :previewUrlBrief="options.signedAssetUrls.microMovie.fileBrief"
                :image_url="options.isAssetUploadRequired ? data.microMovie.file : ''"
                :image_url_brief="data.microMovie.fileBrief"
                :rules="[(v) => !!v || options.errorOfRequired]"
                :isNotReadOnly="options.isNotReadOnly"
                :diffIdentifier="`microMovie.${fileKey}`"
                :imagePlaceholder="'video'"
                @addBriefToAsset="
                  (previewUrl, briefUrl, approveAsset) =>
                    addToAsset(previewUrl, briefUrl, approveAsset, 'microMovie')
                "
                :isAssetApproved="data.microMovie.fileApproved"
                :canFinalizeAsset="true"
                :canAppproveAsset="canApproveAsset"
              />
            </v-col>
            <v-col>
              <RichTextEditor
                :readonly="options.isReadOnly || options.isAssetUploadRequired"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.microMovie.brief"
                fieldIdentifier="microMovie.brief"
                placeholder="brief"
                :showValidationError="false"
                :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
              />
            </v-col>
            <v-col>
              <RichTextEditor
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.microMovie.videoInstructions"
                fieldIdentifier="microMovie.videoInstructions"
                placeholder="Enter Video Instructions"
                :counter="120"
                :wordCounter="20"
                :allowWordCount="true"
                :allowCharacterCount="true"
              />
            </v-col>
            <v-col>
              <SubSection :title="AppTexts.TEACHER_ZONE" :key="0" />
              <RichTextEditor
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.microMovie.teacherZone"
                fieldIdentifier="microMovie.teacherZone"
              />
            </v-col>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col md="6">
            <v-col>
              <BlockTitle :title="'COOL TALK'" />
              <p>Appears after Analysis.(Optional Video)</p>
              <FileInput
                :canShowDiffIcon="options.canShowDiffIcon"
                :fileAcceptType="
                  !options.isAssetUploadRequired ? options.fileAcceptType + ',video/*' : 'video/*'
                "
                label="Your MP4"
                :loadingStatus="options.fileUploadingStatus.coolTalk == 'in_progress'"
                :isRequired="false"
                :isAssetUploadRequired="options.isAssetUploadRequired"
                :fileChangeHandler="
                  (file) => fileUploadHandler(file, 'coolTalk', data.coolTalk[fileKey])
                "
                :previewUrl="
                  options.isAssetUploadRequired ? options.signedAssetUrls.coolTalk.file : ''
                "
                :previewUrlBrief="options.signedAssetUrls.coolTalk.fileBrief"
                :image_url="options.isAssetUploadRequired ? data.coolTalk.file : ''"
                :image_url_brief="data.coolTalk.fileBrief"
                :isNotReadOnly="options.isNotReadOnly"
                :diffIdentifier="`coolTalk.${fileKey}`"
                :imagePlaceholder="'video'"
                @addBriefToAsset="
                  (previewUrl, briefUrl, approveAsset) =>
                    addToAsset(previewUrl, briefUrl, approveAsset, 'coolTalk')
                "
                :isAssetApproved="data.coolTalk.fileApproved"
                :canFinalizeAsset="true"
                :canAppproveAsset="canApproveAsset"
              />
            </v-col>
            <v-col>
              <RichTextEditor
                :readonly="options.isReadOnly || options.isAssetUploadRequired"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.coolTalk.brief"
                fieldIdentifier="coolTalk.brief"
                placeholder="brief"
                :showValidationError="false"
                :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
              />
            </v-col>
            <v-col>
              <RichTextEditor
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.coolTalk.videoInstructions"
                fieldIdentifier="coolTalk.videoInstructions"
                placeholder="Enter Video Instructions"
                :counter="120"
                :wordCounter="20"
                :allowWordCount="true"
                :allowCharacterCount="true"
              />
            </v-col>
            <v-col>
              <SubSection :title="AppTexts.TEACHER_ZONE" :key="1" />
              <RichTextEditor
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.coolTalk.teacherZone"
                fieldIdentifier="coolTalk.teacherZone"
              />
            </v-col>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col md="6">
            <v-col>
              <BlockTitle :title="'SOLVED EXAMPLE'" />
              <p>Appears only in Study Desk.(Optional Video)</p>
              <FileInput
                :canShowDiffIcon="options.canShowDiffIcon"
                :fileAcceptType="
                  !options.isAssetUploadRequired ? options.fileAcceptType + ',video/*' : 'video/*'
                "
                label="Your MP4"
                :loadingStatus="options.fileUploadingStatus.solvedExample == 'in_progress'"
                :isRequired="false"
                :isAssetUploadRequired="options.isAssetUploadRequired"
                :fileChangeHandler="
                  (file) => fileUploadHandler(file, 'solvedExample', data.solvedExample[fileKey])
                "
                :previewUrl="
                  options.isAssetUploadRequired ? options.signedAssetUrls.solvedExample.file : ''
                "
                :previewUrlBrief="options.signedAssetUrls.solvedExample.fileBrief"
                :image_url="options.isAssetUploadRequired ? data.solvedExample.file : ''"
                :image_url_brief="data.solvedExample.fileBrief"
                :isNotReadOnly="options.isNotReadOnly"
                :diffIdentifier="`solvedExample.${fileKey}`"
                :imagePlaceholder="'video'"
                @addBriefToAsset="
                  (previewUrl, briefUrl, approveAsset) =>
                    addToAsset(previewUrl, briefUrl, approveAsset, 'solvedExample')
                "
                :isAssetApproved="data.solvedExample.fileApproved"
                :canFinalizeAsset="true"
                :canAppproveAsset="canApproveAsset"
              />
            </v-col>
            <v-col>
              <RichTextEditor
                :readonly="options.isReadOnly || options.isAssetUploadRequired"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.solvedExample.brief"
                fieldIdentifier="solvedExample.brief"
                placeholder="brief"
                :showValidationError="false"
                :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
              />
            </v-col>
            <v-col>
              <RichTextEditor
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.solvedExample.videoInstructions"
                fieldIdentifier="solvedExample.videoInstructions"
                placeholder="Enter Video Instructions"
              />
            </v-col>
            <v-col>
              <SubSection :title="AppTexts.TEACHER_ZONE" :key="2" />
              <RichTextEditor
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.solvedExample.teacherZone"
                fieldIdentifier="solvedExample.teacherZone"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from 'vuex';
import ExpansionPanelHeader from '@/components/contentEntry/oLessonComponents/ExpansionPanelHeader';
import FileInput from '@/components/inputs/FileInput';
import BlockTitle from '@/components/inputs/BlockTitle';
import Errors from '@/constants/Errors';
import RichTextEditor from '@/components/RTE/RichTextEditor';
import AppTexts from '@/constants/AppTexts';
import SubSection from '@/components/inputs/SubSection';

export default {
  name: 'Video',
  data() {
    return {
      Errors,
      panel: [0],
      AppTexts,
      VIDEO_POSITION_TEXTS: [
        'Before slide 1 in Action',
        'After slide 1 in Action',
        'After slide 2 in Action',
        'After slide 3 in Action',
      ],
    };
  },
  components: {
    ExpansionPanelHeader,
    FileInput,
    BlockTitle,
    RichTextEditor,
    SubSection,
  },
  computed: {
    ...mapState({
      currentStageDataCopy: (state) => state.contentEntry.currentStageDataCopy,
    }),
    fileKey() {
      return this.options.isAssetUploadRequired ? 'file' : 'fileBrief';
    },
  },
  created() {
    this.data.time = '8 Mins';
    this.currentStageDataCopy.time = '8 Mins';
    if (typeof this.data.microMovie.brief == 'undefined') {
      this.$set(this.data.microMovie, 'brief', '');
      this.currentStageDataCopy.microMovie.brief = '';
    }
    if (typeof this.data.solvedExample.brief == 'undefined') {
      this.$set(this.data.solvedExample, 'brief', '');
      this.currentStageDataCopy.solvedExample.brief = '';
    }
    if (typeof this.data.coolTalk.brief == 'undefined') {
      this.$set(this.data.coolTalk, 'brief', '');
      this.currentStageDataCopy.coolTalk.brief = '';
    }
  },
  methods: {
    fileUploadHandler(file, type, path) {
      if (file == null) {
        this.fileChangeHandler(file, type, path);
        return true;
      }
      if (!this.options.isAssetUploadRequired || file.type.startsWith('video')) {
        this.fileChangeHandler(file, type, path);
      } else {
        this.$notify({
          title: 'Error!',
          text: 'File format not supported, please upload a video file',
          type: 'error',
          clean: false,
          duration: -1,
        });
      }
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canApproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
