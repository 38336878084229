<template>
  <div>
    <v-row>
      <v-radio-group @change="onChangeType" row v-model="data.type">
        <v-radio
          v-for="item in SUPPORTED_PRACTICE_LESSON_HOMEWORK_TYPES"
          :key="item"
          :label="startCase(item)"
          :value="item"
        ></v-radio>
      </v-radio-group>
    </v-row>
    <section v-if="data.type != NONE">
      <v-row>
        <v-text-field
          v-model="data.description"
          label="Description"
          placeholder="Description"
          :readonly="isReadOnly"
          outlined
        ></v-text-field>
      </v-row>
      <RTEWithTwoAssets
        class="mgn-Tm"
        :isReadOnly="isReadOnly"
        :canShowDiffIcon="canShowDiffIcon"
        :data="data"
        diffIdentifierPrefix="application.homework"
        descriptionPlaceholder=""
        :diffChanges="diffChanges || {}"
        :isAssetUploadRequired="isAssetUploadRequired"
        :signedUrls="signedUrls"
        :uploadingStatus="uploadingStatus"
        :acceptableFileType="acceptableFileType"
        :showImageBrief="showImageBrief"
        :fileValidators="fileValidators"
        :canAppproveAsset="canAppproveAsset"
        :canFinalizeAsset="true"
        :isFirstAssetMandatory="false"
        :isSecondAssetMandatory="false"
        :descriptionMaxLength="0"
        :isDescriptionOptional="true"
        :showRTEDescription="false"
        :firstAssetKey="firstAssetKey"
        :secondAssetKey="secondAssetKey"
        :secondAssetAnswerKey="secondAssetAnswerKey"
        :assetTypeMapping="{
          [firstAssetKey]: AssetTypes.PRACTICE_HOMEWORK_PROJECT_WORK_ASSET,
          [secondAssetKey]: AssetTypes.PRACTICE_HOMEWORK_MY_LEARNING_CHART_ASSET,
          [secondAssetAnswerKey]: AssetTypes.PRACTICE_HOMEWORK_MY_LEARNING_CHART_ANSWER_ASSET,
        }"
        :showSecondAsset="canShowSecondaryAsset"
      />
    </section>
  </div>
</template>

<script>
import {
  SUPPORTED_PRACTICE_LESSON_HOMEWORK_TYPES,
  PROJECT_WORK_TYPE,
  PROJECT_WORK_AND_MLC_TYPE,
  PROJECT_WORK_TYPE_DESCRIPTION,
  PROJECT_WORK_AND_MLC_TYPE_DESCRIPTION,
  PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA,
  PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA,
  NONE,
} from '@/constants/common.js';
import RTEWithTwoAssets from '@/components/inputs/RTEWithTwoAssets.vue';
import AssetTypes from '@/constants/AssetTypes';
import { startCase, cloneDeep } from 'lodash';

export default {
  name: 'PracticeHomework',
  components: {
    RTEWithTwoAssets,
  },
  computed: {
    canShowSecondaryAsset() {
      return this.data.type == PROJECT_WORK_AND_MLC_TYPE;
    },
  },
  data() {
    return {
      SUPPORTED_PRACTICE_LESSON_HOMEWORK_TYPES,
      PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA,
      NONE,
      PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA,
      firstAssetKey: 'project_work',
      secondAssetKey: 'my_learning_chart',
      secondAssetAnswerKey: 'my_learning_chart_answer',
      AssetTypes,
    };
  },
  methods: {
    startCase,
    onChangeType(type) {
      if (type == PROJECT_WORK_TYPE) {
        this.data.description = PROJECT_WORK_TYPE_DESCRIPTION;
        this.data.my_learning_chart = cloneDeep(
          this.PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA.my_learning_chart
        );
        this.signedUrls.my_learning_chart = cloneDeep(
          this.PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA.my_learning_chart
        );
      } else if (type == PROJECT_WORK_AND_MLC_TYPE) {
        this.data.description = PROJECT_WORK_AND_MLC_TYPE_DESCRIPTION;
        this.data.my_learning_chart_answer = cloneDeep(
          this.PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA.my_learning_chart_answer
        );
        this.signedUrls.my_learning_chart_answer = cloneDeep(
          this.PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA.my_learning_chart_answer
        );
      } else if (type == NONE) {
        this.data.description = '';
        this.data.my_learning_chart = cloneDeep(
          this.PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA.my_learning_chart
        );
        this.data.project_work = cloneDeep(this.PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA.project_work);
        this.signedUrls = cloneDeep(this.PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA);
      }
    },
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    diffChanges: {
      type: Object,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: true,
    },
    signedUrls: {
      type: Object,
      required: false,
    },
    uploadingStatus: {
      type: Object,
      required: true,
    },
    acceptableFileType: {
      type: String,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    fileValidators: {
      type: Array,
      required: false,
    },
    canAppproveAsset: {
      type: Boolean,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (this.data.type == PROJECT_WORK_AND_MLC_TYPE && !this.data[this.secondAssetAnswerKey]) {
      this.data.my_learning_chart_answer = cloneDeep(
        this.PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA.my_learning_chart_answer
      );
      this.signedUrls.my_learning_chart_answer = cloneDeep(
        this.PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA.my_learning_chart_answer
      );
    }
  },
};
</script>
