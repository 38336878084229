import AppTexts from '@/constants/AppTexts';
import { baseEarlyProgramMixin } from './base';

export const fiveStepTemplate = {
  mixins: [baseEarlyProgramMixin],
  data() {
    return {
      Schema: {
        analysis: {
          showGd: false,
          showHot: false,
          showPart1Text: false,
          label: {
            activityDiscussion: AppTexts.DISCUSSION, // done
            activityExplanation: AppTexts.EXPLANATION_TEXT, // done
          },
        },
      },
    };
  },
};
