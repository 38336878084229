<template>
  <v-form v-if="lessonPlanData.application" ref="applicationSectionForm" class="section-title-cnr">
    <RichTextEditor
      :readonly="!isNotReadOnly"
      :canShowDiffIcon="canShowDiffIcon"
      v-model="lessonPlanData.application.text"
      :fieldIdentifier="`application.text`"
      :showValidationError="true"
      :allowCharacterCount="false"
      placeholder="Enter text..."
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <FileInput
      :image_url="isAssetUploadRequired ? lessonPlanData.application.image.assetId : ''"
      :image_url_brief="lessonPlanData.application.image.brief.assetId"
      :previewUrl="
        isAssetUploadRequired && applicationSignedUrls
          ? applicationSignedUrls.image.assetSignedUrl
          : ''
      "
      :previewUrlBrief="applicationSignedUrls.image.brief.assetSignedUrl"
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiffChanged('image', imageKey)"
      :diffIdentifier="`application.image.${imageKey}`"
      label="Select Asset"
      :loadingStatus="uploadingStatus[assetTypeMapping.image] == FILE_UPLOADING_STATUS.IN_PROGRESS"
      :fileChangeHandler="(file) => fileChangeHandler(file, 'image')"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :fileAcceptType="acceptableFileType"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="true"
      :canAppproveAsset="canAppproveAsset"
      :isAssetApproved="lessonPlanData.application.image.assetApproved"
      :isRequired="false"
      @addBriefToAsset="
        (previewUrl, briefUrl, isAssetApproved) =>
          addBriefToAsset(previewUrl, briefUrl, 'image', isAssetApproved)
      "
    />
    <RichTextEditor
      class="mgn-Tm"
      v-if="showImageBrief"
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isImageBriefDiffChanged(`image.brief.text`)"
      :fieldIdentifier="`application.image.brief.text`"
      v-model="lessonPlanData.application.image.brief.text"
      :showValidationError="true"
      placeholder="image brief"
      :counter="3000"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { APPLICATION_BLOCK_KEY_OF_STATE_LP_DATA, LP_TYPES } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import AssetTypes from '@/constants/AssetTypes';
import { getAcceptableFileType } from '@/utils';
import FileInput from '@/components/inputs/FileInput';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import { get } from 'lodash';

export default {
  name: 'ApplicationSectionForDiscussionLpType',
  components: {
    RichTextEditor,
    FileInput,
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      questionTeacherTipRevision: 0,
      APPLICATION_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      LP_TYPES,
      BASIC_TOOLS,
      imageKey: this.isAssetUploadRequired ? 'assetId' : 'brief.assetId',
      assetTypeMapping: {
        image: AssetTypes.DISCUSSION_APPLICATION_IMAGE,
      },
    };
  },
  methods: {
    ...mapActions(['importSingleAssetAction', 'deleteSingleAssetAction', 'copySingleAssetAction']),
    getAcceptableFileType,
    getFileNameFromUrl,
    updateDescriptionToState(description) {
      this.updateApplicationClassworkDescription({ description });
    },
    isDiffChanged(entity, attribute) {
      if (this.applicationDiff[entity]) {
        try {
          let diffAttributVal = get(this.applicationDiff, `${entity}.${attribute}.isChanged`);
          return diffAttributVal == true;
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    isImageBriefDiffChanged(entity) {
      if (this.applicationDiff) {
        let diff = get(this.applicationDiff, entity);
        if (diff) {
          return diff.isChanged;
        }
      }
    },
    fileChangeHandler(file, entity) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: this.assetTypeMapping[entity],
        });
      } else {
        this.deleteSingleAssetAction({
          assetId: get(this.lessonPlanData, `application.${entity}.${this.imageKey}`),
          assetType: this.assetTypeMapping[entity],
          fieldIdentifier: `application.${entity}.${this.imageKey}`,
        });
      }
    },
    addBriefToAsset(previewUrl, briefUrl, entity, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: this.assetTypeMapping[entity],
        isAssetApproved,
      });
    },
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    lessonPlanData: {
      type: Object,
      required: true,
    },
    deletePractice: {
      type: Function,
      required: true,
    },
    addPractice: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    applicationSignedUrls: {
      type: Object,
      required: true,
    },
    applicationDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    workSheetEnabled: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canAppproveAsset: { type: Boolean, required: true },
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      applicationBockStaticTexts: (state) => state.lessonPlan.staticTexts,
      lpType: (state) => state.lessonPlan.lpType,
    }),
  },
};
</script>
<style scoped>
.small-text-field {
  zoom: 0.8;
  height: 40px;
  transform: translateY(-10px);
}
.sub-head {
  font-weight: 500;
}
</style>
