var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasGlobalSearch)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},on:{"change":function($event){return _vm.doSearch()},"click:clear":function($event){return _vm.searchHandler('')}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.filters),function(filter,index){return _c('v-col',{key:index,attrs:{"cols":filter.cols || 2}},[(filter.type == 'select')?_c('span',[_c('v-select',{attrs:{"hide-details":"","outlined":"","items":filter.items || [],"label":filter.label,"multiple":filter.multiple || false,"clearable":true},on:{"change":function (event) { return _vm.callFilterHandler(filter, event); }},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})],1):(filter.type == 'text')?_c('span',[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","label":filter.label},on:{"change":function (event) { return _vm.callFilterHandler(filter, event); }},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})],1):(filter.type == 'button')?_c('span',[_c('v-btn',{on:{"click":filter.handler}},[_vm._v(_vm._s(filter.text))])],1):_c('span',[_vm._v("Un-supported Filter Type")])])}),1),_c('v-data-table',{class:_vm.dataTableClass,attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":_vm.loadingText,"server-items-length":_vm.totalCount,"items-per-page":_vm.isAdmin ? 50 : 10,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions }},on:{"update:options":_vm.paginationHandler},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.items.indexOf(item) + 1))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_vm._l((_vm.handlers),function(handler,index){return _c('span',{key:index},[(
              (_vm.auth[handler.authPermissionKey] ||
                (_vm.auth.canEditProjectList && _vm.auth.canEditProjectList.includes(item.id))) &&
              !(handler.canShow && !handler.canShow(item))
            )?_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return handler.function(item)}}},[(handler.icon)?_c('v-icon',{attrs:{"size":"15"}},[_vm._v(_vm._s(handler.icon))]):_vm._e(),_vm._v(" "+_vm._s(handler.name)+" ")],1):_vm._e()],1)}),(_vm.showSubHandlerMenu(item))?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.subHandlers),function(handler,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[(
                    (_vm.auth[handler.authPermissionKey] ||
                      (_vm.auth.canEditProjectList && _vm.auth.canEditProjectList.includes(item.id))) &&
                    !(handler.canShow && !handler.canShow(item))
                  )?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return handler.function(item)}}},[(handler.icon)?_c('v-icon',{attrs:{"size":"15"}},[_vm._v(_vm._s(handler.icon))]):_vm._e(),_vm._v(" "+_vm._s(handler.name)+" ")],1):_vm._e()],1)],1)}),1)],1):_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }