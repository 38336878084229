const { max, min } = require('lodash');
const { MONTH_NUMBER_TO_NAME_MAPPING } = require('../../../constants/month');

export function getEarliestTime(arrayOdTimesInMs) {
  if (arrayOdTimesInMs.length == 0) return null;

  return min(arrayOdTimesInMs.map((_ms) => +_ms));
}

export function getLastTime(arrayOdTimesInMs) {
  if (arrayOdTimesInMs.length == 0) return null;

  return max(arrayOdTimesInMs.map((_ms) => +_ms));
}

export function getDisplayTextForDate(dateObject) {
  if (!dateObject) return null;

  var hours = dateObject.getHours();
  var minutes = dateObject.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const month = MONTH_NUMBER_TO_NAME_MAPPING[dateObject.getMonth()];
  const date = dateObject.getDate();
  const year = dateObject.getFullYear();

  return {
    fullDateString: `${date} ${month}, ${year}`,
    fullTimeString: `${hours}:${minutes}`,
    ampm: ampm,
    year: year,
  };
}
