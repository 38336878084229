<template>
  <div class="sub-project-status-wrapper">
    <div class="project-name">{{ lessonType }}</div>
    <div class="project-status-wrapper">
      <span
        v-if="
          currentStage == AppTexts.SUB_PROJECT_COMPLETED_STATUS &&
          currentStatus != AppTexts.WORKFLOW_IN_PROGRESS
        "
      >
        <img src="@/assets/bluetick.svg" height="30" width="30" />
      </span>
      <span
        v-else-if="
          currentStage == AppTexts.SUB_PROJECT_COMPLETED_STATUS &&
          currentStatus == AppTexts.WORKFLOW_IN_PROGRESS
        "
      >
        <img src="@/assets/greentick.svg" height="30" width="30" />
      </span>
      <div
        v-else
        :style="'border-color: ' + getBorderColorBasedOnStage(currentStage) + ';'"
        class="current-stage"
      >
        <span>{{ currentStage }}</span>
      </div>
      <div class="project-current-status-wrapper">
        <div class="project-status-graph">
          <div
            :class="
              currentStage == AppTexts.SUB_PROJECT_COMPLETED_STATUS &&
              currentStatus == AppTexts.WORKFLOW_IN_PROGRESS
                ? snakeCase('com') + '-bar'
                : snakeCase(currentStatus) + '-bar'
            "
          ></div>
        </div>
        <div class="project-status-text">{{ capitalCase(getProjectStausText(status)) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalCase, snakeCase } from 'change-case';
import AppTexts from '@/constants/AppTexts';
const currentStageBorderColorMapping = {
  AU1: '#FFA158',
  AU2: '#FFA158',
  R1: '#66E6DD',
  R2: '#66E6DD',
  ASO: '#70D37C',
  NS: '#979797',
  NA: '#979797',
  COM: '#3ab54a',
  PR: '#9370D3',
  VIZ: '#F2E749',
  AA: '#BF820F',
  DEFAULT: '#FFA158',
};

export default {
  name: 'SubProjectStatus',
  data() {
    return {
      AppTexts,
    };
  },
  methods: {
    capitalCase,
    snakeCase,
    getBorderColorBasedOnStage(currentStage) {
      return (
        currentStageBorderColorMapping[currentStage] || currentStageBorderColorMapping['DEFAULT']
      );
    },
    getProjectStausText(status) {
      if (status == 'completed') return 'published';
      else if (this.currentStage == 'COM') return 'completed';
      return status;
    },
  },
  computed: {
    currentStatus() {
      let splitted = this.status.split(' ');
      return splitted[0] == 'in_progress' ? splitted[0] : this.status;
    },
  },
  props: {
    lessonType: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    currentStage: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.sub-project-status-wrapper {
  height: 60px;
}
.project-name {
  color: #242424;
  font-weight: 700;
  font-size: 12px;
}
.project-status-wrapper {
  margin-top: 14px;
  display: flex;
}
.project-status-wrapper .current-stage {
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-width: 1px;
  border-style: solid;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-status-wrapper .current-stage-text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-status-text {
  font-size: 8px;
  font-weight: 500;
}
.project-current-status-wrapper {
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-status-graph {
  border-radius: 10.5px;
  height: 5px;
  width: 70px;
  background-color: #eaeaea;
}
.project-status-graph > div {
  height: 5px;
  border-radius: 10.5px;
}
.project-status-graph .in_progress-bar {
  width: 50%;
  background-color: #ffa158;
}
.project-status-graph .completed-bar {
  width: 100%;
  background-color: #007aff;
}
.project-status-graph .com-bar {
  width: 100%;
  background-color: #3ab54a;
}
.project-status-graph .not_started-bar {
  width: 0%;
  background-color: #979797;
}
</style>
