<template>
  <div>
    <RichTextEditor
      :key="`${optionTextRevision}-${generateIdentifier}`"
      :readonly="false"
      :canShowDiffIcon="true"
      :placeholder="placeholder"
      :fieldIdentifier="`${partialFieldIdentifier}['${data._id}'].optionText`"
      projectType="question"
      :showValidationError="true"
      v-model="data.optionText"
      :style="setOptionWidth"
      :includeOnlyToolBarActions="getToolBarActions"
      @input="onChangeOptionText"
      :isAssetUploadRequired="options.isAssetUploadRequired"
      :forceAssetFinalized="forceAssetFinalized"
      :acceptedFileTypes="getRTEAcceptedFileType"
    />
    <v-row>
      <v-col
        v-for="(image, index) in imagesInOptionText"
        :key="`imagesInOptionText${revision}${index}`"
        :cols="7"
      >
        <FileInput
          :canShowDiffIcon="false"
          :diffIdentifier="''"
          :fileAcceptType="'Image/*'"
          :label="'Image'"
          :loadingStatus="false"
          :isRequired="options.isAssetUploadRequired"
          :isAssetUploadRequired="options.isAssetUploadRequired"
          :fileChangeHandler="(file) => fileChangeHandler(file, index)"
          :previewUrl="image.attrs.src"
          :previewUrlBrief="image.attrs.briefSrc"
          :image_url="image.attrs.assetId"
          :image_url_brief="image.attrs.briefAssetId"
          :isNotReadOnly="options.isNotReadOnly"
          :canAppproveAsset="canAppproveAsset"
          :ApproveAssetAndEmitResult="true"
          :canFinalizeAsset="true"
          :useAssetVault="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, approveAsset) =>
              addToAsset(previewUrl, briefUrl, approveAsset, index)
          "
          :isAssetApproved="image.attrs.assetApproved"
          @assetApprovedByUser="(val) => approveAssets(val, index)"
        />
        <br />
        <RichTextEditor
          :readonly="options.isAssetUploadRequired"
          :canShowDiffIcon="false"
          v-model="image.attrs.brief"
          placeholder="Enter Image Brief"
          projectType="question"
          :showValidationError="false"
          :includeOnlyToolBarActions="getToolBarActionsForQuestionBriefs"
          @input="addBriefTextAtPosition(image.attrs.brief, index)"
          :removeAllPluginExceptFullScreen="true"
          :counter="3000"
          :allowCharacterCount="true"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import FileInput from '@/components/inputs/FileInput';
import {
  getImageUrlFromRte,
  deleteImageInRTE,
  insertImageAssetAtPosition,
  approveAssetAtPosition,
  addBriefToAssetInPosition,
} from '@xseededucation/rte-helper-functions';

export default {
  components: {
    RichTextEditor,
    FileInput,
  },
  data() {
    return {
      imagesInOptionText: [],
      revision: 0,
      optionTextRevision: 0,
    };
  },
  computed: {
    ...mapState({
      questionCommentsToggle: (state) => state.questions.questionCommentsToggle,
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
    }),
    setOptionWidth() {
      return this.questionCommentsToggle || this.setQuestionDiffPanel
        ? 'width: 400px'
        : 'width: 380px';
    },
    generateIdentifier() {
      return 'mtf-Option-Key-or-Value' + Math.random().toString(36).slice(2);
    },
  },
  methods: {
    addBriefTextAtPosition(rteData, index) {
      let optionText = JSON.parse(this.data.optionText);
      addBriefToAssetInPosition(optionText.content, index, 0, rteData);
      this.data.optionText = JSON.stringify(optionText);
    },
    fileChangeHandler(file, index) {
      if (!file) {
        let optionText = JSON.parse(this.data.optionText);
        deleteImageInRTE(optionText.content, index, 0, this.options.isAssetUploadRequired);
        this.getImagesListFromOptionText(optionText);
        this.data.optionText = JSON.stringify(optionText);
        this.revision += 1;
        this.optionTextRevision += 1;
      } else {
        let optionText = JSON.parse(this.data.optionText);
        insertImageAssetAtPosition(
          optionText.content,
          index,
          0,
          file,
          this.options.isAssetUploadRequired
        );
        this.getImagesListFromOptionText(optionText);
        this.data.optionText = JSON.stringify(optionText);
        this.revision += 1;
        this.optionTextRevision += 1;
      }
    },
    getImagesListFromOptionText(jsonData) {
      let imagesInOptionText = [];
      getImageUrlFromRte(jsonData.content, imagesInOptionText);
      this.imagesInOptionText = imagesInOptionText;
      this.revision += 1;
    },
    addToAsset(previewUrl, briefUrl, approveAsset, index) {
      let optionText = JSON.parse(this.data.optionText);
      approveAssetAtPosition(
        optionText.content,
        index,
        0,
        this.options.isAssetUploadRequired,
        approveAsset,
        previewUrl,
        briefUrl
      );
      this.getImagesListFromOptionText(optionText);
      this.data.optionText = JSON.stringify(optionText);
      this.revision += 1;
      this.optionTextRevision += 1;
    },
    approveAssets(val, index) {
      let optionText = JSON.parse(this.data.optionText);
      approveAssetAtPosition(optionText.content, index, 0, this.options.isAssetUploadRequired, val);
      this.getImagesListFromOptionText(optionText);
      this.data.optionText = JSON.stringify(optionText);
      this.revision += 1;
    },
    onChangeOptionText(jsonString) {
      let jsonData = JSON.parse(jsonString);
      this.getImagesListFromOptionText(jsonData);
    },
  },
  mounted() {
    this.getImagesListFromOptionText(JSON.parse(this.data.optionText));
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    partialFieldIdentifier: {
      type: String,
      required: true,
    },
    getToolBarActions: {
      type: Array,
      required: true,
    },
    getToolBarActionsForQuestionBriefs: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    forceAssetFinalized: {
      type: Boolean,
      required: true,
    },
    getRTEAcceptedFileType: {
      type: Array,
      required: true,
    },
  },
};
</script>
