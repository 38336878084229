<template>
  <div class="text-center">
    <v-dialog v-model="dialogToogle" @click:outside="clearModel" width="700">
      <template v-slot:activator="{ on }">
        <span>
          <v-btn v-if="canCreateProject" @click="onClickAddProject" v-on="on">Add Project</v-btn>
        </span>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Add Project</v-card-title>

        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              @click="validateCurrentFormAndNavidateTo(1)"
              class="step"
              >Step 1</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
              @click="validateCurrentFormAndNavidateTo(2)"
              class="step"
              >Step 2</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step step="3" @click="validateCurrentFormAndNavidateTo(3)" class="step"
              >Step 3</v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="container" style="width: 80%;">
                <v-flex class="text-xs-center">
                  <v-form ref="form1">
                    <v-label>{{ AppTexts.SELECT_PRODUCT }}</v-label>
                    <v-select
                      v-model="projectData.product.id"
                      :items="data.products"
                      :rules="[(v) => !!v || 'Product is required']"
                      required
                      item-text="name"
                      item-value="id"
                      class="select-grade-dropdown"
                      :disabled="isEdit"
                    />
                    <v-label>{{ AppTexts.SELECT_BOARD }}</v-label>
                    <v-select
                      v-model="projectData.board.id"
                      :items="data.boards"
                      :rules="[(v) => !!v || 'Board is required']"
                      required
                      item-text="name"
                      item-value="id"
                      class="select-board-dropdown"
                      :disabled="isEdit"
                    />
                    <v-label>{{ AppTexts.SELECT_SUBJECT }}</v-label>
                    <v-select
                      v-model="projectData.subject.id"
                      :items="data.subjects"
                      :rules="[(v) => !!v || 'Subject is required']"
                      required
                      item-text="name"
                      item-value="id"
                      class="select-subject-dropdown"
                      :disabled="isEdit"
                    />
                    <v-label>{{ AppTexts.SELECT_STANDARD }}</v-label>
                    <v-select
                      v-model="projectData.standard.id"
                      :items="data.standards"
                      :rules="[(v) => !!v || 'Grade is required']"
                      required
                      item-text="name"
                      item-value="id"
                      class="select-grade-dropdown"
                      :disabled="isEdit"
                    />
                    <v-layout row justify-center></v-layout>
                  </v-form>
                </v-flex>
              </div>
              <v-row>
                <v-col></v-col>
                <v-col cols="5">
                  <v-btn color="primary" @click="validateForm1">Next</v-btn>
                  <v-btn text @click="cancelForm">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="container" style="width: 80%;">
                <v-flex class="text-xs-center">
                  <v-form ref="form2">
                    <div v-if="!isEarlyProgram">
                      <v-label>{{ AppTexts.SELECT_PROJECT_TYPE }}</v-label>
                      <v-radio-group v-model="selectedProjectType">
                        <v-radio
                          v-for="projectType in projectTypes"
                          :key="projectType.value"
                          :label="projectType.name"
                          :value="projectType.value"
                          :disabled="isEdit"
                        ></v-radio>
                      </v-radio-group>
                    </div>

                    <v-label>{{ AppTexts.SELECT_BLOCK }}</v-label>
                    <v-select
                      v-model="projectData.block.id"
                      :items="blockList"
                      :rules="[(v) => !!v || 'Block is required']"
                      required
                      item-text="label"
                      item-value="id"
                      class="select-board-dropdown"
                      @change="blockChangeHandler()"
                      :disabled="isEdit == true"
                    />
                    <span v-if="selectedProjectType === 'LessonPlan'">
                      <v-label>{{ selectChildrenLabel }}</v-label>
                      <v-select
                        v-model="projectData.childrenEntityIds"
                        :items="selectedBlockChildrens"
                        :rules="[(v) => (!!v && v.length > 0) || 'Atelast one should be selected']"
                        required
                        item-text="name"
                        item-value="id"
                        class="select-block-children-dropdown"
                        chips
                        multiple
                        :disabled="isEdit == true"
                      />
                    </span>
                    <v-label>{{ AppTexts.PROJECT_NAME }}</v-label>
                    <v-text-field
                      v-model="projectData.name"
                      :rules="[(v) => !!v || 'Project Name is required']"
                      required
                      item-text="name"
                      item-value="id"
                      class="select-subject-dropdown"
                    />
                    <v-layout row justify-center></v-layout>
                  </v-form>
                </v-flex>
              </div>
              <v-row>
                <v-col></v-col>
                <v-col cols="4">
                  <v-btn color="primary" @click="validateForm2">Next</v-btn>
                  <v-btn text @click="cancelForm">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="container" style="width: 80%;">
                <v-flex class="text-xs-center">
                  <v-form ref="form3">
                    <ProjectStageAssigneeFormContent
                      :projectData="projectData"
                      :getUsersByRoles="getUsersByRoles"
                    />
                  </v-form>
                </v-flex>
              </div>
              <v-row>
                <v-col></v-col>
                <v-col cols="4">
                  <v-btn color="primary" @click="validateForm3">Save</v-btn>
                  <v-btn text @click="cancelForm">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import { BLOCK_CHILDREN_TYPES_HASH } from '@/constants/Block';
import ProjectStageAssigneeFormContent from '@/components/Admin/ProjectStageAssigneeFormContent';
import { PROJECT_CREATE_MANDATORY_STAGES, SKIP_STAGE_USER_ID_VALUE } from '@/constants/microTopic';
import { PROJECT_TYPE_VIRTUAL_QUESTION } from '@/constants/common';

export default {
  name: 'ProjectForm',
  components: {
    ProjectStageAssigneeFormContent,
  },
  data() {
    return {
      dialogToogle: false,
      AppTexts,
      e1: 1,
      defaultProjectName: '',
      selectedBlock: null,
      projectTypes: [
        { name: 'Lesson Plan', value: 'LessonPlan' },
        { name: 'Question', value: PROJECT_TYPE_VIRTUAL_QUESTION },
      ],
      selectedProjectType: 'LessonPlan',
      PROJECT_TYPE_VIRTUAL_QUESTION,
    };
  },
  watch: {
    dialog(newValue) {
      this.dialogToogle = newValue;
      if (newValue) {
        this.defaultProjectName = this.projectData.name;
      }
    },
    selectedProjectType: {
      immediate: true,
      handler(newVal) {
        if (!this.isEdit) {
          this.projectData.block = {};
          this.projectData.name = '';
          this.$set(this.projectData, 'projectType', newVal);
        }
      },
    },
    isEarlyProgram() {
      if (this.isEarlyProgram) {
        this.$set(this.projectData, 'projectType', 'DailyPlan');
      }
    },
    projectData: {
      immediate: true,
      handler(newVal) {
        if (this.isEdit) {
          if (newVal.contents.length && newVal.contents[0].type == 'virtualQuestions') {
            this.selectedProjectType = PROJECT_TYPE_VIRTUAL_QUESTION;
          } else {
            this.selectedProjectType = 'LessonPlan';
          }

          this.blocks = [{ id: newVal.block.id, title: newVal.block.title }];
        }
      },
    },
  },
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    blocks: {
      type: Array,
      required: true,
    },
    createProject: {
      type: Function,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectData: {
      type: Object,
      required: true,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    getBlocksForSelectedProductBoardStandardSubject: {
      type: Function,
      required: true,
    },
    onClickAddProject: {
      type: Function,
      required: true,
    },
    canCreateProject: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    cancelForm() {
      this.onClickCancel();
      this.dialogToogle = false;
      this.$emit('resetForm');
      this.e1 = 1;
      this.projectData.name = this.defaultProjectName;
    },
    saveForm() {
      this.createProject();
      this.dialogToogle = false;
      this.$emit('resetForm');
      this.e1 = 1;
    },
    validateCurrentFormAndNavidateTo(navigateTo) {
      if (this.e1 < navigateTo) {
        switch (navigateTo) {
          case 2:
            if (this.$refs.form1.validate()) this.e1 = navigateTo;
            break;
          case 3:
            if (this.$refs.form1.validate() && this.$refs.form2.validate()) this.e1 = navigateTo;
            break;
        }
      } else {
        this.e1 = navigateTo;
      }
    },
    validateForm1() {
      if (this.$refs.form1.validate()) {
        let board = this.data.boards.find((board) => board.id == this.projectData.board.id);

        if (board) {
          if (this.isEdit != true) {
            this.getBlocksForSelectedProductBoardStandardSubject({
              productId: this.projectData.product.id,
              boardName: board.name,
              subjectId: this.projectData.subject.id,
              standardId: this.projectData.standard.id,
            });
          }
          this.e1 = 2;
        }
      }
    },
    validateForm2() {
      if (this.$refs.form2.validate()) {
        this.e1 = 3;
      }
    },
    validateForm3() {
      if (this.$refs.form3.validate()) {
        this.saveForm();
      }
    },
    clearModel() {
      this.cancelForm();
      this.$emit('clearModel');
    },
    getUsersByRoles(role) {
      let sortListedUsers = [];

      if (!PROJECT_CREATE_MANDATORY_STAGES.includes(role)) {
        sortListedUsers.push({
          id: SKIP_STAGE_USER_ID_VALUE,
          name: 'Skip(Not assign Users)',
        });
      }

      for (let user of this.data.lpEntryUsers) {
        let rolesList = user.roles.map((role) => role.name);
        if (rolesList.includes(role) && user.user) {
          sortListedUsers.push({
            id: user.user._id,
            name: user.user.name,
          });
        }
      }
      return sortListedUsers;
    },
    blockChangeHandler() {
      let block = this.blocks.find((block) => block.id == this.projectData.block.id);
      let board = this.data.boards.find((board) => board.id == this.projectData.board.id);
      let subject = this.data.subjects.find((subject) => subject.id == this.projectData.subject.id);
      let standard = this.data.standards.find(
        (standard) => standard.id == this.projectData.standard.id
      );
      if (block) {
        this.projectData.name = `${board.name} ${standard.displayName} ${
          subject.shortName.charAt(0).toUpperCase() + subject.shortName.slice(1)
        } Blk (${block.sequence}) - ${block.title} - ${block.bId}`;

        if (this.selectedProjectType === PROJECT_TYPE_VIRTUAL_QUESTION) {
          this.projectData.name += ' - Question';
        }

        this.selectedBlock = block;
        this.projectData.block.childrenType = block.childrenType;
        this.projectData.childrenEntityIds = [];
      }
    },
  },
  computed: {
    blockList() {
      return this.blocks.map((_block) => {
        return { id: _block.id, label: `${_block.bId} - ${_block.title}` };
      });
    },
    selectChildrenLabel() {
      let label = AppTexts.SELECT;
      if (this.selectedBlock && this.selectedBlock.childrenType) {
        label =
          this.selectedBlock.childrenType == BLOCK_CHILDREN_TYPES_HASH.DAILY_PLAN
            ? AppTexts.SELECT_DAILY_PLANS
            : AppTexts.SELECT_LESSON_PLANS;
      }

      return label;
    },
    selectedBlockChildrens() {
      if (this.isEdit) {
        let blockChildrens =
          this.projectData.block.childrenType == BLOCK_CHILDREN_TYPES_HASH.DAILY_PLAN
            ? this.projectData.dailyPlans
            : this.projectData.contents;

        return blockChildrens.map((_temp) => ({ id: +_temp.library_id, name: _temp.name }));
      } else {
        let block = this.blocks.find((block) => block.id == this.projectData.block.id);

        if (block) {
          return block.childrenType == BLOCK_CHILDREN_TYPES_HASH.DAILY_PLAN
            ? block.dailyPlans
            : block.lessonPlans;
        }
      }

      return [];
    },
    isEarlyProgram() {
      let earlyChildhood = this.data.subjects.find((_subject) => {
        if (_subject.name == 'Early Childhood') {
          return this.projectData.subject.id == _subject.id;
        }
      });

      return earlyChildhood ? true : false;
    },
  },
};
</script>
<style lang="scss">
.step {
  cursor: pointer;
}
</style>
