import Vue from 'vue';
const state = {
  rteValidator: {},
  fileUploadProgress: 0,
};

const mutations = {
  RTE_VALIDATOR(state, data) {
    if (data.action == 'add') {
      state.rteValidator[data.id] = data.status;
    } else if (data.action == 'remove') {
      Vue.delete(state.rteValidator, data.id);
    }
  },
  SET_FILE_UPLOAD_PROGRESS_VALUE(state, data) {
    Vue.set(state, 'fileUploadProgress', data);
  },
};

export default {
  state,
  mutations,
};
