<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="trainings"
      :single-expand="true"
      item-key="id"
      show-expand
      expand-icon="mdi-menu-down"
      class="elevation-1"
      :search="searchText"
      :hide-default-header="hideHeader"
      hide-default-footer
      :class="classNames"
    >
      <template v-slot:expanded-item="{ item: trainingDataRef }">
        <td :colspan="headers.length" style="padding: 0px;">
          <v-data-table
            :headers="trainingHeaders"
            :items="trainingDataRef.tableData"
            class="elevation-1"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:item.actions="{}">
              <router-link
                style="width: 20%;"
                :to="{
                  name: 'XSEEDAcademyQuestions',
                  params: { trainingId: trainingDataRef.id },
                }"
              >
                <v-btn
                  depressed
                  style="
                    float: right;
                    height: 40px;
                    letter-spacing: 0px;
                    background-color: #f7fbfd;
                    border: 1px solid #8090a3;
                  "
                >
                  Author
                </v-btn>
              </router-link>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: 'TrainingDetailsTable',
  data: () => {
    return {};
  },
  props: {
    trainings: {
      type: Array,
      required: true,
    },
    searchText: {
      type: String,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    trainingHeaders: {
      type: Array,
      required: true,
    },
    classNames: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.x-grey-background {
  background-color: #f7f9fc;
}
</style>
