class LPParser {
  constructor(iframeDoc) {
    this.document = iframeDoc;
  }

  parseHtml() {
    var lpTitle = this.valuefromElement(this.document.getElementsByClassName('LPT')[0]);
    var time = '35 mins';

    var lpData = {
      image_url: '',
      time: time,
      keywords: this.populateKeyWordsArray(),
      assessment: new AssessmentBlock(this).blockHash(),
      aim: new AimBlock(this).blockhash(),
      action: new ActionBlock(this).blockHash(),
      analysis: new AnalysisBlock(this).blockHash(),
      application: new ApplicationBlock(this).blockHash(),
      references: []
    };

    var response = {
      name: lpTitle,
      data: lpData
    };

    return response;
  }

  populateKeyWordsArray() {
    var keys = this.document.getElementsByClassName('KeyTerm');
    var values = this.getElementFromTag('KeyDef');
    var i = 0,
      resultArray = [];

    if (keys.length == values.length) {
      for (i = 0; i < keys.length; i++) {
        resultArray.push({
          keyword: this.valuefromElement(keys[i]),
          description: this.valuefromElement(values[i])
        });
      }
    } else {
      console.log('Keywords count miss-match');
    }

    return resultArray;
  }

  getValueFromTag(key, tag = 'sdttag') {
    var res = this.document.querySelectorAll('[' + tag + '=' + key + ']');
    return res.length == 0 ? 'NA' : res.length == 1 ? this.valuefromElement(res[0]) : res;
  }

  getElementFromTag(key, tag = 'sdttag') {
    return this.document.querySelectorAll('[' + tag + '=' + key + ']');
  }

  valuefromElement(element) {
    if (element) {
      return !this.isString(element)
        ? element.textContent.replace('\n', '').trim()
        : element.replace('\n', '').trim();
    }

    return '';
  }

  getFirstValue(elements) {
    if (!this.isString(elements) && elements.length > 0) {
      return this.valuefromElement(elements[0]);
    } else if (this.isString(elements)) {
      return elements;
    }

    return '';
  }

  getLastValue(elements) {
    if (!this.isString(elements) && elements.length > 0) {
      return this.valuefromElement(elements[elements.length - 1]);
    } else if (this.isString(elements)) {
      return elements;
    }

    return '';
  }

  isString(data) {
    return typeof data == 'string';
  }
}

// Analysis block

class AnalysisBlock {
  constructor(parser) {
    this.parser = parser;
  }

  blockHash() {
    var resultHash = {};
    resultHash['summary'] = this.parser.getValueFromTag('sANAp');
    resultHash['audio'] = '';
    resultHash['time'] =
      this.parser.getFirstValue(this.parser.getValueFromTag('ANAtime')) + ' mins';
    resultHash['type'] = ''; //TODO
    resultHash['activity_discussion'] = this._populateAnalysisDiscussionHash(0);
    resultHash['generalized_discussion'] = this._populateAnalysisDiscussionHash(1);
    resultHash['higher_order_thinking'] = this._populateAnalysisDiscussionHash(2);

    return resultHash;
  }

  _populateAnalysisDiscussionHash(index) {
    var resultHash = {};
    var summaryArray = this._getInstructionSummaryHash(index);
    var concludeArray = this._getDirectSpeechHash(index);

    resultHash['key_question'] = this.parser.valuefromElement(
      this.parser.getValueFromTag('keyqANAp')[index]
    );
    resultHash['instructions'] = summaryArray.concat(concludeArray);
    resultHash['teacher_tip'] = ''; //TODO

    return resultHash;
  }

  _getDirectSpeechHash(itemNumber) {
    return [
      {
        description: this.parser.valuefromElement(
          this.parser.getElementFromTag('dANAp')[itemNumber]
        )
      }
    ];
  }

  _getInstructionSummaryHash(itemNumber) {
    var resultArray = [];
    var summary = this.parser.getElementFromTag('ANAp')[itemNumber];
    if (summary) {
      var summaryArray = summary.getElementsByTagName('b');
      var summaryText = this.parser.valuefromElement(summary);
      var last_index = null,
        index = 0;

      if (summaryArray.length > 0) {
        for (var ele of summaryArray) {
          resultArray.push({
            description: '###'
          });

          if (last_index != null && index > 0) {
            resultArray[index - 1]['description'] = summaryText
              .substr(last_index, summaryText.indexOf(ele.textContent) - last_index - 1)
              .replace('\n', '')
              .trim();
          }

          last_index = summaryText.indexOf(ele.textContent) + ele.textContent.length;
          index++;
        }

        // Set description for last summary
        resultArray[index - 1]['description'] = summaryText
          .substr(last_index, summaryText.length)
          .replace('\n', '')
          .trim();
      }
    }

    return resultArray;
  }
}

// Action block

class ActionBlock {
  constructor(parser) {
    this.parser = parser;
  }
  blockHash() {
    var resultHash = {};
    var teacherTip =
      this.parser.getValueFromTag('tpACTp') == 'NA'
        ? ''
        : this.parser.getValueFromTag('tpACTp') + this.parser.getValueFromTag('dtpACTp') == 'NA'
        ? ''
        : this.parser.getValueFromTag('dtpACTp');

    resultHash['summary'] = this.parser.getValueFromTag('sACTp');
    resultHash['audio'] = '';
    resultHash['time'] =
      this.parser.getFirstValue(this.parser.getValueFromTag('ACTtime')) + ' mins';
    resultHash['type'] = ''; //TODO
    resultHash['multimedia'] = {
      type: 'video',
      url: ''
    };

    resultHash['instructions'] = this._populateActionInstuctionArray();
    resultHash['teacher_tip'] = teacherTip;

    return resultHash;
  }

  _populateActionInstuctionArray() {
    var resultArray = [];
    var dInstructionHead = this.parser.getElementFromTag('dACTh');
    var dInstructionText = this.parser.getElementFromTag('dACTp');
    var instructionText = this.parser.getElementFromTag('ACTp');

    // Direct speech Start
    for (var i = 0; i < dInstructionHead.length; i++) {
      resultArray.push({
        description: dInstructionText[i] ? this.parser.valuefromElement(dInstructionText[i]) : ''
      });
    }

    // Indirect speech Start
    for (var j = 0; j < instructionText.length; j++) {
      var instructionHeadText = this.parser.valuefromElement(instructionText[j].firstElementChild);
      resultArray.push({
        description: this.parser
          .valuefromElement(instructionText[j])
          .replace(instructionHeadText, '')
          .trim()
      });
    }

    return resultArray;
  }
}

// Application block

class ApplicationBlock {
  constructor(parser) {
    this.parser = parser;
  }
  blockHash() {
    var resultHash = {};
    var morePracticeData = this._populateMorePracticeArray();

    resultHash['summary'] =
      '##Classwork:## ' +
      this.parser.getFirstValue(this.parser.getElementFromTag('cAPPp')) +
      '\n##Homework:## ' +
      this.parser.getFirstValue(this.parser.getElementFromTag('hwAPPp'));
    resultHash['audio'] = '';
    resultHash['time'] =
      this.parser.getFirstValue(this.parser.getElementFromTag('APPtime')) + ' mins';
    resultHash['type'] = '';
    resultHash['classwork'] = {
      description: this.parser.getLastValue(this.parser.getElementFromTag('cAPPp')),
      image_url: '',
      time: 'NA mins'
    };
    resultHash['homework'] = {
      description: this.parser.getLastValue(this.parser.getElementFromTag('hwAPPp')),
      question_image_url: '',
      answer_image_url: ''
    };
    resultHash['differentiation_tips'] = {
      need_more_help: {
        description: this.parser.getFirstValue(this.parser.getElementFromTag('APPp'))
      },
      need_extra_challenge: {
        description: this.parser.getLastValue(this.parser.getElementFromTag('APPp'))
      }
    };

    if (!this.parser.isString(morePracticeData) && morePracticeData.length > 0) {
      resultHash['more_practice'] = morePracticeData;
      resultHash['more_practice_time'] = 'NA mins';
    }

    return resultHash;
  }

  _populateMorePracticeArray() {
    var resultArray = [];
    var morePracticeHead = this.parser.getElementFromTag('mpdAPPh');
    var morePracticeText = this.parser.getElementFromTag('mpdAPPp');

    for (var i = 0; i < morePracticeHead.length; i++) {
      resultArray.push({
        title: this.parser.valuefromElement(morePracticeHead[i]),
        description: morePracticeText[i] ? this.parser.valuefromElement(morePracticeText[i]) : ''
      });
    }

    return resultArray;
  }
}

// Asssessment Block

class AssessmentBlock {
  constructor(parser) {
    this.parser = parser;
  }

  blockHash() {
    var resultHash = {};

    resultHash['summary'] =
      '##A:## ' +
      this.parser.getValueFromTag('saASSp') +
      '\n##M:## ' +
      this.parser.getValueFromTag('smASSp') +
      '\n##E:## ' +
      this.parser.getValueFromTag('seASSp');
    resultHash['audio'] = '';
    resultHash['check'] = {
      description: this.parser.getValueFromTag('ASSp'),
      question_image_url: '',
      answer_image_url: ''
    };
    resultHash['grade_template'] = {
      approaching: {
        description: this.parser.getValueFromTag('aASSp')
      },
      meeting: {
        description: this.parser.getValueFromTag('mASSp')
      },
      exceeding: {
        description: this.parser.getValueFromTag('eASSp')
      }
    };

    return resultHash;
  }
}

// Aim Block

class AimBlock {
  constructor(parser) {
    this.parser = parser;
  }

  blockhash() {
    var resultHash = {},
      finalResources = [];
    resultHash['summary'] = this.parser.getValueFromTag('sAIMp');
    resultHash['audio'] = '';
    resultHash['description'] = this.parser.getValueFromTag('AIMp');

    finalResources = this._getResourceArray();
    finalResources = finalResources.concat(this._getResourceArray('rITEMp'));
    resultHash['resources'] = finalResources;

    return resultHash;
  }

  _getResourceArray(nameTag = 'rAIMp') {
    var resultArray = [],
      i = 0,
      resources = [];
    resources = this.parser.getElementFromTag(nameTag);

    for (i = 0; i < resources.length; i++) {
      resultArray.push({
        text: this.parser.valuefromElement(resources[i]),
        image_url: ''
      });
    }
    return resultArray;
  }
}

export default LPParser;
