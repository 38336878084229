<template>
  <v-card class="pa-4 rounded-lg">
    <v-row>
      <v-col>
        <SubSection title="Filter" />
        <v-select
          label="Question Purpose"
          item-text="label"
          item-value="value"
          v-model="filteredItems"
          :items="questionpurposeItems"
          @change="(value) => $emit('onQuestionPurposeChange', value)"
          multiple
          dense
          outlined
        />
        <v-select
          label="Lesson Plan"
          item-text="label"
          item-value="value"
          v-model="selectedLessonPlanItems"
          :items="lessonPlanItems"
          @change="(value) => $emit('onLessonPlanFilterChange', value)"
          multiple
          dense
          outlined
        />
        <v-select
          label="Difficulty Level"
          item-text="label"
          item-value="value"
          v-model="selectedDifficultyLevelItems"
          :items="difficultyLevelItems"
          @change="(value) => $emit('onDifficultyLevelFilterChange', value)"
          multiple
          dense
          outlined
        />
      </v-col>
      <v-col>
        <SubSection title="View" />
        <v-select
          placeholder="Question Purpose"
          item-text="label"
          item-value="value"
          v-model="view"
          :items="item"
          @change="(view) => $emit('onViewChange', view)"
          dense
          outlined
        />
      </v-col>
    </v-row>
    <SubSection title="Fields to inclue in download" />
    <v-row>
      <v-col>
        <v-checkbox
          v-model="questionPropertiesToIncludeInDownload.academicYear"
          label="Academic Year"
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="questionPropertiesToIncludeInDownload.questionPurpose"
          label="Question Purpose"
        />
      </v-col>
      <v-col>
        <v-checkbox v-model="questionPropertiesToIncludeInDownload.time" label="Time" />
      </v-col>
      <v-col>
        <v-checkbox v-model="questionPropertiesToIncludeInDownload.skill" label="Skill" />
      </v-col>
      <v-col>
        <v-checkbox v-model="questionPropertiesToIncludeInDownload.subSkill" label="Sub Skill" />
      </v-col>
      <v-col>
        <v-checkbox v-model="questionPropertiesToIncludeInDownload.difficulty" label="Difficulty" />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="questionPropertiesToIncludeInDownload.languageSkill"
          label="Language"
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="questionPropertiesToIncludeInDownload.lessonPlan"
          label="Lesson Plan"
        />
      </v-col>
    </v-row>
    <SubSection title="File type to download" />
    <v-row class="flex" style="align-items: baseline;">
      <v-col>
        <v-select
          placeholder="Choose the File Type"
          item-text="label"
          item-value="value"
          :items="fileTypeList"
          v-model="fileType"
          @change="(fileType) => $emit('onChangeFileType', fileType)"
          dense
          outlined
        />
      </v-col>
      <v-col>
        <v-btn
          :disabled="isReadOnly"
          color="primary"
          class="mr-8 ml-3"
          style="font-size: 18px; height: 40px;"
          @click="downloadQuestions"
          >Download Questions</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SubSection from '@/components/inputs/SubSection.vue';
export default {
  name: 'QuestionFilter',
  components: {
    SubSection,
  },
  data() {
    return {
      filteredItems: [],
      item: [
        {
          label: 'Detailed View',
          value: 'detailedView',
        },
        {
          label: 'List View',
          value: 'listView',
        },
      ],
      fileTypeList: [
        {
          label: 'HTML',
          value: 'html',
        },
        {
          label: 'PDF',
          value: 'pdf',
        },
      ],
      view: 'detailedView',
      fileType: 'pdf',
    };
  },
  watch: {
    selectedQuestionpurposeItems(val) {
      this.filteredItems = val;
    },
  },
  props: {
    questionpurposeItems: {
      trype: Array,
      default: () => {
        return [];
      },
    },
    selectedQuestionpurposeItems: {
      trype: Array,
      default: () => {
        return [];
      },
    },
    lessonPlanItems: {
      trype: Array,
      default: () => {
        return [];
      },
    },
    selectedLessonPlanItems: {
      trype: Array,
      default: () => {
        return [];
      },
    },
    difficultyLevelItems: {
      trype: Array,
      default: () => {
        return [];
      },
    },
    selectedDifficultyLevelItems: {
      trype: Array,
      default: () => {
        return [];
      },
    },
    questionPropertiesToIncludeInDownload: {
      type: Object,
      required: true,
    },
    downloadQuestions: {
      type: Function,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
};
</script>

<style></style>
