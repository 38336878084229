<template>
  <div>
    <v-app>
      <div>
        <router-view />
      </div>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>
