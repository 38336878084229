<template>
    <v-row style="width: 100vw">
        <v-col>
            <v-row>
                <v-col cols="10">
                    <v-toolbar-title class="pa-0 font-weight-bold">{{title}}</v-toolbar-title>
                    <v-subheader class="sub-title pl-0">{{subTitle}}</v-subheader>
                </v-col>
                <v-btn class="btn-color-green" @click="buttonActionHandler">{{ actionButtonText }}</v-btn>
            </v-row>
            <v-row class="d-flex align-center mt-n10 border-bottom">
                <template>
                    <v-col cols="9">
                        <v-tabs color="#007AFF" slider-color="#007AFF" background-color="transparent darken-3"
                                show-arrows>
                            <v-tab
                                    v-for="tab in tabs"
                                    :key="tab._id"
                                    class="nav-tabs"
                                    @click="filterChangedActionHandler(tab)"
                            >{{ tab.name }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col cols="2" class="pt-10">
                        <v-text-field  v-show="showSearch" type="text" v-model="search" @keyup="searchChangedHandler"
                                      append-icon="mdi-magnify"
                                      outlined
                                      clearable
                                      :placeholder="placeholderText">
                        </v-text-field>
                    </v-col>
                </template>
            </v-row>
        </v-col>

    </v-row>
</template>

<script>
  export default {
    name: "HorizontalFilterNav",
    data() {
      return {
        search: ""
      };
    },
    methods: {
      filterChangedActionHandler(tab) {
        this.$emit("filterChangedAction", tab);
      },
      searchChangedHandler() {
        this.$emit("searchChangedHandler", this.search);
      },
      buttonActionHandler() {
        this.$emit("buttonActionHandler");
      }
    },
    computed: {},
    props: {
      tabs: {
        type: Array,
        required: true
      },
      filerText: {
        type: String,
        required: true
      },
      actionButtonText: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      subTitle: {
        type: String,
        required: true
      },
      showSearch: {
        type: Boolean,
        default: false,
        required: true
      },
      placeholderText: {
        type: String,
        required: true
      }
    }
  };
</script>

<style scoped lang="scss">
    .btn-color-green {
        background-color: #1c9d5a !important;
        color: white;
        border-radius: 2px;
    }

    .border-bottom {
        border-bottom: 1px black !important;
    }

</style>
