export const SUBJECT_SELECTION_MUTATION = {
  SET_SELECTED_BOARD: (state, data) => {
    state.selectedBoard = data;
  },
  SET_SELECTED_SUBJECT: (state, data) => {
    state.selectedSubject = data;
  },
  SET_SELECTED_GRADE: (state, data) => {
    state.selectedGrade = data;
  },
  SET_SELECTED_PRODUCT: (state, data) => {
    state.selectedProduct = data;
  }
};
