<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span class="font-familt-label-wrapper" style="width: 130px;" v-bind="attrs" v-on="on">
        <span class="font-familt-label">
          {{ getFontName(value) || 'Select Font' }}
        </span>
        <img style="float: right; margin-top: 7px;" height="6" src="@/assets/RTE/dropdown.svg" />
      </span>
    </template>
    <v-list style="background: #eee;">
      <div v-for="(item, index) in fontsToList" :key="index">
        <v-list-item @click="onSelect(item.value)">
          <v-list-item-title :style="'font-family: ' + item.value + ';'">
            {{ getFontName(item.label) }}
          </v-list-item-title>
        </v-list-item>
        <v-divider v-if="index < fontFaces.length - 1" />
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FontFamilyPicker',
  data: () => ({
    fontFaces: [
      {
        label: 'Merriweather',
        value: 'Merriweather'
      },
      {
        label: 'OpenSans',
        value: 'OpenSans'
      },
      {
        label: 'Roboto',
        value: 'Roboto'
      },
      {
        label: 'RobotoSlab',
        value: 'RobotoSlab'
      },
      {
        label: 'SourceSansPro',
        value: 'SourceSansPro'
      },
      {
        label: 'ChanakyaXUniN',
        value: 'ChanakyaXUniN'
      },
      {
        label: 'ChanakyaXDottedUniN',
        value: 'ChanakyaXDottedUniN'
      },
      {
        label: 'ChanakyaXDUniN',
        value: 'ChanakyaXDUniN'
      },
      {
        label: 'ChanakyaXUniB',
        value: 'ChanakyaXUniB'
      },
      {
        label: 'ChanakyaXUniBI',
        value: 'ChanakyaXUniBI'
      },
      {
        label: 'ChanakyaXUniI',
        value: 'ChanakyaXUniI'
      },
    ],
    hindiFontFace: [
      {
        label: 'ChanakyaXUniN',
        value: 'ChanakyaXUniN'
      },
      {
        label: 'ChanakyaXDottedUniN',
        value: 'ChanakyaXDottedUniN'
      },
      {
        label: 'ChanakyaXDUniN',
        value: 'ChanakyaXDUniN'
      },
      {
        label: 'ChanakyaXUniB',
        value: 'ChanakyaXUniB'
      },
      {
        label: 'ChanakyaXUniBI',
        value: 'ChanakyaXUniBI'
      },
      {
        label: 'ChanakyaXUniI',
        value: 'ChanakyaXUniI'
      },
    ]
  }),
  methods: {
    getFontName(fontValue) {
      if (fontValue) {
        return fontValue.split(',')[0].trim();
      }
      return null;
    }
  },
  computed:{
    isHindi() {
     return this.subjectName.toLowerCase() == 'hindi';
    },
    fontsToList(){
    return this.isHindi ? this.hindiFontFace: this.fontFaces
    }
  },
  props: {
    onSelect: {
      type: Function,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    subjectName: {
      type: String,
    }
  }
};
</script>
<style scoped>
.font-familt-label-wrapper {
  display: inline-block;
  font-size: 0.8em;
  padding: 0 12px;
  padding-top: 7px;
}
.v-menu__content {
  left: 48px !important;
  margin-top: 15px;
}
</style>
