<template>
  <v-form ref="analyseSectionForm" class="section-title-cnr">
    <DiscussionExplanation
      title="Sorting by Sound"
      diffKey="sort_by_sound"
      sectionKey="sort_by_sound"
      :instructions="lessonPlanData.analysis.sort_by_sound.instructions"
      :diff="analysisDiff"
      instructionsIdentifier="analysis.sort_by_sound"
      :canShowDiffIcon="canShowDiffIcon"
      keyQuestionFieldIdentifier="analysis.sort_by_sound.key_question"
      keyQuestionGuideFieldIdentifier="analysis.sort_by_sound.key_question_guide"
      :schema="schema"
      :canAppproveAsset="canAppproveAsset"
      :addStep="addPhonicsAnalysisStep"
      :deleteStep="deletePhonicsAnalysisStep"
      :addSubStep="addPhonicsAnalysisSubStep"
      :deleteSubStep="deletePhonicsAnalysisSubStep"
      :toggleTeacherTipField="togglePhonicsAnalysisInstructionTeacherTipField"
      :lessonPlanData="lessonPlanData"
      :staticText="staticTexts.sort_by_sound.prefix"
      instructionsTitle="Explanation"
      :isAssetUploadRequired="isAssetUploadRequired"
      :analysisSignedUrls="analysisSignedUrls.sort_by_sound"
      class="mgn-Ts"
    />
    <DiscussionExplanation
      title="Sound and Spelling Rule"
      diffKey="sort_by_spelling"
      sectionKey="sort_by_spelling"
      :instructions="lessonPlanData.analysis.sort_by_spelling.instructions"
      :diff="analysisDiff"
      instructionsIdentifier="analysis.sort_by_spelling"
      :canShowDiffIcon="canShowDiffIcon"
      keyQuestionFieldIdentifier="analysis.sort_by_spelling.key_question"
      keyQuestionGuideFieldIdentifier="analysis.sort_by_spelling.key_question_guide"
      :schema="schema"
      :canAppproveAsset="canAppproveAsset"
      :addStep="addPhonicsAnalysisStep"
      :deleteStep="deletePhonicsAnalysisStep"
      :addSubStep="addPhonicsAnalysisSubStep"
      :deleteSubStep="deletePhonicsAnalysisSubStep"
      :toggleTeacherTipField="togglePhonicsAnalysisInstructionTeacherTipField"
      :lessonPlanData="lessonPlanData"
      :staticText="staticTexts.sort_by_spelling.prefix"
      instructionsTitle="Explanation"
      :isAssetUploadRequired="isAssetUploadRequired"
      :analysisSignedUrls="analysisSignedUrls.sort_by_spelling"
      class="mgn-Ts"
    />
    <RTEWithMultipleAssetUpload
      :data="lessonPlanData.analysis.listen_and_read"
      :isReadOnly="!isNotReadOnly"
      :isAssetUploadRequired="isAssetUploadRequired"
      :canShowDiffIcon="canShowDiffIcon"
      placeholder="Question Teacher Tip"
      :diffChanges="analysisDiff.listen_and_read || {}"
      :assetSignedUrlsHash="(analysisSignedUrls.listen_and_read || {}).assets || []"
      :showImageBrief="showImageBrief"
      :fileValidators="fileValidators"
      :assetDiffIdentifierPrefix="`analysis.listen_and_read.assets[<assetIndex>]`"
      :descriptionDiffIdentifier="`analysis.listen_and_read.description`"
      :sectionIdentifier="`analysis.listen_and_read`"
      :requireDeleteAsset="true"
      :requireAddAsset="true"
      title="Listen to and Read the Story"
      :stepIndex="0"
      :isAssetMandatory="isAssetUploadRequired"
      @deleteBlock="() => {}"
      :requireDeleteBlock="false"
      :descriptionMaxLength="schema.teacherTipsDescriptionMaxLength"
      :canAppproveAsset="canAppproveAsset"
      :canFinalizeAsset="true"
    />
    <XSDisplayCard :compact="true" :title="AppTexts.TEACHER_TIP">
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="true"
        :fieldIdentifier="`analysis.listen_and_read.teacher_tip`"
        :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
        v-model="lessonPlanData.analysis.listen_and_read.teacher_tip"
        :counter="schema.discussionTeacherTipMaxLength"
        :readonly="!isNotReadOnly"
        :includeOnlyToolBarActions="BASIC_TOOLS"
        class="mgn-Bm"
      />
      <br />
    </XSDisplayCard>
  </v-form>
</template>

<script>
import DiscussionExplanation from '@/components/lessonPlanTemplate/phonics/DiscussionExplanation.vue';
import RTEWithMultipleAssetUpload from '@/components/inputs/RTEWithMultipleAssetUpload.vue';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard.vue';
import { mapActions } from 'vuex';
import AppTexts from '@/constants/AppTexts';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';

export default {
  name: 'AnalyseSection',
  components: {
    DiscussionExplanation,
    RTEWithMultipleAssetUpload,
    RichTextEditor,
    XSDisplayCard,
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    analysisSignedUrls: {
      type: Object,
      required: true,
    },
    analysisDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    staticTexts: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    canAppproveAsset: { type: Boolean, required: true },
  },
  data() {
    return {
      AppTexts,
      BASIC_TOOLS,
    };
  },
  methods: {
    ...mapActions([
      'addPhonicsAnalysisStep',
      'deletePhonicsAnalysisStep',
      'addPhonicsAnalysisSubStep',
      'deletePhonicsAnalysisSubStep',
      'togglePhonicsAnalysisInstructionTeacherTipField',
    ]),
  },
};
</script>
