import { baseMixin } from '../base';
import { PROJECT_TYPE_DAILY_PLAN } from '@/constants/Project';
import { DP_TIMES_HASH } from '@/constants/common';
import AppTexts from '@/constants/AppTexts';
import { mapState } from 'vuex';
import _ from 'lodash';
import { getAcceptableFileType, validateAssetAuthorized } from '@/utils';

export const baseEarlyProgramMixin = {
  mixins: [baseMixin],
  data() {
    return {
      navigateTo: '',
      showSaveDialogPopup: false,
      projectType: PROJECT_TYPE_DAILY_PLAN,
      Schema: {
        canEditTitle: false,
        canEditDescription: false,
        types: ['individual', 'pair', 'group', 'class', 'circle'],
        aim: {
          maxKeywordsLength: 12,
          keyWordDefinitionOptional: true,
          label: {
            resourcesTitle: AppTexts.THINGS_WE_WILL_USE,
            keywordsTitle: AppTexts.WORD_WALL,
          },
          summaryMaxLength: 600,
          descriptionMaxLength: 600,
          keywordTextMaxLength: 600,
          keywordDescriptionMaxLength: 600,
          resourceNameMaxLength: 600,
          resourceMaxLength: 10,
          isSummaryOptional: true,
        },
        action: {
          summaryMaxLength: 600,
          stepDescriptionMaxLength: 600,
          subStepDescriptionMaxLength: 600,
          stepTeacherTipMaxLength: 1000, // todo
          teacherTipMaxLength: 1000,
          isSummaryOptional: true,
        },
        analysis: {
          showUniversalDiscussion: true,
          summaryMaxLength: 600,
          discussionKeyQuestionMaxLength: 600,
          discussionKeyQuestionGuideMaxLength: 600,
          discussionStepDescriptionMaxLength: 600,
          discussionSubStepDescriptionMaxLength: 600,
          discussionStepTeacherTipMaxLength: 1000, // todo
          discussionTeacherTipMaxLength: 1000,
          isKeyQuestionGuideMandatory: false,
          isSummaryOptional: true,
        },
        application: {
          morePractice: false,
          isHomeworkOptional: true,
          classworkSummaryMaxLength: 600,
          homeworkSummaryMaxLength: 600,
          classworkDescriptionMaxLength: undefined,
          homeworkDescriptionMaxLength: 1000,
          neeMoreHelpDescriptionMaxLength: 600,
          needExtraChallengeDescriptionMaxLength: 600,
          isNeedMoreHelpMandatory: false,
          isNeedExtraChallengeMandatory: false,
          showClassworkTeacherTip: true,
          classworkTeacherTipMaxLength: 1000,
          showHomeworkTeacherTip: false,
          isSummaryOptional: true
        },
        assessment: {
          approachingSummaryMaxLength: 600,
          meetingSummaryMaxLength: 600,
          exceedingSummaryMaxLength: 600,
          overallSummaryMaxLength: 600,
          approachingDescriptionMaxLength: 600,
          meetingDescriptionMaxLength: 600,
          exceedingDescriptionMaxLength: 600,
          overallDescriptionMaxLength: 600,
          isSummaryOptional: true
        },
        reference: {
          referenceTextMaxLength: 600,
        },
      },
    };
  },
  computed: {
    isCircleTime() {
      return this.$route.query.lpTimeKey == DP_TIMES_HASH.CIRCLE_TIME;
    },
    isDramaTime() {
      return this.$route.query.lpTimeKey == DP_TIMES_HASH.DRAMA_TIME;
    },
    canShowEvaluateAndGrade() {
      const isWorksheetEnabled = JSON.parse(this.$route.query.worksheetEnabled) == true;
      return isWorksheetEnabled;
    },
    ...mapState({
      staticTexts: (state) => state.lessonPlan.staticTexts,
    }),
    breadCrumbText() {
      let text = `Home > Block <a href=javascript:history.back()>- ${this.$route.query.blockTitle} (${this.$route.query.bId})</a>`;

      if (
        this.lpCurrentStageDataDailyPlanFromVuex &&
        this.lpCurrentStageDataDailyPlanFromVuex.name
      ) {
        return `${text} > ${this.lpCurrentStageDataDailyPlanFromVuex.name}`;
      }

      return text;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isSaved) {
      this.isSaved = _.isEqual(this.lessonPlan.data, this.lessonPlanDataCopy);
    }
    if (this.isSaved) {
      next();
    } else {
      this.showSaveDialogPopup = true;
      this.navigateTo = to;
    }
  },
  watch: {
    staticTexts: {
      immediate: true,
      handler(val) {
        if (val) {
          if (val.analysis) {
            this.Schema.analysis.prefix = val.analysis.prefix;
          }
          if (val.keywords) this.Schema.aim.label.keywordsSubTitle = val.keywords.prefix;
        }
      },
    },
    lessonPlanData: {
      immediate: true,
      deep: true,
      handler() {
        const isWorksheetEnabled = JSON.parse(this.$route.query.worksheetEnabled) == true;

        if (isWorksheetEnabled) {
          this.Schema.isWorksheetEnabled = true;

          if (this.lessonPlan.data.assessment) {
            if (this.lessonPlan.data.assessment.summary) {
              // Don nothing
            } else {
              this.lessonPlan.data.assessment.summary = '';
            }

            for (const iterator of ['approaching', 'meeting', 'exceeding']) {
              if (this.lessonPlan.data.assessment.grade_template[iterator]) {
                // Don nothing
              } else {
                this.$set(this.lessonPlan.data.assessment.grade_template, iterator, {
                  description: '',
                });
              }
            }
          }

          if (this.lessonPlan.data.application) {
            if (this.lessonPlan.data.application.differentiation_tips) {
              // Don nothing
            } else {
              this.$set(this.lessonPlan.data.application, 'differentiation_tips', {});
            }

            for (const iterator1 of ['need_more_help', 'need_extra_challenge']) {
              if (this.lessonPlan.data.application.differentiation_tips[iterator1]) {
                // Don nothing
              } else {
                this.$set(this.lessonPlan.data.application.differentiation_tips, iterator1, {
                  description: '',
                });
              }
            }
          }
        } else {
          if (this.lessonPlan.data.assessment) {
            if (this.lessonPlan.data.assessment.overallSummary) {
              // Don nothing
            } else {
              this.$set(this.lessonPlan.data.assessment, 'overallSummary', '');
            }

            if (this.lessonPlan.data.assessment.grade_template.overall) {
              // Don nothing
            } else {
              this.$set(this.lessonPlan.data.assessment.grade_template, 'overall', {
                description: '',
              });
            }
          }
        }
      },
    },
  },
  methods: {
    getAcceptableFileType,
    redirectToPreviousPage() {
      window.location.href = `/projects/${this.$route.params.projectId}/dailyPlans/${this.$route.params.dailyPlanLibraryId}`;
    },
    proceedNavigationBasedOnChoice(choice) {
      if (choice == 'yes') {
        this.saveForm(false);
      }
      if (this.navigateTo && this.navigateTo != '') {
        this.showSaveDialogPopup = false;
        this.isSaved = true;
        this.$router.push({ path: this.navigateTo.path });
      }
    },
    setRedirectRoute() {
      this.redirectionPathObject = {
        name: 'dailyPlanEditPage',
        params: {
          dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
          projectId: this.$route.params.projectId,
        },
      };
    },
    validateLPForm() {
      this.reassignFormInputs(this.$refs.form);
      if (
        this.$refs.form.validate() &&
        this.customValidations() &&
        this.validateAssetAuthoringStagingValidations() &&
        this.validateRTEText()
      ) {
        return true;
      } else {
        return false;
      }
    },
    customValidations() {
      try {
        let lp = this.lessonPlan;

        if (!lp) throw Error('Invalid LessonPlan');
        if (!lp.data) throw Error('Invalid LessonPlan Data');

        // time validation - start
        const getMinutesFromTimeString = (timeString) => {
          try {
            return +timeString.split(' ')[0];
          } catch (error) {
            return 0;
          }
        };

        let overallTime = lp.data ? getMinutesFromTimeString(lp.data.time) : 0;
        let actionTime =
          lp.data && lp.data.action ? getMinutesFromTimeString(lp.data.action.time) : 0;
        let analysisTime =
          lp.data && lp.data.analysis ? getMinutesFromTimeString(lp.data.analysis.time) : 0;
        let applicationTime =
          lp.data && lp.data.application ? getMinutesFromTimeString(lp.data.application.time) : 0;

        if (overallTime !== actionTime + analysisTime + applicationTime) {
          let errorMessage = 'Overall time of the LP is not equal to sum of individual step time';
          throw Error(errorMessage);
        }
        // time validation - start

        return true;
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    validateAssetAuthoringStagingValidations() {
      try {
        if (this.currentStage == 'assetAuthorizingStage') {
          let unApprovedAssetCount = 0;

          unApprovedAssetCount = validateAssetAuthorized(
            this.lessonPlan.data,
            ['assetId'],
            {
              assetId: 'assetApproved',
            },
            ['brief', 'placeholder'],
            0
          );
          if (unApprovedAssetCount > 0) {
            this.$notify({
              title: 'Failed',
              text: `There are ${unApprovedAssetCount} unapproved assets`,
              type: 'error',
              duration: -1,
            });

            return false;
          }

          return true;
        } else {
          return true;
        }
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    getUnApprovedAssetSCountForInstructions(items) {
      var unApprovedAssetsCount = 0;

      for (var item of items) {
        if (item.assets) {
          for (var asset of item.assets) {
            const isApproved = asset.url_approved == true;

            if (!isApproved) unApprovedAssetsCount += 1;
          }
        }
      }

      return unApprovedAssetsCount;
    },
    validateAssetAuthorized(_currentStageData, fieldIdentifier, approvedFlag, returned) {
      for (let key in _currentStageData) {
        if (typeof _currentStageData[key] == 'object') {
          returned = this.validateAssetAuthorized(
            _currentStageData[key],
            fieldIdentifier,
            approvedFlag,
            returned
          );
        } else {
          if (key == fieldIdentifier) {
            if (
              _currentStageData[fieldIdentifier] != '' &&
              (!_currentStageData[approvedFlag] || _currentStageData[approvedFlag] == false)
            ) {
              returned += 1;
            }
          }
        }
      }

      return returned;
    },
    prepareLessonPlanDataToSave() {
      // remove loadingStatus from resource object..
      let resources = [];
      this.lessonPlan.data.aim.resources.forEach((resource) => {
        delete resource.loadingStatus;
        resources.push(resource);
      });
      this.lessonPlan.data.aim.resources = resources;

      // set placeholder image for action instructions videos
      for (let ins of this.lessonPlan.data.action.instructions) {
        if (ins.assets && (ins.assets[0] || {}).type == 'video') {
          if (!ins.assets[0].placeholder) {
            ins.assets[0].placeholder = { assetId: '' };
          }
          ins.assets[0].placeholder = this.lessonPlan.data.assetIds;
        }
        let assets = [];

        for (let asset of ins.assets || []) {
          delete asset.loadingStatus;
          assets.push(asset);
        }
        ins.assets = assets;
      }

      for (let _discussionName of ['discussion']) {
        let instructionData = [];
        this.lessonPlan.data.analysis[_discussionName].instructions.forEach(function (
          _instruction
        ) {
          delete _instruction.loadingStatus;
          instructionData.push(_instruction);
        });

        this.lessonPlan.data.analysis[_discussionName].instructions = instructionData;
      }

      if (
        this.lessonPlan.data.application.more_practice &&
        this.lessonPlan.data.application.more_practice.length > 0
      ) {
        // set placeholder image for more_practice instructions videos
        for (let ins of this.lessonPlan.data.application.more_practice) {
          if (ins.assets && (ins.assets[0] || {}).type == 'video') {
            if (!ins.assets[0].placeholder) {
              ins.assets[0].placeholder = { assetId: '' };
            }
            ins.assets[0].placeholder = this.lessonPlan.data.assetId;
          }
          let assets = [];

          for (let asset of ins.assets || []) {
            delete asset.loadingStatus;
            assets.push(asset);
          }
          ins.assets = assets;
        }
      }
      try {
        this.lessonPlan.data.assessment.check.description = this.lessonPlan.data.application.classwork.description;
      } catch (e) {
        console.log(e);
      }
      try {
        this.lessonPlan.data.assessment.check.question_image_brief = this.lessonPlan.data.application.homework.question_image_brief;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
