<template>
  <v-card :class="`micro-topic-component-${columnType}-column`">
    <div>
      <!-- Delete Project Modal -->
      <v-dialog v-model="showLoader" max-width="290" persistent>
        <v-card>
          <v-card-title style="text-align: center; display: block;" class="headline">
            Deleting the project...
          </v-card-title>
          <v-card-text style="text-align: center;">
            <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- End Delete Project Modal -->
      <v-dialog v-model="deleteProjectConfirmationDialog" width="350">
        <v-card>
          <v-card-title class="headline">Remove Project?</v-card-title>
          <v-card-text
            >Are you sure you want to do this? This is an irreversible action</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="() => (deleteProjectConfirmationDialog = false)">Cancel</v-btn>
            <v-btn color="primary" @click="confirmProjectDeletion">Remove Project</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <Stepper
        :userData="assigneeData"
        :currentStepIndex="currentStageIndex"
        :headerText="type"
        :updateDateText="updatedDate"
        :showNames="true"
        :showOnlyLastUpdateTime="showOnlyLastUpdateTime"
      />
    </div>
    <div class="stages-wrapper">
      <div class="current-stage-content">
        <div>{{ currentStage }}</div>
        <div>{{ currentStageIndex + 1 }} of 11</div>
      </div>
    </div>
    <div class="responsibility-wrapper">
      <div class="responsibility-content">
        <div>{{ assignee }}</div>
        <div>{{ currentStage }}</div>
      </div>
    </div>
    <div class="action-button-wrapper" v-if="showViewDelete">
      <div class="action-button-content">
        <v-btn tile class="action-view-button" @click="viewActionHandler(id)">VIEW</v-btn><br />
        <a @click="initDeleteMicroTopicProject(id)" v-if="currentStage != 'Published'">Delete</a>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Stepper from '@/components/contentEntry/microTopic/Stepper';
import { LP_STAGES } from '@/constants/LessonPlan';

export default {
  name: 'MicroTopicSingleView',
  data() {
    return {
      deleteProjectConfirmationDialog: false,
      projectIdForDeletion: '',
    };
  },
  computed: {
    ...mapState({
      initialMicroTopicsListApiStatus: (state) => state.initialMicroTopicsListApiStatus,
    }),
    showLoader() {
      return this.initialMicroTopicsListApiStatus == 'in_progress';
    },
    currentStageIndex() {
      try {
        let _index = Object.keys(LP_STAGES).indexOf(this.projectData.contents[0].currentStage);

        return _index != -1 ? _index : 0;
      } catch (error) {
        console.log('error' + error);
      }
      return 0;
    },

    assigneeData() {
      let names = {};
      for (let role in this.projectData.assignees) {
        if (
          role != 'assetAuthorizer' &&
          (!this.projectData.assignees[role] || this.projectData.assignees[role].id === '')
        ) {
          names[role] = 'skipped';
        } else {
          names[role] = this.projectData.assignees[role].name;
        }
      }
      names['author2'] = names.author;
      return names;
    },
  },
  components: {
    Stepper,
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    currentStage: {
      type: String,
    },
    assignee: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    projectData: {
      type: Object,
    },
    updatedDate: {
      type: String,
      required: true,
    },
    showViewDelete: {
      type: Boolean,
      default: true,
    },
    columnType: {
      type: String,
      default: 'four',
    },
    showOnlyLastUpdateTime: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['deleteMicroTopicProject']),
    viewActionHandler(_id) {
      this.$emit('viewAction', _id);
    },
    initDeleteMicroTopicProject(_id) {
      this.deleteProjectConfirmationDialog = true;
      this.projectIdForDeletion = _id;
    },
    confirmProjectDeletion() {
      this.deleteProjectConfirmationDialog = false;
      this.deleteMicroTopicProject(this.projectIdForDeletion);
    },
  },
};
</script>
<style lang="scss">
.micro-topic-component-four-column {
  margin: 0 15px 20px 15px;
  background: #fff;
  display: flex;
  border-radius: 5px;
  > div:nth-child(1) {
    width: 39%;
  }
  .stages-wrapper {
    display: contents;
    .current-stage-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgb(0, 0, 0, 0.2);
      width: 17%;
      margin: 10px 0px;
      > :nth-child(2) {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
      }
    }
  }
  .responsibility-wrapper {
    display: contents;
    .responsibility-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgb(0, 0, 0, 0.2);
      width: 20%;
      margin: 10px 0px;
      > :nth-child(2) {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
      }
    }
  }
}

.micro-topic-component-three-column {
  margin: 0 15px 20px 0px;
  width: 100%;
  background: #fff;
  display: flex;
  border-radius: 5px;
  > div:nth-child(1) {
    width: 50%;
  }
  .stages-wrapper {
    display: contents;
    .current-stage-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgb(0, 0, 0, 0.2);
      width: 20%;
      margin: 10px 0px;
      > :nth-child(2) {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
      }
    }
  }
  .responsibility-wrapper {
    display: contents;
    .responsibility-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgb(0, 0, 0, 0.2);
      width: 30%;
      margin: 10px 0px;
      > :nth-child(2) {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
      }
    }
  }
}

.action-button-wrapper {
  display: contents;
  .action-button-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 278px;
    .action-view-button {
      background: #b5e9e4 !important;
      width: 200px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.edit-button {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
</style>
