<template>
  <div>
    <v-dialog v-model="showContentEntryLoadingDialog" max-width="290" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">{{
          loadingStatus.message ? loadingStatus.message : 'Brewing your coffee...'
        }}</v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showContentEntryStatusDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline" v-if="!loadingStatus.message && !isSaved">{{
          alertText
        }}</v-card-title>
        <v-card-text
          class="headline center pt-5"
          v-if="loadingStatus.message && loadingStatus.message != ''"
        >
          <b>
            {{ loadingStatus.message }}
          </b>
        </v-card-text>
        <v-card-text v-else-if="!isSaved">
          You have not saved your changes yet. Press YES to save your changes, press NO to continue
          without saving.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="proceedNavigationBasedOnChoice('no')"
            v-if="!loadingStatus.message && !isSaved"
            >NO</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="proceedNavigationBasedOnChoice('yes')"
            v-if="!loadingStatus.message && !isSaved"
            >YES</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="proceedNavigationBasedOnChoice('ok')"
            v-if="loadingStatus.message"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FormLayout
      :moveToNextStageButtonText="getNextStageButtonText()"
      :canMoveProjectLP="
        !contentEntryProjectFormAuth.canMoveProjectLP || currentStage == 'completedStage'
      "
      :breadCrumbText="breadCrumbText"
      :buttonActions="buttonActions"
      :auth="contentEntryProjectFormAuth"
      :onClickMoveToNextStage="onClickMoveToNextStage"
      :onClickCompleteAndPublish="onClickCompleteAndPublish"
      :isCompleted="currentStage == 'completedStage'"
      :isPublished="projectDetails.workflowStatus == 'completed'"
      :canPublishProject="contentEntryProjectFormAuth.canPublishProject"
      :onClickPublish="onClickPublishHandler"
      :rightSidePreviewContent="rightSidePreviewContent"
      :toggleRightSidePanel="toggleRightSidePanel"
      :showRightSidePanel="showRightSidePanel"
      :currentStage="currentStage"
      :loggedInUserEmail="loggedInUserEmail ? loggedInUserEmail : ''"
      :commentsHash="commentsHash"
      :diffOptions="{
        diffCloseHandler: diffCloseHandler,
        fieldDiff: fieldDiff,
        currentDiffIdentifier: currentDiffIdentifier,
      }"
      :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
      :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
      :closeComments="closeComments"
      :commentSectionIdentifier="commentSectionIdentifier"
      :onClickMoveToPreviousStage="onClickMoveToPreviousStage"
      :projectType="projectDetails.type"
      :commentSectionHeaderText="commentSectionHeaderText"
      :previewData="previewData"
      :isAdmin="isAdmin"
    >
      <div v-if="projectDetails && Object.keys(projectDetails).length" class="project-wrapper">
        <v-row class="flex-row">
          <v-col cols="6">
            <div class="lesson-plan-title bold">
              {{ projectDetails.name.toUpperCase() }} — {{ getProjectTypeText }}
            </div>
            <div>GRADE {{ projectDetails.standard.name }} - {{ projectDetails.subject.name }}</div>
            <div
              v-if="
                currentMicroTopic.microSkills &&
                currentMicroTopic.microSkills.Doing &&
                currentMicroTopic.microSkills.Doing != ''
              "
            >
              DOING- {{ currentMicroTopic.microSkills.Doing }}
            </div>
            <div
              v-if="
                currentMicroTopic.microSkills.Understanding &&
                currentMicroTopic.microSkills.Understanding != ''
              "
            >
              UNDERSTANDING- {{ currentMicroTopic.microSkills.Understanding }}
            </div>
          </v-col>
          <v-col cols="6">
            <Stepper
              :userData="assigneeData"
              :currentStepIndex="currentStageIndex"
              :showNames="false"
            />
          </v-col>
        </v-row>
        <v-form ref="form" class="left-hand-side-form">
          <MicroText
            :data="data"
            :fileChangeHandler="fileChangeHandler"
            v-if="projectDetails.type == 'microText'"
            :options="componentOptions"
            :toggleComments="toggleComments"
            :addToAsset="addToAsset"
            :canApproveAsset="canAppproveAsset"
            :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE()"
          />
          <Worksheet
            :data="data"
            :fileChangeHandler="fileChangeHandler"
            v-else-if="projectDetails.type == 'worksheet'"
            :options="componentOptions"
            :toggleComments="toggleComments"
            :addToAsset="addToAsset"
            :canApproveAsset="canAppproveAsset"
            :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE()"
          />
          <ContentBook
            v-else-if="projectDetails.type == 'contentBook'"
            :data="data"
            :fileChangeHandler="fileChangeHandler"
            :options="componentOptions"
            :toggleComments="toggleComments"
            :addToAsset="addToAsset"
            :canApproveAsset="canAppproveAsset"
            :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE()"
          />
          <Video
            :data="data"
            :fileChangeHandler="fileChangeHandler"
            :options="componentOptions"
            v-else-if="projectDetails.type == 'videos'"
            :toggleComments="toggleComments"
            :addToAsset="addToAsset"
            :canApproveAsset="canAppproveAsset"
            :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE()"
          />
          <oLessonPlan
            :data="data"
            :fileChangeHandler="fileChangeHandler"
            :options="componentOptions"
            :contentEntryAddKeyword="contentEntryAddKeyword"
            :contentEntryDeleteKeyword="contentEntryDeleteKeyword"
            :signedUrls="signedAssetUrls"
            :contentEntryAddResource="contentEntryAddResource"
            :contentEntryDeleteResource="contentEntryDeleteResource"
            v-else-if="projectDetails.type == 'oLesson'"
            :toggleComments="toggleComments"
            :addToAsset="addToAsset"
            :canAppproveAsset="canAppproveAsset"
            :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE()"
          />
          <Questions
            :data="data"
            @onQuestionDataChanged="onQuestionDataChanged"
            :saveForm="handleQuestionSave"
            :options="componentOptions"
            :canAppproveAsset="canAppproveAsset"
            :toggleComments="toggleComments"
            @question-preview-cliked="handler"
            :currentStage="currentStage"
            v-else-if="projectDetails.type == 'questions'"
          />
          <div v-else>Un handled project type</div>
        </v-form>
      </div>
    </FormLayout>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import AppTexts from '@/constants/AppTexts';
import Stepper from '@/components/contentEntry/microTopic/Stepper';
import FormLayout from '@/components/layouts/FormLayout';
import {
  LP_FORM_SUBMIT_TEXT,
  LP_STAGES,
  RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
} from '@/constants/LessonPlan';
import MicroText from '@/components/contentEntry/MicroText';
import Video from '@/components/contentEntry/Video';
import Worksheet from '@/components/contentEntry/Worksheet';
import ContentBook from '@/components/contentEntry/ContentBook';
import oLessonPlan from '@/components/contentEntry/oLessonPlan';
import Questions from '@/components/contentEntry/Questions';
import { getAssetUploadRequiredStatus, getAcceptableFileType } from '@/utils';
import Errors from '@/constants/Errors';
import _ from 'lodash';

export default {
  name: 'ContentEntryProjectForm',
  components: {
    FormLayout,
    MicroText,
    Video,
    Worksheet,
    ContentBook,
    oLessonPlan,
    Stepper,
    Questions,
  },
  data() {
    return {
      AppTexts,
      LP_FORM_SUBMIT_TEXT,
      LP_STAGES,
      RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
      data: {},
      isAssetUploadRequired: false,
      canShowImageBrief: false,
      canShowDiffIcon: false,
      fileAcceptType: '',
      commentSectionIdentifier: '',

      buttonActions: {
        saveForm: this.saveForm,
        validateContent: this.validateContent,
        redirectToPreviousPage: this.redirectToPreviousPage,
        downloadContentAssets: this.downloadContentAssets,
        activateContent: () => this.activateContent(),
      },
      Errors,
      currentStageIndex: '',
      loaderText: '',
      isSaved: false,
      savedDialogConfirmation: false,
      navigateTo: '',
      alertText: 'Alert',
      commentSectionHeaderText: '',
      questionPreviewData: {},
    };
  },
  created() {
    this.getContentEntryProjectDetails({
      projectId: this.$route.params.projectId,
    });
    this.getCurrentUserContentEntryProjectFormPermissions({
      projectId: this.$route.params.projectId,
      contentLibraryId: 0,
    });
    this.getMicroTopicDetails({
      microTopicId: this.$route.params.microTopicId,
    });
    this.getMicroTopicDetails({
      microTopicId: this.$route.params.microTopicId,
    });
    this.getCurrentUserAuthPermissions();
  },
  beforeRouteLeave(to, from, next) {
    this.alertText = 'Do you want to save changes?';
    if (!this.isSaved) {
      this.isSaved = _.isEqual(this.currentStageDataCopy, this.data);
    }
    if (this.componentOptions.isReadOnly == true || this.isSaved == true) {
      next();
    } else {
      this.isSaved = false;
      this.navigateTo = to;
      this.savedDialogConfirmation = true;
    }
  },
  beforeMount() {
    this.setRightSidePanelVisibility(false);
    this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
  },
  computed: {
    ...mapState({
      currentMicroTopic: (state) => state.currentMicroTopic,
      contentEntryProjectFormAuth: (state) => state.auth.contentEntryProjectForm,
      currentStage: (state) => state.contentEntry.contentData.currentStage,
      projectDetails: (state) => state.contentEntry.projectDetails,
      contentDetails: (state) => state.contentEntry.contentDetails,
      loadingStatus: (state) => state.contentEntry.loadingStatus,
      signedAssetUrls: (state) => state.contentEntry.currentStageAssetsSignedUrl,
      currentStageData: (state) => state.contentEntry.currentStageData,
      currentStageDataCopy: (state) => state.contentEntry.currentStageDataCopy,
      fileUploadingStatus: (state) => state.contentEntry.fileUploadingStatus,
      rightSidePreviewContent: (state) => state.rightSideContent.rightSidePreviewContent,
      showRightSidePanel: (state) => state.rightSideContent.showRightSidePanel,
      currentDiffIdentifier: (state) => state.rightSideContent.currentDiffIdentifier,
      fieldDiff: (state) => state.rightSideContent.fieldDiff,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      validationFlag: (state) => state.contentEntry.calledValidateFlag,
      rteValidator: (state) => state.common.rteValidator,
      isAdmin: (state) => state.auth.isAdmin,
    }),
    getToolBarActionsForRTE() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'fullScreen',
      ];
    },
    getProjectTypeText() {
      return _.startCase(this.projectDetails.type);
    },
    showContentEntryLoadingDialog() {
      return this.loadingStatus.status == 'in_progress';
    },
    assigneeData() {
      let names = {};
      if (this.currentMicroTopic.projects) {
        let projectData = this.currentMicroTopic.projects.find(
          (_) => _.id == this.projectDetails.id
        );

        for (let stage in projectData.assignees) {
          if (projectData.assignees[stage].id == null) {
            names[stage] = 'skipped';
          } else {
            names[stage] = projectData.assignees[stage].name;
          }
        }
        names['author2'] = names.author;
      }
      return names;
    },
    canAppproveAsset() {
      if (this.currentStage == 'assetAuthorizingStage') return true;
      return false;
    },

    showContentEntryStatusDialog() {
      return (
        (this.loadingStatus.status == 'completed' &&
          this.loadingStatus.message &&
          this.loadingStatus.message != '') ||
        this.savedDialogConfirmation
      );
    },
    breadCrumbText() {
      let isProjectDetailsLoaded = (Object.keys(this.projectDetails) || []).length;
      if (isProjectDetailsLoaded) {
        return `
 <div class="olesson-breadcrumb" style="font-size: 16px;"><a href="/micro_topics" style=" cursor:pointer;padding: 0 10px;color: rgba(0, 0, 0, 0.7);text-decoration: none;">Home </a> /<a href=javascript:history.back() style="padding: 0 10px; cursor: pointer;color: rgba(0, 0, 0, 0.7);text-decoration: none;"> Microtopic ${this.currentMicroTopic.mtId} </a> / <b>${this.projectDetails.name} (${this.getProjectTypeText})</b><div>`;
      } else {
        return 'Loading...';
      }
    },
    componentOptions() {
      return {
        isNotReadOnly: this.contentEntryProjectFormAuth.canSaveContent,
        isReadOnly: !this.contentEntryProjectFormAuth.canSaveContent,
        isAssetUploadRequired: this.isAssetUploadRequired,
        canShowDiffIcon: this.canShowDiffIcon,
        fileAcceptType: this.fileAcceptType,
        fileUploadingStatus: this.fileUploadingStatus,
        signedAssetUrls: this.signedAssetUrls,
        errorOfRequired: this.Errors.REQUIRED_FIELD,
        validationFlag: this.validationFlag,
      };
    },
    previewData() {
      if (this.projectDetails.type == 'questions' && this.questionPreviewData) {
        return this.questionPreviewData;
      }
      if (this.projectDetails.type != null) {
        return {
          data: this.data,
          previewMicroTopicDetails: {
            microTopicTitle: this.projectDetails.name,
            microTopicSequence: 0,
            courseName: this.currentMicroTopic.topic.name,
            courseSequence: 0,
            standardName: this.projectDetails.standard.name,
            subjectName: this.projectDetails.subject.name,
          },
          displayBriefUrl: !this.componentOptions.isAssetUploadRequired,
          preSignedUrls: this.componentOptions.signedAssetUrls,
          projectType: this.projectDetails.type,
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    currentStageData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.data = val;
      },
    },
    currentStage: {
      immediate: true,
      deep: true,
      handler() {
        this.setValuesForCurrentStage();
      },
    },
    // data: {
    //   deep: true,
    //   handler(val) {
    //     if (!this.skipLoadedData && Object.keys(val).length > 1) {
    //       console.log('show once');
    //       this.isSaved = false;
    //     }
    //     console.log(Object.keys(val).length);
    //     console.log('show onceeeee');
    //     this.skipLoadedData = false;
    //   },
    // },
  },

  methods: {
    ...mapMutations(['SET_VALIDATION_STATUS', 'SET_PROJECT_CONTENT_CURRENT_STAGE_DATA_COPY']),
    ...mapActions([
      'getContentEntryProjectDetails',
      'getCurrentUserContentEntryProjectFormPermissions',
      'contentEntryFileUpload',
      'contentEntryFileRemove',
      'contentEntryGetCurrentStageData',
      'saveCurrentContentData',
      'validateContentData',
      'moveContentToNextStage',
      'completeContentAndPublish',
      'contentEntryDeleteKeyword',
      'publishContent',
      'contentEntryAddKeyword',
      'contentEntryAddResource',
      'contentEntryDeleteResource',
      'setRightSidePreviewContent',
      'fetchMicroTopicProjectComment',
      'toggleRightSidePanel',
      'saveComment',
      'resolveComment',
      'fetchProjectLessonPlanSectionComment',
      'setRightSidePanelVisibility',
      'contentEntryDownloadAssets',
      'getMicroTopicDetails',
      'moveFromProofReaderToAssetAuthorizingStage',
      'contentEntryAddBriefAssetToAsset',
      'getCurrentUserAuthPermissions',
      'activateLessonPlanAndPublishToTeacherReadinessApp',
    ]),
    getAssetUploadRequiredStatus,
    getAcceptableFileType,
    allowedToolBarActionWithTablesForRTE() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'table',
        'fullScreen',
      ];
    },
    getNextStageButtonText() {
      let buttonText;
      const stages = Object.keys(this.LP_FORM_SUBMIT_TEXT);
      const nextStageIndex = stages.indexOf(this.currentStage || stages[0]) + 1;

      if (stages[nextStageIndex]) {
        buttonText =
          AppTexts.MOVE_TO_PREFIX_TEXT + this.LP_FORM_SUBMIT_TEXT[stages[nextStageIndex - 1]];
      } else {
        buttonText = 'Completed';
      }

      return buttonText;
    },
    proceedNavigationBasedOnChoice(choice) {
      if (choice == 'yes') {
        this.saveForm(false);
      }
      if (choice == 'no') {
        this.SET_PROJECT_CONTENT_CURRENT_STAGE_DATA_COPY(this.data);
      }
      this.savedDialogConfirmation = false;
      this.isSaved = true;
      this.loadingStatus.message = '';
      if (this.navigateTo && this.navigateTo != '')
        this.$router.push({ path: this.navigateTo.path });
    },
    redirectToPreviousPage() {
      this.$router.push({
        path: '/micro_topics/' + this.$route.params.microTopicId,
      });
    },
    setValuesForCurrentStage() {
      let currentStageIndex = Object.keys(LP_FORM_SUBMIT_TEXT).indexOf(this.currentStage);
      this.currentStageIndex = currentStageIndex;
      currentStageIndex = currentStageIndex == -1 ? 0 : currentStageIndex;

      this.canShowImageBrief = currentStageIndex < 7;
      this.isAssetUploadRequired = getAssetUploadRequiredStatus(this.currentStage);
      this.fileAcceptType = getAcceptableFileType(this.isAssetUploadRequired);
      this.canShowDiffIcon = currentStageIndex > 1;
    },
    saveForm(validateForm = true, showLoader = true) {
      if (!validateForm) {
        this.data.time = '10';
        this.saveCurrentContentData({
          data: this.data,
          projectId: this.$route.params.projectId,
          loader: showLoader,
          banner: showLoader,
        });
      }
    },
    reassignFormInputs(form) {
      const inputs = [];
      // Copied from VForm's previous life* which had a getInputs() function
      const search = (children, depth = 0) => {
        for (let index = 0; index < children.length; index++) {
          const child = children[index];
          if (child.errorBucket !== undefined) inputs.push(child);
          else search(child.$children, depth + 1);
        }
        if (depth === 0) return inputs;
      };
      search(form.$children);
      form.inputs = inputs;
    },
    validateRTEText() {
      return Object.keys(this.rteValidator).length == 0;
    },
    validateContentEntryForm() {
      this.reassignFormInputs(this.$refs.form);
      this.SET_VALIDATION_STATUS(true);
      if (this.$refs.form.validate() && this.validateRTEText()) {
        return true;
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Validation failed. Please enter valid data in the highlighted field ',
          type: 'error',
        });

        return false;
      }
    },
    validateContent() {
      let validationStatus = this.validateContentEntryForm();
      if (validationStatus) {
        this.validateContentData({
          projectId: this.$route.params.projectId,
          data: this.data,
          loader: true,
        });
      }
    },
    onClickMoveToNextStage() {
      let validationStatus = this.validateContentEntryForm();
      if (validationStatus) {
        this.isSaved = false;
        this.moveContentToNextStage({
          projectId: this.$route.params.projectId,
          data: this.data,
        });
      }
    },
    onClickCompleteAndPublish() {
      let validationStatus = this.validateContentEntryForm();
      if (validationStatus) {
        this.isSaved = false;
        this.completeContentAndPublish({
          projectId: this.$route.params.projectId,
          data: this.data,
        });
      }
    },
    downloadContentAssets() {
      // if (['contentBook', 'microText'].includes(this.projectDetails.type)) {
      //   if (this.currentStageData.file == '' || this.currentStageData.brief_url == '') {
      //     this.$notify({
      //       title: 'Failed',
      //       text: 'Sorry there is no assets available in previous stage',
      //       type: 'error',
      //     });
      //     return false;
      //   }
      // }
      this.contentEntryDownloadAssets({
        projectId: this.$route.params.projectId,
      });
    },
    onClickPublishHandler() {
      this.publishContent({
        projectId: this.$route.params.projectId,
      });
    },
    addToAsset(previewUrl, briefUrl, approveAsset, type, index = -1) {
      this.contentEntryAddBriefAssetToAsset({
        previewUrl,
        briefUrl,
        approveAsset,
        type,
        index,
      });
    },
    fileChangeHandler(file, type, url, index = -1) {
      if (file) {
        this.contentEntryFileUpload({
          file,
          type,
          data: {
            projectId: this.$route.params.projectId,
            data: this.data,
            microTopicId: this.$route.params.microTopicId,
          },
          index,
        });
      } else {
        this.contentEntryFileRemove({
          type,
          url,
          data: { projectId: this.$route.params.projectId, data: this.data },
          index,
        });
      }
    },
    toggleComments(commentSectionIdentifier, projectType = null) {
      this.setRightSidePanelVisibility(true);
      if (
        commentSectionIdentifier == this.commentSectionIdentifier &&
        this.rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS
      ) {
        return true;
      } else {
        this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS);
        this.commentSectionIdentifier = commentSectionIdentifier;
      }
      if (projectType == 'questions') {
        let groupQuestion = false;
        let questionNumber = this.data.findIndex(
          (_question) => _question._id == commentSectionIdentifier
        );
        if (questionNumber == -1) {
          questionNumber = this.data.findIndex(
            (_question) => _question.questionGroupId == commentSectionIdentifier
          );
          if (questionNumber > -1) {
            this.commentSectionHeaderText = `Stimulus`;
            groupQuestion = true;
          }
        }
        if (!groupQuestion) {
          this.commentSectionHeaderText = `Question ${questionNumber + 1}`;
        }
      }

      if (this.rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS) {
        this.fetchProjectLessonPlanSectionComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: commentSectionIdentifier,
          projectType: projectType,
        });
      }
    },
    diffCloseHandler() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
    },
    commentsSaveButtonClickHandler(text, stageName) {
      if (this.contentEntryProjectFormAuth.canAddComment) {
        this.saveComment({
          projectId: this.$route.params.projectId,
          sectionIdentifier: this.commentSectionIdentifier,
          text,
          stageName,
        });
      }
    },
    commentsResolveButtonClickHandler(stageName) {
      if (this.contentEntryProjectFormAuth.canResolveComment) {
        this.resolveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.commentSectionIdentifier,
          stageName,
        });
      }
    },
    closeComments() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
      this.commentSectionIdentifier = '';
    },
    onClickMoveToPreviousStage() {
      this.moveFromProofReaderToAssetAuthorizingStage({
        projectId: this.$route.params.projectId,
      });
    },
    handler(val) {
      var questionData = {
        data: val,
        projectType: 'question',
        previewMicroTopicDetails: {
          microTopicTitle: this.projectDetails.name,
          standardName: this.projectDetails.standard.name,
          subjectName: this.projectDetails.subject.name,
        },
        displayBriefUrl: !this.componentOptions.isAssetUploadRequired,
      };

      this.questionPreviewData = questionData;
    },
    onQuestionDataChanged(val) {
      this.data = val;
    },
    handleQuestionSave(payload) {
      const { data, validateForm, showLoader } = payload;
      this.data = data;

      this.saveForm(validateForm, showLoader);
    },
    async activateContent() {
      if (!this.isAdmin) {
        this.$notify({
          title: 'Failed',
          text: 'Admin can only activate a Lesson Plan.',
          type: 'warn',
        });
        return;
      }
      this.activateLessonPlanAndPublishToTeacherReadinessApp({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
      });
    },
  },
};
</script>
<style scoped>
.project-wrapper {
  padding: 30px;
}
.lesson-plan-title {
  font-size: 20px;
}
</style>
