<template>
  <Padder :left="30" :right="10" :vertical="5" style="overflow: hidden;">
    <!-- Third Row -->
    <v-row>
      <SubSection title="Answer" />
    </v-row>
    <Padder :left="-50">
      <v-subheader style="margin-left: -16px;">
        NOTE: Please input the correct order in the answer. The system will take care of shuffling
        the options that will be shown to the user.
      </v-subheader>
    </Padder>
    <v-row
      v-for="(option, index) in data.questionData.optionValues"
      :key="`atfQuestionOptions${revision}${index}`"
    >
      <Option
        :key="`atfQuestionOptionValues${index}`"
        :data="option"
        :index="index"
        :deleteOption="deleteOption"
        :partialFieldIdentifier="`['${data._id}'].questionData.optionValues`"
        :getToolBarActions="getToolBarActions"
        :options="options"
        :canAppproveAsset="canAppproveAsset"
        :forceAssetFinalized="forceAssetFinalized"
        :getRTEAcceptedFileType="getRTEAcceptedFileType"
        :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs"
      />
    </v-row>
    <v-row>
      <v-btn
        class="xs-accent-button font-weight-medium"
        width="165"
        @click="addNewOption"
        :disabled="data.questionData.optionValues.length > 9"
        ><v-icon left>mdi-plus</v-icon> Add option</v-btn
      >
    </v-row>
    <XSDivider />
  </Padder>
</template>

<script>
import Option from '@/components/contentEntry/questionComponents/dialogComponents/atfComponents/Option.vue';
import SubSection from '@/components/inputs/SubSection.vue';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import _ from 'lodash';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
export default {
  components: {
    Option,
    SubSection,
  },
  data() {
    return {
      questionsOprogramProject,
      DEFAULT_QUESTION_DATA,
      revision: 0,
    };
  },
  methods: {
    deleteOption(index) {
      this.data.questionData.optionValues.splice(index, 1);
      for (let i = index, j = this.data.questionData.optionValues.length; i < j; i++) {
        this.data.questionData.optionValues[i].sequence -= 1;
      }
      this.revision += 1;
    },
    addNewOption() {
      this.data.questionData.optionValues.push(
        _.cloneDeep(
          this.DEFAULT_QUESTION_DATA[questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF]
            .questionData.optionValues[0]
        )
      );
      this.data.questionData.optionValues[
        this.data.questionData.optionValues.length - 1
      ].sequence = this.data.questionData.optionValues.length;
      this.revision += 1;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    wrapAnswerBlockInContainer: {
      type: Boolean,
      default: false,
    },
    getToolBarActions: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    forceAssetFinalized: {
      type: Boolean,
      required: true,
    },
    getRTEAcceptedFileType: {
      type: Array,
      required: true,
    },
    getToolBarActionsForQuestionBriefs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.answerContainer {
  background: white;
  border-radius: 15px;
}
</style>
