<template>
  <v-form style="margin-top: 15px" dense class="steps-cnr">
    <v-row>
      <v-col
        cols="5"
        v-for="(assetHash, _index) in assets || []"
        :v-bind="_index"
        :key="_index"
        style="margin-right: 30px"
      >
        <v-row>
          <v-col :cols="11">
            <FileInput
              :image_url="isAssetUploadRequired ? assetHash.assetId : ''"
              :image_url_brief="assetHash.brief.assetId"
              :previewUrl="getPreviewUrl(_index).assetSignedUrl"
              :previewUrlBrief="getPreviewUrl(_index).assetSignedBriefUrl"
              label="Select Asset"
              :fileAcceptType="getFileAcceptType(assetHash.type, isAssetUploadRequired)"
              :loadingStatus="getPreviewUrl(_index).loadingStatus == FILE_UPLOADING_STATUS.IN_PROGRESS"
              :isAssetUploadRequired="isAssetUploadRequired"
              :isRequired="isAssetUploadRequired && isAssetMandatory"
              :fileChangeHandler="
                (file) =>
                  onAssetsChange(
                    file,
                    _index,
                    isAssetUploadRequired ? assetHash.assetId : assetHash.brief.assetId
                  )
              "
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isAssetImageChanged(_index)"
              :diffIdentifier="getAssetImageDiffIdentifierPrefix(_index)"
              :isNotReadOnly="isNotReadOnly"
              :fileValidators="fileValidators"
              @addBriefToAsset="
                (previewUrl, briefUrl, approveAsset) =>
                  copyAssetFromBrief(previewUrl, briefUrl, _index, approveAsset)
              "
              :isAssetApproved="assetHash.assetApproved"
              :canAppproveAsset="canAppproveAsset"
              :canFinalizeAsset="canFinalizeAsset"
            />
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              @click="
                deleteAssest(_index, isAssetUploadRequired ? assetHash.assetId : assetHash.brief.assetId)
              "
              title="Delete Assest"
              v-if="isNotReadOnly && requireDeleteAsset"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showImageBrief">
          <v-col cols="11">
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isAssetBriefChanged(_index)"
              :fieldIdentifier="getAssetBriefDiffIdentifier(_index)"
              :key="`${_index}rte_with_multi_assetUpload_brief`"
              :placeholder="AppTexts.REFERENCE_IMAGE_BRIEF"
              v-model="assetHash.brief.text"
              :showValidationError="isAssetMandatory"
              :counter="3000"
              :readonly="!isNotReadOnly"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col 
        v-if="requireAddAsset"
        cols="3" class="step-add-assets-button">
        <v-btn
          class="xs-accent-button"
          :class="
            (assets && assets[0] && assets[0].type == 'video') || !isNotReadOnly
              ? 'disable-btn-click-event'
              : ''
          "
          @click="onClickAddNewAssest()"
          dense
        >
          <img class="mgn-Rs" width="21" height="19" src="@/assets/imagecolor.svg" />
          {{ AppTexts.ADD_ASSET }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
/* eslint-disable prettier/prettier */

import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import { getFileNameFromUrl } from '@/functions/global';
import { mapActions } from 'vuex';
import FileInput from '@/components/inputs/FileInput';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';

export default {
  name: 'MultipleAssetUpload',
  components: {
    FileInput,
    RichTextEditor,
  },
  data() {
    return {
      AppTexts,
      Errors,
      FILE_UPLOADING_STATUS,
      BASIC_TOOLS,
    };
  },
  props: {
    assets: {
      type: Array,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    assetSignedUrlsHash: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    sectionIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    canShowDiffIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    assetDiffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    assetsDiffChangedHash: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    requireDeleteAsset: {
      type: Boolean,
      required: false,
      default: true,
    },
    requireAddAsset: {
      type: Boolean,
      required: false,
      default: true,
    },
    stepIndex: {
      type: Number,
      required: true
    },
    isAssetMandatory: {
      type: Boolean,
      required: false,
      default: true,
    },
    canFinalizeAsset: {
      type: Boolean,
      required: false,
      default: false,
    },
    canAppproveAsset: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  methods: {
    ...mapActions(['addNewAssest', 'removeAssest', 'copyStepAssetAction', 'importStepAssetAction', 'deleteStepAssetAction']),
    getFileNameFromUrl,
    getPreviewUrl(assetIndex) {
      try {
        let previewUrls = {
          assetSignedUrl: '',
          assetSignedBriefUrl: '',
          loadingStatus: 'not_started'
        }
        let asset = this.assetSignedUrlsHash[assetIndex];
        if(asset) {
          previewUrls.assetSignedUrl = asset.assetSignedUrl
          previewUrls.assetSignedBriefUrl = asset.brief.assetSignedUrl
          previewUrls.loadingStatus = asset.loadingStatus
        }
        return previewUrls;
      } catch (error) {
        console.log(error);
        return '';
      }
    },
    getImageBrief(assets, index) {
      return assets && assets.length > index && assets[index] ? assets[index].brief : '';
    },
    getFileAcceptType(assetHashType, isAssetUploadRequired) {
      if (assetHashType) {
        if (['image', 'video'].includes(assetHashType)) {
          return assetHashType + '/*';
        } else if (assetHashType == 'pdf') {
          return '.pdf';
        }
      }

      if (isAssetUploadRequired) {
        return 'image/*,video/*,.pdf';
      } else {
        return 'image/*,video/*,.docx,.pdf,.doc';
      }
    },
    onAssetsChange(file, assetIndex, assetId) {
      if (file) {
        this.importStepAssetAction({
          file,
          stepIndex: this.stepIndex,
          assetIndex,
          section: this.sectionIdentifier,
        });
      } else {
        this.deleteStepAssetAction({
          assetId,
          identifier: this.getAssetImageDiffIdentifierPrefix(assetIndex),
          stepIndex: this.stepIndex,
          assetIndex,
          section: this.sectionIdentifier,
          removeHashAlso: false,
        });
      }
    },
    copyAssetFromBrief(signedUrl, assetId, assetIndex, approveAsset) {
      this.copyStepAssetAction({
        signedUrl,
        assetId,
        stepIndex: this.stepIndex,
        assetIndex,
        section: this.sectionIdentifier,
        approveAsset
      });
    },
    onClickAddNewAssest() {
      this.addNewAssest({ stepIndex: null, section: this.sectionIdentifier });
    },
    deleteAssest(assetIndex, url) {
      this.removeAssest({
        stepIndex: null,
        assetIndex,
        url,
        section: this.sectionIdentifier,
      });
    },
    getAssetBriefDiffIdentifier(assetIndex) {
      try {
        let str = this.assetDiffIdentifierPrefix.replace('<assetIndex>', assetIndex);
        return `${str}.brief.text`;
      } catch (error) {
        console.log(error);
      }

      return '';
    },
    getAssetImageDiffIdentifierPrefix(assetIndex) {
      let str = this.assetDiffIdentifierPrefix.replace('<assetIndex>', assetIndex);

      if (this.isAssetUploadRequired) {
        return `${str}.assetId`;
      } else {
        return `${str}.brief.assetId`;
      }
    },
    isAssetBriefChanged(assetIndex) {
      try {   
        if (this.canShowDiffIcon) {
          return this.assetsDiffChangedHash[assetIndex].brief.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isAssetImageChanged(assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          if (this.isAssetUploadRequired) {
            return this.assetsDiffChangedHash[assetIndex].assetId.isChanged == true;
          } else {
            return this.assetsDiffChangedHash[assetIndex].brief.assetId.isChanged == true;
          }
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
};
</script>
