const AUTHORING1_STAGE = 'authoring1Stage';
const REVIEW1_STAGE = 'review1Stage';
const REVIEW2_STAGE = 'review2Stage';
const AUTHORING2_STAGE = 'authoring2Stage';
const ACADEMIC_SIGN_OFF_STAGE = 'academicSignOffStage';
const EDITING_STAGE = 'editingStage';
const VISUALIZING_STAGE = 'visualizingStage';
const ASSET_UPLOAD_STAGE = 'assetUploadStage';
const ASSET_AUTHORIZING_STAGE = 'assetAuthorizingStage';
const PROOF_READING_STAGE = 'proofReadingStage';

export const PROJECT_LESSON_PLAN_STAGES = {
  AUTHORING1_STAGE: AUTHORING1_STAGE,
  REVIEW1_STAGE: REVIEW1_STAGE,
  REVIEW2_STAGE: REVIEW2_STAGE,
  AUTHORING2_STAGE: AUTHORING2_STAGE,
  ACADEMIC_SIGN_OFF_STAGE: ACADEMIC_SIGN_OFF_STAGE,
  VISUALIZING_STAGE: VISUALIZING_STAGE,
  EDITING_STAGE: EDITING_STAGE,
  ASSET_UPLOAD_STAGE: ASSET_UPLOAD_STAGE,
  ASSET_AUTHORIZING_STAGE: ASSET_AUTHORIZING_STAGE,
  PROOF_READING_STAGE: PROOF_READING_STAGE,
};

export const PROJECT_LESSON_PLAN_STAGES_IN_ORDER = [
  AUTHORING1_STAGE,
  REVIEW1_STAGE,
  REVIEW2_STAGE,
  AUTHORING2_STAGE,
  ACADEMIC_SIGN_OFF_STAGE,
  EDITING_STAGE,
  VISUALIZING_STAGE,
  ASSET_UPLOAD_STAGE,
  ASSET_AUTHORIZING_STAGE,
  PROOF_READING_STAGE,
];

export const PROJECT_LESSON_PLAN_COMMENT_SECTION_NAME = {
  SUMMARY: 'summary',
  AIM: 'aim',
  ACTION: 'action',
  ANALYSIS: 'analysis',
  APPLICATION: 'application',
  ASSESSMENT: 'assessment',
};

export const DIFF_INDICATOR_COLORS = {
  active: '#FD8733',
  inactive: '#FEC49D',
};

export const PROJECT_LIST_FILTERS = {
  DEFAULT: 'All Projects',
  PERSONALIZED: 'Assigned to Me',
};

export const PROJECT_TYPE_DAILY_PLAN = 'SchoolDailyPlan';
export const PROJECT_TYPE_LESSON_PLAN = 'SchoolLessonPlan';

const PROJECT_WORKFLOW_STATUS_COMPLETED = 'completed';
const PROJECT_WORKFLOW_STATUS_PUBLISHED = 'published';
const PROJECT_WORKFLOW_STATUS_NOT_STARTED = 'not_started';
const PROJECT_WORKFLOW_STATUS_IN_PROGRESS = 'in_progress';
const PROJECT_WORKFLOW_STATUS_PUBLISH_IN_PROGRESS = 'publish_in_progress';
export const PROJECT_WORKFLOW_STATUSES_HASH = {
  COMPLETED: PROJECT_WORKFLOW_STATUS_COMPLETED,
  PUBLISHED: PROJECT_WORKFLOW_STATUS_PUBLISHED,
  NOT_STARTED: PROJECT_WORKFLOW_STATUS_NOT_STARTED,
  IN_PROGRESS: PROJECT_WORKFLOW_STATUS_IN_PROGRESS,
  PUBLISH_IN_PROGRESS: PROJECT_WORKFLOW_STATUS_PUBLISH_IN_PROGRESS,
};

const AUTHOR_LEVEL_ONE = 'Author 1';
const AUTHOR_LEVEL_TWO = 'Author 2';
const REVIEWER_1 = 'Reviewer 1';
const REVIEWER_2 = 'Reviewer 2';
const ACADEMIC_SIGN_OFF = 'Academic Sign Off';
const EDITOR = 'Editor';
const VISUALIZER = 'Visualizer';
const ASSET_UPLOADER = 'Asset Uploader';
const ASSET_AUTHORIZER = 'Asset Authorizer';
const PROOF_READER = 'Proof Reader';
export const PROJECT_STAGES_ASSIGNEE_IN_ORDER = [
  AUTHOR_LEVEL_ONE,
  REVIEWER_1,
  REVIEWER_2,
  AUTHOR_LEVEL_TWO,
  ACADEMIC_SIGN_OFF,
  EDITOR,
  VISUALIZER,
  ASSET_UPLOADER,
  ASSET_AUTHORIZER,
  PROOF_READER,
];

export const PROJECT_LESSON_PLAN_STAGE_KEY_TO_NAME_MAPPING = {
  [AUTHORING1_STAGE]: AUTHOR_LEVEL_ONE,
  [REVIEW1_STAGE]: REVIEWER_1,
  [REVIEW2_STAGE]: REVIEWER_2,
  [AUTHORING2_STAGE]: AUTHOR_LEVEL_TWO,
  [ACADEMIC_SIGN_OFF_STAGE]: ACADEMIC_SIGN_OFF,
  [VISUALIZING_STAGE]: VISUALIZER,
  [EDITING_STAGE]: EDITOR,
  [ASSET_UPLOAD_STAGE]: ASSET_UPLOADER,
  [ASSET_AUTHORIZING_STAGE]: ASSET_AUTHORIZER,
  [PROOF_READING_STAGE]: PROOF_READER,
};

export const PROJECT_LESSON_PLAN_STAGES_THAT_HAS_LP_DATA = [
  AUTHORING1_STAGE,
  REVIEW1_STAGE,
  REVIEW2_STAGE,
  AUTHORING2_STAGE,
  ACADEMIC_SIGN_OFF_STAGE,
  EDITING_STAGE,
  VISUALIZING_STAGE,
  ASSET_UPLOAD_STAGE,
  ASSET_UPLOAD_STAGE,
  PROOF_READING_STAGE,
];

const PROJECT_ASSIGNEE_KEY_AUTHOR = 'author';
const PROJECT_ASSIGNEE_KEY_REVIEWER1 = 'reviewer1';
const PROJECT_ASSIGNEE_KEY_REVIEWER2 = 'reviewer2';
const PROJECT_ASSIGNEE_KEY_ACADEMICSIGNOFF = 'academicSignOff';
const PROJECT_ASSIGNEE_KEY_EDITOR = 'editor';
const PROJECT_ASSIGNEE_KEY_VISUALIZER = 'visualizer';
const PROJECT_ASSIGNEE_KEY_ASSETUPLOADER = 'assetUploader';
const PROJECT_ASSIGNEE_KEY_ASSETAUTHORIZER = 'assetAuthorizer';
const PROJECT_ASSIGNEE_KEY_PROOFREADER = 'proofReader';
export const PROJECT_LESSON_PLAN_STAGE_KEY_TO_ASSIGNEE_KEY_NAME_MAPPING = {
  [AUTHORING1_STAGE]: PROJECT_ASSIGNEE_KEY_AUTHOR,
  [REVIEW1_STAGE]: PROJECT_ASSIGNEE_KEY_REVIEWER1,
  [REVIEW2_STAGE]: PROJECT_ASSIGNEE_KEY_REVIEWER2,
  [AUTHORING2_STAGE]: PROJECT_ASSIGNEE_KEY_AUTHOR,
  [ACADEMIC_SIGN_OFF_STAGE]: PROJECT_ASSIGNEE_KEY_ACADEMICSIGNOFF,
  [EDITING_STAGE]: PROJECT_ASSIGNEE_KEY_EDITOR,
  [VISUALIZING_STAGE]: PROJECT_ASSIGNEE_KEY_VISUALIZER,
  [ASSET_UPLOAD_STAGE]: PROJECT_ASSIGNEE_KEY_ASSETUPLOADER,
  [ASSET_AUTHORIZING_STAGE]: PROJECT_ASSIGNEE_KEY_ASSETAUTHORIZER,
  [PROOF_READING_STAGE]: PROJECT_ASSIGNEE_KEY_PROOFREADER,
};
