import gql from 'graphql-tag';

export const fetchAllBlocksForSelectedProductBoardStandardSubjectQuery = (
  productId,
  boardName,
  standardId,
  subjectId
) => {
  return gql`
  query {
    getAllBlocksOnStandardSubjectBoardProductComboForProjectCreation(
      boardName: "${boardName}",
      subjectId: ${subjectId},
      standardId: ${standardId},
      productId: ${productId}
    ){
      id
      title
      bId
    }
  }
`;
};

export const fetchConfidenceBlocks = (standardId, subjectId) => {
  return gql`
    query {
      fetchConfidenceBlocks(
        gradeId: "${standardId}"
        subjectId: "${subjectId}"
      ) {
        data {
          id: blockId
          title: blockName
        }
        success
        error
      }
    }
`;
};

export const fetchConfidenceAllGradeSubjects = gql`
  query {
    fetchAllConfidencdGradeSubjects {
      data {
        id: _id # Renaming _id to id
        name
        displayName: shortCode
        subjects {
          id: _id # Renaming _id to id inside subjects
          name
          displayName: shortCode
        }
      }
    }
  }
`;
