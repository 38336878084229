<template>
  <div class="block-details">
    <div class="block-sequence-number">{{ block.blockSequence }}</div>
    <div class="block-title">
      <span>{{ block.blockTitle }}</span>
      <br />
      <div>{{ block.bId }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockHeader',
  props: ['block']
};
</script>

<style>
.block-details {
  padding: 10px;
  overflow: hidden;
}

.block-details .block-sequence-number {
  padding: 10px;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  line-height: 30px;
  background-color: white;
  text-align: center;
}

.block-details .block-title {
  margin-left: 10px;
  padding-top: 10px;
  height: 50px;
}

.block-details .block-sequence-number,
.block-details .block-title {
  float: left;
}

.block-title div {
  margin-top: 8px;
}
</style>
