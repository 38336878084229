<template>
  <v-expansion-panels multiple focusable v-model="panel">
    <v-row class="cnr">
      <CoverImage
        :data="data"
        :previewUrl="options.signedAssetUrls.imageUrl"
        :previewUrlBrief="options.signedAssetUrls.briefUrl"
        :coverImagePlaceholder="AppTexts.O_PROGRAM_PLAN_COVER_IMAGE"
        :fileAcceptedTypes="options.fileAcceptType"
        :isAssetUploadRequired="options.isAssetUploadRequired"
        :fileChangeHandler="fileChangeHandler"
        :canShowDiffIcon="options.canShowDiffIcon"
        :showImageBrief="true"
        :loadingStatus="options.fileUploadingStatus.coverImage"
        :isNotReadOnly="options.isNotReadOnly"
        :addToAsset="addToAsset"
        :canAppproveAsset="canAppproveAsset"
        :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
        :validationFlag="options.validationFlag"
      />
    </v-row>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="'Aim Section'"
          :displayTrailing="false"
          :toggleComments="() => toggleComments('aim')"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <AimSection
          :key="'aimSection' + aimKey"
          :data="data.aim"
          :options="options"
          :contentEntryDeleteKeyword="contentEntryDeleteKeyword"
          :contentEntryAddKeyword="contentEntryAddKeyword"
          :signedUrls="signedUrls.aim"
          :contentEntryAddResource="contentEntryAddResource"
          :contentEntryDeleteResource="contentEntryDeleteResource"
          :fileChangeHandler="fileChangeHandler"
          :addToAsset="addToAsset"
          :canAppproveAsset="canAppproveAsset"
          :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="'Action Section'"
          :time="data.action.time"
          :enableTimerDropDown="true"
          @set-timer-input="
            (val) => {
              data.action.time = val;
            }
          "
          :typeSync="data.action.type"
          :displayTrailing="true"
          :displayTime="false"
          :canEditType="false"
          :canEditTime="true"
          :toggleComments="() => toggleComments('action')"
          :type="data.action.type"
          :headerCheck="true"
          headerCheckText="CANNOT BE DONE WITHOUT AN ADULT"
          :initialHeaderCheckValue="data.action.guidanceNeeded"
          @headerCheckboxChange="(e) => (data.action.guidanceNeeded = e)"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ActionSection
          :key="'ActionSection' + actionKey"
          :data="data.action"
          :options="options"
          :fileChangeHandler="fileChangeHandler"
          :addToAsset="addToAsset"
          :canAppproveAsset="canAppproveAsset"
          :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="'Analysis Section'"
          :time="data.analysis.time"
          :typeSync="data.analysis.type"
          :displayTrailing="true"
          :displayTime="true"
          :olessonTimer="true"
          :toggleComments="() => toggleComments('analysis')"
          :type="data.analysis.type"
          :canEditType="false"
          @update-type-of-block-to-state="
            (val) => {
              data.analysis.type = val;
            }
          "
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <AnalysisSection
          :key="'AnalysisSection' + analysisKey"
          :data="data.analysis"
          :options="options"
          :fileChangeHandler="fileChangeHandler"
          :addToAsset="addToAsset"
          :canAppproveAsset="canAppproveAsset"
          :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="'Application Section'"
          :time="data.application.time"
          :typeSync="data.application.type"
          :displayTrailing="true"
          :toggleComments="() => toggleComments('application')"
          :type="data.application.type"
          @update-type-of-block-to-state="
            (val) => {
              data.application.type = val;
            }
          "
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ApplicationSection
          :key="'ApplicationSection' + applicationSectionKey"
          :data="data.application"
          :options="options"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="'WHAT DID YOU LEARN'"
          :time="data.time"
          :typeSync="data.whatDidYouLearn.type"
          :displayTrailing="false"
          :toggleComments="() => toggleComments('whatDidYouLearn')"
          :type="data.whatDidYouLearn.type"
          @update-type-of-block-to-state="
            (val) => {
              data.whatDidYouLearn.type = val;
            }
          "
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <LearningSection :data="data.whatDidYouLearn" :options="options" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <ExpansionPanelHeader
          :sectionTitle="'TEACHER REFERENCE'"
          :displayTrailing="false"
          :toggleComments="() => toggleComments('teacherReference')"
          @update-type-of-block-to-state="
            (val) => {
              data.teacherReference.type = val;
            }
          "
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Reference
          :key="'reference' + referenceKey"
          :data="data.teacherReference"
          :options="options"
          @reference="
            (reference) => {
              data.teacherReference = reference;
            }
          "
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from 'vuex';
import ExpansionPanelHeader from '@/components/contentEntry/oLessonComponents/ExpansionPanelHeader';
import AimSection from '@/components/contentEntry/oLessonComponents/AimSection';
import ActionSection from '@/components/contentEntry/oLessonComponents/ActionSection';
import AnalysisSection from '@/components/contentEntry/oLessonComponents/AnalysisSection';
import ApplicationSection from '@/components/contentEntry/oLessonComponents/ApplicationSection';
import LearningSection from '@/components/contentEntry/oLessonComponents/LearningSection';
import Reference from '@/components/contentEntry/oLessonComponents/Reference';
import CoverImage from '@/components/contentEntry/oLessonComponents/CoverImage';
import AppTexts from '@/constants/AppTexts';
export default {
  name: 'oLessonPlan',
  data() {
    return {
      AppTexts,
      panel: [0, 1, 2, 3, 4, 5],
      referenceKey: 0,
      analysisKey: 0,
      aimKey: 0,
      actionKey: 0,
      applicationSectionKey: 0,
    };
  },
  components: {
    ExpansionPanelHeader,
    AimSection,
    ActionSection,
    AnalysisSection,
    ApplicationSection,
    LearningSection,
    Reference,
    CoverImage,
  },
  created() {
    this.data.analysis.time = '10 Mins';
    this.data.application.time = '10 Mins';
    this.data.action.type = 'individual';
    this.data.analysis.type = 'individual';
    if (!this.data.action.guidanceNeeded) {
      this.data.action.guidanceNeeded = false;
      this.currentStageDataCopy.action.guidanceNeeded = false;
    }

    this.currentStageDataCopy.analysis.time = '10 Mins';
    this.currentStageDataCopy.application.time = '10 Mins';
    this.currentStageDataCopy.action.type = 'individual';
    this.currentStageDataCopy.analysis.type = 'individual';
  },
  computed: {
    ...mapState({
      currentStageDataCopy: (state) => state.contentEntry.currentStageDataCopy,
    }),
  },
  watch: {
    data() {
      this.referenceKey++;
      this.analysisKey++;
      this.actionKey++;
      this.analysisKey++;
      this.applicationSectionKey++;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    contentEntryDeleteKeyword: {
      type: Function,
      required: true,
    },
    contentEntryAddKeyword: {
      type: Function,
      required: true,
    },
    signedUrls: {
      type: Object,
      required: true,
    },
    contentEntryAddResource: {
      type: Function,
      required: true,
    },
    contentEntryDeleteResource: {
      type: Function,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
