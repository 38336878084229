<template>
  <div>
    <v-row v-if="hasGlobalSearch">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @change="doSearch()"
          clearable
          @click:clear="searchHandler('')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col v-for="(filter, index) in filters" :key="index" :cols="filter.cols || 2">
        <span v-if="filter.type == 'select'">
          <v-select
            hide-details
            outlined
            :items="filter.items || []"
            v-model="filter.value"
            :label="filter.label"
            :multiple="filter.multiple || false"
            @change="(event) => callFilterHandler(filter, event)"
            :clearable="true"
          />
        </span>
        <span v-else-if="filter.type == 'text'">
          <v-text-field
            hide-details
            outlined
            v-model="filter.value"
            :label="filter.label"
            @change="(event) => callFilterHandler(filter, event)"
          />
        </span>
        <span v-else-if="filter.type == 'button'">
          <v-btn @click="filter.handler">{{ filter.text }}</v-btn>
        </span>
        <span v-else>Un-supported Filter Type</span>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :loading-text="loadingText"
      :class="dataTableClass"
      :server-items-length="totalCount"
      @update:options="paginationHandler"
      :items-per-page="isAdmin ? 50 : 10"
      :footer-props="{ itemsPerPageOptions: itemsPerPageOptions }"
    >
      <template v-slot:item.index="{ item }">
        <span>{{ items.indexOf(item) + 1 }}</span>
      </template>
      <!-- <template v-slot:item.questionsStatus="{ item }">
        <div>
          <div>#Qus: {{(item.details || {questionsCreated: 0}).questionsCreated}}</div>
          <div>#Test-Qus: {{(item.details || {testQuestionsCreated: 0}).testQuestionsCreated}}</div>
        </div>
      </template>
      <template v-slot:item.videosStatus="{ item }">
        <div>
          <span>#Videos: {{(item.details || {videosCreated: 0}).videosCreated}}</span>
        </div>
      </template>-->
      <template v-slot:item.index="{ item }">
        <span>{{ items.indexOf(item) + 1 }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div style="display: flex; flex-direction: row;">
          <span v-for="(handler, index) in handlers" :key="index">
            <v-btn
              v-if="
                (auth[handler.authPermissionKey] ||
                  (auth.canEditProjectList && auth.canEditProjectList.includes(item.id))) &&
                !(handler.canShow && !handler.canShow(item))
              "
              small
              class="mr-2"
              @click="handler.function(item)"
            >
              <v-icon size="15" v-if="handler.icon">{{ handler.icon }}</v-icon>
              {{ handler.name }}
            </v-btn>
          </span>
          <v-menu bottom left v-if="showSubHandlerMenu(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon dark>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(handler, index) in subHandlers" :key="index">
                <v-list-item-title>
                  <v-btn
                    v-if="
                      (auth[handler.authPermissionKey] ||
                        (auth.canEditProjectList && auth.canEditProjectList.includes(item.id))) &&
                      !(handler.canShow && !handler.canShow(item))
                    "
                    small
                    @click="handler.function(item)"
                  >
                    <!-- <v-img v-if="handler.image" :src="handler.image"></v-img>
      <v-icon v-else-if="handler.icon" size="15">{{ handler.icon }}</v-icon> -->

                    <v-icon size="15" v-if="handler.icon">{{ handler.icon }}</v-icon>
                    {{ handler.name }}
                  </v-btn></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'XTable',
  data() {
    return {
      search: '',
    };
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    handlers: {
      type: Array,
      required: true,
    },
    subHandlers: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    searchHandler: {
      type: Function,
      required: false,
    },
    options: {
      type: Object,
      required: false,
    },
    totalCount: {
      type: Number,
      required: false,
    },
    paginationHandler: {
      type: Function,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingText: {
      type: String,
      required: false,
      default: '',
    },
    dataTableClass: {
      type: String,
      required: false,
      default: '',
    },
    hasGlobalSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemsPerPageOptions: {
      type: Array,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    callFilterHandler(filter, event) {
      if (filter && filter.handler) {
        filter.handler(event, filter);
      }
    },
    doSearch() {
      this.searchHandler(this.search);
    },
    showSubHandlerMenu(item) {
      let showSubHandlerMenu = false;
      this.subHandlers.forEach((_h) => {
        showSubHandlerMenu = showSubHandlerMenu || _h.canShow(item);
      });
      console.log(showSubHandlerMenu);
      return showSubHandlerMenu;
    },
  },
};
</script>
<style scoped>
.minMaxFieldWrapper {
  display: flex;
}
.small-text-field {
  width: 35%;
  height: 40px;
}
.minMaxComponentWrapper {
  border-radius: 4px;
  border: 1px solid #979797;
  padding: 12px 12px 5px 12px;
  position: relative;
}
.minMaxItemLabel {
  font-size: 14px;
  position: absolute;
  top: -12px;
  z-index: 1;
  background-color: white;
  padding: 0px 5px;
}
</style>
