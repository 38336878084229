export const PROJECT_STATE = {
  initialProjectsListApiStatus: 'not_started',
  projects: [],
  boards: [],
  subjects: [],
  standards: [],
  products: [],
  blocks: [],
  currentProject: {},
  revertProjectRequestData: {
    status: 'not_started',
  },
  totalCount: 0,
  currentWorkflowStatus: "completed"
};
