var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.lessonPlanData.application.teacher_tips),function(teacher_tip,index){return _c('div',{key:("questionTeacherTipsForPractice" + index)},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('BlockTitle',{attrs:{"title":("Question " + (teacher_tip.questionNumber ? teacher_tip.questionNumber : '') + " Tip")}})],1),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.deleteQuestionTeacherTipAtIndex(index); }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('TextFieldWithLabel',{attrs:{"placeholder":"Question Number","type":"number"},model:{value:(teacher_tip.questionNumber),callback:function ($$v) {_vm.$set(teacher_tip, "questionNumber", $$v)},expression:"teacher_tip.questionNumber"}}),(teacher_tip.isAuthoredQuestion == true)?_c('span',_vm._l((Object.keys(
          _vm.getQuestionsToDisplay(teacher_tip.questions)
        )),function(questionIdKey,index){return _c('div',{key:("questionTeacherTipsQuestion" + index)},[(Array.isArray(_vm.getQuestionsToDisplay(teacher_tip.questions)[questionIdKey]))?_c('v-card',{staticClass:"wrapper-container"},[_c('Padder',{attrs:{"horizontal":30}},[_c('QuestionGroupCard',{attrs:{"data":_vm.getQuestionsToDisplay(teacher_tip.questions)[questionIdKey],"isReadOnly":true,"saveForm":_vm.saveForm,"stimulus":_vm.getQuestionsToDisplay(teacher_tip.questions)[questionIdKey][0].stimulus,"options":_vm.options,"toggleComments":_vm.toggleComments,"isViewOnly":true,"shouldEmitDeleteAction":false,"canShowActionButtons":false},on:{"remove-question-clicked":function () {}}}),_vm._l((_vm.getQuestionsToDisplay(
                teacher_tip.questions
              )[questionIdKey]),function(question,groupQuestionIndex){return _c('div',{key:("question-card-wrapper-" + groupQuestionIndex)},[_c('QuestionCard',{attrs:{"flat":true,"data":question,"isReadOnly":true,"saveForm":_vm.saveForm,"options":_vm.options,"toggleComments":_vm.toggleComments,"shouldEmitDeleteAction":false,"lessonPlans":_vm.lessonPlans,"isViewOnly":true,"isSchoolProgramQuestion":true,"canShowActionButtons":false,"questionPurposeDisplayText":_vm.getQuestionPurposeDisplayText},on:{"question-preview-cliked":function () {},"remove-question-clicked":function () {}}}),(
                  _vm.getQuestionsToDisplay(teacher_tip.questions)[questionIdKey].length - 1 !=
                  groupQuestionIndex
                )?_c('v-divider',{staticClass:"mb-8"}):_vm._e()],1)}),_c('section',[_c('TeacherTipDisplay',{attrs:{"teacherTip":_vm.getQuestionsToDisplay(teacher_tip.questions)[questionIdKey][0].teacherTip,"isAssetUploadRequired":_vm.options.isAssetUploadRequired}})],1)],2)],1):_c('div',{staticClass:"list-view-question-wrapper"},[_c('QuestionCard',{key:("question-card-" + index),attrs:{"data":_vm.getQuestionsToDisplay(teacher_tip.questions)[questionIdKey],"isReadOnly":true,"saveForm":_vm.saveForm,"options":_vm.options,"toggleComments":_vm.toggleComments,"shouldEmitDeleteAction":false,"lessonPlans":_vm.lessonPlans,"isViewOnly":true,"isSchoolProgramQuestion":true,"canShowActionButtons":false,"questionPurposeDisplayText":_vm.getQuestionPurposeDisplayText},on:{"question-preview-cliked":function () {},"remove-question-clicked":function () {}}})],1)],1)}),0):_c('span',[_c('RTEWithMultipleAssetUpload',{key:("questionTeacherTipsForPracticeRTEWithAsseetOption" + index),attrs:{"data":teacher_tip,"isReadOnly":!_vm.isNotReadOnly,"isAssetUploadRequired":_vm.isAssetUploadRequired,"canShowDiffIcon":_vm.canShowDiffIcon,"placeholder":"Question Teacher Tip","diffChanges":(_vm.applicationDiff.teacher_tips || [])[index] || {},"assetSignedUrlsHash":(_vm.applicationSignedUrls.teacher_tips[index] || { assets: [] }).assets,"showImageBrief":_vm.showImageBrief,"fileValidators":_vm.fileValidators,"assetDiffIdentifierPrefix":("application.teacher_tips[" + index + "].assets[<assetIndex>]"),"descriptionDiffIdentifier":("application.teacher_tips[" + index + "].description"),"sectionIdentifier":"application.teacher_tips","assetTypeMapping":{
          practice: _vm.AssetTypes.PRACTICE_TEACHER_TIP_ASSET_TYPE,
        },"requireDeleteAsset":false,"requireAddAsset":false,"stepIndex":index,"isAssetMandatory":false,"descriptionMaxLength":_vm.schema.teacherTipsDescriptionMaxLength,"canAppproveAsset":_vm.canAppproveAsset,"canFinalizeAsset":true,"requireDeleteBlock":false}})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }