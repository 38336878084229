<template>
  <v-flex xs3 class="add-question-filter-wrapper">
    <v-row class="alc">
      <v-col>
        <BlockTitle title="Filter" class="question-block-wrapper" />
      </v-col>
      <v-col>
        <div class="d-flex flex-row items boardwork-title">
          <p class="no-margin">
            <u @click="(e) => $emit('onClickClearFilter', e)"> CLEAR FILTER</u>
          </p>
        </div></v-col
      >
    </v-row>
    <v-form class="filtters-form-wrapper">
      <div class="mgn-Ts">
        <div v-for="(key, index) in Object.keys(filterOptions)" :key="index">
          <div v-if="filterOptions[key].type == 'textField'">
            <div class="filter-textfield-wrapper">
              <v-text-field
                :placeholder="filterOptions[key].name"
                type="number"
                min="0"
                outlined
                dense
                :value="selectedFilters[key]"
                hide-details
                @input="(val) => $emit('onChangeFilterValue', key, val)"
                @keydown="
                  (event) => {
                    if (event.key === '-') {
                      event.preventDefault();
                    }
                  }
                "
              ></v-text-field>
            </div>
          </div>
          <div v-else-if="filterOptions[key].type == 'select'">
            <div class="filter-dropdown-wrapper">
              <FiterSelectDropDown
                class="filterdropdown"
                :placeholder="filterOptions[key].name"
                :canEditType="!(filterOptions[key].disabled || false)"
                :filterItems="filterOptions[key].filterList"
                :value="selectedFilters[key]"
                :disabled="filterOptions[key].disabled || false"
                @input="(val) => $emit('onChangeFilterValue', key, val)"
              />
            </div>
          </div>
        </div>
      </div>
    </v-form>
    <div class="apply-filter-button">
      <v-btn @click="() => $emit('onApplyFilter')" depressed color="primary">APPLY FILTER</v-btn>
    </div>
  </v-flex>
</template>
<script>
import BlockTitle from '@/components/inputs/BlockTitle.vue';
import FiterSelectDropDown from '@/components/Questions/FilterSelectDropDown.vue';

export default {
  name: 'QuestionFilterPanel',
  components: {
    BlockTitle,
    FiterSelectDropDown,
  },
  props: {
    filterOptions: {
      type: Object,
      required: true,
    },
    selectedFilters: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.no-margin {
  margin: 0px;
}
.boardwork-title u {
  cursor: pointer;
}
</style>
<style>
.question-block-wrapper .block-title {
  color: #000 !important;
}
.filterdropdown {
  display: flex;
}
.filter-dropdown-wrapper {
  padding: 0px 10px 20px 10px;
  margin: 0px 15px;
}
.filter-textfield-wrapper {
  padding: 0px 10px 20px 10px;
  margin: 0px 10px;
}
.apply-filter-button {
  background: #fff;
  position: sticky;
  bottom: 0px;
}
.apply-filter-button button {
  width: 100%;
}
.filtters-form-wrapper {
  padding-top: 10px;
  overflow: auto;
  height: 100%;
  padding-bottom: 100px;
}
</style>
