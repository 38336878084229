import { getAssetUploadRequiredStatus } from '@/utils';
import Vue from 'vue';
import lodash from 'lodash';

const mutations = {
  SET_LOADING_STATUS(state, { status, message }) {
    state.loadingStatus = { status, message };
  },
  SET_PROJECT_DETAILS(state, data) {
    state.projectDetails = data;
  },
  SET_CONTENT_DETAILS(state, data) {
    state.contentDetails = data;
  },
  SET_PROJECT_CONTENT_CURRENT_STAGE_DATA(state, data) {
    state.contentData = data;
    state.currentStageData = data.currentStageData.data;
    state.currentStageDataCopy = JSON.parse(JSON.stringify(data.currentStageData.data));
    state.currentStageAssetsSignedUrl = data.currentStageAssetsSignedUrl;
  },
  SET_PROJECT_CONTENT_CURRENT_STAGE_DATA_COPY(state, data) {
    state.currentStageDataCopy = JSON.parse(JSON.stringify(data));
  },
  SET_FILE_UPLOADING_STATUS(state, { type, status }) {
    if (type.includes('slider')) {
      Vue.set(state.fileUploadingStatus.slider_action, type.split('.').pop(), status);
    } else if (type.includes('resources')) {
      Vue.set(state.fileUploadingStatus.resources, type.split('.').pop(), status);
    } else {
      state.fileUploadingStatus[type] = status;
    }
  },
  SET_UPLOADED_FILE_PATH(state, { type, data, index, forceApproveAsset }) {
    let forceUpdateImageUrl = forceApproveAsset != null;
    let isAssetApproved =
      (forceApproveAsset || state.contentData.currentStage == 'assetAuthorizingStage') &&
      data.path != '';
    let isAssetUploadRequired = (this.isAssetUploadRequired = getAssetUploadRequiredStatus(
      state.contentData.currentStage
    ));

    let imageKey = forceUpdateImageUrl || isAssetUploadRequired ? 'image_url' : 'brief_url';
    let newImageKey = forceUpdateImageUrl || isAssetUploadRequired ? 'imageUrl' : 'briefUrl';
    let newFileKey = forceUpdateImageUrl || isAssetUploadRequired ? 'file' : 'fileBrief';
    if (type == 'microTextImage') {
      state.currentStageData[imageKey] = data.path;
      state.currentStageData['image_url_approved'] = isAssetApproved;
      state.currentStageAssetsSignedUrl[imageKey] = data.signedUrl;
    } else if (type == 'worksheetImage') {
      state.currentStageData[newFileKey] = data.path;
      state.currentStageData.fileApproved = isAssetApproved;
      state.currentStageAssetsSignedUrl[newFileKey] = data.signedUrl;
    } else if (type == 'contentBookImage') {
      state.currentStageData[newFileKey] = data.path;
      state.currentStageData.fileApproved = isAssetApproved;
      state.currentStageAssetsSignedUrl[newFileKey] = data.signedUrl;
    } else if (type == 'microMovie') {
      state.currentStageData.microMovie[newFileKey] = data.path;
      state.currentStageData.microMovie.fileApproved = isAssetApproved;
      state.currentStageAssetsSignedUrl.microMovie[newFileKey] = data.signedUrl;
    } else if (type == 'coolTalk') {
      state.currentStageData.coolTalk[newFileKey] = data.path;
      state.currentStageData.coolTalk.fileApproved = isAssetApproved;
      state.currentStageAssetsSignedUrl.coolTalk[newFileKey] = data.signedUrl;
    } else if (type == 'solvedExample') {
      state.currentStageData.solvedExample[newFileKey] = data.path;
      state.currentStageData.solvedExample.fileApproved = isAssetApproved;
      state.currentStageAssetsSignedUrl.solvedExample[newFileKey] = data.signedUrl;
    } else if (type.includes('resources')) {
      Vue.set(state.currentStageData.aim.resources[index], newImageKey, data.path);
      Vue.set(state.currentStageData.aim.resources[index], 'imageUrlApproved', isAssetApproved);
      Vue.set(state.currentStageAssetsSignedUrl.aim.resources[index], newImageKey, data.signedUrl);
    } else if (type == 'coverImage') {
      state.currentStageData[newImageKey] = data.path;
      state.currentStageData.imageUrlApproved = isAssetApproved;
      state.currentStageAssetsSignedUrl[newImageKey] = data.signedUrl;
    } else if (type.includes('slider')) {
      let splitted = type.split('.');
      let actionIndex = splitted[2];
      let identifier = splitted[1];
      let keyIdentifier = identifier == 'action' ? 'instructions' : 'contents';
      if (index > -1) {
        if (state.currentStageData[identifier][keyIdentifier][actionIndex].layoutData.images) {
          state.currentStageAssetsSignedUrl[identifier][keyIdentifier][actionIndex].layoutData[
            'images'
          ][index][newImageKey] = data.signedUrl;
          state.currentStageData[identifier][keyIdentifier][actionIndex].layoutData['images'][
            index
          ][newImageKey] = data.path;
          state.currentStageData[identifier][keyIdentifier][actionIndex].layoutData['images'][
            index
          ].imageUrlApproved = isAssetApproved;
        } else {
          state.currentStageAssetsSignedUrl[identifier][keyIdentifier][actionIndex].layoutData[
            index
          ][newImageKey] = data.signedUrl;
          state.currentStageData[identifier][keyIdentifier][actionIndex].layoutData[index][
            newImageKey
          ] = data.path;
          state.currentStageData[identifier][keyIdentifier][actionIndex].layoutData[
            index
          ].imageUrlApproved = isAssetApproved;
        }
      } else {
        state.currentStageAssetsSignedUrl[identifier][keyIdentifier][actionIndex].layoutData[
          newImageKey
        ] = data.signedUrl;
        state.currentStageData[identifier][keyIdentifier][actionIndex].layoutData[newImageKey] =
          data.path;
        state.currentStageData[identifier][keyIdentifier][
          actionIndex
        ].layoutData.imageUrlApproved = isAssetApproved;
      }
    }
  },
  moveCurrentContentEntryToNextStage(state, { currentStage }) {
    state.currentStage = currentStage;
  },
  contentEntryDeleteKeyword(state, index) {
    state.currentStageData.aim.keywords.splice(index, 1);
  },
  contentEntryAddKeyword(state) {
    state.currentStageData.aim.keywords.push({
      keyword: '',
      description: '',
    });
  },
  contentEntryAddResource(state) {
    state.currentStageData.aim.resources.push({
      text: '',
      imageUrl: '',
      brief: '',
    });
    state.currentStageAssetsSignedUrl.aim.resources.push({
      signedUrl: '',
      signedBriefUrl: '',
    });
  },
  contentEntryDeleteResource(state, index) {
    state.currentStageData.aim.resources.splice(index, 1);
    state.currentStageAssetsSignedUrl.aim.resources.splice(index, 1);
  },
  SET_CONTENT_ENTRY_COMMENTS_DATA(state, { status, data }) {
    let oldCommentsData = state.comments;

    state.comments = {
      status: status || oldCommentsData.status,
      data: data || oldCommentsData.data,
    };
  },
  SET_VALIDATION_STATUS(state, data) {
    state.calledValidateFlag = data;
  },
  SET_CURRENT_STAGE_DATA(state, data) {
    state.currentStageData = data;
  },
  ASSET_ZIP_UPLOADER_STATUS(state, data) {
    state.assetZipUploaderStatus = data.status;
    if (data.assetType == 'assetFile' && data.status == 'completed') {
      Vue.set(state.assetZipUploadBasicProjectInfo, 'finalAssetsUploaded', true);
    } else if (data.assetType == 'sourceFile' && data.status == 'completed') {
      Vue.set(state.assetZipUploadBasicProjectInfo, 'sourceFilesUploaded', true);
    }
  },
  ASSET_ZIP_UPLOADER_VALIDITY(state, data) {
    state.validatedAssetZipUploaderState = data.state;
    if (data.data) {
      state.assetZipUploadBasicProjectInfo = data.data;
    }
  },
  SET_CAN_APPROVE_ASSET_TRUE(state, identifier) {
    let { currentStageData } = state;
    let path = '';
    let assetIdentifier = identifier.replace('data.', '');
    let identifierSplit = assetIdentifier.split('.');

    if (identifierSplit[identifierSplit.length - 1] == 'image_url') {
      path = assetIdentifier + '_approved';
    } else {
      path = assetIdentifier + 'Approved';
    }
    lodash.set(currentStageData, path, true);
    state.currentStageData = Object.assign({}, currentStageData);
  },
};

export default mutations;
