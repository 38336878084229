import { baseMixin } from './base';
import { validateAssetAuthorized } from '@/utils';
import InformationAndAssessment from '@/components/lessonPlanTemplate/InformationAndAssessment';
export const informationAndAssessmentLessonMixin = {
  components: { InformationAndAssessment },
  mixins: [baseMixin],
  data() {
    return {
      showSaveDialogPopup: false,
      Schema: {
        coverImageAllowedTimes: ['35 mins'],
        aim: {
          show: false,
        },
        action: {
          show: false,
        },
        analysis: {
          show: false,
        },
        application: {
          show: false,
        },
        assessment: {
          show: false,
        },
        reference: {
          show: false,
        },
        summary: {
          show: false,
        },
        informationAndAssessment: {
          show: true,
          showQuestion: true,
          showAnswer: true,
          showPrimaryDescription: true,
          showSecondaryDescription: true,
          showTeacherTipsDescription: true,
          teacherTipsDescriptionMaxLength: undefined,
        },
      },
    };
  },
  computed: {
    canShowEvaluateAndGrade() {
      return false;
    },
    worksheetEnabled() {
      return false;
    },
  },
  methods: {
    setRedirectRoute() {
      if (this.$route.params.dailyPlanLibraryId) {
        this.redirectionPathObject = {
          name: 'dailyPlanEditPage',
          params: {
            dailyPlanLibraryId: this.$route.params.dailyPlanLibraryId,
            projectId: this.$route.params.projectId,
          },
        };
      } else {
        this.redirectionPathObject = {
          path: '/projects/' + this.$route.params.projectId,
        };
      }
    },
    validateLPForm() {
      this.reassignFormInputs(this.$refs.form);

      if (
        this.$refs.form.validate() &&
        this.customValidations() &&
        this.assetAutorizingStageValidations() &&
        this.validateRTEText()
      ) {
        return true;
      } else {
        return false;
      }
    },
    assetAutorizingStageValidations() {
      try {
        if (this.currentStage == 'assetAuthorizingStage') {
          let unApprovedAssetCount = validateAssetAuthorized(
            this.lessonPlan.data,
            ['assetId'],
            {
              assetId: 'assetApproved',
            },
            ['brief', 'placeholder'],
            0
          );
          if (unApprovedAssetCount > 0) {
            this.$notify({
              title: 'Failed',
              text: `There are ${unApprovedAssetCount} unapproved assets`,
              type: 'error',
              duration: -1,
            });

            return false;
          }

          return true;
        } else {
          return true;
        }
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    getUnApprovedAssetSCountForInstructions(items) {
      var unApprovedAssetsCount = 0;

      for (var item of items) {
        for (var asset of item.assets) {
          if (asset.url) {
            const isApproved = asset.url_approved == true;

            if (!isApproved) unApprovedAssetsCount += 1;
          }
        }
      }

      return unApprovedAssetsCount;
    },
    prepareLessonPlanDataToSave() {},
    customValidations() {
      let lp = this.lessonPlan;
      if (!lp) throw Error('Invalid LessonPlan');
      if (!lp.data) throw Error('Invalid LessonPlan Data');
      if (!lp.data.lp_title || !lp.data.lp_description)
        throw Error('Title or Description cannot be empty');

      return true;
    },
    validateLPData() {
      return true;
    },
  },
};
