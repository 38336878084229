<template>
  <div>
    <section>
      <BlockTitle :title="title" />
      <div class="mgn-Tm mgn-Ls">
        <div class="mgn-Bs">{{ staticText }}</div>
      </div>
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDiscussionKeyQuestionChanged(diffKey)"
        :fieldIdentifier="keyQuestionFieldIdentifier"
        :placeholder="AppTexts.KEY_QUESTION"
        :showValidationError="true"
        v-model="lessonPlanData.analysis[sectionKey].key_question"
        @update:value="keyQuestion = $event"
        :readonly="!isNotReadOnly"
        :counter="schema.discussionKeyQuestionMaxLength"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <br />
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDiscussionKeyQuestionGuideChanged(diffKey)"
        :fieldIdentifier="keyQuestionGuideFieldIdentifier"
        :placeholder="AppTexts.KEY_QUESTION_GUIDE"
        :showValidationError="true"
        v-model="lessonPlanData.analysis[sectionKey].key_question_guide"
        @update:value="keyQuestionGuide = $event"
        :readonly="!isNotReadOnly"
        :counter="schema.discussionKeyQuestionGuideMaxLength"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <br />
    </section>

    <section>
      <BlockTitleWithSubtitle v-if="instructionsTitle" :subTitle="instructionsTitle" />
      <Step
        :items="instructions"
        :canShowStepNumber="true"
        :canShowInstructionBreakCheckbox="true"
        :hasSubStep="true"
        :maxNoOfSteps="schema.maxNoOfDiscussionSteps"
        :maxNoOfSubStepsInStep="schema.maxNoOfSubStepsDiscussionStep"
        :maxSumOfStepsAndSubSteps="schema.maxSumOfStepsAndSubDiscussionSteps"
        :supportAssets="true"
        :descriptionPlaceholder="AppTexts.EXPLANATION"
        :canShowAddNewStepButton="true"
        :allowDeleteStep="true"
        :allowDeleteSubStep="true"
        :canShowStepBullet="true"
        :canShowDiffIcon="canShowDiffIcon"
        :stepAssetIdentifier="`${instructionsIdentifier}.instructions`"
        :stepDiffIdentifier="`${instructionsIdentifier}.description`"
        :subStepDiffIdentifier="`${instructionsIdentifier}.instructions[<stepIndex>].children[<subStepIndex>].description`"
        :assetBriefDiffIdentifier="`${instructionsIdentifier}.instructions[<stepIndex>].assets[<assetIndex>].brief.text`"
        :assetImageDiffIdentifierPrefix="`${instructionsIdentifier}.instructions[<stepIndex>].assets[<assetIndex>]`"
        :addNewStepHandler="
          (index) => {
            addStep({ ...index, sectionKey });
          }
        "
        :deleteStepHandler="
          (index) => {
            deleteStep({ index, sectionKey });
          }
        "
        :addNewSubStepHandler="
          (index) => {
            addSubStep({ index, sectionKey });
          }
        "
        :deleteSubStepHandler="
          ({ stepIndex, subStepIndex }) => {
            deleteSubStep({ stepIndex, subStepIndex, sectionKey });
          }
        "
        :isStepDescriptionChanged="isStepDescriptionChanged"
        :isNotReadOnly="isNotReadOnly"
        :getPreviewUrl="getPreviewUrl"
        :showImageBrief="true"
        class="analysis-explanation-step"
        :canFinalizeAsset="true"
        :canAppproveAsset="canAppproveAsset"
        :isAssetUploadRequired="isAssetUploadRequired"
        :instructionTeacherTipDiffIdentifier="`${instructionsIdentifier}[<stepIndex>].teacher_tip`"
        :canShowInstructionTeacherTip="true"
        :canShowLastInstructionTeacherTip="false"
        :toggleTeacherTipFieldHandler="
          ({ stepIndex }) => {
            toggleTeacherTipField({ stepIndex, sectionKey });
          }
        "
        :stepDescriptionMaxLength="schema.discussionStepDescriptionMaxLength"
        :subStepDescriptionMaxLength="schema.discussionSubStepDescriptionMaxLength"
        :stepTeacherTipMaxLength="schema.discussionStepTeacherTipMaxLength"
      />
      <XSDisplayCard :compact="true" :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="true"
          :fieldIdentifier="`${instructionsIdentifier}.teacher_tip`"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="lessonPlanData.analysis[sectionKey].teacher_tip"
          :counter="schema.discussionTeacherTipMaxLength"
          customClass
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
      </XSDisplayCard>
      <v-divider class="mgn-Ts mgn-Bs" />
    </section>
  </div>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import BlockTitle from '@/components/inputs/BlockTitle';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import Step from '@/components/inputs/Step';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard.vue';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';

export default {
  name: 'DiscussionExplanation',
  components: {
    Step,
    RichTextEditor,
    BlockTitle,
    BlockTitleWithSubtitle,
    XSDisplayCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    staticText: {
      type: String,
      required: false,
    },
    instructionsTitle: {
      type: String,
    },
    lessonPlanData: {
      type: Object,
      required: true,
    },
    instructions: {
      type: Array,
      required: true,
    },
    diff: {
      type: Object,
      required: true,
    },
    diffKey: {
      type: String,
      required: true,
    },
    sectionKey: {
      type: String,
      required: true,
    },
    instructionsIdentifier: {
      type: String,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    keyQuestionFieldIdentifier: {
      type: String,
      required: true,
    },
    keyQuestionGuideFieldIdentifier: {
      type: String,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    addStep: {
      type: Function,
      required: true,
    },
    deleteStep: {
      type: Function,
      required: true,
    },
    addSubStep: {
      type: Function,
      required: true,
    },
    deleteSubStep: {
      type: Function,
      required: true,
    },
    canAppproveAsset: { type: Boolean, required: true },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleTeacherTipField: {
      type: Function,
    },
    analysisSignedUrls: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      AppTexts,
      BASIC_TOOLS,
    };
  },
  methods: {
    isDiscussionKeyQuestionChanged(discussionName) {
      try {
        if (this.canShowDiffIcon && this.analysisDiff[discussionName]) {
          return this.analysisDiff[discussionName].key_question.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDiscussionKeyQuestionGuideChanged(discussionName) {
      try {
        if (this.canShowDiffIcon && this.analysisDiff[discussionName]) {
          return this.analysisDiff[discussionName].key_question_guide.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedUrl: '',
        assetSignedBriefUrl: '',
        loadingStatus: 'in_progress',
      };
      if (this.analysisSignedUrls && this.analysisSignedUrls.instructions) {
        let asset = this.analysisSignedUrls.instructions[stepIndex]
          ? this.analysisSignedUrls.instructions[stepIndex].assets
          : '';

        if (asset) {
          if (this.isAssetUploadRequired) {
            previewUrl.assetSignedUrl = asset[assetIndex].assetSignedUrl;
          }
          previewUrl.assetSignedBriefUrl = asset[assetIndex].brief.assetSignedUrl;
          previewUrl.loadingStatus = asset[assetIndex].loadingStatus;
        }
      }

      return previewUrl;
    },
    isStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff[this.sectionKey].instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
};
</script>
