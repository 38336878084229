<template>
  <Padder :left="30" :right="10" :vertical="5" style="overflow: hidden;">
    <!-- Third Row -->
    <v-row>
      <SubSection title="Answer" />
    </v-row>
    <v-radio-group @change="changedOption" :key="rivision" :value="correctOption">
      <v-row
        v-for="(option, index) in data.questionData.options"
        :key="`mcqQuestionOptions${rivision}${index}`"
      >
        <Option
          :key="index"
          :data="option"
          :index="index"
          :deleteOption="deleteOption"
          :partialFieldIdentifier="`['${data._id}'].questionData.options`"
          :getToolBarActions="getToolBarActions"
          :options="options"
          :canAppproveAsset="canAppproveAsset"
          :forceAssetFinalized="forceAssetFinalized"
          :getRTEAcceptedFileType="getRTEAcceptedFileType"
          :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs"
        />
      </v-row>
    </v-radio-group>
    <v-row>
      <v-btn
        class="xs-accent-button font-weight-medium"
        width="165"
        @click="addNewOption"
        :disabled="data.questionData.options.length > 9"
        ><v-icon left>mdi-plus</v-icon> Add option</v-btn
      >
    </v-row>
  </Padder>
</template>

<script>
import Option from '@/components/contentEntry/questionComponents/dialogComponents/mcqComponents/Option.vue';
import SubSection from '@/components/inputs/SubSection.vue';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import _ from 'lodash';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
export default {
  components: {
    Option,
    SubSection,
  },
  data() {
    return {
      questionsOprogramProject,
      DEFAULT_QUESTION_DATA,
      rivision: 0,
    };
  },
  computed: {
    correctOption() {
      for (let i = 0; i < this.data.questionData.options.length; i++) {
        let { isCorrectAnswer } = this.data.questionData.options[i];
        if (isCorrectAnswer == true) {
          return i;
        }
      }
      return null;
    },
  },
  methods: {
    changedOption(val) {
      let j = 0;
      this.data.questionData.options.map((option) => {
        if (val == j) {
          option.isCorrectAnswer = true;
        } else {
          option.isCorrectAnswer = false;
        }
        j += 1;
      });
    },
    deleteOption(index) {
      this.data.questionData.options.splice(index, 1);
      this.rivision += 1;
    },
    addNewOption() {
      let defaultData = _.cloneDeep(
        DEFAULT_QUESTION_DATA[questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ]
      );
      if (this.modifyChangeQuestionBaseData) {
        defaultData = this.modifyChangeQuestionBaseData(defaultData);
      }
      this.data.questionData.options.push(defaultData.questionData.options[1]);
      this.rivision += 1;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    wrapAnswerBlockInContainer: {
      type: Boolean,
      default: false,
    },
    getToolBarActions: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    forceAssetFinalized: {
      type: Boolean,
      required: true,
    },
    getRTEAcceptedFileType: {
      type: Array,
      required: true,
    },
    getToolBarActionsForQuestionBriefs: {
      type: Array,
      required: true,
    },
    modifyChangeQuestionBaseData: {
      type: Function,
      default: undefined,
    },
  },
};
</script>

<style scoped>
.answerContainer {
  background: white;
  border-radius: 15px;
}
</style>
