<template>
  <div>
    <v-app-bar
      height="58px;"
      dense
      absolute
      color="white"
      elevation="2"
      scroll-target="#scrolling-techniques-7"
      class="action-bar"
    >
      <v-row>
        <div class="bread-crumb-row">
          <a href="/learnometer">Learnometer </a>
          <v-icon style="color: #818181;">mdi-chevron-right</v-icon>
          <a :href="questionSetPageLink">Question Authoring </a>
          <v-icon style="color: #818181;">mdi-chevron-right</v-icon>
          <a href="javascript:void(0);"><b>Create question</b></a>
        </div>
        <v-spacer></v-spacer>
        <span v-if="!auth.canSaveContent" class="read-only-mode-message">
          <img height="16" width="18" src="@/assets/alert.png" />
          <div style="float: right; margin-left: 8px;">
            This page cannot be edited, its in read only mode
          </div>
        </span>
      </v-row>
      <div v-if="!auth.canSaveContent" id="read-only-mode-banner">
        <img src="@/assets/readonly.png" />
      </div>
    </v-app-bar>
    <v-sheet
      style="margin-top: 92px; padding-left: 50px; padding-right: 50px;"
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
      <div
        :style="
          showRightSidePanel
            ? 'width: 65%; position: relative;'
            : ' width: 96%; position: relative;'
        "
      >
        <div>
          <span class="heading-text">Total Clones - {{ getTotalClonesCount() }}</span>
        </div>
        <div>
          <span class="sub-heading-text">
            Grade {{ getGradeName() }} - {{ getSubjectName() }}
          </span>
          <!-- <div style="position: relative; float: right;">
            <v-btn
              @click="openQuestionDialog"
              depressed
              color="primary"
              height="40"
              width="200"
              style="margin-top: -25px;"
            >
              ADD QUESTIONS</v-btn
            >
          </div> -->
        </div>
        <div style="margin-top: 20px;">
          <v-card class="mb-8" v-if="shouldShowMetaDataTile">
            <div style="display: flex; flex-direction: row; padding-bottom: 16px;">
              <div
                class="rounded"
                :style="`width: 5px; height: 23px; background: #BB161B; margin-top: 17px; margin-left: 10px`"
              ></div>
              <div style="margin-top: 15px; margin-left: 10px;">
                <span
                  style="font-size: 20px; font-weight: 400; color: #242424;"
                  v-if="!isGroupQuestion"
                >
                  Q{{ questionNumber }} - Metadata
                </span>
                <span
                  style="font-size: 20px; font-weight: 400; color: #242424;"
                  v-else-if="isGroupQuestion"
                >
                  {{ questionNumber }} - Metadata
                </span>
              </div>
            </div>
            <div style="padding-left: 9px; padding-right: 9px;">
              <hr style="border-top: 1px solid #979797; opacity: 0.5;" />
            </div>
            <v-row style="margin-left: 10px;">
              <v-col
                cols="3"
                v-for="(value, key) in getMetaData()"
                :key="`metaDataBoxLearnometerQuestion${key}`"
              >
                <div>
                  <span style="font-size: 16px; font-weight: 800; color: #242424;"
                    >{{ keyNameToLabelNameMap[value.label] || value.label }}:</span
                  >
                  <br />
                  <span style="font-size: 20px; font-weight: 400; color: #242424;">{{
                    value.value
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <div>
            <div
              v-for="(datum, index) in currentQuestionsForList"
              :key="`questionRendererForLearnometerGroupQuestion${index}`"
            >
              <XSQuestionRenderer
                :questionIndex="index"
                :datum="datum"
                :saveForm="handleQuestionSave"
                :options="{
                  isAssetUploadRequired: isAssetUploadRequired,
                  isReadOnly: !auth.canSaveContent,
                  customGroupQuestionCommentIdentifier: isGroupQuestion
                    ? `${datum[0].questionGroupId}.clone_${datum[0].cloneNumber}`
                    : '',
                }"
                :toggleComments="
                  (commentSectionIdentifier, projectType) =>
                    isGroupQuestion
                      ? toggleGroupComments(commentSectionIdentifier, projectType, datum)
                      : toggleComments(commentSectionIdentifier, projectType)
                "
                :isViewOnly="!auth.canSaveContent"
                :canShowActionButtons="auth.canSaveContent"
                :isSchoolProgramQuestion="false"
                :showMinimalDetails="!isGroupQuestion"
                :shouldEmitDeleteAction="auth.canSaveContent"
                :questionMetaInfo="{}"
                :convertIndividualToGroupQuestion="() => {}"
                :openQuestionDialog="editQuestionDialog"
                :openGroupQuestionDialog="
                  isGroupQuestion ? editGroupQuestionDialog : editQuestionDialog
                "
                :deleteGroupQuestion="() => {}"
                @remove-question-clicked="
                  (e) => (isGroupQuestion ? deleteGroupQuestion(e) : deleteQuestion(e))
                "
                lpType="learnometerQuestion"
                :minimalInfoPrefixText="isGroupQuestion ? 'Question' : 'Clone'"
                :cloneQuestionData="
                  showDemoQuestions && getTotalClonesCount() >= 2
                    ? undefined
                    : isGroupQuestion
                    ? cloneGroupQuestion
                    : isValidQuestion(datum)
                    ? cloneQuestion
                    : undefined
                "
                :deleteQuestion="() => {}"
                :cloneNotAvailableMessage="cloneNotAvailableMessage"
                :groupQuestionCardTitle="`Clone ${index + 1}`"
                :groupQuestionPreventIndividualQuestionDeletion="true"
                :showLearnometerGroupQuestionMetaData="isGroupQuestion"
                :dynamicMetaDataKeySourceDataMap="
                  isGroupQuestion ? getGroupQuestionMetaDataKeySourceDataMap(datum) : {}
                "
              />
              <br />
            </div>
          </div>
        </div>
        <div style="margin-top: 40px;">
          <QuestionDialog
            v-if="showDialog"
            :saveForm="handleQuestionSave"
            :options="{
              hideComments: true,
              disableQuestionTypeDropdown: true,
              hideDiff: true,
              isAssetUploadRequired: isAssetUploadRequired,
              metaDataKeyToLabelMap: keyNameToLabelNameMap,
            }"
            :toggleComments="() => {}"
            :isViewOnly="false"
            :canShowActionButtons="true"
            :canAppproveAsset="canAppproveAsset"
            lpType="learnometerQuestion"
            :lessonPlans="{}"
            :isLanguageSubject="false"
            :isSchoolProgramQuestion="false"
            :showMinimalDetails="true"
            :shouldEmitDeleteAction="true"
            :questionMetaInfo="{}"
            :convertIndividualToGroupQuestion="() => {}"
            :openQuestionDialog="editQuestionDialog"
            :openGroupQuestionDialog="() => {}"
            :deleteGroupQuestion="() => {}"
            @remove-question-clicked="deleteQuestion"
            minimalInfoPrefixText="Clone"
            :deleteQuestion="() => {}"
            :cloneNotAvailableMessage="cloneNotAvailableMessage"
            :showMetaFields="false"
            :subSectionTitle="`Questions - Q${questionNumber}`"
            :dynamicMetaDataKeySourceDataMap="getMetaDataKeySourceDataMap()"
            :turnOffQuestionTextValidation="showDemoQuestions"
          />
          <QuestionGroupDialog
            v-else-if="showGroupQuestionDialog"
            :saveForm="handleQuestionSave"
            :options="{
              hideComments: true,
              disableQuestionTypeDropdown: true,
              hideDiff: true,
              isAssetUploadRequired: isAssetUploadRequired,
              metaDataKeyToLabelMap: keyNameToLabelNameMap,
            }"
            :canAppproveAsset="canAppproveAsset"
            lpType="learnometerQuestion"
            :lessonPlans="[]"
            :isLanguageSubject="false"
            isSchoolProgramQuestion=""
            :modifyQuestionDataHash="{}"
            :checkIfOptionsWillNotBeRetained="() => {}"
            :showMetaFields="false"
            :dynamicMetaDataKeySourceDataMap="getMetaDataKeySourceDataMap()"
            :canAddNewQuestionToGroup="false"
            :canDeleteQuestionFromGroup="false"
          />
        </div>
      </div>
      <div style="align-item: center; width: 35%;" class="preview-wrapper">
        <div>
          <v-btn
            icon
            :style="
              showRightSidePanel ? 'right: 34%; margin-top: 10px;' : 'right: 3px; margin-top: 10px;'
            "
            class="toggle-preview-button"
            :title="showRightSidePanel ? 'Hide Preview' : 'Show Preview'"
            @click="toggleRightSidePanel()"
          >
            <v-icon large>
              {{
                showRightSidePanel ? 'mdi-arrow-right-drop-circle' : 'mdi-arrow-left-drop-circle'
              }}
            </v-icon>
          </v-btn>
        </div>
        <div class="right-side-panel-wrapper" v-if="showRightSidePanel">
          <div v-if="rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS">
            <div style="overflow: hidden; background-color: #f6f6f6; padding: 10px;">
              <span class="comment-identifier"
                >{{
                  !commentSectionHeaderText
                    ? capitalCase(commentSectionIdentifier)
                    : capitalCase(commentSectionHeaderText)
                }}
                Section</span
              >
              <span
                style="cursor: pointer; float: right; margin-right: 10px; color: #cb4c52;"
                @click="closeComments()"
              >
                CLOSE
                <v-icon title="close" color="#cb4c52">mdi-close</v-icon>
              </span>
            </div>
            <div v-if="commentsHash[commentSectionIdentifier].fetchStatus === 'in_progress'">
              <span style="padding: 10px;">
                {{ commentsHash[commentSectionIdentifier].message }}
              </span>
            </div>
            <div v-else-if="commentsHash[commentSectionIdentifier].fetchStatus === 'completed'">
              <Comments
                :comments="commentsHash[commentSectionIdentifier].comments"
                :currentStage="currentStage"
                :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
                :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
                :canAddComment="auth.canAddComment"
                :canResolveComment="auth.canResolveComment"
                :loggedInUserEmail="loggedInUserEmail || ''"
                :saveStatusesHash="commentsHash[commentSectionIdentifier].saveStatusesHash"
                :resolveStatusesHash="commentsHash[commentSectionIdentifier].resolveStatusesHash"
                :commentSectionIdentifier="commentSectionIdentifier"
              />
            </div>
            <div v-else-if="commentsHash[commentSectionIdentifier].fetchStatus === 'error'">
              {{ commentsHash[commentSectionIdentifier].message }}
            </div>
          </div>
          <div v-else><br /><br /><br /><b>PLEASE PRESS COMMENT BUTTON ON A QUESTION....</b></div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapMutations, mapState, mapActions } from 'vuex';
import QuestionDialog from '@/components/contentEntry/questionComponents/QuestionDailog.vue';
import { XSQuestionRenderer } from '@xseededucation/vue-question-display-component';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import { getAssetUploadRequiredStatus } from '@/utils';
import { isRteEmpty } from '@xseededucation/rte-helper-functions';
import { RIGHT_SIDE_PREVIEW_CONTENT_TYPE } from '@/constants/LessonPlan';
import Comments from '@/components/comments/Comments';
import { capitalCase } from 'change-case';
import QuestionGroupDialog from '@/components/contentEntry/questionComponents/QuestionGroupDialog.vue';
export default {
  name: 'Question',
  created() {
    this.getLearnometerProjectDetails({
      projectId: this.projectId,
      yearId: this.$route.params.selectedYear,
    });
    this.fetchAirtableBigSkills();
    this.fetchAirtableSubSkills();
    this.fetchAirtableThemes();
    this.fetchAirtableMicrotopics();
    this.fetchAirtableMicroskillCategories();
    this.setRightSidePanelVisibility(false);
    this.getCurrentUserContentEntryProjectFormPermissions({
      projectId: this.projectId,
      contentLibraryId: 0,
    });
    this.fetchStandards({ allStandards: true });
  },
  components: {
    QuestionDialog,
    XSQuestionRenderer,
    Comments,
    QuestionGroupDialog,
  },
  data() {
    return {
      DEFAULT_QUESTION_DATA,
      RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
      questionsOprogramProject,
      currentQuestionsForList: [],
      bigSkillIdToSkillNameMap: {},
      subSkillIdToSkillNameMap: {},
      microskillCategoryIdToMicroskillCategoryNameMap: {},
      updatedQuestion: [],
      isAssetUploadRequired: false,
      commentSectionIdentifier: '',
      themeIdToThemeNameMap: {},
      microtopicIdToMicrotopicNameMap: {},
      microtopicIdToSkillAssessedMap: {},
      standardIdToNameMap: {},
      keyNameToLabelNameMap: {
        primaryBigSkillId: 'Primary Big Skill',
        primarySubSkillId: 'Primary Sub Skill',
        secondaryBigSkillId: 'Secondary Big Skill',
        secondarySubSkillId: 'Secondary Sub Skill',
        themeId: 'Theme',
        microtopicId: 'Microtopic',
        score: 'score',
        microskillCategoryId: 'Microskill Category',
        stage: 'Item Grade Level',
        difficulty: 'Difficulty',
        microskillAssessed: 'Microskill Assessed',
      },
    };
  },
  watch: {
    currentStageData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.setQuestionInitialDataToStore(val);
        }
      },
    },
    questionList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.updatedQuestion = _.cloneDeep(val);
          if (!this.isGroupQuestion) {
            this.currentQuestionsForList = val.filter(
              (_v) =>
                _v.globalQuestionId == this.getGlobalQuestionNumber &&
                (this.showDemoQuestions ? _v.isDemoQuestion : !_v.isDemoQuestion)
            );
          } else {
            this.popuateCurrentQuestionsForListForGroupQuestion(val);
          }
        }
      },
    },
    bigSkillsData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.bigSkillIdToSkillNameMap = val.reduce((accu, current) => {
            if (!accu[current.id]) {
              accu[current.id] = current.name;
            }
            return accu;
          }, {});
        }
      },
    },
    subSkillsData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.subSkillIdToSkillNameMap = val.reduce((accu, current) => {
            if (!accu[current.id]) {
              accu[current.id] = current.name;
            }
            return accu;
          }, {});
        }
      },
    },
    currentStage: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isAssetUploadRequired = this.getAssetUploadRequiredStatus(val);
        }
      },
    },
    themesData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.themeIdToThemeNameMap = val.reduce((accu, current) => {
            if (!accu[current.id]) {
              accu[current.id] = current.name;
            }
            return accu;
          }, {});
        }
      },
    },
    microtopicsData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.microtopicIdToMicrotopicNameMap = val.reduce((accu, current) => {
            if (!accu[current.id]) {
              accu[current.id] = current.name;
            }
            return accu;
          }, {});
          this.microtopicIdToSkillAssessedMap = val.reduce((accu, current) => {
            if (!accu[current.id]) {
              accu[current.id + '_Understanding'] = current.understandingMicroskill;
              accu[current.id + '_Doing'] = current.doingMicroskill;
            }
            return accu;
          }, {});
        }
      },
    },
    microskillCategoriesData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.microskillCategoryIdToMicroskillCategoryNameMap = val.reduce((accu, current) => {
            if (!accu[current.id]) {
              accu[current.id] = current.name;
            }
            return accu;
          }, {});
        }
      },
    },
    standardData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.standardIdToNameMap = val.reduce((accu, current) => {
            if (!accu[current.id]) {
              accu[current.id] = current.name;
            }
            return accu;
          }, {});
        }
      },
    },
  },
  computed: {
    ...mapState({
      showDialog: (state) => state.questions.showQuestionDialog,
      showGroupQuestionDialog: (state) => state.questions.showGroupQuestionDialog,
      currentStageData: (state) => state.contentEntry.currentStageData,
      questionList: (state) => state.questions.questionData,
      bigSkillsData: (state) => state.learnometer.airtableBigSkillsApiResponse.data,
      subSkillsData: (state) => state.learnometer.airtableSubSkillsApiResponse.data,
      projectDetails: (state) => state.contentEntry.projectDetails,
      currentStage: (state) => state.contentEntry.contentData.currentStage,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      auth: (state) => state.auth.contentEntryProjectForm,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      showRightSidePanel: (state) => state.rightSideContent.showRightSidePanel,
      rightSidePreviewContent: (state) => state.rightSideContent.rightSidePreviewContent,
      themesData: (state) => state.learnometer.airtableThemesApiResponse.data,
      microtopicsData: (state) => state.learnometer.airtableMicrotopicsApiResponse.data,
      microskillCategoriesData: (state) =>
        state.learnometer.airtableMicroskillsCategoiesOnApiResponse.data,
      standardData: (state) => state.learnometer.standardsListApiResponse.data,
    }),

    shouldShowMetaDataTile() {
      return !this.showDemoQuestions && !this.isGroupQuestion;
    },
    showDemoQuestions() {
      return this.$route.query.showDemoQuestions;
    },
    getFirstQuestion() {
      return this.isGroupQuestion
        ? this.currentQuestionsForList[0][0] || {}
        : this.currentQuestionsForList[0] || {};
    },

    questionNumber() {
      return this.$route.params.questionSequenceNumber;
    },

    getGlobalQuestionNumber() {
      return this.$route.params.globalQuestionId;
    },

    projectId() {
      return this.$route.params.id;
    },
    isGroupQuestion() {
      return this.$route.query.isGroupQuestion || false;
    },
    questionSetPageLink() {
      return `/learnometer/${this.projectId}`;
    },
    canAppproveAsset() {
      return this.currentStage == 'assetAuthorizingStage';
    },
    cloneNotAvailableMessage() {
      return this.showDemoQuestions
        ? 'A demo question cannot have more than two clones'
        : undefined;
    },
    getGlobalQuestionIdObject() {
      let returnObject = {};
      if (this.isGroupQuestion) {
        for (let item of this.currentQuestionsForList[0]) {
          returnObject[item.globalQuestionId] = item.globalQuestionId;
        }
      } else {
        returnObject = {
          [this.currentQuestionsForList[0].globalQuestionId]: this.currentQuestionsForList[0]
            .globalQuestionId,
        };
      }

      return returnObject;
    },
  },
  methods: {
    ...mapActions([
      'getContentEntryProjectDetails',
      'fetchAirtableBigSkills',
      'fetchAirtableSubSkills',
      'saveCurrentContentData',
      'setQuestionInitialDataToStore',
      'deleteContentEntryQuestion',
      'questionForceSaveWithData',
      'getCurrentUserContentEntryProjectFormPermissions',
      'setRightSidePanelVisibility',
      'setRightSidePreviewContent',
      'fetchProjectLessonPlanSectionComment',
      'toggleRightSidePanel',
      'saveComment',
      'resolveComment',
      'fetchAirtableThemes',
      'fetchAirtableMicrotopics',
      'fetchAirtableMicroskillCategories',
      'fetchStandards',
      'deleteContentEntryGroupQuestionWithClone',
      'getLearnometerProjectDetails',
    ]),
    ...mapMutations([
      'SET_SHOW_QUESTION_DIALOG',
      'SET_CURRENT_QUESTION_DIALOG_DATA',
      'SET_SHOW_GROUP_QUESTION_DIALOG',
    ]),
    capitalCase,
    getAssetUploadRequiredStatus,
    getMetaDataKeyToLabelMap() {
      return this.keyNameToLabelNameMap;
    },
    cloneQuestion(question) {
      let clonedQuestion = _.cloneDeep(question);
      let originalQuestion = clonedQuestion._id;
      delete clonedQuestion._id;
      clonedQuestion.originalQuestionId = originalQuestion;
      clonedQuestion.cloneNumber = this.currentQuestionsForList.length + 1;
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: clonedQuestion });
      this.SET_SHOW_QUESTION_DIALOG(true);
    },
    cloneGroupQuestion(questionData) {
      let clonedQuestion = _.cloneDeep(questionData);
      clonedQuestion.forEach((_c) => {
        _c.originalQuestionId = _c._id;
        _c.cloneNumber = _c.cloneNumber + 1;
        delete _c._id;
      });
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: clonedQuestion });
      this.SET_SHOW_GROUP_QUESTION_DIALOG(true);
    },
    openQuestionDialog() {
      this.SET_SHOW_QUESTION_DIALOG(true);
      let clonedQuestion = _.cloneDeep(this.getFirstQuestion);
      const clonedDefaultQuestion = _.cloneDeep(
        DEFAULT_QUESTION_DATA[[questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ]]
      );
      let originalQuestion = clonedQuestion._id;
      delete clonedQuestion._id;
      clonedQuestion.originalQuestionId = originalQuestion;
      clonedQuestion.questionData = clonedDefaultQuestion.questionData;
      clonedQuestion.cloneNumber = this.currentQuestionsForList.length + 1;
      // if (!this.showDemoQuestions) {
      //   clonedQuestion.metadata.score = this.getFirstQuestion.score;
      //   clonedQuestion.metadata.weightage = this.getFirstQuestion.weightage;
      //   clonedQuestion.metadata.stage = this.getFirstQuestion.stage;
      // }
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: clonedQuestion });
    },
    editQuestionDialog(data) {
      this.SET_SHOW_QUESTION_DIALOG(true);
      // if (!this.showDemoQuestions) {
      //   data.metadata.score = this.getFirstQuestion.score;
      //   data.metadata.weightage = this.getFirstQuestion.weightage;
      //   data.metadata.stage = this.getFirstQuestion.stage;
      // }
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: data });
    },
    editGroupQuestionDialog(data) {
      this.SET_SHOW_GROUP_QUESTION_DIALOG(true);
      this.SET_CURRENT_QUESTION_DIALOG_DATA({ data: data });
    },
    saveForm(data, showLoader = true) {
      if (!this.isGroupQuestion) {
        //TODO: Alter this logic
        let currentQuestion = data
          .filter((_t) => (this.showDemoQuestions ? _t.isDemoQuestion : !_t.isDemoQuestion))
          .find((_v) => _v.questionSequenceNumber == this.$route.params.questionSequenceNumber);
        currentQuestion.isDummyQuestion = false;
      }
      this.saveCurrentContentData({
        data: data.map((_d) => {
          if (_d.metadata) {
            delete _d.metadata.score;
            delete _d.metadata.weightage;
            delete _d.metadata.stage;
          }
          return _d;
        }),
        projectId: this.projectId,
        loader: showLoader,
        banner: showLoader,
      });
    },
    handleQuestionSave(payload) {
      const { data, showLoader } = payload;
      this.saveForm(data, showLoader);
    },
    getMetaDataKeySourceDataMap() {
      return {
        primaryBigSkillId: this.bigSkillIdToSkillNameMap,
        primarySubSkillId: this.subSkillIdToSkillNameMap,
        secondaryBigSkillId: this.bigSkillIdToSkillNameMap,
        secondarySubSkillId: this.subSkillIdToSkillNameMap,
        themeId: this.themeIdToThemeNameMap,
        microtopicId: this.microtopicIdToMicrotopicNameMap,
        score: { [this.getFirstQuestion.score]: this.getFirstQuestion.score },
        weightage: { [this.getFirstQuestion.weightage]: this.getFirstQuestion.weightage },
        stage: {
          [this.getFirstQuestion.stage]: this.standardIdToNameMap[this.getFirstQuestion.stage],
        },
        microskillCategoryId: this.microskillCategoryIdToMicroskillCategoryNameMap,
        globalQuestionId: this.getGlobalQuestionIdObject,
        difficulty: { Easy: 'Easy', Medium: 'Medium', Hard: 'Hard' },
        microskillAssessed: this.microtopicIdToSkillAssessedMap,
      };
    },

    getGroupQuestionsMetaDataKeySourceDataMap() {
      return {
        primaryBigSkillId: this.bigSkillIdToSkillNameMap,
        primarySubSkillId: this.subSkillIdToSkillNameMap,
        secondaryBigSkillId: this.bigSkillIdToSkillNameMap,
        secondarySubSkillId: this.subSkillIdToSkillNameMap,
        themeId: this.themeIdToThemeNameMap,
        microtopicId: this.microtopicIdToMicrotopicNameMap,
        score: this.score,
        weightage: this.weightage,
        microskillCategoryId: this.microskillCategoryIdToMicroskillCategoryNameMap,
        difficulty: { Easy: 'Easy', Medium: 'Medium', Hard: 'Hard' },
        microskillAssessed: this.microtopicIdToSkillAssessedMap,
        globalQuestionId: this.getGlobalQuestionIdObject,
      };
    },
    mappedMetaData(datum) {
      const metaDataKeyToSourceMap = this.getGroupQuestionsMetaDataKeySourceDataMap();
      const metaDataArray = Object.keys(datum.metadata || {})
        .filter((key) => key !== 'microSkillCategoryId')
        .map((key) => {
          const dynamicDataMap = metaDataKeyToSourceMap[key];
          const metaDataValue = dynamicDataMap ? dynamicDataMap[datum.metadata[key]] : 'NA';
          return {
            label: key,
            value: metaDataValue,
          };
        });

      metaDataArray.push({
        label: 'score',
        value: datum.score,
      });

      metaDataArray.push({
        label: 'weightage',
        value: datum.weightage,
      });

      metaDataArray.push({
        label: 'microskillAssessed',
        value: this.microtopicIdToSkillAssessedMap[
          datum.metadata.microtopicId +
            '_' +
            this.microskillCategoryIdToMicroskillCategoryNameMap[
              datum.metadata.microskillCategoryId
            ]
        ],
      });
      metaDataArray.push({
        label: 'globalQuestionId',
        value: datum.globalQuestionId,
      });

      return metaDataArray;
    },

    getGroupQuestionMetaDataKeySourceDataMap(datum) {
      const metaDataArray = [];
      datum.forEach((item) => {
        const metaDataKeyToSourceMapForGroupQuestion = this.mappedMetaData(item);
        let mapGroupQuestionLabelWithTitle = this.getQuestionTitleDisplayText(
          metaDataKeyToSourceMapForGroupQuestion
        );
        metaDataArray.push(mapGroupQuestionLabelWithTitle);
      });

      return metaDataArray;
    },
    getMetaData() {
      let metaDataKeyToSourceMap = this.getMetaDataKeySourceDataMap();
      let metaDataArray = Object.keys(this.getFirstQuestion.metadata || {}).map((_k) => {
        return {
          label: _k,
          value: (metaDataKeyToSourceMap[_k] || {})[this.getFirstQuestion.metadata[_k]] || 'NA',
        };
      });

      metaDataArray.push({
        label: 'Score',
        value: this.getFirstQuestion.score,
      });

      metaDataArray.push({
        label: 'Weightage',
        value: this.getFirstQuestion.weightage,
      });

      metaDataArray.push({
        label: 'Global Question ID',
        value: this.getFirstQuestion.globalQuestionId,
      });

      metaDataArray.push({
        label: 'Microskill Assessed',
        value: this.microtopicIdToSkillAssessedMap[
          this.getFirstQuestion.metadata.microtopicId +
            '_' +
            this.microskillCategoryIdToMicroskillCategoryNameMap[
              this.getFirstQuestion.metadata.microskillCategoryId
            ]
        ],
      });

      return metaDataArray;
    },

    getQuestionTitleDisplayText(labelList) {
      labelList.forEach((item) => {
        switch (item.label) {
          case 'difficulty':
            item.label = 'Difficulty';
            break;
          case 'microskillCategoryId':
            item.label = 'Microskill Category';
            break;
          case 'microtopicId':
            item.label = 'Microtopic Name';
            break;
          case 'primaryBigSkillId':
            item.label = 'Primary Big Skill';
            break;
          case 'primarySubSkillId':
            item.label = 'Primary Sub Skill';
            break;
          case 'secondaryBigSkillId':
            item.label = 'Secondary Big Skil';
            break;
          case 'secondarySubSkillId':
            item.label = 'Secondary Sub Skill';
            break;
          case 'themeId':
            item.label = 'Theme';
            break;
          case 'score':
            item.label = 'Score';
            break;
          case 'weightage':
            item.label = 'Weightage';
            break;
          case 'globalQuestionId':
            item.label = 'Global Question ID';
            break;
          case 'microskillAssessed':
            item.label = 'Microskill Assessed';
            break;
          default:
            item.label = 'NA';
            break;
        }
      });
      return labelList;
    },
    deleteGroupQuestion(questions) {
      if (questions[0].cloneNumber == 1) {
        this.$notify({
          title: 'Error!',
          text: 'The first question clone cannot be removed',
          type: 'error',
          clean: false,
          duration: -1,
        });
        return;
      }
      let currentCloneNumber = questions[0].cloneNumber;
      let questionGroupId = questions[0].questionGroupId;
      let groupQuestionDuplicate = JSON.parse(JSON.stringify(questions));
      this.deleteContentEntryGroupQuestionWithClone({
        questionGroupId,
        onSuccess: () => {
          setTimeout(() => {
            this.updatedQuestion.forEach((_c) => {
              if (
                _c.questionGroupId == this.questionNumber &&
                _c.cloneNumber > currentCloneNumber
              ) {
                _c.cloneNumber -= 1;
              }
            });
            groupQuestionDuplicate.forEach((_q) => {
              _q.isDeleted = true;
            });
            let questionToUpdate = JSON.parse(JSON.stringify(this.updatedQuestion));
            questionToUpdate.push(...groupQuestionDuplicate);
            this.saveForm(questionToUpdate);
          }, 500);
        },
        cloneNumber: currentCloneNumber,
      });
    },
    deleteQuestion(question) {
      if (!question.originalQuestionId) {
        this.$notify({
          title: 'Error!',
          text: 'The first question clone cannot be removed',
          type: 'error',
          clean: false,
          duration: -1,
        });
        return;
      }
      let currentCloneNumber = question.cloneNumber;
      let questionDuplicate = question;

      this.deleteContentEntryQuestion({
        questionId: question._id,
        onSuccess: () => {
          setTimeout(() => {
            this.updatedQuestion.forEach((_c) => {
              if (
                _c.globalQuestionId == this.getGlobalQuestionNumber &&
                _c.cloneNumber > currentCloneNumber
              ) {
                _c.cloneNumber -= 1;
              }
            });
            questionDuplicate.isDeleted = true;
            let questionToUpdate = JSON.parse(JSON.stringify(this.updatedQuestion));
            questionToUpdate.push(questionDuplicate);
            this.saveForm(questionToUpdate);
          }, 500);
        },
      });
    },
    getTotalClonesCount() {
      return this.currentQuestionsForList.length;
    },
    getGradeName() {
      return ((this.projectDetails || {}).standard || {}).name || 'NA';
    },
    getSubjectName() {
      return ((this.projectDetails || {}).subject || {}).name || 'NA';
    },
    isValidQuestion(datum) {
      return !datum.isDummyQuestion && this.validateIsNotQuestionEmpty(datum);
    },
    validateIsNotQuestionEmpty(datum) {
      if (isRteEmpty(datum.questionData.questionText)) return false;
      let isDataPresent = true;
      for (const options of datum.questionData.options) {
        if (isRteEmpty(options.optionText)) {
          isDataPresent = false;
          break;
        }
      }
      if (!isDataPresent) return false;
      return true;
    },
    toggleGroupComments(commentSectionIdentifier, projectType = null, questionData) {
      this.setRightSidePanelVisibility(true);
      if (
        commentSectionIdentifier == this.commentSectionIdentifier &&
        this.rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS
      ) {
        return true;
      } else {
        this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS);
        this.commentSectionIdentifier = commentSectionIdentifier;
      }
      this.commentSectionHeaderText = `Question Clone ${questionData[0].cloneNumber}`;

      if (this.rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS) {
        this.fetchProjectLessonPlanSectionComment({
          projectId: this.projectId,
          libraryId: 0,
          sectionIdentifier: commentSectionIdentifier,
          projectType: projectType,
        });
      }
    },
    toggleComments(commentSectionIdentifier, projectType = null) {
      this.setRightSidePanelVisibility(true);
      if (
        commentSectionIdentifier == this.commentSectionIdentifier &&
        this.rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS
      ) {
        return true;
      } else {
        this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS);
        this.commentSectionIdentifier = commentSectionIdentifier;
      }
      if (projectType == 'questions') {
        let question = this.currentQuestionsForList.find(
          (_question) => _question._id == commentSectionIdentifier
        );
        this.commentSectionHeaderText = `Question Clone ${question.cloneNumber}`;
      }

      if (this.rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS) {
        this.fetchProjectLessonPlanSectionComment({
          projectId: this.projectId,
          libraryId: 0,
          sectionIdentifier: commentSectionIdentifier,
          projectType: projectType,
        });
      }
    },
    closeComments() {
      this.setRightSidePanelVisibility(false);
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS);
      this.commentSectionIdentifier = '';
    },
    commentsSaveButtonClickHandler(text, stageName) {
      if (this.auth.canAddComment) {
        this.saveComment({
          projectId: this.projectId,
          sectionIdentifier: this.commentSectionIdentifier,
          text,
          stageName,
        });
      }
    },
    commentsResolveButtonClickHandler(stageName) {
      if (this.auth.canResolveComment) {
        this.resolveComment({
          projectId: this.projectId,
          libraryId: 0,
          sectionIdentifier: this.commentSectionIdentifier,
          stageName,
        });
      }
    },
    popuateCurrentQuestionsForListForGroupQuestion(val) {
      this.currentQuestionsForList = [];

      let groupQuestions = val.filter(
        (_v) => _v.questionGroupId && _v.questionGroupId == this.questionNumber
      );
      let questionsGroupedByCloneNumber = _.groupBy(groupQuestions, (o) => o.cloneNumber);
      Object.keys(questionsGroupedByCloneNumber).forEach((_c) => {
        let questionSortedBySequenceNumber = _.sortBy(
          questionsGroupedByCloneNumber[_c],
          'questionSequenceInGroup'
        );
        this.currentQuestionsForList.push(questionSortedBySequenceNumber);
      });
    },
    getMicroskillAssessed() {
      let microskillCategory = this.microskillCategoryIdToMicroskillCategoryNameMap[
        this.getFirstQuestion.metadata.microskillCategoryId
      ];
      let currentMicroskillData = this.microtopicIdToSkillAssessedMap[
        this.getFirstQuestion.metadata.microtopicId
      ];
      return currentMicroskillData[microskillCategory.toLowerCase() + 'Microskill'];
    },
  },
};
</script>
<style scoped>
.sub-heading-text {
  font-size: 20px;
  font-weight: 400;
  color: #242424;
}
.heading-text {
  font-size: 20px;
  font-weight: 700;
  color: #242424;
}
.bread-crumb-row p {
  width: 5px;
  height: 9px;
  padding: 9px 10px;
  color: #979797;
}
.bread-crumb-row a {
  cursor: pointer;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #242424;
  opacity: 0.7;
}
.bread-crumb-row {
  display: flex;
  flex-direction: row;
}
#read-only-mode-banner {
  position: fixed;
}
#read-only-mode-banner img {
  opacity: 0.1;
  position: fixed;
}
#read-only-mode-banner img {
  transform: rotate(-20deg);
  top: 40vh;
  left: -5px;
  width: 96vw;
}
.toggle-preview-button {
  position: fixed;
  top: 120px;
  z-index: 1;
}
.preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.right-side-panel-wrapper {
  margin-top: 120px;
  position: fixed;
  top: 0px;
  right: 0px;
  display: inline-block;
  overflow: scroll;
  width: 35%;
  padding: 2px;
  height: 83vh;
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.3);
}
.comment-identifier {
  font-size: 20px;
  font-weight: 500;
}
</style>
