export const groupBy = (arrayOfObjects, key) => {
  return arrayOfObjects.reduce(function(_tempHash, _object) {
    (_tempHash[_object[key]] = _tempHash[_object[key]] || []).push(_object);
    return _tempHash;
  }, {});
};

export const sortBy = (arrayOfObjects, key) => {
  return arrayOfObjects.sort(function(a, b) {
    return a[key] - b[key];
  });
};

export const sortByFnForArrayOfObjectsByStringProperty = (arrayOfObjects, key) => {
  return arrayOfObjects.sort(function(a, b) {
    let _a = a[key] || '',
      _b = b[key] || '';
    if (_a < _b) return -1;
    else if (_a > _b) return 1;
    else return 0;
  });
};
