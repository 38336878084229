<template>
  <div v-if="processDataFlag">
    <v-row>
      <v-col cols="3">
        <v-select
          :items="items"
          v-model="selected_layout"
          @change="changeLayout"
          outlined
          filled
          class="xs-v-select"
          item-text="title"
          item-value="value"
          :hide-details="false"
          :menu-props="{
            contentClass: 'xs-v-layout-select',
            overflowY: true,
            bottom: true,
            nudgeBottom: '55',
            maxHeight: '400px',
          }"
        >
          <template slot="item" slot-scope="data">
            <div style="color: rgba(36, 36, 36, 0.5) !important;">
              <img :src="data.item.image" width="150" />
              <h2>{{ data.item.title }}</h2>
              <p>{{ data.item.subtitle }}</p>
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <div class="layout1" v-if="selected_layout == 'Layout1'">
      <RichTextEditor
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        v-model="data.layoutData.text"
        :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.text`"
        :key="`layout1-0${fieldIdentifierPrefix}`"
        :showValidationError="options.validationFlag && isValidationRequired"
        :counter="450"
        :wordCounter="75"
        :allowWordCount="true"
        :allowCharacterCount="true"
      />
    </div>
    <div class="layout2" v-else-if="selected_layout == 'Layout2'">
      <RichTextEditor
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        v-model="data.layoutData.text"
        :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.text`"
        :counter="120"
        :wordCounter="20"
        :allowWordCount="true"
        :allowCharacterCount="true"
      />
      <v-row>
        <v-col cols="4" v-for="index in 3" :key="index">
          <Padder :right="5">
            <FileInput
              :canShowDiffIcon="options.canShowDiffIcon"
              :fileAcceptType="options.isAssetUploadRequired ? 'image/*' : options.fileAcceptType"
              label="image"
              :loadingStatus="
                selectedIndex == index &&
                options.fileUploadingStatus.slider_action[itemIndex] == 'in_progress'
              "
              :isRequired="
                isValidationRequired && index == 1 ? options.isAssetUploadRequired : false
              "
              :isAssetUploadRequired="options.isAssetUploadRequired"
              :fileChangeHandler="
                (file) => fileUploadHandler(file, type, getAssetPath(index), index)
              "
              :previewUrl="getAssetUrl(index, 'imageUrl')"
              :previewUrlBrief="getAssetUrl(index, 'briefUrl')"
              :diffIdentifier="`${fieldIdentifierPrefix}.layoutData.images[${index - 1}].${
                options.isAssetUploadRequired ? 'imageUrl' : 'briefUrl'
              }`"
              :image_url="getAssetPathBasedOnKey(index, 'imageUrl')"
              :image_url_brief="getAssetPathBasedOnKey(index, 'briefUrl')"
              :isNotReadOnly="options.isNotReadOnly"
              @addBriefToAsset="
                (previewUrl, briefUrl, approveAsset) =>
                  addToAsset(previewUrl, briefUrl, approveAsset, type, index - 1)
              "
              :canAppproveAsset="canAppproveAsset"
              :isAssetApproved="getAssetPathBasedOnKey(index, 'imageUrlApproved')"
              :canFinalizeAsset="true"
            />
            <Padder>
              <TextInput
                :enableTextFormatting="false"
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.images[${
                  index - 1
                }].imageTitle`"
                :placeholder="'Image Title 1'"
                v-model="data.layoutData.images[index - 1].imageTitle"
                customClass="xs-dummy"
                :counter="20"
              />
            </Padder>
            <Padder :bottom="10">
              <RichTextEditor
                :readonly="options.isReadOnly || options.isAssetUploadRequired"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.layoutData.images[index - 1].brief"
                :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.images[${index - 1}].brief`"
                :key="`${fieldIdentifierPrefix}.layoutData.images[${index - 1}].brief`"
                :showValidationError="
                  !options.isAssetUploadRequired &&
                  options.validationFlag &&
                  isValidationRequired &&
                  index == 1
                "
                :counter="3000"
                :allowWordCount="false"
                :allowCharacterCount="true"
                placeholder="Reference Image Brief"
                :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
              />
            </Padder>
          </Padder>
        </v-col>
      </v-row>
    </div>
    <div class="layout3" v-else-if="selected_layout == 'Layout3'">
      <RichTextEditor
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        v-model="data.layoutData.headerText"
        :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.headerText`"
        :counter="120"
        :wordCounter="20"
        :allowWordCount="true"
        :allowCharacterCount="true"
      />
      <v-row>
        <v-col cols="4" v-for="index in 3" :key="index">
          <Padder :right="5">
            <FileInput
              :canShowDiffIcon="options.canShowDiffIcon"
              :fileAcceptType="options.isAssetUploadRequired ? 'image/*' : options.fileAcceptType"
              label="Image"
              :loadingStatus="
                selectedIndex == index &&
                options.fileUploadingStatus.slider_action[itemIndex] == 'in_progress'
              "
              :isRequired="
                isValidationRequired && index == 1 ? options.isAssetUploadRequired : false
              "
              :diffIdentifier="`${fieldIdentifierPrefix}.layoutData.images[${index - 1}].${
                options.isAssetUploadRequired ? 'imageUrl' : 'briefUrl'
              }`"
              :isAssetUploadRequired="options.isAssetUploadRequired"
              :fileChangeHandler="
                (file) => fileUploadHandler(file, type, getAssetPath(index), index)
              "
              :previewUrl="getAssetUrl(index, 'imageUrl')"
              :previewUrlBrief="getAssetUrl(index, 'briefUrl')"
              :image_url="getAssetPathBasedOnKey(index, 'imageUrl')"
              :image_url_brief="getAssetPathBasedOnKey(index, 'briefUrl')"
              :isNotReadOnly="options.isNotReadOnly"
              @addBriefToAsset="
                (previewUrl, briefUrl, approveAsset) =>
                  addToAsset(previewUrl, briefUrl, approveAsset, type, index - 1)
              "
              :canAppproveAsset="canAppproveAsset"
              :isAssetApproved="getAssetPathBasedOnKey(index, 'imageUrlApproved')"
              :canFinalizeAsset="true"
            />
            <Padder>
              <TextInput
                :enableTextFormatting="false"
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.images[${
                  index - 1
                }].imageTitle`"
                :placeholder="'Image Title'"
                v-model="data.layoutData.images[index - 1].imageTitle"
                customClass="xs-dummy"
                :counter="20"
              />
            </Padder>
            <Padder :bottom="10">
              <RichTextEditor
                :readonly="options.isReadOnly || options.isAssetUploadRequired"
                :canShowDiffIcon="options.canShowDiffIcon"
                v-model="data.layoutData.images[index - 1].brief"
                :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.images[${index - 1}].brief`"
                :key="`${fieldIdentifierPrefix}.layoutData.images[${index - 1}].brief`"
                :showValidationError="
                  !options.isAssetUploadRequired &&
                  options.validationFlag &&
                  isValidationRequired &&
                  index == 1
                "
                :counter="3000"
                :allowWordCount="false"
                :allowCharacterCount="true"
                placeholder="Reference Image Brief"
                :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
              />
            </Padder>
          </Padder>
        </v-col>
      </v-row>
      <RichTextEditor
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        v-model="data.layoutData.footerText"
        :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.footerText`"
        :key="`layout3-1${fieldIdentifierPrefix}`"
        :showValidationError="options.validationFlag && isValidationRequired"
        :counter="120"
        :wordCounter="20"
        :allowWordCount="true"
        :allowCharacterCount="true"
      />
    </div>
    <div class="layout4" v-else-if="selected_layout == 'Layout4'">
      <v-row>
        <v-col cols="6">
          <Padder :right="5" :vertical="0">
            <RichTextEditor
              :readonly="options.isReadOnly"
              :canShowDiffIcon="options.canShowDiffIcon"
              :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData[0].text`"
              :placeholder="'Text'"
              v-model="data.layoutData[0].text"
              :key="`layout4-0${fieldIdentifierPrefix}`"
              :showValidationError="options.validationFlag && isValidationRequired"
              :counter="260"
              :wordCounter="50"
              :allowWordCount="true"
              :allowCharacterCount="true"
            />
          </Padder>
        </v-col>
        <v-col cols="6">
          <FileInput
            class="xs-layout layout4-img"
            :canShowDiffIcon="options.canShowDiffIcon"
            :fileAcceptType="options.isAssetUploadRequired ? 'image/*' : options.fileAcceptType"
            label="Image"
            :loadingStatus="
              selectedIndex == 1 &&
              options.fileUploadingStatus.slider_action[itemIndex] == 'in_progress'
            "
            :isRequired="isValidationRequired ? options.isAssetUploadRequired : false"
            :diffIdentifier="`${fieldIdentifierPrefix}.layoutData[0].${
              options.isAssetUploadRequired ? 'imageUrl' : 'briefUrl'
            }`"
            :isAssetUploadRequired="options.isAssetUploadRequired"
            :fileChangeHandler="(file) => fileUploadHandler(file, type, getAssetPath(1), 1)"
            @addBriefToAsset="
              (previewUrl, briefUrl, approveAsset) =>
                addToAsset(previewUrl, briefUrl, approveAsset, type, 0)
            "
            :previewUrl="getAssetUrl(1, 'imageUrl')"
            :previewUrlBrief="getAssetUrl(1, 'briefUrl')"
            :image_url="getAssetPathBasedOnKey(1, 'imageUrl')"
            :image_url_brief="getAssetPathBasedOnKey(1, 'briefUrl')"
            :isNotReadOnly="options.isNotReadOnly"
            :canAppproveAsset="canAppproveAsset"
            :isAssetApproved="getAssetPathBasedOnKey(1, 'imageUrlApproved')"
            :canFinalizeAsset="true"
          />
          <Padder :bottom="10">
            <TextInput
              :enableTextFormatting="true"
              :readonly="options.isReadOnly"
              :canShowDiffIcon="options.canShowDiffIcon"
              :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData[0].imageTitle`"
              :placeholder="'Image Title'"
              v-model="data.layoutData[0].imageTitle"
              customClass="xs-bottomPadding"
              :counter="20"
            />
          </Padder>
          <Padder :bottom="20">
            <RichTextEditor
              :readonly="options.isReadOnly || options.isAssetUploadRequired"
              :canShowDiffIcon="options.canShowDiffIcon"
              v-model="data.layoutData[0].brief"
              :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData[0].brief`"
              :key="`${fieldIdentifierPrefix}.layoutData[0].brief`"
              :showValidationError="
                !options.isAssetUploadRequired && options.validationFlag && isValidationRequired
              "
              :counter="3000"
              :allowWordCount="false"
              :allowCharacterCount="true"
              placeholder="Reference Image Brief"
              :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
            />
          </Padder>
        </v-col>
        <v-col cols="6">
          <Padder :right="5" :vertical="0">
            <RichTextEditor
              :readonly="options.isReadOnly"
              :canShowDiffIcon="options.canShowDiffIcon"
              :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData[1].text`"
              :placeholder="'Title'"
              v-model="data.layoutData[1].text"
              :key="`layout4-1${fieldIdentifierPrefix}`"
              :counter="260"
              :wordCounter="50"
              :allowWordCount="true"
              :allowCharacterCount="true"
            />
          </Padder>
        </v-col>
        <v-col cols="6">
          <FileInput
            class="xs-layout layout4-img"
            :canShowDiffIcon="options.canShowDiffIcon"
            :fileAcceptType="options.isAssetUploadRequired ? 'image/*' : options.fileAcceptType"
            label="Image"
            :loadingStatus="
              selectedIndex == 2 &&
              options.fileUploadingStatus.slider_action[itemIndex] == 'in_progress'
            "
            :isRequired="false"
            :diffIdentifier="`${fieldIdentifierPrefix}.layoutData[1].${
              options.isAssetUploadRequired ? 'imageUrl' : 'briefUrl'
            }`"
            :isAssetUploadRequired="options.isAssetUploadRequired"
            :fileChangeHandler="(file) => fileUploadHandler(file, type, getAssetPath(2), 2)"
            @addBriefToAsset="
              (previewUrl, briefUrl, approveAsset) =>
                addToAsset(previewUrl, briefUrl, approveAsset, type, 1)
            "
            :previewUrl="getAssetUrl(2, 'imageUrl')"
            :previewUrlBrief="getAssetUrl(2, 'briefUrl')"
            :image_url="getAssetPathBasedOnKey(2, 'imageUrl')"
            :image_url_brief="getAssetPathBasedOnKey(2, 'briefUrl')"
            :isNotReadOnly="options.isNotReadOnly"
            :canAppproveAsset="canAppproveAsset"
            :isAssetApproved="getAssetPathBasedOnKey(2, 'imageUrlApproved')"
            :canFinalizeAsset="true"
          />
          <Padder :bottom="10">
            <TextInput
              :enableTextFormatting="true"
              :readonly="options.isReadOnly"
              :canShowDiffIcon="options.canShowDiffIcon"
              :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData[1].imageTitle`"
              :placeholder="'Image Title'"
              v-model="data.layoutData[1].imageTitle"
              customClass="xs-bottomPadding"
              :counter="20"
            />
          </Padder>
          <RichTextEditor
            :readonly="options.isReadOnly || options.isAssetUploadRequired"
            :canShowDiffIcon="options.canShowDiffIcon"
            v-model="data.layoutData[1].brief"
            :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData[1].brief`"
            :key="`${fieldIdentifierPrefix}.layoutData[1].brief`"
            :counter="3000"
            :allowWordCount="false"
            :allowCharacterCount="true"
            placeholder="Reference Image Brief"
            :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
          />
        </v-col>
      </v-row>
    </div>
    <div class="layout5" v-else-if="selected_layout == 'Layout5'">
      <Padder>
        <FileInput
          :canShowDiffIcon="options.canShowDiffIcon"
          :fileAcceptType="options.isAssetUploadRequired ? 'image/*' : options.fileAcceptType"
          label="Image"
          :loadingStatus="
            selectedIndex == -1 &&
            options.fileUploadingStatus.slider_action[itemIndex] == 'in_progress'
          "
          :isRequired="isValidationRequired ? options.isAssetUploadRequired : false"
          :diffIdentifier="`${fieldIdentifierPrefix}.layoutData.${
            options.isAssetUploadRequired ? 'imageUrl' : 'briefUrl'
          }`"
          :isAssetUploadRequired="options.isAssetUploadRequired"
          :fileChangeHandler="(file) => fileUploadHandler(file, type, getAssetPath(0), -1)"
          :previewUrl="getAssetUrl(-1, 'imageUrl')"
          :previewUrlBrief="getAssetUrl(-1, 'briefUrl')"
          :image_url="getAssetPathBasedOnKey(-1, 'imageUrl')"
          :image_url_brief="getAssetPathBasedOnKey(-1, 'briefUrl')"
          @addBriefToAsset="
            (previewUrl, briefUrl, approveAsset) =>
              addToAsset(previewUrl, briefUrl, approveAsset, type, -1)
          "
          :isNotReadOnly="options.isNotReadOnly"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="getAssetPathBasedOnKey(-1, 'imageUrlApproved')"
          :canFinalizeAsset="true"
        />
      </Padder>
      <Padder :bottom="10">
        <TextInput
          :enableTextFormatting="true"
          :readonly="options.isReadOnly"
          :canShowDiffIcon="options.canShowDiffIcon"
          :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.imageTitle`"
          :placeholder="'Image Title'"
          v-model="data.layoutData.imageTitle"
          customClass="xs-bottomPadding"
          :counter="50"
          :wordCounter="8"
        />
      </Padder>
      <RichTextEditor
        :readonly="options.isReadOnly || options.isAssetUploadRequired"
        :canShowDiffIcon="options.canShowDiffIcon"
        v-model="data.layoutData.brief"
        :fieldIdentifier="`${fieldIdentifierPrefix}.layoutData.brief`"
        :key="`${fieldIdentifierPrefix}.layoutData.brief`"
        :counter="3000"
        :allowWordCount="false"
        :allowCharacterCount="true"
        placeholder="Reference Image Brief"
        :showValidationError="
          !options.isAssetUploadRequired && options.validationFlag && isValidationRequired
        "
        :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
      />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import TextInput from '@/components/inputs/TextInput';
import FileInput from '@/components/inputs/FileInput';
import RichTextEditor from '@/components/RTE/RichTextEditor';
import contentEntrySliderDefaultData from '@/constants/contentEntrySliderDefaultData';
import layout1 from '@/assets/layout/layout1.png';
import layout2 from '@/assets/layout/layout2.png';
import layout3 from '@/assets/layout/layout3.png';
import layout4 from '@/assets/layout/layout4.png';
import layout5 from '@/assets/layout/layout5.png';
export default {
  name: 'Slider',
  data() {
    return {
      items: [
        {
          value: 'Layout1',
          title: 'Layout 1',
          subtitle: 'Text Only',
          image: layout1,
        },
        {
          value: 'Layout2',
          title: 'Layout 2',
          subtitle: 'Text/1-3 images',
          image: layout2,
        },
        {
          value: 'Layout3',
          title: 'Layout 3',
          subtitle: 'Text/Image(s)/Text',
          image: layout3,
        },
        {
          value: 'Layout4',
          title: 'Layout 4',
          subtitle: '1-2 rows of Text',
          image: layout4,
        },
        {
          value: 'Layout5',
          title: 'Layout 5',
          subtitle: 'Full Screen Image',
          image: layout5,
        },
      ],
      selected_layout: '',
      itemIndex: 0,
      contentEntrySliderDefaultData,
      initTime: true,
      selectedIndex: 0,
      processDataFlag: false,
      iterationKeys: ['imageUrl', 'briefUrl', 'imageTitle', 'brief', 'imageUrlApproved'],
      assetPathKeys: ['imageUrl', 'briefUrl'],
    };
  },
  computed: {
    getLayoutString() {
      switch (this.selected_layout) {
        case 'Layout1':
          return '1 rich text box';
        case 'Layout2':
          return '1 rich text box + 3 images';
        case 'Layout3':
          return '1 rich text box (top) + 3 images + 1 rich text box (bottom)';
        case 'Layout4':
          return 'Table of 1 rich text box + 1 image';
        case 'Layout5':
          return '1 image';
        default:
          return '1 rich text box';
      }
    },
  },
  created() {
    this.itemIndex = parseInt(this.type.split('.').pop());
    this.selected_layout = this.getLayoutIndexFromString(this.data.layoutType);
    this.processData();
  },
  methods: {
    getLayoutIndexFromString(layout) {
      switch (layout) {
        case '1 rich text box':
          return 'Layout1';
        case '1 rich text box + 3 images':
          return 'Layout2';
        case '1 rich text box (top) + 3 images + 1 rich text box (bottom)':
          return 'Layout3';
        case 'Table of 1 rich text box + 1 image':
          return 'Layout4';
        case '1 image':
          return 'Layout5';
        default:
          return 'Layout1';
      }
    },
    changeLayout(val) {
      try {
        if (this.type.includes('slider')) {
          let splitted = this.type.split('.');
          let actionIndex = splitted[2];
          let identifier = splitted[1];
          let keyIdentifier = identifier == 'action' ? 'instructions' : 'contents';
          let layoutBackup = _.cloneDeep(this.data);
          let assetBackup = _.cloneDeep(
            this.options.signedAssetUrls[identifier][keyIdentifier][actionIndex]
          );
          this.data.layoutType = this.getLayoutString;
          let layoutDataMockup = _.cloneDeep(
            this.contentEntrySliderDefaultData.SLIDER_DEFAULT_DATA_LAYOUT[val]
          );
          let assetUrlMockup = _.cloneDeep(
            this.contentEntrySliderDefaultData.SLIDER_DEFAULT_ASSETS_LAYOUT[val]
          );
          if (val == 'Layout4') {
            this.data.layoutData = _.cloneDeep(layoutDataMockup);
            this.options.signedAssetUrls[identifier][keyIdentifier][
              actionIndex
            ].layoutData = _.cloneDeep(assetUrlMockup);
          } else {
            this.data.layoutData = _.cloneDeep(layoutDataMockup);
            this.options.signedAssetUrls[identifier][keyIdentifier][
              actionIndex
            ].layoutData = _.cloneDeep(assetUrlMockup);
          }
          this.data = this.setPreviousLayoutData(
            layoutBackup,
            this.data,
            this.iterationKeys,
            layoutBackup.layoutType,
            this.data.layoutType,
            'data'
          );
          this.options.signedAssetUrls[identifier][keyIdentifier][
            actionIndex
          ] = this.setPreviousLayoutData(
            assetBackup,
            this.options.signedAssetUrls[identifier][keyIdentifier][actionIndex],
            this.assetPathKeys,
            layoutBackup.layoutType,
            this.data.layoutType,
            'asset'
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
    getPreviousLayoutText(layout, previousData) {
      switch (layout) {
        case 'Layout1':
          return previousData.layoutData.text;
        case 'Layout2':
          return previousData.layoutData.text;
        case 'Layout3':
          return previousData.layoutData.headerText;
        case 'Layout4':
          return previousData.layoutData[0].text;
        default:
          return '';
      }
    },
    setcurrentLayoutText(layout, layoutText, currentData) {
      switch (layout) {
        case 'Layout1':
          currentData.layoutData.text = layoutText;
          break;
        case 'Layout2':
          currentData.layoutData.text = layoutText;
          break;
        case 'Layout3':
          currentData.layoutData.headerText = layoutText;
          break;
        case 'Layout4':
          currentData.layoutData[0].text = layoutText;
          break;
      }
      return currentData.layoutData;
    },
    setPreviousLayoutData(previousData, currentData, dataKeys, previousLayout, currentLayout, key) {
      let previousLayoutName = this.getLayoutIndexFromString(previousLayout);
      let currentLayoutName = this.getLayoutIndexFromString(currentLayout);
      let previousDataWithValidKey;
      let currentDataWithValidKey;
      let previousLayoutText;
      if (key == 'data') {
        previousLayoutText = this.getPreviousLayoutText(previousLayoutName, previousData);
      }
      if (previousLayoutName != 'Layout1' && currentLayoutName != 'Layout1') {
        if (previousLayoutName == 'Layout5') {
          previousDataWithValidKey = [previousData.layoutData];
        } else {
          previousDataWithValidKey = previousData.layoutData.images
            ? previousData.layoutData.images
            : previousData.layoutData;
        }
      } else {
        if (key == 'data') {
          currentData.layoutData = this.setcurrentLayoutText(
            currentLayoutName,
            previousLayoutText,
            _.cloneDeep(currentData)
          );
        }
        return currentData;
      }
      if (currentLayoutName == 'Layout5') {
        currentDataWithValidKey = [currentData.layoutData];
      } else {
        currentDataWithValidKey = currentData.layoutData.images
          ? currentData.layoutData.images
          : currentData.layoutData;
      }
      for (let i = 0, j = currentDataWithValidKey.length; i < j; i++) {
        if (!previousDataWithValidKey[i]) break;
        dataKeys.forEach((key) => {
          currentDataWithValidKey[i][key] = previousDataWithValidKey[i][key];
        });
      }
      if (currentData.layoutData.images) {
        currentData.layoutData.images = currentDataWithValidKey;
      } else {
        if (currentLayoutName == 'Layout5') {
          currentData.layoutData = currentDataWithValidKey[0];
        } else {
          currentData.layoutData = currentDataWithValidKey;
        }
      }
      if (key == 'data') {
        currentData.layoutData = this.setcurrentLayoutText(
          currentLayoutName,
          previousLayoutText,
          _.cloneDeep(currentData)
        );
      }
      return currentData;
    },
    processData() {
      let splitted = this.type.split('.');
      let keyIdentifier = splitted[1] == 'action' ? 'instructions' : 'contents';
      if (this.selected_layout == 'Layout2' || this.selected_layout == 'Layout3') {
        for (let i = 0; i < 3; i++) {
          if (!this.data.layoutData.images[i]) {
            this.data.layoutData.images.push(
              JSON.parse(
                JSON.stringify({
                  imageTitle: '',
                  imageUrl: '',
                  brief: '',
                  briefUrl: '',
                })
              )
            );
            this.dataCopy.layoutData.images.push(
              JSON.parse(
                JSON.stringify({
                  imageTitle: '',
                  imageUrl: '',
                  brief: '',
                  briefUrl: '',
                })
              )
            );
            this.options.signedAssetUrls[splitted[1]][keyIdentifier][
              this.itemIndex
            ].layoutData.images.push(_.cloneDeep({ briefUrl: '', imageUrl: '' }));
          }
        }
      } else if (this.selected_layout == 'Layout4') {
        for (let i = 0; i < 2; i++) {
          if (!this.data.layoutData[i]) {
            this.data.layoutData.push(
              _.cloneDeep({
                text: '',
                imageTitle: '',
                imageUrl: '',
                brief: '',
                briefUrl: '',
              })
            );
            this.dataCopy.layoutData.push(
              _.cloneDeep({
                imageTitle: '',
                imageUrl: '',
                brief: '',
                briefUrl: '',
              })
            );
            this.options.signedAssetUrls[splitted[1]][keyIdentifier][
              this.itemIndex
            ].layoutData.push(_.cloneDeep({ briefUrl: '', imageUrl: '' }));
          }
        }
      }
      this.processDataFlag = true;
    },
    fileUploadHandler(file, type, url, index) {
      if (!file) {
        this.fileChangeHandler(file, type, url, index - 1);
      } else {
        let pattern = '';
        if (this.options.isAssetUploadRequired) {
          pattern = 'image/*';
        } else {
          pattern = this.options.fileAcceptType;
        }
        if (!(file.type.startsWith('image') || pattern.includes(file.name.split('.').pop()))) {
          let partialErrorText = this.options.isAssetUploadRequired
            ? 'please upload an image file'
            : 'Please upload an image or a document file';
          this.$notify({
            title: 'Error!',
            text: `File format not supported, ${partialErrorText}`,
            type: 'error',
            clean: false,
            duration: -1,
          });
        } else {
          this.selectedIndex = index;
          this.fileChangeHandler(file, type, url, index - 1);
        }
      }
    },
    getAssetPath(index) {
      index = index - 1;
      let url = this.options.isAssetUploadRequired ? 'imageUrl' : 'briefUrl';
      if (this.selected_layout == 'Layout4')
        return this.data.layoutData[index] ? this.data.layoutData[index][url] : '';
      else if (this.selected_layout == 'Layout5')
        return this.data.layoutData.briefUrl ? this.data.layoutData[url] : '';
      else {
        if (this.data.layoutData.images) {
          return this.data.layoutData.images[index] ? this.data.layoutData.images[index][url] : '';
        }
      }
      return '';
    },
    getAssetPathBasedOnKey(index, key) {
      index = index - 1;
      if (this.selected_layout == 'Layout4')
        return this.data.layoutData[index] ? this.data.layoutData[index][key] : '';
      else if (this.selected_layout == 'Layout5')
        return this.data.layoutData ? this.data.layoutData[key] : '';
      else {
        if (this.data.layoutData.images) {
          return this.data.layoutData.images[index] ? this.data.layoutData.images[index][key] : '';
        }
      }
      return '';
    },
    getAssetUrl(index, url) {
      let identifier = this.type.split('.')[1];
      let identifierKey = identifier == 'action' ? 'instructions' : 'contents';
      if (this.options.signedAssetUrls[identifier][identifierKey][this.itemIndex]) {
        if (this.selected_layout == 'Layout4') {
          if (
            this.options.signedAssetUrls[identifier][identifierKey][this.itemIndex].layoutData[
              index - 1
            ]
          )
            return this.options.signedAssetUrls[identifier][identifierKey][this.itemIndex]
              .layoutData[index - 1][url];
        } else if (this.selected_layout == 'Layout5')
          return this.options.signedAssetUrls[identifier][identifierKey][this.itemIndex].layoutData[
            url
          ];
        else if (
          this.options.signedAssetUrls[identifier][identifierKey][this.itemIndex].layoutData.images
        ) {
          return this.options.signedAssetUrls[identifier][identifierKey][this.itemIndex].layoutData
            .images[index - 1]
            ? this.options.signedAssetUrls[identifier][identifierKey][this.itemIndex].layoutData
                .images[index - 1][url]
            : '';
        }
      }
      return '';
    },
  },
  components: {
    TextInput,
    FileInput,
    RichTextEditor,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
    fileChangeHandler: {
      type: Function,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    fieldIdentifierPrefix: {
      type: String,
      required: false,
    },
    isValidationRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    dataCopy: {
      type: Object,
      required: false,
    },
    addToAsset: {
      type: Function,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.xs-layout.layout4-img {
  margin-bottom: 15px;
}
.xs-v-select fieldset {
  border: none;
}
.xs-v-layout-select {
  min-width: auto !important;
  width: auto;
  background-color: #eee;
  box-shadow: none;
}
.xs-v-layout-select .v-list {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  flex: 1;
  max-width: 740px;
  padding-top: 0px;
}
.xs-v-layout-select .v-select-list .v-list-item {
  flex: 1 !important;
  background: #eee;
  padding: 20px;
  width: 180px;
}
</style>
