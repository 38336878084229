<template>
  <div>
    <BlockTitle :title="sectionName" />
    <div class="mgn-Ls mgn-Ts mgn-Bs" v-if="prefixInstructions && prefixInstructions.length">
      <div v-for="(insText, index) in prefixInstructions" :key="index" v-html="insText" />
    </div>
    <RichTextEditor
      :readonly="isReadOnly"
      :canShowDiffIcon="canShowDiffIcon"
      v-model="data[sectionKey].description"
      :fieldIdentifier="`application.${sectionKey}.description`"
      placeholder="Description"
      :showValidationError="true"
      :isDiffChanged="getSectionIsChangedValue(sectionKey, 'description')"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />

    <div class="display-flex" style="align-items: center; justify-content: space-between;">
      <BlockTitle title="Questions" />
      <div v-if="uniqueQuestionsCount > 0">
        {{ convertToPluralText(uniqueQuestionsCount, 'question') }} added
      </div>
    </div>
    <div class="question-details-loading-indicator" v-if="showLoadingForQuestionDataList">
      <v-progress-circular :width="2" color="red" indeterminate></v-progress-circular>
      <span class="mgn-Ls">Loading Question Details, Please wait.</span>
    </div>
    <Questions
      v-else
      :key="sectionName"
      :data="questionData"
      @onQuestionDataChanged="() => {}"
      :saveForm="() => {}"
      :canAppproveAsset="false"
      :toggleComments="() => {}"
      @question-preview-cliked="() => {}"
      :lessonPlans="currentProjectQuestionLinkableLessonPlans"
      @remove-question-clicked="
        (question) => removeApplicationSectionQuestion(sectionKey, question)
      "
      currentStage=""
      :options="options"
      :isViewOnly="true"
      :lpType="lpType"
      :forceUseAssetBasedOnQuestionProjectCurrentStageData="true"
    />
    <AddQuestionDialog
      :onSubmit="(data) => addApplicationSectionQuestion(sectionKey, data)"
      :maxItemsCanAdd="maxItemsCanAdd"
      :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
      :questionPurpose="lpType"
      :lpType="lpType"
    >
      <AddItemButton
        buttonText="Add Question"
        :isReadOnly="isReadOnly"
        :canAddItem="canAddNewItem"
        :remainingItemsCount="maxItemsCanAdd"
        remainingItemsSuffix="question"
        :onClick="() => {}"
      />
    </AddQuestionDialog>
    <br />
    <div class="pad-Ts mgn-Bs">
      <XSDisplayCard :compact="true" :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionIsChangedValue(sectionKey, 'teacher_tip')"
          :fieldIdentifier="`application.${sectionKey}.teacher_tip`"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="data[sectionKey].teacher_tip"
          :counter="teacherTipMaxLength"
          :readonly="isReadOnly"
          class="mgn-Bm mgn-Tm"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </XSDisplayCard>
    </div>
  </div>
</template>

<script>
import Errors from '@/constants/Errors';
import BlockTitle from '@/components/inputs/BlockTitle.vue';
import Questions from '@/components/contentEntry/Questions';
import AddQuestionDialog from '@/components/Questions/AddQuestionDialog.vue';
import AddItemButton from '@/components/Questions/AddItemButton.vue';
import AppTexts from '@/constants/AppTexts';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import { LP_TYPES_HASH } from '@/constants/common';
import { convertToPluralText } from '@/utils';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';

export default {
  name: 'QuesstionLessonIndividualSection',
  components: {
    BlockTitle,
    AddItemButton,
    AddQuestionDialog,
    Questions,
    XSDisplayCard,
    RichTextEditor,
  },
  data() {
    return {
      LP_TYPES_HASH,
      BASIC_TOOLS,
      Errors,
      AppTexts,
      options: {
        isReadOnly: false,
      },
    };
  },
  methods: { convertToPluralText },
  props: {
    sectionName: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    sectionKey: {
      type: String,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    showLoadingForQuestionDataList: {
      type: Boolean,
      required: true,
    },
    questionData: {
      type: Array,
      required: true,
    },
    removeApplicationSectionQuestion: {
      type: Function,
      required: true,
    },
    addApplicationSectionQuestion: {
      type: Function,
      required: true,
    },
    maxItemsCanAdd: {
      type: Number,
      required: true,
    },
    canAddNewItem: {
      type: Boolean,
      required: true,
    },
    teacherTipMaxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    getSectionIsChangedValue: {
      type: Function,
      required: true,
    },
    prefixInstructions: {
      type: Array,
      required: false,
    },
    lpType: {
      type: String,
      required: true,
    },
    currentProjectQuestionLinkableLessonPlans: {
      type: Array,
      required: true,
    },
    uniqueQuestionsCount: {
      type: Number,
      required: true,
    },
  },
};
</script>
