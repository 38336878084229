var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-project-status-wrapper"},[_c('div',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.lessonType))]),_c('div',{staticClass:"project-status-wrapper"},[(
        _vm.currentStage == _vm.AppTexts.SUB_PROJECT_COMPLETED_STATUS &&
        _vm.currentStatus != _vm.AppTexts.WORKFLOW_IN_PROGRESS
      )?_c('span',[_c('img',{attrs:{"src":require("@/assets/bluetick.svg"),"height":"30","width":"30"}})]):(
        _vm.currentStage == _vm.AppTexts.SUB_PROJECT_COMPLETED_STATUS &&
        _vm.currentStatus == _vm.AppTexts.WORKFLOW_IN_PROGRESS
      )?_c('span',[_c('img',{attrs:{"src":require("@/assets/greentick.svg"),"height":"30","width":"30"}})]):_c('div',{staticClass:"current-stage",style:('border-color: ' + _vm.getBorderColorBasedOnStage(_vm.currentStage) + ';')},[_c('span',[_vm._v(_vm._s(_vm.currentStage))])]),_c('div',{staticClass:"project-current-status-wrapper"},[_c('div',{staticClass:"project-status-graph"},[_c('div',{class:_vm.currentStage == _vm.AppTexts.SUB_PROJECT_COMPLETED_STATUS &&
            _vm.currentStatus == _vm.AppTexts.WORKFLOW_IN_PROGRESS
              ? _vm.snakeCase('com') + '-bar'
              : _vm.snakeCase(_vm.currentStatus) + '-bar'})]),_c('div',{staticClass:"project-status-text"},[_vm._v(_vm._s(_vm.capitalCase(_vm.getProjectStausText(_vm.status))))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }