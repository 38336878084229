<template>
  <div @click="clickedOutside($event)">
    <v-row class="alc">
      <v-col>
        <div v-if="canEditTitle">
          <div
            class="lesson-plan-title"
            @dblclick="enableEditingThisField('lp_title')"
            v-show="!enableEditing.lp_title"
          >
            {{ lessonPlan[titleKey].trim() || 'No Title' }}
          </div>
          <div class="lesson-plan-title" v-show="enableEditing.lp_title" id="lp-title-field">
            <SelectInput
              v-if="showSelectInputForTitle"
              :items="inputItemsForLpTitle"
              :selectedValue="lessonPlan[titleKey]"
              :canShowDiffIcon="canShowDiffIcon"
              :fieldIdentifier="titleKey"
              :placeholder="getPlaceholderTitleDescription(titleKey)"
              @selectChanged="(e) => (lessonPlan[titleKey] = e)"
            />
            <TextInput
              v-else
              v-model="lessonPlan[titleKey]"
              :placeholder="getPlaceholderTitleDescription(titleKey)"
              :canShowDiffIcon="canShowDiffIcon"
              :readonly="!isNotReadOnly"
              :fieldIdentifier="titleKey"
              ref="lp_title"
            />
          </div>
        </div>
        <div class="lesson-plan-title" v-else>{{ lessonPlanDetails.title }}</div>
        <div class="lesson-plan-details">{{ lessonPlanDetails.details }}</div>
        <div v-if="canEditDescription">
          <div
            class="lesson-plan-title"
            @dblclick="enableEditingThisField('lp_description')"
            v-show="!enableEditing.lp_description"
          >
            {{ lessonPlan[descriptionKey].trim() || 'No Description' }}
          </div>
          <div
            class="lesson-plan-title"
            v-show="enableEditing.lp_description"
            id="lp-description-field"
          >
            <TextInput
              v-model="lessonPlan[descriptionKey]"
              :placeholder="getPlaceholderTitleDescription(descriptionKey)"
              :canShowDiffIcon="canShowDiffIcon"
              :readonly="!isNotReadOnly"
              :fieldIdentifier="descriptionKey"
              ref="lp_description"
            />
          </div>
        </div>
        <div v-else class="lesson-plan-title">
          {{ lessonPlan.lp_description.trim() || 'No Description' }}
        </div>
      </v-col>
      <v-col v-if="lessonPlanDetails.time && lessonPlanDetails.time.length" :cols="4">
        <div class="lesson-plan-time-wrapper">
          <v-select
            v-if="canEditTime"
            class="lesson-plan-overall-time-select-field"
            v-on:change="(val) => $emit('set-timer-input', val)"
            @click="
              (event) => {
                event.stopPropagation();
              }
            "
            :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
            :items="allowedTimeValues"
            :value="lessonPlanDetails.time"
            placeholder="Select time"
            background-color="#f5f5f5"
            prepend-inner-icon="mdi-clock-outline"
            hide-details
            :disabled="!isNotReadOnly"
            solo
            flat
          ></v-select>
          <span v-else>
            <img class="mgn-Rm mgn-Lm" height="20" width="20" src="@/assets/time.svg" />
            <span class="lesson-plan-time">{{ lessonPlanDetails.time }}</span></span
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <TextInput
          v-if="showImageTitle"
          :value="title"
          placeholder="Image Title"
          :canShowDiffIcon="false"
          :onChange="imageTitleChangeHandler"
          :readonly="!isNotReadOnly"
        />
        <FileInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isImageChanged"
          :diffIdentifier="isAssetUploadRequired ? 'assetId' : 'brief.assetId'"
          :fileAcceptType="isAssetUploadRequired ? 'image/*' : 'image/*,.docx,.doc'"
          :label="coverImagePlaceholder"
          :loadingStatus="loadingStatus"
          :isRequired="isAssetUploadRequired"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileChangeHandler="fileChangeHandler"
          :previewUrl="previewUrl"
          :previewUrlBrief="previewUrlBrief"
          :image_url="image_url"
          :image_url_brief="image_url_brief"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="true"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="isAssetApproved"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              copyLessonPlanCoverImage(previewUrl, briefUrl, isAssetApproved)
          "
        />
        <RichTextEditor
          class="mgn-Tm"
          v-if="showImageBrief"
          :canShowDiffIcon="canShowDiffIcon"
          fieldIdentifier="brief"
          :isDiffChanged="isImageBriefDiffChanged"
          @input="imageBriefChangeHandler($event)"
          :value="brief"
          placeholder="Image brief"
          :showValidationError="true"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col />
      <v-col cols="6">
        <Stepper
          :currentStepIndex="currentStageDetails.index"
          :currentProjectAssignees="currentProjectAssignees"
          :headerText="currentStageDetails.stageName"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FileInput from '@/components/inputs/FileInput';
import Stepper from '@/components/lessonPlan/Stepper';
import Errors from '@/constants/Errors';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';

export default {
  name: 'CoverImage',
  data() {
    return {
      Errors,
      BASIC_TOOLS,
      enableEditing: {
        lp_title: false,
        lp_description: false,
      },
    };
  },
  methods: {
    enableEditingThisField(fieldName) {
      this.enableEditing[fieldName] = true;
      let refCurrentField = this.$refs[fieldName];
      if (refCurrentField) {
        refCurrentField.focusInput();
      }
    },
    clickedOutside(event) {
      let lp_description_field = document.getElementById('lp-description-field');
      let lp_title_field = document.getElementById('lp-title-field');
      if (!lp_description_field.contains(event.target) && !lp_title_field.contains(event.target)) {
        Object.keys(this.enableEditing).forEach((_e) => {
          this.enableEditing[_e] = false;
        });
        this.$emit('changedTitleAndDesc', {
          title: this.lessonPlan[this.titleKey],
          description: this.lessonPlan[this.descriptionKey],
        });
      }
    },
    getPlaceholderTitleDescription(value) {
      let splittedKey = value.split('_');
      return `${splittedKey[0].toUpperCase()} ${splittedKey[1][0].toUpperCase()}${splittedKey[1].slice(
        1
      )}`;
    },
  },

  components: {
    RichTextEditor,
    FileInput,
    Stepper,
    TextInput,
    SelectInput,
  },
  props: {
    title: { type: String, required: false },
    brief: { type: String, required: true },
    image_url: { type: String, required: false, default: '' },
    image_url_brief: { type: String, required: false, default: '' },
    lessonPlanDetails: { type: Object, required: true },
    coverImagePlaceholder: { type: String, required: true },
    previewUrl: { type: String, required: false, default: '' },
    previewUrlBrief: { type: String, required: false, default: '' },
    isAssetUploadRequired: { type: Boolean, required: true },
    loadingStatus: { type: Boolean, required: true },
    fileChangeHandler: { type: Function, required: true },
    canShowDiffIcon: { type: Boolean, required: true },
    isImageChanged: { type: Boolean, required: false },
    showImageBrief: { type: Boolean, required: true },
    isImageBriefDiffChanged: { type: Boolean, required: false, default: false },
    showImageTitle: { type: Boolean, required: false, default: false },
    currentStageDetails: { type: Object, required: true },
    isNotReadOnly: { type: Boolean, required: false, default: true },
    fileValidators: { type: Array, required: false, default: () => [] },
    copyLessonPlanCoverImage: { type: Function, required: true },
    canAppproveAsset: { type: Boolean, required: true },
    isAssetApproved: { type: Boolean, required: false, default: false },
    allowedTimeValues: {
      type: Array,
      required: false,
      default: () => [],
    },
    canEditTime: {
      type: Boolean,
      default: false,
    },
    currentProjectAssignees: {
      type: Object,
      default: () => {
        return {};
      },
    },
    imageTitleChangeHandler: { type: Function, required: true },
    imageBriefChangeHandler: { type: Function, required: true },
    showSelectInputForTitle: { type: Boolean, default: false },
    inputItemsForLpTitle: { type: Array, default: () => [] },
    canEditTitle: { type: Boolean, default: false },
    canEditDescription: { type: Boolean, default: false },
    lessonPlan: {
      type: Object,
      default: () => {
        return {};
      },
    },
    titleKey: {
      type: String,
      default: 'lp_title',
    },
    descriptionKey: {
      type: String,
      default: 'lp_description',
    },
  },
};
</script>

<style scoped>
.lesson-plan-time-wrapper {
  background-color: #f5f5f5;
  height: 50px;
  width: 200px;
  float: right;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lesson-plan-title {
  font-size: 20px;
  font-weight: 500;
}
.lesson-plan-details {
  color: #242424;
}
.lesson-plan-time {
  font-size: 20px;
}
</style>
