export default {
  LESSON_PLAN_TITLE: 'Lesson Plan Title',
  TIME: 'Time',
  LESSON_PLAN_COVER_IMAGE: 'Lesson Plan cover image',
  O_PROGRAM_PLAN_COVER_IMAGE: 'Cover Image',
  AIM: 'Aim',
  ACTION: 'Action',
  ANALYSIS: 'Analysis',
  APPLICATION: 'Application',
  ASSESS: 'Assessment',
  RESOURCES: 'RESOURCES WE WILL NEED',
  INFORMATION_AND_ASSESSMENT: 'Information And Assessment',
  THINGS_WE_WILL_USE: 'THINGS WE WILL USE',
  CLASSWORK: 'Classwork',
  HOMEWORK: 'Homework',
  APPROACHING: 'Approaching',
  MEETING: 'Meeting',
  EXCEEDING: 'Exceeding',
  OVERALL: 'Overall',
  AIM_SUMMARY: 'Aim Summary',
  ACTION_SUMMARY: 'Action Summary',
  ANALYSIS_SUMMARY: 'Analysis Summary',
  SUMMARY: 'Teacher Summary',
  AIM_DESCRIPTION: 'Aim Description',
  RESOURCE_NAME: 'Name of the resource',
  SELECT_RESOURCE: 'Select resource file',
  KEYWORD: 'Keyword',
  KEYWORDS_WE_WILL_USE: 'KEYWORDS WE WILL USE',
  WORD_WALL: 'WORD WALL',
  KEYWORD_DESCRIPTION: 'Keyword definition',
  SELECT_VIDEO: 'Select video',
  STEP_HEADER: 'Step Header',
  STEP_DESCRIPTION: 'Step Description',
  ADD_SUB_STEP: 'ADD SUB STEP',
  DELETE_STEP: 'DELETE STEP',
  ADD_STEP: 'ADD STEP',
  INSERT_STEP: 'INSERT STEP',
  ADD_ASSET: 'ADD ASSET',
  ADD_IMAGE: 'ADD IMAGE',
  REMOVE_IMAGE: 'REMOVE IMAGE',
  TEACHER_TIP: 'Teacher Tip',
  ADD_TEACHER_TIP: 'Add Teacher Tip',
  TEACHER_TIP_DESCRIPTION: 'Teacher tip description',
  ACTIVITY_DISCUSSION_TEACHER_TIP: 'Activity discussion teacher tip',
  GENERALIZED_DISCUSSION_TEACHER_TIP: 'Generalized discussion teacher tip',
  HOT_TEACHER_TIP: 'Higher order thinking teacher tip',
  ACTIVITY_DISCUSSION: 'Activity Discussion',
  KEY_QUESTION: 'Key question',
  DISCUSSION: 'DISCUSSION',
  KEY_QUESTION_GUIDE: 'Key question guide',
  SELECT_BOARDWORK_IMAGE: 'Select boardwork image',
  GENERALIZED_DISCUSSION: 'Generalized Discussion',
  HIGHER_ORDER_THINKING: 'Higher Order Thinking',
  WORKSHEET_NUMBER: 'Worksheet number',
  SELECT_CLASSWORK_FILE: 'Select classwork file',
  SELECT_STEP_ASSETS: 'Select Assets',
  DIFFERENTIATION_TIPS: 'Differentiation Tips for Teacher',
  THOSE_WHO_NEED_MORE_HELP: 'Those needing more help',
  THOSE_WHO_NEED_EXTRA_CHALLENGE: 'Extra Challenge',
  HOMEWORK_TEXT: 'Homework Text',
  SELECT_HOMEWORK_QUESTION_FILE: 'Select homework question file',
  SELECT_HOMEWORK_ANSWER_FILE: 'Select homework answer file',
  SELECT_FILE: 'Select file',
  MORE_PRACTICE: 'More Practice',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  CHECK: 'Check',
  BOARDWORK_TITLE: 'Boardwork',
  CHECK_TEXT: 'Check text',
  SELECT_QUESTION_FILE: 'Select question file',
  SELECT_ANSWER_FILE: 'Select answer file',
  REFERENCES: 'References',
  ADD_REFERENCE: 'ADD REFERENCE',
  SAVE: 'SAVE',
  SAVE_AS_DRAFT: 'DRAFT SAVE',
  SAVE_BLOCK_DETAILS: 'SAVE BLOCK DETAILS',
  MOVE_TO_PREFIX_TEXT: 'Submit ',
  IMPORT_HTML_FILE: 'Import HTML file',
  IMPORT_ASSETS: 'Import Assets',
  API_FAILURE_MESSAGE: 'Error occured',
  API_FETCH_IN_PROGRESS_MESSAGE: 'Fetching data from server. Please wait...',
  HINT_IMPORT_ASSETS: '* supports zip only',
  RESOURCE_DESCRIPTION: 'Resource Description',
  ADD_RESOURCE: 'Add Resource',
  ADD_KEYWORD: 'Add Keyword',
  ADD_SUPPORT_TIP: 'Add Support Tip',
  ADD_PRACTICE: 'Add Practice',
  REFERENCE_TEXT: 'Reference Text',
  HOMEWORK_ANSWER_FILE_BRIEF: 'Homework answer file brief',
  HOMEWORK_QUESTION_FILE_BRIEF: 'Homework question file brief',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  WAITING_FOR_REVIEW: 'Waiting for Review',
  PUBLISHED: 'Published',
  NOT_STARTED: 'Not Started',
  SELECT_BOARD: 'Select Board',
  SELECT_SUBJECT: 'Select Subject',
  SELECT_STANDARD: 'Select Grade',
  SELECT_PRODUCT: 'Select Product',
  EXPLANATION_TEXT: 'Explanation',
  ACTIVITY_EXPLANATION: 'Activity Explanation',
  GENERALIZED_EXPLANATION: 'Generalized Explanation',
  HIGHER_ORDER_THINKING_EXPLANATION: 'HOTS Explanation',
  EXPLANATION: 'Explanation text',
  QUESTION_FOR_THE_STUDENTS_TEXT: 'Question for the students:',
  PROJECT_NAME: 'Project Name',
  SELECT_BLOCK: 'Select Block',
  SELECT: 'Select',
  SELECT_LESSON_PLANS: 'Select Lesson Plans',
  SELECT_DAILY_PLANS: 'Select Daily Plans',
  AUTHOR: 'Author',
  REVIEWER_1: 'Reviewer 1',
  REVIEWER_2: 'Reviewer 2',
  ASO: 'Academic Manager Review',
  EDITOR: 'Editor',
  VISUALIZER: 'Visualizer',
  ASSET_AUTHORIZER: 'Asset Authorizer',
  PROOF_READER: 'Proof Reader',
  RESOURCE_IMAGE_BRIEF: 'Resource image brief',
  QUESTION_NUMBER: 'Question number',
  REFERENCE_IMAGE_BRIEF: 'Reference image brief',
  CLASSWORK_IMAGE_BRIEF: 'Classwork image brief',
  WORKSHEET_ANSWER_BRIEF: 'Worksheet answer file brief',
  CLASSWORK_AND_WORKSHEET: 'CLASSWORK - WORKSHEET',
  CLASSWORK_AND_MORE_PRACTICE: 'CLASSWORK - MORE PRACTICE',
  WORKSHEET_QUESTION_BRIEF: 'Worksheet question file brief',
  ASSET_UPLOADER: 'Asset Uploader',
  SELECT_ROLE: 'Select Role',
  FILTER_ROLE: 'Filter Role',
  EMAIL: 'Email',
  FULL_NAME: 'Full Name',
  CREATE_USER: 'Create User',
  UPDATE_USER: 'Update User',
  SUSPEND_USER: 'Suspend user?',
  CLICK_OK_TO_SUSPEND: "Click 'Ok' to suspend the account",
  CANCEL: 'Cancel',
  OK: 'Ok',
  PROJECT_FETCH_LOADING_MESSAGE: 'Loading project details, Please wait',
  COVER_IMAGE_BRIEF: 'Cover Image Brief',
  LP_SECTION_COMMENT_BUTTON_TEXT: 'COMMENT',
  LP_SECTION_COMMENT_SAVE_BUTTON_TEXT: 'SAVE COMMENT',
  LP_SECTION_COMMENT_RESOLVE_TEXT: 'Resolve',
  LP_SECTION_COMMENT_RESOLVED_TEXT: 'Resolved',
  LP_SECTION_COMMENT_RESOLVED_BY_TEXT: 'Resolved by',
  YOU_TEXT: 'You',
  NO_COMMENTS_TEXT: 'No comments',
  NO_COMMENTS_YET_TEXT: 'No comments yet',
  REPLACE: 'REPLACE',
  FETCHING_DATA_TEXT: 'Loading, please wait...',
  MOVE_TO_NEXT_STAGE: 'Move to next stage',
  APPLICATION_CLASSWORK_STATIC_TEXT: 'Please do the worksheet on your own.',
  MANAGE_USER_TEXT: 'Manage User',
  USER_PLACEHOLDER_TEXT: 'Search user by name, email',
  MICRO_TEXT_HEADING: 'MICRO-TEXT',
  CONTENT_BOOK_HEADING: 'Content-Book',
  VIDEO_HEADING: 'Video',
  WORKSHEET: 'Worksheet',
  TEACHER_ZONE: 'Teacher Zone',
  APPLICATION_SECTION_TEACHER_TIP_HEADER: 'Differentiation tips to teacher',
  STUDENT_ATTENTION_TEXT: 'This is a good time fo focus on students who needs attention.',
  EXTRA_CHALLENGE: 'Extra challenge',
  ASSESSMENT_RUBRIX: 'Assessment Rubrix',
  SELECT_A_OPTION_BELOW: 'SELECT A OPTION BELOW :',
  SUB_PROJECT_COMPLETED_STATUS: 'COM',
  WORKFLOW_IN_PROGRESS: 'in_progress',
  WORKFLOW_COMPLETED: 'completed',
  SELECT_PROJECT_TYPE: 'Select Project Type',
  AIM_RECAP: 'Recap',
  AIM_HARD_PART: 'Hard Part',
  AIM_FOCUS: 'Focus',
  REVIEW_INSTRUCTION: 'Review Instruction',
  QUESTIONS_TO_GUIDE_DISCUSSION: 'Questions to Guide Discussion',
  QUESTION: 'Question',
  ADD_QUESTION: 'Add Question',
  QUESTIONS: 'Questions',
  ADD_AUDIO_WORD: 'Add Audio Word',
  SUPPORT_TIP: 'Support Tip',
  SUPPORT_TIP_DESCRIPTION: 'Support Tip definition',
  CODING_CONNECTION: 'Coding Connection',
  WHAT_WE_LEARNED: 'What We Learned',
  ACTION_PROGRAM_1: 'Action Program 1',
  ACTION_PROGRAM_2: 'Action Program 2',
  ANALYSIS_PROGRAM_1: 'Analysis Program 1',
  ANALYSIS_PROGRAM_2: 'Analysis Program 2',
  ACTION_PROJECT_1: 'Action Project 1',
  ACTION_PROJECT_2: 'Action Project 2',
  ANALYSIS_PROJECT_1: 'Analysis Project 1',
  ANALYSIS_PROJECT_2: 'Analysis Project 2',
  ADD_SLIDE: 'Add Slide',
};
