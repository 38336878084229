<template>
  <v-form ref="applicationSectionForm" class="section-title-cnr">
    <!-- CLASSWORK BLOCK -->
    <BlockTitleWithSubtitle
      v-if="schema.showLegacyClassWorkHomeWork || schema.showClassWorkHomeWork"
      :subTitle="
        schema.showClassWorkHomeWork ? AppTexts.CLASSWORK : AppTexts.CLASSWORK_AND_WORKSHEET
      "
    />
    <ClassWorkHomeWork
      v-if="schema.showClassWorkHomeWork"
      class="mgn-Tm"
      :isReadOnly="!isNotReadOnly"
      :canShowDiffIcon="canShowDiffIcon"
      :data="lessonPlanData.application.classwork"
      diffIdentifierPrefix="application.classwork"
      descriptionPlaceholder="Classwork Description"
      :diffChanges="applicationDiff.classwork || {}"
      :isAssetUploadRequired="isAssetUploadRequired"
      :signedUrls="applicationSignedUrls.classwork"
      :uploadingStatus="uploadingStatus"
      :acceptableFileType="getAcceptableFileType(isAssetUploadRequired)"
      :showImageBrief="showImageBrief"
      :fileValidators="fileValidators"
      :canAppproveAsset="canAppproveAsset"
      :canFinalizeAsset="true"
      :isQuestionImageMandatory="
        schema.isSkipQuestionAsset ? false : schema.isClassworkQuestionImageMandatory
      "
      :assetTypeMapping="{
        question: AssetTypes.EP_CLASSWORK_QUESTION_ASSET_TYPE,
        question_answer: AssetTypes.EP_CLASSWORK_QUESTION_ANSWER_ASSET_TYPE,
      }"
      :descriptionMaxLength="schema.classworkDescriptionMaxLength"
      :showTeacherTip="schema.showClassworkTeacherTip"
    />
    <LegacyClassWorkHomeWork
      v-else-if="schema.showLegacyClassWorkHomeWork"
      :data="lessonPlanData.application"
      :applicationStaticText="applicationBockStaticTexts.application"
      :canShowDiffIcon="canShowDiffIcon"
      :isNotReadOnly="isNotReadOnly"
      section="classwork"
      :sectionDiff="applicationDiff.classwork || {}"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :acceptableFileType="acceptableFileType"
      :sectionSignedUrls="applicationSignedUrls.classwork"
      :canApproveAsset="canAppproveAsset"
      :sectionText="applicationBockStaticTexts.application.classwork.paragraph_text"
      sectionTextPlaceholder="<<worksheet_number>>"
      :staticTexts2="AppTexts.APPLICATION_CLASSWORK_STATIC_TEXT"
      :label="AppTexts.SELECT_CLASSWORK_FILE"
      :fileValidators="fileValidators"
    />
    <XSDisplayCard
      :title="AppTexts.DIFFERENTIATION_TIPS"
      v-if="workSheetEnabled || schema.showDifferentiationTips"
    >
      {{
        applicationBockStaticTexts.application.differentiation_tips
          ? applicationBockStaticTexts.application.differentiation_tips.paragraph_text || ''
          : ''
      }}
      <p class="mt-5 mb-3 sub-head">{{ AppTexts.THOSE_WHO_NEED_MORE_HELP }}</p>
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDifferentiationTipsChanged('need_more_help')"
        fieldIdentifier="application.differentiation_tips.need_more_help.description"
        :placeholder="AppTexts.DESCRIPTION"
        :showValidationError="schema.isNeedMoreHelpMandatory"
        v-model="lessonPlanData.application.differentiation_tips.need_more_help.description"
        :readonly="!isNotReadOnly"
        :counter="schema.neeMoreHelpDescriptionMaxLength"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <p class="mt-5 mb-3 sub-head">{{ AppTexts.THOSE_WHO_NEED_EXTRA_CHALLENGE }}</p>
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDifferentiationTipsChanged('need_extra_challenge')"
        fieldIdentifier="application.differentiation_tips.need_extra_challenge.description"
        :placeholder="AppTexts.DESCRIPTION"
        :showValidationError="schema.isNeedExtraChallengeMandatory"
        v-model="lessonPlanData.application.differentiation_tips.need_extra_challenge.description"
        :readonly="!isNotReadOnly"
        :counter="schema.needExtraChallengeDescriptionMaxLength"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
    </XSDisplayCard>

    <!-- MORE_PRACTICE BLOCK -->
    <div v-if="lpType === LP_TYPES.standard && schema.morePractice">
      <v-row>
        <v-col>
          <BlockTitleWithSubtitle
            :subTitle="AppTexts.CLASSWORK_AND_MORE_PRACTICE"
            :time="lessonPlanData.application.more_practice_time"
          />
        </v-col>
      </v-row>
      {{
        applicationBockStaticTexts.application.more_practice
          ? applicationBockStaticTexts.application.more_practice.paragraph_text || ''
          : ''
      }}
      <Step
        v-if="lessonPlanData.application.more_practice"
        :items="lessonPlanData.application.more_practice"
        :canShowStepNumber="true"
        :canShowAddNewStepButton="true"
        :addNewStepHandler="addPractice"
        :allowDeleteStep="true"
        :deleteStepHandler="deletePractice"
        :descriptionPlaceholder="AppTexts.DESCRIPTION"
        :maxNoOfSteps="4"
        :supportAssets="true"
        :showImageBrief="showImageBrief"
        :isAssetUploadRequired="isAssetUploadRequired"
        :getPreviewUrl="getMorePracticeInstructionAssetPreviewUrl"
        stepAssetIdentifier="application.more_practice"
        :canShowDiffIcon="canShowDiffIcon"
        stepDiffIdentifier="application.more_practice[<stepIndex>].description"
        :isStepDescriptionChanged="isMorePracticeStepDescriptionChanged"
        assetBriefDiffIdentifier="application.more_practice[<stepIndex>].assets[<assetIndex>].brief"
        :isAssetBriefChanged="isMorePracticeAssetBriefChanged"
        assetDiffIdentifierPrefix="application.more_practice[<stepIndex>].assets[<assetIndex>]"
        assetImageDiffIdentifierPrefix="application.more_practice[<stepIndex>].assets[<assetIndex>]"
        :isAssetImageChanged="isMorePracticeAssetImageChanged"
        :isNotReadOnly="isNotReadOnly"
        :fileValidators="fileValidators"
        :stepDescriptionMaxLength="schema.morePracticeStepDescriptionMaxLength"
        :subStepDescriptionMaxLength="schema.morePracticeSubStepDescriptionMaxLength"
        :stepTeacherTipMaxLength="schema.morePracticeStepTeacherTipMaxLength"
        :canAppproveAsset="canAppproveAsset"
        :canFinalizeAsset="true"
      />
    </div>
    <!-- Thinking Out Loud BLOCK -->
    <XSRightSwitch
      :primaryDs="COMPONENT_TO_DS_MAP.MORE_PRACTICE"
      :secondaryDs="COMPONENT_TO_DS_MAP.SLIDE_INSTRUCTION"
      :subPath="schema.configurableMorePractice.items"
      :initialActive="
        isSlideLayoutEnabled(schema.configurableMultiSlide.slideLayoutCheckPath, lessonPlanData) || !keysOtherThanLayout(schema.configurableMultiSlide.subPathWithoutLeaf, lessonPlanData, 'instructions', [])
      "
      :showLegacyComponentSwitcher="schema.showLegacyComponentSwitcher"
    >
      <template #true-slot>
        <BlockTitleWithSubtitle :subTitle="schema.configurableMorePractice.title" :time="time" />
        <MultiSlideLayout
          :isNotReadOnly="isNotReadOnly"
          :subPath="schema.configurableMultiSlide.subPath"
          :lessonPlanData="lessonPlanData"
          :isAssetUploadRequired="isAssetUploadRequired"
          :canAppproveAsset="canAppproveAsset"
          :signedUrls="allSignedUrls"
          :canShowDiffIcon="canShowDiffIcon"
          :showImageBrief="showImageBrief"
          :maxSlidesCount="schema.configurableMultiSlide.maxSlidesCount"
        />
      </template>
      <template #false-slot>
        <div v-if="schema.configurableMorePractice.show">
          <StepWithHeader
            :title="schema.configurableMorePractice.title"
            :paragraphText="get(lessonPlanData, schema.configurableMorePractice.paragraphTextKey)"
            :items="get(lessonPlanData, schema.configurableMorePractice.items)"
            :descriptionPlaceholder="AppTexts.DESCRIPTION"
            :subPath="schema.configurableMorePractice.items"
            :addNewStepHandler="addPracticeForStep"
            :signedUrls="applicationSignedUrls[schema.configurableMorePractice.leafNode]"
            :showImageBrief="showImageBrief"
            :isAssetUploadRequired="isAssetUploadRequired"
            :canAppproveAsset="canAppproveAsset"
            :canFinalizeAsset="true"
            :deleteStepHandler="deletePracticeForStep"
            :isNotReadOnly="isNotReadOnly"
            :fileValidators="fileValidators"
          />
        </div>
      </template>
    </XSRightSwitch>

    <v-divider></v-divider>
    <!-- HOMEWORK BLOCK -->
    <BlockTitle :title="AppTexts.HOMEWORK" v-if="schema.showHomeWork" />
    <ClassWorkHomeWork
      v-if="schema.showHomeWork && schema.showClassWorkHomeWork"
      :isReadOnly="!isNotReadOnly"
      :canShowDiffIcon="canShowDiffIcon"
      :data="lessonPlanData.application.homework"
      diffIdentifierPrefix="application.homework"
      descriptionPlaceholder="Homework Description"
      :diffChanges="applicationDiff.homework || {}"
      :isAssetUploadRequired="isAssetUploadRequired"
      :signedUrls="applicationSignedUrls.homework"
      :uploadingStatus="uploadingStatus"
      :acceptableFileType="getAcceptableFileType(isAssetUploadRequired)"
      :showImageBrief="showImageBrief"
      :fileValidators="fileValidators"
      :canAppproveAsset="canAppproveAsset"
      :canFinalizeAsset="true"
      :isQuestionImageMandatory="!schema.isHomeworkOptional"
      :isDescriptionOptional="schema.isHomeworkOptional"
      :assetTypeMapping="{
        question: AssetTypes.EP_HOMEWORK_QUESTION_ASSET_TYPE,
        question_answer: AssetTypes.EP_HOMEWORK_QUESTION_ANSWER_ASSET_TYPE,
      }"
      :descriptionMaxLength="schema.homeworkDescriptionMaxLength"
      :showTeacherTip="schema.showHomeworkTeacherTip"
      :teacherTipMaxLength="schema.classworkTeacherTipMaxLength"
    />
    <LegacyClassWorkHomeWork
      v-else-if="schema.showLegacyClassWorkHomeWork"
      :data="lessonPlanData.application"
      :applicationStaticText="applicationBockStaticTexts.application"
      :canShowDiffIcon="canShowDiffIcon"
      :isNotReadOnly="isNotReadOnly"
      section="homework"
      :sectionDiff="applicationDiff.homework || {}"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :acceptableFileType="acceptableFileType"
      :sectionSignedUrls="applicationSignedUrls.homework"
      :canApproveAsset="canAppproveAsset"
      :sectionText="applicationBockStaticTexts.application.homework.paragraph_text1"
      sectionTextPlaceholder="<<practice_questions>>"
      :staticTexts2="applicationBockStaticTexts.application.homework.paragraph_text2"
      :greetingText="applicationBockStaticTexts.application.homework.greeting_text"
      :label="AppTexts.SELECT_HOMEWORK_QUESTION_FILE"
      :fileValidators="fileValidators"
    />
    <!-- Practice Lesson -> QuestionTeacherTips -->
    <div v-if="schema.showQuestionTeacherTip">
      <BlockTitle title="Teacher Support Tips" />

      <div>Walk around the class and help students as needed, using the following tips.</div>

      <div>
        <BlockTitle title="Additional Support Tips" />
        <Keywords
          class="mgn-Bm"
          :placeholder="AppTexts.SUPPORT_TIP"
          :descriptionPlaceholder="AppTexts.SUPPORT_TIP_DESCRIPTION"
          :items="lessonPlanData.application.support_tips"
          :addKeyword="() => addSupportTip(schema.maxSupportTipsLength)"
          :deleteKeyword="deleteSupportTip"
          :keywordsDiff="applicationDiff.support_tips || []"
          :canShowDiffIcon="canShowDiffIcon"
          :isNotReadOnly="isNotReadOnly"
          :maxKeywordLength="schema.maxSupportTipsLength"
          :maxNameLength="schema.supportTipTextMaxLength"
          :maxDescriptionLength="schema.supportTipDescriptionMaxLength"
          :buttonText="AppTexts.ADD_SUPPORT_TIP"
          diffIdentifierPrefix="application.support_tips"
          :isKeywordNameOptional="false"
        />
      </div>

      <TeacherTips
        :lessonPlanData="lessonPlanData"
        :showImageBrief="showImageBrief"
        :fileValidators="fileValidators"
        :isReadOnly="!isNotReadOnly"
        :isAssetUploadRequired="isAssetUploadRequired"
        :canShowDiffIcon="canShowDiffIcon"
        :schema="schema"
        :applicationDiff="applicationDiff"
        :canAppproveAsset="canAppproveAsset"
        :applicationSignedUrls="applicationSignedUrls"
        :localQuestions="localQuestions"
        :questionMetaInfo="questionMetaInfo"
        :lessonPlans="currentProjectQuestionLinkableLessonPlans"
        :saveForm="saveForm"
      />
      <div v-if="showAddQuestionTeacherTipOptions">
        <div style="display: flex;">
          <div style="flex: 1;">
            <BlockTitle :title="`Question Tip`" />
          </div>
          <div>
            <v-btn icon @click="hideQuestionTeacherTipOptions()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row>
          <v-col>
            <div style="width=100%;display: flex;position: relative;">
              <v-btn
                class="xs-accent-button"
                :class="!isNotReadOnly ? 'disable-btn-click-event' : ''"
                @click="onClickAddQuestionTipWithAsset()"
                dense
              >
                ADD QUESTION AS ASSET
              </v-btn>
              <div style="margin-top: 5px; margin-left: 10px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>Add questions as screenshot/image</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
          <v-col>
            <AddQuestionDialog
              :onSubmit="(data) => onSubmit(data)"
              :maxItemsCanAdd="10"
              :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
              :questionPurpose="VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE"
              :lpType="lpType"
            >
              <div style="width=100%;display: flex;position: relative;">
                <AddItemButton
                  buttonText="ADD VIRTUAL QUESTION"
                  :isReadOnly="isReadOnly"
                  :canAddItem="true"
                  :remainingItemsCount="10"
                  remainingItemsSuffix="question"
                  :onClick="() => {}"
                />
                <div style="margin-top: 5px; margin-left: 2px;">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>Add questions from the Question Bank</span>
                  </v-tooltip>
                </div>
              </div>
            </AddQuestionDialog>
          </v-col>
        </v-row>
      </div>
      <v-btn
        v-else
        class="xs-accent-button"
        :class="!isNotReadOnly ? 'disable-btn-click-event' : ''"
        @click="onClickAddQuestionTip()"
        dense
      >
        Add Question Teacher Tip
      </v-btn>
    </div>
    <section class="mgn-Tl" v-if="schema.showPracticeHomework">
      <BlockTitleWithSubtitle :subTitle="AppTexts.HOMEWORK" />
      <PracticeHomework
        :data="lessonPlanData.application.homework || {}"
        :diffChanges="applicationDiff.homework || {}"
        :isAssetUploadRequired="isAssetUploadRequired"
        :signedUrls="applicationSignedUrls.homework"
        :uploadingStatus="uploadingStatus"
        :acceptableFileType="getAcceptableFileType(isAssetUploadRequired)"
        :showImageBrief="showImageBrief"
        :fileValidators="fileValidators"
        :canAppproveAsset="canAppproveAsset"
        :isReadOnly="!isNotReadOnly"
        :canShowDiffIcon="canShowDiffIcon"
      />
    </section>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BlockTitle from '@/components/inputs/BlockTitle';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import Step from '@/components/inputs/Step';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { APPLICATION_BLOCK_KEY_OF_STATE_LP_DATA, LP_TYPES } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import AssetTypes from '@/constants/AssetTypes';
import ClassWorkHomeWork from '@/components/lessonPlanTemplate/commonLpComponents/ClassWorkHomeWork';
import PracticeHomework from '@/components/lessonPlanTemplate/practice/PracticeHomework';
import Keywords from '@/components/inputs/Keywords';
import { getAcceptableFileType } from '@/utils';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import LegacyClassWorkHomeWork from '@/components/lessonPlanTemplate/legacyLpComponents/ClassWorkHomeWork';
import TeacherTips from '@/components/lessonPlanTemplate/practice/TeacherTips.vue';
import AddQuestionDialog from '@/components/Questions/AddQuestionDialog.vue';
import AddItemButton from '@/components/Questions/AddItemButton.vue';
import { LINKABLE_LESSON_TYPES_IN_VIRTUAL_QUESTION } from '@/constants/common';
import { sortBy, cloneDeep, get } from 'lodash';
import { VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE } from '@/constants/question.js';
import StepWithHeader from '@/components/lessonPlanTemplate/commonLpComponents/StepWithHeader';
import XSRightSwitch from '@/components/lessonPlanTemplate/commonLpComponents/XSRightSwitch.vue';
import { COMPONENT_TO_DS_MAP } from '@/constants/componentDataStructure.js';
import { isSlideLayoutEnabled, keysOtherThanLayout } from '@/utils/slideLayoutHelpers.js';
import MultiSlideLayout from '@/components/lessonPlanTemplate/commonLpComponents/MultiSlideLayout';

export default {
  name: 'ApplicationSection',
  components: {
    BlockTitle,
    BlockTitleWithSubtitle,
    Step,
    XSDisplayCard,
    ClassWorkHomeWork,
    LegacyClassWorkHomeWork,
    RichTextEditor,
    Keywords,
    TeacherTips,
    AddQuestionDialog,
    AddItemButton,
    PracticeHomework,
    StepWithHeader,
    XSRightSwitch,
    MultiSlideLayout,
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      questionTeacherTipRevision: 0,
      APPLICATION_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      LP_TYPES,
      BASIC_TOOLS,
      showAddQuestionTeacherTipOptions: false,
      blockLessonsFetchInitiated: false,
      currentProjectQuestionLinkableLessonPlans: [],
      VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE,
      localQuestions: [],
      LINKABLE_LESSON_TYPES_IN_VIRTUAL_QUESTION,
      COMPONENT_TO_DS_MAP,
    };
  },
  methods: {
    ...mapActions([
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'updateApplicationClassworkDescription',
      'copySingleAssetAction',
      'addSupportTip',
      'deleteSupportTip',
      'getLessonPlansInBlock',
      'loadQuestionDataForList',
      'addPracticeForStep',
      'deletePracticeForStep',
    ]),
    get,
    getAcceptableFileType,
    isSlideLayoutEnabled,
    keysOtherThanLayout,
    importApplicationHomeworkQuestionImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.application.homework.question.assetId
            : this.lessonPlanData.application.homework.question.brief.assetId,
          assetType: AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE,
        });
      }
    },
    copyApplicationHomeworkQuestionImage(previewUrl, briefUrl) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE,
      });
    },
    importApplicationHomeworkAnswerImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.application.homework.question_answer.assetId
            : this.lessonPlanData.application.homework.question_answer.brief.assetId,
          assetType: AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE,
        });
      }
    },
    copyApplicationHomeworkAnswerImage(previewUrl, briefUrl) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE,
      });
    },
    importApplicationClassworkImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.application.classwork.assetId
            : this.lessonPlanData.application.classwork.brief.assetId,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        });
      }
    },
    copyApplicationClassworkImage(previewUrl, briefUrl) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
      });
    },
    getMorePracticeInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedUrl: '',
        assetSignedBriefUrl: '',
        loadingStatus: 'not_started',
      };

      if (this.applicationSignedUrls) {
        let instruction = this.applicationSignedUrls.more_practice[stepIndex];
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.assetSignedUrl = asset.assetSignedUrl;
            }
            previewUrl.assetSignedBriefUrl = asset.brief.assetSignedUrl;
            previewUrl.loadingStatus = asset.loadingStatus;
          }
        }
      }

      return previewUrl;
    },
    getFileNameFromUrl,
    updateDescriptionToState(description) {
      this.updateApplicationClassworkDescription({ description });
    },
    isMorePracticeStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.applicationDiff.more_practice[stepIndex].isChanged == true ||
            this.applicationDiff.more_practice[stepIndex].description.isChanged == true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isMorePracticeAssetBriefChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.applicationDiff.more_practice[stepIndex].isChanged == true ||
            this.applicationDiff.more_practice[stepIndex].assets[assetIndex].isChanged == true ||
            this.applicationDiff.more_practice[stepIndex].assets[assetIndex].brief.isChanged == true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isMorePracticeAssetImageChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          if (this.isAssetUploadRequired) {
            return (
              this.applicationDiff.more_practice[stepIndex].isChanged == true ||
              this.applicationDiff.more_practice[stepIndex].assets[assetIndex].isChanged == true ||
              this.applicationDiff.more_practice[stepIndex].assets[assetIndex].url.isChanged == true
            );
          } else {
            return (
              this.applicationDiff.more_practice[stepIndex].isChanged == true ||
              this.applicationDiff.more_practice[stepIndex].assets[assetIndex].isChanged == true ||
              this.actionDiff.instructions[stepIndex].assets[assetIndex].brief_url.isChanged == true
            );
          }
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDifferentiationTipsChanged(blockName) {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.differentiation_tips[blockName].description.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getClasswordIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.classwork[type].isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getHomewordIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.homework[type].isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isTeacherTipChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.teacher_tip.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    onClickAddQuestionTip() {
      this.showAddQuestionTeacherTipOptions = true;
    },
    hideQuestionTeacherTipOptions() {
      this.showAddQuestionTeacherTipOptions = false;
    },
    groupedQuestion(questions) {
      let questionData = {};
      questions.forEach((datum) => {
        if (!datum.questionGroupId) {
          questionData[datum._id] = [cloneDeep(datum)];
        } else {
          if (!questionData[datum.questionGroupId]) {
            questionData[datum.questionGroupId] = [];
          }
          questionData[datum.questionGroupId].push(cloneDeep(datum));
        }
      });
      return questionData;
    },

    onSubmit(data) {
      this.hideQuestionTeacherTipOptions();
      const questionsTips = this.groupedQuestion(data);
      Object.keys(questionsTips).forEach((_datum) => {
        this.$set(
          this.lessonPlanData.application.teacher_tips,
          this.lessonPlanData.application.teacher_tips.length,
          {
            questionNumber: '',
            isAuthoredQuestion: true,
            questions: questionsTips[_datum].map((_q) => _q._id),
          }
        );
        if (!this.applicationSignedUrls.teacher_tips) {
          this.$set(this.applicationSignedUrls, 'teacher_tips', []);
        }
        this.$set(
          this.applicationSignedUrls.teacher_tips,
          this.applicationSignedUrls.teacher_tips.length,
          {}
        );
      });

      for (const iterator of data) {
        this.localQuestions.push(iterator);
      }
      this.saveForm();
    },
    onClickAddQuestionTipWithAsset() {
      this.hideQuestionTeacherTipOptions();

      this.$set(
        this.lessonPlanData.application.teacher_tips,
        this.lessonPlanData.application.teacher_tips.length,
        {
          questionNumber: '',
          description: '',
          assets: [
            {
              type: '',
              assetId: '',
              brief: {
                assetId: '',
                text: '',
              },
              placeholder: {
                assetId: '',
              },
            },
          ],
        }
      );

      if (!this.applicationSignedUrls.teacher_tips) {
        this.$set(this.applicationSignedUrls, 'teacher_tips', []);
      }

      this.$set(
        this.applicationSignedUrls.teacher_tips,
        this.applicationSignedUrls.teacher_tips.length,
        {
          description: '',
          assets: [
            {
              assetSignedUrl: '',
              brief: {
                assetSignedUrl: '',
              },
              loadingStatus: '',
            },
          ],
        }
      );
    },
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    lessonPlanData: {
      type: Object,
      required: true,
    },
    deletePractice: {
      type: Function,
      required: true,
    },
    addPractice: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    applicationSignedUrls: {
      type: Object,
      required: true,
    },
    applicationDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    workSheetEnabled: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    saveForm: {
      type: Function,
      default: () => {},
    },
    canAppproveAsset: { type: Boolean, required: true },
    allSignedUrls: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      applicationBockStaticTexts: (state) => state.lessonPlan.staticTexts,
      lpType: (state) => state.lessonPlan.lpType,
      currentProject: (state) => state.currentProject,
      lessonPlansInBlock: (state) => state.lessonPlansInBlock,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
    }),
  },
  watch: {
    currentProject: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.blockLessonsFetchInitiated != true) {
          // Fetch all lesson plans of this block
          this.getLessonPlansInBlock({ id: val.block.id });
          this.blockLessonsFetchInitiated = true;

          const questionsId = [];
          if (
            this.lessonPlanData.application &&
            this.lessonPlanData.application.teacher_tips &&
            this.lessonPlanData.application.teacher_tips.length > 0
          ) {
            for (const teacherTip of this.lessonPlanData.application.teacher_tips) {
              if (teacherTip.isAuthoredQuestion) {
                for (const iterator of teacherTip.questions) {
                  questionsId.push(iterator);
                }
              }
            }
          }

          if (questionsId.length > 0) {
            this.loadQuestionDataForList({ questionsId });
          }
        }
      },
    },
    lessonPlansInBlock: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.length) {
          let unsortedList = val
            .map((_, index) => {
              return {
                library_id: parseInt(_.id),
                name: _.displayName || `LP ${index + 1}: ${_.name}`,
                type: _.type,
                sequence: _.sequence,
              };
            })
            .filter((_) => this.LINKABLE_LESSON_TYPES_IN_VIRTUAL_QUESTION.includes(_.type));
          this.currentProjectQuestionLinkableLessonPlans = sortBy(unsortedList, (o) => o.sequence);
        }
      },
    },
  },
};
</script>
<style scoped>
.small-text-field {
  zoom: 0.8;
  height: 40px;
  transform: translateY(-10px);
}
.sub-head {
  font-weight: 500;
}
</style>
