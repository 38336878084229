<template>
  <div>
    <v-dialog v-model="showLoadingDialog" max-width="290" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">{{
          loadingStatus.message ? loadingStatus.message : 'Brewing your coffee...'
        }}</v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FormLayout
      :moveToNextStageButtonText="getNextStageButtonText()"
      :canMoveProjectLP="!auth.canMoveProjectLP || currentStage == 'completedStage'"
      :breadCrumbText="breadCrumbText"
      :buttonActions="buttonActions"
      :auth="auth"
      :onClickMoveToNextStage="onClickMoveToNextStage"
      :onClickCompleteAndPublish="() => {}"
      :isCompleted="currentStage == 'completedStage'"
      :isPublished="false"
      :canPublishProject="auth.canPublishProject || auth.canSaveProjectLP"
      :onClickPublish="() => {}"
      :rightSidePreviewContent="rightSidePreviewContent"
      :toggleRightSidePanel="toggleRightSidePanel"
      :showRightSidePanel="showRightSidePanel"
      :currentStage="currentStage"
      :loggedInUserEmail="loggedInUserEmail ? loggedInUserEmail : ''"
      :commentsHash="commentsHash"
      :diffOptions="{
        diffCloseHandler: diffCloseHandler,
        fieldDiff: fieldDiff,
        currentDiffIdentifier: currentDiffIdentifier,
      }"
      :commentsSaveButtonClickHandler="commentsSaveButtonClickHandler"
      :commentsResolveButtonClickHandler="commentsResolveButtonClickHandler"
      :closeComments="closeComments"
      :commentSectionIdentifier="commentSectionIdentifier"
      :onClickMoveToPreviousStage="onClickMoveToPreviousStage"
      :projectType="'question'"
      :previewData="previewData"
      :isLessonPlan="true"
      :lpPreviewUrl="lpPreviewUrl"
      :isAdmin="isAdmin"
    >
      <v-row class="left-hand-side-form">
        <v-form ref="form" style="width: 100%;">
          <section
            v-if="
              lpType == LP_TYPES_HASH.REVISION ||
              lpType == LP_TYPES_HASH.ASSESSMENT ||
              lpType == LP_TYPES_HASH.MENTAL_MATH
            "
            class="revision-question"
          >
            <QuestionsLessonTemplate
              :lpType="lpType"
              :lessonPlanDetails="lessonPlanDetails"
              :lessonPlanData="lessonPlan.data"
              :signedUrls="signedUrls"
              :componentOptions="componentOptions"
              :currentStageDetails="currentStageDetails"
              :uploadingStatus="uploadingStatus"
              :showImageBrief="showImageBrief"
              :canAppproveAsset="canAppproveAsset"
              :onChangeLessonPlanData="handleQuestionSave"
              :toggleComments="toggleComments"
              :diff="diff"
              :auth="auth"
              :acceptableFileType="acceptableFileType"
              :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
            />
          </section>
          <section v-else-if="lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS" class="virtual-question">
            <VirtualQuestion
              :data="lessonPlan.data"
              :onQuestionDataChanged="onQuestionDataChanged"
              :handleQuestionSave="handleQuestionSave"
              :componentOptions="componentOptions"
              :canAppproveAsset="canAppproveAsset"
              :toggleComments="toggleComments"
              :lpType="lpType"
              :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
              :currentStage="currentStage"
              :lessonPlanDetails="lessonPlanDetails"
              :currentStageDetails="currentStageDetails"
              :currentProjectAssignees="currentProject.assignees"
              :onConfirmRemoveQuestion="handleOnConfirmRemoveQuestion"
            />
          </section>
          <div v-else>Un handled project type</div>
        </v-form>
      </v-row>
    </FormLayout>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  LESSON_PLAN_LOADING_STATES,
  LP_FORM_SUBMIT_TEXT,
  LP_STAGES,
  RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
} from '@/constants/LessonPlan';
import AppTexts from '@/constants/AppTexts';
import {
  getAssetUploadRequiredStatus,
  getAcceptableFileType,
  validateAssetAuthorized,
  validateAllAssetsPresent,
} from '@/utils';
import { PROJECT_TYPE_LESSON_PLAN } from '@/constants/Project';
import Errors from '@/constants/Errors';
import { LP_TYPES_HASH, LINKABLE_LESSON_TYPES_IN_VIRTUAL_QUESTION } from '@/constants/common';
import questionsOprogramProject from '@/constants/questionsOprogramProject';
import FormLayout from '@/components/layouts/FormLayout';
import QuestionsLessonTemplate from '@/components/Questions/QuestionsLessonTemplate.vue';
import VirtualQuestion from '@/components/Questions/VirtualQuestion.vue';
import { APP_COOKIE_NAME } from '@/constants/common';
import { getCookie } from '@/utils/auth';
import { sortBy } from 'lodash';

export default {
  name: 'StandardLPForm',
  components: {
    FormLayout,
    QuestionsLessonTemplate,
    VirtualQuestion,
  },
  data() {
    return {
      lessonPlanDataCalledForFirstTime: true,
      currentStageWatcher: '',
      isSaved: true,
      currentStageDetails: {
        idenx: 0,
        name: LP_STAGES[0],
      },
      showImageBrief: true,
      isAssetUploadRequired: false,
      acceptableFileType: '',
      canShowDiffIcon: false,
      lpPreviewUrl: process.env.VUE_APP_LP_PREVIEW_ENDPOINT,
      projectType: PROJECT_TYPE_LESSON_PLAN,
      buttonActions: {
        saveForm: this.saveForm,
        validateContent: () => this.handleValidateForm(),
        redirectToPreviousPage: this.redirectToPreviousPage,
        downloadContentAssets: this.downloadLPAssets,
        activateContent: () => this.activateContent(),
      },
      commentSectionIdentifier: '',
      LP_TYPES_HASH,
      LINKABLE_LESSON_TYPES_IN_VIRTUAL_QUESTION,
      currentProjectQuestionLinkableLessonPlans: [],
      firstCall: true,
      APP_COOKIE_NAME,
    };
  },
  created() {
    this.getProjectDetails({
      projectId: this.$route.params.projectId,
    });
    this.getLessonPlanData({
      projectId: this.$route.params.projectId,
      libraryId: this.$route.params.libraryId,
      projectType: this.projectType,
    });
    this.setLibraryId(this.$route.params.libraryId);
    this.getLessonPlanSaveAndMoveToNextStagePermission({
      projectId: this.$route.params.projectId,
      libraryId: this.$route.params.libraryId,
    });
    this.getCurrentUserAuthPermissions();
    // this.addEventLisnerForWindowUnLoad();
  },
  computed: {
    ...mapState({
      lessonPlan: (state) => state.lessonPlan,
      currentStage: (state) => state.lessonPlan.currentStage,
      lessonPlanData: (state) => state.lessonPlan.data,
      loadingStatus: (state) => state.lessonPlan.lessonPlanLoadingStatus,
      auth: (state) => state.auth.lessonPlan,
      rightSidePreviewContent: (state) => state.rightSideContent.rightSidePreviewContent,
      showRightSidePanel: (state) => state.rightSideContent.showRightSidePanel,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      currentDiffIdentifier: (state) => state.rightSideContent.currentDiffIdentifier,
      fieldDiff: (state) => state.rightSideContent.fieldDiff,
      saveLessonPlanTriggerState: (state) => state.lessonPlan.saveLessonPlanTriggerState,
      signedUrls: (state) => state.lessonPlan.signedUrls,
      currentQuestionData: (state) => state.questions.questionData,
      currentProject: (state) => state.currentProject,
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      diff: (state) => state.lessonPlan.diff,
      lessonPlansInBlock: (state) => state.lessonPlansInBlock,
      questionDataForList: (state) => state.questions.questionDataForList,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
      isAdmin: (state) => state.auth.isAdmin,
    }),
    canAppproveAsset() {
      return this.currentStage == 'assetAuthorizingStage';
    },
    showLoadingDialog() {
      return this.loadingStatus.status == LESSON_PLAN_LOADING_STATES.inProgress;
    },
    breadCrumbText() {
      return `Home > Block - ${this.$route.query.blockTitle} (${this.$route.query.bId})`;
    },
    componentOptions() {
      return {
        isNotReadOnly: this.auth.canSaveProjectLP,
        isReadOnly: !this.auth.canSaveProjectLP,
        isAssetUploadRequired: this.isAssetUploadRequired,
        canShowDiffIcon: this.canShowDiffIcon,
        fileAcceptType: this.acceptableFileType,
        fileUploadingStatus: {},
        signedAssetUrls: this.signedUrls,
        errorOfRequired: Errors.REQUIRED_FIELD,
        // validationFlag: this.validationFlag,
      };
    },
    lpType() {
      return this.$route.query.lpType;
    },
    template() {
      return this.$route.query.template;
    },
    lessonPlanDetails() {
      return {
        title: this.lessonPlan.name,
        details: `${this.$route.query.boardName} - Grade ${this.$route.query.grade} - Block ${this.$route.query.blockTitle} (${this.$route.query.bId})`,
        time: this.lessonPlan.lpType == LP_TYPES_HASH.REVISION ? '40 mins' : '35 mins',
      };
    },
    previewData() {
      if (this.template == 'question') {
        let lessonPlan = JSON.parse(JSON.stringify(this.lessonPlan));
        if (lessonPlan.data.application.mental_math) {
          lessonPlan.data.application.mental_math.questions = this.sectionQuestionData(
            'mental_math'
          );
        }
        if (lessonPlan.data.application.problem_solving) {
          lessonPlan.data.application.problem_solving.questions = this.sectionQuestionData(
            'problem_solving'
          );
        }

        if (lessonPlan.data.application.homework) {
          lessonPlan.data.application.homework.questions = this.sectionQuestionData('homework');
        }
        if (lessonPlan.data.application.classwork) {
          lessonPlan.data.application.classwork.questions = this.sectionQuestionData('classwork');
        }
        const token = this.getCookie(this.APP_COOKIE_NAME);
        const assetServiceBaseUrl = process.env.VUE_APP_ASSET_VAULT_ENDPOINT + '/';
        return {
          ...lessonPlan,
          template: this.$route.query.template,
          awsSignedUrls: this.signedUrls || {},
          displayBriefUrl: !this.isAssetUploadRequired,
          token,
          assetServiceBaseUrl,
        };
      }
      return {};
    },
  },
  watch: {
    lessonPlan: {
      immediate: true,
      deep: true,
      handler(val) {
        if (
          this.firstCall &&
          val &&
          Object.keys(val).length > 0 &&
          Object.keys(val.data).length > 0
        ) {
          if (val.data.time) {
            if (LP_TYPES_HASH.REVISION == val.lpType) {
              this.lessonPlan.data.time = '40 mins';
            }
            this.firstCall = false;
          }
        }
      },
    },
    currentStage: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          if (
            val &&
            val.length &&
            this.currentStageWatcher &&
            this.currentStageWatcher.length &&
            val != this.currentStageWatcher
          ) {
            this.reSetLessonPlanFormState();
            this.redirectToPreviousPage();
          } else {
            let currentStageIndex = Object.keys(LP_FORM_SUBMIT_TEXT).indexOf(val);
            this.currentStageDetails = {
              index: currentStageIndex,
              stageName: LP_STAGES[val],
            };
            let assetUploadStageIndex = Object.keys(LP_STAGES).indexOf('assetUploadStage');
            this.showImageBrief = currentStageIndex < assetUploadStageIndex;
            this.isAssetUploadRequired = getAssetUploadRequiredStatus(val);
            this.acceptableFileType = getAcceptableFileType(this.isAssetUploadRequired);
            this.currentStageWatcher = val;
            this.canShowDiffIcon = currentStageIndex > 1;
          }
        }
      },
    },
    saveLessonPlanTriggerState: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.saveForm(false);
        }
      },
    },
    lessonPlansInBlock: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.length) {
          let unsortedList = val
            .map((_) => {
              return {
                library_id: parseInt(_.id),
                name: _.displayName || `LP ${_.displaySequence}: ${_.name}`,
                type: _.type,
                sequence: _.sequence,
              };
            })
            .filter((_) => this.LINKABLE_LESSON_TYPES_IN_VIRTUAL_QUESTION.includes(_.type));
          this.currentProjectQuestionLinkableLessonPlans = sortBy(unsortedList, (o) => o.sequence);
        }
      },
    },
    currentProject: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          // Fetch all lesson plans of this block
          this.getLessonPlansInBlock({ id: val.block.id });
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'reSetLessonPlanFormState',
      'moveToNextStage',
      'moveToPrevioustage',
      'getLessonPlanData',
      'saveLessonPlanData',
      'toggleRightSidePanel',
      'setRightSidePreviewContent',
      'saveComment',
      'resolveComment',
      'setLibraryId',
      'getLessonPlanSaveAndMoveToNextStagePermission',
      'validateLessonPlanData',
      'setRightSidePanelVisibility',
      'fetchProjectLessonPlanSectionComment',
      'setQuestionInitialDataToStore',
      'getProjectDetails',
      'getLessonPlansInBlock',
      'onConfirmRemoveQuestion',
      'questionForceSaveWithData',
      'getCurrentUserAuthPermissions',
      'activateLessonPlanAndPublishToTeacherReadinessApp',
    ]),
    getCookie,
    getAssetUploadRequiredStatus,
    getAcceptableFileType,
    handleQuestionSave(payload) {
      const { data } = payload;
      this.lessonPlan.data = data;

      this.saveForm();
    },
    saveForm() {
      this.lessonPlanDataCopy = JSON.parse(JSON.stringify(this.lessonPlan.data));
      if (this.lessonPlan.name) {
        this.saveLessonPlanData({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          data: this.lessonPlan,
        });
      }
    },
    handleValidateForm() {
      const validationStatus = this.validateLPForm();
      if (validationStatus) {
        this.validateLessonPlanData({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    getNextStageButtonText() {
      let buttonText;
      const stages = Object.keys(LP_FORM_SUBMIT_TEXT);
      const nextStageIndex = stages.indexOf(this.currentStageWatcher || stages[0]) + 1;

      if (stages[nextStageIndex]) {
        buttonText = AppTexts.MOVE_TO_PREFIX_TEXT + LP_FORM_SUBMIT_TEXT[stages[nextStageIndex - 1]];
      } else {
        buttonText = 'Completed';
      }

      return buttonText;
    },
    redirectToPreviousPage() {
      this.$router.push({
        path: '/projects/' + this.$route.params.projectId,
      });
    },
    onClickMoveToNextStage() {
      const validationStatus = this.validateLPForm();
      if (validationStatus) {
        let { projectId, libraryId } = this.$route.params;
        this.moveToNextStage({
          projectId,
          libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    onClickMoveToPreviousStage() {
      const validationStatus = this.validateLPForm();
      if (validationStatus) {
        let { projectId, libraryId } = this.$route.params;
        this.moveToPrevioustage({
          projectId,
          libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    ProcessedQuestionData() {
      let data = JSON.parse(JSON.stringify(this.lessonPlan.data));
      data.forEach((_datum) => {
        this.processQuestionData(_datum, 'parse');
      });
      return data;
    },
    dataToValidate() {
      return this.lpType == LP_TYPES_HASH.VIRTUAL_QUESTIONS
        ? this.ProcessedQuestionData()
        : this.lessonPlan.data;
    },
    validateAssetAuthorized() {
      return validateAssetAuthorized(
        this.dataToValidate(),
        ['assetId'],
        {
          assetId: 'assetApproved',
        },
        ['brief', 'placeholder'],
        0
      );
    },
    validateAllAssetsAreFilled() {
      // todo: update the array in second parameter
      return validateAllAssetsPresent(
        this.dataToValidate(),
        ['assetId'],
        ['brief', 'placeholder'],
        0
      );
    },
    validateLPForm() {
      this.reassignFormInputs(this.$refs.form);
      if (this.currentStage == 'assetUploadStage') {
        let blankAsset = this.validateAllAssetsAreFilled();
        if (blankAsset) {
          this.notifyUserWithError(`There are ${blankAsset} Blank assets`);
          return false;
        }
      }
      if (this.canAppproveAsset) {
        let countUnApprovedAssets = this.validateAssetAuthorized();
        if (countUnApprovedAssets > 0) {
          this.notifyUserWithError(`There are ${countUnApprovedAssets} unapproved assets`);
          return false;
        }
      }

      if (this.$refs.form.validate()) {
        if (
          this.lessonPlan.lpType == LP_TYPES_HASH.REVISION &&
          this.lessonPlan.data.application.classwork &&
          this.lessonPlan.data.application.classwork.questions
        ) {
          let totalQuestionTime = 0;
          let self = this;
          this.lessonPlan.data.application.classwork.questions.forEach((linkedQuestionId) => {
            let questionFromList = self.questionDataForList.find(
              (question) => question._id == linkedQuestionId
            );
            if (questionFromList) {
              totalQuestionTime += parseInt(questionFromList.time);
            }
          });
          if (totalQuestionTime < 30 || totalQuestionTime > 60) {
            console.log(totalQuestionTime);
            let errorMessage =
              'sum of the times of questions added should not be less than 30 or should not exceed 60 minutes';
            this.notifyUserWithError(errorMessage);
            return false;
          }
        }
        const getMinutesFromTimeString = (timeString) => {
          try {
            return +timeString.split(' ')[0];
          } catch (error) {
            return 0;
          }
        };
        let lp = this.lessonPlan;
        let overallTime = lp.data ? getMinutesFromTimeString(lp.data.time) : 0;
        let applicationTime =
          lp.data && lp.data.application ? getMinutesFromTimeString(lp.data.application.time) : 0;

        if (this.lessonPlan.lpType != LP_TYPES_HASH.REVISION && overallTime !== applicationTime) {
          let errorMessage = 'Sum of all sub-section should be equal to 35';
          this.notifyUserWithError(errorMessage);
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    notifyUserWithError(text) {
      this.$notify({
        title: 'Failed',
        text: text,
        type: 'error',
        duration: -1,
      });
    },
    processQuestionData(data, processType) {
      if (data.stimulus && data.questionGroupId != null && data.questionSequenceInGroup == 0) {
        data.stimulus =
          processType == 'parse' ? JSON.parse(data.stimulus) : JSON.stringify(data.stimulus);
      }
      let { questionType, questionData } = data;
      questionData.questionText =
        processType == 'parse'
          ? JSON.parse(questionData.questionText)
          : JSON.stringify(questionData.questionText);
      if (
        questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ ||
        questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ ||
        questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF ||
        questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF
      ) {
        if (questionData.options) {
          questionData.options.forEach((_option) => {
            _option.optionText =
              processType == 'parse'
                ? JSON.parse(_option.optionText)
                : JSON.stringify(_option.optionText);
          });
        }
        if (questionData.optionKeys) {
          questionData.optionKeys.forEach((_option) => {
            _option.optionText =
              processType == 'parse'
                ? JSON.parse(_option.optionText)
                : JSON.stringify(_option.optionText);
          });
        }
        if (questionData.optionValues) {
          questionData.optionValues.forEach((_option) => {
            _option.optionText =
              processType == 'parse'
                ? JSON.parse(_option.optionText)
                : JSON.stringify(_option.optionText);
          });
        }
      }
      if (
        questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA ||
        questionType == questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA
      ) {
        questionData.answerGuideText =
          processType == 'parse'
            ? JSON.parse(questionData.answerGuideText)
            : JSON.stringify(questionData.answerGuideText);
      }
    },
    reassignFormInputs(form) {
      const inputs = [];
      // Copied from VForm's previous life* which had a getInputs() function
      const search = (children, depth = 0) => {
        for (let index = 0; index < children.length; index++) {
          const child = children[index];
          if (child.errorBucket !== undefined) inputs.push(child);
          else search(child.$children, depth + 1);
        }
        if (depth === 0) return inputs;
      };
      search(form.$children);
      form.inputs = inputs;
    },
    diffCloseHandler() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
    },
    closeComments() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
      this.commentSectionIdentifier = '';
    },
    commentsSaveButtonClickHandler(text, stageName) {
      if (this.auth.canAddComment) {
        this.saveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.commentSectionIdentifier,
          text,
          stageName,
        });
      }
    },
    commentsResolveButtonClickHandler(stageName) {
      if (this.auth.canResolveComment) {
        this.resolveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.commentSectionIdentifier,
          stageName,
        });
      }
    },
    downloadAsset() {
      this.downloadLPAssets({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
      });
    },
    toggleComments(commentSectionIdentifier) {
      this.setRightSidePanelVisibility(true);

      if (
        commentSectionIdentifier == this.commentSectionIdentifier &&
        this.rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS
      ) {
        return true;
      } else {
        this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS);
        this.commentSectionIdentifier = commentSectionIdentifier;
      }

      if (this.rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS) {
        this.fetchProjectLessonPlanSectionComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: commentSectionIdentifier,
          projectType: this.$route.query.lpType == 'virtualQuestions' ? 'questions' : 'nonQuestion',
        });
      }
    },
    onQuestionDataChanged(val) {
      this.lessonPlan.data = val;
    },
    handleOnConfirmRemoveQuestion(question) {
      this.onConfirmRemoveQuestion({
        question,
        onSuccess: () => {
          this.questionForceSaveWithData({
            validateForm: false,
            showLoader: true,
            callback: this.handleQuestionSave,
          });
        },
      });
    },
    sectionQuestionData(section) {
      if (this.lessonPlanData.application[section]) {
        const questionIds = this.lessonPlanData.application[section].questions;

        const questionData = this.questionDataForList.filter((question) =>
          questionIds.includes(question._id)
        );

        return questionData.map((question) => {
          let emptySkill = { id: '', name: '' };
          let emptySubSkill = { id: '', name: '' };
          if (this.questionMetaInfo) {
            emptySkill =
              this.questionMetaInfo.skills.find((skill) => question.skill == skill.id) ||
              emptySkill;
            if (emptySkill.id != '') {
              emptySubSkill = (this.questionMetaInfo.subSkills[question.skill] || []).find(
                (subSkill) => subSkill.id == question.subSkill
              );
              emptySubSkill.id = '' + emptySubSkill.id;
            }
          }
          return { ...question, skill: emptySkill, subSkill: emptySubSkill };
        });
      } else {
        return [];
      }
    },
    async activateContent() {
      if (!this.isAdmin) {
        this.$notify({
          title: 'Failed',
          text: 'Admin can only activate a Lesson Plan.',
          type: 'warn',
        });
        return;
      }
      this.activateLessonPlanAndPublishToTeacherReadinessApp({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
      });
    },
  },
};
</script>

<style scoped>
.left-hand-side-form {
  width: 96%;
  margin: auto;
  margin-top: 50px;
}
</style>
