import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import apolloProvider from './apollo';
import VueProgressBar from 'vue-progressbar';
import Notifications from 'vue-notification';
import VueCookies from 'vue-cookies';
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import '@/styles/global.css';
import '@/fonts/index.css';
import Padder from '@/components/common/Padder.vue';
import XSDivider from '@/components/common/XSDivider.vue';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

Vue.use(vuetify);

Vue.use(Notifications);
Vue.component('DynamicScroller', DynamicScroller);
Vue.component('DynamicScrollerItem', DynamicScrollerItem);
Vue.use(VueVideoPlayer);
Vue.config.productionTip = false;

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px',
});

Vue.use(VueCookies);

Vue.component('Padder', Padder);
Vue.component('XSDivider', XSDivider);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  apolloProvider,
  mounted: function () {
    if (!this.$route.path.includes('xseed_academy')) {
      store.dispatch('authoriseUser');
    }
  },
}).$mount('#app');
