import gql from 'graphql-tag';

export const getMicroTopicsPaginationQuery = () => {
  return gql`
    query microtopicProjects($limit: Int, $offset: Int, $filters: MicrotopicProjectsFiltersInput) {
      microtopicProjects(limit: $limit, offset: $offset, filters: $filters) {
        count
        data {
          id
          name
          mtId
          subject {
            id
            name
            shortName
          }
          standard {
            id
            name
            displayName
          }
          topic {
            id
            name
          }
          theme {
            id
            name
          }
          projects {
            id
            name
            workflowStatus
            type
            contents {
              id
              library_id
              currentStage
            }
          }
        }
      }
    }
  `;
};

export const getFilterDataForMicroTopicListPageQuery = () => {
  return gql`
    {
      subjects {
        id
        name
        shortName
      }
      standards {
        id
        name
        displayName
      }
      strands {
        id
        name
      }
    }
  `;
};

export const getMicroTopicDetailsQuery = (microTopicId) => {
  return gql`
    {
      microtopicProject(microTopicId: ${microTopicId}) {
        id
        name
        description
        microSkills
        mtId
        lessonPlanMap
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
          shortName
        }
        topic {
          id
          name
        }
        theme {
          id
          name
        }
        projects {
          id
          name
          type
          workflowStatus
          createdAt
          updatedAt
          assignees {
            author {
              id
              name
            }
            reviewer1 {
              id
              name
            }
            reviewer2 {
              id
              name
            }
            academicSignOff {
              id
              name
            }
            editor {
              id
              name
            }
            visualizer {
              id
              name
            }
            assetUploader {
              id
              name
            }
            assetAuthorizer {
              id
              name
            }
            proofReader {
              id
              name
            }
          }
          contents{
            currentStage
          }
        
        }
      }
    }
  `;
};

export const createMicroTopicProjectQuery = () => {
  return gql`
    mutation createProject($input: ProjectCreateInputType!) {
      createProject(input: $input) {
        id
        name
        workflowStatus
        type
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
        }
        block {
          id
          bId
          title
        }
        contents {
          currentStage
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
      }
    }
  `;
};

export const deleteMicroTopicProjectMutation = () => {
  return gql`
    mutation deleteProject($id: ID!) {
      deleteProject(id: $id) {
        status
      }
    }
  `;
};

export const updateMicroTopicProjectQuery = () => {
  return gql`
    mutation updateProject($input: ProjectUpdateInputType!) {
      updateProject(input: $input) {
        id
        name
        workflowStatus
        type
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
        }
        block {
          id
          bId
          title
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
        contents {
          currentStage
        }
      }
    }
  `;
};
