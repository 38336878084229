<template>
  <div>
    <v-dialog v-model="showLoader" max-width="400" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          Brewing your coffee...
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="showDeletePopup" persistent max-width="390">
        <v-card>
          <v-card-title class="headline">
            Are you sure about deleting this question set?
          </v-card-title>
          <v-card-text>
            This action will delete the question set and it cannot be retrieved back. Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="showDeletePopup = false">
              CANCEL
            </v-btn>
            <v-btn color="green darken-1" text @click="deleteTest">
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-app-bar
      height="60px;"
      dense
      absolute
      color="white"
      elevation="0"
      scroll-target="#scrolling-techniques-7"
    >
      <v-row>
        <div class="bread-crumb-row">
          <a
            href="/learnometer"
            style="color: #818181; text-decoration: underline; margin-left: 20px;"
            >Learnometer Question Bank</a
          >
          <v-icon style="color: #818181;">mdi-chevron-right</v-icon>
          <a href="javascript:void(0)" style="color: #000000; font-size: 12px;"
            ><b>View Question Sets</b></a
          >
        </div>
        <v-spacer></v-spacer>
      </v-row>
    </v-app-bar>
    <v-card style="border: none; box-shadow: none; width: 100%; background-color: #fff0f0;">
      <v-simple-table style="background-color: #fff0f0;">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="landing-page">
                <div style="height: 300px; background-color: #fff0f0; margin-top: -25px;">
                  <div>
                    <v-col>
                      <v-row style="margin-top: 60px;">
                        <td>
                          <v-container class="ml-2">
                            <p
                              style="
                                font-weight: 700;
                                font-size: 20px;
                                margin-left: 60px;
                                margin-top: 55px;
                              "
                            >
                              View Question Sets
                            </p></v-container
                          >
                        </td>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 294px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                            align-items: center;
                            margin-left: 75px;
                          "
                        >
                          Search:
                        </v-col>

                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 174px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                            margin-left: 15px;
                          "
                        >
                          Blueprint:
                        </v-col>

                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 174px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                          "
                        >
                          Grade:
                        </v-col>
                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 180px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                            margin-left: 9px;
                          "
                        >
                          Subject:
                        </v-col>

                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 164px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                          "
                        >
                          Year:
                        </v-col>
                      </v-row>

                      <div
                        style="
                          width: 1300px;

                          height: 40px;
                          margin-left: 75px;
                        "
                      >
                        <v-row>
                          <div>
                            <v-text-field
                              placeholder="Type question set title"
                              dense
                              style="
                                font-size: 12px;
                                box-sizing: border-box;
                                border: 0.5px solid #818181;
                                margin-top: 3px;
                                width: 294px;
                              "
                              outlined
                              type="input"
                              single-line
                              hide-details
                              height="35"
                              v-model="questionSetName"
                            ></v-text-field>
                          </div>
                          <div
                            style="
                              position: relative;
                              height: 40px;
                              margin-left: 15px;
                              margin-top: 2px;
                            "
                          >
                            <v-select
                              outlined
                              placeholder="Select a blueprint"
                              :items="blueprints.data"
                              style="
                                font-size: 12px;
                                box-sizing: border-box;
                                border: 0.5px solid #818181;
                                width: 164px;
                              "
                              dense
                              hide-details
                              v-model="blueprintId"
                              item-text="name"
                              item-value="id"
                            ></v-select>
                          </div>
                          <div style="margin-left: 15px; margin-top: 2px;">
                            <v-select
                              outlined
                              placeholder="Select a grade"
                              :items="standards.data"
                              style="
                                font-size: 12px;
                                box-sizing: border-box;
                                border: 0.5px solid #818181;
                                width: 164px;
                              "
                              dense
                              hide-details
                              v-model="standardId"
                              item-text="name"
                              item-value="id"
                            ></v-select>
                          </div>

                          <div style="margin-left: 15px; margin-top: 2px;">
                            <v-select
                              outlined
                              style="
                                font-size: 12px;
                                box-sizing: border-box;
                                border: 0.5px solid #818181;
                                width: 164px;
                              "
                              placeholder="Select a subject"
                              :items="subjects.data"
                              dense
                              v-model="subjectId"
                              hide-details
                              item-text="name"
                              item-value="id"
                            ></v-select>
                          </div>

                          <div style="margin-left: 15px; margin-top: 2px;">
                            <v-select
                              outlined
                              style="
                                font-size: 12px;
                                box-sizing: border-box;
                                border: 0.5px solid #818181;
                                width: 164px;
                              "
                              placeholder="Select a year"
                              :items="years.data"
                              dense
                              item-text="name"
                              item-value="name"
                              hide-details
                              v-model="yearId"
                            ></v-select>
                          </div>

                          <div style="margin-top: 2px; margin-left: -20px;">
                            <v-btn
                              text
                              @click="resetFilter()"
                              style="
                                background: #fff0f0;
                                border: none;
                                box-shadow: none;
                                text-transform: unset !important;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                width: fit-content;
                                text-shadow: none;
                                margin-left: 25px;
                                align-content: center;

                                height: 15px;
                              "
                              >Clear filters</v-btn
                            >

                            <v-btn
                              text
                              @click="getQuestionSets()"
                              style="
                                background: #b81e22;
                                border-radius: 111px;
                                text-transform: unset !important;
                                font-weight: 600;
                                font-size: 12px;
                                color: #ffffff;
                                width: 110px;
                                margin-left: 19px;
                                height: 31px;
                              "
                              >Search</v-btn
                            >
                          </div>
                        </v-row>
                      </div>
                    </v-col>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- No questions are found from the filter-->
    <div
      v-if="this.showFilterQuestionSets && this.isSearchTriggered && isFilteredQuestionSetsEmpty()"
      style="display: flex; flex-direction: row; justify-content: center;"
    >
      <div class="no-question-sets-message">
        <p>No question sets found. Please change the filters.</p>
      </div>
    </div>
    <!-- No recently created questions found-->
    <div
      v-if="!this.showFilterQuestionSets && isRecentQuestionSetsEmpty()"
      style="display: flex; flex-direction: row; justify-content: center;"
    >
      <div class="no-question-sets-message">
        <p>You haven't created any question set yet.</p>
      </div>
    </div>

    <!-- Recently created questions-->
    <div
      v-if="this.showRecentQuestionSets && !this.isSearchTriggered"
      style="
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-left: 89px;
        align-items: flex-start;
        margin-top: 70px;
      "
    >
      <div class="question-sets-title">
        Recently Created Question Sets
      </div>
      <div v-for="question in questionSets.data" :key="question.id">
        <RelatedQuestionSetTile
          :questionSet="question"
          :deleteQuestionSetTrigger="deleteQuestionSetTrigger"
        />
      </div>
    </div>

    <!-- Question sets from filters-->
    <div
      v-if="this.showFilterQuestionSets && this.isSearchTriggered"
      style="
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-left: 89px;
        align-items: flex-start;
        margin-top: 30px;
      "
    >
      <div v-for="question in questionSetsFromFilters.data" :key="question.id">
        <RelatedQuestionSetTile
          :questionSet="question"
          :deleteQuestionSetTrigger="deleteQuestionSetTrigger"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RelatedQuestionSetTile from '@/components/learnometer/RelatedQuestionSetTile.vue';

export default {
  name: 'ViewQuestionSet',
  components: {
    RelatedQuestionSetTile,
  },
  created() {
    this.fetchStandards({
      allStandards: false,
    });
    this.fetchBlueprints();
    this.fetchSubjects();
    this.getRecentlyCreatedQuestionSets();
    this.fetchYears();
  },
  computed: {
    ...mapState({
      subjects: (state) => state.learnometer.subjectListApiResponse,
      standards: (state) => state.learnometer.standardsListApiResponse,
      questionSets: (state) => state.learnometer.recentlyCreatedQuestionSetsApiResponse,
      questionSetsFromFilters: (state) => state.learnometer.questionSetsUsingFiltersApiResponse,
      blueprints: (state) => state.learnometer.blueprintListApiResponse,
      delete: (state) => state.learnometer.deleteTestApiResponse,
      years: (state) => state.learnometer.yearListApiResponse,
    }),
    showLoader() {
      return (
        this.questionSets.status == 'in_progress' ||
        this.questionSetsFromFilters.status == 'in_progress' ||
        this.delete.status == 'in_progress'
      );
    },
  },
  watch: {
    standardId: {
      handler() {
        this.subjectId = '';
      },
    },
    subjectId: {
      handler() {
        this.yearId = '';
      },
    },
  },
  methods: {
    ...mapActions([
      'fetchStandards',
      'fetchSubjects',
      'fetchRecentlyCreatedQuestionSets',
      'fetchQuestionSetsUsingFilters',
      'fetchBlueprints',
      'deleteXLMTest',
      'fetchYears',
    ]),
    isCreatedQuestionSetsEmpty() {
      return this.questionSets.data.length == 0;
    },
    isQuestionSetsFromFiltersEmpty() {
      return this.questionSetsFromFilters.data.length == 0;
    },
    getRecentlyCreatedQuestionSets() {
      this.fetchRecentlyCreatedQuestionSets();
      this.showRecentQuestionSets = true;
    },
    resetFilter() {
      this.subjectId = '';
      this.standardId = '';
      this.questionSetName = '';
      this.blueprintId = '';
      this.isSearchTriggered = false;
      this.showRecentQuestionSets = true;
      this.showFilterQuestionSets = false;
    },
    getQuestionSets() {
      this.questionSetName = this.questionSetName.replace(/\s+/g, ' ').trim();
      this.fetchQuestionSetsUsingFilters({
        name: this.questionSetName == '' ? undefined : this.questionSetName,
        blueprintId: this.blueprintId == '' ? undefined : this.blueprintId,
        gradeId: this.standardId == '' ? undefined : Number(this.standardId),
        subjectId: this.subjectId == '' ? undefined : Number(this.subjectId),
        yearId: this.yearId == '' ? undefined : String(this.yearId),
      });
      this.isSearchTriggered = true;
      this.showFilterQuestionSets = true;
    },
    isFilteredQuestionSetsEmpty() {
      return this.questionSetsFromFilters.data.length == 0;
    },
    isRecentQuestionSetsEmpty() {
      return this.questionSets.data.length == 0;
    },
    deleteQuestionSetTrigger(questionSet) {
      console.log('hello');
      this.showDeletePopup = true;
      this.questionSetToBeDeleted = questionSet;
    },
    async deleteTest() {
      console.log(this.questionSetToBeDeleted);
      this.showDeletePopup = false;
      await this.deleteXLMTest({ id: this.questionSetToBeDeleted });
      const recentQuestionSetIds = this.questionSets.data.map((questionSet) => questionSet.id);
      if (recentQuestionSetIds.includes(this.questionSetToBeDeleted)) {
        await this.getRecentlyCreatedQuestionSets();
      } else {
        await this.fetchQuestionSetsUsingFilters({
          name: this.questionSetName == '' ? undefined : this.questionSetName,
          blueprintId: this.blueprintId == '' ? undefined : this.blueprintId,
          gradeId: this.standardId == '' ? undefined : Number(this.standardId),
          subjectId: this.subjectId == '' ? undefined : Number(this.subjectId),
          yearId: this.yearId == '' ? undefined : String(this.yearId),
        });
      }
      this.questionSetToBeDeleted = '';
    },
  },
  data() {
    return {
      subjectId: '',
      standardId: '',
      yearId: '',
      blueprintId: '',
      questionSetName: '',
      isSearchTriggered: false,
      showFilterQuestionSets: false,
      showRecentQuestionSets: true,
      showDeletePopup: false,
      questionSetToBeDeleted: '',
    };
  },
};
</script>
<style>
.page-title {
  width: 175px;
  height: 23px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
}
.bread-crumb-row p {
  width: 5px;
  height: 9px;
  padding: 9px 10px;
  color: #979797;
}
.bread-crumb-row a {
  cursor: pointer;
  padding: 10px 10px;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}
.bread-crumb-row {
  display: flex;
  flex-direction: row;
  padding-left: 60px;
}
.page-title {
  margin-top: 55px;
  margin-left: 60px;
}
.label-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding-bottom: 0%;
  margin-bottom: 0%;
}
.text-search-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-top: -20px;
  width: 294px;
}
.select-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 164px;
  background: #fff0f0;
  margin-top: -20px;
}
.label-input-pair {
  margin-right: 0;
  margin-left: 33px;
}
.no-question-sets-message {
  align-items: center;
  margin-top: 100px;
}
.question-sets-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  position: absolute;
  height: 19px;
  top: 320px;
  margin-bottom: 30px;
  margin-top: 10px;
}
</style>
