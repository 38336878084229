import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import { loginRedirect } from '@/utils/auth';
import LpForm from '@/views/LpForm';
import DailyPlanEditPage from '@/views/DailyPlanEditPage';
import MainLayout from '@/components/layouts/MainLayout';
import Project from '@/views/Admin/Project';
import ViewProject from '@/views/Admin/ViewProject';
import { APP_COOKIE_NAME } from '@/constants/common';
import Users from '@/views/Admin/Users';
import BlockMapping from '@/views/BlockMapping';
import BlockMapsStatus from '@/views/BlockMapsStatus';
import SubjectSelction from '@/views/SubjectSelection';
import ModelAnswerGuide from '@/views/ModelAnswerGuide';
import MicroTopics from '@/views/Admin/MicroTopics';
import MicroTopicView from '@/views/Admin/MicroTopicView';
import ContentEntryProjectForm from '@/views/ContentEntryProjectForm';
import AssetUploaderForm from '@/views/AssetUploaderForm';
import LessonPlanTemplate from '@/views/LessonPlanTemplate';
import StandardLPForm from '@/views/StandardLPForm';
import MaintenancePage from '@/views/MaintenancePage';
import UnAuthorised from '@/views/UnAuthorised';
import XSEEDAcademyLayout from '@/views/XSEEDAcademy/layout.vue';
import XSEEDAcademyTrainings from '@/views/XSEEDAcademy/index.vue';
import XSEEDAcademyQuestions from '@/views/XSEEDAcademy/XSEEDAcademyQuestions.vue';
import LearnometerLayout from '@/views/Learnometer/layout.vue';
import Learnometer from '@/views/Learnometer/LandingPage.vue';
import ViewLearnometerProject from '@/views/Learnometer/ViewProject.vue';
import QuestionSetAuthoring from '@/views/Learnometer/QuestionSetAuthoring.vue';
import LearnometerQuestion from '@/views/Learnometer/Question.vue';
import LearnometerQuestionSet from '@/views/Learnometer/QuestionSet.vue';
import ViewQuestionSet from '@/views/Learnometer/ViewQuestionSet.vue';
import CreateQuestionSet from '@/views/Learnometer/CreateQuestionSet.vue';
import ConfidenceLayout from '@/views/Confidence/layout.vue';
import Confidence from '@/views/Confidence/LandingPage.vue';
import QuestionList from '@/views/Confidence/QuestionList.vue';
import QRManagementPage from '../views/QRManagement/QRManagementPage.vue';
import QrManagementLayout from '@/views/QRManagement/layout.vue';

Vue.use(VueRouter);
Vue.use(VueCookies);

// const MAINTENANCE_PATH = 'maintenance';

const routes = [
  {
    path: '/uploadAssets',
    name: 'AssetUploaderForm',
    component: AssetUploaderForm,
  },
  {
    path: '/login',
    name: '',
    component: LpForm,
  },
  {
    path: '/unauthorised',
    name: 'UnAuthorised',
    component: UnAuthorised,
  },
  {
    path: '/',
    name: 'mainLayout',
    component: MainLayout,
    children: [
      {
        path: '/redirect',
        name: 'Redirect',
        beforeEnter(to) {
          window.location = `${process.env.VUE_APP_CAA_LOGIN_WITH_REDIRECT}${to.query.redirectTo}`;
        },
      },
      {
        path: '',
        name: 'Projects',
        component: Project,
      },
      {
        path: '/projects',
        name: 'Projects2',
        component: Project,
      },
      {
        path: 'projects/:projectId',
        name: 'viewProject',
        component: ViewProject,
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
      {
        path: 'micro_topics',
        name: 'MicroTopics',
        component: MicroTopics,
      },
      {
        path: 'micro_topics/:microTopicId',
        name: 'MicroTopicView',
        component: MicroTopicView,
      },
      {
        path: 'select_subject',
        name: 'subjectSelction',
        component: SubjectSelction,
      },
      {
        path: 'model_answer_guide_download',
        name: 'modelAnswerGuide',
        component: ModelAnswerGuide,
      },
      {
        path: 'block_mapping',
        name: 'blockMapping',
        component: BlockMapping,
      },
      {
        path: 'blockMaps_status',
        name: 'blockMapsStatus',
        component: BlockMapsStatus,
      },
      {
        path: 'projects/:projectId/lp_form/:libraryId',
        name: 'lpForm',
        component: LpForm,
      },
      {
        path: 'projects/:projectId/standard_lp_form/:libraryId',
        name: 'StandardLPForm',
        component: StandardLPForm,
      },
      {
        path: 'projects/:projectId/lesson_plan_form/:libraryId',
        name: 'LessonPlanTemplate',
        component: LessonPlanTemplate,
      },
      {
        path: 'lp_form/:projectId/:libraryId/:dailyPlanLibraryId',
        name: 'DailyPlanLessonPlanTemplate',
        component: LessonPlanTemplate,
      },
      {
        path: 'projects/:projectId/dailyPlans/:dailyPlanLibraryId',
        name: 'dailyPlanEditPage',
        component: DailyPlanEditPage,
      },
      {
        path: 'micro_topics/:microTopicId/project/:projectId/edit',
        name: 'ContentEntryProjectForm',
        component: ContentEntryProjectForm,
      },
      {
        path: 'maintenance',
        name: 'Maintenance',
        component: MaintenancePage,
      },
      {
        path: 'xseed_academy',
        component: XSEEDAcademyLayout,
        children: [
          {
            path: '',
            name: 'XSEEDAcademyTrainings',
            component: XSEEDAcademyTrainings,
          },
          {
            path: 'questions/:trainingId/:entity/:pollIndex',
            name: 'XSEEDAcademyQuestions',
            component: XSEEDAcademyQuestions,
          },
        ],
      },
      {
        path: 'learnometer',
        name: 'Learnometer',
        component: LearnometerLayout,
        children: [
          {
            path: '',
            name: 'LearnometerLanding',
            component: Learnometer,
          },
          {
            path: 'viewQuestionSet',
            name: 'ViewQuestionSet',
            component: ViewQuestionSet,
          },
          {
            path: 'createQuestionSet',
            name: 'CreateQuestionSet',
            component: CreateQuestionSet,
          },
          {
            path: ':id',
            name: 'ViewLearnometerProject',
            component: ViewLearnometerProject,
          },
          {
            path: '/learnometer/QuestionSetAuthoring/:testId/:type',
            name: 'QuestionSetAuthoring',
            component: QuestionSetAuthoring,
          },
          {
            path: ':id/learnometer_question/:questionSequenceNumber/:selectedYear',
            name: 'LearnometerQuestion',
            component: LearnometerQuestion,
          },
          {
            path: ':id/learnometer_questionSet/:globalQuestionId/:selectedYear',
            name: 'LearnometerQuestionSet',
            component: LearnometerQuestionSet,
          },
        ],
      },
      {
        path: 'confidence',
        name: 'Confidence',
        component: ConfidenceLayout,
        children: [
          {
            path: '',
            name: 'Confidence Landing page',
            component: Confidence,
          },
          {
            path: 'questionList/:id',
            name: 'QuestionList',
            component: QuestionList,
          },
        ],
      },
      {
        path: 'qr',
        name: 'QRManagement',
        component: QrManagementLayout,
        children: [
          {
            path: '',
            name: 'QRManagementPage',
            component: QRManagementPage,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
/* eslint-disable */
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});

router.beforeEach((to, from, next) => {
  // This Nav Gaurd is responsible for setting the token to cookie if received in the URL query
  // Skip this step if its external redirection (its just login for now)
  if (to.path.includes('redirect')) next();
  else {
    // Check for token in query and set it to cookie for later use
    if (to.query.token) Vue.$cookies.set(APP_COOKIE_NAME, to.query.token);
    next();
  }
});

router.beforeEach((to, from, next) => {
  // This Nav Gaurd is responsible for verifying login and forcing it if the token is not present
  // Skip this step if its external redirection (its just login for now)
  if (to.path.includes('redirect')) {
    next();
  } else {
    // Check for token in cookie, proceed if it exists
    if (Vue.$cookies.get(APP_COOKIE_NAME)) next();
    else {
      // Redirect to login page with `to` as redirect URL.
      next(loginRedirect(to.fullPath));
    }
  }
});

export default router;
