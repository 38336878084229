import gql from 'graphql-tag';

export const airTableSubjectsQuery = gql`
  query {
    airTableSubjects {
      id
      name
      shortCode
    }
  }
`;

export const airTableStandardsQuery = gql`
  query($allStandards: Boolean) {
    airTableStandards(allStandards: $allStandards) {
      id
      name
    }
  }
`;

export const projectsFromAirTableStandardsAndSubjectsQuery = gql`
  query projectsFromAirTableStandardsAndSubjects($standardId: Int, $subjectId: Int) {
    projectsFromAirTableStandardsAndSubjects(standardId: $standardId, subjectId: $subjectId) {
      standardId
      standardName
      subjectId
      subjectName
      workflowStatus
      currentStage
      isProjectExist
      projectId
      assignees
      projectName
    }
  }
`;

export const airTableBigSkillsQuery = gql`
  query {
    airTableBigSkills {
      id
      name
      code
    }
  }
`;

export const airTableSubSkillsQuery = gql`
  query {
    airTableSubSkills {
      id
      name
      code
    }
  }
`;
export const SYNC_AIRTABLE_DATA = gql`
  query {
    syncAirTableData {
      status
    }
  }
`;
export const airTableThemesQuery = gql`
  query {
    airTableThemes {
      id
      name
    }
  }
`;
export const airTableMicrotopicsQuery = gql`
  query {
    airTableMicrotopics {
      id
      name
      understandingMicroskill
      doingMicroskill
    }
  }
`;
export const airTableMicroskillCategoriesQuery = gql`
  query {
    airTableMicroskillCategories {
      id
      name
      shortCode
    }
  }
`;

export const recentlyCreatedQuestionSetsQuery = gql`
  query {
    getRecentlyCreatedTests {
      id
      testName
      blueprintName
    }
  }
`;

export const testFromFiltersQuery = gql`
  query getTestsFromFilters($filters: TestFilters!) {
    getTestsFromFilters(filters: $filters) {
      id
      testName
      blueprintName
    }
  }
`;
export const airTableBlueprintsQuery = gql`
  query {
    airtableBlueprints {
      id
      name
    }
  }
`;
export const initiateTestCreation = gql`
  mutation createXLMTest(
    $id: ID
    $testName: String
    $testDescription: String
    $standardId: Int!
    $isRandomisedClone: Boolean!
    $questionSetId: ID!
    $questionList: [JSON]
  ) {
    createXLMTest(
      id: $id
      testName: $testName
      testDescription: $testDescription
      standardId: $standardId
      questionSetId: $questionSetId
      isRandomisedClone: $isRandomisedClone
      questionList: $questionList
    ) {
      data {
        id
      }
      status
      message
    }
  }
`;
export const finalTestCreation = gql`
  mutation publishXLMTest(
    $testName: String!
    $testDescription: String!
    $isRandomisedClone: Boolean!
    $id: ID
    $questionSetId: ID!
    $questionList: [JSON]!
    $publishTest: Boolean!
  ) {
    publishXLMTest(
      id: $id
      testName: $testName
      testDescription: $testDescription
      questionSetId: $questionSetId
      isRandomisedClone: $isRandomisedClone
      questionList: $questionList
      publishTest: $publishTest
    ) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const editTest = gql`
  mutation editXLMTest($id: ID) {
    editXLMTest(id: $id) {
      status
    }
  }
`;

export const deleteTest = gql`
  mutation deleteXLMTest($id: ID) {
    deleteXLMTest(id: $id) {
      status
    }
  }
`;

export const fetchAllTestQuestionsQuery = gql`
  query fetchAllTestQuestions($testId: ID!) {
    fetchAllTestQuestions(testId: $testId)
  }
`;

export const fetchYearsQuery = gql`
  query {
    getTestYears {
      id
    }
  }
`;

export const fetchProjectListQuery = gql`
  query fetchProjectListWithStandardIdAndSubjectId($Questions: JSON!) {
    fetchProjectListWithStandardIdAndSubjectId(Questions: $Questions)
  }
`;
