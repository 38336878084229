var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SubSection',{staticClass:"pad-Bs",attrs:{"title":"Answer Criteria"}}),_c('div',{staticClass:"criteria-wrapper"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"criteria-header"},[_vm._v("Criteria")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"criteria-header"},[_vm._v("%")])])],1),_vm._l((_vm.answerCriteriaState),function(criteria,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"placeholder":"Criteria title","type":"text","outlined":"","dense":"","rules":[function (v) { return !!v || 'Required'; }],"hide-details":""},model:{value:(criteria.title),callback:function ($$v) {_vm.$set(criteria, "title", $$v)},expression:"criteria.title"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"placeholder":"%","type":"number","outlined":"","dense":"","hide-details":"","max":_vm.totalPercentage,"rules":[
            function (v) { return !!v || 'This is required'; },
            function (v) { return v >= 1 || 'Minimum is 1'; },
            function (v) { return v <= 100 || 'Maximum is 100'; },
            _vm.isValidTotalPercentage
          ],"min":"0","suffix":"%"},on:{"keydown":function (event) {
              if (event.key === '.' || event.key === '-') {
                event.preventDefault();
              }
            }},model:{value:(criteria.percentage),callback:function ($$v) {_vm.$set(criteria, "percentage", $$v)},expression:"criteria.percentage"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"action-buttons-wrapper"},[_c('v-btn',{on:{"click":function($event){return _vm.addRow(index)}}},[_vm._v("+")]),(_vm.canRemoveItem)?_c('v-btn',{on:{"click":function($event){return _vm.removeRow(index)}}},[_vm._v("x")]):_vm._e()],1)])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"criteria-header"},[_c('b',[_vm._v("Total")])])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"criteria-header"},[_c('b',[_vm._v(_vm._s(_vm.sumOfPercentage)+" %")])])])],1),(!_vm.isValidTotalPercentage)?_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',[_c('span',{staticClass:"percentage-total-warning"},[_vm._v("Total percentage should add up to "),_c('b',[_vm._v("100")])])])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }