<template>
  <div>
    <v-dialog
      v-if="showblockFilter"
      :value="showblockFilter"
      persistent
      scrollable
      color="white"
      :width="500"
    >
      <div class="flex-column">
        <div class="selection-card flex-column">
          <v-card style="padding: 10px;">
            <div class="header-div flex-row">
              <div
                style="background-color: #f6f6f6; padding: 10px; height: 56px; text-align: right;"
              >
                <v-btn color="primary" text @click="closeBlockFilterDialog"
                  >Close<v-icon>mdi-close</v-icon></v-btn
                >
              </div>
            </div>

            <v-form ref="form1" style="padding: 10px;">
              <v-label>{{ AppTexts.SELECT_PRODUCT }}</v-label>
              <v-select
                v-model="filterData.productId"
                :items="filterOptions.products"
                :rules="[(v) => !!v || 'Product is required']"
                required
                item-text="name"
                item-value="id"
                class="select-grade-dropdown"
              />
              <v-label>{{ AppTexts.SELECT_BOARD }}</v-label>
              <v-select
                v-model="filterData.boardId"
                :items="filterOptions.boards"
                :rules="[(v) => !!v || 'Board is required']"
                required
                item-text="name"
                item-value="id"
                class="select-board-dropdown"
              />
              <v-label>{{ AppTexts.SELECT_SUBJECT }}</v-label>
              <v-select
                v-model="filterData.subjectId"
                :items="filterOptions.subjects"
                :rules="[(v) => !!v || 'Subject is required']"
                required
                item-text="name"
                item-value="id"
                class="select-subject-dropdown"
              />
              <v-label>{{ AppTexts.SELECT_STANDARD }}</v-label>
              <v-select
                v-model="filterData.standardId"
                :items="filterOptions.standards"
                :rules="[(v) => !!v || 'Grade is required']"
                required
                item-text="name"
                item-value="id"
                class="select-grade-dropdown"
              />
              <v-layout row justify-center></v-layout>
            </v-form>
            <div class="flex-row bottom-buttons">
              <v-btn color="primary mr-2" @click="getBlockDetaildAndCloseDialog">Submit</v-btn>
              <v-btn text @click="closeBlockFilterDialog">Cancel</v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-if="blockDialog"
      :value="blockDialog"
      persistent
      scrollable
      color="white"
      :width="500"
    >
      <div class="flex-column">
        <div class="selection-card flex-column">
          <v-card style="padding: 10px;">
            <div class="header-div flex-row">
              <div
                style="background-color: #f6f6f6; padding: 10px; height: 56px; text-align: right;"
              >
                <v-btn color="primary" text @click="closeBlockDialog"
                  >Close<v-icon>mdi-close</v-icon></v-btn
                >
              </div>
            </div>

            <v-form ref="blockForm" style="padding: 10px;">
              <v-label>{{ AppTexts.SELECT_BLOCK }}</v-label>
              <v-autocomplete
                v-model="filterData.blockId"
                :items="blockList"
                :rules="[(v) => !!v || 'Product is required']"
                required
                item-text="label"
                item-value="id"
                class="select-grade-dropdown"
              />
              <v-layout row justify-center></v-layout>
            </v-form>
            <div class="flex-row bottom-buttons">
              <v-btn color="primary mr-2" @click="getLessonDetaildAndCloseDialog">Submit</v-btn>
              <v-btn text @click="closeBlockDialog">Cancel</v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </v-dialog>

    <AddQuestionToReuse
      :onSubmit="(data) => getSelectedQuestionList(data)"
      :currentProjectQuestionLinkableLessonPlans="reuseQustionLessonPlans"
      :lpType="lpType"
      :blockId="filterData.blockId.toString()"
      :alreadyAddedQuestionOrQuestionGroupIdsUsingReuse="
        alreadyAddedQuestionOrQuestionGroupIdsUsingReuse
      "
    />
    <v-dialog v-model="mapLessonPlanDialog" fullscreen transition="dialog-bottom-transition">
      <v-card style="padding: 70px 20px 10px 10px !important;">
        <BlockTitle title="Map Questions to Lesson" />
        <div class="bottom-save-close-bar" style="z-index: 2;">
          <div class="close-save-button-wrapper" style="padding-right: 20px;">
            <div class="flex-row bottom-buttons">
              <v-btn color="primary mr-2" @click="saveReusedQuestion">Submit</v-btn>
              <v-btn text @click="mapLessonPlanDialog = false">Cancel</v-btn>
            </div>
          </div>
        </div>
        <v-form ref="form3">
          <div v-for="(key, index) of Object.keys(groupedSelectedQuestion)" :key="index">
            <div
              v-if="Array.isArray(groupedSelectedQuestion[key])"
              class="list-view-question-wrapper"
            >
              <v-row>
                <v-col cols="8">
                  <SingleGroupQuestionView
                    :index="index + 1"
                    :questionMetaInfo="questionMetaInfo"
                    :lessonPlans="sourceBlockLessons"
                    :data="groupedSelectedQuestion[key]"
                    :isReuseQuestion="true"
                  />
                </v-col>
                <v-col cols="4">
                  <div>
                    <v-select
                      v-on:change="(val) => updateLessonPlanForSelectedQuestion(key, val)"
                      :items="sourceBlockLessonsForMultiSelectInput"
                      item-text="label"
                      item-value="value"
                      placeholder="lessonPlan"
                      background-color="white"
                      :rules="[(v) => !!v || 'LessonPlan is required']"
                      hide-details
                      solo
                      flat
                      dense
                      outlined
                      required
                      :value="groupedSelectedQuestion[key][0].lessonPlanLibraryId"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else class="list-view-question-wrapper">
              <v-row>
                <v-col cols="8">
                  <SingleQuestionView
                    :index="index + 1"
                    :questionMetaInfo="questionMetaInfo"
                    :lessonPlans="sourceBlockLessons"
                    :data="groupedSelectedQuestion[key]"
                    :isReuseQuestion="true"
                  />
                </v-col>
                <v-col cols="4">
                  <div>
                    <v-select
                      v-on:change="(val) => updateLessonPlanForSelectedQuestion(key, val)"
                      :items="sourceBlockLessonsForMultiSelectInput"
                      item-text="label"
                      item-value="value"
                      placeholder="lessonPlan"
                      background-color="white"
                      :rules="[(v) => !!v || 'LessonPlan is required']"
                      required
                      hide-details
                      solo
                      flat
                      dense
                      outlined
                      :value="groupedSelectedQuestion[key].lessonPlanLibraryId"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AppTexts from '@/constants/AppTexts';
import AddQuestionToReuse from '@/components/Questions/AddQuestionToReuse.vue';
import SingleQuestionView from '@/components/Questions/SingleQuestionView';
import SingleGroupQuestionView from '@/components/Questions/SingleGroupQuestionView';
import { cloneDeep, flatten } from 'lodash';
import Vue from 'vue';
import BlockTitle from '@/components/inputs/BlockTitle.vue';

export default {
  name: 'BlockFilterDialog',
  components: {
    AddQuestionToReuse,
    SingleQuestionView,
    SingleGroupQuestionView,
    BlockTitle,
  },
  data() {
    return {
      AppTexts,
      filterData: {
        productId: 0,
        boardId: 0,
        standardId: 0,
        blockId: 0,
      },
      blocks: [],
      blockDialog: false,
      mapLessonPlanDialog: false,
      selectedQuestion: [],
    };
  },
  computed: {
    ...mapState({
      reuseQuestionBlocks: (state) => state.questions.reuseQuestionBlocks,
      reuseQustionLessonPlans: (state) => state.questions.reuseQuestionLessonPlans,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
      questionData: (state) => state.lessonPlan.data,
      currentProjectBlock: (state) => state.currentProject.block,
    }),
    blockList() {
      return this.reuseQuestionBlocks
        .filter((_b) => this.currentProjectBlock.id != _b.id)
        .map((_block) => {
          return { id: _block.id, label: `${_block.bId} - ${_block.title}` };
        });
    },
    lpType() {
      return this.$route.query.lpType;
    },
    sourceBlockLessonsForMultiSelectInput() {
      return (this.sourceBlockLessons || []).map((e) => {
        return { label: e.name, value: parseInt(e.library_id) };
      });
    },
    groupedSelectedQuestion() {
      const groupedQuestions = {};

      for (const datum of this.selectedQuestion) {
        if (!datum.questionGroupId) {
          groupedQuestions[datum._id] = cloneDeep(datum);
        } else {
          if (!groupedQuestions[datum.questionGroupId]) {
            groupedQuestions[datum.questionGroupId] = [];
          }
          groupedQuestions[datum.questionGroupId].push(cloneDeep(datum));
        }
      }

      return groupedQuestions;
    },
    alreadyAddedQuestionOrQuestionGroupIdsUsingReuse() {
      return this.questionData
        .filter((_question) => _question.isReusedQuestion == true)
        .map((_question) => _question.questionGroupId || _question._id);
    },
  },
  watch: {
    reuseQuestionBlocks: {
      immediate: false,
      deep: true,
      handler(val) {
        console.log(val);
        this.blockDialog = true;
      },
    },
  },
  methods: {
    ...mapActions([
      'getAllBlocksForSelectedProductBoardStandardSubject',
      'addQuestionsSelectedForReuseToQuestionDataInStore',
      'questionForceSaveWithData',
    ]),
    ...mapMutations([
      'SHOW_SOURCE_BLOCK_FILTER_DIALOG',
      'SET_REUSE_QUESTION_LESSON_PLANS',
      'GET_ALL_BLOCKS_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT',
      'SET_ADD_QUESTION_DIALOG',
    ]),
    closeBlockFilterDialog() {
      this.SHOW_SOURCE_BLOCK_FILTER_DIALOG(false);
    },
    closeBlockDialog() {
      this.blockDialog = false;
    },
    getBlockDetaildAndCloseDialog() {
      if (this.$refs.form1.validate()) {
        let board = this.filterOptions.boards.find(
          (board) => board.id.toString() == this.filterData.boardId.toString()
        );
        if (board) {
          this.getAllBlocksForSelectedProductBoardStandardSubject({
            productId: this.filterData.productId,
            boardName: board.name,
            subjectId: this.filterData.subjectId,
            standardId: this.filterData.standardId,
          });
        }
        this.closeBlockFilterDialog();
      }
    },
    getLessonDetaildAndCloseDialog() {
      if (this.$refs.blockForm.validate()) {
        if (this.filterData.blockId.toString().length > 0) {
          let lessonPlans = this.reuseQuestionBlocks.find(
            (block) => block.id == this.filterData.blockId
          ).lessonPlans;

          this.SET_REUSE_QUESTION_LESSON_PLANS(lessonPlans);
          // CLEAR BLOCK LIST AFTER GETTING THE LESSONPLAN
          this.GET_ALL_BLOCKS_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT([]);
          this.closeBlockDialog();
          this.SET_ADD_QUESTION_DIALOG(true);
        }
      }
    },
    getSelectedQuestionList(questions) {
      this.closeBlockDialog();
      const questionsCopy = JSON.parse(JSON.stringify(questions));
      for (const iterator of questionsCopy) {
        iterator.lessonPlanLibraryId = null;
        iterator.isReusedQuestion = true;
      }
      this.selectedQuestion = questionsCopy;
      if (this.selectedQuestion.length > 0) {
        this.mapLessonPlanDialog = true;
      } else {
        Vue.notify({
          title: 'Failed!',
          text: 'Please select atleast 1 question to be reused!',
          type: 'warn',
        });
      }
    },
    updateLessonPlanForSelectedQuestion(key, lessonPlanId) {
      if (Array.isArray(this.groupedSelectedQuestion[key])) {
        for (const iterator of this.groupedSelectedQuestion[key]) {
          iterator.lessonPlanLibraryId = lessonPlanId;
        }
      } else {
        this.groupedSelectedQuestion[key].lessonPlanLibraryId = lessonPlanId;
      }

      this.selectedQuestion = flatten(Object.values(this.groupedSelectedQuestion));
    },
    saveReusedQuestion() {
      this.closeBlockDialog();
      if (this.$refs.form3.validate()) {
        this.mapLessonPlanDialog = false;

        const selectedQuestions = this.selectedQuestion;

        for (const selectedQuestion of selectedQuestions) {
          selectedQuestion['sourceDetails'] = {
            productId: this.filterData.productId,
            boardId: this.filterData.boardId,
            standardId: this.filterData.standardId,
            subjectId: this.filterData.subjectId,
            blockId: this.filterData.blockId,
          };
        }

        this.addQuestionsSelectedForReuseToQuestionDataInStore({
          questions: selectedQuestions,
        });

        this.questionForceSaveWithData({
          validateForm: false,
          showLoader: true,
          callback: this.saveForm,
        });
      }
    },
  },
  props: {
    filterOptions: {
      type: Object,
      required: true,
    },
    showblockFilter: {
      type: Boolean,
      required: true,
    },
    sourceBlockLessons: {
      type: Array,
      required: false,
    },
    saveForm: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
.header-div {
  justify-content: flex-end;
}
.bottom-buttons {
  justify-content: flex-end;
}
</style>
