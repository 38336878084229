<template>
  <v-form ref="learningSectionForm" class="section-title-cnr">
    <Slide :slide="'Slide 10'" />
    <div>
      <p class="p-text">{{ AppTexts.SELECT_A_OPTION_BELOW }}</p>
      <v-radio-group v-model="data.contentOption">
        <v-radio :key="1" value="General" label="General" />
        <v-radio :key="2" value="KW chart" label="KW Chart" />
      </v-radio-group>
      <SubSection :title="AppTexts.TEACHER_ZONE" />
      <TextInput
        :placeholder="AppTexts.ADD_TEACHER_TIP"
        :enableTextFormatting="true"
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        fieldIdentifier="whatDidYouLearn.teacherZone"
        :max="100"
        class="mgn-Bm"
        v-model="data.teacherZone"
      />
    </div>
  </v-form>
</template>
<script>
import Slide from '@/components/contentEntry/Slide';
import AppTexts from '@/constants/AppTexts';
import TextInput from '@/components/inputs/TextInput';
import SubSection from '@/components/inputs/SubSection';

export default {
  name: 'LearningSection',
  data() {
    return { AppTexts };
  },
  components: {
    Slide,
    TextInput,
    SubSection,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.p-text {
  color: #bb161b;
  font-size: 16px;
  font-weight: 400;
}
</style>
