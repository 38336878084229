<template>
  <div class="text-center">
    <v-dialog persistent v-model="openDialog" width="1000">
      <v-card :height="!readonly ? '520px' : '420px'" style="overflow: scroll;">
        <Padder :horizontal="10" :vertical="10">
          <!-- NOTE: THIS WILL CAUSE CYCLIC REFERENCE AND THIS IS NEEDED AS ALL FEATURES OF RTE 
          IS NEEDED IN FULL SCREEN MODE TOO-->
          <RichTextEditor
            :readonly="readonly"
            :canShowDiffIcon="false"
            v-model="rteData"
            :fieldIdentifier="`${fieldIdentifier}`"
            :counter="counter"
            :wordCounter="wordCounter"
            :allowWordCount="allowWordCount"
            :allowCharacterCount="allowCharacterCount"
            :inFullScreenMode="true"
            :excludeOnlyToolBarActions="excludeOnlyToolBarActions"
            :includeOnlyToolBarActions="includeOnlyToolBarActions"
            :isAssetUploadRequired="isAssetUploadRequired"
            :showValidationError="showValidationError"
          />
        </Padder>

        <v-divider></v-divider>

        <v-card-actions v-if="!readonly">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updateDataInOriginalRTE">
            Update
          </v-btn>
          <v-btn color="primary" text @click="openDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="openDialog = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: `FullScreenRichTextEditor`,
  data() {
    return {
      rteData: '',
    };
  },

  computed: {
    openDialog: {
      get() {
        this.updateRteData();
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    updateDataInOriginalRTE() {
      this.$emit('dataFromFullScreenView', this.rteData);
      this.openDialog = false;
    },
    updateRteData() {
      this.rteData = this.originalData;
    },
  },
  beforeCreate() {
    this.$options.components.RichTextEditor = require('../RichTextEditor').default;
  },
  props: {
    fieldIdentifier: {
      type: String,
      required: false,
    },
    counter: {
      type: Number,
      default: 0,
    },
    wordCounter: {
      type: Number,
      default: 0,
    },
    allowWordCount: {
      type: Boolean,
      default: false,
    },
    allowCharacterCount: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    originalData: {
      type: String,
      required: true,
    },
    includeOnlyToolBarActions: {
      type: Array,
      required: true,
    },
    excludeOnlyToolBarActions: {
      type: Array,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    showValidationError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
