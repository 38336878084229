import Vue from 'vue';
import Vuex from 'vuex';

import { state } from '@/store/state';
import { actions } from '@/store/actions';
import { mutations } from '@/store/mutations';
import { modules } from '@/store/modules';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules
});
