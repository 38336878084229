<template>
  <v-form ref="applicationSectionForm" class="section-title-cnr">
    <!-- CLASSWORK BLOCK -->
    <BlockTitleWithSubtitle
      :subTitle="AppTexts.CLASSWORK_AND_WORKSHEET"
      v-if="canShowClassworkWorksheet()"
    />
    <v-row class="mgn-Ts mgn-Lxs" v-if="canShowClassworkWorksheet()">
      <v-col md="auto">{{ classworkParagraphTextPrefix() }}</v-col>
      <v-col class="small-text-field" cols="2">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getClasswordIsChangedValue('description')"
          fieldIdentifier="application.classwork.description"
          :placeholder="AppTexts.WORKSHEET_NUMBER"
          v-model="lessonPlanData.application.classwork.description"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :readonly="!isNotReadOnly"
        />
      </v-col>
      <v-col>{{ classworkParagraphTextSuffix() }}</v-col>
    </v-row>
    <v-row class="mgn-Lxs" v-if="canShowClassworkWorksheet()">
      <v-col>{{ AppTexts.APPLICATION_CLASSWORK_STATIC_TEXT }}</v-col>
    </v-row>

    <v-row v-if="canShowClassworkWorksheet()">
      <v-col cols="6" class="pad-Rm" style="align-items: baseline;">
        <FileInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getClasswordIsChangedValue(imageKey)"
          :diffIdentifier="'application.classwork.' + imageKey"
          :image_url="isAssetUploadRequired ? lessonPlanData.application.classwork.image_url : ''"
          :image_url_brief="lessonPlanData.application.classwork.brief_url"
          :previewUrl="isAssetUploadRequired ? applicationSignedUrls.classwork.signedUrl : ''"
          :previewUrlBrief="applicationSignedUrls.classwork.signedBriefUrl"
          :label="AppTexts.SELECT_CLASSWORK_FILE"
          :loadingStatus="uploadingStatus.classwork == FILE_UPLOADING_STATUS.IN_PROGRESS"
          :fileChangeHandler="importApplicationClassworkImage"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :canAppproveAsset="canApproveAsset"
          :canFinalizeAsset="true"
          :isAssetApproved="lessonPlanData.application.classwork.image_url_approved"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              copyApplicationClassworkImage(previewUrl, briefUrl, isAssetApproved)
          "
        />
      </v-col>
      <v-col class="pad-Lm" cols="6">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getClasswordIsChangedValue('brief')"
          fieldIdentifier="application.classwork.brief"
          v-if="showImageBrief"
          :placeholder="AppTexts.CLASSWORK_IMAGE_BRIEF"
          class="text-field-text"
          :showValidationError="true"
          v-model="lessonPlanData.application.classwork.brief"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>
    <XSDisplayCard :title="AppTexts.DIFFERENTIATION_TIPS" v-if="canShowClassworkWorksheet()">
      {{ applicationBockStaticTexts.application.differentiation_tips.paragraph_text }}
      <p class="mt-5 mb-3 sub-head">{{ AppTexts.THOSE_WHO_NEED_MORE_HELP }}</p>
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDifferentiationTipsChanged('need_more_help')"
        fieldIdentifier="application.differentiation_tips.need_more_help.description"
        :placeholder="AppTexts.DESCRIPTION"
        :showValidationError="true"
        v-model="lessonPlanData.application.differentiation_tips.need_more_help.description"
        :readonly="!isNotReadOnly"
        :counter="160"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
      <p class="mt-5 mb-3 sub-head">{{ AppTexts.THOSE_WHO_NEED_EXTRA_CHALLENGE }}</p>
      <RichTextEditor
        :canShowDiffIcon="canShowDiffIcon"
        :isDiffChanged="isDifferentiationTipsChanged('need_extra_challenge')"
        fieldIdentifier="application.differentiation_tips.need_extra_challenge.description"
        :placeholder="AppTexts.DESCRIPTION"
        :showValidationError="true"
        v-model="lessonPlanData.application.differentiation_tips.need_extra_challenge.description"
        :readonly="!isNotReadOnly"
        :counter="160"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
    </XSDisplayCard>

    <!-- MORE_PRACTICE BLOCK -->
    <div v-if="lpType === LP_TYPES.standard">
      <v-row>
        <v-col>
          <BlockTitleWithSubtitle
            :subTitle="AppTexts.CLASSWORK_AND_MORE_PRACTICE"
            :time="lessonPlanData.application.more_practice_time"
          />
        </v-col>
      </v-row>
      {{ applicationBockStaticTexts.application.more_practice.paragraph_text }}
      <Step
        v-if="lessonPlanData.application.more_practice"
        :items="lessonPlanData.application.more_practice"
        :canShowStepNumber="true"
        :canShowAddNewStepButton="true"
        :addNewStepHandler="addPractice"
        :allowDeleteStep="true"
        :deleteStepHandler="deletePractice"
        :descriptionPlaceholder="AppTexts.DESCRIPTION"
        :maxNoOfSteps="4"
        :supportAssets="true"
        :showImageBrief="showImageBrief"
        :isAssetUploadRequired="isAssetUploadRequired"
        :getPreviewUrl="getMorePracticeInstructionAssetPreviewUrl"
        stepAssetIdentifier="application.more_practice"
        :canShowDiffIcon="canShowDiffIcon"
        stepDiffIdentifier="application.more_practice[<stepIndex>].description"
        :isStepDescriptionChanged="isMorePracticeStepDescriptionChanged"
        assetBriefDiffIdentifier="application.more_practice[<stepIndex>].assets[<assetIndex>].brief"
        :isAssetBriefChanged="isMorePracticeAssetBriefChanged"
        assetImageDiffIdentifierPrefix="application.more_practice[<stepIndex>].assets[<assetIndex>]"
        :isAssetImageChanged="isMorePracticeAssetImageChanged"
        :isNotReadOnly="isNotReadOnly"
        :fileValidators="fileValidators"
        :canFinalizeAsset="true"
        :canAppproveAsset="canApproveAsset"
      />
    </div>
    <v-divider></v-divider>
    <!-- HOMEWORK BLOCK -->
    <BlockTitle :title="AppTexts.HOMEWORK" />
    <v-row class="mgn-Lxs">
      <v-col md="auto">{{ homeworkParagraphTextPrefix() }}</v-col>
      <v-col class="small-text-field" cols="3">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getHomewordIsChangedValue('description')"
          fieldIdentifier="application.homework.description"
          :placeholder="AppTexts.QUESTION_NUMBER"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          v-model="lessonPlanData.application.homework.description"
          :readonly="!isNotReadOnly"
          :counter="220"
        />
      </v-col>
      <v-col>{{ homeworkParagraphTextSuffix() }}</v-col>
    </v-row>
    <v-row class="mgn-Ts mgn-Lxs">
      <v-col>
        <p>{{ applicationBockStaticTexts.application.homework.paragraph_text2 }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="pad-Rm">
        <FileInput
          :image_url="
            isAssetUploadRequired ? lessonPlanData.application.homework.question_image_url : ''
          "
          :image_url_brief="lessonPlanData.application.homework.question_image_brief_url"
          :previewUrl="
            isAssetUploadRequired ? applicationSignedUrls.homework.signedQuestionUrl : ''
          "
          :previewUrlBrief="applicationSignedUrls.homework.signedQuestionBriefUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getHomewordIsChangedValue(questionImageKey)"
          :diffIdentifier="'application.homework.' + questionImageKey"
          :label="AppTexts.SELECT_HOMEWORK_QUESTION_FILE"
          :loadingStatus="uploadingStatus.homework == FILE_UPLOADING_STATUS.IN_PROGRESS"
          :fileChangeHandler="importApplicationHomeworkQuestionImage"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :isAssetApproved="lessonPlanData.application.homework.question_image_url_approved"
          :canAppproveAsset="canApproveAsset"
          :canFinalizeAsset="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              copyApplicationHomeworkQuestionImage(previewUrl, briefUrl, isAssetApproved)
          "
        />
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getHomewordIsChangedValue('question_image_brief')"
          fieldIdentifier="application.homework.question_image_brief"
          v-if="showImageBrief"
          :placeholder="AppTexts.HOMEWORK_QUESTION_FILE_BRIEF"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          :showValidationError="true"
          v-model="lessonPlanData.application.homework.question_image_brief"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col cols="6" class="pad-Lm">
        <FileInput
          :image_url="
            isAssetUploadRequired ? lessonPlanData.application.homework.answer_image_url : ''
          "
          :image_url_brief="lessonPlanData.application.homework.answer_image_brief_url"
          :previewUrl="isAssetUploadRequired ? applicationSignedUrls.homework.signedAnswerUrl : ''"
          :previewUrlBrief="applicationSignedUrls.homework.signedAnswerBriefUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getHomewordIsChangedValue(answerImageKey)"
          :diffIdentifier="'application.homework.' + answerImageKey"
          :label="AppTexts.SELECT_HOMEWORK_ANSWER_FILE"
          :loadingStatus="uploadingStatus.homework_answer == FILE_UPLOADING_STATUS.IN_PROGRESS"
          :fileChangeHandler="importApplicationHomeworkAnswerImage"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :isRequired="false"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :isAssetApproved="lessonPlanData.application.homework.answer_image_url_approved"
          :canAppproveAsset="canApproveAsset"
          :canFinalizeAsset="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              copyApplicationHomeworkAnswerImage(previewUrl, briefUrl, isAssetApproved)
          "
        />
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getHomewordIsChangedValue('answer_image_brief')"
          fieldIdentifier="application.homework.answer_image_brief"
          v-if="showImageBrief"
          :placeholder="AppTexts.HOMEWORK_ANSWER_FILE_BRIEF"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          v-model="lessonPlanData.application.homework.answer_image_brief"
          :showValidationError="true"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>

    <div class="mgn-Bs">{{ applicationBockStaticTexts.application.homework.greeting_text }}</div>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BlockTitle from '@/components/inputs/BlockTitle';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import Step from '@/components/inputs/Step';
import FileInput from '@/components/inputs/FileInput';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Errors from '@/constants/Errors';
import { APPLICATION_BLOCK_KEY_OF_STATE_LP_DATA, LP_TYPES } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import AssetTypes from '@/constants/AssetTypes';
import TextInput from '@/components/inputs/TextInput';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import { LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE } from '@/constants/LpForm';

export default {
  name: 'ApplicationSection',
  components: {
    BlockTitle,
    BlockTitleWithSubtitle,
    FileInput,
    Step,
    XSDisplayCard,
    TextInput,
    RichTextEditor,
  },
  data() {
    return {
      AppTexts,
      AssetTypes,
      Errors,
      APPLICATION_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      LP_TYPES,
      imageKey: this.isAssetUploadRequired ? 'image_url' : 'brief_url',
      questionImageKey: this.isAssetUploadRequired
        ? 'question_image_url'
        : 'question_image_brief_url',
      answerImageKey: this.isAssetUploadRequired ? 'answer_image_url' : 'answer_image_brief_url',
      BASIC_TOOLS,
    };
  },
  methods: {
    ...mapActions([
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'updateApplicationClassworkDescription',
      'copySingleAssetAction',
    ]),
    importApplicationHomeworkQuestionImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.application.homework.question_image_url
            : this.lessonPlanData.application.homework.question_image_brief_url,
          assetType: AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE,
        });
      }
    },
    copyApplicationHomeworkQuestionImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE,
        isAssetApproved
      });
    },
    importApplicationHomeworkAnswerImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.application.homework.answer_image_url
            : this.lessonPlanData.application.homework.answer_image_brief_url,
          assetType: AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE,
        });
      }
    },
    copyApplicationHomeworkAnswerImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE,
        isAssetApproved
      });
    },
    importApplicationClassworkImage(file) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        });
      } else {
        this.deleteSingleAssetAction({
          url: this.isAssetUploadRequired
            ? this.lessonPlanData.application.classwork.image_url
            : this.lessonPlanData.application.classwork.brief_url,
          assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        });
      }
    },
    copyApplicationClassworkImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
        isAssetApproved
      });
    },
    getMorePracticeInstructionAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        signedBriefUrl: '',
        signedUrl: '',
      };

      if (this.applicationSignedUrls) {
        let instruction = this.applicationSignedUrls.more_practice[stepIndex];
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.signedUrl = asset.signedUrl;
            }
            previewUrl.signedBriefUrl = asset.signedBriefUrl;
          }
        }
      }

      return previewUrl;
    },
    getFileNameFromUrl,
    updateDescriptionToState(description) {
      this.updateApplicationClassworkDescription({ description });
    },
    classworkParagraphTextPrefix() {
      return this.applicationBockStaticTexts.application.classwork.paragraph_text.split(
        '<<worksheet_number>>'
      )[0];
    },
    classworkParagraphTextSuffix() {
      let array = this.applicationBockStaticTexts.application.classwork.paragraph_text.split(
        '<<worksheet_number>>'
      );
      return array[1] + ` ${this.lessonPlanData.application.classwork.description} ` + array[2];
    },
    homeworkParagraphText() {
      return this.applicationBockStaticTexts.application.homework.paragraph_text1.replace(
        /<<practice_questions>>/g,
        this.lessonPlanData.application.homework.description
      );
    },
    homeworkParagraphTextPrefix() {
      return this.applicationBockStaticTexts.application.homework.paragraph_text1.split(
        '<<practice_questions>>'
      )[0];
    },
    homeworkParagraphTextSuffix() {
      return this.applicationBockStaticTexts.application.homework.paragraph_text1.split(
        '<<practice_questions>>'
      )[1];
    },
    isMorePracticeStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.applicationDiff.more_practice[stepIndex].isChanged == true ||
            this.applicationDiff.more_practice[stepIndex].description.isChanged == true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isMorePracticeAssetBriefChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.applicationDiff.more_practice[stepIndex].isChanged == true ||
            this.applicationDiff.more_practice[stepIndex].assets[assetIndex].isChanged == true ||
            this.applicationDiff.more_practice[stepIndex].assets[assetIndex].brief.isChanged == true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isMorePracticeAssetImageChanged(stepIndex, assetIndex) {
      try {
        if (this.canShowDiffIcon) {
          if (this.isAssetUploadRequired) {
            return (
              this.applicationDiff.more_practice[stepIndex].isChanged == true ||
              this.applicationDiff.more_practice[stepIndex].assets[assetIndex].isChanged == true ||
              this.applicationDiff.more_practice[stepIndex].assets[assetIndex].url.isChanged == true
            );
          } else {
            return (
              this.applicationDiff.more_practice[stepIndex].isChanged == true ||
              this.applicationDiff.more_practice[stepIndex].assets[assetIndex].isChanged == true ||
              this.actionDiff.instructions[stepIndex].assets[assetIndex].brief_url.isChanged == true
            );
          }
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDifferentiationTipsChanged(blockName) {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.differentiation_tips[blockName].description.isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getClasswordIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.classwork[type].isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getHomewordIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.homework[type].isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isTeacherTipChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff.teacher_tip.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    canShowClassworkWorksheet() {
      return !(
        this.lessonPlanFormRendererType == LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE &&
        this.isWorkSheetEnabled == false
      );
    },
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    deletePractice: {
      type: Function,
      required: true,
    },
    addPractice: {
      type: Function,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    applicationSignedUrls: {
      type: Object,
      required: true,
    },
    applicationDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    lessonPlanFormRendererType: {
      type: String,
      required: false,
    },
    isWorkSheetEnabled: {
      type: Boolean,
      required: false,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    canApproveAsset: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      applicationBockStaticTexts: (state) => state.lessonPlan.staticTexts,
      lpType: (state) => state.lessonPlan.lpType,
    }),
  },
};
</script>
<style scoped>
.small-text-field {
  zoom: 0.8;
  height: 40px;
  transform: translateY(-10px);
}
.sub-head {
  font-weight: 500;
}
</style>
