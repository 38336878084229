<template>
  <div>
    <div>
      <RichTextEditor
        :readonly="isNotReadOnly"
        :canShowDiffIcon="canShowDiffIcon"
        :fieldIdentifier="`${diffIdentifierPrefix}.primary_description`"
        :placeholder="primaryDescriptionPlaceholder"
        :showValidationError="!isPrimaryDescriptionOptional"
        :returnEmptyStringInsteadOfDefaultJson="true"
        :isDiffChanged="(diffChanges.primary_description || {}).isChanged || false"
        v-model="data.informationAndAssessment.primary_description"
        :includeOnlyToolBarActions="BASIC_TOOLS"
      />
    </div>

    <v-row>
      <v-col cols="6" class="pad-Rm">
        <FileInput
          :image_url="isAssetUploadRequired ? data.informationAndAssessment.question.assetId : ''"
          :image_url_brief="data.informationAndAssessment.question.brief.assetId"
          :previewUrl="isAssetUploadRequired ? signedUrls.question.assetSignedUrl : ''"
          :previewUrlBrief="signedUrls.question.brief.assetSignedUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged('question', imageKey)"
          :diffIdentifier="`${diffIdentifierPrefix}.question.${imageKey}`"
          label="Select Asset"
          :loadingStatus="
            uploadingStatus[assetTypeMapping.question] == FILE_UPLOADING_STATUS.IN_PROGRESS
          "
          :fileChangeHandler="(file) => fileChangeHandler(file, 'question')"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="getAcceptableFileType(isAssetUploadRequired)"
          :isNotReadOnly="!isNotReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="true"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="data.informationAndAssessment.question.assetApproved"
          :isRequired="true"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              addBriefToAsset(previewUrl, briefUrl, 'question', isAssetApproved)
          "
        />
        <RichTextEditor
          v-if="showImageBrief"
          v-model="data.informationAndAssessment.question.brief.text"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged('question', 'brief')"
          :fieldIdentifier="`${diffIdentifierPrefix}.question.brief`"
          placeholder="Question Asset Brief"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          :showValidationError="true"
          :readonly="isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col cols="6" class="pad-Rm">
        <FileInput
          :image_url="
            isAssetUploadRequired ? data.informationAndAssessment.question_answer.assetId : ''
          "
          :image_url_brief="data.informationAndAssessment.question_answer.brief.assetId"
          :previewUrl="isAssetUploadRequired ? signedUrls.question_answer.assetSignedUrl : ''"
          :previewUrlBrief="signedUrls.question_answer.brief.assetSignedUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged('question_answer', imageKey)"
          :diffIdentifier="`${diffIdentifierPrefix}.question_answer.${imageKey}`"
          label="Select Asset"
          :loadingStatus="
            uploadingStatus[assetTypeMapping.question_answer] == FILE_UPLOADING_STATUS.IN_PROGRESS
          "
          :fileChangeHandler="(file) => fileChangeHandler(file, 'question_answer')"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="getAcceptableFileType(isAssetUploadRequired)"
          :isNotReadOnly="!isNotReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="true"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="data.informationAndAssessment.question_answer.assetApproved"
          :isRequired="false"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              addBriefToAsset(previewUrl, briefUrl, 'question_answer', isAssetApproved)
          "
        />
        <RichTextEditor
          v-if="showImageBrief"
          v-model="data.informationAndAssessment.question_answer.brief.text"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged('question_answer', 'brief')"
          :fieldIdentifier="`${diffIdentifierPrefix}.question_answer.brief`"
          placeholder="Answer Asset Brief"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          :readonly="isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>

    <div>
      <RichTextEditor
        :readonly="isNotReadOnly"
        :canShowDiffIcon="canShowDiffIcon"
        :fieldIdentifier="`${diffIdentifierPrefix}.secondary_description`"
        :placeholder="secondaryDescriptionPlaceholder"
        :showValidationError="!isSecondaryDescriptionOptional"
        :returnEmptyStringInsteadOfDefaultJson="true"
        :isDiffChanged="(diffChanges.secondary_description || {}).isChanged || false"
        v-model="data.informationAndAssessment.secondary_description"
      />
    </div>
    <v-row>
      <v-col cols="6" class="pad-Rm">
        <FileInput
          :image_url="
            isAssetUploadRequired ? data.informationAndAssessment.secondaryAsset.assetId : ''
          "
          :image_url_brief="data.informationAndAssessment.secondaryAsset.brief.assetId"
          :previewUrl="
            isAssetUploadRequired ? (signedUrls.secondaryAsset || {}).assetSignedUrl : ''
          "
          :previewUrlBrief="(signedUrls.secondaryAsset || {}).brief.assetSignedUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged('secondaryAsset', imageKey)"
          :diffIdentifier="`${diffIdentifierPrefix}.secondaryAsset.${imageKey}`"
          label="Select Asset"
          :loadingStatus="
            uploadingStatus[assetTypeMapping.secondaryAsset] == FILE_UPLOADING_STATUS.IN_PROGRESS
          "
          :fileChangeHandler="(file) => fileChangeHandler(file, 'secondaryAsset')"
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="getAcceptableFileType(isAssetUploadRequired)"
          :isNotReadOnly="!isNotReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="true"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="data.informationAndAssessment.secondaryAsset.assetApproved"
          :isRequired="false"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              addBriefToAsset(previewUrl, briefUrl, 'secondaryAsset', isAssetApproved)
          "
        />
        <RichTextEditor
          v-if="showImageBrief"
          v-model="data.informationAndAssessment.secondaryAsset.brief.text"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged('secondaryAsset', 'brief')"
          :fieldIdentifier="`${diffIdentifierPrefix}.secondaryAsset.brief`"
          placeholder="Secondary Asset Brief"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          :readonly="isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>
    <div class="pad-Ts mgn-Bs">
      <XSDisplayCard :compact="true" :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isTeacherTipChanged()"
          :fieldIdentifier="`${diffIdentifierPrefix}.teacher_tip`"
          :diffIdentifier="`${diffIdentifierPrefix}.teacher_tip`"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="data.informationAndAssessment.teacher_tip"
          :readonly="isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
      </XSDisplayCard>
    </div>
  </div>
</template>

<script>
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import AssetTypes from '@/constants/AssetTypes';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import { getAcceptableFileType } from '@/utils';
import { mapActions, mapState } from 'vuex';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import FileInput from '@/components/inputs/FileInput';
import _ from 'lodash';

export default {
  name: 'InformationAndAssessment',
  components: { RichTextEditor, XSDisplayCard, FileInput },
  data() {
    return {
      AppTexts,
      AssetTypes,
      FILE_UPLOADING_STATUS,
      Errors,
      BASIC_TOOLS,
    };
  },
  mounted() {
    // secondaryAsset is newly added filed this is not present for the old I & A proejcts.
    if (!this.data.informationAndAssessment.secondaryAsset) {
      this.data.informationAndAssessment.secondaryAsset = {
        brief: {
          text: '',
          assetId: '',
        },
        assetId: '',
        assetApproved: false,
      };
      this.signedUrls.secondaryAsset = {
        assetSignedUrl: '',
        brief: {
          assetSignedUrl: '',
        },
      };
    }
  },
  computed: {
    imageKey() {
      return this.isAssetUploadRequired ? 'assetId' : 'brief.assetId';
    },
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
    }),
  },
  props: {
    isNotReadOnly: {
      type: Boolean,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isPrimaryDescriptionOptional: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSecondaryDescriptionOptional: {
      type: Boolean,
      required: false,
      default: true,
    },
    data: {
      type: Object,
      required: true,
    },
    diffIdentifierPrefix: {
      type: String,
      required: true,
    },
    primaryDescriptionPlaceholder: {
      type: String,
      required: true,
    },
    secondaryDescriptionPlaceholder: {
      type: String,
      required: true,
    },
    diffChanges: {
      type: Object,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    signedUrls: {
      type: Object,
      required: false,
    },
    canAppproveAsset: { type: Boolean, required: true },
    assetTypeMapping: {
      type: Object,
      required: false,
      default: () => {
        return {
          question: AssetTypes.INFORMATION_AND_ASSESSMENT_QUESTION_ASSET_TYPE,
          question_answer: AssetTypes.INFORMATION_AND_ASSESSMENT_ANSWER_ASSET_TYPE,
          secondaryAsset: AssetTypes.INFORMATION_AND_ASSESSMENT_SECONDARY_ASSET_TYPE,
        };
      },
    },
  },
  methods: {
    ...mapActions(['importSingleAssetAction', 'deleteSingleAssetAction', 'copySingleAssetAction']),
    isTeacherTipChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.diffChanges.teacher_tip.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDiffChanged(entity, attribute) {
      try {
        return this.diffChanges[entity][attribute].isChanged == true;
      } catch (error) {
        return false;
      }
    },
    addBriefToAsset(previewUrl, briefUrl, entity, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: this.assetTypeMapping[entity],
        isAssetApproved,
      });
    },
    fileChangeHandler(file, entity) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: this.assetTypeMapping[entity],
        });
      } else {
        this.deleteSingleAssetAction({
          assetId: _.get(this.data['informationAndAssessment'], `${entity}.${this.imageKey}`),
          fieldIdentifier: `${this.diffIdentifierPrefix}.${entity}.${this.imageKey}`,
          assetType: this.assetTypeMapping[entity],
        });
      }
    },
    getAcceptableFileType,
  },
};
</script>

<style></style>
