import { baseMixin } from './base';
import { validateAssetAuthorized } from '@/utils';

export const practiceLessonMixin = {
  mixins: [baseMixin],
  data() {
    return {
      Schema: {
        aim: {
          show: true,
          maxKeywordsLength: 35,
          summaryMaxLength: 250,
        },
        action: {
          show: false,
        },
        assessment: {
          show: false,
        },
        application: {
          morePractice: false,
          isHomeworkOptional: true,
          showQuestionTeacherTip: true,
          showHomeWork: false,
          showPracticeHomework: true,
          classworkSummaryMaxLength: 250,
          maxSupportTipsLength: 10,
          supportTipTextMaxLength: 33,
          supportTipDescriptionMaxLength: 600,
        },
        analysis: {
          showGd: false,
          showHot: false,
          showPart1Text: false,
          show: true,
          showDiscussionKeyQuestionAndGuide: false,
          showUniversalDiscussion: true,
          prefix: '',
          label: {
            activityDiscussion: '',
            activityExplanation: '',
          },
          discussionTeacherTipMaxLength: 500,
          summaryMaxLength: 250,
        },
      },
    };
  },
  methods: {
    customValidations() {
      try {
        let lp = this.lessonPlan;
        if (!lp) throw Error('Invalid LessonPlan');
        if (!lp.data) throw Error('Invalid LessonPlan Data');

        if (!lp.data.lp_title || !lp.data.lp_description)
          throw Error('Title or Description cannot be empty');

        // time validation - start
        const getMinutesFromTimeString = (timeString) => {
          try {
            return +timeString.split(' ')[0];
          } catch (error) {
            return 0;
          }
        };

        let overallTime = lp.data ? getMinutesFromTimeString(lp.data.time) : 0;

        let analysisTime =
          lp.data && lp.data.analysis ? getMinutesFromTimeString(lp.data.analysis.time) : 0;
        let applicationTime =
          lp.data && lp.data.application ? getMinutesFromTimeString(lp.data.application.time) : 0;

        if (overallTime !== analysisTime + applicationTime) {
          let errorMessage = 'Overall time of the LP is not equal to sum of individual step time';
          throw Error(errorMessage);
        }

        const teacherTipsIsAuthoredQuestionValues = lp.data.application.teacher_tips.map(
          (_tt) => _tt.isAuthoredQuestion
        );

        const allAreInNewStructure = teacherTipsIsAuthoredQuestionValues.every((_a) => _a == true);
        const allAreInOldStructure = teacherTipsIsAuthoredQuestionValues.every((_a) => !_a);
        console.log(allAreInOldStructure);
        console.log(allAreInNewStructure);
        // if (allAreInNewStructure || allAreInOldStructure) {
        //   // Valid
        // } else {
        //   throw new Error(
        //     'All Application Teacher Tips should be either in Asset upload or Virtual question. Not mixing of both.'
        //   );
        // }

        const validationStatus = this.validationForAssetApprovingStage();

        return true && validationStatus;
      } catch (exception) {
        console.log(exception);
        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });
        return false;
      }
    },
    prepareLessonPlanDataToSave() {
      // remove loadingStatus from resource object..
      let resources = [];
      this.lessonPlan.data.aim.resources.forEach((resource) => {
        delete resource.loadingStatus;
        resources.push(resource);
      });

      this.lessonPlan.data.aim.resources = resources;

      for (let _discussionName of ['discussion']) {
        let instructionData = [];
        this.lessonPlan.data.analysis[_discussionName].instructions.forEach(function (
          _instruction
        ) {
          delete _instruction.loadingStatus;
          instructionData.push(_instruction);
        });

        this.lessonPlan.data.analysis[_discussionName].instructions = instructionData;
      }

      if (
        this.lessonPlan.data.application.more_practice &&
        this.lessonPlan.data.application.more_practice.length > 0
      ) {
        // set placeholder image for more_practice instructions videos
        for (let ins of this.lessonPlan.data.application.more_practice) {
          if (ins.assets && (ins.assets[0] || {}).type == 'video') {
            ins.assets[0].placeholder.assetId = this.lessonPlan.data.assetId;
          }
          let assets = [];

          for (let asset of ins.assets || []) {
            delete asset.loadingStatus;
            assets.push(asset);
          }
          ins.assets = assets;
        }
      }
    },
    validateLPData() {
      return true;
    },
    validationForAssetApprovingStage() {
      try {
        if (this.currentStage == 'assetAuthorizingStage') {
          let unApprovedAssetCount = validateAssetAuthorized(
            this.lessonPlan.data,
            ['assetId'],
            {
              assetId: 'assetApproved',
            },
            ['brief', 'placeholder'],
            0
          );

          if (unApprovedAssetCount > 0) {
            this.$notify({
              title: 'Failed',
              text: `There are ${unApprovedAssetCount} unapproved assets`,
              type: 'error',
              duration: -1,
            });

            return false;
          }
        }

        return true;
      } catch (error) {
        console.log(error);

        this.$notify({
          title: 'Validation error!',
          text: error,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
  },
};
