<template>
  <div class="micro-topic-wrapper">
    <v-dialog
      content-class="micro-topic-form-dialog"
      v-if="microTopicProjectForm.show"
      v-model="microTopicProjectForm.show"
    >
      <MicroTopicProjectFormContent
        :newMicroTopicProjectsDataKeys="Object.keys(newMicroTopicProjectsData)"
        :newMicroTopicProjectsData="Object.values(newMicroTopicProjectsData)"
        :users="users"
        :saveProject="saveProject"
        :closeProjectDialog="() => (microTopicProjectForm.show = false)"
        dialogTile="Create Project"
        :microTopicProjectDetails="{
          microTopicName: microTopicProjectForm.microTopic.name,
          grade: microTopicProjectForm.microTopic.standard.displayName,
          subject: microTopicProjectForm.microTopic.subject.name,
          theme: microTopicProjectForm.microTopic.theme.name,
        }"
        :id="microTopicProjectForm.microTopic.id"
        :key="microTopicProjectForm.microTopic.id"
      />
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <Loader
          :loadingText="AppTexts.FETCHING_DATA_TEXT"
          :loadingStatus="
            microTopicAuth.canReadMicroTopic == null || isMicroTopicsListFetchingInProgress
          "
        />
        <div v-if="microTopicAuth.canReadMicroTopic">
          <div class="filter-wrapper">
            <div class="heading-text">Filters:</div>
            <div class="reset-filters-link">
              <v-btn text @click="resetFilter()">
                <img
                  style="margin-right: 6px; transform: translateY(2px);"
                  src="@/assets/reset.svg"
                  height="16"
                  width="16"
                />
                Reset Filters
              </v-btn>
            </div>
          </div>
          <v-card class="micro-topic-filter-card">
            <MicroTopicFilter
              @onSearchApply="onSearchApply"
              :filters="filters"
              :filterData="filterData"
              :key="index"
            />
          </v-card>
          <br />
          <template>
            <v-tabs @change="filterTwo">
              <v-tab v-for="(filter, key) in filtersForUser" :key="`projectSingleFilter${key}`">{{
                filter.name
              }}</v-tab>
            </v-tabs> </template
          ><br /><br />
          <div class="heading-text">MICRO TOPICS:</div>
          <MicroTopicList
            :microTopics="isMicroTopicsListFetchingInProgress ? [] : microTopics"
            @createMicroTopic="createMicroTopic"
            @viewMicroTopic="viewMicroTopic"
          />
          <v-pagination
            class="micro-topic-summary-pagination"
            v-model="paginationOptions.page"
            :length="Math.ceil(microTopicTotalCount / paginationOptions.itemsPerPage)"
            :total-visible="7"
            :key="index"
          ></v-pagination>
        </div>
        <div v-else-if="microTopicAuth.canReadMicroTopic == false" class="text-center">
          You are not authorized to view this page.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import XTable from "@/components/Admin/XTable";
import Loader from '@/components/common/Loader';
import AppTexts from '@/constants/AppTexts';
import MICRO_TOPIC_CONSTANTS from '@/constants/microTopic';
import { capitalCase } from 'change-case';
import MicroTopicProjectFormContent from '@/components/Admin/MicroTopicProjectFormContent';
import { singleProjectDefaultData } from '@/utils/mtCommonUtils.js';
import MicroTopicFilter from '@/components/contentEntry/microTopic/MicroTopicFilter';
import MicroTopicList from '@/components/contentEntry/microTopic/MicroTopicList';
import _ from 'lodash';

export default {
  name: 'MicroTopic',
  components: {
    // XTable,
    Loader,
    MicroTopicProjectFormContent,
    MicroTopicFilter,
    // SubProjectStatus,
    MicroTopicList,
  },
  data() {
    return {
      AppTexts,
      MICRO_TOPIC_CONSTANTS,
      microTopicListDetails: [],
      searchKey: '',
      index: 0,
      microTopicProjectForm: {
        show: false,
        microTopic: {
          standard: {},
          subject: {},
          theme: {},
        },
      },
      newMicroTopicProjectsData: {},
      filterData: {},
      filtersForUser: [],
      projectSingleFilter: [
        { name: 'ALL', forAdmin: true, forUser: false },
        { name: 'ALL ACTIVE', forAdmin: true, forUser: false },
        { name: 'NOT STARTED', forAdmin: true, forUser: false },
        { name: 'PARTIALLY STARTED', forAdmin: true, forUser: false },
        { name: 'PUBLISHED', forAdmin: true, forUser: false },
        { name: 'ASSIGNED TO ME', forAdmin: true, forUser: true },
        { name: 'PENDING FROM ME', forAdmin: true, forUser: true },
        { name: 'COMPLETED', forAdmin: false, forUser: true },
      ],
      filters: [
        {
          key: 'standardId',
          label: 'Grade',
          dataKey: 'standards',
          items: [],
          value: '',
        },
        {
          key: 'subjectId',
          dataKey: 'subjects',
          label: 'Subject',
          items: [],
          value: '',
        },
        {
          key: 'themeId',
          dataKey: 'themes',
          label: 'Theme',
          items: [],
          value: '',
        },
        {
          key: 'oLessonStatus',
          label: 'O Lesson',
          items: MICRO_TOPIC_CONSTANTS.MICROTOPIC_STATUSES,
          value: '',
        },
        {
          key: 'microTextStatus',
          label: 'Microtext',
          items: MICRO_TOPIC_CONSTANTS.MICROTOPIC_STATUSES,
          value: '',
        },
        {
          key: 'contentBookStatus',
          label: 'Content book',
          items: MICRO_TOPIC_CONSTANTS.MICROTOPIC_STATUSES,
          value: '',
        },
        {
          key: 'worksheetStatus',
          label: 'Worksheet',
          items: MICRO_TOPIC_CONSTANTS.MICROTOPIC_STATUSES,
          value: '',
        },
        {
          key: 'videosStatus',
          label: 'Videos',
          items: MICRO_TOPIC_CONSTANTS.MICROTOPIC_STATUSES,
          value: '',
        },
        {
          key: 'questionsStatus',
          label: 'Questions',
          items: MICRO_TOPIC_CONSTANTS.MICROTOPIC_STATUSES,
          value: '',
        },
      ],
      paginationOptions: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },
  created() {
    this.getCurrentUserMicroTopicAuthPermissions();
    this.getUsers();
    this.getFilterDataForMicroTopicListPage();
    this.getMicroTopicSummaryFilter();
  },
  computed: {
    ...mapState({
      microTopics: (state) => state.microTopics,
      microTopicAuth: (state) => state.auth.microTopic,
      isAdmin: (state) => state.auth.isAdmin,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
      users: (state) => state.user.users,
      filterDataFromStore: (state) => state.summaryFilter,
      initialMicroTopicsListApiStatus: (state) => state.initialMicroTopicsListApiStatus,
      filterOptions: (state) => state.microTopicfilterOptions,
      microTopicTotalCount: (state) => state.microTopicTotalCount,
    }),
    microTopicsListFetchingStatusText() {
      let text;
      if (this.initialMicroTopicsListApiStatus == 'completed') {
        text = 'No data available.'; // In completed state, send loading text as no data available. It will be shown only when list is empty.
      } else if (this.initialMicroTopicsListApiStatus == 'error') {
        text = 'Something went wrong. Please try again after some time.';
      } else {
        text = 'Fetching micro topics...';
      }

      return text;
    },
    isMicroTopicsListFetchingInProgress() {
      return this.initialMicroTopicsListApiStatus == 'in_progress';
    },
  },
  watch: {
    microTopics: {
      deep: true,
      immediate: true,
      handler() {
        this.microTopicListDetails = this.populateMicroTopicListDetails();
      },
    },
    isAdmin: {
      immediate: true,
      deep: true,
      handler(_isAdmin) {
        if (_isAdmin) {
          this.projectListFilterType = this.MICRO_TOPIC_CONSTANTS.PROJECT_LIST_FILTERS.DEFAULT;
          this.paginationOptions.itemsPerPage = 50;
        } else {
          this.projectListFilterType = this.MICRO_TOPIC_CONSTANTS.PROJECT_LIST_FILTERS.PERSONALIZED;
        }

        let filterIndex = this.filters.findIndex((item) => item.key == 'assignee');

        if (filterIndex != -1) {
          this.filters[filterIndex].value = this.projectListFilterType;
        }
        this.loadSingleFilterData();
      },
    },
    isAcademicAdmin: {
      immediate: true,
      handler() {
        this.loadSingleFilterData();
      },
    },
    filterOptions: {
      immediate: true,
      deep: true,
      handler(value) {
        Object.keys(value).forEach((key) => {
          let filterIndex = this.filters.findIndex((item) => item.dataKey == key);
          if (filterIndex != -1) {
            this.filters[filterIndex].items = value[key] || [];
          }
        });
      },
    },
    filterDataFromStore: {
      immediate: true,
      deep: true,
      handler(val) {
        this.filterData = val;
        this.fillFilterData();
        setTimeout(() => {
          this.fetchMicroTopics();
        }, 350);
      },
    },
    paginationOptions: {
      deep: true,
      handler() {
        this.fetchMicroTopics();
      },
    },
  },
  methods: {
    ...mapActions([
      'getMicroTopicsPagination',
      'getUsers',
      'createMicroTopicProjecs',
      'getCurrentUserMicroTopicAuthPermissions',
      'getFilterDataForMicroTopicListPage',
      'setMicroTopicSummaryFilter',
      'getMicroTopicSummaryFilter',
      'removeMicroTopicSummaryFilterData',
    ]),
    singleProjectDefaultData,
    capitalCase,
    fillFilterData() {
      for (let key in this.filterData) {
        this.filters.map((_) => {
          if (_.key == key) {
            _.value = this.filterData[key];
          }
        });
      }
    },
    resetFilter() {
      for (let key in this.filterData) {
        this.filters.map((_) => {
          if (_.key == key) {
            _.value = '';
          }
        });
      }
      this.filterData = {};
      this.index += 1;
      this.paginationOptions.page = 1;
      this.removeMicroTopicSummaryFilterData();
      this.fetchMicroTopics();
    },
    initNewMicroTopicFormData() {
      this.newMicroTopicProjectsData = _.cloneDeep({
        oLesson: _.cloneDeep(this.singleProjectDefaultData('oLesson')),
        questions: _.cloneDeep(this.singleProjectDefaultData('questions')),
        microText: _.cloneDeep(this.singleProjectDefaultData('microText')),
        worksheet: _.cloneDeep(this.singleProjectDefaultData('worksheet')),
        contentBook: _.cloneDeep(this.singleProjectDefaultData('contentBook')),
        videos: _.cloneDeep(this.singleProjectDefaultData('videos')),
      });
    },
    loadSingleFilterData() {
      if (this.isAdmin || this.isAcademicAdmin) {
        this.filtersForUser = this.projectSingleFilter.filter((filter) => filter.forAdmin == true);
      } else {
        this.filtersForUser = this.projectSingleFilter.filter((filter) => filter.forUser == true);
      }
    },
    populateMicroTopicListDetails() {
      let microTopicsList = [];
      this.microTopics.forEach((microTopic, index) => {
        let projectTypeAndStatusMapping = this.getProjectTypeAndStatusMapping(
          microTopic.projects || []
        );

        microTopicsList.push({
          index: index + 1,
          id: microTopic.id,
          mtId: microTopic.mtId,
          name: microTopic.name,
          standardName: microTopic.standard.displayName,
          subjectName: microTopic.subject.name,
          topicName: microTopic.topic.name,
          themeName: microTopic.theme.name,
          ...projectTypeAndStatusMapping,
          actions: 'actions',
        });
      });

      return microTopicsList;
    },
    getProjectTypeAndStatusMapping(projects) {
      let response = {};

      this.MICRO_TOPIC_CONSTANTS.MICRO_TOPIC_PROJECT_TYPES.forEach((projectType) => {
        let projectData = projects.find((pro) => pro.type == projectType);

        response[projectType + 'Status'] = projectData
          ? this.getFormattedProjectStatus(projectData.workflowStatus)
          : this.MICRO_TOPIC_CONSTANTS.NOT_STARTED_ITEM_STATUS;
      });

      return response;
    },
    filterTwo(val) {
      this.filterData['projectListFilter'] = this.filtersForUser[val].name;
      this.index += 1;
      this.paginationOptions.page = 1;
      this.fetchMicroTopics();
    },
    getFormattedProjectStatus(value) {
      if (typeof value == 'string') {
        return this.capitalCase(value);
      } else {
        return value;
      }
    },
    canShowCreateAction(item) {
      return !this.isActiveMicroTopic(item);
    },
    canShowViewAction(item) {
      return this.isActiveMicroTopic(item);
    },
    isActiveMicroTopic(microTopic) {
      return (
        microTopic.oLessonStatus.split(' ')[0] ==
          this.MICRO_TOPIC_CONSTANTS.IN_PROGRESS_ITEM_STATUS ||
        microTopic.worksheetStatus.split(' ')[0] ==
          this.MICRO_TOPIC_CONSTANTS.IN_PROGRESS_ITEM_STATUS ||
        microTopic.microTextStatus.split(' ')[0] ==
          this.MICRO_TOPIC_CONSTANTS.IN_PROGRESS_ITEM_STATUS ||
        microTopic.contentBookStatus.split(' ')[0] ==
          this.MICRO_TOPIC_CONSTANTS.IN_PROGRESS_ITEM_STATUS ||
        microTopic.videosStatus.split(' ')[0] ==
          this.MICRO_TOPIC_CONSTANTS.IN_PROGRESS_ITEM_STATUS ||
        microTopic.questionsStatus.split(' ')[0] ==
          this.MICRO_TOPIC_CONSTANTS.IN_PROGRESS_ITEM_STATUS
      );
    },
    createMicroTopic(microTopicItem) {
      let microTopic = this.microTopics.find((mt) => mt.id == microTopicItem.id);
      this.initNewMicroTopicFormData();

      this.microTopicProjectForm = {
        show: true,
        microTopic,
      };
    },
    viewMicroTopic(microTopic) {
      this.$router.push({
        name: 'MicroTopicView',
        params: {
          microTopicId: microTopic.id,
          mtId: microTopic.mtId,
        },
      });
    },
    onSearchApply(value, filter) {
      this.filterData[filter.key] = value.target ? value.target.value : value;
      if (this.filterData) {
        this.setMicroTopicSummaryFilter(this.filterData);
        this.fetchMicroTopics();
        this.paginationOptions.page = 1;
      }
    },
    saveProject() {
      Object.keys(this.newMicroTopicProjectsData).forEach((projectType) => {
        let projectAssigneeDetails = this.newMicroTopicProjectsData[projectType];

        if (projectAssigneeDetails && projectAssigneeDetails.enabled) {
          this.createMicroTopicProjecs({
            assignees: projectAssigneeDetails.assignees,
            microTopic: this.microTopicProjectForm.microTopic,
            projectType: projectType,
            isListPage: true,
          });
        }
      });

      this.microTopicProjectForm.show = false;
    },
    fetchMicroTopics() {
      window.scrollTo(0, 0);
      this.getMicroTopicsPagination({
        options: {
          limit: this.paginationOptions.itemsPerPage,
          offset: (this.paginationOptions.page - 1) * this.paginationOptions.itemsPerPage,
          filters: this.filterData,
        },
      });
    },
  },
};
</script>
<style scoped>
.micro-topic-wrapper {
  margin: 2% 4%;
}
.micro-topic-filter-card {
  padding: 20px;
}
.heading-text {
  font-size: 24px;
  font-weight: 700;
  color: #242424;
  margin-bottom: 15px;
}
.micro-topic-summary-pagination {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-device-width: 1280px) {
  .micro-topic-wrapper {
    margin: 2% 3%;
  }
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;
}
.reset-filters-link {
  font-size: 16px;
  color: #177eee;
  cursor: pointer;
}
</style>

<style>
.micro-topic-summary-pagination button {
  box-shadow: none !important;
  font-size: 20px;
  user-select: none;
}
.micro-topic-summary-pagination .v-pagination__item--active {
  font-weight: bold;
}
.micro-topic-form-dialog {
  transform: translateY(30px);
  max-height: 88% !important;
}
</style>
