export const AUTH_STATE = {
  loggedInUser: {},
  auth: {
    isAdmin: false,
    isAcademicAdmin: false,
    isTrainingAdmin: false,
    project: {
      canMutateProject: false,
      canReadProject: null,
      canDeleteProject: false,
    },
    lessonPlan: {
      canMoveProjectLP: false,
      canSaveProjectLP: false,
      canAddComment: false,
      canResolveComment: false,
      canDownloadContentAssets: false,
    },
    dailyPlan: {
      canDownloadContentAssets: false,
    },
    microTopic: {
      canMutateMicroTopic: false,
      canReadMicroTopic: null,
      canViewMicroTopic: false,
      canEditMicroTopicsList: [],
    },
    contentEntryProjectForm: {
      canMoveContent: false,
      canSaveContent: false,
      canDownloadContentAssets: false,
      canPublishProject: false,
      canRevertProjectToPreviousStage: false,
      canValidateContent: false,
      canAddComment: false,
      canResolveComment: false,
      canMoveContentProjectToPreviousStage: false,
      canSwitchType: false,
    },
  },
};
