import { LessonPlansQuery, BlockMapLPDPQuery } from '@/apiQueries/BlockMapping';
import { lessonPlansDataStatus } from '@/apiQueries/lpForm';
import { dailyPlansDataStatus } from '@/apiQueries/dailyPlan';
import { apolloClient } from '@/apollo';
import { logError } from '@/utils';

export const BLOCK_MAP_ACTION = {
  async getLessonPlans(context, { boardName, subjectId, gradeId, productId }) {
    try {
      const lessonPlans = await apolloClient.query({
        query: LessonPlansQuery(boardName, subjectId, gradeId, productId),
      });
      let lessonPlansArray = lessonPlans.data.blockMapLessonPlans;

      const statuses = await apolloClient.query({
        query: lessonPlansDataStatus(lessonPlansArray.map((lp) => lp.libraryId)),
      });

      let dataCompletedLessonPlans = [];
      statuses.data.lessonPlansDataStatus.forEach((lp) => {
        if (lp.status == 'completed') {
          dataCompletedLessonPlans.push(lp.libraryId.toString());
        }
      });

      let data = lessonPlansArray.map((lp) => {
        (lp.status = dataCompletedLessonPlans.includes(lp.libraryId) ? 'completed' : 'not_started'),
          (lp.image_url = 'https://lesson-plan-app-staging.s3.ap-south-1.amazonaws.com/cover.jpg');

        return lp;
      });

      context.commit('SET_LESSON_PLANS', data);
    } catch (er) {
      logError(er);
    }
  },
  async getBlockMapLPDP(context, { productName, boardName }) {
    try {
      const blockMaps = await apolloClient.query({
        query: BlockMapLPDPQuery(productName, boardName),
      });

      let lpIds = [];
      let dpIds = [];

      blockMaps.data.blockMapsWithBlockLessonDailyPlans.filter((x) => {
        if (x.Standard == 'LKG' || x.Standard == 'UKG' || x.Standard == 'Nursery') {
          dpIds.push(x.libraryId);
        } else {
          lpIds.push(x.libraryId);
        }
      });

      let promise = await Promise.all([
        apolloClient.query({
          query: lessonPlansDataStatus(lpIds),
        }),
        apolloClient.query({
          query: dailyPlansDataStatus(dpIds),
        }),
      ]);

      const lpStatuses = JSON.parse(promise[0].data.lessonPlansDataStatus).map((x) => x.libraryId);
      const dpStatuses = JSON.parse(promise[1].data.dailyPlansDataStatus).map((x) => x.libraryId);

      let data = blockMaps.data.blockMapsWithBlockLessonDailyPlans.map((lp) => {
        if (lp.Standard == 'LKG' || lp.Standard == 'UKG' || lp.Standard == 'Nursery') {
          lp.status = dpStatuses.includes(lp.libraryId) ? 'completed' : 'not_started';
        } else {
          lp.status = lpStatuses.includes(lp.libraryId) ? 'completed' : 'not_started';
        }
        return lp;
      });
      context.commit('SET_BLOCK_MAPS', data);
    } catch (er) {
      logError(er);
    }
  },
};
