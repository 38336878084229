import { LP_STAGES } from '@/constants/LessonPlan';
import Vue from 'vue';
const defaultMarkDownToTagMapping = [
  {
    markDown: '**',
    tag: 'b',
  },
  {
    markDown: '*',
    tag: 'i',
  },
  {
    markDown: '_',
    tag: 'u',
  },
  {
    markDown: '$$',
    tag: 'e',
  },
];

export const setNextArrayIndexWith = (array, With) => {
  Vue.set(array, array.length, With);
};

export const logError = (err) => {
  console.error(err);
  console.log(`Error: ${err.name} :  ${err.message}`);
};

export const getAssetUploadRequiredStatus = (currentStage) => {
  let stagesArray = Object.keys(LP_STAGES);
  let assetUploaderIndex = stagesArray.findIndex(
    (currentStage) => currentStage == 'assetUploadStage'
  );
  let currentStageIndex = stagesArray.indexOf(currentStage);
  return !(currentStageIndex < assetUploaderIndex);
};

export const getAcceptableFileType = (isAboveAssetUploadStage) => {
  return isAboveAssetUploadStage ? 'image/*,.pdf' : 'image/*,.pdf,.docx,.doc';
};

export const validateAssetAuthorized = (
  _currentStageData,
  fieldIdentifier,
  approvedFlag,
  skipKey,
  returned,
  path = ""
) => {
  for (let key in _currentStageData) {
    if (
      key != "" &&
      typeof _currentStageData[key] == 'object' &&
      key != '_id' &&
      _currentStageData[key] &&
      !skipKey.includes(key)
    ) {
      returned = validateAssetAuthorized(
        _currentStageData[key],
        fieldIdentifier,
        approvedFlag,
        skipKey,
        returned,
        `${path}.${key}`
      );
    } else {
      if (fieldIdentifier.includes(key)) {
        if (
          _currentStageData[key] != '' &&
          (!_currentStageData[approvedFlag[key]] || _currentStageData[approvedFlag[key]] == false)
        ) {
          console.log(`path of unapproved: ${path}`)
          returned += 1;
        }
      }
    }
  }
  return returned;
};

export const validateAllAssetsPresent = (_currentStageData, fieldIdentifier, skipKey, returned) => {
  for (let key in _currentStageData) {
    if (typeof _currentStageData[key] == 'object' && key != '_id' && _currentStageData[key] &&  !skipKey.includes(key)) {
      returned = validateAllAssetsPresent(_currentStageData[key], fieldIdentifier, skipKey, returned);
    } else {
      if (fieldIdentifier.includes(key)) {
        if (!_currentStageData[key]) {
          returned += 1;
        }
      }
    }
  }
  return returned;
};
export const setFormDataForFileUploadForOProgram = (file, fileName, path) => {
  let formData = new FormData();
  formData.append('inputFile', file, fileName);
  formData.append('path', path);
  return formData;
};
export const setFormDataForFileUpload = (file, fileName, preset, appName) => {
  let formData = new FormData();
  formData.append('assets', file, fileName);
  formData.append('preset', preset);
  formData.append('appName', appName);
  return formData;
};

export const getStepAdditionValidationText = (count, itemName) => {
  if (count != 1) itemName = itemName + 's';
  return ` (You can add ${count} more ${itemName})`;
};

export const convertToPluralText = (count, itemName) => {
  if (count > 1) itemName = itemName + 's';

  return `${count} ${itemName}`;
};

export const formatTime = (timeCreated) => {
  if (timeCreated == 0) return '-';
  var diff = Math.floor((Date.now() - timeCreated) / 1000);
  var interval = Math.floor(diff / 31536000);

  if (interval >= 1) {
    return interval + ' years ago';
  }
  interval = Math.floor(diff / 2592000);
  if (interval >= 1) {
    return interval + ' months ago';
  }
  interval = Math.floor(diff / 604800);
  if (interval >= 1) {
    return interval + ' weeks ago';
  }
  interval = Math.floor(diff / 86400);
  if (interval >= 1) {
    return interval + ' days ago';
  }
  interval = Math.floor(diff / 3600);
  if (interval >= 1) {
    return interval + ' hours ago';
  }
  interval = Math.floor(diff / 60);
  if (interval >= 1) {
    return interval + ' minutes ago';
  }
  return '<1 minute';
};

export const showErrorNotification = (error) => {
  Vue.notify({
    title: 'Error!',
    text: error,
    type: 'error',
  });
};

export const mdToHtml = (str, equationEditorJsonArray = [], markDownToTagMapping) => {
  markDownToTagMapping = markDownToTagMapping || defaultMarkDownToTagMapping;
  var tempStr = str;

  markDownToTagMapping.forEach((makDownHash) => {
    tempStr = mdToHtmlHelper(tempStr, makDownHash, equationEditorJsonArray);
  });

  return tempStr;
};

const mdToHtmlHelper = (val, makDownHash, equationEditorJsonArray = []) => {
  let str = val;

  const { markDown, tag } = makDownHash;
  const markDownTextLength = markDown.length;

  while (str.indexOf(markDown) !== -1) {
    var firstPos = str.indexOf(markDown);
    var nextPos = str.indexOf(markDown, firstPos + markDownTextLength);

    if (nextPos !== -1) {
      var innerTxt = str.substring(firstPos + markDownTextLength, nextPos);
      var strongified;

      if (markDown == '$$') {
        const equationEditorJson = equationEditorJsonArray.find((_) => _.latex == innerTxt);
        const equationEditorJsonValue = equationEditorJson
          ? JSON.parse(equationEditorJson.json || JSON.stringify('{}'))
          : '{}';

        strongified = `<span data-latex=${innerTxt} data-equation-json-string='${equationEditorJsonValue}'></span>`;
      } else {
        strongified = `<${tag}>${innerTxt}</${tag}>`;
      }

      str =
        str.substring(0, firstPos) +
        strongified +
        str.substring(nextPos + markDownTextLength, str.length);
    } else {
      str = str.replace(markDown, '');
    }
  }

  return str;
};
