import { baseMixin } from './base';
import { validateAssetAuthorized } from '@/utils';
export const magicLessonMixin = {
  mixins: [baseMixin],
  data() {
    return {
      Schema: {
        application: {
          showLegacyClassWorkHomeWork: true,
          showClassWorkHomeWork: false,
          showDifferentiationTips: true,
          morePractice: false,
        },
        assessment: {
          canShowCheck: true,
        },
      },
    };
  },
  methods: {
    unApprovedAssetValidation() {
      let countUnApprovedAssets = validateAssetAuthorized(
       this.lessonPlan.data,
        ['assetId'],
        {
          assetId: 'assetApproved',
        },
        ['brief', 'placeholder'],
        0
      );
      if (countUnApprovedAssets > 0) {
        this.$notify({
          title: 'Failed',
          text: `There are ${countUnApprovedAssets} unapproved assets`,
          type: 'error',
          duration: -1,
        });
        return false;
      }
      return true;
    },
  },
};
