import { apolloClient } from '@/teacherTrainingGQLClient';
import {
  FETCH_TRAININGS_QUERY,
  FETCH_QUESTIONS_BY_TRAINING_ID_AND_ENTITY_TYPE,
  SAVE_QUESTIONS_FOR_TRAINING_ENTITY,
  FETCH_TRAINING_DETAILS_BY_TRAINING_ID,
  DELETE_QUESTIONS_BY_IDS,
} from '@/apiQueries/training';
import Vue from 'vue';
const actions = {
  fetchTrainings: async ({ commit }, payload) => {
    try {
      console.log('ACTION#fetchTrainings#START');
      let { limit = 150, offset = 0 } = payload || {};
      commit('setTrainingsDataOnApiInProgress', {
        message: 'Fetching training data...',
      });

      const response = await apolloClient.query({
        query: FETCH_TRAININGS_QUERY,
        variables: { limit, offset },
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        setTimeout(() => {
          commit('setTrainingsDataOnApiSuccess', {
            message: 'Successfully fetched training data!',
            data: response.data,
          });
        }, 500);
        console.log('ACTION#fetchTrainings#END#SUCCESS');
        return;
      }

      setTimeout(() => {
        commit('setTrainingsDataOnApiFailure', {
          message:
            'Something went wrong while fetching training details! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchTrainings#END#FAILURE');
    } catch (error) {
      setTimeout(() => {
        commit('setTrainingsDataOnApiError', {
          message: 'Some error occurred! Please try again after sometime.',
        });
      }, 500);
      console.log('ACTION#fetchTrainings#END#ERROR');
    }
  },
  fetchQuestionsByTrainingIdAndEntityType: async ({ commit }, payload) => {
    try {
      console.log('ACTION#fetchQuestionsByTrainingIdAndEntityType#START');
      commit('setFetchQuestionApiStatusToState', {
        status: 'in_progress',
        message: 'Fetching Question data...',
      });

      const response = await apolloClient.query({
        query: FETCH_QUESTIONS_BY_TRAINING_ID_AND_ENTITY_TYPE,
        variables: {
          trainingId: payload.trainingId,
          entity: payload.entity,
          pollIndex: payload.pollIndex,
        },
        fetchPolicy: 'no-cache',
      });

      if (response && response.data && response.data.questions) {
        setTimeout(() => {
          commit('setFetchQuestionApiStatusToState', {
            status: 'completed',
            message: 'Successfully fetched question data!',
            data: response.data.questions,
          });
          let questions = response.data.questions;
          questions.forEach((_q) => {
            _q.questionType = _q.questionType.toLowerCase();
          });
          commit('SET_QUESTION_DATA', questions);
        }, 500);
        console.log('ACTION#fetchTrainings#END#SUCCESS');
        return;
      }
      setTimeout(() => {
        commit('setFetchQuestionApiStatusToState', {
          status: 'failed',
          message:
            'Something went wrong while fetching training details! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
    } catch (error) {
      setTimeout(() => {
        commit('setFetchQuestionApiStatusToState', {
          status: 'failed',
          message:
            'Something went wrong while fetching training details! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
      console.log('ACTION#fetchQuestionsByTrainingIdAndEntityType#END#ERROR');
    }
  },
  saveQuestionsForTrainingModule: async ({ commit }, payload) => {
    try {
      console.log(commit);
      console.log('ACTION#saveQuestionsForTrainingModule#START');
      const response = await apolloClient.mutate({
        mutation: SAVE_QUESTIONS_FOR_TRAINING_ENTITY,
        variables: {
          questionsInput: payload.questions,
          trainingId: payload.trainingId,
          entity: payload.entity,
          pollIndex: payload.pollIndex,
        },
      });

      if (response && response.data) {
        setTimeout(() => {
          Vue.notify({
            title: 'Success!',
            text: `Question created/updated Successfully!`,
            type: 'success',
          });
        }, 500);
        console.log('ACTION#saveQuestionsForTrainingModule#END#SUCCESS');
        return;
      }
      setTimeout(() => {
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
    } catch (error) {
      setTimeout(() => {
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
      console.log('ACTION#saveQuestionsForTrainingModule#END#ERROR');
    }
  },
  deleteTrainingQuestionsByIds: async ({ commit }, payload) => {
    try {
      console.log('ACTION#deleteQuestionsByIds#START');
      commit('setFetchQuestionApiStatusToState', {
        status: 'in_progress',
        message: 'Deleting Question Please wait...',
      });
      const response = await apolloClient.mutate({
        mutation: DELETE_QUESTIONS_BY_IDS,
        variables: {
          questionIds: payload.questionIds,
        },
      });

      if (response && response.data) {
        setTimeout(() => {
          commit('setFetchQuestionApiStatusToState', {
            status: 'completed',
            message: 'Deleting Question Please wait...',
          });
          Vue.notify({
            title: 'Success!',
            text: `Question deleted Successfully!`,
            type: 'success',
          });
        }, 500);
        console.log('ACTION#deleteQuestionsByIds#END#SUCCESS');
        return;
      }
      setTimeout(() => {
        commit('setFetchQuestionApiStatusToState', {
          status: 'failed',
          message:
            'Something went wrong while deleting questions! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
    } catch (error) {
      setTimeout(() => {
        commit('setFetchQuestionApiStatusToState', {
          status: 'failed',
          message:
            'Something went wrong while deleting questions! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
      console.log('ACTION#deleteQuestionsByIds#END#ERROR');
    }
  },
  fetchTrainingDetailsByTrainingId: async ({ commit }, payload) => {
    try {
      console.log('ACTION#fetchTrainingDetailsByTrainingId#START');
      commit('setFetchTrainingDetailsApiStatusToState', {
        status: 'in_progress',
        message: 'Fetching Training Details data...',
      });

      const response = await apolloClient.query({
        query: FETCH_TRAINING_DETAILS_BY_TRAINING_ID,
        variables: { id: payload.id },
        fetchPolicy: 'no-cache',
      });

      if (response && response.data && response.data.training) {
        setTimeout(() => {
          commit('setFetchTrainingDetailsApiStatusToState', {
            status: 'completed',
            message: 'Successfully fetched training details data',
            data: response.data.training,
          });
        }, 500);
        console.log('ACTION#fetchTrainingDetailsByTrainingId#END#SUCCESS');
        return;
      }
      setTimeout(() => {
        commit('setFetchTrainingDetailsApiStatusToState', {
          status: 'failed',
          message:
            'Something went wrong while fetching training details! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
    } catch (error) {
      setTimeout(() => {
        commit('setFetchTrainingDetailsApiStatusToState', {
          status: 'failed',
          message:
            'Something went wrong while fetching training details! Please try again after sometime.',
        });
        Vue.notify({
          title: 'Error!',
          text: `Something went wrong!`,
          type: 'error',
        });
      }, 500);
      console.log('ACTION#fetchTrainingDetailsByTrainingId#END#ERROR');
    }
  },
};

export default actions;
