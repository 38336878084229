const AssetTypes = require('../../../constants/AssetTypes');
const AssetTypesExported = AssetTypes.default;

const defaultState = () => {
  return {
    id: '',
    libraryId: 0,
    currentStage: '',
    status: 'not_started',
    name: '',
    subjectName: '',
    time: '',
    lpType: '',
    lessonPlanLoadingStatus: {
      status: '',
      message: '',
    },
    lessonPlanDataFetchStatus: 'not_started',
    uploading_status: {
      assets: 'not_started',
      cover_image: 'not_started',
      video: 'not_started',
      boardwork: 'not_started',
      classwork: 'not_started',
      student_workbook: 'not_started',
      student_workbook_answer: 'not_started',
      homework: 'not_started',
      homework_answer: 'not_started',
      activity_explanation: 'not_started',
      generalized_explanation: 'not_started',
      higher_order_thinking_explanation: 'not_started',
      [AssetTypesExported.EP_HOMEWORK_QUESTION_ASSET_TYPE]: 'not_started',
      [AssetTypesExported.EP_HOMEWORK_QUESTION_ANSWER_ASSET_TYPE]: 'not_started',
      [AssetTypesExported.EP_CLASSWORK_QUESTION_ASSET_TYPE]: 'not_started',
      [AssetTypesExported.EP_CLASSWORK_QUESTION_ANSWER_ASSET_TYPE]: 'not_started',
    },
    equationEditorJsonArray: [],
    data: {},
    diff: {},
    commentsCount: {},
    staticTexts: {},
    signedUrls: {},
    lpCurrentStageDataDailyPlanFromVuex: {},
    saveLessonPlanTriggerState: false,
  };
};

const state = defaultState();

module.exports = {
  state,
  defaultState,
};
