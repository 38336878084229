import * as RightSideContentQueries from '@/apiQueries/rightSideContent';
import { apolloClient } from '@/apollo';
import { logError } from '@/utils';
import Vue from 'vue';
import { API_FETCH_STATUS } from '@/constants/ApiFetchStatus';

const DEFAULT_STATUS_HASH = {
  authoring1Stage: 'not_started',
  review1Stage: 'not_started',
  review2Stage: 'not_started',
  authoring2Stage: 'not_started',
  academicSignOffStage: 'not_started',
  editingStage: 'not_started',
  visualizingStage: 'not_started',
  assetUploadStage: 'not_started',
  assetAuthorizingStage: 'not_started',
  proofReadingStage: 'not_started',
};

const state = {
  setQuestionDiffPanel: false,
  setGroupQuestionDiffPanel: false,
  currentDiffIdentifier: '',
  rightSidePreviewContent: 'lp_preview',
  showRightSidePanel: false,
  questionCommentsLoader: false,
  fieldDiff: {
    fetchStatus: 'not_started',
    data: {
      authoring1Stage: '',
      authoring1StageUpdatedAt: 0,
      review1Stage: '',
      review1StageUpdatedAt: 0,
      review2Stage: '',
      review2StageUpdatedAt: 0,
      authoring2Stage: '',
      authoring2StageUpdatedAt: 0,
      academicSignOffStage: '',
      academicSignOffStageUpdatedAt: 0,
      editingStage: '',
      editingStageUpdatedAt: 0,
      visualizingStage: '',
      visualizingStageUpdatedAt: 0,
      assetUploadStage: '',
      assetUploadStageUpdatedAt: 0,
      assetAuthorizingStage: '',
      assetAuthorizingStageUpdatedAt: 0,
      proofReadingStage: '',
      proofReadingStageUpdatedAt: 0,
    },
  },
  commentsHash: {
    summary: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    aim: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    action: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    actionProgram1: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    actionProgram2: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    analysis: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    analysisProgram1: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    analysisProgram2: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    application: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    assessment: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    worksheet: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    whatDidYouLearn: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    teacherReference: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    microText: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    contentBook: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    video: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
    informationAndAssessment: {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    },
  },
};

const actions = {
  async projectLessonPlanDiffData(
    { commit },
    { projectId, libraryId, fieldIdentifier, projectType }
  ) {
    try {
      commit('setProjectLessonPlanDiffFetchStatus', {
        status: API_FETCH_STATUS.IN_PROGRESS,
      });
      if (projectType == 'noQuestion') {
        commit('updateRightSidePanelVisiblity', true);
      }
      const response = await apolloClient.mutate({
        mutation: RightSideContentQueries.projectLessonPlanDiffDataQuery,
        variables: {
          lessonPlanLibraryId: libraryId || 0,
          projectId,
          fieldIdentifier,
        },
      });

      if (response && response.data && response.data.projectLessonPlanDiffData) {
        commit('setProjectLessonPlanDiffFetchStatus', {
          status: API_FETCH_STATUS.COMPLETED,
        });
        commit('setCurrentDiffIdentifier', fieldIdentifier);
        commit('setProjectLessonPlanDiffData', {
          data: response.data.projectLessonPlanDiffData,
        });
      } else {
        commit('setProjectLessonPlanDiffFetchStatus', {
          status: API_FETCH_STATUS.ERROR,
        });
        Vue.notify({
          title: 'Failed',
          text: 'Failed to fetch diff data',
          type: 'error',
        });
      }
    } catch (err) {
      commit('setProjectLessonPlanDiffFetchStatus', {
        status: API_FETCH_STATUS.ERROR,
      });
      logError(err);
      Vue.notify({
        title: 'Failed',
        text: 'Failed to fetch diff data' + err.message,
        type: 'error',
      });
    }
  },
  async fetchProjectLessonPlanSectionComment(
    { commit },
    { projectId, libraryId, sectionIdentifier, projectType }
  ) {
    if (projectType && projectType == 'questions') {
      commit('setDefaultDataForQuestionComment', sectionIdentifier);
      commit('SET_QUESTION_COMMENTS_LOADER', true);
    }
    commit('setProjectLessonPlanSectionCommentFetchStatus', {
      sectionIdentifier,
      status: 'in_progress',
      message: 'Fetching comments. Please wait...',
    });

    const response = await apolloClient.query({
      query: RightSideContentQueries.ProjectLessonPlanSectionCommentQuery(
        projectId,
        libraryId || 0,
        sectionIdentifier
      ),
    });

    if (response && response.data && response.data.projectLessonPlanSectionComment) {
      commit('setProjectLessonPlanSectionComment', {
        projectId,
        libraryId: libraryId,
        sectionIdentifier,
        comments: response.data.projectLessonPlanSectionComment,
      });
      if (projectType && projectType == 'questions') {
        commit('SET_QUESTION_COMMENTS_LOADER', false);
      }
      commit('setProjectLessonPlanSectionCommentFetchStatus', {
        sectionIdentifier,
        status: 'completed',
        message: 'Successfully fetched the comments.',
      });
    } else {
      commit('setProjectLessonPlanSectionCommentFetchStatus', {
        sectionIdentifier,
        status: 'error',
        message: response.errors[0].message,
      });
    }
  },
  async saveComment({ commit }, { projectId, libraryId, sectionIdentifier, text, stageName }) {
    try {
      commit('setCommentSaveStatus', {
        sectionIdentifier,
        stageName,
        status: 'in_progress',
      });
      const response = await apolloClient.mutate({
        mutation: RightSideContentQueries.saveProjectLessonPlanSectionCommentQuery,
        variables: {
          projectId,
          sectionIdentifier,
          text,
          lessonPlanLibraryId: libraryId || 0,
        },
      });

      if (response && response.data && response.data.saveProjectLessonPlanSectionComment) {
        commit('saveComment', response.data.saveProjectLessonPlanSectionComment);
        commit('setCommentSaveStatus', {
          sectionIdentifier,
          stageName,
          status: 'completed',
        });

        Vue.notify({
          title: 'Success',
          text: 'Successfully saved the comment!',
          type: 'success',
        });
      } else {
        commit('setCommentSaveStatus', {
          sectionIdentifier,
          stageName,
          status: 'error',
        });
        Vue.notify({
          title: 'Failed',
          text: 'Failed to save the comment',
          type: 'error',
        });
      }
    } catch (err) {
      logError(err);
      commit('setCommentSaveStatus', {
        sectionIdentifier,
        stageName,
        status: 'error',
      });
      Vue.notify({
        title: 'Failed',
        text: 'Failed to save comment. Error: ' + err.message,
        type: 'error',
      });
    }
  },
  async resolveComment({ commit }, { projectId, libraryId, sectionIdentifier, stageName }) {
    try {
      commit('setCommentResolveStatus', {
        sectionIdentifier,
        stageName,
        status: 'in_progress',
      });
      const response = await apolloClient.mutate({
        mutation: RightSideContentQueries.resolveProjectLessonPlanSectionCommentQuery,
        variables: {
          lessonPlanLibraryId: libraryId || 0,
          projectId,
          sectionIdentifier,
          stageName,
        },
      });

      if (response && response.data && response.data.resolveProjectLessonPlanSectionComment) {
        commit('resolveComment', response.data.resolveProjectLessonPlanSectionComment);
        commit('setCommentResolveStatus', {
          sectionIdentifier,
          stageName,
          status: 'completed',
        });
        Vue.notify({
          title: 'Success',
          text: 'Successfully resolved the comment!',
          type: 'success',
        });
      } else {
        commit('setCommentResolveStatus', {
          sectionIdentifier,
          stageName,
          status: 'error',
        });
        Vue.notify({
          title: 'Failed',
          text: 'Failed to resolve the comment',
          type: 'error',
        });
      }
    } catch (err) {
      commit('setCommentResolveStatus', {
        sectionIdentifier,
        stageName,
        status: 'error',
      });
      logError(err);
      Vue.notify({
        title: 'Failed',
        text: 'Failed to resolve comment. Error: ' + err.message,
        type: 'error',
      });
    }
  },
  setRightSidePreviewContent({ commit }, value) {
    commit('setRightSidePreviewContent', value);
  },
  setQuestionRightSidePanel({ commit }, value) {
    commit('setQuestionRightSidePanel', value);
  },
  setGroupQuestionRightSidePanel({ commit }, value) {
    commit('setGroupQuestionRightSidePanel', value);
  },
  toggleRightSidePanel({ commit, state }) {
    commit('updateRightSidePanelVisiblity', !state.showRightSidePanel);
  },
  setRightSidePanelVisibility({ commit }, value) {
    commit('updateRightSidePanelVisiblity', value);
  },
};

const mutations = {
  setQuestionRightSidePanel(state, data) {
    state.setQuestionDiffPanel = data;
  },
  setGroupQuestionRightSidePanel(state, data) {
    state.setGroupQuestionDiffPanel = data;
  },
  SET_QUESTION_COMMENTS_LOADER(state, data) {
    state.questionCommentsLoader = data;
  },
  setProjectLessonPlanDiffFetchStatus(state, { status }) {
    state.fieldDiff.fetchStatus = status;

    if (status != API_FETCH_STATUS.COMPLETED) state.fieldDiff.data = {};
  },
  setProjectLessonPlanDiffData(state, { data }) {
    state.fieldDiff.data = data;
  },
  setCurrentDiffIdentifier(state, fieldIdentifier) {
    state.currentDiffIdentifier = fieldIdentifier;
  },
  setRightSidePreviewContent(state, value) {
    state.rightSidePreviewContent = value;
  },
  updateRightSidePanelVisiblity(state, value) {
    state.showRightSidePanel = value;
  },
  setDefaultDataForQuestionComment(state, sectionIdentifier) {
    Vue.set(state.commentsHash, sectionIdentifier, {
      fetchStatus: 'not_started',
      saveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      resolveStatusesHash: JSON.parse(JSON.stringify(DEFAULT_STATUS_HASH)),
      message: '',
      comments: {},
    });
  },
  setProjectLessonPlanSectionComment(state, { sectionIdentifier, comments }) {
    if (state.commentsHash[sectionIdentifier])
      state.commentsHash[sectionIdentifier].comments = comments;
  },
  setProjectLessonPlanSectionCommentFetchStatus(state, { sectionIdentifier, status, message }) {
    if (state.commentsHash[sectionIdentifier])
      state.commentsHash[sectionIdentifier].fetchStatus = status;
    if (state.commentsHash[sectionIdentifier])
      state.commentsHash[sectionIdentifier].message = message;
  },
  setCommentSaveStatus(state, { sectionIdentifier, stageName, status }) {
    state.commentsHash[sectionIdentifier].saveStatusesHash[stageName] = status;
  },
  saveComment(state, { sectionIdentifier, stageName, comments }) {
    state.commentsHash[sectionIdentifier].comments[stageName] = comments;
  },
  resolveComment(state, { sectionIdentifier, stageName, comments }) {
    state.commentsHash[sectionIdentifier].comments[stageName] = comments;
  },
  setCommentResolveStatus(state, { sectionIdentifier, stageName, status }) {
    state.commentsHash[sectionIdentifier].resolveStatusesHash[stageName] = status;
  },
};

export default {
  state,
  mutations,
  actions,
};
