import { SUBJECT_SELECTION_STATE } from './subjectSelection';
import { BLOCK_MAP_STATE } from './blockMap';
import { PROJECT_STATE } from './project';
import { AUTH_STATE } from './auth';
import { MICRO_TOPIC_STATE } from './micro_topic';
import { BLOCK_STATE } from './block';

export const state = {
  ...SUBJECT_SELECTION_STATE,
  ...BLOCK_MAP_STATE,
  ...PROJECT_STATE,
  ...AUTH_STATE,
  ...MICRO_TOPIC_STATE,
  ...BLOCK_STATE,
  isLoading: false,
  currentModule: 'School Program',
};
