<template>
  <div>
    <v-row dense class="item section">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Aim</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionSummary('aim')"
          fieldIdentifier="aim.summary"
          placeholder="Write Aim Summary"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="summaryData.aim"
          :onChange="(value) => textChangeHandler('aim', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="66"
        />
      </v-col>
    </v-row>
    <v-row dense class="item section">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Action</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionSummary('action')"
          fieldIdentifier="action.summary"
          placeholder="Write Action Summary"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="summaryData.action"
          :onChange="(value) => textChangeHandler('action', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="66"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      class="item section"
      v-if="lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE"
    >
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Analysis</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionSummary('analysis')"
          fieldIdentifier="analysis.summary"
          placeholder="Write Analysis Summary"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="summaryData.analysis"
          :onChange="(value) => textChangeHandler('analysis', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="66"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      class="item section"
      v-if="lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE"
    >
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Application</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionSummary('application')"
          fieldIdentifier="application.summary"
          placeholder="Classwork"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="applicationClassWorkSummary"
          :onChange="(value) => textChangeHandler('application.classwork', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="33"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      class="section"
      v-if="lessonPlanFormRendererType != LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE"
    >
      <v-col cols="3"></v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="false"
          placeholder="Homework"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="applicationHomeWorkSummary"
          :onChange="(value) => textChangeHandler('application.homework', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="33"
        />
      </v-col>
    </v-row>
    <v-row dense class="item section" v-if="canShowAMEGradeSummary()">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Assessment</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getSectionSummary('assessment')"
          fieldIdentifier="assessment.summary"
          placeholder="Approaching"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="assessmentApproachingSummaryText"
          :onChange="(value) => textChangeHandler('assessment.approaching', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="45"
        />
      </v-col>
    </v-row>
    <v-row dense class="section" v-if="canShowAMEGradeSummary()">
      <v-col cols="3"></v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="false"
          placeholder="Meeting"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="assessmentMeetingSummaryText"
          :onChange="(value) => textChangeHandler('assessment.meeting', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="45"
        />
      </v-col>
    </v-row>
    <v-row dense class="section" v-if="canShowAMEGradeSummary()">
      <v-col cols="3"></v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="false"
          placeholder="Exceeding"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="assessmentExceedingSummaryText"
          :onChange="(value) => textChangeHandler('assessment.exceeding', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="45"
        />
      </v-col>
    </v-row>
    <v-row dense class="item section" v-if="canShowOverallGradeSummary()">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Assessment</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <TextInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="getApplicationOverallSectionSummary()"
          fieldIdentifier="assessment.overallSummary"
          placeholder="Overall"
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :value="assessmentOverallSummaryText"
          :onChange="(value) => textChangeHandler('assessment.overallSummary', value)"
          :enableTextFormatting="true"
          :readonly="!isNotReadOnly"
          :counter="45"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import TextInput from '@/components/inputs/TextInput';
import Errors from '@/constants/Errors';
import {
  LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE,
  LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE,
  LP_FORM_RENDERER_TYPE_NORMAL
} from '@/constants/LpForm';

export default {
  name: 'SummarySection',
  components: {
    TextInput
  },
  computed: {
    assessmentApproachingSummaryText: function() {
      const assessmentSummaryArray = this.parseSummaryText(this.summaryData.assessment);

      return assessmentSummaryArray[0] || '';
    },
    assessmentMeetingSummaryText: function() {
      const assessmentSummaryArray = this.parseSummaryText(this.summaryData.assessment);

      return assessmentSummaryArray[1] || '';
    },
    assessmentExceedingSummaryText: function() {
      const assessmentSummaryArray = this.parseSummaryText(this.summaryData.assessment);

      return assessmentSummaryArray[2] || '';
    },
    assessmentOverallSummaryText: function() {
      return this.summaryData.assessmentOverall || '';
    },
    applicationClassWorkSummary: function() {
      const applicationSummaryArray = this.parseSummaryText(this.summaryData.application);

      return applicationSummaryArray[0] || '';
    },
    applicationHomeWorkSummary: function() {
      const applicationSummaryArray = this.parseSummaryText(this.summaryData.application);

      return applicationSummaryArray[1] || '';
    }
  },
  data() {
    return {
      AppTexts,
      LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE,
      Errors
    };
  },
  props: {
    summaryData: {
      type: Object,
      required: true
    },
    updateSummaryTextToStore: {
      type: Function,
      required: false,
      default: () => {}
    },
    diff: {
      type: Object,
      required: true
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true
    },
    lessonPlanFormRendererType: {
      type: String,
      required: false
    },
    isWorkSheetEnabled: {
      type: Boolean,
      required: false
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    getSectionSummary(section) {
      try {
        if (this.canShowDiffIcon) {
          return this.diff[section].summary.isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getApplicationOverallSectionSummary() {
      try {
        if (this.canShowDiffIcon) {
          return this.diff.assessment.overallSummary.isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    parseSummaryText(text) {
      let array = [];
      text.split('##').forEach((val, index) => {
        if (index != 0 && index % 2 === 0) {
          array.push(val.trim());
        }
      });

      return array;
    },
    canShowAMEGradeSummary() {
      return (
        this.lessonPlanFormRendererType == LP_FORM_RENDERER_TYPE_NORMAL ||
        (this.lessonPlanFormRendererType == LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE &&
          this.isWorkSheetEnabled == true)
      );
    },
    canShowOverallGradeSummary() {
      return (
        this.lessonPlanFormRendererType == LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE &&
        this.isWorkSheetEnabled == false
      );
    },
    textChangeHandler(key, text) {
      let updateText, updateKey;

      switch (key) {
        case 'aim':
          updateKey = key;
          updateText = text;
          break;
        case 'action':
          updateKey = key;
          updateText = text;
          break;
        case 'analysis':
          updateKey = key;
          updateText = text;
          break;
        case 'application.classwork':
          updateKey = 'application';
          updateText = `##Classwork:## ${text} ##Homework:## ${this.applicationHomeWorkSummary}`;
          break;
        case 'application.homework':
          updateKey = 'application';
          updateText = `##Classwork:## ${this.applicationClassWorkSummary} ##Homework:## ${text}`;
          break;
        case 'assessment.approaching':
          updateKey = 'assessment';
          updateText = `##A:## ${text} ##M:## ${this.assessmentMeetingSummaryText} ##E:## ${this.assessmentExceedingSummaryText}`;
          break;
        case 'assessment.meeting':
          updateKey = 'assessment';
          updateText = `##A:## ${this.assessmentApproachingSummaryText} ##M:## ${text} ##E:## ${this.assessmentExceedingSummaryText}`;
          break;
        case 'assessment.exceeding':
          updateKey = 'assessment';
          updateText = `##A:## ${this.assessmentApproachingSummaryText} ##M:## ${this.assessmentMeetingSummaryText} ##E:## ${text}`;
          break;
        case 'assessment.overallSummary':
          updateKey = key;
          updateText = text;
          break;
      }

      this.updateSummaryTextToStore({ key: updateKey, text: updateText });
    }
  }
};
</script>
<style scoped>
.pad-Ts {
  padding-top: 10px;
}
.summary-section-pentagon {
  text-align: center;
  text-transform: uppercase;
  color: #242424;
  font-size: 20px;
}
.bubble {
  position: relative;
  width: 140px;
  height: 50px;
  padding: 0px;
  background: #b5e9e4;
}

.bubble:after {
  content: '';
  position: absolute;
  top: 12px;
  right: -37px;
  transform: rotate(-90deg);
  border-style: solid;
  border-width: 25px 25px 0;
  border-color: #b5e9e4 transparent;
  display: block;
  width: 0;
  z-index: 1;
}
.bubble .v-label {
  top: 10px;
  font-size: 20px;
  color: #242424;
  padding: 0px 6px;
}
.row.item {
  margin-top: 10px;
}
.section {
  padding: 0px;
}
</style>
