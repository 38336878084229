<template>
  <v-form ref="referenceSectionForm" class="section-title-cnr steps-cnr mgn-Bs">
    <BlockTitleWithSubtitle title="Reference" />
    <div class="mgn-Ts" v-for="(reference, index) in references" :key="index" :v-bind="index">
      <v-row dense>
        <v-col cols="11">
          <TextInput
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="isChanged(index)"
            :fieldIdentifier="'references[' + index + ']'"
            :label="AppTexts.REFERENCE_TEXT"
            v-model="references[index]"
            :placeholder="'Reference ' + (index + 1)"
            customClass=""
            :readonly="!isNotReadOnly"
            :counter="300"
          />
        </v-col>
        <v-col cols="1" v-if="isNotReadOnly">
          <v-btn icon @click="deleteReference(index)">
            <img height="16" width="16" src="@/assets/close.svg" />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-btn
      class="xs-accent-button"
      @click="addReference"
      :class="!canAddReference() || !isNotReadOnly ? 'disable-btn-click-event' : ''"
    >
      {{ AppTexts.ADD_REFERENCE }}
    </v-btn>
    <span class="mgn-Lm remaining-step-warning-text" v-if="canAddReference()">
      {{ getStepAdditionValidationText(referenceMaxLength - references.length, 'reference') }}
    </span>
  </v-form>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import { getStepAdditionValidationText } from '@/utils';
import BlockTitleWithSubtitle from '@/components/BlockTitleWithSubtitle';
import TextInput from '@/components/inputs/TextInput';

export default {
  name: 'ReferencesSection',
  components: {
    BlockTitleWithSubtitle,
    TextInput
  },
  data() {
    return {
      referenceMaxLength: 4,
      AppTexts
    };
  },
  methods: {
    getStepAdditionValidationText,
    canAddReference() {
      return this.references.length < this.referenceMaxLength;
    },
    isChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return this.referenceDiff[index].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    }
  },
  props: {
    deleteReference: {
      type: Function,
      required: true
    },
    addReference: {
      type: Function,
      required: true
    },
    references: {
      type: Array,
      required: true
    },
    referenceDiff: {
      type: Array,
      required: true
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>
