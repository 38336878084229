<template>
  <v-form v-if="lessonPlanData.analysis" ref="analyseSectionForm" class="section-title-cnr">
    <Step
      :items="lessonPlanData.analysis.instructions || []"
      :canShowStepNumber="false"
      :canShowInstructionBreakCheckbox="true"
      :hasSubStep="false"
      :maxNoOfSteps="schema.instructionsMaxNoOfSteps"
      :updateInstructionBreakValueOfStep="updateInstructionBreakValueOfStep"
      :maxNoOfAssetsPerStep="schema.instructionsMaxNoOfAssetsPerStep"
      :supportAssets="true"
      :descriptionPlaceholder="AppTexts.DESCRIPTION"
      :canShowAddNewStepButton="true"
      :allowDeleteStep="true"
      :deleteStepHandler="deleteAnalysisActivityDiscussionStep"
      :canShowDiffIcon="canShowDiffIcon"
      stepAssetIdentifier="analysis.instructions"
      stepDiffIdentifier="analysis.instructions[<stepIndex>].description"
      assetBriefDiffIdentifier="analysis.instructions[<stepIndex>].assets[<assetIndex>].briefAssetId"
      assetImageDiffIdentifierPrefix="analysis.instructions[<stepIndex>].assets[<assetIndex>]"
      :addNewStepHandler="addAnalysiDiscussionStep"
      :isStepDescriptionChanged="isADStepDescriptionChanged"
      :isNotReadOnly="isNotReadOnly"
      :getPreviewUrl="getAnalysisAssetPreviewUrl"
      :showImageBrief="true"
      class="analysis-explanation-step"
      :canFinalizeAsset="true"
      :canAppproveAsset="canAppproveAsset"
      :isAssetUploadRequired="isAssetUploadRequired"
      :canShowInstructionTeacherTip="false"
      :canShowLastInstructionTeacherTip="false"
    />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiffChanged('main_section', 'key_question')"
      fieldIdentifier="analysis.main_section.key_question"
      :placeholder="AppTexts.KEY_QUESTION"
      v-model="lessonPlanData.analysis.main_section.key_question"
      :showValidationError="true"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <br />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiffChanged('main_section', 'explanation')"
      fieldIdentifier="analysis.main_section.explanation"
      :placeholder="AppTexts.EXPLANATION_TEXT"
      v-model="lessonPlanData.analysis.main_section.explanation"
      :showValidationError="true"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <div style="padding: 5px 0px;" v-if="schema.showReadXSEEDContentBookCheckbox">
      <v-checkbox
        color="success"
        style="width: 250px; height: 40px;"
        :class="lessonPlanData.analysis.readXSEEDContentBook ? 'selected-box' : 'not-selected-box'"
        :background-color="lessonPlanData.analysis.readXSEEDContentBook ? '#37B074' : '#D9D9D9'"
        v-model="lessonPlanData.analysis.readXSEEDContentBook"
      >
        <template v-slot:label>
          <div
            :style="lessonPlanData.analysis.readXSEEDContentBook ? 'color: white' : 'color: black'"
            class="pt-2 pb-2 mb-0"
          >
            XSEED Content Book
          </div>
        </template>
      </v-checkbox>
      <div class="py-5" v-if="lessonPlanData.analysis.readXSEEDContentBook">
        <RichTextEditor
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged('xseed_content_book', 'review_instruction')"
          fieldIdentifier="analysis.xseed_content_book.review_instruction"
          :placeholder="AppTexts.REVIEW_INSTRUCTION"
          v-model="lessonPlanData.analysis.xseed_content_book.review_instruction"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
        <BlockTitle title="Content Book" />
        <FileInput
          :image_url="
            isAssetUploadRequired
              ? lessonPlanData.analysis.xseed_content_book.content_book.assetId
              : ''
          "
          :image_url_brief="lessonPlanData.analysis.xseed_content_book.content_book.brief.assetId"
          :previewUrl="
            isAssetUploadRequired && analysisSignedUrls
              ? analysisSignedUrls.xseed_content_book.content_book.assetSignedUrl
              : ''
          "
          :previewUrlBrief="analysisSignedUrls.xseed_content_book.content_book.brief.assetSignedUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isPDFchanged(`xseed_content_book.content_book.${imageKey}`)"
          :diffIdentifier="`analysis.xseed_content_book.content_book.${imageKey}`"
          label="Select Asset"
          :loadingStatus="
            uploadingStatus[assetTypeMapping.content_book] == FILE_UPLOADING_STATUS.IN_PROGRESS
          "
          :fileChangeHandler="
            (file) => fileChangeHandler(file, 'xseed_content_book', 'content_book')
          "
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="true"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="lessonPlanData.analysis.xseed_content_book.content_book.assetApproved"
          :isRequired="false"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              addBriefToAsset(previewUrl, briefUrl, 'content_book', isAssetApproved)
          "
        />
        <RichTextEditor
          class="mgn-Tm"
          v-if="showImageBrief"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isImageBriefDiffChanged(`xseed_content_book.content_book.brief.text`)"
          fieldIdentifier="analysis.xseed_content_book.content_book.brief.text"
          v-model="lessonPlanData.analysis.xseed_content_book.content_book.brief.text"
          :showValidationError="true"
          placeholder="Content Book Brief"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </div>
    </div>
    <BlockTitle :title="AppTexts.QUESTIONS_TO_GUIDE_DISCUSSION" />
    <div>
      <div v-for="(question, index) in questions" :key="index" :v-bind="index">
        <v-row>
          <v-col cols="6">
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isQTGDiffChanged('question', index)"
              :fieldIdentifier="
                'analysis.questions_to_guide_discussion.questions[' + index + '].question'
              "
              :placeholder="`${AppTexts.QUESTION_NUMBER} ${index + 1}`"
              v-model="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index].question
              "
              :readonly="!isNotReadOnly"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </v-col>
          <v-col cols="5">
            <RichTextEditor
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isQTGDiffChanged('question', index)"
              :fieldIdentifier="
                'analysis.questions_to_guide_discussion.questions[' + index + '].explanation'
              "
              :placeholder="`${AppTexts.EXPLANATION_TEXT} ${index + 1}`"
              v-model="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index].explanation
              "
              :readonly="!isNotReadOnly"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </v-col>
          <v-col cols="1">
            <v-btn icon @click="deleteQuestion(index)" v-if="isNotReadOnly">
              <img height="16" width="16" src="@/assets/close.svg" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <FileInput
              :image_url="
                isAssetUploadRequired
                  ? lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                      .question_image.assetId
                  : ''
              "
              :image_url_brief="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                  .question_image.brief.assetId
              "
              :previewUrl="
                isAssetUploadRequired && analysisSignedUrls
                  ? analysisSignedUrls.questions_to_guide_discussion.questions[index].question_image
                      .assetSignedUrl
                  : ''
              "
              :previewUrlBrief="
                analysisSignedUrls.questions_to_guide_discussion.questions[index].question_image
                  .brief.assetSignedUrl
              "
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="
                isPDFchanged(
                  `questions_to_guide_discussion.questions.${index}.question_image.${imageKey}`
                )
              "
              :diffIdentifier="`analysis.questions_to_guide_discussion.questions.${index}.question_image.${imageKey}`"
              label="Select Asset"
              :loadingStatus="
                uploadingStatus[
                  `analysis.questions_to_guide_discussion.questions.${index}.question_image`
                ] == FILE_UPLOADING_STATUS.IN_PROGRESS
              "
              :fileChangeHandler="
                (file) => questionsFileChangeHandler(file, 'question_image', index)
              "
              :showImageBrief="showImageBrief"
              :isAssetUploadRequired="isAssetUploadRequired"
              :fileAcceptType="acceptableFileType"
              :isNotReadOnly="isNotReadOnly"
              :fileValidators="fileValidators"
              :canFinalizeAsset="true"
              :canAppproveAsset="canAppproveAsset"
              :isAssetApproved="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                  .question_image.assetApproved
              "
              :isRequired="false"
              @addBriefToAsset="
                (previewUrl, briefUrl, isAssetApproved) =>
                  questionsAddBriefToAsset(
                    previewUrl,
                    briefUrl,
                    'question_image',
                    index,
                    isAssetApproved
                  )
              "
            />
            <RichTextEditor
              class="mgn-Tm"
              v-if="showImageBrief"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="
                isImageBriefDiffChanged(
                  `questions_to_guide_discussion.questions.${index}.question_image.brief.text`
                )
              "
              :fieldIdentifier="`analysis.questions_to_guide_discussion.questions.${index}.question_image.brief.text`"
              v-model="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                  .question_image.brief.text
              "
              :showValidationError="true"
              :placeholder="`${AppTexts.QUESTION_NUMBER} ${index + 1} brief`"
              :counter="3000"
              :readonly="!isNotReadOnly"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </v-col>
          <v-col cols="6">
            <FileInput
              :image_url="
                isAssetUploadRequired
                  ? lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                      .explanation_image.assetId
                  : ''
              "
              :image_url_brief="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                  .explanation_image.brief.assetId
              "
              :previewUrl="
                isAssetUploadRequired && analysisSignedUrls
                  ? analysisSignedUrls.questions_to_guide_discussion.questions[index]
                      .explanation_image.assetSignedUrl
                  : ''
              "
              :previewUrlBrief="
                analysisSignedUrls.questions_to_guide_discussion.questions[index].explanation_image
                  .brief.assetSignedUrl
              "
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="
                isPDFchanged(
                  `questions_to_guide_discussion.questions.${index}.explanation_image.${imageKey}`
                )
              "
              :diffIdentifier="`analysis.questions_to_guide_discussion.questions.${index}.explanation_image.${imageKey}`"
              label="Select Asset"
              :loadingStatus="
                uploadingStatus[
                  `analysis.questions_to_guide_discussion.questions.${index}.explanation_image`
                ] == FILE_UPLOADING_STATUS.IN_PROGRESS
              "
              :fileChangeHandler="
                (file) => questionsFileChangeHandler(file, 'explanation_image', index)
              "
              :showImageBrief="showImageBrief"
              :isAssetUploadRequired="isAssetUploadRequired"
              :fileAcceptType="acceptableFileType"
              :isNotReadOnly="isNotReadOnly"
              :fileValidators="fileValidators"
              :canFinalizeAsset="true"
              :canAppproveAsset="canAppproveAsset"
              :isAssetApproved="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                  .explanation_image.assetApproved
              "
              :isRequired="false"
              @addBriefToAsset="
                (previewUrl, briefUrl, isAssetApproved) =>
                  questionsAddBriefToAsset(
                    previewUrl,
                    briefUrl,
                    'explanation_image',
                    index,
                    isAssetApproved
                  )
              "
            />
            <RichTextEditor
              class="mgn-Tm"
              v-if="showImageBrief"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="
                isImageBriefDiffChanged(
                  `questions_to_guide_discussion.questions.${index}.explanation_image.brief.text`
                )
              "
              :fieldIdentifier="`analysis.questions_to_guide_discussion.questions.${index}.explanation_image.brief.text`"
              v-model="
                lessonPlanData.analysis.questions_to_guide_discussion.questions[index]
                  .explanation_image.brief.text
              "
              :showValidationError="true"
              :placeholder="`${AppTexts.EXPLANATION_TEXT} ${index + 1} brief`"
              :counter="3000"
              :readonly="!isNotReadOnly"
              :includeOnlyToolBarActions="BASIC_TOOLS"
            />
          </v-col>
        </v-row>
      </div>
      <v-btn class="xs-accent-button mgn-Tm" @click="() => addQuestion()">{{
        AppTexts.ADD_QUESTION
      }}</v-btn>
    </div>
    <FileInput
      :key="boardworkKey + 'boardwork_image_primary'"
      :image_url="
        isAssetUploadRequired
          ? lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_primary.assetId
          : ''
      "
      :image_url_brief="
        lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_primary.brief.assetId
      "
      :previewUrl="
        isAssetUploadRequired && analysisSignedUrls
          ? analysisSignedUrls.questions_to_guide_discussion.boardwork_image_primary.assetSignedUrl
          : ''
      "
      :previewUrlBrief="
        analysisSignedUrls.questions_to_guide_discussion.boardwork_image_primary.brief
          .assetSignedUrl
      "
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="
        isPDFchanged('questions_to_guide_discussion', 'boardwork_image_primary', imageKey)
      "
      :diffIdentifier="`analysis.questions_to_guide_discussion.boardwork_image_primary.${imageKey}`"
      label="Select Asset"
      :loadingStatus="
        uploadingStatus[assetTypeMapping.boardwork_image_primary] ==
        FILE_UPLOADING_STATUS.IN_PROGRESS
      "
      :fileChangeHandler="
        (file) =>
          fileChangeHandler(file, 'questions_to_guide_discussion', 'boardwork_image_primary')
      "
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :fileAcceptType="acceptableFileType"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="true"
      :canAppproveAsset="canAppproveAsset"
      :isAssetApproved="
        lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_primary.assetApproved
      "
      :isRequired="false"
      @addBriefToAsset="
        (previewUrl, briefUrl, isAssetApproved) =>
          addBriefToAsset(previewUrl, briefUrl, 'boardwork_image_primary', isAssetApproved)
      "
    />
    <RichTextEditor
      class="mgn-Tm"
      v-if="showImageBrief"
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="
        isImageBriefDiffChanged(`questions_to_guide_discussion.boardwork_image_primary.brief.text`)
      "
      :fieldIdentifier="`analysis.questions_to_guide_discussion.boardwork_image_primary.brief.text`"
      v-model="
        lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_primary.brief.text
      "
      :showValidationError="true"
      placeholder="primary image Brief"
      :counter="3000"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <FileInput
      :key="boardworkKey + 'boardwork_image_secondary'"
      :image_url="
        isAssetUploadRequired
          ? lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_secondary.assetId
          : ''
      "
      :image_url_brief="
        lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_secondary.brief
          .assetId
      "
      :previewUrl="
        isAssetUploadRequired && analysisSignedUrls
          ? analysisSignedUrls.questions_to_guide_discussion.boardwork_image_secondary
              .assetSignedUrl
          : ''
      "
      :previewUrlBrief="
        analysisSignedUrls.questions_to_guide_discussion.boardwork_image_secondary.brief
          .assetSignedUrl
      "
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="
        isPDFchanged('questions_to_guide_discussion', 'boardwork_image_secondary', imageKey)
      "
      :diffIdentifier="`analysis.questions_to_guide_discussion.boardwork_image_secondary.${imageKey}`"
      label="Select Asset"
      :loadingStatus="
        uploadingStatus[assetTypeMapping.boardwork_image_secondary] ==
        FILE_UPLOADING_STATUS.IN_PROGRESS
      "
      :fileChangeHandler="
        (file) =>
          fileChangeHandler(file, 'questions_to_guide_discussion', 'boardwork_image_secondary')
      "
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :fileAcceptType="acceptableFileType"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="true"
      :canAppproveAsset="canAppproveAsset"
      :isAssetApproved="
        lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_secondary
          .assetApproved
      "
      :isRequired="false"
      @addBriefToAsset="
        (previewUrl, briefUrl, isAssetApproved) =>
          addBriefToAsset(previewUrl, briefUrl, 'boardwork_image_secondary', isAssetApproved)
      "
    />
    <RichTextEditor
      class="mgn-Tm"
      v-if="showImageBrief"
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="
        isImageBriefDiffChanged(
          `questions_to_guide_discussion.boardwork_image_secondary.brief.text`
        )
      "
      :fieldIdentifier="`analysis.questions_to_guide_discussion.boardwork_image_secondary.brief.text`"
      v-model="
        lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_secondary.brief.text
      "
      :showValidationError="true"
      placeholder="secondary image brief"
      :counter="3000"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <BlockTitle :title="AppTexts.TEACHER_TIP" />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isDiffChanged('questions_to_guide_discussion', 'teacher_tip')"
      fieldIdentifier="analysis.questions_to_guide_discussion.teacher_tip"
      :placeholder="AppTexts.TEACHER_TIP"
      v-model="lessonPlanData.analysis.questions_to_guide_discussion.teacher_tip"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import Step from '@/components/inputs/Step';
import AppTexts from '@/constants/AppTexts';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import Step from '@/components/inputs/Step';
import Errors from '@/constants/Errors';
import { ANALYSIS_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan.js';
import { getFileNameFromUrl } from '@/functions/global';
import AssetTypes from '@/constants/AssetTypes';
import FileInput from '@/components/inputs/FileInput';
import { LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE } from '@/constants/LpForm';
import BlockTitle from '@/components/inputs/BlockTitle';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import _ from 'lodash';

export default {
  name: 'AnalyseSectionForDiscussionLpType',
  components: {
    RichTextEditor,
    FileInput,
    BlockTitle,
    Step,
  },
  data() {
    return {
      AppTexts,
      boardworkKey: 0,
      LP_FORM_RENDERER_TYPE_SCIENCE_MATH_ENGLISH_TYPE,
      AssetTypes,
      Errors,
      ANALYSIS_BLOCK_KEY_OF_STATE_LP_DATA,
      FILE_UPLOADING_STATUS,
      imageKey: this.isAssetUploadRequired ? 'assetId' : 'brief.assetId',
      assetTypeMapping: {
        content_book: AssetTypes.DISCUSSION_ANALYSIS_CONTENT_BOOK_PDF,
        boardwork_image_primary: AssetTypes.DISCUSSION_ANALYSIS_BOARDWORK_IMAGE_PRIMARY,
        boardwork_image_secondary: AssetTypes.DISCUSSION_ANALYSIS_BOARDWORK_IMAGE_SECONDARY,
        question_image: AssetTypes.DISCUSSION_ANALYSIS_QUESTION_IMAGE_ASSET,
        explanation_image: AssetTypes.DISCUSSION_ANALYSIS_QUESTION_EXPLANATION_IMAGE_ASSET,
      },
      BASIC_TOOLS,
    };
  },
  created() {
    let imageSchema = {
      assetId: '',
      brief: {
        assetId: '',
        text: '',
      },
      assetApproved: false,
    };
    let signedUrlSchema = {
      assetSignedUrl: '',
      brief: {
        assetSignedUrl: '',
      },
    };
    if (
      this.lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_primary ==
      undefined
    ) {
      this.$set(
        this.lessonPlanData.analysis.questions_to_guide_discussion,
        'boardwork_image_primary',
        imageSchema
      );
      this.boardworkKey += 1;
    }
    if (
      this.lessonPlanData.analysis.questions_to_guide_discussion.boardwork_image_secondary ==
      undefined
    ) {
      this.$set(
        this.lessonPlanData.analysis.questions_to_guide_discussion,
        'boardwork_image_secondary',
        imageSchema
      );
      this.boardworkKey += 1;
    }
    if (
      this.analysisSignedUrls.questions_to_guide_discussion.boardwork_image_secondary == undefined
    ) {
      this.$set(
        this.analysisSignedUrls.questions_to_guide_discussion,
        'boardwork_image_secondary',
        signedUrlSchema
      );
      this.boardworkKey += 1;
    }
    if (
      this.analysisSignedUrls.questions_to_guide_discussion.boardwork_image_primary == undefined
    ) {
      this.$set(
        this.analysisSignedUrls.questions_to_guide_discussion,
        'boardwork_image_primary',
        signedUrlSchema
      );
      this.boardworkKey += 1;
    }
  },
  methods: {
    ...mapActions([
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'copySingleAssetAction',
      'addQuestion',
      'deleteQuestion',
    ]),
    getFileNameFromUrl,
    isDiffChanged(entity, attribute) {
      if (this.analysisDiff && this.analysisDiff[entity]) {
        try {
          return this.analysisDiff[entity][attribute].isChanged == true;
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    isPDFchanged(entity, key, attribute) {
      if (this.analysisDiff && this.analysisDiff[entity] && this.analysisDiff[key]) {
        try {
          let assetChanged = _.get(this.analysisDiff, `${entity}.${key}.${attribute}.isChanged`);
          let briefAssetChanged = _.get(
            this.analysisDiff,
            `${entity}.${key}.${attribute}.brief.isChanged`
          );
          return assetChanged == true || briefAssetChanged == true;
        } catch (error) {
          return false;
        }
      }
    },
    isQTGDiffChanged(entity, index) {
      if (this.analysisDiff && this.analysisDiff[entity]) {
        try {
          if (this.canShowDiffIcon) {
            return (
              this.analysisDiff.questions_to_guide_discussion.questions[index][entity].isChanged ==
              true
            );
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    isImageBriefDiffChanged(entity) {
      if (this.analysisDiff) {
        const diff = _.get(this.analysisDiff, entity);
        if (diff) {
          return diff.isChanged;
        }
      }
    },
    fileChangeHandler(file, key, entity) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: this.assetTypeMapping[entity],
        });
      } else {
        this.deleteSingleAssetAction({
          assetId: _.get(this.lessonPlanData, `analysis.${key}.${entity}.${this.imageKey}`),
          assetType: this.assetTypeMapping[entity],
          fieldIdentifier: `analysis.${key}.${entity}.${this.imageKey}`,
        });
      }
    },
    questionsFileChangeHandler(file, entity, index) {
      let assetType;
      if (['question_image', 'explanation_image'].includes(entity)) {
        // Do nothing
      } else {
        return;
      }

      if (entity == 'question_image') {
        assetType = AssetTypes.DISCUSSION_ANALYSIS_QUESTION_IMAGE_ASSET;
      } else {
        assetType = AssetTypes.DISCUSSION_ANALYSIS_QUESTION_EXPLANATION_IMAGE_ASSET;
      }

      if (file) {
        this.importSingleAssetAction({
          file,
          assetType,
          index,
        });
      } else {
        this.deleteSingleAssetAction({
          assetId: _.get(
            this.lessonPlanData,
            `analysis.questions_to_guide_discussion.questions.${index}.${entity}.${this.imageKey}`
          ),
          assetType,
          index,
          fieldIdentifier: `analysis.questions_to_guide_discussion.questions.${index}.${entity}.${this.imageKey}`,
        });
      }
    },
    addBriefToAsset(previewUrl, briefUrl, entity, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: this.assetTypeMapping[entity],
        isAssetApproved,
      });
    },
    questionsAddBriefToAsset(previewUrl, briefUrl, entity, index, isAssetApproved) {
      if (['question_image', 'explanation_image'].includes(entity)) {
        // Do nothing
      } else {
        return;
      }

      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: this.assetTypeMapping[entity],
        isAssetApproved,
        index,
      });
    },
    isADStepDescriptionChanged(stepIndex) {
      try {
        if (this.canShowDiffIcon) {
          return this.analysisDiff.instructions[stepIndex].isChanged == true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    getAnalysisAssetPreviewUrl(stepIndex, assetIndex) {
      let previewUrl = {
        assetSignedBriefUrl: '',
        assetSignedUrl: '',
        loadingStatus: 'not_Started',
      };
      if (this.analysisSignedUrls) {
        let instruction = this.analysisSignedUrls.instructions;
        if (instruction) {
          instruction = instruction[stepIndex];
        }
        if (instruction) {
          let asset = instruction.assets[assetIndex];
          if (asset) {
            if (this.isAssetUploadRequired) {
              previewUrl.assetSignedUrl = asset.assetSignedUrl;
            }
            previewUrl.assetSignedBriefUrl = asset.brief.assetSignedUrl;
            previewUrl.loadingStatus = asset.loadingStatus;
          }
        }
      }

      return previewUrl;
    },
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    analysisSignedUrls: {
      type: Object,
      required: true,
    },
    analysisDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    schema: {
      type: Object,
      required: true,
    },
    canAppproveAsset: { type: Boolean, required: true },
    deleteAnalysisActivityDiscussionStep: {
      type: Function,
      required: true,
    },
    addAnalysiDiscussionStep: {
      type: Function,
      required: true,
    },
    updateInstructionBreakValueOfStep: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState({
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      analysisBockStaticTexts: (state) => state.lessonPlan.staticTexts.analysis,
    }),
    questions() {
      if (
        this.lessonPlanData.analysis &&
        this.lessonPlanData.analysis.questions_to_guide_discussion
      ) {
        return this.lessonPlanData.analysis.questions_to_guide_discussion.questions;
      }
      return [];
    },
  },
};
</script>
