<template>
  <div>
    <v-form ref="applicationSectionForm" class="section-title-cnr">
      <section v-if="data.classwork">
        <QuesstionLessonIndividualSection
          sectionName="Classwork"
          :data="data"
          sectionKey="classwork"
          :canShowDiffIcon="canShowDiffIcon"
          :isReadOnly="isReadOnly"
          :showLoadingForQuestionDataList="showLoadingForQuestionDataList"
          :questionData="sectionQuestionData('classwork')"
          :removeApplicationSectionQuestion="removeApplicationSectionQuestion"
          :addApplicationSectionQuestion="addApplicationSectionQuestion"
          :maxItemsCanAdd="remainingSectionQuestionCount('classwork')"
          :canAddNewItem="canAddSectionQuestion('classwork')"
          :teacherTipMaxLength="schema.teacherTipMaxLength"
          :getSectionIsChangedValue="getSectionIsChangedValue"
          :prefixInstructions="(applicationStaticTexts.classwork || {}).prefixInstructions"
          :lpType="lpType"
          :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
          :uniqueQuestionsCount="sectionUniqueQuestionsCount('classwork')"
        />
      </section>
      <section v-if="data.homework">
        <QuesstionLessonIndividualSection
          sectionName="Homework"
          :data="data"
          sectionKey="homework"
          :canShowDiffIcon="canShowDiffIcon"
          :isReadOnly="isReadOnly"
          :showLoadingForQuestionDataList="showLoadingForQuestionDataList"
          :questionData="sectionQuestionData('homework')"
          :removeApplicationSectionQuestion="removeApplicationSectionQuestion"
          :addApplicationSectionQuestion="addApplicationSectionQuestion"
          :maxItemsCanAdd="remainingSectionQuestionCount('homework')"
          :canAddNewItem="canAddSectionQuestion('homework')"
          :teacherTipMaxLength="schema.teacherTipMaxLength"
          :getSectionIsChangedValue="getSectionIsChangedValue"
          :lpType="lpType"
          :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
          :uniqueQuestionsCount="sectionUniqueQuestionsCount('homework')"
        />
      </section>
      <section v-if="data.mental_math">
        <QuesstionLessonIndividualSection
          sectionName="Mental Math"
          :data="data"
          sectionKey="mental_math"
          :canShowDiffIcon="canShowDiffIcon"
          :isReadOnly="isReadOnly"
          :showLoadingForQuestionDataList="showLoadingForQuestionDataList"
          :questionData="sectionQuestionData('mental_math')"
          :removeApplicationSectionQuestion="removeApplicationSectionQuestion"
          :addApplicationSectionQuestion="addApplicationSectionQuestion"
          :maxItemsCanAdd="remainingSectionQuestionCount('mental_math')"
          :canAddNewItem="canAddSectionQuestion('mental_math')"
          :teacherTipMaxLength="schema.teacherTipMaxLength"
          :getSectionIsChangedValue="getSectionIsChangedValue"
          :lpType="VIRTUAL_QUESTION_PURPOSE_MENTAL_MATH"
          :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
          :uniqueQuestionsCount="sectionUniqueQuestionsCount('mental_math')"
        />
      </section>
      <section v-if="data.problem_solving">
        <QuesstionLessonIndividualSection
          sectionName="Problem Solving"
          :data="data"
          sectionKey="problem_solving"
          :canShowDiffIcon="canShowDiffIcon"
          :isReadOnly="isReadOnly"
          :showLoadingForQuestionDataList="showLoadingForQuestionDataList"
          :questionData="sectionQuestionData('problem_solving')"
          :removeApplicationSectionQuestion="removeApplicationSectionQuestion"
          :addApplicationSectionQuestion="addApplicationSectionQuestion"
          :maxItemsCanAdd="remainingSectionQuestionCount('problem_solving')"
          :canAddNewItem="canAddSectionQuestion('problem_solving')"
          :teacherTipMaxLength="schema.teacherTipMaxLength"
          :getSectionIsChangedValue="getSectionIsChangedValue"
          :lpType="VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE"
          :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
          :uniqueQuestionsCount="sectionUniqueQuestionsCount('problem_solving')"
        />
      </section>
    </v-form>
  </div>
</template>

<script>
import QuesstionLessonIndividualSection from '@/components/Questions/QuesstionLessonIndividualSection';
import {
  VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE,
  VIRTUAL_QUESTION_PURPOSE_MENTAL_MATH,
} from "@/constants/question.js";

export default {
  name: 'QuestionApplicationSection',
  components: {
    QuesstionLessonIndividualSection,
  },
  data() {
    return {
      VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE,
      VIRTUAL_QUESTION_PURPOSE_MENTAL_MATH,

    };
  },
  computed: {
    lpType() {
      return this.$route.query.lpType;
    },
  },
  methods: {
    getSectionIsChangedValue(section, type) {
      try {
        if (this.canShowDiffIcon) {
          return this.applicationDiff[section][type].isChanged;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    sectionQuestionData(section) {
      const questionIds = this.data[section].questions;

      const questionData = this.questionDataForList.filter((question) =>
        questionIds.includes(question._id)
      );
      return questionData;
    },
    canAddSectionQuestion(section) {
      try {
        const sectionMaxLengthMapping = {
          classwork: this.schema.maxClassworkQuestionsLength,
          homework: this.schema.maxHomeworkQuestionsLength,
          mental_math: this.schema.maxMentalMathQuestionsLength,
          problem_solving: this.schema.maxProblemSolvingQuestionsLength,
        };

        return sectionMaxLengthMapping[section] > this.sectionUniqueQuestionsCount(section);
      } catch (er) {
        return false;
      }
    },
    remainingSectionQuestionCount(section) {
      try {
        const sectionMaxLengthMapping = {
          classwork: this.schema.maxClassworkQuestionsLength,
          homework: this.schema.maxHomeworkQuestionsLength,
          mental_math: this.schema.maxMentalMathQuestionsLength,
          problem_solving: this.schema.maxProblemSolvingQuestionsLength,
        };

        return sectionMaxLengthMapping[section] - this.sectionUniqueQuestionsCount(section);
      } catch (error) {
        return 0;
      }
    },
    sectionUniqueQuestionsCount(section) {
      const questionData = this.sectionQuestionData(section);
      const ids = questionData.map((element) => {
        return element.questionGroupId || element._id;
      });
      const uniqueQuestionIds = [...new Set(ids)];

      return uniqueQuestionIds.length;
    },
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    addApplicationSectionQuestion: {
      type: Function,
      required: true,
    },
    removeApplicationSectionQuestion: {
      type: Function,
      required: true,
    },
    applicationDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    showLoadingForQuestionDataList: {
      type: Boolean,
      required: true,
    },
    questionDataForList: {
      type: Array,
      required: true,
    },
    applicationStaticTexts: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    currentProjectQuestionLinkableLessonPlans: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.question-details-loading-indicator {
  font-size: 17px;
  padding: 20px;
}
</style>
