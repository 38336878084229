export const WRITING_LINES_HASH = {
  min: 0,
  max: 20,
};
export const DRAWING_BOX_OPTION_NONE = 'none';
export const DRAWING_BOX_OPTION_HALF = '1/2';
export const DRAWING_BOX_OPTION_ONE_FOURTH = '1/4';
export const DRAWING_BOX_OPTIONS = [
  { label: 'None', value: DRAWING_BOX_OPTION_NONE },
  { label: '1/4 page', value: DRAWING_BOX_OPTION_ONE_FOURTH },
  { label: '1/2 page', value: DRAWING_BOX_OPTION_HALF },
];

const QUESTION_PURPOSE_MICRO_TEST = 'microtest';
const QUESTION_PURPOSE_PRACTICE = 'practice';

export const VIRTUAL_QUESTION_PURPOSE_AT_HOME_PRACTICE = 'at_home_practice';
export const VIRTUAL_QUESTION_PURPOSE_GRAMMAR = 'grammar';
export const VIRTUAL_QUESTION_PURPOSE_WORKSHEET = 'worksheet';
export const VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE = 'in_class_practice';
export const VIRTUAL_QUESTION_PURPOSE_REVISION = 'revision';
export const VIRTUAL_QUESTION_PURPOSE_ASSESSMENT = 'assessment';
export const VIRTUAL_QUESTION_PURPOSE_MENTAL_MATH = 'mental_math';

export const VIRTUAL_QUESTION_PURPOSE_DISPLAY_TEXT_MAPPING = {
  // Virtual Questions
  [VIRTUAL_QUESTION_PURPOSE_AT_HOME_PRACTICE]: 'At Home Practice Question',
  [VIRTUAL_QUESTION_PURPOSE_GRAMMAR]: 'Grammar',
  [VIRTUAL_QUESTION_PURPOSE_WORKSHEET]: 'Worksheet',
  [VIRTUAL_QUESTION_PURPOSE_IN_CLASS_PRACTICE]: 'In Class Practice Question',
  [VIRTUAL_QUESTION_PURPOSE_REVISION]: 'Revision',
  [VIRTUAL_QUESTION_PURPOSE_ASSESSMENT]: 'Assessment',
  [VIRTUAL_QUESTION_PURPOSE_MENTAL_MATH]: 'Mental Math',
  // Questions
  [QUESTION_PURPOSE_MICRO_TEST]: 'Micro Test',
  [QUESTION_PURPOSE_PRACTICE]: 'Practice Question',
};

export const QUESTION_PURPOSE_COLORS = [
  'rgb(248, 176, 6)',
  'rgb(98, 54, 255)',
  // 'rgb(255, 230, 230)',
  'rgb(207, 255, 250)',
  // 'rgb(216, 243, 255)',
];

export const QUESTION_PURPOSE_BORDER_COLORS = [
  'rgba(248, 176, 6, 0.1)',
  'rgba(98, 54, 255, 0.1)',
  // 'rgba(255, 230, 230, 0.1)',
  'rgba(207, 255, 250, 0.1)',
  // 'rgba(216, 243, 255, 0.1)',
];
