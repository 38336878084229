<template>
  <v-form ref="assessmentSectionForm" class="section-title-cnr">
    <div class="mgn-Bs">
      <AssessmentCheck
        v-if="schema.canShowCheck"
        :data="lessonPlanData.assessment"
        :showImageBrief="showImageBrief"
        :isAssetUploadRequired="isAssetUploadRequired"
        :acceptableFileType="acceptableFileType"
        :assessmentSignedUrls="assessmentSignedUrls"
        :assessmentDiff="assessmentDiff"
        :canShowDiffIcon="canShowDiffIcon"
        :isNotReadOnly="isNotReadOnly"
        :fileValidators="fileValidators"
        :canApproveAsset="canAppproveAsset"
        :classworkDescription="lessonPlanData.application.classwork.description || ''"
      />
      <v-row class="assessment-grade-template-row" v-if="canShowAMEField">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.APPROACHING[0] }}</span>
        </div>
        <v-col>
          <RichTextEditor
            class="mgn-Tm"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('approaching')"
            fieldIdentifier="assessment.grade_template.approaching.description"
            :placeholder="AppTexts.APPROACHING"
            v-model="lessonPlanData.assessment.grade_template.approaching.description"
            :showValidationError="true"
            :readonly="!isNotReadOnly"
            :counter="schema.approachingDescriptionMaxLength"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </v-col>
      </v-row>
      <v-row class="assessment-grade-template-row" v-if="canShowAMEField">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.MEETING[0] }}</span>
        </div>
        <v-col>
          <RichTextEditor
            class="mgn-Tm"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('meeting')"
            fieldIdentifier="assessment.grade_template.meeting.description"
            :placeholder="AppTexts.MEETING"
            v-model="lessonPlanData.assessment.grade_template.meeting.description"
            :showValidationError="true"
            :readonly="!isNotReadOnly"
            :counter="schema.meetingDescriptionMaxLength"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </v-col>
      </v-row>
      <v-row class="assessment-grade-template-row" v-if="canShowAMEField">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.EXCEEDING[0] }}</span>
        </div>
        <v-col>
          <RichTextEditor
            class="mgn-Tm"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('exceeding')"
            fieldIdentifier="assessment.grade_template.exceeding.description"
            :placeholder="AppTexts.EXCEEDING"
            v-model="lessonPlanData.assessment.grade_template.exceeding.description"
            :showValidationError="true"
            :readonly="!isNotReadOnly"
            :counter="schema.exceedingDescriptionMaxLength"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </v-col>
      </v-row>
      <v-row class="assessment-grade-template-row" v-if="!canShowAMEField && schema.canShowOverall">
        <div class="assessment-grade-name-col">
          <span class="assessment-grade-name">{{ AppTexts.OVERALL[0] }}</span>
        </div>
        <v-col>
          <RichTextEditor
            class="mgn-Tm"
            :canShowDiffIcon="canShowDiffIcon"
            :isDiffChanged="getAssessmentGradeTemplateIsChangedValue('overall')"
            fieldIdentifier="assessment.grade_template.overall.description"
            :placeholder="AppTexts.OVERALL"
            v-model="lessonPlanData.assessment.grade_template.overall.description"
            :showValidationError="true"
            :readonly="!isNotReadOnly"
            :counter="schema.overallDescriptionMaxLength"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </v-col>
      </v-row>
      <slot></slot>
    </div>
  </v-form>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import AssessmentCheck from '@/components/lessonPlanTemplate/legacyLpComponents/AssessmentCheck';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';

export default {
  name: 'AssessmentSection',
  components: {
    AssessmentCheck,
    RichTextEditor,
  },
  data() {
    return {
      AppTexts,
      Errors,
      BASIC_TOOLS,
    };
  },
  computed: {
    canShowAMEField() {
      console.log(`canShowEvaluateAndGrade`, this.canShowEvaluateAndGrade)
      console.log(`schema.forceShowAMEFields`, this.schema.forceShowAMEFields)
      return this.canShowEvaluateAndGrade || this.schema.forceShowAMEFields;
    },
  },
  methods: {
    getAssessmentGradeTemplateIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.assessmentDiff.grade_template[type].description.isChanged;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
    getAssessmentCheckAttributesIsChangedValue(type) {
      try {
        if (this.canShowDiffIcon) {
          return this.assessmentDiff.check[type].isChanged;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    assessmentSignedUrls: {
      type: Object,
      required: true,
    },
    assessmentDiff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    canShowEvaluateAndGrade: {
      type: Boolean,
      default: false,
      required: false,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    schema: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.assessment-grade-name {
  background: #b5e9e4;
  padding: 4px 1px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
}
.assessment-grade-name-col {
  display: flex;
  align-items: center;
  width: 45px;
}
.workseet-text {
  font-size: 14px;
  display: block;
  color: #000000;
  transform: translateY(9px);
  margin-left: 10px;
}
.pad-top-none {
  padding-top: 0px !important;
}
.assessment-grade-template-row {
  padding: 0px 5px;
  align-items: center;
}
</style>
