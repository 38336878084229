<template>
  <div>
    <div v-if="currentMicroTopic && currentMicroTopic.name">
      <v-dialog v-model="showMicroTopicProjectForm" content-class="micro-topic-form-dialog">
        <MicroTopicProjectFormContent
          :newMicroTopicProjectsDataKeys="Object.keys(updateMicroTopicProjectData)"
          :newMicroTopicProjectsData="Object.values(updateMicroTopicProjectData)"
          :users="users"
          :saveProject="saveMicrotopicProjects"
          :closeProjectDialog="() => (showMicroTopicProjectForm = false)"
          dialogTile="Update Micro Topic Projects"
          :isUpdate="true"
          :microTopicProjectDetails="{
            microTopicName: currentMicroTopic.name,
            grade: currentMicroTopic.standard.displayName,
            subject: currentMicroTopic.subject.name,
            theme: currentMicroTopic.theme.name,
          }"
        />
      </v-dialog>
      <v-row class="breadcrumb-row">
        <v-col class="breadcrumb-design">
          <!-- breadcrumb-->
          <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{
                item.text.toUpperCase()
              }}</v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <p class="slash">/</p>
          <span
            >microtopic
            {{
              currentMicroTopic ? currentMicroTopic.mtId + ' - ' + currentMicroTopic.name : 'NA'
            }}</span
          >
        </v-col>
        <v-col :cols="1" class="action-button-wrapper">
          <v-btn @click="editMicroTopic" v-show="canShowEditButton" class="action-edit-button"
            >Edit</v-btn
          >
        </v-col>
      </v-row>

      <div class="view-container">
        <div class="topic-css">
          <div>
            <p>
              Grade {{ currentMicroTopic.standard.name }} -
              {{ currentMicroTopic.subject.name }}
            </p>
          </div>
          <div>
            <p>
              Theme:
              {{ currentMicroTopic.theme.name }} - Topic:
              {{ currentMicroTopic.topic.name }}
            </p>
          </div>
          <div>
            <p>
              <span>{{ currentMicroTopic.mtId + ':' + currentMicroTopic.name }}</span>
            </p>
            <p v-if="currentMicroTopic.description">
              {{ currentMicroTopic.description }}
            </p>
          </div>
          <v-col
            v-if="currentMicroTopic.lessonPlanMap && currentMicroTopic.lessonPlanMap.boardName"
            class="mt-3"
            style="
              padding: 10px 0px 10px 12px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              background: #d9d9d9;
              border-radius: 5px;
            "
            ><p>This Microtopic is linked to School Program:</p>
            <span
              @click="goToLp"
              style="
                font-weight: bold;
                padding: 12px 0 0 0;
                cursor: pointer;
                text-decoration: underline;
              "
              >{{ currentMicroTopic.lessonPlanMap.boardName }},Block
              {{ currentMicroTopic.lessonPlanMap.blockId }}, LP{{
                currentMicroTopic.lessonPlanMap.sequence
              }}-{{ currentMicroTopic.lessonPlanMap.lpName }}
            </span>
          </v-col>
        </div>

        <div class="topic-view-container">
          <div class="main-header">
            <div>Project Name</div>
            <div>Current Stage</div>
            <div>Person Responsible</div>
            <div></div>
          </div>
          <div>
            <div v-for="(project, index) in currentProjects" :key="index">
              <MicroTopic
                :currentStage="getProjectCurrentStage(project)"
                :type="capitalCase(project.type)"
                :name="project.name"
                :assignee="getProjectCurrentStageAssignee(project)"
                :id="project.id"
                :projectData="project"
                :isAdmin="isAdmin"
                :updatedDate="updatedDay"
                @viewAction="viewProject"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="ml-9 mt-3" v-if="currentMicroTopic.microSkills">
        <p v-if="currentMicroTopic.microSkills.Doing">
          <b>Doing</b>: {{ currentMicroTopic.microSkills.Doing }}
        </p>

        <p v-if="currentMicroTopic.microSkills.Understanding">
          <b>Understanding</b>: {{ currentMicroTopic.microSkills.Understanding }}
        </p>
        <p v-if="currentMicroTopic.microSkills.Expressing">
          <b>Expressing</b>: {{ currentMicroTopic.microSkills.Expressing }}
        </p>
      </div>
    </div>
    <div v-else style="margin-top: 50px;">
      <Loader
        :loadingText="AppTexts.FETCHING_DATA_TEXT"
        :loadingStatus="!(currentMicroTopic && currentMicroTopic.name)"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import MicroTopicProjectFormContent from '@/components/Admin/MicroTopicProjectFormContent';
import Loader from '@/components/common/Loader';
import AppTexts from '@/constants/AppTexts';
import { capitalCase } from 'change-case';
import { LP_STAGES, LP_ASSIGNEES } from '@/constants/LessonPlan';
import { SKIP_STAGE_USER_ID_VALUE, PROJECT_TYPES_HASH } from '@/constants/microTopic';
import { singleProjectDefaultData } from '@/utils/mtCommonUtils.js';
import MicroTopic from '@/components/contentEntry/microTopic/MicroTopicSingleView';
export default {
  name: 'MicroTopicView',
  components: {
    MicroTopicProjectFormContent,
    Loader,
    MicroTopic,
  },
  data() {
    return {
      allProjectTypes: ['oLesson', 'questions', 'microText', 'worksheet', 'contentBook', 'videos'],
      AppTexts,
      LP_ASSIGNEES,
      LP_STAGES,
      SKIP_STAGE_USER_ID_VALUE,
      breadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          href: '/micro_topics',
        },
      ],
      showMicroTopicProjectForm: false,
      updateMicroTopicProjectData: {},
    };
  },
  created() {
    this.getMicroTopicDetails({
      microTopicId: this.$route.params.microTopicId,
    });
    this.getUsers();
    this.getCurrentUserMicroTopicAuthPermissions();
  },
  computed: {
    ...mapState({
      currentMicroTopic: (state) => state.currentMicroTopic,
      canEditMicroTopicsList: (state) => state.auth.microTopic.canEditMicroTopicsList,
      users: (state) => state.user.users,
      isAdmin: (state) => state.auth.isAdmin,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
    }),
    canShowEditButton() {
      if (this.isAdmin) {
        return true;
      } else if (this.isAcademicAdmin) {
        return this.canEditMicroTopicsList.includes(parseInt(this.currentMicroTopic.id));
      }
      return false;
    },
    currentProjects() {
      let _projects = [];

      // Backend is sending the projects in recently created first order. So find will give the most recent one each type.
      //Also since reopening of a project is applicable and the requirement is to show only one published project
      //we are finding a project with workflow status as in_progress and published
      let oLesson = this.currentMicroTopic.projects.find(
        (_) => _.type == PROJECT_TYPES_HASH.O_LESSON
      );
      if (oLesson) _projects.push(oLesson);
      let question = this.currentMicroTopic.projects.find(
        (_) => _.type == PROJECT_TYPES_HASH.QUESTIONS
      );
      if (question) _projects.push(question);
      let microtext = this.currentMicroTopic.projects.find(
        (_) => _.type == PROJECT_TYPES_HASH.MICROTEXT
      );
      if (microtext) _projects.push(microtext);
      let worksheet = this.currentMicroTopic.projects.find(
        (_) => _.type == PROJECT_TYPES_HASH.WORKSHEET
      );
      if (worksheet) _projects.push(worksheet);
      let contentbook = this.currentMicroTopic.projects.find(
        (_) => _.type == PROJECT_TYPES_HASH.CONTENTBOOK
      );
      if (contentbook) _projects.push(contentbook);
      let videos = this.currentMicroTopic.projects.find((_) => _.type == PROJECT_TYPES_HASH.VIDEOS);
      if (videos) _projects.push(videos);

      return _projects;
    },
    updatedDay() {
      const pastDate = this.currentMicroTopic.projects[0].updatedAt;
      let dayDifference = Date.now() - pastDate;
      const toatalDay = Math.floor(dayDifference / (1000 * 3600 * 24));
      const hours = toatalDay * 1000 * 3600 * 24;
      const totalHours = Math.ceil((dayDifference - hours) / (1000 * 3600));
      return `last edited before ${toatalDay} days and ${totalHours} hours ago`;
    },
  },
  methods: {
    ...mapActions([
      'getMicroTopicDetails',
      'getUsers',
      'updateMicroTopicProjectAssignee',
      'createMicroTopicProjecs',
      'getCurrentUserMicroTopicAuthPermissions',
    ]),
    singleProjectDefaultData,
    capitalCase,
    viewProject(id) {
      this.$router.push({
        name: 'ContentEntryProjectForm',
        params: {
          microTopicId: this.$route.params.microTopicId,
          projectId: id,
        },
      });
    },
    editMicroTopic() {
      this.updateMicroTopicProjectData = this.updateProjectData();
      this.showMicroTopicProjectForm = true;
    },
    saveMicrotopicProjects() {
      this.allProjectTypes.forEach((projectType) => {
        // Check whether updated data has this projectType's assignee data
        if (
          this.updateMicroTopicProjectData[projectType] &&
          this.updateMicroTopicProjectData[projectType].enabled
        ) {
          // Check whether project already exists for this projectType.
          // If yes, we need to just update the project.
          // Else, create a new project.
          let project = this.currentProjects.find(
            (_) => _.type == projectType && _.workflowStatus != this.AppTexts.WORKFLOW_COMPLETED
          );

          if (project) {
            let projectDetails = this.updateMicroTopicProjectData[projectType];

            if (projectDetails.enabled)
              this.updateMicroTopicProjectAssignee({
                assignees: projectDetails.assignees,
                microTopic: this.currentMicroTopic,
                projectId: project.id,
                isListPage: false,
              });
          } else {
            let projectDetails = this.updateMicroTopicProjectData[projectType];

            if (projectDetails.enabled)
              this.createMicroTopicProjecs({
                assignees: projectDetails.assignees,
                microTopic: this.currentMicroTopic,
                projectType: projectType,
              });
          }
        }
      });
      this.showMicroTopicProjectForm = false;
    },
    handleSkippedStages(assigneeObjects) {
      return assigneeObjects;
    },
    goToLp() {
      let routeData = this.$router.resolve({
        name: 'lpForm',
        params: {
          libraryId: this.currentMicroTopic.lessonPlanMap.lpId,
          projectId: this.currentMicroTopic.lessonPlanMap.projectId,
        },
        query: {
          lpType: '',
          blockSequence: '',
          blockTitle: '',
          bId: this.currentMicroTopic.lessonPlanMap.blockId,
          productName: '',
          grade: this.currentMicroTopic.lessonPlanMap.standardName,
          subject: this.currentMicroTopic.lessonPlanMap.subjectName,
          boardName: this.currentMicroTopic.lessonPlanMap.boardName,
        },
      });
      window.open(routeData.href, '_blank');
    },
    updateProjectData() {
      let response = {};
      this.allProjectTypes.forEach((projectType) => {
        let project = this.currentProjects.find((_) => _.type == projectType);

        if (project) {
          response[project.type] = {
            enabled: project.workflowStatus != this.AppTexts.WORKFLOW_COMPLETED ? true : false,
            assignees: this.handleSkippedStages(project.assignees),
            id: project.id,
            contents: project.contents,
            workflowStatus: project.workflowStatus,
          };
        } else {
          response[projectType] = this.singleProjectDefaultData(projectType);
        }
      });

      return response;
    },
    getProjectCurrentStage(project) {
      try {
        if (project.workflowStatus == 'completed') return 'Published';
        return this.LP_STAGES[project.contents[0].currentStage];
      } catch (er) {
        return '-';
      }
    },
    getProjectCurrentStageAssignee(project) {
      try {
        return project.assignees[this.LP_ASSIGNEES[project.contents[0].currentStage]].name;
      } catch (er) {
        return '-';
      }
    },
  },
};
</script>
<style lang="scss">
.breadcrumb-row {
  padding: 0 40px !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  .breadcrumb-design {
    display: flex;
    flex-direction: row;
    > span {
      padding: 18px 12px 18px 8px;
      cursor: pointer;
      color: #242424;
      font-weight: 500;
    }
    a {
      color: rgba(0, 0, 0, 0.7) !important;
      font-size: 14px !important;
    }
    ul {
      padding-left: 10px;
    }
  }
}
.view-container {
  width: 94vw;
  margin-left: 40px;
  margin-right: 40px;
  padding: 0px;
  padding-bottom: 20px;
  background: #ececec;
}
.topic-css {
  margin-top: 20px;
  padding-bottom: 40px;
  display: flex;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  flex-direction: column;
  background: #fff;

  p {
    margin: 0px !important;
  }
}
.topic-css > :nth-child(1) {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
}
.topic-css :nth-child(2) {
  margin-bottom: 0px;
}
.topic-css :nth-child(3) {
  > p {
    display: flex;
    align-items: baseline;
    font-size: 20px;
    font-weight: 500;
    > span {
      font-size: 28px;
      font-weight: 700;
    }
  }
}
.topic-view-container {
  .main-header {
    margin: 20px 15px 25px 15px;
    display: -webkit-box;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    height: 50px;
  }
  .main-header :nth-child(1) {
    width: 39%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
  .main-header :nth-child(2) {
    width: 17%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
  .main-header :nth-child(3) {
    width: 20%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
  .main-header :nth-child(4) {
    width: 278px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
}
@media only screen and (max-device-width: 1280px) {
  .micro-topic-wrapper {
    margin: 2% 3%;
  }
}

.micro-topic-form-dialog {
  transform: translateY(30px);
  max-height: 88% !important;
}
.action-button-wrapper {
  .action-edit-button {
    background: #1c9d5a !important;
    width: 200px;
    font-size: 18px;
    font-weight: 500;
    top: 17px !important;
    color: #fff;
  }
}
.action-navigate-button {
  background: rgb(255, 180, 88) !important;
  width: 200px;
  font-size: 18px;
  font-weight: 500;
  top: 17px !important;
}
</style>
