<template>
  <div>
    <div style="display: flex;">
      <div style="flex: 1;">
        <BlockTitle :title="title" v-if="title" />
      </div>
      <div>
        <v-btn v-if="requireDeleteBlock" icon @click="$emit('deleteBlock')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <RichTextEditor
      :readonly="isReadOnly"
      :canShowDiffIcon="canShowDiffIcon"
      v-model="data.description"
      :fieldIdentifier="descriptionDiffIdentifier"
      :placeholder="placeholder"
      :showValidationError="showValidationError"
      :isDiffChanged="(diffChanges.description || {}).isChanged || false"
      :counter="descriptionMaxLength"
      :includeOnlyToolBarActions="BASIC_TOOLS"
      :returnEmptyStringInsteadOfDefaultJson="returnEmptyStringInsteadOfDefaultJsonInDescription"
    />
    <MultipleAssetUpload
      :assets="data.assets || {}"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :assetSignedUrlsHash="assetSignedUrlsHash"
      :sectionIdentifier="sectionIdentifier"
      :canShowDiffIcon="canShowDiffIcon"
      :assetDiffIdentifierPrefix="assetDiffIdentifierPrefix"
      :assetsDiffChangedHash="diffChanges.assets || {}"
      :isNotReadOnly="!isReadOnly"
      :fileValidators="fileValidators"
      :requireDeleteAsset="requireDeleteAsset"
      :requireAddAsset="requireAddAsset"
      :stepIndex="stepIndex"
      :isAssetMandatory="isAssetMandatory"
      :canAppproveAsset="canAppproveAsset"
      :canFinalizeAsset="canFinalizeAsset"
    />
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import MultipleAssetUpload from '@/components/inputs/MultipleAssetUpload';
import BlockTitle from '@/components/inputs/BlockTitle';

export default {
  name: 'RTEWithMultipleAssetUpload',
  components: {
    RichTextEditor,
    MultipleAssetUpload,
    BlockTitle,
  },
  data() {
    return {
      BASIC_TOOLS,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    diffChanges: {
      type: Object,
      required: true,
    },
    assetSignedUrlsHash: {
      type: Array,
      required: false,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    fileValidators: {
      type: Array,
      required: false,
    },
    assetDiffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    descriptionDiffIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    sectionIdentifier: {
      type: String,
      required: true,
    },
    requireDeleteAsset: {
      type: Boolean,
      required: false,
      default: true,
    },
    requireAddAsset: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
    },
    stepIndex: {
      type: Number,
      required: true,
    },
    isAssetMandatory: {
      type: Boolean,
      required: false,
      default: true,
    },
    descriptionMaxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    requireDeleteBlock: {
      type: Boolean,
      required: false,
      default: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    canFinalizeAsset: {
      type: Boolean,
      required: false,
    },
    returnEmptyStringInsteadOfDefaultJsonInDescription: {
      type: Boolean,
      default: false,
    },
    showValidationError: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
