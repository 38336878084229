<template>
  <v-form ref="referenceSectionForm" class="section-title-cnr">
    <Padder>
      <SubSection :title="AppTexts.REFERENCE" />
      <RichTextEditor
        :placeholder="AppTexts.REFERENCES"
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        fieldIdentifier="teacherReference"
        :max="100"
        class="mgn-Tm"
        v-model="reference"
      />
    </Padder>
  </v-form>
</template>
<script>
import RichTextEditor from '@/components/RTE/RichTextEditor';
import AppTexts from '@/constants/AppTexts';
import SubSection from '@/components/inputs/SubSection';

export default {
  name: 'Reference',
  data() {
    return {
      AppTexts,
      reference: ''
    };
  },
  watch: {
    reference: function(val) {
      if (val && val != '') this.$emit('reference', val);
    }
  },
  components: {
    RichTextEditor,
    SubSection
  },
  created() {
    this.reference = this.data;
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    data: {
      type: String,
      required: true
    }
  }
};
</script>
