<template>
  <v-stepper v-model="currentStage" class="stage-stepper">
    <v-stepper-header>
      <v-stepper-step :complete="currentStage > 1" step="AU1">{{
        stepUserName(assignees[assigneesKey[0]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 2" step="R1">{{
        stepUserName(assignees[assigneesKey[1]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 3" step="R2">{{
        stepUserName(assignees[assigneesKey[2]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 4" step="AU2">{{
        stepUserName(assignees[assigneesKey[3]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 5" step="AMR">{{
        stepUserName(assignees[assigneesKey[4]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 7" step="EDT">{{
        stepUserName(assignees[assigneesKey[5]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 6" step="VIZ">{{
        stepUserName(assignees[assigneesKey[6]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 7" step="UA">{{
        stepUserName(assignees[assigneesKey[7]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 8" step="AA">{{
        stepUserName(assignees[assigneesKey[8]])
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="currentStage > 9" step="PR">{{
        stepUserName(assignees[assigneesKey[9]])
      }}</v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
import { LP_ASSIGNEES } from '@/constants/LessonPlan';
export default {
  name: 'LessonPlanStageStepper',
  data() {
    return {
      assigneesKey: Object.values(LP_ASSIGNEES),
    };
  },
  props: {
    currentStage: {
      type: Number,
      required: false,
      default: -1,
    },
    assignees: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    stepUserName(data) {
      try {
        return data.name;
      } catch (error) {
        return 'skipped';
      }
    },
  },
};
</script>
<style scoped>
.v-stepper__step {
  display: flex;
  flex-direction: column;
}
.v-stepper__label {
  padding-top: 10px;
}
</style>
<style>
.v-stepper.stage-stepper .v-stepper__label {
  font-size: 0.8em;
}
.v-stepper__step .v-stepper__step__step {
  padding: 15px;
}
</style>
