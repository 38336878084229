import axios from 'axios';
import Vue from 'vue';
import { apolloClient } from '../../../apollo';
import {
  fetchAllBlocksForSelectedProductBoardStandardSubjectQuery,
  fetchConfidenceAllGradeSubjects,
  fetchConfidenceBlocks,
} from '../../../apiQueries/qrManagement';
const QR_SERVICE_ENDPOINT = process.env.VUE_APP_QR_SERVICE_ENDPOINT;
const actions = {
  async fetchQrCodes({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get(`${QR_SERVICE_ENDPOINT}qr/list`);
      commit('SET_QR_CODES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'Failed to fetch QR codes');
      Vue.notify({
        title: '<strong><i class="mdi mdi-alert-circle-outline"></i> Oops!</strong>',
        text: `
    <div style="font-size: 14px;">
      ❌ <strong>Something went wrong in the QR Management Module!</strong> <br/>
      <span style="color: #f44336;">
        Please try again or contact support if the issue persists.
      </span>
    </div>`,
        type: 'error',
        duration: 3000,
        speed: 300,
        closeOnClick: true,
        progressBar: true,
      });
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addQrCode({ commit, dispatch }, qrData) {
    commit('changeLoadingState', true);
    try {
      await axios.post(`${QR_SERVICE_ENDPOINT}qr/generate`, qrData);
      dispatch('fetchQrCodes');
      Vue.notify({
        title: '<strong><i class="mdi mdi-check-circle-outline"></i> Success!</strong>',
        text: `
            <div style="font-size: 14px;">
              🎉 <strong>QR Code </strong> has been successfully added! <br/>
            </div>
            `,
        type: 'success',
        duration: 3000,
        speed: 300,
        closeOnClick: true,
        progressBar: true,
      });
    } catch (error) {
      commit('SET_ERROR', 'Failed to add QR code');
      Vue.notify({
        title: '<strong><i class="mdi mdi-alert-circle-outline"></i> Failed!</strong>',
        text: `
          <div style="font-size: 14px;">
            ❌ <strong>QR Code </strong> generation failed. <br/>
            <span style="color: #ff5252;">
              Please try again or contact support if the issue persists.
            </span>
          </div>`,
        type: 'error',
        duration: 3000,
        speed: 300,
        closeOnClick: true,
        progressBar: true,
      });
    } finally {
      commit('changeLoadingState', false);
    }
  },
  async updateQrCode({ commit, dispatch }, qrData) {
    commit('changeLoadingState', true);
    try {
      await axios.put(`${QR_SERVICE_ENDPOINT}qr/update/${qrData.qrHash}`, qrData);
      Vue.notify({
        title: '<strong><i class="mdi mdi-check-circle-outline"></i> Success!</strong>',
        text: `
            <div style="font-size: 14px;">
              🎉 <strong>QR Code Mapping</strong> has been successfully updated! <br/>
            </div>
            `,
        type: 'success',
        duration: 3000,
        speed: 300,
        closeOnClick: true,
        progressBar: true,
      });

      dispatch('fetchQrCodes');
    } catch (error) {
      commit('SET_ERROR', 'Failed to add QR code');
      Vue.notify({
        title: '<strong><i class="mdi mdi-alert-circle-outline"></i> Failed!</strong>',
        text: `
          <div style="font-size: 14px;">
            ❌ <strong>QR Code Mapping </strong> update failed. <br/>
            <span style="color: #ff5252;">
              Please try again or contact support if the issue persists.
            </span>
          </div>`,
        type: 'error',
        duration: 3000,
        speed: 300,
        closeOnClick: true,
        progressBar: true,
      });
    } finally {
      commit('changeLoadingState', false);
    }
  },
  async fetchAllBlocksForSelectedProductBoardStandardSubject({ commit }, payload) {
    try {
      let { productId, boardName, standardId, subjectId } = payload;
      commit('changeLoadingState', true);

      const response = await apolloClient.query({
        query: fetchAllBlocksForSelectedProductBoardStandardSubjectQuery(
          productId,
          boardName,
          standardId,
          subjectId
        ),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        const blocks =
          response.data.getAllBlocksOnStandardSubjectBoardProductComboForProjectCreation || [];
        commit('SET_BLOCKS_FOR_QR', blocks); // Committing the blocks to state
        commit('changeLoadingState', false);
        return blocks; // Return the fetched blocks
      }
    } catch (er) {
      commit('changeLoadingState', false);

      Vue.notify({
        title: '<strong><i class="mdi mdi-alert-circle-outline"></i> Failed!</strong>',
        text: `
          <div style="font-size: 14px;">
            ❌ <strong>Something went wrong, while getting the block for selected filters</strong><br/>
            <span style="color: #ff5252;">
              Please try again or contact support if the issue persists.
            </span>
          </div>`,
        type: 'error',
        duration: 3000,
        speed: 300,
        closeOnClick: true,
        progressBar: true,
      });
      return [];
    }
  },
  async fetchConfidenceBlocksForQR({ commit }, { standardId, subjectId }) {
    try {
      commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: fetchConfidenceBlocks(standardId, subjectId),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data && response.data.fetchConfidenceBlocks) {
        const blocks = response.data.fetchConfidenceBlocks.data || [];
        commit('SET_BLOCKS_FOR_QR', blocks);
        commit('changeLoadingState', false);
        return blocks;
      }
      commit('changeLoadingState', false);
    } catch (error) {
      console.log(error);
      commit('changeLoadingState', false);
      return [];
    }
  },
  async fetchConfidenceStandardsSubjects({ commit }) {
    try {
      commit('changeLoadingState', true);
      const response = await apolloClient.query({
        query: fetchConfidenceAllGradeSubjects,
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        const grades = response.data.fetchAllConfidencdGradeSubjects || [];
        commit('SET_CONFIDENCE_GRADES', grades.data);
        commit('changeLoadingState', false);
      }
      commit('changeLoadingState', false);
    } catch (error) {
      console.log(error);
      commit('changeLoadingState', false);
    }
  },
};

export default actions;
