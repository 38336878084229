/**
 * Library
 */
import { mapState, mapActions } from 'vuex';
import { get, set } from 'lodash';

/**
 * Constants
 */
import AppTexts from '@/constants/AppTexts';
import {
  LP_TYPES,
  LP_FORM_SUBMIT_TEXT,
  COMMENT_SECTION_NAMES_HASH,
  LESSON_PLAN_LOADING_STATES,
  RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
  LP_STAGES,
} from '@/constants/LessonPlan';
import { PROJECT_TYPE_LESSON_PLAN } from '@/constants/Project';

import AssetTypes from '@/constants/AssetTypes';
import Errors from '@/constants/Errors';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import {
  PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA,
  PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA,
} from '@/constants/common';
import {
  DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION,
  DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
} from '@/constants/LessonPlan';

/**
 * Functions
 */
import { getFileNameFromUrl } from '@/functions/global';
import LPParser from '@/utils/LessonPlanParser';
import { getAssetUploadRequiredStatus, getAcceptableFileType } from '@/utils';

/**
 * Components
 */
import CoverImage from '@/components/lessonPlanTemplate/CoverImage';
import FormLayout from '@/components/layouts/FormLayout';
import SummarySection from '@/components/lessonPlanTemplate/SummarySection';
import AimSection from '@/components/lessonPlanTemplate/AimSection';
import ActionSection from '@/components/lessonPlanTemplate/ActionSection';
import AnalyseSection from '@/components/lessonPlanTemplate/AnalyseSection';
import ApplicationSection from '@/components/lessonPlanTemplate/ApplicationSection';
import AssessmentSection from '@/components/lessonPlanTemplate/AssessmentSection';
import ReferencesSection from '@/components/lessonPlanTemplate/ReferencesSection';
import ExpansionPanelHeader from '@/components/lessonPlanTemplate/ExpansionPanelHeader';
import ActionProgram from '@/components/lessonPlanTemplate/lab/ActionProgram.vue';
import AnalysisProgram from '@/components/lessonPlanTemplate/lab/AnalysisProgram.vue';

export const baseMixin = {
  components: {
    CoverImage,
    FormLayout,
    SummarySection,
    AimSection,
    ActionSection,
    AnalyseSection,
    ApplicationSection,
    AssessmentSection,
    ReferencesSection,
    ExpansionPanelHeader,
    ActionProgram,
    AnalysisProgram,
  },
  created() {
    this.getProjectDetails({ projectId: this.$route.params.projectId });
    this.getLessonPlanData({
      projectId: this.$route.params.projectId,
      libraryId: this.$route.params.libraryId,
      projectType: this.projectType,
    });
    this.setLibraryId(this.$route.params.libraryId);
    this.getLessonPlanSaveAndMoveToNextStagePermission({
      projectId: this.$route.params.projectId,
      libraryId: this.$route.params.libraryId,
    });
    this.getCurrentUserAuthPermissions();
    // this.addEventLisnerForWindowUnLoad();
  },
  data() {
    return {
      isSaved: false,
      redirectionPathObject: '',
      projectType: PROJECT_TYPE_LESSON_PLAN,
      PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA,
      PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA,
      Schema: {
        canEditTitle: true,
        canEditDescription: true,
        showSelectInputForLpTitle: false,
        types: ['individual', 'pair', 'group', 'class'],
        isCoverImageEditable: true,
        coverImageAllowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION,
        isWorksheetEnabled: false,
        aim: {
          show: true,
          showKeywords: true,
          showKeyQuestion: false,
          showResources: true,
          maxKeywordsLength: 5, // done
          maxResourcesLength: 7, // done
          keyWordDefinitionOptional: false,
          showKeywordDescriptions: true, // done
          label: {
            resourcesTitle: AppTexts.RESOURCES, // done
            keywordsTitle: AppTexts.KEYWORDS_WE_WILL_USE, // done
            keywordsSubTitle: null, /// done
            // keywordsSubTitle: "First, let's add these words to the Word Wall!",
          },
          summaryMaxLength: 66,
          descriptionMaxLength: 250,
          keyQuestionMaxLength: 250,
          keywordTextMaxLength: 33,
          keywordDescriptionMaxLength: 100,
          resourceNameMaxLength: undefined,
          isSummaryOptional: false,
          resourceMaxLength: 7,
        },
        action: {
          show: true, // done
          summaryMaxLength: 66,
          maxNoOfSteps: 12,
          maxNoOfSubStepsInStep: 6,
          maxSumOfStepsAndSubSteps: 25,
          stepDescriptionMaxLength: undefined,
          subStepDescriptionMaxLength: undefined,
          stepTeacherTipMaxLength: 220,
          teacherTipMaxLength: 220,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
          isSummaryOptional: false,
          showInstructionAudioWord: false,
          maxAudioWordLength: 5,
          maxAudioWordNameLength: 250,
          maxAudioWordDescriptionLength: 250,
          showSummarySection: false,
        },
        analysis: {
          show: true, // done
          showSummarySection: false,
          showDiscussionLpTypeComponent: false,
          showGd: true,
          showHot: true,
          showPart1Text: true, // done
          showUniversalDiscussion: false,
          showDiscussionKeyQuestionAndGuide: true,
          showDiscussionExplanation: true,
          showPhonicsLpTypeComponent: false,
          prefix: 'OK, now let’s talk about what we did!',
          label: {
            activityDiscussion: AppTexts.ACTIVITY_DISCUSSION, // done
            activityExplanation: AppTexts.ACTIVITY_EXPLANATION, // done
          },
          maxNoOfDiscussionSteps: 12,
          maxNoOfSubStepsDiscussionStep: 3,
          maxSumOfStepsAndSubDiscussionSteps: 12,
          summaryMaxLength: 66,
          discussionKeyQuestionMaxLength: 200,
          discussionKeyQuestionGuideMaxLength: 160,
          discussionStepDescriptionMaxLength: undefined,
          discussionSubStepDescriptionMaxLength: undefined,
          discussionStepTeacherTipMaxLength: 220,
          discussionTeacherTipMaxLength: 220,
          isSummaryOptional: false,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION,
          showReadXSEEDContentBookCheckbox: false,
          isKeyQuestionGuideMandatory: true,
          instructionsMaxNoOfSteps: 0,
          instructionsMaxNoOfAssetsPerStep: undefined,
          showCodingConnection: false,
        },
        application: {
          show: true, // done
          displayTime: true,
          displayTrailing: true,
          showSummarySection: true,
          showLegacyClassWorkHomeWork: false,
          showClassWorkHomeWork: true,
          showDiscussionLpTypeComponent: false,
          isHomeworkOptional: false,
          showDifferentiationTips: false,
          morePractice: true,
          showClasswork: true,
          showHomeWork: true,
          showPracticeHomework: false,
          isClassworkQuestionImageMandatory: false,
          label: {
            worksheet: {
              prefix1: '',
              prefix2: '',
            },
          },
          showQuestionTeacherTip: false,
          classworkSummaryMaxLength: 33,
          homeworkSummaryMaxLength: 33,
          mentalMathSummaryMaxLength: 33,
          problemSolvingSummaryMaxLength: 33,
          classworkDescriptionMaxLength: undefined,
          homeworkDescriptionMaxLength: undefined,
          neeMoreHelpDescriptionMaxLength: 160,
          needExtraChallengeDescriptionMaxLength: 160,
          morePracticeStepDescriptionMaxLength: undefined,
          morePracticeSubStepDescriptionMaxLength: undefined,
          morePracticeStepTeacherTipMaxLength: 220,
          teacherTipsDescriptionMaxLength: undefined,
          isSummaryOptional: false,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION,
          isNeedMoreHelpMandatory: true,
          isNeedExtraChallengeMandatory: true,
          classworkTeacherTipMaxLength: 1000,
          showClassworkTeacherTip: true,
          configurableMorePractice: {
            show: false,
            items: [],
          },
          showSwitchableLayout: false,
          configurableMultiSlide: {
            slideLayoutCheckPath: '',
          },
        },
        assessment: {
          show: true, // done
          showSummarySection: true,
          canShowOverall: true,
          approachingSummaryMaxLength: 45,
          meetingSummaryMaxLength: 45,
          exceedingSummaryMaxLength: 45,
          overallSummaryMaxLength: 45,
          approachingDescriptionMaxLength: 160,
          meetingDescriptionMaxLength: 160,
          exceedingDescriptionMaxLength: 160,
          overallDescriptionMaxLength: undefined,
          isSummaryOptional: false,
          forceShowAMEFields: false,
          canShowCheck: false,
        },
        reference: {
          referenceTextMaxLength: 300,
          show: true,
        },
        actionProgram1: {
          show: false,
        },
        actionProgram2: {
          show: false,
        },
        analysisProgram1: {
          show: false,
        },
        analysisProgram2: {
          show: false,
        },
        summary: {
          show: true,
        },
        informationAndAssessment: {
          show: false,
        },
      },
      LP_STAGES,
      AppTexts,
      lf: '122',
      LP_FORM_SUBMIT_TEXT,
      LESSON_PLAN_LOADING_STATES,
      COMMENT_SECTION_NAMES_HASH,
      AssetTypes,
      Errors,
      FILE_UPLOADING_STATUS,
      resourcesError: '',
      importedHtmlFile: '',
      panel: [0, 1, 2, 3, 4, 5, 6],
      dialog: false,
      lpPreviewUrl: process.env.VUE_APP_LP_PREVIEW_ENDPOINT,
      summaryData: {
        aim: '',
        action: '',
        analysis: '',
        application: '',
        assessment: '',
        assessmentOverall: '',
      },
      lessonPlanTimeObject: {},
      lessonPlanDataCopy: {},
      lessonPlanWatcher: {},
      unsavedChanges: false,
      LP_TYPES,
      currentStageWatcher: '',
      showImageBrief: true,
      isAssetUploadRequired: false,
      commentButtonPageYValue: 'margin-top: 0px;',
      RIGHT_SIDE_PREVIEW_CONTENT_TYPE,
      commentSectionIdentifier: '',
      canShowDiffIcon: false,
      currentStageDetails: {
        idenx: 0,
        name: LP_STAGES[0],
      },
      blocksToHide: [],
      firstCall: true,
      acceptableFileType: '',
      buttonActions: {
        saveForm: () => this.saveForm(),
        validateContent: () => this.handleValidateForm(),
        redirectToPreviousPage: this.redirectToPreviousPage,
        downloadContentAssets: this.downloadAsset,
        activateContent: () => this.activateContent(),
      },
      previewData: {},
    };
  },
  computed: {
    ...mapState({
      rteValidator: (state) => state.common.rteValidator,
      subjectName: (state) => state.lessonPlan.subjectName,
      lessonPlan: (state) => state.lessonPlan,
      currentStage: (state) => state.lessonPlan.currentStage,
      lessonPlanData: (state) => state.lessonPlan.data,
      diff: (state) => state.lessonPlan.diff,
      fieldDiff: (state) => state.rightSideContent.fieldDiff,
      commentsCount: (state) => state.lessonPlan.commentsCount,
      commentsHash: (state) => state.rightSideContent.commentsHash,
      uploadingStatus: (state) => state.lessonPlan.uploading_status,
      signedUrls: (state) => state.lessonPlan.signedUrls,
      auth: (state) => state.auth.lessonPlan,
      loggedInUserEmail: (state) => state.loggedInUser.email,
      staticTexts: (state) => state.lessonPlan.staticTexts,
      lessonPlanLoadingStatus: (state) => state.lessonPlan.lessonPlanLoadingStatus,
      lessonPlanDataFetchStatus: (state) => state.lessonPlan.lessonPlanDataFetchStatus,
      rightSidePreviewContent: (state) => state.rightSideContent.rightSidePreviewContent,
      showRightSidePanel: (state) => state.rightSideContent.showRightSidePanel,
      currentDiffIdentifier: (state) => state.rightSideContent.currentDiffIdentifier,
      lpCurrentStageDataDailyPlanFromVuex: (state) =>
        state.lessonPlan.lpCurrentStageDataDailyPlanFromVuex,
      saveLessonPlanTriggerState: (state) => state.lessonPlan.saveLessonPlanTriggerState,
      currentProject: (state) => state.currentProject,
      isAdmin: (state) => state.auth.isAdmin,
    }),
    showAssetDownloadButton() {
      if (
        this.currentProject &&
        this.currentProject.subject &&
        this.currentProject.subject.shortName
      ) {
        return this.currentProject.subject.shortName != 'EC';
      }
      return true;
    },
    worksheetEnabled() {
      return this.$route.query.worksheetEnabled == 'true';
    },
    canShowEvaluateAndGrade() {
      return true;
    },
    canAppproveAsset() {
      if (this.currentStage == 'assetAuthorizingStage') return true;
      return false;
    },
    breadCrumbText() {
      let text = `Home > Block <a href="/projects/${this.$route.params.projectId}">- ${this.$route.query.blockTitle} (${this.$route.query.bId})</a>`;

      if (
        this.lpCurrentStageDataDailyPlanFromVuex &&
        this.lpCurrentStageDataDailyPlanFromVuex.name
      ) {
        return `${text} > ${this.lpCurrentStageDataDailyPlanFromVuex.name}`;
      }

      return text;
    },
    isPreviewBeingDisplayedInRightSideView() {
      return (
        this.rightSidePreviewContent != RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS &&
        this.rightSidePreviewContent != RIGHT_SIDE_PREVIEW_CONTENT_TYPE.CONTENT_DIFF
      );
    },
    showSaveInprogressDialog() {
      return this.lessonPlanLoadingStatus.status == LESSON_PLAN_LOADING_STATES.inProgress;
    },
    fileValidators() {
      const FILE_SIZE_50_MB = 50 * 1024 * 1024;
      const FILE_SIZE_200_MB = 200 * 1024 * 1024;
      const error200mb = 'File size must be < 200 MB.';
      const error50mb = 'File size must be < 50 MB.';
      const defaultValidator = (file) => (file && file.size > FILE_SIZE_50_MB ? error50mb : true);

      if (this.subjectName == 'English') {
        return [
          (file) => {
            if (file) {
              if (file.type.includes('video/')) {
                if (file.size > FILE_SIZE_200_MB) {
                  return error200mb;
                }
              } else {
                if (file.size > FILE_SIZE_50_MB) {
                  return error50mb;
                }
              }
            }
          },
        ];
      } else {
        return [
          (file) => {
            if (file) {
              if (file.type.includes('video/')) {
                if (file.size > FILE_SIZE_200_MB) {
                  return error200mb;
                }
              } else {
                defaultValidator;
              }
            }
          },
        ];
      }
    },
  },
  watch: {
    currentStage: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          if (
            val &&
            val.length &&
            this.currentStageWatcher &&
            this.currentStageWatcher.length &&
            val != this.currentStageWatcher
          ) {
            this.reSetLessonPlanFormState();
            this.redirectToPreviousPage();
          } else {
            let currentStageIndex = Object.keys(LP_FORM_SUBMIT_TEXT).indexOf(val);
            this.currentStageDetails = {
              index: currentStageIndex,
              stageName: this.LP_STAGES[val],
            };
            //let assetUploadStageIndex = Object.keys(this.LP_STAGES).indexOf('assetUploadStage');
            this.showImageBrief = true;
            this.isAssetUploadRequired = getAssetUploadRequiredStatus(val);
            this.acceptableFileType = getAcceptableFileType(this.isAssetUploadRequired);
            this.currentStageWatcher = val;
            this.canShowDiffIcon = currentStageIndex > 1;
          }
        }
      },
    },
    lessonPlanData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          if (this.$route.query.template == 'practice') {
            if (val.application && !val.application.homework) {
              set(val.application, 'homework', this.PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA);
              set(
                this.signedUrls.application,
                'homework',
                this.PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA
              );
            }
          }

          if (this.firstCall) {
            this.lessonPlanDataCopy = JSON.parse(JSON.stringify(val));
            //This is specific to daily plan drama time
            if (this.isDramaTime) {
              if (val.action && !val.action.homework) {
                this.$set(this.lessonPlanData.action, 'homework', { description: '', assets: [] });
                this.$set(this.signedUrls.action, 'homework', { assets: [] });
              }
            } else {
              if (this.lessonPlanData.action && this.lessonPlanData.action.homework) {
                this.$delete(this.lessonPlanData.action, 'homework');
              }
              if (this.signedUrls.action && this.signedUrls.action.homework) {
                this.$delete(this.signedUrls.action, 'homework');
              }
            }
          }
          this.firstCall = false;
          this.lessonPlanWatcher = val;
          if (val.aim) {
            this.summaryData['aim'] = val.aim.summary;
          }
          if (val.action) {
            this.summaryData['action'] = val.action.summary;
          }
          if (val.analysis) {
            this.summaryData['analysis'] = val.analysis.summary;
          }
          if (val.application) {
            this.summaryData['application'] = val.application.summary;
          }
          if (val.assessment) {
            this.summaryData['assessment'] = val.assessment.summary;
            this.summaryData['assessmentOverall'] = val.assessment.overallSummary;
          }
          this.sendDataToPreview(val);
        }
      },
    },
    saveLessonPlanTriggerState: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.saveForm(false);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'getProjectDetails',
      'updateSummaryText',
      'addKeyword',
      'deleteKeyword',
      'addResource',
      'deleteResource',
      'addActionStep',
      'addActionSubStep',
      'deleteActionStep',
      'updateInstructionBreakValueOfStep',
      'addPractice',
      'deletePractice',
      'addAnalysisHigherOrderStep',
      'deleteAnalysisHigherOrderStep',
      'addAnalysisGeneralizedDiscussionStep',
      'deleteAnalysisGeneralizedDiscussionStep',
      'addAnalysisActivityDiscussionStep',
      'addAnalysisDiscussionStep',
      'addAnalysisInstructionStep',
      'deleteAnalysisActivityDiscussionStep',
      'deleteDiscussionStepInstruction',
      'deleteAnalysisDiscussionStep',
      'addAnaysisExplanationAssets',
      'deleteAnaysisExplanationAssets',
      'addReference',
      'deleteReference',
      'deleteActionSubStep',
      'setParsedJsonToState',
      'importAssetsAction',
      'importSingleAssetAction',
      'deleteSingleAssetAction',
      'getLessonPlanData',
      'saveLessonPlanData',
      'downloadLPAssets',
      'setLibraryId',
      'moveToNextStage',
      'reSetLessonPlanFormState',
      'fetchProjectLessonPlanSectionComment',
      'saveComment',
      'resolveComment',
      'getLessonPlanSaveAndMoveToNextStagePermission',
      'updateBriefText',
      'updateTypeOfBlock',
      'projectLessonPlanDiffData',
      'setRightSidePreviewContent',
      'moveToPrevioustage',
      'copySingleAssetAction',
      'addAnalysisSubStep',
      'toggleRightSidePanel',
      'setRightSidePanelVisibility',
      'validateLessonPlanData',
      'activateLessonPlanAndPublishToTeacherReadinessApp',
      'getCurrentUserAuthPermissions',
      'addInstructionStepForPath',
    ]),
    getFileNameFromUrl,
    getAcceptableFileType,
    iframeLoad() {
      this.sendDataToPreview(this.lessonPlanWatcher);
    },
    redirectToPreviousPage() {
      this.$router.push(this.redirectionPathObject);
    },
    setRedirectRoute() {
      this.redirectionPathObject = {
        path: '/projects/' + this.$route.params.projectId,
      };
    },
    sendDataToPreview(data) {
      let awsSignedUrls = this.signedUrls || {};
      let lpStaticTexts = this.staticTexts || {};

      var iframe = this.$refs['lp-preview-iframe'];
      if (data != null && iframe) {
        const doc = this.getIframeWindow1(iframe);
        const post = JSON.parse(
          JSON.stringify({
            data: {
              ...data,
              title: this.lessonPlan.name,
              type: this.$route.query.lpType,
            },
            awsSignedUrls,
            displayBriefUrl: !this.isAssetUploadRequired,
            lpStaticTexts,
          })
        );
        console.log('called from mixin');
        setTimeout(function () {
          doc.postMessage(post, '*');
        }, 2000);
      }
    },
    getIframeWindow1(iframe) {
      var doc;
      if (iframe && iframe.contentWindow) {
        return iframe.contentWindow;
      }
      if (iframe.window) {
        return iframe.window;
      }
      if (!doc && iframe.contentDocument) {
        doc = iframe.contentDocument;
      }
      if (!doc && iframe.document) {
        doc = iframe.document;
      }
      if (doc && doc.defaultView) {
        return doc.defaultView;
      }
      if (doc && doc.parentWindow) {
        return doc.parentWindow;
      }
      return undefined;
    },
    addEventLisnerForWindowUnLoad() {
      window.onbeforeunload = this.closeIt;
      const inputs = document.querySelectorAll('input');
      for (let input of inputs) {
        input.change = () => {
          this.unsavedChanges = true;
        };
      }
    },
    isBlockToBeHidden(blockName) {
      return !this.blocksToHide.includes(blockName);
    },
    closeIt() {
      if (this.unsavedChanges) {
        return 'You have unsaved changes in this page ';
      }
    },
    importAssets(file) {
      if (file.length) {
        this.importAssetsAction(file);
      }
    },
    copyLessonPlanCoverImage(previewUrl, briefUrl, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        isAssetApproved,
      });
    },
    importLessonPlanCoverImage(file) {
      if (file && file.name && file.name.length) {
        this.importSingleAssetAction({
          file,
          assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        });
      } else if (file == null) {
        this.deleteSingleAssetAction({
          assetId: this.isAssetUploadRequired
            ? this.lessonPlanData.assetId
            : this.lessonPlanData.brief.assetId,
          fieldIdentifier: this.isAssetUploadRequired ? 'assetId' : 'brief.assetId',
          assetType: AssetTypes.COVER_IMAGE_ASSET_TYPE,
        });
      }
    },
    reassignFormInputs(form) {
      const inputs = [];
      // Copied from VForm's previous life* which had a getInputs() function
      const search = (children, depth = 0) => {
        for (let index = 0; index < children.length; index++) {
          const child = children[index];
          if (child.errorBucket !== undefined) inputs.push(child);
          else search(child.$children, depth + 1);
        }
        if (depth === 0) return inputs;
      };
      search(form.$children);
      form.inputs = inputs;
    },
    downloadAsset() {
      this.downloadLPAssets({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
      });
    },
    saveForm() {
      this.lessonPlanDataCopy = JSON.parse(JSON.stringify(this.lessonPlan.data));
      if (this.lessonPlan.name) {
        this.unsavedChanges = false;
        this.saveLessonPlanData({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          data: this.lessonPlan,
        });
      }
    },
    validateRTEText() {
      return Object.keys(this.rteValidator).filter((_) => _ != '').length == 0;
    },
    handleValidateForm() {
      this.prepareLessonPlanDataToSave();
      const validationStatus = this.validateLPForm();

      if (validationStatus) {
        this.unsavedChanges = false;
        this.validateLessonPlanData({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    async activateContent() {
      if (!this.isAdmin) {
        this.$notify({
          title: 'Failed',
          text: 'Admin can only activate a Lesson Plan.',
          type: 'warn',
        });
        return;
      }
      this.activateLessonPlanAndPublishToTeacherReadinessApp({
        projectId: this.$route.params.projectId,
        libraryId: this.$route.params.libraryId,
      });
    },
    validateLPForm() {
      this.reassignFormInputs(this.$refs.form);
      this.validateLPData(this.lessonPlan);
      if (
        this.$refs.form.validate() &&
        this.customValidations() &&
        this.resourcesError.length == 0 &&
        this.assetAuthorizingStageValidation() &&
        this.validateRTEText()
      ) {
        return true;
      } else {
        return false;
      }
    },
    assetAuthorizingStageValidation() {
      if (this.currentStage == 'assetAuthorizingStage') {
        return this.unApprovedAssetValidation();
      }
      return true;
    },
    unApprovedAssetValidation() {
      return true;
    },
    prepareLessonPlanDataToSave() {
      // remove loadingStatus from resource object..
      let resources = [];
      this.lessonPlan.data.aim.resources.forEach((resource) => {
        delete resource.loadingStatus;
        resources.push(resource);
      });

      this.lessonPlan.data.aim.resources = resources;

      // set placeholder image for action instructions videos
      for (let ins of this.lessonPlan.data.action.instructions) {
        if (ins.assets && (ins.assets[0] || {}).type == 'video') {
          ins.assets[0].placeholder.assetId = this.lessonPlan.data.assetId;
        }
        let assets = [];

        for (let asset of ins.assets || []) {
          delete asset.loadingStatus;
          assets.push(asset);
        }
        ins.assets = assets;
      }

      for (let _discussionName of [
        'activity_discussion',
        'generalized_discussion',
        'higher_order_thinking',
      ]) {
        let explanationData = [];
        this.lessonPlan.data.analysis[_discussionName].explanations.forEach(function (
          _explanation
        ) {
          delete _explanation.loadingStatus;
          explanationData.push(_explanation);
        });

        this.lessonPlan.data.analysis[_discussionName].explanations = explanationData;
      }

      if (
        this.lessonPlan.data.application.more_practice &&
        this.lessonPlan.data.application.more_practice.length > 0
      ) {
        // set placeholder image for more_practice instructions videos
        for (let ins of this.lessonPlan.data.application.more_practice) {
          if (ins.assets && (ins.assets[0] || {}).type == 'video') {
            ins.assets[0].placeholder.assetId = this.lessonPlan.data.assetId;
          }
          let assets = [];

          for (let asset of ins.assets || []) {
            delete asset.loadingStatus;
            assets.push(asset);
          }
          ins.assets = assets;
        }
      }

      this.lessonPlan.data.assessment.check.description = this.lessonPlan.data.application.classwork.description;
      this.lessonPlan.data.assessment.check.question.brief.text = this.lessonPlan.data.application.homework.question.brief.text;
    },
    customValidations() {
      try {
        let lp = this.lessonPlan;

        if (!lp) throw Error('Invalid LessonPlan');
        if (!lp.data) throw Error('Invalid LessonPlan Data');

        if (!lp.data.lp_title || !lp.data.lp_description)
          throw Error('Title or Description cannot be empty');

        if (!lp.data.analysis) throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS');
        if (!lp.data.analysis.activity_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD');
        if (!lp.data.analysis.activity_discussion.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_AD_EXPLANATION');
        if (!lp.data.analysis.generalized_discussion)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD');
        if (!lp.data.analysis.generalized_discussion.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_GD_EXPLANATION');
        if (!lp.data.analysis.higher_order_thinking)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS');
        if (!lp.data.analysis.higher_order_thinking.explanations)
          throw Error('Invalid LessonPlan Data. CODE: EMP_ANALYSIS_HOTS_EXPLANATION');

        // Atleast one of the AD, GD, HOTS should have the explanations image and content.
        let isADHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.activity_discussion.explanations
        );
        let isGDHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.generalized_discussion.explanations
        );
        let isHOTSHasExplanationData = this.isValidExplanationDataInAnalysisStepDiscussions(
          lp.data.analysis.higher_order_thinking.explanations
        );
        if (!(isADHasExplanationData || isGDHasExplanationData || isHOTSHasExplanationData))
          throw Error(
            'Invalid LessonPlan Data. ERROR: Atleast one of Activity Discussion or Generalized Discussion or Higher Order Thinking should have valid explanations data.'
          );

        return true;
      } catch (exception) {
        console.log(exception);

        this.$notify({
          title: 'Validation error!',
          text: exception,
          type: 'warn',
          duration: 10000,
        });

        return false;
      }
    },
    isValidExplanationDataInAnalysisStepDiscussions(explanationHash) {
      if (explanationHash && explanationHash.length) {
        let { assetId } = explanationHash[0];
        if (this.isAssetUploadRequired) {
          return assetId && assetId.length > 0;
        } else {
          return true;
        }
      }

      return false;
    },
    getResourceValidationArray() {
      let validatePresence = [{ name: 'WORKSHEET', label: 'Worksheet' }];
      if (this.subjectName != 'English') {
        validatePresence.unshift({ name: 'CONTENT BOOK', label: 'Content book' });
      }
      return validatePresence;
    },
    validateLPData(lessonPlan) {
      let lessonPlanData = lessonPlan.data;
      let resources = lessonPlanData.aim.resources;
      let errorMessage = '';
      let errors = [];

      let validatePresence = this.getResourceValidationArray();

      validatePresence.forEach((_v) => {
        let index = resources.findIndex(
          (_t) => (_t.text || '').toUpperCase().indexOf(_v.name) !== -1
        );
        if (index == -1) {
          errors.push(`'${_v.label}'`);
        } else {
          if (
            this.isAssetUploadRequired &&
            (resources[index].assetId == null || resources[index].assetId.length == 0)
          ) {
            console.log(this.isAssetUploadRequired);
            errors.push(`${_v.label}'s image`);
          }
        }
      });

      if (errors.length > 0) {
        let suffix = (errors.length > 1 ? 'are' : 'is') + ' missing';
        errorMessage = `${errors.join(' and ')} ${suffix}`;
      }
      this.resourcesError = errorMessage;
    },
    importHtml(file) {
      if (file && file.name && file.name.length) {
        const fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onload = (result) => {
          this.importedHtmlFile = result.target.result;
        };
      }
    },
    iframeLoaded() {
      if (this.importedHtmlFile) {
        const doc = this.getIframeWindow(this.$refs['html-iframe']);
        const parsedJson = new LPParser(doc.document).parseHtml();
        console.log(parsedJson);
        this.setParsedJsonToState(parsedJson);
      }
    },
    getIframeWindow(iframe) {
      var doc;
      if (iframe && iframe.contentWindow) {
        return iframe.contentWindow;
      }
      if (iframe && iframe.window) {
        return iframe.window;
      }
      if (!doc && iframe && iframe.contentDocument) {
        doc = iframe.contentDocument;
      }
      if (!doc && iframe && iframe.document) {
        doc = iframe.document;
      }
      if (doc && doc.defaultView) {
        return doc.defaultView;
      }
      if (doc && doc.parentWindow) {
        return doc.parentWindow;
      }
      return undefined;
    },
    getNextStageButtonText() {
      let buttonText;
      const stages = Object.keys(this.LP_FORM_SUBMIT_TEXT);
      const nextStageIndex = stages.indexOf(this.currentStageWatcher || stages[0]) + 1;

      if (stages[nextStageIndex]) {
        buttonText =
          AppTexts.MOVE_TO_PREFIX_TEXT + this.LP_FORM_SUBMIT_TEXT[stages[nextStageIndex - 1]];
      } else {
        buttonText = 'Completed';
      }

      return buttonText;
    },
    onClickMoveToNextStage() {
      this.isSaved = true;
      const validationStatus = this.validateLPForm();
      this.prepareLessonPlanDataToSave();
      if (validationStatus) {
        let { projectId, libraryId } = this.$route.params;
        this.moveToNextStage({
          projectId,
          libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    onClickMoveToPreviousStage() {
      this.isSaved = true;
      const validationStatus = this.validateLPForm();
      this.prepareLessonPlanDataToSave();
      if (validationStatus) {
        let { projectId, libraryId } = this.$route.params;
        this.moveToPrevioustage({
          projectId,
          libraryId,
          data: this.lessonPlan,
        });
      } else {
        this.$notify({
          title: 'Failed',
          text: 'Lesson Plan validation failed',
          type: 'warn',
        });
      }
    },
    goToProjects() {
      this.$router.push({
        path: '/projects/' + this.$route.params.projectId,
      });
    },
    closeComments() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
      this.commentSectionIdentifier = '';
    },
    diffCloseHandler() {
      this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.PREVIEW_LP);
    },
    toggleComments(commentSectionIdentifier, event) {
      this.setRightSidePanelVisibility(true);

      if (event) {
        this.commentButtonPageYValue = `margin-top: ${event.pageY - 30}px;`;
      }
      if (
        commentSectionIdentifier == this.commentSectionIdentifier &&
        this.rightSidePreviewContent == RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS
      ) {
        return true;
      } else {
        this.setRightSidePreviewContent(RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS);
        this.commentSectionIdentifier = commentSectionIdentifier;
      }

      if (this.rightSidePreviewContent === RIGHT_SIDE_PREVIEW_CONTENT_TYPE.COMMENTS) {
        this.fetchProjectLessonPlanSectionComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: commentSectionIdentifier,
        });
      }
    },
    commentsSaveButtonClickHandler(text, stageName) {
      if (this.auth.canAddComment) {
        this.saveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.commentSectionIdentifier,
          text,
          stageName,
        });
      }
    },
    commentsResolveButtonClickHandler(stageName) {
      if (this.auth.canResolveComment) {
        this.resolveComment({
          projectId: this.$route.params.projectId,
          libraryId: this.$route.params.libraryId,
          sectionIdentifier: this.commentSectionIdentifier,
          stageName,
        });
      }
    },
    lessonPlanDetails() {
      let time = '35 mins';
      try {
        time = this.lessonPlan.data.time;
      } catch (error) {
        console.log(error.message);
      }

      return {
        title: this.lessonPlan.name,
        details: `${this.$route.query.boardName} - Grade ${this.$route.query.grade} - Block ${this.$route.query.blockTitle} (${this.$route.query.bId})`,
        time: time,
      };
    },
    updateTypeOfBlockToState(blockName, value) {
      this.updateTypeOfBlock({
        blockName,
        value,
      });
    },
    getCoverImageIsChangedValue() {
      try {
        if (this.canShowDiffIcon) {
          let imageKey = this.isAssetUploadRequired ? 'assetId' : 'brief.assetId';
          return get(this.diff, `${imageKey}.isChanged`);
        }
      } catch (err) {
        console.log(err.message);
      }

      return false;
    },
    getCoverImageBriefIsChangedValue() {
      try {
        if (this.canShowDiffIcon) {
          return this.diff.brief.isChanged === true;
        }
      } catch (err) {
        console.log(err.message);
      }

      return false;
    },
    getSummaryTitle() {
      let summaryText = '';
      let lpTimeKey = this.$route.query.lpTimeKey || '';

      if (lpTimeKey && lpTimeKey.length) {
        summaryText = this.lessonPlan.name;
      } else {
        summaryText = 'Teacher';
      }
      return (summaryText + ' summary').toUpperCase();
    },
  },
};
