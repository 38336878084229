import { apolloClient } from '@/apollo';
import router from '@/router';
import { getQuestionMetaInfo, instantiateContentEntryProjectData } from '@/apiQueries/contentEntry';
import {
  searchQuestionsQuery,
  virtualQuestionLessonPlanLinkDetailsQuery,
  unLinkQuestionsFromQuestionLessonMutation,
  downloadQuestions,
} from '@/apiQueries/question';
import { getAllBlocksForSelectedProductBoardStandardSubjectQuery } from '@/apiQueries/project';
import Vue from 'vue';
import _ from 'lodash';

const actions = {
  setQuestionInitialDataToStore({ commit }, data) {
    commit('SET_QUESTION_DATA', data);
  },
  async fetchQuestionMetaData({ commit, state }, { currentSubjectId }) {
    try {
      if (!state.questionMetaInfo) {
        let response = await apolloClient.query({
          query: getQuestionMetaInfo,
          variables: {
            subjectId: currentSubjectId,
          },
        });

        if (response && response.data) {
          commit('SET_QUESTION_META_INFORMATION', response.data.questionMetaData);
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  closeDialogAndClearQuestionData({ commit }) {
    commit('SET_SHOW_QUESTION_DIALOG', false);
    commit('SET_SHOW_GROUP_QUESTION_DIALOG', false);
    commit('SET_CURRENT_QUESTION_DIALOG_DATA', { data: null });
  },
  async instantiateContentEntryProjectData({ state, commit }, { type, data }) {
    try {
      commit('SET_QUESTION_DIALOG_LOADER', true);

      const response = await apolloClient.mutate({
        mutation: instantiateContentEntryProjectData(),
        variables: {
          type: type,
          data: data,
        },
      });
      if (response && response.data && response.data.instantiateContentEntryProjectData) {
        // after creation of questions, by default mark it as selecteded
        response.data.instantiateContentEntryProjectData.forEach(function (e) {
          e.isSelected = true;
        });
        let currentQuestionStateData = _.cloneDeep(state.questionData);
        currentQuestionStateData.push(...response.data.instantiateContentEntryProjectData);
        commit('SET_QUESTION_DATA', currentQuestionStateData);
        // commit(
        //   'SET_CURRENT_QUESTION_DIALOG_DATA',
        //   state.currentStageData[state.currentStageData.length - 1]
        // );
      } else {
        commit('SET_SHOW_QUESTION_DIALOG', false);
        commit('SET_SHOW_GROUP_QUESTION_DIALOG', false);
      }
      commit('SET_QUESTION_DIALOG_LOADER', false);
    } catch (e) {
      commit('SET_QUESTION_DIALOG_LOADER', false);
      commit('SET_SHOW_QUESTION_DIALOG', false);
      commit('SET_SHOW_GROUP_QUESTION_DIALOG', false);
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong, while creating question',
        type: 'error',
      });
    }
  },
  setQuestionDataInStore({ state, commit }, data) {
    let questionData = data.data;
    const questionIndex = _.findIndex(state.questionData, { _id: questionData._id });

    // Cloning the state to avoid reference issue
    let newStageData = _.cloneDeep(state.questionData);
    newStageData[questionIndex] = questionData;

    commit('SET_QUESTION_DATA', newStageData);
  },
  deleteContentEntryGroupQuestion({ state }, { questionGroupId, onSuccess }) {
    let i = 0;
    while (i < state.questionData.length) {
      if (state.questionData[i].questionGroupId == questionGroupId) {
        //skip incrementing the counter as vue.delete automatically moves array elements up
        Vue.delete(state.questionData, i);
      } else {
        i++;
      }
    }

    if (onSuccess) {
      onSuccess();
    }
  },
  deleteContentEntryGroupQuestionWithClone(
    { state },
    { questionGroupId, onSuccess, cloneNumber = 0 }
  ) {
    let i = 0;
    while (i < state.questionData.length) {
      if (
        state.questionData[i].questionGroupId == questionGroupId &&
        (!cloneNumber || state.questionData[i].cloneNumber == cloneNumber)
      ) {
        //skip incrementing the counter as vue.delete automatically moves array elements up
        Vue.delete(state.questionData, i);
      } else {
        i++;
      }
    }

    if (onSuccess) {
      onSuccess();
    }
  },
  async setGroupQuestionDataInStore({ state, commit }, { updatedQuestions, deletedQuestions }) {
    let newQuestionData = _.cloneDeep(state.questionData);
    for (let datum of updatedQuestions) {
      let questionIndex = state.questionData.findIndex((question) => question._id == datum._id);
      newQuestionData[questionIndex] = datum;
    }
    for (let del of deletedQuestions) {
      let quesitonIndex = state.questionData.findIndex((question) => question._id == del);
      Vue.delete(newQuestionData, quesitonIndex);
    }
    commit('SET_QUESTION_DATA', _.cloneDeep(newQuestionData));
  },
  async addQuestionsSelectedForReuseToQuestionDataInStore({ state, commit }, { questions }) {
    let newQuestionData = _.cloneDeep(state.questionData);
    for (let datum of questions) {
      newQuestionData.push(datum);
    }
    commit('SET_QUESTION_DATA', _.cloneDeep(newQuestionData));
  },
  deleteContentEntryQuestion({ state }, { questionId, onSuccess }) {
    for (let i = 0; i < state.questionData.length; i++) {
      if (state.questionData[i]._id == questionId) {
        Vue.delete(state.questionData, i);
      }
    }

    if (onSuccess) {
      onSuccess();
    }
  },
  questionForceSaveWithData({ state }, payload) {
    const { callback, validateForm, showLoader } = payload;
    callback({
      data: state.questionData,
      validateForm,
      showLoader,
    });
  },
  async getQuestionsBasedOnFilters({ commit }, payload) {
    try {
      commit('SET_SHOW_QUESTION_SEARCH_LOADER', true);
      const { blockId, filter } = payload;

      let response = await apolloClient.query({
        query: searchQuestionsQuery(),
        fetchPolicy: 'no-cache',
        variables: {
          blockId: parseInt(blockId),
          filter,
        },
      });

      if (response && response.data && response.data.searchQuestions) {
        commit('SET_SEARCHED_QUESTION', response.data.searchQuestions);
        commit('SET_SHOW_QUESTION_SEARCH_LOADER', false);
      } else {
        throw new Error('Something went wrong...');
      }
    } catch (err) {
      console.log(err);
      commit('SET_SEARCHED_QUESTION', []);
      commit('SET_SHOW_QUESTION_SEARCH_LOADER', false);
    }
  },
  clearSearchedQuestionsList({ commit }) {
    commit('SET_SEARCHED_QUESTION', []);
  },
  async loadQuestionDataForList({ commit, rootState }, { questionIds }) {
    try {
      commit('SET_QUESTION_DATA_FOR_LIST_LOADING_STATUS', true);
      const blockId = rootState.currentProject.block.id;

      let response = await apolloClient.query({
        query: searchQuestionsQuery(),
        fetchPolicy: 'no-cache',
        variables: {
          blockId: parseInt(blockId),
          filter: {
            questionIds,
          },
        },
      });

      if (response && response.data && response.data.searchQuestions) {
        commit('SET_QUESTION_DATA_FOR_LIST', response.data.searchQuestions);
        commit('SET_QUESTION_DATA_FOR_LIST_LOADING_STATUS', false);
      }
    } catch (error) {
      console.log(error);
      commit('SET_QUESTION_DATA_FOR_LIST', []);
      commit('SET_QUESTION_DATA_FOR_LIST_LOADING_STATUS', false);
    }
  },
  async onConfirmRemoveQuestion({ commit, dispatch }, { question, onSuccess }) {
    try {
      const routerParams = router.history.current.params;
      const projectId = routerParams.projectId;
      const libraryId = routerParams.libraryId;

      let questionIds;
      let entityIdNeedToRemove;
      let isQuestionGroup = false;

      commit('SET_REMOVE_QUESTION_LOADING_STATUS', true);
      if (Array.isArray(question)) {
        questionIds = question.map((q) => q._id);
        isQuestionGroup = true;
        entityIdNeedToRemove = question.find((q) => q.questionGroupId).questionGroupId;
      } else {
        questionIds = [question._id];
        entityIdNeedToRemove = questionIds[0];
      }

      let response = await apolloClient.query({
        query: virtualQuestionLessonPlanLinkDetailsQuery(),
        fetchPolicy: 'no-cache',
        variables: {
          projectId,
          libraryId: parseInt(libraryId),
          questionIds,
        },
      });

      if (response && response.data && response.data.virtualQuestionLessonPlanLinkDetails) {
        const linkedLPDetails = response.data.virtualQuestionLessonPlanLinkDetails;

        if (linkedLPDetails && linkedLPDetails.length) {
          commit('SET_UNLINK_AND_DELETE_QUESTION_CONFIRMATION_DIALOG_STATUS', {
            status: true,
            data: {
              linkedLPDetails,
              isQuestionGroup,
              entityIdNeedToRemove,
            },
          });
        } else {
          // Delete question
          if (isQuestionGroup) {
            dispatch('deleteContentEntryGroupQuestion', {
              questionGroupId: entityIdNeedToRemove,
              onSuccess,
            });
          } else {
            dispatch('deleteContentEntryQuestion', {
              questionId: entityIdNeedToRemove,
              onSuccess,
            });
          }
        }
      } else {
        throw new Error('Something went wrong...');
      }
      commit('SET_REMOVE_QUESTION_LOADING_STATUS', false);
    } catch (err) {
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong, while checking the question link',
        type: 'error',
      });

      console.log(err);
      commit('SET_REMOVE_QUESTION_LOADING_STATUS', false);
    }
  },
  async onConfirmRemoveLinkedQuestion({ commit, dispatch }, { payload, onSuccess }) {
    try {
      let dataToSent = [];
      const allQuestionIds = [];
      const _payload = Object.assign({}, payload);
      const data = _payload.linkedLPDetails;

      commit('SET_REMOVE_QUESTION_LOADING_STATUS', true);
      commit('SET_UNLINK_AND_DELETE_QUESTION_CONFIRMATION_DIALOG_STATUS', {
        status: false,
        data: {},
      });

      data.forEach((linkDetail) => {
        linkDetail.questionIds.forEach((questionId) => {
          allQuestionIds.push(questionId);

          dataToSent.push({
            questionId,
            projectId: linkDetail.projectId,
            lpId: linkDetail.lpId,
          });
        });
      });

      let response = await apolloClient.query({
        query: unLinkQuestionsFromQuestionLessonMutation(),
        fetchPolicy: 'no-cache',
        variables: {
          data: dataToSent,
        },
      });

      if (response && response.data && response.data.unLinkQuestionsFromQuestionLesson) {
        //Remove Question

        if (_payload.isQuestionGroup) {
          dispatch('deleteContentEntryGroupQuestion', {
            questionGroupId: _payload.entityIdNeedToRemove,
            onSuccess,
          });
        } else {
          dispatch('deleteContentEntryQuestion', {
            questionId: _payload.entityIdNeedToRemove,
            onSuccess,
          });
        }
      } else {
        throw Error('Request failed');
      }

      commit('SET_REMOVE_QUESTION_LOADING_STATUS', false);
    } catch (error) {
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong, while removing the question link',
        type: 'error',
      });

      console.log(error);
      commit('SET_REMOVE_QUESTION_LOADING_STATUS', false);
    }
  },
  async downloadQuestions({ commit }, payload) {
    try {
      commit('changeLoadingState', true);
      let response = await apolloClient.query({
        query: downloadQuestions(),
        fetchPolicy: 'no-cache',
        variables: payload,
      });
      if (response && response.data && response.data.downloadVirtualQuestions) {
        const baase64Response = response.data.downloadVirtualQuestions.base64FileContent;
        const fileExt = response.data.downloadVirtualQuestions.downloadExt;
        let mimeType = `application/${fileExt}`;
        if (fileExt == 'html') {
          mimeType = 'text/html';
        }
        let base64Content = `data:${mimeType};base64,${baase64Response}`;
        const blob = await (await fetch(base64Content)).blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${this.state.currentProject.block.bId}${Date.now()}`;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        throw Error('Request failed');
      }
    } catch (error) {
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong, while removing the question link',
        type: 'error',
      });

      console.log(error);
      commit('SET_REMOVE_QUESTION_LOADING_STATUS', false);
    }
    commit('changeLoadingState', false);
  },
  async getAllBlocksForSelectedProductBoardStandardSubject({ commit }, payload) {
    try {
      let { productId, boardName, standardId, subjectId } = payload;
      commit('changeLoadingState', true);

      const response = await apolloClient.query({
        query: getAllBlocksForSelectedProductBoardStandardSubjectQuery(
          productId,
          boardName,
          standardId,
          subjectId
        ),
        fetchPolicy: 'no-cache',
      });

      if (response && response.data) {
        commit('changeLoadingState', false);
        let blocks =
          response.data.getAllBlocksOnStandardSubjectBoardProductComboForProjectCreation || [];
        commit('GET_ALL_BLOCKS_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT', blocks);
      }
    } catch (er) {
      commit('changeLoadingState', false);
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong, while getting the block for selected filters',
        type: 'error',
      });
    }
  },
  async getAllBlocksForSelectedProductBoardStandardSubjectBlockId({ commit, state }, payload) {
    try {
      let { productId, boardName, standardId, subjectId, blockId } = payload;

      if (
        state.blockIdToBlockBIdMapping &&
        !Object.keys(state.blockIdToBlockBIdMapping).includes(blockId)
      ) {
        commit('changeLoadingState', true);
        const response = await apolloClient.query({
          query: getAllBlocksForSelectedProductBoardStandardSubjectQuery(
            productId,
            boardName,
            standardId,
            subjectId
          ),
          fetchPolicy: 'no-cache',
        });

        if (response && response.data) {
          commit('changeLoadingState', false);
          let blocks =
            response.data.getAllBlocksOnStandardSubjectBoardProductComboForProjectCreation || [];
          const block = blocks.find((block) => block.id === blockId);
          commit('GET_ALL_BLOCK_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT_BLOCK_ID', {
            blockId,
            block,
          });
        }
      }
    } catch (er) {
      commit('changeLoadingState', false);
      Vue.notify({
        title: 'Failed',
        text: 'Something went wrong, while getting the block for reused question',
        type: 'error',
      });
    }
  },
  closeWarningDialog({ commit }) {
    commit('SET_SHOW_WARNING_DIALOG', false);
  },
  showWarningDialog({ commit }) {
    commit('SET_SHOW_WARNING_DIALOG', true);
  },
};

export default actions;
