var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('BlockTitleWithSubtitle',{attrs:{"title":_vm.AppTexts.CHECK}}),_c('div',{staticClass:"mgn-Ts"},[_c('span',{staticClass:"workseet-text"},[_vm._v("Worksheet "+_vm._s(_vm.classworkDescription))]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pad-Rm",attrs:{"cols":"6"}},[_c('FileInput',{attrs:{"image_url":_vm.isAssetUploadRequired ? _vm.data.check.question.assetId : '',"image_url_brief":_vm.data.check.question.brief.assetId,"previewUrl":_vm.isAssetUploadRequired ? _vm.assessmentSignedUrls.check.question.assetSignedUrl : '',"previewUrlBrief":_vm.assessmentSignedUrls.check.question.brief.assetSignedUrl,"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getAssessmentCheckAttributesIsChangedValue(_vm.imageKey.questionUrl),"diffIdentifier":'assessment.check.' + _vm.imageKey.questionUrl,"fileAcceptType":_vm.acceptableFileType,"label":_vm.AppTexts.SELECT_QUESTION_FILE,"loadingStatus":_vm.uploadingStatus.student_workbook == _vm.FILE_UPLOADING_STATUS.IN_PROGRESS,"isRequired":true,"isAssetUploadRequired":_vm.isAssetUploadRequired,"fileChangeHandler":function (file) { return _vm.importAssessmentImage(
                file,
                _vm.AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE,
                _vm.imageKey.questionUrl
              ); },"isNotReadOnly":_vm.isNotReadOnly,"fileValidators":_vm.fileValidators,"isAssetApproved":_vm.data.check.question.assetApproved,"canAppproveAsset":_vm.canApproveAsset,"canFinalizeAsset":true},on:{"addBriefToAsset":function (previewUrl, briefUrl, isAssetApproved) { return _vm.copySectionImage(
                previewUrl,
                briefUrl,
                isAssetApproved,
                _vm.AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE
              ); }}})],1),_c('v-col',{staticClass:"pad-Lm",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"pad-top-none",attrs:{"dense":""}},[_c('v-col',{staticClass:"pad-top-none"},[_c('FileInput',{attrs:{"image_url":_vm.isAssetUploadRequired ? _vm.data.check.question_answer.assetId : '',"image_url_brief":_vm.data.check.question_answer.brief.assetId,"previewUrl":_vm.isAssetUploadRequired ? _vm.assessmentSignedUrls.check.question_answer.assetSignedUrl : '',"previewUrlBrief":_vm.assessmentSignedUrls.check.question_answer.brief.assetSignedUrl,"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getAssessmentCheckAttributesIsChangedValue(_vm.imageKey.answerUrl),"diffIdentifier":'assessment.check.' + _vm.imageKey.answerUrl,"fileAcceptType":_vm.acceptableFileType,"label":_vm.AppTexts.SELECT_ANSWER_FILE,"loadingStatus":_vm.uploadingStatus.student_workbook_answer == _vm.FILE_UPLOADING_STATUS.IN_PROGRESS,"isRequired":false,"isAssetUploadRequired":_vm.isAssetUploadRequired,"fileChangeHandler":function (file) { return _vm.importAssessmentImage(
                    file,
                    _vm.AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE,
                    _vm.imageKey.answerUrl
                  ); },"isNotReadOnly":_vm.isNotReadOnly,"fileValidators":_vm.fileValidators,"isAssetApproved":_vm.data.check.question_answer.assetApproved,"canAppproveAsset":_vm.canApproveAsset,"canFinalizeAsset":true},on:{"addBriefToAsset":function (previewUrl, briefUrl, isAssstApproved) { return _vm.copySectionImage(
                    previewUrl,
                    briefUrl,
                    isAssstApproved,
                    _vm.AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE
                  ); }}}),(_vm.showImageBrief)?_c('RichTextEditor',{staticClass:"mgn-Tm",attrs:{"canShowDiffIcon":_vm.canShowDiffIcon,"isDiffChanged":_vm.getAssessmentCheckAttributesIsChangedValue('answer_image_brief'),"fieldIdentifier":"assessment.check.question_answer.brief.text","placeholder":_vm.AppTexts.WORKSHEET_ANSWER_BRIEF,"showValidationError":true,"counter":3000,"readonly":!_vm.isNotReadOnly,"includeOnlyToolBarActions":_vm.BASIC_TOOLS},model:{value:(_vm.data.check.question_answer.brief.text),callback:function ($$v) {_vm.$set(_vm.data.check.question_answer.brief, "text", $$v)},expression:"data.check.question_answer.brief.text"}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }