<template>
  <v-container>
    <v-card>
      <!-- Card Title -->
      <v-card-title>
        {{ qrData.redirectUrl ? 'Edit' : 'Add New' }}
        {{ qrType === QR_TYPES.CONFIDENCE ? 'Confidence' : 'Computer Science' }} QR Code
      </v-card-title>

      <v-form ref="form" v-model="valid">
        <v-card-text>
          <!-- Fields for non-confidence QR Type -->
          <template v-if="qrType !== QR_TYPES.CONFIDENCE">
            <!-- Product Selection -->
            <v-select
              v-model="formData.product"
              :items="products"
              item-text="name"
              item-value="id"
              label="Product"
              required
              return-object
              :rules="[(v) => !!v || 'Product is required']"
              @change="onProductChange"
            ></v-select>

            <!-- Board Selection -->
            <v-select
              v-model="formData.board"
              :items="boards"
              item-text="name"
              item-value="id"
              label="Board"
              required
              return-object
              :rules="[(v) => !!v || 'Board is required']"
              @change="onBoardChange"
            ></v-select>
          </template>

          <!-- Grade Selection -->
          <v-select
            v-model="formData.grade"
            :items="standards"
            item-text="displayName"
            item-value="id"
            label="Grade"
            required
            return-object
            :rules="[(v) => !!v || 'Grade is required']"
            @change="onGradeChange"
          ></v-select>

          <!-- Subject Selection -->
          <v-select
            v-model="formData.subject"
            :items="getFilteredSubjects()"
            item-text="name"
            item-value="id"
            label="Subject"
            required
            return-object
            :rules="[(v) => !!v || 'Subject is required']"
            @change="onSubjectChange"
          ></v-select>

          <!-- Year Selection for Confidence QR -->
          <v-select
            v-if="qrType === QR_TYPES.CONFIDENCE"
            v-model="formData.year"
            :items="years"
            label="Year"
            required
            :rules="[(v) => !!v || 'Year is required']"
          ></v-select>

          <!-- Block Selection -->
          <v-select
            v-model="formData.block"
            :items="localBlocks"
            :item-text="blockItemText"
            item-value="id"
            label="Block"
            :required="qrType !== QR_TYPES.CONFIDENCE"
            return-object
            :rules="qrType !== QR_TYPES.CONFIDENCE ? [(v) => !!v || 'Block is required'] : []"
          ></v-select>

          <!-- Redirect URL (only for non-confidence QR Type) -->
          <v-text-field
            v-if="qrType !== QR_TYPES.CONFIDENCE"
            v-model="formData.redirectUrl"
            label="Redirect URL"
            required
            :rules="[(v) => !!v || 'Redirect URL is required']"
          ></v-text-field>
        </v-card-text>

        <!-- Form Actions -->
        <v-card-actions>
          <!-- Button for Creating QR -->
          <v-btn
            v-if="!qrData.redirectUrl"
            color="primary"
            @click="() => this.submitForm(false)"
            :disabled="isCreateDisabled"
            >Generate QR</v-btn
          >
          <!-- Button for Updating QR -->
          <v-btn
            v-else
            color="primary"
            @click="() => this.submitForm(true)"
            :disabled="isUpdateDisabled"
            >Update QR Mapping</v-btn
          >
          <v-btn text @click="$emit('cancel')">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { QR_TYPES } from '../../constants/qrManagement';

export default {
  name: 'QRForm',
  props: {
    qrType: {
      type: String,
      required: true,
    },
    qrData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      QR_TYPES,
      valid: false, // Form validation status
      formData: {
        product: '', // Selected product
        board: '', // Selected board
        grade: '', // Selected grade
        subject: '', // Selected subject
        block: '', // Selected block
        year: '', // Selected year
        redirectUrl: '', // Redirect URL
      },
      localBlocks: [], // Blocks based on selected criteria
      years: ['2023', '2024', '2025', '2026', '2027', '2028', '2029'], // Predefined years
    };
  },
  computed: {
    ...mapState({
      standards() {
        return this.qrType === QR_TYPES.CONFIDENCE
          ? this.$store.state.qrManagement.confidenceGrades
          : this.$store.state.standards || [];
      },
      products: (state) => state.products || [],
      boards: (state) => state.boards || [],
    }),

    // Disable button for Create based on QR type
    isCreateDisabled() {
      if (this.qrType === QR_TYPES.CONFIDENCE) {
        return !(this.valid && this.formData.grade && this.formData.subject && this.formData.year);
      }
      return !(
        this.valid &&
        this.formData.product &&
        this.formData.board &&
        this.formData.grade &&
        this.formData.subject
      );
    },

    // Disable button for Update based on QR type
    isUpdateDisabled() {
      if (this.qrType === QR_TYPES.CONFIDENCE) {
        return !(this.valid && this.formData.grade && this.formData.subject && this.formData.year);
      }
      return !(
        this.valid &&
        this.formData.product &&
        this.formData.board &&
        this.formData.grade &&
        this.formData.subject
      );
    },
  },
  watch: {
    // Populate form data when `qrData` changes (for edit mode)
    qrData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          const grade = this.standards.find((grade) => grade.id === newData.grade) || {
            subjects: [],
          };
          const subject = grade.subjects.find((subject) => subject.id === newData.subject);
          this.formData = {
            ...newData,
            grade: { id: grade.id, name: grade.name, displayName: grade.displayName },
            subject: subject,
          };
        }
        this.fetchBlocks();
      },
    },
  },
  methods: {
    ...mapActions([
      'fetchAllBlocksForSelectedProductBoardStandardSubject',
      'fetchConfidenceBlocksForQR',
    ]),

    // Handle Product Selection
    onProductChange() {
      this.formData.board = '';
      this.formData.grade = '';
      this.formData.subject = '';
      this.formData.block = '';
      this.localBlocks = [];
    },

    // Handle Board Selection
    onBoardChange() {
      this.formData.grade = '';
      this.formData.subject = '';
      this.formData.block = '';
      this.localBlocks = [];
    },

    // Handle Grade Selection
    onGradeChange() {
      this.formData.subject = '';
      this.formData.block = '';
      this.localBlocks = [];
    },

    // Handle Subject Selection
    onSubjectChange() {
      this.localBlocks = [];
      this.formData.block = '';
      this.fetchBlocks();
    },

    // Format Block Display Text
    blockItemText(block) {
      if (block && block.bId && block.title) {
        return `${block.bId} - ${block.title}`;
      } else if (block && block.title) {
        return block.title;
      }
      return '';
    },

    // Filter Subjects Based on Selected Grade
    getFilteredSubjects() {
      const selectedGrade = this.formData.grade;
      const grade = this.standards.find((grade) => grade.id === selectedGrade.id);
      if (!grade || !grade.subjects) {
        return [];
      }
      return grade.subjects;
    },

    // Fetch Blocks Based on Criteria
    async fetchBlocks() {
      const { product, board, grade, subject } = this.formData;
      if (this.qrType === QR_TYPES.CONFIDENCE) {
        if (grade && subject) {
          const payload = {
            standardId: grade.id,
            subjectId: subject.id,
          };
          try {
            const blocks = await this.fetchConfidenceBlocksForQR(payload);
            this.localBlocks = blocks;
          } catch (error) {
            console.error('Error fetching blocks:', error);
            this.localBlocks = [];
          }
        } else {
          this.localBlocks = [];
        }
      } else {
        if (product && board && grade && subject) {
          const payload = {
            productId: product.id,
            boardName: board.name,
            standardId: grade.id,
            subjectId: subject.id,
          };
          try {
            const blocks = await this.fetchAllBlocksForSelectedProductBoardStandardSubject(payload);
            this.localBlocks = blocks;
          } catch (error) {
            console.error('Error fetching blocks:', error);
            this.localBlocks = [];
          }
        } else {
          this.localBlocks = [];
        }
      }
    },

    // Submit Form
    submitForm(isEditFormData = false) {
      if (this.$refs.form.validate()) {
        const payload = {
          qrHash: this.qrData.qrHash,
          qrType: this.qrType,
          grade: this.formData.grade.id,
          subject: this.formData.subject.id,
          year: this.formData.year,
          block: this.formData.block,
          redirectUrl: this.formData.redirectUrl,
          board: this.formData.board,
          product: this.formData.product,
        };
        this.$emit(isEditFormData ? 'update' : 'submit', payload);
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 20px;
}
</style>
