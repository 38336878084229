export default function fileUpload(props, model) {
  return {
    name: 'fileUpload',
    model,
    props: {
      canShowDiffIcon: props.canShowDiffIcon,
      label: 'file upload',
      showImageBrief: props.showImageBrief,
      isAssetUploadRequired: props.isAssetUploadRequired,
      fileAcceptType: props.fileAcceptType,
      fileValidators: props.fileValidators,
      canFinalizeAsset: true,
      canAppproveAsset: props.canAppproveAsset,
      isAssetApproved: props.isAssetApproved,
      isRequired: true,
    },
  };
}
