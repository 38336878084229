<template>
  <div>
    <notifications style="margin-bottom: 15px;" position="bottom right" />
    <v-app-bar color="primary" dark>
      <v-toolbar-title>XSEED</v-toolbar-title>
    </v-app-bar>
    <v-app-bar>
      <v-btn icon>
        <v-icon>mdi-widgets</v-icon>
      </v-btn>
      <v-toolbar-title>Upload your work to XSEED</v-toolbar-title>
    </v-app-bar>
    <v-form>
      <v-row
        v-if="!!assetZipUploadBasicProjectInfo.name"
        style="padding: 0px 40px;"
        align="center"
        justify="center"
      >
        <v-col :cols="12">
          <div>
            <div class="topic-css">
              <div>
                <p>
                  <span>{{ assetZipUploadBasicProjectInfo.name + ' - ' + getProjectType }}</span>
                </p>
              </div>
              <div>
                <p style="font-size: 0.8em; font-weight: normal;">
                  Grade {{ assetZipUploadBasicProjectInfo.standard }} -
                  {{ assetZipUploadBasicProjectInfo.subject }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        style="padding: 0px 40px;"
        align="center"
        justify="center"
        v-if="
          validatedAssetZipUploaderState == 'success' &&
          !assetZipUploadBasicProjectInfo.sourceFilesUploaded
        "
      >
        <v-col :cols="12">
          <v-card>
            <v-app-bar
              :color="!assetZipUploadBasicProjectInfo.finalAssetsUploaded ? 'primary' : 'grey'"
              dense
            >
              <v-toolbar-title style="color: white;">Final Assets</v-toolbar-title>
            </v-app-bar>
            <div class="pa-6">
              <p>Upload the final asset zip file here</p>
              <AssetVaultUpload
                :key="fieldIdentifier"
                :preset="UPLOAD_FINAL_ASSETS_BY_VENDOR_PRESET"
                :appName="CA_APP_NAME"
                :assetResponseHook="
                  (info) => {
                    getAssetResponse(info, 'assetFile');
                  }
                "
                :acceptedFileTypes="[
                  'zip',
                  'application/octet-stream',
                  'application/zip',
                  'application/x-zip',
                  'application/x-zip-compressed',
                ]"
                placeholder="Upload final asset zip file"
                prependIcon="mdi-upload"
                :disabled="assetZipUploadBasicProjectInfo.finalAssetsUploaded"
                :clearFieldOnSuccess="false"
                :loading="finalAssetLoading"
                :acceptableFileSizeInMB="assetZipUploadBasicProjectInfo.acceptableFinalAssetSizeInMB"
              />
              <div
                style="color: #3ab54a;"
                class="mt-2"
                v-if="assetZipUploadBasicProjectInfo.finalAssetsUploaded"
              >
                <img
                  src="@/assets/greentick.svg"
                  style="position: relative; top: 7px;"
                  height="30"
                  width="30"
                />
                The file is uploaded successfully
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="12">
          <v-card>
            <v-app-bar
              :color="assetZipUploadBasicProjectInfo.finalAssetsUploaded ? 'primary' : 'grey'"
              dense
            >
              <v-toolbar-title style="color: white;">Source Files</v-toolbar-title>
            </v-app-bar>
            <div class="pa-6">
              <p>Upload the source files zip here</p>
              <AssetVaultUpload
                :key="fieldIdentifier"
                :preset="UPLOAD_SOURCE_ASSETS_BY_VENDOR_PRESET"
                :appName="CA_APP_NAME"
                :assetResponseHook="
                  (info) => {
                    getAssetResponse(info, 'sourceFile');
                  }
                "
                :acceptedFileTypes="[
                  'zip',
                  'application/octet-stream',
                  'application/zip',
                  'application/x-zip',
                  'application/x-zip-compressed',
                ]"
                placeholder="Upload source asset zip file"
                prependIcon="mdi-upload"
                outlined
                :disabled="!assetZipUploadBasicProjectInfo.finalAssetsUploaded"
                :clearFieldOnSuccess="false"
                :loading="sourceAssetLoading"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="canUploadAsset">
        <v-col class="pt-6" align="center">
          <img
            src="@/assets/greentick.svg"
            style="position: relative; top: 7px;"
            height="50"
            width="50"
          />
          <br />
          <br />
          <p>We have received your files successfully. Thank you!</p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="pt-6" align="center">
          <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
          <p>Loading Please wait...</p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { capitalCase } from 'change-case';
import AssetVaultUpload from '@/components/AssetVault/AssetVaultUpload';
import {
  CA_APP_NAME,
  UPLOAD_FINAL_ASSETS_BY_VENDOR_PRESET,
  UPLOAD_SOURCE_ASSETS_BY_VENDOR_PRESET,
} from '@/constants/centralizedAssetContants.js';

export default {
  name: 'AssetUploaderForm',
  data() {
    return {
      projectId: '',
      libraryId: 0,
      actualAsset: null,
      sourceFile: null,
      CA_APP_NAME,
      UPLOAD_FINAL_ASSETS_BY_VENDOR_PRESET,
      UPLOAD_SOURCE_ASSETS_BY_VENDOR_PRESET,
      finalAssetLoading: false,
      sourceAssetLoading: false,
    };
  },
  components: { AssetVaultUpload },
  computed: {
    ...mapState({
      assetZipUploaderStatus: (state) => state.contentEntry.assetZipUploaderStatus,
      validatedAssetZipUploaderState: (state) => state.contentEntry.validatedAssetZipUploaderState,
      assetZipUploadBasicProjectInfo: (state) => state.contentEntry.assetZipUploadBasicProjectInfo,
    }),
    getProjectType() {
      return this.capitalCase(this.assetZipUploadBasicProjectInfo.type);
    },
    canUploadAsset() {
      return (
        this.validatedAssetZipUploaderState == 'already_moved_to_next_stage' ||
        (this.assetZipUploaderStatus == 'completed' &&
          this.assetZipUploadBasicProjectInfo.sourceFilesUploaded &&
          this.assetZipUploadBasicProjectInfo.sourceFilesUploaded == true)
      );
    },
  },
  methods: {
    ...mapActions(['contentEntryAssetZipUpload', 'validateUploadAssetPageToken']),
    capitalCase,
    async getAssetResponse(info, type) {
      console.info(info);
      if (type == 'assetFile') {
        this.finalAssetLoading = true;
        await this.contentEntryAssetZipUpload({
          assetId: info._id,
          preSignedUrl: info.data.original,
          projectId: this.projectId,
          libraryId: this.libraryId,
          assetType: 'assetFile',
        });
      } else if (type == 'sourceFile') {
        this.sourceAssetLoading = true;
        await this.contentEntryAssetZipUpload({
          assetId: info._id,
          preSignedUrl: info.data.original,
          projectId: this.projectId,
          libraryId: this.libraryId,
          assetType: 'sourceFile',
        });
      } else {
        console.error('Invalid field type. Only assetFile or sourceFile is possible');
      }
      this.sourceAssetLoading = false;
      this.finalAssetLoading = false;
    },
  },
  created() {
    this.projectId = this.$route.query.projectId;
    this.libraryId = this.$route.query.libraryId;
    this.accessToken = this.$route.query.accessToken;
  },
  mounted() {
    this.validateUploadAssetPageToken({
      projectId: this.projectId,
      libraryId: this.libraryId,
    });
  },
};
</script>
