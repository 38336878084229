<template>
  <div>
    <v-row v-if="headerCheck">
      <v-checkbox
        v-model="initHeaderCheckbox"
        hide-details
        class="shrink mr-2 mt-0"
        @click.native.stop
        @change="(e) => $emit('headerCheckboxChange', e)"
        :ripple="false"
      >
        <template v-slot:label>
          <div>
            <b>{{ headerCheckText }}</b>
          </div>
        </template>
      </v-checkbox>
    </v-row>
    <v-row class="section-title-bar-inner-wrapper" no-gutters>
      <v-col class="section-title-text">{{ sectionTitle }}</v-col>
      <div v-if="(displayTrailing && displayTime) || olessonTimer" class="lesson-plan-time-wrapper">
        <img class="mgn-Rs" height="20" width="20" src="@/assets/time.svg" />
        <span class="lesson-plan-time">{{ time }}</span>
      </div>
      <div>
        <v-select
          class="lesson-plan-time lesson-plan-time-select"
          v-on:change="(val) => $emit('set-timer-input', val)"
          @click="
            (event) => {
              event.stopPropagation();
            }
          "
          :rules="[(v) => !!v || Errors.REQUIRED_FIELD]"
          :items="times"
          :value="time"
          v-if="enableTimerDropDown"
          placeholder="Select time"
          background-color="white"
          hide-details
          :disabled="!canEditTime || !isNotReadOnly"
          prepend-inner-icon="mdi-clock-outline"
          solo
          flat
          item-color="black"
        ></v-select>
      </div>

      <div v-if="displayTrailing && toggleComments" class="section-type-select-wrapper">
        <v-select
          class="section-type-select-field"
          v-on:change="(val) => $emit('update-type-of-block-to-state', val)"
          @click="
            (event) => {
              event.stopPropagation();
            }
          "
          :rules="[(v) => (v && v.length > 0) || Errors.REQUIRED_FIELD]"
          :items="ACTIVITY_TYPES"
          :value="types"
          placeholder="Select types"
          background-color="white"
          hide-details
          :disabled="!canEditType || !isNotReadOnly"
          solo
          flat
          multiple
        ></v-select>
      </div>
      <div class="comments-button-wrapper" v-if="displayComments && toggleComments">
        <!-- https://stackoverflow.com/a/46969113/12748022 -->
        <v-btn @click.stop="toggleComments" class="comment-btn">
          <span style="color: #fff">COMMENT</span>
        </v-btn>
      </div>
    </v-row>
  </div>
</template>

<script>
import Errors from '@/constants/Errors';
import { ACTIVITY_TYPES } from '@/constants/LessonPlan';
export default {
  name: 'ExpansionPanelHeader',
  data: () => {
    return {
      Errors,
      ACTIVITY_TYPES,
      times: ['5 mins', '6 mins', '7 mins', '8 mins', '9 mins', '10 mins'],
      initHeaderCheckbox: false,
    };
  },
  created() {
    this.initHeaderCheckbox = this.initialHeaderCheckValue;
  },
  props: {
    toggleComments: {
      type: Function,
      required: false,
    },
    sectionTitle: {
      type: String,
      required: true,
    },
    types: {
      type: Array,
      default: () => [],
    },
    canEditType: {
      type: Boolean,
      required: false,
      default: false,
    },
    time: {
      type: String,
      default: '',
    },
    displayTrailing: {
      type: Boolean,
      default: true,
      required: false,
    },
    displayComments: {
      type: Boolean,
      default: true,
      required: false,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    enableTimerDropDown: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayTime: {
      type: Boolean,
      required: false,
      default: true,
    },
    olessonTimer: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditTime: {
      type: Boolean,
      default: false,
    },
    headerCheck: {
      type: Boolean,
      default: false,
    },
    headerCheckText: {
      type: String,
      default: '',
    },
    initialHeaderCheckValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.lesson-plan-time-select {
  padding-top: 0px !important;
  margin-top: 0px !important;
  width: 150px;
  font-size: 16px !important;
}
.lesson-plan-time-select .v-input__control {
  min-height: 40px !important;
}
.section-title-text {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 20px;
}
.section-title-bar-inner-wrapper {
  display: flex;
  align-items: center;
}
.comment-btn {
  background-color: #177eee !important;
  font-weight: 500;
  font-size: 18px;
  height: 40px !important;
  float: right;
  box-shadow: none;
  border-radius: 2px;
  width: 170px;
  height: 40px;
}
.section-type-select-field {
  width:270px;
  height: 40px;
}
.section-type-select-field .v-input__control {
  min-height: 40px !important;
}
.lesson-plan-time-wrapper {
  background-color: #d1d1d1;
  height: 40px;
  width: 100px;
  margin-left: 10px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-type-select-wrapper {
  margin-left: 30px;
  overflow: hidden;
}
.comments-button-wrapper {
  margin-left: 30px;
}
</style>
