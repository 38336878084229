<template>
  <v-card
    v-if="!isLoading"
    class="mx-auto my-5 pa-5"
    outlined
    elevation="2"
    color="error lighten-5"
  >
    <v-card-title class="red--text darken-3">
      <v-icon left>mdi-alert</v-icon> Access Denied
    </v-card-title>

    <v-card-subtitle>
      {{ subTitleText }}
      Please check the following:
    </v-card-subtitle>

    <v-card-text>
      <ul>
        <li>Ensure you have the correct access rights.</li>
        <li>Contact the project administrator for permission.</li>
        <li>Review your role settings in your account profile.</li>
      </ul>
    </v-card-text>

    <v-card-actions>
      <v-btn text color="secondary" @click="goBack"> Go Back </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UnAuthorizedCard',
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },
  props: {
    subTitleText: {
      type: String,
      default:
        'Unfortunately, you do not have the necessary permissions to author the Confidence project.',
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Projects' });
    },
  },
};
</script>
