<template>
  <div v-if="isConfidenceUser || isAdmin">
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <div v-if="computeIsReadOnly" id="read-only-mode-banner">
            <img src="@/assets/readonly.png" />
          </div>
          <div style="background-color: #fff0f0;">
            <div class="w-100 p-4 d-flex align-items-center justify-content-center">
              <v-col
                >̦
                <v-btn @click="goBack" color="primary" class="ml-4 mt-4">
                  <v-icon left>mdi-arrow-left</v-icon> Back
                </v-btn>
                <v-row class="justify-end px-16">
                  <v-btn
                    class="ml-5 primary"
                    depressed
                    color="blue"
                    medium
                    :disabled="computeIsReadOnly"
                    @click="() => updateBlockMethod()"
                  >
                    <v-icon left>mdi-content-save</v-icon> {{ AppTexts.SAVE_BLOCK_DETAILS }}</v-btn
                  >
                  <v-btn
                    v-if="
                      blockDetailsAndQuestionsData.currentStage == 'completedStage' &&
                      blockDetailsAndQuestionsData.workflowStatus == 'in_progress'
                    "
                    class="primary ml-2"
                    @click="onClickMoveToNextStage"
                    :disabled="isReadOnly"
                  >
                    Publish Project
                  </v-btn>
                </v-row>
                <v-row>
                  <!-- First column - 70% width -->
                  <v-col cols="7">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col>
                          <v-card class="pa-3 elevation-3" color="#f9f9f9" rounded="lg">
                            <v-text-field
                              label="Block Name"
                              variant="outlined"
                              v-model="blockDetailsAndQuestionsData.blockName"
                              dense
                              hide-details
                              :disabled="computeIsReadOnly"
                              color="primary"
                              class="mb-4"
                            ></v-text-field>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-container>
                        <v-row
                          v-for="(aim, index) in aimTexts"
                          :key="index"
                          class="aim-field"
                          align="center"
                        >
                          <v-col cols="11">
                            <v-text-field
                              v-model="aim.text"
                              :label="'Aim ' + (index + 1)"
                              :rules="[aimLimitRule]"
                              :disabled="computeIsReadOnly"
                              outlined
                              dense
                              hide-details
                            />
                          </v-col>
                          <v-col cols="1">
                            <v-btn
                              :disabled="computeIsReadOnly"
                              icon
                              @click="removeAim(index)"
                              color="red"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-btn
                          :disabled="!canAddMoreAim || computeIsReadOnly"
                          color="primary"
                          @click="addAim"
                          class="add-aim-btn"
                        >
                          <v-icon left>mdi-plus</v-icon>
                          {{ aimTexts.length === 0 ? 'Add Aim' : 'Add More Aim' }}
                        </v-btn>
                      </v-container>
                      <v-row class="mt-4">
                        <v-col>
                          <div class="topic-view-container-one">
                            <v-card>
                              <div class="main-header">
                                <div>Block Detail</div>
                                <div>Current Stage</div>
                                <div>Person Responsible</div>
                              </div>
                            </v-card>
                            <MicroTopicSingleView
                              :currentStage="LP_STAGES[blockDetailsAndQuestionsData.currentStage]"
                              :name="blockDetailsAndQuestionsData.blockName || ''"
                              :assignee="currentAssigneeDetails && currentAssigneeDetails.name"
                              :id="currentAssigneeDetails && currentAssigneeDetails.id"
                              :projectData="{
                                contents: [
                                  {
                                    currentStage: blockDetailsAndQuestionsData.currentStage,
                                  },
                                ],
                                assignees: blockDetailsAndQuestionsData.assignees,
                              }"
                              :isAdmin="isAdmin"
                              updatedDate="last edited 23 hours ago"
                              :showViewDelete="false"
                              :showOnlyLastUpdateTime="false"
                              columnType="three"
                              type=""
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>

                  <!-- Divider in center -->
                  <v-divider vertical class="mx-4"></v-divider>

                  <!-- Second column - 30% width -->
                  <v-col cols="3">
                    <ConfidenceThumbnailImage
                      :data="blockDetailsAndQuestions"
                      :previewUrl="blockDetailsAndQuestions.previewUrl"
                      :previewUrlBrief="blockDetailsAndQuestions.previewUrlBrief"
                      coverImagePlaceholder="Block Thumbnail Image"
                      :fileAcceptType="isAssetUploadRequired ? 'image/*' : 'image/*,.docx,.doc'"
                      :fileAcceptedTypes="isAssetUploadRequired ? 'image/*' : 'image/*,.docx,.doc'"
                      :isAssetUploadRequired="isAssetUploadRequired"
                      :fileChangeHandler="fileUploadChangeHandler"
                      :canShowDiffIcon="false"
                      :showImageBrief="true"
                      loadingStatus="not_started"
                      :isNotReadOnly="!computeIsReadOnly"
                      :addToAsset="addBlockBriefAssetToFinalAsset"
                      :canAppproveAsset="true"
                      :allowedToolBarActionWithTablesForRTE="[
                        'fontFamily',
                        'fontSize',
                        'unorderedList',
                        'orderedList',
                        'fontColor',
                        'bold',
                        'italic',
                        'underline',
                        'superscript',
                        'subscript',
                        'equation',
                        'table',
                        'fullScreen',
                      ]"
                      :validationFlag="true"
                      @assetApprovedByUser="approveBlockImageAsset"
                    />
                  </v-col>
                </v-row>

                <!---------------------------------------------------------------- Question Section -------------------------------------------------------------------------------->

                <!-- Create Question Section  -->
                <v-row class="col">
                  <v-col>
                    <v-card class="pa-4 rounded-lg">
                      <div class="mb-4">
                        <SubSection title="Create Question" />
                      </div>
                      <v-btn
                        x-large
                        :disabled="computeIsReadOnly"
                        color="primary"
                        class="mr-8"
                        width="320"
                        style="font-size: 18px;"
                        depressed
                        @click="createGroupQuestion"
                        >Group Question</v-btn
                      >
                      <v-btn
                        x-large
                        :disabled="computeIsReadOnly"
                        width="320"
                        class="mr-8"
                        color="primary"
                        @click="createIndividualQuestion"
                        style="font-size: 18px;"
                        depressed
                        >Individual Question</v-btn
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </div>
          <ConfidenceQuestionEdit
            v-if="isAddIndividualQuestion"
            :data="defaultQuestionData.mcq"
            :newlyCreatedQuestion="true"
            :changeInQuestionType="() => {}"
            :closeEditForm="
              () => {
                isAddIndividualQuestion = !isAddIndividualQuestion;
              }
            "
            :options="{
              isNotReadOnly: true,
              isReadOnly: computeIsReadOnly,
              isAssetUploadRequired: isAssetUploadRequired,
              canShowDiffIcon: true,
              fileAcceptType: 'image/*,.pdf',
              fileUploadingStatus: {},
              signedAssetUrls: null,
              errorOfRequired: 'This is a required field',
            }"
            :canAppproveAsset="true"
            :lpType="''"
            :lessonPlans="[]"
            :isLanguageSubject="false"
            :showIndividualQuestionCloseButton="true"
            :key="`keyForDialogContentInQuestionDialog ${0}`"
            :showMetaFields="true"
            :metadata="listOfMetaData"
            :selectedMetaDataProp="{}"
            :onMetaDataSelect="(value) => {}"
            :onQuestionChange="() => {}"
            :subSectionTitle="'Question'"
            :turnOffQuestionTextValidation="false"
            :saveForm="
              (data) => {
                onQuestionFormSave(data, true);
              }
            "
          />
          <div
            v-if="groupQuestionQuestions.length > 0 && currentEditGroupId === ''"
            class="text-end px-4 pt-4"
            :style="{ backgroundColor: 'rgba(248, 176, 6, 0.1)' }"
          >
            <!-- Group Question Top Actions -->
            <div>
              <v-btn color="primary my-8" text @click="closeEditForm(groupQuestionQuestions)"
                >Close<v-icon>mdi-close</v-icon></v-btn
              >
              <!-- Save Button -->
              <v-btn
                class="primary"
                text
                @click="
                  () => {
                    onQuestionFormSave(groupQuestionQuestions, true);
                  }
                "
              >
                <v-icon left>mdi-content-save</v-icon> Save
              </v-btn>
            </div>
            <v-row class="flex flex-col justify-start m-0 p-0">
              <div class="flex-col justify-start pl-2">
                <h2 class="my-4">Group Question</h2>
                <h4 class="text-start">Stimulus</h4>
              </div>
              <v-spacer></v-spacer>
            </v-row>

            <RichTextEditor
              :key="`groupQuestionStimulusText-${stimulusRevision}`"
              :readonly="false"
              :canShowDiffIcon="!newQuestionGroup"
              v-model="stimulus"
              placeholder="Enter question Stimulus"
              projectType="question"
              subType="group"
              :showValidationError="true"
              :includeOnlyToolBarActions="getToolBarActions()"
              @input="onChangeStimulusText"
              :isAssetUploadRequired="isAssetUploadRequired"
            />
            <div v-for="(question, index) in groupQuestionQuestions" :key="`question_${index}`">
              <ConfidenceQuestionEdit
                :data="question"
                :newlyCreatedQuestion="true"
                :changeInQuestionType="() => {}"
                :closeEditForm="() => {}"
                :showIndividualQuestionCloseButton="false"
                :onQuestionDelete="() => deleteQuestion(index)"
                :options="{
                  isNotReadOnly: !computeIsReadOnly,
                  isReadOnly: computeIsReadOnly,
                  isAssetUploadRequired: isAssetUploadRequired,
                  canShowDiffIcon: true,
                  fileAcceptType: 'image/*,.pdf',
                  fileUploadingStatus: {},
                  signedAssetUrls: null,
                  errorOfRequired: 'This is a required field',
                }"
                :canAppproveAsset="true"
                :lpType="''"
                :lessonPlans="[]"
                :isLanguageSubject="false"
                :key="`keyForDialogContentInQuestionDialog ${index}`"
                :showMetaFields="true"
                :metadata="listOfMetaData"
                :selectedMetaDataProp="{}"
                :subSectionTitle="'Question'"
                :turnOffQuestionTextValidation="false"
                :onQuestionChange="(updatedQuestion) => onQuestionUpdate(updatedQuestion, index)"
              />
            </div>

            <!-- Group Question Button Actions -->
            <div class="pb-4">
              <!-- Add More Question Button -->
              <v-btn class="primary my-8 mr-2" text @click="() => addMoreQuestion()">
                <v-icon left>mdi-plus</v-icon> Add More Question
              </v-btn>

              <!-- Save Button -->
              <v-btn
                class="primary"
                text
                @click="
                  () => {
                    onQuestionFormSave(groupQuestionQuestions, true);
                  }
                "
              >
                <v-icon left>mdi-content-save</v-icon> Save
              </v-btn>
            </div>
          </div>
          <!-- Questions List Section  -->
          <v-card class="pl-4 ml-2 mr-2 pb-4">
            <v-card-title>Questions List</v-card-title>
            <div v-for="(question, index) in processQuestionData" :key="index">
              <div v-if="!Array.isArray(question)">
                <!---------------------------- Individual Question Edit View ---------------------------->
                <template v-if="currentEditQuestionId === question.questionId">
                  <ConfidenceQuestionEdit
                    :data="question"
                    :newlyCreatedQuestion="true"
                    :changeInQuestionType="() => {}"
                    :closeEditForm="() => closeEditForm(question)"
                    :options="{
                      isNotReadOnly: true,
                      isReadOnly: computeIsReadOnly,
                      isAssetUploadRequired: isAssetUploadRequired,
                      canShowDiffIcon: true,
                      fileAcceptType: 'image/*,.pdf',
                      fileUploadingStatus: {},
                      signedAssetUrls: null,
                      errorOfRequired: 'This is a required field',
                    }"
                    :canAppproveAsset="true"
                    :lpType="''"
                    :lessonPlans="[]"
                    :isLanguageSubject="false"
                    :showIndividualQuestionCloseButton="true"
                    :key="`keyForDialogContentInQuestionDialog ${index}`"
                    :showMetaFields="true"
                    :metadata="listOfMetaData"
                    :selectedMetaDataProp="
                      Object.entries(question.metadata || {}).reduce(
                        (acc, [key, value]) =>
                          value && value._id ? { ...acc, [key]: value._id } : acc,
                        {}
                      )
                    "
                    :onQuestionChange="() => {}"
                    :subSectionTitle="'Question'"
                    :turnOffQuestionTextValidation="false"
                    :saveForm="(data) => onQuestionFormSave(data, false)"
                    :questionNumber="question.questionNumber"
                  />
                </template>
              </div>
              <div v-else class="py-4">
                <!---------------------------- Group Question Edit View ---------------------------->
                <div
                  v-if="isCurrentQuestionInEditMode(question) === true"
                  class="text-end px-4"
                  :style="{ backgroundColor: 'rgba(248, 176, 6, 0.1)' }"
                >
                  <v-btn color="primary my-8" text @click="closeEditForm(question)"
                    >Close<v-icon>mdi-close</v-icon></v-btn
                  >
                  <v-btn
                    class="primary my-8"
                    text
                    @click="() => onQuestionFormSave(groupQuestionQuestions, false)"
                    >Save</v-btn
                  >

                  <v-row class="flex flex-col justify-start">
                    <div class="flex-col justify-start">
                      <h2 class="my-4">Group Question</h2>
                      <h4 class=" ">Stimulus</h4>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="primary my-8 mx-8"
                      text
                      @click="() => addQuestionInGroupQuestion(groupQuestionQuestions)"
                      >Add More Question</v-btn
                    >
                  </v-row>
                  <RichTextEditor
                    :key="`groupQuestionStimulusText-${stimulusRevision}`"
                    :readonly="false"
                    :canShowDiffIcon="!newQuestionGroup"
                    v-model="stimulus"
                    placeholder="Enter question Stimulus"
                    projectType="question"
                    subType="group"
                    :showValidationError="true"
                    :includeOnlyToolBarActions="getToolBarActions()"
                    @input="onChangeStimulusText"
                    :isAssetUploadRequired="isAssetUploadRequired"
                  />
                  <div class="my-8"></div>
                  <div
                    v-for="(groupQuestion, groupQuestionIndex) in groupQuestionQuestions"
                    :key="`keyForContentInGroupQuestion-${index}-${groupQuestionIndex}`"
                  >
                    <template v-if="currentEditGroupId === groupQuestion.questionGroupId">
                      <ConfidenceQuestionEdit
                        :data="groupQuestion"
                        :newlyCreatedQuestion="true"
                        :changeInQuestionType="() => {}"
                        :closeEditForm="() => {}"
                        :saveForm="() => {}"
                        :closeDialog="() => closeEditForm(groupQuestion)"
                        :onQuestionDelete="
                          () => onQuestionRemove(groupQuestionQuestions, groupQuestionIndex, false)
                        "
                        :options="{
                          isNotReadOnly: !computeIsReadOnly,
                          isReadOnly: computeIsReadOnly,
                          isAssetUploadRequired: isAssetUploadRequired,
                          canShowDiffIcon: true,
                          fileAcceptType: 'image/*,.pdf',
                          fileUploadingStatus: {},
                          signedAssetUrls: null,
                          errorOfRequired: 'This is a required field',
                        }"
                        :canAppproveAsset="true"
                        :lpType="''"
                        :lessonPlans="[]"
                        :isLanguageSubject="false"
                        :showIndividualQuestionCloseButton="false"
                        :showMetaFields="true"
                        :metadata="listOfMetaData"
                        :selectedMetaDataProp="
                          Object.entries(groupQuestion.metadata || {}).reduce(
                            (acc, [key, value]) =>
                              value && value._id ? { ...acc, [key]: value._id } : acc,
                            {}
                          )
                        "
                        :subSectionTitle="'Question'"
                        :turnOffQuestionTextValidation="false"
                        :onQuestionChange="(data) => onQuestionUpdate(data, groupQuestionIndex)"
                        class="pb-4 ;"
                        :questionNumber="question.questionNumber"
                      />
                    </template>
                  </div>

                  <div class="pb-4">
                    <!-- Add More Question Button -->
                    <v-btn
                      class="primary my-8 mr-2"
                      text
                      @click="() => addQuestionInGroupQuestion(groupQuestionQuestions)"
                    >
                      <v-icon left>mdi-plus</v-icon> Add More Question
                    </v-btn>

                    <!-- Save Button -->
                    <v-btn
                      class="primary"
                      text
                      @click="
                        () => {
                          onQuestionFormSave(groupQuestionQuestions, false);
                        }
                      "
                    >
                      <v-icon left>mdi-content-save</v-icon> Save
                    </v-btn>
                  </div>
                </div>
              </div>

              <!---------------------------- Questions List Rendering ---------------------------->
              <XSQuestionRenderer
                v-if="isCurrentQuestionInEditMode(question) === false"
                :isConfidenceQuestion="true"
                :questionIndex="index"
                :datum="question"
                :isDialogView="false"
                :isLeanometerQuestionSetView="false"
                :isSchoolProgramQuestion="false"
                :minimalInfoPrefixText="'Question'"
                :options="{
                  isNotReadOnly: !computeIsReadOnly,
                  isReadOnly: computeIsReadOnly,
                  isAssetUploadRequired: isAssetUploadRequired,
                  canShowDiffIcon: true,
                  fileAcceptType: 'image/*,.pdf',
                  fileUploadingStatus: {},
                  signedAssetUrls: null,
                  errorOfRequired: 'This is a required field',
                }"
                :toggleComments="() => {}"
                :isViewOnly="computeIsReadOnly"
                :lessonPlans="[]"
                :canShowActionButtons="true"
                :showMinimalDetails="false"
                :shouldEmitDeleteAction="false"
                :questionMetaInfo="question.metadata"
                :boards="[]"
                :blockIdToBlockBIdMapping="{}"
                :questionDataFromState="questions"
                :getAllBlocksForSelectedProductBoardStandardSubjectBlockId="() => {}"
                :convertIndividualToGroupQuestion="() => {}"
                :getAnswerCriteria="() => {}"
                :deleteQuestion="() => onQuestionRemove(question, index, false)"
                :deleteGroupQuestion="() => onQuestionRemove(question, index, true)"
                :openGroupQuestionDialog="openGroupQuestionDialog"
                :openQuestionDialog="openQuestionDialog"
                :saveForm="() => {}"
                :lpType="''"
                :questionPurposeDisplayText="() => {}"
                :groupQuestionPreventIndividualQuestionDeletion="true"
                @question-preview-cliked="() => {}"
                @remove-question-clicked="(e) => $emit('remove-question-clicked', e)"
                :confidenceMetaDataKeyValueMapping="{
                  Aim: ['aim', 'text'],
                  'Cognitive Type': ['cognitiveType', 'name'],
                  Skill: ['skill', 'name'],
                  'Micro Topic': ['microTopic', 'title'],
                  Difficulty: ['difficulty', 'name'],
                  'NCF Standard': ['ncfStandard', 'code'],
                  'PISA Standard': ['pisaStandard', 'code'],
                  'Multi Competency': ['multiCompetency', 'text'],
                }"
              />
            </div>
          </v-card>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center m-10" style="margin: 100px;">
      <v-btn
        v-if="
          blockDetailsAndQuestionsData.currentStage == 'completedStage' &&
          blockDetailsAndQuestionsData.workflowStatus == 'in_progress'
        "
        class="primary m-10"
        width="300"
        @click="onClickMoveToNextStage"
        :disabled="isReadOnly"
      >
        Publish Project
      </v-btn>
      <v-btn
        v-else
        class="primary m-10"
        width="300"
        :disabled="computeIsReadOnly"
        @click="onClickMoveToNextStage"
      >
        Move Project To Next Stage
      </v-btn>
    </div>
  </div>
  <UnAuthorizedCard v-else />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MicroTopicSingleView from '@/components/contentEntry/microTopic/MicroTopicSingleView';
import { XSQuestionRenderer } from '@xseededucation/vue-question-display-component';
import ConfidenceThumbnailImage from '../../components/contentEntry/confidenceComponents/ConfidenceThumbnailImage.vue';
import AppTexts from '../../constants/AppTexts';
import UnAuthorizedCard from './UnAuthorizedCard.vue';
import SubSection from '../../components/inputs/SubSection.vue';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import ConfidenceQuestionEdit from '../../components/contentEntry/confidenceComponents/ConfidenceQuestionEdit.vue';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import { getImageUrlFromRte } from '@xseededucation/rte-helper-functions';
import { LP_STAGES, LP_ASSIGNEES } from '@/constants/LessonPlan';
import { getAssetUploadRequiredStatus } from '@/utils';
import { cloneDeep } from 'lodash';
import Vue from 'vue';

export default {
  name: 'QuestionList',
  components: {
    MicroTopicSingleView,
    XSQuestionRenderer,
    ConfidenceThumbnailImage,
    UnAuthorizedCard,
    SubSection,
    ConfidenceQuestionEdit,
    RichTextEditor,
  },
  watch: {
    fetchedMetaData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.listOfMetaData = val;
        this.listOfMetaData.aims = this.blockDetailsAndQuestionsData.aims;
      },
    },
    blockDetailsAndQuestionsData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val || !val.blockImage) return;
        this.blockDetailsAndQuestions.brief = val.blockImage.brief;
        this.blockDetailsAndQuestions.imageUrl = val.blockImage.image_url;
        this.blockDetailsAndQuestions.briefUrl = val.blockImage.brief_url;
        this.blockDetailsAndQuestions.previewUrl = val.blockImage.image_url;
        this.blockDetailsAndQuestions.assetId = val.blockImage.assetId;
        this.blockDetailsAndQuestions.briefAssetId = val.blockImage.briefAssetId;
        this.blockDetailsAndQuestions.previewUrlBrief = val.blockImage.brief_url;
        this.blockDetailsAndQuestions.imageUrlApproved = val.blockImage.imageUrlApproved;
        this.isAssetUploadRequired = this.getAssetUploadRequiredStatus(val.currentStage);
      },
    },
  },
  beforeCreate() {},
  created() {
    console.log('isAdmin: ' + this.isAdmin);
    console.log('isAcademicAdmin: ' + this.isAcademicAdmin);
    console.log('isConfidenceUser: ' + this.isConfidenceUser);
    this.checkIsConfidenceUser();
    this.getCurrentUserAuthPermissions();
    this.fetchConfidenceQuestions({ projectId: this.$route.params.id });
  },
  data() {
    return {
      AppTexts,
      defaultQuestionData: cloneDeep(DEFAULT_QUESTION_DATA),
      aims: [],
      LP_STAGES,
      LP_ASSIGNEES,
      aimLimitRule: (v) => v.length <= 30 || 'Aim must be less than 30 characters',
      blockDetailsAndQuestions: {
        brief: '',
        briefUrl: '',
        briefAssetId: '',
        assetId: '',
        imageUrl: '',
        imageTitle: '',
        imageUrlApproved: false,
        previewUrl: '',
        previewUrlBrief: '',
      },
      currentEditQuestionId: '',
      currentEditGroupId: '',
      isAddIndividualQuestion: false,
      isAddGroupQuestion: false,
      groupQuestionQuestions: [],
      stimulus: '',
      imagesInStimulusText: [],
      imageContentRevision: 0,
      stimulusRevision: 0,
      newQuestionGroup: false,
      listOfMetaData: this.fetchedMetaData,
      isReadOnly: this.computeIsReadOnly,
      isAssetUploadRequired: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      isAdmin: (state) => state.auth.isAdmin,
      currentProjectAuth: (state) => state.auth.project,
      isConfidenceUser: (state) => state.confidence.isConfidenceUser,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
      questions: (state) => state.confidence.fetchConfidenceQuestionsApiResponse.data.questions,
      blockDetailsAndQuestionsData: (state) =>
        state.confidence.fetchConfidenceQuestionsApiResponse.data,
      aimTexts: (state) => {
        return state.confidence.fetchConfidenceQuestionsApiResponse.data.aims || [];
      },
      fetchedMetaData: (state) => {
        return state.confidence.fetchConfidenceMetadataApiResponse.data;
      },
      loggedInUser: (state) => state.loggedInUser,
    }),
    getRTEAcceptedFileType() {
      return this.options.isAssetUploadRequired
        ? ['image/png', 'image/jpeg', 'video/mp4', 'audio/mpeg']
        : [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'video/mp4',
            'audio/mpeg',
          ];
    },
    canAddMoreAim() {
      // Check if all aim fields have been filled
      return this.aimTexts.every((aim) => aim.text.trim().length > 0);
    },
    computeIsReadOnly() {
      let isReadOnly = false;
      if (this.blockDetailsAndQuestionsData.currentStage == 'completedStage') {
        isReadOnly = true;
      }
      if (this.loggedInUser && !this.isAdmin) {
        let assignees = this.blockDetailsAndQuestionsData.assignees;
        if (assignees) {
          const currentStageAssigneeName = this.LP_ASSIGNEES[
            this.blockDetailsAndQuestionsData.currentStage
          ];
          const assignee = this.blockDetailsAndQuestionsData.assignees[currentStageAssigneeName];
          if (assignee && assignee._id !== this.loggedInUser.id) {
            isReadOnly = true;
          }
        }
      }
      return isReadOnly;
    },
    currentAssigneeDetails() {
      const assigneeDetails = {
        name: '',
        id: '',
      };
      if (this.loggedInUser) {
        let assignees = this.blockDetailsAndQuestionsData.assignees;
        if (assignees) {
          const currentStageAssigneeName = this.LP_ASSIGNEES[
            this.blockDetailsAndQuestionsData.currentStage
          ];
          const assignee = this.blockDetailsAndQuestionsData.assignees[currentStageAssigneeName];
          if (assignee && assignee._id) {
            assigneeDetails.name = assignee.name;
            assigneeDetails.id = assignee._id;
          }
        }
      }
      return assigneeDetails;
    },
    processQuestionData() {
      const questions = this.questions || [];

      // Step 1: Validate input
      if (!Array.isArray(questions)) {
        console.error('Invalid data format: questions must be an array.');
        return [];
      }

      // Step 2: Sort questions by `questionNumber.start`
      const sortedQuestions = questions.sort(
        (a, b) => (a.questionNumber?.start || 0) - (b.questionNumber?.start || 0)
      );

      // Step 3: Initialize containers for grouped and individual questions
      const groupedQuestions = {}; // Maps `questionGroupId` to an array of questions
      const finalQuestions = []; // Final result with individual questions and grouped questions as arrays

      // Step 4: Iterate through sorted questions
      sortedQuestions.forEach((question) => {
        if (question.questionGroupId) {
          // Grouped question logic
          if (!groupedQuestions[question.questionGroupId]) {
            groupedQuestions[question.questionGroupId] = [];
          }
          groupedQuestions[question.questionGroupId].push(question);
        } else {
          // Individual question logic
          finalQuestions.push(question); // Add directly to the final list
        }
      });

      // Step 5: Add grouped questions to the finalQuestions array
      Object.keys(groupedQuestions).forEach((groupId) => {
        const group = groupedQuestions[groupId];

        // Sort within the group by `questionSequenceInGroup`
        group.sort((a, b) => (a.questionSequenceInGroup || 0) - (b.questionSequenceInGroup || 0));

        // Add the entire group as an array to the final list
        finalQuestions.push(group);
      });

      // Step 6: Sort finalQuestions by `questionNumber.start`
      const result = finalQuestions.sort((a, b) => {
        const aStart = Array.isArray(a) ? a[0].questionNumber?.start : a.questionNumber?.start;
        const bStart = Array.isArray(b) ? b[0].questionNumber?.start : b.questionNumber?.start;
        return (aStart || 0) - (bStart || 0);
      });

      return result;
    },

    // Determine whether a question is currently being edited
    isEditing() {
      return !!this.currentEditQuestionId || !!this.currentEditGroupId;
    },
  },
  methods: {
    ...mapActions([
      'fetchConfidenceQuestions',
      'fetchConfidenceMetadata',
      'updateBlock',
      'checkIsConfidenceUser',
      'getCurrentUserAuthPermissions',
      'updateConfidenceQuestion',
      'validateProjectLessonPlanStageData',
      'moveProjectLessonPlanToNextStage',
      'saveProjectLessonPlanStageData',
      'deleteConfidenceQuestion',
      'publishConfidenceProject',
      'createConfidenceQuestion',
      'confidenceFileRemove',
      'uploadBlockImage',
    ]),
    getToolBarActions() {
      return [
        'fontFamily',
        'fontSize',
        'unorderedList',
        'orderedList',
        'fontColor',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'image',
        'fullScreen',
        'table',
      ];
    },
    goBack() {
      this.$router.go(-1);
    },
    addMoreQuestion() {
      this.groupQuestionQuestions.push(cloneDeep(DEFAULT_QUESTION_DATA.msq));
    },
    addBlockBriefAssetToFinalAsset(previewUrl, briefUrl, approveAsset, assetPath) {
      briefUrl;
      assetPath;
      this.blockDetailsAndQuestions.imageUrlApproved = approveAsset;
      this.blockDetailsAndQuestions.assetId = this.blockDetailsAndQuestions.briefAssetId;
      const blockImageData = {
        briefAssetId: this.blockDetailsAndQuestions.briefAssetId,
        assetId: this.blockDetailsAndQuestions.briefAssetId,
        imageUrlApproved: this.blockDetailsAndQuestions.imageUrlApproved,
      };
      this.updateBlockMethod(blockImageData);
    },
    approveBlockImageAsset(val) {
      this.blockDetailsAndQuestions.imageUrlApproved = val;
      const blockImageData = {
        brief: this.blockDetailsAndQuestions.brief,
        briefAssetId: this.blockDetailsAndQuestions.briefAssetId,
        assetId: this.blockDetailsAndQuestions.assetId,
        imageUrlApproved: this.blockDetailsAndQuestions.imageUrlApproved,
      };
      this.updateBlockMethod(blockImageData);
    },
    addQuestionInGroupQuestion(groupQuestion) {
      let addGroupQuestion = cloneDeep(DEFAULT_QUESTION_DATA.mcq);
      addGroupQuestion.questionGroupId = groupQuestion[0].questionGroupId;
      addGroupQuestion.stimulus = groupQuestion[0].stimulus;
      addGroupQuestion.metadata = {};
      this.groupQuestionQuestions.push(addGroupQuestion);
    },
    validateAndMoveProjectMethod() {
      // Check if blockName is not empty or null
      if (!this.blockDetailsAndQuestionsData.blockName) {
        Vue.notify({
          type: 'error',
          title: 'Validation Failed',
          text: 'Block Name cannot be empty. Please provide a valid Block Name.',
        });
        return;
      }
      // Check if questions length is greater than 1
      if (
        !this.blockDetailsAndQuestionsData.questions ||
        this.blockDetailsAndQuestionsData.questions.length <= 1
      ) {
        Vue.notify({
          type: 'error',
          title: 'Validation Failed',
          text: 'Please ensure there are more than one question to proceed.',
        });
        return;
      }

      if (this.blockDetailsAndQuestionsData.currentStage == 'completedStage') {
        this.publishConfidenceProject({
          projectId: this.$route.params.id,
        });
      } else {
        this.validateProjectLessonPlanStageData({
          projectId: this.$route.params.id,
          contentLibraryId: 0,
          isNextPublishStage: false,
        });
      }
    },

    updateBlockMethod(blockImageData) {
      // Check if blockName is not empty or null
      if (!this.blockDetailsAndQuestionsData.blockName) {
        Vue.notify({
          type: 'error',
          title: 'Validation Failed',
          text: 'Block Name cannot be empty. Please provide a valid Block Name.',
        });
        return;
      }
      const payload = {
        brief: this.blockDetailsAndQuestions.brief,
        briefAssetId: this.blockDetailsAndQuestions.briefAssetId,
        assetId: this.blockDetailsAndQuestions.assetId,
        imageUrlApproved: this.blockDetailsAndQuestions.imageUrlApproved,
      };
      this.updateBlock({
        projectId: this.$route.params.id,
        blockName: this.blockDetailsAndQuestionsData.blockName,
        aims: this.aimTexts,
        blockImage: blockImageData ? blockImageData : payload,
      });
    },
    onQuestionRemove(question, index, isMultipleQuestionDelete) {
      // Check if deletion is allowed
      if (this.computeIsReadOnly) {
        Vue.notify({
          title: 'Failed',
          text: 'You are not allowed to delete this question',
          type: 'error',
        });
        return;
      }
      console.log('delete question operation');
      const isGroupQuestion = Array.isArray(question);
      if (isGroupQuestion) {
        if (isMultipleQuestionDelete || question[index].questionId) {
          this.deleteConfidenceQuestion({
            projectId: this.$route.params.id,
            questionId: isMultipleQuestionDelete ? '' : question[index].questionId,
            questionGroupId: isMultipleQuestionDelete ? question[0].questionGroupId : '',
          });
        }
        this.deleteQuestion(index);
      } else {
        this.deleteConfidenceQuestion({
          projectId: this.$route.params.id,
          questionId: question.questionId,
          questionGroupId: '',
        });
      }
    },
    onQuestionUpdate(question, index) {
      console.log('questionUpdated-On-Index' + index);
      this.groupQuestionQuestions[index] = question;

      // Find the first valid questionGroupId in the groupQuestionQuestions array
      let questionGroupId = null;
      if (this.groupQuestionQuestions && this.groupQuestionQuestions.length) {
        for (let i = 0; i < this.groupQuestionQuestions.length; i++) {
          if (this.groupQuestionQuestions[i] && this.groupQuestionQuestions[i].questionGroupId) {
            questionGroupId = this.groupQuestionQuestions[i].questionGroupId;
            break;
          }
        }
      }

      // Assign the found questionGroupId to the updated question
      if (questionGroupId) {
        this.groupQuestionQuestions[index].questionGroupId = questionGroupId;
      }

      console.log(this.groupQuestionQuestions[index]);
    },
    deleteQuestion(index) {
      // Ensure the index is valid before deleting
      if (index > -1 && index < this.groupQuestionQuestions.length) {
        // Remove the question at the specified index
        this.groupQuestionQuestions.splice(index, 1);
      }
    },

    isCurrentQuestionInEditMode(question) {
      let isEditable = false;
      if (!Array.isArray(question)) {
        if (question.questionId === this.currentEditQuestionId) {
          isEditable = true;
        }
      } else {
        if (question[0]['questionGroupId'] === this.currentEditGroupId) {
          isEditable = true;
        }
      }
      return isEditable;
    },
    fileUploadChangeHandler(file, type, assetPath, index = -1) {
      if (file) {
        this.uploadBlockImage({
          file,
          data: {
            projectId: this.$route.params.id,
            blockName: this.blockDetailsAndQuestionsData.blockName,
            aims: this.blockDetailsAndQuestionsData.aims,
            blockImage: this.blockDetailsAndQuestionsData.blockImage,
            currentStage: this.blockDetailsAndQuestionsData.currentStage,
          },
          assetPath,
        });
      } else {
        this.confidenceFileRemove({
          url: assetPath,
          data: {
            projectId: this.$route.params.id,
            blockName: this.blockDetailsAndQuestionsData.blockName,
            aims: this.blockDetailsAndQuestionsData.aims,
            blockImage: this.blockDetailsAndQuestionsData.blockImage,
          },
          index,
        });
      }
    },
    addAim() {
      this.aimTexts.push({ text: '' });
    },
    removeAim(index) {
      this.aimTexts.splice(index, 1);
    },
    saveForm() {},
    onClickMoveToNextStage() {
      this.validateAndMoveProjectMethod();
    },
    createGroupQuestion() {
      this.groupQuestionQuestions.push(cloneDeep(DEFAULT_QUESTION_DATA.mcq));
    },
    createIndividualQuestion() {
      console.log('createIndividualQuestion');
      this.isAddIndividualQuestion = true;
    },
    openQuestionDialog(questionId) {
      console.log('openQuestionDialog');
      console.log(questionId.questionId);
      if (!this.currentEditGroupId) {
        // Allow editing if no group is being edited
        this.currentEditQuestionId = questionId.questionId;
      }
    },

    openGroupQuestionDialog(groupQuestion) {
      this.groupQuestionQuestions = [];
      this.groupQuestionQuestions = groupQuestion;
      console.log(groupQuestion[0].stimulus);
      this.stimulus = groupQuestion[0].stimulus;
      if (!this.currentEditQuestionId) {
        // Allow editing if no individual question is being edited
        this.currentEditGroupId = groupQuestion[0].questionGroupId;
      }
    },

    closeEditForm(_question) {
      console.log(_question);
      this.currentEditQuestionId = '';
      this.currentEditGroupId = '';
      this.groupQuestionQuestions = [];
    },
    onChangeStimulusText(jsonString) {
      console.log(jsonString);
      this.stimulus = jsonString;
      let jsonData = JSON.parse(jsonString);
      this.getImagesListFromStimulusText(jsonData);
    },
    getImagesListFromStimulusText(jsonData) {
      let imagesInStimulusText = [];
      getImageUrlFromRte(jsonData.content, imagesInStimulusText);
      this.imagesInStimulusText = imagesInStimulusText;
      this.imageContentRevision += 1;
    },

    // Modify how you handle form save to close the edit mode after saving
    onQuestionFormSave(question, isCreate) {
      // Helper function to validate a question object
      const validateQuestion = (_question) => {
        const errors = [];

        // Check if questionText is empty
        if (
          !_question.questionData?.questionText ||
          _question.questionData.questionText.trim() === ''
        ) {
          errors.push('Question text is required.');
        }

        // Check if metadata contains required fields
        const requiredMetadataFields = ['skill', 'difficulty', 'cognitiveType'];
        requiredMetadataFields.forEach((field) => {
          if (!_question.metadata?.[field]) {
            errors.push(`${field} is required in metadata and should be a string.`);
          }
        });

        // Notify the user if there are errors
        if (errors.length > 0) {
          Vue.notify({
            type: 'error',
            title: 'Validation Error',
            text: errors.join(' '),
          });
          return false;
        }
        return true;
      };

      // Helper function to process individual question object
      const processQuestion = (_question, index) => {
        // Validate question before processing
        if (!validateQuestion(_question)) {
          throw Error('Question Validation Failed');
        }

        return {
          questionId: _question.questionId,
          questionGroupId: _question.questionGroupId,
          stimulus: this.stimulus,
          questionSequenceInGroup: index + 1,
          academicYear: _question.academicYear,
          questionPurpose: _question.questionPurpose,
          difficulty: _question.difficulty,
          questionType: _question.questionType,
          // Reduce metadata to keep only entries with a valid questionId
          metadata:
            typeof _question.metadata.skill === 'string'
              ? _question.metadata
              : Object.entries(_question.metadata || {}).reduce(
                  (acc, [key, value]) => (value && value._id ? { ...acc, [key]: value._id } : acc),
                  {}
                ),
          // Fallback to empty object if questionData is null or undefined
          questionData: _question.questionData ?? {},
        };
      };

      // Determine if the question is a group (array) or a single question
      const isGroupQuestion = Array.isArray(question);

      // Process either a single question or a group of questions
      const questionData = isGroupQuestion
        ? this.groupQuestionQuestions.map(processQuestion).filter(Boolean) // Filter out invalid questions
        : [processQuestion(question)].filter(Boolean); // Filter out invalid question

      if (questionData.length === 0) {
        Vue.notify({
          group: 'notifications',
          type: 'error',
          title: 'Validation Error',
          text: 'No valid questions to save.',
        });
        return;
      }

      const listOfMetaData = this.listOfMetaData;
      // Ensure multiCompetency metadata is formatted correctly
      questionData.forEach((question) => {
        if (
          question.metadata.multiCompetency &&
          typeof question.metadata.multiCompetency !== 'object'
        ) {
          const multiCompetency = listOfMetaData.multiCompetencies.find(
            (item) => item._id === question.metadata.multiCompetency
          );
          if (!multiCompetency) {
            question.metadata.multiCompetency = null;
            return;
          } else {
            question.metadata.multiCompetency = {
              blockId: multiCompetency.block._id,
              _id: multiCompetency._id,
              text: multiCompetency.text,
            };
          }
        }
      });

      // Handle create or update operation
      if (isCreate) {
        this.createConfidenceQuestion({
          projectId: this.$route.params.id,
          questionData,
          isGroupQuestion,
        });
        this.isAddIndividualQuestion = false;

        Vue.notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Question(s) created successfully.',
        });
      } else {
        // Update the confidence question with project details and question data
        this.updateConfidenceQuestion({
          projectId: this.$route.params.id,
          isGroupQuestion,
          questionData,
        });

        // Close the edit form after saving
        this.closeEditForm();

        Vue.notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Question(s) updated successfully.',
        });
      }

      this.groupQuestionQuestions = [];
    },

    getAssetUploadRequiredStatus,
  },
  beforeDestroy() {
    this.$store.dispatch('resetAllConfidenceState');
  },
};
</script>

<style scoped lang="scss">
.wrapper-container {
  background-color: rgba(248, 176, 6, 0.1);
  border-radius: 5px;
}

.topic-view-container-one {
  .main-header {
    margin: 20px 15px 25px 15px;
    display: -webkit-box;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    height: 50px;
  }

  .main-header :nth-child(1) {
    width: 35%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  .main-header :nth-child(2) {
    width: 21%;
    border-right: 1px solid rgb(0, 0, 0, 0.2);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  .main-header :nth-child(3) {
    width: 22%;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
}

.add-aim-btn {
  margin-top: 16px;
  background-color: #428bca;
  color: white;
}

#read-only-mode-banner {
  position: fixed;
  z-index: 100;
}
#read-only-mode-banner img {
  opacity: 0.1;
  position: fixed;
}
#read-only-mode-banner img {
  transform: rotate(-20deg);
  top: 40vh;
  left: -5px;
  width: 96vw;
}
.title {
  height: 30px;
  width: 10px;
  font-size: 20px;
}
.items {
  align-items: center;
}
.boardwork-title {
  padding: 12px 0px;
}
.block-title {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
}
</style>
