import { SUBJECT_SELECTION_ACTION } from './subjectSelection';
import { BLOCK_MAP_ACTION } from './blockMap';
import { PROJECT_ACTION } from './project';
import { AUTH_ACTION } from './auth';
import { MICRO_TOPIC_ACTION } from './microTopic';
import { BLOCK_ACTION } from './block';

export const actions = {
  ...SUBJECT_SELECTION_ACTION,
  ...BLOCK_MAP_ACTION,
  ...PROJECT_ACTION,
  ...AUTH_ACTION,
  ...MICRO_TOPIC_ACTION,
  ...BLOCK_ACTION,
  changeLoadingState(context, payload) {
    context.commit('changeLoadingState', payload);
  },
  changeModule(context, moduleName) {
    context.commit('SET_CURRENT_MODULE', moduleName);
  },
};
