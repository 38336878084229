<template>
  <div id="block-maps-status">
    <div id="content">
      <div class="product-board-selector row">
        <div class="col-2">
          <v-select
            :label="AppTexts.SELECT_PRODUCT"
            v-model="selectedProduct"
            :items="products"
            :rules="[(v) => !!v || 'Product is required']"
            required
            item-text="name"
            item-value="id"
            class="select-board-dropdown"
            @change="selectProduct"
          />
        </div>
        <div class="col-2">
          <v-select
            :label="AppTexts.SELECT_BOARD"
            v-model="selectedBoard"
            :items="boards"
            :rules="[(v) => !!v || 'Board is required']"
            required
            item-text="name"
            item-value="id"
            class="select-board-dropdown"
            @change="selectBoard"
          />
        </div>
        <div class="col-2">
          <v-btn
            class="view-block-map-btn"
            :disabled="disableViewBlockMapButton"
            color="primary"
            @click="submitHandler()"
            >View Data</v-btn
          >
        </div>
        <div v-if="blockMaps.length > 0" class="total-count col-2">
          <v-label>Total Blockmaps : {{ filteredData().length }}</v-label>
        </div>
      </div>
      <div v-if="fetchStatus">
        <Loader :loadingText="AppTexts.FETCHING_DATA_TEXT" :loadingStatus="fetchStatus" />
      </div>
      <div v-else-if="blockMaps.length > 0">
        <v-data-table
          height="calc(100vh - 134px)"
          calculate-widths
          v-model="selected"
          fixed-header
          hide-default-footer
          multi-sort
          :headers="headers"
          :items="filteredData()"
          :items-per-page="200"
          item-key="name"
          class="elevation-1"
        >
          <template v-slot:[`header.grade`]="{ header }">
            <span>
              Grade
              <v-menu top :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-filter-variant</v-icon>
                </template>

                <v-select
                  flat
                  hide-details
                  hide-selected
                  multiple
                  clearable
                  @click.stop="(e) => e.preventDefault()"
                  :items="columnValueList(header.text)"
                  v-model="filters[header.value]"
                >
                </v-select>
              </v-menu>
            </span>
          </template>
          <template v-slot:[`header.subject`]="{ header }">
            <span>
              Subject
              <v-menu top :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-filter-variant</v-icon>
                </template>

                <v-select
                  flat
                  hide-details
                  small
                  multiple
                  clearable
                  @click.stop="(e) => e.preventDefault()"
                  :items="columnValueList(header.text)"
                  v-model="filters[header.value]"
                >
                </v-select>
              </v-menu>
            </span>
          </template>
          <template v-slot:[`item.fullyPublishedBlocks`]="{ item }">
            <span :class="getClassNameFullyPublished(item.totalBlocks, item.fullyPublishedBlocks)">
              {{ item.fullyPublishedBlocks }}</span
            >
          </template>
          <template v-slot:[`item.block1`]="{ item }">
            <span :class="getClassName(item.block1 || '')"> {{ item.block1 }}</span>
          </template>
          <template v-slot:[`item.block2`]="{ item }">
            <span :class="getClassName(item.block2 || '')"> {{ item.block2 }}</span>
          </template>
          <template v-slot:[`item.block3`]="{ item }">
            <span :class="getClassName(item.block3 || '')"> {{ item.block3 }}</span>
          </template>
          <template v-slot:[`item.block4`]="{ item }">
            <span :class="getClassName(item.block4 || '')"> {{ item.block4 }}</span>
          </template>
          <template v-slot:[`item.block5`]="{ item }">
            <span :class="getClassName(item.block5 || '')"> {{ item.block5 }}</span>
          </template>
          <template v-slot:[`item.block6`]="{ item }">
            <span :class="getClassName(item.block6 || '')"> {{ item.block6 }}</span>
          </template>
          <template v-slot:[`item.block7`]="{ item }">
            <span :class="getClassName(item.block7 || '')"> {{ item.block7 }}</span>
          </template>
          <template v-slot:[`item.block8`]="{ item }">
            <span :class="getClassName(item.block8 || '')"> {{ item.block8 }}</span>
          </template>
          <template v-slot:[`item.block9`]="{ item }">
            <span :class="getClassName(item.block9 || '')"> {{ item.block9 }}</span>
          </template>
          <template v-slot:[`item.block10`]="{ item }">
            <span :class="getClassName(item.block10 || '')"> {{ item.block10 }}</span>
          </template>
          <template v-slot:[`item.block11`]="{ item }">
            <span :class="getClassName(item.block11 || '')"> {{ item.block11 }}</span>
          </template>
          <template v-slot:[`item.block12`]="{ item }">
            <span :class="getClassName(item.block12 || '')"> {{ item.block12 }}</span>
          </template>
          <template v-slot:[`item.block13`]="{ item }">
            <span :class="getClassName(item.block13 || '')"> {{ item.block13 }}</span>
          </template>
          <template v-slot:[`item.block14`]="{ item }">
            <span :class="getClassName(item.block14 || '')"> {{ item.block14 }}</span>
          </template>
          <template v-slot:[`item.block15`]="{ item }">
            <span :class="getClassName(item.block15 || '')"> {{ item.block15 }}</span>
          </template>
          <template v-slot:[`item.block16`]="{ item }">
            <span :class="getClassName(item.block16 || '')"> {{ item.block16 }}</span>
          </template>
          <template v-slot:[`item.block17`]="{ item }">
            <span :class="getClassName(item.block17 || '')"> {{ item.block17 }}</span>
          </template>
          <template v-slot:[`item.block18`]="{ item }">
            <span :class="getClassName(item.block18 || '')"> {{ item.block18 }}</span>
          </template>
          <template v-slot:[`item.block19`]="{ item }">
            <span :class="getClassName(item.block19 || '')"> {{ item.block19 }}</span>
          </template>
          <template v-slot:[`item.block20`]="{ item }">
            <span :class="getClassName(item.block20 || '')"> {{ item.block20 }}</span>
          </template>
          <template v-slot:[`item.block21`]="{ item }">
            <span :class="getClassName(item.block21 || '')"> {{ item.block21 }}</span>
          </template>
          <template v-slot:[`item.block22`]="{ item }">
            <span :class="getClassName(item.block22 || '')"> {{ item.block22 }}</span>
          </template>
          <template v-slot:[`item.block23`]="{ item }">
            <span :class="getClassName(item.block23 || '')"> {{ item.block23 }}</span>
          </template>
          <template v-slot:[`item.block24`]="{ item }">
            <span :class="getClassName(item.block24 || '')"> {{ item.block24 }}</span>
          </template>
          <template v-slot:[`item.block25`]="{ item }">
            <span :class="getClassName(item.block25 || '')"> {{ item.block25 }}</span>
          </template>
          <template v-slot:[`item.block26`]="{ item }">
            <span :class="getClassName(item.block26 || '')"> {{ item.block26 }}</span>
          </template>
          <template v-slot:[`item.block27`]="{ item }">
            <span :class="getClassName(item.block27 || '')"> {{ item.block27 }}</span>
          </template>
          <template v-slot:[`item.block28`]="{ item }">
            <span :class="getClassName(item.block28 || '')"> {{ item.block28 }}</span>
          </template>
          <template v-slot:[`item.block29`]="{ item }">
            <span :class="getClassName(item.block29 || '')"> {{ item.block29 }}</span>
          </template>
          <template v-slot:[`item.block30`]="{ item }">
            <span :class="getClassName(item.block30 || '')"> {{ item.block30 }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader';
import { mapState, mapActions } from 'vuex';
import { API_FETCH_STATUS } from '@/constants/ApiFetchStatus';
import AppTexts from '@/constants/AppTexts';
import { sortBy, groupBy } from '@/functions/array';
import { EXHAUSTIVE_BLOCK_MAPS_LIST } from '@/constants/BlockMaps';
export default {
  name: 'BlockMapsStatus',
  components: { Loader },
  data() {
    return {
      data: {
        blockMaps: [],
      },
      selectedProduct: '',
      selectedBoard: '',
      fetchStatus: false,
      API_FETCH_STATUS,
      AppTexts,
      selected: [],
      headers: [
        {
          text: 'Grade',
          align: 'left',
          value: 'grade',
          fixed: true,
          width: '100px',
        },
        {
          text: 'Subject',
          align: 'left',
          value: 'subject',
          fixed: true,
          width: '100px',
        },
        { text: 'Num blocks', value: 'totalBlocks' },
        { text: 'Num fully published', value: 'fullyPublishedBlocks' },
        { text: 'Block 1', value: 'block1' },
        { text: 'Block 2', value: 'block2' },
        { text: 'Block 3', value: 'block3' },
        { text: 'Block 4', value: 'block4' },
        { text: 'Block 5', value: 'block5' },
        { text: 'Block 6', value: 'block6' },
        { text: 'Block 7', value: 'block7' },
        { text: 'Block 8', value: 'block8' },
        { text: 'Block 9', value: 'block9' },
        { text: 'Block 10', value: 'block10' },
        { text: 'Block 11', value: 'block11' },
        { text: 'Block 12', value: 'block12' },
        { text: 'Block 13', value: 'block13' },
        { text: 'Block 14', value: 'block14' },
        { text: 'Block 15', value: 'block15' },
        { text: 'Block 16', value: 'block16' },
        { text: 'Block 17', value: 'block17' },
        { text: 'Block 18', value: 'block18' },
        { text: 'Block 19', value: 'block19' },
        { text: 'Block 20', value: 'block20' },
        { text: 'Block 21', value: 'block21' },
        { text: 'Block 22', value: 'block22' },
        { text: 'Block 23', value: 'block23' },
        { text: 'Block 24', value: 'block24' },
        { text: 'Block 25', value: 'block25' },
        { text: 'Block 26', value: 'block26' },
        { text: 'Block 27', value: 'block27' },
        { text: 'Block 28', value: 'block28' },
        { text: 'Block 29', value: 'block29' },
        { text: 'Block 30', value: 'block30' },
      ],
      filters: {
        product: [],
        board: [],
        grade: [],
        subject: [],
      },
      pagination: {
        sortBy: 'name',
      },
    };
  },
  created() {
    //this.getBlockMapLPDP();
  },
  computed: {
    boards() {
      return this.columnValueList('Board');
    },
    products() {
      return this.columnValueList('Product');
    },
    disableViewBlockMapButton() {
      return !(this.selectedBoard && this.selectedProduct);
    },
    ...mapState({
      blockMaps: (state) => state.blockMaps,
    }),
  },
  watch: {
    blockMaps: {
      immediate: true,
      deep: true,
      handler(val) {
        this.blockMapList = EXHAUSTIVE_BLOCK_MAPS_LIST.filter(
          (x) => x.Product == this.selectedProduct && x.Board == this.selectedBoard
        );
        let lessonPlansGroupedByBlockMapId = groupBy(val, 'bmId');
        let groupedByBlockMaps = Object.keys(lessonPlansGroupedByBlockMapId).map((_blockMapId) => {
          const lessonPlansGroupedByBlockId = groupBy(
            sortBy(lessonPlansGroupedByBlockMapId[_blockMapId], 'sequence'),
            'bId'
          );
          let blocks = [];
          Object.keys(lessonPlansGroupedByBlockId).map((_blockId) => {
            blocks.push(lessonPlansGroupedByBlockId[_blockId]);
          });

          const Product = lessonPlansGroupedByBlockMapId[_blockMapId][0].Product;
          const Board = lessonPlansGroupedByBlockMapId[_blockMapId][0].Board;
          const Standard = lessonPlansGroupedByBlockMapId[_blockMapId][0].Standard;
          const Subject = lessonPlansGroupedByBlockMapId[_blockMapId][0].Subject;
          let unit = '';
          if (Standard == 'LKG' || Standard == 'UKG' || Standard == 'Nursery') {
            unit = ' DP';
          } else {
            unit = ' LP';
          }
          return {
            bmId: _blockMapId,
            product: Product,
            board: Board,
            grade: Standard,
            subject: Subject,
            totalBlocks: blocks.length,
            fullyPublishedBlocks: blocks.filter((x) => x.every((c) => c.status == 'completed'))
              .length,
            block1:
              blocks.length > 0
                ? blocks[0].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[0].length +
                  unit
                : '',
            block2:
              blocks.length > 1
                ? blocks[1].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[1].length +
                  unit
                : '',
            block3:
              blocks.length > 2
                ? blocks[2].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[2].length +
                  unit
                : '',
            block4:
              blocks.length > 3
                ? blocks[3].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[3].length +
                  unit
                : '',
            block5:
              blocks.length > 4
                ? blocks[4].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[4].length +
                  unit
                : '',
            block6:
              blocks.length > 5
                ? blocks[5].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[5].length +
                  unit
                : '',
            block7:
              blocks.length > 6
                ? blocks[6].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[6].length +
                  unit
                : '',
            block8:
              blocks.length > 7
                ? blocks[7].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[7].length +
                  unit
                : '',
            block9:
              blocks.length > 8
                ? blocks[8].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[8].length +
                  unit
                : '',
            block10:
              blocks.length > 9
                ? blocks[9].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[9].length +
                  unit
                : '',
            block11:
              blocks.length > 10
                ? blocks[10].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[10].length +
                  unit
                : '',
            block12:
              blocks.length > 11
                ? blocks[11].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[11].length +
                  unit
                : '',
            block13:
              blocks.length > 12
                ? blocks[12].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[12].length +
                  unit
                : '',
            block14:
              blocks.length > 13
                ? blocks[13].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[13].length +
                  unit
                : '',
            block15:
              blocks.length > 14
                ? blocks[14].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[14].length +
                  unit
                : '',
            block16:
              blocks.length > 15
                ? blocks[15].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[15].length +
                  unit
                : '',
            block17:
              blocks.length > 16
                ? blocks[16].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[16].length +
                  unit
                : '',
            block18:
              blocks.length > 17
                ? blocks[17].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[17].length +
                  unit
                : '',
            block19:
              blocks.length > 18
                ? blocks[18].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[18].length +
                  unit
                : '',
            block20:
              blocks.length > 19
                ? blocks[19].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[19].length +
                  unit
                : '',
            block21:
              blocks.length > 20
                ? blocks[20].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[20].length +
                  unit
                : '',
            block22:
              blocks.length > 21
                ? blocks[21].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[21].length +
                  unit
                : '',
            block23:
              blocks.length > 22
                ? blocks[22].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[22].length +
                  unit
                : '',
            block24:
              blocks.length > 23
                ? blocks[23].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[23].length +
                  unit
                : '',
            block25:
              blocks.length > 24
                ? blocks[24].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[24].length +
                  unit
                : '',
            block26:
              blocks.length > 25
                ? blocks[25].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[25].length +
                  unit
                : '',
            block27:
              blocks.length > 26
                ? blocks[26].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[26].length +
                  unit
                : '',
            block28:
              blocks.length > 27
                ? blocks[27].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[27].length +
                  unit
                : '',
            block29:
              blocks.length > 28
                ? blocks[28].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[28].length +
                  unit
                : '',
            block30:
              blocks.length > 29
                ? blocks[29].filter((c) => c.status == 'completed').length +
                  '/' +
                  blocks[29].length +
                  unit
                : '',
          };
        });

        this.blockMapsData = [];

        if (groupedByBlockMaps.length > 0) {
          for (let i = 0; i < this.blockMapList.length; i++) {
            let blockMap = groupedByBlockMaps.find((el) => {
              return (
                el.product == this.blockMapList[i].Product &&
                el.board == this.blockMapList[i].Board &&
                el.grade == this.blockMapList[i].Grade &&
                el.subject == this.blockMapList[i].Subject
              );
            });
            if (blockMap == undefined) {
              blockMap = {
                product: this.blockMapList[i].Product,
                board: this.blockMapList[i].Board,
                grade: this.blockMapList[i].Grade,
                subject: this.blockMapList[i].Subject,
                totalBlocks: 0,
                fullyPublishedBlocks: 0,
              };
            }
            this.blockMapsData.push(blockMap);
          }
        }
        this.fetchStatus = false;
      },
    },
  },
  methods: {
    ...mapActions(['getBlockMapLPDP']),
    submitHandler() {
      this.fetchStatus = true;
      this.getBlockMapLPDP({ productName: this.selectedProduct, boardName: this.selectedBoard });
    },
    selectBoard(boardName) {
      this.selectedBoard = boardName;
    },
    selectProduct(productName) {
      this.selectProduct = productName;
    },
    columnValueList(val) {
      return EXHAUSTIVE_BLOCK_MAPS_LIST.map((d) => d[val]);
    },
    filteredData() {
      return this.blockMapsData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    getClassNameFullyPublished(item1, item2) {
      if (item1 != item2) {
        return 'not-completed-item';
      } else {
        return 'completed-item';
      }
    },
    getClassName(item) {
      item = item.replace(' LP', '');
      item = item.replace(' DP', '');

      const itemSplit = item.split('/');

      if (itemSplit[0] != itemSplit[1]) {
        return 'not-completed-item';
      }
      return '';
    },
  },
};
</script>
<style scoped>
.completed-item {
  color: green;
}
.not-completed-item {
  color: red;
}
.product-board-selector {
  height: 70px;
}
.total-count {
  padding-top: 10px !important;
}
.v-data-table >>> td:nth-child(1),
.v-data-table >>> td:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  background: white;
}
.v-data-table >>> td:nth-child(1),
.v-data-table >>> th:nth-child(1) {
  left: 0;
}
.v-data-table >>> td:nth-child(2),
.v-data-table >>> th:nth-child(2) {
  left: 100px;
}
.v-data-table >>> th:nth-child(1),
.v-data-table >>> th:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  z-index: 3;
  top: 0;
  background: white;
}
.col-2,
.v-text-field {
  padding-top: 2px;
}
.v-data-table >>> th {
  vertical-align: baseline;
}
</style>
