var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-preview-wrapper"},[_c('div',[_c('v-dialog',{attrs:{"content-class":'after-asset-upload-test',"width":_vm.getDialogWidth},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_c('div',_vm._g({},on),[_c('v-avatar',{staticClass:"profile-avatar",attrs:{"color":"grey","tile":""}},[(_vm.previewFileFormat(_vm.imageUrl[+_vm.isAssetUploadRequired]) == 'image')?_c('v-img',{style:(_vm.ApproveAssetAndEmitResult ? 'max-width: 450px;' : ''),attrs:{"contain":"","src":_vm.imageUrl[+_vm.isAssetUploadRequired]}}):(_vm.previewFileFormat(_vm.imageUrl[+_vm.isAssetUploadRequired]) == 'video')?_c('v-img',{attrs:{"contain":"","src":require("@/assets/video-arrow-overlay.png")}}):(_vm.previewFileFormat(_vm.imageUrl[+_vm.isAssetUploadRequired]) == 'audio')?_c('img',{staticStyle:{"width":"50%"},attrs:{"contain":"","src":require("@/assets/audio.svg")}}):(_vm.previewFileFormat(_vm.imageUrl[+_vm.isAssetUploadRequired]) == 'doc')?_c('v-img',{attrs:{"contain":"","src":require("@/assets/docx-image.png")}}):(_vm.previewFileFormat(_vm.imageUrl[+_vm.isAssetUploadRequired]) == 'pdf')?_c('v-img',{attrs:{"contain":"","src":require("@/assets/pdf-overlay.png")}}):_vm._e()],1)],1),_c('span',{staticClass:"file-name-label-wrapper"},[_c('div',{staticClass:"filename-display"},[_c('div',{staticClass:"file-name-header"},[_vm._v(" "+_vm._s(_vm.getFileNameFromUrl(_vm.imageUrl[+_vm.isAssetUploadRequired]))+" ")])]),(_vm.isNotReadOnly)?_c('v-btn',{staticStyle:{"float":"right"},attrs:{"icon":""},on:{"click":function($event){return _vm.fileChangeHandler(null)}}},[_c('img',{attrs:{"height":"16","width":"16","src":require("@/assets/close-button-white.svg")}})]):_vm._e()],1),(_vm.canShowDiffIcon)?_c('DiffButton',{staticClass:"asset-diff-button",attrs:{"fieldIdentifier":_vm.diffIdentifier,"isDiffChanged":_vm.isDiffChanged,"canShowDiffIcon":_vm.canShowDiffIcon}}):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{class:_vm.isAssetUploadRequired == true
            ? 'lpForm-asset-preview-container-brief'
            : 'lpForm-asset-preview-container-before-asset-uploader'},_vm._l((_vm.imageUrl),function(assetUrl,index){return _c('v-card',{key:index},[_c('v-row',{staticClass:"preview-container"},[_c('span',{staticClass:"file-name-label"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(index == 0 && assetUrl)?_c('div',_vm._g(_vm._b({staticClass:"filename"},'div',attrs,false),on),[_vm._v(" "+_vm._s('Brief Asset ->' + _vm.getFileNameFromUrl(assetUrl))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"filename"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getFileNameFromUrl(assetUrl))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getFileNameFromUrl(assetUrl)))])])],1),(assetUrl && _vm.previewFileFormat(assetUrl) == 'video')?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"preview-video-player",style:(_vm.isAssetUploadRequired ? 'margin-left: 5%;' : 'margin-left: 30%;'),attrs:{"options":{
                  sources: [{ type: 'video/mp4', src: assetUrl }],
                  poster: _vm.videoBackgroundPoster,
                  language: 'en',
                },"playsinline":true,"customEventName":"customstatechangedeventname"}})],1):_vm._e(),(assetUrl && _vm.previewFileFormat(assetUrl) == 'audio')?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('div',{style:(("position: absolute; top: 30%; " + (_vm.isAssetUploadRequired ? 'margin-left: 140px;' : 'margin-left: 420px;')))},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":assetUrl,"type":"audio/mpeg"}})])])]):_c('v-col',{staticClass:"asset-wrapper",attrs:{"cols":"12","sm":"6","md":"12"}},[(assetUrl)?_c('v-col',[(_vm.previewFileFormat(assetUrl) == 'image')?_c('v-img',{attrs:{"contain":"","src":assetUrl,"height":"450px"}}):(_vm.previewFileFormat(assetUrl) == 'doc')?_c('v-img',{attrs:{"contain":"","src":require("@/assets/docx-image.png"),"height":"300px"}}):(_vm.previewFileFormat(assetUrl) == 'pdf')?_c('PdfViewer',{key:("pdfViewer" + index),attrs:{"src":assetUrl,"height":"300px"}}):_vm._e()],1):_c('v-col',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"md":"8"}},[_c('v-img',{attrs:{"src":require("@/assets/na.svg"),"height":"450px","contain":""}})],1)],1),_c('div',{staticClass:"align-download-center",style:(_vm.previewFileFormat(assetUrl) == 'pdf' ? 'margin-top: 270px;' : '')},[(assetUrl)?_c('a',{staticStyle:{"text-align":"center"},attrs:{"href":assetUrl,"download":""}},[_vm._v("Download")]):_vm._e(),(assetUrl && _vm.canAppproveAsset && index == 1)?_c('v-btn',{staticClass:"ml-5",attrs:{"disabled":_vm.isAssetApproved},on:{"click":_vm.assetApproval}},[_vm._v(_vm._s(_vm.isAssetApproved ? 'Approved' : 'Approve Asset'))]):_vm._e()],1)],1)],1)}),1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }