const mutations = {
  SET_QR_CODES(state, qrCodes) {
    const sortedQRCodes = qrCodes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    state.qrCodes = sortedQRCodes;
  },
  SET_BLOCKS_FOR_QR(state, blocks) {
    state.blocks = blocks;
  },

  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_CONFIDENCE_GRADES(state, confidenceGrades) {
    state.confidenceGrades = confidenceGrades || [];
  },
};

export default mutations;
