export default function teacherTip(props, model) {
  return {
    name: 'teacherTip',
    model,
    props: {
      canShowDiffIcon: props.canShowDiffIcon,
      isDiffChanged: props.isDiffChanged,
      fieldIdentifier: props.fieldIdentifier,
      showValidationError: props.showValidationError,
      placeholder: props.placeholder,
      isNotReadOnly: props.isNotReadOnly,
    },
  };
}
