<template>
  <v-row>
    <CoverImage
      :lessonPlanDetails="lessonPlanDetails"
      :title="lessonPlanData.title || ''"
      :brief="lessonPlanData.brief.text"
      :imageTitleChangeHandler="(val) => (lessonPlanData.title = val)"
      :imageBriefChangeHandler="(val) => (lessonPlanData.brief.text = val)"
      :image_url="isAssetUploadRequired ? lessonPlanData.assetId : ''"
      :image_url_brief="lessonPlanData.brief.assetId"
      :previewUrl="isAssetUploadRequired ? signedUrls.assetSignedUrl : ''"
      :previewUrlBrief="signedUrls.brief.assetSignedUrl"
      :coverImagePlaceholder="AppTexts.LESSON_PLAN_COVER_IMAGE"
      :currentStageDetails="currentStageDetails"
      :loadingStatus="uploadingStatus.cover_image == FILE_UPLOADING_STATUS.IN_PROGRESS"
      :isAssetUploadRequired="isAssetUploadRequired"
      :fileChangeHandler="importLessonPlanCoverImage"
      :briefChangeHandler="(text) => updateBriefText({ text })"
      :canShowDiffIcon="canShowDiffIcon"
      :isImageChanged="getCoverImageIsChangedValue()"
      :isImageBriefDiffChanged="getCoverImageBriefIsChangedValue()"
      :showImageBrief="showImageBrief"
      :isNotReadOnly="auth.canSaveProjectLP"
      :fileValidators="fileValidators()"
      :copyLessonPlanCoverImage="copyLessonPlanCoverImage"
      :canAppproveAsset="canAppproveAsset"
      :isAssetApproved="lessonPlanData.assetApproved || false"
      :currentProjectAssignees="currentProject.assignees"
      style="width: 100%;"
      :lessonPlan="lessonPlanData"
      :canEditTitle="Schema.canEditTitle"
      :canEditDescription="Schema.canEditDescription"
    />
    <v-expansion-panels multiple focusable v-model="panel">
      <v-expansion-panel class="expansion-panel" key="SummarySection">
        <v-expansion-panel-header class="section-title-bar">
          <ExpansionPanelHeader
            sectionTitle="Teacher Summary"
            :displayTrailing="false"
            :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.SUMMARY, e)"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="section-title-content">
          <SummarySection
            :summaryData="summaryData"
            :updateSummaryTextToStore="updateSummaryText"
            :diff="diff"
            :canShowDiffIcon="canShowDiffIcon"
            :isNotReadOnly="auth.canSaveProjectLP"
            :canShowEvaluateAndGrade="canShowEvaluateAndGrade"
            :schema="Schema"
            :isQuestionLesson="true"
            :lessonPlanData="lessonPlanData"
        /></v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="Schema.aim.show" class="expansion-panel" key="AimSection">
        <v-expansion-panel-header class="section-title-bar">
          <ExpansionPanelHeader
            :sectionTitle="AppTexts.AIM"
            :displayTrailing="false"
            :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.AIM, e)"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="section-title-content">
          <AimSection
            :lessonPlanData="lessonPlanData"
            :addResource="addResource"
            :deleteResource="deleteResource"
            :addKeyword="addKeyword"
            :deleteKeyword="deleteKeyword"
            :showImageBrief="showImageBrief"
            :isAssetUploadRequired="isAssetUploadRequired"
            :acceptableFileType="acceptableFileType"
            :resourcesError="resourcesError"
            :aimSignedUrls="signedUrls.aim"
            :aimDiff="diff.aim || {}"
            :keywordsDiff="diff.keywords || []"
            :canShowDiffIcon="canShowDiffIcon"
            :isNotReadOnly="auth.canSaveProjectLP"
            :fileValidators="fileValidators()"
            :schema="Schema.aim"
            :isKeywordNameOptional="!isCircleTime"
            :isKeywordDefinitionOptional="Schema.aim.keyWordDefinitionOptional"
            :canAppproveAsset="canAppproveAsset"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        class="expansion-panel"
        key="ApplicationSection"
        v-if="Schema.application.show"
      >
        <v-expansion-panel-header class="section-title-bar">
          <ExpansionPanelHeader
            :sectionTitle="AppTexts.APPLICATION"
            :time="
              lessonPlanData && lessonPlanData.application ? lessonPlanData.application.time : ''
            "
            :types="
              lessonPlanData && lessonPlanData.application ? lessonPlanData.application.types : []
            "
            @update-type-of-block-to-state="(val) => updateTypeOfBlockToState('application', val)"
            :canEditType="auth.canSaveProjectLP"
            :toggleComments="(e) => toggleComments(COMMENT_SECTION_NAMES_HASH.APPLICATION, e)"
            :displayTime="true"
            :canEditTime="auth.canSaveProjectLP"
            @set-timer-input="(val) => updateTimeOfBlockToState('application', val)"
            :allowedTimeValues="Schema.application.allowedTimeValues"
            :schema="Schema"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="section-title-content">
          <QuestionApplicationSection
            :isReadOnly="isReadOnly"
            :schema="Schema.application"
            :data="lessonPlanData.application"
            :addApplicationSectionQuestion="addApplicationSectionQuestion"
            :removeApplicationSectionQuestion="removeApplicationSectionQuestion"
            :canShowDiffIcon="canShowDiffIcon"
            :applicationDiff="diff.application || {}"
            :showLoadingForQuestionDataList="showLoadingForQuestionDataList"
            :questionDataForList="questionDataForList"
            :applicationStaticTexts="staticTexts.application || {}"
            :currentProjectQuestionLinkableLessonPlans="currentProjectQuestionLinkableLessonPlans"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import SummarySection from '@/components/lessonPlanTemplate/SummarySection';
import CoverImage from '@/components/lessonPlanTemplate/CoverImage';
import AimSection from '@/components/lessonPlanTemplate/AimSection';
import ExpansionPanelHeader from '@/components/lessonPlanTemplate/ExpansionPanelHeader';
import QuestionApplicationSection from '@/components/Questions/QuestionApplicationSection.vue';

import { baseQuestionMixin } from '@/mixins/questions/base.js';

export default {
  name: 'QuestionsLessonTemplate',
  mixins: [baseQuestionMixin],
  components: {
    QuestionApplicationSection,
    ExpansionPanelHeader,
    SummarySection,
    CoverImage,
    AimSection,
  },
  props: {
    lpType: {
      type: String,
      required: true,
    },
    lessonPlanDetails: {
      type: Object,
      required: true,
    },
    lessonPlanData: {
      type: Object,
      required: true,
    },
    currentStageDetails: {
      type: Object,
      required: true,
    },
    uploadingStatus: {
      type: Object,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
    componentOptions: {
      type: Object,
      required: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    diff: {
      type: Object,
      required: true,
    },
    onChangeLessonPlanData: {
      type: Function,
      required: true,
    },
    toggleComments: {
      type: Function,
      required: true,
    },
    currentProjectQuestionLinkableLessonPlans: {
      type: Array,
      required: true,
    },
  },
};
</script>
