<template>
  <div>
    <RichTextEditor
      v-if="showRTEDescription"
      :readonly="isReadOnly"
      :canShowDiffIcon="canShowDiffIcon"
      v-model="data.description"
      :fieldIdentifier="`${diffIdentifierPrefix}.description`"
      :placeholder="descriptionPlaceholder"
      :showValidationError="!isDescriptionOptional"
      :returnEmptyStringInsteadOfDefaultJson="true"
      :isDiffChanged="(diffChanges.description || {}).isChanged || false"
      :counter="descriptionMaxLength"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <v-row>
      <v-col cols="6" class="pad-Rm">
        <FileInput
          :image_url="isAssetUploadRequired ? data[firstAssetKey].assetId : ''"
          :image_url_brief="data[firstAssetKey].brief.assetId"
          :previewUrl="isAssetUploadRequired ? signedUrls[firstAssetKey].assetSignedUrl : ''"
          :previewUrlBrief="signedUrls[firstAssetKey].brief.assetSignedUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged(firstAssetKey, imageKey)"
          :diffIdentifier="`${diffIdentifierPrefix}.${firstAssetKey}.${imageKey}`"
          label="Select Asset"
          :loadingStatus="
            uploadingStatus[assetTypeMapping[firstAssetKey]] == FILE_UPLOADING_STATUS.IN_PROGRESS
          "
          :fileChangeHandler="
            (file) =>
              fileChangeHandler(
                file,
                firstAssetKey,
                `${diffIdentifierPrefix}.${firstAssetKey}.${imageKey}`
              )
          "
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="!isReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="canFinalizeAsset"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="data[firstAssetKey].assetApproved"
          :isRequired="isFirstAssetMandatory"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              addBriefToAsset(previewUrl, briefUrl, firstAssetKey, isAssetApproved)
          "
        />
        <RichTextEditor
          v-if="showImageBrief"
          v-model="data[firstAssetKey].brief.text"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged(firstAssetKey, 'brief')"
          :fieldIdentifier="`${diffIdentifierPrefix}.${firstAssetKey}.brief`"
          :placeholder="startCase(firstAssetKey) + ' Asset Brief'"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          :showValidationError="isFirstAssetMandatory"
          :counter="3000"
          :readonly="isReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col v-if="showSecondAsset" cols="6" class="pad-Lm">
        <FileInput
          :image_url="isAssetUploadRequired ? data[secondAssetKey].assetId : ''"
          :image_url_brief="data[secondAssetKey].brief.assetId"
          :previewUrl="isAssetUploadRequired ? signedUrls[secondAssetKey].assetSignedUrl : ''"
          :previewUrlBrief="signedUrls[secondAssetKey].brief.assetSignedUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged(secondAssetKey, imageKey)"
          :diffIdentifier="`${diffIdentifierPrefix}.${secondAssetKey}.${imageKey}`"
          label="Select Asset"
          :loadingStatus="
            uploadingStatus[assetTypeMapping[secondAssetKey]] == FILE_UPLOADING_STATUS.IN_PROGRESS
          "
          :fileChangeHandler="
            (file) =>
              fileChangeHandler(
                file,
                secondAssetKey,
                `${diffIdentifierPrefix}.${secondAssetKey}.${imageKey}`
              )
          "
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="!isReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="canFinalizeAsset"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="data[secondAssetKey].assetApproved"
          :isRequired="isSecondAssetMandatory"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              addBriefToAsset(previewUrl, briefUrl, secondAssetKey, isAssetApproved)
          "
        />
        <RichTextEditor
          v-if="showImageBrief"
          v-model="data[secondAssetKey].brief.text"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged(secondAssetKey, 'brief')"
          :fieldIdentifier="`${diffIdentifierPrefix}.${secondAssetKey}.brief`"
          :placeholder="startCase(secondAssetKey) + ' Asset Brief'"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px;"
          :showValidationError="isSecondAssetMandatory"
          :counter="3000"
          :readonly="isReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col v-if="secondAssetAnswerKey && showSecondAsset" cols="6" class="pad-Rm">
        <FileInput
          :image_url="isAssetUploadRequired ? data[secondAssetAnswerKey].assetId : ''"
          :image_url_brief="data[secondAssetAnswerKey].brief.assetId"
          :previewUrl="isAssetUploadRequired ? signedUrls[secondAssetAnswerKey].assetSignedUrl : ''"
          :previewUrlBrief="signedUrls[secondAssetAnswerKey].brief.assetSignedUrl"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged(secondAssetAnswerKey, imageKey)"
          :diffIdentifier="`${diffIdentifierPrefix}.${secondAssetAnswerKey}.${imageKey}`"
          label="Select Asset"
          :loadingStatus="
            uploadingStatus[assetTypeMapping[secondAssetAnswerKey]] == FILE_UPLOADING_STATUS.IN_PROGRESS
          "
          :fileChangeHandler="
            (file) =>
              fileChangeHandler(
                file,
                secondAssetAnswerKey,
                `${diffIdentifierPrefix}.${secondAssetAnswerKey}.${imageKey}`
              )
          "
          :showImageBrief="showImageBrief"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileAcceptType="acceptableFileType"
          :isNotReadOnly="!isReadOnly"
          :fileValidators="fileValidators"
          :canFinalizeAsset="canFinalizeAsset"
          :canAppproveAsset="canAppproveAsset"
          :isAssetApproved="data[secondAssetAnswerKey].assetApproved"
          :isRequired="isSecondAssetMandatory"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              addBriefToAsset(previewUrl, briefUrl, secondAssetAnswerKey, isAssetApproved)
          "
        />
        <RichTextEditor
          v-if="showImageBrief"
          v-model="data[secondAssetAnswerKey].brief.text"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChanged(secondAssetAnswerKey, 'brief')"
          :fieldIdentifier="`${diffIdentifierPrefix}.${secondAssetAnswerKey}.brief`"
          :placeholder="startCase(secondAssetAnswerKey) + ' Asset Brief'"
          class="text-field-text"
          style="margin: 25px 0px 0px 0px"
          :showValidationError="isSecondAssetMandatory"
          :counter="3000"
          :readonly="isReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import { mapActions } from 'vuex';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import FileInput from '@/components/inputs/FileInput';
import Errors from '@/constants/Errors';
import FILE_UPLOADING_STATUS from '@/constants/FileUploadingStatus';
import { get, startCase } from 'lodash';

export default {
  name: 'RTEWithTwoAssets',
  components: {
    RichTextEditor,
    FileInput,
  },
  data() {
    return { Errors, FILE_UPLOADING_STATUS, BASIC_TOOLS };
  },
  computed: {
    imageKey() {
      return this.isAssetUploadRequired ? 'assetId' : 'brief.assetId';
    },
  },
  methods: {
    ...mapActions(['importSingleAssetAction', 'deleteSingleAssetAction', 'copySingleAssetAction']),
    startCase,
    isDiffChanged(entity, attribute) {
      try {
        return this.diffChanges[entity][attribute].isChanged == true;
      } catch (error) {
        console.log(error.message);
      }
      return false;
    },
    fileChangeHandler(file, entity, fieldIdentifier) {
      if (file) {
        this.importSingleAssetAction({
          file,
          assetType: this.assetTypeMapping[entity],
        });
      } else {
        this.deleteSingleAssetAction({
          assetId: get(this.data[entity], this.imageKey),
          assetType: this.assetTypeMapping[entity],
          fieldIdentifier,
        });
      }
    },
    addBriefToAsset(previewUrl, briefUrl, entity, isAssetApproved) {
      this.copySingleAssetAction({
        signedUrl: previewUrl,
        path: briefUrl,
        assetType: this.assetTypeMapping[entity],
        isAssetApproved,
      });
    },
  },
  props: {
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    diffIdentifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    diffChanges: {
      type: Object,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: true,
    },
    signedUrls: {
      type: Object,
      required: false,
    },
    uploadingStatus: {
      type: Object,
      required: true,
    },
    acceptableFileType: {
      type: String,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    fileValidators: {
      type: Array,
      required: false,
    },
    assetTypeMapping: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      required: true,
    },
    canFinalizeAsset: {
      type: Boolean,
      required: true,
    },
    isFirstAssetMandatory: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSecondAssetMandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDescriptionOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    descriptionMaxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    showRTEDescription: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSecondAsset: {
      type: Boolean,
      required: false,
      default: true,
    },
    firstAssetKey: {
      type: String,
      required: false,
      default: 'question',
    },
    secondAssetKey: {
      type: String,
      required: false,
      default: 'question_answer',
    },
    secondAssetAnswerKey: {
      type: String,
      required: false,
    },
  },
};
</script>
