<template>
  <div>
    <SubSection class="pad-Bs" title="Answer Criteria" />
    <div class="criteria-wrapper">
      <v-row>
        <v-col cols="8">
          <div class="criteria-header">Criteria</div>
        </v-col>
        <v-col cols="2">
          <div class="criteria-header">%</div>
        </v-col>
      </v-row>
      <v-row v-for="(criteria, index) in answerCriteriaState" :key="index">
        <v-col cols="8">
          <v-text-field
            placeholder="Criteria title"
            type="text"
            outlined
            dense
            v-model="criteria.title"
            :rules="[(v) => !!v || 'Required']"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            placeholder="%"
            type="number"
            outlined
            dense
            v-model="criteria.percentage"
            hide-details
            :max="totalPercentage"
            :rules="[
              (v) => !!v || 'This is required',
              (v) => v >= 1 || 'Minimum is 1',
              (v) => v <= 100 || 'Maximum is 100',
              isValidTotalPercentage
            ]"
            min="0"
            suffix="%"
            @keydown="
              (event) => {
                if (event.key === '.' || event.key === '-') {
                  event.preventDefault();
                }
              }
            "
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <div class="action-buttons-wrapper">
            <v-btn @click="addRow(index)">+</v-btn>
            <v-btn @click="removeRow(index)" v-if="canRemoveItem">x</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <div class="criteria-header">
            <b>Total</b>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="criteria-header">
            <b>{{ sumOfPercentage }} %</b>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!isValidTotalPercentage">
        <v-col cols="8"></v-col>
        <v-col>
          <span class="percentage-total-warning">Total percentage should add up to <b>100</b></span>
        </v-col></v-row
      >
    </div>
  </div>
</template>

<script>
import SubSection from '@/components/inputs/SubSection.vue';

export default {
  name: 'AnswerCriteriaInput',
  components: {
    SubSection
  },
  data() {
    return {
      answerCriteriaState: [
        {
          title: '',
          percentage: 100
        }
      ],
      totalPercentage: 100
    };
  },
  created() {
    if (this.answerCriteria && this.answerCriteria.length) {
      this.answerCriteriaState = this.answerCriteria;
    }
  },
  watch: {
    answerCriteriaState: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit('onUpdate', val);
      }
    }
  },
  computed: {
    canRemoveItem() {
      return this.answerCriteriaState.length > 1;
    },
    sumOfPercentage() {
      return this.answerCriteriaState.reduce(
        (total, value) => total + parseInt(value.percentage || 0),
        0
      );
    },
    isValidTotalPercentage() {
      return this.sumOfPercentage == this.totalPercentage;
    }
  },
  methods: {
    addRow(index) {
      const data = {
        title: '',
        percentage: 0
      };
      this.answerCriteriaState.splice(index + 1, 0, data);
    },
    removeRow(index) {
      if (this.canRemoveItem) this.answerCriteriaState.splice(index, 1);
    }
  },
  props: {
    answerCriteria: {
      type: Array,
      required: true
    }
  }
};
</script>
<style scroped>
.criteria-wrapper {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}
.action-buttons-wrapper {
  display: flex;
  justify-content: space-around;
}
.criteria-header {
  display: flex;
  justify-content: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
  font-size: 20px;
  background: rgb(204, 202, 202);
}
.percentage-total-warning {
  font-size: 12px;
  color: red;
  font-weight: 500;
}
</style>
