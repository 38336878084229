const MICRO_TOPIC_CONSTANTS = '../constants/microTopic.js';

function singleProjectDefaultData(projectType) {
  let defaultUserForNonMandatoryProjects = ['worksheet', 'contentBook', 'videos'].includes(
    projectType
  )
    ? { id: MICRO_TOPIC_CONSTANTS.SKIP_STAGE_USER_ID_VALUE, name: '' }
    : { id: '', name: '' };

  return {
    enabled: false,
    assignees: {
      author: Object.assign({}, defaultUserForNonMandatoryProjects),
      reviewer1: Object.assign({}, defaultUserForNonMandatoryProjects),
      reviewer2: Object.assign({}, defaultUserForNonMandatoryProjects),
      academicSignOff: Object.assign({}, defaultUserForNonMandatoryProjects),
      editor: Object.assign({}, defaultUserForNonMandatoryProjects),
      visualizer: Object.assign({}, defaultUserForNonMandatoryProjects),
      assetUploader: {
        id: '',
        name: '',
      },
      assetAuthorizer: {
        id: '',
        name: '',
      },
      proofReader: {
        id: '',
        name: '',
      },
    },
    workflowStatus: ''
  };
}
module.exports = {
  singleProjectDefaultData,
};
