<template>
  <div class="subject-selection">
    <v-container v-bind:style="{ width: '450px' }">
      <v-flex class="text-xs-center">
        <v-form ref="form">
          <v-label>{{ AppTexts.SELECT_BOARD }}</v-label>
          <v-select
            v-model="selectedBoard"
            :items="boards"
            :rules="[(v) => !!v || 'Board is required']"
            required
            item-text="name"
            item-value="name"
            class="select-board-dropdown"
            @change="selectBoard"
          />
          <v-label>{{ AppTexts.SELECT_SUBJECT }}</v-label>
          <v-select
            v-model="selectedSubject"
            :items="subjects"
            :rules="[(v) => !!v || 'Subject is required']"
            required
            item-text="name"
            item-value="id"
            class="select-subject-dropdown"
            @change="selectSubject"
          />
          <v-label>{{ AppTexts.SELECT_STANDARD }}</v-label>
          <v-select
            v-model="selectedGrade"
            :items="standards"
            :rules="[(v) => !!v || 'Grade is required']"
            required
            item-text="name"
            item-value="id"
            class="select-grade-dropdown"
            @change="selectGrade"
          />
          <v-label>{{ AppTexts.SELECT_PRODUCT }}</v-label>
          <v-select
            v-model="selectedProduct"
            :items="products"
            :rules="[(v) => !!v || 'Product is required']"
            required
            item-text="name"
            item-value="id"
            class="select-grade-dropdown"
            @change="selectProduct"
          />
          <v-label>{{ AppTexts.SELECT_BLOCK }}</v-label>
          <v-select
            v-model="selectedBlock"
            :items="blockList"
            :rules="[(v) => !!v || 'Block is required']"
            required
            item-text="title"
            item-value="id"
            class="select-grade-dropdown"
            multiple
            @change="selectBlock"
          />
          <v-layout justify-start align-center>
            <v-checkbox v-model="metaData" label="Metadata" class="metadata-checkbox"></v-checkbox>
            <v-layout justify-center>
              <v-btn
                class="view-block-map-btn"
                :disabled="disbleDownloadButton"
                color="primary"
                @click="submitHandler()"
                >DOWNLOAD</v-btn
              ></v-layout
            >
          </v-layout>
        </v-form>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fetchAllBoardsSubjectsStandards } from '@/apiQueries/subjectSelectionQuery';
import AppTexts from '@/constants/AppTexts';
import { sortByFnForArrayOfObjectsByStringProperty } from '@/functions/array';
import Vue from 'vue';

export default {
  name: 'modelAnswerGuide',
  apollo: {
    globalBoardsSubjectsStandards: {
      query: fetchAllBoardsSubjectsStandards,
      loadingKey: 'loading',
      update: (data) => data,
    },
  },
  computed: {
    subjects() {
      console.log('globalBoardsSubjectsStandards', this.globalBoardsSubjectsStandards.standards);
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.subjects || [],
        'name'
      );
    },
    standards() {
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.standards || [],
        'name'
      );
    },
    boards() {
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.boards || [],
        'name'
      );
    },
    products() {
      return sortByFnForArrayOfObjectsByStringProperty(
        this.globalBoardsSubjectsStandards.products || [],
        'name'
      );
    },
    ...mapState({
      selectedBoard: (state) => state.selectedBoard,
      selectedSubject: (state) => state.selectedSubject,
      selectedGrade: (state) => state.selectedGrade,
      selectedProduct: (state) => state.selectedProduct,
      selectedBlock: (state) => state.selectedBlock,
      blocks: (state) => state.blocks,
    }),
    disbleDownloadButton() {
      return !(
        this.selectedBoard != '' &&
        this.selectedSubject != '' &&
        this.selectedGrade != '' &&
        this.selectedProduct != '' &&
        this.selectedBlock.length > 0
      );
    },
  },
  data: () => {
    return {
      AppTexts,
      selectedBoard: '',
      selectedSubject: '',
      selectedGrade: '',
      selectedProduct: '',
      selectedBlock: [],
      blockList: [],
      globalBoardsSubjectsStandards: {
        subjects: [],
        standards: [],
        boards: [],
        products: [],
      },
      metaData: false,
    };
  },
  methods: {
    ...mapActions([
      'getBlocksForSelectedProductBoardStandardSubject',
      'generateModelAnswerGuideTemplate',
    ]),
    selectBoard(boardId) {
      console.log('boardId', boardId);
      this.toggleMetadata();
      this.getBlocks();
    },
    selectSubject(subjectId) {
      console.log('subjectId', subjectId);
      this.toggleMetadata();
      this.getBlocks();
    },
    selectGrade(gradeId) {
      console.log('gradeId', gradeId);
      this.toggleMetadata();
      this.getBlocks();
    },
    selectProduct(productId) {
      console.log('productId', productId);
      this.toggleMetadata();
      this.getBlocks();
    },
    selectBlock(value) {
      console.log('blockId', value);
      if (value.length > 5) {
        Vue.notify({
          title: 'Info!',
          text: `Maximum number of pdf download is 5!`,
          type: 'info',
        });
        this.selectedBlock = this.selectedBlock.slice(0, 5);
      } else {
        this.selectedBlock = value;
      }
    },
    async submitHandler() {
      console.log('submitHandler : selectedProduct', this.selectedProduct);
      console.log('submitHandler : selectedBoard : ', this.selectedBoard);
      console.log('submitHandler : selectedSubject : ', this.selectedSubject);
      console.log('submitHandler : selectedGrade : ', this.selectedGrade);
      console.log('submitHandler : selectedBlock : ', this.selectedBlock);
      console.log('submitHandler : metadata:', this.metaData);
      this.generateModelAnswerGuideTemplate({
        blockIdList: this.selectedBlock,
        metaData: this.metaData,
      });
    },
    getBlocks() {
      console.log('productId', 'called');
      if (
        this.selectedBoard != '' &&
        this.selectedSubject != '' &&
        this.selectedGrade != '' &&
        this.selectedProduct != ''
      ) {
        this.selectedBlock = [];
        this.getBlocksForSelectedProductBoardStandardSubject({
          productId: this.selectedProduct,
          boardName: this.selectedBoard,
          subjectId: this.selectedSubject,
          standardId: this.selectedGrade,
        });
      }
    },
    toggleMetadata(value = false) {
      this.metaData = value;
    },
  },
  watch: {
    blocks: {
      deep: true,
      immediate: true,
      handler(value) {
        this.blockList = [];
        if (Array.isArray(value) && value.length > 0) {
          this.blockList = value;
          console.log('value', value);
        }
      },
    },
  },
};
</script>
