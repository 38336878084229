<template>
  <div>
    <Padder :left="50" :right="10" :vertical="20">
      <!-- Third Row -->
      <v-row>
        <SubSection title="Answer" />
        <Padder :left="-50" :bottom="10">
          <v-subheader style="margin-left: -13px;">
            NOTE: Please input the correct match against each row. The system will take care of
            shuffling the options that will be shown to the user.
          </v-subheader>
        </Padder>
      </v-row>
      <div style="display: flex; flex-direction: column;">
        <div
          v-for="(option, index) in optionsArray"
          :key="`mtfOptionList${revision}${index}`"
          style="flex: 1;"
        >
          <div
            :class="`${rightPanelOpened ? 'minified-view' : ''}`"
            :style="`display: flex; flex-direction: ${rightPanelOpened ? 'column' : 'row'};`"
          >
            <div :class="`${rightPanelOpened ? 'text-align: left' : ''} `">
              <span class="step-number">{{ index + 1 }}</span>
            </div>

            <Padder style="flex: 1;" :right="20">
              <Option
                :data="option.key"
                :key="`mtfOptionKey${generateIdentifier}`"
                :partialFieldIdentifier="`['${data._id}'].questionData.optionKeys`"
                :getToolBarActions="getToolBarActions"
                placeholder="Enter question text"
                :options="options"
                :forceAssetFinalized="forceAssetFinalized"
                :canAppproveAsset="canAppproveAsset"
                :getRTEAcceptedFileType="getRTEAcceptedFileType"
                :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs"
              />
            </Padder>
            <Padder :style="`${rightPanelOpened ? 'display:flex' : 'flex: 1'}`" :right="20">
              <Option
                :data="option.value"
                :key="`mtfOptionValue${generateIdentifier}`"
                :partialFieldIdentifier="`['${data._id}'].questionData.optionValues`"
                :getToolBarActions="getToolBarActions"
                placeholder="Enter correct match text"
                :options="options"
                :canAppproveAsset="canAppproveAsset"
                :forceAssetFinalized="forceAssetFinalized"
                :getRTEAcceptedFileType="getRTEAcceptedFileType"
                :getToolBarActionsForQuestionBriefs="getToolBarActionsForQuestionBriefs"
              />
            </Padder>
            <div :class="`${rightPanelOpened ? 'close' : ''}`">
              <v-btn icon @click="deleteOption(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <v-row>
        <v-row>
          <v-btn
            class="xs-accent-button font-weight-medium"
            width="165"
            @click="addNewOptionKeyAndValue"
            :disabled="data.questionData.optionValues.length > 9"
            ><v-icon left>mdi-plus</v-icon> Add option</v-btn
          >
        </v-row>
      </v-row>
      <XSDivider />
    </Padder>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Option from '@/components/contentEntry/questionComponents/dialogComponents/mtfComponents/Option.vue';
import SubSection from '@/components/inputs/SubSection.vue';
import _ from 'lodash';
import { DEFAULT_QUESTION_DATA } from '@/constants/contentEntryQuestionDefaultData.js';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
export default {
  data() {
    return {
      DEFAULT_QUESTION_DATA,
      questionsOprogramProject,
      revision: 0,
    };
  },
  components: {
    SubSection,
    Option,
  },
  methods: {
    addNewOptionKeyAndValue() {
      let { questionData } = this.data;
      let defaultQuestionData = this.DEFAULT_QUESTION_DATA[
        this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF
      ].questionData;
      questionData.optionKeys.push(_.cloneDeep(defaultQuestionData.optionKeys[0]));
      questionData.optionKeys[questionData.optionKeys.length - 1].sequence =
        questionData.optionKeys.length;
      questionData.optionValues.push(_.cloneDeep(defaultQuestionData.optionValues[0]));
      questionData.optionValues[questionData.optionValues.length - 1].sequence =
        questionData.optionValues.length;
      this.revision += 1;
    },
    deleteOption(index) {
      this.data.questionData.optionKeys.splice(index, 1);
      this.data.questionData.optionValues.splice(index, 1);

      for (let i = index, j = this.data.questionData.optionKeys.length; i < j; i++) {
        this.data.questionData.optionKeys[i].sequence -= 1;
        this.data.questionData.optionValues[i].sequence -= 1;
      }
      this.revision += 1;
    },
  },
  computed: {
    ...mapState({
      setQuestionDiffPanel: (state) => state.rightSideContent.setQuestionDiffPanel,
      questionCommentsToggle: (state) => state.questions.questionCommentsToggle,
      setGroupQuestionDiffPanel: (state) => state.rightSideContent.setGroupQuestionDiffPanel,
      groupQuestionCommentsToggle: (state) => state.questions.groupQuestionCommentsToggle,
    }),
    rightPanelOpened() {
      return (
        this.setQuestionDiffPanel ||
        this.questionCommentsToggle ||
        this.setGroupQuestionDiffPanel ||
        this.groupQuestionCommentsToggle
      );
    },
    generateIdentifier() {
      return Math.random().toString(36).slice(2);
    },
    optionsArray() {
      return this.data.questionData.optionKeys.map((optionKey, index) => {
        return {
          key: optionKey,
          value: this.data.questionData.optionValues[index],
        };
      });
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    getToolBarActions: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    forceAssetFinalized: {
      type: Boolean,
      required: true,
    },
    getRTEAcceptedFileType: {
      type: Array,
      required: true,
    },
    getToolBarActionsForQuestionBriefs: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.step-number {
  height: 30px;
  width: 30px;
  padding: 5px 10px;
  margin: 10px 10px 10px 5px;
  border-radius: 50%;
  background-color: #b5e9e4;
  text-align: center;
}
.minified-view {
  position: relative;
}
.minified-view .close {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
