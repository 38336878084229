<template>
  <v-row
    style="width=80%;display:flex;position: relative; align-items:center;flex-direcrtion:column"
  >
    <v-select
      v-on:change="(val) => $emit('on-change', val)"
      :items="filterItems"
      item-text="label"
      item-value="value"
      :placeholder="placeholder"
      background-color="white"
      hide-details
      :disabled="!canEditType"
      solo
      flat
      dense
      multiple
      outlined
      @input="onChange"
      :value="value"
    ></v-select>
  </v-row>
</template>

<script>
export default {
  name: 'FiterSelectDropDown',
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue);
    }
  },
  props: {
    placeholder: {
      type: String,
      required: true
    },
    canEditType: {
      type: Boolean,
      required: true
    },
    filterItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>
