<template>
  <v-form ref="acationSectionForm" class="section-title-cnr">
    <Padder :top="20">
      <Slide slide="Silde 6" />
    </Padder>
    <BlockTitle :title="AppTexts.KEY_QUESTION" />
    <v-row v-for="index in 3" :key="index" style="display: flex">
      <div>
        <span class="step-number">{{ index }}</span>
      </div>
      <div style="flex-grow: 1">
        <RichTextEditor
          :readonly="options.isReadOnly"
          :canShowDiffIcon="options.canShowDiffIcon"
          v-model="data.keyQuestions[index - 1]"
          :fieldIdentifier="`analysis.keyQuestions[${index - 1}]`"
          :showValidationError="options.validationFlag"
          :counter="200"
          :allowCharacterCount="true"
        />
      </div>
    </v-row>
    <Padder :top="20">
      <SubSection :title="AppTexts.TEACHER_ZONE" />
      <TextInput
        :enableTextFormatting="true"
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        fieldIdentifier="analysis.teacherZone"
        :hintText="AppTexts.TEACHER_ZONE"
        v-model="data.teacherZone"
      />
    </Padder>
    <XSDivider />
    <Slide slide="Silde 6a" />
    <v-subheader>Optional - Stimulus to be used with QUESTIONS</v-subheader>
    <Slider
      :options="options"
      :data="data.contents[2]"
      :fileChangeHandler="fileChangeHandler"
      fieldIdentifierPrefix="analysis.optionalContentSlide"
      :type="'slider.analysis.2'"
      :isValidationRequired="false"
      :dataCopy="currentStageDataCopy.analysis.contents[2]"
      :addToAsset="addToAsset"
      :canAppproveAsset="canAppproveAsset"
      :key="'sliderAnalysis1'"
      :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
    />
    <Padder :top="20">
      <SubSection :title="AppTexts.TEACHER_ZONE" />
      <TextInput
        :enableTextFormatting="true"
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        fieldIdentifier="analysis.optionalContentSlide.teacherZone"
        :hintText="AppTexts.TEACHER_ZONE"
        v-model="data.contents[2].teacherZone"
      />
    </Padder>
    <XSDivider />
    <Slide slide="Silde 7" />
    <v-subheader>Explanation 1</v-subheader>
    <Slider
      :options="options"
      :data="data.contents[0]"
      :fileChangeHandler="fileChangeHandler"
      fieldIdentifierPrefix="analysis.contents[0]"
      :type="'slider.analysis.0'"
      :dataCopy="currentStageDataCopy.analysis.contents[0]"
      :addToAsset="addToAsset"
      :canAppproveAsset="canAppproveAsset"
      :key="'sliderAnalysis2'"
      :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
    />
    <Padder :top="20">
      <SubSection :title="AppTexts.TEACHER_ZONE" />
      <TextInput
        :enableTextFormatting="true"
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        fieldIdentifier="analysis.contents[0].teacherZone"
        :hintText="AppTexts.TEACHER_ZONE"
        v-model="data.contents[0].teacherZone"
      />
    </Padder>

    <XSDivider />
    <Slide slide="Silde 8" />
    <v-subheader>Explanation 2</v-subheader>
    <Slider
      :options="options"
      :data="data.contents[1]"
      fieldIdentifierPrefix="analysis.contents[1]"
      :fileChangeHandler="fileChangeHandler"
      :type="'slider.analysis.1'"
      :dataCopy="currentStageDataCopy.analysis.contents[1]"
      :addToAsset="addToAsset"
      :canAppproveAsset="canAppproveAsset"
      :key="'sliderAnalysis3'"
      :allowedToolBarActionWithTablesForRTE="allowedToolBarActionWithTablesForRTE"
    />
    <Padder :top="20">
      <SubSection :title="AppTexts.TEACHER_ZONE" />
      <TextInput
        :enableTextFormatting="true"
        :readonly="options.isReadOnly"
        :canShowDiffIcon="options.canShowDiffIcon"
        fieldIdentifier="analysis.contents[1].teacherZone"
        :hintText="AppTexts.TEACHER_ZONE"
        v-model="data.contents[1].teacherZone"
      />
    </Padder>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import Slider from '@/components/inputs/Slider.vue';
import BlockTitle from '@/components/inputs/BlockTitle';
import TextInput from '@/components/inputs/TextInput';
import AppTexts from '@/constants/AppTexts';
import RichTextEditor from '@/components/RTE/RichTextEditor';
import Slide from '@/components/contentEntry/Slide';
import SubSection from '@/components/inputs/SubSection';
import _ from 'lodash';

export default {
  name: 'AnalysisSection',
  data() {
    return {
      AppTexts,
    };
  },
  computed: {
    ...mapState({
      currentStageDataCopy: (state) => state.contentEntry.currentStageDataCopy,
    }),
  },
  created() {
    //NOTE: this is for accomodating optionalContentSlide in slider since slider accepts only
    //Array of objects in one level. SO analysis.contents[2] is used as a Data holder
    //Increment the value of static index if more slides needed to be added to analysis section
    //Please leave the analysis.contents[analysis.contents.length - 1]as a placeholder for optional
    //content slide
    if (!this.data.optionalContentSlide) {
      this.data.optionalContentSlide = _.cloneDeep({
        teacherZone: '',
        layoutType: '1 rich text box',
        layoutData: {
          text: '',
        },
      });

      this.currentStageDataCopy.analysis.optionalContentSlide = _.cloneDeep({
        teacherZone: '',
        layoutType: '1 rich text box',
        layoutData: {
          text: '',
        },
      });

      this.options.signedAssetUrls.analysis.contents[2] = {
        layoutData: {},
      };
    }
    this.currentStageDataCopy.analysis.contents[2] = _.cloneDeep(this.data.optionalContentSlide);
    this.$set(this.data.contents, 2, _.cloneDeep(this.data.optionalContentSlide));
    this.options.signedAssetUrls.analysis.contents[2] = _.cloneDeep(
      this.options.signedAssetUrls.analysis.optionalContentSlide
    );
  },
  components: {
    TextInput,
    BlockTitle,
    Slider,
    RichTextEditor,
    Slide,
    SubSection,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.step-number {
  height: 30px;
  width: 30px;
  padding: 5px 10px;
  margin: 10px 10px 10px 5px;
  border-radius: 50%;
  background-color: #b5e9e4;
  text-align: center;
}
</style>
