import rte from './components/rte.js';
import fileUpload from './components/fileUpload.js';
import teacherTip from './components/teacherTip.js';

const LAYOUT_GENRIC = {
  layoutName: 'layout1',
  layoutTemplate: 'generic',
  components: [],
};

const getTeacherTipStructure = (enabler, teacherTipPath, propsObj) => {
  return [
    {
      name: 'row',
      props: { dense: true },
      children: [
        {
          name: 'switch',
          class: "['justify-center slide-break-switch']",
          style: {
            width: '400px;',
          },
          props: {
            label: 'Enable Teacher Tip',
            'hide-details': true,
            'background-color': '#F5F5F5',
          },
          model: enabler,
        },
      ],
    },
    {
      name: 'row',
      props: {
        dense: true,
        renderOn: {
          key: enabler,
          value: true,
        },
      },
      children: [
        {
          name: 'column',
          props: { cols: 12 },
          children: [teacherTip({ ...propsObj }, teacherTipPath)],
        },
      ],
    },
  ];
};

const getLayoutStructure = (layoutName, propsObj) => {
  let components = [];
  switch (layoutName) {
    case 'layout1':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { dense: true },
          children: [
            {
              name: 'column',
              props: { cols: 4 },
              children: [fileUpload(propsObj, 'components.1.children.0.child.children.0.content')],
            },
            {
              name: 'column',
              style: { 'margin-top': '3.5rem' },
              props: { cols: 8 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.1.children.1.child.children.0.content`,
                  },
                  'components.1.children.1.child.children.0.content'
                ),
              ],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.2.children.0.child.children.0.props.enabled',
          'components.2.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.2.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout2':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { dense: true },
          children: [
            {
              name: 'column',
              style: { 'margin-top': '3.5rem' },
              props: { cols: 8 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.1.children.0.child.children.0.content`,
                  },
                  'components.1.children.0.child.children.0.content'
                ),
              ],
            },
            {
              name: 'column',
              props: { cols: 4 },
              children: [fileUpload(propsObj, 'components.1.children.1.child.children.0.content')],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.2.children.0.child.children.0.props.enabled',
          'components.2.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.2.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout3':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.1.children.0.child.children.0.content`,
                  },
                  'components.1.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { dense: true },
          children: [
            {
              name: 'column',
              props: { cols: 6 },
              children: [fileUpload(propsObj, 'components.2.children.0.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 6 },
              children: [fileUpload(propsObj, 'components.2.children.1.children.0.content')],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.3.children.0.child.children.0.props.enabled',
          'components.3.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.3.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout4':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.1.children.0.child.children.0.content`,
                  },
                  'components.1.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { dense: true },
          children: [
            {
              name: 'column',
              props: { cols: 4 },
              children: [fileUpload(propsObj, 'components.2.children.0.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 4 },
              children: [fileUpload(propsObj, 'components.2.children.1.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 4 },
              children: [fileUpload(propsObj, 'components.2.children.2.children.0.content')],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.3.children.0.child.children.0.props.enabled',
          'components.3.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.3.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout5':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [fileUpload(propsObj, 'components.1.children.0.children.0.content')],
            },
          ],
        },
        {
          name: 'row',
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.2.children.0.child.children.0.content`,
                  },
                  'components.2.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.3.children.0.child.children.0.props.enabled',
          'components.3.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.3.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout6':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'Header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [fileUpload(propsObj, 'components.1.children.0.child.children.0.content')],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.2.children.0.child.children.0.props.enabled',
          'components.2.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.2.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout7':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.5.children.0.child.children.0.content`,
                  },
                  'components.5.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 6 },
              children: [fileUpload(propsObj, 'components.0.children.0.child.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 6 },
              style: { 'margin-top': '3.5rem' },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.1.child.children.0.content`,
                  },
                  'components.0.children.1.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 6 },
              children: [fileUpload(propsObj, 'components.1.children.0.child.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 6 },
              style: { 'margin-top': '3.5rem' },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.1.children.1.child.children.0.content`,
                  },
                  'components.1.children.1.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 6 },
              children: [fileUpload(propsObj, 'components.2.children.0.child.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 6 },
              style: { 'margin-top': '3.5rem' },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.2.children.1.child.children.0.content`,
                  },
                  'components.2.children.1.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.3.children.0.child.children.0.content`,
                  },
                  'components.3.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.4.children.0.child.children.0.props.enabled',
          'components.4.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.4.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout8':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step description',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.1.child.children.0.content`,
                  },
                  'components.0.children.1.child.children.0.content'
                ),
              ],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.1.children.0.child.children.0.props.enabled',
          'components.1.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.1.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    case 'layout9':
      components = [
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'header',
                    fieldIdentifier: `${propsObj.subPath}.components.0.children.0.child.children.0.content`,
                  },
                  'components.0.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 6 },
              children: [fileUpload(propsObj, 'components.1.children.0.child.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 6 },
              style: { 'margin-top': '3.5rem' },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.1.children.1.child.children.0.content`,
                  },
                  'components.1.children.1.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          props: { justify: 'space-between' },
          children: [
            {
              name: 'column',
              props: { cols: 6 },
              children: [fileUpload(propsObj, 'components.2.children.0.child.children.0.content')],
            },
            {
              name: 'column',
              props: { cols: 6 },
              style: { 'margin-top': '3.5rem' },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: true,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.2.children.1.child.children.0.content`,
                  },
                  'components.2.children.1.child.children.0.content'
                ),
              ],
            },
          ],
        },
        {
          name: 'row',
          children: [
            {
              name: 'column',
              props: { cols: 12 },
              children: [
                rte(
                  {
                    ...propsObj,
                    showValidationError: false,
                    placeholder: 'Step Description',
                    fieldIdentifier: `${propsObj.subPath}.components.3.children.0.child.children.0.content`,
                  },
                  'components.3.children.0.child.children.0.content'
                ),
              ],
            },
          ],
        },
        ...getTeacherTipStructure(
          'components.4.children.0.child.children.0.props.enabled',
          'components.4.children.0.child.children.0.content',
          {
            ...propsObj,
            fieldIdentifier: `${propsObj.subPath}.components.4.children.0.child.children.0.content`,
          }
        ),
      ];
      break;
    
    }

  return { ...LAYOUT_GENRIC, components: components };
};

export { getLayoutStructure };
