<template>
  <v-app class="app-content">
     <v-dialog v-model="isLoading" max-width="290" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          {{AppTexts.FETCHING_DATA_TEXT}}
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AppHeader :user="loggedInUser" />
    <vue-progress-bar></vue-progress-bar>
    <notifications style="margin-bottom: 15px;" position="bottom right" />
    <v-card style="height: 100%; border-radious: 0px;" class="overflow-hidden">
      <router-view />
    </v-card>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AppHeader from '@/components/AppHeader';
import AppTexts from '@/constants/AppTexts';

export default {
  name: 'MainLayout',
  data() {
    return {
      AppTexts
    }
  },
  components: {
    AppHeader,
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.loggedInUser,
      isLoading: (state) => {
        return state.isLoading;
      },
    }),
  },
  created() {
    this.setLoggedInUser();
  },
  watch: {
    isLoading(newValue) {
      console.log(newValue);
      if (newValue) {
        this.$Progress.start();
      } else {
        this.$Progress.finish();
      }
    },
  },
  methods: {
    ...mapActions(['changeLoadingState', 'setLoggedInUser']),
  },
};
</script>
<style scoped>
.app-content {
  margin-top: 64px;
}
</style>
