<template>
  <div>
    <v-dialog v-model="showLoader" max-width="400" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          {{ projectList.message }}
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showYearDailog" max-width="400" persistent>
      <v-card class="pt-2 pl-2 pr-2" height="180">
        <v-card-actions>
          <div style="font-size: 12px;">Select year to download Asset</div>
          <v-spacer></v-spacer>
          <v-btn text @click="downloadProjectBriefAssetYearToggle({}, false)">Close</v-btn>
        </v-card-actions>
        <v-select
          class="mt-2"
          dense="true"
          outlined="true"
          v-model="selectedYear"
          :items="years"
          label="Select a year"
          @change="onYearSelected"
        />
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="resetYearFilter()"
            style="
              font-weight: 600;
              font-size: 13px;
              color: black;
              width: fit-content;
              text-shadow: none;
              margin-right: 25px;
              align-content: center;
              margin-top: 10px;
              height: 15px;
            "
            >Clear</v-btn
          >
          <v-btn
            v-if="selectedYear != null"
            text
            @click="onAssetDownload()"
            style="
              background: #b81e22;
              border-radius: 111px;
              text-transform: unset !important;
              font-weight: 600;
              font-size: 12px;
              color: #ffffff;
              width: 110px;
              margin-right: 10px;
              height: 31px;
            "
            >Download</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCreateQuestionProject" content-class="learnometer-topic-form-dialog">
      <v-card height="380">
        <v-card-title class="headline grey lighten-2" primary-title>{{
          createOrEditQuestionSetDialogText
        }}</v-card-title>
        <div class="form-content">
          <v-form ref="project_assignee_form" :key="`projectFormPopUp`" class="ma-4">
            <ProjectStageAssigneeFormContent
              :projectData="projectData"
              :getUsersByRoles="getUsersByRoles"
              colsPerRow="3"
            />
          </v-form>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 0;
            margin-top: -60px;
            padding-right: 10px;
          "
        >
          <v-btn text @click="showCreateQuestionProject = false">Cancel</v-btn>
          <v-btn color="primary" v-if="this.buttonText == 'CREATE'" @click="validateAssigneeForm"
            >Create</v-btn
          >
          <v-btn
            color="primary"
            v-else-if="this.buttonText == 'UPDATE'"
            @click="validateAssigneeForm"
            >Update</v-btn
          >
          <v-btn
            color="primary"
            v-else-if="this.buttonText == 'REOPEN'"
            @click="validateAssigneeForm"
            >Reopen</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-card style="border: none; box-shadow: none; width: 100%; background-color: #fff0f0;">
      <v-simple-table style="background-color: #fff0f0;">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="landing-page">
                <div style="height: 300px; background-color: #fff0f0; margin-top: -25px;">
                  <div class="w-100 p-4 d-flex align-items-center justify-content-center">
                    <v-col>
                      <v-row style="margin-top: 60px;">
                        <td>
                          <v-container class="ml-2">
                            <p style="font-weight: 700; font-size: 20px; margin-left: 60px;">
                              LEARNOMETER Question Bank
                            </p></v-container
                          >
                        </td>

                        <td
                          style="width: 100%; margin-top: -70px; margin-right: 30px;"
                          align="right"
                        >
                          <div
                            style="width: 250px; margin-top: 10px; height: 40px; margin-left: 50px;"
                          >
                            <v-btn
                              @click="triggerFetchAirtable()"
                              style="
                                border: 2px solid #008ddc;
                                background-color: transparent;
                                box-shadow: none;
                                margin-right: 80px;
                              "
                            >
                              <img src="@/assets/airTable.png" style="width: 22px; height: 22px;" />

                              <p
                                style="
                                  color: #008ddc;
                                  font-weight: 600;
                                  font-size: 12px;
                                  margin-top: 15px;
                                  margin-left: 8px;
                                "
                              >
                                SYNC AIRTABLE
                              </p>
                            </v-btn>
                          </div>
                          <div style="margin-top: -35px; margin-right: 30px;">
                            <v-tooltip bottom small color="#FBF7C5">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span style="color: black;">
                                This feature will sync the Master and the<br />
                                Test blueprint in CES.</span
                              >
                            </v-tooltip>
                          </div>
                        </td>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 319px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                            align-items: center;

                            margin-left: 75px;
                          "
                        >
                          Grade:
                        </v-col>

                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 300px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                            align-items: center;
                            margin-left: 25px;
                          "
                        >
                          Subject:
                        </v-col>
                      </v-row>

                      <div style="width: 900px; position: relative; height: 40px;">
                        <v-row>
                          <v-select
                            :items="standards.data"
                            v-model="standardId"
                            item-text="name"
                            item-value="id"
                            style="box-sizing: border-box;justify-content: space-between;align-items: center; width:256px;height: :34px;border: 0.5px solid #818181;margin-left: 74px;"
                            label="Select Grade"
                            outlined
                            dense
                            hide-details
                            on
                            class="filter-select-field"
                          ></v-select>

                          <v-select
                            :disabled="!standardId || standardId == 0"
                            :items="subjects.data"
                            v-model="subjectId"
                            item-text="name"
                            item-value="id"
                            style="box-sizing: border-box;justify-content: space-between;align-items: center; width:256px;height: :34px;border: 0.5px solid #818181;margin-left: 74px;"
                            label="Select Subject"
                            outlined
                            dense
                            hide-details
                            on
                            class="filter-select-field"
                          ></v-select>

                          <v-btn
                            text
                            @click="resetFilter()"
                            style="
                              background: #fff0f0;
                              border: none;
                              box-shadow: none;
                              text-transform: unset !important;
                              font-weight: 600;
                              font-size: 12px;
                              color: black;
                              width: fit-content;
                              text-shadow: none;
                              margin-left: 25px;
                              align-content: center;
                              margin-top: 12px;
                              height: 15px;
                            "
                            >Clear</v-btn
                          >

                          <v-btn
                            text
                            @click="triggerFilter()"
                            style="
                              background: #b81e22;
                              border-radius: 111px;
                              text-transform: unset !important;
                              font-weight: 600;
                              font-size: 12px;
                              color: #ffffff;
                              width: 110px;
                              margin-left: 19px;
                              height: 31px;
                            "
                            >Search</v-btn
                          >
                        </v-row>
                      </div>
                    </v-col>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-container fluid class="landing">
      <v-card style="border: none; box-shadow: none; width: 100%; margin-left: 50px;">
        <v-simple-table class="airTable-btn">
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="airTable-btn">
                  <v-container
                    style="margin-left: -21px; margin-top: 10px; font-weight: 600; font-size: 14px;"
                  >
                    <p style="font-weight: 600; font-size: 16px;">GRADES & SUBJECTS</p>
                  </v-container>

                  <div
                    style="width: 250px; margin-left: auto; margin-bottom: 30px; margin-top: -60px;"
                  >
                    <td>
                      <v-btn
                        @click="gotoCreateQuestionSetView()"
                        v-if="(isAdmin || isAcademicAdmin)"
                        style="
                          background: #b81e22;
                          border-radius: 111px;
                          text-transform: unset !important;
                          font-weight: 600;
                          font-size: 12px;
                          color: #ffffff;
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          align-items: center;
                          margin-left: -90px;
                          padding: 9px 15px;
                          gap: 6px;
                          width: 147px;
                          height: 31px;
                          background: #b81e22;
                          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                          border-radius: 3px;
                        "
                      >
                        Create question set</v-btn
                      >
                    </td>
                    <td style>
                      <v-btn
                        @click="gotoViewQuestionSetView()"
                        v-if="(isAdmin || isAcademicAdmin)"
                        style="
                          background: #b7e9e4;
                          border-radius: 111px;
                          text-transform: unset !important;
                          font-weight: 600;
                          font-size: 12px;
                          color: black;
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          align-items: center;
                          padding: 9px 15px;
                          gap: 6px;
                          margin-left: 20px;
                          width: 147px;
                          height: 31px;
                          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                          border-radius: 3px;
                        "
                        >View question set</v-btn
                      >
                    </td>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <v-card class="micro-topic-filter-card">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, index) in projectList.data" :key="`airtableProjectList${index}`">
                <td class="body-1 font-weight-medium">
                  G{{ item.standardName }}, {{ item.subjectName }}
                </td>
                <td class="py-3">
                  <SubProjectStatus
                    lessonType="Questions"
                    :currentStage="
                      MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING[item.currentStage] || 'NA'
                    "
                    :status="item.workflowStatus || AppTexts.NOT_STARTED"
                  />
                </td>
                <td>
                  <div
                    v-if="item.isProjectExist"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      margin-left: auto;
                      justify-content: end;
                    "
                  >
                    <v-btn
                      v-if="canShowReopenButton(item)"
                      width="110"
                      outlined
                      color="#C10230"
                      style="
                        margin-right: 20px;
                        border: 1.5px solid #b81e22;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                      "
                      @click="() => showReopenProjectDialog(item)"
                    >
                      Reopen
                    </v-btn>
                    <v-btn
                      v-if="canShowEditButton(item)"
                      width="110"
                      outlined
                      color="#C10230"
                      style="
                        margin-right: 20px;
                        border: 1.5px solid #b81e22;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                      "
                      @click="showEditProjectDialog(item)"
                    >
                      Edit
                    </v-btn>
                    <v-btn
                      style="
                        margin-right: 20px;
                        text-transform: unset !important;
                        width: 110px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                      "
                      class="xs-accent-button action-button create-micro-topic-btn"
                      @click="gotoProject(item.projectId)"
                    >
                      View
                    </v-btn>
                    <v-btn
                      v-if="canDownloadProjectBriefAsset(item)"
                      width="110"
                      outlined
                      color="#C10230"
                      style="
                        border: 1.5px solid #b81e22;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                      "
                      @click="downloadProjectBriefAssetYearToggle(item, true)"
                    >
                      Asset
                    </v-btn>
                  </div>

                  <v-btn
                    class="right-btn"
                    outlined
                    color="#C10230"
                    style="
                      margin-left: 20px;
                      border: 1.5px solid #b81e22;
                      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    "
                    width="110"
                    v-else
                    @click="() => showCreateProjectDialog(item)"
                  >
                    Create
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import SubProjectStatus from '@/components/contentEntry/microTopic/SubProjectStatus';
import ProjectStageAssigneeFormContent from '@/components/contentEntry/ProjectStageAssigneeFormContent';
import AppTexts from '@/constants/AppTexts';
import { mapActions, mapState } from 'vuex';
import {
  SKIP_STAGE_USER_ID_VALUE,
  MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING,
} from '@/constants/microTopic';
import { PROJECT_WORKFLOW_STATUSES_HASH, PROJECT_LESSON_PLAN_STAGES } from '@/constants/Project';
import { COMPLETED_STAGE } from '@/constants/microTopic';
import { PROJECT_ENTITY_TYPE_LEARNOMETER } from '@/constants/common';
export default {
  name: 'Learnometer',
  components: {
    SubProjectStatus,
    ProjectStageAssigneeFormContent,
  },
  watch: {
    isAdmin: {
      immediate: true,
      handler(val) {
        this.canShowAirtableSync = val;
      },
    },
  },
  beforeCreate() {
    this.getCurrentUserAuthPermissions();
  },
  created() {
    this.getCurrentUserAuthPermissions();
    console.log('isAdmin: ' + this.isAdmin);
    console.log('isAcademicAdmin: ' + this.isAcademicAdmin);
    setTimeout(null, 2000);
    this.fetchStandards({
      allStandards: false,
    });
    this.fetchSubjects();
    this.getUsers();
    this.resetProjectData();
    this.fetchProjectsFromAirTableStandardsAndSubjects();
  },
  data() {
    return {
      PROJECT_ENTITY_TYPE_LEARNOMETER,
      MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING,
      AppTexts,
      COMPLETED_STAGE,
      isEditPage: false,
      isReopen: false,
      buttonText: 'CREATE',
      subjectId: '',
      standardId: '',
      canShowAirtableSync: false,
      mandatoryStages: ['Asset Uploader', 'Asset Authorizer', 'Proof Reader'],
      showCreateQuestionProject: false,
      SKIP_STAGE_USER_ID_VALUE,
      updateData: {},
      showYearDailog: false,
      selectedYear: null,
      years: [2022, 2023, 2024],
      yearId: null,
      selectedProjectIdForAsset: null,
      projectData: {
        workflowStatus: PROJECT_WORKFLOW_STATUSES_HASH.NOT_STARTED,
        entityType: 'Learnometer',
        type: 'learnometerQuestion',
        subjectId: 0,
        standardId: 0,
        name: '',
        entityId: '111',
        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
        contents: [
          {
            currentStage: '',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      subjects: (state) => state.learnometer.subjectListApiResponse,
      standards: (state) => state.learnometer.standardsListApiResponse,
      projectList: (state) => state.learnometer.projectListApiResponse,
      users: (state) => state.user.users,
      isAdmin: (state) => state.auth.isAdmin,
      airtableApiFetchCall: (state) => state.learnometer.airtableDataOnApiResponse,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
    }),
    showLoader() {
      return this.projectList.status == 'in_progress';
    },
    yearDailog() {
      return this.showYearDailog == true;
    },
    createOrEditQuestionSetDialogText() {
      return this.buttonText == 'UPDATE'
        ? 'Update Question Set'
        : this.buttonText == 'REOPEN'
        ? 'Reopen Question Set'
        : 'Create Question Set';
    },
  },
  methods: {
    ...mapActions([
      'fetchSubjects',
      'fetchStandards',
      'getUsers',
      'createLearnometerQuestionProject',
      'fetchProjectsFromAirTableStandardsAndSubjects',
      'fetchAirtableData',
      'getCurrentUserAuthPermissions',
      'updateProject',
      'downloadLPAssets',
    ]),
    triggerFetchAirtable() {
      this.fetchAirtableData();
    },
    resetYearFilter() {
      this.selectedYear = null;
      this.yearId = null;
    },
    downloadProjectBriefAssetYearToggle(row, open) {
      if (open) {
        this.showYearDailog = true;
        this.selectedProjectIdForAsset = row.projectId;
      } else {
        this.showYearDailog = false;
      }
    },
    onYearSelected() {
      if (this.selectedYear == 2022) {
        this.yearId = 1;
      } else if (this.selectedYear == 2023) {
        this.yearId = 2;
      } else if (this.selectedYear == 2024) {
        this.yearId = 3;
      }
    },
    onAssetDownload() {
      this.showYearDailog = false;
      this.downloadProjectBriefAsset();
    },
    getUsersByRoles(role) {
      let sortListedUsers = [];
      if (!this.mandatoryStages.includes(role)) {
        sortListedUsers.push({
          id: this.SKIP_STAGE_USER_ID_VALUE,
          name: 'Skip(Not assign Users)',
        });
      }

      for (let user of this.users) {
        let rolesList = user.roles.map((role) => role.name);
        if (rolesList.includes(role) && user.user && user.user._id && user.user.name) {
          sortListedUsers.push({
            id: user.user._id,
            name: user.user.name,
          });
        }
      }

      return sortListedUsers;
    },
    validateAssigneeForm() {
      if (this.$refs.project_assignee_form.validate()) {
        if (this.isEditPage) {
          this.showCreateQuestionProject = false;
          this.isEditPage = false;
          this.updateProject(this.updateData);
        } else {
          this.saveForm();
        }
      }
    },
    saveForm() {
      this.projectData.standardId = +this.selectedSubjectGrade.standardId;
      this.projectData.subjectId = +this.selectedSubjectGrade.subjectId;
      this.projectData.name = `Grade ${this.selectedSubjectGrade.standardName} - ${this.selectedSubjectGrade.subjectName} Learnometer Question Set`;
      this.createLearnometerQuestionProject(this.projectData);
      this.showCreateQuestionProject = false;
      this.resetProjectData();
    },
    resetFilter() {
      this.subjectId = '';
      this.standardId = '';
      this.triggerFilter();
    },
    showCreateProjectDialog(row) {
      this.resetProjectData();
      this.isEditPage = false;
      this.buttonText = 'CREATE';
      for (const assignee in this.projectData.assignees) {
        this.projectData.assignees[assignee].id = '';
      }
      this.showCreateQuestionProject = true;
      this.selectedSubjectGrade = row;
    },
    resetProjectData() {
      this.projectData = {
        workflowStatus: PROJECT_WORKFLOW_STATUSES_HASH.NOT_STARTED,
        entityType: 'Learnometer',
        type: 'learnometerQuestion',
        subjectId: '',
        standardId: '',
        name: '',
        entityId: '111',
        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
        contents: [
          {
            currentStage: '',
          },
        ],
      };
    },
    triggerFilter() {
      console.log(this.subjectId);
      this.fetchProjectsFromAirTableStandardsAndSubjects({
        standardId: +(this.standardId || 0),
        subjectId: +(this.subjectId || 0),
      });
    },
    gotoProject(id) {
      this.$router.push({
        name: 'ViewLearnometerProject',
        params: { id },
      });
    },
    gotoViewQuestionSetView() {
      this.$router.push({
        name: 'ViewQuestionSet',
      });
    },
    gotoCreateQuestionSetView() {
      this.$router.push({
        name: 'CreateQuestionSet',
      });
    },
    showEditProjectDialog(row) {
      this.resetProjectData();
      this.isEditPage = true;
      this.buttonText = 'UPDATE';
      this.projectData.workflowStatus = row.workflowStatus;
      this.projectData.contents[0].currentStage = row.currentStage;
      for (const assignee in this.projectData.assignees) {
        this.projectData.assignees[assignee].id = row.assignees[assignee] || '';
      }
      this.updateData = {
        id: row.projectId,
        name: row.projectName,
        product: {
          id: undefined,
        },
        board: {
          id: undefined,
        },
        standard: {
          id: +row.standardId,
        },
        subject: {
          id: +row.subjectId,
        },
        block: {
          id: 111,
        },
        entityType: this.PROJECT_ENTITY_TYPE_LEARNOMETER,
        assignees: this.projectData.assignees,
      };
      this.showCreateQuestionProject = true;
      this.selectedSubjectGrade = row;
    },

    showReopenProjectDialog(row) {
      this.resetProjectData();
      this.isReopen = true;
      this.buttonText = 'REOPEN';
      for (const assignee in this.projectData.assignees) {
        this.projectData.assignees[assignee].id = '';
      }
      this.showCreateQuestionProject = true;
      this.selectedSubjectGrade = row;
    },
    canShowEditButton(row) {
      return (
        row.workflowStatus &&
        row.workflowStatus.toLowerCase() == PROJECT_WORKFLOW_STATUSES_HASH.IN_PROGRESS &&
        row.currentStage != COMPLETED_STAGE &&
        (this.isAdmin || this.isAcademicAdmin)
      );
    },
    canDownloadProjectBriefAsset(row) {
      return (
        (this.isAdmin || this.isAcademicAdmin) &&
        row.currentStage == PROJECT_LESSON_PLAN_STAGES.ASSET_UPLOAD_STAGE
      );
    },
    canShowReopenButton(row) {
      return (
        row.workflowStatus &&
        row.workflowStatus.toLowerCase() == PROJECT_WORKFLOW_STATUSES_HASH.COMPLETED &&
        row.currentStage == COMPLETED_STAGE &&
        (this.isAdmin || this.isAcademicAdmin)
      );
    },
    downloadProjectBriefAsset() {
      this.downloadLPAssets({
        projectId: this.selectedProjectIdForAsset,
        libraryId: 0,
        yearId: this.yearId ?? 0,
      });
    },
  },
  mounted() {
    this.getCurrentUserAuthPermissions();
  },
};
</script>
<style>
.filter-select-field,
.filter-select-field label {
  font-size: 14px;
  font-weight: 500;
  color: black;
}
.filter-select-field .v-label:not(.v-label--active) {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-5px);
}
.micro-topic-filter-card {
  padding: 20px;
  margin-left: 50px;
}
.landing {
  padding: 30px;
}
.learnometer-topic-form-dialog {
  transform: translateY(30px);
  max-height: 88% !important;
}

.landing-page:hover {
  background-color: #fff0f0;
  box-shadow: none;
}
.airTable-btn:hover {
  background-color: white;
  box-shadow: none;
}
.right-btn {
  position: absolute;
  right: 0;
  margin-right: 45px;
}
</style>
