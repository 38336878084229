<template>
  <div>
    <div class="switch-container" v-if="showLegacyComponentSwitcher">
      <v-switch
        v-model="isActive"
        :label="isActive ? 'Slide Layout' : 'Legacy Layout'"
        :true-value="true"
        :false-value="false"
        @change="buttonStateChanged()"
      >
        <template v-slot:label>
          <v-progress-circular
            v-if="!switchCompleted"
            indeterminate
            color="primary"
            size="20"
          ></v-progress-circular>
          <span v-else>{{ isActive ? 'Slide Layout' : 'Legacy Layout' }}</span>
        </template>
      </v-switch>
    </div>
    <div v-if="switchCompleted">
      <slot v-if="isActive" name="true-slot"></slot>
      <slot v-else name="false-slot"></slot>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'XSRightSwitch',
  data() {
    return {
      isActive: this.initialActive,
      switchCompleted: true,
    };
  },
  methods: {
    ...mapActions(['switchComponent']),
    async buttonStateChanged() {
      this.switchCompleted = false;
      await this.switchComponent({
        subPath: this.subPath,
        dsToSwitch: this.isActive ? this.secondaryDs : this.primaryDs,
        currentStatus: this.isActive,
        ignoreKeys: this.ignoreKeys,
        assetKey: this.assetKey
      });
      this.switchCompleted = true;
    },
  },
  props: {
    initialActive: {
      type: Boolean,
      default: false,
    },
    primaryDs: {
      type: [Array, Object],
      required: false,
    },
    secondaryDs: {
      type: [Array, Object],
      required: false,
    },
    subPath: {
      type: String,
      required: true,
    },
    showLegacyComponentSwitcher: {
      type: Boolean,
      default: false,
    },
    ignoreKeys: {
      type: Array,
      default: () => [],
    },
    assetKey: {
      type: String,
      default: null,
    }
  },
  mounted() {
    this.isActive = this.initialActive;
  },
};
</script>
<style scoped>
.switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
