<template>
  <div>
    <Padder :left="50" :right="10" :vertical="20" style="overflow: hidden;">
      <!-- Third Row -->
      <v-row>
        <SubSection title="Answer" />
      </v-row>
      <v-radio-group @change="changedOption" :value="correctOption">
        <v-row
          v-for="(option, index) in data.questionData.options"
          :key="`msqQuestionTypeOptions${index}`"
        >
          <Option
            :key="`trueOrFalseQuestion${index}`"
            :data="option"
            :partialFieldIdentifier="`['${data._id}'].questionData.options`"
          />
        </v-row>
      </v-radio-group>
      <XSDivider />
    </Padder>
  </div>
</template>

<script>
import Option from '@/components/contentEntry/questionComponents/dialogComponents/tfComponents/Option.vue';
import SubSection from '@/components/inputs/SubSection.vue';
export default {
  components: {
    Option,
    SubSection,
  },
  computed: {
    correctOption() {
      for (let i = 0; i < this.data.questionData.options.length; i++) {
        let { isCorrectAnswer } = this.data.questionData.options[i];
        if (isCorrectAnswer == true) {
          return this.data.questionData.options[i].optionText;
        }
      }
      return null;
    },
  },
  methods: {
    changedOption(value) {
      this.data.questionData.options.map((option) => {
        option.isCorrectAnswer = option.optionText == value;
        return;
      });
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
