<template>
  <v-form ref="fileInputForm">
    <div
      style="align-items: baseline;"
      v-for="(item, index) in items"
      :key="index"
      :v-bind="index"
      class="file-input-row"
    >
      <v-row>
        <v-col class="pad-Rm">
          <RichTextEditor
            :key="index + 'name'"
            :canShowDiffIcon="options.canShowDiffIcon"
            :isDiffChanged="isResourceTextChanged(index)"
            :fieldIdentifier="'aim.resources[' + index + '].text'"
            :placeholder="placeholder"
            class="text-field-text"
            v-model="item.text"
            :showValidationError="true"
            :counter="50"
            v-if="item.text != null && item.text != undefined"
            :readonly="!options.isNotReadOnly"
            :includeOnlyToolBarActions="BASIC_TOOLS"
          />
        </v-col>
        <v-col class="pad-Lm" cols="5">
          <FileInput
            :canShowDiffIcon="options.canShowDiffIcon"
            :isDiffChanged="isResourceImageChanged(index)"
            :diffIdentifier="'aim.resources[' + index + '].' + imageKey"
            :image_url="options.isAssetUploadRequired ? item.imageUrl : ''"
            :image_url_brief="item.briefUrl"
            :previewUrl="
              options.isAssetUploadRequired
                ? options.signedAssetUrls.aim.resources[index].imageUrl
                : ''
            "
            :previewUrlBrief="options.signedAssetUrls.aim.resources[index].briefUrl"
            :fileAcceptType="options.fileAcceptType"
            :label="label"
            :loadingStatus="options.fileUploadingStatus.resources[index] == 'in_progress'"
            :isRequired="options.isRequired"
            :isAssetUploadRequired="options.isAssetUploadRequired"
            :fileChangeHandler="
              (file) =>
                fileUploadHandler(
                  file,
                  'resources.' + index,
                  options.isAssetUploadRequired ? item.imageUrl : item.briefUrl,
                  index
                )
            "
            :isNotReadOnly="options.isNotReadOnly"
            @addBriefToAsset="
              (previewUrl, briefUrl, approveAsset) =>
                addToAsset(previewUrl, briefUrl, approveAsset, 'resources.' + index, index)
            "
            :canFinalizeAsset="true"
            :canAppproveAsset="canAppproveAsset"
            :isAssetApproved="item.imageUrlApproved"
          /><br />
          <RichTextEditor
            :key="index + 'oprogram_Resources_brief'"
            :readonly="!options.isNotReadOnly || options.isAssetUploadRequired"
            :canShowDiffIcon="options.canShowDiffIcon"
            :fieldIdentifier="'aim.resources[' + index + '].brief'"
            :placeholder="AppTexts.RESOURCE_IMAGE_BRIEF"
            v-model="item.brief"
            :counter="3000"
            :allowWordCount="false"
            :allowCharacterCount="true"
            :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
            :showValidationError="(!options.isAssetUploadRequired && options.validationFlag)"
          />
        </v-col>
        <v-col cols="1">
          <v-btn
            icon
            @click="deleteResource(index)"
            title="Remove Resource"
            v-if="options.isNotReadOnly"
            style="padding-top: 12px;"
          >
            <img height="16" width="16" src="@/assets/close.svg" />
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <p class="error-message">{{ error }}</p>
    <div style="display: flex; align-items: center;">
      <div>
        <v-btn
          class="xs-accent-button"
          v-show="withAdd"
          @click="addResource"
          :class="!canAddResouce() ? 'disable-btn-click-event' : ''"
        >
          {{ AppTexts.ADD_RESOURCE }}
        </v-btn>
      </div>
      <div>
        <p
          class="mgn-Lm remaining-step-warning-text"
          style="margin-bottom: 0; padding-top: 1px;"
          v-if="canAddResouce()"
        >
          {{ getStepAdditionValidationText(resourseMaxLength - items.length, 'resource') }}
        </p>
      </div>
    </div>
  </v-form>
</template>

<script>
import Errors from '@/constants/Errors';
import AppTexts from '@/constants/AppTexts';
import { getFileNameFromUrl } from '@/functions/global';
import FileInput from '@/components/inputs/FileInput';
import { getStepAdditionValidationText } from '@/utils';
import RichTextEditor from '@/components/RTE/RichTextEditor';

export default {
  name: 'ContentEntryResources',
  components: {
    FileInput,
    RichTextEditor,
  },
  data() {
    return {
      Errors,
      AppTexts,
      imageKey: this.options.isAssetUploadRequired ? 'imageUrl' : 'briefUrl',
    };
  },
  methods: {
    getStepAdditionValidationText,
    getFileNameFromUrl,
    canAddResouce() {
      return this.items.length < this.resourseMaxLength && this.options.isNotReadOnly;
    },
    isResourceTextChanged(index) {
      try {
        if (this.options.canShowDiffIcon) {
          return (
            this.resourcesDiff[index].isChanged === true ||
            this.resourcesDiff[index].text.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    fileUploadHandler(file, type, url, index) {
      if (!file) {
        this.fileChangeHandler(file, type, url, index);
        return false;
      }
      if (
        !(
          file.type.startsWith('image') ||
          this.options.fileAcceptType.includes(file.name.split('.').pop())
        )
      ) {
        this.$notify({
          title: 'Error!',
          text: `File format not supported, please upload a file of type ${this.options.fileAcceptType.replace(
            '/*',
            ''
          )}`,
          type: 'error',
          clean: false,
          duration: -1,
        });
      } else this.fileChangeHandler(file, type, url, index);
    },
    isResourceBriefChanged(index) {
      try {
        if (this.options.canShowDiffIcon) {
          return (
            this.resourcesDiff[index].isChanged === true ||
            this.resourcesDiff[index].brief.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isResourceImageChanged(index) {
      try {
        if (this.options.canShowDiffIcon) {
          return (
            this.resourcesDiff[index].isChanged === true ||
            this.resourcesDiff[index][this.imageKey].isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    addResource: {
      type: Function,
      required: true,
    },
    deleteResource: {
      type: Function,
      required: true,
    },
    withAdd: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: true,
      default: '',
    },
    showImageBrief: {
      type: Boolean,
      required: true,
      default: false,
    },
    resourcesDiff: {
      type: Array,
      required: true,
    },
    resourseMaxLength: {
      type: Number,
      required: false,
      default: 7,
    },
    fileChangeHandler: {
      type: Function,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    addToAsset: {
      type: Function,
      required: true,
    },
    canAppproveAsset: {
      type: Boolean,
      default: false,
    },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.error-message {
  margin: 10px;
  color: red;
}
</style>
