<template>
  <div>
    <v-layout>
      <span @click="dialog = true"><slot /></span>
      <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
        <v-card style="overflow: hidden;" tile>
          <div class="bottom-save-close-bar">
            <div class="close-save-button-wrapper">
              <v-btn @click="onCloseDialog" height="30" width="180" style="margin-right: 40px;">
                cancel</v-btn
              >
              <v-btn
                @click="onClickSave"
                depressed
                color="primary"
                height="30"
                width="180"
                style="margin-right: 30px;"
              >
                add questions</v-btn
              >
            </div>
          </div>
          <v-layout style="padding-top: 20px;">
            <QuestionFilterPanel
              :filterOptions="questionSearchFilterOptions"
              :selectedFilters="selectedFilters"
              @onClickClearFilter="handleClearFilter"
              @onApplyFilter="onClickApplyFilter()"
              @onChangeFilterValue="handleChangeFilterValue"
            />
            <v-flex xs9 style="max-height: 100%; overflow: auto;" class="question-list-wrapper">
              <div class="display-flex question-list-top-wrapper">
                <BlockTitle title="Question List" />
                <span>{{
                  Object.keys(groupedQuestions).length > 0
                    ? Object.keys(groupedQuestions).length + ' questions found'
                    : ''
                }}</span>
              </div>
              <div>
                <div v-if="Object.keys(groupedQuestions).length == 0">
                  <center>No Questions Found for the selected filter</center>
                </div>
                <div v-else>
                  <div v-for="(key, index) of Object.keys(groupedQuestions)" :key="index">
                    <div
                      v-if="Array.isArray(groupedQuestions[key])"
                      class="list-view-question-wrapper"
                    >
                      <SingleGroupQuestionView
                        :isSelected="
                          getIsQuestionOrQuestionGroupSelectedValue(groupedQuestions[key])
                        "
                        :index="index + 1"
                        :questionMetaInfo="questionMetaInfo"
                        :data="groupedQuestions[key]"
                        :canSelectItem="canAddQuestion"
                        @onSelectQuestion="handleQuestionSelect"
                        @showQuestionFullScreenDialog="showQuestionFullScreenDialog"
                        :lessonPlans="currentProjectQuestionLinkableLessonPlans"
                      />
                    </div>
                    <div v-else class="list-view-question-wrapper">
                      <SingleQuestionView
                        :isSelected="
                          getIsQuestionOrQuestionGroupSelectedValue(groupedQuestions[key])
                        "
                        :index="index + 1"
                        :questionMetaInfo="questionMetaInfo"
                        :data="groupedQuestions[key]"
                        :canSelectItem="canAddQuestion"
                        @onSelectQuestion="handleQuestionSelect"
                        @showQuestionFullScreenDialog="showQuestionFullScreenDialog"
                        :lessonPlans="currentProjectQuestionLinkableLessonPlans"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
    <div class="text-xs-center">
      <v-dialog v-model="showQuestionSearchLoader" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
      @click:outside="closeQuestionFullScreenDialog"
      max-width="80%"
      v-model="questionFullScreenDialog.status"
      hide-overlay
    >
      <div class="full-screen-question-dialog-wrapper">
        <Questions
          :data="[questionFullScreenDialog.data]"
          @onQuestionDataChanged="() => {}"
          :saveForm="() => {}"
          :canAppproveAsset="false"
          :toggleComments="() => {}"
          @question-preview-cliked="() => {}"
          :lessonPlans="currentProjectQuestionLinkableLessonPlans"
          currentStage=""
          :options="options"
          :isViewOnly="true"
          :canShowActionButtons="false"
          :forceUseAssetBasedOnQuestionProjectCurrentStageData="true"
          :lpType="lpType"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="showMaxLimitReachedDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Max limit Reached</v-card-title>
        <v-card-text>
          You have reached the maximum question limit for this section.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showMaxLimitReachedDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import QuestionFilterPanel from '@/components/Questions/QuestionFilterPanel.vue';
import SingleQuestionView from '@/components/Questions/SingleQuestionView';
import SingleGroupQuestionView from '@/components/Questions/SingleGroupQuestionView';
import BlockTitle from '@/components/inputs/BlockTitle.vue';
import Questions from '@/components/contentEntry/Questions';
import _ from 'lodash';
import { capitalCase } from 'change-case';
import questionsOprogramProject from '@/constants/questionsOprogramProject.js';
import { LP_TYPES_HASH, LANGUAGE_SUBJECT_SHORT_NAMES } from '@/constants/common';
import QuestionsOprogramProject from '@/constants/questionsOprogramProject.js';

export default {
  name: 'AddQuestionDialog',
  data() {
    return {
      LP_TYPES_HASH,
      LANGUAGE_SUBJECT_SHORT_NAMES,
      questionsOprogramProject,
      selectedQuestions: [],
      selectedFilters: {},
      dialog: false,
      showMaxLimitReachedDialog: false,
      questionSearchFilterOptions: {},
      newlyAddedUniqueQuestionCount: 0,
      questionFullScreenDialog: {
        status: false,
        data: {},
      },
      options: { isReadOnly: true },
    };
  },
  components: {
    QuestionFilterPanel,
    SingleQuestionView,
    SingleGroupQuestionView,
    BlockTitle,
    Questions,
  },
  computed: {
    ...mapState({
      searchedQuestionsList: (state) => state.questions.searchedQuestionsList,
      questionMetaInfo: (state) => state.questions.questionMetaInfo,
      showQuestionSearchLoader: (state) => state.questions.showQuestionSearchLoader,
      blockId: (state) => state.currentProject.block.id,
      currentProject: (state) => state.currentProject,
    }),
    groupedQuestions() {
      let questionData = {};
      const searchedQuestionsListInLessonOrder = [];

      // Sort the questions by LP then difficulty level
      for (const question of this.currentProjectQuestionLinkableLessonPlans) {
        const thisLessonQuestions = this.searchedQuestionsList.filter(function (_q) {
          return _q.lessonPlanLibraryId == question.library_id;
        });

        for (const difficultyLevel of [
          QuestionsOprogramProject.DIFFICULTY_EASY,
          QuestionsOprogramProject.DIFFICULTY_MEDIUM,
          QuestionsOprogramProject.DIFFICULTY_HARD,
        ]) {
          thisLessonQuestions
            .filter(function (_q) {
              return _q.difficulty == difficultyLevel;
            })
            .map(function (_q) {
              searchedQuestionsListInLessonOrder.push(_q);
            });
        }
      }

      searchedQuestionsListInLessonOrder.forEach((datum) => {
        if (!datum.questionGroupId) {
          questionData[datum._id] = _.cloneDeep(datum);
        } else {
          if (!questionData[datum.questionGroupId]) {
            questionData[datum.questionGroupId] = [];
          }
          questionData[datum.questionGroupId].push(_.cloneDeep(datum));
        }
      });
      return questionData;
    },
    canAddQuestion() {
      return this.newlyAddedUniqueQuestionCount < this.maxItemsCanAdd;
    },
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.onClickApplyFilter();
        }
      },
    },
    currentProject: {
      immediate: true,
      deep: true,
      handler() {
        this.addLanguageSkillToFilter();
      },
    },
    canAddQuestion: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (this.dialog) {
          if (newVal == false && oldVal == true) {
            this.showMaxLimitReachedDialog = true;
          }
        }
      },
    },
    questionMetaInfo: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.constructQuestionSearchFilters(val);
          this.addLanguageSkillToFilter();
        }
      },
    },
    currentProjectQuestionLinkableLessonPlans: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.questionSearchFilterOptions['lessonPlanLibraryId'] = {
            type: 'select',
            name: 'Lesson Plan',
            filterList: this.currentProjectQuestionLinkableLessonPlans.map((e) => {
              return { label: e.name, value: parseInt(e.library_id) };
            }),
          };
        }
      },
    },
  },
  beforeDestroy() {
    this.closeQuestionFullScreenDialog();
  },
  methods: {
    ...mapActions(['getQuestionsBasedOnFilters', 'clearSearchedQuestionsList']),
    capitalCase,
    addLanguageSkillToFilter() {
      if (
        this.questionMetaInfo &&
        this.questionMetaInfo.languageSkills &&
        this.currentProject &&
        this.currentProject.subject
      ) {
        if (this.LANGUAGE_SUBJECT_SHORT_NAMES.includes(this.currentProject.subject.shortName)) {
          this.questionSearchFilterOptions['languageSkill'] = {
            type: 'select',
            name: 'Language Skill',
            filterList: this.questionMetaInfo.languageSkills.map((e) => {
              return { label: e, value: _.camelCase(e) };
            }),
          };
        }
      }
    },
    onClickSave() {
      this.onSubmit(this.selectedQuestions);
      this.resetForm();
      this.dialog = false;
    },
    onClickApplyFilter() {
      if (this.selectedFilters.time) {
        this.selectedFilters.time = [parseInt(this.selectedFilters.time)];
      }

      this.getQuestionsBasedOnFilters({
        blockId: this.blockId,
        filter: this.selectedFilters,
      });
    },
    handleClearFilter() {
      const filterKeys = Object.keys(this.questionSearchFilterOptions);
      let defailtFilterData = {};

      filterKeys.forEach((key) => {
        if (key == 'questionPurpose') {
          defailtFilterData[key] = [this.questionPurpose];
        } else {
          defailtFilterData[key] = null;
        }
      });

      this.selectedFilters = defailtFilterData;
    },
    clearSelectedItems() {
      this.selectedQuestions = [];
      this.newlyAddedUniqueQuestionCount = 0;
    },
    handleQuestionSelect(isChecked, question) {
      let newlyAddedQuestionList = Array.isArray(question) ? question : [question];
      if (isChecked) {
        this.newlyAddedUniqueQuestionCount += 1;
        this.selectedQuestions = this.selectedQuestions.concat(newlyAddedQuestionList);
      } else {
        this.newlyAddedUniqueQuestionCount -= 1;
        newlyAddedQuestionList.forEach((element) => {
          const questionIndex = this.selectedQuestions.findIndex((e) => e == element);
          if (questionIndex != -1) {
            this.selectedQuestions.splice(questionIndex, 1);
          }
        });
      }
    },
    onCloseDialog() {
      this.resetForm();
      this.dialog = false;
    },
    resetForm() {
      this.clearSelectedItems();
      this.handleClearFilter();
      this.clearSearchedQuestionsList();
    },
    handleChangeFilterValue(key, value) {
      if (key) {
        this.selectedFilters[key] = value;

        if (key == 'skill') {
          if (value && value.length) {
            let currentSkillSubSkills = [];
            value.forEach((skillId) => {
              currentSkillSubSkills = currentSkillSubSkills.concat(
                this.questionMetaInfo['subSkills'][skillId]
              );
            });

            this.questionSearchFilterOptions['subSkill'].filterList = currentSkillSubSkills.map(
              (subSkill) => {
                return {
                  label: subSkill.name,
                  value: parseInt(subSkill.id),
                };
              }
            );
          } else {
            this.selectedFilters['subSkill'] = null;
            this.questionSearchFilterOptions['subSkill'].filterList = [];
          }
        }
      }
    },
    getIsQuestionOrQuestionGroupSelectedValue(question) {
      let questionIds = [];

      if (Array.isArray(question)) {
        questionIds = question.map((element) => element._id);
      } else {
        questionIds = [question._id];
      }

      const isSelected = this.selectedQuestions.find((e) => questionIds.includes(e._id));
      return !!isSelected;
    },
    constructQuestionSearchFilters(questionMetaInfo) {
      let questionFilterHashTemp = {};
      questionFilterHashTemp['lessonPlanLibraryId'] = {};

      questionFilterHashTemp['time'] = {
        type: 'textField',
        name: 'Time (mins)',
      };

      questionFilterHashTemp['questionType'] = {
        type: 'select',
        name: 'Question Type',
        filterList: [
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_MCQ,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_MCQ,
          },
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_MSQ,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_MSQ,
          },
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_TF,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_TF,
          },
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_MTF,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_MTF,
          },
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_FIB,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_FIB,
          },
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_ATF,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_ATF,
          },
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_SA,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_SA,
          },
          {
            label: this.questionsOprogramProject.DISPLAY_QUESTION_TYPE_LA,
            value: this.questionsOprogramProject.ACRONYM_QUESTION_TYPE_LA,
          },
        ],
      };

      questionFilterHashTemp['questionPurpose'] = {
        type: 'select',
        name: 'Question Purpose',
        disabled: true,
        filterList: [
          {
            label: _.startCase(_.camelCase(this.questionPurpose)),
            value: this.questionPurpose,
          },
        ],
      };

      questionFilterHashTemp['academicYear'] = {
        type: 'select',
        name: 'Academic year',
        filterList: questionMetaInfo.academicYears.map((e) => {
          return { label: e, value: e };
        }),
      };

      questionFilterHashTemp['difficulty'] = {
        type: 'select',
        name: 'Difficulty',
        filterList: questionMetaInfo.difficultyLevels.map((e) => {
          return { label: this.capitalCase(e), value: e };
        }),
      };

      questionFilterHashTemp['skill'] = {
        type: 'select',
        name: 'Skill',
        filterList: questionMetaInfo.skills.map((e) => {
          return { label: e.name, value: parseInt(e.id) };
        }),
      };

      questionFilterHashTemp['subSkill'] = {
        type: 'select',
        name: 'Sub Skill',
        filterList: [],
      };

      questionFilterHashTemp['lessonPlanLibraryId'] = {
        type: 'select',
        name: 'Lesson Plan',
        filterList: (this.currentProjectQuestionLinkableLessonPlans || []).map((e) => {
          return { label: e.name, value: parseInt(e.library_id) };
        }),
      };

      this.questionSearchFilterOptions = questionFilterHashTemp;
      this.handleClearFilter();
    },
    showQuestionFullScreenDialog(questionData) {
      this.questionFullScreenDialog = {
        status: true,
        data: questionData,
      };
    },
    closeQuestionFullScreenDialog(questionData) {
      this.questionFullScreenDialog = {
        status: true,
        data: questionData,
      };
    },
  },

  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    maxItemsCanAdd: {
      type: Number,
      required: true,
    },
    questionPurpose: {
      type: String,
      required: true,
    },
    currentProjectQuestionLinkableLessonPlans: {
      type: Array,
      required: true,
    },
    lpType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.question-text a {
  text-decoration: none;
  color: #000;
  padding: 10px 0;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
}

.question-text a:hover {
  text-decoration: underline;
}
.add-question-filter-wrapper {
  padding: 50px 10px 0px 10px;
  background: rgb(247, 247, 247);
  height: calc(100vh - 65px);
  border-radius: 10px;
  overflow: hidden;
}
.question-list-wrapper {
  padding: 66px 40px;
}
.bottom-save-close-bar {
  position: fixed;
  bottom: 0px;
  background: rgb(255, 255, 255);
  height: 70px;
  width: 100%;
}
.close-save-button-wrapper {
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.list-view-question-wrapper {
  margin-bottom: 20px;
}
.question-list-top-wrapper {
  width: 100%;
  justify-content: space-between;
}
.full-screen-question-dialog-wrapper {
  background: rgb(255, 255, 255);
  margin: auto;
  padding: 10px;
  transform: translateY(30px);
}
</style>
