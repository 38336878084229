<template>
  <v-card
    class="mx-auto"
    width="688"
    height="63"
    outlined
    elevation="0"
    style="margin-top: 22px; border-color: #404040;"
  >
    <v-row>
      <v-col cols="9">
        <p class="text question-set-name">{{ questionSet.testName }}</p>
        <p v-if="questionSet.blueprintName != undefined" class="text blueprint-name">
          Blueprint: {{ questionSet.blueprintName }}
        </p>
      </v-col>
      <v-col
        cols="3"
        style="
          display: flex;
          flex-direction: row;
          margin-top: 3px;
          justify-content: end;
          padding-right: 30px;
        "
      >
        <v-btn
          icon
          class="icon-btn"
          elevation="0"
          style="margin-left: 10px;"
          @click="editQuestionSet"
        >
          <v-img
            :src="require('@/assets/edit_square.png')"
            alt="Icon"
            style="width: 17px; height: 17px;"
          />
          <span class="text btn-text" style="margin-top: 5px;">Edit</span>
        </v-btn>
        <v-btn
          icon
          class="icon-btn"
          elevation="0"
          style="margin-left: 10px;"
          @click="deleteQuestionSetTrigger(questionSet.id)"
        >
          <v-img
            :src="require('@/assets/delete.png')"
            alt="Icon"
            style="width: 17px; height: 17px;"
          />

          <span class="text btn-text" style="color: #c24245; margin-top: 5px;">Delete</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'RelatedQuestionSetTile',
  props: {
    questionSet: {
      type: Object,
      required: true,
    },
    deleteQuestionSetTrigger: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    ...mapActions(['editXLMTest']),
    async editQuestionSet() {
      console.log('Edit');
      console.log(this.questionSet.id);
      await this.editXLMTest({
        id: this.questionSet.id,
      });
    },
  },
};
</script>
<style>
.icon-btn .v-btn__content {
  flex-direction: column;
}
.text {
  font-family: 'Roboto';
  font-style: normal;
}
.btn-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
.question-set-name {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  margin-left: 22px;
  margin-bottom: 0px;
  padding-top: 2px;
}
.blueprint-name {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #686868;
  margin-left: 22px;
  margin-top: -7px;
  padding: 0px;
}
</style>
