import { startCase, camelCase } from 'lodash';

const mutations = {
  SET_QUESTION_DATA(state, data) {
    state.questionData = data.map((e) => {
      e.isSelected = true;
      return e;
    });
  },
  SET_QUESTION_META_INFORMATION(state, data) {
    state.questionMetaInfo = data;
    state.virtualQuestionPurposes = data.questionPurposes.virtualQuestions.map((purpose) => {
      return { label: startCase(camelCase(purpose)), value: purpose };
    });
    state.selectedVirtualQuestionPurposes = state.virtualQuestionPurposes;
  },
  SET_SHOW_GROUP_QUESTION_DIALOG(state, data) {
    state.showGroupQuestionDialog = data;
  },
  SET_SHOW_QUESTION_DIALOG(state, data) {
    state.showQuestionDialog = data;
  },
  SHOW_SOURCE_BLOCK_FILTER_DIALOG(state, data) {
    state.showSourceBlockFilterDialog = data;
  },
  SET_REUSE_QUESTION_LESSON_PLANS(state, data) {
    state.reuseQuestionLessonPlans = data;
  },
  GET_ALL_BLOCKS_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT(state, data) {
    state.reuseQuestionBlocks = data;
  },
  GET_ALL_BLOCK_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT_BLOCK_ID(state, data) {
    const blockIdToBlockBIdMapping = JSON.parse(JSON.stringify(state.blockIdToBlockBIdMapping));
    blockIdToBlockBIdMapping[data.blockId] = data.block.bId;
    state.blockIdToBlockBIdMapping = blockIdToBlockBIdMapping;
  },
  SET_QUESTION_DIALOG_LOADER(state, data) {
    state.questionDialogLoader = data;
  },
  SET_CURRENT_QUESTION_DIALOG_DATA(state, { data }) {
    state.currentQuestionDialogData = data;
  },
  SET_ADD_QUESTION_DIALOG(state, data) {
    state.addQuestionDialog = data;
  },
  RESTORE_LOCAL_STORAGE_DATA(state) {
    const localStorageData = localStorage.getItem('QUESTION_CACHED_DATA');

    if (localStorageData) {
      try {
        const parsedData = JSON.parse(localStorageData);
        const isGroupQuestion = Array.isArray(parsedData);

        state.currentQuestionDialogData = parsedData;

        if (isGroupQuestion) {
          state.showGroupQuestionDialog = true;
        } else {
          state.showQuestionDialog = true;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  SET_COMMENTS_DIALOG(state, data) {
    state.questionCommentsToggle = data;
  },
  SET_GROUP_QUESTION_COMMENTS_DIALOG(state, data) {
    state.groupQuestionCommentsToggle = data;
  },
  SET_QUESTION_CURRENT_STAGE(state, data) {
    state.currentStage = data;
  },
  RESET_QUESTION_DATA(state) {
    state.currentStage = '';
    state.questionData = [];
  },
  SET_QUESTION_SEARCH_FILTER_OPTIONS(state, data) {
    state.questionSearchFilterOptions = data;
  },
  SET_SEARCHED_QUESTION(state, data) {
    state.searchedQuestionsList = data;
  },
  SET_SHOW_QUESTION_SEARCH_LOADER(state, status) {
    state.showQuestionSearchLoader = status;
  },
  SET_QUESTION_DATA_FOR_LIST_LOADING_STATUS(state, status) {
    state.showLoadingForQuestionDataList = status;
  },
  SET_QUESTION_DATA_FOR_LIST(state, data) {
    state.questionDataForList = data;
  },
  SET_REMOVE_QUESTION_LOADING_STATUS(state, status) {
    state.removeQuestionLoader = status;
  },
  SET_UNLINK_AND_DELETE_QUESTION_CONFIRMATION_DIALOG_STATUS(state, data) {
    state.unlinkAndDeleteQuestionConfirmationDialog = data;
  },
  SET_SHOW_WARNING_DIALOG(state, data) {
    state.showWarningDialog = data;
  },
};

export default mutations;
