import { state } from './state';
import mutations from './mutation';
import actions from './action';
import getters from './getters';

export default {
  state,
  mutations,
  actions,
  getters,
};
