import Vue from 'vue';

export const PROJECT_MUTATION = {
  GET_PRODUCTS_BOARDS_STANDARDS_SUBJECTS: (state, data) => {
    state.boards = data.boards;
    state.standards = data.standards;
    state.subjects = data.subjects;
    state.products = data.products;
  },
  GET_PROJECTS_LIST: (state, data) => {
    state.projects = data || [];
  },
  GET_PROJECTS_DATA: (state, data) => {
    state.projects = data.projects || [];
    state.totalCount = data.totalCount;
  },
  SET_INITIAL_PROJECTS_LIST_API_STATUS: (state, status) => {
    state.initialProjectsListApiStatus = status;
  },
  CREATE_PROJECT: (state, data) => {
    let localProjects = state.projects;
    localProjects.push(Object.assign({}, data));
    state.projects = [].concat(localProjects);
  },
  UPDATE_PROJECT: (state, data) => {
    let localProjects = state.projects;
    let projectIds = state.projects.map((i) => {
      return i.id;
    });
    let index = projectIds.indexOf(data.id);
    data.workflowStatus = localProjects[index].workflowStatus;
    localProjects[index] = Object.assign({}, data);

    state.projects = [].concat(localProjects);
  },
  GET_BLOCKS_FOR_SELECTED_PRODUCT_BOARD_STANDARD_SUBJECT: (state, blocks) => {
    state.blocks = blocks;
  },
  SET_PROJECT_DATA: (state, projectDetails) => {
    state.currentProject = projectDetails;
  },
  PUBLISH_PROJECT: (state, { workflowStatus }) => {
    state.currentProject = {
      ...state.currentProject,
      workflowStatus,
    };
  },
  REVERT_PROJECT_STATUS: (state, { status, message }) => {
    state.revertProjectRequestData = {
      status,
      message,
    };
  },
  SET_PROJECT_STATUS_POLLING_STATUS: (state, workflowStatus) => {
    state.currentWorkflowStatus = workflowStatus;
  },
  DELETE_PROJECT_FROM_LIST: (state, projectId) => {
    const indexOfTheProject = state.projects.findIndex((_p) => _p.id == projectId);
    if (indexOfTheProject > 0) {
      Vue.delete(state.projects, indexOfTheProject);
    }
  },
};
