import gql from 'graphql-tag';

export const projectLessonPlanDiffDataQuery = gql`
  query($projectId: ID!, $lessonPlanLibraryId: ID, $fieldIdentifier: String!) {
    projectLessonPlanDiffData(
      projectId: $projectId
      contentLibraryId: $lessonPlanLibraryId
      fieldIdentifier: $fieldIdentifier
    ) {
      authoring1Stage
      review1Stage
      review2Stage
      authoring2Stage
      academicSignOffStage
      editingStage
      visualizingStage
      assetUploadStage
      assetAuthorizingStage
      proofReadingStage
      authoring1StageUpdatedAt
      review1StageUpdatedAt
      review2StageUpdatedAt
      authoring2StageUpdatedAt
      academicSignOffStageUpdatedAt
      editingStageUpdatedAt
      visualizingStageUpdatedAt
      assetUploadStageUpdatedAt
      assetAuthorizingStageUpdatedAt
      proofReadingStageUpdatedAt
    }
  }
`;

export const ProjectLessonPlanSectionCommentQuery = (
  projectId,
  lessonPlanLibraryId,
  sectionIdentifier
) => gql`
query{
  projectLessonPlanSectionComment(
    projectId: "${projectId}",
    contentLibraryId: "${lessonPlanLibraryId}",
    sectionIdentifier: "${sectionIdentifier}"
  ){
    authoring1Stage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    authoring2Stage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    review1Stage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    review2Stage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    editingStage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    visualizingStage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    assetUploadStage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    assetAuthorizingStage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    academicSignOffStage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
    proofReadingStage{
      text
      createdAt
      updatedAt
      resolvedBy{
        name
        email
      }
      resolvedAt
      commentedBy{
        name
        email
      }
    }
  }
}
`;

export const saveProjectLessonPlanSectionCommentQuery = gql`
  mutation($projectId: ID!, $lessonPlanLibraryId: ID, $sectionIdentifier: String!, $text: String!) {
    saveProjectLessonPlanSectionComment(
      projectId: $projectId
      contentLibraryId: $lessonPlanLibraryId
      sectionIdentifier: $sectionIdentifier
      text: $text
    ) {
      projectId
      contentLibraryId
      sectionIdentifier
      stageName
      comments {
        text
        createdAt
        updatedAt
        resolvedBy {
          name
          email
        }
        resolvedAt
        commentedBy {
          name
          email
        }
      }
    }
  }
`;

export const resolveProjectLessonPlanSectionCommentQuery = gql`
  mutation(
    $projectId: ID!
    $lessonPlanLibraryId: ID
    $sectionIdentifier: String!
    $stageName: String!
  ) {
    resolveProjectLessonPlanSectionComment(
      projectId: $projectId
      contentLibraryId: $lessonPlanLibraryId
      sectionIdentifier: $sectionIdentifier
      stageName: $stageName
    ) {
      projectId
      contentLibraryId
      sectionIdentifier
      stageName
      comments {
        text
        createdAt
        updatedAt
        resolvedBy {
          name
          email
        }
        resolvedAt
        commentedBy {
          name
          email
        }
      }
    }
  }
`;
