export const LP_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  WAITING_FOR_REVIEW: 'waiting_for_review',
  COMPLETED: 'completed',
  PUBLISHED: 'published',
};

export const LP_TYPES = {
  standard: 'standard',
  magic: 'magic',
  practice: 'practice',
  classroom: 'classroom',
};

export const STEPS_DEFAULT_TIME = {
  standard: {
    action: '8 mins',
    analysis: '10 mins',
    applicationWorksheet: '10 mins',
    applicationMorePractice: '7 mins',
  },
  magic: {
    action: '15 mins',
    analysis: '10 mins',
    application: '10 mins',
  },
  practice: {
    analysis: '10 mins',
    application: '25 mins',
  },
};

export const AIM_BLOCK_KEY_OF_STATE_LP_DATA = 'aim';
export const ACTION_BLOCK_KEY_OF_STATE_LP_DATA = 'action';
export const ANALYSIS_BLOCK_KEY_OF_STATE_LP_DATA = 'analysis';
export const APPLICATION_BLOCK_KEY_OF_STATE_LP_DATA = 'application';
export const ASSESSMENT_BLOCK_KEY_OF_STATE_LP_DATA = 'assessment';

export const LIMIT = {
  RESOURCES: 7,
  KEYWORDS: 5,
  SUPPORT_TIPS: 10,
};

export const LP_FORM_SUBMIT_TEXT = {
  authoring1Stage: 'Authored LP',
  review1Stage: 'Review 1',
  review2Stage: 'Review 2',
  authoring2Stage: 'Final Draft LP',
  academicSignOffStage: 'AM Reviewed LP',
  editingStage: 'Edited LP',
  visualizingStage: 'visualized LP',
  assetUploadStage: 'Final Assets LP',
  assetAuthorizingStage: 'Final Approved LP',
  proofReadingStage: 'Proofread LP',
  completedStage: 'Complete',
};

export const LP_STAGES = {
  authoring1Stage: 'Authoring 1',
  review1Stage: 'Review 1',
  review2Stage: 'Review 2',
  authoring2Stage: 'Authoring 2',
  academicSignOffStage: 'Academic Manager Review',
  editingStage: 'Editing',
  visualizingStage: 'Visualizing Assets',
  assetUploadStage: 'Uploading Assets',
  assetAuthorizingStage: 'Approving Assets',
  proofReadingStage: 'Proofreading',
  completedStage: 'Completed',
};

export const LP_STAGES_HEADER_TEXT = {
  authoring1Stage: 'Author 1',
  review1Stage: 'Reviewer 1',
  review2Stage: 'Reviewer 2',
  authoring2Stage: 'Author 2',
  academicSignOffStage: 'Academic Manager Review',
  editingStage: 'Editor',
  visualizingStage: 'Visualizer',
  assetUploadStage: 'Asset Uploader',
  assetAuthorizingStage: 'Asset Authorizer',
  proofReadingStage: 'Proof Reader',
  completedStage: 'Completed',
};

export const LP_ASSIGNEES = {
  authoring1Stage: 'author',
  review1Stage: 'reviewer1',
  review2Stage: 'reviewer2',
  authoring2Stage: 'author',
  academicSignOffStage: 'academicSignOff',
  editingStage: 'editor',
  visualizingStage: 'visualizer',
  assetUploadStage: 'assetUploader',
  assetAuthorizingStage: 'assetAuthorizer',
  proofReadingStage: 'proofReader',
  completedStage: '',
};

export const LESSON_PLAN_LOADING_STATES = {
  notStarted: 'not_started',
  inProgress: 'in_progress',
  completed: 'completed',
};

export const COMMENT_SECTION_NAMES_HASH = {
  SUMMARY: 'summary',
  AIM: 'aim',
  ACTION: 'action',
  ACTION_PROGRAM_1: 'actionProgram1',
  ACTION_PROGRAM_2: 'actionProgram2',
  ANALYSIS_PROGRAM_1: 'analysisProgram1',
  ANALYSIS_PROGRAM_2: 'analysisProgram2',
  ANALYSIS: 'analysis',
  APPLICATION: 'application',
  ASSESSMENT: 'assessment',
  INFORMATION_AND_ASSESSMENT: 'informationAndAssessment',
};

export const RIGHT_SIDE_PREVIEW_CONTENT_TYPE = {
  PREVIEW_LP: 'preview_lp',
  COMMENTS: 'comments',
  CONTENT_DIFF: 'content_diff',
};

export const LP_TIME_TYPES = {
  circleTime: 'circleTime',
  scienceTime: 'scienceTime',
  mathTime: 'mathTime',
  storyTime: 'storyTime',
  englishTime: 'englishTime',
  artTime: 'artTime',
  playTime: 'playTime',
  musicAndMovementTime: 'musicAndMovementTime',
  dramaTime: 'dramaTime',
  blockIntroduction: 'blockIntroduction',
};

export const ASSET_AUTHORIZING_STAGE = 'assetAuthorizingStage';

export const DAILY_PLANS_LESSON_PLAN_TIMES_COLLECTION = [
  '5 mins',
  '10 mins',
  '15 mins',
  '20 mins',
  '25 mins',
  '30 mins',
  '35 mins',
  '40 mins',
  '45 mins',
  '50 mins',
];

export const DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION = [
  '5 mins',
  '8 mins',
  '10 mins',
  '12 mins',
  '13 mins',
  '15 mins',
  '20 mins',
  '25 mins',
  '30 mins',
  '35 mins',
  '40 mins',
  '45 mins',
  '50 mins',
];

export const O_LESSON_PLAN_TIMES_COLLECTION = [
  '5 mins',
  '6 mins',
  '7 mins',
  '8 mins',
  '9 mins',
  '10 mins',
];
export const STANDARD_LESSON_PLAN_TIMES_COLLECTION = [
  '5 mins',
  '8 mins',
  '10 mins',
  '15 mins',
  '20 mins',
  '25 mins',
  '30 mins',
  '35 mins',
  '40 mins',
  '45 mins',
  '50 mins',
];
export const ACTIVITY_TYPES = ['individual', 'pair', 'group', 'class'];

export const BLOCKED_BOARDS = ['XSEED One PH'];
export const BLOCKED_PRODUCTS = ['PH'];
