<template>
  <div v-if="isConfidenceUser || isAdmin">
    <v-dialog v-model="showLoader" max-width="400" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          {{ blockList.message }}
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <GradeSubjectDialog
      v-model="showCreateBlockGradeSubject"
      :grades="standards.data"
      :subjects="subjects"
      @confirm="handleConfirm"
      :createOrEditQuestionSetDialogText="createOrEditQuestionSetDialogText"
      :getUsersByRoles="getUsersByRoles"
    />

    <v-dialog v-model="showCreateBlockProject" content-class="learnometer-topic-form-dialog">
      <v-card height="380">
        <v-card-title class="headline grey lighten-2" primary-title>{{
          createOrEditQuestionSetDialogText
        }}</v-card-title>
        <div class="form-content">
          <v-form ref="project_assignee_form" :key="`projectFormPopUp`" class="ma-4">
            <ProjectStageAssigneeFormContent
              :projectData="blockData"
              :getUsersByRoles="getUsersByRoles"
              colsPerRow="3"
            />
          </v-form>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 0;
            margin-top: -60px;
            padding-right: 10px;
          "
        >
          <v-btn text @click="showCreateBlockProject = false">Cancel</v-btn>
          <v-btn color="primary" v-if="this.buttonText == 'CREATE'" @click="validateAssigneeForm"
            >Create</v-btn
          >
          <v-btn
            color="primary"
            v-else-if="this.buttonText == 'UPDATE'"
            @click="validateAssigneeForm"
            >Update</v-btn
          >
          <v-btn
            color="primary"
            v-else-if="this.buttonText == 'REOPEN'"
            @click="validateAssigneeForm"
            >Reopen</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <v-card style="border: none; box-shadow: none; width: 100%; background-color: #fff0f0;">
      <v-simple-table style="background-color: #fff0f0;">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="landing-page">
                <div style="height: 250px; background-color: #fff0f0; margin-top: -25px;">
                  <div class="w-100 p-4 d-flex align-items-center justify-content-center">
                    <v-col>
                      <v-row style="margin-top: 60px;">
                        <td>
                          <v-container class="ml-2">
                            <p style="font-weight: 700; font-size: 20px; margin-left: 60px;">
                              <v-icon left>mdi-format-list-bulleted</v-icon> Confidence Block List
                            </p></v-container
                          >
                        </td>

                        <td
                          style="width: 100%; margin-top: -70px; margin-right: 30px;"
                          align="right"
                        >
                          <div
                            style="width: 250px; margin-top: 10px; height: 40px; margin-left: 50px;"
                          ></div>
                        </td>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 319px;
                            vertical-align: top;
                            justify-content: space-between;
                            align-items: center;
                            margin-left: 75px;
                            padding-bottom: 20px;
                          "
                        >
                          <v-icon small>mdi-school</v-icon> Grade
                        </v-col>

                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 300px;
                            /* line-height: 15px; */
                            vertical-align: top;
                            justify-content: space-between;
                            align-items: center;
                            margin-left: 25px;
                          "
                        >
                          <v-icon small>mdi-book-open-variant</v-icon> Subject
                        </v-col>
                      </v-row>

                      <div style="width: 900px; position: relative;">
                        <v-row>
                          <v-select
                            :items="standards.data"
                            v-model="standardId"
                            item-text="name"
                            item-value="_id"
                            label="Select Grade"
                            style="
                              justify-content: space-between;
                              align-items: center;
                              width: 256px;
                              height: 34px;
                              margin-left: 74px;
                            "
                            outlined
                            dense
                            hide-details
                            on
                            class="filter-select-field"
                          ></v-select>
                          <v-select
                            :disabled="!standardId"
                            :items="subjects"
                            v-model="subjectId"
                            item-text="name"
                            item-value="_id"
                            style="
                              justify-content: space-between;
                              align-items: center;
                              width: 256px;
                              height: 34px;
                              margin-left: 74px;
                            "
                            label="Select Subject"
                            outlined
                            dense
                            hide-details
                            on
                            class="filter-select-field"
                          ></v-select>
                          <v-btn
                            text
                            @click="resetFilter()"
                            style="
                              background: #fff0f0;
                              border: none;
                              box-shadow: none;
                              text-transform: unset !important;
                              font-weight: 600;
                              font-size: 12px;
                              color: black;
                              width: fit-content;
                              text-shadow: none;
                              margin-left: 25px;
                              align-content: center;
                              margin-top: 12px;
                              height: 15px;
                            "
                            >Clear</v-btn
                          >
                          <v-btn
                            text
                            @click="triggerFilter()"
                            style="
                              background: #b81e22;
                              border-radius: 111px;
                              text-transform: unset !important;
                              font-weight: 600;
                              font-size: 12px;
                              color: #ffffff;
                              width: 110px;
                              margin-left: 19px;
                              height: 31px;
                            "
                            ><v-icon left>mdi-magnify</v-icon>Search</v-btn
                          >
                        </v-row>
                      </div>
                    </v-col>
                  </div>
                </div>
              </td>
              <td>
                <v-btn
                  :disabled="!isAdmin"
                  class="right-btn"
                  outlined
                  color="#C10230"
                  style="
                    margin-left: 20px;
                    border: 1.5px solid #b81e22;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                  "
                  @click="() => showCreateBlock()"
                >
                  <v-icon left>mdi-plus-box</v-icon> Add Block
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-container fluid class="landing">
      <v-card style="border: none; box-shadow: none; width: 100%; margin-left: 50px;">
        <v-simple-table class="airTable-btn">
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="airTable-btn">
                  <v-container
                    style="margin-left: -21px; margin-top: 10px; font-weight: 600; font-size: 14px;"
                  >
                    <p style="font-weight: 600; font-size: 16px;">
                      <v-icon left>mdi-format-list-bulleted</v-icon> Block List
                    </p>
                  </v-container>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <v-card class="micro-topic-filter-card">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="bold">Block Sequence</td>
                <td class="bold">Block Name</td>
                <td class="bold">Workflow Status</td>
                <td class="text-center bold">Actions</td>
              </tr>
              <tr v-for="(item, index) in blockList" :key="`airtableProjectList${index}`">
                <td class="body-1 font-weight-medium">
                  {{ item.blockSequenceNumber }}
                </td>
                <td class="body-1 font-weight-medium">
                  G{{ item.standardName }}, {{ item.subjectName }}: {{ item.blockName }}
                </td>
                <td class="py-3">
                  <SubProjectStatus
                    lessonType=""
                    :currentStage="
                      MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING[item.currentStage] || 'NA'
                    "
                    :status="item.workflowStatus || AppTexts.NOT_STARTED"
                  />
                </td>
                <td>
                  <div
                    v-if="item.isProjectExist"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      margin-left: auto;
                      justify-content: end;
                    "
                  >
                    <v-btn
                      v-if="canShowReopenButton(item)"
                      width="110"
                      outlined
                      color="#C10230"
                      style="
                        margin-right: 20px;
                        border: 1.5px solid #b81e22;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                      "
                      @click="() => showCreateProjectDialog(item)"
                    >
                      Reopen
                    </v-btn>
                    <v-btn
                      v-if="canShowEditButton(item)"
                      outlined
                      color="#C10230"
                      style="
                        margin-right: 20px;
                        border: 1.5px solid #b81e22;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                      "
                      :disabled="!isAdmin"
                      @click="() => showEditProjectDialog(item)"
                    >
                      Manage Assignees
                    </v-btn>
                    <v-btn
                      style="
                        margin-right: 20px;
                        text-transform: unset !important;
                        width: 110px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                      "
                      class="xs-accent-button action-button create-micro-topic-btn"
                      @click="gotoViewQuestionListPage(item.projectId)"
                    >
                      View
                    </v-btn>
                  </div>

                  <v-btn
                    :disabled="!isAdmin"
                    class="right-btn"
                    outlined
                    color="#C10230"
                    style="
                      margin-left: 20px;
                      border: 1.5px solid #b81e22;
                      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    "
                    v-else
                    @click="() => showCreateProjectDialog(item)"
                  >
                    Manage Assignees
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <v-card-text class="text-center" v-if="blockList && !blockList.length">
              Please select Grade & Section</v-card-text
            >
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </div>
  <UnAuthorizedCard v-else />
</template>
<script>
import SubProjectStatus from '@/components/contentEntry/microTopic/SubProjectStatus';
import ProjectStageAssigneeFormContent from '@/components/contentEntry/ProjectStageAssigneeFormContent';
import AppTexts from '@/constants/AppTexts';
import { mapActions, mapState } from 'vuex';
import { PROJECT_WORKFLOW_STATUSES_HASH } from '@/constants/Project';
import { COMPLETED_STAGE } from '@/constants/microTopic';
import { MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING } from '../../constants/microTopic';
import GradeSubjectDialog from '../../components/contentEntry/confidenceComponents/GradeSubjectDialog.vue';
import UnAuthorizedCard from './UnAuthorizedCard.vue';
import { SKIP_STAGE_USER_ID_VALUE } from '@/constants/microTopic';

export default {
  name: 'Confidence',
  components: {
    SubProjectStatus,
    ProjectStageAssigneeFormContent,
    GradeSubjectDialog,
    UnAuthorizedCard,
  },
  watch: {
    isAdmin: {
      immediate: true,
      handler(val) {
        this.canShowAirtableSync = val;
      },
    },
  },
  created() {
    console.log('isAdmin: ' + this.isAdmin);
    console.log('isAcademicAdmin: ' + this.isAcademicAdmin);
    console.log('isConfidenceUser: ' + this.isConfidenceUser);
    this.checkIsConfidenceUser();
    this.getUsers();
    this.fetchConfidenceStandardsSubjects();
  },
  data() {
    return {
      MICRO_TOPIC_PROJECT_STAGE_SHORT_NAME_MAPPING,
      AppTexts,
      COMPLETED_STAGE,
      buttonText: 'CREATE',
      subjectId: null,
      standardId: null,
      showCreateBlockProject: false,
      showCreateBlockGradeSubject: false,
      mandatoryStages: ['Asset Uploader', 'Asset Authorizer', 'Proof Reader'],
      SKIP_STAGE_USER_ID_VALUE,
      blockData: {
        title: '',
        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      standards: (state) => state.confidence.fetchStandardsSubjectsApiResponse.data,
      blockList: (state) => state.confidence.fetchConfidenceBlocksApiResponse.data,
      isConfidenceUser: (state) => state.confidence.isConfidenceUser,
      users: (state) => state.user.users,
      isAdmin: (state) => state.auth.isAdmin,
      isAcademicAdmin: (state) => state.auth.isAcademicAdmin,
    }),
    subjects() {
      if (!this.standards.data) return [];
      let subbbs =
        (this.standards.data.find((value) => value._id == this.standardId) || {}).subjects || [];
      console.log(subbbs);
      return subbbs;
    },
    showLoader() {
      return this.blockList.status == 'in_progress';
    },
    createOrEditQuestionSetDialogText() {
      return this.buttonText == 'UPDATE'
        ? 'Manage Assignees'
        : this.buttonText == 'REOPEN'
        ? 'Reopen Block'
        : 'Create Block';
    },
  },
  methods: {
    ...mapActions([
      'fetchConfidenceStandardsSubjects',
      'fetchConfidenceBlocks',
      'getUsers',
      'getCurrentUserAuthPermissions',
      'updateAssignees',
      'createConfidenceProject',
      'checkIsConfidenceUser',
    ]),
    gotoViewQuestionListPage(id) {
      this.$router.push({
        name: 'QuestionList',
        params: { id },
      });
    },
    getUsersByRoles(role) {
      let sortListedUsers = [];
      if (!this.mandatoryStages.includes(role)) {
        sortListedUsers.push({
          id: this.SKIP_STAGE_USER_ID_VALUE,
          name: 'Skip(Not assign Users)',
        });
      }

      for (let user of this.users) {
        let rolesList = user.roles.map((role) => role.name);
        if (rolesList.includes(role) && user.user && user.user._id && user.user.name) {
          sortListedUsers.push({
            id: user.user._id,
            name: user.user.name,
          });
        }
      }

      return sortListedUsers;
    },
    validateAssigneeForm() {
      if (this.$refs.project_assignee_form.validate()) {
        if (this.isEditPage) {
          const payload = {
            projectId: this.blockData.projectId,
            assignees: this.blockData.assignees,
            standardId: this.standardId,
            subjectId: this.subjectId,
          };
          this.updateAssignees(payload);
          this.showCreateBlockProject = false;
          this.isEditPage = false;
        } else {
          const payload = {
            name: `Grade ${this.blockData.standardName} - ${this.blockData.blockName} : ${this.blockData.subjectName} Confidence`,
            subjectId: this.blockData.subjectId,
            standardId: this.blockData.standardId,
            entityId: this.blockData.entityId,
            assignees: this.blockData.assignees,
          };
          this.createConfidenceProject(payload);
          this.showCreateBlockProject = false;
        }
      }
    },
    resetFilter() {
      this.subjectId = '';
      this.standardId = '';
      this.triggerFilter();
    },
    showCreateProjectDialog(row) {
      this.resetProjectData();
      this.isEditPage = false;
      this.buttonText = 'CREATE';
      this.blockData.projectId = row.projectId;
      for (const assignee in row.assignees) {
        this.blockData.assignees[assignee] = { id: '' };
        this.blockData.assignees[assignee].id = row.assignees[assignee] || '';
      }
      this.blockData.blockName = row.blockName || '';
      this.blockData.subjectId = row.subjectId || '';
      this.blockData.subjectName = row.subjectName || '';
      this.blockData.standardId = row.standardId || '';
      this.blockData.standardName = row.standardName || '';
      this.blockData.entityId = row.blockId || '';
      this.showCreateBlockProject = true;
      this.selectedSubjectGrade = row;
    },
    createProject() {},
    resetProjectData() {
      this.blockData = {
        _id: '',
        title: '',
        description: '',
        assignees: {
          author: {
            id: '',
          },
          reviewer1: {
            id: '',
          },
          reviewer2: {
            id: '',
          },
          academicSignOff: {
            id: '',
          },
          editor: {
            id: '',
          },
          visualizer: {
            id: '',
          },
          assetUploader: {
            id: '',
          },
          assetAuthorizer: {
            id: '',
          },
          proofReader: {
            id: '',
          },
        },
        startDate: '',
        endDate: '',
        workflowStatus: '',
        currentStage: '',
        projectStage: '',
        confidenceStandardId: '',
        subjectId: '',
        grade: '',
        isLocked: false,
        isArchived: false,
      };
    },
    triggerFilter() {
      this.fetchConfidenceBlocks({
        standardId: this.standardId,
        subjectId: this.subjectId,
      });
    },
    canShowEditButton(row) {
      return (
        row.workflowStatus &&
        row.workflowStatus.toLowerCase() == PROJECT_WORKFLOW_STATUSES_HASH.IN_PROGRESS &&
        row.currentStage != COMPLETED_STAGE &&
        (this.isAdmin || this.isAcademicAdmin)
      );
    },
    canShowReopenButton(row) {
      return (
        row.workflowStatus &&
        row.workflowStatus.toLowerCase() == PROJECT_WORKFLOW_STATUSES_HASH.COMPLETED &&
        row.currentStage == COMPLETED_STAGE &&
        (this.isAdmin || this.isAcademicAdmin)
      );
    },
    showEditProjectDialog(row) {
      if (this.isAdmin) {
        this.resetProjectData();
        this.buttonText = 'UPDATE';
        this.blockData.projectId = row.projectId;
        for (const assignee in row.assignees) {
          this.blockData.assignees[assignee] = { id: '' };
          this.blockData.assignees[assignee].id = row.assignees[assignee] || '';
        }
        this.showCreateBlockProject = true;
        this.isEditPage = true;
      }
    },
    showCreateBlock() {
      this.showCreateBlockGradeSubject = true;
      console.log(this.showCreateBlockGradeSubject);
    },
    handleConfirm(selection) {
      console.log('Selected Grade:', selection.grade);
      console.log('Selected Subject:', selection.subject);
    },
  },
  mounted() {
    this.getCurrentUserAuthPermissions();
  },
};
</script>
<style>
.filter-select-field,
.filter-select-field label {
  font-size: 14px;
  font-weight: 500;
  color: black;
}
.filter-select-field .v-label:not(.v-label--active) {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-5px);
}
.micro-topic-filter-card {
  padding: 20px;
  margin-left: 50px;
}
.landing {
  padding: 30px;
}
.learnometer-topic-form-dialog {
  transform: translateY(30px);
  max-height: 88% !important;
}

.landing-page:hover {
  background-color: #fff0f0;
  box-shadow: none;
}
.airTable-btn:hover {
  background-color: white;
  box-shadow: none;
}
.right-btn {
  position: absolute;
  right: 0;
  margin-right: 45px;
}
</style>
