<template>
  <v-col :cols="12">
    <h3>COVER IMAGE</h3>
    <br />
    <TextInput
      v-model="data.imageTitle"
      placeholder="Image Title"
      :canShowDiffIcon="false"
      :readonly="!isNotReadOnly"
      :counter="50"
    />
    <FileInput
      :canShowDiffIcon="canShowDiffIcon"
      :fileAcceptType="fileAcceptedTypes"
      :label="coverImagePlaceholder"
      :isRequired="true"
      :fileChangeHandler="
        (file) =>
          fileUploadHandler(
            file,
            'coverImage',
            isAssetUploadRequired ? data.imageUrl : data.briefUrl
          )
      "
      :previewUrl="previewUrl"
      :previewUrlBrief="previewUrlBrief"
      :image_url="data.imageUrl"
      :image_url_brief="data.briefUrl"
      :isNotReadOnly="isNotReadOnly"
      :loadingStatus="loadingStatus == 'in_progress'"
      :clearable="true"
      :isAssetUploadRequired="isAssetUploadRequired"
      @addBriefToAsset="
        (previewUrl, briefUrl, approveAsset) =>
          addToAsset(previewUrl, briefUrl, approveAsset, 'coverImage', -1)
      "
      :diffIdentifier="isAssetUploadRequired ? 'imageUrl' : 'briefUrl'"
      :canAppproveAsset="canAppproveAsset"
      :isAssetApproved="data.imageUrlApproved"
      :canFinalizeAsset="true"
    /><br />
    <RichTextEditor
      :readonly="!isNotReadOnly || isAssetUploadRequired"
      :canShowDiffIcon="canShowDiffIcon"
      fieldIdentifier="brief"
      :placeholder="'Brief'"
      v-model="data.brief"
      :key="`oLessonBrief`"
      :counter="3000"
      :allowWordCount="false"
      :allowCharacterCount="true"
      :includeOnlyToolBarActions="allowedToolBarActionWithTablesForRTE"
      :showValidationError="isValidationRequired"
    />
  </v-col>
</template>

<script>
import TextInput from '@/components/inputs/TextInput';
import FileInput from '@/components/inputs/FileInput';
import RichTextEditor from '@/components/RTE/RichTextEditor';
import Errors from '@/constants/Errors';

export default {
  name: 'CoverImage',
  data() {
    return {
      Errors,
    };
  },
  components: {
    TextInput,
    FileInput,
    RichTextEditor,
  },
  props: {
    coverImagePlaceholder: { type: String, required: true },
    previewUrl: { type: String, required: false, default: '' },
    previewUrlBrief: { type: String, required: false, default: '' },
    fileChangeHandler: { type: Function, required: true },
    canShowDiffIcon: { type: Boolean, required: true },
    showImageBrief: { type: Boolean, required: true },
    isNotReadOnly: { type: Boolean, required: false, default: true },
    loadingStatus: { type: String, required: false, default: '' },
    data: { type: Object, required: true },
    isAssetUploadRequired: { type: Boolean, required: true },
    canAppproveAsset: { type: Boolean, default: false },
    validationFlag: { type: Boolean, default: false },
    fileAcceptedTypes: {
      type: String,
      required: true,
    },
    addToAsset: { type: Function, required: true },
    allowedToolBarActionWithTablesForRTE: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isValidationRequired() {
      return !this.isAssetUploadRequired && this.validationFlag;
    },
  },
  methods: {
    fileUploadHandler(file, type, path) {
      if (file == null) {
        this.fileChangeHandler(file, type, path);
        return true;
      }
      let pattern = this.fileAcceptedTypes;
      let errorText = '';
      if (this.isAssetUploadRequired) {
        pattern = 'image/*';
        errorText = ' please upload an image file';
      } else {
        errorText = ' please upload an image or document file';
      }
      if (!(file.type.startsWith('image') || pattern.includes(file.name.split('.').pop()))) {
        this.$notify({
          title: 'Error!',
          text: `File format not supported, ${errorText}`,
          type: 'error',
          clean: false,
          duration: -1,
        });
      } else {
        this.fileChangeHandler(file, type, path);
      }
    },
  },
};
</script>
