<template>
  <div>
    <v-app-bar
      height="58px;"
      dense
      absolute
      color="white"
      elevation="2"
      scroll-target="#scrolling-techniques-7"
      class="action-bar"
    >
      <v-row>
        <v-col>
          <span class="lp-detailes-header" v-html="breadCrumbText"></span>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <span
        v-if="!(auth.canSaveContent || auth.canSaveProjectLP)"
        class="read-only-mode-message mgn-Rs"
      >
        <img height="18" width="18" src="@/assets/alert.png" />
        <div style="float: right; margin-left: 10px;">
          This page cannot be edited, its in read only mode
        </div>
      </span>
      <div v-if="!(auth.canSaveContent || auth.canSaveProjectLP)" id="read-only-mode-banner">
        <img
          src="@/assets/readonly.png"
          :class="showRightSidePanel ? 'half-width-view' : 'full-width-view'"
        />
      </div>

      <v-btn
        class="ml-5 download-assets-button"
        v-show="
          auth.canDownloadContentAssets &&
          currentStage == 'assetUploadStage' &&
          showAssetDownloadButton
        "
        depressed
        color="blue"
        medium
        :disabled="!buttonActions.downloadContentAssets"
        @click="buttonActions.downloadContentAssets() || null"
        >Assets</v-btn
      >
      <v-btn
        v-if="isAdmin"
        class="ml-5"
        :class="!isAdmin ? 'disable-btn-click-event' : ''"
        depressed
        color="primary save-button"
        :disabled="!buttonActions.activateContent || auth.canSaveProjectLP"
        @click="buttonActions.activateContent() || null"
        medium
        >ACTIVATE</v-btn
      >
      <v-btn
        class="ml-5"
        :class="
          !(auth.canValidateContent || auth.canSaveProjectLP) ? 'disable-btn-click-event' : ''
        "
        depressed
        color="primary save-button"
        :disabled="!buttonActions.validateContent"
        @click="buttonActions.validateContent() || null"
        medium
        >VALIDATE</v-btn
      >
      <v-btn
        :class="!(auth.canSaveContent || auth.canSaveProjectLP) ? 'disable-btn-click-event' : ''"
        class="ml-5 draft-save-button"
        depressed
        color="blue"
        medium
        :disabled="!buttonActions.saveForm"
        @click="buttonActions.saveForm(false) || null"
        >{{ AppTexts.SAVE_AS_DRAFT }}</v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
import AppTexts from '@/constants/AppTexts';

export default {
  name: 'FormActionBar',
  data() {
    return {
      AppTexts,
    };
  },
  props: {
    breadCrumbText: {
      type: String,
      required: true,
    },
    buttonActions: {
      type: Object,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
    showRightSidePanel: {
      type: Boolean,
      required: true,
    },
    currentStage: {
      type: String,
      required: true,
    },
    showAssetDownloadButton: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.lp-detailes-header {
  font-size: 14px;
  color: #242424;
}
.action-bar {
  z-index: 100;
  height: 58px;
  position: fixed;
  top: 64px;
  transform: translateY(0px);
  left: 0px;
  right: 0px;
}
.read-only-mode-message {
  background-color: #f5f5f5;
  padding: 5px 10px 3px 10px;
  opacity: 0.8;
  font-size: 12px;
}
#read-only-mode-banner {
  position: fixed;
}
#read-only-mode-banner img {
  opacity: 0.1;
  position: fixed;
}
#read-only-mode-banner img.full-width-view {
  transform: rotate(-20deg);
  top: 40vh;
  left: -5px;
  width: 96vw;
}
#read-only-mode-banner img.half-width-view {
  transform: rotate(-30deg);
  top: 45vh;
  left: -45px;
  width: 71vw;
}
.toggle-preview-button {
  position: fixed;
  top: 120px;
  z-index: 1;
}
.draft-save-button {
  color: #0da27d;
  background: #fff !important;
  border: 1px solid #0da27d !important;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
}
.save-button {
  border-radius: 2px;
  width: 150px;
  font-size: 18px;
  font-weight: 500;
}
.download-assets-button {
  color: #fff;
  background: #f08d4a !important;
  border: 1px solid #f08d4a !important;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
}
</style>
