<template>
  <v-form ref="keywordForm">
    <v-row v-for="(item, index) in items" :key="index" :v-bind="index">
      <v-col class="pad-Rm">
        <TextInput
          key="keyword name"
          name="keyword name"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isKeywordChanged(index)"
          :fieldIdentifier="`${diffIdentifierPrefix}[${index}].keyword`"
          :placeholder="placeholder"
          v-model="item.keyword"
          :rules="[(v) => isKeywordNameOptional || !!v || Errors.REQUIRED_FIELD]"
          customClass=""
          :readonly="!isNotReadOnly"
          :counter="maxNameLength"
        />
      </v-col>
      <v-col v-show="showKeywordDescriptions" class="pad-Lm" cols="5">
        <RichTextEditor
          key="keyword description"
          name="keyword description"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDescriptionChanged(index)"
          :fieldIdentifier="`${diffIdentifierPrefix}[${index}].description`"
          :showValidationError="isKeywordDefinitionOptional != true"
          :placeholder="descriptionPlaceholder"
          v-model="item.description"
          :readonly="!isNotReadOnly"
          :counter="maxDescriptionLength"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col cols="1">
        <v-btn icon @click="deleteKeyword(index)" v-if="isNotReadOnly">
          <img height="16" width="16" src="@/assets/close.svg" />
        </v-btn>
      </v-col>
    </v-row>

    <v-btn
      class="xs-accent-button mgn-Tm"
      @click="() => addKeyword()"
      :class="!canAddKeyword() ? 'disable-btn-click-event' : ''"
      >{{ buttonText }}</v-btn
    >
    <span class="mgn-Lm remaining-step-warning-text" v-if="maxKeywordLength && canAddKeyword()">
      {{ getStepAdditionValidationText(maxKeywordLength - items.length, 'keyword') }}
    </span>
  </v-form>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import TextInput from '@/components/inputs/TextInput';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import { getStepAdditionValidationText } from '@/utils';

export default {
  name: 'keywords',
  components: {
    TextInput,
    RichTextEditor,
  },
  data() {
    return {
      AppTexts,
      Errors,
      BASIC_TOOLS,
    };
  },
  methods: {
    getStepAdditionValidationText,
    canAddKeyword() {
      return (
        isNaN(this.maxKeywordLength) ||
        (this.items.length < this.maxKeywordLength && this.isNotReadOnly)
      );
    },
    isKeywordChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.keywordsDiff[index].isChanged === true ||
            this.keywordsDiff[index].keyword.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
    isDescriptionChanged(index) {
      try {
        if (this.canShowDiffIcon) {
          return (
            this.keywordsDiff[index].isChanged === true ||
            this.keywordsDiff[index].description.isChanged === true
          );
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    descriptionPlaceholder: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    addKeyword: {
      type: Function,
      required: true,
    },
    deleteKeyword: {
      type: Function,
      required: true,
    },
    keywordsDiff: {
      type: Array,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxKeywordLength: {
      type: Number,
      default: 5,
    },
    maxNameLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    maxDescriptionLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    showKeywordDescriptions: {
      type: Boolean,
      default: true,
    },
    isKeywordNameOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    isKeywordDefinitionOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonText: {
      type: String,
      default: AppTexts.ADD_KEYWORD,
    },
    diffIdentifierPrefix: {
      type: String,
      default: 'keywords',
    },
  },
};
</script>
