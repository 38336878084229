const state = {
  projectDailyPlanCurrentStageData: {},
  coverImageUploadingStatus: 'not_started',
  projectBasicDetails: {},
  dailyPlanDataFetchApiStatus: 'not_started',
  dailyPlanLoadingStatus: {
    message: '',
    status: 'not_started'
  }
};

module.exports = {
  state
};
