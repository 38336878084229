<template>
  <div class="move-to-next-stage-wrapper">
    <center>
      <v-btn
        class="ml-5 move-to-next-stage-button"
        :class="canMoveProjectLP ? 'disable-btn-click-event' : ''"
        @click="stageConfirmationDialog = true"
        depressed
        medium
        >{{ buttonText }}</v-btn
      >
      <v-dialog v-model="stageConfirmationDialog" max-width="600">
        <v-card class="move-to-next-stage-card">
          <v-card-title class="card-title"></v-card-title>
          <v-card-subtitle class="card-sub-title">Do you want to {{ buttonText }}?</v-card-subtitle>
          <v-card-actions class="action-buttons-row">
            <v-spacer></v-spacer>
            <v-btn class="cancel-move-stage" text @click="stageConfirmationDialog = false"
              >Cancel</v-btn
            >
            <v-btn class="confirm-move-stage" text @click="submitForNextStage">Yes, Move</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </center>
  </div>
</template>
<script>
export default {
  name: 'MoveToNextStageButton',
  data() {
    return {
      stageConfirmationDialog: false
    };
  },
  methods: {
    submitForNextStage() {
      this.stageConfirmationDialog = false;
      this.$emit('onClickMoveToNextStage');
    }
  },

  props: {
    canMoveProjectLP: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped>
.move-to-next-stage-wrapper {
  height: 300px;
  text-align: center;
  padding: 50px;
}
.move-to-next-stage-button {
  background-color: #38b274 !important;
  border-radius: 5px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 11px 31px !important;
  height: 50px !important;
  letter-spacing: 0px;
}
.cancel-move-stage {
  height: 40px;
  width: 140px;
  border: 1px solid #979797;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  color: #242424 !important;
}
.confirm-move-stage {
  height: 40px;
  width: 140px;
  border: 1px solid #979797;
  background-color: #177eee;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  color: #fff !important;
}
.card-sub-title {
  font-size: 18px !important;
  color: #242424;
  padding: 17px !important;
  margin-top: 0px;
}
.card-title {
  height: 30px;
  background-color: #bb161b;
}
.action-buttons-row {
  padding: 0px 15px 15px 0px;
}
</style>
