<template>
  <v-form v-if="lessonPlanData.aim" ref="aimSectionForm" class="section-title-cnr">
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isAimDescriptionChanged"
      fieldIdentifier="aim.description"
      :placeholder="AppTexts.AIM_DESCRIPTION"
      v-model="lessonPlanData.aim.description"
      :showValidationError="true"
      :readonly="!isNotReadOnly"
      :counter="schema.descriptionMaxLength"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <BlockTitle :title="schema.label.keywordsTitle" />
    <div>{{ schema.label.keywordsSubTitle }}</div>
    <Keywords
      class="mgn-Bm"
      :items="lessonPlanData.keywords"
      :addKeyword="() => addKeyword(schema.maxKeywordsLength)"
      :deleteKeyword="deleteKeyword"
      :placeholder="AppTexts.KEYWORD"
      :descriptionPlaceholder="AppTexts.KEYWORD_DESCRIPTION"
      :canShowDiffIcon="canShowDiffIcon"
      :keywordsDiff="keywordsDiff"
      :isNotReadOnly="isNotReadOnly"
      :maxNameLength="schema.keywordTextMaxLength"
      :maxDescriptionLength="schema.keywordDescriptionMaxLength"
      :maxKeywordLength="schema.maxKeywordsLength"
      :showKeywordDescriptions="schema.showKeywordDescriptions"
      :isKeywordNameOptional="isKeywordNameOptional"
      :isKeywordDefinitionOptional="isKeywordDefinitionOptional"
    />
    <BlockTitle :title="schema.label.resourcesTitle" />
    <Resources
      class="mgn-Bs"
      :items="lessonPlanData.aim.resources"
      :addResource="addResource"
      :deleteResource="deleteResource"
      withAdd
      :placeholder="AppTexts.RESOURCE_NAME"
      :isRequired="false"
      :label="AppTexts.SELECT_RESOURCE"
      :descriptionPlaceholder="AppTexts.RESOURCE_DESCRIPTION"
      :error="resourcesError"
      :showImageBrief="showImageBrief"
      :isAssetUploadRequired="isAssetUploadRequired"
      :acceptableFileType="acceptableFileType"
      :resourcesSignedUrls="aimSignedUrls.resources"
      :resourcesDiff="aimDiff.resources || []"
      :canShowDiffIcon="canShowDiffIcon"
      :isNotReadOnly="isNotReadOnly"
      :fileValidators="fileValidators"
      :canFinalizeAsset="true"
      :canAppproveAsset="canAppproveAsset"
      :resourseMaxLength="schema.resourceMaxLength"
      :maxNameLength="schema.resourceNameMaxLength"
    />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isAimDescriptionChanged"
      fieldIdentifier="aim.recap"
      :placeholder="AppTexts.AIM_RECAP"
      v-model="lessonPlanData.aim.recap"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <br />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isAimDescriptionChanged"
      fieldIdentifier="aim.the_hard_part"
      :placeholder="AppTexts.AIM_HARD_PART"
      v-model="lessonPlanData.aim.the_hard_part"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <br />
    <RichTextEditor
      :canShowDiffIcon="canShowDiffIcon"
      :isDiffChanged="isAimDescriptionChanged"
      fieldIdentifier="aim.focus"
      :placeholder="AppTexts.AIM_FOCUS"
      v-model="lessonPlanData.aim.focus"
      :readonly="!isNotReadOnly"
      :includeOnlyToolBarActions="BASIC_TOOLS"
    />
    <br />
    <div />
  </v-form>
</template>

<script>
import Keywords from '@/components/inputs/Keywords';
import Resources from '@/components/inputs/Resources';
import BlockTitle from '@/components/inputs/BlockTitle';
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import { AIM_BLOCK_KEY_OF_STATE_LP_DATA } from '@/constants/LessonPlan';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';

export default {
  name: 'AimSectionForDiscussionLpType',
  components: {
    RichTextEditor,
    Keywords,
    Resources,
    BlockTitle,
  },
  data() {
    return {
      AppTexts,
      Errors,
      AIM_BLOCK_KEY_OF_STATE_LP_DATA,
      BASIC_TOOLS,
    };
  },
  props: {
    lessonPlanData: {
      type: Object,
      required: true,
    },
    addResource: {
      type: Function,
      required: true,
    },
    deleteResource: {
      type: Function,
      required: true,
    },
    addKeyword: {
      type: Function,
      required: true,
    },
    deleteKeyword: {
      type: Function,
      required: true,
    },
    resourcesError: {
      type: String,
      required: true,
    },
    showImageBrief: {
      type: Boolean,
      required: true,
    },
    isAssetUploadRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    aimSignedUrls: {
      type: Object,
      required: false,
    },
    aimDiff: {
      type: Object,
      required: true,
    },
    keywordsDiff: {
      type: Array,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptableFileType: {
      type: String,
      required: false,
      default: null,
    },
    fileValidators: {
      type: Array,
      required: false,
      default: () => [],
    },
    schema: {
      type: Object,
      required: true,
    },
    isKeywordNameOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    isKeywordDefinitionOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    canAppproveAsset: { type: Boolean, required: true },
  },
  computed: {
    isAimDescriptionChanged() {
      try {
        if (this.canShowDiffIcon) {
          return this.aimDiff.description.isChanged === true;
        }
      } catch (error) {
        return false;
      }

      return false;
    },
  },
};
</script>
