var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('notifications',{staticStyle:{"margin-bottom":"15px"},attrs:{"position":"bottom right"}}),_c('v-app-bar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("XSEED")])],1),_c('v-app-bar',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-widgets")])],1),_c('v-toolbar-title',[_vm._v("Upload your work to XSEED")])],1),_c('v-form',[(!!_vm.assetZipUploadBasicProjectInfo.name)?_c('v-row',{staticStyle:{"padding":"0px 40px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":12}},[_c('div',[_c('div',{staticClass:"topic-css"},[_c('div',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.assetZipUploadBasicProjectInfo.name + ' - ' + _vm.getProjectType))])])]),_c('div',[_c('p',{staticStyle:{"font-size":"0.8em","font-weight":"normal"}},[_vm._v(" Grade "+_vm._s(_vm.assetZipUploadBasicProjectInfo.standard)+" - "+_vm._s(_vm.assetZipUploadBasicProjectInfo.subject)+" ")])])])])])],1):_vm._e(),(
        _vm.validatedAssetZipUploaderState == 'success' &&
        !_vm.assetZipUploadBasicProjectInfo.sourceFilesUploaded
      )?_c('v-row',{staticStyle:{"padding":"0px 40px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-app-bar',{attrs:{"color":!_vm.assetZipUploadBasicProjectInfo.finalAssetsUploaded ? 'primary' : 'grey',"dense":""}},[_c('v-toolbar-title',{staticStyle:{"color":"white"}},[_vm._v("Final Assets")])],1),_c('div',{staticClass:"pa-6"},[_c('p',[_vm._v("Upload the final asset zip file here")]),_c('AssetVaultUpload',{key:_vm.fieldIdentifier,attrs:{"preset":_vm.UPLOAD_FINAL_ASSETS_BY_VENDOR_PRESET,"appName":_vm.CA_APP_NAME,"assetResponseHook":function (info) {
                  _vm.getAssetResponse(info, 'assetFile');
                },"acceptedFileTypes":[
                'zip',
                'application/octet-stream',
                'application/zip',
                'application/x-zip',
                'application/x-zip-compressed' ],"placeholder":"Upload final asset zip file","prependIcon":"mdi-upload","disabled":_vm.assetZipUploadBasicProjectInfo.finalAssetsUploaded,"clearFieldOnSuccess":false,"loading":_vm.finalAssetLoading,"acceptableFileSizeInMB":_vm.assetZipUploadBasicProjectInfo.acceptableFinalAssetSizeInMB}}),(_vm.assetZipUploadBasicProjectInfo.finalAssetsUploaded)?_c('div',{staticClass:"mt-2",staticStyle:{"color":"#3ab54a"}},[_c('img',{staticStyle:{"position":"relative","top":"7px"},attrs:{"src":require("@/assets/greentick.svg"),"height":"30","width":"30"}}),_vm._v(" The file is uploaded successfully ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-app-bar',{attrs:{"color":_vm.assetZipUploadBasicProjectInfo.finalAssetsUploaded ? 'primary' : 'grey',"dense":""}},[_c('v-toolbar-title',{staticStyle:{"color":"white"}},[_vm._v("Source Files")])],1),_c('div',{staticClass:"pa-6"},[_c('p',[_vm._v("Upload the source files zip here")]),_c('AssetVaultUpload',{key:_vm.fieldIdentifier,attrs:{"preset":_vm.UPLOAD_SOURCE_ASSETS_BY_VENDOR_PRESET,"appName":_vm.CA_APP_NAME,"assetResponseHook":function (info) {
                  _vm.getAssetResponse(info, 'sourceFile');
                },"acceptedFileTypes":[
                'zip',
                'application/octet-stream',
                'application/zip',
                'application/x-zip',
                'application/x-zip-compressed' ],"placeholder":"Upload source asset zip file","prependIcon":"mdi-upload","outlined":"","disabled":!_vm.assetZipUploadBasicProjectInfo.finalAssetsUploaded,"clearFieldOnSuccess":false,"loading":_vm.sourceAssetLoading}})],1)],1)],1)],1):(_vm.canUploadAsset)?_c('v-row',[_c('v-col',{staticClass:"pt-6",attrs:{"align":"center"}},[_c('img',{staticStyle:{"position":"relative","top":"7px"},attrs:{"src":require("@/assets/greentick.svg"),"height":"50","width":"50"}}),_c('br'),_c('br'),_c('p',[_vm._v("We have received your files successfully. Thank you!")])])],1):_c('v-row',[_c('v-col',{staticClass:"pt-6",attrs:{"align":"center"}},[_c('v-progress-circular',{attrs:{"width":3,"color":"primary","indeterminate":""}}),_c('p',[_vm._v("Loading Please wait...")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }