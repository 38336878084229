import Vue from 'vue';
import { setCookie, getCookie, reLogin } from '@/utils/auth';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import VueApollo from 'vue-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { APP_COOKIE_NAME } from '@/constants/common';

Vue.use(VueApollo);

const cache = new InMemoryCache({
  addTypename: false
});

let authToken = getCookie(APP_COOKIE_NAME);

if (!authToken) {
  const urlParams = new URLSearchParams(window.location.search);
  authToken = urlParams.get('token');

  if (!authToken) reLogin();
  setCookie({ name: APP_COOKIE_NAME, value: authToken });
}

let headers = {
  Authorization: `Bearer ${authToken}`
};

const link = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      Vue.notify({
        title: 'Error!',
        text: message,
        type: 'error',
        clean: false,
        duration: -1
      });
    });
  }

  if (networkError && networkError.statusCode == 401) reLogin();
});

export const apolloClient = new ApolloClient({
  link: link.concat(
    createUploadLink({
      uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
      headers: headers
    })
  ),
  cache
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

export default apolloProvider;
