import gql from 'graphql-tag';

export const fetchConfidenceAllGradeSubjects = gql`
  query {
    fetchAllConfidencdGradeSubjects {
      data {
        _id
        name
        subjects {
          _id
          name
        }
      }
    }
  }
`;
export const getConfidenceQuestions = (projectId) => {
  return gql`
    query {
    getConfidenceQuestions(projectId: "${projectId}") {
      data {
        _id
        questions {
          _id
          questionId
          stimulus
          questionGroupId
          questionSequenceInGroup
          isActive
          academicYear
          questionNumber {
            start
            end
          }
          metadata {
            aim {
              _id
              text
            }
            cognitiveType {
              _id
              name
              shortCode
            }
            pisaStandard {
              _id
              type
              title
              subject {
                _id
                name
                shortCode
              }
              grade {
                _id
                name
                shortCode
              }
              code
              description
            }
            ncfStandard {
              _id
              type
              title
              subject {
                _id
                name
                shortCode
              }
              grade {
                _id
                name
                shortCode
              }
              code
              description
            }
            skill {
              _id
              name
              shortCode
            }
            subSkill {
              _id
              name
              shortCode
            }
            microTopic {
              _id
              title
              microtopicId
              understandingMicroskill
              doingMicroskill
            }
            difficulty {
              _id
              name
              shortCode
            }
            multiCompetency {
              _id
              blockId
              text
            }
          }
          questionPurpose
          difficulty
          questionType
          questionData
          createdAt
          updatedAt
        }
        blockId
        blockName
        blockImage {
          brief
          brief_url
          image_url
          assetId
          briefAssetId
          imageUrlApproved
        }
        aims{
          _id
          text
        }
        assignees {
          author {
            _id
            name
          }
          reviewer1 {
            _id
            name
          }
          reviewer2 {
            _id
            name
          }
          academicSignOff {
            _id
            name
          }
          editor {
            _id
            name
          }
          visualizer {
            _id
            name
          }
          assetUploader {
            _id
            name
          }
          assetAuthorizer {
            _id
            name
          }
          proofReader {
            _id
            name
          }
        }
        workflowStatus
        currentStage
        gradeId
        subjectId
      }
    }
  }
`;
};

export const fetchConfidenceBlocks = (standardId, subjectId) => {
  return gql`
    query {
      fetchConfidenceBlocks(
        gradeId: "${standardId}"
        subjectId: "${subjectId}"
      ) {
        data {
          blockSequenceNumber
          blockId
          blockName
          assignees
          currentStage
          isProjectExist
          projectId
          projectName
          standardId
          standardName
          subjectId
          subjectName
          workflowStatus
        }
        success
        error
      }
    }
`;
};

export const updateAssignees = () => {
  return gql`
    mutation updateConfidenceProject(
      $projectId: ID
      $assignees: ConfidenceProjectAssigneesInputType
    ) {
      updateConfidenceProject(input: { projectId: $projectId, assignees: $assignees }) {
        success
        error
      }
    }
  `;
};
export const updateBlock = () => {
  return gql`
    mutation updateConfidenceProject(
      $projectId: ID
      $blockName: String
      $aims: [JSON]
      $blockImage: BlockImageInputType
    ) {
      updateConfidenceProject(
        input: {
          projectId: $projectId
          blockName: $blockName
          aims: $aims
          blockImage: $blockImage
        }
      ) {
        success
        error
      }
    }
  `;
};
export const createConfidenceProject = () => {
  return gql`
    mutation createConfidenceProject($input: ProjectCreateInputType!) {
      createConfidenceProject(input: $input) {
        id
        name
        workflowStatus
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
        }
        contents {
          id
          library_id
          name
          type
          template
          currentStage
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
      }
    }
  `;
};

export const createConfidenceBlock = () => {
  return gql`
    mutation createConfidenceBlock(
      $title: String!
      $blockId: String!
      $blockSequenceNumber: Int!
      $subjectId: ID!
      $standardId: ID!
      $assignees: ConfidenceProjectAssigneesInputType
    ) {
      createConfidenceBlock(
        input: {
          title: $title
          blockId: $blockId
          blockSequenceNumber: $blockSequenceNumber
          subjectId: $subjectId
          standardId: $standardId
          assignees: $assignees
        }
      ) {
        id
        name
        workflowStatus
        standard {
          id
          name
          displayName
        }
        subject {
          id
          name
        }
        contents {
          id
          library_id
          name
          type
          template
          currentStage
        }
        assignees {
          author {
            id
            name
          }
          reviewer1 {
            id
            name
          }
          reviewer2 {
            id
            name
          }
          academicSignOff {
            id
            name
          }
          editor {
            id
            name
          }
          visualizer {
            id
            name
          }
          assetUploader {
            id
            name
          }
          assetAuthorizer {
            id
            name
          }
          proofReader {
            id
            name
          }
        }
      }
    }
  `;
};
export const checkIsConfidenceUser = () => {
  return gql`
    query {
      isConfidenceUser: canPerformLPAction(action: "canAccessConfidence", arguments: {})
    }
  `;
};

export const updateConfidenceQuestion = () => {
  return gql`
    mutation updateConfidenceQuestion(
      $projectId: ID
      $isGroupQuestion: Boolean
      $questionData: [ConfidenceQuestionInput]
    ) {
      updateConfidenceQuestion(
        input: {
          projectId: $projectId
          isGroupQuestion: $isGroupQuestion
          questionData: $questionData
        }
      ) {
        success
        error
      }
    }
  `;
};
export const saveProjectLessonPlanStageData = () => {
  return gql`
    mutation saveProjectLessonPlanStageData(
      $projectId: ID!
      $contentLibraryId: ID
      $data: JSON!
      $equationEditorJsonArray: [EquationEditorJsonInput]!
    ) {
      saveProjectLessonPlanStageData(
        projectId: $projectId
        contentLibraryId: $contentLibraryId
        data: $data
        equationEditorJsonArray: $equationEditorJsonArray
      )
    }
  `;
};
export const validateProjectLessonPlanStageData = () => {
  return gql`
    mutation validateProjectLessonPlanStageData($projectId: ID!, $contentLibraryId: ID) {
      validateProjectLessonPlanStageData(
        projectId: $projectId
        contentLibraryId: $contentLibraryId
      ) {
        status
        errors {
          path
          errorMessage
        }
      }
    }
  `;
};
export const moveProjectLessonPlanToNextStage = () => {
  return gql`
    mutation moveProjectLessonPlanToNextStage($projectId: ID!, $contentLibraryId: ID) {
      moveProjectLessonPlanToNextStage(projectId: $projectId, contentLibraryId: $contentLibraryId) {
        currentStage
      }
    }
  `;
};

export const deleteConfidenceQuestion = () => {
  return gql`
    mutation deleteConfidenceQuestion($projectId: ID!, $questionId: ID) {
      deleteConfidenceQuestion(input: { projectId: $projectId, questionId: $questionId }) {
        success
        error
      }
    }
  `;
};
export const deleteConfidenceGroupQuestion = () => {
  return gql`
    mutation deleteConfidenceQuestion($projectId: ID!, $questionGroupId: ID) {
      deleteConfidenceQuestion(
        input: { projectId: $projectId, questionGroupId: $questionGroupId }
      ) {
        success
        error
      }
    }
  `;
};

export const publishConfidenceProject = () => {
  return gql`
    mutation($projectId: ID!) {
      publishConfidenceProject(input: { projectId: $projectId }) {
        success
        error
      }
    }
  `;
};

export const createConfidenceQuestion = () => {
  return gql`
    mutation addConfidenceQuestion(
      $projectId: ID
      $questionData: [ConfidenceQuestionInput]
      $isGroupQuestion: Boolean
    ) {
      addConfidenceQuestion(
        input: {
          projectId: $projectId
          questionData: $questionData
          isGroupQuestion: $isGroupQuestion
        }
      ) {
        success
        error
      }
    }
  `;
};
export const fetchMultiCompetencies = () => {
  return gql`
    query {
      fetchMultiCompetencies(
        blockId: "66d2c55ec0b792a3be671769"
        gradeId: "66d2c4cdc0b792a3be66d310"
        subjectId: "66d2c4d7c0b792a3be66d343"
      ) {
        data {
          _id
          title
          aims {
            _id
            text
          }
        }
      }
    }
  `;
};

export const fetchConfidenceMetadata = () => {
  return gql`
    query fetchConfidenceMetadata($gradeId: ID, $subjectId: ID) {
      fetchConfidenceMetadata(gradeId: $gradeId, subjectId: $subjectId) {
        data {
          skills {
            name
            shortCode
            _id
          }
          difficulties {
            _id
            name
          }
          cognitiveTypes {
            name
            _id
          }
          questionTypes {
            name
            _id
          }
          microTopics {
            _id
            title
            doingMicroskill
            standards {
              standardName
              superIdShort
              globalStandardCode
              details
            }
          }
          pisaStandards {
            _id
            type
            title
            subject {
              _id
              name
              shortCode
            }
            grade {
              _id
              name
              shortCode
            }
            code
            description
          }
          ncfStandards {
            _id
            type
            title
            subject {
              _id
              name
              shortCode
            }
            grade {
              _id
              name
              shortCode
            }
            code
            description
          }
          multiCompetencies {
            _id
            text
            block {
              _id
              title
            }
            grade {
              name
              shortCode
            }
            subject {
              name
              shortCode
            }
          }
        }
      }
    }
  `;
};
