<template>
  <v-form ref="applicationSectionForm" class="section-title-cnr">
    <Slide :slide="'Slide 9'" />
    <Padder>
      <v-row>
        <fieldset class="teacher-tip-fieldset">
          <legend class="first-legend">
            {{ AppTexts.APPLICATION_SECTION_TEACHER_TIP_HEADER }}
          </legend>
          <div class="input-wrapper">
            <p>{{ AppTexts.STUDENT_ATTENTION_TEXT }}</p>
            <p class="p-text">{{ AppTexts.THOSE_WHO_NEED_MORE_HELP }}</p>
            <TextInput
              :placeholder="AppTexts.EXPLANATION"
              :enableTextFormatting="true"
              :readonly="options.isReadOnly"
              :canShowDiffIcon="options.canShowDiffIcon"
              fieldIdentifier="application.differentiation_tips.need_more_help.description"
              :max="100"
              class="mgn-Bm"
              v-model="data.differentiation_tips.need_more_help.description"
              :rules="[(v) => !!v || options.errorOfRequired]"
              :counter="160"
            />
            <p class="p-text">{{ AppTexts.THOSE_WHO_NEED_EXTRA_CHALLENGE }}</p>
            <TextInput
              :placeholder="AppTexts.EXPLANATION"
              :enableTextFormatting="true"
              :readonly="options.isReadOnly"
              :canShowDiffIcon="options.canShowDiffIcon"
              fieldIdentifier="application.differentiation_tips.need_extra_challenge.description"
              :max="100"
              class="mgn-Bm"
              v-model="data.differentiation_tips.need_extra_challenge.description"
              :rules="[(v) => !!v || options.errorOfRequired]"
              :counter="160"
            />
          </div>
        </fieldset>
      </v-row>
    </Padder>
    <Padder>
      <v-row>
        <fieldset class="teacher-tip-fieldset">
          <legend class="first-legend">{{ AppTexts.ASSESSMENT_RUBRIX }}</legend>
          <div class="input-wrapper">
            <v-row class="mgn-Bm">
              <span class="step-number">A</span>
              <TextInput
                :placeholder="AppTexts.APPROACHING"
                :enableTextFormatting="true"
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                fieldIdentifier="application.rubrics.approaching.description"
                :max="100"
                class="application-input-text"
                v-model="data.rubrics.approaching.description"
                :rules="[(v) => !!v || options.errorOfRequired]"
                :counter="160"
              />
            </v-row>
            <v-row class="mgn-Bm">
              <span class="step-number">M</span>
              <TextInput
                :placeholder="AppTexts.MEETING"
                :enableTextFormatting="true"
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                fieldIdentifier="application.rubrics.meeting.description"
                :max="100"
                class="application-input-text"
                v-model="data.rubrics.meeting.description"
                :rules="[(v) => !!v || options.errorOfRequired]"
                :counter="160"
              />
            </v-row>
            <v-row class="mgn-Bm">
              <span class="step-number">E</span>
              <TextInput
                :placeholder="AppTexts.EXCEEDING"
                :enableTextFormatting="true"
                :readonly="options.isReadOnly"
                :canShowDiffIcon="options.canShowDiffIcon"
                fieldIdentifier="application.rubrics.exceeding.description"
                :max="100"
                class="application-input-text"
                v-model="data.rubrics.exceeding.description"
                :rules="[(v) => !!v || options.errorOfRequired]"
                :counter="160"
              />
            </v-row>
          </div>
        </fieldset>
      </v-row>
    </Padder>
  </v-form>
</template>
<script>
import TextInput from '@/components/inputs/TextInput';

import Slide from '@/components/contentEntry/Slide';
import AppTexts from '@/constants/AppTexts';
export default {
  name: 'ApplicationSection',
  data() {
    return {
      AppTexts,
    };
  },
  components: {
    Slide,
    TextInput,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.input-wrapper {
  padding: 15px;
}
.application-input-text {
  width: 93%;
}
.p-text {
  color: #242424;
  font-size: 16px;
  font-weight: 500;
}
.first-legend {
  background: #bb161b;
  color: #fff;
  font-size: 20px;
  padding: 7px 20px;
  border-radius: 20px;
  margin: 10px;
}
.teacher-tip-fieldset {
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid #979797;
}
.step-number {
  height: 30px;
  width: 30px;
  padding: 4px;
  margin: 10px 5px;
  border-radius: 50%;
  background-color: #b5e9e4;
  text-align: center;
}
</style>
