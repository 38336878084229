import { CA_APP_NAME, CA_QUESTION_PRESET } from '@/constants/centralizedAssetContants.js';
import { getCookie } from '@/utils/auth';
import axios from 'axios';
import store from '@/store';
import path from 'path';

export const setFormDataForCAUpload = (file, fileName) => {
  let formData = new FormData();
  formData.append('assets', file, fileName);
  formData.append('preset', CA_QUESTION_PRESET);
  formData.append('appName', CA_APP_NAME);
  return formData;
};

export const uploadToCA = async (file, fileName) => {
  let formData = setFormDataForCAUpload(file, fileName);
  let response = await axios.post(`${process.env.VUE_APP_ASSET_VAULT_ENDPOINT}/upload`, formData, {
    headers: {
      Authorization: `Bearer ${getCookie('xs-lp-entry-token')}`,
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    onUploadProgress: function (progressEvent) {
      if (path.extname(fileName) == '.mp4') {
        store.commit(
          'SET_FILE_UPLOAD_PROGRESS_VALUE',
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      }
    },
  });
  if (path.extname(fileName) == '.mp4') {
    store.commit('SET_FILE_UPLOAD_PROGRESS_VALUE', 0);
  }
  if (response && response.data && response.data.data) {
    let { _id, data } = response.data.data;
    return {
      status: 'success',
      id: _id,
      signedUrl: data.optimized ? data.optimized : data.original,
    };
  } else {
    return { status: 'failed' };
  }
};
