export const MICRO_TOPIC_STATE = {
  microTopics: [],
  currentMicroTopic: {},
  initialMicroTopicsListApiStatus: 'not_started',
  microTopicfilterOptions: {
    subjects: [],
    standards: [],
    themes: []
  },
  microTopicTotalCount: 0,
  summaryFilter: {}
};
