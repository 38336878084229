<template>
  <div>
    <v-row class="alc">
      <v-col>
        <div class="lesson-plan-title">{{ lessonPlanDetails.title }}</div>
        <div class="lesson-plan-details">{{ lessonPlanDetails.details }}</div>
      </v-col>
      <v-col v-if="lessonPlanDetails.time && lessonPlanDetails.time.length" :cols="4">
        <div class="lesson-plan-time-wrapper">
          <img
            style="margin-bottom: 5px;"
            class="mgn-Rm"
            height="20"
            width="20"
            src="@/assets/time.svg"
          />
          <span class="lesson-plan-time">{{ lessonPlanDetails.time }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <TextInput
          v-if="showImageTitle"
          :value="title"
          placeholder="Image Title"
          :canShowDiffIcon="false"
          :onChange="imageTitleChangeHandler"
          :readonly="!isNotReadOnly"
        />
        <FileInput
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isImageChanged"
          :diffIdentifier="isAssetUploadRequired ? 'assetId' : 'brief.assetId'"
          :fileAcceptType="isAssetUploadRequired ? 'image/*' : 'image/*,.docx,.doc'"
          :label="coverImagePlaceholder"
          :loadingStatus="loadingStatus"
          :isRequired="isAssetUploadRequired"
          :isAssetUploadRequired="isAssetUploadRequired"
          :fileChangeHandler="fileChangeHandler"
          :previewUrl="previewUrl"
          :previewUrlBrief="previewUrlBrief"
          :image_url="image_url"
          :image_url_brief="image_url_brief"
          :isNotReadOnly="isNotReadOnly"
          :fileValidators="fileValidators"
          :isAssetApproved="isAssetApproved"
          @addBriefToAsset="
            (previewUrl, briefUrl, isAssetApproved) =>
              copyLessonPlanCoverImage(previewUrl, briefUrl, isAssetApproved)
          "
          :canFinalizeAsset="canFinalizeAsset"
          :canAppproveAsset="canApproveAsset"
        />
        <RichTextEditor
          class="mgn-Tm"
          v-if="showImageBrief"
          :canShowDiffIcon="canShowDiffIcon"
          fieldIdentifier="brief"
          :isDiffChanged="isImageBriefDiffChanged"
          @input="imageBriefChangeHandler($event)"
          :value="brief"
          placeholder="Image brief"
          :showValidationError="true"
          :counter="3000"
          :readonly="!isNotReadOnly"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
      <v-col />
      <v-col cols="6">
        <Stepper
          :currentStepIndex="currentStageDetails.index"
          :headerText="currentStageDetails.stageName"
          :currentProjectAssignees="currentProjectAssignees"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FileInput from '@/components/inputs/FileInput';
import Stepper from '@/components/lessonPlan/Stepper';
import Errors from '@/constants/Errors';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import TextInput from '@/components/inputs/TextInput.vue';

export default {
  name: 'CoverImage',
  data() {
    return {
      BASIC_TOOLS,
      Errors,
    };
  },
  components: {
    FileInput,
    RichTextEditor,
    Stepper,
    TextInput,
  },
  props: {
    title: { type: String, required: false },
    brief: { type: String, required: true },
    image_url: { type: String, required: false, default: '' },
    image_url_brief: { type: String, required: false, default: '' },
    lessonPlanDetails: { type: Object, required: true },
    coverImagePlaceholder: { type: String, required: true },
    previewUrl: { type: String, required: false, default: '' },
    previewUrlBrief: { type: String, required: false, default: '' },
    isAssetUploadRequired: { type: Boolean, required: true },
    loadingStatus: { type: Boolean, required: true },
    fileChangeHandler: { type: Function, required: true },
    imageTitleChangeHandler: { type: Function, required: true },
    imageBriefChangeHandler: { type: Function, required: true },
    canShowDiffIcon: { type: Boolean, required: true },
    isImageChanged: { type: Boolean, required: false },
    showImageBrief: { type: Boolean, required: true },
    isImageBriefDiffChanged: { type: Boolean, required: true },
    showImageTitle: { type: Boolean, required: false, default: false },
    currentStageDetails: { type: Object, required: true },
    isNotReadOnly: { type: Boolean, required: false, default: true },
    fileValidators: { type: Array, required: false, default: () => [] },
    copyLessonPlanCoverImage: { type: Function, required: true },
    canApproveAsset: { type: Boolean, required: true },
    canFinalizeAsset: { type: Boolean, required: true },
    isAssetApproved: { type: Boolean, required: true },
    currentProjectAssignees: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style scoped>
.lesson-plan-time-wrapper {
  background-color: #f5f5f5;
  height: 50px;
  width: 200px;
  float: right;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lesson-plan-title {
  font-size: 20px;
  font-weight: 500;
}
.lesson-plan-details {
  color: #242424;
}
.lesson-plan-time {
  font-size: 20px;
}
</style>
