<template>
  <v-btn
    :class="{ 'is-active': isActive }"
    dense
    x-small
    class="tiptap-toolbar-action"
    @click="onClick"
  >
    <v-icon v-if="icon">{{ icon }}</v-icon>
    <img v-else :height="iconSize" :width="iconSize" :src="loadIconData()" />
  </v-btn>
</template>

<script>
export default {
  name: 'ToolBarActionButton',
  methods: {
    loadIconData() {
      return require('@/assets/RTE/' + this.iconName);
    },
  },
  props: {
    onClick: {
      type: Function,
      required: true,
      default: () => {},
    },
    iconName: {
      type: String,
    },
    icon: {
      type: String,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconSize: {
      type: Number,
      required: false,
      default: 20,
    },
  },
};
</script>
<style scoped>
.tiptap-toolbar-action {
  background-color: #f5f5f5 !important;
  border-radius: 0px;
  box-shadow: none;
  padding: 0px !important;
  min-width: 0px !important;
  height: 35px !important;
}
.tiptap-toolbar-action.is-active {
  background-color: #e1e1e1 !important;
}
</style>
