<template>
  <div>
    <v-dialog v-model="showLoader" max-width="400" persistent>
      <v-card>
        <v-card-title style="text-align: center; display: block;" class="headline">
          Brewing your coffee...
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar
      height="60px;"
      dense
      absolute
      color="white"
      elevation="0"
      scroll-target="#scrolling-techniques-7"
    >
      <v-row>
        <div class="bread-crumb-row">
          <a
            href="/learnometer"
            style="color: #818181; text-decoration: underline; margin-left: 20px;"
            >Learnometer Question Bank</a
          >
          <v-icon style="color: #818181;">mdi-chevron-right</v-icon>
          <a href="javascript:void(0)" style="color: #000000;"><b>Create a Question Set</b></a>
        </div>
        <v-spacer></v-spacer>
      </v-row>
    </v-app-bar>

    <v-card style="border: none; box-shadow: none; width: 100%; background-color: #fff0f0;">
      <v-simple-table style="background-color: #fff0f0;">
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                <div style="height: 300px; background-color: #fff0f0; margin-top: -25px;">
                  <div>
                    <v-col>
                      <v-row style="margin-top: 60px;">
                        <td>
                          <v-container class="ml-2">
                            <p
                              style="
                                font-weight: 700;
                                font-size: 20px;
                                margin-left: 60px;
                                margin-top: 55px;
                              "
                            >
                              Create a Question Set
                            </p></v-container
                          >
                        </td>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 294px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                            align-items: center;
                            margin-left: 75px;
                          "
                        >
                          Blueprint:
                        </v-col>

                        <v-col
                          cols="auto"
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            height: 15px;
                            width: 174px;
                            line-height: 15px;
                            vertical-align: top;
                            justify-content: space-between;
                            margin-left: 15px;
                          "
                        >
                          Grade:
                        </v-col>
                      </v-row>
                      <div
                        style="
                          width: 1300px;

                          height: 40px;
                          margin-left: 65px;
                        "
                      >
                        <v-row>
                          <div
                            style="
                              position: relative;
                              height: 40px;
                              margin-left: 15px;
                              margin-top: 2px;
                            "
                          >
                            <v-select
                              outlined
                              placeholder="Select a Blueprint"
                              :items="blueprints.data"
                              item-text="name"
                              item-value="id"
                              width="222"
                              dense
                              hide-details
                              v-model="blueprintId"
                              style="font-size: 12px;box-sizing: border-box;justify-content: space-between;align-items: center; width:256px;height: :34px;border: 0.5px solid #818181;"
                            ></v-select>
                          </div>

                          <div style="margin-left: 55px; margin-top: 2px;">
                            <v-select
                              :disabled="!blueprintId || blueprintId == 0"
                              outlined
                              placeholder="Select a Grade"
                              :items="standards.data"
                              dense
                              v-model="standardId"
                              hide-details
                              style="font-size: 12px;box-sizing: border-box;justify-content: space-between;align-items: center; width:150px;height: :34px;border: 0.5px solid #818181;"
                              item-text="name"
                              item-value="id"
                            ></v-select>
                          </div>

                          <div style="margin-top: 2px; margin-left: -20px;">
                            <v-btn
                              text
                              rounded="xl"
                              size="large"
                              variant="flat"
                              style="
                                background: #fff0f0;
                                border: none;
                                box-shadow: none;
                                text-transform: unset !important;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                width: fit-content;
                                text-shadow: none;
                                margin-left: 50px;
                                align-content: center;

                                height: 15px;
                              "
                              @click="resetFilter()"
                            >
                              Clear
                            </v-btn>

                            <v-btn
                              rounded="xl"
                              size="small"
                              variant="flat"
                              class="primary"
                              style="text-transform: unset !important;"
                              width="110"
                              @click="createQuestionSet()"
                            >
                              Create
                            </v-btn>
                          </div>
                        </v-row>
                      </div>
                    </v-col>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <div style="display: flex; flex-direction: row; justify-content: center;">
      <div class="select-blueprint-message">
        <p>Select a blueprint & a grade to create a new question set.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CreateQuestionSet',
  created() {
    this.fetchBlueprints();
    this.fetchStandards({
      allStandards: false,
    });
  },
  computed: {
    ...mapState({
      standards: (state) => state.learnometer.standardsListApiResponse,
      blueprints: (state) => state.learnometer.blueprintListApiResponse,
      createdTestData: (state) => state.learnometer.createTestApiResponse,
    }),
    showLoader() {
      return (
        this.blueprints.status == 'in_progress' || this.createdTestData.status == 'in_progress'
      );
    },
  },
  watch: {},
  methods: {
    ...mapActions(['fetchStandards', 'fetchBlueprints', 'createXLMTest']),
    triggerFilter() {
      console.log(this.subjectId);
      this.fetchStandards({
        allStandards: false,
      });
    },
    resetFilter() {
      this.blueprintId = '';
      this.standardId = '';
      this.triggerFilter();
    },
    getStandardsBasedOnBlueprint() {
      console.log(this.blueprintId);
    },
    async createQuestionSet() {
      if (this.blueprintId !== '' && this.standardId !== '') {
        await this.createXLMTest({
          questionSetId: this.blueprintId,
          standardId: parseInt(this.standardId),
        });
        console.log(this.createdTestData);
        // this.$router.push({
        //   name: 'QuestionSetAuthoring',
        //   params: { testId: '649b3b2733aca62938b4283b' },
        // });
        console.log(this.standardId), console.log(this.blueprintId);
      }
    },
  },
  data() {
    return {
      standardId: '',
      blueprintId: '',
    };
  },
};
</script>
<style>
.page-title {
  width: 175px;
  height: 23px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
}
.bread-crumb-row p {
  width: 5px;
  height: 9px;
  padding: 9px 10px;
  color: #979797;
}
.bread-crumb-row a {
  cursor: pointer;
  padding: 10px 10px;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}
.bread-crumb-row {
  display: flex;
  flex-direction: row;
  padding-left: 60px;
}
.page-title {
  margin-top: 55px;
  margin-left: 60px;
}
.label-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding-bottom: 0%;
  margin-bottom: 0%;
}
.text-search-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-top: -20px;
  width: 294px;
}
.select-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff0f0;
  margin-top: -20px;
}
.select-blueprint-message {
  align-items: center;
  margin-top: 300px;
}
.question-sets-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  position: absolute;
  height: 19px;
  top: 320px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.btn {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
</style>
