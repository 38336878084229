<template>
  <div :style="`background: #eee; border-radius: 7px; padding: 10px 3px`">
    <v-row v-for="(distractor, j) in data" :key="`blank-row-wrapper-${j}`">
      <v-col style="width: 20px;">
        <span class="tip-tap-plugin-blank-wrapper" style="border-bottom: 0px;"
          ><span
            class="tip-tap-plugin-blank"
            :style="`color: transparent; background: #bb151b; width: 15px; height: 15px;`"
          ></span
        ></span>
      </v-col>
      <v-col cols="10">
        <RichTextEditor
          :key="`blank-distractor-answer-${j}`"
          :readonly="false"
          :canShowDiffIcon="true"
          v-model="distractor.text"
          placeholder="Enter the distractor text here"
          :fieldIdentifier="`['${j}']`"
          projectType="question"
          :showValidationError="true"
          :includeOnlyToolBarActions="['equation']"
        />
      </v-col>
      <v-col>
        <v-btn
          icon
          @click="
            () => {
              _deleteDistractor(j);
            }
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="addDistractor" color="primary"
          ><v-icon left> mdi-plus </v-icon>Add Distractor</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import { FIB_COLOR_CODES } from '@/components/RTE/utils/constants.js';

export default {
  data() {
    return {
      FIB_COLOR_CODES,
    };
  },
  components: {
    RichTextEditor,
  },
  methods: {
    _deleteDistractor(j) {
      this.data.splice(j, 1);
    },
    addDistractor() {
      this.data.push({ text: '' });
    },
    updatedistractor(dataJson, j) {
      this.data[j] = dataJson;
    },
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
};
</script>
