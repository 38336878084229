import gql from 'graphql-tag';

export const LessonPlansQuery = (boardName, subjectId, standardId, productId) => {
  return gql`
    query {
      blockMapLessonPlans(filter: {
        boardName: "${boardName}",
        subjectId: ${subjectId},
        standardId: ${standardId},
        productId: ${productId}

      }){
        name
        linkedName
        libraryId
        lpType
        description
        bId
        blockSequence
        blockTitle
        sequence
      }
    }
  `;
};

export const BlockMapLPDPQuery = (productName, boardName) => {
  return gql`
    query {
      blockMapsWithBlockLessonDailyPlans(productName:"${productName}",boardName:"${boardName}") {
        bmId
        Product
        Board
        Subject
        Standard
        bId
        sequence
        libraryId
      }
    }
  `;
};
