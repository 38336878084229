<template>
  <div class="">
    <v-card>
      <div id="training-list-tabs" style="overflow: auto; padding-right: 50px; padding-top: 10px;">
        <!-- TRAINING_LIST_TABS Starts -->
        <v-tabs
          v-model="tab"
          background-color="transparent"
          align-with-title
          dense
          style="width: 80%; float: left;"
          color="basil"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="(tabItem, itemIndex) in tabItems"
            :key="itemIndex"
            style="letter-spacing: 0px;"
            :href="`#tab-${itemIndex}`"
          >
            {{ tabItem.name }}
          </v-tab>
        </v-tabs>
        <!-- TRAINING_LIST_TABS Ends -->
      </div>
    </v-card>

    <div class="training-list-cards-wrapper" style="padding: 0 25px;">
      <div v-if="fetchTrainingApiStatus == 'in_progress'">
        <div>
          <v-skeleton-loader
            class="skeleton-loader-placeholder"
            type="list-item-two-line, list-item-two-line, list-item-two-line"
          />
        </div>
      </div>
      <div v-else-if="fetchTrainingApiStatus == 'completed'">
        <v-card-title>
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div class="training-list-cards">
          <div v-if="tab == tabItems[0].tabId">
            <div v-if="upcomingTrainingsInOrder.length > 0" fluid>
              <TrainingDetailsTable
                :trainings="upcomingTrainingsInOrder"
                :searchText="searchText"
                :headers="headers"
                :trainingHeaders="trainingHeaders"
              />
            </div>
            <div v-else class="text-center mgnTm">
              No upcoming trainings right now.
            </div>
          </div>
          <div v-else-if="tab == tabItems[1].tabId">
            <div v-if="completedYearsInOrder.length > 0">
              <!-- <TrainingDetailsTable
                :trainings="[]"
                :headers="headers"
                :trainingHeaders="trainingHeaders"
                classNames="empty-tbody"
              /> -->
              <div v-for="(yearName, yearIndex) in completedYearsInOrder" :key="yearIndex">
                <v-container fluid>
                  <span
                    class="year-name-header-text"
                    style="padding: 10px; background-color: #f6e3e4;"
                  >
                    Completed Trainings in {{ yearName }}
                  </span>
                  <!-- <div style="margin-top: 10px;"></div> -->
                </v-container>
                <TrainingDetailsTable
                  :trainings="completedTrainingsGroupedByYearInOrder[yearName]"
                  :searchText="searchText"
                  :hideHeader="false"
                  :headers="headers"
                  :trainingHeaders="trainingHeaders"
                  classNames="empty-tead"
                />
              </div>
            </div>
            <div v-else class="text-center mgnTm">
              No completed trainings right now.
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="fetchTrainingApiStatus == 'error'"
        style="margin-top: 50px;"
        class="text-center"
      >
        <div class="mgnTm"></div>
        <p>{{ fetchTrainingApiStatusMessage }}</p>
      </div>
      <div v-else></div>
    </div>
    <!-- TRAINING_LIST_ITEMS Ends -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { sortBy, groupBy, reverse } from 'lodash';
import TrainingDetailsTable from './TrainingDetailsTable.vue';

const TAB_ITEMS = [
  { name: 'Upcoming Training', tabId: 'tab-0' },
  { name: 'Completed Training', tabId: 'tab-1' },
];

export default {
  name: 'Trainings',
  created() {
    this.fetchTrainings();
  },
  components: {
    TrainingDetailsTable,
  },
  data: () => {
    return {
      tab: null,
      tabItems: TAB_ITEMS,
      searchText: null,
      options: {},
      headers: [
        {
          value: 'index',
          sortable: false,
          text: 'S.No',
          class: 'x-grey-background',
          width: '10%',
        },
        {
          value: 'title',
          sortable: false,
          text: 'Training title',
          class: 'x-grey-background eighty-width',
        },
        { text: 'Actions', value: 'data-table-expand', class: 'x-grey-background', width: '10%' },
      ],
      trainingHeaders: [
        {
          value: 'name',
          sortable: false,
          text: 'Name',
          align: 'center',
          divider: true,
        },
        {
          value: 'count',
          sortable: false,
          text: 'Training title',
          align: 'center',
          divider: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          divider: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'completedTrainings',
      'upcomingTrainings',
      'fetchTrainingApiStatus',
      'fetchTrainingApiStatusMessage',
    ]),
    upcomingTrainingsInOrder() {
      if (this.upcomingTrainings) {
        let index = 0;
        const upcomingTrainingsCopy = JSON.parse(JSON.stringify(this.upcomingTrainings));
        return sortBy(upcomingTrainingsCopy, 'sortParam').map((_e) => {
          console.log('_e', _e);
          index++;
          _e.index = index;
          _e.tableData = [];
          _e.tableData.push({ name: 'Pre-Test', count: _e.preTestQuestionCount });
          Object.keys(_e.pollsQuestionCount).forEach((element) => {
            _e.tableData.push({ name: element, count: _e.pollsQuestionCount[element] });
          });
          _e.tableData.push({ name: 'Post-Test', count: _e.postTestQuestionCount });
          _e.tableData.push({
            name: 'Feedback-Questions',
            count: _e.feedbackTestQuestionCount,
          });
          return _e;
        });
      }
      return [];
    },
    completedTrainingsGroupedByYearInOrder() {
      if (this.completedTrainings) {
        const completedTrainingsCopy = JSON.parse(JSON.stringify(this.completedTrainings));

        const groupedCompletedTrainings = groupBy(completedTrainingsCopy, 'groupByParam');
        for (const key in groupedCompletedTrainings) {
          let index = 0;
          for (const iterator of groupedCompletedTrainings[key]) {
            index++;
            iterator.index = index;
            iterator.tableData = [];
            iterator.tableData.push({ name: 'Pre-Test', count: iterator.preTestQuestionCount });
            Object.keys(iterator.pollsQuestionCount).forEach((element) => {
              iterator.tableData.push({
                name: element,
                count: iterator.pollsQuestionCount[element],
              });
            });
            iterator.tableData.push({ name: 'Post-Test', count: iterator.postTestQuestionCount });
            iterator.tableData.push({
              name: 'Feedback-Questions',
              count: iterator.feedbackTestQuestionCount,
            });
          }
        }

        return groupedCompletedTrainings;
      }

      return {};
    },
    completedYearsInOrder() {
      const yearNames = Object.keys(this.completedTrainingsGroupedByYearInOrder);
      return reverse(sortBy(yearNames));
    },
  },
  methods: {
    ...mapActions(['fetchTrainings']),
    handleRowClick(item) {
      this.$router.push({ name: 'XSEEDAcademyQuestions', params: { trainingId: item.id } });
    },
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0px;
}
.v-tab {
  text-transform: capitalize;
  font-size: 16px;
}
.v-tab.v-tab--active {
  color: #bb151b !important;
  font-weight: 600;
}
.skeleton-loader-placeholder {
  float: left;
  width: 350px;
  margin-left: 40px;
  margin-top: 20px;
}
</style>
