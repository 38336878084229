import { twoStepTemplate } from '@/mixins/lessonPlan/earlyProgram/twoStepTemplate';
import { fiveStepTemplate } from '@/mixins/lessonPlan/earlyProgram/fiveStepTemplate';
import { standardLessonMixin } from '@/mixins/lessonPlan/standardLesson';
import { magicLessonMixin } from '@/mixins/lessonPlan/magicLesson';
import { practiceLessonMixin } from '@/mixins/lessonPlan/practiceLesson';
import { foundation5aLessonMixin } from '@/mixins/lessonPlan/foundation5aLesson';
import { discussionLessonMixin } from '@/mixins/lessonPlan/discussionLesson';
import { informationAndAssessmentLessonMixin } from '@/mixins/lessonPlan/informationAndAssessmentLesson';
import { phonicsLessonMixin } from '@/mixins/lessonPlan/phonicsLesson';
import { classRoomLessonMixin } from '@/mixins/lessonPlan/classRoomLesson';
import { LabLessonMixin } from '@/mixins/lessonPlan/labLesson';

export const APP_COOKIE_NAME = 'xs-lp-entry-token';

export const STATUS_NOT_STARTED = 'not_started';
export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_COMPLETED = 'completed';

export const TWO_STEP_TEMPLATE = '2Step';
export const FIVE_STEP_TEMPLATE = '5Step';

export const LP_TYPE_STANDARD = 'standard';
export const LP_TYPE_MAGIC = 'magic';
export const LP_TYPE_PRACTICE = 'practice';
export const LP_TYPE_VIRTUAL_QUESTIONS = 'virtualQuestions';
export const LP_TYPE_REVISION = 'revision';
export const LP_TYPE_ASSESSMENT = 'assessment';
export const LP_TYPE_MENTAL_MATH = 'mental_math';
export const LP_TYPE_FOUNDATION_5A = 'foundation_5a';
export const LP_TYPE_DISCUSSION = 'discussion';
export const LP_TYPE_INFORMATION_AND_ASSESSMENT = 'information_assessment';
export const LP_TYPE_PHONICS = 'phonics';
export const LP_TYPE_CLASSROOM = 'classroom';
export const LP_TYPE_LAB = 'lab';

export const PROJECT_TYPE_SCHOOL_LESSON_PLAN = 'SchoolLessonPlan';
export const PROJECT_TYPE_VIRTUAL_QUESTION = 'virtualQuestion';
export const PROJECT_TYPE_QUESTIONS = 'questions';

export const PROJECT_ENTITY_TYPE_LEARNOMETER = 'Learnometer';

export const LP_TYPES_HASH = {
  STANDARD: LP_TYPE_STANDARD,
  MAGIC: LP_TYPE_MAGIC,
  PRACTICE: LP_TYPE_PRACTICE,
  VIRTUAL_QUESTIONS: LP_TYPE_VIRTUAL_QUESTIONS,
  REVISION: LP_TYPE_REVISION,
  ASSESSMENT: LP_TYPE_ASSESSMENT,
  MENTAL_MATH: LP_TYPE_MENTAL_MATH,
  FOUNDATION_5A: LP_TYPE_FOUNDATION_5A,
  INFORMATION_AND_ASSESSMENT: LP_TYPE_INFORMATION_AND_ASSESSMENT,
  DISCUSSION: LP_TYPE_DISCUSSION,
  PHONICS: LP_TYPE_PHONICS,
  CLASSROOM: LP_TYPE_CLASSROOM,
  LAB: LP_TYPE_LAB,
};

const LP_TEMPLATE_STANDARD = 'standard';
const LP_TEMPLATE_MAGIC = 'magic';
const LP_TEMPLATE_PRACTICE = 'practice';
const LP_TEMPLATE_QUESTION = 'question';
const LP_TEMPLATE_PHONICS = 'phonics';
const LP_TEMPLATE_INFORMATION_ASSESSMENT = 'information_assessment';
const LP_TEMPLATE_FOUNDATION5A = 'foundation5a';
const LP_TEMPLATE_DAY4DISCUSSION = 'day4discussion';
const LP_TEMPLATE_CLASSROOM = 'classroom';
const LP_TEMPLATE_LAB = 'lab';

export const LP_TEMPLATES_HASH = {
  STANDARD: LP_TEMPLATE_STANDARD,
  MAGIC: LP_TEMPLATE_MAGIC,
  PRACTICE: LP_TEMPLATE_PRACTICE,
  QUESTION: LP_TEMPLATE_QUESTION,
  PHONICS: LP_TEMPLATE_PHONICS,
  INFORMATION_ASSESSMENT: LP_TEMPLATE_INFORMATION_ASSESSMENT,
  FOUNDATION5A: LP_TEMPLATE_FOUNDATION5A,
  DAY4DISCUSSION: LP_TEMPLATE_DAY4DISCUSSION,
  CLASSROOM: LP_TEMPLATE_CLASSROOM,
  LAB: LP_TEMPLATE_LAB
};

export const LP_TEMPLATE_TO_ROUTES_HASH = {
  [LP_TEMPLATE_STANDARD]: 'LessonPlanTemplate',
  [LP_TEMPLATE_MAGIC]: 'LessonPlanTemplate',
  [LP_TEMPLATE_PRACTICE]: 'LessonPlanTemplate',
  [LP_TEMPLATE_QUESTION]: 'StandardLPForm',
  [LP_TEMPLATE_PHONICS]: 'LessonPlanTemplate',
  [LP_TEMPLATE_INFORMATION_ASSESSMENT]: 'LessonPlanTemplate',
  [LP_TEMPLATE_FOUNDATION5A]: 'LessonPlanTemplate',
  [LP_TEMPLATE_DAY4DISCUSSION]: 'LessonPlanTemplate',
  [LP_TEMPLATE_CLASSROOM]: 'LessonPlanTemplate',
  [LP_TEMPLATE_LAB]: 'LessonPlanTemplate',
};

export const LP_TEMPLATE_TO_MIXIN_HASH = {
  [TWO_STEP_TEMPLATE]: [twoStepTemplate],
  [FIVE_STEP_TEMPLATE]: [fiveStepTemplate],
  [LP_TEMPLATE_STANDARD]: [standardLessonMixin],
  [LP_TEMPLATE_MAGIC]: [magicLessonMixin],
  [LP_TEMPLATE_PRACTICE]: [practiceLessonMixin],
  [LP_TEMPLATE_PHONICS]: [phonicsLessonMixin],
  [LP_TEMPLATE_INFORMATION_ASSESSMENT]: [informationAndAssessmentLessonMixin],
  [LP_TEMPLATE_FOUNDATION5A]: [foundation5aLessonMixin],
  [LP_TEMPLATE_DAY4DISCUSSION]: [discussionLessonMixin],
  [LP_TEMPLATE_CLASSROOM]: [classRoomLessonMixin],
  [LP_TEMPLATE_LAB]: [LabLessonMixin],
};

const CIRCLE_TIME = 'Circle Time';
const ENGLISH_TIME = 'English Time';
const MATH_TIME = 'Math Time';
const SCIENCE_TIME = 'Science Time';
const ART_TIME = 'Art Time';
const STORY_TIME = 'Story Time';
const PLAY_TIME = 'Play Time';
const DRAMA_TIME = 'Drama Time';
const MUSIC_AND_MOVEMENT_TIME = 'Music & Movement Time';
const HINDI_TIME = 'Hindi Time';

export const DAILY_PLAN_SUPPORTED_TIMES = [
  CIRCLE_TIME,
  ENGLISH_TIME,
  MATH_TIME,
  SCIENCE_TIME,
  ART_TIME,
  STORY_TIME,
  PLAY_TIME,
  DRAMA_TIME,
  MUSIC_AND_MOVEMENT_TIME,
  HINDI_TIME,
];
export const LP_TYPE_ABLE_TO_SWITCH_DIFFERENT_TYPE = [
  LP_TYPE_STANDARD,
  LP_TYPE_MAGIC,
  LP_TYPE_PRACTICE,
  LP_TYPE_REVISION,
  LP_TYPE_MENTAL_MATH,
  LP_TYPE_FOUNDATION_5A,
  LP_TYPE_DISCUSSION,
  LP_TYPE_INFORMATION_AND_ASSESSMENT,
  LP_TYPE_PHONICS,
  LP_TYPE_CLASSROOM,
  LP_TEMPLATE_LAB,
];

export const DP_TEMPLATE_ABLE_TO_SWITCH_DIFFERENT_TEMPLATE = [
  TWO_STEP_TEMPLATE,
  FIVE_STEP_TEMPLATE,
];

export const DP_TIME_CIRCLE_TIME = 'Circle Time';
export const DP_TIME_DRAMA_TIME = 'Drama Time';

export const DP_TIMES_HASH = {
  CIRCLE_TIME: DP_TIME_CIRCLE_TIME,
  DRAMA_TIME: DP_TIME_DRAMA_TIME,
};

export const SUBJECT_SHORT_NAME_ENGLISH = 'ENG';
export const SUBJECT_SHORT_NAME_HINDI = 'HIN';

export const LANGUAGE_SUBJECT_SHORT_NAMES = [SUBJECT_SHORT_NAME_ENGLISH, SUBJECT_SHORT_NAME_HINDI];

export const LP_FORM_ROUTES = [
  'LessonPlanTemplate',
  'DailyPlanLessonPlanTemplate',
  'lpForm',
  'StandardLPForm',
];

export const PROJECT_WORK_TYPE = 'projectWork';
export const PROJECT_WORK_AND_MLC_TYPE = 'projectWorkAndMLC';
export const NONE = 'none';

export const PROJECT_WORK_TYPE_DESCRIPTION = 'Complete the Project Work.';
export const PROJECT_WORK_AND_MLC_TYPE_DESCRIPTION =
  'Complete the Project Work and My Learning Chart.';

export const SUPPORTED_PRACTICE_LESSON_HOMEWORK_TYPES = [
  PROJECT_WORK_TYPE,
  PROJECT_WORK_AND_MLC_TYPE,
  NONE,
];

export const PRACTICE_LESSON_HOMEWORK_DEFAULT_DATA = {
  type: PROJECT_WORK_TYPE,
  description: PROJECT_WORK_TYPE_DESCRIPTION,
  project_work: {
    assetId: '',
    brief: {
      text: '',
      assetId: '',
    },
  },
  my_learning_chart: {
    assetId: '',
    brief: {
      text: '',
      assetId: '',
    },
  },
  my_learning_chart_answer: {
    assetId: '',
    brief: {
      text: '',
      assetId: '',
    },
  },
};

export const PRACTICE_LESSON_HOMEWORK_SIGNED_URL_DATA = {
  project_work: {
    assetId: '',
    brief: {
      assetId: '',
    },
  },
  my_learning_chart: {
    assetId: '',
    brief: {
      assetId: '',
    },
  },
  my_learning_chart_answer: {
    assetId: '',
    brief: {
      assetId: '',
    },
  },
};

export const LINKABLE_LESSON_TYPES_IN_VIRTUAL_QUESTION = [
  LP_TYPES_HASH.STANDARD,
  LP_TYPES_HASH.MAGIC,
  LP_TYPES_HASH.DISCUSSION,
  LP_TYPES_HASH.FOUNDATION_5A,
];
